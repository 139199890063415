import { Component, Input } from '@angular/core';
import { SortDirection } from '../../types/sorting.types';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cloud-table-sort-icon',
  templateUrl: './table-sort-icon.component.html',
  styleUrls: ['./table-sort-icon.component.css']
})
export class TableSortIconComponent {
  @Input() sortDirection: SortDirection;
  @Input() activeSortDirection: SortDirection;

  @Input() identifier: string;
  @Input() activeIdentifier: string;
  @Input() translatedIdentifier: string;

  constructor(public translate: TranslateService) {}
}
