import { ofType, createEffect, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { switchMap, switchMapTo, tap } from 'rxjs/operators';
import { RouterFacade } from './router.facade';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';

import { AvailableRouteIdentifiers } from './../../app.routing.module';
import { TranslateService } from '@ngx-translate/core';

import { TitleService } from './../../services/title.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Injectable()
export class RouterTitleEffects {
  routeToTitleMap: { [key in AvailableRouteIdentifiers]: string } = {
    depotsearch: _('NAV.DEPOTSEARCH'),
    orders: _('NAV.ORDERMANAGEMENT'),
    devices: _('NAV.DEVICES'),
    contentcollection: _('NAV.CONTENTCOLLECTION'),
    docs: _('NAV.DOCUMENTATION'),
    admin: _('APP.ADMINISTRATION'),
    about: _('APP.IMPRINT'),
    tpl: _('APP.THIRDPARTYLICENSES'),
    privacy: _('APP.PRIVACY'),
    copyright: _('APP.COPYRIGHT')
  };

  constructor(
    private actions$: Actions,
    private routerFacade: RouterFacade,
    private titleService: TitleService,
    private translate: TranslateService
  ) {}

  mapRouteToTitle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        switchMapTo(this.routerFacade.currentRootRoute$),
        switchMap((route) => {
          const mappedRouteTitle = this.routeToTitleMap[route!];
          return this.translate.get(mappedRouteTitle ?? _('APP.TITLE'));
        }),
        tap((title) => this.titleService.setBaseTitle(title))
      ),
    { dispatch: false }
  );
}
