import { Point } from '@arction/lcjs';
import { AvailableAxis2D, ScalesConfig, ScalesConfig2D } from './lightningPlot.types';
import { exhaustiveMatchingGuard } from '../../shared/utility-functions/exhaustiveMatchingGuard';
import { StreamingChart } from '../utils/streaming-chart.types';
import { getDbRefAndDbFactor } from '../utils/dataset.utils';

export type ScalingTransformFunction = (value: number, tempDbRef: number, tempDbFactor: number) => number;
export interface ScalingTransformFunctionOptions {
  isSquared?: boolean;
  rawDataType?: any;
  quantity?: any;
  rawQuantity?: any;
}

const calcDBValue = (value: number, dbRef: number, dbFactor: number): number => {
  if (value <= 0) {
    return -400;
  }
  const val = dbFactor * Math.log10(value / dbRef);
  return val < -400 ? -400 : val;
};

export const applyScaleTypeTransformation = (
  value: number,
  axis: AvailableAxis2D,
  scalesConfig: ScalesConfig | ScalesConfig2D,
  plottingParameters?: StreamingChart.PlottingParameters
): number => {
  switch (axis) {
    case 'xAxis': {
      if (scalesConfig.xAxis === 'db') {
        const { dbRef, dbFactor } = getDbRefAndDbFactor(plottingParameters);
        return calcDBValue(value, dbRef, dbFactor);
      } else if (scalesConfig.xAxis === 'third') {
        return value;
      }
      break;
    }
    case 'yAxis': {
      if (scalesConfig.yAxis === 'db') {
        const { dbRef, dbFactor } = getDbRefAndDbFactor(plottingParameters);
        // console.log(`dbRef: ${dbRef}, dbFactor: ${dbFactor}, value: ${value}`);
        return calcDBValue(value, dbRef, dbFactor);
      } else if (scalesConfig.yAxis === 'third') {
        return value;
      }
      break;
    }
    default:
      exhaustiveMatchingGuard(axis);
  }

  return value;
};
