import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'cloud-svg-icon-customizable',
  standalone: true,
  template: `<svg
    attr.width="{{ width }}"
    attr.alt="{{ alt }}"
    attr.height="{{ height }}"
    attr.fill="{{ fill }}"
    attr.stroke="{{ stroke }}"
    attr.class="{{ class }}"
  >
    <ng-container *ngIf="trustedSvgContent">
      <ng-container *ngIf="trustedSvgContent !== 'error'">
        <foreignObject width="100%" height="100%" [innerHTML]="trustedSvgContent"></foreignObject>
      </ng-container>
    </ng-container>
  </svg>`,
  styles: [':host {display: flex}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule]
})
export class SvgIconCustomizableComponent implements OnInit {
  defaultSVGPath = `assets/img/custom_icons`;
  fileExt = '.svg';
  trustedSvgContent: SafeHtml | null = null;

  @Input() set fileName(name: string) {
    name && this.loadSvg(name);
  }
  @Input() width?: number;
  @Input() height?: number;
  @Input() size?: number = 16;
  @Input() fill?: string;
  @Input() stroke?: string;
  @Input() class?: string;

  @Input() alt: string = 'SVG Image';

  constructor(private http: HttpClient, private cref: ChangeDetectorRef, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (!this.width || !this.height) {
      this.width = this.size;
      this.height = this.size;
    }
  }

  loadSvg(fileName) {
    if (fileName.match(/[^a-zA-Z0-9_-]/g)) {
      console.error('Unsafe file name detected, please provide a safe one');
      this.trustedSvgContent = 'error';
      return;
    }

    const safeFileName = fileName.replace(/[^a-zA-Z0-9_-]/g, '');
    const url = `${this.defaultSVGPath}/${safeFileName}${this.fileExt}`;
    this.http.get(url, { responseType: 'text' }).subscribe((data) => {
      this.trustedSvgContent = this.sanitizer.bypassSecurityTrustHtml(data);
      this.cref.markForCheck();
    });
  }
}
