import { LUT, ColorRGBA } from '@arction/lcjs';

/**
 * PAK style color palette as used by per et.al. from sales
 * @param {number} maxIntensity Maximum intensity in plot
 */
export const pakColorPalette = (maxIntensity: number) =>
  new LUT({
    steps: [
      { value: maxIntensity * (0 / 101), color: ColorRGBA(250, 250, 250) },
      { value: maxIntensity * (1 / 101), color: ColorRGBA(238, 244, 251) },
      { value: maxIntensity * (2 / 101), color: ColorRGBA(226, 238, 251) },
      { value: maxIntensity * (3 / 101), color: ColorRGBA(214, 232, 251) },
      { value: maxIntensity * (4 / 101), color: ColorRGBA(202, 226, 251) },
      { value: maxIntensity * (5 / 101), color: ColorRGBA(190, 220, 252) },
      { value: maxIntensity * (6 / 101), color: ColorRGBA(178, 214, 252) },
      { value: maxIntensity * (7 / 101), color: ColorRGBA(166, 208, 252) },
      { value: maxIntensity * (8 / 101), color: ColorRGBA(154, 202, 252) },
      { value: maxIntensity * (9 / 101), color: ColorRGBA(142, 196, 253) },
      { value: maxIntensity * (10 / 101), color: ColorRGBA(130, 190, 253) },
      { value: maxIntensity * (11 / 101), color: ColorRGBA(119, 184, 253) },
      { value: maxIntensity * (12 / 101), color: ColorRGBA(107, 178, 253) },
      { value: maxIntensity * (13 / 101), color: ColorRGBA(95, 172, 254) },
      { value: maxIntensity * (14 / 101), color: ColorRGBA(83, 166, 254) },
      { value: maxIntensity * (15 / 101), color: ColorRGBA(71, 160, 254) },
      { value: maxIntensity * (16 / 101), color: ColorRGBA(59, 154, 254) },
      { value: maxIntensity * (17 / 101), color: ColorRGBA(47, 148, 255) },
      { value: maxIntensity * (18 / 101), color: ColorRGBA(35, 142, 255) },
      { value: maxIntensity * (19 / 101), color: ColorRGBA(23, 136, 255) },
      { value: maxIntensity * (20 / 101), color: ColorRGBA(11, 130, 255) },
      { value: maxIntensity * (21 / 101), color: ColorRGBA(0, 124, 255) },
      { value: maxIntensity * (22 / 101), color: ColorRGBA(2, 128, 247) },
      { value: maxIntensity * (23 / 101), color: ColorRGBA(3, 132, 240) },
      { value: maxIntensity * (24 / 101), color: ColorRGBA(5, 136, 232) },
      { value: maxIntensity * (25 / 101), color: ColorRGBA(6, 140, 225) },
      { value: maxIntensity * (26 / 101), color: ColorRGBA(7, 144, 217) },
      { value: maxIntensity * (27 / 101), color: ColorRGBA(9, 148, 210) },
      { value: maxIntensity * (28 / 101), color: ColorRGBA(10, 151, 202) },
      { value: maxIntensity * (29 / 101), color: ColorRGBA(11, 155, 195) },
      { value: maxIntensity * (30 / 101), color: ColorRGBA(13, 159, 187) },
      { value: maxIntensity * (31 / 101), color: ColorRGBA(14, 163, 180) },
      { value: maxIntensity * (32 / 101), color: ColorRGBA(15, 167, 172) },
      { value: maxIntensity * (33 / 101), color: ColorRGBA(17, 171, 165) },
      { value: maxIntensity * (34 / 101), color: ColorRGBA(18, 175, 157) },
      { value: maxIntensity * (35 / 101), color: ColorRGBA(19, 178, 150) },
      { value: maxIntensity * (36 / 101), color: ColorRGBA(21, 182, 142) },
      { value: maxIntensity * (37 / 101), color: ColorRGBA(22, 186, 135) },
      { value: maxIntensity * (38 / 101), color: ColorRGBA(23, 190, 127) },
      { value: maxIntensity * (39 / 101), color: ColorRGBA(25, 194, 120) },
      { value: maxIntensity * (40 / 101), color: ColorRGBA(24, 195, 115) },
      { value: maxIntensity * (41 / 101), color: ColorRGBA(24, 196, 110) },
      { value: maxIntensity * (42 / 101), color: ColorRGBA(24, 196, 105) },
      { value: maxIntensity * (43 / 101), color: ColorRGBA(24, 197, 100) },
      { value: maxIntensity * (44 / 101), color: ColorRGBA(24, 197, 95) },
      { value: maxIntensity * (45 / 101), color: ColorRGBA(24, 198, 90) },
      { value: maxIntensity * (46 / 101), color: ColorRGBA(24, 198, 85) },
      { value: maxIntensity * (47 / 101), color: ColorRGBA(24, 199, 80) },
      { value: maxIntensity * (48 / 101), color: ColorRGBA(26, 203, 75) },
      { value: maxIntensity * (49 / 101), color: ColorRGBA(28, 206, 70) },
      { value: maxIntensity * (50 / 101), color: ColorRGBA(29, 209, 66) },
      { value: maxIntensity * (51 / 101), color: ColorRGBA(31, 213, 61) },
      { value: maxIntensity * (52 / 101), color: ColorRGBA(33, 216, 57) },
      { value: maxIntensity * (53 / 101), color: ColorRGBA(34, 219, 52) },
      { value: maxIntensity * (54 / 101), color: ColorRGBA(36, 223, 47) },
      { value: maxIntensity * (55 / 101), color: ColorRGBA(38, 226, 43) },
      { value: maxIntensity * (56 / 101), color: ColorRGBA(39, 229, 38) },
      { value: maxIntensity * (57 / 101), color: ColorRGBA(41, 232, 34) },
      { value: maxIntensity * (58 / 101), color: ColorRGBA(43, 236, 29) },
      { value: maxIntensity * (59 / 101), color: ColorRGBA(44, 239, 24) },
      { value: maxIntensity * (60 / 101), color: ColorRGBA(46, 242, 20) },
      { value: maxIntensity * (61 / 101), color: ColorRGBA(48, 246, 15) },
      { value: maxIntensity * (62 / 101), color: ColorRGBA(49, 249, 11) },
      { value: maxIntensity * (63 / 101), color: ColorRGBA(51, 252, 6) },
      { value: maxIntensity * (64 / 101), color: ColorRGBA(52, 255, 2) },
      { value: maxIntensity * (65 / 101), color: ColorRGBA(73, 255, 1) },
      { value: maxIntensity * (66 / 101), color: ColorRGBA(93, 255, 1) },
      { value: maxIntensity * (67 / 101), color: ColorRGBA(113, 255, 1) },
      { value: maxIntensity * (68 / 101), color: ColorRGBA(133, 255, 1) },
      { value: maxIntensity * (69 / 101), color: ColorRGBA(153, 255, 0) },
      { value: maxIntensity * (70 / 101), color: ColorRGBA(173, 255, 0) },
      { value: maxIntensity * (71 / 101), color: ColorRGBA(193, 255, 0) },
      { value: maxIntensity * (72 / 101), color: ColorRGBA(213, 255, 0) },
      { value: maxIntensity * (73 / 101), color: ColorRGBA(233, 255, 0) },
      { value: maxIntensity * (74 / 101), color: ColorRGBA(243, 255, 0) },
      { value: maxIntensity * (75 / 101), color: ColorRGBA(253, 255, 0) },
      { value: maxIntensity * (76 / 101), color: ColorRGBA(255, 248, 0) },
      { value: maxIntensity * (77 / 101), color: ColorRGBA(255, 237, 0) },
      { value: maxIntensity * (78 / 101), color: ColorRGBA(255, 227, 0) },
      { value: maxIntensity * (79 / 101), color: ColorRGBA(255, 217, 0) },
      { value: maxIntensity * (80 / 101), color: ColorRGBA(255, 206, 0) },
      { value: maxIntensity * (81 / 101), color: ColorRGBA(255, 196, 0) },
      { value: maxIntensity * (82 / 101), color: ColorRGBA(255, 186, 0) },
      { value: maxIntensity * (83 / 101), color: ColorRGBA(255, 175, 0) },
      { value: maxIntensity * (84 / 101), color: ColorRGBA(255, 165, 0) },
      { value: maxIntensity * (85 / 101), color: ColorRGBA(255, 155, 0) },
      { value: maxIntensity * (86 / 101), color: ColorRGBA(255, 144, 0) },
      { value: maxIntensity * (87 / 101), color: ColorRGBA(255, 134, 0) },
      { value: maxIntensity * (88 / 101), color: ColorRGBA(255, 124, 0) },
      { value: maxIntensity * (89 / 101), color: ColorRGBA(255, 113, 0) },
      { value: maxIntensity * (90 / 101), color: ColorRGBA(255, 103, 0) },
      { value: maxIntensity * (91 / 101), color: ColorRGBA(255, 93, 0) },
      { value: maxIntensity * (92 / 101), color: ColorRGBA(255, 82, 0) },
      { value: maxIntensity * (93 / 101), color: ColorRGBA(255, 72, 0) },
      { value: maxIntensity * (94 / 101), color: ColorRGBA(255, 62, 0) },
      { value: maxIntensity * (95 / 101), color: ColorRGBA(239, 51, 0) },
      { value: maxIntensity * (96 / 101), color: ColorRGBA(224, 41, 0) },
      { value: maxIntensity * (97 / 101), color: ColorRGBA(208, 31, 0) },
      { value: maxIntensity * (98 / 101), color: ColorRGBA(193, 20, 0) },
      { value: maxIntensity * (99 / 101), color: ColorRGBA(177, 10, 0) },
      { value: maxIntensity * (100 / 101), color: ColorRGBA(162, 0, 0) }
    ],
    interpolate: true
  });

/**
 * Cividis color palette from Matplotlib
 * @param {number} maxIntensity Maximum intensity in plot
 */
export const cividisPalette = (maxIntensity: number) =>
  new LUT({
    steps: [
      { value: maxIntensity * (0 / 255), color: ColorRGBA(0, 34, 78) },
      { value: maxIntensity * (1 / 255), color: ColorRGBA(0, 35, 79) },
      { value: maxIntensity * (2 / 255), color: ColorRGBA(0, 36, 81) },
      { value: maxIntensity * (3 / 255), color: ColorRGBA(0, 37, 83) },
      { value: maxIntensity * (4 / 255), color: ColorRGBA(0, 37, 84) },
      { value: maxIntensity * (5 / 255), color: ColorRGBA(0, 38, 86) },
      { value: maxIntensity * (6 / 255), color: ColorRGBA(0, 39, 88) },
      { value: maxIntensity * (7 / 255), color: ColorRGBA(0, 40, 89) },
      { value: maxIntensity * (8 / 255), color: ColorRGBA(0, 40, 91) },
      { value: maxIntensity * (9 / 255), color: ColorRGBA(0, 41, 93) },
      { value: maxIntensity * (10 / 255), color: ColorRGBA(0, 42, 95) },
      { value: maxIntensity * (11 / 255), color: ColorRGBA(0, 42, 97) },
      { value: maxIntensity * (12 / 255), color: ColorRGBA(0, 43, 98) },
      { value: maxIntensity * (13 / 255), color: ColorRGBA(0, 44, 100) },
      { value: maxIntensity * (14 / 255), color: ColorRGBA(0, 44, 102) },
      { value: maxIntensity * (15 / 255), color: ColorRGBA(0, 45, 104) },
      { value: maxIntensity * (16 / 255), color: ColorRGBA(0, 46, 106) },
      { value: maxIntensity * (17 / 255), color: ColorRGBA(0, 46, 108) },
      { value: maxIntensity * (18 / 255), color: ColorRGBA(0, 47, 109) },
      { value: maxIntensity * (19 / 255), color: ColorRGBA(0, 48, 111) },
      { value: maxIntensity * (20 / 255), color: ColorRGBA(0, 48, 112) },
      { value: maxIntensity * (21 / 255), color: ColorRGBA(0, 49, 112) },
      { value: maxIntensity * (22 / 255), color: ColorRGBA(0, 49, 113) },
      { value: maxIntensity * (23 / 255), color: ColorRGBA(1, 50, 113) },
      { value: maxIntensity * (24 / 255), color: ColorRGBA(5, 51, 113) },
      { value: maxIntensity * (25 / 255), color: ColorRGBA(8, 51, 112) },
      { value: maxIntensity * (26 / 255), color: ColorRGBA(12, 52, 112) },
      { value: maxIntensity * (27 / 255), color: ColorRGBA(15, 53, 112) },
      { value: maxIntensity * (28 / 255), color: ColorRGBA(18, 53, 112) },
      { value: maxIntensity * (29 / 255), color: ColorRGBA(20, 54, 112) },
      { value: maxIntensity * (30 / 255), color: ColorRGBA(22, 55, 112) },
      { value: maxIntensity * (31 / 255), color: ColorRGBA(24, 55, 111) },
      { value: maxIntensity * (32 / 255), color: ColorRGBA(26, 56, 111) },
      { value: maxIntensity * (33 / 255), color: ColorRGBA(28, 57, 111) },
      { value: maxIntensity * (34 / 255), color: ColorRGBA(30, 58, 111) },
      { value: maxIntensity * (35 / 255), color: ColorRGBA(32, 58, 111) },
      { value: maxIntensity * (36 / 255), color: ColorRGBA(33, 59, 110) },
      { value: maxIntensity * (37 / 255), color: ColorRGBA(35, 60, 110) },
      { value: maxIntensity * (38 / 255), color: ColorRGBA(36, 60, 110) },
      { value: maxIntensity * (39 / 255), color: ColorRGBA(38, 61, 110) },
      { value: maxIntensity * (40 / 255), color: ColorRGBA(39, 62, 110) },
      { value: maxIntensity * (41 / 255), color: ColorRGBA(41, 63, 110) },
      { value: maxIntensity * (42 / 255), color: ColorRGBA(42, 63, 109) },
      { value: maxIntensity * (43 / 255), color: ColorRGBA(43, 64, 109) },
      { value: maxIntensity * (44 / 255), color: ColorRGBA(45, 65, 109) },
      { value: maxIntensity * (45 / 255), color: ColorRGBA(46, 65, 109) },
      { value: maxIntensity * (46 / 255), color: ColorRGBA(47, 66, 109) },
      { value: maxIntensity * (47 / 255), color: ColorRGBA(49, 67, 109) },
      { value: maxIntensity * (48 / 255), color: ColorRGBA(50, 67, 109) },
      { value: maxIntensity * (49 / 255), color: ColorRGBA(51, 68, 109) },
      { value: maxIntensity * (50 / 255), color: ColorRGBA(52, 69, 108) },
      { value: maxIntensity * (51 / 255), color: ColorRGBA(53, 69, 108) },
      { value: maxIntensity * (52 / 255), color: ColorRGBA(54, 70, 108) },
      { value: maxIntensity * (53 / 255), color: ColorRGBA(56, 71, 108) },
      { value: maxIntensity * (54 / 255), color: ColorRGBA(57, 72, 108) },
      { value: maxIntensity * (55 / 255), color: ColorRGBA(58, 72, 108) },
      { value: maxIntensity * (56 / 255), color: ColorRGBA(59, 73, 108) },
      { value: maxIntensity * (57 / 255), color: ColorRGBA(60, 74, 108) },
      { value: maxIntensity * (58 / 255), color: ColorRGBA(61, 74, 108) },
      { value: maxIntensity * (59 / 255), color: ColorRGBA(62, 75, 108) },
      { value: maxIntensity * (60 / 255), color: ColorRGBA(63, 76, 108) },
      { value: maxIntensity * (61 / 255), color: ColorRGBA(64, 76, 108) },
      { value: maxIntensity * (62 / 255), color: ColorRGBA(65, 77, 108) },
      { value: maxIntensity * (63 / 255), color: ColorRGBA(66, 78, 108) },
      { value: maxIntensity * (64 / 255), color: ColorRGBA(67, 78, 108) },
      { value: maxIntensity * (65 / 255), color: ColorRGBA(68, 79, 108) },
      { value: maxIntensity * (66 / 255), color: ColorRGBA(69, 80, 108) },
      { value: maxIntensity * (67 / 255), color: ColorRGBA(70, 81, 108) },
      { value: maxIntensity * (68 / 255), color: ColorRGBA(71, 81, 108) },
      { value: maxIntensity * (69 / 255), color: ColorRGBA(72, 82, 108) },
      { value: maxIntensity * (70 / 255), color: ColorRGBA(73, 83, 108) },
      { value: maxIntensity * (71 / 255), color: ColorRGBA(74, 83, 108) },
      { value: maxIntensity * (72 / 255), color: ColorRGBA(75, 84, 108) },
      { value: maxIntensity * (73 / 255), color: ColorRGBA(76, 85, 108) },
      { value: maxIntensity * (74 / 255), color: ColorRGBA(77, 85, 108) },
      { value: maxIntensity * (75 / 255), color: ColorRGBA(78, 86, 108) },
      { value: maxIntensity * (76 / 255), color: ColorRGBA(79, 87, 108) },
      { value: maxIntensity * (77 / 255), color: ColorRGBA(80, 87, 108) },
      { value: maxIntensity * (78 / 255), color: ColorRGBA(81, 88, 109) },
      { value: maxIntensity * (79 / 255), color: ColorRGBA(82, 89, 109) },
      { value: maxIntensity * (80 / 255), color: ColorRGBA(83, 90, 109) },
      { value: maxIntensity * (81 / 255), color: ColorRGBA(84, 90, 109) },
      { value: maxIntensity * (82 / 255), color: ColorRGBA(85, 91, 109) },
      { value: maxIntensity * (83 / 255), color: ColorRGBA(85, 92, 109) },
      { value: maxIntensity * (84 / 255), color: ColorRGBA(86, 92, 109) },
      { value: maxIntensity * (85 / 255), color: ColorRGBA(87, 93, 109) },
      { value: maxIntensity * (86 / 255), color: ColorRGBA(88, 94, 109) },
      { value: maxIntensity * (87 / 255), color: ColorRGBA(89, 94, 110) },
      { value: maxIntensity * (88 / 255), color: ColorRGBA(90, 95, 110) },
      { value: maxIntensity * (89 / 255), color: ColorRGBA(91, 96, 110) },
      { value: maxIntensity * (90 / 255), color: ColorRGBA(92, 97, 110) },
      { value: maxIntensity * (91 / 255), color: ColorRGBA(93, 97, 110) },
      { value: maxIntensity * (92 / 255), color: ColorRGBA(94, 98, 110) },
      { value: maxIntensity * (93 / 255), color: ColorRGBA(94, 99, 111) },
      { value: maxIntensity * (94 / 255), color: ColorRGBA(95, 99, 111) },
      { value: maxIntensity * (95 / 255), color: ColorRGBA(96, 100, 111) },
      { value: maxIntensity * (96 / 255), color: ColorRGBA(97, 101, 111) },
      { value: maxIntensity * (97 / 255), color: ColorRGBA(98, 101, 111) },
      { value: maxIntensity * (98 / 255), color: ColorRGBA(99, 102, 112) },
      { value: maxIntensity * (99 / 255), color: ColorRGBA(100, 103, 112) },
      { value: maxIntensity * (100 / 255), color: ColorRGBA(101, 104, 112) },
      { value: maxIntensity * (101 / 255), color: ColorRGBA(101, 104, 112) },
      { value: maxIntensity * (102 / 255), color: ColorRGBA(102, 105, 112) },
      { value: maxIntensity * (103 / 255), color: ColorRGBA(103, 106, 113) },
      { value: maxIntensity * (104 / 255), color: ColorRGBA(104, 106, 113) },
      { value: maxIntensity * (105 / 255), color: ColorRGBA(105, 107, 113) },
      { value: maxIntensity * (106 / 255), color: ColorRGBA(106, 108, 113) },
      { value: maxIntensity * (107 / 255), color: ColorRGBA(107, 109, 114) },
      { value: maxIntensity * (108 / 255), color: ColorRGBA(108, 109, 114) },
      { value: maxIntensity * (109 / 255), color: ColorRGBA(108, 110, 114) },
      { value: maxIntensity * (110 / 255), color: ColorRGBA(109, 111, 114) },
      { value: maxIntensity * (111 / 255), color: ColorRGBA(110, 111, 115) },
      { value: maxIntensity * (112 / 255), color: ColorRGBA(111, 112, 115) },
      { value: maxIntensity * (113 / 255), color: ColorRGBA(112, 113, 115) },
      { value: maxIntensity * (114 / 255), color: ColorRGBA(113, 114, 116) },
      { value: maxIntensity * (115 / 255), color: ColorRGBA(114, 114, 116) },
      { value: maxIntensity * (116 / 255), color: ColorRGBA(114, 115, 116) },
      { value: maxIntensity * (117 / 255), color: ColorRGBA(115, 116, 117) },
      { value: maxIntensity * (118 / 255), color: ColorRGBA(116, 116, 117) },
      { value: maxIntensity * (119 / 255), color: ColorRGBA(117, 117, 117) },
      { value: maxIntensity * (120 / 255), color: ColorRGBA(118, 118, 118) },
      { value: maxIntensity * (121 / 255), color: ColorRGBA(119, 119, 118) },
      { value: maxIntensity * (122 / 255), color: ColorRGBA(119, 119, 119) },
      { value: maxIntensity * (123 / 255), color: ColorRGBA(120, 120, 119) },
      { value: maxIntensity * (124 / 255), color: ColorRGBA(121, 121, 119) },
      { value: maxIntensity * (125 / 255), color: ColorRGBA(122, 122, 120) },
      { value: maxIntensity * (126 / 255), color: ColorRGBA(123, 122, 120) },
      { value: maxIntensity * (127 / 255), color: ColorRGBA(124, 123, 120) },
      { value: maxIntensity * (128 / 255), color: ColorRGBA(125, 124, 120) },
      { value: maxIntensity * (129 / 255), color: ColorRGBA(126, 124, 120) },
      { value: maxIntensity * (130 / 255), color: ColorRGBA(126, 125, 120) },
      { value: maxIntensity * (131 / 255), color: ColorRGBA(127, 126, 120) },
      { value: maxIntensity * (132 / 255), color: ColorRGBA(128, 127, 120) },
      { value: maxIntensity * (133 / 255), color: ColorRGBA(129, 127, 120) },
      { value: maxIntensity * (134 / 255), color: ColorRGBA(130, 128, 121) },
      { value: maxIntensity * (135 / 255), color: ColorRGBA(131, 129, 121) },
      { value: maxIntensity * (136 / 255), color: ColorRGBA(132, 130, 121) },
      { value: maxIntensity * (137 / 255), color: ColorRGBA(133, 130, 121) },
      { value: maxIntensity * (138 / 255), color: ColorRGBA(134, 131, 121) },
      { value: maxIntensity * (139 / 255), color: ColorRGBA(135, 132, 120) },
      { value: maxIntensity * (140 / 255), color: ColorRGBA(136, 133, 120) },
      { value: maxIntensity * (141 / 255), color: ColorRGBA(137, 133, 120) },
      { value: maxIntensity * (142 / 255), color: ColorRGBA(138, 134, 120) },
      { value: maxIntensity * (143 / 255), color: ColorRGBA(139, 135, 120) },
      { value: maxIntensity * (144 / 255), color: ColorRGBA(140, 136, 120) },
      { value: maxIntensity * (145 / 255), color: ColorRGBA(141, 136, 120) },
      { value: maxIntensity * (146 / 255), color: ColorRGBA(142, 137, 120) },
      { value: maxIntensity * (147 / 255), color: ColorRGBA(143, 138, 120) },
      { value: maxIntensity * (148 / 255), color: ColorRGBA(144, 139, 120) },
      { value: maxIntensity * (149 / 255), color: ColorRGBA(145, 139, 120) },
      { value: maxIntensity * (150 / 255), color: ColorRGBA(146, 140, 120) },
      { value: maxIntensity * (151 / 255), color: ColorRGBA(146, 141, 120) },
      { value: maxIntensity * (152 / 255), color: ColorRGBA(147, 142, 120) },
      { value: maxIntensity * (153 / 255), color: ColorRGBA(148, 142, 119) },
      { value: maxIntensity * (154 / 255), color: ColorRGBA(149, 143, 119) },
      { value: maxIntensity * (155 / 255), color: ColorRGBA(150, 144, 119) },
      { value: maxIntensity * (156 / 255), color: ColorRGBA(151, 145, 119) },
      { value: maxIntensity * (157 / 255), color: ColorRGBA(152, 146, 119) },
      { value: maxIntensity * (158 / 255), color: ColorRGBA(153, 146, 119) },
      { value: maxIntensity * (159 / 255), color: ColorRGBA(154, 147, 118) },
      { value: maxIntensity * (160 / 255), color: ColorRGBA(155, 148, 118) },
      { value: maxIntensity * (161 / 255), color: ColorRGBA(156, 149, 118) },
      { value: maxIntensity * (162 / 255), color: ColorRGBA(157, 149, 118) },
      { value: maxIntensity * (163 / 255), color: ColorRGBA(158, 150, 118) },
      { value: maxIntensity * (164 / 255), color: ColorRGBA(159, 151, 117) },
      { value: maxIntensity * (165 / 255), color: ColorRGBA(160, 152, 117) },
      { value: maxIntensity * (166 / 255), color: ColorRGBA(161, 153, 117) },
      { value: maxIntensity * (167 / 255), color: ColorRGBA(162, 153, 117) },
      { value: maxIntensity * (168 / 255), color: ColorRGBA(163, 154, 116) },
      { value: maxIntensity * (169 / 255), color: ColorRGBA(164, 155, 116) },
      { value: maxIntensity * (170 / 255), color: ColorRGBA(165, 156, 116) },
      { value: maxIntensity * (171 / 255), color: ColorRGBA(166, 156, 116) },
      { value: maxIntensity * (172 / 255), color: ColorRGBA(167, 157, 115) },
      { value: maxIntensity * (173 / 255), color: ColorRGBA(168, 158, 115) },
      { value: maxIntensity * (174 / 255), color: ColorRGBA(169, 159, 115) },
      { value: maxIntensity * (175 / 255), color: ColorRGBA(170, 160, 115) },
      { value: maxIntensity * (176 / 255), color: ColorRGBA(171, 160, 114) },
      { value: maxIntensity * (177 / 255), color: ColorRGBA(172, 161, 114) },
      { value: maxIntensity * (178 / 255), color: ColorRGBA(173, 162, 114) },
      { value: maxIntensity * (179 / 255), color: ColorRGBA(174, 163, 113) },
      { value: maxIntensity * (180 / 255), color: ColorRGBA(175, 164, 113) },
      { value: maxIntensity * (181 / 255), color: ColorRGBA(176, 165, 113) },
      { value: maxIntensity * (182 / 255), color: ColorRGBA(177, 165, 112) },
      { value: maxIntensity * (183 / 255), color: ColorRGBA(179, 166, 112) },
      { value: maxIntensity * (184 / 255), color: ColorRGBA(180, 167, 111) },
      { value: maxIntensity * (185 / 255), color: ColorRGBA(181, 168, 111) },
      { value: maxIntensity * (186 / 255), color: ColorRGBA(182, 169, 111) },
      { value: maxIntensity * (187 / 255), color: ColorRGBA(183, 169, 110) },
      { value: maxIntensity * (188 / 255), color: ColorRGBA(184, 170, 110) },
      { value: maxIntensity * (189 / 255), color: ColorRGBA(185, 171, 109) },
      { value: maxIntensity * (190 / 255), color: ColorRGBA(186, 172, 109) },
      { value: maxIntensity * (191 / 255), color: ColorRGBA(187, 173, 109) },
      { value: maxIntensity * (192 / 255), color: ColorRGBA(188, 174, 108) },
      { value: maxIntensity * (193 / 255), color: ColorRGBA(189, 174, 108) },
      { value: maxIntensity * (194 / 255), color: ColorRGBA(190, 175, 107) },
      { value: maxIntensity * (195 / 255), color: ColorRGBA(191, 176, 107) },
      { value: maxIntensity * (196 / 255), color: ColorRGBA(192, 177, 106) },
      { value: maxIntensity * (197 / 255), color: ColorRGBA(193, 178, 106) },
      { value: maxIntensity * (198 / 255), color: ColorRGBA(194, 179, 105) },
      { value: maxIntensity * (199 / 255), color: ColorRGBA(195, 179, 105) },
      { value: maxIntensity * (200 / 255), color: ColorRGBA(196, 180, 104) },
      { value: maxIntensity * (201 / 255), color: ColorRGBA(197, 181, 104) },
      { value: maxIntensity * (202 / 255), color: ColorRGBA(198, 182, 103) },
      { value: maxIntensity * (203 / 255), color: ColorRGBA(199, 183, 103) },
      { value: maxIntensity * (204 / 255), color: ColorRGBA(200, 184, 102) },
      { value: maxIntensity * (205 / 255), color: ColorRGBA(201, 185, 101) },
      { value: maxIntensity * (206 / 255), color: ColorRGBA(203, 185, 101) },
      { value: maxIntensity * (207 / 255), color: ColorRGBA(204, 186, 100) },
      { value: maxIntensity * (208 / 255), color: ColorRGBA(205, 187, 99) },
      { value: maxIntensity * (209 / 255), color: ColorRGBA(206, 188, 99) },
      { value: maxIntensity * (210 / 255), color: ColorRGBA(207, 189, 98) },
      { value: maxIntensity * (211 / 255), color: ColorRGBA(208, 190, 98) },
      { value: maxIntensity * (212 / 255), color: ColorRGBA(209, 191, 97) },
      { value: maxIntensity * (213 / 255), color: ColorRGBA(210, 192, 96) },
      { value: maxIntensity * (214 / 255), color: ColorRGBA(211, 192, 95) },
      { value: maxIntensity * (215 / 255), color: ColorRGBA(212, 193, 95) },
      { value: maxIntensity * (216 / 255), color: ColorRGBA(213, 194, 94) },
      { value: maxIntensity * (217 / 255), color: ColorRGBA(214, 195, 93) },
      { value: maxIntensity * (218 / 255), color: ColorRGBA(215, 196, 92) },
      { value: maxIntensity * (219 / 255), color: ColorRGBA(217, 197, 92) },
      { value: maxIntensity * (220 / 255), color: ColorRGBA(218, 198, 91) },
      { value: maxIntensity * (221 / 255), color: ColorRGBA(219, 199, 90) },
      { value: maxIntensity * (222 / 255), color: ColorRGBA(220, 200, 89) },
      { value: maxIntensity * (223 / 255), color: ColorRGBA(221, 200, 88) },
      { value: maxIntensity * (224 / 255), color: ColorRGBA(222, 201, 88) },
      { value: maxIntensity * (225 / 255), color: ColorRGBA(223, 202, 87) },
      { value: maxIntensity * (226 / 255), color: ColorRGBA(224, 203, 86) },
      { value: maxIntensity * (227 / 255), color: ColorRGBA(225, 204, 85) },
      { value: maxIntensity * (228 / 255), color: ColorRGBA(226, 205, 84) },
      { value: maxIntensity * (229 / 255), color: ColorRGBA(228, 206, 83) },
      { value: maxIntensity * (230 / 255), color: ColorRGBA(229, 207, 82) },
      { value: maxIntensity * (231 / 255), color: ColorRGBA(230, 208, 81) },
      { value: maxIntensity * (232 / 255), color: ColorRGBA(231, 209, 80) },
      { value: maxIntensity * (233 / 255), color: ColorRGBA(232, 210, 79) },
      { value: maxIntensity * (234 / 255), color: ColorRGBA(233, 211, 78) },
      { value: maxIntensity * (235 / 255), color: ColorRGBA(234, 211, 76) },
      { value: maxIntensity * (236 / 255), color: ColorRGBA(235, 212, 75) },
      { value: maxIntensity * (237 / 255), color: ColorRGBA(237, 213, 74) },
      { value: maxIntensity * (238 / 255), color: ColorRGBA(238, 214, 73) },
      { value: maxIntensity * (239 / 255), color: ColorRGBA(239, 215, 72) },
      { value: maxIntensity * (240 / 255), color: ColorRGBA(240, 216, 70) },
      { value: maxIntensity * (241 / 255), color: ColorRGBA(241, 217, 69) },
      { value: maxIntensity * (242 / 255), color: ColorRGBA(242, 218, 68) },
      { value: maxIntensity * (243 / 255), color: ColorRGBA(243, 219, 66) },
      { value: maxIntensity * (244 / 255), color: ColorRGBA(245, 220, 65) },
      { value: maxIntensity * (245 / 255), color: ColorRGBA(246, 221, 63) },
      { value: maxIntensity * (246 / 255), color: ColorRGBA(247, 222, 62) },
      { value: maxIntensity * (247 / 255), color: ColorRGBA(248, 223, 60) },
      { value: maxIntensity * (248 / 255), color: ColorRGBA(249, 224, 58) },
      { value: maxIntensity * (249 / 255), color: ColorRGBA(251, 225, 56) },
      { value: maxIntensity * (250 / 255), color: ColorRGBA(252, 226, 54) },
      { value: maxIntensity * (251 / 255), color: ColorRGBA(253, 227, 52) },
      { value: maxIntensity * (252 / 255), color: ColorRGBA(254, 228, 52) },
      { value: maxIntensity * (253 / 255), color: ColorRGBA(254, 229, 53) },
      { value: maxIntensity * (254 / 255), color: ColorRGBA(254, 230, 54) },
      { value: maxIntensity * (255 / 255), color: ColorRGBA(254, 232, 56) }
    ],
    interpolate: true
  });

/**
 * Inferno color palette from Matplotlib
 * @param {number} maxIntensity Maximum intensity in plot
 */
export const infernoPalette = (maxIntensity: number) =>
  new LUT({
    steps: [
      { value: maxIntensity * (0 / 255), color: ColorRGBA(0, 0, 4) },
      { value: maxIntensity * (1 / 255), color: ColorRGBA(1, 0, 5) },
      { value: maxIntensity * (2 / 255), color: ColorRGBA(1, 1, 6) },
      { value: maxIntensity * (3 / 255), color: ColorRGBA(1, 1, 8) },
      { value: maxIntensity * (4 / 255), color: ColorRGBA(2, 1, 10) },
      { value: maxIntensity * (5 / 255), color: ColorRGBA(2, 2, 12) },
      { value: maxIntensity * (6 / 255), color: ColorRGBA(2, 2, 14) },
      { value: maxIntensity * (7 / 255), color: ColorRGBA(3, 2, 16) },
      { value: maxIntensity * (8 / 255), color: ColorRGBA(4, 3, 18) },
      { value: maxIntensity * (9 / 255), color: ColorRGBA(4, 3, 20) },
      { value: maxIntensity * (10 / 255), color: ColorRGBA(5, 4, 23) },
      { value: maxIntensity * (11 / 255), color: ColorRGBA(6, 4, 25) },
      { value: maxIntensity * (12 / 255), color: ColorRGBA(7, 5, 27) },
      { value: maxIntensity * (13 / 255), color: ColorRGBA(8, 5, 29) },
      { value: maxIntensity * (14 / 255), color: ColorRGBA(9, 6, 31) },
      { value: maxIntensity * (15 / 255), color: ColorRGBA(10, 7, 34) },
      { value: maxIntensity * (16 / 255), color: ColorRGBA(11, 7, 36) },
      { value: maxIntensity * (17 / 255), color: ColorRGBA(12, 8, 38) },
      { value: maxIntensity * (18 / 255), color: ColorRGBA(13, 8, 41) },
      { value: maxIntensity * (19 / 255), color: ColorRGBA(14, 9, 43) },
      { value: maxIntensity * (20 / 255), color: ColorRGBA(16, 9, 45) },
      { value: maxIntensity * (21 / 255), color: ColorRGBA(17, 10, 48) },
      { value: maxIntensity * (22 / 255), color: ColorRGBA(18, 10, 50) },
      { value: maxIntensity * (23 / 255), color: ColorRGBA(20, 11, 52) },
      { value: maxIntensity * (24 / 255), color: ColorRGBA(21, 11, 55) },
      { value: maxIntensity * (25 / 255), color: ColorRGBA(22, 11, 57) },
      { value: maxIntensity * (26 / 255), color: ColorRGBA(24, 12, 60) },
      { value: maxIntensity * (27 / 255), color: ColorRGBA(25, 12, 62) },
      { value: maxIntensity * (28 / 255), color: ColorRGBA(27, 12, 65) },
      { value: maxIntensity * (29 / 255), color: ColorRGBA(28, 12, 67) },
      { value: maxIntensity * (30 / 255), color: ColorRGBA(30, 12, 69) },
      { value: maxIntensity * (31 / 255), color: ColorRGBA(31, 12, 72) },
      { value: maxIntensity * (32 / 255), color: ColorRGBA(33, 12, 74) },
      { value: maxIntensity * (33 / 255), color: ColorRGBA(35, 12, 76) },
      { value: maxIntensity * (34 / 255), color: ColorRGBA(36, 12, 79) },
      { value: maxIntensity * (35 / 255), color: ColorRGBA(38, 12, 81) },
      { value: maxIntensity * (36 / 255), color: ColorRGBA(40, 11, 83) },
      { value: maxIntensity * (37 / 255), color: ColorRGBA(41, 11, 85) },
      { value: maxIntensity * (38 / 255), color: ColorRGBA(43, 11, 87) },
      { value: maxIntensity * (39 / 255), color: ColorRGBA(45, 11, 89) },
      { value: maxIntensity * (40 / 255), color: ColorRGBA(47, 10, 91) },
      { value: maxIntensity * (41 / 255), color: ColorRGBA(49, 10, 92) },
      { value: maxIntensity * (42 / 255), color: ColorRGBA(50, 10, 94) },
      { value: maxIntensity * (43 / 255), color: ColorRGBA(52, 10, 95) },
      { value: maxIntensity * (44 / 255), color: ColorRGBA(54, 9, 97) },
      { value: maxIntensity * (45 / 255), color: ColorRGBA(56, 9, 98) },
      { value: maxIntensity * (46 / 255), color: ColorRGBA(57, 9, 99) },
      { value: maxIntensity * (47 / 255), color: ColorRGBA(59, 9, 100) },
      { value: maxIntensity * (48 / 255), color: ColorRGBA(61, 9, 101) },
      { value: maxIntensity * (49 / 255), color: ColorRGBA(62, 9, 102) },
      { value: maxIntensity * (50 / 255), color: ColorRGBA(64, 10, 103) },
      { value: maxIntensity * (51 / 255), color: ColorRGBA(66, 10, 104) },
      { value: maxIntensity * (52 / 255), color: ColorRGBA(68, 10, 104) },
      { value: maxIntensity * (53 / 255), color: ColorRGBA(69, 10, 105) },
      { value: maxIntensity * (54 / 255), color: ColorRGBA(71, 11, 106) },
      { value: maxIntensity * (55 / 255), color: ColorRGBA(73, 11, 106) },
      { value: maxIntensity * (56 / 255), color: ColorRGBA(74, 12, 107) },
      { value: maxIntensity * (57 / 255), color: ColorRGBA(76, 12, 107) },
      { value: maxIntensity * (58 / 255), color: ColorRGBA(77, 13, 108) },
      { value: maxIntensity * (59 / 255), color: ColorRGBA(79, 13, 108) },
      { value: maxIntensity * (60 / 255), color: ColorRGBA(81, 14, 108) },
      { value: maxIntensity * (61 / 255), color: ColorRGBA(82, 14, 109) },
      { value: maxIntensity * (62 / 255), color: ColorRGBA(84, 15, 109) },
      { value: maxIntensity * (63 / 255), color: ColorRGBA(85, 15, 109) },
      { value: maxIntensity * (64 / 255), color: ColorRGBA(87, 16, 110) },
      { value: maxIntensity * (65 / 255), color: ColorRGBA(89, 16, 110) },
      { value: maxIntensity * (66 / 255), color: ColorRGBA(90, 17, 110) },
      { value: maxIntensity * (67 / 255), color: ColorRGBA(92, 18, 110) },
      { value: maxIntensity * (68 / 255), color: ColorRGBA(93, 18, 110) },
      { value: maxIntensity * (69 / 255), color: ColorRGBA(95, 19, 110) },
      { value: maxIntensity * (70 / 255), color: ColorRGBA(97, 19, 110) },
      { value: maxIntensity * (71 / 255), color: ColorRGBA(98, 20, 110) },
      { value: maxIntensity * (72 / 255), color: ColorRGBA(100, 21, 110) },
      { value: maxIntensity * (73 / 255), color: ColorRGBA(101, 21, 110) },
      { value: maxIntensity * (74 / 255), color: ColorRGBA(103, 22, 110) },
      { value: maxIntensity * (75 / 255), color: ColorRGBA(105, 22, 110) },
      { value: maxIntensity * (76 / 255), color: ColorRGBA(106, 23, 110) },
      { value: maxIntensity * (77 / 255), color: ColorRGBA(108, 24, 110) },
      { value: maxIntensity * (78 / 255), color: ColorRGBA(109, 24, 110) },
      { value: maxIntensity * (79 / 255), color: ColorRGBA(111, 25, 110) },
      { value: maxIntensity * (80 / 255), color: ColorRGBA(113, 25, 110) },
      { value: maxIntensity * (81 / 255), color: ColorRGBA(114, 26, 110) },
      { value: maxIntensity * (82 / 255), color: ColorRGBA(116, 26, 110) },
      { value: maxIntensity * (83 / 255), color: ColorRGBA(117, 27, 110) },
      { value: maxIntensity * (84 / 255), color: ColorRGBA(119, 28, 109) },
      { value: maxIntensity * (85 / 255), color: ColorRGBA(120, 28, 109) },
      { value: maxIntensity * (86 / 255), color: ColorRGBA(122, 29, 109) },
      { value: maxIntensity * (87 / 255), color: ColorRGBA(124, 29, 109) },
      { value: maxIntensity * (88 / 255), color: ColorRGBA(125, 30, 109) },
      { value: maxIntensity * (89 / 255), color: ColorRGBA(127, 30, 108) },
      { value: maxIntensity * (90 / 255), color: ColorRGBA(128, 31, 108) },
      { value: maxIntensity * (91 / 255), color: ColorRGBA(130, 32, 108) },
      { value: maxIntensity * (92 / 255), color: ColorRGBA(132, 32, 107) },
      { value: maxIntensity * (93 / 255), color: ColorRGBA(133, 33, 107) },
      { value: maxIntensity * (94 / 255), color: ColorRGBA(135, 33, 107) },
      { value: maxIntensity * (95 / 255), color: ColorRGBA(136, 34, 106) },
      { value: maxIntensity * (96 / 255), color: ColorRGBA(138, 34, 106) },
      { value: maxIntensity * (97 / 255), color: ColorRGBA(140, 35, 105) },
      { value: maxIntensity * (98 / 255), color: ColorRGBA(141, 35, 105) },
      { value: maxIntensity * (99 / 255), color: ColorRGBA(143, 36, 105) },
      { value: maxIntensity * (100 / 255), color: ColorRGBA(144, 37, 104) },
      { value: maxIntensity * (101 / 255), color: ColorRGBA(146, 37, 104) },
      { value: maxIntensity * (102 / 255), color: ColorRGBA(147, 38, 103) },
      { value: maxIntensity * (103 / 255), color: ColorRGBA(149, 38, 103) },
      { value: maxIntensity * (104 / 255), color: ColorRGBA(151, 39, 102) },
      { value: maxIntensity * (105 / 255), color: ColorRGBA(152, 39, 102) },
      { value: maxIntensity * (106 / 255), color: ColorRGBA(154, 40, 101) },
      { value: maxIntensity * (107 / 255), color: ColorRGBA(155, 41, 100) },
      { value: maxIntensity * (108 / 255), color: ColorRGBA(157, 41, 100) },
      { value: maxIntensity * (109 / 255), color: ColorRGBA(159, 42, 99) },
      { value: maxIntensity * (110 / 255), color: ColorRGBA(160, 42, 99) },
      { value: maxIntensity * (111 / 255), color: ColorRGBA(162, 43, 98) },
      { value: maxIntensity * (112 / 255), color: ColorRGBA(163, 44, 97) },
      { value: maxIntensity * (113 / 255), color: ColorRGBA(165, 44, 96) },
      { value: maxIntensity * (114 / 255), color: ColorRGBA(166, 45, 96) },
      { value: maxIntensity * (115 / 255), color: ColorRGBA(168, 46, 95) },
      { value: maxIntensity * (116 / 255), color: ColorRGBA(169, 46, 94) },
      { value: maxIntensity * (117 / 255), color: ColorRGBA(171, 47, 94) },
      { value: maxIntensity * (118 / 255), color: ColorRGBA(173, 48, 93) },
      { value: maxIntensity * (119 / 255), color: ColorRGBA(174, 48, 92) },
      { value: maxIntensity * (120 / 255), color: ColorRGBA(176, 49, 91) },
      { value: maxIntensity * (121 / 255), color: ColorRGBA(177, 50, 90) },
      { value: maxIntensity * (122 / 255), color: ColorRGBA(179, 50, 90) },
      { value: maxIntensity * (123 / 255), color: ColorRGBA(180, 51, 89) },
      { value: maxIntensity * (124 / 255), color: ColorRGBA(182, 52, 88) },
      { value: maxIntensity * (125 / 255), color: ColorRGBA(183, 53, 87) },
      { value: maxIntensity * (126 / 255), color: ColorRGBA(185, 53, 86) },
      { value: maxIntensity * (127 / 255), color: ColorRGBA(186, 54, 85) },
      { value: maxIntensity * (128 / 255), color: ColorRGBA(188, 55, 84) },
      { value: maxIntensity * (129 / 255), color: ColorRGBA(189, 56, 83) },
      { value: maxIntensity * (130 / 255), color: ColorRGBA(191, 57, 82) },
      { value: maxIntensity * (131 / 255), color: ColorRGBA(192, 58, 81) },
      { value: maxIntensity * (132 / 255), color: ColorRGBA(193, 58, 80) },
      { value: maxIntensity * (133 / 255), color: ColorRGBA(195, 59, 79) },
      { value: maxIntensity * (134 / 255), color: ColorRGBA(196, 60, 78) },
      { value: maxIntensity * (135 / 255), color: ColorRGBA(198, 61, 77) },
      { value: maxIntensity * (136 / 255), color: ColorRGBA(199, 62, 76) },
      { value: maxIntensity * (137 / 255), color: ColorRGBA(200, 63, 75) },
      { value: maxIntensity * (138 / 255), color: ColorRGBA(202, 64, 74) },
      { value: maxIntensity * (139 / 255), color: ColorRGBA(203, 65, 73) },
      { value: maxIntensity * (140 / 255), color: ColorRGBA(204, 66, 72) },
      { value: maxIntensity * (141 / 255), color: ColorRGBA(206, 67, 71) },
      { value: maxIntensity * (142 / 255), color: ColorRGBA(207, 68, 70) },
      { value: maxIntensity * (143 / 255), color: ColorRGBA(208, 69, 69) },
      { value: maxIntensity * (144 / 255), color: ColorRGBA(210, 70, 68) },
      { value: maxIntensity * (145 / 255), color: ColorRGBA(211, 71, 67) },
      { value: maxIntensity * (146 / 255), color: ColorRGBA(212, 72, 66) },
      { value: maxIntensity * (147 / 255), color: ColorRGBA(213, 74, 65) },
      { value: maxIntensity * (148 / 255), color: ColorRGBA(215, 75, 63) },
      { value: maxIntensity * (149 / 255), color: ColorRGBA(216, 76, 62) },
      { value: maxIntensity * (150 / 255), color: ColorRGBA(217, 77, 61) },
      { value: maxIntensity * (151 / 255), color: ColorRGBA(218, 78, 60) },
      { value: maxIntensity * (152 / 255), color: ColorRGBA(219, 80, 59) },
      { value: maxIntensity * (153 / 255), color: ColorRGBA(221, 81, 58) },
      { value: maxIntensity * (154 / 255), color: ColorRGBA(222, 82, 56) },
      { value: maxIntensity * (155 / 255), color: ColorRGBA(223, 83, 55) },
      { value: maxIntensity * (156 / 255), color: ColorRGBA(224, 85, 54) },
      { value: maxIntensity * (157 / 255), color: ColorRGBA(225, 86, 53) },
      { value: maxIntensity * (158 / 255), color: ColorRGBA(226, 87, 52) },
      { value: maxIntensity * (159 / 255), color: ColorRGBA(227, 89, 51) },
      { value: maxIntensity * (160 / 255), color: ColorRGBA(228, 90, 49) },
      { value: maxIntensity * (161 / 255), color: ColorRGBA(229, 92, 48) },
      { value: maxIntensity * (162 / 255), color: ColorRGBA(230, 93, 47) },
      { value: maxIntensity * (163 / 255), color: ColorRGBA(231, 94, 46) },
      { value: maxIntensity * (164 / 255), color: ColorRGBA(232, 96, 45) },
      { value: maxIntensity * (165 / 255), color: ColorRGBA(233, 97, 43) },
      { value: maxIntensity * (166 / 255), color: ColorRGBA(234, 99, 42) },
      { value: maxIntensity * (167 / 255), color: ColorRGBA(235, 100, 41) },
      { value: maxIntensity * (168 / 255), color: ColorRGBA(235, 102, 40) },
      { value: maxIntensity * (169 / 255), color: ColorRGBA(236, 103, 38) },
      { value: maxIntensity * (170 / 255), color: ColorRGBA(237, 105, 37) },
      { value: maxIntensity * (171 / 255), color: ColorRGBA(238, 106, 36) },
      { value: maxIntensity * (172 / 255), color: ColorRGBA(239, 108, 35) },
      { value: maxIntensity * (173 / 255), color: ColorRGBA(239, 110, 33) },
      { value: maxIntensity * (174 / 255), color: ColorRGBA(240, 111, 32) },
      { value: maxIntensity * (175 / 255), color: ColorRGBA(241, 113, 31) },
      { value: maxIntensity * (176 / 255), color: ColorRGBA(241, 115, 29) },
      { value: maxIntensity * (177 / 255), color: ColorRGBA(242, 116, 28) },
      { value: maxIntensity * (178 / 255), color: ColorRGBA(243, 118, 27) },
      { value: maxIntensity * (179 / 255), color: ColorRGBA(243, 120, 25) },
      { value: maxIntensity * (180 / 255), color: ColorRGBA(244, 121, 24) },
      { value: maxIntensity * (181 / 255), color: ColorRGBA(245, 123, 23) },
      { value: maxIntensity * (182 / 255), color: ColorRGBA(245, 125, 21) },
      { value: maxIntensity * (183 / 255), color: ColorRGBA(246, 126, 20) },
      { value: maxIntensity * (184 / 255), color: ColorRGBA(246, 128, 19) },
      { value: maxIntensity * (185 / 255), color: ColorRGBA(247, 130, 18) },
      { value: maxIntensity * (186 / 255), color: ColorRGBA(247, 132, 16) },
      { value: maxIntensity * (187 / 255), color: ColorRGBA(248, 133, 15) },
      { value: maxIntensity * (188 / 255), color: ColorRGBA(248, 135, 14) },
      { value: maxIntensity * (189 / 255), color: ColorRGBA(248, 137, 12) },
      { value: maxIntensity * (190 / 255), color: ColorRGBA(249, 139, 11) },
      { value: maxIntensity * (191 / 255), color: ColorRGBA(249, 140, 10) },
      { value: maxIntensity * (192 / 255), color: ColorRGBA(249, 142, 9) },
      { value: maxIntensity * (193 / 255), color: ColorRGBA(250, 144, 8) },
      { value: maxIntensity * (194 / 255), color: ColorRGBA(250, 146, 7) },
      { value: maxIntensity * (195 / 255), color: ColorRGBA(250, 148, 7) },
      { value: maxIntensity * (196 / 255), color: ColorRGBA(251, 150, 6) },
      { value: maxIntensity * (197 / 255), color: ColorRGBA(251, 151, 6) },
      { value: maxIntensity * (198 / 255), color: ColorRGBA(251, 153, 6) },
      { value: maxIntensity * (199 / 255), color: ColorRGBA(251, 155, 6) },
      { value: maxIntensity * (200 / 255), color: ColorRGBA(251, 157, 7) },
      { value: maxIntensity * (201 / 255), color: ColorRGBA(252, 159, 7) },
      { value: maxIntensity * (202 / 255), color: ColorRGBA(252, 161, 8) },
      { value: maxIntensity * (203 / 255), color: ColorRGBA(252, 163, 9) },
      { value: maxIntensity * (204 / 255), color: ColorRGBA(252, 165, 10) },
      { value: maxIntensity * (205 / 255), color: ColorRGBA(252, 166, 12) },
      { value: maxIntensity * (206 / 255), color: ColorRGBA(252, 168, 13) },
      { value: maxIntensity * (207 / 255), color: ColorRGBA(252, 170, 15) },
      { value: maxIntensity * (208 / 255), color: ColorRGBA(252, 172, 17) },
      { value: maxIntensity * (209 / 255), color: ColorRGBA(252, 174, 18) },
      { value: maxIntensity * (210 / 255), color: ColorRGBA(252, 176, 20) },
      { value: maxIntensity * (211 / 255), color: ColorRGBA(252, 178, 22) },
      { value: maxIntensity * (212 / 255), color: ColorRGBA(252, 180, 24) },
      { value: maxIntensity * (213 / 255), color: ColorRGBA(251, 182, 26) },
      { value: maxIntensity * (214 / 255), color: ColorRGBA(251, 184, 29) },
      { value: maxIntensity * (215 / 255), color: ColorRGBA(251, 186, 31) },
      { value: maxIntensity * (216 / 255), color: ColorRGBA(251, 188, 33) },
      { value: maxIntensity * (217 / 255), color: ColorRGBA(251, 190, 35) },
      { value: maxIntensity * (218 / 255), color: ColorRGBA(250, 192, 38) },
      { value: maxIntensity * (219 / 255), color: ColorRGBA(250, 194, 40) },
      { value: maxIntensity * (220 / 255), color: ColorRGBA(250, 196, 42) },
      { value: maxIntensity * (221 / 255), color: ColorRGBA(250, 198, 45) },
      { value: maxIntensity * (222 / 255), color: ColorRGBA(249, 199, 47) },
      { value: maxIntensity * (223 / 255), color: ColorRGBA(249, 201, 50) },
      { value: maxIntensity * (224 / 255), color: ColorRGBA(249, 203, 53) },
      { value: maxIntensity * (225 / 255), color: ColorRGBA(248, 205, 55) },
      { value: maxIntensity * (226 / 255), color: ColorRGBA(248, 207, 58) },
      { value: maxIntensity * (227 / 255), color: ColorRGBA(247, 209, 61) },
      { value: maxIntensity * (228 / 255), color: ColorRGBA(247, 211, 64) },
      { value: maxIntensity * (229 / 255), color: ColorRGBA(246, 213, 67) },
      { value: maxIntensity * (230 / 255), color: ColorRGBA(246, 215, 70) },
      { value: maxIntensity * (231 / 255), color: ColorRGBA(245, 217, 73) },
      { value: maxIntensity * (232 / 255), color: ColorRGBA(245, 219, 76) },
      { value: maxIntensity * (233 / 255), color: ColorRGBA(244, 221, 79) },
      { value: maxIntensity * (234 / 255), color: ColorRGBA(244, 223, 83) },
      { value: maxIntensity * (235 / 255), color: ColorRGBA(244, 225, 86) },
      { value: maxIntensity * (236 / 255), color: ColorRGBA(243, 227, 90) },
      { value: maxIntensity * (237 / 255), color: ColorRGBA(243, 229, 93) },
      { value: maxIntensity * (238 / 255), color: ColorRGBA(242, 230, 97) },
      { value: maxIntensity * (239 / 255), color: ColorRGBA(242, 232, 101) },
      { value: maxIntensity * (240 / 255), color: ColorRGBA(242, 234, 105) },
      { value: maxIntensity * (241 / 255), color: ColorRGBA(241, 236, 109) },
      { value: maxIntensity * (242 / 255), color: ColorRGBA(241, 237, 113) },
      { value: maxIntensity * (243 / 255), color: ColorRGBA(241, 239, 117) },
      { value: maxIntensity * (244 / 255), color: ColorRGBA(241, 241, 121) },
      { value: maxIntensity * (245 / 255), color: ColorRGBA(242, 242, 125) },
      { value: maxIntensity * (246 / 255), color: ColorRGBA(242, 244, 130) },
      { value: maxIntensity * (247 / 255), color: ColorRGBA(243, 245, 134) },
      { value: maxIntensity * (248 / 255), color: ColorRGBA(243, 246, 138) },
      { value: maxIntensity * (249 / 255), color: ColorRGBA(244, 248, 142) },
      { value: maxIntensity * (250 / 255), color: ColorRGBA(245, 249, 146) },
      { value: maxIntensity * (251 / 255), color: ColorRGBA(246, 250, 150) },
      { value: maxIntensity * (252 / 255), color: ColorRGBA(248, 251, 154) },
      { value: maxIntensity * (253 / 255), color: ColorRGBA(249, 252, 157) },
      { value: maxIntensity * (254 / 255), color: ColorRGBA(250, 253, 161) },
      { value: maxIntensity * (255 / 255), color: ColorRGBA(252, 255, 164) }
    ],
    interpolate: true
  });

/**
 * Jet color palette from Matplotlib
 * @param {number} maxIntensity Maximum intensity in plot
 */
export const jetPalette = (maxIntensity: number) =>
  new LUT({
    steps: [
      { value: maxIntensity * (0 / 255), color: ColorRGBA(0, 0, 128) },
      { value: maxIntensity * (1 / 255), color: ColorRGBA(0, 0, 132) },
      { value: maxIntensity * (2 / 255), color: ColorRGBA(0, 0, 137) },
      { value: maxIntensity * (3 / 255), color: ColorRGBA(0, 0, 141) },
      { value: maxIntensity * (4 / 255), color: ColorRGBA(0, 0, 146) },
      { value: maxIntensity * (5 / 255), color: ColorRGBA(0, 0, 150) },
      { value: maxIntensity * (6 / 255), color: ColorRGBA(0, 0, 155) },
      { value: maxIntensity * (7 / 255), color: ColorRGBA(0, 0, 159) },
      { value: maxIntensity * (8 / 255), color: ColorRGBA(0, 0, 164) },
      { value: maxIntensity * (9 / 255), color: ColorRGBA(0, 0, 168) },
      { value: maxIntensity * (10 / 255), color: ColorRGBA(0, 0, 173) },
      { value: maxIntensity * (11 / 255), color: ColorRGBA(0, 0, 178) },
      { value: maxIntensity * (12 / 255), color: ColorRGBA(0, 0, 182) },
      { value: maxIntensity * (13 / 255), color: ColorRGBA(0, 0, 187) },
      { value: maxIntensity * (14 / 255), color: ColorRGBA(0, 0, 191) },
      { value: maxIntensity * (15 / 255), color: ColorRGBA(0, 0, 196) },
      { value: maxIntensity * (16 / 255), color: ColorRGBA(0, 0, 200) },
      { value: maxIntensity * (17 / 255), color: ColorRGBA(0, 0, 205) },
      { value: maxIntensity * (18 / 255), color: ColorRGBA(0, 0, 209) },
      { value: maxIntensity * (19 / 255), color: ColorRGBA(0, 0, 214) },
      { value: maxIntensity * (20 / 255), color: ColorRGBA(0, 0, 218) },
      { value: maxIntensity * (21 / 255), color: ColorRGBA(0, 0, 223) },
      { value: maxIntensity * (22 / 255), color: ColorRGBA(0, 0, 227) },
      { value: maxIntensity * (23 / 255), color: ColorRGBA(0, 0, 232) },
      { value: maxIntensity * (24 / 255), color: ColorRGBA(0, 0, 237) },
      { value: maxIntensity * (25 / 255), color: ColorRGBA(0, 0, 241) },
      { value: maxIntensity * (26 / 255), color: ColorRGBA(0, 0, 246) },
      { value: maxIntensity * (27 / 255), color: ColorRGBA(0, 0, 250) },
      { value: maxIntensity * (28 / 255), color: ColorRGBA(0, 0, 255) },
      { value: maxIntensity * (29 / 255), color: ColorRGBA(0, 0, 255) },
      { value: maxIntensity * (30 / 255), color: ColorRGBA(0, 0, 255) },
      { value: maxIntensity * (31 / 255), color: ColorRGBA(0, 0, 255) },
      { value: maxIntensity * (32 / 255), color: ColorRGBA(0, 0, 255) },
      { value: maxIntensity * (33 / 255), color: ColorRGBA(0, 4, 255) },
      { value: maxIntensity * (34 / 255), color: ColorRGBA(0, 8, 255) },
      { value: maxIntensity * (35 / 255), color: ColorRGBA(0, 12, 255) },
      { value: maxIntensity * (36 / 255), color: ColorRGBA(0, 16, 255) },
      { value: maxIntensity * (37 / 255), color: ColorRGBA(0, 20, 255) },
      { value: maxIntensity * (38 / 255), color: ColorRGBA(0, 24, 255) },
      { value: maxIntensity * (39 / 255), color: ColorRGBA(0, 28, 255) },
      { value: maxIntensity * (40 / 255), color: ColorRGBA(0, 32, 255) },
      { value: maxIntensity * (41 / 255), color: ColorRGBA(0, 36, 255) },
      { value: maxIntensity * (42 / 255), color: ColorRGBA(0, 40, 255) },
      { value: maxIntensity * (43 / 255), color: ColorRGBA(0, 44, 255) },
      { value: maxIntensity * (44 / 255), color: ColorRGBA(0, 48, 255) },
      { value: maxIntensity * (45 / 255), color: ColorRGBA(0, 52, 255) },
      { value: maxIntensity * (46 / 255), color: ColorRGBA(0, 56, 255) },
      { value: maxIntensity * (47 / 255), color: ColorRGBA(0, 60, 255) },
      { value: maxIntensity * (48 / 255), color: ColorRGBA(0, 64, 255) },
      { value: maxIntensity * (49 / 255), color: ColorRGBA(0, 68, 255) },
      { value: maxIntensity * (50 / 255), color: ColorRGBA(0, 72, 255) },
      { value: maxIntensity * (51 / 255), color: ColorRGBA(0, 76, 255) },
      { value: maxIntensity * (52 / 255), color: ColorRGBA(0, 80, 255) },
      { value: maxIntensity * (53 / 255), color: ColorRGBA(0, 84, 255) },
      { value: maxIntensity * (54 / 255), color: ColorRGBA(0, 88, 255) },
      { value: maxIntensity * (55 / 255), color: ColorRGBA(0, 92, 255) },
      { value: maxIntensity * (56 / 255), color: ColorRGBA(0, 96, 255) },
      { value: maxIntensity * (57 / 255), color: ColorRGBA(0, 100, 255) },
      { value: maxIntensity * (58 / 255), color: ColorRGBA(0, 104, 255) },
      { value: maxIntensity * (59 / 255), color: ColorRGBA(0, 108, 255) },
      { value: maxIntensity * (60 / 255), color: ColorRGBA(0, 112, 255) },
      { value: maxIntensity * (61 / 255), color: ColorRGBA(0, 116, 255) },
      { value: maxIntensity * (62 / 255), color: ColorRGBA(0, 120, 255) },
      { value: maxIntensity * (63 / 255), color: ColorRGBA(0, 124, 255) },
      { value: maxIntensity * (64 / 255), color: ColorRGBA(0, 128, 255) },
      { value: maxIntensity * (65 / 255), color: ColorRGBA(0, 132, 255) },
      { value: maxIntensity * (66 / 255), color: ColorRGBA(0, 136, 255) },
      { value: maxIntensity * (67 / 255), color: ColorRGBA(0, 140, 255) },
      { value: maxIntensity * (68 / 255), color: ColorRGBA(0, 144, 255) },
      { value: maxIntensity * (69 / 255), color: ColorRGBA(0, 148, 255) },
      { value: maxIntensity * (70 / 255), color: ColorRGBA(0, 152, 255) },
      { value: maxIntensity * (71 / 255), color: ColorRGBA(0, 156, 255) },
      { value: maxIntensity * (72 / 255), color: ColorRGBA(0, 160, 255) },
      { value: maxIntensity * (73 / 255), color: ColorRGBA(0, 164, 255) },
      { value: maxIntensity * (74 / 255), color: ColorRGBA(0, 168, 255) },
      { value: maxIntensity * (75 / 255), color: ColorRGBA(0, 172, 255) },
      { value: maxIntensity * (76 / 255), color: ColorRGBA(0, 176, 255) },
      { value: maxIntensity * (77 / 255), color: ColorRGBA(0, 180, 255) },
      { value: maxIntensity * (78 / 255), color: ColorRGBA(0, 184, 255) },
      { value: maxIntensity * (79 / 255), color: ColorRGBA(0, 188, 255) },
      { value: maxIntensity * (80 / 255), color: ColorRGBA(0, 192, 255) },
      { value: maxIntensity * (81 / 255), color: ColorRGBA(0, 196, 255) },
      { value: maxIntensity * (82 / 255), color: ColorRGBA(0, 200, 255) },
      { value: maxIntensity * (83 / 255), color: ColorRGBA(0, 204, 255) },
      { value: maxIntensity * (84 / 255), color: ColorRGBA(0, 208, 255) },
      { value: maxIntensity * (85 / 255), color: ColorRGBA(0, 212, 255) },
      { value: maxIntensity * (86 / 255), color: ColorRGBA(0, 216, 255) },
      { value: maxIntensity * (87 / 255), color: ColorRGBA(0, 220, 254) },
      { value: maxIntensity * (88 / 255), color: ColorRGBA(0, 224, 251) },
      { value: maxIntensity * (89 / 255), color: ColorRGBA(0, 228, 248) },
      { value: maxIntensity * (90 / 255), color: ColorRGBA(2, 232, 244) },
      { value: maxIntensity * (91 / 255), color: ColorRGBA(6, 236, 241) },
      { value: maxIntensity * (92 / 255), color: ColorRGBA(9, 240, 238) },
      { value: maxIntensity * (93 / 255), color: ColorRGBA(12, 244, 235) },
      { value: maxIntensity * (94 / 255), color: ColorRGBA(15, 248, 231) },
      { value: maxIntensity * (95 / 255), color: ColorRGBA(19, 252, 228) },
      { value: maxIntensity * (96 / 255), color: ColorRGBA(22, 255, 225) },
      { value: maxIntensity * (97 / 255), color: ColorRGBA(25, 255, 222) },
      { value: maxIntensity * (98 / 255), color: ColorRGBA(28, 255, 219) },
      { value: maxIntensity * (99 / 255), color: ColorRGBA(31, 255, 215) },
      { value: maxIntensity * (100 / 255), color: ColorRGBA(35, 255, 212) },
      { value: maxIntensity * (101 / 255), color: ColorRGBA(38, 255, 209) },
      { value: maxIntensity * (102 / 255), color: ColorRGBA(41, 255, 206) },
      { value: maxIntensity * (103 / 255), color: ColorRGBA(44, 255, 202) },
      { value: maxIntensity * (104 / 255), color: ColorRGBA(48, 255, 199) },
      { value: maxIntensity * (105 / 255), color: ColorRGBA(51, 255, 196) },
      { value: maxIntensity * (106 / 255), color: ColorRGBA(54, 255, 193) },
      { value: maxIntensity * (107 / 255), color: ColorRGBA(57, 255, 190) },
      { value: maxIntensity * (108 / 255), color: ColorRGBA(60, 255, 186) },
      { value: maxIntensity * (109 / 255), color: ColorRGBA(64, 255, 183) },
      { value: maxIntensity * (110 / 255), color: ColorRGBA(67, 255, 180) },
      { value: maxIntensity * (111 / 255), color: ColorRGBA(70, 255, 177) },
      { value: maxIntensity * (112 / 255), color: ColorRGBA(73, 255, 173) },
      { value: maxIntensity * (113 / 255), color: ColorRGBA(77, 255, 170) },
      { value: maxIntensity * (114 / 255), color: ColorRGBA(80, 255, 167) },
      { value: maxIntensity * (115 / 255), color: ColorRGBA(83, 255, 164) },
      { value: maxIntensity * (116 / 255), color: ColorRGBA(86, 255, 160) },
      { value: maxIntensity * (117 / 255), color: ColorRGBA(90, 255, 157) },
      { value: maxIntensity * (118 / 255), color: ColorRGBA(93, 255, 154) },
      { value: maxIntensity * (119 / 255), color: ColorRGBA(96, 255, 151) },
      { value: maxIntensity * (120 / 255), color: ColorRGBA(99, 255, 148) },
      { value: maxIntensity * (121 / 255), color: ColorRGBA(102, 255, 144) },
      { value: maxIntensity * (122 / 255), color: ColorRGBA(106, 255, 141) },
      { value: maxIntensity * (123 / 255), color: ColorRGBA(109, 255, 138) },
      { value: maxIntensity * (124 / 255), color: ColorRGBA(112, 255, 135) },
      { value: maxIntensity * (125 / 255), color: ColorRGBA(115, 255, 131) },
      { value: maxIntensity * (126 / 255), color: ColorRGBA(119, 255, 128) },
      { value: maxIntensity * (127 / 255), color: ColorRGBA(122, 255, 125) },
      { value: maxIntensity * (128 / 255), color: ColorRGBA(125, 255, 122) },
      { value: maxIntensity * (129 / 255), color: ColorRGBA(128, 255, 119) },
      { value: maxIntensity * (130 / 255), color: ColorRGBA(131, 255, 115) },
      { value: maxIntensity * (131 / 255), color: ColorRGBA(135, 255, 112) },
      { value: maxIntensity * (132 / 255), color: ColorRGBA(138, 255, 109) },
      { value: maxIntensity * (133 / 255), color: ColorRGBA(141, 255, 106) },
      { value: maxIntensity * (134 / 255), color: ColorRGBA(144, 255, 102) },
      { value: maxIntensity * (135 / 255), color: ColorRGBA(148, 255, 99) },
      { value: maxIntensity * (136 / 255), color: ColorRGBA(151, 255, 96) },
      { value: maxIntensity * (137 / 255), color: ColorRGBA(154, 255, 93) },
      { value: maxIntensity * (138 / 255), color: ColorRGBA(157, 255, 90) },
      { value: maxIntensity * (139 / 255), color: ColorRGBA(160, 255, 86) },
      { value: maxIntensity * (140 / 255), color: ColorRGBA(164, 255, 83) },
      { value: maxIntensity * (141 / 255), color: ColorRGBA(167, 255, 80) },
      { value: maxIntensity * (142 / 255), color: ColorRGBA(170, 255, 77) },
      { value: maxIntensity * (143 / 255), color: ColorRGBA(173, 255, 73) },
      { value: maxIntensity * (144 / 255), color: ColorRGBA(177, 255, 70) },
      { value: maxIntensity * (145 / 255), color: ColorRGBA(180, 255, 67) },
      { value: maxIntensity * (146 / 255), color: ColorRGBA(183, 255, 64) },
      { value: maxIntensity * (147 / 255), color: ColorRGBA(186, 255, 60) },
      { value: maxIntensity * (148 / 255), color: ColorRGBA(190, 255, 57) },
      { value: maxIntensity * (149 / 255), color: ColorRGBA(193, 255, 54) },
      { value: maxIntensity * (150 / 255), color: ColorRGBA(196, 255, 51) },
      { value: maxIntensity * (151 / 255), color: ColorRGBA(199, 255, 48) },
      { value: maxIntensity * (152 / 255), color: ColorRGBA(202, 255, 44) },
      { value: maxIntensity * (153 / 255), color: ColorRGBA(206, 255, 41) },
      { value: maxIntensity * (154 / 255), color: ColorRGBA(209, 255, 38) },
      { value: maxIntensity * (155 / 255), color: ColorRGBA(212, 255, 35) },
      { value: maxIntensity * (156 / 255), color: ColorRGBA(215, 255, 31) },
      { value: maxIntensity * (157 / 255), color: ColorRGBA(219, 255, 28) },
      { value: maxIntensity * (158 / 255), color: ColorRGBA(222, 255, 25) },
      { value: maxIntensity * (159 / 255), color: ColorRGBA(225, 255, 22) },
      { value: maxIntensity * (160 / 255), color: ColorRGBA(228, 255, 19) },
      { value: maxIntensity * (161 / 255), color: ColorRGBA(231, 255, 15) },
      { value: maxIntensity * (162 / 255), color: ColorRGBA(235, 255, 12) },
      { value: maxIntensity * (163 / 255), color: ColorRGBA(238, 255, 9) },
      { value: maxIntensity * (164 / 255), color: ColorRGBA(241, 252, 6) },
      { value: maxIntensity * (165 / 255), color: ColorRGBA(244, 248, 2) },
      { value: maxIntensity * (166 / 255), color: ColorRGBA(248, 245, 0) },
      { value: maxIntensity * (167 / 255), color: ColorRGBA(251, 241, 0) },
      { value: maxIntensity * (168 / 255), color: ColorRGBA(254, 237, 0) },
      { value: maxIntensity * (169 / 255), color: ColorRGBA(255, 234, 0) },
      { value: maxIntensity * (170 / 255), color: ColorRGBA(255, 230, 0) },
      { value: maxIntensity * (171 / 255), color: ColorRGBA(255, 226, 0) },
      { value: maxIntensity * (172 / 255), color: ColorRGBA(255, 222, 0) },
      { value: maxIntensity * (173 / 255), color: ColorRGBA(255, 219, 0) },
      { value: maxIntensity * (174 / 255), color: ColorRGBA(255, 215, 0) },
      { value: maxIntensity * (175 / 255), color: ColorRGBA(255, 211, 0) },
      { value: maxIntensity * (176 / 255), color: ColorRGBA(255, 208, 0) },
      { value: maxIntensity * (177 / 255), color: ColorRGBA(255, 204, 0) },
      { value: maxIntensity * (178 / 255), color: ColorRGBA(255, 200, 0) },
      { value: maxIntensity * (179 / 255), color: ColorRGBA(255, 196, 0) },
      { value: maxIntensity * (180 / 255), color: ColorRGBA(255, 193, 0) },
      { value: maxIntensity * (181 / 255), color: ColorRGBA(255, 189, 0) },
      { value: maxIntensity * (182 / 255), color: ColorRGBA(255, 185, 0) },
      { value: maxIntensity * (183 / 255), color: ColorRGBA(255, 182, 0) },
      { value: maxIntensity * (184 / 255), color: ColorRGBA(255, 178, 0) },
      { value: maxIntensity * (185 / 255), color: ColorRGBA(255, 174, 0) },
      { value: maxIntensity * (186 / 255), color: ColorRGBA(255, 171, 0) },
      { value: maxIntensity * (187 / 255), color: ColorRGBA(255, 167, 0) },
      { value: maxIntensity * (188 / 255), color: ColorRGBA(255, 163, 0) },
      { value: maxIntensity * (189 / 255), color: ColorRGBA(255, 159, 0) },
      { value: maxIntensity * (190 / 255), color: ColorRGBA(255, 156, 0) },
      { value: maxIntensity * (191 / 255), color: ColorRGBA(255, 152, 0) },
      { value: maxIntensity * (192 / 255), color: ColorRGBA(255, 148, 0) },
      { value: maxIntensity * (193 / 255), color: ColorRGBA(255, 145, 0) },
      { value: maxIntensity * (194 / 255), color: ColorRGBA(255, 141, 0) },
      { value: maxIntensity * (195 / 255), color: ColorRGBA(255, 137, 0) },
      { value: maxIntensity * (196 / 255), color: ColorRGBA(255, 134, 0) },
      { value: maxIntensity * (197 / 255), color: ColorRGBA(255, 130, 0) },
      { value: maxIntensity * (198 / 255), color: ColorRGBA(255, 126, 0) },
      { value: maxIntensity * (199 / 255), color: ColorRGBA(255, 122, 0) },
      { value: maxIntensity * (200 / 255), color: ColorRGBA(255, 119, 0) },
      { value: maxIntensity * (201 / 255), color: ColorRGBA(255, 115, 0) },
      { value: maxIntensity * (202 / 255), color: ColorRGBA(255, 111, 0) },
      { value: maxIntensity * (203 / 255), color: ColorRGBA(255, 108, 0) },
      { value: maxIntensity * (204 / 255), color: ColorRGBA(255, 104, 0) },
      { value: maxIntensity * (205 / 255), color: ColorRGBA(255, 100, 0) },
      { value: maxIntensity * (206 / 255), color: ColorRGBA(255, 96, 0) },
      { value: maxIntensity * (207 / 255), color: ColorRGBA(255, 93, 0) },
      { value: maxIntensity * (208 / 255), color: ColorRGBA(255, 89, 0) },
      { value: maxIntensity * (209 / 255), color: ColorRGBA(255, 85, 0) },
      { value: maxIntensity * (210 / 255), color: ColorRGBA(255, 82, 0) },
      { value: maxIntensity * (211 / 255), color: ColorRGBA(255, 78, 0) },
      { value: maxIntensity * (212 / 255), color: ColorRGBA(255, 74, 0) },
      { value: maxIntensity * (213 / 255), color: ColorRGBA(255, 71, 0) },
      { value: maxIntensity * (214 / 255), color: ColorRGBA(255, 67, 0) },
      { value: maxIntensity * (215 / 255), color: ColorRGBA(255, 63, 0) },
      { value: maxIntensity * (216 / 255), color: ColorRGBA(255, 59, 0) },
      { value: maxIntensity * (217 / 255), color: ColorRGBA(255, 56, 0) },
      { value: maxIntensity * (218 / 255), color: ColorRGBA(255, 52, 0) },
      { value: maxIntensity * (219 / 255), color: ColorRGBA(255, 48, 0) },
      { value: maxIntensity * (220 / 255), color: ColorRGBA(255, 45, 0) },
      { value: maxIntensity * (221 / 255), color: ColorRGBA(255, 41, 0) },
      { value: maxIntensity * (222 / 255), color: ColorRGBA(255, 37, 0) },
      { value: maxIntensity * (223 / 255), color: ColorRGBA(255, 34, 0) },
      { value: maxIntensity * (224 / 255), color: ColorRGBA(255, 30, 0) },
      { value: maxIntensity * (225 / 255), color: ColorRGBA(255, 26, 0) },
      { value: maxIntensity * (226 / 255), color: ColorRGBA(255, 22, 0) },
      { value: maxIntensity * (227 / 255), color: ColorRGBA(255, 19, 0) },
      { value: maxIntensity * (228 / 255), color: ColorRGBA(250, 15, 0) },
      { value: maxIntensity * (229 / 255), color: ColorRGBA(246, 11, 0) },
      { value: maxIntensity * (230 / 255), color: ColorRGBA(241, 8, 0) },
      { value: maxIntensity * (231 / 255), color: ColorRGBA(237, 4, 0) },
      { value: maxIntensity * (232 / 255), color: ColorRGBA(232, 0, 0) },
      { value: maxIntensity * (233 / 255), color: ColorRGBA(228, 0, 0) },
      { value: maxIntensity * (234 / 255), color: ColorRGBA(223, 0, 0) },
      { value: maxIntensity * (235 / 255), color: ColorRGBA(218, 0, 0) },
      { value: maxIntensity * (236 / 255), color: ColorRGBA(214, 0, 0) },
      { value: maxIntensity * (237 / 255), color: ColorRGBA(209, 0, 0) },
      { value: maxIntensity * (238 / 255), color: ColorRGBA(205, 0, 0) },
      { value: maxIntensity * (239 / 255), color: ColorRGBA(200, 0, 0) },
      { value: maxIntensity * (240 / 255), color: ColorRGBA(196, 0, 0) },
      { value: maxIntensity * (241 / 255), color: ColorRGBA(191, 0, 0) },
      { value: maxIntensity * (242 / 255), color: ColorRGBA(187, 0, 0) },
      { value: maxIntensity * (243 / 255), color: ColorRGBA(182, 0, 0) },
      { value: maxIntensity * (244 / 255), color: ColorRGBA(178, 0, 0) },
      { value: maxIntensity * (245 / 255), color: ColorRGBA(173, 0, 0) },
      { value: maxIntensity * (246 / 255), color: ColorRGBA(168, 0, 0) },
      { value: maxIntensity * (247 / 255), color: ColorRGBA(164, 0, 0) },
      { value: maxIntensity * (248 / 255), color: ColorRGBA(159, 0, 0) },
      { value: maxIntensity * (249 / 255), color: ColorRGBA(155, 0, 0) },
      { value: maxIntensity * (250 / 255), color: ColorRGBA(150, 0, 0) },
      { value: maxIntensity * (251 / 255), color: ColorRGBA(146, 0, 0) },
      { value: maxIntensity * (252 / 255), color: ColorRGBA(141, 0, 0) },
      { value: maxIntensity * (253 / 255), color: ColorRGBA(137, 0, 0) },
      { value: maxIntensity * (254 / 255), color: ColorRGBA(132, 0, 0) },
      { value: maxIntensity * (255 / 255), color: ColorRGBA(128, 0, 0) }
    ],
    interpolate: true
  });

/**
 * Magma color palette from Matplotlib
 * @param {number} maxIntensity Maximum intensity in plot
 */
export const magmaPalette = (maxIntensity: number) =>
  new LUT({
    steps: [
      { value: maxIntensity * (0 / 255), color: ColorRGBA(0, 0, 4) },
      { value: maxIntensity * (1 / 255), color: ColorRGBA(1, 0, 5) },
      { value: maxIntensity * (2 / 255), color: ColorRGBA(1, 1, 6) },
      { value: maxIntensity * (3 / 255), color: ColorRGBA(1, 1, 8) },
      { value: maxIntensity * (4 / 255), color: ColorRGBA(2, 1, 9) },
      { value: maxIntensity * (5 / 255), color: ColorRGBA(2, 2, 11) },
      { value: maxIntensity * (6 / 255), color: ColorRGBA(2, 2, 13) },
      { value: maxIntensity * (7 / 255), color: ColorRGBA(3, 3, 15) },
      { value: maxIntensity * (8 / 255), color: ColorRGBA(3, 3, 18) },
      { value: maxIntensity * (9 / 255), color: ColorRGBA(4, 4, 20) },
      { value: maxIntensity * (10 / 255), color: ColorRGBA(5, 4, 22) },
      { value: maxIntensity * (11 / 255), color: ColorRGBA(6, 5, 24) },
      { value: maxIntensity * (12 / 255), color: ColorRGBA(6, 5, 26) },
      { value: maxIntensity * (13 / 255), color: ColorRGBA(7, 6, 28) },
      { value: maxIntensity * (14 / 255), color: ColorRGBA(8, 7, 30) },
      { value: maxIntensity * (15 / 255), color: ColorRGBA(9, 7, 32) },
      { value: maxIntensity * (16 / 255), color: ColorRGBA(10, 8, 34) },
      { value: maxIntensity * (17 / 255), color: ColorRGBA(11, 9, 36) },
      { value: maxIntensity * (18 / 255), color: ColorRGBA(12, 9, 38) },
      { value: maxIntensity * (19 / 255), color: ColorRGBA(13, 10, 41) },
      { value: maxIntensity * (20 / 255), color: ColorRGBA(14, 11, 43) },
      { value: maxIntensity * (21 / 255), color: ColorRGBA(16, 11, 45) },
      { value: maxIntensity * (22 / 255), color: ColorRGBA(17, 12, 47) },
      { value: maxIntensity * (23 / 255), color: ColorRGBA(18, 13, 49) },
      { value: maxIntensity * (24 / 255), color: ColorRGBA(19, 13, 52) },
      { value: maxIntensity * (25 / 255), color: ColorRGBA(20, 14, 54) },
      { value: maxIntensity * (26 / 255), color: ColorRGBA(21, 14, 56) },
      { value: maxIntensity * (27 / 255), color: ColorRGBA(22, 15, 59) },
      { value: maxIntensity * (28 / 255), color: ColorRGBA(24, 15, 61) },
      { value: maxIntensity * (29 / 255), color: ColorRGBA(25, 16, 63) },
      { value: maxIntensity * (30 / 255), color: ColorRGBA(26, 16, 66) },
      { value: maxIntensity * (31 / 255), color: ColorRGBA(28, 16, 68) },
      { value: maxIntensity * (32 / 255), color: ColorRGBA(29, 17, 71) },
      { value: maxIntensity * (33 / 255), color: ColorRGBA(30, 17, 73) },
      { value: maxIntensity * (34 / 255), color: ColorRGBA(32, 17, 75) },
      { value: maxIntensity * (35 / 255), color: ColorRGBA(33, 17, 78) },
      { value: maxIntensity * (36 / 255), color: ColorRGBA(34, 17, 80) },
      { value: maxIntensity * (37 / 255), color: ColorRGBA(36, 18, 83) },
      { value: maxIntensity * (38 / 255), color: ColorRGBA(37, 18, 85) },
      { value: maxIntensity * (39 / 255), color: ColorRGBA(39, 18, 88) },
      { value: maxIntensity * (40 / 255), color: ColorRGBA(41, 17, 90) },
      { value: maxIntensity * (41 / 255), color: ColorRGBA(42, 17, 92) },
      { value: maxIntensity * (42 / 255), color: ColorRGBA(44, 17, 95) },
      { value: maxIntensity * (43 / 255), color: ColorRGBA(45, 17, 97) },
      { value: maxIntensity * (44 / 255), color: ColorRGBA(47, 17, 99) },
      { value: maxIntensity * (45 / 255), color: ColorRGBA(49, 17, 101) },
      { value: maxIntensity * (46 / 255), color: ColorRGBA(51, 16, 103) },
      { value: maxIntensity * (47 / 255), color: ColorRGBA(52, 16, 105) },
      { value: maxIntensity * (48 / 255), color: ColorRGBA(54, 16, 107) },
      { value: maxIntensity * (49 / 255), color: ColorRGBA(56, 16, 108) },
      { value: maxIntensity * (50 / 255), color: ColorRGBA(57, 15, 110) },
      { value: maxIntensity * (51 / 255), color: ColorRGBA(59, 15, 112) },
      { value: maxIntensity * (52 / 255), color: ColorRGBA(61, 15, 113) },
      { value: maxIntensity * (53 / 255), color: ColorRGBA(63, 15, 114) },
      { value: maxIntensity * (54 / 255), color: ColorRGBA(64, 15, 116) },
      { value: maxIntensity * (55 / 255), color: ColorRGBA(66, 15, 117) },
      { value: maxIntensity * (56 / 255), color: ColorRGBA(68, 15, 118) },
      { value: maxIntensity * (57 / 255), color: ColorRGBA(69, 16, 119) },
      { value: maxIntensity * (58 / 255), color: ColorRGBA(71, 16, 120) },
      { value: maxIntensity * (59 / 255), color: ColorRGBA(73, 16, 120) },
      { value: maxIntensity * (60 / 255), color: ColorRGBA(74, 16, 121) },
      { value: maxIntensity * (61 / 255), color: ColorRGBA(76, 17, 122) },
      { value: maxIntensity * (62 / 255), color: ColorRGBA(78, 17, 123) },
      { value: maxIntensity * (63 / 255), color: ColorRGBA(79, 18, 123) },
      { value: maxIntensity * (64 / 255), color: ColorRGBA(81, 18, 124) },
      { value: maxIntensity * (65 / 255), color: ColorRGBA(82, 19, 124) },
      { value: maxIntensity * (66 / 255), color: ColorRGBA(84, 19, 125) },
      { value: maxIntensity * (67 / 255), color: ColorRGBA(86, 20, 125) },
      { value: maxIntensity * (68 / 255), color: ColorRGBA(87, 21, 126) },
      { value: maxIntensity * (69 / 255), color: ColorRGBA(89, 21, 126) },
      { value: maxIntensity * (70 / 255), color: ColorRGBA(90, 22, 126) },
      { value: maxIntensity * (71 / 255), color: ColorRGBA(92, 22, 127) },
      { value: maxIntensity * (72 / 255), color: ColorRGBA(93, 23, 127) },
      { value: maxIntensity * (73 / 255), color: ColorRGBA(95, 24, 127) },
      { value: maxIntensity * (74 / 255), color: ColorRGBA(96, 24, 128) },
      { value: maxIntensity * (75 / 255), color: ColorRGBA(98, 25, 128) },
      { value: maxIntensity * (76 / 255), color: ColorRGBA(100, 26, 128) },
      { value: maxIntensity * (77 / 255), color: ColorRGBA(101, 26, 128) },
      { value: maxIntensity * (78 / 255), color: ColorRGBA(103, 27, 128) },
      { value: maxIntensity * (79 / 255), color: ColorRGBA(104, 28, 129) },
      { value: maxIntensity * (80 / 255), color: ColorRGBA(106, 28, 129) },
      { value: maxIntensity * (81 / 255), color: ColorRGBA(107, 29, 129) },
      { value: maxIntensity * (82 / 255), color: ColorRGBA(109, 29, 129) },
      { value: maxIntensity * (83 / 255), color: ColorRGBA(110, 30, 129) },
      { value: maxIntensity * (84 / 255), color: ColorRGBA(112, 31, 129) },
      { value: maxIntensity * (85 / 255), color: ColorRGBA(114, 31, 129) },
      { value: maxIntensity * (86 / 255), color: ColorRGBA(115, 32, 129) },
      { value: maxIntensity * (87 / 255), color: ColorRGBA(117, 33, 129) },
      { value: maxIntensity * (88 / 255), color: ColorRGBA(118, 33, 129) },
      { value: maxIntensity * (89 / 255), color: ColorRGBA(120, 34, 129) },
      { value: maxIntensity * (90 / 255), color: ColorRGBA(121, 34, 130) },
      { value: maxIntensity * (91 / 255), color: ColorRGBA(123, 35, 130) },
      { value: maxIntensity * (92 / 255), color: ColorRGBA(124, 35, 130) },
      { value: maxIntensity * (93 / 255), color: ColorRGBA(126, 36, 130) },
      { value: maxIntensity * (94 / 255), color: ColorRGBA(128, 37, 130) },
      { value: maxIntensity * (95 / 255), color: ColorRGBA(129, 37, 129) },
      { value: maxIntensity * (96 / 255), color: ColorRGBA(131, 38, 129) },
      { value: maxIntensity * (97 / 255), color: ColorRGBA(132, 38, 129) },
      { value: maxIntensity * (98 / 255), color: ColorRGBA(134, 39, 129) },
      { value: maxIntensity * (99 / 255), color: ColorRGBA(136, 39, 129) },
      { value: maxIntensity * (100 / 255), color: ColorRGBA(137, 40, 129) },
      { value: maxIntensity * (101 / 255), color: ColorRGBA(139, 41, 129) },
      { value: maxIntensity * (102 / 255), color: ColorRGBA(140, 41, 129) },
      { value: maxIntensity * (103 / 255), color: ColorRGBA(142, 42, 129) },
      { value: maxIntensity * (104 / 255), color: ColorRGBA(144, 42, 129) },
      { value: maxIntensity * (105 / 255), color: ColorRGBA(145, 43, 129) },
      { value: maxIntensity * (106 / 255), color: ColorRGBA(147, 43, 128) },
      { value: maxIntensity * (107 / 255), color: ColorRGBA(148, 44, 128) },
      { value: maxIntensity * (108 / 255), color: ColorRGBA(150, 44, 128) },
      { value: maxIntensity * (109 / 255), color: ColorRGBA(152, 45, 128) },
      { value: maxIntensity * (110 / 255), color: ColorRGBA(153, 45, 128) },
      { value: maxIntensity * (111 / 255), color: ColorRGBA(155, 46, 127) },
      { value: maxIntensity * (112 / 255), color: ColorRGBA(156, 46, 127) },
      { value: maxIntensity * (113 / 255), color: ColorRGBA(158, 47, 127) },
      { value: maxIntensity * (114 / 255), color: ColorRGBA(160, 47, 127) },
      { value: maxIntensity * (115 / 255), color: ColorRGBA(161, 48, 126) },
      { value: maxIntensity * (116 / 255), color: ColorRGBA(163, 48, 126) },
      { value: maxIntensity * (117 / 255), color: ColorRGBA(165, 49, 126) },
      { value: maxIntensity * (118 / 255), color: ColorRGBA(166, 49, 125) },
      { value: maxIntensity * (119 / 255), color: ColorRGBA(168, 50, 125) },
      { value: maxIntensity * (120 / 255), color: ColorRGBA(170, 51, 125) },
      { value: maxIntensity * (121 / 255), color: ColorRGBA(171, 51, 124) },
      { value: maxIntensity * (122 / 255), color: ColorRGBA(173, 52, 124) },
      { value: maxIntensity * (123 / 255), color: ColorRGBA(174, 52, 123) },
      { value: maxIntensity * (124 / 255), color: ColorRGBA(176, 53, 123) },
      { value: maxIntensity * (125 / 255), color: ColorRGBA(178, 53, 123) },
      { value: maxIntensity * (126 / 255), color: ColorRGBA(179, 54, 122) },
      { value: maxIntensity * (127 / 255), color: ColorRGBA(181, 54, 122) },
      { value: maxIntensity * (128 / 255), color: ColorRGBA(183, 55, 121) },
      { value: maxIntensity * (129 / 255), color: ColorRGBA(184, 55, 121) },
      { value: maxIntensity * (130 / 255), color: ColorRGBA(186, 56, 120) },
      { value: maxIntensity * (131 / 255), color: ColorRGBA(188, 57, 120) },
      { value: maxIntensity * (132 / 255), color: ColorRGBA(189, 57, 119) },
      { value: maxIntensity * (133 / 255), color: ColorRGBA(191, 58, 119) },
      { value: maxIntensity * (134 / 255), color: ColorRGBA(192, 58, 118) },
      { value: maxIntensity * (135 / 255), color: ColorRGBA(194, 59, 117) },
      { value: maxIntensity * (136 / 255), color: ColorRGBA(196, 60, 117) },
      { value: maxIntensity * (137 / 255), color: ColorRGBA(197, 60, 116) },
      { value: maxIntensity * (138 / 255), color: ColorRGBA(199, 61, 115) },
      { value: maxIntensity * (139 / 255), color: ColorRGBA(200, 62, 115) },
      { value: maxIntensity * (140 / 255), color: ColorRGBA(202, 62, 114) },
      { value: maxIntensity * (141 / 255), color: ColorRGBA(204, 63, 113) },
      { value: maxIntensity * (142 / 255), color: ColorRGBA(205, 64, 113) },
      { value: maxIntensity * (143 / 255), color: ColorRGBA(207, 64, 112) },
      { value: maxIntensity * (144 / 255), color: ColorRGBA(208, 65, 111) },
      { value: maxIntensity * (145 / 255), color: ColorRGBA(210, 66, 111) },
      { value: maxIntensity * (146 / 255), color: ColorRGBA(211, 67, 110) },
      { value: maxIntensity * (147 / 255), color: ColorRGBA(213, 68, 109) },
      { value: maxIntensity * (148 / 255), color: ColorRGBA(214, 69, 108) },
      { value: maxIntensity * (149 / 255), color: ColorRGBA(216, 69, 108) },
      { value: maxIntensity * (150 / 255), color: ColorRGBA(217, 70, 107) },
      { value: maxIntensity * (151 / 255), color: ColorRGBA(219, 71, 106) },
      { value: maxIntensity * (152 / 255), color: ColorRGBA(220, 72, 105) },
      { value: maxIntensity * (153 / 255), color: ColorRGBA(222, 73, 104) },
      { value: maxIntensity * (154 / 255), color: ColorRGBA(223, 74, 104) },
      { value: maxIntensity * (155 / 255), color: ColorRGBA(224, 76, 103) },
      { value: maxIntensity * (156 / 255), color: ColorRGBA(226, 77, 102) },
      { value: maxIntensity * (157 / 255), color: ColorRGBA(227, 78, 101) },
      { value: maxIntensity * (158 / 255), color: ColorRGBA(228, 79, 100) },
      { value: maxIntensity * (159 / 255), color: ColorRGBA(229, 80, 100) },
      { value: maxIntensity * (160 / 255), color: ColorRGBA(231, 82, 99) },
      { value: maxIntensity * (161 / 255), color: ColorRGBA(232, 83, 98) },
      { value: maxIntensity * (162 / 255), color: ColorRGBA(233, 84, 98) },
      { value: maxIntensity * (163 / 255), color: ColorRGBA(234, 86, 97) },
      { value: maxIntensity * (164 / 255), color: ColorRGBA(235, 87, 96) },
      { value: maxIntensity * (165 / 255), color: ColorRGBA(236, 88, 96) },
      { value: maxIntensity * (166 / 255), color: ColorRGBA(237, 90, 95) },
      { value: maxIntensity * (167 / 255), color: ColorRGBA(238, 91, 94) },
      { value: maxIntensity * (168 / 255), color: ColorRGBA(239, 93, 94) },
      { value: maxIntensity * (169 / 255), color: ColorRGBA(240, 95, 94) },
      { value: maxIntensity * (170 / 255), color: ColorRGBA(241, 96, 93) },
      { value: maxIntensity * (171 / 255), color: ColorRGBA(242, 98, 93) },
      { value: maxIntensity * (172 / 255), color: ColorRGBA(242, 100, 92) },
      { value: maxIntensity * (173 / 255), color: ColorRGBA(243, 101, 92) },
      { value: maxIntensity * (174 / 255), color: ColorRGBA(244, 103, 92) },
      { value: maxIntensity * (175 / 255), color: ColorRGBA(244, 105, 92) },
      { value: maxIntensity * (176 / 255), color: ColorRGBA(245, 107, 92) },
      { value: maxIntensity * (177 / 255), color: ColorRGBA(246, 108, 92) },
      { value: maxIntensity * (178 / 255), color: ColorRGBA(246, 110, 92) },
      { value: maxIntensity * (179 / 255), color: ColorRGBA(247, 112, 92) },
      { value: maxIntensity * (180 / 255), color: ColorRGBA(247, 114, 92) },
      { value: maxIntensity * (181 / 255), color: ColorRGBA(248, 116, 92) },
      { value: maxIntensity * (182 / 255), color: ColorRGBA(248, 118, 92) },
      { value: maxIntensity * (183 / 255), color: ColorRGBA(249, 120, 93) },
      { value: maxIntensity * (184 / 255), color: ColorRGBA(249, 121, 93) },
      { value: maxIntensity * (185 / 255), color: ColorRGBA(249, 123, 93) },
      { value: maxIntensity * (186 / 255), color: ColorRGBA(250, 125, 94) },
      { value: maxIntensity * (187 / 255), color: ColorRGBA(250, 127, 94) },
      { value: maxIntensity * (188 / 255), color: ColorRGBA(250, 129, 95) },
      { value: maxIntensity * (189 / 255), color: ColorRGBA(251, 131, 95) },
      { value: maxIntensity * (190 / 255), color: ColorRGBA(251, 133, 96) },
      { value: maxIntensity * (191 / 255), color: ColorRGBA(251, 135, 97) },
      { value: maxIntensity * (192 / 255), color: ColorRGBA(252, 137, 97) },
      { value: maxIntensity * (193 / 255), color: ColorRGBA(252, 138, 98) },
      { value: maxIntensity * (194 / 255), color: ColorRGBA(252, 140, 99) },
      { value: maxIntensity * (195 / 255), color: ColorRGBA(252, 142, 100) },
      { value: maxIntensity * (196 / 255), color: ColorRGBA(252, 144, 101) },
      { value: maxIntensity * (197 / 255), color: ColorRGBA(253, 146, 102) },
      { value: maxIntensity * (198 / 255), color: ColorRGBA(253, 148, 103) },
      { value: maxIntensity * (199 / 255), color: ColorRGBA(253, 150, 104) },
      { value: maxIntensity * (200 / 255), color: ColorRGBA(253, 152, 105) },
      { value: maxIntensity * (201 / 255), color: ColorRGBA(253, 154, 106) },
      { value: maxIntensity * (202 / 255), color: ColorRGBA(253, 155, 107) },
      { value: maxIntensity * (203 / 255), color: ColorRGBA(254, 157, 108) },
      { value: maxIntensity * (204 / 255), color: ColorRGBA(254, 159, 109) },
      { value: maxIntensity * (205 / 255), color: ColorRGBA(254, 161, 110) },
      { value: maxIntensity * (206 / 255), color: ColorRGBA(254, 163, 111) },
      { value: maxIntensity * (207 / 255), color: ColorRGBA(254, 165, 113) },
      { value: maxIntensity * (208 / 255), color: ColorRGBA(254, 167, 114) },
      { value: maxIntensity * (209 / 255), color: ColorRGBA(254, 169, 115) },
      { value: maxIntensity * (210 / 255), color: ColorRGBA(254, 170, 116) },
      { value: maxIntensity * (211 / 255), color: ColorRGBA(254, 172, 118) },
      { value: maxIntensity * (212 / 255), color: ColorRGBA(254, 174, 119) },
      { value: maxIntensity * (213 / 255), color: ColorRGBA(254, 176, 120) },
      { value: maxIntensity * (214 / 255), color: ColorRGBA(254, 178, 122) },
      { value: maxIntensity * (215 / 255), color: ColorRGBA(254, 180, 123) },
      { value: maxIntensity * (216 / 255), color: ColorRGBA(254, 182, 124) },
      { value: maxIntensity * (217 / 255), color: ColorRGBA(254, 183, 126) },
      { value: maxIntensity * (218 / 255), color: ColorRGBA(254, 185, 127) },
      { value: maxIntensity * (219 / 255), color: ColorRGBA(254, 187, 129) },
      { value: maxIntensity * (220 / 255), color: ColorRGBA(254, 189, 130) },
      { value: maxIntensity * (221 / 255), color: ColorRGBA(254, 191, 132) },
      { value: maxIntensity * (222 / 255), color: ColorRGBA(254, 193, 133) },
      { value: maxIntensity * (223 / 255), color: ColorRGBA(254, 194, 135) },
      { value: maxIntensity * (224 / 255), color: ColorRGBA(254, 196, 136) },
      { value: maxIntensity * (225 / 255), color: ColorRGBA(254, 198, 138) },
      { value: maxIntensity * (226 / 255), color: ColorRGBA(254, 200, 140) },
      { value: maxIntensity * (227 / 255), color: ColorRGBA(254, 202, 141) },
      { value: maxIntensity * (228 / 255), color: ColorRGBA(254, 204, 143) },
      { value: maxIntensity * (229 / 255), color: ColorRGBA(254, 205, 144) },
      { value: maxIntensity * (230 / 255), color: ColorRGBA(254, 207, 146) },
      { value: maxIntensity * (231 / 255), color: ColorRGBA(254, 209, 148) },
      { value: maxIntensity * (232 / 255), color: ColorRGBA(254, 211, 149) },
      { value: maxIntensity * (233 / 255), color: ColorRGBA(254, 213, 151) },
      { value: maxIntensity * (234 / 255), color: ColorRGBA(254, 215, 153) },
      { value: maxIntensity * (235 / 255), color: ColorRGBA(254, 216, 154) },
      { value: maxIntensity * (236 / 255), color: ColorRGBA(253, 218, 156) },
      { value: maxIntensity * (237 / 255), color: ColorRGBA(253, 220, 158) },
      { value: maxIntensity * (238 / 255), color: ColorRGBA(253, 222, 160) },
      { value: maxIntensity * (239 / 255), color: ColorRGBA(253, 224, 161) },
      { value: maxIntensity * (240 / 255), color: ColorRGBA(253, 226, 163) },
      { value: maxIntensity * (241 / 255), color: ColorRGBA(253, 227, 165) },
      { value: maxIntensity * (242 / 255), color: ColorRGBA(253, 229, 167) },
      { value: maxIntensity * (243 / 255), color: ColorRGBA(253, 231, 169) },
      { value: maxIntensity * (244 / 255), color: ColorRGBA(253, 233, 170) },
      { value: maxIntensity * (245 / 255), color: ColorRGBA(253, 235, 172) },
      { value: maxIntensity * (246 / 255), color: ColorRGBA(252, 236, 174) },
      { value: maxIntensity * (247 / 255), color: ColorRGBA(252, 238, 176) },
      { value: maxIntensity * (248 / 255), color: ColorRGBA(252, 240, 178) },
      { value: maxIntensity * (249 / 255), color: ColorRGBA(252, 242, 180) },
      { value: maxIntensity * (250 / 255), color: ColorRGBA(252, 244, 182) },
      { value: maxIntensity * (251 / 255), color: ColorRGBA(252, 246, 184) },
      { value: maxIntensity * (252 / 255), color: ColorRGBA(252, 247, 185) },
      { value: maxIntensity * (253 / 255), color: ColorRGBA(252, 249, 187) },
      { value: maxIntensity * (254 / 255), color: ColorRGBA(252, 251, 189) },
      { value: maxIntensity * (255 / 255), color: ColorRGBA(252, 253, 191) }
    ],
    interpolate: true
  });

/**
 * Plasma color palette from Matplotlib
 * @param {number} maxIntensity Maximum intensity in plot
 */
export const plasmaPalette = (maxIntensity: number) =>
  new LUT({
    steps: [
      { value: maxIntensity * (0 / 255), color: ColorRGBA(13, 8, 135) },
      { value: maxIntensity * (1 / 255), color: ColorRGBA(16, 7, 136) },
      { value: maxIntensity * (2 / 255), color: ColorRGBA(19, 7, 137) },
      { value: maxIntensity * (3 / 255), color: ColorRGBA(22, 7, 138) },
      { value: maxIntensity * (4 / 255), color: ColorRGBA(25, 6, 140) },
      { value: maxIntensity * (5 / 255), color: ColorRGBA(27, 6, 141) },
      { value: maxIntensity * (6 / 255), color: ColorRGBA(29, 6, 142) },
      { value: maxIntensity * (7 / 255), color: ColorRGBA(32, 6, 143) },
      { value: maxIntensity * (8 / 255), color: ColorRGBA(34, 6, 144) },
      { value: maxIntensity * (9 / 255), color: ColorRGBA(36, 6, 145) },
      { value: maxIntensity * (10 / 255), color: ColorRGBA(38, 5, 145) },
      { value: maxIntensity * (11 / 255), color: ColorRGBA(40, 5, 146) },
      { value: maxIntensity * (12 / 255), color: ColorRGBA(42, 5, 147) },
      { value: maxIntensity * (13 / 255), color: ColorRGBA(44, 5, 148) },
      { value: maxIntensity * (14 / 255), color: ColorRGBA(46, 5, 149) },
      { value: maxIntensity * (15 / 255), color: ColorRGBA(47, 5, 150) },
      { value: maxIntensity * (16 / 255), color: ColorRGBA(49, 5, 151) },
      { value: maxIntensity * (17 / 255), color: ColorRGBA(51, 5, 151) },
      { value: maxIntensity * (18 / 255), color: ColorRGBA(53, 4, 152) },
      { value: maxIntensity * (19 / 255), color: ColorRGBA(55, 4, 153) },
      { value: maxIntensity * (20 / 255), color: ColorRGBA(56, 4, 154) },
      { value: maxIntensity * (21 / 255), color: ColorRGBA(58, 4, 154) },
      { value: maxIntensity * (22 / 255), color: ColorRGBA(60, 4, 155) },
      { value: maxIntensity * (23 / 255), color: ColorRGBA(62, 4, 156) },
      { value: maxIntensity * (24 / 255), color: ColorRGBA(63, 4, 156) },
      { value: maxIntensity * (25 / 255), color: ColorRGBA(65, 4, 157) },
      { value: maxIntensity * (26 / 255), color: ColorRGBA(67, 3, 158) },
      { value: maxIntensity * (27 / 255), color: ColorRGBA(68, 3, 158) },
      { value: maxIntensity * (28 / 255), color: ColorRGBA(70, 3, 159) },
      { value: maxIntensity * (29 / 255), color: ColorRGBA(72, 3, 159) },
      { value: maxIntensity * (30 / 255), color: ColorRGBA(73, 3, 160) },
      { value: maxIntensity * (31 / 255), color: ColorRGBA(75, 3, 161) },
      { value: maxIntensity * (32 / 255), color: ColorRGBA(76, 2, 161) },
      { value: maxIntensity * (33 / 255), color: ColorRGBA(78, 2, 162) },
      { value: maxIntensity * (34 / 255), color: ColorRGBA(80, 2, 162) },
      { value: maxIntensity * (35 / 255), color: ColorRGBA(81, 2, 163) },
      { value: maxIntensity * (36 / 255), color: ColorRGBA(83, 2, 163) },
      { value: maxIntensity * (37 / 255), color: ColorRGBA(85, 2, 164) },
      { value: maxIntensity * (38 / 255), color: ColorRGBA(86, 1, 164) },
      { value: maxIntensity * (39 / 255), color: ColorRGBA(88, 1, 164) },
      { value: maxIntensity * (40 / 255), color: ColorRGBA(89, 1, 165) },
      { value: maxIntensity * (41 / 255), color: ColorRGBA(91, 1, 165) },
      { value: maxIntensity * (42 / 255), color: ColorRGBA(92, 1, 166) },
      { value: maxIntensity * (43 / 255), color: ColorRGBA(94, 1, 166) },
      { value: maxIntensity * (44 / 255), color: ColorRGBA(96, 1, 166) },
      { value: maxIntensity * (45 / 255), color: ColorRGBA(97, 0, 167) },
      { value: maxIntensity * (46 / 255), color: ColorRGBA(99, 0, 167) },
      { value: maxIntensity * (47 / 255), color: ColorRGBA(100, 0, 167) },
      { value: maxIntensity * (48 / 255), color: ColorRGBA(102, 0, 167) },
      { value: maxIntensity * (49 / 255), color: ColorRGBA(103, 0, 168) },
      { value: maxIntensity * (50 / 255), color: ColorRGBA(105, 0, 168) },
      { value: maxIntensity * (51 / 255), color: ColorRGBA(106, 0, 168) },
      { value: maxIntensity * (52 / 255), color: ColorRGBA(108, 0, 168) },
      { value: maxIntensity * (53 / 255), color: ColorRGBA(110, 0, 168) },
      { value: maxIntensity * (54 / 255), color: ColorRGBA(111, 0, 168) },
      { value: maxIntensity * (55 / 255), color: ColorRGBA(113, 0, 168) },
      { value: maxIntensity * (56 / 255), color: ColorRGBA(114, 1, 168) },
      { value: maxIntensity * (57 / 255), color: ColorRGBA(116, 1, 168) },
      { value: maxIntensity * (58 / 255), color: ColorRGBA(117, 1, 168) },
      { value: maxIntensity * (59 / 255), color: ColorRGBA(119, 1, 168) },
      { value: maxIntensity * (60 / 255), color: ColorRGBA(120, 1, 168) },
      { value: maxIntensity * (61 / 255), color: ColorRGBA(122, 2, 168) },
      { value: maxIntensity * (62 / 255), color: ColorRGBA(123, 2, 168) },
      { value: maxIntensity * (63 / 255), color: ColorRGBA(125, 3, 168) },
      { value: maxIntensity * (64 / 255), color: ColorRGBA(126, 3, 168) },
      { value: maxIntensity * (65 / 255), color: ColorRGBA(128, 4, 168) },
      { value: maxIntensity * (66 / 255), color: ColorRGBA(129, 4, 167) },
      { value: maxIntensity * (67 / 255), color: ColorRGBA(131, 5, 167) },
      { value: maxIntensity * (68 / 255), color: ColorRGBA(132, 5, 167) },
      { value: maxIntensity * (69 / 255), color: ColorRGBA(134, 6, 166) },
      { value: maxIntensity * (70 / 255), color: ColorRGBA(135, 7, 166) },
      { value: maxIntensity * (71 / 255), color: ColorRGBA(136, 8, 166) },
      { value: maxIntensity * (72 / 255), color: ColorRGBA(138, 9, 165) },
      { value: maxIntensity * (73 / 255), color: ColorRGBA(139, 10, 165) },
      { value: maxIntensity * (74 / 255), color: ColorRGBA(141, 11, 165) },
      { value: maxIntensity * (75 / 255), color: ColorRGBA(142, 12, 164) },
      { value: maxIntensity * (76 / 255), color: ColorRGBA(143, 13, 164) },
      { value: maxIntensity * (77 / 255), color: ColorRGBA(145, 14, 163) },
      { value: maxIntensity * (78 / 255), color: ColorRGBA(146, 15, 163) },
      { value: maxIntensity * (79 / 255), color: ColorRGBA(148, 16, 162) },
      { value: maxIntensity * (80 / 255), color: ColorRGBA(149, 17, 161) },
      { value: maxIntensity * (81 / 255), color: ColorRGBA(150, 19, 161) },
      { value: maxIntensity * (82 / 255), color: ColorRGBA(152, 20, 160) },
      { value: maxIntensity * (83 / 255), color: ColorRGBA(153, 21, 159) },
      { value: maxIntensity * (84 / 255), color: ColorRGBA(154, 22, 159) },
      { value: maxIntensity * (85 / 255), color: ColorRGBA(156, 23, 158) },
      { value: maxIntensity * (86 / 255), color: ColorRGBA(157, 24, 157) },
      { value: maxIntensity * (87 / 255), color: ColorRGBA(158, 25, 157) },
      { value: maxIntensity * (88 / 255), color: ColorRGBA(160, 26, 156) },
      { value: maxIntensity * (89 / 255), color: ColorRGBA(161, 27, 155) },
      { value: maxIntensity * (90 / 255), color: ColorRGBA(162, 29, 154) },
      { value: maxIntensity * (91 / 255), color: ColorRGBA(163, 30, 154) },
      { value: maxIntensity * (92 / 255), color: ColorRGBA(165, 31, 153) },
      { value: maxIntensity * (93 / 255), color: ColorRGBA(166, 32, 152) },
      { value: maxIntensity * (94 / 255), color: ColorRGBA(167, 33, 151) },
      { value: maxIntensity * (95 / 255), color: ColorRGBA(168, 34, 150) },
      { value: maxIntensity * (96 / 255), color: ColorRGBA(170, 35, 149) },
      { value: maxIntensity * (97 / 255), color: ColorRGBA(171, 36, 148) },
      { value: maxIntensity * (98 / 255), color: ColorRGBA(172, 38, 148) },
      { value: maxIntensity * (99 / 255), color: ColorRGBA(173, 39, 147) },
      { value: maxIntensity * (100 / 255), color: ColorRGBA(174, 40, 146) },
      { value: maxIntensity * (101 / 255), color: ColorRGBA(176, 41, 145) },
      { value: maxIntensity * (102 / 255), color: ColorRGBA(177, 42, 144) },
      { value: maxIntensity * (103 / 255), color: ColorRGBA(178, 43, 143) },
      { value: maxIntensity * (104 / 255), color: ColorRGBA(179, 44, 142) },
      { value: maxIntensity * (105 / 255), color: ColorRGBA(180, 46, 141) },
      { value: maxIntensity * (106 / 255), color: ColorRGBA(181, 47, 140) },
      { value: maxIntensity * (107 / 255), color: ColorRGBA(182, 48, 139) },
      { value: maxIntensity * (108 / 255), color: ColorRGBA(183, 49, 138) },
      { value: maxIntensity * (109 / 255), color: ColorRGBA(184, 50, 137) },
      { value: maxIntensity * (110 / 255), color: ColorRGBA(186, 51, 136) },
      { value: maxIntensity * (111 / 255), color: ColorRGBA(187, 52, 136) },
      { value: maxIntensity * (112 / 255), color: ColorRGBA(188, 53, 135) },
      { value: maxIntensity * (113 / 255), color: ColorRGBA(189, 55, 134) },
      { value: maxIntensity * (114 / 255), color: ColorRGBA(190, 56, 133) },
      { value: maxIntensity * (115 / 255), color: ColorRGBA(191, 57, 132) },
      { value: maxIntensity * (116 / 255), color: ColorRGBA(192, 58, 131) },
      { value: maxIntensity * (117 / 255), color: ColorRGBA(193, 59, 130) },
      { value: maxIntensity * (118 / 255), color: ColorRGBA(194, 60, 129) },
      { value: maxIntensity * (119 / 255), color: ColorRGBA(195, 61, 128) },
      { value: maxIntensity * (120 / 255), color: ColorRGBA(196, 62, 127) },
      { value: maxIntensity * (121 / 255), color: ColorRGBA(197, 64, 126) },
      { value: maxIntensity * (122 / 255), color: ColorRGBA(198, 65, 125) },
      { value: maxIntensity * (123 / 255), color: ColorRGBA(199, 66, 124) },
      { value: maxIntensity * (124 / 255), color: ColorRGBA(200, 67, 123) },
      { value: maxIntensity * (125 / 255), color: ColorRGBA(201, 68, 122) },
      { value: maxIntensity * (126 / 255), color: ColorRGBA(202, 69, 122) },
      { value: maxIntensity * (127 / 255), color: ColorRGBA(203, 70, 121) },
      { value: maxIntensity * (128 / 255), color: ColorRGBA(204, 71, 120) },
      { value: maxIntensity * (129 / 255), color: ColorRGBA(204, 73, 119) },
      { value: maxIntensity * (130 / 255), color: ColorRGBA(205, 74, 118) },
      { value: maxIntensity * (131 / 255), color: ColorRGBA(206, 75, 117) },
      { value: maxIntensity * (132 / 255), color: ColorRGBA(207, 76, 116) },
      { value: maxIntensity * (133 / 255), color: ColorRGBA(208, 77, 115) },
      { value: maxIntensity * (134 / 255), color: ColorRGBA(209, 78, 114) },
      { value: maxIntensity * (135 / 255), color: ColorRGBA(210, 79, 113) },
      { value: maxIntensity * (136 / 255), color: ColorRGBA(211, 81, 113) },
      { value: maxIntensity * (137 / 255), color: ColorRGBA(212, 82, 112) },
      { value: maxIntensity * (138 / 255), color: ColorRGBA(213, 83, 111) },
      { value: maxIntensity * (139 / 255), color: ColorRGBA(213, 84, 110) },
      { value: maxIntensity * (140 / 255), color: ColorRGBA(214, 85, 109) },
      { value: maxIntensity * (141 / 255), color: ColorRGBA(215, 86, 108) },
      { value: maxIntensity * (142 / 255), color: ColorRGBA(216, 87, 107) },
      { value: maxIntensity * (143 / 255), color: ColorRGBA(217, 88, 106) },
      { value: maxIntensity * (144 / 255), color: ColorRGBA(218, 90, 106) },
      { value: maxIntensity * (145 / 255), color: ColorRGBA(218, 91, 105) },
      { value: maxIntensity * (146 / 255), color: ColorRGBA(219, 92, 104) },
      { value: maxIntensity * (147 / 255), color: ColorRGBA(220, 93, 103) },
      { value: maxIntensity * (148 / 255), color: ColorRGBA(221, 94, 102) },
      { value: maxIntensity * (149 / 255), color: ColorRGBA(222, 95, 101) },
      { value: maxIntensity * (150 / 255), color: ColorRGBA(222, 97, 100) },
      { value: maxIntensity * (151 / 255), color: ColorRGBA(223, 98, 99) },
      { value: maxIntensity * (152 / 255), color: ColorRGBA(224, 99, 99) },
      { value: maxIntensity * (153 / 255), color: ColorRGBA(225, 100, 98) },
      { value: maxIntensity * (154 / 255), color: ColorRGBA(226, 101, 97) },
      { value: maxIntensity * (155 / 255), color: ColorRGBA(226, 102, 96) },
      { value: maxIntensity * (156 / 255), color: ColorRGBA(227, 104, 95) },
      { value: maxIntensity * (157 / 255), color: ColorRGBA(228, 105, 94) },
      { value: maxIntensity * (158 / 255), color: ColorRGBA(229, 106, 93) },
      { value: maxIntensity * (159 / 255), color: ColorRGBA(229, 107, 93) },
      { value: maxIntensity * (160 / 255), color: ColorRGBA(230, 108, 92) },
      { value: maxIntensity * (161 / 255), color: ColorRGBA(231, 110, 91) },
      { value: maxIntensity * (162 / 255), color: ColorRGBA(231, 111, 90) },
      { value: maxIntensity * (163 / 255), color: ColorRGBA(232, 112, 89) },
      { value: maxIntensity * (164 / 255), color: ColorRGBA(233, 113, 88) },
      { value: maxIntensity * (165 / 255), color: ColorRGBA(233, 114, 87) },
      { value: maxIntensity * (166 / 255), color: ColorRGBA(234, 116, 87) },
      { value: maxIntensity * (167 / 255), color: ColorRGBA(235, 117, 86) },
      { value: maxIntensity * (168 / 255), color: ColorRGBA(235, 118, 85) },
      { value: maxIntensity * (169 / 255), color: ColorRGBA(236, 119, 84) },
      { value: maxIntensity * (170 / 255), color: ColorRGBA(237, 121, 83) },
      { value: maxIntensity * (171 / 255), color: ColorRGBA(237, 122, 82) },
      { value: maxIntensity * (172 / 255), color: ColorRGBA(238, 123, 81) },
      { value: maxIntensity * (173 / 255), color: ColorRGBA(239, 124, 81) },
      { value: maxIntensity * (174 / 255), color: ColorRGBA(239, 126, 80) },
      { value: maxIntensity * (175 / 255), color: ColorRGBA(240, 127, 79) },
      { value: maxIntensity * (176 / 255), color: ColorRGBA(240, 128, 78) },
      { value: maxIntensity * (177 / 255), color: ColorRGBA(241, 129, 77) },
      { value: maxIntensity * (178 / 255), color: ColorRGBA(241, 131, 76) },
      { value: maxIntensity * (179 / 255), color: ColorRGBA(242, 132, 75) },
      { value: maxIntensity * (180 / 255), color: ColorRGBA(243, 133, 75) },
      { value: maxIntensity * (181 / 255), color: ColorRGBA(243, 135, 74) },
      { value: maxIntensity * (182 / 255), color: ColorRGBA(244, 136, 73) },
      { value: maxIntensity * (183 / 255), color: ColorRGBA(244, 137, 72) },
      { value: maxIntensity * (184 / 255), color: ColorRGBA(245, 139, 71) },
      { value: maxIntensity * (185 / 255), color: ColorRGBA(245, 140, 70) },
      { value: maxIntensity * (186 / 255), color: ColorRGBA(246, 141, 69) },
      { value: maxIntensity * (187 / 255), color: ColorRGBA(246, 143, 68) },
      { value: maxIntensity * (188 / 255), color: ColorRGBA(247, 144, 68) },
      { value: maxIntensity * (189 / 255), color: ColorRGBA(247, 145, 67) },
      { value: maxIntensity * (190 / 255), color: ColorRGBA(247, 147, 66) },
      { value: maxIntensity * (191 / 255), color: ColorRGBA(248, 148, 65) },
      { value: maxIntensity * (192 / 255), color: ColorRGBA(248, 149, 64) },
      { value: maxIntensity * (193 / 255), color: ColorRGBA(249, 151, 63) },
      { value: maxIntensity * (194 / 255), color: ColorRGBA(249, 152, 62) },
      { value: maxIntensity * (195 / 255), color: ColorRGBA(249, 154, 62) },
      { value: maxIntensity * (196 / 255), color: ColorRGBA(250, 155, 61) },
      { value: maxIntensity * (197 / 255), color: ColorRGBA(250, 156, 60) },
      { value: maxIntensity * (198 / 255), color: ColorRGBA(250, 158, 59) },
      { value: maxIntensity * (199 / 255), color: ColorRGBA(251, 159, 58) },
      { value: maxIntensity * (200 / 255), color: ColorRGBA(251, 161, 57) },
      { value: maxIntensity * (201 / 255), color: ColorRGBA(251, 162, 56) },
      { value: maxIntensity * (202 / 255), color: ColorRGBA(252, 163, 56) },
      { value: maxIntensity * (203 / 255), color: ColorRGBA(252, 165, 55) },
      { value: maxIntensity * (204 / 255), color: ColorRGBA(252, 166, 54) },
      { value: maxIntensity * (205 / 255), color: ColorRGBA(252, 168, 53) },
      { value: maxIntensity * (206 / 255), color: ColorRGBA(252, 169, 52) },
      { value: maxIntensity * (207 / 255), color: ColorRGBA(253, 171, 51) },
      { value: maxIntensity * (208 / 255), color: ColorRGBA(253, 172, 51) },
      { value: maxIntensity * (209 / 255), color: ColorRGBA(253, 174, 50) },
      { value: maxIntensity * (210 / 255), color: ColorRGBA(253, 175, 49) },
      { value: maxIntensity * (211 / 255), color: ColorRGBA(253, 177, 48) },
      { value: maxIntensity * (212 / 255), color: ColorRGBA(253, 178, 47) },
      { value: maxIntensity * (213 / 255), color: ColorRGBA(253, 180, 47) },
      { value: maxIntensity * (214 / 255), color: ColorRGBA(253, 181, 46) },
      { value: maxIntensity * (215 / 255), color: ColorRGBA(254, 183, 45) },
      { value: maxIntensity * (216 / 255), color: ColorRGBA(254, 184, 44) },
      { value: maxIntensity * (217 / 255), color: ColorRGBA(254, 186, 44) },
      { value: maxIntensity * (218 / 255), color: ColorRGBA(254, 187, 43) },
      { value: maxIntensity * (219 / 255), color: ColorRGBA(254, 189, 42) },
      { value: maxIntensity * (220 / 255), color: ColorRGBA(254, 190, 42) },
      { value: maxIntensity * (221 / 255), color: ColorRGBA(254, 192, 41) },
      { value: maxIntensity * (222 / 255), color: ColorRGBA(253, 194, 41) },
      { value: maxIntensity * (223 / 255), color: ColorRGBA(253, 195, 40) },
      { value: maxIntensity * (224 / 255), color: ColorRGBA(253, 197, 39) },
      { value: maxIntensity * (225 / 255), color: ColorRGBA(253, 198, 39) },
      { value: maxIntensity * (226 / 255), color: ColorRGBA(253, 200, 39) },
      { value: maxIntensity * (227 / 255), color: ColorRGBA(253, 202, 38) },
      { value: maxIntensity * (228 / 255), color: ColorRGBA(253, 203, 38) },
      { value: maxIntensity * (229 / 255), color: ColorRGBA(252, 205, 37) },
      { value: maxIntensity * (230 / 255), color: ColorRGBA(252, 206, 37) },
      { value: maxIntensity * (231 / 255), color: ColorRGBA(252, 208, 37) },
      { value: maxIntensity * (232 / 255), color: ColorRGBA(252, 210, 37) },
      { value: maxIntensity * (233 / 255), color: ColorRGBA(251, 211, 36) },
      { value: maxIntensity * (234 / 255), color: ColorRGBA(251, 213, 36) },
      { value: maxIntensity * (235 / 255), color: ColorRGBA(251, 215, 36) },
      { value: maxIntensity * (236 / 255), color: ColorRGBA(250, 216, 36) },
      { value: maxIntensity * (237 / 255), color: ColorRGBA(250, 218, 36) },
      { value: maxIntensity * (238 / 255), color: ColorRGBA(249, 220, 36) },
      { value: maxIntensity * (239 / 255), color: ColorRGBA(249, 221, 37) },
      { value: maxIntensity * (240 / 255), color: ColorRGBA(248, 223, 37) },
      { value: maxIntensity * (241 / 255), color: ColorRGBA(248, 225, 37) },
      { value: maxIntensity * (242 / 255), color: ColorRGBA(247, 226, 37) },
      { value: maxIntensity * (243 / 255), color: ColorRGBA(247, 228, 37) },
      { value: maxIntensity * (244 / 255), color: ColorRGBA(246, 230, 38) },
      { value: maxIntensity * (245 / 255), color: ColorRGBA(246, 232, 38) },
      { value: maxIntensity * (246 / 255), color: ColorRGBA(245, 233, 38) },
      { value: maxIntensity * (247 / 255), color: ColorRGBA(245, 235, 39) },
      { value: maxIntensity * (248 / 255), color: ColorRGBA(244, 237, 39) },
      { value: maxIntensity * (249 / 255), color: ColorRGBA(243, 238, 39) },
      { value: maxIntensity * (250 / 255), color: ColorRGBA(243, 240, 39) },
      { value: maxIntensity * (251 / 255), color: ColorRGBA(242, 242, 39) },
      { value: maxIntensity * (252 / 255), color: ColorRGBA(241, 244, 38) },
      { value: maxIntensity * (253 / 255), color: ColorRGBA(241, 245, 37) },
      { value: maxIntensity * (254 / 255), color: ColorRGBA(240, 247, 36) },
      { value: maxIntensity * (255 / 255), color: ColorRGBA(240, 249, 33) }
    ],
    interpolate: true
  });

/**
 * Viridis color palette from Matplotlib
 * @param {number} maxIntensity Maximum intensity in plot
 */
export const viridisPalette = (maxIntensity: number) =>
  new LUT({
    steps: [
      { value: maxIntensity * (0 / 255), color: ColorRGBA(68, 1, 84) },
      { value: maxIntensity * (1 / 255), color: ColorRGBA(68, 2, 86) },
      { value: maxIntensity * (2 / 255), color: ColorRGBA(69, 4, 87) },
      { value: maxIntensity * (3 / 255), color: ColorRGBA(69, 5, 89) },
      { value: maxIntensity * (4 / 255), color: ColorRGBA(70, 7, 90) },
      { value: maxIntensity * (5 / 255), color: ColorRGBA(70, 8, 92) },
      { value: maxIntensity * (6 / 255), color: ColorRGBA(70, 10, 93) },
      { value: maxIntensity * (7 / 255), color: ColorRGBA(70, 11, 94) },
      { value: maxIntensity * (8 / 255), color: ColorRGBA(71, 13, 96) },
      { value: maxIntensity * (9 / 255), color: ColorRGBA(71, 14, 97) },
      { value: maxIntensity * (10 / 255), color: ColorRGBA(71, 16, 99) },
      { value: maxIntensity * (11 / 255), color: ColorRGBA(71, 17, 100) },
      { value: maxIntensity * (12 / 255), color: ColorRGBA(71, 19, 101) },
      { value: maxIntensity * (13 / 255), color: ColorRGBA(72, 20, 103) },
      { value: maxIntensity * (14 / 255), color: ColorRGBA(72, 22, 104) },
      { value: maxIntensity * (15 / 255), color: ColorRGBA(72, 23, 105) },
      { value: maxIntensity * (16 / 255), color: ColorRGBA(72, 24, 106) },
      { value: maxIntensity * (17 / 255), color: ColorRGBA(72, 26, 108) },
      { value: maxIntensity * (18 / 255), color: ColorRGBA(72, 27, 109) },
      { value: maxIntensity * (19 / 255), color: ColorRGBA(72, 28, 110) },
      { value: maxIntensity * (20 / 255), color: ColorRGBA(72, 29, 111) },
      { value: maxIntensity * (21 / 255), color: ColorRGBA(72, 31, 112) },
      { value: maxIntensity * (22 / 255), color: ColorRGBA(72, 32, 113) },
      { value: maxIntensity * (23 / 255), color: ColorRGBA(72, 33, 115) },
      { value: maxIntensity * (24 / 255), color: ColorRGBA(72, 35, 116) },
      { value: maxIntensity * (25 / 255), color: ColorRGBA(72, 36, 117) },
      { value: maxIntensity * (26 / 255), color: ColorRGBA(72, 37, 118) },
      { value: maxIntensity * (27 / 255), color: ColorRGBA(72, 38, 119) },
      { value: maxIntensity * (28 / 255), color: ColorRGBA(72, 40, 120) },
      { value: maxIntensity * (29 / 255), color: ColorRGBA(72, 41, 121) },
      { value: maxIntensity * (30 / 255), color: ColorRGBA(71, 42, 122) },
      { value: maxIntensity * (31 / 255), color: ColorRGBA(71, 44, 122) },
      { value: maxIntensity * (32 / 255), color: ColorRGBA(71, 45, 123) },
      { value: maxIntensity * (33 / 255), color: ColorRGBA(71, 46, 124) },
      { value: maxIntensity * (34 / 255), color: ColorRGBA(71, 47, 125) },
      { value: maxIntensity * (35 / 255), color: ColorRGBA(70, 48, 126) },
      { value: maxIntensity * (36 / 255), color: ColorRGBA(70, 50, 126) },
      { value: maxIntensity * (37 / 255), color: ColorRGBA(70, 51, 127) },
      { value: maxIntensity * (38 / 255), color: ColorRGBA(70, 52, 128) },
      { value: maxIntensity * (39 / 255), color: ColorRGBA(69, 53, 129) },
      { value: maxIntensity * (40 / 255), color: ColorRGBA(69, 55, 129) },
      { value: maxIntensity * (41 / 255), color: ColorRGBA(69, 56, 130) },
      { value: maxIntensity * (42 / 255), color: ColorRGBA(68, 57, 131) },
      { value: maxIntensity * (43 / 255), color: ColorRGBA(68, 58, 131) },
      { value: maxIntensity * (44 / 255), color: ColorRGBA(68, 59, 132) },
      { value: maxIntensity * (45 / 255), color: ColorRGBA(67, 61, 132) },
      { value: maxIntensity * (46 / 255), color: ColorRGBA(67, 62, 133) },
      { value: maxIntensity * (47 / 255), color: ColorRGBA(66, 63, 133) },
      { value: maxIntensity * (48 / 255), color: ColorRGBA(66, 64, 134) },
      { value: maxIntensity * (49 / 255), color: ColorRGBA(66, 65, 134) },
      { value: maxIntensity * (50 / 255), color: ColorRGBA(65, 66, 135) },
      { value: maxIntensity * (51 / 255), color: ColorRGBA(65, 68, 135) },
      { value: maxIntensity * (52 / 255), color: ColorRGBA(64, 69, 136) },
      { value: maxIntensity * (53 / 255), color: ColorRGBA(64, 70, 136) },
      { value: maxIntensity * (54 / 255), color: ColorRGBA(63, 71, 136) },
      { value: maxIntensity * (55 / 255), color: ColorRGBA(63, 72, 137) },
      { value: maxIntensity * (56 / 255), color: ColorRGBA(62, 73, 137) },
      { value: maxIntensity * (57 / 255), color: ColorRGBA(62, 74, 137) },
      { value: maxIntensity * (58 / 255), color: ColorRGBA(62, 76, 138) },
      { value: maxIntensity * (59 / 255), color: ColorRGBA(61, 77, 138) },
      { value: maxIntensity * (60 / 255), color: ColorRGBA(61, 78, 138) },
      { value: maxIntensity * (61 / 255), color: ColorRGBA(60, 79, 138) },
      { value: maxIntensity * (62 / 255), color: ColorRGBA(60, 80, 139) },
      { value: maxIntensity * (63 / 255), color: ColorRGBA(59, 81, 139) },
      { value: maxIntensity * (64 / 255), color: ColorRGBA(59, 82, 139) },
      { value: maxIntensity * (65 / 255), color: ColorRGBA(58, 83, 139) },
      { value: maxIntensity * (66 / 255), color: ColorRGBA(58, 84, 140) },
      { value: maxIntensity * (67 / 255), color: ColorRGBA(57, 85, 140) },
      { value: maxIntensity * (68 / 255), color: ColorRGBA(57, 86, 140) },
      { value: maxIntensity * (69 / 255), color: ColorRGBA(56, 88, 140) },
      { value: maxIntensity * (70 / 255), color: ColorRGBA(56, 89, 140) },
      { value: maxIntensity * (71 / 255), color: ColorRGBA(55, 90, 140) },
      { value: maxIntensity * (72 / 255), color: ColorRGBA(55, 91, 141) },
      { value: maxIntensity * (73 / 255), color: ColorRGBA(54, 92, 141) },
      { value: maxIntensity * (74 / 255), color: ColorRGBA(54, 93, 141) },
      { value: maxIntensity * (75 / 255), color: ColorRGBA(53, 94, 141) },
      { value: maxIntensity * (76 / 255), color: ColorRGBA(53, 95, 141) },
      { value: maxIntensity * (77 / 255), color: ColorRGBA(52, 96, 141) },
      { value: maxIntensity * (78 / 255), color: ColorRGBA(52, 97, 141) },
      { value: maxIntensity * (79 / 255), color: ColorRGBA(51, 98, 141) },
      { value: maxIntensity * (80 / 255), color: ColorRGBA(51, 99, 141) },
      { value: maxIntensity * (81 / 255), color: ColorRGBA(50, 100, 142) },
      { value: maxIntensity * (82 / 255), color: ColorRGBA(50, 101, 142) },
      { value: maxIntensity * (83 / 255), color: ColorRGBA(49, 102, 142) },
      { value: maxIntensity * (84 / 255), color: ColorRGBA(49, 103, 142) },
      { value: maxIntensity * (85 / 255), color: ColorRGBA(49, 104, 142) },
      { value: maxIntensity * (86 / 255), color: ColorRGBA(48, 105, 142) },
      { value: maxIntensity * (87 / 255), color: ColorRGBA(48, 106, 142) },
      { value: maxIntensity * (88 / 255), color: ColorRGBA(47, 107, 142) },
      { value: maxIntensity * (89 / 255), color: ColorRGBA(47, 108, 142) },
      { value: maxIntensity * (90 / 255), color: ColorRGBA(46, 109, 142) },
      { value: maxIntensity * (91 / 255), color: ColorRGBA(46, 110, 142) },
      { value: maxIntensity * (92 / 255), color: ColorRGBA(46, 111, 142) },
      { value: maxIntensity * (93 / 255), color: ColorRGBA(45, 112, 142) },
      { value: maxIntensity * (94 / 255), color: ColorRGBA(45, 113, 142) },
      { value: maxIntensity * (95 / 255), color: ColorRGBA(44, 113, 142) },
      { value: maxIntensity * (96 / 255), color: ColorRGBA(44, 114, 142) },
      { value: maxIntensity * (97 / 255), color: ColorRGBA(44, 115, 142) },
      { value: maxIntensity * (98 / 255), color: ColorRGBA(43, 116, 142) },
      { value: maxIntensity * (99 / 255), color: ColorRGBA(43, 117, 142) },
      { value: maxIntensity * (100 / 255), color: ColorRGBA(42, 118, 142) },
      { value: maxIntensity * (101 / 255), color: ColorRGBA(42, 119, 142) },
      { value: maxIntensity * (102 / 255), color: ColorRGBA(42, 120, 142) },
      { value: maxIntensity * (103 / 255), color: ColorRGBA(41, 121, 142) },
      { value: maxIntensity * (104 / 255), color: ColorRGBA(41, 122, 142) },
      { value: maxIntensity * (105 / 255), color: ColorRGBA(41, 123, 142) },
      { value: maxIntensity * (106 / 255), color: ColorRGBA(40, 124, 142) },
      { value: maxIntensity * (107 / 255), color: ColorRGBA(40, 125, 142) },
      { value: maxIntensity * (108 / 255), color: ColorRGBA(39, 126, 142) },
      { value: maxIntensity * (109 / 255), color: ColorRGBA(39, 127, 142) },
      { value: maxIntensity * (110 / 255), color: ColorRGBA(39, 128, 142) },
      { value: maxIntensity * (111 / 255), color: ColorRGBA(38, 129, 142) },
      { value: maxIntensity * (112 / 255), color: ColorRGBA(38, 130, 142) },
      { value: maxIntensity * (113 / 255), color: ColorRGBA(38, 130, 142) },
      { value: maxIntensity * (114 / 255), color: ColorRGBA(37, 131, 142) },
      { value: maxIntensity * (115 / 255), color: ColorRGBA(37, 132, 142) },
      { value: maxIntensity * (116 / 255), color: ColorRGBA(37, 133, 142) },
      { value: maxIntensity * (117 / 255), color: ColorRGBA(36, 134, 142) },
      { value: maxIntensity * (118 / 255), color: ColorRGBA(36, 135, 142) },
      { value: maxIntensity * (119 / 255), color: ColorRGBA(35, 136, 142) },
      { value: maxIntensity * (120 / 255), color: ColorRGBA(35, 137, 142) },
      { value: maxIntensity * (121 / 255), color: ColorRGBA(35, 138, 141) },
      { value: maxIntensity * (122 / 255), color: ColorRGBA(34, 139, 141) },
      { value: maxIntensity * (123 / 255), color: ColorRGBA(34, 140, 141) },
      { value: maxIntensity * (124 / 255), color: ColorRGBA(34, 141, 141) },
      { value: maxIntensity * (125 / 255), color: ColorRGBA(33, 142, 141) },
      { value: maxIntensity * (126 / 255), color: ColorRGBA(33, 143, 141) },
      { value: maxIntensity * (127 / 255), color: ColorRGBA(33, 144, 141) },
      { value: maxIntensity * (128 / 255), color: ColorRGBA(33, 145, 140) },
      { value: maxIntensity * (129 / 255), color: ColorRGBA(32, 146, 140) },
      { value: maxIntensity * (130 / 255), color: ColorRGBA(32, 146, 140) },
      { value: maxIntensity * (131 / 255), color: ColorRGBA(32, 147, 140) },
      { value: maxIntensity * (132 / 255), color: ColorRGBA(31, 148, 140) },
      { value: maxIntensity * (133 / 255), color: ColorRGBA(31, 149, 139) },
      { value: maxIntensity * (134 / 255), color: ColorRGBA(31, 150, 139) },
      { value: maxIntensity * (135 / 255), color: ColorRGBA(31, 151, 139) },
      { value: maxIntensity * (136 / 255), color: ColorRGBA(31, 152, 139) },
      { value: maxIntensity * (137 / 255), color: ColorRGBA(31, 153, 138) },
      { value: maxIntensity * (138 / 255), color: ColorRGBA(31, 154, 138) },
      { value: maxIntensity * (139 / 255), color: ColorRGBA(30, 155, 138) },
      { value: maxIntensity * (140 / 255), color: ColorRGBA(30, 156, 137) },
      { value: maxIntensity * (141 / 255), color: ColorRGBA(30, 157, 137) },
      { value: maxIntensity * (142 / 255), color: ColorRGBA(31, 158, 137) },
      { value: maxIntensity * (143 / 255), color: ColorRGBA(31, 159, 136) },
      { value: maxIntensity * (144 / 255), color: ColorRGBA(31, 160, 136) },
      { value: maxIntensity * (145 / 255), color: ColorRGBA(31, 161, 136) },
      { value: maxIntensity * (146 / 255), color: ColorRGBA(31, 161, 135) },
      { value: maxIntensity * (147 / 255), color: ColorRGBA(31, 162, 135) },
      { value: maxIntensity * (148 / 255), color: ColorRGBA(32, 163, 134) },
      { value: maxIntensity * (149 / 255), color: ColorRGBA(32, 164, 134) },
      { value: maxIntensity * (150 / 255), color: ColorRGBA(33, 165, 133) },
      { value: maxIntensity * (151 / 255), color: ColorRGBA(33, 166, 133) },
      { value: maxIntensity * (152 / 255), color: ColorRGBA(34, 167, 133) },
      { value: maxIntensity * (153 / 255), color: ColorRGBA(34, 168, 132) },
      { value: maxIntensity * (154 / 255), color: ColorRGBA(35, 169, 131) },
      { value: maxIntensity * (155 / 255), color: ColorRGBA(36, 170, 131) },
      { value: maxIntensity * (156 / 255), color: ColorRGBA(37, 171, 130) },
      { value: maxIntensity * (157 / 255), color: ColorRGBA(37, 172, 130) },
      { value: maxIntensity * (158 / 255), color: ColorRGBA(38, 173, 129) },
      { value: maxIntensity * (159 / 255), color: ColorRGBA(39, 173, 129) },
      { value: maxIntensity * (160 / 255), color: ColorRGBA(40, 174, 128) },
      { value: maxIntensity * (161 / 255), color: ColorRGBA(41, 175, 127) },
      { value: maxIntensity * (162 / 255), color: ColorRGBA(42, 176, 127) },
      { value: maxIntensity * (163 / 255), color: ColorRGBA(44, 177, 126) },
      { value: maxIntensity * (164 / 255), color: ColorRGBA(45, 178, 125) },
      { value: maxIntensity * (165 / 255), color: ColorRGBA(46, 179, 124) },
      { value: maxIntensity * (166 / 255), color: ColorRGBA(47, 180, 124) },
      { value: maxIntensity * (167 / 255), color: ColorRGBA(49, 181, 123) },
      { value: maxIntensity * (168 / 255), color: ColorRGBA(50, 182, 122) },
      { value: maxIntensity * (169 / 255), color: ColorRGBA(52, 182, 121) },
      { value: maxIntensity * (170 / 255), color: ColorRGBA(53, 183, 121) },
      { value: maxIntensity * (171 / 255), color: ColorRGBA(55, 184, 120) },
      { value: maxIntensity * (172 / 255), color: ColorRGBA(56, 185, 119) },
      { value: maxIntensity * (173 / 255), color: ColorRGBA(58, 186, 118) },
      { value: maxIntensity * (174 / 255), color: ColorRGBA(59, 187, 117) },
      { value: maxIntensity * (175 / 255), color: ColorRGBA(61, 188, 116) },
      { value: maxIntensity * (176 / 255), color: ColorRGBA(63, 188, 115) },
      { value: maxIntensity * (177 / 255), color: ColorRGBA(64, 189, 114) },
      { value: maxIntensity * (178 / 255), color: ColorRGBA(66, 190, 113) },
      { value: maxIntensity * (179 / 255), color: ColorRGBA(68, 191, 112) },
      { value: maxIntensity * (180 / 255), color: ColorRGBA(70, 192, 111) },
      { value: maxIntensity * (181 / 255), color: ColorRGBA(72, 193, 110) },
      { value: maxIntensity * (182 / 255), color: ColorRGBA(74, 193, 109) },
      { value: maxIntensity * (183 / 255), color: ColorRGBA(76, 194, 108) },
      { value: maxIntensity * (184 / 255), color: ColorRGBA(78, 195, 107) },
      { value: maxIntensity * (185 / 255), color: ColorRGBA(80, 196, 106) },
      { value: maxIntensity * (186 / 255), color: ColorRGBA(82, 197, 105) },
      { value: maxIntensity * (187 / 255), color: ColorRGBA(84, 197, 104) },
      { value: maxIntensity * (188 / 255), color: ColorRGBA(86, 198, 103) },
      { value: maxIntensity * (189 / 255), color: ColorRGBA(88, 199, 101) },
      { value: maxIntensity * (190 / 255), color: ColorRGBA(90, 200, 100) },
      { value: maxIntensity * (191 / 255), color: ColorRGBA(92, 200, 99) },
      { value: maxIntensity * (192 / 255), color: ColorRGBA(94, 201, 98) },
      { value: maxIntensity * (193 / 255), color: ColorRGBA(96, 202, 96) },
      { value: maxIntensity * (194 / 255), color: ColorRGBA(99, 203, 95) },
      { value: maxIntensity * (195 / 255), color: ColorRGBA(101, 203, 94) },
      { value: maxIntensity * (196 / 255), color: ColorRGBA(103, 204, 92) },
      { value: maxIntensity * (197 / 255), color: ColorRGBA(105, 205, 91) },
      { value: maxIntensity * (198 / 255), color: ColorRGBA(108, 205, 90) },
      { value: maxIntensity * (199 / 255), color: ColorRGBA(110, 206, 88) },
      { value: maxIntensity * (200 / 255), color: ColorRGBA(112, 207, 87) },
      { value: maxIntensity * (201 / 255), color: ColorRGBA(115, 208, 86) },
      { value: maxIntensity * (202 / 255), color: ColorRGBA(117, 208, 84) },
      { value: maxIntensity * (203 / 255), color: ColorRGBA(119, 209, 83) },
      { value: maxIntensity * (204 / 255), color: ColorRGBA(122, 209, 81) },
      { value: maxIntensity * (205 / 255), color: ColorRGBA(124, 210, 80) },
      { value: maxIntensity * (206 / 255), color: ColorRGBA(127, 211, 78) },
      { value: maxIntensity * (207 / 255), color: ColorRGBA(129, 211, 77) },
      { value: maxIntensity * (208 / 255), color: ColorRGBA(132, 212, 75) },
      { value: maxIntensity * (209 / 255), color: ColorRGBA(134, 213, 73) },
      { value: maxIntensity * (210 / 255), color: ColorRGBA(137, 213, 72) },
      { value: maxIntensity * (211 / 255), color: ColorRGBA(139, 214, 70) },
      { value: maxIntensity * (212 / 255), color: ColorRGBA(142, 214, 69) },
      { value: maxIntensity * (213 / 255), color: ColorRGBA(144, 215, 67) },
      { value: maxIntensity * (214 / 255), color: ColorRGBA(147, 215, 65) },
      { value: maxIntensity * (215 / 255), color: ColorRGBA(149, 216, 64) },
      { value: maxIntensity * (216 / 255), color: ColorRGBA(152, 216, 62) },
      { value: maxIntensity * (217 / 255), color: ColorRGBA(155, 217, 60) },
      { value: maxIntensity * (218 / 255), color: ColorRGBA(157, 217, 59) },
      { value: maxIntensity * (219 / 255), color: ColorRGBA(160, 218, 57) },
      { value: maxIntensity * (220 / 255), color: ColorRGBA(162, 218, 55) },
      { value: maxIntensity * (221 / 255), color: ColorRGBA(165, 219, 54) },
      { value: maxIntensity * (222 / 255), color: ColorRGBA(168, 219, 52) },
      { value: maxIntensity * (223 / 255), color: ColorRGBA(170, 220, 50) },
      { value: maxIntensity * (224 / 255), color: ColorRGBA(173, 220, 48) },
      { value: maxIntensity * (225 / 255), color: ColorRGBA(176, 221, 47) },
      { value: maxIntensity * (226 / 255), color: ColorRGBA(178, 221, 45) },
      { value: maxIntensity * (227 / 255), color: ColorRGBA(181, 222, 43) },
      { value: maxIntensity * (228 / 255), color: ColorRGBA(184, 222, 41) },
      { value: maxIntensity * (229 / 255), color: ColorRGBA(186, 222, 40) },
      { value: maxIntensity * (230 / 255), color: ColorRGBA(189, 223, 38) },
      { value: maxIntensity * (231 / 255), color: ColorRGBA(192, 223, 37) },
      { value: maxIntensity * (232 / 255), color: ColorRGBA(194, 223, 35) },
      { value: maxIntensity * (233 / 255), color: ColorRGBA(197, 224, 33) },
      { value: maxIntensity * (234 / 255), color: ColorRGBA(200, 224, 32) },
      { value: maxIntensity * (235 / 255), color: ColorRGBA(202, 225, 31) },
      { value: maxIntensity * (236 / 255), color: ColorRGBA(205, 225, 29) },
      { value: maxIntensity * (237 / 255), color: ColorRGBA(208, 225, 28) },
      { value: maxIntensity * (238 / 255), color: ColorRGBA(210, 226, 27) },
      { value: maxIntensity * (239 / 255), color: ColorRGBA(213, 226, 26) },
      { value: maxIntensity * (240 / 255), color: ColorRGBA(216, 226, 25) },
      { value: maxIntensity * (241 / 255), color: ColorRGBA(218, 227, 25) },
      { value: maxIntensity * (242 / 255), color: ColorRGBA(221, 227, 24) },
      { value: maxIntensity * (243 / 255), color: ColorRGBA(223, 227, 24) },
      { value: maxIntensity * (244 / 255), color: ColorRGBA(226, 228, 24) },
      { value: maxIntensity * (245 / 255), color: ColorRGBA(229, 228, 25) },
      { value: maxIntensity * (246 / 255), color: ColorRGBA(231, 228, 25) },
      { value: maxIntensity * (247 / 255), color: ColorRGBA(234, 229, 26) },
      { value: maxIntensity * (248 / 255), color: ColorRGBA(236, 229, 27) },
      { value: maxIntensity * (249 / 255), color: ColorRGBA(239, 229, 28) },
      { value: maxIntensity * (250 / 255), color: ColorRGBA(241, 229, 29) },
      { value: maxIntensity * (251 / 255), color: ColorRGBA(244, 230, 30) },
      { value: maxIntensity * (252 / 255), color: ColorRGBA(246, 230, 32) },
      { value: maxIntensity * (253 / 255), color: ColorRGBA(248, 230, 33) },
      { value: maxIntensity * (254 / 255), color: ColorRGBA(251, 231, 35) },
      { value: maxIntensity * (255 / 255), color: ColorRGBA(253, 231, 37) }
    ],
    interpolate: true
  });
