import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonFacade } from '@vas/common';
import { FeatureFlagsFacade } from '@vas/feature-flags';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs/internal/Subscription';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/internal/operators/map';
import { startWith } from 'rxjs/internal/operators/startWith';
import { withLatestFrom } from 'rxjs/internal/operators/withLatestFrom';
import { AppFacade } from '../../+state/app.facade';
import { CloudAppNames } from '../../app.types';
import { ContentCollectionFacade } from '../../content-collection/+state/content-collection.facade';
import { CollectionDeclineShareComponent } from '../../content-collection/collection-decline-share/collection-decline-share.component';
import { CollectionDeleteComponent } from '../../content-collection/collection-delete/collection-delete.component';
import { CollectionEditComponent } from '../../content-collection/collection-edit/collection-edit.component';
import { ContentCollection } from '../../content-collection/content-collection.types';
import { DepotSearchFacade } from '../../depot-search/+state/depot-search.facade';
import { MeasurementsFacade } from '../../measurements/+state/measurements.facade';
import { RouterFacade } from '../../router/+state/router.facade';
import { UserFacade } from '../../user/+state/user.facade';
import { WorkspaceFacade } from '../../workspace/+state/workspace.facade';
import { TopMenuNavItem } from './top-menu.types';

@Component({
  selector: 'cloud-menu-top',
  templateUrl: './top-menu.component.html',
  styleUrls: ['top-menu.component.css']
})
export class TopMenuComponent implements OnInit, OnDestroy {
  PAKinsightName$: Observable<string>;
  showPAKinsightModal = false;

  entries: TopMenuNavItem[];

  // Hold all state subscriptions in a container, since a lot of subscriptions are currently held in memory during the component lifetime and need to be cleaned up eventually
  subscriptionContainer: Subscription[] = [];

  @ViewChild(CollectionDeleteComponent) private deleteDialog: CollectionDeleteComponent;
  @ViewChild(CollectionDeclineShareComponent) private declineDialog: CollectionDeclineShareComponent;
  @ViewChild(CollectionEditComponent) private editDialog: CollectionEditComponent;

  constructor(
    public appFacade: AppFacade,
    public workspaceFacade: WorkspaceFacade,
    public commonFacade: CommonFacade,
    private translate: TranslateService,
    public userFacade: UserFacade,
    public contentCollectionFacade: ContentCollectionFacade,
    public depotSearchFacade: DepotSearchFacade,
    public measurementsFacade: MeasurementsFacade,
    public featureFlagFacade: FeatureFlagsFacade,
    public cloudRouterFacade: RouterFacade
  ) {}

  ngOnInit() {
    const hasBothOrderAndDeviceApp$ = this.appFacade.getIsCloudAppAvailable$(CloudAppNames.ods_order).pipe(
      withLatestFrom(this.appFacade.getIsCloudAppAvailable$(CloudAppNames.device)),
      map(([hasOrderApp, hasDeviceApp]) => hasOrderApp && hasDeviceApp)
    );

    const shouldDisplayOrders$ = combineLatest([
      hasBothOrderAndDeviceApp$,
      this.featureFlagFacade.featureValue$('ORDERS')
    ]).pipe(
      map(([hasBothOrderAndDevice, shouldDisplayOrders]) => hasBothOrderAndDevice && shouldDisplayOrders),
      startWith(false)
    );

    const shouldDisplayDevices$ = combineLatest([
      hasBothOrderAndDeviceApp$,
      this.featureFlagFacade.featureValue$('DEVICES')
    ]).pipe(
      map(([hasBothOrderAndDevice, shouldDisplayDevices]) => hasBothOrderAndDevice && shouldDisplayDevices),
      startWith(false)
    );

    this.PAKinsightName$ = this.translate.onLangChange.pipe(
      startWith(true),
      map((_) => this.translate.instant('APP.APPS.PAK_INSIGHT'))
    );

    this.entries = [
      {
        label: 'Depot Search',
        icon: 'measurement_search',
        routerLink: '/depotsearch',
        queryParams$: this.cloudRouterFacade.queryParamsForRoute$('depotsearch'),
        id: 'DEPOTSEARCH',
        shouldDisplay$: of(true)
      },
      {
        label: 'Orders',
        icon: 'measurement_jobs',
        routerLink: '/orders/overview',
        queryParams$: this.cloudRouterFacade.queryParamsForRoute$('orders'),
        id: 'ORDERMANAGEMENT',
        shouldDisplay$: shouldDisplayOrders$
      },
      {
        label: 'Devices',
        icon: 'devices',
        routerLink: '/devices',
        id: 'DEVICES',
        shouldDisplay$: shouldDisplayDevices$
      }
    ];
    this.userFacade.getUserSelf();
    this.appFacade.setIsDepotAdmin();
    this.appFacade.setIsUserAdmin();

    const updateMenuLang = () => {
      for (const entry of this.entries) {
        const translationId = 'NAV.' + entry.id;
        this.translate.get(translationId).subscribe((res) => {
          const idx = this.entries.findIndex((item) => item.id === entry.id);
          this.entries[idx].label = res;
        });
      }
    };

    this.subscriptionContainer.push(this.appFacade.language$.subscribe(updateMenuLang));

    this.subscriptionContainer.push(
      this.contentCollectionFacade.pakCopySuccess$.subscribe((success) => {
        setTimeout(() => this.contentCollectionFacade.resetCopySuccess(false), 600);
      })
    );
  }

  editContentCollection(contentCollection: ContentCollection) {
    this.editDialog.selectedCollection = contentCollection;
    this.editDialog.setVisibility(true);
  }

  deleteContentCollection(contentCollection: ContentCollection) {
    this.deleteDialog.collectionToDelete = contentCollection;
    this.deleteDialog.visible = true;
  }

  declineShare(contentCollection: ContentCollection) {
    this.declineDialog.collectionToDecline = contentCollection;
    this.declineDialog.visible = true;
  }

  ngOnDestroy() {
    for (const sub of this.subscriptionContainer) {
      sub.unsubscribe();
    }
  }
}
