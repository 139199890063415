import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

export class CsrfInterceptor implements HttpInterceptor {
  constructor(public cookie: CookieService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const csrfToken = this.cookie.getAll()['csrftoken'];
    let req = request.clone();
    if (csrfToken) {
      req = request.clone({
        headers: request.headers.set('X-CSRFTOKEN', csrfToken)
      });
    }

    return next.handle(req);
  }
}
