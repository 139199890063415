import { createAction, props } from '@ngrx/store';
import { UIDimensions } from '../common.types';

export enum CommonActionTypes {
  SetEnvironment = '[Common] Set Environment',
  SetUIDimensions = '[App] Set UI Dimensions',
  MouseDeviceDetected = '[App] Mouse Device detected'
}

export const SetEnvironment = createAction(
  CommonActionTypes.SetEnvironment,
  props<{ appVersion: string; production: boolean }>()
);

export const SetUIDimensions = createAction(CommonActionTypes.SetUIDimensions, props<{ dims: UIDimensions }>());

export const MouseDeviceDetected = createAction(CommonActionTypes.MouseDeviceDetected);
