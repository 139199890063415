import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppFacade } from '../../+state/app.facade';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const menuModel = [
  { label: 'Depot Search', icon: 'fa fa-fw fa-search', routerLink: '/depotsearch', id: _('NAV.DEPOTSEARCH') },
  { label: 'Order Management', icon: 'fa fa-fw fa-book', routerLink: '/orders', id: _('NAV.ORDERMANAGEMENT') },
  { label: 'Devices', icon: 'fa fa-fw fa-hdd', routerLink: '/devices', id: _('NAV.DEVICES') },
  { label: 'Documentation', icon: 'fa fa-fw fa-question-circle', routerLink: '/docs', id: _('NAV.DOCUMENTATION') }
];

@Component({
  selector: 'cloud-megamenu',
  templateUrl: './app.megamenu.component.html',
  styleUrls: ['./app.megamenu.component.css']
})
export class AppMegamenuComponent {
  @Input() active = false;
  @Output() closeTrigger = new EventEmitter<boolean>();
  model = menuModel;
  constructor(public translate: TranslateService, public appFacade: AppFacade) {}
}
