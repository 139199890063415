import { Inject, Injectable } from '@angular/core';
import { JsonApi, Spec } from '@muellerbbm-vas/grivet';
import { AngularHttpContext } from '@root/libs/vas-angular-http-context/src';
import { Observable, forkJoin, from, map, mergeMap, of, switchMap, toArray } from 'rxjs';
import { ANGULAR_HTTP_CONTEXT } from '../../../../app.tokens';
import { CloudService } from '../../../../services/cloud.service';
import { EffectiveRole, RoleInfo, RoleRelation, RoleToUserRelation, UserRelation } from '../types/role.types';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  public users2rolesMap: { [id: string]: JsonApi.RelatedResource } = {};

  constructor(@Inject(ANGULAR_HTTP_CONTEXT) private context: AngularHttpContext, private cloudService: CloudService) {}

  async ensureRolesManagementAppStart() {
    return await this.cloudService.getAppStart('roles');
  }

  getRoles(): Observable<{ roles: RoleInfo[]; roleToUserRelations?: RoleToUserRelation[] }> {
    return this.cloudService.getAppStartObs('roles').pipe(
      switchMap((res) => {
        const baseUsersURL = new URL(res?.relationships['roles'].links?.related.url.href ?? 'https://example.com');
        baseUsersURL.searchParams.append('include', 'role2user');

        return from(JsonApi.Document.fromURL(baseUsersURL, this.context));
      }),
      mergeMap((res) => {
        return from(res.resources).pipe(
          toArray(),
          map((resArr) => ({
            roles: resArr.map((data) => this.createRole(data['rawData'])),
            roleToUserRelations: this.mapUsersToRolesRelations(res)
          }))
        );
      })
    );
  }

  getEffectiveRoles(): Observable<EffectiveRole[]> {
    return this.cloudService.getAppStartObs('roles').pipe(
      switchMap((res) => {
        const baseUsersURL = new URL(
          res?.relationships['effective_role'].links?.related.url.href ?? 'https://example.com'
        );

        return from(JsonApi.Document.fromURL(baseUsersURL, this.context));
      }),
      mergeMap((res) => {
        return from(res.resources).pipe(
          toArray(),
          map((resArr) => resArr.map((data) => this.createEffectiveRole(res, data)))
        );
      })
    );
  }

  deleteUserRoles(userId: string, roleIds: string[]): Observable<any> {
    return this.cloudService.getAppStartObs('roles').pipe(
      switchMap((res: JsonApi.Resource | null) => {
        return res ? from(res?.relatedResources['roles']) : of([]);
      }),
      switchMap((userToRoles) => {
        const rolesToDelete = userToRoles.filter((userToRole) => roleIds.includes(userToRole.id));

        const deleteObservables = rolesToDelete.map((role) => {
          const url = (role.rawData?.relationships?.['role2user']?.links?.['related'] + userId) as string | URL;

          return this.context.deleteDocument(new URL(url));
        });

        return forkJoin(deleteObservables);
      })
    );
  }

  addRolesToUser(userId: string, roleIds: string[]) {
    return this.cloudService.getAppStartObs('roles').pipe(
      switchMap((res: JsonApi.Resource | null) => (res ? from(res?.relatedResources['roles']) : of([]))),
      toArray(),
      switchMap(([roles]) => {
        const observables = roleIds.map((roleId) => {
          const roleToUpd = roles.find((role) => role.id === roleId);
          const url = roleToUpd?.rawData?.relationships?.['role2user']?.links?.related as string | URL;

          const data = {
            attributes: {},
            included: [],
            relationships: {
              user: {
                data: {
                  id: userId,
                  type: 'User'
                }
              }
            },
            type: 'User2Role'
          };

          return this.context.postDocument(new URL(url), { data });
        });

        return forkJoin(observables);
      })
    );
  }

  addRolesToGroup(groupId: string, roleIds: string[]): Observable<any> {
    return this.cloudService.getAppStartObs('roles').pipe(
      switchMap((res: JsonApi.Resource | null) => (res ? from(res?.relatedResources['roles']) : of([]))),
      toArray(),
      switchMap(([roles]) => {
        const observables = roleIds.map((roleId) => {
          const roleToUpd = roles.find((role) => role.id === roleId);
          const url = roleToUpd?.rawData?.relationships?.['role2group']?.links?.related as string | URL;

          const data = {
            relationships: {
              group: {
                data: {
                  id: groupId,
                  type: 'Group'
                }
              }
            },
            type: 'Group2Role'
          };

          return this.context.postDocument(new URL(url), { data });
        });

        return forkJoin(observables);
      })
    );
  }

  deleteGroupRoles(groupId: string, roleIds: string[]): Observable<any> {
    return this.cloudService.getAppStartObs('roles').pipe(
      switchMap((res: JsonApi.Resource | null) => {
        return res ? from(res?.relatedResources['roles']) : of([]);
      }),
      switchMap((userToRoles) => {
        const rolesToDelete = userToRoles.filter((userToRole) => roleIds.includes(userToRole.id));

        const deleteObservables = rolesToDelete.map((role) => {
          const url = (role.rawData?.relationships?.['role2group']?.links?.['related'] + groupId) as string | URL;

          return this.context.deleteDocument(new URL(url));
        });

        return forkJoin(deleteObservables);
      })
    );
  }

  private createRole(roleDoc: JsonApi.Resource['rawData']): RoleInfo {
    const attribs: Spec.AttributesObject | undefined = roleDoc.attributes;

    const relations = roleDoc?.relationships?.role2user?.data;
    const roleToUserRelations = Array.isArray(relations)
      ? (roleDoc?.relationships?.role2user?.data as Spec.ResourceIdentifierObject[]).map((relation) => relation.id)
      : [];

    const role: RoleInfo = {
      id: roleDoc.id,
      name: attribs?.['name'],
      isDefaultRole: attribs?.['is_default_role'],
      roleToUserRelations: roleToUserRelations
    };

    return role;
  }

  private mapUsersToRolesRelations(res): RoleToUserRelation[] | undefined {
    if (res.rawData?.included) {
      return res.rawData?.included?.map((data) => {
        if (data.relationships && data.relationships) {
          return {
            id: data.id,
            role: data.relationships.role.data as RoleRelation,
            user: data.relationships.user.data as UserRelation
          };
        }
      });
    }
  }

  private createEffectiveRole(res: JsonApi.Document, data: JsonApi.Resource): EffectiveRole {
    const userId = (data.rawData.relationships?.user?.data as Spec.ResourceIdentifierObject)?.id;
    const roleId = (data.rawData.relationships?.role?.data as Spec.ResourceIdentifierObject)?.id;

    const group2Roles = data.rawData.relationships?.granted_by_groups?.data as Spec.ResourceIdentifierObject[];
    const groupIds = group2Roles?.map(
      (group2Role) =>
        (res.includedResources?.Group2Role?.[group2Role.id].relationships?.group?.data as Spec.ResourceIdentifierObject)
          ?.id
    );

    const effectiveRole = {
      userId,
      roleId,
      groupIds
    };

    return effectiveRole;
  }
}
