<cloud-download-modal
  [showPAKinsightModal]="showPAKinsightModal"
  [PAKinsightName$]="PAKinsightName$"
></cloud-download-modal>

<clr-header
  id="header"
  class="header"
  [ngStyle]="{ 'background-color': featureFlagFacade.featureValue$('APPLICATION_PRIMARY_COLOR') | async }"
>
  <div class="header-container">
    <div class="header-nav-container">
      <div class="branding">
        <img id="logo" [src]="featureFlagFacade.featureValue$('APPLICATION_LOGO') | async" />
        <div id="tagline" *ngIf="featureFlagFacade.featureIsSet$('APPLICATION_LOGO_TAGLINE') | async">{{
          featureFlagFacade.featureValue$('APPLICATION_LOGO_TAGLINE') | async
        }}</div>
      </div>

      <div class="header-nav" [clr-nav-level]="1">
        <ng-container *ngFor="let entry of entries">
          <a
            *ngIf="entry.shouldDisplay$ | async"
            [routerLink]="entry.routerLink"
            [queryParams]="entry.queryParams$ | async"
            [queryParamsHandling]="null"
            routerLinkActive="active"
            class="nav-link nav-text"
            [id]="entry.id"
            [attr.data-cy]="'FULL_' + entry.id"
            >{{ entry.label }}</a
          >
          <a
            *ngIf="entry.shouldDisplay$ | async"
            [routerLink]="entry.routerLink"
            [queryParams]="entry.queryParams$ | async"
            [queryParamsHandling]="null"
            routerLinkActive="active"
            class="nav-link nav-icon"
            [id]="entry.id"
            [attr.data-cy]="'SMALL_' + entry.id"
            ><cloud-svg-icon [fileName]="entry.icon"></cloud-svg-icon
          ></a>
        </ng-container>
      </div>
    </div>

    <cloud-content-collection-dropdown
      *ngIf="(cloudRouterFacade.currentRootRoute$ | async) === 'depotsearch'"
      (deleteCC)="deleteContentCollection($event)"
      (declineCCShare)="declineShare($event)"
      (editCC)="editContentCollection($event)"
    ></cloud-content-collection-dropdown>

    <div class="header-actions">
      <cloud-debug-header *ngIf="featureFlagFacade.featureValue$('DEBUG_MODE') | async"></cloud-debug-header>
      <cloud-header-move-operations>
        <div>move it</div>
      </cloud-header-move-operations>
      <cloud-header-actions
        [PAKinsightName$]="PAKinsightName$"
        (showPAKinsightModal)="showPAKinsightModal = true"
      ></cloud-header-actions>
    </div>
  </div>
</clr-header>

<cloud-collection-create-with-items
  *ngIf="
    (contentCollectionFacade.activeDialog$ | async) === 'createCollectionWithItems' ||
    (contentCollectionFacade.activeDialog$ | async) === 'createCollection'
  "
></cloud-collection-create-with-items>

<cloud-collection-decline-share></cloud-collection-decline-share>

<cloud-collection-delete></cloud-collection-delete>

<cloud-collection-edit></cloud-collection-edit>

<cloud-measurements-move-modal *ngIf="measurementsFacade.showMoveModal$ | async"></cloud-measurements-move-modal>
