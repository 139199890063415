import { ProcessingParameter, ValueType } from './processing.types';
import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
const validate = require('validate.js');

@Directive({
  selector: '[cloudFormulaParameterValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: FormulaParameterValidatorDirective, multi: true }]
})
export class FormulaParameterValidatorDirective implements Validator {
  @Input('cloudFormulaParameterValidator') param: ProcessingParameter;
  constructor(public translate: TranslateService) {}

  validate(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value && !control.dirty && !control.touched) {
      return null;
    }

    const val = control.value;
    let validCount = 0;

    const errors: string[] = [];

    let errorRequiredType;
    this.translate.get('PROCESSING.REQUIRED_TYPE').subscribe((res) => (errorRequiredType = res));
    let errorChooseOneOf;
    this.translate.get('PROCESSING.CHOOSE_ONE_OF').subscribe((res) => (errorChooseOneOf = res));

    for (const vtype of this.param.value_types) {
      const myType: string = vtype.type;
      if (myType === 'Integer') {
        const containsOnlyDigits = val.match(/^[0-9]+$/) !== null;
        const parsedInt = parseInt(val, 10);
        const isValidInteger = containsOnlyDigits && validate.isInteger(parsedInt);

        if (isValidInteger) {
          validCount++;
        } else {
          errors.push(`${errorRequiredType}: ${vtype.type}`);
        }
      } else if (myType === 'Double') {
        const valFloat = parseFloat(val);
        const isValidInteger = validate.isNumber(valFloat);
        if (isValidInteger) {
          validCount++;
        } else {
          errors.push(`${errorRequiredType}: ${vtype.type}`);
        }
      } else if (myType === 'String') {
        validCount = this.validateConstraints(val, vtype, errorChooseOneOf, validCount, errors);
      }
    }

    if (validCount === 0) {
      const message = errors.reduce((acc, err) => acc + ', ' + err);
      return { isParameterValidationError: { message } };
    }

    return null;
  }

  private validateConstraints(
    value: any,
    vtype: ValueType,
    errorChooseOneOf: any,
    validCount: number,
    errors: string[]
  ) {
    if (vtype.constraints && vtype.constraints.length > 0) {
      if (vtype.constraints.some((constraint) => constraint.value === value)) {
        return validCount + 1;
      } else {
        const constraints = vtype.constraints.reduce((acc, item, idx) => {
          if (idx > 0) {
            return acc + ', ' + item.value;
          } else {
            return item.value;
          }
        }, '');
        errors.push(`${errorChooseOneOf}: ${constraints}`);
        return validCount;
      }
    } else {
      return validCount + 1;
    }
  }
}
