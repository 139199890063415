import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { AppFacade } from '../../+state/app.facade';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { RouterFacade } from '../+state/router.facade';
import { filter, map, switchMapTo, tap } from 'rxjs/operators';
import { NavigationFacade } from '../+state/navigation.facade';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'cloud-router-error',
  templateUrl: './router-error.component.html',
  styleUrls: ['./router-error.component.css']
})
export class RouterErrorComponent implements OnInit {
  constructor(
    public appFacade: AppFacade,
    public translate: TranslateService,
    public routerFacade: RouterFacade,
    public navigationFacade: NavigationFacade
  ) {}

  errorModalClosed$: BehaviorSubject<boolean> = new BehaviorSubject<true>(true);
  showErrorModal$: Observable<boolean>;
  errorTitle$: Observable<string>;
  errorID$: Observable<string>;

  ngOnInit() {
    const hasNavigationErrors$ = this.routerFacade.currentNavigationErrors$.pipe(
      map((errors) => !!errors),
      filter((hasErrors) => hasErrors)
    );
    this.showErrorModal$ = merge(hasNavigationErrors$, this.errorModalClosed$.pipe(map((closed) => !closed)));
    this.errorTitle$ = this.showErrorModal$.pipe(
      filter((show) => show),
      map(() => {
        const rngOneToFour = Math.floor(Math.random() * 4) + 1;
        return this.translate.instant(`APP.URLERR.RANDOMERRTITLES.${rngOneToFour}`);
      })
    );

    // NOTE: This is required for ngx-translate-extract, otherwise non-marked translation keys will be cleansed from the translation files
    _('APP.URLERR.RANDOMERRTITLES.1');
    _('APP.URLERR.RANDOMERRTITLES.2');
    _('APP.URLERR.RANDOMERRTITLES.3');
    _('APP.URLERR.RANDOMERRTITLES.4');

    this.errorID$ = this.routerFacade.currentNavigationErrors$.pipe(map((err) => err?.errorIdentifier ?? ''));
  }

  back() {
    this.navigationFacade.navigate({ queryParams: {}, backToRoot: true });
  }

  reload() {
    location.reload();
  }
}
