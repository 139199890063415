import { Directive } from '@angular/core';
import { RouterLink } from '@angular/router';

@Directive({
  // Disabling directive selector because we want to target a built-in Angular directive
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[routerLink]'
})
export class QueryParamsHandlingDirective {
  constructor(routerLink: RouterLink) {
    routerLink.queryParamsHandling = 'merge';
  }
}
