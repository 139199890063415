import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, RouterEvent } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

interface ContextLinks {
  [index: string]: {
    title: string;
    translationKey?: string;
    targetUrl: string;
  };
}

@Component({
  selector: 'cloud-context-help',
  templateUrl: './context-help.component.html',
  styleUrls: ['./context-help.component.css']
})
export class ContextHelpComponent implements OnInit {
  public currentRoute = '';
  public contextLinks: ContextLinks;
  constructor(public router: Router, private translate: TranslateService) {}

  ngOnInit() {
    this.contextLinks = contextLinks;
    for (const contextLink in this.contextLinks) {
      if (contextLink) {
        const key = this.contextLinks[contextLink].translationKey;
        if (key) {
          this.translate.get(key).subscribe((title) => (this.contextLinks[contextLink].title = title));
        }
      }
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        map((event) => (event as RouterEvent).url)
      )
      .subscribe((url) => (this.currentRoute = url));
  }
}

const contextLinks: ContextLinks = {
  '/docs': {
    title: '',
    translationKey: _('DOCS.CONTEXT.DOCS'),
    targetUrl: '/docs/enduser'
  },
  '/devices': {
    title: '',
    translationKey: _('DOCS.CONTEXT.DEVICES'),
    targetUrl: '/docs/admin'
  }
};
