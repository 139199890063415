import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DepotSearchFacade } from '../../+state/depot-search.facade';
import { TreeNode } from '../../../shared/types/aggregateTree.types';

@Component({
  selector: 'cloud-tree-node',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.css']
})
export class TreeNodeComponent {
  @Input() root: boolean = false;
  @Input() node: TreeNode;
  @Input() selected: boolean;
  @Input() title: string;
  @Output() nodeSelected: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();
  @Output() nodeExpanded: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();
  @Output() nodeCollapsed: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();

  constructor(public depotSearchFacade: DepotSearchFacade) {}
}
