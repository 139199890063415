import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { MeasurementsPartialState } from './measurements.reducer';
import { measurementsQuery } from './measurements.selectors';
import {
  ShowMeasurement,
  ShowDynamicComponentForSelectedEntry,
  RequestDownload,
  CancelDownloadRequest,
  ChangeAttributeButtonState,
  ChangeExtended,
  InitDefaultAttributeDisplayType,
  RequestMove,
  PrepareMove,
  ShowMeasurementMoveModal,
  RequestMoveDryRun,
  SetTargetPath
} from './measurements.actions';

import {
  dynamicComponentIdentifiers,
  MeasurementDownload,
  attributeDisplayType,
  MeasurementMove,
  IntermediateMeasuementDetailsBeforeMove,
  MeasurementPath
} from '../measurements.types';
import { SearchResultEntry } from '../../shared/types/search.types';

@Injectable()
export class MeasurementsFacade {
  constructor(private store: Store<MeasurementsPartialState>) {}

  measurements$ = this.store.pipe(select(measurementsQuery.getMeasurements));
  selectedMeasurementId$ = this.store.pipe(select(measurementsQuery.getSelectedId));
  selectedMeasurement$ = this.store.pipe(select(measurementsQuery.getSelectedMeasurement));

  activeDynamicComponentForSelectedMeasurement$ = this.store.pipe(
    select(measurementsQuery.getSelectedMeasurementActiveDynamicComponent)
  );

  // Move
  showMoveModal$ = this.store.pipe(select(measurementsQuery.getShowMoveModal));
  measurementsToMove$ = this.store.pipe(select(measurementsQuery.getMeasurementsToMove));
  targetPath$ = this.store.pipe(select(measurementsQuery.getTargetPath));
  intermediateMeasurements$ = this.store.pipe(select(measurementsQuery.getIntermediateMeasurements));
  dryRunResults$ = this.store.pipe(select(measurementsQuery.getDryRunResults));
  dryRunState$ = this.store.pipe(select(measurementsQuery.getDryRunState));
  movingMeasurements$ = this.store.pipe(select(measurementsQuery.getMovingMeasurements));
  gatheringPathState$ = this.store.pipe(select(measurementsQuery.getMovingStateGatheringPathState));

  // Download
  downloads$ = this.store.pipe(select(measurementsQuery.getDownloads));
  activeDownloads$ = this.store.pipe(select(measurementsQuery.getActiveDownloads));
  downloadsErrorCount$ = this.store.pipe(select(measurementsQuery.getDownloadsErrorCount));
  attributeDisplayType$ = this.store.pipe(select(measurementsQuery.getDisplayedAttributesState));

  // Parametrized selectors
  componentIsSelectedDynamicComponent$ = (dc: dynamicComponentIdentifiers) =>
    this.store.pipe(select(measurementsQuery.getComponentIsSelectedDynamicComponent(dc)));

  selectedEntryAttribIsLoading$ = (attrib: string) =>
    this.store.pipe(select(measurementsQuery.getSelectedEntryAttribIsLoading(attrib)));

  selectedEntryAttribCount$ = (attrib: string) =>
    this.store.pipe(select(measurementsQuery.getSelectedEntryAttribCount(attrib)));

  showMeasurement(measurement: SearchResultEntry) {
    this.store.dispatch(ShowMeasurement({ measurement: measurement }));
  }

  showDynamicComponentForSelectedEntry(dynamicComponentIdentifier: dynamicComponentIdentifiers) {
    this.store.dispatch(ShowDynamicComponentForSelectedEntry({ dynamicComponentId: dynamicComponentIdentifier }));
  }

  requestDownload(downloadRequest: MeasurementDownload) {
    this.store.dispatch(RequestDownload({ downloadRequest: downloadRequest }));
  }

  cancelDownloadRequest(downloadRequest: MeasurementDownload) {
    this.store.dispatch(CancelDownloadRequest({ downloadRequest: downloadRequest }));
  }

  changeAttributeButtonState(shownAttribs: attributeDisplayType) {
    this.store.dispatch(ChangeAttributeButtonState({ shownAttribs: shownAttribs }));
  }

  changeExtended(extended: boolean, index: number, dynamicComponentIdentifier: dynamicComponentIdentifiers) {
    this.store.dispatch(ChangeExtended({ extended: extended, index: index, identifier: dynamicComponentIdentifier }));
  }

  initializeAttributeDisplayType() {
    this.store.dispatch(InitDefaultAttributeDisplayType());
  }

  // Move

  showMoveModal(show: boolean) {
    this.store.dispatch(ShowMeasurementMoveModal({ show: show }));
  }

  prepareMove(searchResults: SearchResultEntry[]) {
    this.store.dispatch(PrepareMove({ searchResults }));
  }

  requestMove(moveRequest: MeasurementMove) {
    this.store.dispatch(RequestMove({ moveRequest: moveRequest }));
  }

  setTargetPath(targetPath: MeasurementPath) {
    this.store.dispatch(SetTargetPath({ targetPath }));
  }

  requestDryRun(intermediateMeasurementDetails: IntermediateMeasuementDetailsBeforeMove[]) {
    this.store.dispatch(RequestMoveDryRun({ intermediateMeasurementDetails }));
  }
}
