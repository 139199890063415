import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DepotSearchFacade } from '../+state/depot-search.facade';
import { AppFacade } from '../../+state/app.facade';
import { CloudAppNames } from '../../app.types';
import { WebAppError } from '../../app.factories';
import { IOutputData } from 'angular-split/lib/interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonFacade } from '@vas/common';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { LS_USER_SEARCH_AGGREGATE_TREE_WIDTH } from '../../app.constants';

export const langFactory = () => {
  return (translateService: TranslateService) => translateService.currentLang;
};

@Component({
  selector: 'cloud-search-base',
  templateUrl: './search-base.component.html',
  styleUrls: ['./search-base.component.css']
})
export class SearchBaseComponent implements OnInit, OnDestroy {
  shouldDisplayAggregateTree$: Observable<boolean>;

  constructor(
    public depotSearchFacade: DepotSearchFacade,
    public appFacade: AppFacade,
    public commonFacade: CommonFacade
  ) {}

  ngOnInit() {
    this.initSearchTreeWidth();

    this.shouldDisplayAggregateTree$ = this.depotSearchFacade.aggregateTrees$.pipe(
      map(
        (aggregates) =>
          aggregates?.some((aggregate) => {
            let shouldDisplayTree = false;
            const treeHasNodes = aggregate.nodes !== undefined;
            if (treeHasNodes) {
              shouldDisplayTree = aggregate.nodes!.length > 0;
            }
            return shouldDisplayTree;
          }) ?? false
      )
    );

    // We need apps "depot" and "user_management", so ensure they are actually available
    this.appFacade.allAvailableApps$.subscribe((apps) => {
      if (apps.length > 0 && !apps.includes(CloudAppNames.depot)) {
        this.appFacade.passOnError(
          new WebAppError('PAK cloud application "depot" is not installed', _('APP.ERR.APP_MISSING'), { app: 'depot' })
        );
      }
    });
    this.appFacade.allAvailableApps$.subscribe((apps) => {
      if (apps.length > 0 && !apps.includes(CloudAppNames.user_management)) {
        this.appFacade.passOnError(
          new WebAppError('PAK cloud application "user_management" is not installed', _('APP.ERR.APP_MISSING'), {
            app: 'user_management'
          })
        );
      }
    });
  }

  searchAggregateWidthChanged(output: IOutputData) {
    this.depotSearchFacade.setAggregateTreeWidth(output.sizes[0] as number);
  }

  private initSearchTreeWidth() {
    const preferedSearchTreeWidth = localStorage.getItem(LS_USER_SEARCH_AGGREGATE_TREE_WIDTH);
    if (preferedSearchTreeWidth) {
      this.depotSearchFacade.setAggregateTreeWidth(parseInt(preferedSearchTreeWidth, 10));
    }
  }

  ngOnDestroy() {
    // Reset to overview list on moving to another component. This avoids weird GUI artifacts when switching back to the search page.
    this.depotSearchFacade.viewMeasurementOverviewList();
  }
}
