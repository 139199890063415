<div
  class="loader"
  [ngClass]="{
    inline: inline,
    small: size === 'small',
    medium: size === 'medium',
    large: size === 'large',
    xl: size === 'xl'
  }"
></div>
