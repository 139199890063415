<table id="measurementDatasetsTable" class="table">
  <thead>
    <th class="left">{{ 'MEASUREMENT.POSITION' | translate }}</th>
    <th class="left">{{ 'MEASUREMENT.DIRECTION' | translate }}</th>
    <th class="left">{{ 'MEASUREMENT.QUANTITY' | translate }}</th>
    <th class="left">{{ 'MEASUREMENT.DATATYPE' | translate }}</th>
  </thead>

  <tbody #datasetTable>
    <ng-container *ngFor="let dataset of selectableDatasets">
      <ng-container *ngIf="dataset.source === source.source_name">
        <tr
          *ngFor="let description of dataset.datasetDescription"
          (click)="elementSelected(description, dataset.source)"
          [attr.data-cy]="
            'sourceSelection_' + description.attributes['name'] + '_' + description.attributes['direction']
          "
        >
          <td [title]="description.attributes['name']">
            {{ description.attributes['name'] }}
          </td>
          <td [title]="description.attributes['direction']">
            {{ description.attributes['direction'] }}
          </td>
          <td [title]="description.attributes['quantity']">
            {{ description.attributes['quantity'] }}
          </td>

          <td [title]="description.attributes['datatype']">
            {{ description.attributes['datatype'] }}
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </tbody>
</table>
