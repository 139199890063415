import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, EMPTY } from 'rxjs';
import { retryBackoff } from 'backoff-rxjs';
import { catchError } from 'rxjs/operators';
import { WebAppError, createAppError } from './app.factories';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export class TranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient, public prefix: string = '/assets/i18n/', public suffix: string = '.json') {}

  public getTranslation(lang: string): Observable<any> {
    return this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(
      retryBackoff({
        initialInterval: 500,
        maxRetries: 3
      }),
      catchError(() => {
        // Warning: Do not use the method of app.facade here to report this error as this would introduce a circular dependency
        createAppError(
          new WebAppError(`Failed to retrieve '${lang}' language file`, _('APP.LANG_FAILED_TO_LOAD'), { lang: lang })
        );
        return EMPTY;
      })
    );
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const translateLoader = {
  loader: {
    provide: TranslateLoader,
    useFactory: createTranslateLoader,
    deps: [HttpClient]
  }
};
