import { Directive, HostListener } from '@angular/core';

/**
 * Stop event propagation on forms, due to errors with Edge regarding SavePersonalAndPaymentData
 * See https://stackoverflow.com/questions/55521488/microsoft-edge-ispersonal-savepersonalandpaymentdata-brake-react-app
 **/
@Directive({
  // Linter warning is not applicable, because we have to dive too deep into the internal working of forms
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'form'
})
export class PreventSavePersonalAndPaymentsDataDirective {
  constructor() {}

  @HostListener('submit', ['$event'])
  onSubmit(event: Event) {
    event.stopPropagation();
  }
}
