import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DEPOTSEARCH_FEATURE_KEY, DepotSearchState } from './depot-search.reducer';
import { attributesQuery } from '../../shared/+state/attributes/attributes.selectors';

const getDepotSearchState = createFeatureSelector<DepotSearchState>(DEPOTSEARCH_FEATURE_KEY);

const getAggregateTree = createSelector(getDepotSearchState, (state) => state.aggregateTree);
const getSearch = createSelector(getDepotSearchState, (state) => state.search);
const getSearchBasics = createSelector(getDepotSearchState, (state) => state.searchBasics);
const getUI = createSelector(getDepotSearchState, (state) => state.ui);
const getDeletion = createSelector(getDepotSearchState, (state) => state.deletion);

// AggregateTree
const getAggregateTreeDefs = createSelector(
  getAggregateTree,
  (aggregateTree) => aggregateTree.aggregateTreeDefinitions
);
const getAggregateTrees = createSelector(getAggregateTree, (aggregateTree) => aggregateTree.aggregateTrees);
const getAggregateRequestIsInFlight = createSelector(
  getAggregateTree,
  (aggregateTree) => aggregateTree.aggregateTreeStatus === 'active'
);
const getAggregateTreeWidth = createSelector(getAggregateTree, (aggregateTree) => aggregateTree.aggregateTreeWidth);
const getSelectedTreeNode = createSelector(getAggregateTree, (aggregateTree) => aggregateTree.selectedTreeNode);
const getLazyLoadedNodes = createSelector(getAggregateTree, (aggregateTree) => aggregateTree.lazyLoadedNodes);
const getAvailableAggregateTreeSortingMethods = createSelector(
  getAggregateTree,
  (aggregateTree) => aggregateTree.availableAggregateTreeSortingMethods
);
const getActiveAggregateTreeSortingMethod = createSelector(
  getAggregateTree,
  (aggregateTree) => aggregateTree.activeAggregateTreeSortingMethod
);
const getSelectedAggregateTreeType = createSelector(
  getAggregateTree,
  (aggregateTree) => aggregateTree.selectedAggregateTreeType
);

// Search
const getSearchText = createSelector(getSearch, (search) => {
  return search.searchText;
});
const getLastSearchParams = createSelector(getSearch, (search) => {
  return search.lastSearchParameter;
});

const getSelectedSearchIsInFlight = createSelector(getSearch, (search) => {
  return search.depotSearchStatus === 'active';
});

const getInitialSearchPerformed = createSelector(getSearch, (search) => {
  return search.depotSearchStatus !== 'init';
});

const getRestrictToActiveCC = createSelector(getSearch, (search) => search.restrictToActiveCC);
const getSearchBarFilters = createSelector(getSearch, (search) => search.searchBarFilters);
const getSearchInputModificationState = createSelector(getSearch, (search) => search.searchInputModified);
const getActiveSearchBarFilterAttrId = createSelector(getSearch, (search) => search.activeSearchBarFilterAttrId);
const getSort = createSelector(getSearch, (search) => search.sort);

// Search / Result
const getDepotSearchResult = createSelector(getSearch, (search) => search.searchResults);
const getNumberOfResultEntries = createSelector(getDepotSearchResult, (result) => result?.numberOfEntries);

// SearchBasics
const getResultColumns = createSelector(getSearchBasics, (searchBasics) => searchBasics.resultColumns);
const getResultColumnIsLoading = createSelector(
  getSearchBasics,
  (searchBasics) => searchBasics.resultColumns?.some((column) => column.contentLoaded === false) ?? false
);

// UI
const getSelectedSearchResultEntry = createSelector(getUI, (ui) => ui.selectedResultEntry);

const getResultViewShowState = createSelector(getUI, (ui) => ui.showResultDetailView);

const getNumberShownResultLines = createSelector(getUI, (ui) => ui.numberShownResultLines);

const getMarkedResultLine = createSelector(getUI, (ui) => ui.markedResultLine);
const getSelectedMeasurementIDs = createSelector(getUI, (ui) => ui.selectedMeasurementIDs);

// Deletion
const getDeletionsInProgess = createSelector(getDeletion, (deletion) => deletion.deletionsInProgress);
const getUndeletionsInProgess = createSelector(getDeletion, (deletion) => deletion.undeletionsInProgress);

// Combinations
const getDepotSearchReady = createSelector(
  getAggregateTree,
  getSearchBasics,
  attributesQuery.getDepotAttributesLoaded,
  (aggregateTree, searchBasics, attributesLoaded) =>
    attributesLoaded && searchBasics.resultColumnsInitialized && aggregateTree.aggregateTreesInitialized
);

export const depotSearchQuery = {
  getSearchText,
  getLastSearchParams,
  getDepotSearchResult,
  getNumberOfResultEntries,
  getSelectedSearchResultEntry,
  getSelectedSearchIsInFlight,
  getInitialSearchPerformed,
  getAggregateTreeDefs,
  getAggregateTrees,
  getAggregateRequestIsInFlight,
  getDepotSearchReady,
  getRestrictToActiveCC,
  getSearchBarFilters,
  getActiveSearchBarFilterAttrId,
  getSort,
  getSearchInputModificationState,
  getAggregateTreeWidth,
  getResultColumns,
  getResultColumnIsLoading,
  getResultViewShowState,
  getSelectedTreeNode,
  getLazyLoadedNodes,
  getNumberShownResultLines,
  getMarkedResultLine,
  getDeletionsInProgess,
  getUndeletionsInProgess,
  getSelectedMeasurementIDs,
  getAvailableAggregateTreeSortingMethods,
  getSelectedAggregateTreeType,
  getActiveAggregateTreeSortingMethod
};
