import { Depot } from '../../types/search.types';

export interface DepotAttributesIdCache {
  [id: string]: string | string[];
}

export type AttributeDiscriminator = 'SemanticDepotAttribute' | 'DepotAttribute';

export type AttributeType = 'String' | 'Date' | 'Boolean';

export interface SemanticDepotAttribute {
  discriminator: AttributeDiscriminator;
  id: string;
  name: string;
  type: AttributeType;
  translations: { [index: string]: string };
  attributeGroupID: string | null;
  relatedDepotAttributeIds: string[];
}

export interface DepotAttribute {
  discriminator: AttributeDiscriminator;
  idName: string;
  displayName?: string;
  searchable: boolean;
  type: AttributeType;
  semanticAttribute?: SemanticDepotAttribute;
  usedInDepots?: Depot[];
  aoBaseName?: string;
}

export interface SearchAttributeForValues {
  id: string;
  attribute: DepotAttribute | SemanticDepotAttribute;
}

export interface SearchAttributeAndValue extends SearchAttributeForValues {
  searchAttributeValue?: string;
  searchAttributeBoolean?: boolean;
  searchAttributeStart?: string;
  searchAttributeEnd?: string;
  exact_match: boolean;
}

export type compoundType = 'and' | 'or' | 'not';
export type SearchFilter = (SearchAttributeAndValue | SearchAttributeCompound)[];

export interface SearchAttributeCompound {
  type: compoundType;
  filters: SearchFilter;
}

export interface AttributeGroup {
  id: string;
  name: string;
  translations: { [index: string]: string };
}

export function isSemanticAttribute(
  attribute: SemanticDepotAttribute | DepotAttribute
): attribute is SemanticDepotAttribute {
  return attribute.discriminator === 'SemanticDepotAttribute';
}

export function isDepotAttribute(attribute: SemanticDepotAttribute | DepotAttribute): attribute is DepotAttribute {
  return attribute.discriminator !== 'SemanticDepotAttribute';
}
