// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Clarity
import { ClarityModule } from '@clr/angular';

// VAS
import { SearchAggregateTreeComponent } from './search-aggregate-tree/search-aggregate-tree.component';
import { SearchBaseComponent } from './search-base/search-base.component';
import { SearchResultTableComponent } from './search-result-table/search-result-table.component';

import { DepotSearchRoutingModule } from './depot-search.routing.module';
import { translateLoader } from '../app.translate';
import { SharedModule } from '../shared/shared.module';
import { VasPipesModule } from '@vas/pipes';

// NGRX
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  DEPOTSEARCH_FEATURE_KEY,
  initialState as depotSearchInitialState,
  depotSearchReducer
} from './+state/depot-search.reducer';
import { DepotSearchEffects } from './+state/depot-search.effects';
import { DepotSearchFacade } from './+state/depot-search.facade';
import { ContentCollectionFacade } from '../content-collection/+state/content-collection.facade';
import { MeasurementsFacade } from '../measurements/+state/measurements.facade';
import { MeasurementsModule } from '../measurements/measurements.module';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SearchBarAttributeGroupFilterPipe } from './autocomplete-dropdown/pipes/search-bar-attribute-group-filter.pipe';
import { SearchBarHighlightFilterPipe } from './autocomplete-dropdown/pipes/search-bar-highlight.pipe';
import { AutocompleteDropdownComponent } from './autocomplete-dropdown/autocomplete-dropdown.component';
import { TreeNodeComponent } from './search-aggregate-tree/tree-node/tree-node.component';
import { FormatResultCountPipe } from '../shared/pipes/format-result-count.pipe';
import { MeasurementDeleteOrUndeleteComponent } from './measurement-delete-or-undelete-dialog/measurement-delete-or-undelete.component';
import { SearchAggregateTreeWrapperComponent } from './search-aggregate-tree-wrapper/search-aggregate-tree-wrapper.component';
import { AngularSplitModule } from 'angular-split';
import { SearchBarAttributeButtonComponent } from './search-bar/search-bar-attribute-button/search-bar-attribute-button.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { DepotSearchRouterEffects } from '../router/+state/depotsearch-router.effects';
import { DndModule } from 'ngx-drag-drop';

export const depotSearchImports: any[] = [
  CommonModule,
  FormsModule,
  DepotSearchRoutingModule,
  TranslateModule.forChild(translateLoader),
  StoreModule.forFeature(DEPOTSEARCH_FEATURE_KEY, depotSearchReducer, { initialState: depotSearchInitialState }),
  EffectsModule.forFeature([DepotSearchEffects, DepotSearchRouterEffects]),
  AngularSplitModule,
  MeasurementsModule,
  SharedModule,
  VasPipesModule,
  ClarityModule,
  ClickOutsideModule,
  DndModule,
  SearchBarHighlightFilterPipe
];

export const depotSearchDeclarations: any[] = [
  SearchBarComponent,
  SearchBarAttributeButtonComponent,
  SearchAggregateTreeComponent,
  SearchAggregateTreeWrapperComponent,
  TreeNodeComponent,
  SearchBaseComponent,
  SearchResultTableComponent,
  SearchBarAttributeGroupFilterPipe,
  FormatResultCountPipe,
  AutocompleteDropdownComponent,
  MeasurementDeleteOrUndeleteComponent
];

@NgModule({
  imports: depotSearchImports,
  declarations: depotSearchDeclarations,
  exports: [
    SearchAggregateTreeWrapperComponent,
    SearchBaseComponent,
    SearchResultTableComponent,
    SearchBarHighlightFilterPipe
  ],
  providers: [DepotSearchFacade, ContentCollectionFacade, MeasurementsFacade]
})
export class DepotSearchModule {}
