<ng-container *ngIf="(depotSearchFacade.depotSearchReady$ | async) && (appFacade.cloudDepotsLoaded$ | async)">
  <div id="standardTree" *ngIf="(commonFacade.uiWidth$ | async)! > 1200; else overlayTree">
    <cloud-search-aggregate-tree></cloud-search-aggregate-tree>
  </div>

  <ng-template #overlayTree>
    <clr-dropdown>
      <button type="button" size="24" clrDropdownTrigger>
        <clr-icon shape="tree-view"></clr-icon>
        <clr-icon shape="caret down"></clr-icon>
      </button>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-left" id="overlayTree">
        <cloud-search-aggregate-tree></cloud-search-aggregate-tree>
      </clr-dropdown-menu>
    </clr-dropdown>
  </ng-template>
</ng-container>
