import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonFacade } from '@root/libs/common/src';
import { CustomApps, FeatureFlagsFacade } from '@root/libs/feature-flags/src';
import { EmptyError, Observable, combineLatest, filter, firstValueFrom, map, startWith } from 'rxjs';
import { AppFacade } from '../../../+state/app.facade';
import { MeasurementsFacade } from '../../../measurements/+state/measurements.facade';
import { NavigationFacade } from '../../../router/+state/navigation.facade';
import { RouterFacade } from '../../../router/+state/router.facade';
import { UserFacade } from '../../../user/+state/user.facade';
import { WorkspaceFacade } from '../../../workspace/+state/workspace.facade';
import { TranslateService } from '@ngx-translate/core';
import { lookupLicenseNames } from '../../../admin/admin/license/services/licenses-name-lookup';
import { MyLicenseInfo } from '../../../admin/admin/license/types/license.types';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'cloud-header-actions',
  templateUrl: './header-actions.component.html',
  styleUrls: ['./header-actions.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderActionsComponent implements OnInit {
  @Input() PAKinsightName$: Observable<string>;

  @Output() showPAKinsightModal = new EventEmitter<boolean>();

  shouldDisplayDocsHeader$: Observable<boolean>;
  showAppsSections$: Observable<boolean>;
  showPAKinsightLink$: Observable<boolean>;
  customApps$: Observable<CustomApps>;

  showUserDropdown = false;
  showWorkspaceDropdown = false;

  constructor(
    public measurementsFacade: MeasurementsFacade,
    public featureFlagFacade: FeatureFlagsFacade,
    public cloudRouterFacade: RouterFacade,
    public workspaceFacade: WorkspaceFacade,
    public appFacade: AppFacade,
    public commonFacade: CommonFacade,
    public userFacade: UserFacade,
    private navigationFacade: NavigationFacade,
    public translate: TranslateService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.showPAKinsightLink$ = combineLatest([
      this.featureFlagFacade.featureValue$('PAK_INSIGHT_DOWNLOAD'),
      this.featureFlagFacade.featureValue$('PAK_INSIGHT_DOWNLOADANDUPDATE')
    ]).pipe(map(([dl, dlAndUpdate]) => dl || dlAndUpdate));

    this.customApps$ = this.featureFlagFacade.featureValue$('CUSTOM_APPLICATION_LINKS');

    this.showAppsSections$ = combineLatest([
      this.showPAKinsightLink$.pipe(startWith(false)),
      this.customApps$.pipe(startWith([]))
    ]).pipe(map(([showPAKinsightLink, customApps]) => showPAKinsightLink || customApps?.length > 0));

    this.shouldDisplayDocsHeader$ = combineLatest([
      this.featureFlagFacade.featureValue$('DOCS'),
      this.featureFlagFacade.featureValue$('APIDOCS'),
      this.featureFlagFacade.featureValue$('TUTORIAL')
    ]).pipe(
      map(([shouldDisplayDocs, shouldDisplayAPIDocs, shouldDisplayTutorial]) => {
        return shouldDisplayDocs || shouldDisplayAPIDocs || shouldDisplayTutorial;
      }),
      startWith(false)
    );
  }

  selectWorkspace(workspaceId: string) {
    this.navigationFacade.navigate({
      queryParams: { workspace: workspaceId },
      queryParamsHandling: ''
    });
  }

  async handleLogout() {
    try {
      const response = await firstValueFrom(
        this.http.post('/accounts/logout/', {}, { observe: 'response', responseType: 'text' })
      );
      // Handle successful logout here
      if (response.url) {
        window.location.href = response.url;
      } else {
        throw new Error('Received empty URL');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  }
}
