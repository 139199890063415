import { Pipe, PipeTransform } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Pipe({
  name: 'boolToTranslateString'
})
export class BoolToTranslateStringPipe implements PipeTransform {
  transform(value: boolean | string | undefined): string {
    if (value === true || (typeof value === 'string' && value.toLowerCase() === 'true')) {
      return _('GENERAL.TRUE');
    }
    if (value === false || (typeof value === 'string' && value.toLowerCase() === 'false')) {
      return _('GENERAL.FALSE');
    }
    // value is undefined
    return '';
  }
}
