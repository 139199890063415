export type licenseViewType = 'internal' | 'user' | 'admin';

export const lookupLicenseNames = (
  userLicenseId: string,
  options?: { includeUserType?: boolean; includePeriod?: boolean }
) => {
  const info = licenseInfo[userLicenseId];
  if (!info) return userLicenseId;

  if (info.userType === 'Automation') {
    return `${info.typeName} ${info.userType}`;
  } else {
    const userType = options?.includeUserType ? info.userType : '';
    const period = options?.includePeriod ? info.periodType : '';
    return `${info.typeName} ${userType} ${period}`;
  }
};

export const lookupLicensePeriodType = (userLicenseId: string) => {
  const info = licenseInfo[userLicenseId];
  return info.periodType;
};

export const lookupLicenseUserType = (userLicenseId: string) => {
  const info = licenseInfo[userLicenseId];
  return info.userType;
};

export enum LICENSE_TYPE_IDENTIFIERS {
  Depot_Daily = '9a4ef1db-8870-41d9-b4c6-f3ae9ac24d90',
  Depot_Monthly = 'fe0fd310-73ab-4224-b254-6a165f7cc392',
  Order_Daily = '146e6ff6-b290-4389-8201-05c7e709d5aa',
  Order_Monthly = '680ba83b-0b45-4da4-9f6a-902416191b3b',
  Processing_Daily = '6e306855-7a38-49c2-a381-f21372f097c3',
  Processing_Monthly = '721af2d4-d943-4267-85df-5e5f712f671b',
  Insight_Daily = '40c529fd-5101-4e77-b9ca-df9866ae6a69',
  Insight_Monthly = 'dce0cb26-9ce3-4aee-baf2-23af9da9021b',
  Depot_Automation = 'd2d38803-155e-46d8-9f4d-a310ef0e2110',
  Order_Automation = '96eeb456-1241-4bec-99e6-7421a2dab471',
  Processing_Automation = '34fd78d1-8c7b-4e38-958d-2869d7f3d1a9'
}

export interface ILicenseInfo {
  internalName: string;
  typeName: string;
  periodType: 'Daily' | 'Monthly';
  userType: 'User' | 'Automation';
}

const licenseInfo: Record<LICENSE_TYPE_IDENTIFIERS, ILicenseInfo> = {
  [LICENSE_TYPE_IDENTIFIERS.Depot_Daily]: {
    internalName: 'CLOUD_DEPOT_DAILY_USER',
    typeName: 'Depot',
    periodType: 'Daily',
    userType: 'User'
  },
  [LICENSE_TYPE_IDENTIFIERS.Depot_Monthly]: {
    internalName: 'CLOUD_DEPOT_MONTHLY_USER',
    typeName: 'Depot',
    periodType: 'Monthly',
    userType: 'User'
  },
  [LICENSE_TYPE_IDENTIFIERS.Order_Daily]: {
    internalName: 'CLOUD_ORDER_DAILY_USER',
    typeName: 'Order',
    periodType: 'Daily',
    userType: 'User'
  },
  [LICENSE_TYPE_IDENTIFIERS.Order_Monthly]: {
    internalName: 'CLOUD_ORDER_MONTHLY_USER',
    typeName: 'Order',
    periodType: 'Monthly',
    userType: 'User'
  },
  [LICENSE_TYPE_IDENTIFIERS.Processing_Daily]: {
    internalName: 'CLOUD_PROCESSING_DAILY_USER',
    typeName: 'Processing',
    periodType: 'Daily',
    userType: 'User'
  },
  [LICENSE_TYPE_IDENTIFIERS.Processing_Monthly]: {
    internalName: 'CLOUD_PROCESSING_MONTHLY_USER',
    typeName: 'Processing',
    periodType: 'Monthly',
    userType: 'User'
  },
  [LICENSE_TYPE_IDENTIFIERS.Insight_Daily]: {
    internalName: 'CLOUD_INSIGHT_DAILY_USER',
    typeName: 'Insight',
    periodType: 'Daily',
    userType: 'User'
  },
  [LICENSE_TYPE_IDENTIFIERS.Insight_Monthly]: {
    internalName: 'CLOUD_INSIGHT_MONTHLY_USER',
    typeName: 'Insight',
    periodType: 'Monthly',
    userType: 'User'
  },
  [LICENSE_TYPE_IDENTIFIERS.Depot_Automation]: {
    internalName: 'CLOUD_DEPOT_SERVICE_USER',
    typeName: 'Depot',
    periodType: 'Monthly',
    userType: 'Automation'
  },
  [LICENSE_TYPE_IDENTIFIERS.Order_Automation]: {
    internalName: 'CLOUD_ORDER_SERVICE_USER',
    typeName: 'Order',
    periodType: 'Monthly',
    userType: 'Automation'
  },
  [LICENSE_TYPE_IDENTIFIERS.Processing_Automation]: {
    internalName: 'CLOUD_PROCESSING_SERVICE_USER',
    typeName: 'Processing',
    periodType: 'Monthly',
    userType: 'Automation'
  }
};

export const groupedLicenses = [
  {
    groupName: lookupLicenseNames(LICENSE_TYPE_IDENTIFIERS.Depot_Daily, { includeUserType: true }),
    isAutomation: false,
    entries: [
      {
        licenseTypeId: LICENSE_TYPE_IDENTIFIERS.Depot_Daily,
        name: lookupLicenseNames(LICENSE_TYPE_IDENTIFIERS.Depot_Daily, { includeUserType: true })
      },
      {
        licenseTypeId: LICENSE_TYPE_IDENTIFIERS.Depot_Monthly,
        name: lookupLicenseNames(LICENSE_TYPE_IDENTIFIERS.Depot_Monthly, { includeUserType: true })
      }
    ]
  },
  {
    groupName: lookupLicenseNames(LICENSE_TYPE_IDENTIFIERS.Order_Daily, { includeUserType: true }),
    isAutomation: false,
    entries: [
      {
        licenseTypeId: LICENSE_TYPE_IDENTIFIERS.Order_Daily,
        name: lookupLicenseNames(LICENSE_TYPE_IDENTIFIERS.Order_Daily, { includeUserType: true })
      },
      {
        licenseTypeId: LICENSE_TYPE_IDENTIFIERS.Order_Monthly,
        name: lookupLicenseNames(LICENSE_TYPE_IDENTIFIERS.Order_Monthly, { includeUserType: true })
      }
    ]
  },
  {
    groupName: lookupLicenseNames(LICENSE_TYPE_IDENTIFIERS.Processing_Daily, { includeUserType: true }),
    isAutomation: false,
    entries: [
      {
        licenseTypeId: LICENSE_TYPE_IDENTIFIERS.Processing_Daily,
        name: lookupLicenseNames(LICENSE_TYPE_IDENTIFIERS.Processing_Daily, { includeUserType: true })
      },
      {
        licenseTypeId: LICENSE_TYPE_IDENTIFIERS.Processing_Monthly,
        name: lookupLicenseNames(LICENSE_TYPE_IDENTIFIERS.Processing_Monthly, { includeUserType: true })
      }
    ]
  },
  {
    groupName: lookupLicenseNames(LICENSE_TYPE_IDENTIFIERS.Insight_Daily, { includeUserType: true }),
    isAutomation: false,
    entries: [
      {
        licenseTypeId: LICENSE_TYPE_IDENTIFIERS.Insight_Daily,
        name: lookupLicenseNames(LICENSE_TYPE_IDENTIFIERS.Insight_Daily, { includeUserType: true })
      },
      {
        licenseTypeId: LICENSE_TYPE_IDENTIFIERS.Insight_Monthly,
        name: lookupLicenseNames(LICENSE_TYPE_IDENTIFIERS.Insight_Monthly, { includeUserType: true })
      }
    ]
  },
  {
    groupName: lookupLicenseNames(LICENSE_TYPE_IDENTIFIERS.Depot_Automation, { includeUserType: true }),
    isAutomation: true,
    entries: [
      {
        licenseTypeId: LICENSE_TYPE_IDENTIFIERS.Depot_Automation,
        name: lookupLicenseNames(LICENSE_TYPE_IDENTIFIERS.Depot_Automation, { includeUserType: true })
      }
    ]
  },
  {
    groupName: lookupLicenseNames(LICENSE_TYPE_IDENTIFIERS.Order_Automation, { includeUserType: true }),
    isAutomation: true,
    entries: [
      {
        licenseTypeId: LICENSE_TYPE_IDENTIFIERS.Order_Automation,
        name: lookupLicenseNames(LICENSE_TYPE_IDENTIFIERS.Order_Automation, { includeUserType: true })
      }
    ]
  },
  {
    groupName: lookupLicenseNames(LICENSE_TYPE_IDENTIFIERS.Processing_Automation, { includeUserType: true }),
    isAutomation: true,
    entries: [
      {
        licenseTypeId: LICENSE_TYPE_IDENTIFIERS.Processing_Automation,
        name: lookupLicenseNames(LICENSE_TYPE_IDENTIFIERS.Processing_Automation, { includeUserType: true })
      }
    ]
  }
];
