import { AbsoluteTime } from '../prototypes/DatasetMessages';

// Constants
const uint64MaxDecimalVal: bigint = BigInt(2 ** 64);
const int64MaxVal: bigint = BigInt(2 ** 63 - 1);
const int10hoch95: bigint =
  100000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000n;

/**
 * Maximum absolute time
 *
 * Corresponds to BBMAbsoluteTime::max in PAK code
 */
export const BBM_ABSOLUTE_TIME_MAX = () => {
  const maxTime: AbsoluteTime = {
    first: '9223372036854775807',
    second: '-1'
  };
  return maxTime;
};

export const BBM_ABSOLUTE_TIME_MIN = () => {
  const r: AbsoluteTime = {
    first: '0',
    second: '0'
  };
  return r;
};

/**
 * Converts AbsoluteTime with 128-bit precision into bigint with 128-bit
 * @param {AbsoluteTime}    time    Time to convert
 * @returns {bigint}                Time as bigint with 128bit
 */
export const convertAbsoluteTimeToInt128 = (absoluteTime?: AbsoluteTime, doPrint?: boolean): bigint | undefined => {
  if (typeof absoluteTime !== 'undefined') {
    // tslint:disable-next-line:no-bitwise
    // let result = BigInt.asUintN(64, BigInt(time.first)) << BigInt(64);
    // result += BigInt.asUintN(64, BigInt(time.second));
    const int128Val2h96Dec: bigint = BigInt(2 ** 96);

    if (doPrint) {
      console.log(`### 2 time strings: '${absoluteTime.first}' '${absoluteTime.second}'`);
    }

    let firstInt = BigInt(absoluteTime.first);
    let secondInt = BigInt(absoluteTime.second);

    if (secondInt < 0) {
      if (doPrint) {
        const dvStr = (firstInt << 64n) + secondInt;
        console.log(`### time (i1 << 64 + i2): '${dvStr}'`);
      }

      secondInt += uint64MaxDecimalVal;
      firstInt -= 1n;

      const dv = (firstInt << 64n) + secondInt;

      if (doPrint) {
        console.log(`### 2 time unsigned str: '${firstInt}' '${secondInt}'`);
        console.log(`### time (u1 << 64 + u2): '${dv}'`);
      }

      return dv;
    }

    const dvStr = (firstInt << 64n) + secondInt;

    if (doPrint) {
      console.log(`### time (i1 << 64 + i2): '${dvStr}'`);
    }
    return dvStr;
  }
  return undefined;
};

export const convertTwoInt64StrToTimeStr = (
  firstStr: string,
  secondStr: string,
  doPrint?: boolean
): string | undefined => {
  const v_int128 = convertAbsoluteTimeToInt128({ first: firstStr, second: secondStr }, doPrint);

  return convertInt128ToTimeStr(v_int128);
};

export const convertTimeStrToInt128 = (timeStr: string, doPrint?: boolean): bigint => {
  const v_str_parts = timeStr.split('.');

  const v_bi_p1 = BigInt(v_str_parts[0]) << 96n;
  const v_bi_p2 = BigInt(v_str_parts[1]) << 96n;
  const len = v_str_parts[1].length;
  let divisor: bigint = BigInt(1);
  if (len === 95) {
    divisor = int10hoch95;
  } else {
    for (let i = 0; i < len; i++) {
      divisor = divisor * 10n;
    }
  }
  const v_bi_p2_c = v_bi_p2 / divisor;
  const v_int128 = v_bi_p1 + v_bi_p2_c; // BigInt(vv);
  // const v_int128 = BigInt(Number(timeStr) * 2 ** 96);
  // console.log(v_int128);
  if (doPrint) {
    console.log(`### value    dec: ${timeStr}`);
    // console.log(v_str_parts);
    // console.log(`p[0] << 96 : ${v_bi_p1}`);
    // console.log(`p[1] << 96 : ${v_bi_p2}`);
    // console.log(len);
    // console.log(`(p[1]^96)/(10^${len}) : ${v_bi_p2_c}`);
    // const vv = v_bi_p2_c.toString().replace(/0+$/, ''); // Remove trailing zeros
    // console.log(vv);
    // console.log(`(p[0]^96) + (p[1]^96)/(10^${len}) : ${v_int128}`);
    console.log(`### value int128: ${v_int128}`);
  }
  return v_int128;
};

/**
 * Converts bigint with 128-bit into AbsoluteTime with 128-bit precision
 * @param {bigint}    time    Time to convert
 * @returns {AbsoluteTime}    Time as AbsoluteTime with 128bit
 */
export const convertInt128ToAbsoluteTime = (
  time128Bit?: bigint,
  toUnsigned?: boolean,
  doPrint?: boolean
): AbsoluteTime | undefined => {
  if (time128Bit !== undefined) {
    // tslint:disable-next-line:no-bitwise
    if (doPrint) {
      let s = time128Bit.toString(2);
      let tl = BigInt(0);
      let tu = BigInt(0);
      if (s.length > 64) {
        tl = BigInt(`0b${s.slice(-64)}`);
        tu = BigInt(`0b${s.slice(-128, -64)}`);
      } else {
        tl = time128Bit; // BigInt(`0b${s}`);
      }
    }
    let secondTime = time128Bit & 0xffffffffffffffffn;
    // time128Bit -= secondTime;
    let firstTime = time128Bit >> 64n;

    if (doPrint) {
      console.log(`### value 2  int: '${firstTime.toString()}' '${secondTime.toString()}'`);
    }

    if (typeof toUnsigned !== 'undefined') {
      if (toUnsigned === true) {
        if (secondTime < 0n) {
          secondTime += uint64MaxDecimalVal;
          firstTime -= 1n;
        }
      } else {
        if (secondTime > int64MaxVal) {
          secondTime -= uint64MaxDecimalVal;
          firstTime += 1n;
        }
      }
    }

    if (doPrint) {
      console.log(`### value 2 uint: '${firstTime.toString()}' '${secondTime.toString()}'`);
    }

    return { first: firstTime.toString(), second: secondTime.toString() };
  }
  return undefined;
};

/**
 * Converts time as BigInt with 128-bit precision into number that can be displayed
 * @param {bigint}    time    Time as bigint with 128bit
 * @returns {number}          Time in seconds as number
 */
export const convertInt128ToTimeStr = (int128Time?: bigint): string | undefined => {
  if (typeof int128Time !== 'undefined') {
    // First 4 bytes (i.e. 32bit) of 128bit time are seconds since 01/01/1970
    // => 128bit - 32bit = 96bit => Divide by 2^96 to shift number 96 bits and get time in seconds
    // return Number(time) / 2 ** 96;
    const decTime = ((int128Time & 0xffffffffffffffffffffffffn) * int10hoch95) >> 96n;
    // time128Bit -= secondTime;
    const secTime = int128Time >> 96n;
    // console.log(`   : '${secTime.toString()}.${decTime.toString()}'`);
    return secTime.toString().concat('.', decTime.toString());
  }
  return undefined;
};

export const addRelativeTime = (zeroTime: bigint, relativeTime: number): bigint => {
  return zeroTime + convertTimeStrToInt128(relativeTime.toString());
};

export const timeDifference = (firstInt128Abstime: bigint, secondInt128Abstime: bigint): number => {
  return Number(firstInt128Abstime - secondInt128Abstime) / 2 ** 96;
};
