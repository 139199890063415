import { createAction, props } from '@ngrx/store';
import { LicenseInfo } from '../../types/license.types';

export enum LicenseActionTypes {
  LoadLicenses = '[Licenses] Load Licenses',
  LicensesLoaded = '[Licenses] Licenses Loaded',
  AddLicensesToRole = '[Licenses] Add Licenses To Role',
  AddLicensesToRoleSuccess = '[Licenses] Licenses Added To Role',
  RemoveLicensesFromRole = '[Licenses] Remove Licenses From Role',
  RemoveLicensesFromRoleSuccess = '[Licenses] Licenses Removed From Role'
}

export const LoadLicenses = createAction(LicenseActionTypes.LoadLicenses);
export const LicensesLoaded = createAction(LicenseActionTypes.LicensesLoaded, props<{ licenses: LicenseInfo[] }>());
export const AddLicensesToRole = createAction(
  LicenseActionTypes.AddLicensesToRole,
  props<{ roleId: string; licenses: string[] }>()
);
export const AddLicensesToRoleSuccess = createAction(
  LicenseActionTypes.AddLicensesToRoleSuccess,
  props<{ roleId: string; licenses: string[] }>()
);
export const RemoveLicensesFromRole = createAction(
  LicenseActionTypes.RemoveLicensesFromRole,
  props<{ roleId; licenses: string[] }>()
);
export const RemoveLicensesFromRoleSuccess = createAction(
  LicenseActionTypes.RemoveLicensesFromRoleSuccess,
  props<{ roleId; licenses: string[] }>()
);
