<clr-modal
  [clrModalOpen]="true"
  (keyup.escape)="handleEscape($event)"
  (clrModalAlternateClose)="endCreateCollection($event)"
  (clrModalOpenChange)="endCreateCollection($event)"
>
  <h3 class="modal-title">{{ 'CONTENTCOLLECTION.MODALS.CREATE.CREATEDIALOGTITLE' | translate }}</h3>
  <div class="modal-body">
    <ng-container *ngIf="(contentCollectionFacade.itemsForCreation$ | async)?.length > 0">
      <h5>{{ 'CONTENTCOLLECTION.MODALS.CREATE.MEASUREMENTLISTHEADER' | translate }}</h5>
      <div id="measurementContainer">
        <div *ngFor="let mea of contentCollectionFacade.itemsForCreation$ | async">{{
          mea.content.measurementName
        }}</div>
      </div>
    </ng-container>

    <h5>{{ 'CONTENTCOLLECTION.GENERAL.NAME' | translate }}</h5>
    <form clrForm>
      <div class="clr-control-container" [ngClass]="{ 'clr-error': nameExists }">
        <input
          #CCNameInput
          id="CCNameInput"
          placeholder="{{ 'CONTENTCOLLECTION.MODALS.CREATE.ENTERNEWNAME' | translate }}"
          name="CCNameInput"
          data-cy="CCNameInput"
          [ngModel]="newName"
          (ngModelChange)="handleNameChanged($event)"
          type="text"
          (keyup.enter)="confirmCreateCollection($event)"
        />
        <span *ngIf="nameExists" class="clr-subtext clr-error">{{
          'CONTENTCOLLECTION.MODALS.GENERAL.NAMEALREADYEXISTS' | translate
        }}</span>
      </div>
      <clr-checkbox-container>
        <clr-checkbox-wrapper data-cy="ACTIVATE_CC_CHECKBOX">
          <input
            type="checkbox"
            clrCheckbox
            name="shouldActivate"
            [ngModel]="shouldActivate"
            (ngModelChange)="handleShouldActivateChanged($event)"
          />
          <label>{{ 'CONTENTCOLLECTION.MODALS.CREATE.IMMEDIATELYACTIVATE' | translate }}</label>
        </clr-checkbox-wrapper>
      </clr-checkbox-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" data-cy="cancelCCCreateButton" (click)="endCreateCollection($event)">
      {{ 'CONTENTCOLLECTION.MODALS.GENERAL.CANCELBUTTON' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="newName.length === 0 || nameExists"
      data-cy="doCCCreateButton"
      (click)="confirmCreateCollection($event)"
    >
      {{ 'CONTENTCOLLECTION.MODALS.CREATE.CREATEBUTTON' | translate }}
    </button>
  </div>
</clr-modal>
