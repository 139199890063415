<clr-modal
  [clrModalOpen]="showErrorModal$ | async"
  (clrModalOpenChange)="errorModalClosed$.next(true)"
  [clrModalClosable]="false"
>
  <div class="modal-title" data-cy="NavigationErrorModal">
    <clr-icon shape="exclamation-circle" class="is-solid" size="24"></clr-icon>
    <div id="text">{{ errorTitle$ | async }}</div>
    <clr-icon id="closeModalButton" shape="times" size="24" (click)="errorModalClosed$.next(true)"></clr-icon>
  </div>
  <div class="modal-body">
    <div id="modal-body-header">{{ 'APP.URLERR.MESSAGE.INTRO' | translate }}</div>
    <div id="modal-body-content">{{ 'APP.URLERR.MESSAGE.BODY' | translate }}</div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-link btn-small" type="button" (click)="back(); errorModalClosed$.next(true)">
      <clr-icon shape="angle" size="12" style="transform: rotate(270deg)"></clr-icon>
      {{ 'APP.URLERR.RESPONSES.BACK' | translate }}
    </button>
    <button class="btn btn-link btn-small" type="button" (click)="reload()">
      <clr-icon shape="refresh" size="12"></clr-icon>
      {{ 'APP.URLERR.RESPONSES.RETRY' | translate }}
    </button>
    <div id="error-id">Error-ID: {{ errorID$ | async }}</div>
  </div>
</clr-modal>
