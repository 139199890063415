import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import * as FFActions from './feature-flags.actions';
import { CookieService } from 'ngx-cookie-service';
import { TOKEN_FEATURE_FLAG } from '../feature-flags.types';
import { tap } from 'rxjs/operators';

@Injectable()
export class FeatureFlagsEffects {
  persistFeatureFlagAsCookie$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FFActions.PersistFeatureFlagAsCookie),
        tap((action) =>
          this.cookieService.set(`${TOKEN_FEATURE_FLAG}${action.flagName}`, action.value.toString(), 3650, '/')
        )
      ),
    { dispatch: false }
  );

  clearFeatureFlagCookie$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FFActions.ClearFeatureFlagCookie),
        tap((action) => this.cookieService.delete(`${TOKEN_FEATURE_FLAG}${action.flagName}`, '/'))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private cookieService: CookieService) {}
}
