<div
  id="container"
  [ngClass]="{
    loading: toggleState === 'loading',
    on: toggleState === 'on',
    off: toggleState === 'off',
    disabled: toggleState === 'disabled',
    small: small
  }"
  [attr.title]="hint"
  (click)="clicked.emit(toggleState === 'on' ? true : false)"
>
  <div id="checkbox" *ngIf="toggleState !== 'loading'"></div>
  <cloud-spinner id="spinner" *ngIf="toggleState === 'loading'"></cloud-spinner>
  <div id="text">{{ text }}</div>
</div>
