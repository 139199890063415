<h6>
  {{ 'LICENSING.GROUPS' | translate }}
</h6>
<clr-datagrid class="licensing-datagrid">
  <clr-dg-column [clrDgSortBy]="'name'" [clrDgSortOrder]="ascSort">{{ 'LICENSING.GROUP' | translate }}</clr-dg-column>
  <clr-dg-column>{{ 'LICENSING.ROLES' | translate }}</clr-dg-column>
  <clr-dg-column>{{ 'LICENSING.USERS' | translate }}</clr-dg-column>
  <clr-dg-column>{{ 'LICENSING.OBTAINMENT_RIGHTS' | translate }}</clr-dg-column>

  <clr-dg-row
    *clrDgItems="let group of groups$ | async; trackBy: trackById"
    [clrDgExpanded]="group.id === rowExpanded"
    class="group-row"
    [clrDgItem]="group"
    [ngClass]="{ expanded: group.id === rowExpanded }"
    (click)="expandRow(group)"
  >
    <clr-dg-cell>
      <span [innerHTML]="group.name | searchBarHighlightFilter : (searchFilter$ | async)!"></span>
    </clr-dg-cell>
    <clr-dg-cell>
      <ng-container *ngIf="usersFacade.usersFull$ | async as users">
        <span
          [innerHTML]="(getRolesForGroup(group) | async)! | searchBarHighlightFilter : (searchFilter$ | async)!"
        ></span>
      </ng-container>
    </clr-dg-cell>
    <clr-dg-cell>
      <ng-container *ngIf="usersFacade.usersFull$ | async as users">
        <span
          [innerHTML]="
            getUserNames(getUsersForGroup(group, users)).join(', ')
              | searchBarHighlightFilter : (searchFilter$ | async)!
          "
        ></span>
      </ng-container>
    </clr-dg-cell>
    <clr-dg-cell class="license-wrapper">
      <ng-container *ngIf="usersFacade.usersFull$ | async as users">
        <span
          class="license-pill"
          *ngFor="
            let licenseName of lookUpAndSortLicensePills(getLicensesForGroupObs(group)) | async;
            trackBy: trackById
          "
        >
          <cloud-svg-icon
            *ngIf="licenseName.indexOf('Monthly') >= 0"
            fileName="calendar"
            height="24px"
            width="24px"
          ></cloud-svg-icon>

          <cloud-svg-icon
            *ngIf="licenseName.indexOf('Daily') >= 0"
            fileName="24"
            height="24px"
            width="24px"
          ></cloud-svg-icon>

          <cloud-svg-icon
            *ngIf="licenseName.indexOf('Automation') >= 0"
            fileName="robot"
            height="24px"
            width="24px"
          ></cloud-svg-icon>

          <span [innerHTML]="licenseName | searchBarHighlightFilter : (searchFilter$ | async)!"></span>
        </span>
      </ng-container>
    </clr-dg-cell>

    <clr-dg-row-detail *clrIfExpanded class="expanded-row" (click)="cancelBubble($event)">
      <clr-dg-cell class="pick-list-col">
        <cloud-pick-list
          sourceListIconFileName="slide_text_person"
          [sourceList]="availableRoles"
          [sourceListHeader]="'LICENSING.PICK_LISTS.AVAILABLE_ROLES' | translate"
          targetListIconFileName="slide_text_person"
          [targetList]="groupRoles"
          [targetListHeader]="'LICENSING.PICK_LISTS.APPLIED_ROLES' | translate"
          (movedToTarget)="addRoleToGroup($event, group)"
          (movedToSource)="removeRoleFromGroup($event, group)"
          itemKey="name"
        ></cloud-pick-list>
      </clr-dg-cell>
    </clr-dg-row-detail>
  </clr-dg-row>

  <clr-dg-footer>{{ (groups$ | async)?.length }} {{ 'LICENSING.GROUPS' | translate }}</clr-dg-footer>
</clr-datagrid>
