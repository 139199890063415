<div id="container">
  <div id="xAxisScaleSelection" class="interaction" *ngIf="showXAxisOptions$ | async">
    <div class="interactionLabel">
      <div>{{ 'STREAMING.XSCALE' | translate }}:</div>
      <div class="btn-group btn-sm">
        <div class="radio btn" *ngFor="let axisOption of xAxisOptions$ | async">
          <input type="radio" name="btn-group-x-axis-type" [checked]="(selectedXAxisOption$ | async) === axisOption" />
          <label [for]="'btn-xAxis-type-' + axisOption" (click)="setAxisOption('xAxis', axisOption)">{{
            'STREAMING.' + axisOption | translate
          }}</label>
        </div>
      </div>
    </div>
  </div>

  <div id="yAxisScaleSelection" class="interaction" *ngIf="showYAxisOptions$ | async">
    <div class="interactionLabel">
      <div>{{ 'STREAMING.YSCALE' | translate }}:</div>
      <div class="btn-group btn-sm">
        <div class="radio btn" *ngFor="let axisOption of yAxisOptions$ | async">
          <input type="radio" name="btn-group-y-axis-type" [checked]="(selectedYAxisOption$ | async) === axisOption" />
          <label [for]="'btn-yAxis-type-' + axisOption" (click)="setAxisOption('yAxis', axisOption)">{{
            axisOption
          }}</label>
        </div>
      </div>
    </div>
  </div>

  <div id="colorAxisScaleSelection" class="interaction" *ngIf="showColorAxisOptions$ | async">
    <div class="interactionLabel">
      <div>{{ 'STREAMING.COLORSCALE' | translate }}:</div>
      <div class="btn-group btn-sm">
        <div class="radio btn" *ngFor="let axisOption of colorAxisOptions$ | async">
          <input
            type="radio"
            name="btn-group-color-axis-type"
            [checked]="(selectedColorAxisOption$ | async) === axisOption"
          />
          <label [for]="'btn-colorAxis-type-' + axisOption" (click)="setAxisOption('color', axisOption)">{{
            axisOption
          }}</label>
        </div>
      </div>
    </div>
  </div>

  <div
    id="trackSelection"
    class="interaction"
    *ngIf="
      (streamingFacade.selectableTracks$ | async)?.length > 0 && (streamingFacade.showTrackSelection$ | async) === true
    "
  >
    <div class="interactionLabel" *ngIf="(streamingFacade.currentStreamingPackage$ | async)?.type === 'Measurement'">
      <div>{{ 'STREAMING.TRACK' | translate }}:</div>
      <clr-dropdown [clrCloseMenuOnItemClick]="true">
        <button type="button" class="btn btn-sm btn-primary" clrDropdownTrigger>
          <clr-icon shape="caret down"></clr-icon>
          <ng-container *ngIf="streamingFacade.selectedTrack$ | async as track; else autoTrack">{{
            track.name
          }}</ng-container>
          <ng-template #autoTrack>Auto</ng-template></button
        ><clr-dropdown-menu *clrIfOpen clrPosition="bottom-left">
          <button
            clrDropdownItem
            class="btn btn-link btn-icon no-margin nav-icon"
            (click)="setTrack()"
            [ngClass]="{ active: (streamingFacade.selectedTrack$ | async) === undefined }"
          >
            <ng-container>Auto</ng-container>
          </button>
          <div *ngFor="let track of streamingFacade.selectableTracks$ | async" class="formulaButton">
            <button
              clrDropdownItem
              class="btn btn-link btn-icon no-margin nav-icon"
              (click)="setTrack(track)"
              [ngClass]="{ active: (streamingFacade.selectedTrack$ | async)?.locator === track.locator }"
            >
              <ng-container>{{ track.name }}{{ track.quantityName ? ', ' : '' }} {{ track.quantityName }}</ng-container>
            </button>
          </div>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </div>
</div>

<div id="axisReset" [ngClass]="{ noYBar: (streamingFacade.showYBar$ | async) === false }">
  <div class="btn btn-sm btn-icon" (click)="resetAxis.emit(true)">
    <clr-icon shape="refresh"></clr-icon>
  </div>
</div>
