<clr-modal [(clrModalOpen)]="visible" [clrModalSize]="'lg'">
  <h3 *ngIf="numMeasurementsToDeleteOrUndelete() === 0" class="modal-title">
    <div *ngIf="isDeletion">
      {{ 'DEPOTSEARCH.DELETE_MEASUREMENT.DIALOGTITLE_PREPARING' | translate }}
    </div>
    <div *ngIf="!isDeletion">
      {{ 'DEPOTSEARCH.UNDELETE_MEASUREMENT.DIALOGTITLE_PREPARING' | translate }}
    </div>
  </h3>
  <h3 *ngIf="numMeasurementsToDeleteOrUndelete() === 1" class="modal-title">
    <div *ngIf="isDeletion">
      {{ 'DEPOTSEARCH.DELETE_MEASUREMENT.DIALOGTITLE_SINGLE' | translate }}
    </div>
    <div *ngIf="!isDeletion">
      {{ 'DEPOTSEARCH.UNDELETE_MEASUREMENT.DIALOGTITLE_SINGLE' | translate }}
    </div>
  </h3>
  <h3 *ngIf="numMeasurementsToDeleteOrUndelete() > 1" class="modal-title">
    <div *ngIf="isDeletion">
      {{
        'DEPOTSEARCH.DELETE_MEASUREMENT.DIALOGTITLE_MULTI' | translate : { length: numMeasurementsToDeleteOrUndelete() }
      }}
    </div>
    <div *ngIf="!isDeletion">
      {{
        'DEPOTSEARCH.UNDELETE_MEASUREMENT.DIALOGTITLE_MULTI'
          | translate : { length: numMeasurementsToDeleteOrUndelete() }
      }}
    </div>
  </h3>
  <div class="modal-body">
    <div *ngIf="isPrepareForDeletionOrUndeletion$ | async" data-cy="PREPARING_DELETION">
      {{ 'DEPOTSEARCH.DELETE_MEASUREMENT.PREPARE_DELETION' | translate }}
    </div>
    <div *ngIf="!(isPrepareForDeletionOrUndeletion$ | async)">
      <ul id="group-list">
        <li *ngFor="let relativePath of getGroupedMeasurementKeys()">
          {{ relativePath }}
          <ul id="deletable-list">
            <li *ngFor="let measurement of getMeasurementsToDeleteOrUndelete(relativePath)">
              <b>{{ measurement.title | formatMeasurementName }}</b>
              <ul id="deletable-detail-list" *ngIf="measurement.fileType === 'ZATFX'">
                <li *ngFor="let measurementName of measurement.names.slice(0, 10)">
                  {{ measurementName | formatMeasurementName }}
                </li>
                <li *ngIf="numFurtherMeasurements(measurement) > 0">
                  {{
                    'DEPOTSEARCH.DELETE_MEASUREMENT.SOME_MORE_MEASUREMENTS'
                      | translate : { count: numFurtherMeasurements(measurement) }
                  }}
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <div *ngIf="hasMeasurementsToIgnore()">
        <clr-alert [clrAlertType]="'warning'" [clrAlertClosable]="false">
          <clr-alert-item>
            <div *ngIf="isDeletion">
              <span class="alert-text">{{ 'DEPOTSEARCH.DELETE_MEASUREMENT.CANNOT_DELETE' | translate }} </span>
            </div>
            <div *ngIf="!isDeletion">
              <span class="alert-text">{{ 'DEPOTSEARCH.UNDELETE_MEASUREMENT.CANNOT_UNDELETE' | translate }} </span>
            </div>
          </clr-alert-item>
          <ul id="undeletable-list">
            <li *ngFor="let measurements of measurementsToIgnore$ | async | keyvalue">
              <b>{{ measurements.key | translate }}</b>
              <ul id="undeletable-detail-list">
                <li *ngFor="let href of filterIgnoreMeasurementsLinks(measurements.value)">
                  <a href="{{ href }}">{{
                    getDescriptionForIgnoreMeasurementsWithSpecificLink(measurements.value, href)
                  }}</a>
                  <ul id="undeletable-links-list">
                    <li *ngFor="let meas of filterIgnoreMeasurementsWithSpecificLink(measurements.value, href)">
                      {{ meas.id | formatMeasurementName }}
                    </li>
                  </ul>
                </li>
                <li *ngFor="let measurement of filterIgnoreMeasurementsWithoutLink(measurements.value)">
                  {{ measurement.id | formatMeasurementName }}
                </li>
              </ul>
            </li>
          </ul>
        </clr-alert>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" data-cy="CANCEL_DELETION" (click)="cancelDeletionOrUndeletion()">
      {{ 'GENERAL.CANCEL' | translate }}
    </button>
    <div *ngIf="isDeletion">
      <button
        type="button"
        class="btn btn-danger"
        [ngClass]="{ disabled: disableConfirmButton() }"
        data-cy="CONFIRM_DELETION"
        (click)="confirmDeleteMeasurement()"
      >
        {{ 'DEPOTSEARCH.DELETE_MEASUREMENT.DELETEBUTTON' | translate }}
      </button>
    </div>
    <div *ngIf="!isDeletion">
      <button
        type="button"
        class="btn btn-danger"
        [ngClass]="{ disabled: disableConfirmButton() }"
        data-cy="CONFIRM_UNDELETION"
        (click)="confirmUndeleteMeasurement()"
      >
        {{ 'DEPOTSEARCH.UNDELETE_MEASUREMENT.UNDELETE_BUTTON' | translate }}
      </button>
    </div>
  </div>
</clr-modal>
