import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonFacade } from '@root/libs/common/src';
import { DatasetDescription } from '../../measurements/measurements.types';
import { ProcessingInputDataset, ProcessingInputStream } from '../processing.types';

@Component({
  selector: 'cloud-sourceselection-dropdown',
  templateUrl: './sourceselection-dropdown.component.html',
  styleUrls: ['./sourceselection-dropdown.component.css']
})
export class SourceselectionDropdownComponent {
  @Input() selectableDatasets: ProcessingInputDataset[];
  @Input() source: ProcessingInputStream;

  @Output() selectedDataset = new EventEmitter<{ description: DatasetDescription; source: string }>();

  constructor(public commonFacade: CommonFacade) {}

  elementSelected(description: DatasetDescription, source: string) {
    this.selectedDataset.emit({ description, source });
  }
}
