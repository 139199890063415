import { Component } from '@angular/core';
import { MeasurementsFacade } from '../+state/measurements.facade';
@Component({
  selector: 'cloud-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent {
  constructor(public measurementsFacade: MeasurementsFacade) {}
}
