import {
  ProcessingFormula,
  ValueType,
  ProcessingStatus,
  ProcessingRequestSource,
  ProcessingInputDataset,
  FormulaOperation,
  FormulaCondition
} from './processing.types';
import { Injectable, Inject } from '@angular/core';
import { ANGULAR_HTTP_CONTEXT } from '../app.tokens';
import { AngularHttpContext } from '@vas/angular-http-context';
import { CloudService } from '../services/cloud.service';
import { JsonApi, Spec } from '@muellerbbm-vas/grivet';
import { v4 as uuid } from 'uuid';
import { AppFacade } from '../+state/app.facade';
import { ProcessingTokenContainer } from '../streaming/utils/streaming-token.types';
import { RequestState } from '../streaming/utils/streaming.types';
import { dateSerializer } from '../shared/utility-functions/date.helpers';
import { addMinutes } from 'date-fns';

export const PROCESSING_TOKEN_VALIDITY_DURATION_MINUTES = 5;
@Injectable({
  providedIn: 'root'
})
export class ProcessingService {
  readonly targetDepotName = 'working_depot';

  constructor(
    @Inject(ANGULAR_HTTP_CONTEXT) private context: AngularHttpContext,
    private cloudService: CloudService,
    private appFacade: AppFacade
  ) {}

  async runProcessingStreaming(sources: ProcessingRequestSource[], formula: ProcessingFormula): Promise<URL> {
    return this.createProcessingJobStreaming(sources, formula);
  }

  async pollProcessingStatusOnce(
    processingUrl: string,
    doRequestToken: boolean
  ): Promise<{ status: ProcessingStatus; processingUrl: string }> {
    let status: ProcessingStatus = {
      processingJobId: null,
      status: 'error',
      errorMsg: 'Could not fetch processing status'
    };
    const statusDocument = await JsonApi.Document.fromURL(new URL(processingUrl), this.context);
    const statusResource = statusDocument.resource;

    let error;
    if (statusResource) {
      // Check for status for Error
      error = statusResource?.attributes?.['error'];
      if (error) {
        status = {
          processingJobId: statusResource.id,
          status: 'error',
          errorMsg: this.createErrorMessage(error)
        };
      }
    }

    if (statusResource && !error) {
      const isPending = statusResource.attributes?.['is_pending'] ?? false;

      // Processing is still in progress
      if (isPending) {
        const progress = (statusResource.attributes?.['progress'] as number) ?? 0;
        const calcProgress = (statusResource.attributes?.['calculation_progress'] as number) ?? 0;

        status = {
          processingJobId: statusResource.id,
          status: 'active',
          progress: progress,
          calculation_progress: calcProgress
        };

        if (doRequestToken) {
          const tokenContainer = await this.getToken(statusDocument);
          if (tokenContainer !== undefined) {
            status.tokenContainer = tokenContainer;
          }
        }

        // Processing is done
      } else {
        let tokenContainer: ProcessingTokenContainer | undefined;
        if (doRequestToken) {
          tokenContainer = await this.getToken(statusDocument);
        }
        status = {
          processingJobId: statusResource.id,
          status: 'done',
          tokenContainer: tokenContainer
        };
      }
    }

    return {
      status,
      processingUrl
    };
  }

  createErrorMessage(error: any): string {
    let errorMsg = 'Processing error';
    if (error['message']) {
      errorMsg = errorMsg + ': ' + error['message'];
    }
    const details = error['details'];
    if (details) {
      if (typeof details === 'string') {
        errorMsg = errorMsg + ': \n' + details;
      } else if (Array.isArray(details)) {
        details.filter((detail) => detail.length).forEach((detail) => (errorMsg = errorMsg + '\n' + detail));
      } else {
        Object.keys(details).forEach((key) => {
          if (details[key].length) {
            errorMsg = errorMsg + '\n' + key + ': ' + details[key];
          }
        });
      }
    }
    return errorMsg;
  }

  async cancelProcessing(processingUrl: string): Promise<void> {
    const statusDocument = await JsonApi.Document.fromURL(new URL(processingUrl), this.context);
    const cancelUrl = statusDocument.resource?.relationships['cancellation_requests'].links?.['related'].url;
    if (cancelUrl === undefined) {
      console.warn('Processing ' + processingUrl + ': unable to find url to post a cancellation request');
    } else {
      const cancelRequest = { data: { type: 'JobCancellationRequest' } };
      // NOTE: Just cancel, ignore response
      await this.context.postDocument(cancelUrl, cancelRequest);
    }
  }

  private async getToken(responseDocument: JsonApi.Document): Promise<ProcessingTokenContainer | undefined> {
    const outputs = await responseDocument.resource?.relatedResources['outputs'];
    if (outputs) {
      for (const output of outputs) {
        if (!output) {
          continue;
        }
        if (output['type'] === 'ProcessingOutputStreaming') {
          const tokenResponse = await output.relatedResource['token_response'];
          if (tokenResponse !== undefined) {
            const name: string = tokenResponse.attributes?.['token'];
            const serverUrl: string = tokenResponse.attributes?.['web_server_url'];
            if (name !== undefined && serverUrl !== undefined) {
              const result: ProcessingTokenContainer = {
                id: uuid(),
                type: 'Processing',
                token: name,
                webgrpcServerURL: serverUrl,
                requestState: RequestState.SUCCESS,
                validUntilISO: dateSerializer(addMinutes(new Date(), PROCESSING_TOKEN_VALIDITY_DURATION_MINUTES))
              };
              return result;
            }
          }
        }
      }
    }
    return undefined;
  }

  async fetchFormulas(): Promise<ProcessingFormula[]> {
    const processingAppStart = await this.cloudService.getAppStart('processing');

    const formulasRes = await processingAppStart?.relatedResources['formulas'];
    if (!formulasRes) {
      throw new Error('Formulas is not available');
    }
    const formulas: ProcessingFormula[] = [];
    for (const formula of formulasRes) {
      const attrs = formula.attributes!;
      const form = {} as ProcessingFormula;
      const label_nls = attrs['label_nls'];
      if (label_nls) {
        form.name = label_nls;
      } else {
        // ignore formulas without name
        continue;
      }
      form.id = formula.id;
      form.code = attrs['code'];
      form.expire_after = attrs['expire_after'] ?? undefined;
      form.last_change = attrs['last_change'] ?? undefined;
      form.sources = [];
      const sources = await formula.relatedResources['sources'];
      for (const source of sources) {
        if (source.attributes) {
          // parse condition
          const dependency = await source.relatedResource['dependency'];
          const sourceCondition: FormulaOperation | undefined = await this.parseConditions(dependency);

          form.sources.push({
            id: source.id,
            source_name: source.attributes['source_name'],
            is_track: source.attributes['is_track'],
            label: source.attributes['label_nls'],
            helptext: source.attributes['help_nls'],
            condition: sourceCondition?.operands?.length ?? 0 > 0 ? sourceCondition : undefined,
            conditionsFullfilled: false
          });
        }
      }

      form.parameters = [];
      const parameters = await formula.relatedResources['parameters'];
      for (const parameter of parameters) {
        if (parameter.attributes) {
          const val_types: ValueType[] = [];
          for (const value_type of parameter.attributes['value_types']) {
            const val_type: ValueType = {
              type: value_type
            };

            const value_constraints = parameter.attributes['value_constraints'][value_type];
            if (value_constraints) {
              val_type.constraints = [];
              for (const item of value_constraints['choices']) {
                let label = item['label_nls'];
                let labelLong;
                if (label && label.length > 23) {
                  labelLong = label;
                  label = label.substring(0, 19) + '...';
                }

                const constraint = {
                  value: item['value'],
                  label: label,
                  labelLong: labelLong
                };

                val_type.constraints.push(constraint);
              }
            }
            val_types.push(val_type);
          }
          let defaultValue = '';
          let defaultIsSet = false;
          if (parameter.attributes['default_value']) {
            defaultValue = parameter.attributes['default_value'].value.toString();
            defaultIsSet = true;
          }

          // parse condition
          const dependency = await parameter.relatedResource['dependency'];
          const condition: FormulaOperation | undefined = await this.parseConditions(dependency);

          form.parameters.push({
            id: parameter.id,
            name: parameter.attributes['name'],
            value_types: val_types,
            value: defaultValue,
            default_value: parameter.attributes['default_value'],
            default_is_set: defaultIsSet,
            label: parameter.attributes['label_nls'],
            helptext: parameter.attributes['help_nls'],
            condition: condition?.operands?.length ?? 0 > 0 ? condition : undefined,
            conditionsFullfilled: false,
            dependent: false
          });
        }
      }

      let expired = false;
      if (form.expire_after && form.last_change) {
        const last_change = new Date(form.last_change);
        const now = new Date();

        const re = new RegExp('([\\d]+ )?(\\d\\d):(\\d\\d):(\\d\\d)(.[\\d]+)?');
        const result = form.expire_after.match(re);
        if (result && result.length === 6) {
          const dd = Number(result[1]);
          const hh = Number(result[2]);
          const mm = Number(result[3]);
          const ss = Number(result[4]);
          const expire_seconds = 86400 * (isNaN(dd) ? 0 : dd) + 3600 * hh + 60 * mm + ss;
          expired = last_change.getTime() / 1000 < now.getTime() / 1000 - expire_seconds;
        }
      }
      if (!expired) {
        formulas.push(form);
      }
    }

    return formulas;
  }

  private async parseConditions(dependency: JsonApi.Resource): Promise<FormulaOperation | undefined> {
    let condition: FormulaOperation | undefined;

    if (dependency !== null) {
      condition = {
        operator: dependency.attributes!['operator'],
        operands: []
      };
      if (dependency.type === 'ProcessingFormulaDependencyCondition') {
        const formulaCondition = this.createFormulaCondition(dependency);
        if (formulaCondition) {
          condition.operands.push(formulaCondition);
        }
      }
      if (dependency.type === 'ProcessingFormulaDependencyChain') {
        const ProcessingFormulaDependencyChain = dependency.relationships['operands'];
        const operand = await this.createFormualaOperation(dependency);
        condition.operands.push(operand);
      }
    }
    return condition;
  }

  private createFormulaCondition(dependency: JsonApi.Resource): FormulaCondition | undefined {
    if (dependency) {
      const condition: FormulaCondition = {
        operator: dependency.attributes!['operator'],
        reference: dependency!.relationships!['parameter'].data!['id'],
        value: dependency.attributes!['value']
      };

      return condition;
    } else {
      return undefined;
    }
  }

  private async createFormualaOperation(dependency: JsonApi.Resource): Promise<FormulaOperation | FormulaCondition> {
    const dependencyChain = await dependency.relatedResources['operands'];

    const operation: FormulaOperation = {
      operator: dependency.attributes!['operator'],
      operands: []
    };

    dependencyChain.forEach(async (operand) => {
      if (operand.type === 'ProcessingFormulaDependencyChain') {
        const innerOperation: FormulaOperation = {
          operator: operand.attributes!['operator'],
          operands: []
        };
        const child = await this.createFormualaOperation(operand);
        innerOperation.operands.push(child);

        operation.operands.push(innerOperation);
      } else if (operand.type === 'ProcessingFormulaDependencyCondition') {
        if (operand.attributes) {
          const condition: FormulaCondition = {
            operator: operand.attributes['operator'],
            reference: dependency.id,
            value: operand.attributes['values']
          };
          operation.operands.push(condition);
        }
      }
    });

    return operation;
  }

  private isCondition(element: FormulaCondition | FormulaOperation): element is FormulaCondition {
    return (<FormulaCondition>element).value !== undefined;
  }
  private async requestApplicableDatasets(
    depotId: string,
    depotBrowseContentId: string,
    formulas: ProcessingFormula[]
  ): Promise<JsonApi.Resource> {
    const formulas_data = formulas.map((item) => {
      return {
        id: item.id,
        type: 'ProcessingFormula'
      };
    });
    const measurements_data = [{ id: depotBrowseContentId, type: 'DepotBrowseContent' }];

    const post_doc = {
      data: {
        type: 'ProcessingFormulaApplicableDatasets',
        attributes: {},
        relationships: {
          depot: { data: { id: depotId, type: 'Depot' } },
          measurements: { data: measurements_data },
          formulas: { data: formulas_data }
        }
      },
      included: []
    };

    const processingAppStart = await this.cloudService.getAppStart('processing');
    const url = processingAppStart?.relationships['applicable_datasets']?.links?.related?.url;
    if (!url) {
      throw new Error('applicable_datasets is not available');
    }

    const postResponse = await this.context.postDocument(url, post_doc);
    const responseDocument = new JsonApi.Document(postResponse, this.context);
    const applicResource = responseDocument.resource;
    if (!applicResource) {
      throw new Error('POST response for applicable_datasets did not include a JSON API resource');
    }

    return applicResource;
  }

  // return the applicable datasets for each input stream
  async filterApplicableDatasets(
    depotId: string,
    depotBrowseContentId: string,
    formula: ProcessingFormula
  ): Promise<ProcessingInputDataset[]> {
    const applicableResource = await this.requestApplicableDatasets(depotId, depotBrowseContentId, [formula]);
    const sources: ProcessingInputDataset[] = [];

    if (applicableResource) {
      // a list of <formula-id>/<formula agument name> combinations
      const applicableDatasetsForArgument = (await applicableResource.relatedResources['datasets_for_argument']) ?? [];
      const matchingFormulas: ProcessingFormula[] = [];

      for (const datasetForArgument of applicableDatasetsForArgument) {
        // if datasets list is empty -> formula is not applicable
        const datasets = await datasetForArgument.relatedResources['datasets'];
        if (datasets.length === 0) {
          continue;
        }

        const inputStream = await datasetForArgument.relatedResource['argument'];
        if (inputStream) {
          sources[inputStream.id] = {
            inputStreamId: inputStream.id,
            source: inputStream.attributes!['source_name'],
            datasetDescription: []
          };
          datasets.forEach((ds) => {
            sources[inputStream.id].datasetDescription.push(ds.id);
          });
        }
      }
    }
    return sources;
  }

  private async postProcessingJob(
    sources: ProcessingRequestSource[],
    destinationResource: Spec.ResourceObject,
    formula: ProcessingFormula
  ): Promise<URL> {
    // Get url to post processing requests
    const processingAppStart = await this.cloudService.getAppStart('processing');
    const processingListUrl = processingAppStart?.relationships['processings']?.links?.related?.url;
    if (!processingListUrl) {
      throw new Error('Processing is not available');
    }
    const request = await this.createProcessingRequest(sources, destinationResource, formula);
    // Send request
    const postResponse = await this.context.postDocument(processingListUrl, request);
    const postResponseDocument = new JsonApi.Document(postResponse, this.context);
    const selfLink = postResponseDocument.resource?.selfLink?.url;
    if (!selfLink) {
      throw new Error('Processing POST returned without a self link');
    }
    return selfLink;
  }

  private async createProcessingJobStreaming(
    sources: ProcessingRequestSource[],
    formula: ProcessingFormula
  ): Promise<URL> {
    const attributes: Spec.AttributesObject = {
      relative_location_of_new_datasource: `processing_from_webapp/${uuid()}.zatfx`
    };
    const destinationResourceAtfx = this.createDestinationResource('ProcessingDestinationStreaming', attributes);
    const selfLink = await this.postProcessingJob(sources, destinationResourceAtfx, formula);
    return selfLink;
  }

  private createDestinationResource(type: string, attributes?: Spec.AttributesObject): Spec.ResourceObject {
    const destinationUUID = uuid();
    const destinationResource: Spec.ResourceObject = {
      id: destinationUUID,
      type: type,
      attributes: attributes ?? {}
    };

    return destinationResource;
  }

  private async createProcessingRequest(
    sources: ProcessingRequestSource[],
    destinationResource: Spec.ResourceObject,
    formula: ProcessingFormula
  ): Promise<Spec.ClientJsonApiDocument> {
    if (sources.length > formula.sources.length) {
      throw new Error('Too many input sources for formula');
    }

    const sourceResources: Spec.ResourceObject[] = [];
    const sourceIdentifiers: Spec.ResourceIdentifierObject[] = [];

    sources.forEach((source) => {
      const sourceDepotUUID = uuid();
      const sourceResource: Spec.ResourceObject = {
        id: sourceDepotUUID,
        type: 'ProcessingSourceDepot',
        attributes: { name: source.sourceName },
        relationships: {
          depot: { data: { id: source.depotId, type: 'Depot' } },
          dataset: { data: { id: source.datasetId, type: 'DepotDataset' } }
        }
      };
      sourceResources.push(sourceResource);
      sourceIdentifiers.push({
        id: sourceDepotUUID,
        type: 'ProcessingSourceDepot'
      });
    });

    const formulaDataSetUUID = uuid();
    const formulaDatasetResource: Spec.ResourceObject = {
      id: formulaDataSetUUID,
      type: 'CustomProcessingDatasetFormula',
      attributes: { name: 'Processed' },
      relationships: {
        formula: { data: { id: formula.id, type: 'ProcessingFormula' } },
        sources: { data: sourceIdentifiers }
      }
    };

    const parameterResources: Spec.ResourceObject[] = [];
    for (const parameter of formula.parameters) {
      const parameterUUID = uuid();
      const parameterDeclarationId = parameter.id;
      const parameterResource: Spec.ResourceObject = {
        id: parameterUUID,
        type: 'ProcessingFormulaParameter',
        attributes: { value: parameter.value },
        relationships: {
          declaration: { data: { id: parameterDeclarationId, type: 'ProcessingFormulaParameterDeclaration' } }
        }
      };
      parameterResources.push(parameterResource);
    }

    if (parameterResources.length > 0) {
      const params: Spec.ResourceIdentifierObject[] = [];
      for (const param of parameterResources) {
        params.push({ id: param.id, type: 'ProcessingFormulaParameter' });
      }

      formulaDatasetResource.relationships!['parameters'] = {
        data: params
      };
    }

    const destinations: Spec.ResourceObject[] = [destinationResource];

    const request = new JsonApi.ClientDocument('CustomProcessing');
    if (!request.data.data.attributes) {
      request.data.data.attributes = {};
    }

    request.setAttribute('description', 'Custom Processing from webapp');
    // raw attributes access. Enhance grivet to support not only attribute strings, but objects

    request.data.data.attributes['pak_hierarchy'] = { project: 'custom', job: 'processing', subtitle: `${uuid()}` };
    request.setRelationship('datasets', [{ id: formulaDatasetResource.id, type: formulaDatasetResource.type }]);

    const destinationsIdType = destinations.map((item) => {
      return {
        id: item.id,
        type: item.type
      };
    });
    request.setRelationship('destinations', destinationsIdType);
    request.includeResources([formulaDatasetResource].concat(sourceResources, destinations, parameterResources));
    return request.data;
  }
}
