import { createAction, props } from '@ngrx/store';
import {
  PassByNormConfiguration,
  WorkspaceConfigIdentifier,
  AttributesConfig,
  OrderTableConfig
} from '../../workspace/config/workspace.config.types';
import {
  ConfigurationSource,
  ConfigurationTypeIdentifiers,
  Workspace,
  WorkspaceConfigurations,
  WorkspaceTag
} from '../workspace.types';
import { OrderSearchSortDefinition } from './workspace.reducer';

export enum WorkspaceActionTypes {
  GetWorkspaces = '[Workspace] Get Workspaces',
  WorkspacesReceived = '[Workspace] Workspaces Received',
  SetSelectedWorkspaceByRouter = '[Workspace] [Router] Set Selected Workspace',
  SelectedWorkspaceChanged = '[Workspace] Selected Workspace Changed',
  WorkspaceTypeReceived = '[Workspace] Workspace Type received',
  WorkspaceLoadComplete = '[Workspace] Workspace Load Complete',

  // Config
  SetNormsConfig = '[Workspace - Norms] Set Norms Config',
  SetAttributeGroupConfig = '[Workspace - AttributeGroups] Set Attribute Group Config',
  SetTableConfig = '[Workspace - OrderTable] Set Table Config',

  ReceivedWorkspaceConfigs = '[Workspace - Config] Configs Received',
  ApplyConfigToServer = '[Workspace - Config] Apply Config to Server',
  CreateClientAndApplyConfig = '[Workspace - Config] Create Client and Apply Config to Server',
  PerformApplyConfigToServer = '[Workspace - Config] Perform Apply Config',
  ApplyConfigToServerSUCCESS = '[Workspace - Config] Perform Apply Config SUCCESS',
  ApplyConfigToServerFAILED = '[Workspace - Config] Perform Apply Config FAILED',
  ApplyConfigToServerDONE = '[Workspace - Config] Perform Apply Config DONE',

  // Order Table
  SetOrderTableColumnOrder = '[Workspace - OrderTable] Set Column Order',
  RecoverOrderTableColumnOrder = '[Workspace - OrderTable] Recover Column Order',
  SetSort = '[Workspace - OrderTable] Set Sort'
}

export const GetWorkspaces = createAction(WorkspaceActionTypes.GetWorkspaces);

export const WorkspacesReceived = createAction(
  WorkspaceActionTypes.WorkspacesReceived,
  props<{ receivedWorkspaces: Workspace[] }>()
);

export const SetSelectedWorkspaceByRouter = createAction(
  WorkspaceActionTypes.SetSelectedWorkspaceByRouter,
  props<{ workspaceId: string; navigationInfo: any }>()
);
export const SelectedWorkspaceChanged = createAction(
  WorkspaceActionTypes.SelectedWorkspaceChanged,
  props<{ workspaceId: string }>()
);

export const WorkspaceTypeReceived = createAction(
  WorkspaceActionTypes.WorkspaceTypeReceived,
  props<{ workspaceId: string; workspaceType: WorkspaceConfigIdentifier; workspaceTags: WorkspaceTag[] }>()
);

export const WorkspaceLoadComplete = createAction(
  WorkspaceActionTypes.WorkspaceLoadComplete,
  props<{ workspaceId: string }>()
);

export function SetConfigActionFactory<T extends WorkspaceConfigurations>(actionIdentifier: string) {
  return createAction(actionIdentifier, props<{ content: T; source: keyof ConfigurationSource<T> }>());
}

export const SetNormsConfig = SetConfigActionFactory<PassByNormConfiguration>(WorkspaceActionTypes.SetNormsConfig);
export const SetAttributeConfig = SetConfigActionFactory<AttributesConfig>(
  WorkspaceActionTypes.SetAttributeGroupConfig
);
export const SetTableConfig = SetConfigActionFactory<OrderTableConfig>(WorkspaceActionTypes.SetTableConfig);

export const ReceivedWorkspaceConfigs = createAction(WorkspaceActionTypes.ReceivedWorkspaceConfigs);

export function ApplyConfigToServerActionFactory<T extends WorkspaceConfigurations>(actionIdentifier: string) {
  return createAction(actionIdentifier, props<{ config: T }>());
}
export const ApplyConfigToServer = createAction(
  WorkspaceActionTypes.ApplyConfigToServer,
  props<{ content: WorkspaceConfigurations; configType: ConfigurationTypeIdentifiers }>()
);

export const CreateClientAndApplyConfig = createAction(
  WorkspaceActionTypes.CreateClientAndApplyConfig,
  props<{ content: WorkspaceConfigurations; configType: ConfigurationTypeIdentifiers }>()
);

export const PerformApplyConfigToServer = createAction(
  WorkspaceActionTypes.PerformApplyConfigToServer,
  props<{ content: WorkspaceConfigurations; configType: ConfigurationTypeIdentifiers }>()
);

export const ApplyConfigToServerSUCCESS = createAction(
  WorkspaceActionTypes.ApplyConfigToServerSUCCESS,
  props<{ content: WorkspaceConfigurations; configType: ConfigurationTypeIdentifiers }>()
);

export const ApplyConfigToServerFAILED = createAction(
  WorkspaceActionTypes.ApplyConfigToServerFAILED,
  props<{ content: WorkspaceConfigurations; configType: ConfigurationTypeIdentifiers /* NOTE: Reason? */ }>()
);

export const ApplyConfigToServerDONE = createAction(WorkspaceActionTypes.ApplyConfigToServerDONE);

export const SetOrderTableColumnOrder = createAction(
  WorkspaceActionTypes.SetOrderTableColumnOrder,
  props<{ columnOrder: string[] }>()
);
export const RecoverOrderTableColumnOrder = createAction(
  WorkspaceActionTypes.RecoverOrderTableColumnOrder,
  props<{ columnOrderPerWorkspace: { [key: string]: string[] } }>()
);

export const SetSort = createAction(WorkspaceActionTypes.SetSort, props<{ sort: OrderSearchSortDefinition }>());
