import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { FeatureFlagsPartialState } from './feature-flags.reducer';
import { featureFlagQuery } from './feature-flags.selectors';
import { FeatureFlagPrecedence, FeatureFlag, ConcreteFeatureFlag } from '../feature-flags.types';
import {
  SetFeatureFlag,
  SetAvailableFeatureFlags,
  PersistFeatureFlagAsCookie,
  ClearFeatureFlagCookie
} from './feature-flags.actions';

@Injectable()
export class FeatureFlagsFacade {
  constructor(private store: Store<FeatureFlagsPartialState>) {}

  featureFlags$ = this.store.pipe(select(featureFlagQuery.getFeatureFlags));
  featureValue$ = (flagName: string) => this.store.pipe(select(featureFlagQuery.getFeatureFlagValue(flagName)));
  featureIsSet$ = (flagName: string) => this.store.pipe(select(featureFlagQuery.getFeatureFlagIsSet(flagName)));

  setAvailableFeatureFlags(payload: { featureFlags: FeatureFlag[] }) {
    this.store.dispatch(SetAvailableFeatureFlags({ featureFlags: payload.featureFlags }));
  }

  setFeatureFlag(payload: { flagName: string; value: string | boolean; precedence: FeatureFlagPrecedence }) {
    this.store.dispatch(SetFeatureFlag(payload));
  }

  persistFeatureFlagAsCookie(payload: { flagName: string; value: string | boolean }) {
    this.store.dispatch(PersistFeatureFlagAsCookie(payload));
  }

  clearFeatureFlagCookie(payload: { flagName: string }) {
    this.store.dispatch(ClearFeatureFlagCookie(payload));
  }
}
