<ng-container *ngIf="measurementOverview$ | async as measurement">
  <div class="clr-col-12 no-padding overview-title">
    <h3>{{ measurement.name ? measurement.name : ('DEPOTSEARCH.OVERVIEW' | translate) }}</h3>
  </div>

  <ng-container *ngIf="measurement.name">
    <div class="overview-metadata">
      <ul>
        <li>
          <div class="entry">{{ 'MEASUREMENT.START' | translate }}</div>
          <div class="value">{{ measurement.start | formatDateTime }}</div>
        </li>
        <li>
          <div class="entry">{{ 'MEASUREMENT.SIZE' | translate }}</div>
          <div class="value">{{ measurement.size | formatFileSize }}</div>
        </li>
        <li>
          <div class="entry">{{ 'MEASUREMENT.DATASETCOUNT' | translate }}</div>
          <div class="value">{{ measurement.numberDataSets }}</div>
        </li>
        <li>
          <div class="entry">{{ 'MEASUREMENT.UNIT_UNDER_TEST' | translate }}</div>
          <div class="value">{{ measurement.unitUnderTests }}</div>
        </li>
        <li>
          <div class="entry">{{ 'MEASUREMENT.ARCHIVED_STATUS' | translate }}</div>
          <div class="value">{{ displayArchived(measurement.archivedStatus) | translate }}</div>
        </li>
      </ul>
    </div>

    <div>
      <ul [style.column-count]="columns$ | async">
        <li *ngFor="let attr of generalDataDisplay$ | async">
          <div class="entry" [title]="attr[0]">{{ attr[0] }}</div>
          <div class="value" [title]="attr[1]">{{ attr[1] }}</div>
        </li>
      </ul>
    </div>
  </ng-container>
</ng-container>
