import { ofType, Actions, createEffect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  catchError,
  filter,
  map,
  mapTo,
  mergeMap,
  mergeMapTo,
  pairwise,
  share,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import {
  RouterNavigatedPayload,
  ROUTER_NAVIGATED,
  ROUTER_REQUEST,
  SerializedRouterStateSnapshot
} from '@ngrx/router-store';
import { RouterState, Params } from '@angular/router';
import { RouterFacade } from './router.facade';
import * as RouterActions from './router.actions';
import {
  combineLatest,
  forkJoin,
  from,
  merge,
  MonoTypeOperatorFunction,
  Observable,
  of,
  OperatorFunction,
  timer
} from 'rxjs';
import { NavigationFacade, NavigationOptions } from './navigation.facade';
import { CloudRouterErrorDetail, CloudRouterQueryParamErrorDetail } from './router.types';
import { FeatureFlagsFacade } from '@root/libs/feature-flags/src';
import { getCurrentRouteIdentifier, getQueryParams } from './router.helpers';
import { TitleService } from '../../services/title.service';
import { pipeFromArray } from '../../shared/operators/keepInput.operator';

const shouldLog = false;

interface QueryParamDefault<QueryParamIdType> {
  observable: () => Observable<string | undefined>;
  requiredQueryParamIds?: QueryParamIdType[];
  toUserRepresentation?: (value: string) => Observable<string>;
}

interface QueryParamDescription<
  QueryParamIdType extends string
  // QueryParamValueType = string | string[]
> {
  action: (value: any, info: any) => Action; //value is either the queryParamValue or a parsed queryParamValue (e.g. for search attributes)
  onEmptyAction?: (info: any) => Action;
  default?: QueryParamDefault<QueryParamIdType>;
  waitForStates?: ((queryParams: QueryParams<QueryParamIdType>) => Observable<boolean>)[];
  isValid?: (queryParamValue) => Observable<{ errors: CloudRouterQueryParamErrorDetail[] }>; // should not be provided if parser is set
  parser?: {
    inputOrigins: Observable<any>[];
    queryParamValueParser: (
      queryParamValue: string | string[],
      ...inputs: any[]
    ) => { value: any; errors: CloudRouterQueryParamErrorDetail[] };
  };
  isFinishedSuccessfully: (queryParams: QueryParams<QueryParamIdType>) => Observable<boolean>;
}

type ParameterConfiguration<QueryParamIdType extends string> = {
  [key in QueryParamIdType]: QueryParamDescription<QueryParamIdType>;
};

export type QueryParams<QueryParamIdType extends string> = {
  [queryParamName in QueryParamIdType]: string | string[];
};

type Cancellation =
  | {
      curUserNavigationId: number;
    }
  | { timeoutForUserNavigationId: number };

export abstract class AppSpecificRouterEffects<
  QueryParamIdType extends string /* should be an enum, cf. https://github.com/microsoft/TypeScript/issues/30611 */
> {
  config: ParameterConfiguration<QueryParamIdType>;
  routeSpecificTitleMapping?: (queryParams: Params) => Observable<string>;

  onInitialNavigation?: {
    action: () => Action;
    waitForStates?: ((queryParams: QueryParams<QueryParamIdType>) => Observable<boolean>)[];
  };

  onPopState?: {
    action: () => Action;
    waitForStates?: ((queryParams: QueryParams<QueryParamIdType>) => Observable<boolean>)[];
  };

  onChangeToRoute?: {
    action: () => Action;
    condition?: Observable<boolean>;
    waitForStates?: ((queryParams: QueryParams<QueryParamIdType>) => Observable<boolean>)[];
  };

  cancelPreviousNavigation$ = createEffect(
    () =>
      merge(
        this.actions$ // next user navigation has started => cancel any previous navigation effects
          .pipe(
            ofType(RouterActions.UserNavigationStarted),
            map((action) => {
              return { curUserNavigationId: action.userNavigationId } as Cancellation;
            })
          ),
        this.actions$.pipe(
          // start timer for user navigation
          ofType(RouterActions.UserNavigationStarted),
          withLatestFrom(this.featureFlagsFacade.featureValue$('NAVIGATION_TIMEOUT')),
          mergeMap(([action, timeout]) =>
            timer(timeout).pipe(mapTo({ timeoutForUserNavigationId: action.userNavigationId } as Cancellation))
          )
        )
      ).pipe(share()),
    { dispatch: false }
  );

  mapQueryParamsToStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.UserNavigationStarted, RouterActions.UserNavigationWasRedirected),
      mapToRouterNavigatedPayload(),
      filterRoute(this.routeIdentifier),
      this.takeUntilCancelled(
        'mapQueryParamsToStore$',
        mergeMap((payload) =>
          of(payload).pipe(
            map((_) => getQueryParams(payload)), // also contains query params which have just been removed by navigate (e.g. attr=[] in depotsearch after deleting the last attribute filter)
            mergeMap((queryParams) =>
              from(Object.entries(queryParams).map(([key, value]) => [key, value, queryParams]))
            ),
            map(([queryParamIdentifier, queryParamValue, queryParams]) => {
              const queryParamConfig: QueryParamDescription<QueryParamIdType> = this.config[queryParamIdentifier];
              if (!queryParamConfig) {
                console.warn(`(${this.routeIdentifier}) Purged unknown QueryParameter: ${queryParamIdentifier}`);
                this.redirect(
                  {
                    queryParams: JSON.parse(JSON.stringify(queryParams)),
                    discardQueryParams: [queryParamIdentifier]
                  },
                  payload
                );
              }
              return [queryParamIdentifier, queryParamValue, queryParams, queryParamConfig];
            }),
            filter(([_, __, ___, queryParamConfig]) => !!queryParamConfig),
            mergeMap(([queryParamIdentifier, queryParamValue, queryParams, queryParamConfig]) => {
              // wait for states
              if (!queryParamConfig.waitForStates) {
                if (shouldLog) {
                  console.log(queryParamConfig.waitForStates);
                }
                return of([queryParamIdentifier, queryParamValue, queryParams, queryParamConfig]);
              } else {
                if (shouldLog) {
                  console.log(queryParamConfig.waitForStates);
                }
                return this.waitForStatesToBeFulfilled(queryParamConfig.waitForStates, queryParams).pipe(
                  mapTo([queryParamIdentifier, queryParamValue, queryParams, queryParamConfig])
                );
              }
            }),
            mergeMap(([queryParamIdentifier, queryParamValue, queryParams, queryParamConfig]) => {
              if (queryParamConfig.parser) {
                const inputOrigins = queryParamConfig.parser!.inputOrigins as Observable<any>[];
                if (inputOrigins.length > 0) {
                  return combineLatest(inputOrigins).pipe(
                    map((inputs) => [
                      queryParamIdentifier,
                      queryParamValue,
                      queryParamConfig.parser!.queryParamValueParser(queryParamValue, ...inputs),
                      queryParams,
                      queryParamConfig
                    ]),
                    take(1)
                  );
                } else {
                  return of([
                    queryParamIdentifier,
                    queryParamValue,
                    queryParamConfig.parser!.queryParamValueParser(queryParamValue),
                    queryParams,
                    queryParamConfig
                  ]);
                }
              } else {
                return of([queryParamIdentifier, queryParamValue, undefined, queryParams, queryParamConfig]);
              }
            }),
            mergeMap(([queryParamIdentifier, queryParamValue, parserResult, queryParams, queryParamConfig]) => {
              if (queryParamConfig.isValid) {
                if (queryParamConfig.parser) {
                  throw Error(
                    'implementation error: a derived class from AppSpecificRouterEffects may either provide isValid or parser'
                  );
                }
                return queryParamConfig.isValid(queryParamValue).pipe(
                  take(1),
                  tap((isValid) => {
                    if ((isValid as { errors: CloudRouterQueryParamErrorDetail[] }).errors.length > 0) {
                      (isValid as { errors: CloudRouterQueryParamErrorDetail[] }).errors.forEach((error) =>
                        this.store.dispatch(
                          RouterActions.CloudRouterQueryParamsError({
                            error: error
                          })
                        )
                      );
                    }
                  }),
                  map((isValid) => {
                    return [
                      (isValid as { errors: CloudRouterErrorDetail[] }).errors.length === 0,
                      queryParamIdentifier,
                      queryParamValue,
                      parserResult,
                      queryParams,
                      queryParamConfig
                    ];
                  })
                );
              } else if (parserResult) {
                parserResult.errors.forEach((error) =>
                  this.store.dispatch(
                    RouterActions.CloudRouterQueryParamsError({
                      error: error
                    })
                  )
                );
                return of([
                  parserResult.errors.length === 0,
                  queryParamIdentifier,
                  queryParamValue,
                  parserResult,
                  queryParams,
                  queryParamConfig
                ]);
              } else {
                return of([true, queryParamIdentifier, queryParamValue, parserResult, queryParams, queryParamConfig]);
              }
            }),
            filter(([isValid, _1, _2, _3, _4, _5]) => isValid),
            map(([_, queryParamIdentifier, queryParamValue, parserResult, queryParams, queryParamConfig]) => {
              if (shouldLog) {
                console.log(`mapping ${queryParamIdentifier} = ${queryParamValue} to store`);
              }
              if (!queryParamConfig.parser) {
                return queryParamConfig.action(queryParamValue, payload);
              } else {
                return queryParamConfig.action(parserResult.value, payload);
              }
            })
          )
        )
      ),
      catchError((err, caught) => {
        this.store.dispatch(
          RouterActions.CloudRouterError({
            error: { reason: `${err}`, routeIdentifier: this.routeIdentifier }
          })
        );
        return caught;
      })
    )
  );

  mapEmptyQueryParamsToStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.UserNavigationStarted, RouterActions.UserNavigationWasRedirected),
      mapToRouterNavigatedPayload(),
      filterRoute(this.routeIdentifier),
      this.takeUntilCancelled(
        'mapEmptyQueryParamsToStore$',
        mergeMap((payload) => {
          const queryParams = getQueryParams(payload);
          const actionsToDispatch = Object.entries(this.config).reduce(
            (actions, [queryParamIdentifier, queryParamConfig]) => {
              if (
                (queryParamConfig as QueryParamDescription<QueryParamIdType>).onEmptyAction &&
                !queryParams[queryParamIdentifier]
              ) {
                actions.push((queryParamConfig as QueryParamDescription<QueryParamIdType>).onEmptyAction!(payload));
              }
              return actions;
            },
            [] as Action[]
          );
          return from(actionsToDispatch);
        })
      ),
      catchError((err, caught) => {
        this.store.dispatch(
          RouterActions.CloudRouterError({
            error: { reason: `${err}`, routeIdentifier: this.routeIdentifier }
          })
        );
        return caught;
      })
    )
  );

  // currently chooseDefaultQueryParamValues$ will also try to determine a default even if any of the required query parameters is invalid
  // this should not be a problem since the default observable will be cancelled with the next user navigation or timeout
  chooseDefaultQueryParamValues$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.UserNavigationStarted, RouterActions.UserNavigationWasRedirected),
        mapToRouterNavigatedPayload(),
        filterRoute(this.routeIdentifier),
        this.takeUntilCancelled(
          'chooseDefaultQueryParamValues$',
          mergeMap((payload) =>
            of(payload).pipe(
              map((_) => getQueryParams(payload)),
              mergeMap((queryParams) =>
                from(
                  Object.entries(this.config).map(
                    ([key, value]) =>
                      [key, value, queryParams] as [string, QueryParamDescription<QueryParamIdType>, Params]
                  )
                )
              ),
              filter(
                // has no value? -> thus might need a default
                ([queryParamIdentifier, _, queryParams]) =>
                  (queryParams as Params)[queryParamIdentifier as string] === undefined
              ),
              filter(
                // does this queryParam need a value?
                ([_, queryParamConfig, _2]) => !!queryParamConfig.default
              ),
              filter(
                // can we already choose the default value or do we need other parameters in the url first?
                ([_, queryParamConfig, queryParams]) =>
                  this.canDetermineDefault(queryParamConfig.default!, queryParams as QueryParams<QueryParamIdType>)
              ),
              mergeMap(([queryParamIdentifier, queryParamConfig, queryParams]) => {
                if (shouldLog) {
                  console.log(`choosing default for ${queryParamIdentifier}`);
                }
                const navigateToDefault$ = queryParamConfig.default!.observable().pipe(
                  filter((defaultValue) => defaultValue !== undefined),
                  take(1),
                  tap((defaultValue) =>
                    this.redirect(
                      {
                        queryParams: { [queryParamIdentifier]: defaultValue },
                        queryParamsHandling: 'merge'
                      },
                      payload
                    )
                  )
                );
                if (!queryParamConfig.waitForStates || queryParamConfig.waitForStates.length === 0) {
                  return navigateToDefault$;
                } else {
                  return this.waitForStatesToBeFulfilled(
                    queryParamConfig.waitForStates,
                    queryParams as QueryParams<QueryParamIdType>
                  ).pipe(
                    tap((_) => {
                      if (shouldLog) {
                        console.log(`states fulfilled for ${queryParamIdentifier} default`, this.routeIdentifier);
                      }
                    }),
                    mergeMapTo(navigateToDefault$)
                  );
                }
              })
            )
          )
        ),
        catchError((err, caught) => {
          this.store.dispatch(
            RouterActions.CloudRouterError({
              error: { routeIdentifier: this.routeIdentifier, reason: `${err}` }
            })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  additionallyDispatchOnInitialNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.UserNavigationStarted),
        mapToRouterNavigatedPayload(),
        filterRoute(this.routeIdentifier),
        startWith(undefined),
        pairwise(),
        filter(([prev, _]) => prev === undefined), // i.e. initial navigation
        map(([_, curr]) => curr),
        tap((_) => {
          if (shouldLog) {
            console.log(`Initial Navigation for ${this.routeIdentifier}`);
          }
        }),
        this.takeUntilCancelled(
          'additionallyDispatchOnInitialNavigation$',
          filter((_) => !!this.onInitialNavigation),
          map((payload) => getQueryParams(payload!)),
          mergeMap((currQueryParams) => {
            const action = this.onInitialNavigation!.action();
            if (!this.onInitialNavigation!.waitForStates) {
              return of(action);
            } else {
              return this.waitForStatesToBeFulfilled(
                // maybe should wait for navigation finished successfully?
                this.onInitialNavigation!.waitForStates,
                currQueryParams as QueryParams<QueryParamIdType>
              ).pipe(mapTo(action));
            }
          })
        ),
        catchError((err, caught) => {
          this.store.dispatch(
            RouterActions.CloudRouterError({
              error: {
                routeIdentifier: this.routeIdentifier,
                reason: `${err}`
              } as CloudRouterErrorDetail
            })
          );
          return caught as Observable<Action>;
        })
      ) as Observable<Action> // careful: there are more than 9 operators, therefore automatic type detection and checks do not work (since pipe only has overloads for up to 9 operators)
  );

  additionallyDispatchOnChangeToThisRoute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.UserNavigationStarted),
        filter((_) => !!this.onChangeToRoute),
        mapToRouterNavigatedPayload(),
        pairwise(),
        filter(([prev, curr]) => getCurrentRouteIdentifier(prev) !== getCurrentRouteIdentifier(curr)),
        map(([_, curr]) => curr),
        filterRoute(this.routeIdentifier),
        tap((_) => {
          if (shouldLog) {
            console.log(`Changed route to ${this.routeIdentifier}`);
          }
        }),
        this.takeUntilCancelled(
          'additionallyDispatchOnChangeToThisRoute$',
          map((payload) => getQueryParams(payload!)),
          mergeMap((currQueryParams) => {
            const action = this.onChangeToRoute!.action();
            if (!this.onChangeToRoute!.waitForStates) {
              if (!this.onChangeToRoute!.condition) {
                return of(action);
              } else {
                return this.onChangeToRoute!.condition.pipe(
                  take(1),
                  filter((shouldDispatch) => shouldDispatch),
                  mapTo(action)
                );
              }
            } else {
              // maybe should wait for navigation finished successfully?
              if (!this.onChangeToRoute!.condition) {
                return this.waitForStatesToBeFulfilled(
                  this.onChangeToRoute!.waitForStates,
                  currQueryParams as QueryParams<QueryParamIdType>
                ).pipe(mapTo(action));
              } else {
                return this.waitForStatesToBeFulfilled(
                  this.onChangeToRoute!.waitForStates,
                  currQueryParams as QueryParams<QueryParamIdType>
                ).pipe(
                  mergeMapTo(this.onChangeToRoute!.condition),
                  take(1),
                  filter((shouldDispatch) => shouldDispatch),
                  mapTo(action)
                );
              }
            }
          })
        ),
        catchError((err, caught) => {
          this.store.dispatch(
            RouterActions.CloudRouterError({
              error: {
                parameterName: 'unknown',
                requestedValue: 'unknown',
                routeIdentifier: this.routeIdentifier,
                reason: `${err}`
              } as CloudRouterErrorDetail
            })
          );
          return caught;
        })
      ) as Observable<Action> // careful: there are more than 9 operators, therefore automatic type detection and checks do not work (since pipe only has overloads for up to 9 operators)
  );

  additionallyDispatchOnPopState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_REQUEST),
      filter((_) => !!this.onPopState),
      map((action) => (action as any).payload),
      //filterRoute(this.routeIdentifier), // needs better typing to place it here
      filter((payload) => payload.event.navigationTrigger === 'popstate'),
      mergeMap((requestPayload) =>
        this.actions$.pipe(
          ofType(RouterActions.UserNavigationStarted), // there should be no redirections when navigating back and forth in history
          filter((userNavigationAction) => userNavigationAction.userNavigationId === requestPayload.event.id),
          mapToRouterNavigatedPayload(),
          take(1),
          filterRoute(this.routeIdentifier),
          map((payload) => payload!.routerState!.root.queryParams),
          mergeMap((currQueryParams) => {
            const action = this.onPopState!.action();
            if (!this.onPopState!.waitForStates) {
              return of(action);
            } else {
              return this.waitForStatesToBeFulfilled(
                this.onPopState!.waitForStates,
                currQueryParams as QueryParams<QueryParamIdType>
              ).pipe(mapTo(action));
            }
          }),
          takeUntil(
            this.actions$.pipe(
              ofType(ROUTER_REQUEST),
              tap((_) => {
                if (shouldLog) {
                  console.log(this.routeIdentifier, 'cancel additionallyDispatchOnPopState$');
                }
              })
            )
          )
        )
      ),
      catchError((err, caught) => {
        this.store.dispatch(
          RouterActions.CloudRouterError({
            error: {
              routeIdentifier: this.routeIdentifier,
              reason: `${err}`
            }
          })
        );
        return caught;
      })
    )
  );

  // currently we display a message to the user that there was an error and the user is guided back to the default page
  // however, some of the query parameters may have been matched, e.g. workspace and order but activeDynamicComponent not
  // recoverFromCloudRouterError$ would keep as much query parameters as possible and navigate e.g. to the default activeDynamicComponent
  // recoverFromCloudRouterError$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(RouterActions.CloudRouterError),
  //       this.takeUntilCancelled(
  //         'recoverFromCloudRouterError$',
  //         filter((err) => err.error.routeIdentifier === this.routeIdentifier),
  //         mergeMap((err) => {
  //           const queryParamConfig: QueryParamDescription<QueryParamIdType> = this.config[err.error.parameterName];
  //           // TODO: allow custom error handling if we have more specific cases
  //           if (queryParamConfig.default) {
  //             let queryParams;
  //             this.route.root.queryParams.pipe(take(1)).subscribe((cur) => (queryParams = cur));
  //             if (this.canDetermineDefault(queryParamConfig.default!, queryParams as QueryParams<QueryParamIdType>)) {
  //               const defaultObservable = queryParamConfig.default.observable();
  //               return defaultObservable.pipe(
  //                 filter((defaultValue) => !!defaultValue), // maybe a default can be determined later
  //                 tap((defaultValue) =>
  //                   this.navigationFacade.navigate({
  //                     queryParams: { [err.error.parameterName]: defaultValue },
  //                     queryParamsHandling: 'merge'
  //                   })
  //                 ),
  //                 tap((defaultValue) => {
  //                   let userRepresentation = '';
  //                   queryParamConfig
  //                     .default!.toUserRepresentation(defaultValue!)
  //                     .pipe(take(1))
  //                     .subscribe((repr) => (userRepresentation = repr));
  //                   this.store.dispatch(
  //                     RouterActions.CloudRouterErrorHandled({
  //                       error: {
  //                         parameterName: err.error.parameterName,
  //                         routeIdentifier: err.error.routeIdentifier,
  //                         requestedValue: err.error.requestedValue
  //                       },
  //                       changedTo: {
  //                         newValue: defaultValue! as string,
  //                         representation: userRepresentation
  //                       }
  //                     })
  //                   );
  //                 })
  //               );
  //             } else {
  //               return of('maybe we can determine a default value later');
  //             }
  //           } else {
  //             return of(err).pipe(
  //               tap((_) =>
  //                 this.navigationFacade.navigate({
  //                   queryParams: {},
  //                   queryParamsHandling: 'merge',
  //                   discardQueryParams: [err.error.parameterName]
  //                 })
  //               ),
  //               tap((_) =>
  //                 this.store.dispatch(
  //                   RouterActions.CloudRouterErrorHandled({
  //                     error: {
  //                       parameterName: err.error.parameterName,
  //                       routeIdentifier: err.error.routeIdentifier,
  //                       requestedValue: err.error.requestedValue
  //                     },
  //                     changedTo: 'removed'
  //                   })
  //                 )
  //               )
  //             );
  //           }
  //         })
  //       )
  //     ),
  //   { dispatch: false }
  // );

  userNavigationIsFinished$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.UserNavigationStarted, RouterActions.UserNavigationWasRedirected),
      mapToRouterNavigatedPayload(),
      filterRoute(this.routeIdentifier),
      mergeMap((payload) =>
        of(getQueryParams(payload)).pipe(
          map((queryParams) => {
            return Object.values(this.config).map(
              (value) => [value, queryParams] as [QueryParamDescription<QueryParamIdType>, Params]
            );
          }),
          map((queryParamConfig_queryParams_Array) =>
            queryParamConfig_queryParams_Array.map(([queryParamConfig, queryParams]) =>
              queryParamConfig.isFinishedSuccessfully(queryParams as QueryParams<QueryParamIdType>).pipe(
                filter((isFinished: boolean) => isFinished),
                take(1)
              )
            )
          ),
          this.takeUntilCancelled(
            'userNavigationIsFinished$',
            mergeMap((isFinishedObservables) =>
              forkJoin(isFinishedObservables).pipe(
                takeUntil(this.actions$.pipe(ofType(ROUTER_NAVIGATED))),
                withLatestFrom(this.routerFacade.currentUserNavigationId$),
                filter(([finished, currentUserNavigationId]) => !!currentUserNavigationId),
                map(([finished, currentUserNavigationId]) =>
                  RouterActions.UserNavigationFinishedSuccessfully({ id: currentUserNavigationId!, payload: payload })
                )
              )
            )
          ),
          takeUntil(
            this.actions$.pipe(ofType(RouterActions.UserNavigationStarted, RouterActions.UserNavigationWasRedirected))
          )
        )
      ),
      catchError((err, caught) => {
        this.store.dispatch(
          RouterActions.CloudRouterError({
            error: {
              routeIdentifier: this.routeIdentifier,
              reason: `${err}`
            }
          })
        );
        return caught;
      })
    )
  );

  userNavigationStarted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      mapToRouterNavigatedPayload(),
      filterRoute(this.routeIdentifier),
      withLatestFrom(this.routerFacade.currentUserNavigationId$),
      filter(([_, curUserNavigationId]) => {
        const state = this.getHistoryState();
        return !state.redirected || state.redirected?.userNavigationId !== curUserNavigationId;
      }),
      map(([payload, _]) =>
        RouterActions.UserNavigationStarted({ userNavigationId: payload.event.id, payload: payload })
      ),
      catchError((err, caught) => {
        this.store.dispatch(
          RouterActions.CloudRouterError({
            error: {
              routeIdentifier: this.routeIdentifier,
              reason: `${err}`
            }
          })
        );
        return caught;
      })
    )
  );

  userNavigationWasRedirected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      mapToRouterNavigatedPayload(),
      filterRoute(this.routeIdentifier),
      withLatestFrom(this.routerFacade.currentUserNavigationId$),
      filter(([_, curUserNavigationId]) => {
        const state = this.getHistoryState();
        return state.redirected?.userNavigationId && state.redirected.userNavigationId === curUserNavigationId;
      }),
      map(([payload, _]) =>
        RouterActions.UserNavigationWasRedirected({
          userNavigationId: this.getHistoryState().redirected.userNavigationId,
          payload: payload
        })
      ),
      catchError((err, caught) => {
        this.store.dispatch(
          RouterActions.CloudRouterError({
            error: {
              routeIdentifier: this.routeIdentifier,
              reason: `${err}`
            }
          })
        );
        return caught;
      })
    )
  );

  titleMapping$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.UserNavigationFinishedSuccessfully),
        mapToRouterNavigatedPayload(),
        filterRoute(this.routeIdentifier),
        filter(() => !!this.routeSpecificTitleMapping),
        map((payload) => getQueryParams(payload!)),
        switchMap((queryParams) =>
          this.routeSpecificTitleMapping!(queryParams).pipe(
            tap((routeSpecificTitleFragment) => this.titleService.setRouteFragmentTitle(routeSpecificTitleFragment)),
            tap((routeSpecificTitleFragment) => {
              if (shouldLog) {
                console.log('Route specific title fragment:', routeSpecificTitleFragment);
              }
            }),
            takeUntil(this.actions$.pipe(ofType(RouterActions.UserNavigationStarted)))
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<RouterState>,
    private routerFacade: RouterFacade,
    private navigationFacade: NavigationFacade,
    private featureFlagsFacade: FeatureFlagsFacade,
    private titleService: TitleService,
    protected routeIdentifier: string
  ) {}

  getHistoryState = () => {
    return window.history.state;
  };

  redirect(config: NavigationOptions, navigatedPayload: RouterNavigatedPayload): Observable<boolean> {
    config.replaceUrl = true;
    if (!config.state) {
      config.state = {};
    }
    if (!config.state.redirected) {
      config.state.redirected = {};
    }
    config.state.redirected.userNavigationId =
      this.getHistoryState()?.redirected?.userNavigationId || navigatedPayload.event.id;

    return this.navigationFacade.navigate(config);
  }

  waitForStatesToBeFulfilled = (
    waitForStates: ((queryParams: QueryParams<QueryParamIdType>) => Observable<boolean>)[],
    queryParams: QueryParams<QueryParamIdType>
  ) => {
    const stateSources: Observable<boolean>[] = waitForStates.map((stateSource) => stateSource(queryParams));
    if (stateSources.length === 0) {
      stateSources.push(of(true));
    }
    return combineLatest(stateSources).pipe(
      map((statesFulfilled) => statesFulfilled.reduce((prev, curr) => prev && curr, true)),
      filter((conditionFulfilled) => conditionFulfilled),
      take(1) // important, otherwise might occur a close to infinite loop
    );
  };

  canDetermineDefault = (
    queryParamDefaultConfig: QueryParamDefault<QueryParamIdType>,
    queryParams: QueryParams<QueryParamIdType>
  ): boolean => {
    return (
      !queryParamDefaultConfig.requiredQueryParamIds ||
      queryParamDefaultConfig.requiredQueryParamIds.every((queryParamId) => !!queryParams[queryParamId])
    );
  };

  // for typing chains like pipe and takeUntilCancelled there might be a solution someday
  // cf. https://github.com/microsoft/TypeScript/issues/30370
  // currently support as many overloads as pipe
  takeUntilCancelled<A, B>(debugLogName: string, todo1: OperatorFunction<A, B>): OperatorFunction<A, B>;
  takeUntilCancelled<A, B, C>(
    debugLogName: string,
    todo1: OperatorFunction<A, B>,
    todo2: OperatorFunction<B, C>
  ): OperatorFunction<A, C>;
  takeUntilCancelled<A, B, C, D>(
    debugLogName: string,
    todo1: OperatorFunction<A, B>,
    todo2: OperatorFunction<B, C>,
    todo3: OperatorFunction<C, D>
  ): OperatorFunction<A, D>;
  takeUntilCancelled<A, B, C, D, E>(
    debugLogName: string,
    todo1: OperatorFunction<A, B>,
    todo2: OperatorFunction<B, C>,
    todo3: OperatorFunction<C, D>,
    todo4: OperatorFunction<D, E>
  ): OperatorFunction<A, E>;
  takeUntilCancelled<A, B, C, D, E, F>(
    debugLogName: string,
    todo1: OperatorFunction<A, B>,
    todo2: OperatorFunction<B, C>,
    todo3: OperatorFunction<C, D>,
    todo4: OperatorFunction<D, E>,
    todo5: OperatorFunction<E, F>
  ): OperatorFunction<A, F>;
  takeUntilCancelled<A, B, C, D, E, F, G>(
    debugLogName: string,
    todo1: OperatorFunction<A, B>,
    todo2: OperatorFunction<B, C>,
    todo3: OperatorFunction<C, D>,
    todo4: OperatorFunction<D, E>,
    todo5: OperatorFunction<E, F>,
    todo6: OperatorFunction<F, G>
  ): OperatorFunction<A, G>;
  takeUntilCancelled<A, B, C, D, E, F, G, H>(
    debugLogName: string,
    todo1: OperatorFunction<A, B>,
    todo2: OperatorFunction<B, C>,
    todo3: OperatorFunction<C, D>,
    todo4: OperatorFunction<D, E>,
    todo5: OperatorFunction<E, F>,
    todo6: OperatorFunction<F, G>,
    todo7: OperatorFunction<G, H>
  ): OperatorFunction<A, H>;
  takeUntilCancelled<A, B, C, D, E, F, G, H, I>(
    debugLogName: string,
    todo1: OperatorFunction<A, B>,
    todo2: OperatorFunction<B, C>,
    todo3: OperatorFunction<C, D>,
    todo4: OperatorFunction<D, E>,
    todo5: OperatorFunction<E, F>,
    todo6: OperatorFunction<F, G>,
    todo7: OperatorFunction<G, H>,
    todo8: OperatorFunction<H, I>
  ): OperatorFunction<A, I>;
  takeUntilCancelled<A, B, C, D, E, F, G, H, I, J>(
    debugLogName: string,
    todo1: OperatorFunction<A, B>,
    todo2: OperatorFunction<B, C>,
    todo3: OperatorFunction<C, D>,
    todo4: OperatorFunction<D, E>,
    todo5: OperatorFunction<E, F>,
    todo6: OperatorFunction<F, G>,
    todo7: OperatorFunction<G, H>,
    todo8: OperatorFunction<H, I>,
    todo9: OperatorFunction<I, J>
  ): OperatorFunction<A, J>;
  takeUntilCancelled<A, B, C, D, E, F, G, H, I, J>(
    debugLogName: string,
    todo1: OperatorFunction<A, B>,
    todo2: OperatorFunction<B, C>,
    todo3: OperatorFunction<C, D>,
    todo4: OperatorFunction<D, E>,
    todo5: OperatorFunction<E, F>,
    todo6: OperatorFunction<F, G>,
    todo7: OperatorFunction<G, H>,
    todo8: OperatorFunction<H, I>,
    todo9: OperatorFunction<I, J>,
    ...todos: OperatorFunction<any, any>[]
  ): OperatorFunction<A, any>;
  takeUntilCancelled(
    debugLogName: string /* this name is just for printing in console.log for debugging purposes */,
    ...todo: OperatorFunction<any, any>[]
  ): OperatorFunction<any, any> {
    // sadly it is currently not possible to use spread arguments with pipe
    // cf. https://github.com/ReactiveX/rxjs/issues/3989
    return (input$) =>
      input$.pipe(
        withLatestFrom(this.routerFacade.currentUserNavigationId$),
        mergeMap(
          (
            [input, userNavigationId] // necessary to use mergeMap to keep effect working for next navigation
          ) =>
            pipeFromArray([...todo])(of(input)).pipe(
              takeUntil(
                this.cancelPreviousNavigation$.pipe(
                  filter((cancelPayload) => {
                    if ((cancelPayload as { timeoutForUserNavigationId: number }).timeoutForUserNavigationId) {
                      return (
                        (cancelPayload as { timeoutForUserNavigationId: number }).timeoutForUserNavigationId ===
                        userNavigationId
                      );
                    } else {
                      return true;
                    }
                  }),
                  tap((_) => {
                    if (shouldLog) {
                      console.log(`${this.routeIdentifier} cancel ${debugLogName}`, _);
                    }
                  }),
                  tap((cancelPayload) => {
                    if ((cancelPayload as { timeoutForUserNavigationId: string }).timeoutForUserNavigationId) {
                      this.store.dispatch(
                        RouterActions.CloudRouterTimeout({
                          userNavigationId: (cancelPayload as { timeoutForUserNavigationId: number })
                            .timeoutForUserNavigationId
                        })
                      );
                    } // else is not of interest since the user actively started a new navigation
                  })
                )
              )
            )
        ),
        share()
      );
  }
}

const mapToRouterNavigatedPayload: () => OperatorFunction<
  any,
  RouterNavigatedPayload<SerializedRouterStateSnapshot>
> = () => map((navigated) => (navigated as any).payload as RouterNavigatedPayload);

const filterRoute: (
  routeIdentifier: string
) => MonoTypeOperatorFunction<RouterNavigatedPayload<SerializedRouterStateSnapshot>> = (routeIdentifier: string) =>
  filter(
    // only map query params for this route (e.g. orders but not depotsearch)
    (currPayload) => getCurrentRouteIdentifier(currPayload as RouterNavigatedPayload) === routeIdentifier
  );

/*
Reasons for and against choosing the router.effects way for updating after routing and not the angular router methods
main argument for router.effects: the webapp already shows as much information as possible while still fetching data for dependent components

CanActivate
-----------
# meant for doing authorization stuff (which is covered by the PAK cloud itself in our case)
+ redirect works as return type out of the box
+ in DevTools shows a cancel event (NavigationCancelingError: Redirecting to ...)

Resolver
--------
# meant for loading data which is absolutely necessary for a component to load before displaying it
- in DevTools redirecting is shown as an error event or as a cancel due to a newer navigation

for both angular router ways
----------------------------
+ easier to reuse if we decide to change the order url to workspace/{id}/order/{id}
     -> might later choose the workspace manually on entering the order page
     -> then search for orders
     -> then select an order
+ provided by router
- need to inject order facade in root but it seems that lazy loading the order module still works nevertheless
+ only a successful navigation is stored in the browser history (especially useful for redirects, thus only need to do manual browser history manipulation in depot search where the url is changed while typing)
- renders page when data used in the methods is fully available -> especially problematic on initial pageload or when switching workspaces
- creating the redirect link is a bit more complicated due to unfinished navigation

effects
-------
- each navigation succeeds but actually we are still during navigation (evaluating defaults/allowed values...)
- end of finally successful navigation is currently unclear
+ no blank page during navigation -> intermediate state is shown due to many navigations
*/
