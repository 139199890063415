import { NgModule, Optional, APP_INITIALIZER } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { AngularSplitModule } from 'angular-split';
import { ToastrModule } from 'ngx-toastr';
import { ClarityModule } from '@clr/angular';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { translateLoader } from './app.translate';

// CloudApp specific
import { angularHttpContextFactory, HeaderEntry, AppHeaders } from '@vas/angular-http-context';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { BASE_HOST, ANGULAR_HTTP_CONTEXT, ADDITIONAL_HEADERS, HEADERS } from './app.tokens';
import { environment, config } from '../environments/environment';
import { PagesModule } from './pages/pages.module';
import { CookieService } from 'ngx-cookie-service';
import { ContentCollectionModule } from './content-collection/content-collection.module';
import { UserModule } from './user/user.module';
import { MenusModule } from './menus/menu.module';
import { SharedModule } from './shared/shared.module';
import { VasPipesModule } from '@vas/pipes';
import { WorkspaceModule } from './workspace/workspace.module';

// NGRX
import { StoreModule, MetaReducer, Action, USER_RUNTIME_CHECKS, RuntimeChecks, provideStore } from '@ngrx/store';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { StoreDevtoolsModule, provideStoreDevtools } from '@ngrx/store-devtools';
import { storeFreeze } from 'ngrx-store-freeze';
import { reducer as appReducer, initialState as appInitialState } from './+state/app.reducer';
import { AppEffects } from './+state/app.effects';
import { AppFacade } from './+state/app.facade';
import { NavigationFacade } from './router/+state/navigation.facade';

// Others
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { interceptorProviders } from './shared/interceptors/interceptors';
import { AppConfigService } from './services/config.service';
import { DebugService } from './services/debug.service';
import { ClipboardModule } from 'ngx-clipboard';

import {
  reducer as featureFlagReducer,
  initialState as featureFlagInitialState,
  FeatureFlagsFacade,
  FeatureFlagsModule
} from '@vas/feature-flags';

import {
  reducer as commonReducer,
  initialState as commonInitialState,
  CommonFacade,
  VASCommonModule
} from '@vas/common';

import { ENVIRONMENT } from '@root/libs/common/src/lib/common.tokens';

import {
  StoreRouterConnectingModule,
  MinimalRouterStateSerializer,
  RouterState,
  routerReducer
} from '@ngrx/router-store';
import { RouterFacade } from './router/+state/router.facade';
import { CloudRouterModule } from './router/router.module';

import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor-v2';
import { MonacoService, MONACO_LOADED_EVENT } from './services/monaco.service';
import { LicenseModule } from './admin/admin/license/license.module';

export const ngxMaskConfig: Partial<IConfig> | (() => Partial<IConfig>) = {};

export const NgRxRunTimeChecks: RuntimeChecks = {
  strictStateSerializability: true,
  strictActionSerializability: false,
  strictStateImmutability: true,
  strictActionImmutability: true,
  strictActionWithinNgZone: false
};

// NGRX meta reducer
export const metaReducer: MetaReducer<
  { app: any; featureFlags: any; common: any; router: any; attributes: any },
  Action
>[] = [storeFreeze];

// App Initializer
const initializerConfigFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

// Monaco Editor Config
const monacoConfig: NgxMonacoEditorConfig = {
  baseUrl: 'assets',
  defaultOptions: { scrollBeyondLastLine: false, automaticLayout: true },
  onMonacoLoad: () => {
    postMessage(MONACO_LOADED_EVENT);
  }
};

export const appModules: any[] = [
  BrowserModule,
  HttpClientModule,

  StoreModule.forRoot(
    {
      app: appReducer,
      featureFlags: featureFlagReducer,
      common: commonReducer,
      router: routerReducer
    },
    {
      initialState: {
        app: appInitialState,
        featureFlags: featureFlagInitialState,
        common: commonInitialState,
        router: undefined
      },
      metaReducers: !environment.production ? metaReducer : [],
      runtimeChecks: NgRxRunTimeChecks
    }
  ),
  EffectsModule.forRoot([AppEffects /* RouterEffects */]),
  TranslateModule.forRoot(translateLoader),
  ContentCollectionModule,
  AppRoutingModule,
  StoreRouterConnectingModule.forRoot({
    serializer: MinimalRouterStateSerializer,
    routerState: RouterState.Full
  }),
  BrowserAnimationsModule,
  PagesModule,
  LayoutModule,
  UserModule,
  LicenseModule,
  WorkspaceModule,
  MenusModule,
  AngularSplitModule,
  ClarityModule,
  VasPipesModule,
  SharedModule,
  VASCommonModule,
  ClipboardModule,
  FeatureFlagsModule,
  ToastrModule.forRoot({
    timeOut: 10000, // Display it for 10s instead of the default 5s
    preventDuplicates: true,
    countDuplicates: true,
    resetTimeoutOnDuplicate: true,
    enableHtml: true
  }),
  MonacoEditorModule.forRoot(monacoConfig),
  NgxMaskModule.forRoot(ngxMaskConfig),
  CloudRouterModule
];

const additionalHeaders: HeaderEntry = {};
if (config.useBasicAuth && config.basicAuthCredentials) {
  const user = config.basicAuthCredentials.user;
  const pw = config.basicAuthCredentials.pw;
  additionalHeaders['Authorization'] = 'Basic ' + btoa(`${user}:${pw}`);
}

export const baseHostFactory = () => {
  return config.baseHost;
};

export const providers = [
  AppFacade,
  RouterFacade,
  NavigationFacade,
  CookieService,
  DebugService,
  interceptorProviders,
  FeatureFlagsFacade,
  CommonFacade,
  MonacoService,
  Title,
  {
    provide: APP_INITIALIZER,
    useFactory: initializerConfigFn,
    multi: true,
    deps: [AppConfigService, HttpClient]
  },
  {
    provide: USER_RUNTIME_CHECKS,
    useValue: NgRxRunTimeChecks
  },
  {
    provide: BASE_HOST,
    useFactory: baseHostFactory
  },
  {
    provide: ADDITIONAL_HEADERS,
    useValue: additionalHeaders
  },
  {
    provide: HEADERS,
    useClass: AppHeaders,
    deps: [TranslateService, [new Optional(), ADDITIONAL_HEADERS]]
  },
  {
    provide: ANGULAR_HTTP_CONTEXT,
    useFactory: angularHttpContextFactory,
    deps: [HttpClient, [new Optional(), HEADERS], [new Optional(), BASE_HOST]]
  },
  { provide: APP_BASE_HREF, useValue: '/static/webapp/' },
  { provide: ENVIRONMENT, useValue: environment },
  provideStore(
    {
      app: appReducer,
      featureFlags: featureFlagReducer,
      common: commonReducer,
      router: routerReducer
    },
    {
      initialState: {
        app: appInitialState,
        featureFlags: featureFlagInitialState,
        common: commonInitialState,
        router: undefined
      },
      metaReducers: !environment.production ? metaReducer : [],
      runtimeChecks: NgRxRunTimeChecks
    }
  ),
  provideEffects(AppEffects)
];

if (!environment.production) {
  appModules.push(
    StoreDevtoolsModule.instrument({
      maxAge: 200
    })
  );

  providers.push(
    provideStoreDevtools({
      maxAge: 200
    })
  );
}
@NgModule({
  declarations: [AppComponent],
  imports: appModules,
  providers: providers,
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}
