import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RouterState } from '@angular/router';
import { Store } from '@ngrx/store';
import * as RouterActions from '../../router/+state/router.actions';

const HTTP_UNAUTHORIZED = 401;
const HTTP_FORBIDDEN = 403;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private store: Store<RouterState>) {}

  private handleError(err: HttpErrorResponse): Observable<any> {
    if (err.status === HTTP_UNAUTHORIZED) {
      const referenceHREF = `${window.location.protocol}//${window.location.host}/accounts/login/?next=${
        window.location.pathname
      }${encodeURIComponent(window.location.search)}`;
      console.error(`Authentication failed, redirecting to login at: ${referenceHREF}`);
      window.location.href = referenceHREF;
      return throwError(() => new Error('Authentication failed'));
    } else if (err.status === HTTP_FORBIDDEN) {
      const errors = err?.error?.errors || [];
      errors.forEach((error) => {
        const isLicenseError = error.meta?.['internal_detail']?.reason === 'no_license';
        if (isLicenseError) {
          this.store.dispatch(
            RouterActions.CloudRouterInfo({
              info: {
                reason: error.meta['internal_detail'].reason,
                description: error.meta['internal_detail'].description,
                routeIdentifier: ''
              }
            })
          );

          return throwError(() => new Error('Insufficient License'));
        }
      });
    }

    return of(err);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        return this.handleError(err);
      })
    );
  }
}
