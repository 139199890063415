// App Constants
// Container file for assorted constants that may be interesting to the whole of the PAK cloud WebApp

// Below this threshold, Browsers should be treated as "narrow" or "small", the UI should react accordingly
export const SMALL_UI_WIDTH_PX = 1440;

/* Local Storage Keys */
export const LS_USER_SEARCH_AGGREGATE_TREE_WIDTH = 'user-search-aggregate-tree-width'; // key used to store the users set hierarchy list  width
export const LS_USER_SAVED_COLUMNS = 'user-default-columns'; // key used to store users table columns in localStorage
export const LS_USER_AGGREGATE_TREE_TYPE = 'user-aggregate-tree-type'; // key used to store users table columns in localStorage
export const LS_USER_AGGREGATE_TREE_SORT_METHOD = 'user-aggregate-tree-sort-method'; // key used to store users table columns in localStorage
export const LS_lang = 'lang';
export const LS_USER_HIDE_EMPTY_VALUES = 'user-hide-empty-values';
export const LS_USER_ATTRIBUTE_TREE_WIDTH = 'user-attribute-tree-width';
export const LS_USER_PREFERRED_CONTENT_COLLECTION = 'user-preferred-content-collection'; // key used to store the users prefered content-collection
export const LS_USER_ORDER_SEARCH_SAVED_COLUMNS = 'user-order-default-columns'; // key used to store users table columns in localStorage
export const LS_USER_PREFERRED_WORKSPACE = 'user-preferred-workspace';
export const LS_USER_ORDER_TABLE_COLUMN_ORDER = 'user-order-table-column-order';

/* WebApp specific Constants */
export const WEBAPP_CLIENT_UUID = '17db8443-100c-413f-905a-a4d3193fa66d';
export const WEBAPP_CLIENT_NAME = 'PAK cloud WebApp';

/* URL Constants */
export const URL_LISTPARAM_SEPERATOR = ',';
