import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  LOCALE_ID,
  Inject,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SearchAttributeAndValueUnserializable, stringifySearchAttribute } from '../search-bar.component';
import { DepotSearchFacade } from '../../+state/depot-search.facade';
import { langFactory } from '../../search-base/search-base.component';
import { eventPath } from '../../../shared/utility-functions/eventPath';
import { filter, first, map, take, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';

import { addSeconds } from 'date-fns';
import { getAttributeId } from '../../../shared/utility-functions/search.helpers';
import { isDepotAttribute } from '../../../shared/+state/attributes/attributes.types';

@Component({
  selector: 'cloud-search-bar-attribute-button',
  templateUrl: './search-bar-attribute-button.component.html',
  styleUrls: ['./search-bar-attribute-button.component.css'],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useFactory: langFactory()
    }
  ]
})
export class SearchBarAttributeButtonComponent implements OnInit, OnDestroy {
  constructor(
    public translate: TranslateService,
    public depotSearchFacade: DepotSearchFacade,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  nextSearchAttributeValue$: BehaviorSubject<SearchAttributeAndValueUnserializable | undefined> = new BehaviorSubject(
    undefined
  );

  @Input() searchAttribute: SearchAttributeAndValueUnserializable;
  @Input() active = false;

  @Output() triggerSearch = new EventEmitter<boolean>();
  @Output() isDirty: Observable<boolean> = combineLatest([
    this.depotSearchFacade.searchBarFilters$,
    this.nextSearchAttributeValue$
  ]).pipe(
    map(
      ([searchBarFilters, next]) =>
        [
          !this.searchAttribute ||
            searchBarFilters.find((searchBarFilter) => searchBarFilter.id === this.searchAttribute.id),
          next
        ] as [
          true | SearchAttributeAndValueUnserializable | undefined,
          SearchAttributeAndValueUnserializable | undefined
        ]
    ),
    map(([cur, next]) => {
      if (cur === true) {
        // i.e. searchAttribute undefined
        return false;
      } else {
        return (
          !!cur /* i.e. found search bar filter in store */ &&
          (next
            ? cur.exact_match !== next.exact_match ||
              cur.searchAttributeBoolean !== next.searchAttributeBoolean ||
              cur.searchAttributeValue !== next.searchAttributeValue ||
              cur.searchAttributeStart?.toString() !== next.searchAttributeStart?.toString() ||
              cur.searchAttributeEnd?.toString() !== next.searchAttributeEnd?.toString()
            : false)
        );
      }
    })
  );

  @ViewChild('attributeValueDropdown') attributeValueDropdown: ElementRef;

  shouldShowDropdown$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  subs: Subscription[] = [];
  isDepotAttribute = isDepotAttribute; // for template
  replaceAll = String.prototype.replaceAll; // for template

  ngOnInit() {
    // Boolean SearchAttributes should never attempt to show a dropdown
    if (this.searchAttribute.searchAttributeBoolean === undefined) {
      this.shouldShowDropdown$.next(this.active);
    }

    const setFocusForActiveStringAttribute$ = this.shouldShowDropdown$.pipe(
      filter((should) => should),
      filter((_) => this.searchAttribute.attribute.type === 'String'),
      tap((_) => {
        if (this.attributeValueDropdown) {
          this.attributeValueDropdown.nativeElement.focus();
        } else {
          setTimeout(() => {
            // wait for the next tick in the execution context
            if (this.attributeValueDropdown) {
              this.attributeValueDropdown.nativeElement.focus();
            } else {
              console.warn(
                'focus not set to attribute dropdown input since the dropdown is ',
                this.attributeValueDropdown
              );
            }
          }, 0);
        }
      })
    );
    this.subs.push(setFocusForActiveStringAttribute$.subscribe());
  }

  markDateInputValue(
    searchAttribute: SearchAttributeAndValueUnserializable,
    property: string,
    value: Date | string | undefined | null
  ) {
    if (property === 'searchAttributeEnd') {
      const secondsInADayMinuseOne = 60 * 60 * 24 - 1;
      value = addSeconds(value as Date, secondsInADayMinuseOne);
    }

    this.nextSearchAttributeValue$
      .pipe(
        take(1),
        map((nextSearchAttributeValue) => {
          if (nextSearchAttributeValue) {
            nextSearchAttributeValue[property] = value !== null ? value : undefined;
          } else {
            nextSearchAttributeValue = { ...searchAttribute, [property]: value !== null ? value : undefined };
          }
          return nextSearchAttributeValue;
        }),
        tap((next) => this.nextSearchAttributeValue$.next(next))
      )
      .subscribe(); // no unsubscribe since completes immediately
  }

  setSearchBoolean(searchAttribute: SearchAttributeAndValueUnserializable) {
    searchAttribute.searchAttributeBoolean = !searchAttribute.searchAttributeBoolean;
    this.depotSearchFacade.modifySearchBarFilter(stringifySearchAttribute(searchAttribute));
    this.depotSearchFacade.deactivateSearchBarFilter();
  }

  removeSearchBarFilter(searchAttribute: SearchAttributeAndValueUnserializable) {
    this.depotSearchFacade.removeSearchBarFilter(stringifySearchAttribute(searchAttribute));
  }

  markSearchAttributeValue(searchAttribute: SearchAttributeAndValueUnserializable, value: string) {
    this.nextSearchAttributeValue$
      .pipe(
        take(1),
        map((nextSearchAttributeValue) => {
          if (nextSearchAttributeValue) {
            nextSearchAttributeValue.searchAttributeValue = value;
          } else {
            nextSearchAttributeValue = { ...searchAttribute, searchAttributeValue: value };
          }
          return nextSearchAttributeValue;
        }),
        tap((next) => this.nextSearchAttributeValue$.next(next))
      )
      .subscribe(); // no unsubscribe since completes immediately
  }

  markSearchAttributeExactMatch(searchAttribute: SearchAttributeAndValueUnserializable) {
    this.nextSearchAttributeValue$
      .pipe(
        take(1),
        map((nextSearchAttributeValue) => {
          if (nextSearchAttributeValue) {
            nextSearchAttributeValue.exact_match = !nextSearchAttributeValue.exact_match;
          } else {
            nextSearchAttributeValue = { ...searchAttribute, exact_match: !searchAttribute.exact_match };
          }
          return nextSearchAttributeValue;
        }),
        tap((next) => this.nextSearchAttributeValue$.next(next))
      )
      .subscribe(); // no unsubscribe since completes immediately
  }

  preventSpaceDismiss(event: KeyboardEvent): void {
    const key = event.key || event.keyCode;
    if (key === ' ' || key === 32) {
      event.stopPropagation();
    }
  }

  toggleDropdown() {
    this.shouldShowDropdown$
      .pipe(
        first(),
        map((lastValue) => !lastValue),
        tap((nextValue) => this.shouldShowDropdown$.next(nextValue)),
        tap((nextShouldDisplay) => {
          if (nextShouldDisplay) {
            this.depotSearchFacade.activateSearchBarFilter(getAttributeId(this.searchAttribute));
          } else {
            this.depotSearchFacade.deactivateSearchBarFilter();
            // it is too late to trigger the url update in onDestroy -> already do it here! then the url is updated before the dropdown is destroyed, otherwise isDirty is incorrect
            this.nextSearchAttributeValue$
              .pipe(
                take(1),
                tap((next) => {
                  if (next) {
                    this.depotSearchFacade.modifySearchBarFilter(stringifySearchAttribute(next));
                  }
                })
              )
              .subscribe(); // no unsubscribe since completes immediately
          }
        })
      )
      .subscribe(); // unsubscribe not needed since first() will complete the observable
  }

  handleClickOutside(event: MouseEvent) {
    event.stopPropagation();
    const paths = eventPath(event);
    const clickedOnDatepicker = paths.some((path) => path['localName'] === 'clr-datepicker-view-manager');

    if (!clickedOnDatepicker) {
      this.shouldShowDropdown$.next(false);
      this.depotSearchFacade.deactivateSearchBarFilter();
      // it is too late to trigger the url update in onDestroy -> already do it here! then the url is updated before the dropdown is destroyed
      // (necessary for click on search button to use the correct values)
      this.nextSearchAttributeValue$
        .pipe(
          take(1),
          tap((next) => {
            if (next) {
              this.depotSearchFacade.modifySearchBarFilter(stringifySearchAttribute(next));
            }
          })
        )
        .subscribe(); // no unsubscribe since completes immediately
    }
  }

  handleEnter() {
    this.nextSearchAttributeValue$
      .pipe(
        take(1),
        tap((next) => {
          if (next) {
            this.depotSearchFacade.modifySearchBarFilter(stringifySearchAttribute(next));
          }
        })
      )
      .subscribe(); // no unsubscribe since completes immediately
    this.triggerSearch.emit();
    this.toggleDropdown();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
    this.nextSearchAttributeValue$
      // sometime the dropdown is destroyed although neither toggleDropdown nor handleClickOutside have been triggered
      // -> updating the search bar filters is done on destroy in order to not forget any cases
      // e.g. add a second attribute, type something in there, remove first attribute -> typed input otherwise disappeared
      .pipe(
        take(1),
        tap((next) => {
          if (next) {
            this.depotSearchFacade.modifySearchBarFilter(stringifySearchAttribute(next));
          }
        })
      )
      .subscribe(); // no unsubscribe since completes immediately
  }
}
