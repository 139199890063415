import { createAction, props } from '@ngrx/store';
import { AppErrorPayload } from '../../app.types';
import {
  ContentCollection,
  ContentCollectionDialog,
  ContentCollectionItem,
  ContentCollectionShareInfo
} from '../content-collection.types';

export enum ContentCollectionActionTypes {
  LoadContentCollections = '[ContentCollection] Load ContentCollections',
  ContentCollectionsLoaded = '[ContentCollection] ContentCollections Loaded',
  ContentCollectionLoadError = '[ContentCollection] ContentCollection Load Error',

  ShowContentCollectionDialog = '[ContentCollection] Show Dialog',
  HideContentCollectionDialog = '[ContentCollection] Hide Dialog',

  PrepareContentCollectionCreationWithItems = '[ContentCollection] Prepare ContentCollection Creation With Items',
  EndContentCollectionCreationWithItems = '[ContentCollection] End ContentCollection Creation With Items',

  CreateContentCollection = '[ContentCollection] Create ContentCollection',
  ContentCollectionCreated = '[ContentCollection] ContentCollection Created',
  UndoCreateContentCollection = '[ContentCollection] Undo Create ContentCollection',

  DeleteContentCollection = '[ContentCollection] Delete ContentCollection',
  ContentCollectionDeleted = '[ContentCollection] ContentCollection Deleted',
  UndoDeleteContentCollection = '[ContentCollection] Undo Delete ContentCollection',

  EditContentCollection = '[ContentCollection] Edit ContentCollection',
  ContentCollectionEdited = '[ContentCollection] ContentCollection Edited',
  UndoEditContentCollection = '[ContentCollection] Undo Edit ContentCollection',

  ActivateContentCollection = '[ContentCollection] Set active ContentCollection',

  ShareContentCollection = '[ContentCollection] Share ContentCollection',
  ContentCollectionShared = '[ContentCollection] ContentCollection Shared',
  UndoShareContentCollection = '[ContentCollection] Undo Share ContentCollection',

  DeleteContentCollectionShare = '[ContentCollection] Delete ContentCollection Share',
  ContentCollectionShareDeleted = '[ContentCollection] ContentCollection Share Deleted',
  UndoDeleteContentCollectionShare = '[ContentCollection] Undo Delete ContentCollection Share',

  EditContentCollectionShare = '[ContentCollection] Edit ContentCollection Share',
  ContentCollectionShareEdited = '[ContentCollection] ContentCollection Share Edited',
  UndoEditContentCollectionShare = '[ContentCollection] Undo Edit ContentCollection Share',

  DeclineContentCollectionShare = '[ContentCollection] Decline ContentCollection Share',
  ContentCollectionShareDeclined = '[ContentCollection] ContentCollection Share Declined',
  UndoDeclineContentCollectionShare = '[ContentCollection] Undo Decline ContentCollection Share',

  AddContentCollectionItem = '[ContentCollection] Add ContentCollection Item',
  ContentCollectionItemAdded = '[ContentCollection] Added ContentCollection Item to a Content Collection',
  UndoAddContentCollectionItem = '[ContentCollection] Undo Add ContentCollection Item',

  AddMultipleContentCollectionItems = '[ContentCollection] Add Multiple ContentCollection Items',
  MultipleContentCollectionItemsAdded = '[ContentCollection] Added Multiple ContentCollection Items to a Content Collection',

  RemoveContentCollectionItem = '[ContentCollection] Remove ContentCollection Item',
  ContentCollectionItemRemoved = '[ContentCollection] Removed ContentCollection Item from a Content Collection',

  ContentCollectionItemsDragged = '[ContentCollection] Content Collection Items has been dragged',

  CopyContentCollectionToClipboard = '[ContentCollection] Copy Content Collection To Clipboard',
  CopyContentCollectionToClipboardFeedback = '[ContentCollection] Copy Content Collection To Clipboard Feedback',

  NoOperation = '[ContentCollection] No Operation when no PakReferences are available'
}

export const LoadContentCollections = createAction(ContentCollectionActionTypes.LoadContentCollections);
export const ContentCollectionsLoaded = createAction(
  ContentCollectionActionTypes.ContentCollectionsLoaded,
  props<{ collections: ContentCollection[] }>()
);
export const ContentCollectionLoadError = createAction(
  ContentCollectionActionTypes.ContentCollectionLoadError,
  props<{ error: AppErrorPayload }>()
);

export const ShowContentCollectionDialog = createAction(
  ContentCollectionActionTypes.ShowContentCollectionDialog,
  props<{ dialog: ContentCollectionDialog }>()
);

export const HideContentCollectionDialog = createAction(ContentCollectionActionTypes.HideContentCollectionDialog);

export const PrepareContentCollectionCreationWithItems = createAction(
  ContentCollectionActionTypes.PrepareContentCollectionCreationWithItems,
  props<{ items: ContentCollectionItem[] }>()
);
export const EndContentCollectionCreationWithItems = createAction(
  ContentCollectionActionTypes.EndContentCollectionCreationWithItems
);

export const CreateContentCollection = createAction(
  ContentCollectionActionTypes.CreateContentCollection,
  props<{ collection: ContentCollection; activate: boolean }>()
);
export const UndoCreateContentCollection = createAction(
  ContentCollectionActionTypes.UndoCreateContentCollection,
  props<{ collection: ContentCollection }>()
);

export const ContentCollectionCreated = createAction(
  ContentCollectionActionTypes.ContentCollectionCreated,
  props<{
    clientGuid: string;
    returnedCollection: ContentCollection;
    items?: ContentCollectionItem[];
    activate?: boolean;
  }>()
);
export const DeleteContentCollection = createAction(
  ContentCollectionActionTypes.DeleteContentCollection,
  props<{ collection: ContentCollection }>()
);

export const ContentCollectionDeleted = createAction(
  ContentCollectionActionTypes.ContentCollectionDeleted,
  props<{ collection: ContentCollection }>()
);

export const UndoDeleteContentCollection = createAction(
  ContentCollectionActionTypes.UndoDeleteContentCollection,
  props<{ collection: ContentCollection }>()
);
export const EditContentCollection = createAction(
  ContentCollectionActionTypes.EditContentCollection,
  props<{ collection: ContentCollection; name: string }>()
);

export const ContentCollectionEdited = createAction(
  ContentCollectionActionTypes.ContentCollectionEdited,
  props<{ collection: ContentCollection }>()
);

export const UndoEditContentCollection = createAction(
  ContentCollectionActionTypes.UndoEditContentCollection,
  props<{ collection: ContentCollection }>()
);

export const ActivateContentCollection = createAction(
  ContentCollectionActionTypes.ActivateContentCollection,
  props<{ collectionGuid?: string }>()
);

export const ShareContentCollection = createAction(
  ContentCollectionActionTypes.ShareContentCollection,
  props<{ collection: ContentCollection; shareInfo: ContentCollectionShareInfo }>()
);

export const ContentCollectionShared = createAction(
  ContentCollectionActionTypes.ContentCollectionShared,
  props<{ collection: ContentCollection; shareInfo: ContentCollectionShareInfo }>()
);

export const UndoShareContentCollection = createAction(
  ContentCollectionActionTypes.UndoShareContentCollection,
  props<{ collection: ContentCollection; shareInfo: ContentCollectionShareInfo }>()
);

export const DeleteContentCollectionShare = createAction(
  ContentCollectionActionTypes.DeleteContentCollectionShare,
  props<{ collection: ContentCollection; shareInfo: ContentCollectionShareInfo }>()
);

export const EditContentCollectionShare = createAction(
  ContentCollectionActionTypes.EditContentCollectionShare,
  props<{ collection: ContentCollection; shareInfo: ContentCollectionShareInfo }>()
);

export const ContentCollectionShareEdited = createAction(
  ContentCollectionActionTypes.ContentCollectionShareEdited,
  props<{ collection: ContentCollection; shareInfo: ContentCollectionShareInfo }>()
);

export const UndoEditContentCollectionShare = createAction(
  ContentCollectionActionTypes.UndoEditContentCollectionShare,
  props<{ collection: ContentCollection; shareInfo: ContentCollectionShareInfo }>()
);

export const DeclineContentCollectionShare = createAction(
  ContentCollectionActionTypes.DeclineContentCollectionShare,
  props<{ collectionId: string }>()
);

export const ContentCollectionShareDeclined = createAction(
  ContentCollectionActionTypes.ContentCollectionShareDeclined,
  props<{ collectionId: string }>()
);

export const UndoDeclineContentCollectionShare = createAction(
  ContentCollectionActionTypes.UndoDeclineContentCollectionShare,
  props<{ collectionId: string }>()
);

export const ContentCollectionShareDeleted = createAction(
  ContentCollectionActionTypes.ContentCollectionShareDeleted,
  props<{ collection: ContentCollection; shareInfo: ContentCollectionShareInfo }>()
);

export const UndoDeleteContentCollectionShare = createAction(
  ContentCollectionActionTypes.UndoDeleteContentCollectionShare,
  props<{ collection: ContentCollection; shareInfo: ContentCollectionShareInfo }>()
);

export const AddContentCollectionItem = createAction(
  ContentCollectionActionTypes.AddContentCollectionItem,
  props<{ item: ContentCollectionItem; collectionGuid: string }>()
);

export const AddMultipleContentCollectionItems = createAction(
  ContentCollectionActionTypes.AddMultipleContentCollectionItems,
  props<{ items: ContentCollectionItem[]; collectionGuid: string }>()
);

export const ContentCollectionItemAdded = createAction(
  ContentCollectionActionTypes.ContentCollectionItemAdded,
  props<{ clientItemID: string; result: ContentCollectionItem; collectionGuid: string }>()
);

export const MultipleContentCollectionItemsAdded = createAction(
  ContentCollectionActionTypes.MultipleContentCollectionItemsAdded,
  props<{ results: ContentCollectionItem[]; collectionGuid: string }>()
);

export const UndoAddContentCollectionItem = createAction(
  ContentCollectionActionTypes.UndoAddContentCollectionItem,
  props<{ item: ContentCollectionItem; collectionGuid: string }>()
);

export const RemoveContentCollectionItem = createAction(
  ContentCollectionActionTypes.RemoveContentCollectionItem,
  props<{ item: ContentCollectionItem; collectionGuid: string }>()
);

export const ContentCollectionItemRemoved = createAction(
  ContentCollectionActionTypes.ContentCollectionItemRemoved,
  props<{ item: ContentCollectionItem; collectionGuid: string }>()
);

export const CopyContentCollectionToClipboard = createAction(
  ContentCollectionActionTypes.CopyContentCollectionToClipboard,
  props<{ contentCollection: ContentCollection }>()
);

export const CopyContentCollectionToClipboardFeedback = createAction(
  ContentCollectionActionTypes.CopyContentCollectionToClipboardFeedback,
  props<{ success: boolean }>()
);

export const ContentCollectionItemsDragged = createAction(
  ContentCollectionActionTypes.ContentCollectionItemsDragged,
  props<{ contentCollectionItems: ContentCollectionItem[] }>()
);

export const NoOperation = createAction(ContentCollectionActionTypes.NoOperation);
