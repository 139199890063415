import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AttributesState, ATTRIBUTES_FEATURE_KEY } from './attributes.reducer';

const getAttributesState = createFeatureSelector<AttributesState>(ATTRIBUTES_FEATURE_KEY);

const getDepotAttributesLoaded = createSelector(
  getAttributesState,
  (attributes) => attributes.availableDepotAttributesLoaded
);
const getAvailableDepotAttributes = createSelector(
  getAttributesState,
  (attributes) => attributes.availableDepotAttributes
);
const getAvailableSemanticDepotAttributes = createSelector(
  getAttributesState,
  (attributes) => attributes.availableSemanticDepotAttributes
);
const getDepotAttributesCache = createSelector(getAttributesState, (attributes) => attributes.depotAttributesCache);

const getAvailableAttributeGroups = createSelector(getAttributesState, (state) => state.availableAttributeGroups);

export const attributesQuery = {
  getDepotAttributesLoaded,
  getAvailableDepotAttributes,
  getAvailableSemanticDepotAttributes,
  getAvailableAttributeGroups,
  getDepotAttributesCache
};
