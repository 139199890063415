// common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  CONTENTCOLLECTION_FEATURE_KEY,
  initialState as contentCollectionInitialState,
  reducer as contentCollectionReducer
} from './+state/content-collection.reducer';
import { ContentCollectionEffects } from './+state/content-collection.effects';
import { ContentCollectionFacade } from './+state/content-collection.facade';

// VAS
import { translateLoader } from '../app.translate';
import { CollectionCreateWithItemsComponent } from './collection-create-with-items/collection-create-with-items.component';
import { CollectionDeleteComponent } from './collection-delete/collection-delete.component';
import { CollectionDeclineShareComponent } from './collection-decline-share/collection-decline-share.component';
import { CollectionEditComponent } from './collection-edit/collection-edit.component';
import { SharedModule } from '../shared/shared.module';

// Clarity
import { ClarityModule } from '@clr/angular';

export const contentCollectionImports: any[] = [
  CommonModule,
  ClarityModule,
  StoreModule.forFeature(CONTENTCOLLECTION_FEATURE_KEY, contentCollectionReducer, {
    initialState: contentCollectionInitialState
  }),
  EffectsModule.forFeature([ContentCollectionEffects]),
  TranslateModule.forChild(translateLoader),
  SharedModule,
  FormsModule
];

export const contentCollectionDeclarations: any[] = [
  CollectionCreateWithItemsComponent,
  CollectionDeleteComponent,
  CollectionEditComponent,
  CollectionDeclineShareComponent
];

@NgModule({
  declarations: contentCollectionDeclarations,
  imports: contentCollectionImports,
  exports: contentCollectionDeclarations,
  providers: [ContentCollectionFacade]
})
export class ContentCollectionModule {}
