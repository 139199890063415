import { Pipe, PipeTransform } from '@angular/core';
import { DatasetDescription } from '.././measurements.types';

@Pipe({
  name: 'measurementsPositionFilter'
})
export class MeasurementsPositionFilterPipe implements PipeTransform {
  transform(positions: DatasetDescription[], position: string): any {
    if (positions && position) {
      return positions.filter((dataset) => dataset.attributes['name'] === position);
    }
    return null;
  }
}
