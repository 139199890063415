<div class="list collection-shares-list">
  <h4 class="dropdown-header"
    >{{ 'CONTENTCOLLECTION.SHARING.SHARETO' | translate }} "{{ (selectedCC$ | async)?.name }}"</h4
  >

  <div class="dropdown-divider" role="separator"></div>

  <clr-datagrid class="collection-shares-table">
    <clr-dg-column class="shared-to-col"
      >{{ 'LICENSING.ROLE' | translate }}/{{ 'LICENSING.USER' | translate }}</clr-dg-column
    >
    <clr-dg-column class="rights-col">{{ 'CONTENTCOLLECTION.SHARING.MAYEDIT' | translate }}</clr-dg-column>
    <clr-dg-column class="expires-col">{{ 'CONTENTCOLLECTION.SHARING.EXPIRATIONDATE' | translate }}</clr-dg-column>

    <ng-container *ngIf="selectedCC$ | async as selectedContentCollection">
      <clr-dg-row *clrDgItems="let item of selectedContentCollection.shareInfo" [clrDgItem]="item">
        <clr-dg-cell [title]="item.sharedWith" class="shared-to">
          <cloud-svg-icon
            [fileName]="item.shareType === shareTypes.User ? 'person' : 'person_slide_text'"
          ></cloud-svg-icon>
          {{ getSharedToName(item) | async }}
        </clr-dg-cell>

        <clr-dg-cell [title]="item.canAddItems" class="shared-rights">
          <input
            type="checkbox"
            clrCheckbox
            [checked]="item.canAddItems"
            (change)="rightsChanged($event, selectedContentCollection, item, $event.target.checked)"
          />
        </clr-dg-cell>

        <clr-dg-cell [title]="item.validUntil" class="expires-cell">
          <clr-date-container class="date-picker">
            <input
              type="date"
              clrDate
              [clrDate]="getDate(item.validUntil)"
              [min]="minDate"
              (clrDateChange)="validUntilChanged($event, selectedContentCollection, item)"
            />
          </clr-date-container>

          <span
            class="remove-single-icon"
            (click)="deleteContentCollectionShare($event, selectedContentCollection, item)"
          >
            <clr-icon shape="times" size="20" class="is-error"></clr-icon>
          </span>
        </clr-dg-cell>
      </clr-dg-row>

      <clr-dg-row [clrDgItem]="'add_new'">
        <clr-dg-cell title="sharedWith" class="add-new-share" [class.active]="mode === 'add'">
          <cloud-svg-icon
            fileName="person_add"
            *ngIf="mode === 'view'"
            (click)="$event.stopPropagation(); mode = 'add'"
          ></cloud-svg-icon>

          <cloud-share-autocomplete
            *ngIf="mode === 'add'"
            [alreadyShared]="selectedContentCollection.shareInfo"
            [shareInfo]="newShare"
            (optionSelected)="shareToChanged(selectedContentCollection, $event)"
          ></cloud-share-autocomplete>
        </clr-dg-cell>
        <clr-dg-cell class="shared-rights">
          <input type="checkbox" clrCheckbox [(ngModel)]="newShare.canEdit" *ngIf="mode === 'add'" />
        </clr-dg-cell>
        <clr-dg-cell class="expires">
          <clr-date-container *ngIf="mode === 'add'" class="date-picker">
            <input type="date" clrDate [(clrDate)]="newShare.validUntil" [min]="minDate" />
          </clr-date-container>
        </clr-dg-cell>
      </clr-dg-row>
    </ng-container>
  </clr-datagrid>

  <div class="bottom-controls">
    <button class="btn btn-back" (click)="$event.preventDefault(); $event.stopPropagation(); backClicked.emit()">{{
      'CONTENTCOLLECTION.SHARING.BACK' | translate
    }}</button>
  </div>
</div>
