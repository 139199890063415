export interface Environment {
  appVersion: string;
  production: boolean;
}

export interface UIDimensions {
  width: number;
  height: number;
}

/* Local Storage Keys */
export const LS_usesMouse = 'uses_mouse';
export const LS_forceTouch = 'test-force-touch'; // Used solely in E2E Tests that require mouse detection to be suppressed (e.g.: buttons that disappear on hover), as Cypress does not yet have a .hover() command
