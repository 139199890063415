import { Action, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { EffectiveRole, RoleInfo, RoleToUserRelation } from '../../types/role.types';
import * as RolesAction from './roles.actions';

export const ROLES_FEATURE_KEY = 'roles';

export interface RolesState {
  roles: RoleInfo[];
  roleToUserRelations: RoleToUserRelation[];
  effectiveRoles: EffectiveRole[];
  loaded: boolean;
  effectiveRolesLoaded: boolean;
  error?: any;
}

export interface RolesPartialState {
  readonly [ROLES_FEATURE_KEY]: RolesState;
}

export const initialRoles: RoleInfo[] = [];

export const initialState: RolesState = {
  roles: initialRoles,
  roleToUserRelations: [],
  effectiveRoles: [],
  loaded: false,
  effectiveRolesLoaded: false
};

const RolesReducer = createReducer(
  initialState,
  immerOn(RolesAction.LoadRoles, (state) => {
    state.loaded = false;
  }),
  immerOn(RolesAction.RolesLoaded, (state, { roles, roleToUserRelations }) => {
    state.roles = roles;
    state.roleToUserRelations = roleToUserRelations || [];
    state.loaded = true;
  }),
  immerOn(RolesAction.LoadEffectiveRoles, (state) => {
    state.effectiveRolesLoaded = false;
  }),
  immerOn(RolesAction.LoadEffectiveRolesSuccess, (state, { roles }) => {
    state.effectiveRoles = roles;
    state.effectiveRolesLoaded = true;
  }),
  immerOn(RolesAction.DeleteUserRolesSuccess, (state, { relationIds }) => {
    state.roleToUserRelations = state.roleToUserRelations.filter((relation) => !relationIds.includes(relation.id));
  }),
  immerOn(RolesAction.AddRolesToUserSuccess, (state, { roleToUserRelations }) => {
    state.roleToUserRelations = [...state.roleToUserRelations, ...roleToUserRelations];
  })
);

export function reducer(state: RolesState | undefined, action: Action) {
  return RolesReducer(state, action);
}
