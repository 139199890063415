import { Component, OnInit } from '@angular/core';
import { MeasurementsFacade } from '../../../measurements/+state/measurements.facade';
import { map } from 'rxjs';
import { MeasurementMove } from '../../../measurements/measurements.types';

@Component({
  selector: 'cloud-header-move-operations',
  templateUrl: './header-move-operations.component.html',
  styleUrls: ['./header-move-operations.component.css']
})
export class HeaderMoveOperationsComponent implements OnInit {
  showMeasurementMoveInterface$;

  listActiveMovings: MeasurementMove[] = [];
  listDoneMovings: MeasurementMove[] = [];
  listErrorMovings: MeasurementMove[] = [];
  moveIcon: string = 'move_default';
  showMoveDropdown = false;
  firstTimeShown = false;

  constructor(public measurementsFacade: MeasurementsFacade) {}

  ngOnInit(): void {
    this.showMeasurementMoveInterface$ = this.measurementsFacade.movingMeasurements$.pipe(
      map((movingMeas) => {
        const activeMovings: MeasurementMove[] = [];
        const doneMovings: MeasurementMove[] = [];
        const errorMovings: MeasurementMove[] = [];

        for (const key in movingMeas) {
          switch (movingMeas[key].moveState) {
            case 'moving':
              activeMovings.push(movingMeas[key]);
              this.moveIcon = 'move_active';
              break;
            case 'done':
              doneMovings.push(movingMeas[key]);
              this.moveIcon = 'move_done';
              break;
            case 'error':
              errorMovings.push(movingMeas[key]);
              this.moveIcon = 'move_error';
              break;
          }
        }
        this.listActiveMovings = activeMovings;
        this.listDoneMovings = doneMovings;
        this.listErrorMovings = errorMovings;

        if (!this.firstTimeShown && activeMovings.length > 0) {
          this.showMoveDropdown = true;
          this.firstTimeShown = true;
        }

        if (this.firstTimeShown && activeMovings.length === 0 && doneMovings.length > 0) {
          this.firstTimeShown = false;
        }

        return Object.keys(movingMeas).length > 0;
      })
    );
  }
}
