<clr-modal
  [clrModalOpen]="showInfoModal$ | async"
  (clrModalOpenChange)="infoModalClosed$.next(true)"
  [clrModalClosable]="false"
>
  <div class="modal-title" data-cy="NavigationInfoModal">
    <clr-icon shape="exclamation-circle" class="is-solid" size="24"></clr-icon>
    <div id="text">{{ infoTitle$ | async }}</div>
    <clr-icon id="closeModalButton" shape="times" size="24" (click)="infoModalClosed$.next(true)"></clr-icon>
  </div>
  <div class="modal-body">
    <div id="modal-body-header">{{ infoDescription$ | async }}</div>
    <div id="modal-body-content">{{ 'APP.URLINF.MESSAGE.BODY_LIC' | translate }}</div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-link btn-small" type="button" (click)="back(); infoModalClosed$.next(true)">
      <clr-icon shape="angle" size="12" style="transform: rotate(270deg)"></clr-icon>
      {{ 'APP.URLINF.RESPONSES.BACK' | translate }}
    </button>
    <button class="btn btn-link btn-small" type="button" (click)="reload()">
      <clr-icon shape="refresh" size="12"></clr-icon>
      {{ 'APP.URLINF.RESPONSES.RETRY' | translate }}
    </button>
    <div id="info-id">Info-ID: {{ infoID$ | async }}</div>
  </div>
</clr-modal>
