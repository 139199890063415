import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ROLES_FEATURE_KEY, RolesState } from './roles.reducer';

const getRolesState = createFeatureSelector<RolesState>(ROLES_FEATURE_KEY);

const getLoaded = createSelector(getRolesState, (state: RolesState) => {
  return state.loaded;
});

const getEffectiveRolesLoaded = createSelector(getRolesState, (state: RolesState) => {
  return state.effectiveRolesLoaded;
});

const getRoles = createSelector(getRolesState, getLoaded, (state: RolesState, isLoaded) => {
  return isLoaded ? state.roles : undefined;
});

const getEffectiveRoles = createSelector(getRolesState, getLoaded, (state: RolesState, isLoaded) => {
  return isLoaded ? state.effectiveRoles : undefined;
});

const getRoleToUserRelations = createSelector(getRolesState, getLoaded, (state: RolesState, isLoaded) => {
  return isLoaded ? state.roleToUserRelations : undefined;
});

const getError = createSelector(getRolesState, (state: RolesState) => state.error);

export const rolesQuery = {
  getLoaded,
  getEffectiveRolesLoaded,
  getError,
  getRoleToUserRelations,
  getRoles,
  getEffectiveRoles
};
