import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

export type spinnerSize = 'small' | 'medium' | 'large' | 'xl';
@Component({
  selector: 'cloud-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css'],
  standalone: true,
  imports: [NgClass]
})
export class SpinnerComponent {
  @Input() inline: boolean = true;
  @Input() size: spinnerSize = 'small';
}
