import { createAction, props } from '@ngrx/store';
import {
  CloudRouterErrorDetail /*CloudRouterErrorHandling, CloudRouterErrorIdentifier*/,
  CloudRouterInfoDetail,
  CloudRouterQueryParamErrorDetail
} from './router.types';

export enum RouterActionTypes {
  CloudRouterQueryParamsError = '[Router] Cloud Router Query Params Error',
  CloudRouterError = '[Router] Cloud Router Error',
  CloudRouterInfo = '[Router] Cloud Router Info',
  //CloudRouterErrorHandled = '[Router] Cloud Router Error Handled',
  CloudRouterTimeout = '[Router] Timeout for Initial Navigation or User Navigation',
  UserNavigationStarted = '[Router] User Navigation Started',
  UserNavigationFinishedSuccessfully = '[Router] User Navigation Finished Successfully',
  UserNavigationWasRedirected = '[Router] User Navigation Was Redirected',
  Noop = '[Router] Noop'
}

export const CloudRouterQueryParamsError = createAction(
  RouterActionTypes.CloudRouterQueryParamsError,
  props<{
    error: CloudRouterQueryParamErrorDetail;
  }>()
);

export const CloudRouterError = createAction(
  RouterActionTypes.CloudRouterError,
  props<{
    error: CloudRouterErrorDetail;
  }>()
);

export const CloudRouterInfo = createAction(
  RouterActionTypes.CloudRouterInfo,
  props<{
    info: CloudRouterInfoDetail;
  }>()
);

// export const CloudRouterErrorHandled = createAction(
//   RouterActionTypes.CloudRouterErrorHandled,
//   props<{
//     error: CloudRouterErrorIdentifier;
//     changedTo: CloudRouterErrorHandling;
//   }>()
// );

export const CloudRouterTimeout = createAction(
  RouterActionTypes.CloudRouterTimeout,
  props<{ userNavigationId: number }>()
);

export const UserNavigationStarted = createAction(
  RouterActionTypes.UserNavigationStarted,
  props<{ userNavigationId: number; payload: any }>()
);

export const UserNavigationWasRedirected = createAction(
  RouterActionTypes.UserNavigationWasRedirected,
  props<{ userNavigationId: number; payload: any }>()
);

export const UserNavigationFinishedSuccessfully = createAction(
  RouterActionTypes.UserNavigationFinishedSuccessfully,
  props<{ id: number; payload: any }>()
);

export const Noop = createAction(RouterActionTypes.Noop);
