import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AppFacade } from '@root/apps/cloud/src/app/+state/app.facade';
import { RoleInfo } from '@root/apps/cloud/src/app/admin/admin/license/types/role.types';
import {
  ContentCollectionShareInfo,
  ContentCollectionShareType
} from '@root/apps/cloud/src/app/content-collection/content-collection.types';
import { UserFacade } from '@root/apps/cloud/src/app/user/+state/user.facade';
import { UserInfo } from '@root/apps/cloud/src/app/user/user.type';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';

type Option = { id: string; shareType: ContentCollectionShareType; name: string };

@Component({
  selector: 'cloud-share-autocomplete',
  templateUrl: './share-autocomplete.component.html',
  styleUrls: ['./share-autocomplete.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareAutocompleteComponent implements OnInit, AfterViewInit {
  @Input() alreadyShared: ContentCollectionShareInfo[];
  @Input() set shareInfo(info: { shareTo?: string }) {
    this.query = info?.shareTo ?? '';
  }

  @Output() optionSelected = new EventEmitter<Option>();

  @ViewChild('input') input: ElementRef;

  shareTypes = ContentCollectionShareType;

  query: string = this.shareInfo?.shareTo ?? '';
  isFocused: boolean = false;
  filteredOptions$: Observable<Option[]>;

  filterSubject = new BehaviorSubject<string>('');

  constructor(public userFacade: UserFacade, public appFacade: AppFacade) {}

  ngOnInit() {
    this.filteredOptions$ = combineLatest([
      this.userFacade.usersSelfCanSee$,
      this.userFacade.rolesSelfCanSee$,
      this.filterSubject
    ]).pipe(
      map(([users, roles, query]) => {
        const allOptions: Option[] = [...this.getRoleOptions(roles), ...this.getUserOptions(users)];
        return allOptions.filter(
          (option) =>
            !this.alreadyShared?.some((shared) => shared.sharedWith === option.id) &&
            option.name.toLowerCase().includes(query.toLowerCase())
        );
      })
    );
  }

  ngAfterViewInit(): void {
    this.input.nativeElement.focus();
    this.onFocus();
  }

  selectionChanged(option: Option): void {
    this.optionSelected.emit(option);
    this.query = option.name;
    this.isFocused = false;
  }

  onFocus(): void {
    this.isFocused = true;
    this.filterSubject.next(this.query);
  }

  onBlur(): void {
    this.isFocused = false;
  }

  private getUserOptions(users: Partial<UserInfo>[]): Option[] {
    return users.map((user) => ({
      id: user.id as string,
      shareType: ContentCollectionShareType.User,
      name: user.userName as string
    }));
  }

  private getRoleOptions(roles: Partial<RoleInfo>[]): Option[] {
    return roles
      .filter((role) => {
        return !!role.usersCanSeeEachOther;
      })
      .map((role) => ({
        id: role.id as string,
        shareType: ContentCollectionShareType.Role,
        name: role.name as string
      }));
  }
}
