import { Injectable, Inject } from '@angular/core';
import { ANGULAR_HTTP_CONTEXT } from '../app.tokens';
import { AngularHttpContext } from '@vas/angular-http-context';
import { CloudService } from '../services/cloud.service';
import { JsonApi } from '@muellerbbm-vas/grivet';
import {
  Measurement,
  MeasurementOverview,
  DatasetDescriptions,
  DatasetDescription,
  UnitUnderTest,
  TestSequence,
  Attributes,
  dynamicComponentIdentifiers,
  archivedStatus
} from './measurements.types';
import { NO_VALUE } from '../shared/pipes/format-datetime.pipe';

@Injectable({
  providedIn: 'root'
})
export class MeasurementsService {
  resourceIdentifierToFetchingMethodMap: {
    [key in dynamicComponentIdentifiers]: (measurementPath: string) => any;
  } = {
    overview: this.fetchOverview,
    unitsUnderTest: this.fetchUnitUnderTest,
    testSequencesAndAnys: this.fetchTestSequencesAndAnys,
    datasetDescription: this.fetchDatasetDescriptions
  };

  measurementCache: {
    [index: string]: JsonApi.Resource;
  } = {};

  constructor(@Inject(ANGULAR_HTTP_CONTEXT) private context: AngularHttpContext, private cloudService: CloudService) {
    this.bindFetchingMethods();
  }

  /*
  /   Resource fetching methods are called outside of their context when handed over as callbacks
  /   This method binds all these methods listed in resourceIdentifierToFetchingMethodMap to their current context.
  */
  bindFetchingMethods() {
    Object.keys(this.resourceIdentifierToFetchingMethodMap).forEach((key) => {
      this.resourceIdentifierToFetchingMethodMap[key] = this.resourceIdentifierToFetchingMethodMap[key].bind(this);
    });
  }

  async fetchMeasurement(measurementPath: string, identifier: dynamicComponentIdentifiers): Promise<Measurement> {
    const fn = this.resourceIdentifierToFetchingMethodMap[identifier];

    if (!fn) {
      console.error(
        `Attempted to fetch resource ${identifier}, alas, no valid fetchingMethod is registered.` +
          `Check property resourceIdentifierToFetchingMethodMap in measurement.service .`
      );
    }

    return fn(measurementPath);
  }

  async fetchOverview(measurementPath: string): Promise<MeasurementOverview> {
    const measurement: MeasurementOverview = {
      name: '',
      measurementId: '',
      size: 0,
      numberDataSets: 0,
      archivedStatus: archivedStatus.default,
      unitUnderTests: NO_VALUE,
      generalData: {}
    };

    const measurementResource = await this.getMeasurementResource(measurementPath);
    if (measurementResource === undefined) {
      return measurement;
    }

    measurement.name = measurementResource.attributes?.['name'] ?? '';
    measurement.measurementId = measurementResource.id;
    measurement.numberDataSets = measurementResource.relationships['datasets'].data?.['length'] ?? 0;

    const measurementProperties = await measurementResource.relatedResource['measurement_properties'];
    measurement.start = measurementProperties?.attributes?.['start_time'];
    measurement.size = measurementProperties?.attributes?.['approximate_datasets_size'] ?? 0;

    const modelResources = await measurementResource.relatedResources['model_relationships'];
    measurement.unitUnderTests = await this.fetchOverviewUnitUnderTestName(modelResources);
    measurement.generalData = await this.fetchOverviewGeneralData(measurementResource, modelResources);
    const archiveResource = await measurementResource.relatedResource['archive_properties'];
    measurement.archivedStatus = await this.fetchOverviewArchiveStatus(archiveResource);

    return measurement;
  }

  async fetchOverviewGeneralData(
    measurementResource: JsonApi.Resource,
    modelRelationships: JsonApi.Resource[]
  ): Promise<Attributes> {
    const generalData: Attributes = {};
    // For ATFX measurement: Check the model_attributes directly
    const modelAttributes = measurementResource.attributes?.['model_attributes'];
    for (const attribute of modelAttributes ?? []) {
      generalData[attribute['name']] = attribute['value'];
    }

    // For PAK measurements: Check all modelRelationships
    for (const item of modelRelationships) {
      const targets = await item.relatedResources['targets'];
      // usually just one target
      for (const target of targets) {
        const relatedResource = await target.relatedResource['content_type'];
        const aoBasename = relatedResource.attributes?.['ao_basename'];
        if (aoBasename.toLowerCase() === 'AoSubTest'.toLowerCase()) {
          // There can by only one AoSubTest, so no risk of overwriting anything
          const contentResource = await target.relatedResource['content'];
          for (const attribute of contentResource.attributes?.['model_attributes'] ?? []) {
            generalData[attribute['name']] = attribute['value'];
          }
        }
      }
    }
    return generalData;
  }

  async fetchOverviewUnitUnderTestName(modelRelationships: JsonApi.Resource[]): Promise<string> {
    for (const item of modelRelationships) {
      const targets = await item.relatedResources['targets'];
      for (const target of targets) {
        const relatedResource = await target.relatedResource['content_type'];
        const aoBasename = await relatedResource.attributes?.['ao_basename'];
        if (aoBasename.toLowerCase() === 'AoUnitUnderTest'.toLowerCase()) {
          const uutResource = await target.relatedResource['content'];
          return uutResource.attributes?.['name'];
        }
      }
    }
    return NO_VALUE;
  }

  async fetchOverviewArchiveStatus(archiveResource: JsonApi.Resource): Promise<archivedStatus> {
    const isArchived: boolean = (await archiveResource.attributes?.['is_archived']) ?? false;
    const isRestored: boolean = (await archiveResource.attributes?.['is_restored']) ?? false;
    if (isRestored) {
      return archivedStatus.restored;
    } else if (isArchived) {
      return archivedStatus.archived;
    } else {
      return archivedStatus.default;
    }
  }

  async fetchUnitUnderTest(measurementPath: string): Promise<UnitUnderTest[]> {
    const measurementResource = await this.getMeasurementResource(measurementPath);
    if (measurementResource === undefined) {
      return [];
    }

    const relatedResources = await measurementResource.relatedResources['model_relationships'];
    const uutList: UnitUnderTest[] = [];
    if (relatedResources) {
      for (const item of relatedResources) {
        const targets = await item.relatedResources['targets'];
        for (const target of targets) {
          const relatedResource = await target.relatedResource['content_type'];
          if ((await relatedResource.attributes?.['ao_basename']) === 'AoUnitUnderTest') {
            const uutResource = await target.relatedResource['content'];
            const uutId = uutResource.id;
            const uutName = uutResource.attributes?.['name'];
            const uutType = relatedResource.attributes?.['name'];
            const uutAttributes = await uutResource.attributes?.['model_attributes'];

            const uut: UnitUnderTest = {
              id: uutId,
              name: uutName,
              type: uutType,
              attributes: uutAttributes,
              parts: {},
              extended: true
            };
            const children = await uutResource.relatedResources['children'];
            for (const child of children) {
              const relatedPartResource = await child.relatedResource['content_type'];
              if (relatedPartResource.attributes?.['ao_basename'] === 'AoUnitUnderTestPart') {
                const partRessource = await child.relatedResource['content'];
                const partName = await relatedPartResource.attributes['name'];
                const uutPart = await partRessource.attributes?.['model_attributes'];
                uut.parts[partName] = uutPart;
              }
            }
            uutList.push(uut);
          }
        }
      }
    }
    return uutList;
  }

  async fetchTestSequencesAndAnys(measurementPath: string): Promise<TestSequence[]> {
    const measurementResource = await this.getMeasurementResource(measurementPath);
    if (measurementResource === undefined) {
      return [];
    }

    const testSequences: TestSequence[] = [];
    const relatedResources = (await measurementResource.relatedResources['model_relationships']) ?? [];
    for (const item of relatedResources) {
      const targets = (await item.relatedResources['targets']) ?? [];
      for (const target of targets) {
        const aoBasename = (await target.relatedResource['content_type']).attributes?.['ao_basename'];
        if (
          aoBasename &&
          (aoBasename.toLowerCase() === 'AoTestSequence'.toLowerCase() ||
            aoBasename.toLowerCase() === 'AoAny'.toLowerCase())
        ) {
          const testseq = await this.extractTestSequence(target);
          const children =
            (await target.relatedResource['content'].then((res) => res?.relatedResources['children'])) ?? [];
          for (const child of children) {
            const part = await this.extractTestSequence(child);
            testseq.parts[part.name] = part;
          }
          testSequences.push(testseq);
        } else if (aoBasename && aoBasename.toLowerCase() === 'aotestsequencepart') {
          let testseqpart = await this.extractTestSequence(target);
          let curAoBasename = aoBasename;
          while (curAoBasename.toLowerCase() === 'aotestsequencepart') {
            const parentPart = await await (await target.relatedResource['content']).relatedResource['parent'];
            const parent = await this.extractTestSequence(parentPart);
            parent.parts[testseqpart.name] = testseqpart;
            testseqpart = parent;
            curAoBasename = (await parentPart.relatedResource['content_type']).attributes?.['ao_basename'];
          }
          testSequences.push(testseqpart); // assuming that not multiple parts from the same testsequence are linked by the measurement
        }
      }
    }

    // add test sequences only linked by the parent
    const parentResource = await (await measurementResource.relatedResource['parent']).relatedResource['content'];
    const parentRelatedResources = (await parentResource.relatedResources['model_relationships']) ?? [];
    for (const item of parentRelatedResources) {
      const targets = (await item.relatedResources['targets']) ?? [];
      for (const target of targets) {
        const aoBasename = (await target.relatedResource['content_type']).attributes?.['ao_basename'];
        if (
          aoBasename &&
          aoBasename.toLowerCase() === 'aotestsequence' &&
          testSequences.every((tseq) => tseq.id !== target.id)
        ) {
          const testseq = await this.extractTestSequence(target);
          const children =
            (await target.relatedResource['content'].then((res) => res?.relatedResources['children'])) ?? [];
          for (const child of children) {
            const part = await this.extractTestSequence(child);
            testseq.parts[part.name] = part;
          }
          testSequences.push(testseq);
        }
      }
    }

    return testSequences.sort(this.compareTestSequence);
  }

  async extractTestSequence(target: JsonApi.Resource): Promise<TestSequence> {
    const relatedResource = await target.relatedResource['content_type'];
    const aoBasename = relatedResource?.attributes?.['ao_basename'] ?? '';
    const testseqType = relatedResource?.attributes?.['name'] ?? '';

    const testseqResource = await target.relatedResource['content'];
    const testseqId = testseqResource.id;
    const testseqName = testseqResource.attributes?.['name'] ?? '';
    const testseqAttributesRaw = testseqResource.attributes?.['model_attributes'] ?? [];
    const testseqAttributes = {};
    for (const attributeRaw of testseqAttributesRaw) {
      testseqAttributes[attributeRaw.name] = attributeRaw.value;
    }
    const testseqParts = {};

    return {
      id: testseqId,
      name: testseqName,
      type: testseqType,
      aoBasename: aoBasename,
      attributes: testseqAttributes,
      parts: testseqParts,
      extended: false
    };
  }

  compareTestSequence(a: TestSequence, b: TestSequence): number {
    // Reverse order for sorting the test sequence components
    const testSequenceReverseAsamOrder: string[] = [
      '',
      'AoAny'.toLowerCase(),
      'AoTestSequencePart'.toLowerCase(),
      'AoTestSequence'.toLowerCase(),
      'AoTestEquipmentPart'.toLowerCase(),
      'AoTestEquipment'.toLowerCase()
    ];

    // First sort according to asam type (any other Asam type get placed at the end)
    if (a.aoBasename !== b.aoBasename) {
      const aIndex = testSequenceReverseAsamOrder.indexOf(a.aoBasename.toLowerCase());
      const bIndex = testSequenceReverseAsamOrder.indexOf(b.aoBasename.toLowerCase());
      return bIndex - aIndex;
    }

    // For elements of the same type, sort according to the component name
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  }

  async fetchDatasetDescriptions(measurementPath: string): Promise<DatasetDescriptions> {
    const descriptions: DatasetDescriptions = {
      datasets: []
    };

    const measurementResource = await this.getMeasurementResource(measurementPath);
    if (measurementResource === undefined) {
      return descriptions;
    }

    const datasets = (await measurementResource.relatedResources['datasets']) ?? [];
    for (const dataset of datasets) {
      const description = await dataset.relatedResource['description'];
      const pakParams = await dataset.relatedResource['pak_parameters'];
      const depot = await dataset.relatedResource['depot'];
      const datasetDescription: DatasetDescription = {
        id: dataset.id,
        depotId: depot.id,
        attributes: description.attributes,
        PAKParameters: pakParams.attributes
      };
      descriptions.datasets.push(datasetDescription);
    }
    return descriptions;
  }

  async fetchDepotId(measurementPath: string): Promise<string | undefined> {
    const measurementResource = await this.getMeasurementResource(measurementPath);
    if (measurementResource === undefined) {
      return undefined;
    }

    const depotResource = await measurementResource.relatedResource['depot'];
    return depotResource.id;
  }

  async getMeasurementResource(measurementPath: string): Promise<JsonApi.Resource | undefined> {
    // First try loading the resource from cache
    const cachedMeasurement = this.measurementCache[measurementPath];
    if (cachedMeasurement !== undefined) {
      return cachedMeasurement;
    }

    // ... otherwise get it from the cloud
    const appStart = await this.cloudService.getAppStart('depot');
    if (!appStart) {
      return undefined;
    }
    const url = new URL(measurementPath, appStart['document'].url);
    // ... and here is the reason, why having dozens of relationships may not have been the best design decision ever taken:
    //     Without all these include-parameters the detail page is terribly slow to load!
    const includedParams = [
      'content_type',
      'children',
      'dataset_descriptions',
      'measurement_properties',
      'datasets.pak_parameters',
      'datasets.depot',
      'model_relationships.targets.content_type',
      'model_relationships.targets.content.content_type',
      'model_relationships.targets.content.measurement_properties',
      'model_relationships.targets.content.model_relationships.targets.content_type',
      'model_relationships.targets.content.model_relationships.targets.content',
      'model_relationships.targets.content.children.content_type',
      'model_relationships.targets.content.children.content.content_type',
      'model_relationships.targets.content.children.content.measurement_properties',
      'model_relationships.targets.content.children.content.model_relationships.targets.content_type',
      'model_relationships.targets.content.children.content.model_relationships.targets.content',
      'model_relationships.targets.content.parent.content_type',
      'model_relationships.targets.content.parent.content',
      'parent.content.model_relationships.targets.content_type',
      'parent.content.model_relationships.targets.content',
      'archive_properties',
      'storage_properties'
    ];
    url.searchParams.append('include', includedParams.join(','));
    const measurementDocument = await JsonApi.Document.fromURL(url, this.context);
    const measurementResource = measurementDocument.resource ?? undefined;
    // Write this resource into the cache
    if (measurementResource !== undefined) {
      this.measurementCache[measurementPath] = measurementResource;
    }
    return measurementResource;
  }

  // relocate
  // {'data': {'type': 'RelocateMeasurementOrder', 'attributes': {'delete_source': True, 'overwrite_existing': False, 'path_to_destination': ['Project', 'different', '2ndGear']}, 'relationships': {'origin_measurement': {'data': {'id': '9735be7d-7f18-49cf-a9d4-27484b440e80/Project/SampleMeasurementNoAnalysis/2ndGear', 'type': 'DepotBrowseContent'}}, 'destination_depot': {'data': {'id': '9735be7d-7f18-49cf-a9d4-27484b440e80', 'type': 'Depot'}}}}, 'included': []}
  // {'data': {'type': 'RelocateMeasurementOrder', 'attributes': {'delete_source': True, 'overwrite_existing': False, 'path_to_destination': ['Project', 'SampleMeasurementNoAnalysis', '2ndGear']}, 'relationships': {'origin_measurement': {'data': {'id': '5e11b786-6739-454c-9e58-4c18afc8eeab/Project/SampleMeasurementNoAnalysis/2ndGear', 'type': 'DepotBrowseContent'}}, 'destination_depot': {'data': {'id': 'ac02febc-d8f0-46a2-8008-3a2acbe7bc76', 'type': 'Depot'}}}}, 'included': []}

  //   private async createRelocationJob(sources: ProcessingRequestSource[], formula: ProcessingFormula): Promise<URL> {
  //     const attributes: Spec.AttributesObject = {
  //       relative_location_of_new_datasource: `processing_from_webapp/${uuid()}.zatfx`
  //     };
  //     const destinationResourceAtfx = this.createDestinationResource('ProcessingDestinationStreaming', attributes);
  //     const selfLink = await this.postProcessingJob(sources, destinationResourceAtfx, formula);
  //     return selfLink;
  // }
}
