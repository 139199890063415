<div
  class="list measurements-list"
  [ngClass]="{ inactive: (activeCollectionGuid$ | async) === undefined }"
  [attr.inert]="(activeCollectionGuid$ | async) === undefined ? true : null"
>
  <div class="header-container">
    <h4 class="dropdown-header">
      <div class="collection-name">
        {{ (selectedCC$ | async)?.name || 'CONTENTCOLLECTION.GENERAL.CONTENTCOLLECTION' | translate }}

        <ng-container *ngIf="selectedCC$ | async as selectedContentCollection">
          <ng-container *ngIf="canShowEditDelete(selectedContentCollection) | async">
            <cloud-svg-icon
              fileName="pencil"
              data-cy="CCEdit"
              (click)="$event.preventDefault(); $event.stopPropagation(); editCC.emit(selectedContentCollection)"
            ></cloud-svg-icon>

            <cloud-svg-icon
              fileName="trash_can"
              data-cy="CCDelete"
              (click)="$event.preventDefault(); $event.stopPropagation(); deleteCC.emit(selectedContentCollection)"
            ></cloud-svg-icon>
          </ng-container>
        </ng-container>
      </div>
      <div *ngIf="selectedCC$ | async as selectedContentCollection" class="right-side">
        <span
          class="btn btn-outline btn-share"
          *ngIf="
            !!(featureFlagFacade.featureValue$('CONTENT_COLLECTION_SHARE') | async) &&
            selectedContentCollection.createdBy === (userFacade.self$ | async)?.id
          "
          (click)="$event.preventDefault(); $event.stopPropagation(); shareCC.emit(selectedContentCollection)"
        >
          <cloud-svg-icon fileName="share"></cloud-svg-icon> {{ 'CONTENTCOLLECTION.SHARING.SHARE' | translate }}
          <span class="separator"></span>
          <cloud-svg-icon fileName="shared_with"></cloud-svg-icon>
          {{ selectedContentCollection.shareInfo?.length || 0 }}
        </span>
        <span
          class="btn btn-primary btn-activate"
          *ngIf="selectedContentCollection.guid !== (activeCollectionGuid$ | async)"
          data-cy="CCActivateSelected"
          (click)="$event.stopPropagation(); activateContentCollection(selectedContentCollection.guid, $event)"
          ><cloud-svg-icon fileName="play-white"></cloud-svg-icon>
          {{ 'CONTENTCOLLECTION.GENERAL.ACTIVATE' | translate }}</span
        >
      </div>
    </h4>

    <ng-container *ngIf="selectedCC$ | async as selectedCC">
      <div
        class="share-info"
        *ngIf="
          !!(featureFlagFacade.featureValue$('CONTENT_COLLECTION_SHARE') | async) &&
          selectedCC?.createdBy !== (userFacade.self$ | async)?.id
        "
      >
        <span class="share-from">
          <cloud-svg-icon fileName="share_clock" class="share-clock-icon"></cloud-svg-icon>
          {{ getSharedFromName(selectedCC) | async }} shared with you.
        </span>
        <span>
          {{ 'CONTENTCOLLECTION.SHARING.EXPIREAT' | translate }}
          {{ selectedCC.myAccessInfo?.validUntil | formatDateTime }}

          <clr-dropdown class="share-options">
            <cloud-svg-icon fileName="chevron" clrDropdownTrigger></cloud-svg-icon>
            <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
              <div
                (click)="$event.preventDefault(); $event.stopPropagation(); declineCCShare.emit(selectedCC)"
                clrDropdownItem
                class="decline-share-btn"
              >
                {{ 'CONTENTCOLLECTION.SHARING.DECLINE' | translate }}
              </div>
            </clr-dropdown-menu>
          </clr-dropdown>
        </span>
      </div>
    </ng-container>
  </div>

  <div class="dropdown-divider" role="separator"></div>

  <clr-datagrid
    [clrDgSelected]="(selectedCC$ | async)?.myAccessInfo?.canRemoveItems && selectedItems$ | async"
    (clrDgSelectedChange)="selectedItems$.next($event)"
    class="measurements-table"
  >
    <clr-dg-column [clrDgSortBy]="'content.depot'">{{ 'CONTENTCOLLECTION.GENERAL.DEPOT' | translate }}</clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'content.project'">{{
      'CONTENTCOLLECTION.GENERAL.PROJECT' | translate
    }}</clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'content.job'">{{ 'CONTENTCOLLECTION.GENERAL.JOB' | translate }}</clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'content.measurementName'">{{
      'CONTENTCOLLECTION.GENERAL.MEASUREMENT' | translate
    }}</clr-dg-column>
    <clr-dg-column
      [clrDgSortBy]="'content.measurementStartTime'"
      [clrDgSortOrder]="clrSortOrder.DESC"
      class="start-time-col"
      >{{ 'CONTENTCOLLECTION.GENERAL.STARTTIME' | translate }}</clr-dg-column
    >
    <clr-dg-placeholder>
      <div class="noItems">
        {{ 'CONTENTCOLLECTION.GENERAL.NOITEMSINCC.0' | translate }}
        <br />
        {{ 'CONTENTCOLLECTION.GENERAL.NOITEMSINCC.1' | translate }}
      </div>
    </clr-dg-placeholder>
    <ng-container *ngIf="selectedCC$ | async as selectedContentCollection">
      <clr-dg-row *clrDgItems="let item of selectedContentCollection.items" [clrDgItem]="item">
        <clr-dg-cell [title]="item.content.depot">{{ item.content.depot }}</clr-dg-cell>
        <clr-dg-cell [title]="item.content.project">{{ item.content.project }}</clr-dg-cell>
        <clr-dg-cell [title]="item.content.job">{{ item.content.job }}</clr-dg-cell>
        <clr-dg-cell [title]="item.content.measurementName">{{ item.content.measurementName }}</clr-dg-cell>
        <clr-dg-cell [title]="item.content.measurementStartTime | formatDateTime" class="start-time"
          ><span>{{ item.content.measurementStartTime | formatDateTime }}</span>
          <span
            *ngIf="(selectedCC$ | async)?.myAccessInfo?.canRemoveItems"
            class="remove-single-icon"
            (click)="removeSingle(item, selectedContentCollection)"
          >
            <clr-icon shape="trash" size="20" class="is-error"></clr-icon>
          </span>
        </clr-dg-cell>
      </clr-dg-row>
    </ng-container>
  </clr-datagrid>

  <ng-container *ngIf="selectedCC$ | async as selectedContentCollection">
    <div class="controls" [style.visibility]="(selectedItems$ | async)?.length > 0 ? 'visible' : 'hidden'">
      <button
        (click)="removeSelected(selectedContentCollection)"
        data-cy="REMOVE_ALL_BUTTON"
        class="remove-selected-btn btn btn-sm"
        >{{ 'CONTENTCOLLECTION.GENERAL.REMOVESELECTED' | translate }} ({{ (selectedItems$ | async)?.length }})
        <cloud-svg-icon-customizable stroke="#FF5B5B" fileName="cross_red"></cloud-svg-icon-customizable
      ></button>
    </div>
  </ng-container>
</div>
