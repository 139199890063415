import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { availableLanguagesStrings } from '../../app.locales';

@Pipe({
  name: 'formatFileSize',
  pure: false
})
export class FormatFileSizePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: number | undefined): string {
    if (value === undefined) {
      return '';
    }
    const [floatSize, unit] = this.findExponent(value);
    const currentLanguage = this.translate.currentLang as availableLanguagesStrings;
    const formattedSize = formatNumber(floatSize, currentLanguage, '1.0-2');
    return formattedSize + unit;
  }

  findExponent(number: number): [number, string] {
    if (number < 1024) {
      return [number, ' Byte'];
    } else if (number < 1024 ** 2) {
      return [number / 1024, ' kByte'];
    } else if (number < 1024 ** 3) {
      return [number / 1024 ** 2, ' MByte'];
    } else {
      return [number / 1024 ** 3, ' GByte'];
    }
  }
}
