import { Spec } from '@muellerbbm-vas/grivet';
import {
  AttributeDiscriminator,
  AttributeGroup,
  AttributeType,
  DepotAttribute,
  SemanticDepotAttribute
} from '../../shared/+state/attributes/attributes.types';
import { Depot, pendingDeletionAttrId } from '../../shared/types/search.types';

interface DepotAttributeContainer {
  id: string;
  aoBasename: string;
  parentId?: string;
}

export class DepotAttributeParser {
  constructor() {}

  private depotAttributes: { [id: string]: DepotAttribute } = {};
  private depotAttributeContainers: { [id: string]: DepotAttributeContainer } = {};
  private semanticDepotAttributes: { [id: string]: SemanticDepotAttribute } = {};
  private semanticDepotAttributeContainers: { [id: string]: AttributeGroup } = {};
  private depots: { [id: string]: Depot } = {};

  public parseResponse(rawData: Spec.JsonApiDocument): void {
    const attribute2DepotMap: { [id: string]: string[] } = {};
    const attribute2ContainerMap: { [id: string]: string | undefined } = {};
    const attribute2SemanticMap: { [id: string]: string | undefined } = {};

    // Parse main resources, which are always DepotAttributes
    for (const data of rawData.data as Spec.ResourceObject[]) {
      if (data.attributes) {
        const idName = data.attributes['fullname'];
        const displayName = data.attributes['name'];
        const searchable = data.attributes['searchable'];
        const type = this.getAttributeTypeFromName(idName);
        this.depotAttributes[idName] = {
          discriminator: 'DepotAttribute' as AttributeDiscriminator,
          idName: idName,
          displayName: displayName,
          searchable: searchable,
          type: type,
          semanticAttribute: undefined,
          usedInDepots: [],
          aoBaseName: undefined
        };
        attribute2DepotMap[idName] = this.extractRelatedIDs(data, 'depots');
        attribute2ContainerMap[idName] = this.extractOptionalRelatedID(data, 'parent');
        attribute2SemanticMap[idName] = this.extractOptionalRelatedID(data, 'semantic_attribute');
      }
    }

    // Parse all included data
    const includedData: Spec.ResourceObject[] = rawData.included ?? [];
    for (const resourceData of includedData) {
      switch (resourceData.type) {
        case 'Depot':
          this.depots[resourceData.id] = {
            id: resourceData.id,
            name: resourceData.attributes?.['name']
          };
          break;

        case 'DepotAttributeContainer':
          this.depotAttributeContainers[resourceData.id] = {
            id: resourceData.id,
            aoBasename: resourceData.attributes?.['ao_basename'] ?? '',
            parentId: this.extractOptionalRelatedID(resourceData, 'parent')
          };
          break;

        case 'SemanticDepotAttribute':
          const depotAttributesRelation = resourceData.relationships?.['depot_attributes'].data
          let semanticAttrType: AttributeType;
          if (depotAttributesRelation === undefined) {
            console.warn('The relation from SemanticDepotAttribute to depot_attributes unexpectedly is undefined, assuming type String');
            semanticAttrType = 'String';
          } else {
            semanticAttrType = this.getAttributeTypeFromRelatedDepotAttributes(depotAttributesRelation as Spec.ResourceIdentifierObject[]);
          }
          this.semanticDepotAttributes[resourceData.id] = {
            discriminator: 'SemanticDepotAttribute',
            id: resourceData.id,
            name: resourceData.attributes?.['name'] ?? '',
            type: semanticAttrType,
            translations: resourceData.attributes?.['translations'] ?? {},
            attributeGroupID: this.extractOptionalRelatedID(resourceData, 'container') ?? null,
            relatedDepotAttributeIds: []
          };
          break;

        case 'SemanticDepotAttributeContainer':
          this.semanticDepotAttributeContainers[resourceData.id] = {
            id: resourceData.id,
            name: resourceData.attributes?.['name'] ?? '',
            translations: resourceData.attributes?.['translations'] ?? {}
          };
          break;
      }
    }

    // Set relationships correctly
    this.setDepotRelationships(attribute2DepotMap);
    this.setSemanticRelationships(attribute2SemanticMap);
    this.setAoBasenames(attribute2ContainerMap);
  }

  public getAllAttributes(): DepotAttribute[] {
    return Object.values(this.depotAttributes);
  }

  public getAllSemanticAttributes(): SemanticDepotAttribute[] {
    return Object.values(this.semanticDepotAttributes);
  }

  public getAllSemanticContainers(): AttributeGroup[] {
    return Object.values(this.semanticDepotAttributeContainers);
  }

  private extractOptionalRelatedID(resourceData: Spec.ResourceObject, relationshipKey: string): string | undefined {
    const data = resourceData.relationships?.[relationshipKey].data;
    return Array.isArray(data) ? undefined : data?.id;
  }

  private extractRelatedIDs(resourceData: Spec.ResourceObject, relationshipKey: string): string[] {
    const data = resourceData.relationships?.[relationshipKey].data;
    return Array.isArray(data) ? data.map((item) => item.id) : [];
  }

  private getAttributeTypeFromName(attributeName?: string): AttributeType {
    if (attributeName === 'measurement_properties.start_time') {
      return 'Date';
    }
    if (attributeName === 'archive_properties.is_archived') {
      return 'Boolean';
    }
    if (attributeName === 'archive_properties.is_restored') {
      return 'Boolean';
    }
    if (attributeName === pendingDeletionAttrId) {
      return 'Boolean';
    }
    return 'String';
  }

  private getAttributeTypeFromRelatedDepotAttributes(relationshipData: Spec.ResourceIdentifierObject[]): AttributeType {
    const typesOfRelatedDepotAttributes = relationshipData.map(relationship => this.depotAttributes[relationship.id].type)
    if (typesOfRelatedDepotAttributes.every(depotAttrType => depotAttrType === 'Boolean')) {
      return 'Boolean';
    }
    if (typesOfRelatedDepotAttributes.every(depotAttrType => depotAttrType === 'Date')) {
      return 'Date';
    }
    return 'String'
  }

  private setDepotRelationships(attribute2DepotMap: { [id: string]: string[] }) {
    Object.values(this.depotAttributes).forEach(
      (attr) => (attr.usedInDepots = attribute2DepotMap[attr.idName].map((id) => this.depots[id]))
    );
  }

  private setSemanticRelationships(attribute2SemanticMap: { [id: string]: string | undefined }) {
    Object.values(this.depotAttributes).forEach((attr) => {
      const semanticId = attribute2SemanticMap[attr.idName];
      if (semanticId) {
        // Set relationship from DepotAttribute to SemanticDepotAttribute
        attr.semanticAttribute = this.semanticDepotAttributes[semanticId];
        // Set backwards relationship from SemanticDepotAttribute to DepotAttribute
        this.semanticDepotAttributes[semanticId].relatedDepotAttributeIds.push(attr.idName);
      }
    });
  }

  private setAoBasenames(attribute2ContainerMap: { [id: string]: string | undefined }) {
    Object.values(this.depotAttributes).forEach((attr) => {
      const containerId = attribute2ContainerMap[attr.idName];
      if (!containerId) {
        attr.aoBaseName = undefined;
      }
      const container = this.depotAttributeContainers[containerId!];
      attr.aoBaseName = container?.aoBasename;
      if (!attr.aoBaseName && container?.parentId) {
        // Special hack for "table-like" attributes in PAK-models
        // These end with a number and have no aoBaseName themselves, but instead their parents do.
        const lastPart = attr.idName.split('.').pop();
        const isLastPartNumber = !isNaN(Number(lastPart));
        if (isLastPartNumber) {
          attr.aoBaseName = this.depotAttributeContainers[container.parentId]?.aoBasename;
        }
      }
    });
  }
}
