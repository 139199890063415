import { Inject, Injectable } from '@angular/core';
import { JsonApi, Spec } from '@muellerbbm-vas/grivet';
import { AngularHttpContext } from '@root/libs/vas-angular-http-context/src';
import { Observable, from, map, mergeMap, switchMap, toArray } from 'rxjs';
import { ANGULAR_HTTP_CONTEXT } from '../../../../app.tokens';
import { CloudService } from '../../../../services/cloud.service';
import { GroupInfo } from '../types/group.types';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  constructor(@Inject(ANGULAR_HTTP_CONTEXT) private context: AngularHttpContext, private cloudService: CloudService) {}

  async ensureGroupsManagementAppStart() {
    return await this.cloudService.getAppStart('user_management');
  }

  getGroups(): Observable<GroupInfo[]> {
    return this.cloudService.getAppStartObs('user_management').pipe(
      switchMap((res) => {
        const baseGroupsUrl = new URL(res?.relationships['groups'].links?.related.url.href ?? 'https://example.com');

        baseGroupsUrl.searchParams.append('include', 'group2role');

        return from(JsonApi.Document.fromURL(baseGroupsUrl, this.context));
      }),
      mergeMap((res) =>
        from(res.resources).pipe(
          toArray(),
          map((groups) => groups.map((data) => this.createGroup(data['rawData'], res)))
        )
      )
    );
  }

  private createGroup(groupDoc: JsonApi.Resource['rawData'], res: JsonApi.Document): GroupInfo {
    const attribs: Spec.AttributesObject | undefined = groupDoc.attributes;

    const roles = Object.values(res.includedResources?.Group2Role)
      ?.filter((role) => (role.relationships?.group?.data as Spec.ResourceIdentifierObject)?.id === groupDoc.id)
      .map((role) => (role.relationships?.role?.data as Spec.ResourceIdentifierObject)?.id);

    const group: GroupInfo = {
      id: groupDoc.id,
      name: attribs?.['name'],
      isDefaultGroup: attribs?.['is_default_group'],
      roles: roles
    };

    return group;
  }
}
