import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspaceService } from './workspace.service';
import { WorkspaceFacade } from './+state/workspace.facade';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  workspaceReducer,
  WORKSPACE_FEATURE_KEY,
  initialState as workspaceInitialState
} from './+state/workspace.reducer';
import { WorkspaceEffects } from './+state/workspace.effects';

export const imports = [
  CommonModule,
  StoreModule.forFeature(WORKSPACE_FEATURE_KEY, workspaceReducer, { initialState: workspaceInitialState }),
  EffectsModule.forFeature([WorkspaceEffects])
];

@NgModule({
  imports: [imports],
  providers: [WorkspaceService, WorkspaceFacade],
  declarations: []
})
export class WorkspaceModule {}
