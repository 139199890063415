// @generated by protobuf-ts 2.8.2 with parameter long_type_string
// @generated from protobuf file "streamingService.proto" (package "BBMgRPCstreamingNS", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { DatasetChunk } from "./DatasetMessages";
import { DatasetMetaData } from "./DatasetMessages";
import { Channels } from "./DatasetMessages";
import { MeasChainDevices } from "./DatasetMessages";
import { Quantities } from "./DatasetMessages";
import { AbsoluteTimeRange } from "./DatasetMessages";
import { DatasetKeyData } from "./DatasetMessages";
/**
 * --------------------------------------------------------
 * --------------------------------------------------------
 * rpc call EnumerateDatasets Paramter
 *
 * @generated from protobuf message BBMgRPCstreamingNS.DataSourceIdentifier
 */
export interface DataSourceIdentifier {
    /**
     * @generated from protobuf oneof: identifiers
     */
    identifiers: {
        oneofKind: "measurementPath";
        /**
         * @generated from protobuf field: string measurement_path = 1;
         */
        measurementPath: string;
    } | {
        oneofKind: "token";
        /**
         * @generated from protobuf field: string token = 2;
         */
        token: string;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.EnumerateDatasetsParameters
 */
export interface EnumerateDatasetsParameters {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataSourceIdentifier data_source = 1;
     */
    dataSource?: DataSourceIdentifier;
}
/**
 * --------------------------------------------------------
 * -------- EnumerateDatasets Response
 *
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetLocator
 */
export interface DatasetLocator {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string; // id - given from server '[<channel list index>][<position label>][<data type string>]'
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataSourceIdentifier data_source = 2;
     */
    dataSource?: DataSourceIdentifier; // measurement path 
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetKeyDataList
 */
export interface DatasetKeyDataList {
    /**
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.DatasetKeyDataList.Item items = 1;
     */
    items: DatasetKeyDataList_Item[]; // one item per unique 'position & type & ...'
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetKeyDataList.Item
 */
export interface DatasetKeyDataList_Item {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetLocator locator = 1;
     */
    locator?: DatasetLocator; // measurement path & id - given from server
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetKeyData keydata = 2;
     */
    keydata?: DatasetKeyData; // label & dir & type of dataset
}
// --------------------------------------------------------
// --------------------------------------------------------
// rpc call DatasetsMetaData Paramter

// analog ReadDataSelectionAnalysis

// message AnalysisAllTime
// {
//     Quantity quantity_z = 1;
// }
// 
// message AnalysisStep
// {
//     Quantity quantity_z = 1;
//     int      stepnumber = 2;
// }
// 
// message AnalysisTime
// {
//     Quantity quantity_z = 1;
//     double time_min = 2;
//     double time_max = 3;
// }
// 
// message AnalysisBySteps
// {
//     Quantity quantity_z = 1;
//     repeated int measurementSteps = 2;
// }
// 
// message ByStepsWithTrack
// {
//     Quantity quantity_z = 1;
//     double time_min = 2;
//     double time_max = 3;
//     repeated int measurementSteps = 4;
//     // BBMChannel          *m_trackChan;  //!< BBMchannel of the track channel or NULL if track is time
//     // BBMPosition         *m_trackPos; //!< BBMPosition of the track channel or NULL if track is time
//     // const BBMDataSet3DSlowPtr & track_data
//     // GDataProviderGD_DataSelection::TrackType trackType,
//     // double m_diffTime;
//     // BBMDataSet3DSlowPtr m_spDataSetSlowQuantity;
//     // double m_timeDate;
//     // double m_trackMin;
//     // double m_trackMax;
//     // int m_monoType;
//     // double              m_measStart;  //!< start time of measurement
//     // std::vector<double> m_deltaTimes; //!< steps times of the track channel or empty
//     // std::vector<double> m_trackVals;  //!< track values corresponding to m_deltaTimes or empty
// }
// 
// message StepSelection
// {
//     oneof type 
//     {
//         AnalysisAllTime analysis_all_time = 1;
//         AnalysisStep    analysis_step = 2;
//         AnalysisTime    analysis_time = 3;
//         AnalysisBySteps analysis_by_steps = 4;
//         ByStepsWithTrack analysis_by_steps_with_track = 5;
//     }
// }
// 
// message Selection
// { 
//     oneof type
//     {
//         AbsoluteTimeRange time_range = 1;
//         StepSelection     step_selection = 2;
//     }
// }

/**
 * @generated from protobuf message BBMgRPCstreamingNS.StreamDatasetsParameters
 */
export interface StreamDatasetsParameters {
    /**
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.StreamDatasetsParameters.SubscribedDataset subscribed_datasets = 1;
     */
    subscribedDatasets: StreamDatasetsParameters_SubscribedDataset[];
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.StreamDatasetsParameters.SubscribedDataset
 */
export interface StreamDatasetsParameters_SubscribedDataset {
    /**
     * @generated from protobuf field: uint32 subscribe_id = 1;
     */
    subscribeId: number; // A client given ID (unique for the current RPC call) for this SubscribedDataset
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetLocator locator = 2;
     */
    locator?: DatasetLocator; // measurement path & id - given from server      
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.StreamDatasetsParameters.SubscribedDataset.StreamDataType stream_data_type = 3;
     */
    streamDataType: StreamDatasetsParameters_SubscribedDataset_StreamDataType;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.AbsoluteTimeRange time_range = 4;
     */
    timeRange?: AbsoluteTimeRange;
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.StreamDatasetsParameters.SubscribedDataset.StreamDataType
 */
export enum StreamDatasetsParameters_SubscribedDataset_StreamDataType {
    /**
     * @generated from protobuf enum value: StreamFullData = 0;
     */
    StreamFullData = 0,
    /**
     * @generated from protobuf enum value: StreamOnlyTimeSteps = 1;
     */
    StreamOnlyTimeSteps = 1,
    /**
     * @generated from protobuf enum value: StreamWithoutStepValues = 2;
     */
    StreamWithoutStepValues = 2
}
// --------------------------------------------------------
// rpc call DatasetsMetaData Response

/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetMetaDataList
 */
export interface DatasetMetaDataList {
    /**
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.DatasetMetaDataList.Item items = 1;
     */
    items: DatasetMetaDataList_Item[];
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.Quantities quantities = 2;
     */
    quantities?: Quantities;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.MeasChainDevices measChainDevices = 3;
     */
    measChainDevices?: MeasChainDevices;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.Channels channels = 4;
     */
    channels?: Channels;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetMetaDataList.Item
 */
export interface DatasetMetaDataList_Item {
    /**
     * @generated from protobuf field: uint32 subscribe_id = 1;
     */
    subscribeId: number; // The client given subscribe_id of the StreamDatasetsParameters::SubscribedDataset this chunk belongs to.
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetMetaData metadata = 2;
     */
    metadata?: DatasetMetaData;
}
// --------------------------------------------------------
// --------------------------------------------------------
// rpc call StreamDatasets Response

/**
 * @generated from protobuf message BBMgRPCstreamingNS.SubscribedDatasetChunk
 */
export interface SubscribedDatasetChunk {
    /**
     * @generated from protobuf field: uint32 subscribe_id = 1;
     */
    subscribeId: number; // The client given subscribe_id of the StreamDatasetsParameters::SubscribedDataset this chunk belongs to.
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetChunk chunk = 2;
     */
    chunk?: DatasetChunk;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.StreamDatasetsResponse
 */
export interface StreamDatasetsResponse {
    /**
     * @generated from protobuf oneof: type
     */
    type: {
        oneofKind: "datasets";
        /**
         * SubscribedDatasetChunk  dataset  = 1;
         *
         * @generated from protobuf field: BBMgRPCstreamingNS.StreamDatasetsResponse.SubscribedDatasetChunks datasets = 2;
         */
        datasets: StreamDatasetsResponse_SubscribedDatasetChunks;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.StreamDatasetsResponse.SubscribedDatasetChunks
 */
export interface StreamDatasetsResponse_SubscribedDatasetChunks {
    /**
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.SubscribedDatasetChunk datasets = 1;
     */
    datasets: SubscribedDatasetChunk[];
}
// @generated message type with reflection information, may provide speed optimized methods
class DataSourceIdentifier$Type extends MessageType<DataSourceIdentifier> {
    constructor() {
        super("BBMgRPCstreamingNS.DataSourceIdentifier", [
            { no: 1, name: "measurement_path", kind: "scalar", oneof: "identifiers", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "token", kind: "scalar", oneof: "identifiers", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DataSourceIdentifier>): DataSourceIdentifier {
        const message = { identifiers: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DataSourceIdentifier>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DataSourceIdentifier): DataSourceIdentifier {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string measurement_path */ 1:
                    message.identifiers = {
                        oneofKind: "measurementPath",
                        measurementPath: reader.string()
                    };
                    break;
                case /* string token */ 2:
                    message.identifiers = {
                        oneofKind: "token",
                        token: reader.string()
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DataSourceIdentifier, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string measurement_path = 1; */
        if (message.identifiers.oneofKind === "measurementPath")
            writer.tag(1, WireType.LengthDelimited).string(message.identifiers.measurementPath);
        /* string token = 2; */
        if (message.identifiers.oneofKind === "token")
            writer.tag(2, WireType.LengthDelimited).string(message.identifiers.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DataSourceIdentifier
 */
export const DataSourceIdentifier = new DataSourceIdentifier$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EnumerateDatasetsParameters$Type extends MessageType<EnumerateDatasetsParameters> {
    constructor() {
        super("BBMgRPCstreamingNS.EnumerateDatasetsParameters", [
            { no: 1, name: "data_source", kind: "message", T: () => DataSourceIdentifier }
        ]);
    }
    create(value?: PartialMessage<EnumerateDatasetsParameters>): EnumerateDatasetsParameters {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EnumerateDatasetsParameters>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EnumerateDatasetsParameters): EnumerateDatasetsParameters {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.DataSourceIdentifier data_source */ 1:
                    message.dataSource = DataSourceIdentifier.internalBinaryRead(reader, reader.uint32(), options, message.dataSource);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EnumerateDatasetsParameters, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.DataSourceIdentifier data_source = 1; */
        if (message.dataSource)
            DataSourceIdentifier.internalBinaryWrite(message.dataSource, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.EnumerateDatasetsParameters
 */
export const EnumerateDatasetsParameters = new EnumerateDatasetsParameters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetLocator$Type extends MessageType<DatasetLocator> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetLocator", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "data_source", kind: "message", T: () => DataSourceIdentifier }
        ]);
    }
    create(value?: PartialMessage<DatasetLocator>): DatasetLocator {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetLocator>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetLocator): DatasetLocator {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* BBMgRPCstreamingNS.DataSourceIdentifier data_source */ 2:
                    message.dataSource = DataSourceIdentifier.internalBinaryRead(reader, reader.uint32(), options, message.dataSource);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetLocator, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* BBMgRPCstreamingNS.DataSourceIdentifier data_source = 2; */
        if (message.dataSource)
            DataSourceIdentifier.internalBinaryWrite(message.dataSource, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetLocator
 */
export const DatasetLocator = new DatasetLocator$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetKeyDataList$Type extends MessageType<DatasetKeyDataList> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetKeyDataList", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DatasetKeyDataList_Item }
        ]);
    }
    create(value?: PartialMessage<DatasetKeyDataList>): DatasetKeyDataList {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetKeyDataList>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetKeyDataList): DatasetKeyDataList {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BBMgRPCstreamingNS.DatasetKeyDataList.Item items */ 1:
                    message.items.push(DatasetKeyDataList_Item.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetKeyDataList, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BBMgRPCstreamingNS.DatasetKeyDataList.Item items = 1; */
        for (let i = 0; i < message.items.length; i++)
            DatasetKeyDataList_Item.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetKeyDataList
 */
export const DatasetKeyDataList = new DatasetKeyDataList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetKeyDataList_Item$Type extends MessageType<DatasetKeyDataList_Item> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetKeyDataList.Item", [
            { no: 1, name: "locator", kind: "message", T: () => DatasetLocator },
            { no: 2, name: "keydata", kind: "message", T: () => DatasetKeyData }
        ]);
    }
    create(value?: PartialMessage<DatasetKeyDataList_Item>): DatasetKeyDataList_Item {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetKeyDataList_Item>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetKeyDataList_Item): DatasetKeyDataList_Item {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.DatasetLocator locator */ 1:
                    message.locator = DatasetLocator.internalBinaryRead(reader, reader.uint32(), options, message.locator);
                    break;
                case /* BBMgRPCstreamingNS.DatasetKeyData keydata */ 2:
                    message.keydata = DatasetKeyData.internalBinaryRead(reader, reader.uint32(), options, message.keydata);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetKeyDataList_Item, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.DatasetLocator locator = 1; */
        if (message.locator)
            DatasetLocator.internalBinaryWrite(message.locator, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetKeyData keydata = 2; */
        if (message.keydata)
            DatasetKeyData.internalBinaryWrite(message.keydata, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetKeyDataList.Item
 */
export const DatasetKeyDataList_Item = new DatasetKeyDataList_Item$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamDatasetsParameters$Type extends MessageType<StreamDatasetsParameters> {
    constructor() {
        super("BBMgRPCstreamingNS.StreamDatasetsParameters", [
            { no: 1, name: "subscribed_datasets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StreamDatasetsParameters_SubscribedDataset }
        ]);
    }
    create(value?: PartialMessage<StreamDatasetsParameters>): StreamDatasetsParameters {
        const message = { subscribedDatasets: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StreamDatasetsParameters>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StreamDatasetsParameters): StreamDatasetsParameters {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BBMgRPCstreamingNS.StreamDatasetsParameters.SubscribedDataset subscribed_datasets */ 1:
                    message.subscribedDatasets.push(StreamDatasetsParameters_SubscribedDataset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StreamDatasetsParameters, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BBMgRPCstreamingNS.StreamDatasetsParameters.SubscribedDataset subscribed_datasets = 1; */
        for (let i = 0; i < message.subscribedDatasets.length; i++)
            StreamDatasetsParameters_SubscribedDataset.internalBinaryWrite(message.subscribedDatasets[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.StreamDatasetsParameters
 */
export const StreamDatasetsParameters = new StreamDatasetsParameters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamDatasetsParameters_SubscribedDataset$Type extends MessageType<StreamDatasetsParameters_SubscribedDataset> {
    constructor() {
        super("BBMgRPCstreamingNS.StreamDatasetsParameters.SubscribedDataset", [
            { no: 1, name: "subscribe_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "locator", kind: "message", T: () => DatasetLocator },
            { no: 3, name: "stream_data_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.StreamDatasetsParameters.SubscribedDataset.StreamDataType", StreamDatasetsParameters_SubscribedDataset_StreamDataType] },
            { no: 4, name: "time_range", kind: "message", T: () => AbsoluteTimeRange }
        ]);
    }
    create(value?: PartialMessage<StreamDatasetsParameters_SubscribedDataset>): StreamDatasetsParameters_SubscribedDataset {
        const message = { subscribeId: 0, streamDataType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StreamDatasetsParameters_SubscribedDataset>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StreamDatasetsParameters_SubscribedDataset): StreamDatasetsParameters_SubscribedDataset {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 subscribe_id */ 1:
                    message.subscribeId = reader.uint32();
                    break;
                case /* BBMgRPCstreamingNS.DatasetLocator locator */ 2:
                    message.locator = DatasetLocator.internalBinaryRead(reader, reader.uint32(), options, message.locator);
                    break;
                case /* BBMgRPCstreamingNS.StreamDatasetsParameters.SubscribedDataset.StreamDataType stream_data_type */ 3:
                    message.streamDataType = reader.int32();
                    break;
                case /* BBMgRPCstreamingNS.AbsoluteTimeRange time_range */ 4:
                    message.timeRange = AbsoluteTimeRange.internalBinaryRead(reader, reader.uint32(), options, message.timeRange);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StreamDatasetsParameters_SubscribedDataset, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 subscribe_id = 1; */
        if (message.subscribeId !== 0)
            writer.tag(1, WireType.Varint).uint32(message.subscribeId);
        /* BBMgRPCstreamingNS.DatasetLocator locator = 2; */
        if (message.locator)
            DatasetLocator.internalBinaryWrite(message.locator, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.StreamDatasetsParameters.SubscribedDataset.StreamDataType stream_data_type = 3; */
        if (message.streamDataType !== 0)
            writer.tag(3, WireType.Varint).int32(message.streamDataType);
        /* BBMgRPCstreamingNS.AbsoluteTimeRange time_range = 4; */
        if (message.timeRange)
            AbsoluteTimeRange.internalBinaryWrite(message.timeRange, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.StreamDatasetsParameters.SubscribedDataset
 */
export const StreamDatasetsParameters_SubscribedDataset = new StreamDatasetsParameters_SubscribedDataset$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetMetaDataList$Type extends MessageType<DatasetMetaDataList> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetMetaDataList", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DatasetMetaDataList_Item },
            { no: 2, name: "quantities", kind: "message", T: () => Quantities },
            { no: 3, name: "measChainDevices", kind: "message", T: () => MeasChainDevices },
            { no: 4, name: "channels", kind: "message", T: () => Channels }
        ]);
    }
    create(value?: PartialMessage<DatasetMetaDataList>): DatasetMetaDataList {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetMetaDataList>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetMetaDataList): DatasetMetaDataList {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BBMgRPCstreamingNS.DatasetMetaDataList.Item items */ 1:
                    message.items.push(DatasetMetaDataList_Item.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* BBMgRPCstreamingNS.Quantities quantities */ 2:
                    message.quantities = Quantities.internalBinaryRead(reader, reader.uint32(), options, message.quantities);
                    break;
                case /* BBMgRPCstreamingNS.MeasChainDevices measChainDevices */ 3:
                    message.measChainDevices = MeasChainDevices.internalBinaryRead(reader, reader.uint32(), options, message.measChainDevices);
                    break;
                case /* BBMgRPCstreamingNS.Channels channels */ 4:
                    message.channels = Channels.internalBinaryRead(reader, reader.uint32(), options, message.channels);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetMetaDataList, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BBMgRPCstreamingNS.DatasetMetaDataList.Item items = 1; */
        for (let i = 0; i < message.items.length; i++)
            DatasetMetaDataList_Item.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.Quantities quantities = 2; */
        if (message.quantities)
            Quantities.internalBinaryWrite(message.quantities, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.MeasChainDevices measChainDevices = 3; */
        if (message.measChainDevices)
            MeasChainDevices.internalBinaryWrite(message.measChainDevices, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.Channels channels = 4; */
        if (message.channels)
            Channels.internalBinaryWrite(message.channels, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetMetaDataList
 */
export const DatasetMetaDataList = new DatasetMetaDataList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetMetaDataList_Item$Type extends MessageType<DatasetMetaDataList_Item> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetMetaDataList.Item", [
            { no: 1, name: "subscribe_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "metadata", kind: "message", T: () => DatasetMetaData }
        ]);
    }
    create(value?: PartialMessage<DatasetMetaDataList_Item>): DatasetMetaDataList_Item {
        const message = { subscribeId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetMetaDataList_Item>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetMetaDataList_Item): DatasetMetaDataList_Item {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 subscribe_id */ 1:
                    message.subscribeId = reader.uint32();
                    break;
                case /* BBMgRPCstreamingNS.DatasetMetaData metadata */ 2:
                    message.metadata = DatasetMetaData.internalBinaryRead(reader, reader.uint32(), options, message.metadata);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetMetaDataList_Item, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 subscribe_id = 1; */
        if (message.subscribeId !== 0)
            writer.tag(1, WireType.Varint).uint32(message.subscribeId);
        /* BBMgRPCstreamingNS.DatasetMetaData metadata = 2; */
        if (message.metadata)
            DatasetMetaData.internalBinaryWrite(message.metadata, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetMetaDataList.Item
 */
export const DatasetMetaDataList_Item = new DatasetMetaDataList_Item$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubscribedDatasetChunk$Type extends MessageType<SubscribedDatasetChunk> {
    constructor() {
        super("BBMgRPCstreamingNS.SubscribedDatasetChunk", [
            { no: 1, name: "subscribe_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "chunk", kind: "message", T: () => DatasetChunk }
        ]);
    }
    create(value?: PartialMessage<SubscribedDatasetChunk>): SubscribedDatasetChunk {
        const message = { subscribeId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SubscribedDatasetChunk>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SubscribedDatasetChunk): SubscribedDatasetChunk {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 subscribe_id */ 1:
                    message.subscribeId = reader.uint32();
                    break;
                case /* BBMgRPCstreamingNS.DatasetChunk chunk */ 2:
                    message.chunk = DatasetChunk.internalBinaryRead(reader, reader.uint32(), options, message.chunk);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SubscribedDatasetChunk, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 subscribe_id = 1; */
        if (message.subscribeId !== 0)
            writer.tag(1, WireType.Varint).uint32(message.subscribeId);
        /* BBMgRPCstreamingNS.DatasetChunk chunk = 2; */
        if (message.chunk)
            DatasetChunk.internalBinaryWrite(message.chunk, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.SubscribedDatasetChunk
 */
export const SubscribedDatasetChunk = new SubscribedDatasetChunk$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamDatasetsResponse$Type extends MessageType<StreamDatasetsResponse> {
    constructor() {
        super("BBMgRPCstreamingNS.StreamDatasetsResponse", [
            { no: 2, name: "datasets", kind: "message", oneof: "type", T: () => StreamDatasetsResponse_SubscribedDatasetChunks }
        ]);
    }
    create(value?: PartialMessage<StreamDatasetsResponse>): StreamDatasetsResponse {
        const message = { type: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StreamDatasetsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StreamDatasetsResponse): StreamDatasetsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.StreamDatasetsResponse.SubscribedDatasetChunks datasets */ 2:
                    message.type = {
                        oneofKind: "datasets",
                        datasets: StreamDatasetsResponse_SubscribedDatasetChunks.internalBinaryRead(reader, reader.uint32(), options, (message.type as any).datasets)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StreamDatasetsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.StreamDatasetsResponse.SubscribedDatasetChunks datasets = 2; */
        if (message.type.oneofKind === "datasets")
            StreamDatasetsResponse_SubscribedDatasetChunks.internalBinaryWrite(message.type.datasets, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.StreamDatasetsResponse
 */
export const StreamDatasetsResponse = new StreamDatasetsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamDatasetsResponse_SubscribedDatasetChunks$Type extends MessageType<StreamDatasetsResponse_SubscribedDatasetChunks> {
    constructor() {
        super("BBMgRPCstreamingNS.StreamDatasetsResponse.SubscribedDatasetChunks", [
            { no: 1, name: "datasets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SubscribedDatasetChunk }
        ]);
    }
    create(value?: PartialMessage<StreamDatasetsResponse_SubscribedDatasetChunks>): StreamDatasetsResponse_SubscribedDatasetChunks {
        const message = { datasets: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StreamDatasetsResponse_SubscribedDatasetChunks>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StreamDatasetsResponse_SubscribedDatasetChunks): StreamDatasetsResponse_SubscribedDatasetChunks {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BBMgRPCstreamingNS.SubscribedDatasetChunk datasets */ 1:
                    message.datasets.push(SubscribedDatasetChunk.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StreamDatasetsResponse_SubscribedDatasetChunks, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BBMgRPCstreamingNS.SubscribedDatasetChunk datasets = 1; */
        for (let i = 0; i < message.datasets.length; i++)
            SubscribedDatasetChunk.internalBinaryWrite(message.datasets[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.StreamDatasetsResponse.SubscribedDatasetChunks
 */
export const StreamDatasetsResponse_SubscribedDatasetChunks = new StreamDatasetsResponse_SubscribedDatasetChunks$Type();
/**
 * @generated ServiceType for protobuf service BBMgRPCstreamingNS.DatasetStreamingService
 */
export const DatasetStreamingService = new ServiceType("BBMgRPCstreamingNS.DatasetStreamingService", [
    { name: "EnumerateDatasets", options: {}, I: EnumerateDatasetsParameters, O: DatasetKeyDataList },
    { name: "DatasetsMetaData", options: {}, I: StreamDatasetsParameters, O: DatasetMetaDataList },
    { name: "StreamDatasets", serverStreaming: true, options: {}, I: StreamDatasetsParameters, O: StreamDatasetsResponse }
]);
