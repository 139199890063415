import { ContentCollection } from './content-collection.types';

const constructCopyToClipboardString = (pakAbsolutePaths: string[], asamPaths: string[]) => {
  // Note: If the paths have a length of 1, then they only contain the header line
  let copyToClipBoardString = '';
  if (pakAbsolutePaths.length > 1) {
    copyToClipBoardString = pakAbsolutePaths.join('\n');
  }
  if (asamPaths.length > 1) {
    if (copyToClipBoardString.length > 0) {
      copyToClipBoardString += '\n' + asamPaths.join('\n');
    } else {
      copyToClipBoardString += asamPaths.join('\n');
    }
  }
  return copyToClipBoardString;
};

const copyTextToClipboard = (text) => {
  const txtArea = document.createElement('textarea');
  txtArea.id = 'txt';
  txtArea.style.position = 'fixed';
  txtArea.style.top = '0';
  txtArea.style.left = '0';
  txtArea.style.opacity = '0';
  txtArea.value = text;
  document.body.appendChild(txtArea);
  txtArea.select();

  try {
    const successful = document.execCommand('copy');
    if (successful) {
      return true;
    }
  } catch (err) {
    console.log('Unable to copy');
  } finally {
    document.body.removeChild(txtArea);
  }
  return false;
};

export const copyContentCollectionToClipboard = async (contentCollection: ContentCollection) => {
  const aodsPrefix = 'aods:///';
  const pakAbsoluteMode: string[] = ['# pak-absolute-mode'];
  const asamPath: string[] = ['# asam-path'];

  if (contentCollection.items) {
    for (const item of contentCollection.items) {
      const pakRef = item.pakRef;
      if (pakRef) {
        if (pakRef.startsWith(aodsPrefix)) {
          asamPath.push(pakRef.substring(aodsPrefix.length));
        } else {
          pakAbsoluteMode.push(pakRef);
        }
      }
    }
  }

  const returnStr = constructCopyToClipboardString(pakAbsoluteMode, asamPath);
  return copyTextToClipboard(returnStr);
};
