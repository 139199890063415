import { Injectable } from '@angular/core';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';

import { Action, Store } from '@ngrx/store';
import { fetch, optimisticUpdate } from '@nrwl/angular';
import { from } from 'rxjs';
import { catchError, delay, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { LS_USER_PREFERRED_CONTENT_COLLECTION } from '../../app.constants';
import { PerformSearch } from '../../depot-search/+state/depot-search.actions';
import { createAppError, createAppErrorPayload } from '../../app.factories';
import { ContentCollectionService } from '../content-collection.service';
import { ContentCollectionShareInfo } from '../content-collection.types';
import * as CCActions from './content-collection.actions';
import { ContentCollectionFacade } from './content-collection.facade';
import { ContentCollectionPartialState } from './content-collection.reducer';

@Injectable()
export class ContentCollectionEffects implements OnInitEffects {
  constructor(
    private actions$: Actions,
    private contentCollectionService: ContentCollectionService,
    private contentCollectionFacade: ContentCollectionFacade,
    private store: Store
  ) {}

  ngrxOnInitEffects(): Action {
    return CCActions.LoadContentCollections();
  }

  loadContentCollections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CCActions.LoadContentCollections),
      fetch({
        run: () => {
          return from(this.contentCollectionService.loadAllContentCollections()).pipe(
            map((result) => CCActions.ContentCollectionsLoaded({ collections: result }))
          );
        },

        onError: (_, error) => {
          return CCActions.ContentCollectionLoadError({ error: createAppErrorPayload(error) });
        }
      })
    )
  );

  checkContentCollectionLoadedWithUpToDateSearchResults$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CCActions.ContentCollectionsLoaded),
        map((action) => {
          const isFullyLoaded = action.collections.every((c) => {
            if (c.items !== undefined && c.items.length > 0) {
              const loaded = c.items?.every((item) => {
                return item.content.job !== undefined;
              });
              if (!loaded) {
                console.error('Not fully loaded', c.name);
              }
              return loaded;
            } else {
              return true;
            }
          });
        })
      ),
    { dispatch: false }
  );

  addItemsAfterCreation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CCActions.ContentCollectionCreated),
      delay(1000),
      map((action) => {
        return CCActions.AddMultipleContentCollectionItems({
          items: action.items ?? [],
          collectionGuid: action.returnedCollection.guid
        });
      })
    )
  );

  loadContentCollectionsAfterAddingItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CCActions.MultipleContentCollectionItemsAdded, CCActions.ContentCollectionItemAdded),
      map((action) => {
        return CCActions.LoadContentCollections();
      })
    )
  );

  createContentCollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CCActions.CreateContentCollection),
      optimisticUpdate({
        run: (action) => {
          return from(this.contentCollectionService.createContentCollection(action.collection)).pipe(
            map((result) => {
              return CCActions.ContentCollectionCreated({
                clientGuid: action.collection.guid,
                returnedCollection: result,
                items: action.collection.items ?? [],
                activate: action.activate
              });
            })
          );
        },

        undoAction: (action, error: Error) => {
          this.store.dispatch(createAppError(error));
          return CCActions.UndoCreateContentCollection({ collection: action.collection });
        }
      })
    )
  );

  deleteContentCollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CCActions.DeleteContentCollection),
      optimisticUpdate({
        run: (action) => {
          return from(this.contentCollectionService.deleteContentCollection(action.collection)).pipe(
            map(() => {
              return CCActions.ContentCollectionDeleted({ collection: action.collection });
            })
          );
        },

        undoAction: (action, error: Error) => {
          this.store.dispatch(createAppError(error));
          return CCActions.UndoDeleteContentCollection({ collection: action.collection });
        }
      })
    )
  );

  editContentCollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CCActions.EditContentCollection),
      optimisticUpdate({
        run: (action) => {
          return from(this.contentCollectionService.editContentCollection(action.collection, action.name)).pipe(
            map((result) => {
              return CCActions.ContentCollectionEdited({ collection: action.collection });
            })
          );
        },

        undoAction: (action, error: any) => {
          this.store.dispatch(createAppError(error));
          return CCActions.UndoEditContentCollection({ collection: action.collection });
        }
      })
    )
  );

  shareContentCollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CCActions.ShareContentCollection),
      optimisticUpdate({
        run: (action) => {
          return from(this.contentCollectionService.shareContentCollection(action.collection, action.shareInfo)).pipe(
            map((shareInfo: ContentCollectionShareInfo) => {
              return CCActions.ContentCollectionShared({ collection: action.collection, shareInfo });
            })
          );
        },

        undoAction: (action, error: any) => {
          this.store.dispatch(createAppError(error));
          return CCActions.UndoShareContentCollection({ collection: action.collection, shareInfo: action.shareInfo });
        }
      })
    )
  );

  deleteContentCollectionShare$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CCActions.DeleteContentCollectionShare),
      optimisticUpdate({
        run: (action) => {
          return from(this.contentCollectionService.deleteContentCollectionShare(action.shareInfo)).pipe(
            map((result) => {
              return CCActions.ContentCollectionShareDeleted({
                collection: action.collection,
                shareInfo: action.shareInfo
              });
            })
          );
        },

        undoAction: (action, error: any) => {
          this.store.dispatch(createAppError(error));
          return CCActions.UndoDeleteContentCollectionShare({
            collection: action.collection,
            shareInfo: action.shareInfo
          });
        }
      })
    )
  );

  editContentCollectionShare$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CCActions.EditContentCollectionShare),
      optimisticUpdate({
        run: (action) => {
          return from(
            this.contentCollectionService.editContentCollectionShare(action.collection, action.shareInfo)
          ).pipe(
            map((result) => {
              return CCActions.ContentCollectionShareEdited({
                collection: action.collection,
                shareInfo: action.shareInfo
              });
            })
          );
        },

        undoAction: (action, error: any) => {
          this.store.dispatch(createAppError(error));
          return CCActions.UndoEditContentCollectionShare({
            collection: action.collection,
            shareInfo: action.shareInfo
          });
        }
      })
    )
  );

  declineContentCollectionShare$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CCActions.DeclineContentCollectionShare),
      optimisticUpdate({
        run: (action) => {
          return from(this.contentCollectionService.declineContentCollectionShare(action.collectionId)).pipe(
            map(() => {
              return CCActions.ContentCollectionShareDeclined({ collectionId: action.collectionId });
            })
          );
        },

        undoAction: (action, error: any) => {
          this.store.dispatch(createAppError(error));
          return CCActions.UndoDeclineContentCollectionShare({ collectionId: action.collectionId });
        }
      })
    )
  );

  addContentCollectionItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CCActions.AddContentCollectionItem),
      optimisticUpdate({
        run: (action: ReturnType<typeof CCActions.AddContentCollectionItem>, state: ContentCollectionPartialState) => {
          return from(
            this.contentCollectionService.addItemToContentCollection(action.item, action.collectionGuid)
          ).pipe(
            map((result) =>
              CCActions.ContentCollectionItemAdded({
                clientItemID: action.item.itemID,
                result: result,
                collectionGuid: action.collectionGuid
              })
            )
          );
        },

        undoAction: (action: ReturnType<typeof CCActions.AddContentCollectionItem>, error: Error) => {
          this.store.dispatch(createAppError(error));
          return CCActions.UndoAddContentCollectionItem({ ...action });
        }
      })
    )
  );

  addMultipleContentCollectionItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CCActions.AddMultipleContentCollectionItems),
      fetch({
        run: (action) => {
          return from(
            this.contentCollectionService.addMultipleItemsToContentCollection(action.items, action.collectionGuid)
          ).pipe(
            map((results) =>
              CCActions.MultipleContentCollectionItemsAdded({
                results: results,
                collectionGuid: action.collectionGuid
              })
            )
          );
        },

        onError: (action, error) => {
          return createAppError(error);
        }
      })
    )
  );

  removeContentCollectionItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CCActions.RemoveContentCollectionItem),
      fetch({
        id: (action) => action.item.itemID,
        run: (action) => {
          return from(
            this.contentCollectionService.deleteItemFromContentCollection(action.item, action.collectionGuid)
          ).pipe(
            map(() =>
              CCActions.ContentCollectionItemRemoved({ collectionGuid: action.collectionGuid, item: action.item })
            )
          );
        },

        onError: (action, error) => {
          return createAppError(error);
        }
      })
    )
  );

  autoActivateCollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CCActions.ContentCollectionsLoaded,
        CCActions.ContentCollectionDeleted,
        CCActions.ContentCollectionCreated
      ),
      withLatestFrom(this.contentCollectionFacade.contentCollections$),
      map(([action, contentCollections]) => {
        const userPreferredContentCollection = localStorage.getItem(LS_USER_PREFERRED_CONTENT_COLLECTION);
        const userPreferredContentCollectionExists =
          userPreferredContentCollection && contentCollections.find((c) => c.guid === userPreferredContentCollection);
        let targetContentCollection;

        if (
          action.type === CCActions.ContentCollectionActionTypes.ContentCollectionCreated &&
          action?.activate === true
        ) {
          // activate newly create collection
          targetContentCollection = action.returnedCollection.guid;
        } else if (userPreferredContentCollectionExists) {
          // User preferred content collection exists
          targetContentCollection = userPreferredContentCollection;
        } else if (contentCollections.length > 0) {
          // No active content collection was set until now
          targetContentCollection = contentCollections[0].guid;
        } else if (contentCollections.length === 0) {
          // no collection exists anymore
          targetContentCollection = undefined;
        }

        return CCActions.ActivateContentCollection({ collectionGuid: targetContentCollection });
      })
    )
  );

  copyContentCollectionToClipboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CCActions.CopyContentCollectionToClipboard),
      withLatestFrom(this.contentCollectionFacade.activeContentCollection$),
      switchMap(([_, collection]) => this.contentCollectionService.copyContentCollectionToClipboard(collection!)),
      map((success) => {
        return CCActions.CopyContentCollectionToClipboardFeedback({ success });
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  writeSelectedContentCollectionToLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CCActions.ActivateContentCollection),
        tap((action) => {
          if (action.collectionGuid) {
            localStorage.setItem(LS_USER_PREFERRED_CONTENT_COLLECTION, action.collectionGuid);
          }
        }),
        catchError((error, caught) => {
          this.store.dispatch(createAppError(error));
          return caught;
        })
      ),
    { dispatch: false }
  );

  triggerSearchAfterContentCollectionChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CCActions.ActivateContentCollection /* CCActions.ContentCollectionItemAdded */),
      map(() => PerformSearch({}))
    )
  );
}
