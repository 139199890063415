import {
  Measurement,
  dynamicComponentIdentifiers,
  MeasurementDownload,
  attributeDisplayType,
  MeasurementMove,
  IntermediateMeasuementDetailsBeforeMove,
  DryRunMeasurementDetails,
  MeasurementMoveDryRunResults,
  MeasurementPath
} from '../measurements.types';
export const MEASUREMENTS_FEATURE_KEY = 'measurements';

import * as MeasurementsAction from './measurements.actions';
import { createReducer, Action } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { SearchResultEntry } from '../../shared/types/search.types';

export const emptyMeasurementPath: MeasurementPath = {
  depot_id: '',
  depot_name: '',
  project: '',
  job: '',
  subtitle: ''
};

export interface MeasurementsState {
  measurements: { [index: string]: Measurement }; // index based list of Measurements
  selectedMeasurement?: SearchResultEntry; // which Measurements record has been selected
  selectedDynamicComponent?: dynamicComponentIdentifiers;
  downloads?: { [index: string]: MeasurementDownload };
  downloadsErrorCounter: number;
  displayAttributesState: attributeDisplayType;
  moving: {
    prep: {
      searchResultsToMove: SearchResultEntry[];
      targetPath: MeasurementPath;
      showMoveDialog: boolean;
      gatheringPathState: 'none' | 'inProgress' | 'done';
      intermediateMeasurementDetails: IntermediateMeasuementDetailsBeforeMove[];
      dryRunMoveState: 'none' | 'inProgress' | 'done';
      dryRunCheckResults: MeasurementMoveDryRunResults;
    };
    actual: {
      movingMeasurements: { [index: string]: MeasurementMove };
    };
  };
}
export interface MeasurementsPartialState {
  readonly [MEASUREMENTS_FEATURE_KEY]: MeasurementsState;
}

export const initialState: MeasurementsState = {
  measurements: {},
  downloadsErrorCounter: 0,
  displayAttributesState: 'all',
  moving: {
    prep: {
      searchResultsToMove: [],
      targetPath: emptyMeasurementPath,
      showMoveDialog: false,
      gatheringPathState: 'none',
      intermediateMeasurementDetails: [],
      dryRunMoveState: 'none',
      dryRunCheckResults: {
        dryRunMeasurements: [],
        projectExists: false,
        jobExists: false
      }
    },
    actual: {
      movingMeasurements: {}
    }
  }
};

const MeasurementReducer = createReducer(
  initialState,
  immerOn(MeasurementsAction.ShowMeasurement, (state, { measurement }) => {
    const selectedMeasurement: Measurement = state.measurements[measurement.measurementId];

    if (selectedMeasurement) {
      selectedMeasurement.loaded = [];
    }
    if (!state.selectedDynamicComponent) {
      state.selectedDynamicComponent = 'overview';
    }

    if (!state.displayAttributesState) {
      state.displayAttributesState = 'nonempty';
    }
    state.selectedMeasurement = measurement;
  }),
  immerOn(MeasurementsAction.ShowDynamicComponentForSelectedEntry, (state, { dynamicComponentId }) => {
    state.selectedDynamicComponent = dynamicComponentId;
  }),
  immerOn(MeasurementsAction.MeasurementReceived, (state, { measurement, identifier, resource, depotId }) => {
    const selectedMeasurement = state.measurements[measurement.measurementId];
    if (selectedMeasurement) {
      selectedMeasurement.loading = selectedMeasurement.loading.filter((id) => id !== identifier);
      selectedMeasurement.resources[identifier] = resource;
      selectedMeasurement.loaded.push(identifier);
      selectedMeasurement.depotId = depotId;
    } else {
      const newMeasurement: Measurement = {
        measurementBrowseUrl: measurement.measurementBrowseUrl,
        measurementId: measurement.measurementId,
        loading: [],
        loaded: [],
        depotId: depotId,
        resources: {
          overview: [],
          unitsUnderTest: [],
          testSequencesAndAnys: [],
          datasetDescription: []
        }
      };
      newMeasurement.resources[identifier] = resource;
      state.measurements[measurement.measurementId] = newMeasurement;
    }
  }),
  immerOn(MeasurementsAction.FetchSpecificMeasurementTile, (state, { measurement, identifier }) => {
    const selectedMeasurement = state.measurements[measurement.measurementId];
    if (!selectedMeasurement) {
      state.measurements[measurement.measurementId] = {
        measurementBrowseUrl: measurement.measurementBrowseUrl,
        measurementId: measurement.measurementId,
        loading: [],
        loaded: [],
        resources: {
          overview: [],
          unitsUnderTest: [],
          testSequencesAndAnys: [],
          datasetDescription: []
        }
      };
    }
  }),
  immerOn(MeasurementsAction.FetchSpecificMeasurementTileError, (state, { measurement, identifier, error }) => {
    const selectedMeasurement = state.measurements[measurement.measurementId];
    if (selectedMeasurement) {
      selectedMeasurement.loading = selectedMeasurement.loading.filter((ide) => ide !== identifier);
    }
  }),
  immerOn(MeasurementsAction.StartMeasurementLoading, (state, { measurementId, identifier }) => {
    state.measurements[measurementId].loading.push(identifier);
  }),
  immerOn(MeasurementsAction.DownloadRequested, (state, { downloadRequest }) => {
    if (!state.downloads) {
      state.downloads = {};
    }
    state.downloads[downloadRequest.id] = downloadRequest;
  }),
  immerOn(MeasurementsAction.MonitorDownloadRequest, (state, { downloadRequest }) => {
    if (state.downloads && state.downloads[downloadRequest.id]) {
      const newDownloadRequest = {
        ...state.downloads[downloadRequest.id]
      };
      newDownloadRequest.progress = downloadRequest.progress;
      newDownloadRequest.error = downloadRequest.error ? downloadRequest.error : null;

      state.downloads[downloadRequest.id] = newDownloadRequest;
    }
  }),
  immerOn(MeasurementsAction.CleanUpDownloadRequest, (state, { downloadRequest }) => {
    if (
      state.downloads &&
      state.downloads[downloadRequest.id] &&
      state.downloads[downloadRequest.id].error &&
      state.downloadsErrorCounter > 0
    ) {
      state.downloadsErrorCounter--;
    }
    // Delete whole downloads state object if the incoming download request is the last one.
    if (state.downloads && Object.entries(state.downloads).length === 1 && state.downloads[downloadRequest.id]) {
      delete state.downloads;
    } else if (state.downloads && state.downloads[downloadRequest.id]) {
      delete state.downloads[downloadRequest.id];
    }
  }),
  immerOn(MeasurementsAction.DownloadRequestFailed, (state, { downloadRequest }) => {
    if (state.downloads && state.downloads[downloadRequest.id]) {
      state.downloads[downloadRequest.id].error = downloadRequest.error;
      state.downloadsErrorCounter++;
    }
  }),
  immerOn(MeasurementsAction.ChangeAttributeButtonState, (state, { shownAttribs }) => {
    state.displayAttributesState = shownAttribs;
  }),
  immerOn(MeasurementsAction.ChangeExtended, (state, { extended, index, identifier }) => {
    const selectedMeasurementId = state.selectedMeasurement?.measurementId as string;
    if (identifier === 'testSequencesAndAnys') {
      state.measurements[selectedMeasurementId].resources.testSequencesAndAnys[index].extended = !extended;
    }
    if (identifier === 'unitsUnderTest') {
      state.measurements[selectedMeasurementId].resources.unitsUnderTest[index].extended = !extended;
    }
  }),

  immerOn(MeasurementsAction.AttributeDisplayTypeInitialized, (state, { attrDisplayType }) => {
    state.displayAttributesState = attrDisplayType;
  }),

  // Move Dry run
  immerOn(MeasurementsAction.RequestMoveDryRun, (state) => {
    state.moving.prep.dryRunMoveState = 'inProgress';
  }),
  immerOn(MeasurementsAction.MoveDryRunReturned, (state, { dryRunResults }) => {
    state.moving.prep.dryRunMoveState = 'done';
    state.moving.prep.dryRunCheckResults = dryRunResults;
  }),

  // Move
  immerOn(MeasurementsAction.PrepareMove, (state, { searchResults }) => {
    state.moving.prep.searchResultsToMove = searchResults;
  }),

  immerOn(MeasurementsAction.SetTargetPath, (state, { targetPath }) => {
    state.moving.prep.targetPath = targetPath;
  }),

  immerOn(MeasurementsAction.MoveRequested, (state, { moveRequest }) => {
    const moveRequestCopy: MeasurementMove = { ...moveRequest, moveState: 'moving' };
    state.moving.actual.movingMeasurements[moveRequest.id] = moveRequestCopy;
  }),

  immerOn(MeasurementsAction.MonitorMoveRequest, (state, { moveRequest }) => {
    if (state.moving.actual.movingMeasurements && state.moving.actual.movingMeasurements[moveRequest.id]) {
      const moveRequestCopy: MeasurementMove = { ...moveRequest, moveState: 'moving' };
      state.moving.actual.movingMeasurements[moveRequest.id] = moveRequestCopy;
    }
  }),
  immerOn(MeasurementsAction.CleanUpMoveRequest, (state, { moveRequest }) => {
    //Delete whole downloads state object if the incoming download request is the last one.
    if (state.moving.actual.movingMeasurements && state.moving.actual.movingMeasurements[moveRequest.id]) {
      state.moving.actual.movingMeasurements[moveRequest.id].moveState = 'done';
    }
  }),
  immerOn(MeasurementsAction.MoveRequestFailed, (state, { moveRequest }) => {
    if (state.moving.actual.movingMeasurements && state.moving.actual.movingMeasurements[moveRequest.id]) {
      state.moving.actual.movingMeasurements[moveRequest.id].moveState = 'error';
    }
  }),

  immerOn(MeasurementsAction.GetPathAttributesForMeasurementsViaSearch, (state) => {
    state.moving.prep.gatheringPathState = 'inProgress';
  }),
  immerOn(MeasurementsAction.PathAttributesForMeasurementsReceived, (state, { intermediateMeasurementDetails }) => {
    state.moving.prep.gatheringPathState = 'done';
    state.moving.prep.intermediateMeasurementDetails = intermediateMeasurementDetails;
  }),
  immerOn(MeasurementsAction.ShowMeasurementMoveModal, (state, { show }) => {
    state.moving.prep.showMoveDialog = show;
  })
);

export function reducer(state: MeasurementsState | undefined, action: Action) {
  return MeasurementReducer(state, action);
}
