<ng-container *ngIf="user">
  <div class="expanded-row">
    <div class="expanded-row-tabs">
      <button
        class="btn btn-link"
        [ngClass]="{ active: subMenuState === 'details' }"
        (click)="subMenuStateChange.emit('details')"
        >{{ 'LICENSING.DETAILS.DETAILS' | translate }}</button
      >
      <button
        class="btn btn-link"
        [ngClass]="{ active: subMenuState === 'groups' }"
        (click)="subMenuStateChange.emit('groups')"
        >{{ 'LICENSING.GROUPS' | translate }}</button
      >
      <button
        class="btn btn-link"
        [ngClass]="{ active: subMenuState === 'roles' }"
        (click)="subMenuStateChange.emit('roles')"
        >{{ 'LICENSING.ROLES' | translate }}</button
      >
      <button
        class="btn btn-link"
        [ngClass]="{ active: subMenuState === 'licenses' }"
        (click)="subMenuStateChange.emit('licenses')"
        >{{ 'LICENSING.LICENSES' | translate }}</button
      >
    </div>

    <div *ngIf="subMenuState === 'details'" class="tab-content">
      <div class="heading">
        <p>
          <cloud-svg-icon fileName="person_info_filled"></cloud-svg-icon>
          <span>{{ 'LICENSING.DETAILS.DETAILS' | translate }}</span>
        </p>
      </div>
      <div class="user-expanded-col">
        <p *ngIf="user.email"
          ><b>{{ 'LICENSING.DETAILS.EMAIL' | translate }}:</b> {{ user.email }}</p
        >
        <p *ngIf="user.firstName"
          ><b>{{ 'LICENSING.DETAILS.FIRST_NAME' | translate }}</b> {{ user.firstName }}</p
        >
        <p *ngIf="user.lastName"
          ><b>{{ 'LICENSING.DETAILS.LAST_NAME' | translate }}</b> {{ user.lastName }}</p
        >
        <p *ngIf="user.userName"
          ><b>{{ 'LICENSING.DETAILS.USER_NAME' | translate }}</b> {{ user.userName }}</p
        >
        <p *ngIf="user.joined"
          ><b>{{ 'LICENSING.DETAILS.JOINED' | translate }}</b>
          {{ user.joined | date : 'medium' : '' : translate.currentLang }}</p
        >
        <p *ngIf="user.lastLogin"
          ><b>{{ 'LICENSING.DETAILS.LAST_LOGIN' | translate }}</b>
          {{ user.lastLogin | date : 'medium' : '' : translate.currentLang }}</p
        >
      </div>
    </div>
    <div class="pick-list-col tab-content" *ngIf="subMenuState === 'groups'">
      <cloud-pick-list
        sourceListIconFileName="people_team"
        [sourceList]="availableGroups"
        [sourceListHeader]="'LICENSING.PICK_LISTS.EXISTING_GROUPS' | translate"
        targetListIconFileName="people_team"
        [targetList]="userGroups"
        [targetListHeader]="'LICENSING.PICK_LISTS.MEMBER_OF' | translate"
        (movedToTarget)="addGroupToUser($event, user)"
        (movedToSource)="removeGroupFromUser($event, user)"
        itemKey="name"
      ></cloud-pick-list>
    </div>
    <div class="pick-list-col tab-content" *ngIf="subMenuState === 'roles'">
      <cloud-pick-list
        sourceListIconFileName="slide_text_person"
        [sourceList]="availableRoles"
        [sourceListHeader]="'LICENSING.PICK_LISTS.AVAILABLE_ROLES' | translate"
        targetListIconFileName="slide_text_person"
        [targetList]="userRoles"
        [targetListHeader]="'LICENSING.PICK_LISTS.APPLIED_ROLES' | translate"
        [targetListTitle]="'LICENSING.PICK_LISTS.PERSONAL_ROLES' | translate"
        (movedToTarget)="addRoleToUser($event, user)"
        (movedToSource)="removeRoleFromUser($event, user)"
        itemKey="name"
        [disabledList]="getEffectiveRoles(user) | async"
        [disabledListHeader]="'LICENSING.PICK_LISTS.GROUP_ROLES' | translate"
        [disabledListTitle]="'LICENSING.PICK_LISTS.GROUP_ROLES' | translate"
      >
      </cloud-pick-list>
    </div>
    <div *ngIf="subMenuState === 'licenses'" class="tab-content">
      <div class="heading">
        <p>
          <cloud-svg-icon-customizable fill="#264556" fileName="certificate"></cloud-svg-icon-customizable>
          <span>{{ 'LICENSING.OBTAINMENT_RIGHTS' | translate }}</span>
        </p>
      </div>
      <div class="user-expanded-col">
        <ng-container *ngIf="!user.hasVibroLicense">
          <clr-tooltip *ngFor="let license of lookUpAndSortLicensePills(user.licenses); trackBy: trackByLicenseId">
            <span class="license-pill" clrTooltipTrigger>
              <ng-container
                *ngIf="lookupLicenseNames(license.id, { includePeriod: true, includeUserType: true }) as licenseName"
              >
                <cloud-license-icon [license]="license"></cloud-license-icon>
                <span [innerHTML]="licenseName"></span>
              </ng-container>
            </span>
            <clr-tooltip-content [clrPosition]="'top-right'" [clrSize]="'lg'" class="cloud-tooltip">
              <p *ngFor="let role of license.roles" class="role-tooltip"
                ><span>{{ role.role.name }} (role)</span>
                <cloud-svg-icon fileName="union" *ngIf="getGroupNamesForEfRole(role)?.length"></cloud-svg-icon>
                <span>{{ getGroupNamesForEfRole(role) }}</span></p
              >
            </clr-tooltip-content>
          </clr-tooltip>
          <!-- <div
            *ngFor="let license of licenses; trackBy: trackByLicenseId"
            (mouseenter)="licenseHovered.emit(license)"
            (mouseleave)="licenseHovered.emit(null)"
            class="license-pill"
          >
            <ng-container *ngIf="lookupLicenseNames(license.id, { includePeriod: true }) as licenseName">
              <cloud-svg-icon
                *ngIf="licenseName.indexOf('Monthly') >= 0"
                fileName="calendar"
                height="24px"
                width="24px"
              ></cloud-svg-icon>

              <cloud-svg-icon
                *ngIf="licenseName.indexOf('Daily') >= 0"
                fileName="24"
                height="24px"
                width="24px"
              ></cloud-svg-icon>
              {{ licenseName }}
            </ng-container>
          </div> -->
        </ng-container>
        <ng-container *ngIf="user.hasVibroLicense">
          <div class="vibro-license">
            {{ 'LICENSING.VIBROLICENSE_MESSAGE' | translate }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
