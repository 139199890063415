import { createReducer, Action } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { findAllThatEqual, findAllThatEqualOneOf } from '../../../order-management/order.helpers';
import * as AttributesActions from './attributes.actions';
import { AttributeGroup, DepotAttribute, DepotAttributesIdCache, SemanticDepotAttribute } from './attributes.types';

export const ATTRIBUTES_FEATURE_KEY = 'attributes';

export interface AttributesState {
  depotAttributesCache: DepotAttributesIdCache;
  availableDepotAttributes?: DepotAttribute[];
  availableSemanticDepotAttributes?: SemanticDepotAttribute[];
  availableAttributeGroups?: AttributeGroup[];
  availableDepotAttributesLoaded: boolean;
}

export interface AttributesPartialState {
  readonly [ATTRIBUTES_FEATURE_KEY]: AttributesState;
}

export const initialState: AttributesState = {
  availableDepotAttributesLoaded: false,
  depotAttributesCache: {}
};

const attributesReducer = createReducer(
  initialState,
  // #region DepotAttributes

  immerOn(AttributesActions.DepotAttributesAndSemanticsReceived, (state, { attribs, semantics, semanticGroups }) => {
    state.availableDepotAttributes = attribs;
    state.availableSemanticDepotAttributes = semantics;
    state.availableAttributeGroups = semanticGroups;
    state.availableDepotAttributesLoaded = true;
  }),
  immerOn(AttributesActions.DepotAttributesLoaded, (state, { cache }) => {
    state.depotAttributesCache = cache;
  }),
  immerOn(AttributesActions.FillDepotAttributesCache, (state, { attribs }) => {
    // TODO: ? Use SemanticDepotAttributes instead?
    // TODO: Move hardcoded DepotAttributes to config file?

    const depotAttributesIdNames: string[] = [];
    attribs.forEach((attr) => {
      depotAttributesIdNames.push(attr.idName);
    });

    state.depotAttributesCache = {
      'order_iid.order_guid': findAllThatEqualOneOf(
        ['descriptive.order_iid.order_guid', 'descriptive_ancestors.aotest.order_iid.order_guid'],
        depotAttributesIdNames
      ),
      'order_iid.iname': findAllThatEqualOneOf(
        ['descriptive.order_iid.iname', 'descriptive_ancestors.aotest.order_iid.iname'],
        depotAttributesIdNames
      ),
      'hrchypak.Project.name': findAllThatEqual('hrchypak.Project.name', depotAttributesIdNames),
      'order_iid.orderer': findAllThatEqualOneOf(
        ['descriptive.order_iid.orderer', 'descriptive_ancestors.aotest.order_iid.orderer'],
        depotAttributesIdNames
      ),
      'order_iid.description': findAllThatEqualOneOf(
        ['descriptive.order_iid.description', 'descriptive_ancestors.aotest.order_iid.description'],
        depotAttributesIdNames
      ),
      'order_iid.order_date': findAllThatEqualOneOf(
        ['descriptive.order_iid.order_date', 'descriptive_ancestors.aotest.order_iid.order_date'],
        depotAttributesIdNames
      ),
      'hrchypak.Subtitle.name': findAllThatEqual('hrchypak.Subtitle.name', depotAttributesIdNames),
      'descriptive.mea_guid': findAllThatEqual('descriptive.mea_guid', depotAttributesIdNames),
      'descriptive.mime_type': findAllThatEqual('descriptive.mime_type', depotAttributesIdNames),
      'lastUpdate': new Date().toISOString()
    } as DepotAttributesIdCache;
  })
  // #endregion DepotAttributes
);

export function reducer(state: AttributesState | undefined, action: Action) {
  return attributesReducer(state, action);
}
