import { DataType, Position, Quantity } from '../prototypes/DatasetMessages';
import { StreamingChart } from '../utils/streaming-chart.types';
import { LocalValues, createExternalTrackValuesFromStepValues, findMatchingTimestamp } from './lightningPlot';
import { AxisScalingTypes, ColorScalingTypes, ScalesConfig, TrackType } from './lightningPlot.types';
import { exhaustiveMatchingGuard } from '../../shared/utility-functions/exhaustiveMatchingGuard';
import { numberFormatter } from '../utils/formatting.utils';
import { getNthOctaveDelta, getNthOctaveMiddleFrequency, getThirdBands } from './3D/lightningChartCustomCalculations3D';
import { timeDifference } from '../utils/time.utils';
import { StepValues } from './lightningPlot';

export type LCaxisFormattingFunction = (formatterOptions: LCaxisFormattingFunctionOptions) => string;
export type LCaxisFormattingFunctionOptions = {
  value_or_index: number;
  isStepIndex: boolean;
  includeUnit: boolean;
  decimalPlaces?: number;
};
export interface LCaxisMapping {
  title: string;
  formatter: LCaxisFormattingFunction;
  quantity?: Quantity;
}

export interface LCinfoMapping {
  axis: Record<'X' | 'Y', LCaxisMapping> & Partial<Record<'Z', LCaxisMapping>>;
}

const determineTrackType = (
  plottingParameters: StreamingChart.PlottingParameters,
  selectedTrackIsTime: boolean
): TrackType => {
  let result: TrackType;

  if (selectedTrackIsTime) {
    result = 'time';
  } else {
    let hasOriginalTrackType = false;
    if (plottingParameters?.dataInfo?.originalTrackInfo) {
      const hasNonChannelNumber = plottingParameters.dataInfo.originalTrackInfo.channelId > 0;
      const hasPosition = plottingParameters.dataInfo.originalTrackInfo.position !== undefined;
      hasOriginalTrackType = hasNonChannelNumber && hasPosition;
    }

    let hasTrackType = false;
    if (plottingParameters?.dataInfo?.trackInfo) {
      const hasNonChannelNumber = plottingParameters.dataInfo.trackInfo.channelId > 0;
      const hasPosition = plottingParameters.dataInfo.trackInfo.position !== undefined;
      hasTrackType = hasNonChannelNumber && hasPosition;
    }

    let originalAndRPMtrackTypeAreEquivalent = false;
    if (plottingParameters?.dataInfo?.rpmInfos) {
      const firstRPMinfo = plottingParameters.dataInfo.rpmInfos[0];
      if (firstRPMinfo) {
        const originalTrackInfo = plottingParameters?.dataInfo?.originalTrackInfo;
        originalAndRPMtrackTypeAreEquivalent =
          originalTrackInfo?.channelId === firstRPMinfo?.channelId &&
          JSON.stringify(originalTrackInfo?.position) === JSON.stringify(firstRPMinfo?.position);
      }
    }

    const shouldUseOrderRPM = hasOriginalTrackType && originalAndRPMtrackTypeAreEquivalent;
    const shouldUseTrackType = hasTrackType;
    const shouldUseOriginalTrackType = hasOriginalTrackType;

    const zValueExludedQuantityNames = ['Time', 'Meas.step'];
    const shouldUseZValues =
      plottingParameters?.quantityZ &&
      !zValueExludedQuantityNames.includes(plottingParameters?.quantityZ?.rawQuantity?.name);

    const shouldUseTime = plottingParameters?.measZeroPoint !== undefined;

    if (shouldUseOrderRPM) {
      result = 'orderRPM';
    } else if (shouldUseTrackType) {
      result = 'track';
    } else if (shouldUseOriginalTrackType) {
      result = 'originalTrack';
    } else if (shouldUseZValues) {
      result = 'zValues';
    } else if (shouldUseTime) {
      result = 'time';
    } else {
      result = 'step';
    }
  }
  return result;
};

export type LCinfoMappingOptions = {
  localValues: LocalValues;
  datasetParams: StreamingChart.DatasetParaContainer;
  trackQuantity?: Quantity;
  trackPosition?: Position;
  selectedTrackIsTime?: boolean;
  scalesConfig: ScalesConfig;
};

export const equiTimeParameter = (datasetParaContainer: StreamingChart.DatasetParaContainer) => {
  if (
    datasetParaContainer.usedRawDataType === DataType.Type_CompressedThruput &&
    datasetParaContainer.compressedDatasetParams?.plottingParameters
  ) {
    const plotPara = datasetParaContainer.compressedDatasetParams.plottingParameters;
    const firstSampleTime = plotPara.timeScale?.firstSampleTime ?? BigInt(0);
    // const deltaTime = 1 / (options?.datasetParams.plottingParameters?.timeScale?.samplingRate ?? 1);
    const deltaX = plotPara.deltaX;
    const measurementStartPoint = plotPara.measZeroPoint ?? BigInt(0);
    const startPoint = timeDifference(firstSampleTime, measurementStartPoint) ?? 0;
    // const relativeSampleTime = startPoint + forcedInteger * deltaTime;
    return { deltaX: deltaX, startPoint: startPoint };
  } else {
    return { deltaX: 0, startPoint: 0 };
  }
};

export const generateLCinfoMapping = (
  options: LCinfoMappingOptions
): { infoMapping: LCinfoMapping; trackType: TrackType } => {
  const plotParams = options.datasetParams.currentDatasetParams.plottingParameters;
  const is3DPlot = plotParams?.chartType === 'chart3D';
  const is3DData = plotParams?.chartType === 'chart3D' || plotParams?.chartType === 'chart3D2D';
  const useTrack = is3DData && (plotParams?.rangeZ?.num ?? 1) > 1;

  let result = defaultLCinfoMapping(is3DPlot) as LCinfoMapping;
  let trackType = determineTrackType(plotParams!, options.selectedTrackIsTime ?? false);
  if (
    useTrack &&
    (trackType === 'time' || trackType === 'step') &&
    options.trackQuantity &&
    !options.selectedTrackIsTime
  ) {
    trackType = 'originalTrack'; // NOTE: better move this decision to determineTrackType
  }

  let trackQuantity: Quantity | undefined;

  if (useTrack && (trackType === 'orderRPM' || trackType === 'originalTrack')) {
    trackQuantity = options.trackQuantity;
    if (!trackQuantity && trackType === 'orderRPM') {
      if (plotParams?.dataInfo?.rpmInfos) {
        const quantId = plotParams?.dataInfo.rpmInfos[0].quantityId;
        console.warn(
          `now we have to calculate the quantity for first order rpm channel ; we have the quantityId ${quantId} but not the corresponding entry in the quantities list`
        );
      }
      if (!trackQuantity) {
        console.warn(`now we create a default RPM quantity for the order rpm infos`);
        trackQuantity = createDefaultTrackQuantity();
      }
    }
  }

  // Missing Track Quantity Fallback
  // If !trackQuantity, use quantityZ for 3D, use quantityX for 2D
  if (!trackQuantity) {
    if (useTrack) {
      trackQuantity = plotParams?.quantityZ?.rawQuantity;
    } else {
      trackQuantity = plotParams?.quantityX?.rawQuantity;
    }
  }

  // Based on chartType, we need to decide which axis goes where
  // 2D:
  // 3D2D:
  // x is track
  // y is data values
  // z -> discard

  // 3D:
  // y is track
  // x is something (frequency / ord / octave)
  // z is data values

  // Later:
  // 3D which looks like an APS but has only one step, should later be handled as 2D

  const axisMapping = {
    '2D': {
      track: 'X',
      data: 'Y'
    },
    '3D': {
      X: 'X',
      track: 'Y',
      data: 'Z'
    }
  };

  const actualMapping = is3DPlot ? axisMapping['3D'] : axisMapping['2D'];

  // Specific X-Value Axis for 3D Plot OR 2D Plot from 3D data with one step
  if (is3DPlot || (!useTrack && is3DData)) {
    const { title: xTitle, unit: xUnitDisplayName } = getAxisTitleAndUnitDisplayName(plotParams?.quantityX);

    result.axis.X.title = `${xTitle} [${xUnitDisplayName}]`;
    result.axis.X.formatter = (formatterOptions: LCaxisFormattingFunctionOptions) => {
      let value = numberFormatter({
        value: formatterOptions.value_or_index,
        options: { digits: { significantDigits: 2 } }
      });
      if (options.scalesConfig.xAxis === 'third' && plotParams) {
        value = handleNthOctaveAxisValue(formatterOptions.value_or_index, plotParams);
      }
      const unit = formatterOptions.includeUnit ? xUnitDisplayName : '';
      return `${value} ${unit}`;
    };
  }
  // Data Axis
  const dataAxis = actualMapping['data'];

  let dataAxisConfig: ColorScalingTypes | AxisScalingTypes;
  if (is3DPlot) {
    dataAxisConfig = options.scalesConfig.color;
  } else {
    dataAxisConfig = options.scalesConfig.yAxis;
  }

  let dataUnit: string | undefined = '';
  if (options.datasetParams.currentDatasetParams.rawDataType == DataType.Type_TachoEdges) {
    result.axis[dataAxis].title = '';
  } else {
    dataUnit = dataAxisConfig === 'db' ? 'dB' : plotParams?.quantityY?.unitDisplayName;
    if (plotParams?.freqWeight) {
      dataUnit += generateFreqWeightString(plotParams?.freqWeight);
    }
    let dataTitle = `${plotParams?.quantityY?.name}`;
    if (dataUnit) {
      dataTitle += ` [${dataUnit}]`;
    }
    result.axis[dataAxis].title = dataTitle;
  }

  result.axis[dataAxis].formatter = (formatterOptions: LCaxisFormattingFunctionOptions) => {
    // console.log(dataAxis, 'formatterOptions', formatterOptions.value_or_index, formatterOptions.isStepIndex);
    let value = '';

    const plotParams = options.datasetParams.currentDatasetParams.plottingParameters;

    switch (dataAxisConfig) {
      case 'lin':
      case 'log':
        // TODO: check for a more elegent version of this if-else
        if (formatterOptions.decimalPlaces) {
          value = numberFormatter({
            value: formatterOptions.value_or_index,
            options: { digits: { fixDecimalDigits: formatterOptions.decimalPlaces } }
          });
        } else {
          value = numberFormatter({
            value: formatterOptions.value_or_index,
            options: { digits: { significantDigits: 5 } }
          });
        }

        break;
      case 'db':
        value = numberFormatter({
          value: formatterOptions.value_or_index,
          options: { digits: { fixDecimalDigits: 1 } }
        });
        dataUnit = 'dB';
        dataUnit += generateFreqWeightString(plotParams?.freqWeight);

        break;
      case 'third':
        // Ignore me, this axis config cannot exist in the data axis plane
        break;
      default:
        exhaustiveMatchingGuard(dataAxisConfig);
    }
    const unit = formatterOptions.includeUnit ? dataUnit : '';
    return `${value} ${unit}`;
  };

  const externalTrackValues = options.localValues.externalTrackValues?.values.length
    ? options.localValues.externalTrackValues?.values.length > 0
      ? options.localValues.externalTrackValues.values
      : undefined
    : (undefined as number[] | undefined);

  const stepValues = options.localValues['stepValues'] as StepValues | undefined;

  // Track
  const trackAxis = actualMapping['track'];
  switch (trackType) {
    case 'orderRPM':
      if (useTrack) {
        const trackValues = stepValues?.steps.map((step) => step.orderRpmValue);

        let trackTitle = '';
        const trackPosition = options.trackPosition;
        if (trackPosition !== undefined) {
          trackTitle += `${trackPosition.coordinateName}` + ', ';
        }
        const trackUnit = trackQuantity?.unitDisplayName;
        trackTitle += `${trackQuantity?.name}`;
        if (trackUnit) {
          trackTitle += ` [${trackUnit}]`;
        }

        result.axis[trackAxis].title = trackTitle;
        result.axis[trackAxis].formatter = (formatterOptions: LCaxisFormattingFunctionOptions) => {
          let value = '' as string;
          if (formatterOptions.isStepIndex) {
            const forcedInteger: number = toleranceBasedRound(formatterOptions.value_or_index);
            value = trackValues?.[forcedInteger]?.toFixed(formatterOptions.decimalPlaces ?? 0) ?? '';
          } else {
            value = formatterOptions.value_or_index.toFixed(formatterOptions.decimalPlaces ?? 0);
          }
          const unit = formatterOptions.includeUnit ? trackUnit : '';
          return `${value} ${unit}`;
        };
      }
      break;
    case 'originalTrack':
      if (useTrack) {
        // OriginalTrack Y-Axis
        let trackTitle = '';
        const trackPosition = options.trackPosition;
        if (trackPosition !== undefined) {
          trackTitle += `${trackPosition.coordinateName}` + ', ';
        }
        trackTitle += `${trackQuantity?.name}`;
        const trackUnit = trackQuantity?.unitDisplayName;
        if (trackUnit) {
          trackTitle += ` [${trackUnit}]`;
        }

        result.axis[trackAxis].title = trackTitle;
        result.axis[trackAxis].formatter = (formatterOptions: LCaxisFormattingFunctionOptions) => {
          let trackValues =
            options.localValues.externalTrackValues?.values.length &&
            options.localValues.externalTrackValues?.values.length > 0
              ? options.localValues.externalTrackValues
              : undefined;
          if (trackValues === undefined) {
            const trackValuesFromSteps = stepValues ? createExternalTrackValuesFromStepValues(stepValues) : undefined;
            trackValues = trackValuesFromSteps;
          }

          let value = '';
          if (trackValues && stepValues) {
            if (formatterOptions.isStepIndex) {
              const dataRowIndex = formatterOptions.value_or_index;
              const forcedInteger: number = toleranceBasedRound(dataRowIndex);
              // find matching track value for step by timestamps
              const stepValuesTimes = stepValues.times.map((time) => time) as bigint[];
              if (forcedInteger < stepValuesTimes.length) {
                const timestampToFind = stepValuesTimes[forcedInteger];
                const i = findMatchingTimestamp(timestampToFind, trackValues);
                if (i >= 0) {
                  const trackValue = trackValues.values[i];
                  value = trackValue.toFixed(formatterOptions.decimalPlaces ?? 0) ?? '';
                } else {
                  // don't warn if when the user zooms out too far
                  if (dataRowIndex >= 0) {
                    console.warn('did not find track value for time ', timestampToFind);
                  }
                }
              }
            } else {
              value = formatterOptions.value_or_index.toFixed(formatterOptions.decimalPlaces ?? 0);
            }
          } else {
            value = formatterOptions.value_or_index.toString();
          }
          const unit = formatterOptions.includeUnit ? trackUnit : '';
          // console.log(`track value: ${value} ${unit}`);
          return `${value} ${unit}`;
        };
      }

      break;

    case 'track':
      if (useTrack) {
        let trackTitle = '';
        const trackPosition = options.trackPosition;
        if (trackPosition !== undefined) {
          trackTitle += `${trackPosition.coordinateName}` + ', ';
        }
        trackTitle += `${trackQuantity?.name}`;
        const trackUnit = trackQuantity?.unitDisplayName;
        if (trackUnit) {
          trackTitle += ` [${trackUnit}]`;
        }
        result.axis[trackAxis].title = trackTitle ?? 'Track';
        result.axis[trackAxis].formatter = (formatterOptions: LCaxisFormattingFunctionOptions) => {
          // TODO: why do we need externalTrackValues here
          const trackValuesFromSteps = stepValues?.steps.map((step) => step.trackValue) as number[] | undefined;
          const trackValues = trackValuesFromSteps ?? externalTrackValues;
          if (
            trackValuesFromSteps &&
            externalTrackValues &&
            trackValuesFromSteps.length > 0 &&
            externalTrackValues.length > 0 &&
            trackValuesFromSteps.length !== externalTrackValues.length
          ) {
            console.error(
              `length of track values of steps (${trackValuesFromSteps.length}) are different from external track values (${externalTrackValues.length})`
            );
          }

          let value = '';
          if (trackValues) {
            if (formatterOptions.isStepIndex) {
              const forcedInteger: number = toleranceBasedRound(formatterOptions.value_or_index);
              value = trackValues?.[forcedInteger]?.toFixed(formatterOptions.decimalPlaces ?? 0) ?? '';
            } else {
              value = formatterOptions.value_or_index.toFixed(formatterOptions.decimalPlaces ?? 0);
            }
          } else {
            value = formatterOptions.value_or_index.toString();
          }
          const unit = formatterOptions.includeUnit ? trackUnit : '';
          return `${value} ${unit}`;
        };
      }
      break;

    case 'time':
      if (useTrack || !is3DData) {
        const trackUnit = trackQuantity?.unitDisplayName;
        let trackTitle = trackQuantity?.name;
        if (trackUnit) {
          trackTitle += ` [${trackUnit}]`;
        }

        result.axis[trackAxis].title = trackTitle ?? 'Time';
        result.axis[trackAxis].formatter = (formatterOptions: LCaxisFormattingFunctionOptions) => {
          let time: number | undefined;
          if (plotParams?.chartType === 'chart2DEqui') {
            if (formatterOptions.isStepIndex) {
              const forcedInteger: number = toleranceBasedRound(formatterOptions.value_or_index);
              const plotEquiTimeParam = equiTimeParameter(options?.datasetParams);
              time = plotEquiTimeParam.startPoint + forcedInteger * plotEquiTimeParam.deltaX!;
            } else {
              time = formatterOptions.value_or_index;
            }
          } else {
            if (formatterOptions.isStepIndex) {
              const timeValuesFromSteps = stepValues?.times;
              const timeValues = options.localValues['times'] as bigint[] | undefined;
              if (
                timeValuesFromSteps &&
                timeValues &&
                timeValuesFromSteps.length > 0 &&
                timeValues.length > 0 &&
                timeValuesFromSteps.length !== timeValues.length
              ) {
                console.error(
                  `length of time values of steps (${timeValuesFromSteps.length}) are different from local time values (${timeValues.length})`
                );
              }
              const trackValues = timeValuesFromSteps ?? timeValues;
              const forcedInteger: number = toleranceBasedRound(formatterOptions.value_or_index);
              const actualTrackValue = trackValues?.[forcedInteger];
              if (actualTrackValue !== undefined) {
                time = timeDifference(actualTrackValue, plotParams?.measZeroPoint ?? BigInt(0));
              } else {
                // don't return a time value, because there is no, and don't throw an error here, because this can happen when the user zooms out too far
              }
            } else {
              time = formatterOptions.value_or_index;
            }
          }

          let value = '';
          if (time !== undefined) {
            // time value zero is valid: check if time is not undefined
            value = numberFormatter({
              value: time,
              options: { digits: { fixDecimalDigits: formatterOptions.decimalPlaces ?? 2 } }
            });
          }

          const unit = formatterOptions.includeUnit ? trackUnit : '';
          return `${value} ${unit}`;
        };
      }

      break;

    case 'zValues': {
      console.error('This should not happen');
      // FIXME: Implement?
    }

    case 'step':
      if (useTrack || !is3DData) {
        result.axis[trackAxis].title = 'Measurement steps'; // TODO: Translate
        result.axis[trackAxis].formatter = (formatterOptions: LCaxisFormattingFunctionOptions) => {
          const value = formatterOptions.value_or_index?.toFixed(0) ?? '';
          return `${value}`;
        };
      }
      break;
    default:
      exhaustiveMatchingGuard(trackType);
      break;
  }

  return { infoMapping: result, trackType: trackType };
};

const toleranceBasedRound = (value: number, tolerance: number = 0.001): number => {
  let result: number = value;
  const difference = Math.abs(result - Math.round(result));

  if (difference < tolerance) {
    // adjust this value as needed
    result = Math.round(result);
  }
  return result;
};

const defaultLCinfoMapping = (includeZAxis: boolean): LCinfoMapping => {
  const result: LCinfoMapping = {
    axis: {
      X: {
        title: '',
        formatter: (formatterOptions: LCaxisFormattingFunctionOptions) => formatterOptions.value_or_index.toString()
      },
      Y: {
        title: '',
        formatter: (formatterOptions: LCaxisFormattingFunctionOptions) => formatterOptions.value_or_index.toString()
      }
    }
  };

  if (includeZAxis) {
    result.axis.Z = {
      title: '',
      formatter: (formatterOptions: LCaxisFormattingFunctionOptions) => formatterOptions.value_or_index.toString()
    };
  }

  return result;
};

const getAxisTitleAndUnitDisplayName = (
  quantity?: StreamingChart.Quantity,
  axistype: AxisScalingTypes = 'lin'
): { title: string; unit: string } => {
  let title = '';
  let unit = '';
  if (quantity) {
    title = quantity.name;
    if (axistype === 'db') {
      unit = 'dB';
    } else if (quantity.unitDisplayName) {
      unit = quantity.unitDisplayName;
    }
  }
  return { title, unit };
};

const handleNthOctaveAxisValue = (value_or_index: number, plottingParams: StreamingChart.PlottingParameters) => {
  let result: string = '';
  if (plottingParams?.octaveNth && plottingParams.rangeX?.start && Number.isInteger(value_or_index)) {
    const octaveNth = plottingParams.octaveNth;
    const start = plottingParams.rangeX.start;
    if (octaveNth === 3) {
      const thirdBands = getThirdBands();
      const bandNumber = start + value_or_index;
      if (thirdBands[bandNumber] !== undefined) {
        const frequency = thirdBands[bandNumber][1];
        if (frequency) {
          result = frequency.toFixed(1);
        }
      }
    } else {
      const channelDecimal = start + value_or_index * getNthOctaveDelta(octaveNth);
      result = getNthOctaveMiddleFrequency(channelDecimal).toFixed(1);
    }
  }
  return result;
};

export const generateFreqWeightString = (freqWeight: string | undefined) => {
  if (freqWeight) {
    return ` (${freqWeight})`;
  } else {
    return '';
  }
};

const createDefaultTrackQuantity = (): Quantity => {
  const trackQuantity = Quantity.create();
  trackQuantity.ampereExponent = 0;
  trackQuantity.ampereExponentDenominator = 1;
  trackQuantity.axisScaling = 'linear';
  trackQuantity.candelaExponent = 0;
  trackQuantity.candelaExponentDenominator = 1;
  trackQuantity.dbReferenceFactor = 1;
  trackQuantity.dbWithOrientation = false;
  trackQuantity.decimalPlaces = 'auto';
  trackQuantity.isPowerType = false;
  trackQuantity.isoFactor = 1;
  trackQuantity.isoOffset = 0;
  trackQuantity.kelvinExponent = 0;
  trackQuantity.kelvinExponentDenominator = 1;
  trackQuantity.kilogramExponent = 0;
  trackQuantity.kilogramExponentDenominator = 1;
  trackQuantity.meterExponent = 0;
  trackQuantity.meterExponentDenominator = 1;
  trackQuantity.molExponent = 0;
  trackQuantity.molExponentDenominator = 1;
  trackQuantity.name = 'Rotational Speed';
  trackQuantity.originalAngleExponent = 0;
  trackQuantity.originalAngleExponentDenominator = 1;
  trackQuantity.originalRotationalSpeedExponent = 1;
  trackQuantity.originalRotationalSpeedExponentDenominator = 1;
  trackQuantity.originalStrainExponent = 0;
  trackQuantity.originalStrainExponentDenominator = 1;
  trackQuantity.secondExponent = -1;
  trackQuantity.secondExponentDenominator = 1;
  trackQuantity.subscribeId = -1;
  trackQuantity.unitBaseName = '1/min';
  trackQuantity.unitDisplayName = '1/min';
  trackQuantity.unitIsoName = '1/min';
  trackQuantity.unitName = '1/min';
  trackQuantity.unitSiPrefix = 'ena';
  trackQuantity.yAmplitude = 'peak';
  return trackQuantity;
};
