import {
  FeatureFlag,
  BaseFeatureFlagIdentifiers,
  BaseFeatureFlags,
  ConcreteFeatureFlag,
  featureFlagDatatypeDefaults
} from '@vas/feature-flags';

import { DeprecatedFeatureFlagIdentifiers } from './app.feature-flags.deprecated';

// A Union Type of currently available Feature Flag Identifiers
export type FeatureFlagIdentifiers =
  | BaseFeatureFlagIdentifiers
  | 'ACCELERATION_STREAMING_DB_REFERENCE_FACTOR'
  | 'AGGREGATE_SIZE'
  | 'APIDOCS'
  | 'APPLICATION_LOGO'
  | 'APPLICATION_LOGO_TAGLINE'
  | 'APPLICATION_PRIMARY_COLOR'
  | 'CLOUDTPL'
  | 'CONTENT_COLLECTION_SHARE'
  | 'CREATE_ORDER'
  | 'CUSTOM_APPLICATION_LINKS'
  | 'DELETE_ORDER'
  | 'DEPOT_SEARCH_SORTING'
  | 'DEVICES'
  | 'GEO'
  | 'LIGHTNING_LICENSE'
  | 'NAVIGATION_TIMEOUT'
  | 'ONLINEDOCS_ADMIN'
  | 'ONLINEDOCS_USER'
  | 'ONLINEDOCS_WHATSNEW'
  | 'ORDERS_SORTING'
  | 'ORDERS_CHECKOUT_TOKEN_REFRESHRATE'
  | 'ORDERS_SHOW_PASSBY_SEND_TO_PAK'
  | 'ORDERS'
  | 'PAK_INSIGHT_DOWNLOAD'
  | 'PAK_INSIGHT_DOWNLOADANDUPDATE'
  | 'PROCESSING_FORMULA'
  | 'RESTORE_ORDER'
  | 'SHOW_SVG_PLOT'
  | 'TUTORIAL'
  | DeprecatedFeatureFlagIdentifiers;

// Before adding Feature Flags: Please review the Readme, several other places must be changed as well.
export const availableFeatureFlags: FeatureFlag[] = [
  ...BaseFeatureFlags,
  {
    id: 'ACCELERATION_STREAMING_DB_REFERENCE_FACTOR',
    descr: `A custom value to adjust the streaming dB reference factor for acceleration`,
    area: 'user',
    datatype: 'positiveNumber',
    defaultValue: 1e-6
  },
  {
    id: 'AGGREGATE_SIZE',
    descr: `Set the default aggregate size (must be well formed, comma separated triple of integers, i.e. '100,10,10' )`,
    area: 'user',
    datatype: 'aggregateSize',
    defaultValue: featureFlagDatatypeDefaults['aggregateSize']
  },
  {
    id: 'APIDOCS',
    descr: `Show the API Documentation`,
    area: 'user',
    datatype: 'boolean',
    defaultValue: featureFlagDatatypeDefaults['boolean']
  },
  {
    id: 'APPLICATION_LOGO',
    descr: `Use a specific Logo as Application Logo`,
    area: 'user',
    datatype: 'path',
    defaultValue: featureFlagDatatypeDefaults['path']
  },
  {
    id: 'APPLICATION_LOGO_TAGLINE',
    descr: `Use a specific Tagline next to the logo`,
    area: 'user',
    datatype: 'string',
    defaultValue: featureFlagDatatypeDefaults['string']
  },
  {
    id: 'APPLICATION_PRIMARY_COLOR',
    descr: `Use a specific color as header background`,
    area: 'user',
    datatype: 'color',
    defaultValue: featureFlagDatatypeDefaults['color']
  },
  {
    id: 'CLOUDTPL',
    descr: `Show Custom Cloud Third Party License link`,
    area: 'user',
    datatype: 'path',
    defaultValue: featureFlagDatatypeDefaults['path']
  },
  {
    id: 'CONTENT_COLLECTION_SHARE',
    descr: `Show the Content Collection Share Button`,
    area: 'user',
    datatype: 'boolean',
    defaultValue: featureFlagDatatypeDefaults['boolean']
  },
  {
    id: 'CREATE_ORDER',
    descr: `Enables the creation of orders by showing the 'Create Order' button`,
    area: 'user',
    datatype: 'boolean',
    defaultValue: featureFlagDatatypeDefaults['boolean']
  },
  {
    id: 'CUSTOM_APPLICATION_LINKS',
    descr: `Show Custom Application Links`,
    area: 'test',
    datatype: 'customAppsArray',
    defaultValue: featureFlagDatatypeDefaults['array']
  },
  {
    id: 'DELETE_ORDER',
    descr: `Enables the deletion of orders by showing the 'Delete Order' button`,
    area: 'user',
    datatype: 'boolean',
    defaultValue: featureFlagDatatypeDefaults['boolean']
  },
  {
    id: 'DEVICES',
    descr: `Show views related to Devices`,
    area: 'user',
    datatype: 'boolean',
    defaultValue: featureFlagDatatypeDefaults['boolean']
  },
  {
    id: 'DEPOT_SEARCH_SORTING',
    descr: `Enable Sorting Measurements in Depot Search`,
    area: 'user',
    datatype: 'boolean',
    defaultValue: featureFlagDatatypeDefaults['boolean']
  },
  {
    id: 'GEO',
    descr: `Show the Geometry Viewer Dummy Tile/Panel in Order Details`,
    area: 'user',
    datatype: 'boolean',
    defaultValue: featureFlagDatatypeDefaults['boolean']
  },
  {
    id: 'LIGHTNING_LICENSE',
    descr: `Required to enable proper LightningChartJS Licensing`,
    area: 'user',
    datatype: 'string',
    defaultValue: 'Enter Lightning License Token'
  },
  {
    id: 'NAVIGATION_TIMEOUT',
    descr: `Overwrite the navigation timeout to simulate requests timeouts [in ms]`,
    area: 'test',
    datatype: 'number',
    defaultValue: 60000
  },
  {
    id: 'ONLINEDOCS_ADMIN',
    descr: `Replace the link to the admin online documentation`,
    area: 'user',
    datatype: 'path',
    defaultValue: featureFlagDatatypeDefaults['path']
  },
  {
    id: 'ONLINEDOCS_USER',
    descr: `Replace the link to the user online documentation`,
    area: 'user',
    datatype: 'path',
    defaultValue: featureFlagDatatypeDefaults['path']
  },
  {
    id: 'ONLINEDOCS_WHATSNEW',
    descr: `Replace the link to the user online documentation`,
    area: 'user',
    datatype: 'path',
    defaultValue: featureFlagDatatypeDefaults['path']
  },
  {
    id: 'ORDERS_SORTING',
    descr: `Enable Order Sorting`,
    area: 'user',
    datatype: 'boolean',
    defaultValue: featureFlagDatatypeDefaults['boolean']
  },

  {
    id: 'ORDERS_CHECKOUT_TOKEN_REFRESHRATE',
    descr: `Overwrite the checkout refresh rate [in ms]`,
    area: 'test',
    datatype: 'number',
    defaultValue: 5000
  },

  {
    id: 'ORDERS_SHOW_PASSBY_SEND_TO_PAK',
    descr: `Show send to PAK link in PassBy Order Detail`,
    area: 'user',
    datatype: 'boolean',
    defaultValue: featureFlagDatatypeDefaults['boolean']
  },

  {
    id: 'ORDERS',
    descr: `Show views related to Orders`,
    area: 'user',
    datatype: 'boolean',
    defaultValue: featureFlagDatatypeDefaults['boolean']
  },

  {
    id: 'PAK_INSIGHT_DOWNLOAD',
    deprecatedIds: ['EVT_DOWNLOAD'],
    descr: `Enable downloading PAK insight`,
    area: 'user',
    datatype: 'path',
    defaultValue: featureFlagDatatypeDefaults['path']
  },
  {
    id: 'PAK_INSIGHT_DOWNLOADANDUPDATE',
    deprecatedIds: ['EVT_DOWNLOADANDUPDATE'],
    descr: `Eperimental, Enable downloading PAK insight with update capability`,
    area: 'user',
    datatype: 'path',
    defaultValue: featureFlagDatatypeDefaults['path']
  },

  {
    id: 'PROCESSING_FORMULA',
    descr: `Enables interaction with processing`,
    area: 'user',
    datatype: 'boolean',
    defaultValue: featureFlagDatatypeDefaults['boolean']
  },

  {
    id: 'RESTORE_ORDER',
    descr: `Enables the restoration of pending deletion orders`,
    area: 'user',
    datatype: 'boolean',
    defaultValue: featureFlagDatatypeDefaults['boolean']
  },

  {
    id: 'SHOW_SVG_PLOT',
    descr: `Show svg plot button in measurement's datasets`,
    area: 'user',
    datatype: 'boolean',
    defaultValue: featureFlagDatatypeDefaults['boolean']
  },
  {
    id: 'TUTORIAL',
    descr: `Show a custom Tutorial link`,
    area: 'user',
    datatype: 'path',
    defaultValue: featureFlagDatatypeDefaults['path']
  }
];

// App specific Feature Flag defaults
export const featureFlagDefaults: ConcreteFeatureFlag[] = [
  {
    id: 'AGGREGATE_SIZE',
    area: 'user',
    datatype: 'aggregateSize',
    precedence: 'default',
    value: [1000, 1000, 1000]
  },
  {
    id: 'APPLICATION_LOGO',
    area: 'user',
    datatype: 'path',
    precedence: 'default',
    value: 'assets/img/pak_cloud_signet_white.png'
  },
  {
    id: 'APPLICATION_PRIMARY_COLOR',
    area: 'user',
    datatype: 'color',
    precedence: 'default',
    value: '#1B2831'
  },
  {
    id: 'DEVICES',
    area: 'user',
    datatype: 'boolean',
    precedence: 'default',
    value: true
  },
  {
    id: 'ORDERS',
    area: 'user',
    datatype: 'boolean',
    precedence: 'default',
    value: true
  },
  {
    id: 'DELETE_ORDER',
    area: 'user',
    datatype: 'boolean',
    precedence: 'default',
    value: true
  },
  {
    id: 'RESTORE_ORDER',
    area: 'user',
    datatype: 'boolean',
    precedence: 'default',
    value: true
  },
  {
    id: 'ORDERS_CHECKOUT_TOKEN_REFRESHRATE',
    area: 'test',
    datatype: 'number',
    precedence: 'default',
    value: 5000
  },
  {
    id: 'DOCS',
    area: 'user',
    datatype: 'boolean',
    precedence: 'default',
    value: true
  },
  {
    id: 'APIDOCS',
    area: 'user',
    datatype: 'boolean',
    precedence: 'default',
    value: true
  },
  {
    id: 'ONLINEDOCS_ADMIN',
    area: 'user',
    datatype: 'path',
    precedence: 'default',
    value: 'https://docs.mbbm-vas.com/cloud/getting-started-admin/getting-started-admins/'
  },
  {
    id: 'ONLINEDOCS_USER',
    area: 'user',
    datatype: 'path',
    precedence: 'default',
    value: 'https://docs.mbbm-vas.com/cloud/getting-started-user/meas-search-preview/'
  },
  {
    id: 'ONLINEDOCS_WHATSNEW',
    area: 'user',
    datatype: 'path',
    precedence: 'default',
    value: 'https://docs.mbbm-vas.com/cloud/whats-new/'
  },
  {
    id: 'NAVIGATION_TIMEOUT',
    area: 'test',
    datatype: 'number',
    precedence: 'default',
    value: 60000
  }
];
