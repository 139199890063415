import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  baseTitle = '';
  routeFragmentTitle = '';

  constructor(private title: Title) {}

  setBaseTitle = (baseTitle: string) => {
    this.baseTitle = baseTitle;
    this.routeFragmentTitle = '';
    this.setTitle();
  };

  setRouteFragmentTitle = (routeFragmentTitle: string) => {
    this.routeFragmentTitle = routeFragmentTitle;
    this.setTitle();
  };

  private setTitle = () => {
    if (this.routeFragmentTitle && this.routeFragmentTitle.length > 0) {
      this.title.setTitle(`${this.baseTitle} - ${this.routeFragmentTitle}`);
    } else {
      this.title.setTitle(`${this.baseTitle}`);
    }
  };
}
