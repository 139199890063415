import {
  AxisOptions,
  AxisScrollStrategies,
  AxisTickStrategies,
  ChartXY,
  ColorHEX,
  Dashboard,
  LineSeries,
  Point,
  RectangleSeries,
  SolidFill,
  SolidLine,
  Themes,
  disableThemeEffects,
  emptyLine,
  lightningChart
} from '@arction/lcjs';
import { TranslateService } from '@ngx-translate/core';
import { exhaustiveMatchingGuard } from '../../../shared/utility-functions/exhaustiveMatchingGuard';
import { StreamingChart } from '../../utils/streaming-chart.types';
import { LCconstructorOptions2D, ScalesConfig2D } from '../lightningPlot.types';
import { timeDifference } from '../../utils/time.utils';
import { calc2DYValues } from './lightningChartCustomCalculations2D';
import { LightningPlot, LocalValues3D2D } from '../lightningPlot';
import { StreamingFacade } from '../../+state/streaming.facade';
import { Update2DData } from './lightningChart2D';
import { distinguishableValuesFractionDigitsForMinimalDelta } from '../../utils/formatting.utils';

const seriesColor = '#0072A3';
const ENABLE_AXIS_CURSOR_LABEL = false;

export class LightningPlot3D2D extends LightningPlot {
  lineSeries?: LineSeries;
  rectangleSeries?: RectangleSeries;

  values: LocalValues3D2D = {
    dataValues: [],
    externalTrackValues: {
      absoluteInt128Time: [],
      values: []
    },
    stepValues: {
      steps: [],
      zValues: [],
      times: []
    }
  };

  constructor(options: LCconstructorOptions2D, translate: TranslateService, streamingFacade: StreamingFacade) {
    super(options, translate, streamingFacade);
  }

  protected override setupPlot(scalesConfig?: ScalesConfig2D) {
    if (!this.disposed) {
      this.dashBoard = this.createDashboard();
      this.chart = this.createChart(scalesConfig ?? undefined);

      this.initChart();
      this.setupAxisFormatters();
      this.setupAxisBehavior();
    }
  }

  private createDashboard(): Dashboard {
    const dashBoard = lightningChart(this.licenseConfig.license, {
      appTitle: this.licenseConfig.appTitle,
      company: this.licenseConfig.company
    }).Dashboard({
      numberOfRows: 1,
      numberOfColumns: 1,
      container: this.htmlElementId,
      theme: disableThemeEffects(Themes.light)
    });

    dashBoard.setColumnWidth(0, 11);
    dashBoard.setColumnWidth(1, 1);
    dashBoard.setSplitterStyle(emptyLine);
    return dashBoard;
  }

  private createChart(scalesConfig?: ScalesConfig2D): ChartXY {
    // TODO: Extract / simplify mapping
    let axisYConfig: AxisOptions = { type: 'linear' };
    if (scalesConfig && scalesConfig.yAxis) {
      switch (scalesConfig.yAxis) {
        case 'lin': {
          axisYConfig = { type: 'linear' };
          break;
        }
        case 'log': {
          axisYConfig = { type: 'logarithmic', base: 10 };
          break;
        }
        case 'db': {
          axisYConfig = { type: 'linear' };
          break;
        }
        case 'third':
          console.error('Chart2D with third octave at y-axis will not implemented');
          break;
        default:
          exhaustiveMatchingGuard(scalesConfig.yAxis);
      }
    }

    let axisXConfig: AxisOptions = { type: 'linear' };

    const chart = this.dashBoard
      .createChartXY({
        columnIndex: 0,
        columnSpan: 1,
        rowIndex: 0,
        rowSpan: 1,
        defaultAxisX: axisXConfig as AxisOptions,
        defaultAxisY: axisYConfig as AxisOptions
      })
      .setAnimationsEnabled(false)
      .setSeriesHighlightOnHover(false);

    return chart;
  }

  private initChart() {
    // member variables initialization
    this.lastTrackValue = undefined;

    this.chart.setTitle(this.generatePlotTitle(this.tpDatasetParams.currentDatasetParams));

    this.lineSeries = this.chart.addLineSeries({
      dataPattern: {
        pattern: 'ProgressiveX',
        regularProgressiveStep: false
      }
    });
    this.lineSeries?.setName('');
    this.lineSeries?.setStrokeStyle(new SolidLine({ fillStyle: new SolidFill({ color: ColorHEX(seriesColor) }) }));
    this.lineSeries?.setCursorInterpolationEnabled(false);

    this.rectangleSeries = this.chart.addRectangleSeries();
    this.rectangleSeries?.setName('');

    this.chart.getDefaultAxisX().setScrollStrategy(AxisScrollStrategies.fitting);
  }

  private setupAxisFormatters() {
    this.chart.getDefaultAxisY().setTickStrategy(AxisTickStrategies.Numeric, (numericTicks) =>
      numericTicks
        .setCursorFormatter((tickPosition) => {
          if (ENABLE_AXIS_CURSOR_LABEL) {
            return this.infoMapping.axis.Y.formatter({
              value_or_index: tickPosition,
              isStepIndex: false,
              includeUnit: true
            });
          } else {
            return '';
          }
        })
        .setMajorFormattingFunction((tickPosition) => {
          if (this.infoMapping?.axis?.Y?.formatter) {
            return this.infoMapping.axis.Y.formatter({
              value_or_index: tickPosition,
              isStepIndex: false,
              includeUnit: false
            });
          } else {
            return '';
          }
        })
        .setMinorFormattingFunction((tickPosition) => {
          if (this.infoMapping?.axis?.Y?.formatter) {
            return this.infoMapping.axis.Y.formatter({
              value_or_index: tickPosition,
              isStepIndex: false,
              includeUnit: false
            });
          } else {
            return '';
          }
        })
    );

    this.chart.getDefaultAxisX().setTickStrategy(AxisTickStrategies.Numeric, (numericTicks) =>
      numericTicks
        .setCursorFormatter((tickPosition) => {
          if (ENABLE_AXIS_CURSOR_LABEL) {
            return this.infoMapping.axis.X.formatter({
              value_or_index: tickPosition,
              isStepIndex: false,
              includeUnit: true
            });
          } else {
            return '';
          }
        })
        .setMajorFormattingFunction((tickPosition) => {
          if (this.infoMapping?.axis?.X?.formatter) {
            return this.infoMapping.axis.X.formatter({
              value_or_index: tickPosition,
              isStepIndex: false,
              includeUnit: false
            });
          } else {
            return '';
          }
        })
        .setMinorFormattingFunction((tickPosition) => {
          if (this.infoMapping?.axis?.X?.formatter) {
            return this.infoMapping.axis.X.formatter({
              value_or_index: tickPosition,
              isStepIndex: false,
              includeUnit: false
            });
          } else {
            return '';
          }
        })
    );

    this.lineSeries?.setCursorResultTableFormatter((builder, _, x, y, datapoint) => {
      // console.log('3D->2D cursor x:', x, ' y:', y, ' xv:', datapoint.x, ' yv:', datapoint.y);
      return builder
        .addRow(
          `x: ${this.infoMapping.axis.X.formatter({
            value_or_index: datapoint.x,
            isStepIndex: false,
            includeUnit: true,
            decimalPlaces: this.DECIMAL_PLACES_X
          })}`
        )
        .addRow(
          `y: ${this.infoMapping.axis.Y.formatter({
            value_or_index: datapoint.y,
            isStepIndex: false,
            includeUnit: true
          })}`
        );
    });

    this.rectangleSeries?.setCursorResultTableFormatter((builder, series, figure) => {
      const rectangleDimensions = figure.getDimensionsTwoPoints();
      return builder.addRow(
        `x: ${this.infoMapping.axis.X.formatter({
          value_or_index: rectangleDimensions.x1,
          isStepIndex: false,
          includeUnit: true,
          decimalPlaces: this.DECIMAL_PLACES_X
        })}`
      );
    });

    this.chart.getDefaultAxisY().onIntervalChange((defaultAxis, start, end) => {
      this.checkRebounceOnIntervallChange(
        this.lineSeries?.getYMin(),
        this.lineSeries?.getYMax(),
        defaultAxis,
        start,
        end
      );
    });

    this.chart.getDefaultAxisX().onIntervalChange((defaultAxis, start, end) => {
      this.checkRebounceOnIntervallChange(
        this.lineSeries?.getXMin(),
        this.lineSeries?.getXMax(),
        defaultAxis,
        start,
        end
      );
    });
  }

  private setupAxisBehavior() {
    this.chart.getDefaultAxisY().setNibMousePickingAreaSize(0);
    this.chart.getDefaultAxisX().setNibMousePickingAreaSize(0);
    this.chart.setBackgroundFillStyle(new SolidFill({ color: ColorHEX('#ffffff00') }));
  }

  public override handleScaleConfigChange(scalesConfig: ScalesConfig2D) {
    this.setupPlot(scalesConfig);
    if (this.tpDatasetParams.currentDatasetParams.plottingParameters?.rangeZ?.num === 1) {
      this.updateLineSeries(this.values.dataValues, this.values.stepValues.zValues);
    } else {
      switch (this.trackType) {
        case 'time':
          {
            let relativeTimes: number[] = [];
            const startPoint: bigint =
              this.tpDatasetParams.currentDatasetParams.plottingParameters?.measZeroPoint ?? BigInt(0);
            this.values.stepValues.times.forEach((value) => {
              relativeTimes.push(timeDifference(value, startPoint));
            });
            this.updateLineSeries(this.values.dataValues, relativeTimes);
          }
          break;
        case 'track':
          {
            const xValues = this.values.stepValues.steps.map((step) => {
              return step.trackValue;
            });
            this.updateLineSeries(this.values.dataValues, xValues);
          }
          break;
        case 'orderRPM':
          {
            const xValues = this.values.stepValues.steps.map((step) => {
              return step.orderRpmValue;
            });
            this.updateLineSeries(this.values.dataValues, xValues);
          }
          break;
        case 'originalTrack':
          if (this.values.externalTrackValues && this.values.externalTrackValues.values.length > 0) {
            let xValues: number[] = [];
            this.values.stepValues.times.forEach((val) => {
              const i = this.values.externalTrackValues?.absoluteInt128Time.findIndex(
                (value: bigint, index: number) => {
                  if (value >= val) {
                    return true;
                  }
                }
              );
              if (i !== undefined && i >= 0) {
                if (this.values.externalTrackValues?.values[i]) {
                  xValues.push(this.values.externalTrackValues?.values[i]);
                } else {
                  console.error('didnt found track value for time (2) ', val);
                  xValues.push(xValues.at(-1) ?? 0);
                }
              } else {
                console.error('didnt found track value for time ', val);
                xValues.push(xValues.at(-1) ?? 0);
              }
            });
            this.updateLineSeries(this.values.dataValues, xValues);
            break;
          }
        case 'zValues':
          this.updateLineSeries(this.values.dataValues, this.values.stepValues.zValues);
          break;
        case 'step':
          const xvals: number[] = [0];
          this.updateLineSeries(this.values.dataValues, xvals);
          break;
      }
    }
    this.updateInfoMapping();
  }

  public add3D2Dincrement = (data: Update2DData) => {
    setTimeout(() => {
      const xStart = this.values.dataValues.length;
      // this.values.dataValues.push(...data.dataValues); - s. ERROR RangeError: Maximum call stack size exceeded
      data.dataValues.forEach((value) => {
        this.values.dataValues.push(value);
      });
      if (this.tpDatasetParams.currentDatasetParams.plottingParameters?.rangeZ?.num === 1) {
        data.trackOrXValues?.forEach((value) => {
          this.values.stepValues.zValues.push(value);
          if (this.lastTrackValue !== undefined) {
            const diff = Math.abs(value - this.lastTrackValue);
            if (this.MINIMUM_X_DELTA === undefined || diff < this.MINIMUM_X_DELTA) {
              this.MINIMUM_X_DELTA = diff;
            }
          }
          this.lastTrackValue = value;
        });
        this.DECIMAL_PLACES_X = distinguishableValuesFractionDigitsForMinimalDelta(this.MINIMUM_X_DELTA);
        this.updateLineSeries(data.dataValues, data.trackOrXValues ?? Number[0]);
      } else {
        switch (this.trackType) {
          case 'time':
            {
              data.trackOrXValues?.forEach((value) => {
                this.values.stepValues.zValues.push(value);
              });
              let relativeTimes: number[] = [];
              const startPoint: bigint =
                this.tpDatasetParams.currentDatasetParams.plottingParameters?.measZeroPoint ?? BigInt(0);
              data.absTimeValues?.forEach((value) => {
                this.values.stepValues.times.push(value);
                const relTime = timeDifference(value, startPoint);
                if (this.lastTrackValue !== undefined) {
                  const diff = Math.abs(relTime - this.lastTrackValue);
                  if (this.MINIMUM_X_DELTA === undefined || diff < this.MINIMUM_X_DELTA) {
                    this.MINIMUM_X_DELTA = diff;
                  }
                }
                this.lastTrackValue = relTime;

                relativeTimes.push(relTime);
              });
              this.DECIMAL_PLACES_X = distinguishableValuesFractionDigitsForMinimalDelta(this.MINIMUM_X_DELTA);
              this.updateLineSeries(data.dataValues, relativeTimes);
            }
            break;
          case 'track':
            {
              data.trackOrXValues?.forEach((value) => {
                this.values.stepValues.zValues.push(value);
              });
              let xValues: number[] = [];
              data.absTimeValues?.forEach((val, index) => {
                this.values.stepValues.times.push(val);
                const step = this.values.stepValues.steps.at(xStart + index);
                if (step) {
                  xValues.push(step.trackValue);
                } else {
                  console.error('didnt found track value in step values at index ${index}');
                  xValues.push(xValues.at(-1) ?? 0);
                }
              });
              xValues.forEach((value) => {
                if (this.lastTrackValue !== undefined) {
                  const diff = Math.abs(value - this.lastTrackValue);
                  if (this.MINIMUM_X_DELTA === undefined || diff < this.MINIMUM_X_DELTA) {
                    this.MINIMUM_X_DELTA = diff;
                  }
                }
                this.lastTrackValue = value;
              });
              this.DECIMAL_PLACES_X = distinguishableValuesFractionDigitsForMinimalDelta(this.MINIMUM_X_DELTA);
              this.updateLineSeries(this.values.dataValues, xValues);
            }
            break;
          case 'orderRPM':
            {
              data.trackOrXValues?.forEach((value) => {
                this.values.stepValues.zValues.push(value);
              });
              let xValues: number[] = [];
              data.absTimeValues?.forEach((val, index) => {
                this.values.stepValues.times.push(val);
                const step = this.values.stepValues.steps.at(xStart + index);
                if (step) {
                  xValues.push(step.orderRpmValue);
                } else {
                  console.error('didnt found order rpm value at index ${index}');
                  xValues.push(xValues.at(-1) ?? 0);
                }
              });
              xValues.forEach((value) => {
                if (this.lastTrackValue !== undefined) {
                  const diff = Math.abs(value - this.lastTrackValue);
                  if (this.MINIMUM_X_DELTA === undefined || diff < this.MINIMUM_X_DELTA) {
                    this.MINIMUM_X_DELTA = diff;
                  }
                }
                this.lastTrackValue = value;
              });
              this.DECIMAL_PLACES_X = distinguishableValuesFractionDigitsForMinimalDelta(this.MINIMUM_X_DELTA);
              this.updateLineSeries(this.values.dataValues, xValues);
            }
            break;
          case 'originalTrack':
            if (this.values.externalTrackValues && this.values.externalTrackValues.values.length > 0) {
              data.trackOrXValues?.forEach((value) => {
                this.values.stepValues.zValues.push(value);
              });
              let xValues: number[] = [];
              data.absTimeValues?.forEach((val) => {
                this.values.stepValues.times.push(val);
                const i = this.values.externalTrackValues?.absoluteInt128Time.findIndex(
                  (value: bigint, index: number) => {
                    if (value >= val) {
                      return true;
                    }
                  }
                );
                if (i !== undefined && i >= 0) {
                  if (this.values.externalTrackValues?.values[i]) {
                    xValues.push(this.values.externalTrackValues?.values[i]);
                  } else {
                    console.error('didnt found track value for time (2) ', val);
                    xValues.push(xValues.at(-1) ?? 0);
                  }
                } else {
                  console.error('didnt found track value for time ', val);
                  xValues.push(xValues.at(-1) ?? 0);
                }
              });
              xValues.forEach((value) => {
                if (this.lastTrackValue !== undefined) {
                  const diff = Math.abs(value - this.lastTrackValue);
                  if (this.MINIMUM_X_DELTA === undefined || diff < this.MINIMUM_X_DELTA) {
                    this.MINIMUM_X_DELTA = diff;
                  }
                }
                this.lastTrackValue = value;
              });
              this.DECIMAL_PLACES_X = distinguishableValuesFractionDigitsForMinimalDelta(this.MINIMUM_X_DELTA);
              this.updateLineSeries(data.dataValues, xValues);

              break;
            }
          case 'zValues':
            data.absTimeValues?.forEach((value) => {
              this.values.stepValues.times.push(value);
            });
            data.trackOrXValues?.forEach((value) => {
              this.values.stepValues.zValues.push(value);
              if (this.lastTrackValue !== undefined) {
                const diff = Math.abs(value - this.lastTrackValue);
                if (this.MINIMUM_X_DELTA === undefined || diff < this.MINIMUM_X_DELTA) {
                  this.MINIMUM_X_DELTA = diff;
                }
              }
              this.lastTrackValue = value;
            });
            this.DECIMAL_PLACES_X = distinguishableValuesFractionDigitsForMinimalDelta(this.MINIMUM_X_DELTA);
            this.updateLineSeries(data.dataValues, data.trackOrXValues ?? Number[0]);
            break;
          case 'step':
            data.trackOrXValues?.forEach((value) => {
              this.values.stepValues.zValues.push(value);
            });
            data.absTimeValues?.forEach((value) => {
              this.values.stepValues.times.push(value);
            });
            const xvals: number[] = [xStart];
            this.DECIMAL_PLACES_X = 0;
            this.updateLineSeries(data.dataValues, xvals);
            break;
        }
        this.updateInfoMapping();
      }
      if (this.lineSeries) {
        this.yAxisInterval = { start: this.lineSeries.getYMax(), end: this.lineSeries.getYMin() };
        this.xAxisInterval = { start: this.lineSeries.getXMax(), end: this.lineSeries.getXMin() };
      } else {
        this.yAxisInterval = { start: this.rectangleSeries?.getYMax(), end: this.rectangleSeries?.getYMin() };
        this.xAxisInterval = { start: this.rectangleSeries?.getXMax(), end: this.rectangleSeries?.getXMin() };
      }
      if (data.eofReached) {
        this.resetAxis();
      }
    }, 0);
  };

  updateLineSeries(dataValues: number[], xvals: number[]) {
    if (this.tpDatasetParams.currentDatasetParams) {
      const additionalYvals = calc2DYValues(
        dataValues,
        this.scalesConfig,
        this.tpDatasetParams.currentDatasetParams,
        this.calibration
      );
      let additionalPoints: Point[];
      if (xvals.length === 1 && dataValues.length > 1) {
        additionalPoints = additionalYvals.map((value, index) => {
          return { x: xvals[0] + index, y: value };
        });
      } else if (additionalYvals.length === xvals.length) {
        additionalPoints = additionalYvals.map((value, index) => {
          return { x: xvals[index], y: value };
        });
      } else {
        additionalPoints = [];
        console.error('Chart3D2D with different length of x (${xvals.length}) and y (${additionalYvals.length})');
      }

      this.lineSeries?.add(additionalPoints);
    }
  }

  // Parent class overrides

  resetAxis() {
    this.chart.getDefaultAxisX().fit(true);
    this.chart.getDefaultAxisY().fit(true);
  }

  protected override generatePlotTitle(parameter: StreamingChart.DatasetParameters): string {
    const quantityName: string = parameter.plottingParameters?.quantityY?.name ?? ''; // TODO reference quantityY and position
    return 'Channel ' + parameter.label + ' [' + parameter.direction + '] ' + quantityName + ' - ' + parameter.type;
  }

  protected override additionalDispose(): void {
    this.lineSeries?.dispose();
    this.rectangleSeries?.dispose();
  }
}
