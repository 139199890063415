import {
  ConcreteFeatureFlag,
  FeatureFlag,
  FeatureFlagContentValidatorFn,
  FeatureFlagTypeValidatorFn,
  FeatureFlagValidatorFn,
  FeatureFlagValidatorMappingFn,
  FeatureFlagValueType
} from '../feature-flags.types';
const validate = require('validate.js');

// Feature Flag Type Validators, check whether the value of a specific flag is of the expected type

const numberValidator: FeatureFlagTypeValidatorFn<number> = (value: unknown): value is number => {
  return !isNaN(Number(value));
};

const positiveNumberValidator: FeatureFlagTypeValidatorFn<number> = (value: unknown): value is number => {
  return !isNaN(Number(value)) && Number(value) > 0;
};

const booleanValidator: FeatureFlagTypeValidatorFn<boolean> = (value: unknown): value is boolean => {
  return typeof value === 'boolean';
};

const stringValidator: FeatureFlagTypeValidatorFn<string> = (value: unknown): value is string => {
  return typeof value === 'string';
};

const arrayValidator: FeatureFlagTypeValidatorFn<any[]> = (value: unknown): value is any[] => {
  return typeof value === 'object' && Array.isArray(value);
};

const objectValidator: FeatureFlagTypeValidatorFn<object> = (value: unknown): value is object => {
  return typeof value === 'object' && !Array.isArray(value);
};

export type CustomApps = { name: string; url: string }[];
const customAppsArrayValidator: FeatureFlagTypeValidatorFn<CustomApps> = (value: unknown): value is CustomApps => {
  let valid = false;

  if (stringValidator(value) || arrayValidator(value)) {
    // Validate string by parsing it as JSON and checking the form: [{'name': 'string', 'url': 'string'}]
    try {
      const parsedValue = stringValidator(value) ? JSON.parse(value) : value;
      if (Array.isArray(parsedValue)) {
        valid = parsedValue.every((entry) => {
          const isObject = typeof entry === 'object';
          const hasName = entry.hasOwnProperty('name');
          const hasUrl = entry.hasOwnProperty('url');
          const nameIsString = typeof entry.name === 'string';
          const urlIsString = typeof entry.url === 'string';
          const ok = isObject && hasName && hasUrl && nameIsString && urlIsString;
          if (!ok) {
            console.error('Invalid custom app entry', entry);
          }
          return ok;
        });
      }
    } catch (e) {
      console.error('Error parsing custom apps array', e);
    }
  }
  return valid;
};

// Feature Flag Content Validators, check whether the value of a specific flag conforms to the expected format

const nonEmptyValidator: FeatureFlagContentValidatorFn = (value: unknown): boolean => {
  const isBoolean = typeof value === 'boolean';
  let isNonEmptyString = false;
  if (typeof value === 'string') {
    isNonEmptyString = value.length > 0;
  }
  return isBoolean || isNonEmptyString;
};

const arrayOfLengthValidator =
  (length: number) =>
  (value: unknown): boolean => {
    let valid = false;
    if (Array.isArray(value)) {
      valid = value.length === length;
    }
    return valid;
  };

const arrayContentIsOfTypeValidator =
  (type: 'string' | 'number' | 'array') =>
  (value: unknown): boolean => {
    switch (type) {
      case 'string':
        return Array.isArray(value) && value.every((entry) => typeof entry === 'string');
      case 'number':
        return Array.isArray(value) && value.every((entry) => typeof entry === 'number');
      case 'array':
        return Array.isArray(value) && value.every((entry) => Array.isArray(entry));
      default:
        return false;
    }
  };

const urlValidator: FeatureFlagContentValidatorFn = (value: unknown): boolean => {
  let isAbsoluteURL = false;
  let isRelativePath = false;

  if (typeof value === 'string') {
    isAbsoluteURL = isValidURL(value);
    isRelativePath = isValidRelativePath(value);
  }

  return isAbsoluteURL || isRelativePath;
};

const pathValidator: FeatureFlagContentValidatorFn = (value: unknown): boolean => {
  // Accept only relative Paths, not URLs
  let isAbsoluteURL = true;
  let isRelativePath = false;

  if (typeof value === 'string') {
    isAbsoluteURL = isValidURL(value);
    isRelativePath = isValidRelativePath(value);
  }

  return !isAbsoluteURL && isRelativePath;
};

export const featureFlagValidators = {
  nonEmptyStringValidator: nonEmptyValidator,
  stringValidator,
  arrayValidator,
  objectValidator,
  urlValidator,
  pathValidator,
  numberValidator,
  positiveNumberValidator,
  booleanValidator,
  arrayOfLengthValidator,
  arrayContentIsOfTypeValidator,
  customAppsArrayValidator
};

// A map assigning validators to feature flag identifiers
// Reason: Function References cannot be serialized and therefore cannot be part of NgRx store
// TODO: Let client applications chose validators based on validator string identifiers
export const featureFlagValidatorMapping: FeatureFlagValidatorMappingFn = {
  AGGREGATE_SIZE: [featureFlagValidators.arrayOfLengthValidator(3), arrayContentIsOfTypeValidator('number')],
  LIGHTNING_LICENSE: [featureFlagValidators.nonEmptyStringValidator],
  CUSTOM_APPLICATION_LINKS: [featureFlagValidators.customAppsArrayValidator],
  ACCELERATION_STREAMING_DB_REFERENCE_FACTOR: [featureFlagValidators.positiveNumberValidator]
};

export const assembleValidatorsForFlag = (flag: FeatureFlag | ConcreteFeatureFlag): FeatureFlagValidatorFn[] => {
  const validators: FeatureFlagValidatorFn[] = [];
  if (featureFlagValidatorMapping[flag.id]) {
    validators.push(...(featureFlagValidatorMapping[flag.id] as FeatureFlagValidatorFn[]));
  } else if (flag.datatype === 'url') {
    validators.push(featureFlagValidators.urlValidator);
  } else if (flag.datatype === 'number') {
    validators.push(featureFlagValidators.numberValidator);
  } else if (flag.datatype === 'boolean') {
    validators.push(featureFlagValidators.booleanValidator);
  } else if (flag.datatype === 'string') {
    validators.push(featureFlagValidators.stringValidator);
  } else if (flag.datatype === 'array') {
    validators.push(featureFlagValidators.arrayValidator);
  } else if (flag.datatype === 'object') {
    validators.push(featureFlagValidators.objectValidator);
  }
  return validators;
};

export const validateFeatureFlagValue = (
  flag: FeatureFlag | ConcreteFeatureFlag,
  value: FeatureFlagValueType,
  validators: FeatureFlagValidatorFn[]
): boolean => {
  let isValid = false;

  for (const validator of validators) {
    try {
      isValid = validator(value);
    } catch (e) {
      console.error(`Error during validation of Feature Flag ${flag.id}:`, e);
    }

    if (!isValid) {
      break;
    }
  }
  return isValid;
};

const isValidURL = (str: string): boolean => {
  const isValid = validate(
    { website: str },
    {
      website: {
        url: {
          allowLocal: true
        }
      }
    }
  );
  return isValid === undefined;
};

const isValidRelativePath = (str: string): boolean => {
  const r = new RegExp('^(?:[a-z]+:)?//', 'i');
  return !r.test(str);
};
