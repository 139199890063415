// eslint-disable-next-line @typescript-eslint/no-empty-interface

import { AggregateTree, AggregateTreeDef } from './aggregateTree.types';
import { DepotAttribute, SearchFilter, SemanticDepotAttribute } from '../../shared/+state/attributes/attributes.types';
import { SortDirection } from './sorting.types';
import { DepotSearchParameters } from '../../depot-search/depot-search.types';
import { OrderSearchParameters } from '../../order-management/order-search.types';

export interface Depot {
  id: string;
  name?: string;
}

export type searchStatus = 'active' | 'error' | 'done' | 'init';

export const pendingDeletionAttrId = 'pending_deletion_properties.has_pending_deletion'; // ID of has_pending_deletion attribute used to mark deleted measurement in search index

export interface SortAttributeAndValue {
  id: string;
  depot_attribute: DepotAttribute | SemanticDepotAttribute;
  attribute: SortDirection;
}

export const isDepotSearchParams = (
  params: DepotSearchParameters | OrderSearchParameters
): params is DepotSearchParameters => {
  return (params as DepotSearchParameters).restrictToActiveCC !== undefined;
};

export const isOrderSearchParams = (
  params: DepotSearchParameters | OrderSearchParameters
): params is OrderSearchParameters => {
  return (params as OrderSearchParameters).collapse !== undefined;
};

export interface SearchParameters {
  text: string;
  searchLimit: number;
  offset: number;
  description?: string;
  resultColumns?: ResultColumn[];
  searchFilters?: SearchFilter;
  aggregateTreeDefs?: AggregateTreeDef[];
  sortFilter?: SortAttributeAndValue[];
}

export interface ResultColumn {
  /** attribute associated with the column */
  attribute: DepotAttribute | SemanticDepotAttribute;
  /** Identifier for the column. Either 'idName' for DepotAttribute or 'id' for SemanticDepotAttribute */
  field: string;
  /** Was column content already loaded? */
  contentLoaded?: boolean;
}

export interface SearchResultEntry {
  measurementBrowseUrl: string;
  measurementId: string;
  [index: string]: string;
}

export interface SearchResult {
  numberOfEntries: number;
  numberOfTotalResults: number;
  entries: SearchResultEntry[];
  aggregates?: AggregateTree[];
}
