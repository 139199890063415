<clr-dropdown class="attributeDropdownButton" [clrCloseMenuOnItemClick]="false">
  <button
    *ngIf="searchAttribute?.attribute?.type === 'String'"
    type="button"
    class="btn btn-outline-primary btn-sm badgebarItem selectItem"
    [attr.data-cy]="
      'attributeStringDropdownButton_' +
      (isDepotAttribute(searchAttribute.attribute) ? searchAttribute.attribute.idName.replaceAll('.', '__dot__') : searchAttribute.attribute.id)
    "
    (click)="toggleDropdown()"
  >
    <clr-icon
      class="exact-match-icon"
      *ngIf="searchAttribute.exact_match"
      shape="crosshairs"
      [title]="'DEPOTSEARCH.EXACTSEARCH' | translate"
    ></clr-icon>
    <span>{{ searchAttribute.attribute | translateAttribute }}: </span
    ><span id="text" class="searchAttributeInputValue">{{ searchAttribute.searchAttributeValue }}</span>
    <clr-icon shape="caret down"></clr-icon>
  </button>

  <button
    *ngIf="searchAttribute?.attribute?.type === 'Date'"
    type="button"
    class="btn btn-outline-primary btn-sm badgebarItem selectItem"
    (click)="toggleDropdown()"
    [attr.data-cy]="
      'attributeDateDropdownButton_' +
      (isDepotAttribute(searchAttribute.attribute) ? searchAttribute.attribute.idName : searchAttribute.attribute.id)
    "
  >
    <span>{{ searchAttribute.attribute | translateAttribute }}: </span
    ><span id="text" class="searchAttributeInputValue">
      {{ searchAttribute.searchAttributeStart | date: 'mediumDate':'':translate.currentLang }} -
      {{ searchAttribute.searchAttributeEnd | date: 'mediumDate':'':translate.currentLang }}
    </span>
    <clr-icon shape="caret down"></clr-icon>
  </button>

  <div
    *ngIf="searchAttribute?.attribute?.type === 'Boolean'"
    class="btn btn-outline-primary btn-sm badgebarItem selectItem"
    (click)="setSearchBoolean(searchAttribute)"
  >
    <span style="float: left">{{ searchAttribute.attribute | translateAttribute }}: </span>

    <span id="text" style="float: left; margin-left: 10px; margin-top: 0px"
      ><input
        type="checkbox"
        clrCheckbox
        name="options"
        (change)="setSearchBoolean(searchAttribute)"
        [ngModel]="searchAttribute.searchAttributeBoolean"
        data-cy="searchAttributeBooleanToggle"
    /></span>
  </div>

  <button
    type="button"
    class="btn btn-icon btn-sm badgebarItem removeItem"
    aria-label="remove"
    [attr.data-cy]="
      'attributeDropdownRemoveButton_' +
      (isDepotAttribute(searchAttribute.attribute) ? searchAttribute.attribute.idName : searchAttribute.attribute.id)
    "
    (click)="removeSearchBarFilter(searchAttribute)"
  >
    <clr-icon shape="times"></clr-icon>
  </button>
  <div
    id="datePickerDropdown"
    *ngIf="shouldShowDropdown$ | async"
    (clickOutside)="handleClickOutside($event)"
    [delayClickOutsideInit]="true"
    [excludeBeforeClick]="true"
    [exclude]="'clr-datepicker-view-manager'"
  >
    <div *ngIf="searchAttribute.attribute.type === 'String'">
      <div class="attributeValueDropdown">
        <div style="margin-bottom: 23px">
          <input
            clrInput
            #attributeValueDropdown
            placeholder="{{ 'DEPOTSEARCH.ATTRIBUTEVALUE' | translate }}"
            name="input"
            [id]="searchAttribute.id"
            data-cy="searchAttributeStringValueInput"
            [ngModel]="searchAttribute.searchAttributeValue"
            (ngModelChange)="markSearchAttributeValue(searchAttribute, attributeValueDropdown.value)"
            (keydown)="preventSpaceDismiss($event)"
            (keydown.enter)="handleEnter()"
          />
        </div>

        <div>
          <clr-checkbox-wrapper>
            <input
              type="checkbox"
              clrCheckbox
              name="exactmatch"
              data-cy="searchAttributeStringValueExactMatchCheckbox"
              [ngModel]="searchAttribute.exact_match"
              (change)="markSearchAttributeExactMatch(searchAttribute)"
              (keydown)="preventSpaceDismiss($event)"
              (keydown.enter)="handleEnter()"
            />
            <label>{{ 'DEPOTSEARCH.EXACTSEARCH' | translate }}</label>
          </clr-checkbox-wrapper>
        </div>
      </div>
    </div>
    <div *ngIf="searchAttribute.attribute.type === 'Date'">
      <div class="attributeValueDropdown">
        <form clrForm clrLayout="vertical">
          <clr-date-container>
            <input
              type="date"
              #startdate
              name="startdate"
              [id]="searchAttribute.id"
              data-cy="searchAttributeStartDateValueInput"
              [(clrDate)]="searchAttribute.searchAttributeStart"
              (keydown)="preventSpaceDismiss($event)"
              (keydown.enter)="$event.preventDefault(); startdate.blur(); handleEnter()"
              (change)="
                markDateInputValue(searchAttribute, 'searchAttributeStart', searchAttribute.searchAttributeStart)
              "
              (clrDateChange)="
                markDateInputValue(searchAttribute, 'searchAttributeStart', searchAttribute.searchAttributeStart)
              "
              clrDate
            />
          </clr-date-container>
        </form>

        <form clrForm clrLayout="vertical">
          <clr-date-container>
            <input
              type="date"
              #enddate
              name="enddate"
              data-cy="searchAttributeEndDateValueInput"
              [(clrDate)]="searchAttribute.searchAttributeEnd"
              (keydown)="preventSpaceDismiss($event)"
              (keydown.enter)="$event.preventDefault(); enddate.blur(); handleEnter()"
              (change)="markDateInputValue(searchAttribute, 'searchAttributeEnd', searchAttribute.searchAttributeEnd)"
              (clrDateChange)="
                markDateInputValue(searchAttribute, 'searchAttributeEnd', searchAttribute.searchAttributeEnd)
              "
              clrDate
            />
          </clr-date-container>
        </form>
      </div>
    </div>
  </div>
</clr-dropdown>
