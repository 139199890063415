import {
  Order,
  Orders,
  initialResources,
  OrderResource,
  CallState,
  LoadingState,
  PendingState,
  OrderState
} from './../order.types';

import * as OrdersActions from './orders.actions';
import * as OrdersSearchActions from './orders-search.actions';
import * as WorkspaceActions from '../../workspace/+state/workspace.actions';

import { createReducer, Action } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { ResultColumn, SearchResultEntry, searchStatus } from '../../shared/types/search.types';
import { defaultSearchParams, OrderSearchParameters, OrderSearchResult } from '../order-search.types';
import { FilterIdentifier, QuickFilterIdentifier } from '../../workspace/config/workspace.config.types';
import { SortingMethod } from '../order-filter-list/sorting/sorting.types';
import { OrderFilterEntry } from '../order-filter-list/order-filter.types';
import { isDepotAttribute } from '../../shared/+state/attributes/attributes.types';
import { defaultOrderSearchLimit } from '../order.constants';

export const ORDERS_FEATURE_KEY = 'orders';

export type SideBarFilterState = {
  [filterIdentifier in FilterIdentifier]: OrderFilterState<any>;
};

export interface OrderFilterState<TYPE = string> {
  base: TYPE[]; // The base list of filter entries
  available: TYPE[]; // The filter entries that are actually available in the current search
  actual: string[]; // the currently active filter entries
  sorting?: SortingMethod;
  extended: boolean;
}

export type OrderFilterStateSlot = 'base' | 'available';
export type OrderDeletionInteraction = 'none' | 'confirmModal' | 'inProgressModal';
export type OrderRestorationInteraction = 'none' | 'inProgressModal';

export interface OrdersState {
  ordersSlice: {
    orders: Orders;
    selected: string | null;
    callState: CallState;
    totalNumber: number;
    skeletonOrders: {
      show: boolean; // Should the skeletonOrderModal be shown
      orders: Orders; // the actual orders that are not found by the search
      baseOrders: string[]; // a list of order IDs that have been found for this workspace
    };
  };

  availableOrderStates: OrderState[];

  sideBarFilters: SideBarFilterState;

  searchBasics: {
    resultColumns?: ResultColumn[];
    resultColumnsInitialized: boolean;
  };

  search: {
    orderSearchStatus: searchStatus;
    lastSearchParameter: OrderSearchParameters;
    orderSearchText: string; // actual search text
    orderSearchLimit: number; // max search results per search query
    searchInputModified: boolean;
    searchResults?: OrderSearchResult;
  };

  ui: {
    showOrderDetailFullScreen: boolean;
    orderDeletionInteraction: OrderDeletionInteraction;
    orderRestorationInteraction: OrderRestorationInteraction;
    showPendingDeletionOrders: boolean;
    specificOrderMode: boolean;
  };
}

export interface OrdersPartialState {
  readonly [ORDERS_FEATURE_KEY]: OrdersState;
}

export const initialState: OrdersState = {
  availableOrderStates: [],
  sideBarFilters: {
    // recentOrdersOnly: false,
    [FilterIdentifier.QUICK]: {
      base: [
        {
          name: 'Pending Deletion',
          name_nls_key: 'ORDER.PENDING_DELETION',
          identifier: QuickFilterIdentifier.PendingDeletion,
          toggleState: false
        }
        // {
        //   name: 'Recently Changed',
        //   identifier: QuickFilterIdentifier.RecentlyChanged,
        //   toggleState: false
        // }
      ],
      available: [],
      actual: [],
      sorting: {
        method: 'numDocs',
        direction: 'desc'
      },
      extended: false
    },
    [FilterIdentifier.PROJECTS]: {
      base: [],
      available: [],
      actual: [],
      sorting: {
        method: 'numDocs',
        direction: 'desc'
      },
      extended: false
    },
    [FilterIdentifier.STANDARDS]: {
      base: [],
      available: [],
      actual: [],
      sorting: {
        method: 'numDocs',
        direction: 'desc'
      },
      extended: false
    },
    [FilterIdentifier.STATES]: {
      base: [],
      available: [],
      actual: [],
      sorting: {
        method: 'numDocs',
        direction: 'desc'
      },
      extended: false
    }
  },

  ordersSlice: {
    orders: {},
    selected: null,
    callState: LoadingState.INIT,
    totalNumber: 0,
    skeletonOrders: {
      orders: {},
      baseOrders: [],
      show: false
    }
  },

  searchBasics: {
    resultColumnsInitialized: false
  },

  search: {
    searchInputModified: false,
    lastSearchParameter: defaultSearchParams,
    orderSearchStatus: 'done',
    orderSearchText: '',
    orderSearchLimit: defaultOrderSearchLimit
  },

  ui: {
    showOrderDetailFullScreen: false,
    orderDeletionInteraction: 'none',
    orderRestorationInteraction: 'none',
    showPendingDeletionOrders: false,
    specificOrderMode: false
  }
};

export const initialPendingState: PendingState = {
  load: false,
  create: false,
  delete: false,
  restore: false,
  deviceAssignments: false,
  displayAttributes: false,
  resources: false,
  orderState: false
};

export const rawOrder = (): Order => {
  return {
    lock: {
      locked: false
    },
    pendingDeletion: false,
    attributes: {},
    computedProps: {
      pending: initialPendingState,
      standards: [],
      testplanType: 'not_a_testplan',
      download: {
        condition: 'none'
      }
    },
    relations: {},
    resources: initialResources(),
    dynamicComponents: {
      availableDynamicComponents: [],
      customDynamicComponents: [],
      selectedDynamicComponent: 'project' // actually this will be set by url but the app feels more responsive if we directly switch and no longer show the previously selected component
    }
  };
};

const OrdersReducer = createReducer(
  initialState,

  // #region Search Handling
  immerOn(OrdersSearchActions.SetSearchTextByRouter, (state, { searchText }) => {
    state.search.orderSearchText = searchText;
    state.search.searchInputModified = isSearchInputModified(
      state.search.lastSearchParameter,
      state.search.orderSearchText
    );
  }),

  immerOn(OrdersSearchActions.SetSearchLimitByRouter, (state, { searchLimit }) => {
    state.search.orderSearchLimit = parseInt(searchLimit, 10);
  }),

  immerOn(OrdersSearchActions.TriggerSearchUI, (state) => {
    state.search.orderSearchStatus = 'active';
    state.ordersSlice.orders = {};
  }),
  immerOn(OrdersSearchActions.SetLastSearchParameters, (state, { params }) => {
    state.search.lastSearchParameter = params;
  }),
  immerOn(OrdersSearchActions.SendSearchRequest, (state) => {
    state.search.orderSearchStatus = 'active';
  }),
  immerOn(OrdersSearchActions.SearchResultReceived, (state, { result, offset, isNewSearch }) => {
    const hasPreviousResults = state.search.searchResults && offset > 0;

    if (hasPreviousResults) {
      const clonedResult: OrderSearchResult = JSON.parse(JSON.stringify(state.search.searchResults));
      clonedResult.entries = clonedResult.entries.concat(result.entries);
      clonedResult.numberOfEntries += result.numberOfEntries;
      clonedResult.numberOfEntriesLastSearch = result.numberOfEntriesLastSearch;
      state.search.searchResults = clonedResult;
    } else {
      state.search.searchResults = result;
      state.search.lastSearchParameter.offset = 0;
    }
    state.search.orderSearchStatus = 'done';
    if (isNewSearch) {
      state.search.searchInputModified = isSearchInputModified(
        state.search.lastSearchParameter,
        state.search.orderSearchText
      );
    }
  }),
  immerOn(OrdersSearchActions.SearchError, (state) => {
    state.search.orderSearchStatus = 'error';
  }),
  immerOn(OrdersActions.ShowOrderDetailFullscreen, (state, { fullscreen }) => {
    state.ui.showOrderDetailFullScreen = fullscreen;
  }),

  immerOn(OrdersActions.SetOrderDeletionInteraction, (state, { interaction }) => {
    state.ui.orderDeletionInteraction = interaction;
  }),
  immerOn(OrdersActions.SetOrderRestorationInteraction, (state, { interaction }) => {
    state.ui.orderRestorationInteraction = interaction;
  }),

  immerOn(OrdersSearchActions.TriggerSearchForSpecificOrder, (state) => {
    state.ui.specificOrderMode = true;
  }),

  immerOn(OrdersSearchActions.UnsetSpecificOrderMode, (state) => {
    state.ui.specificOrderMode = false;
  }),

  immerOn(OrdersSearchActions.ResetSearch, (state) => {
    state.ui.specificOrderMode = false;
  }),
  // #endregion Search Handling

  // #region SearchResult Columns
  immerOn(OrdersSearchActions.ResultColumnsInitialized, (state, { columns }) => {
    state.searchBasics.resultColumnsInitialized = true;
    state.searchBasics.resultColumns = columns;
  }),
  immerOn(OrdersSearchActions.AddResultColumn, (state, { attrib }) => {
    const field = isDepotAttribute(attrib) ? attrib.idName : attrib.id;
    const isNewColumn = state.searchBasics.resultColumns?.every((x) => x.field !== field) ?? true;
    if (isNewColumn) {
      const newColumn: ResultColumn = {
        attribute: attrib,
        field: field,
        contentLoaded: false
      };
      state.searchBasics.resultColumns = state.searchBasics.resultColumns?.concat(newColumn) ?? [newColumn];
    }
  }),
  immerOn(OrdersSearchActions.ColumnContentReceived, (state, { result, column }) => {
    if (state.search.searchResults) {
      const resultsToUpdate: OrderSearchResult = JSON.parse(JSON.stringify(state.search.searchResults));
      for (const newEntry of result.entries) {
        updateExistingResultEntry(resultsToUpdate.entries, newEntry);
      }
      state.searchBasics.resultColumns?.forEach((col) => {
        if (col.field === column.field) {
          col.contentLoaded = true;
        }
      });
      state.search.searchResults = resultsToUpdate;
    }
  }),

  immerOn(OrdersSearchActions.NewColumnError, (state, { error, column }) => {
    const errorColumnIndex = state.searchBasics.resultColumns?.findIndex((col) => col.field === column.field);
    if (errorColumnIndex && errorColumnIndex >= 0) {
      state.searchBasics.resultColumns!.splice(errorColumnIndex, 1);
    }
  }),
  immerOn(OrdersSearchActions.RemoveResultColumn, (state, { column }) => {
    state.searchBasics.resultColumns = state.searchBasics.resultColumns?.filter((col) => col.field !== column.field);
  }),
  // #endregion SearchResult Columns

  // #region Order Loading

  immerOn(OrdersActions.LoadingDone, (state) => {
    state.ordersSlice.callState = LoadingState.LOADED;
  }),
  immerOn(OrdersActions.OrderLoaded, (state, { order }) => {
    if (order.id) {
      state.ordersSlice.orders[order.id] = JSON.parse(JSON.stringify(order));
      state.ordersSlice.orders[order.id].computedProps.pending.load = false;
    }
  }),
  immerOn(OrdersActions.LoadOrderDisplayAttributes, (state, { order }) => {
    if (order.id) {
      state.ordersSlice.orders[order.id].computedProps.pending.displayAttributes = true;
    }
  }),
  immerOn(OrdersActions.LoadOrderDisplayAttributesDone, (state, { order }) => {
    if (order.id) {
      state.ordersSlice.orders[order.id].attributes = JSON.parse(JSON.stringify(order.attributes));
      state.ordersSlice.orders[order.id].computedProps.pending.displayAttributes = false;
    }
  }),
  immerOn(OrdersSearchActions.OrderListReceived, (state, { orders, append }) => {
    const clonedOrders = JSON.parse(JSON.stringify(orders));

    // Overwrite some UI sensitive properties of OrderListReceived Orders with properties of respective Orders already in State to avoid flickering
    Object.values(state.ordersSlice.orders).forEach((orderInState) => {
      const respectiveClonedOrder: Order | undefined = clonedOrders[orderInState?.id ?? ''];
      if (respectiveClonedOrder) {
        respectiveClonedOrder.lock = orderInState.lock;
        respectiveClonedOrder.computedProps = orderInState.computedProps;
        respectiveClonedOrder.resources = orderInState.resources;
        respectiveClonedOrder.pendingDeletion = orderInState.pendingDeletion;
        respectiveClonedOrder.dynamicComponents = orderInState.dynamicComponents;
      }
    });

    if (append) {
      state.ordersSlice.orders = {
        ...state.ordersSlice.orders,
        ...clonedOrders
      };
    } else {
      state.ordersSlice.orders = clonedOrders;
    }
    state.ordersSlice.callState = LoadingState.LOADED;
  }),
  // #endregion Order Loading

  // #region Order Downloading
  immerOn(OrdersActions.TriggerOrderDownload, (state, { orderId, readonly }) => {
    const order = state.ordersSlice.orders[orderId] ?? state.ordersSlice.skeletonOrders.orders[orderId];
    order.computedProps.download.condition = 'prepare';
    order.computedProps.download.readonly = readonly;
    order.computedProps.download.progress = {
      loaded: 0,
      total: 0,
      percentage: 0
    };
  }),
  immerOn(OrdersActions.PerformOrderDownload, (state, { orderId }) => {
    const order = state.ordersSlice.orders[orderId] ?? state.ordersSlice.skeletonOrders.orders[orderId];
    order.computedProps.download.condition = 'ready';
  }),
  immerOn(OrdersActions.ReportOrderDownloadProgress, (state, { orderId, loaded, total }) => {
    const order = state.ordersSlice.orders[orderId] ?? state.ordersSlice.skeletonOrders.orders[orderId];
    if (order.computedProps.download.condition !== 'done' && order.computedProps.download.condition !== 'error') {
      order.computedProps.download.condition = 'active';
    }
    order.computedProps.download.progress = {
      loaded: loaded,
      total: total,
      percentage: loaded / total
    };
  }),
  immerOn(OrdersActions.OrderDownloadSuccess, (state, { orderId }) => {
    const order = state.ordersSlice.orders[orderId] ?? state.ordersSlice.skeletonOrders.orders[orderId];
    order.computedProps.download.condition = 'done';
  }),
  immerOn(OrdersActions.OrderDownloadError, (state, { orderId, reason }) => {
    const order = state.ordersSlice.orders[orderId] ?? state.ordersSlice.skeletonOrders.orders[orderId];
    order.computedProps.download.condition = 'error';
    order.computedProps.download.error = reason;
  }),
  immerOn(OrdersActions.CancelOrderDownload, (state, { orderId }) => {
    const order = state.ordersSlice.orders[orderId] ?? state.ordersSlice.skeletonOrders.orders[orderId];
    order.computedProps.download.condition = 'cancel';
  }),
  immerOn(OrdersActions.ResetOrderDownloadState, (state, { orderId }) => {
    const order = state.ordersSlice.orders[orderId] ?? state.ordersSlice.skeletonOrders.orders[orderId];
    order.computedProps.download.condition = 'none';
  }),
  immerOn(OrdersActions.SetOrderLockState, (state, { orderId, locked }) => {
    if (locked === false) {
      const order = state.ordersSlice.orders[orderId];
      order.computedProps.download.condition = 'unlocking';
    }
  }),

  // #endregion Order Downloading

  // #region Order Checkout

  immerOn(OrdersActions.CheckoutTokensReceived, (state, { tokens }) => {
    // Unlock / Lockstate for all orders
    Object.keys(state.ordersSlice.orders).forEach((orderId) => {
      const tokensForOrder = tokens.filter((token) => token.orderId === orderId);
      // state.ordersSlice.orders[orderId].computedProps.checkoutHistory = tokensForOrder;

      const orderLockedtoken = tokensForOrder.find((token) => token.state === 'given_out');
      if (orderLockedtoken) {
        state.ordersSlice.orders[orderId].lock = {
          locked: true,
          byUser: orderLockedtoken.user,
          since: orderLockedtoken.checkout_time
        };
      } else {
        state.ordersSlice.orders[orderId].lock = {
          locked: false
        };
      }
    });
  }),
  // #endregion Order Checkout

  // #region Order Checkout

  immerOn(OrdersActions.PendingDeletionsReceived, (state, { pendingDeletionOrderIDs }) => {
    Object.values(state.ordersSlice.orders).forEach((order) => {
      if (order.id) {
        order.pendingDeletion = pendingDeletionOrderIDs.includes(order.id);
      }
    });
  }),
  // #endregion Order Checkout

  // #region Device Assignment / Removal
  immerOn(OrdersActions.AssignDevice, (state, { deviceAssignment }) => {
    const order = state.ordersSlice.orders[deviceAssignment.orderId];
    order.resources.deviceAssignments.content.push(deviceAssignment);
    order.computedProps.pending.deviceAssignments = true;
    state.ordersSlice.orders[deviceAssignment.orderId] = order;
  }),
  immerOn(OrdersActions.DeviceAssigned, (state, { deviceAssignment }) => {
    const order = state.ordersSlice.orders[deviceAssignment.orderId];
    const content = order.resources.deviceAssignments.content;
    const currentAssignment = content.filter((assignment) => {
      return assignment.initialId === deviceAssignment.initialId;
    });
    if (currentAssignment.length === 1) {
      currentAssignment[0].id = deviceAssignment.id;
    } else {
      throw new Error('Expected exactly one assignment entry with that ID, found ' + currentAssignment.length);
    }
    order.computedProps.pending.deviceAssignments = false;
  }),
  immerOn(OrdersActions.AssignDeviceUndo, (state, { deviceAssignment }) => {
    const order = state.ordersSlice.orders[deviceAssignment.orderId];
    order.computedProps.pending.deviceAssignments = false;
    order.resources.deviceAssignments = order.resources.deviceAssignments.content.filter(
      (assignment) => assignment.initialId !== deviceAssignment.initialId
    );
  }),
  immerOn(OrdersActions.RemoveDeviceAssignment, (state, { deviceAssignment }) => {
    const order = state.ordersSlice.orders[deviceAssignment.orderId];
    order.computedProps.pending.deviceAssignments = true;
    order.resources.deviceAssignments.content = order.resources.deviceAssignments.content.filter(
      (assignment) => assignment.id !== deviceAssignment.id
    );
  }),
  immerOn(OrdersActions.DeviceAssignmentRemoved, (state, { deviceAssignment }) => {
    const order = state.ordersSlice.orders[deviceAssignment.orderId];
    order.computedProps.pending.deviceAssignments = false;
  }),
  immerOn(OrdersActions.RemoveDeviceAssignmentUndo, (state, { deviceAssignment }) => {
    const order = state.ordersSlice.orders[deviceAssignment.orderId];
    order.computedProps.pending.deviceAssignments = false;
    order.resources.deviceAssignments.content.push(deviceAssignment);
  }),
  // #endregion Device Assignment / Removal

  // #region Order Creation / Deletion
  immerOn(OrdersActions.CreateOrder, (state, { initialOrder }) => {
    if (initialOrder.initialId) {
      state.ordersSlice.orders[initialOrder.initialId] = {
        ...initialOrder,
        name: initialOrder.name,
        initialId: initialOrder.initialId,
        attributes: initialOrder.attributes,
        computedProps: {
          ...initialOrder.computedProps,
          pending: {
            ...initialOrder.computedProps.pending,
            create: true
          }
        }
      };
      state.ordersSlice.selected = initialOrder.initialId;
    }
  }),
  immerOn(OrdersActions.OrderCreated, (state, { orderId, orderStateId, initialOrder }) => {
    if (initialOrder.initialId) {
      const initialOrderInState = state.ordersSlice.orders[initialOrder.initialId];
      const order: Order = {
        ...initialOrderInState,
        id: orderId,
        computedProps: {
          ...initialOrder.computedProps,
          pending: {
            ...initialOrder.computedProps.pending,
            create: false
          }
        },
        relations: {
          ...initialOrderInState.relations,
          orderStateId: orderStateId
        }
      };

      delete state.ordersSlice.orders[initialOrder.initialId];
      state.ordersSlice.orders[orderId] = order;
    }
  }),
  immerOn(OrdersActions.CreateOrderUndo, (state) => {
    const creatingId = Object.values(state.ordersSlice.orders).find(
      (order) => order.computedProps.pending.create
    )?.initialId;
    if (creatingId && state.ordersSlice.orders[creatingId]) {
      delete state.ordersSlice.orders[creatingId];
    }
    state.ordersSlice.selected = null;
  }),
  immerOn(OrdersActions.DeleteOrder, (state, { order }) => {
    if (order.id) {
      state.ordersSlice.orders[order.id].computedProps.pending.delete = true;
    }
  }),
  immerOn(OrdersActions.OrderDeleted, (state, { order }) => {
    if (order.id) {
      state.ordersSlice.orders[order.id].computedProps.pending.delete = false;
      if (state.ordersSlice.selected === order.id) {
        state.ordersSlice.selected = null;
      }
      state.ordersSlice.totalNumber -= 1;
    }
  }),
  immerOn(OrdersActions.DeleteOrderUndo, (state, { order }) => {
    if (order.id) {
      state.ordersSlice.orders[order.id].computedProps.pending.delete = false;
    }
  }),
  immerOn(OrdersActions.RestoreDeletedOrder, (state, { order }) => {
    if (order.id) {
      state.ordersSlice.orders[order.id].computedProps.pending.restore = true;
    }
  }),
  immerOn(OrdersActions.DeletedOrderRestored, (state, { order }) => {
    if (order.id) {
      state.ordersSlice.orders[order.id].computedProps.pending.restore = false;
    }
  }),
  immerOn(OrdersActions.RestoreDeletedOrderUndo, (state, { order }) => {
    if (order.id) {
      state.ordersSlice.orders[order.id].computedProps.pending.restore = false;
    }
  }),
  // #endregion Order Creation / Deletion

  // #region router handling

  immerOn(OrdersActions.SetSelectedOrderByRouter, (state, { orderId }) => {
    state.ordersSlice.selected = orderId;
  }),

  immerOn(OrdersSearchActions.ProjectsReceived, (state, { projects, slot }) => {
    state.sideBarFilters[FilterIdentifier.PROJECTS][slot] = projects;
  }),
  immerOn(OrdersSearchActions.SetProjectsFilterByRouter, (state, { projects }) => {
    state.sideBarFilters[FilterIdentifier.PROJECTS].actual = projects;
  }),

  immerOn(OrdersSearchActions.AvailableStatesReceived, (state, { states }) => {
    state.sideBarFilters[FilterIdentifier.STATES].available = states;
  }),
  immerOn(OrdersSearchActions.SetStateFilterByRouter, (state, { states }) => {
    state.sideBarFilters[FilterIdentifier.STATES].actual = states;
  }),

  immerOn(OrdersSearchActions.SetQuickFiltersByRouter, (state, { filters }) => {
    const baseEntries = state.sideBarFilters[FilterIdentifier.QUICK].base as OrderFilterEntry[];

    filters.forEach((filter) => {
      const entry = baseEntries.find((entry) => {
        return entry.identifier === filter.identifier;
      });

      if (entry) {
        entry.toggleState = filter.value;

        // special handling of pending deletion quickfilter, should also write to base order state
        if (entry.identifier === QuickFilterIdentifier.PendingDeletion) {
          state.ui.showPendingDeletionOrders = filter.value;
        }
      }
    });
  }),

  immerOn(OrdersSearchActions.StandardsReceived, (state, { standards, slot }) => {
    state.sideBarFilters[FilterIdentifier.STANDARDS][slot] = standards;
  }),
  immerOn(OrdersSearchActions.SetStandardsFilterByRouter, (state, { standards }) => {
    state.sideBarFilters[FilterIdentifier.STANDARDS].actual = standards;
  }),

  immerOn(OrdersSearchActions.SetSortingMethodForFilter, (state, { filterIdentifier, method }) => {
    state.sideBarFilters[filterIdentifier].sorting = method;
  }),
  immerOn(OrdersSearchActions.SetExtensionStateForFilter, (state, { filterIdentifier, extended }) => {
    state.sideBarFilters[filterIdentifier].extended = extended;
  }),
  // #endregion router handling

  // #region OrderState
  immerOn(OrdersActions.LoadOrderStatesDone, (state, { orderStates }) => {
    state.availableOrderStates = orderStates;
  }),
  immerOn(OrdersActions.WorkspaceOrderStatesReceived, (state, { workspaceId, workspaceOrderStates }) => {
    state.sideBarFilters[FilterIdentifier.STATES].base = workspaceOrderStates;
  }),
  immerOn(OrdersActions.ChangeOrderState, (state, { order }) => {
    if (order.id) {
      state.ordersSlice.orders[order.id].computedProps.pending.orderState = true;
    }
  }),
  immerOn(OrdersActions.OrderStateIDForOrderReceived, (state, { orderId, orderStateId }) => {
    const order = state.ordersSlice.orders[orderId];
    if (order) {
      order.relations.orderStateId = orderStateId;
    }
  }),
  immerOn(OrdersActions.ChangeOrderStateDone, (state, { order, orderState }) => {
    if (order.id) {
      state.ordersSlice.orders[order.id].computedProps.pending.orderState = false;
      state.ordersSlice.orders[order.id].relations.orderStateId = orderState.id;
    }
  }),
  // #endregion OrderState
  // #region Resources
  immerOn(OrdersActions.PrepGatherResource, (state, { orderId, identifier }) => {
    const order = state.ordersSlice.orders[orderId];
    if (order) {
      order.computedProps.pending.resources = true;
      const resource: OrderResource = order.resources[identifier];
      if (resource) {
        resource.loading = true;
      }
    }
  }),
  immerOn(OrdersActions.GatherResource, (state, { orderId, identifier }) => {
    const order = state.ordersSlice.orders[orderId];
    if (order) {
      const resource: OrderResource = state.ordersSlice.orders[orderId].resources[identifier];
      if (resource) {
        resource.loading = true;
      }
    }
  }),
  immerOn(OrdersActions.ResourceGathered, (state, { orderId, identifier, resource: returnedResource }) => {
    const order = state.ordersSlice.orders[orderId];
    if (order) {
      const resource: OrderResource = state.ordersSlice.orders[orderId].resources[identifier];
      if (resource) {
        resource.loading = false;
        resource.content = returnedResource;
      }

      let orderStillHasLoadingResources = false;
      for (const res of Object.values(order.resources)) {
        if ((res as OrderResource).loading) {
          orderStillHasLoadingResources = true;
          break;
        }
      }
      order.computedProps.pending.resources = orderStillHasLoadingResources;
    }
  }),
  // #endregion Resources

  immerOn(OrdersActions.TestplanTypeDetermined, (state, { orderId, testplanType }) => {
    if (state.ordersSlice.orders[orderId]) {
      state.ordersSlice.orders[orderId].computedProps.testplanType = testplanType;
    }
  }),
  // #region DynamicComponents
  immerOn(OrdersActions.SetActiveDynamicComponentForSelectedOrderByRouter, (state, { dynamicComponentIdentifier }) => {
    if (state.ordersSlice.selected && state.ordersSlice.orders[state.ordersSlice.selected]) {
      state.ordersSlice.orders[state.ordersSlice.selected].dynamicComponents.selectedDynamicComponent =
        dynamicComponentIdentifier;
    }
  }),
  immerOn(OrdersActions.SetActiveDynamicComponentForSelectedOrder, (state, { dynamicComponentIdentifier }) => {
    if (state.ordersSlice.selected && state.ordersSlice.orders[state.ordersSlice.selected]) {
      state.ordersSlice.orders[state.ordersSlice.selected].dynamicComponents.selectedDynamicComponent =
        dynamicComponentIdentifier;
    }
  }),
  immerOn(OrdersActions.AddCustomDynamicComponentForOrder, (state, { component, orderId }) => {
    const order = state.ordersSlice.orders[orderId];
    if (order) {
      order.dynamicComponents.customDynamicComponents.push(component);
    }
  }),
  immerOn(OrdersActions.RemoveCustomDynamicComponentsForOrder, (state, { orderId }) => {
    const order = state.ordersSlice.orders[orderId];
    if (order) {
      order.dynamicComponents.customDynamicComponents = [];
    }
  }),
  immerOn(
    OrdersActions.OrderDetailDeterminedAvailableDynamicComponentsForOrder,
    (state, { availableDynamicComponents, orderId }) => {
      const order = state.ordersSlice.orders[orderId];
      if (order) {
        order.dynamicComponents.availableDynamicComponents = availableDynamicComponents;
      }
    }
  ),
  // #endregion DynamicComponents

  immerOn(WorkspaceActions.SelectedWorkspaceChanged, (state) => {
    state.ordersSlice.callState = LoadingState.INIT;
    state.ordersSlice.selected = null;
    state.ordersSlice.orders = {};
    // state.availableProjects = [];
  })
);

export function ordersReducer(state: OrdersState | undefined, action: Action) {
  return OrdersReducer(state, action);
}

function updateExistingResultEntry(existingResultEntries: SearchResultEntry[], newResultEntry: SearchResultEntry) {
  const existingEntry = existingResultEntries.find(
    (existing) => existing.measurementId === newResultEntry.measurementId
  );
  if (existingEntry) {
    for (const property in newResultEntry) {
      if (
        Object.prototype.hasOwnProperty.call(newResultEntry, property) &&
        property !== 'measurementBrowseUrl' &&
        property !== 'measurementId'
      ) {
        existingEntry[property] = newResultEntry[property];
      }
    }
  }
}

// ToDo: only basic => will be usefull with more detailed search options
const isSearchInputModified = (lastSearchParameter: OrderSearchParameters, searchText: string): boolean => {
  const isSame = lastSearchParameter.text === searchText;

  return !isSame;
};
