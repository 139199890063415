import {
  DataType,
  DatasetThreeDimensionalMetaData_PsychoParams_PsychoType,
  Quantity
} from '../prototypes/DatasetMessages';

type AmplitudeType = 'peak-to-peak' | 'rms' | 'peak' | 'quDefault';
const M_SQRT1_2 = Math.sqrt(2) / 2;

const yTypeFact = (yType: AmplitudeType): number => {
  let fact = 1.0;

  switch (yType) {
    case 'rms':
      fact = M_SQRT1_2;
      break;
    case 'peak':
      fact = 1;
      break;
    case 'peak-to-peak':
      fact = 2;
      break;
  }

  return fact;
};

const isQuadraticType = (
  dataType: DataType,
  psychoType?: DatasetThreeDimensionalMetaData_PsychoParams_PsychoType
): boolean => {
  let isQuadratic = false;
  switch (dataType) {
    case DataType.Type_CPSSpectrum:
    case DataType.Type_MultipleCrossOrder:
      isQuadratic = true;
      break;
    case DataType.Type_Psycho:
      isQuadratic =
        false || // aber nicht fuer Psycho_Tonality_Din!
        psychoType == DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Tone2Noise_Ansi ||
        psychoType == DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Prominence_Ansi;
      break;
    default:
      break;
  }
  return isQuadratic;
};

const isPowerType = (
  quantityY: Quantity,
  dataType: DataType,
  psychoType?: DatasetThreeDimensionalMetaData_PsychoParams_PsychoType
): boolean => {
  return quantityY.isPowerType || isQuadraticType(dataType, psychoType);
};

// NOTE:  returns if the appearance square flag should really lead to square of data
const effectiveAppearSquared = (quantityY: Quantity, dataType: DataType, appearSquared: boolean): boolean => {
  return isPowerType(quantityY, dataType) ? false : appearSquared;
};

const isYamplitudeTypeUsed = (dataType: DataType): boolean => {
  // s. PAKxy function dataEditDataTypeDarstellungUsed()
  // if (plot_data_type == DT_DREHZAHL_FLANKEN || plot_data_type == DT_PTP_DATA || plot_data_type == DT_TIME_SERIES) {
  //   y_amp_used = 0;
  //   quad_flag_used = 0;
  //   density_flag_used = 0;
  //   cumulate_spec_flag_used = 0;
  //   integ_flag_used = 0;
  // }
  // else {

  //   /* y-Amplitude immer , falls kein Zeitsignal , Langsame Gr��e , Psycho ! */
  //   if (!DT_is_faktor_datentyp(plot_data_type) &&
  //     !DT_is_ZEIT_SIGNAL_type(plot_data_type) &&
  //     !DT_is_faktor_datentyp(plot_data_type) &&
  //     !DT_is_CEPSTRUM_type(plot_data_type) &&
  //     (!DT_is_SLOW_type(plot_data_type) ||
  //       DT_is_STAT_AUSW_type(plot_data_type) && !DT_is_KW_MeasDataType(mess_data_type) && !DT_is_ZEIT_SIGNAL_type(mess_data_type)) &&
  //     !DT_is_PSYCHO_type(plot_data_type))
  //   {
  //     y_amp_used = 1;
  //   }
  //   if (mess_data_type != DT_DETEKTOR && plot_data_type == DT_DETEKTOR)
  //     y_amp_used = 0;

  let y_amp_used = false;

  switch (dataType) {
    case DataType.Type_TachoEdges:
    case DataType.Type_PTPData:
    case DataType.Type_TimeSeries:
    case DataType.Type_Thruput:
    case DataType.Type_CompressedThruput:
    case DataType.Type_SlowThruput:
    case DataType.Type_ThruputCA:
    case DataType.Type_Cepstrum:
    case DataType.Type_SlowQuantity:
    case DataType.Type_SlowQuantityRef:
    case DataType.Type_TimeBlock:
    case DataType.Type_VarTimeBlock:
    case DataType.Type_Window:
    case DataType.Type_MultiplePhaseRefOrder:
    case DataType.Type_CrossCorrelation:
    case DataType.Type_Coherence:
    case DataType.Type_PartialCoherence:
    case DataType.Type_Psycho:
    case DataType.Type_ImpulseResponse:
    case DataType.Type_HVQBase:
    case DataType.Type_HSQBase:
    case DataType.Type_FRFSpectrum:
    case DataType.Type_MultipleCoherence:
    case DataType.Type_AutoCorrelation:
      y_amp_used = false;
      break;
    case DataType.Type_Detector:
      y_amp_used = false;
      break;
    case DataType.Type_Unknown:
      //if ( has_MaxAmpFreqTypeInfo() || // DT_MAX_AMP_FREQUENZ ds->info<MaxAmpFreqTypeInfo>()
      //  has_SubTypeOrderPeriod() ) // DT_ORDER_PERIOD  ds->subType() == BBMDataSet_Unknown::SubTypeOrderPeriod
      //{
      //  y_amp_used = true;
      //} else {
      //}
      y_amp_used = false;
      break;

    case DataType.Type_MultipleCrossOrder:
    case DataType.Type_MultipleSingleOrder:
      //   Shape_Cut,Shape_OpResult	      DF_Phase	   	      Ord 	  Kalman Spectr
      //  Shape_Cut,Shape_OpResult	    DF_Complex	DF_Real DF_Imag DF_Mag     	        Ord 	  Kalman Spectr
      //  Shape_Cut	    DF_Complex	DF_Real DF_Imag DF_Mag 	      Ord 	  Kalman Spectr
      //   Shape_Cut	      DF_Phase	   	      Ord 	  Kalman Spectr
      y_amp_used = true;
      break;
    case DataType.Type_Trajectory:
      //const BBMDataSet_Trajectory* trj = static_cast<const BBMDataSet_Trajectory*>(dtset);
      //BBMDS::BBMDataType bdt = trj->baseDataType();
      // if ( trj->isNyquist()) {
      //switch ( getTrajectoryNyquistBaseDataTypeFct() )
      //{
      //case BBMDS::DT_FFTSpectrum:
      //case BBMDS::DT_CPSSpectrum:
      //case BBMDS::DT_PRSSpectrum:
      //  y_amp_used = true; // return   DT_FFT_NYQUIST; DT_ORD_FFT_NYQUIST;
      //  //      return DT_CPS_NYQUIST; DT_ORD_CPS_NYQUIST;
      //  //      return DT_PRS_NYQUIST; DT_ORD_PRS_NYQUIST;
      //  break;
      //}
      //break;
      y_amp_used = true;
      break;
    default:
      y_amp_used = true;
      break;
  }

  return y_amp_used;
};

/* from BBMDataSetAtomic::dataYIsoAdjustAppearance()
  Correcting for square, ytype (yAmplitudeType) and density

  Correcting for square:

    Normal datatypes:
    data linear - appearance linear - do nothing
    data linear - appearance square - square data
    data square - appearance linear - root
    data square - appearance square - do nothing

    Quadratic types or quantities (isPowerType):
    data linear - appearance linear - do nothing (explicitly not allowed)
    data linear - appearance square - do nothing (normal appearance is square)
    data square - appearance linear - root (explicitly not allowed)
    data square - appearance square - root (normal appearance is square, extra square must be removed)

    Meaning: quadratic types will always be returned the same, independent of the appearance square flag
*/

export const dataYIsoAdjustAppearanceSingleValue = (
  dataType: DataType,
  isSquared: boolean,
  quantityY: Quantity,
  data: number
): { isoData: number; effectiveIsSquared: boolean } => {
  const ret = dataYIsoAdjustAppearance(dataType, isSquared, quantityY, [data]);
  return { isoData: ret.isoData[0], effectiveIsSquared: ret.effectiveIsSquared };
};

export const dataYIsoAdjustAppearance = (
  dataType: DataType,
  isSquared: boolean,
  quantityY: Quantity,
  data: number[]
): { isoData: number[]; effectiveIsSquared: boolean } => {
  const appearSquared: boolean = false; // default: false
  // NOTE: correct for square
  const isEffectiveAppearSquared = effectiveAppearSquared(quantityY, dataType, appearSquared);
  let dataIsSquared = isSquared;
  if (isSquared && !isEffectiveAppearSquared) {
    data = data.map((d) => Math.sqrt(d));
    dataIsSquared = false;
  } else if (!isSquared && isEffectiveAppearSquared) {
    data = data.map((d) => Math.pow(d, 2));
    dataIsSquared = true;
  }

  // NOTE: correct for ytype
  if (isYamplitudeTypeUsed(dataType)) {
    const appearYType: AmplitudeType = <AmplitudeType>quantityY.yAmplitude;
    let fact = yTypeFact(appearYType);
    if (appearSquared || isPowerType(quantityY, dataType)) {
      fact *= fact;
    }
    if (fact != 1) {
      data = data.map((d) => d * fact);
    }
  }

  // NOTE: correct for density if (AppearDensity && shape() == BBMDS::Shape_Normal) {

  // NOTE: correct for restricted phase range
  return {
    isoData: data,
    effectiveIsSquared: dataIsSquared
  };
};
