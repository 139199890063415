import { ProcessingService } from './../../processing/processing.service';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { map, delay, catchError, withLatestFrom, mergeMap, switchMap } from 'rxjs/operators';

import { AvailableFormulasLoaded } from './processing.actions';
import * as ProcessingAction from './processing.actions';
import * as StreamingAction from '../../streaming/+state/streaming.actions';

import { createAppError, createAppErrorPayload } from '../../app.factories';
import { MeasurementsFacade } from '../../measurements/+state/measurements.facade';
import { MeasurementsActionTypes } from '../../measurements/+state/measurements.actions';
import { ProcessingFacade } from './processing.facade';
import { Store } from '@ngrx/store';
import { ProcessingStatus } from '../processing.types';
import { NOOP } from '../../+state/app.actions';

// key used to store user's attributeDisplayType in localStorage
export const USER_SAVED_ATTRIBUTE_DISPLAY_TYPE = 'user-attribute-display-type';

@Injectable()
export class ProcessingEffects {
  unknownError = 'Unknown Error';
  constructor(
    private actions$: Actions,
    private measurementsFacade: MeasurementsFacade,
    private processingService: ProcessingService,
    private processingFacade: ProcessingFacade,
    private store: Store
  ) {}

  loadFormulas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessingAction.LoadAvailableFormulas),
      switchMap((_) => from(this.processingService.fetchFormulas())),
      map((formulas) => AvailableFormulasLoaded({ formulas: formulas })),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  deriveFilteredProcessingDatasets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessingAction.SelectFormula),
      withLatestFrom(this.measurementsFacade.selectedMeasurement$),
      mergeMap(([selectedFormulaAction, selectedMeasurement]) => {
        const selectedFormula = selectedFormulaAction.formula;
        const datasets = selectedMeasurement?.resources?.['datasetDescription']?.datasets;
        const measurementId = selectedMeasurement?.resources?.overview?.measurementId ?? '';
        if (datasets && datasets[0] && selectedFormula) {
          const depotId = datasets[0]?.depotId;
          return from(this.processingService.filterApplicableDatasets(depotId, measurementId, selectedFormula));
        } else {
          return of([]);
        }
      }),
      withLatestFrom(this.measurementsFacade.selectedMeasurement$),
      map(([inputStreamsApplicableDatasets, selectedMeasurement]) => {
        const datasets = selectedMeasurement?.resources?.['datasetDescription']?.datasets;
        const filteredInputDatasets = Object.entries(inputStreamsApplicableDatasets).map((item) => {
          const filteredDatasets = datasets?.filter((dataset) => item[1].datasetDescription.includes(dataset.id)) ?? [];
          return {
            inputStreamId: item[0],
            source: item[1].source,
            datasetDescription: filteredDatasets
          };
        });
        return ProcessingAction.SetFilteredProcessingDatasets({ filteredDatasets: filteredInputDatasets });
      }),
      catchError((error, caught) => {
        this.store.dispatch(ProcessingAction.FilteringProcessingDatasetsFailed({}));
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  resetChosenDatasetsIfSelectedMeasurementDiffersOnSelectFormula$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessingAction.SelectFormula),
      map((selectedFormulaAction) => selectedFormulaAction.formula),
      map((formula) => formula?.sources),
      map((sources) => sources?.filter((source) => source.value !== undefined)),
      withLatestFrom(this.measurementsFacade.selectedMeasurement$),
      map(([sources, selectedMeasurement]) => {
        const datasets = selectedMeasurement?.resources?.['datasetDescription']?.datasets;
        if (!datasets) {
          return sources;
        } else {
          const datasetIds = datasets.map((ds) => ds.id);
          return sources?.filter((source) => !datasetIds.includes(source.value!.id));
        }
      }),
      map((sourcesToReset) =>
        sourcesToReset?.map((source) => {
          return { sourceName: source.source_name, dataset: undefined };
        })
      ),
      map((sourcesToReset) => {
        if (!sourcesToReset || sourcesToReset.length === 0) {
          return ProcessingAction.ChangeSelectedFormulaInputSources({ sources: [] }); // createEffect expects an action to be returned
        } else {
          return ProcessingAction.ChangeSelectedFormulaInputSources({ sources: sourcesToReset });
        }
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  startProcessing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessingAction.StartProcessing),
      map((params) => {
        return { sources: params.sources, formula: params.formula };
      }),
      switchMap((processedParams) => {
        return this.processingService.runProcessingStreaming(processedParams.sources, processedParams.formula);
      }),
      withLatestFrom(this.processingFacade.useLateProcessingToken$, this.processingFacade.pollingState$),
      map(([processingUrl, useLateProcessingToken, pollingState]) => {
        return ProcessingAction.PollStatus({
          processingUrl: processingUrl.toString(),
          doRequestToken: true
        });
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        const err = createAppErrorPayload(error);
        const status: ProcessingStatus = {
          processingJobId: '0',
          status: 'error',
          errorMsg: err.message
        };
        this.store.dispatch(ProcessingAction.ProcessingDoneWithError({ status: status }));
        return caught;
      })
    )
  );

  cancelProcessing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessingAction.CancelProcessing),
      switchMap((action) => this.processingService.cancelProcessing(action.processingStatusUrl)),
      map(() => ProcessingAction.PollNoOp()),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  //
  // Polling
  //

  pollProcessingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessingAction.PollStatus),
      delay(500),
      switchMap((action) =>
        this.processingService.pollProcessingStatusOnce(action.processingUrl, action.doRequestToken)
      ),
      map((response) =>
        ProcessingAction.ReceivedStatus({ status: response.status, processingUrl: response.processingUrl })
      ),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        const err = createAppErrorPayload(error);
        const status: ProcessingStatus = {
          processingJobId: '0',
          status: 'error',
          errorMsg: err.message
        };
        this.store.dispatch(ProcessingAction.ProcessingDoneWithError({ status: status }));
        return caught;
      })
    )
  );

  keepPolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessingAction.ReceivedStatus),
      withLatestFrom(this.processingFacade.currentProcessing$),
      map(([action, currentProcessing]) => {
        if (currentProcessing.status === 'canceled') {
          return ProcessingAction.PollNoOp(); // no error
        } else if (currentProcessing.url !== null) {
          if (action.status.status === 'done' || action.status.status === 'error') {
            return ProcessingAction.StopPollingStatus({
              processingUrl: currentProcessing.url,
              finalStatus: action.status
            });
          } else {
            return ProcessingAction.PollStatus({
              processingUrl: currentProcessing.url,
              doRequestToken:
                currentProcessing.lastPollStatus.tokenContainer?.token &&
                currentProcessing.lastPollStatus.tokenContainer?.token.length > 0
                  ? false
                  : true
            });
          }
        } else {
          return ProcessingAction.PollNoOp(); // TODO: Error Action
        }
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  stopPolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessingAction.StopPollingStatus),
      map((response) => {
        const hasError = response.finalStatus.status === 'error';
        if (hasError) {
          return ProcessingAction.ProcessingDoneWithError({ status: response.finalStatus });
        } else {
          return ProcessingAction.ProcessingDone({ status: response.finalStatus });
        }
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  processingDone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessingAction.ProcessingDone),
      map((response) => {
        if (response.status.tokenContainer) {
          return StreamingAction.AddTokenToStreamingPackage({ tokenContainer: response.status.tokenContainer });
        } else {
          console.error('No token container received from processing service');
          return NOOP();
        }
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  //
  // Measurement Selection related
  //

  resetFilteredProcessingDatasetsOnMeasurementChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementsActionTypes.ShowMeasurement),
      map((_) => ProcessingAction.SetFilteredProcessingDatasets({ filteredDatasets: [] })),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  resetSelectedFormulaOnMeasurementChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementsActionTypes.ShowMeasurement),
      map((_) => ProcessingAction.SelectFormula({ formula: null })),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );
}
