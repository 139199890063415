import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map } from 'rxjs/operators';
import { GroupsLoaded } from './groups.actions';

import { Store } from '@ngrx/store';
import * as GroupAction from './groups.actions';

import { fetch } from '@nrwl/angular';
import { from } from 'rxjs/internal/observable/from';
import { createAppError } from '../../../../../app.factories';
import { GroupsService } from '../../services/groups.service';
import { GroupsPartialState } from './groups.reducer';

@Injectable()
export class GroupsEffects {
  loadGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupAction.LoadGroups),
      fetch({
        run: (action: ReturnType<typeof GroupAction.LoadGroups>, state: GroupsPartialState) => {
          return from(this.groupsService.getGroups()).pipe(map((groups) => GroupsLoaded({ groups: groups })));
        },

        onError: (action: ReturnType<typeof GroupAction.LoadGroups>, error) => {
          return createAppError(error);
        }
      })
    )
  );

  constructor(private groupsService: GroupsService, private actions$: Actions, private store: Store) {}
}
