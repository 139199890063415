import { Inject, Injectable } from '@angular/core';
import { AngularHttpContext } from '@vas/angular-http-context';
import { ANGULAR_HTTP_CONTEXT, BASE_HOST } from '../app.tokens';
import { Client, CloudAppNames, CloudDepot, CloudDepotType } from '../app.types';
import { JsonApi } from '@muellerbbm-vas/grivet';
import { Observable, from, merge, EMPTY, of, forkJoin, combineLatest, lastValueFrom } from 'rxjs';
import { switchMap, map, filter, tap, mergeMap, withLatestFrom, shareReplay } from 'rxjs/operators';
import { WEBAPP_CLIENT_NAME, WEBAPP_CLIENT_UUID } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class CloudService {
  defaultService?: Observable<JsonApi.Resource>;
  appCache: {
    [index: string]: JsonApi.Resource | null;
  } = {};

  appNames$ = this.getDefaultService().pipe(
    map((serviceResource) =>
      Object.entries(serviceResource.relationships)
        .filter(([_, rel]) => rel.data && !rel.empty)
        .filter(([name, _]) => name !== 'service_apps')
        .map(([name, _]) => name as keyof typeof CloudAppNames)
        .filter((appNameKey) => appNameKey !== undefined)
    )
  );

  cachedApp$ = (requestedApp: string, force: boolean = false) =>
    from(this.getDefaultService()).pipe(
      filter(() => Object.prototype.hasOwnProperty.call(this.appCache, requestedApp) && force === false),
      map(() => this.appCache[requestedApp])
    );

  nonCachedApp$ = (requestedApp: string, force: boolean = false) =>
    from(this.getDefaultService(force)).pipe(
      filter(() => !Object.prototype.hasOwnProperty.call(this.appCache, requestedApp) || force === true),
      switchMap((res: JsonApi.Resource) => from(res.relatedResource[requestedApp])),
      switchMap((app: JsonApi.Resource | undefined) => (app ? from(app.relatedResource['start']) : of(null))),
      tap((res: JsonApi.Resource | null) => (this.appCache[requestedApp] = res))
    );

  constructor(
    @Inject(ANGULAR_HTTP_CONTEXT) private context: AngularHttpContext,
    @Inject(BASE_HOST) private baseHost: string
  ) {}

  getDefaultService(force: boolean = false): Observable<JsonApi.Resource> {
    if (!this.defaultService || force) {
      this.defaultService = from(JsonApi.Document.fromURL(new URL(`${this.baseHost}/api/`), this.context)).pipe(
        switchMap((apiEntryPoint) =>
          from(apiEntryPoint.resource?.relatedResource['default_service'] as Promise<JsonApi.Resource>)
        ),
        shareReplay(1)
      );

      // Reset after 5 minutes
      setTimeout(() => {
        this.reset();
      }, 1000 * 60 * 5);
    }
    return this.defaultService;
  }

  getAppStart(requestedApp: string, force: boolean = false): Promise<JsonApi.Resource | null> {
    return lastValueFrom(merge(this.cachedApp$(requestedApp, force), this.nonCachedApp$(requestedApp, force)));
  }

  getAppStartObs(requestedApp: string, force: boolean = false): Observable<JsonApi.Resource | null> {
    return merge(this.cachedApp$(requestedApp, force), this.nonCachedApp$(requestedApp, force));
  }

  getAvailableCloudServices(): Observable<CloudAppNames[]> {
    return this.appNames$.pipe(map((appnameKeys) => appnameKeys.map((key) => CloudAppNames[key])));
  }

  getAvailableAPIDocs(): Observable</* Record<string, string[]> */ any> {
    return this.getDefaultService().pipe(
      withLatestFrom(this.appNames$),
      switchMap(([serviceResource, appNames]) => {
        const result = Promise.all(
          appNames.map(async (appName) => {
            const appResource = await serviceResource.relatedResource[appName];
            const links = appResource.relationships['start']?.links;
            if (links) {
              return links['related'].url.href;
            } else {
              return false;
            }
          })
        );
        return result;
      }),
      map((serviceURLs) => serviceURLs.filter((servicURL) => servicURL !== false)),
      switchMap((serviceURLs) =>
        Promise.all(
          serviceURLs.map(async (serviceUrl) => {
            return await JsonApi.Document.fromURL(new URL(`${serviceUrl}`), this.context);
          })
        )
      ),
      withLatestFrom(this.appNames$),
      map(([services, appNames]) => {
        let apiDocsLinksPerApp = services.map((service) => {
          const link = service.rawData.data?.['meta']?.links?.['api_doc'].href ?? false;
          const relatedAppName = appNames.find((appName) => link.includes(`/${appName}/`));
          return { relatedAppName, link };
        });
        const filteredApiDocs = (apiDocsLinksPerApp = apiDocsLinksPerApp.filter(
          (apiDocsLink) => apiDocsLink.link !== false && apiDocsLink.relatedAppName !== undefined
        ));
        const sortedAndFilteredApiDocs = filteredApiDocs.sort((a, b) =>
          a.relatedAppName! > b.relatedAppName! ? 1 : -1
        );
        return sortedAndFilteredApiDocs;
      })
    );
  }

  getCloudSeverVersion(): Observable<string | undefined> {
    return this.getDefaultService().pipe(
      switchMap((serviceResource) => serviceResource.relatedResource['version'] ?? of(undefined)),
      map((res) => res?.attributes?.['version_label_nls'])
    );
  }

  getClient(): Observable<Client> {
    return this.getAppStartObs('client', true).pipe(
      switchMap((clientApp: JsonApi.Resource) => {
        return from(clientApp!.relatedResources['clients']);
      }),
      map((clients) => {
        const client: Client = {
          id: WEBAPP_CLIENT_UUID,
          name: WEBAPP_CLIENT_NAME,
          exists: false
        };

        const foundClient = clients.find((client) => client.id === WEBAPP_CLIENT_UUID);
        if (foundClient) {
          client.exists = true;
          client.name = foundClient.attributes?.name ?? 'Unnamed';
        }

        return client;
      })
    );
  }

  getDepots(): Observable<CloudDepot[]> {
    // Ok, this is basically unreadable, so we do comment this pretty heavily...
    // First of all, we ask the cloud for a list of all depots,
    const depotResourceObservable = this.getAppStartObs('depot').pipe(
      switchMap((resource: JsonApi.Resource) => from(resource.relatedResources['depots']))
    );
    // ...but this still misses the depot types,
    const depotTagResourceObservable = depotResourceObservable.pipe(
      // ...which we can only get as Promises (or Observables after calling 'from')
      map((resources: JsonApi.Resource[]) => resources.map((resource) => from(resource.relatedResource['typed_tag']))),
      // ...so take the last hit from all those Tag observables and join them.
      // ...which first results in an Observable<Observable<JsonApi.Resource[]>>, so let us use mergeMap to flatten the two nested Observables
      mergeMap((observables) => (observables.length > 0 ? forkJoin(observables) : of([])))
    );
    // Now that we got all resources somewhere
    return combineLatest([depotResourceObservable, depotTagResourceObservable]).pipe(
      map(([depots, tags]) => depots.map((depot, i) => this.createDepotInfo(depot, tags[i])))
    );
  }

  createDepotInfo(depotResource: JsonApi.Resource, tagResource: JsonApi.Resource): CloudDepot {
    const depotTypeConversion = new Map<string, CloudDepotType>([
      ['DepotPAK', 'PAKDepot'],
      ['DepotATFXML', 'ATFXDepot']
    ]);
    return {
      id: depotResource.id,
      name: depotResource.attributes?.['name'],
      label: depotResource.attributes?.['label_nls'],
      depotType: depotTypeConversion.get(tagResource.type) ?? 'PAKDepot',
      browseURL: depotResource.selfLink!.url.href
    };
  }

  /**
   *
   * We just try to get on the user_management/users page
   * and either return "true" or throw an exception.
   * The called should check their onError to catch missing permissions!
   *
   * @returns {Observable<boolean>}
   * @memberof CloudService
   */
  getHasDepotAdminPermissions(): Observable<boolean> {
    return from(this.getAppStart('depot')).pipe(
      switchMap((res: JsonApi.Resource) => {
        const relatedLinks = res.relationships['admin'].links?.['related'];
        return relatedLinks ? from(this.context.optionsDocument(relatedLinks.url)) : EMPTY;
      }),
      map(() => true)
    );
  }

  /**
   *
   * We just try to get on the user_management/users page
   * and either return "true" or throw an exception.
   * The called should check their onError to catch missing permissions!
   *
   * @returns {Observable<boolean>}
   * @memberof CloudService
   */
  getHasUserAdminPermissions(): Observable<boolean> {
    return from(this.getAppStart('user_management')).pipe(
      switchMap((res: JsonApi.Resource) => {
        const relatedLinks = res.relationships['users'].links?.['related'];
        return relatedLinks ? from(this.context.optionsDocument(relatedLinks.url)) : EMPTY;
      }),
      map(() => true)
    );
  }

  reset(): void {
    this.defaultService = undefined;
    this.appCache = {};
  }
}
