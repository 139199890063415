import { Component, OnInit } from '@angular/core';
import { filter, map, withLatestFrom } from 'rxjs';
import { MyLicenseInfo } from '../../../../admin/admin/license/types/license.types';
import { UserFacade } from '../../../../user/+state/user.facade';
import { TranslateService } from '@ngx-translate/core';
import { LicensesFacade } from '../../../../admin/admin/license/+state/licenses/licenses.facade';

@Component({
  selector: 'cloud-license-header',
  templateUrl: './license-header.component.html',
  styleUrls: ['./license-header.component.css']
})
export class LicenseHeaderComponent implements OnInit {
  myLicenses$ = this.userFacade.myLicenses$.pipe(
    filter((licenses) => !!licenses),
    withLatestFrom(this.licenseFacade.licenses$),
    map(([myLicenses, licenses]) => {
      const actualLicenses = myLicenses?.map((myLicense) => {
        const license: MyLicenseInfo = structuredClone(myLicense);
        const { remainingTime, timeClass } = this.deriveRemainingTimeAndTimeClass(myLicense.valid_until);
        license.remainingTime = remainingTime;
        license.timeClass = timeClass;
        return license;
      });
      actualLicenses?.sort((a, b) => a.userDisplayName?.localeCompare(b?.userDisplayName ?? '') ?? -1);
      return actualLicenses;
    })
  );

  constructor(
    private userFacade: UserFacade,
    public translate: TranslateService,
    private licenseFacade: LicensesFacade
  ) {}

  ngOnInit(): void {
    this.userFacade.loadMyLicenses();
  }

  private deriveRemainingTimeAndTimeClass(time: Date) {
    const now = new Date().getTime();
    const validUntil = new Date(time).getTime();
    const timeDifference = validUntil - now;
    const minutesLeft = Math.floor(timeDifference / (1000 * 60));
    const hoursLeft = Math.floor(timeDifference / (1000 * 60 * 60));
    const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    let remainingTime: string;
    let timeClass: string;

    if (minutesLeft < 60) {
      remainingTime = this.translate.instant('NAV.REMAINING_TIME.MINUTES', { count: minutesLeft });
      timeClass = 'license-label-minutes';
    } else if (hoursLeft < 24) {
      remainingTime = this.translate.instant('NAV.REMAINING_TIME.HOURS', { count: hoursLeft });
      timeClass = 'license-label-hours';
    } else {
      remainingTime = this.translate.instant('NAV.REMAINING_TIME.DAYS', { count: daysLeft });
      timeClass = 'license-label-days';
    }

    return { remainingTime, timeClass };
  }
}
