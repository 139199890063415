import naturalCompare from 'string-natural-compare';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import {
  AggregateTreeSortingMethod,
  AggregateTreeSortingMethodNames,
  AggregateType,
  TreeNode
} from '../../shared/types/aggregateTree.types';
import { SortDirection } from '../../shared/types/sorting.types';

export const availableTreeSortingMethodsForAggregateType: {
  [key in AggregateType]: AggregateTreeSortingMethod[];
} = {
  RequestedTermAggregation: [
    {
      method: 'numDocs',
      direction: 'desc'
    },
    {
      method: 'natural',
      direction: 'desc'
    }
  ],
  RequestedDateHistogramAggregation: [
    {
      method: 'date',
      direction: 'asc'
    },
    {
      method: 'numDocs',
      direction: 'desc'
    }
  ]
};

export const aggregateTreeSortingDefaultTranslationKeys: {
  [key in AggregateTreeSortingMethodNames | SortDirection]: string;
} = {
  asc: _('DEPOTSEARCH.HIERARCHYSORTMETHODS.ASC'),
  desc: _('DEPOTSEARCH.HIERARCHYSORTMETHODS.DESC'),
  natural: _('DEPOTSEARCH.HIERARCHYSORTMETHODS.NATURAL'),
  numDocs: _('DEPOTSEARCH.HIERARCHYSORTMETHODS.NUMDOCS'),
  date: _('DEPOTSEARCH.HIERARCHYSORTMETHODS.DATE')
};

type treeNodeCompareFunction = (a: TreeNode, b: TreeNode) => number;
export const recursiveAggregateTreeNodeSort = (
  nodes: TreeNode[] | undefined,
  compareFunction: treeNodeCompareFunction
): TreeNode[] => {
  if (nodes) {
    nodes.sort(compareFunction);
    nodes = nodes.map((node) => {
      if (node.children && node.children.length > 0) {
        recursiveAggregateTreeNodeSort(node.children, compareFunction);
      }
      return node;
    });
  }
  return nodes ?? [];
};

export const aggregateTreeSortFunctionLookup: {
  [name in AggregateTreeSortingMethodNames]: {
    [direction in SortDirection]: treeNodeCompareFunction;
  };
} = {
  natural: {
    asc: (a, b) => naturalCompare(b.label, a.label, { caseInsensitive: true }),
    desc: (a, b) => naturalCompare(a.label, b.label, { caseInsensitive: true })
  },
  numDocs: {
    asc: (a, b) => {
      let result: number = 0;
      if (a.docCount > b.docCount) {
        result = 1;
      } else if (a.docCount < b.docCount) {
        result = -1;
      }

      if (result === 0) {
        result = naturalCompare(b.label, a.label, { caseInsensitive: true });
      }
      return result;
    },
    desc: (a, b) => {
      let result: number = 0;
      if (a.docCount < b.docCount) {
        result = 1;
      } else if (a.docCount > b.docCount) {
        result = -1;
      }

      if (result === 0) {
        result = naturalCompare(a.label, b.label, { caseInsensitive: true });
      }
      return result;
    }
  },
  date: {
    // original values for dates are int timestamps, basic numeric compare
    asc: (a, b) => (a.originalValue > b.originalValue ? 1 : -1),
    desc: (a, b) => (a.originalValue > b.originalValue ? -1 : 1)
  }
};
