import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { DocsComponent } from './docs/docs.component';

const routes: Routes = [
  /*   {
    path: '',
    component: DocsComponent
  } */
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule {}
