import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

type SearchEntity = 'users' | 'groups' | 'roles' | 'licenses';

@Injectable({
  providedIn: 'root'
})
export class LicensingSearchService {
  public searchFilter$ = new BehaviorSubject<string>('');
}
