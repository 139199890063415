import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { CommonPartialState } from './common.reducer';
import { commonQuery } from './common.selectors';
import { SetEnvironment, SetUIDimensions, MouseDeviceDetected } from './common.actions';
import { UIDimensions } from '../common.types';

@Injectable()
export class CommonFacade {
  constructor(private store: Store<CommonPartialState>) {}

  appVersion$ = this.store.pipe(select(commonQuery.getAppVersion));
  production$ = this.store.pipe(select(commonQuery.getProduction));
  uiWidth$ = this.store.pipe(select(commonQuery.getUIWidth));
  uiHeight$ = this.store.pipe(select(commonQuery.getUIHeight));
  isTouchDevice$ = this.store.pipe(select(commonQuery.getIsTouchDevice));

  setEnvironment(appVersion: string, production: boolean) {
    this.store.dispatch(SetEnvironment({ appVersion, production }));
  }

  setUIDimensions(dimensions: UIDimensions) {
    this.store.dispatch(SetUIDimensions({ dims: dimensions }));
  }

  mouseDeviceDetected() {
    this.store.dispatch(MouseDeviceDetected());
  }
}
