import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { BehaviorSubject } from 'rxjs';

import { DepotSearchFacade } from '../+state/depot-search.facade';
import { AppFacade } from '../../+state/app.facade';
import { CloudDepot } from '../../app.types';
import { MeasurementDeleteOrUndeleteData, MeasurementToIgnore, MeasurementsToIgnore } from '../depot-search.types';
import { DepotSearchService } from '../depot-search.service';
import { SearchResultEntry } from '../../shared/types/search.types';

@Component({
  selector: 'cloud-measurement-delete-or-undelete',
  templateUrl: './measurement-delete-or-undelete.component.html',
  styleUrls: ['./measurement-delete-or-undelete.component.css']
})
export class MeasurementDeleteOrUndeleteComponent {
  measurementsToDeleteOrUndelete$: BehaviorSubject<MeasurementDeleteOrUndeleteData[]> = new BehaviorSubject<any>([]);
  groupedMeasurementsToDeleteOrUndelete$: BehaviorSubject<{
    [relativePath: string]: MeasurementDeleteOrUndeleteData[];
  }> = new BehaviorSubject<any>({});
  measurementsToIgnore$: BehaviorSubject<MeasurementsToIgnore> = new BehaviorSubject<any>({});
  isPrepareForDeletionOrUndeletion$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  visible = false;
  isDeletion = true;

  constructor(
    private depotSearchFacade: DepotSearchFacade,
    private appFacade: AppFacade,
    public translate: TranslateService,
    private depotSearchService: DepotSearchService
  ) {}

  public async showComponent(selectedMeasurements: SearchResultEntry[], cloudDepots: { [index: string]: CloudDepot }) {
    // Reset display data, then show component with the "Please wait" text
    this.measurementsToDeleteOrUndelete$.next([]);
    this.groupedMeasurementsToDeleteOrUndelete$.next({});
    this.measurementsToIgnore$.next({});
    this.isPrepareForDeletionOrUndeletion$.next(true);
    this.visible = true;
    await this.depotSearchService
      .prepareForDeletionOrUndeletion(selectedMeasurements, cloudDepots, this.isDeletion)
      .then(([measurementsToDeleteOrUndelete, measurementsToIgnore]) => {
        this.measurementsToDeleteOrUndelete$.next(measurementsToDeleteOrUndelete);
        const groupedMeas: { [relativePath: string]: MeasurementDeleteOrUndeleteData[] } = {};
        for (const meas of measurementsToDeleteOrUndelete) {
          const relativePath = meas.location.join(' / ');
          if (relativePath in groupedMeas) {
            groupedMeas[relativePath].push(meas);
          } else {
            groupedMeas[relativePath] = [meas];
          }
        }
        this.groupedMeasurementsToDeleteOrUndelete$.next(groupedMeas);
        this.measurementsToIgnore$.next(measurementsToIgnore);
        this.isPrepareForDeletionOrUndeletion$.next(false);
      })
      .catch((err: Error) => {
        this.appFacade.showError(err.message);
        this.visible = false;
      });
  }

  getGroupedMeasurementKeys(): string[] {
    return Object.keys(this.groupedMeasurementsToDeleteOrUndelete$.getValue());
  }

  getMeasurementsToDeleteOrUndelete(relativePath: string): MeasurementDeleteOrUndeleteData[] {
    return this.groupedMeasurementsToDeleteOrUndelete$.getValue()[relativePath];
  }

  numMeasurementsToDeleteOrUndelete(): number {
    return this.measurementsToDeleteOrUndelete$.getValue().length;
  }

  hasMeasurementsToIgnore(): boolean {
    return Object.keys(this.measurementsToIgnore$.getValue()).length > 0;
  }

  numFurtherMeasurements(measurement: MeasurementDeleteOrUndeleteData): number {
    return measurement.ids.length - 10;
  }

  filterIgnoreMeasurementsWithoutLink(ignoreData: MeasurementToIgnore[]): MeasurementToIgnore[] {
    return ignoreData.filter((meas) => !meas.href);
  }

  filterIgnoreMeasurementsLinks(ignoreData: MeasurementToIgnore[]): string[] {
    // We remove duplicate links, by checking whether the current index is the index of the first occurance
    const linksWithDuplicates = ignoreData.filter((meas) => !!meas.href).map((meas) => meas.href as string);
    return linksWithDuplicates.filter((href, i) => i === linksWithDuplicates.indexOf(href));
  }

  filterIgnoreMeasurementsWithSpecificLink(ignoreData: MeasurementToIgnore[], href: string): MeasurementToIgnore[] {
    return ignoreData.filter((meas) => meas.href === href);
  }

  getDescriptionForIgnoreMeasurementsWithSpecificLink(ignoreData: MeasurementToIgnore[], href: string): string {
    return ignoreData.find((meas) => meas.href === href)?.description ?? '';
  }

  disableConfirmButton(): boolean {
    return (
      this.isPrepareForDeletionOrUndeletion$.getValue() || this.measurementsToDeleteOrUndelete$.getValue().length === 0
    );
  }

  confirmDeleteMeasurement() {
    if (!this.isPrepareForDeletionOrUndeletion$.getValue()) {
      this.depotSearchFacade.deleteMeasurements(this.measurementsToDeleteOrUndelete$.getValue());
    }
    this.visible = false;
  }

  confirmUndeleteMeasurement() {
    if (!this.isPrepareForDeletionOrUndeletion$.getValue()) {
      this.depotSearchFacade.undeleteMeasurements(this.measurementsToDeleteOrUndelete$.getValue());
    }
    this.visible = false;
  }

  cancelDeletionOrUndeletion() {
    this.isPrepareForDeletionOrUndeletion$.next(false);
    this.visible = false;
  }
}
