import { createReducer, Action } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';

import * as CommonActions from './common.actions';

export const COMMON_FEATURE_KEY = 'common';

export interface CommonState {
  appVersion: string;
  production: boolean;
  uiWidth: number;
  uiHeight: number;
  isTouchDevice: boolean;
}

export interface CommonPartialState {
  readonly [COMMON_FEATURE_KEY]: CommonState;
}

export const initialState: CommonState = {
  appVersion: '-',
  production: false,
  uiWidth: 0,
  uiHeight: 0,
  isTouchDevice: true
};

const commonReducer = createReducer(
  initialState,
  immerOn(CommonActions.SetEnvironment, (state, { appVersion, production }) => {
    state.appVersion = appVersion;
    state.production = production;
  }),
  immerOn(CommonActions.SetUIDimensions, (state, { dims }) => {
    state.uiWidth = dims.width;
    state.uiHeight = dims.height;
  }),
  immerOn(CommonActions.MouseDeviceDetected, (state) => {
    state.isTouchDevice = false;
  })
);

export function reducer(state: CommonState | undefined, action: Action) {
  return commonReducer(state, action);
}
