import {
  AttributesConfig,
  OrderTableConfig,
  PassByNormConfiguration,
  WorkspaceConfigIdentifier
} from '../workspace/config/workspace.config.types';

export interface WorkspaceTag {
  tagTypeName: string;
  value: string;
}
export interface Workspace {
  id: string;
  type?: WorkspaceConfigIdentifier;
  tags: WorkspaceTag[];
  orderTableColumns: string[];
  name?: string;
  label?: string;
}

export enum ConfigurationTypeIdentifiers {
  NORMS = 'norms',
  ATTRIBUTES = 'attributes',
  TABLE = 'table'
}

export type ConfigurationTypeIdentifiersNLS = {
  [identifier in ConfigurationTypeIdentifiers]: string;
};

export const configurationTypeIdentifiersNLS: ConfigurationTypeIdentifiersNLS = {
  attributes: 'ORDER.CONFIG_EDITOR.ATTRIBUTES',
  norms: 'ORDER.CONFIG_EDITOR.NORMS',
  table: 'ORDER.CONFIG_EDITOR.TABLE'
};

export type WorkspaceConfigurations = PassByNormConfiguration | AttributesConfig | OrderTableConfig;

export interface ConfigurationSource<T extends WorkspaceConfigurations> {
  // loadingState: LoadingState<T>;
  // errorState: ErrorState<T>
  default: T;
  server?: T;
  preview?: T;
}

export interface RawWorkspaceConfiguration {
  id: string;
  type: string;
  content: string;
}

export const createWorkspace = (
  id: string,
  type: WorkspaceConfigIdentifier,
  name: string,
  label: string
): Workspace => ({
  id,
  type: type,
  tags: [],
  orderTableColumns: [],
  name: name,
  label: label
});
