import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  inject
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DragDropModule, transferArrayItem } from '@angular/cdk/drag-drop';
import { SvgIconCustomizableComponent } from '../svg-icon/svg-icon-customizable.component';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { PickListStore } from './pick-list.store';
import { SearchBarHighlightFilterPipe } from '../../../depot-search/autocomplete-dropdown/pipes/search-bar-highlight.pipe';
import { ClickOutsideModule } from 'ng-click-outside';
import { UiContainerComponent } from './ui-container/ui-container.component';
import { Subscription, filter, fromEvent, map, tap } from 'rxjs';

export type ListType = 'source' | 'target' | 'disabled';

@Component({
  selector: 'cloud-pick-list',
  templateUrl: './pick-list.component.html',
  styleUrls: ['./pick-list.component.css'],
  standalone: true,
  imports: [
    SvgIconCustomizableComponent,
    DragDropModule,
    CommonModule,
    FormsModule,
    SvgIconComponent,
    SearchBarHighlightFilterPipe,
    ClickOutsideModule,
    UiContainerComponent
  ],
  providers: [PickListStore]
})
export class PickListComponent<T> implements OnInit, OnDestroy {
  @Input() sourceList: T[];
  @Input() sourceListHeader: string;
  @Input() sourceListTitle: string;
  @Input() sourceListIconFileName: string;

  @Input() targetList: T[];
  @Input() targetListHeader: string;
  @Input() targetListTitle: string;
  @Input() targetListIconFileName: string;

  @Input() disabledList: T[];
  @Input() disabledListHeader: string;
  @Input() disabledListTitle: string;

  @Input() itemKey: string;

  selectedItemsSource: T[] = [];
  selectedItemsTarget: T[] = [];

  @Output() movedToTarget: EventEmitter<any> = new EventEmitter();
  @Output() movedToSource: EventEmitter<any> = new EventEmitter();

  selectedItems: T[] = [];
  activeList: ListType | null = null;

  pickListStore = inject(PickListStore<T>);

  sourceList$ = this.pickListStore.sourceList$;
  targetList$ = this.pickListStore.targetList$;
  disabledList$ = this.pickListStore.disabledList$;

  sourceQuery$ = this.pickListStore.sourceQuery$;
  targetQuery$ = this.pickListStore.targetQuery$;
  disabledQuery$ = this.pickListStore.disabledQuery$;

  zone = inject(NgZone);
  sub: Subscription;
  ngOnInit() {
    this.pickListStore.itemKey = this.itemKey;
    this.pickListStore.setSourceList(this.sourceList);
    this.pickListStore.setTargetList(this.targetList);
    this.pickListStore.setDisabledList(this.disabledList);

    this.zone.runOutsideAngular(() => {
      this.sub = fromEvent(document, 'keydown')
        .pipe(
          map((event: KeyboardEvent) => event.key),
          filter((key) => key === 'Escape'),
          tap((key) => {
            if (this.activeList === 'source') {
              this.onResetSourceFilter();
            } else if (this.activeList === 'target') {
              this.onResetTargetFilter();
            } else if (this.activeList === 'disabled') {
              this.onResetDisabledFilter();
            }
          })
        )
        .subscribe();
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  moveToTarget() {
    if (this.selectedItemsSource?.length) {
      this.pickListStore.moveToTarget(this.selectedItemsSource);
      this.movedToTarget.emit({
        items: this.selectedItemsSource
      });

      this.selectedItemsSource = [];
    }
  }

  moveToSource() {
    if (this.selectedItemsTarget?.length) {
      this.pickListStore.moveToSource(this.selectedItemsTarget);
      this.movedToSource.emit({
        items: this.selectedItemsTarget
      });

      this.selectedItemsTarget = [];
    }
  }

  onSourceDrop(event) {
    if (event?.container?.id === event?.previousContainer?.id) return;

    transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);

    const item = event.item.data;
    const isAlreadySelected = this.selectedItemsTarget?.find(
      (selectedItem) => selectedItem[this.itemKey] === item[this.itemKey]
    );

    if (isAlreadySelected) {
      this.selectedItemsTarget = this.selectedItemsTarget.filter(
        (selectedItem) => selectedItem[this.itemKey] !== item[this.itemKey]
      );
    }

    const items = [item];
    this.pickListStore.moveToSource(items);
    this.movedToSource.emit({ items });
  }

  onTargetDrop(event) {
    if (event?.container?.id === event?.previousContainer?.id) return;
    transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);

    const item = event.item.data;
    const isAlreadySelected = this.selectedItemsSource?.find(
      (selectedItem) => selectedItem[this.itemKey] === item[this.itemKey]
    );

    if (isAlreadySelected) {
      this.selectedItemsTarget = this.selectedItemsSource.filter(
        (selectedItem) => selectedItem[this.itemKey] !== item[this.itemKey]
      );
    }

    const items = [item];
    this.pickListStore.moveToTarget(items);
    this.movedToTarget.emit({ items });
  }

  onFilterSource($event: string) {
    this.pickListStore.setSourceQuery($event);
  }

  onFilterTarget($event) {
    this.pickListStore.setTargetQuery($event);
  }

  onFilterDisable($event) {
    this.pickListStore.setDisabledQuery($event);
  }

  onResetSourceFilter() {
    this.pickListStore.setSourceQuery('');
  }

  onResetTargetFilter() {
    this.pickListStore.setTargetQuery('');
  }

  onResetDisabledFilter() {
    this.pickListStore.setDisabledQuery('');
  }
}
