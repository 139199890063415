import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { StreamingService } from '../streaming.service';

import * as StreamingActions from './streaming.actions';
import { StreamingFacade } from './streaming.facade';

import { StreamingPartialState } from './streaming.reducer';

import { createAppErrorPayload } from '../../app.factories';
import { from, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { fetch } from '@nrwl/angular';
import { NOOP } from '../../+state/app.actions';
import { StreamingType } from '../utils/streaming.types';
import { Store } from '@ngrx/store';
import { dateDeserializer } from '../../shared/utility-functions/date.helpers';
import { differenceInMinutes } from 'date-fns';
import { TOKEN_VALIDITY_CHECK_INTERVAL_MINUTES } from '../../measurements/+state/measurements.effects';

@Injectable()
export class StreamingEffects {
  requestToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StreamingActions.RequestToken),
      fetch({
        run: (action: ReturnType<typeof StreamingActions.RequestToken>, state: StreamingPartialState) => {
          return from(this.streamingService.getToken(action.tokenContainerId)).pipe(
            map((tokenContainer) => StreamingActions.RequestTokenSuccess({ tokenContainer }))
          );
        },
        onError: (action: ReturnType<typeof StreamingActions.RequestToken>, error) => {
          return StreamingActions.RequestTokenError({ error: createAppErrorPayload(error) });
        }
      })
    )
  );

  lookupTokenFromCacheOrRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StreamingActions.TriggerTokenLookup),
      concatLatestFrom(() => [/* this.streamingFacade.isProcessing$ */ of(false), this.streamingFacade.tokens$]),
      fetch({
        run: (action: ReturnType<typeof StreamingActions.TriggerTokenLookup>, isProcessing, tokenContainers) => {
          if (isProcessing === true) {
            return;
          }

          const tokenContainerFromState = tokenContainers[action.tokenContainerId];

          if (tokenContainerFromState === undefined) {
            return StreamingActions.RequestToken({ tokenContainerId: action.tokenContainerId });
          } else {
            const validUntilDate = dateDeserializer(tokenContainerFromState.validUntilISO);
            const validTimeLeft = differenceInMinutes(validUntilDate, new Date());

            // NOTE: if the expected remaining lifetime of a token is less than the interval at which we check for validity,
            // we will regenerate the token. This is to avoid the case where the token expires between checks.
            const MIN_VALID_TIME_LEFT = TOKEN_VALIDITY_CHECK_INTERVAL_MINUTES;
            if (validTimeLeft <= MIN_VALID_TIME_LEFT) {
              return StreamingActions.RequestToken({ tokenContainerId: action.tokenContainerId });
            } else {
              return StreamingActions.LookupTokenSuccess({
                tokenContainer: tokenContainerFromState
              });
            }
          }
        },
        onError: (action: ReturnType<typeof StreamingActions.TriggerTokenLookup>, error) => {
          return StreamingActions.LookupTokenError({ error: createAppErrorPayload(error) });
        }
      })
    )
  );

  checkStreamingPackageCompletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StreamingActions.CreateStreamingPackage, StreamingActions.AddTokenToStreamingPackage),
      withLatestFrom(this.streamingFacade.currentStreamingPackage$),
      map(([_, streamingPackage]) => {
        let completed = false;
        if (streamingPackage) {
          const hasToken = streamingPackage.tokenContainer?.token && streamingPackage.tokenContainer?.token.length > 0;
          switch (streamingPackage.type) {
            case StreamingType.PROCESSING:
                if (hasToken) {
                  completed = true;
                }

              break;
            case StreamingType.MEASUREMENT: {
              const hasDataset = streamingPackage.datasetContainer.dataset !== undefined;
              if (hasToken && hasDataset) {
                completed = true;
              }
              break;
            }
          }
        }

        if (completed) {
          return StreamingActions.StreamingPackageCompleted();
        } else {
          return NOOP();
        }
      })
    )
  );

  // This checks whether the selected track is still selectable after the selectable tracks have been updated.
  checkIfSelectedTrackResetNeeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StreamingActions.SetSelectableTracks),
      withLatestFrom(this.streamingFacade.selectedTrack$),
      map(([tracks, selectedTrack]) => {
        const selectedTrackIsInSelectableTracks =
          selectedTrack &&
          tracks.selectableTracks.some((selectableTrack) => selectableTrack.locator === selectedTrack.locator);
        const selectedTrackIsTime = selectedTrack && selectedTrack.locator === 'TIME_TRACK';

        if (!selectedTrackIsInSelectableTracks && !selectedTrackIsTime) {
          return StreamingActions.SelectedTrack({ track: undefined });
        } else {
          return NOOP();
        }
      })
    )
  );

  constructor(
    private streamingService: StreamingService,
    private actions$: Actions,
    private streamingFacade: StreamingFacade,
    private store: Store<StreamingPartialState>
  ) {}
}
