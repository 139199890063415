import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  HostListener,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList
} from '@angular/core';
import { DepotSearchFacade } from '../+state/depot-search.facade';
import { ContentCollectionFacade } from '../../content-collection/+state/content-collection.facade';
import { AppFacade } from '../../+state/app.facade';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, combineLatest, BehaviorSubject } from 'rxjs';
import { filter, map, distinctUntilChanged, throttleTime, tap, startWith, take, mergeMap, mapTo } from 'rxjs/operators';
import { CommonFacade } from '@vas/common';
import { AvailableDepotSearchQueryParamIdentifiers } from '../../router/+state/depotsearch-router.effects';
import { NavigationFacade } from '../../router/+state/navigation.facade';
import { getAttributeId } from '../../shared/utility-functions/search.helpers';
import {
  DepotAttribute,
  isDepotAttribute,
  SearchAttributeAndValue,
  SearchAttributeForValues,
  SemanticDepotAttribute
} from '../../shared/+state/attributes/attributes.types';
import { firstValueTakeOne } from '../../shared/utility-functions/firstValueTakeOne';

export interface SearchAttributeAndValueUnserializable extends SearchAttributeForValues {
  id: string;
  attribute: DepotAttribute | SemanticDepotAttribute;
  searchAttributeValue?: string;
  searchAttributeBoolean?: boolean;
  searchAttributeStart?: Date;
  searchAttributeEnd?: Date;
  exact_match: boolean;
}

export const stringifySearchAttribute = (
  searchAttribute: SearchAttributeAndValueUnserializable
): SearchAttributeAndValue => {
  let start: string | undefined;
  let end: string | undefined;
  if (searchAttribute.attribute.type === 'Date') {
    if (searchAttribute.searchAttributeStart && !isNaN(searchAttribute.searchAttributeStart.getTime())) {
      start = searchAttribute.searchAttributeStart.toISOString();
    }
    if (searchAttribute.searchAttributeEnd && !isNaN(searchAttribute.searchAttributeEnd.getTime())) {
      end = searchAttribute.searchAttributeEnd.toISOString();
    }
  }

  const updatedSearchAttributeAndValue: SearchAttributeAndValue = {
    ...searchAttribute,
    searchAttributeStart: start,
    searchAttributeEnd: end
  };

  return updatedSearchAttributeAndValue;
};

@Component({
  selector: 'cloud-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit, OnDestroy, AfterViewInit {
  searchAttributes$: Observable<SearchAttributeAndValueUnserializable[]> = new Observable<
    SearchAttributeAndValueUnserializable[]
  >();
  isSearchingPossible$: Observable<boolean>;
  attributesAreDirty$: BehaviorSubject<{ [attrId: string]: boolean }> = new BehaviorSubject<{
    [attrId: string]: boolean;
  }>({});

  searchLimit: number;
  contentCollectionsLoaded = false;
  windowHeight: number;
  activeSearchAttribute: string;

  subs: Subscription[] = [];
  private searchSubscription?: Subscription;
  private resetSubscription?: Subscription;

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @ViewChildren('attributeValueDropdown') attributeValueDropdowns: QueryList<ElementRef>;
  @ViewChildren('startdate') startdates: QueryList<ElementRef>;

  constructor(
    public depotSearchFacade: DepotSearchFacade,
    public contentCollectionFacade: ContentCollectionFacade,
    public appFacade: AppFacade,
    public translate: TranslateService,
    public commonFacade: CommonFacade,
    private navigationFacade: NavigationFacade
  ) {}

  @HostListener('document:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // keyCode is deprecated, its replacement event.key is however not yet fully available.
    // Check https://medium.com/@uistephen/keyboardevent-key-for-cross-browser-key-press-check-61dbad0a067a for more info
    // tslint:disable-next-line: deprecation
    const key = event.key || event.keyCode;
    if ((key === 13 || key === 'Enter') && event.ctrlKey) {
      if (event.shiftKey) {
        this.reset();
      } else {
        this.search();
      }
    }
  }

  ngOnInit() {
    this.subs.push(
      this.commonFacade.uiHeight$
        .pipe(distinctUntilChanged(), throttleTime(500))
        .subscribe((val) => this.depotSearchFacade.setNumberShownResultLines(val))
    );

    this.isSearchingPossible$ = combineLatest([
      this.depotSearchFacade.currentDepotSearchIsInFlight$,
      this.depotSearchFacade.currentAggregateRequestIsInFlight$
    ]).pipe(map(([searchInFlight, aggregateInFlight]) => !searchInFlight && !aggregateInFlight));

    this.appFacade.cloudDepotsLoaded$.pipe(
      filter((depotsLoaded) => depotsLoaded === false),
      tap((_) => this.appFacade.getCloudDepots())
    );

    this.searchAttributes$ = combineLatest([
      this.depotSearchFacade.searchBarFilters$,
      this.translate.onLangChange.pipe(startWith(true)),
      this.depotSearchFacade.activeSearchBarFilterAttrId$
    ]).pipe(
      map(([result, _, activeSearchBarFilterAttrId]) => {
        const attributes: SearchAttributeAndValueUnserializable[] = [];
        this.activeSearchAttribute = '';
        if (result) {
          for (const searchAttribute of result) {
            const start = searchAttribute.searchAttributeStart
              ? new Date(searchAttribute.searchAttributeStart)
              : undefined;
            const end = searchAttribute.searchAttributeEnd ? new Date(searchAttribute.searchAttributeEnd) : undefined;

            const updatedSearchAttributeAndValue: SearchAttributeAndValueUnserializable = {
              id: searchAttribute.id,
              attribute: searchAttribute.attribute,
              searchAttributeValue: searchAttribute.searchAttributeValue,
              searchAttributeBoolean: searchAttribute.searchAttributeBoolean,
              searchAttributeStart: start,
              searchAttributeEnd: end,
              exact_match: searchAttribute.exact_match
            };
            attributes.push(updatedSearchAttributeAndValue);

            if (activeSearchBarFilterAttrId) {
              const searchAttributeAttrId = isDepotAttribute(searchAttribute.attribute)
                ? searchAttribute.attribute.idName
                : searchAttribute.attribute.id;
              if (searchAttributeAttrId === activeSearchBarFilterAttrId) {
                this.activeSearchAttribute = searchAttribute.id;
              }
            }
          }
        }
        return attributes.reverse();
      })
    );

    this.subs.push(
      this.contentCollectionFacade.loaded$.subscribe((loaded) => {
        this.contentCollectionsLoaded = loaded;
      })
    );
  }

  reset() {
    this.resetSubscription?.unsubscribe();
    this.depotSearchFacade.unselectNode();
    this.depotSearchFacade.resetSearchBar();
    this.depotSearchFacade.setSearchSort();
    this.resetSubscription = combineLatest([
      this.depotSearchFacade.searchText$,
      this.depotSearchFacade.searchBarFilters$
    ])
      .pipe(
        map(([searchText, searchBarFilters]) => searchText === '' && searchBarFilters.length === 0),
        filter((isResetted) => isResetted),
        take(1),
        tap((_) => {
          this.depotSearchFacade.setSelectedMeasurementIDs([]);
          this.depotSearchFacade.performSearch();
        })
      )
      .subscribe();
  }

  search() {
    this.searchSubscription?.unsubscribe();
    // Ensure that the current value of the free-text search input is used. If started on Enter, the value is not automatically used otherwise.
    this.searchSubscription = this.depotSearchFacade.searchText$
      .pipe(
        take(1),
        tap((searchText) => {
          const nextSearchText = this.searchInput.nativeElement.value;
          if (searchText !== nextSearchText) {
            this.navigationFacade.navigate({
              queryParams: {
                [AvailableDepotSearchQueryParamIdentifiers.SearchText]: nextSearchText
              },
              queryParamsHandling: 'merge'
            });
          }
        }),
        mapTo(this.searchInput.nativeElement.value),
        mergeMap((nextSearchText) =>
          combineLatest([this.depotSearchFacade.searchText$, this.attributesAreDirty$]).pipe(
            filter(([searchText, _]) => searchText === nextSearchText), // wait for search text to be updated
            filter(([_, attributesAreDirty]) => Object.values(attributesAreDirty).every((isDirty) => !isDirty)), // wait for search attributes to be updated (an open attribute dropdown is rendered after the url and the store have been updated and then emits dirty-false)
            take(1),
            tap((_) => {
              // Update GUI to remove measurement selection; otherwise they would get reselected once the search results come in...
              this.depotSearchFacade.setSelectedMeasurementIDs([]);
              this.depotSearchFacade.performSearch();
            })
          )
        )
      )
      .subscribe();
  }

  searchTextChanged(nextText: string) {
    this.depotSearchFacade.modifySearchText(nextText);
  }

  addSearchBarFilter(attribute: DepotAttribute | SemanticDepotAttribute) {
    this.depotSearchFacade.addSearchBarFilter(attribute);
    this.depotSearchFacade.activateSearchBarFilter(isDepotAttribute(attribute) ? attribute.idName : attribute.id);
  }

  public async toggleRestrictToActiveCCState() {
    const currentValue = await firstValueTakeOne(this.depotSearchFacade.searchIsRestrictedToActiveCC$);
    this.depotSearchFacade.restrictToActiveCC(!currentValue);
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  isAttributeDirty(attributeValue: SearchAttributeAndValueUnserializable | undefined, isDirty: boolean) {
    if (attributeValue) {
      this.attributesAreDirty$
        .pipe(
          take(1),
          map((attributesAreDirty) => {
            attributesAreDirty[getAttributeId(attributeValue)] = isDirty;
            return attributesAreDirty;
          }),
          tap((attributesAreDirty) => this.attributesAreDirty$.next(attributesAreDirty))
        )
        .subscribe(); // no unsubscribe since completes immediately
    }
  }

  ngAfterViewInit() {
    this.searchInput.nativeElement.focus();
  }

  ngOnDestroy() {
    this.depotSearchFacade.revertSearchBarFilterChanges();
    this.subs.forEach((sub) => sub.unsubscribe());
    this.searchSubscription?.unsubscribe();
    this.resetSubscription?.unsubscribe();
  }
}
