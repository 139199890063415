<div
  class="dropdown content-collection-dropdown"
  data-cy="CCDropdown"
  dndDropzone
  (dndDrop)="handleDrop()"
  [ngClass]="{
    'cc-dropdown-menu-droppable': (this.contentCollectionFacade.contentCollectionItemsDragged$ | async)?.length > 0,
    'open': showDropdown$ | async
  }"
  (clickOutside)="handleDropDownToggle($event, true)"
  [delayClickOutsideInit]="true"
  [excludeBeforeClick]="true"
  [exclude]="'div.modal-dialog,.clr-popover-content,.day-btn'"
>
  <div class="content-collection-trigger dropdown-toggle" (click)="handleDropDownToggle($event)">
    <button class="show-content-collection-btn btn" data-cy="selectContentCollection">
      <cloud-svg-icon fileName="content_collection"></cloud-svg-icon>

      <ng-container *ngIf="!(activeCollectionName$ | async)">
        <span class="content-collection-title">{{ 'CONTENTCOLLECTION.GENERAL.CONTENTCOLLECTION' | translate }}</span>
      </ng-container>
      <span class="content-collection-title truncate">{{ activeCollectionName$ | async }}</span>
    </button>
    <span
      *ngIf="!(contentCollectionItemsLoaded$ | async)"
      data-cy="ccActiveLoadingBadge"
      class="content-collection-badge"
    >
      <cloud-svg-icon fileName="content_collection_spinner" class="content-collection-spinner"></cloud-svg-icon>
    </span>
    <ng-container
      *ngIf="(contentCollectionItemsLoaded$ | async) && (activeCollection$ | async) as activeContentCollection"
    >
      <span data-cy="ccCountBadge" class="content-collection-badge">{{ activeContentCollection.items.length }}</span>
    </ng-container>
    <cloud-svg-icon
      *ngIf="(showDropdown$ | async) === true; else caretDown"
      fileName="caret_up"
      height="5px"
      width="10px"
    ></cloud-svg-icon>
    <ng-template #caretDown>
      <cloud-svg-icon fileName="caret_down" height="5px" width="10px"></cloud-svg-icon>
    </ng-template>
  </div>

  <div
    class="dropdown-menu content-collection-dropdown-menu"
    [ngClass]="{ activeCookieBanner: (appFacade.cookieNoticeAcknowledged$ | async) === false }"
  >
    <!-- Collections -->
    <cloud-ccd-collection-list
      [selectedCC$]="selectedCC$"
      [selectedCCGuid$]="selectedCCGuid$"
      (addNewCollection)="addNewCollectionClicked()"
    ></cloud-ccd-collection-list>

    <!-- Manipulations -->
    <ng-container
      *ngIf="
        !!(featureFlagFacade.featureValue$('CONTENT_COLLECTION_SHARE') | async) &&
          shareActive &&
          (selectedCC$ | async)?.createdBy === (userFacade.self$ | async)?.id;
        else measurementsTable
      "
    >
      <cloud-ccd-share-step [selectedCC$]="selectedCC$" (backClicked)="backClicked()"> </cloud-ccd-share-step>
    </ng-container>

    <!-- Measurements -->
    <ng-template #measurementsTable>
      <cloud-ccd-measurements-table
        [selectedCC$]="selectedCC$"
        [selectedCCGuid$]="selectedCCGuid$"
        (deleteCC)="deleteCC.emit($event)"
        (deleteCCItem)="deleteCCItemClicked($event)"
        (deleteCCItemBulk)="deleteCCItemBulkClicked($event)"
        (editCC)="editCC.emit($event)"
        (shareCC)="shareCCClicked($event)"
        (declineCCShare)="declineCCShareClicked($event)"
      ></cloud-ccd-measurements-table>
    </ng-template>

    <button id="ActivateAndShowInSearch" class="btn btn-primary" (click)="activateAndSearch($event)"
      >Activate and show in search</button
    >
  </div>
</div>
