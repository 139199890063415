<div id="tile">
  <div id="background" *ngIf="backgroundImage" [style.background-image]="'url(' + backgroundImage + ')'"></div>
  <div id="content">
    <i *ngIf="iconClass" [ngClass]="iconClass"></i>
  </div>
  <cloud-measurement-tile-title
    [selected]="selected$ | async"
    [title]="title"
    [counter]="counter$ | async"
    [loading]="loading$ | async"
  >
  </cloud-measurement-tile-title>
</div>
