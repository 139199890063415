<img
  (click)="selectedSmiley = 'frowny'"
  *ngIf="selectedSmiley !== 'frowny'"
  src="assets/img/feedback/frowny.png"
  data-cy="SMILEY_FROWNY_INACTIVE"
/>

<img *ngIf="selectedSmiley === 'frowny'" src="assets/img/feedback/frownySelected.png" data-cy="SMILEY_FROWNY_ACTIVE" />
<img
  (click)="selectedSmiley = 'neutral'"
  *ngIf="selectedSmiley !== 'neutral'"
  src="assets/img/feedback/neutral.png"
  data-cy="SMILEY_NEUTRAL_INACTIVE"
/>
<img
  *ngIf="selectedSmiley === 'neutral'"
  src="assets/img/feedback/neutralSelected.png"
  data-cy="SMILEY_NEUTRAL_ACTIVE"
/>
<img
  (click)="selectedSmiley = 'smiley'"
  *ngIf="selectedSmiley !== 'smiley'"
  src="assets/img/feedback/smiley.png"
  data-cy="SMILEY_SMILEY_INACTIVE"
/>
<img *ngIf="selectedSmiley === 'smiley'" src="assets/img/feedback/smileySelected.png" data-cy="SMILEY_SMILEY_ACTIVE" />
