<div class="lists-container" cdkDropListGroup>
  <div class="pick-list-wrapper">
    <div class="pick-list-heading">
      <cloud-svg-icon [fileName]="sourceListIconFileName"></cloud-svg-icon>
      <span>{{ sourceListHeader }}</span>
    </div>
    <cloud-ui-container
      *ngIf="sourceList$ | async as sourceList"
      [activeListName]="'source'"
      [title]="sourceListTitle"
      [data]="sourceList"
      [(activeList)]="activeList"
      (resetFilter)="onResetSourceFilter()"
      [query]="sourceQuery$ | async"
      (queryChange)="onFilterSource($event)"
      [itemKey]="itemKey"
      [(selectedItems)]="selectedItemsSource"
      cdkDropList
      [cdkDropListData]="sourceList"
      (cdkDropListDropped)="onSourceDrop($event)"
    />
  </div>
  <div class="controls">
    <div class="arrow arrow-right" (click)="moveToTarget()">
      <cloud-svg-icon-customizable fill="#fff" class="caret-icon" fileName="caret"></cloud-svg-icon-customizable>
    </div>
    <div class="arrow arrow-left" (click)="moveToSource()">
      <cloud-svg-icon-customizable fill="#E0383A" class="caret-icon" fileName="caret"></cloud-svg-icon-customizable>
    </div>
  </div>
  <div class="pick-list-wrapper">
    <div class="pick-list-heading">
      <cloud-svg-icon [fileName]="targetListIconFileName"></cloud-svg-icon>
      <span>{{ targetListHeader }}</span>
    </div>
    <cloud-ui-container
      *ngIf="targetList$ | async as targetList"
      [title]="targetListTitle"
      [activeListName]="'target'"
      [data]="targetList"
      [(activeList)]="activeList"
      [query]="targetQuery$ | async"
      (queryChange)="onFilterTarget($event)"
      (resetFilter)="onResetTargetFilter()"
      [itemKey]="itemKey"
      [(selectedItems)]="selectedItemsTarget"
      cdkDropList
      [cdkDropListData]="targetList"
      (cdkDropListDropped)="onTargetDrop($event)"
    />
    <cloud-ui-container
      *ngIf="disabledList$ | async as disabledList"
      [activeListName]="'disabled'"
      [title]="disabledListTitle"
      [(activeList)]="activeList"
      [data]="disabledList"
      [itemKey]="itemKey"
      [query]="disabledQuery$ | async"
      (queryChange)="onFilterDisable($event)"
      (resetFilter)="onResetDisabledFilter()"
      [isDisabled]="true"
    />
  </div>
</div>
