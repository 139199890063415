import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { ThirdPartyLicensesComponent } from './pages/tpl/tpl.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { CopyrightComponent } from './pages/copyright/copyright.component';
import { CanActivateAdmin } from './shared/guards/admin.guard';

export const rootPath = 'depotsearch';

export type AvailableRouteIdentifiers =
  | typeof rootPath
  | 'contentcollection'
  | 'orders'
  | 'devices'
  | 'admin'
  | 'docs'
  | 'about'
  | 'tpl'
  | 'privacy'
  | 'copyright';

// Note: When introducing new Routes, make sure to extend AvailableRouteIdentifiers above as well
export const routes: Routes = [
  {
    path: rootPath,
    loadChildren: () => import('./depot-search/depot-search.module').then((m) => m.DepotSearchModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./order-management/order.module').then((m) => m.OrderModule)
  },
  {
    path: 'devices',
    loadChildren: () => import('./device-management/device-management.module').then((m) => m.DeviceManagementModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [CanActivateAdmin]
  },
  {
    path: 'docs',
    loadChildren: () => import('./docs/docs.module').then((m) => m.DocsModule)
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'tpl',
    component: ThirdPartyLicensesComponent
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    path: 'copyright',
    component: CopyrightComponent
  },
  {
    path: '**',
    redirectTo: '/' + rootPath,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
