import { Injectable, Inject } from '@angular/core';
import { ANGULAR_HTTP_CONTEXT } from '../app.tokens';
import { AngularHttpContext } from '@vas/angular-http-context';
import { CloudService } from '../services/cloud.service';
import { JsonApi, Spec } from '@muellerbbm-vas/grivet';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class PlottingService {
  constructor(@Inject(ANGULAR_HTTP_CONTEXT) private context: AngularHttpContext, private cloudService: CloudService) {}

  async fetchPlotSVG(depotId: string, datasetId: string): Promise<[string, string]> {
    return this.createPlotJob(datasetId, depotId)
      .then((plotUrl) => this.waitForPlotData(plotUrl))
      .then((svgUrl) => this.context.getSVG(svgUrl))
      .then((result) => [result, datasetId]);
  }

  private async createPlotJob(datasetId: string, depotId: string): Promise<URL> {
    // Get url to post plotting requests
    const plottingAppStart = await this.cloudService.getAppStart('plotting');
    const plottingListUrl = plottingAppStart?.relationships['plots']?.links?.related?.url;
    if (!plottingListUrl) {
      throw new Error('Plotting is not available');
    }
    // Create request
    const request = this.createPlottingRequest(depotId, datasetId);
    // Send request
    const postResponse = await this.context.postDocument(plottingListUrl, request);
    const postResponseDocument = new JsonApi.Document(postResponse, this.context);
    // This usually does not yet have link to the svg, so just extract the self link
    const selfLink = postResponseDocument.resource?.selfLink?.url;
    if (!selfLink) {
      throw new Error('Plotting POST returned without a self link');
    }
    return selfLink;
  }

  createPlottingRequest(depotId: string, datasetId: string): Spec.ClientJsonApiDocument {
    const sourceDepotUUID = uuid();
    const sourceResource: Spec.ResourceObject = {
      id: sourceDepotUUID,
      type: 'PlottingSourceDepot',
      relationships: {
        depot: { data: { id: depotId, type: 'Depot' } },
        dataset: { data: { id: datasetId, type: 'DepotDataset' } }
      }
    };

    const graphUUID = uuid();
    const graphResource: Spec.ResourceObject = {
      id: graphUUID,
      type: 'Graph',
      relationships: {
        source: { data: { id: sourceDepotUUID, type: 'PlottingSourceDepot' } }
      }
    };

    const request = new JsonApi.ClientDocument('Plot');
    request.setAttribute('description', 'Plot from webapp');
    request.setRelationship('graphs', [{ id: graphUUID, type: 'Graph' }]);
    request.includeResources([graphResource, sourceResource]);

    return request.data;
  }

  private async waitForPlotData(plotUrl: URL): Promise<URL> {
    let numTries = 0;
    while (numTries < 40) {
      // Sleep for half a second
      await new Promise((resolve) => setTimeout(resolve, 500));
      // Check whether the plot data is already available
      const response = await this.context.getDocument(plotUrl);
      const responseDocument = new JsonApi.Document(response, this.context);
      const svgUrl = responseDocument.resource?.metaLinks['download']?.url;
      if (svgUrl) {
        return svgUrl;
      }
      // Check for error responses
      const error = responseDocument.resource?.attributes?.['error'];
      if (error) {
        let errorMsg = 'Cloud error';
        if (error['message']) {
          errorMsg = errorMsg + ': ' + error['message'];
        }
        if (error['details']) {
          errorMsg = errorMsg + ': ' + error['details'];
        }
        throw new Error(errorMsg);
      }
      numTries = numTries + 1;
    }
    throw new Error('Timeout: Plotting could not be created.');
  }
}
