import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'escapeDangerousCharacters'
})
export class EscapeDangerousCharactersPipe implements PipeTransform {
  // We place attribute names in "innerHtml" to properly highlight in the autocomplete dropdown box
  // So we have to ensure that attribute names are properly escaped
  // cf. https://owasp.org/www-project-top-ten/2017/A7_2017-Cross-Site_Scripting_(XSS)
  //     https://cheatsheetseries.owasp.org/cheatsheets/Cross_Site_Scripting_Prevention_Cheat_Sheet.html
  // Replacements:
  //   & --> &amp;
  //   < --> &lt;
  //   > --> &gt;
  //   " --> &quot;
  //   ' --> &#x27;
  //   / --> &#x2F;
  transform(value: string): string {
    value = replaceAll(value, '&', '&amp;');
    value = replaceAll(value, '<', '&lt;');
    value = replaceAll(value, '>', '&gt;');
    value = replaceAll(value, '"', '&quot;');
    value = replaceAll(value, "'", '&#x27;');
    value = replaceAll(value, '/', '&#x2F;');
    return value;
  }
}

/**
 * Replace all the occerencess of $find by $replace in $originalString
 * https://stackoverflow.com/a/35408958/1792564
 * @param  {originalString} input - Raw string.
 * @param  {find} input - Target key word or regex that need to be replaced.
 * @param  {replace} input - Replacement key word
 * @return {String}       Output string
 */
function replaceAll(originalString, find, replace) {
  return originalString.replace(new RegExp(find, 'g'), replace);
}
