import { Component, Input, OnInit } from '@angular/core';

import { LicenseInfo } from '../types/license.types';

@Component({
  selector: 'cloud-license-icon',
  templateUrl: './license-icon.component.html',
  styleUrls: ['./license-icon.component.css']
})
export class LicenseIconComponent implements OnInit {
  @Input() license: LicenseInfo;

  constructor() {}

  ngOnInit() {}
}
