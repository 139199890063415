import { Token } from 'monaco-editor';
import { DatasetDescription } from '../../measurements/measurements.types';
import {
  DataInfo,
  DataType,
  DataFormat,
  Quantity as ProtoBufQuantity,
  DatasetTwoDimensionalMetaData,
  DatasetThreeDimensionalMetaData
} from '../prototypes/DatasetMessages';
import { StreamingType } from './streaming.types';
import { DatasetLocator, DatasetMetaDataList, DatasetMetaDataList_Item } from '../prototypes/streamingService';

export namespace StreamingChart {
  export type Direction = 'S' | '+X' | '-X' | '+Y' | '-Y' | '+Z' | '-Z';

  export enum ChartType {
    CHART3D = 'chart3D',
    CHART3D2D = 'chart3D2D',
    CHART2DTacho = 'chart2DTacho',
    CHART2DEqui = 'chart2DEqui',
    CHART2DNonEqui = 'chart2DNonEqui'
  }

  // export type AxisType = 'is2d' | 'is2dEqui' | 'is2dNonEqui' | 'is2dSlowQuantity' | 'is3d' | undefined;

  export type StreamingStatus = 'init' | 'streaming' | 'eofReached' | 'cancelled' | 'error';

  export interface TokenAndDataset {
    type: StreamingType;
    token: Token;
    dataset?: DatasetDescription;
  }

  export interface LightningConfig {
    license: string;
    appTitle: string;
    company: string;
  }

  export interface Range {
    start?: number;
    stop?: number;
    num?: number;
  }

  export interface TimeRange {
    start?: bigint;
    stop?: bigint;
  }

  export interface TimeScale {
    estimatedRange?: TimeRange;
    firstSampleTime?: bigint;
    samplingRate?: number;
  }

  export interface DatasetRegister {
    metaDataList: DatasetMetaDataList;
    subscribeIdToLocatorMap: Record<number, DatasetLocator>;
    datasatParamsList: DatasetParameters[];
  }

  export type DatasetInfos = Record<string, DatasetParameters>;

  export interface DatasetCalibration {
    calibscale: number;
    calibfact: number;
    calibofs: number;
  }

  export interface ReferenceDataset {
    refChannelId: number;
    refPosition: Position;
    refquantityY1?: Quantity;
    refquantityY2?: Quantity;
    isvVarPosMode: boolean; // s. VarPosMode in DatasetMessages.proto
  }
  export interface DatasetParameters {
    id: string;
    label: string;
    direction: Direction;
    rawDataType: DataType;
    type: string;
    position?: Position;
    metaData?: DatasetTwoDimensionalMetaData | DatasetThreeDimensionalMetaData;
    referenceDataset?: ReferenceDataset;
    plottingParameters?: PlottingParameters;
    desiredTimeRange?: TimeRange;
  }

  export interface DatasetParaContainer {
    usedRawDataType: DataType;
    currentDatasetParams: DatasetParameters;
    compressedDatasetParams?: DatasetParameters;
  }

  export interface Quantity {
    name: string;
    unitDisplayName?: string;
    dbReferenceFactor: number;
    isPowerType: boolean;
    isoFactor: number;
    isoOffset: number;
    rawQuantity: ProtoBufQuantity;
  }

  export interface DataPoint2D {
    x: number | bigint;
    y: number;
  }

  export interface DataPoint3D2D {
    trackvalue: number;
    steptime: bigint;
    y: number;
  }

  export interface EofInfo {
    eofTime?: bigint;
  }

  export interface BofInfo {
    startTime?: bigint;
    firstSampleTime?: bigint;
  }

  export type DataPointTypes = DataPoint2D | DataPoint3D2D | EofInfo | BofInfo;

  export function isDataPoint2D(dataPoint: DataPointTypes): dataPoint is DataPoint2D {
    if (dataPoint['x'] !== undefined && dataPoint['y'] !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  export function isDataPoint3D2D(dataPoint: DataPointTypes): dataPoint is DataPoint3D2D {
    if (dataPoint['trackvalue'] !== undefined && dataPoint['steptime'] !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  export function isEofInfo(dataPoint: DataPoint2D | DataPoint3D2D | EofInfo | BofInfo): dataPoint is EofInfo {
    if (dataPoint['eofTime'] !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  export function isBofInfo(dataPoint: DataPoint2D | DataPoint3D2D | EofInfo | BofInfo): dataPoint is BofInfo {
    if (dataPoint['startTime'] !== undefined || dataPoint['firstSampleTime'] !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  export interface PlotRange {
    start: number;
    stop: number;
    num: number;
  }

  export interface PlottingParameters {
    chartType: ChartType;
    dataInfo?: DataInfo;
    nPoints?: number;
    nSteps?: number;
    nLines?: number;
    quantityX?: Quantity;
    quantityY?: Quantity;
    quantityZ?: Quantity;
    rangeX?: Range;
    rangeY?: Range;
    rangeZ?: Range;
    deltaX?: number;
    significantDeltaX?: number;
    timeScale?: TimeScale;
    measZeroPoint?: bigint;
    isComplex?: boolean;
    isCompressed?: boolean;
    effectiveDataFormat?: DataFormat;
    isSquared?: boolean;
    effectiveIsSquared?: boolean; // if values are squared after appearance and user value transformation
    isPowerType?: boolean;
    hasOctave?: boolean;
    octaveNth?: number;
    freqWeight?: string;
    defaultAccelerationdBreferenceFactor?: number;
  }

  export type CoordinateSystemType =
    | 'Cartesian'
    | 'Cylinder_X'
    | 'Cylinder_Y'
    | 'Cylinder_Z'
    | 'Spherical_X'
    | 'Spherical_Y'
    | 'Spherical_Z';

  export interface Position {
    measurementPointText?: string;
    coordinateSystemType?: CoordinateSystemType;
    coordinateSystemName?: string;
    coordinateX?: number;
    coordinateY?: number;
    coordinateZ?: number;
    directionX?: number;
    directionY?: number;
    directionZ?: number;
  }

  export type FreqWeight = 'A' | 'B' | 'C' | 'D';

  export function directionString(position: Position) {
    if (position.directionX == 0 && position.directionY == 0 && position.directionZ == 0) return 'S';
    else if (position.directionX == 1 && position.directionY == 0 && position.directionZ == 0) return '+X';
    else if (position.directionX == 0 && position.directionY == 1 && position.directionZ == 0) return '+Y';
    else if (position.directionX == 0 && position.directionY == 0 && position.directionZ == 1) return '+Z';
    else if (position.directionX == -1 && position.directionY == 0 && position.directionZ == 0) return '-X';
    else if (position.directionX == 0 && position.directionY == -1 && position.directionZ == 0) return '-Y';
    else if (position.directionX == 0 && position.directionY == 0 && position.directionZ == -1) return '-Z';
    else return '';
  }

  export interface MagnitudesAndTrackValues {
    magnitudes: number[][];
    zValues: number[];
    timeValues: bigint[];
  }
}
