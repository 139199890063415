<div [ngClass]="{ fullSize: (streamingFacade.fullSize$ | async) === true }">
  <clr-modal
    [clrModalSize]="'xl'"
    [clrModalOpen]="showAnalysisEditor$ | async"
    [clrModalStaticBackdrop]="false"
    [clrModalClosable]="false"
    (clrModalOpenChange)="showAnalysisEditor$.next(false)"
  >
    <div class="modal-body">
      <div class="custom-modal-header">
        <h3>Analysis Editor</h3>
        <div id="spacer"></div>
        <button
          *ngIf="(streamingFacade.fullSize$ | async) === false"
          (click)="streamingFacade.setFullSize(true)"
          class="btn btn-sm btn-icon btn-outline"
          data-cy="FULLSCREEN_UP"
        >
          <clr-icon shape="resize-up"></clr-icon>
        </button>
        <button
          *ngIf="(streamingFacade.fullSize$ | async) === true"
          (click)="streamingFacade.setFullSize(false)"
          class="btn btn-sm btn-icon btn-outline"
          data-cy="FULLSCREEN_DOWN"
        >
          <clr-icon shape="resize-down"></clr-icon>
        </button>
        <button
          class="btn btn-sm btn-icon btn-outline"
          data-cy="FULLSCREEN_CLOSE_ANALYSIS_EDITOR"
          (click)="showAnalysisEditor$.next(false)"
        >
          <clr-icon shape="window-close"></clr-icon>
        </button>
      </div>
      <div class="split-area">
        <as-split [disabled]="!(showAnalysisEditorBar$ | async)" direction="horizontal" unit="pixel">
          <as-split-area
            [size]="40"
            *ngIf="!(showAnalysisEditorBar$ | async) && (isProcessingAvailable$ | async)"
            [order]="0"
          >
            <div class="toggleButtonArea">
              <button
                title="{{ 'PROCESSING.OPENSIDEBAR' | translate }}"
                (click)="toggleAnalysisEditorBar()"
                class="toggelButton btn btn-link"
                data-cy="openEditorBar"
              >
                <clr-icon shape="caret right"></clr-icon>
              </button>
            </div>
          </as-split-area>
          <as-split-area
            minSize="300"
            [size]="400"
            maxSize="500"
            *ngIf="(showAnalysisEditorBar$ | async) && (isProcessingAvailable$ | async)"
            [order]="1"
          >
            <div id="analysisEditorInput" data-cy="analysisEditorInput">
              <div class="toggleButtonArea">
                <button
                  title="{{ 'PROCESSING.CLOSESIDEBAR' | translate }}"
                  (click)="toggleAnalysisEditorBar()"
                  class="toggelButton btn btn-link"
                >
                  <clr-icon shape="caret left"></clr-icon>
                </button>
              </div>
              <!-- Formula -->
              <div id="formulaInputArea">
                <div>{{ 'PROCESSING.FORMULA' | translate }}:</div>
                <div id="selectFormula">
                  <clr-dropdown
                    [clrCloseMenuOnItemClick]="true"
                    *ngIf="isProcessingAvailable$ | async"
                    class="formulaDropdown"
                  >
                    <button
                      id="formulaSelectButton"
                      class="btn btn-sm btn-outline"
                      clrDropdownTrigger
                      [attr.disabled]="(processingFacade.currentProcessing$ | async)!.status === 'active' ? true : null"
                      data-cy="formulaButton"
                    >
                      <div id="formulaButtonLabel">
                        <ng-container *ngIf="processingFacade.selectedFormula$ | async as formula; else analysis">
                          {{ formula.name }}
                        </ng-container>
                        <ng-template #analysis>
                          <div data-cy="noFormulaSelected">
                            {{ 'PROCESSING.SELECT_ANALYSES' | translate }}
                          </div>
                        </ng-template>
                      </div>
                      <clr-icon shape="caret down"></clr-icon>
                    </button>
                    <clr-dropdown-menu *clrIfOpen>
                      <label class="dropdown-header" aria-hidden="true">{{ 'PROCESSING.FORMULAS' | translate }}</label>
                      <div *ngFor="let formula of sortedFormulas$ | async" class="formulaButton">
                        <button
                          clrDropdownItem
                          class="btn btn-link btn-icon no-margin nav-icon analysis-item"
                          (click)="datatypeFilter$.next(ANALYSES_DATATYPE); selectFormula$.next(formula)"
                          title="{{ 'PROCESSING.PROCESSING' | translate }}: {{ formula.name }}"
                          [attr.data-cy]="'chooseFormula_' + formula.id"
                        >
                          {{ formula.name }}
                        </button>
                      </div>
                    </clr-dropdown-menu>
                  </clr-dropdown>

                  <div *ngIf="processingFacade.selectedFormula$ | async as selectedFormula" id="formulaHeader">
                    <div role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-bottom-left">
                      <clr-icon shape="info-circle" size="24"></clr-icon>
                      <span class="tooltip-content"
                        ><div class="formulaDetails">
                          <table>
                            <tr>
                              <td>{{ 'PROCESSING.LASTMODIFIED' | translate }}</td>
                              <td>{{ selectedFormula.last_change | date : 'short' }}</td>
                            </tr>
                          </table>

                          <div>
                            {{ 'PROCESSING.SOURCES' | translate }}:
                            <span class="badge">{{ selectedFormula.sources.length }}</span>
                          </div>
                          <table>
                            <thead>
                              <th>{{ 'GENERAL.NAME' | translate }}:</th>
                              <th>track</th>
                            </thead>
                          </table>
                        </div></span
                      >
                    </div>
                    <button
                      id="resetToDefault"
                      *ngIf="resetAvailable$ | async"
                      class="btn btn-sm btn-link btn-icon"
                      (click)="resetForm$.next(true)"
                      [attr.disabled]="(processingFacade.currentProcessing$ | async)!.status === 'active' ? true : null"
                      title="{{ 'PROCESSING.RESET' | translate }}"
                    >
                      <clr-icon shape="refresh" size="18" data-cy="resetDatasetsAndParameters"></clr-icon>
                    </button>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="(this.filteredDataSets$ | async)?.length > 0; else noDatasets">
                <form id="formulaForm" class="clr-form" #parameterForm="ngForm" data-cy="formulaForm">
                  <div *ngIf="processingFacade.selectedFormula$ | async as selectedFormula">
                    <!-- Source Input -->
                    <p>{{ 'PROCESSING.SOURCES' | translate }}:</p>
                    <ng-container *ngIf="measurementsFacade.selectedMeasurement$ | async as selectedMeasurement">
                      <div *ngIf="(this.filteredDataSets$ | async)?.length > 0">
                        <div
                          *ngFor="let source of conditionCheckedSources$ | async; let i = index"
                          class="sourceInputArea"
                        >
                          <clr-dropdown
                            *ngIf="source.conditionsFullfilled"
                            class="source-btn"
                            [clrCloseMenuOnItemClick]="true"
                            id="selectSourceDropdownButton"
                          >
                            <button
                              type="button"
                              class="btn btn-outline btn-sm source-btn"
                              [ngClass]="{
                                'btn-warning-outline': !source.value
                              }"
                              [attr.disabled]="
                                (processingFacade.currentProcessing$ | async)!.status === 'active' ? true : null
                              "
                              [attr.data-cy]="'sourceSelectionDropdownButton_' + source.source_name"
                              clrDropdownTrigger
                            >
                              <ng-container *ngIf="source.value; else elseBlockSource">
                                <ng-container *ngIf="source.label; else elseBlockName"
                                  ><label>{{ source.label }}:</label></ng-container
                                >
                                <ng-template #elseBlockName
                                  ><label>{{ source.source_name }}:</label></ng-template
                                >
                                {{ source.value.attributes['name'] }} -
                                {{ source.value.attributes['datatype'] }}
                              </ng-container>
                              <ng-template #elseBlockSource>
                                <ng-container *ngIf="source.label; else elseBlockName"
                                  ><label [attr.data-cy]="'source_' + source.source_name + '_notChosen'"
                                    >{{ source.label }}:</label
                                  ></ng-container
                                >
                                <ng-template #elseBlockName
                                  ><label>{{ source.source_name }}:</label></ng-template
                                >
                                {{ 'PROCESSING.SOURCESELECT' | translate }}
                              </ng-template>
                              <clr-icon shape="caret down"></clr-icon>
                            </button>
                            <ng-template [(clrIfOpen)]="dropdownOpen[i]">
                              <clr-dropdown-menu>
                                <cloud-sourceselection-dropdown
                                  (selectedDataset)="selectionChanged($event)"
                                  [selectableDatasets]="filteredDataSets$ | async"
                                  [source]="source"
                                ></cloud-sourceselection-dropdown>
                              </clr-dropdown-menu>
                            </ng-template>
                          </clr-dropdown>
                          <div
                            *ngIf="source.helptext && source.conditionsFullfilled"
                            role="tooltip"
                            aria-haspopup="true"
                            class="tooltip tooltip-md tooltip-bottom-left"
                          >
                            <clr-icon shape="info-circle" size="24"></clr-icon>
                            <span class="tooltip-content" [attr.data-cy]="'sourceToolTipContent_' + source.id"
                              >{{ source.helptext }}<br />{{ source.dependencyToolTip }}</span
                            >
                          </div>
                          <button
                            type="button"
                            class="btn btn-sm btn-link btn-icon show-source-btn"
                            *ngIf="source.value"
                            (click)="this.prepareStream(source.value, selectedMeasurement)"
                            title="{{ 'PROCESSING.SHOWSOURCE' | translate }}"
                            [attr.disabled]="
                              (processingFacade.currentProcessing$ | async)!.status === 'active' ? true : null
                            "
                          >
                            <clr-icon
                              shape="play"
                              *ngIf="source.conditionsFullfilled"
                              [attr.data-cy]="
                                'showDataset_' +
                                source.source_name +
                                '_' +
                                source.value.attributes['name'] +
                                '_' +
                                source.value.attributes['direction']
                              "
                            ></clr-icon>
                            <clr-icon
                              shape="play"
                              class="is-solid"
                              *ngIf="
                                source.value?.id ===
                                  (streamingFacade.currentStreamingPackage$ | async)?.datasetContainer?.dataset?.id &&
                                source.conditionsFullfilled
                              "
                              [attr.data-cy]="
                                'showingDataset_' +
                                source.source_name +
                                '_' +
                                source.value.attributes['name'] +
                                '_' +
                                source.value.attributes['direction']
                              "
                            ></clr-icon>
                          </button>
                        </div>
                      </div>
                      <!-- Parameter Input -->
                      <div
                        *ngFor="let parameter of sortedAndConditionCheckedParameters$ | async; trackBy: trackByFn"
                        class="paramWrapper"
                      >
                        <div *ngIf="parameter.conditionsFullfilled" [ngClass]="{ indented: parameter.dependent }">
                          <div
                            *ngIf="
                              (hasConstraints$ | async) && (hasConstraints$ | async)![parameter.name];
                              else elseBlock
                            "
                          >
                            <clr-select-container>
                              <label *ngIf="parameter.label; else elseBlockName">{{ parameter.label }}</label>
                              <ng-template #elseBlockName
                                ><label>{{ parameter.name }}</label></ng-template
                              >
                              <select
                                [ngClass]="{ defaultInput: parameter.default_is_set }"
                                clrSelect
                                name="{{ parameter.name }}"
                                [ngModel]="parameter.value"
                                #myParamSelect
                                required
                                [cloudFormulaParameterValidator]="parameter"
                                (change)="inputChanged($event, parameter, myParamSelect.value)"
                                [attr.data-cy]="'parameterSelect_' + parameter.name"
                                [attr.disabled]="
                                  (processingFacade.currentProcessing$ | async)!.status === 'active' ? true : null
                                "
                              >
                                <option
                                  [value]="item!.value"
                                  *ngFor="let item of (vconstraints$ | async)![parameter.name]"
                                  [title]="item!.labelLong ? item!.labelLong : ''"
                                  [attr.data-cy]="'parameterOption_' + parameter.name + '_' + item.value"
                                >
                                  <span *ngIf="item!.label">{{ item!.label }} ({{ item!.value }})</span>
                                  <span *ngIf="!item!.label">{{ item!.value }}</span>
                                </option>
                              </select>
                              <clr-control-helper>{{ parameter.helptext }}</clr-control-helper>
                              <clr-control-error *clrIfError="'isParameterValidationError'; error as err">
                                {{ err.message }}
                                <span *ngIf="parameter.helptext" class="parameterValidationErrorHelptext"
                                  >({{ parameter.helptext }})</span
                                >
                              </clr-control-error>
                            </clr-select-container>
                          </div>
                          <ng-template #elseBlock>
                            <div class="inputParameter">
                              <clr-input-container
                                [attr.disabled]="
                                  (processingFacade.currentProcessing$ | async)!.status === 'active' ? true : null
                                "
                              >
                                <label
                                  >{{ parameter.label ? parameter.label : parameter.name }}
                                  <div
                                    role="tooltip"
                                    aria-haspopup="true"
                                    class="tooltip tooltip-md tooltip-bottom-right"
                                  >
                                    <clr-icon
                                      *ngIf="parameter.dependent === true"
                                      shape="organization"
                                      class="dependentIcon"
                                    ></clr-icon>
                                    <span *ngIf="parameter.dependencyToolTip" class="tooltip-content"
                                      ><div class="formulaDetails">{{ parameter.dependencyToolTip }}</div></span
                                    >
                                  </div></label
                                >
                                <input
                                  [ngClass]="{ defaultInput: parameter.default_is_set }"
                                  clrInput
                                  [ngModel]="parameter.value"
                                  name="{{ parameter.name }}"
                                  #myParamVar
                                  type="text"
                                  required
                                  [cloudFormulaParameterValidator]="parameter"
                                  (keyup)="inputChanged($event, parameter, myParamVar.value)"
                                  [attr.disabled]="
                                    (processingFacade.currentProcessing$ | async)!.status === 'active' ? true : null
                                  "
                                  [attr.data-cy]="'parameterInput_' + parameter.name"
                                />
                                <clr-control-helper>{{ parameter.helptext }}</clr-control-helper>
                                <clr-control-error *clrIfError="'isParameterValidationError'; error as err">
                                  {{ err.message }}
                                </clr-control-error>
                              </clr-input-container>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </form>

                <ng-container>
                  <div id="runbuttonarea">
                    <!-- No active Processing  -->
                    <ng-container *ngIf="(this.runButtonState$ | async) === 'READY'">
                      <div
                        class="centeredbutton"
                        *ngIf="!(streamingFacade.currentStreamingPackageIsStreaming$ | async)"
                      >
                        <div class="btn-group btn-primary">
                          <button
                            id="startProcessingButton"
                            class="btn btn-success"
                            [title]="runButtonTitle$ | async"
                            (click)="startProcessing$.next(true)"
                            [disabled]="
                              !parameterForm.form.valid ||
                              !(allSelectedFormulasHaveValue$ | async) ||
                              (streamingFacade.currentStreamingPackageIsStreaming$ | async)
                            "
                            data-cy="startProcessingButton"
                          >
                            <span style="text-transform: uppercase">
                              {{ 'PROCESSING.RUNPROCESSING' | translate }}
                            </span>
                          </button>
                        </div>
                      </div>
                    </ng-container>
                    <!-- Active Processing  -->
                    <!-- Current active dataset is being processed -> Spinner with percent -->
                    <div class="centeredbutton" *ngIf="(this.runButtonState$ | async) === 'PROCESSING'">
                      <div class="btn-group btn-primary">
                        <button
                          id="activeProcessingIndicator"
                          [ngStyle]="{
                            background:
                              'linear-gradient(90deg, rgb(0, 121, 184) 0%, rgb(0, 121, 184)' +
                              (processingFacade.currentProcessingProgress$ | async) +
                              '%, rgba(0, 121, 184, 0.8) ' +
                              (processingFacade.currentProcessingProgress$ | async) +
                              '%, rgba(0, 121, 184, 0.8) 100%)'
                          }"
                          class="btn btn-success"
                          title="{{ 'PROCESSING.PROCESSING' | translate }}: {{
                            (processingFacade.selectedFormula$ | async)?.name
                          }}"
                          disabled
                        >
                          <clr-spinner clrInline clrSmall clrInverse></clr-spinner>
                          <span style="text-transform: uppercase">
                            {{ 'PROCESSING.PROCESSING' | translate }}
                            {{ processingFacade.currentProcessingProgress$ | async }}%
                          </span>
                        </button>
                        <button
                          data-cy="CANCEL_PROCESSING"
                          class="btn cancelButton"
                          (click)="streamingFacade.stopStreaming(); cancelProcessing$.next(true)"
                        >
                          <clr-icon shape="ban"></clr-icon>
                        </button>
                      </div>
                    </div>
                    <!-- processing done, still streaming -> show spinner without percent -->
                    <div class="centeredbutton" *ngIf="(this.runButtonState$ | async) === 'STREAMING'">
                      <div class="btn-group btn-primary">
                        <button
                          id="activeStreamingIndicator"
                          [ngStyle]="{
                            background:
                              'linear-gradient(90deg, rgb(0, 121, 184) 0%, rgb(0, 121, 184)' +
                              (processingFacade.currentProcessingProgress$ | async) +
                              '%, rgba(0, 121, 184, 0.8) ' +
                              (processingFacade.currentProcessingProgress$ | async) +
                              '%, rgba(0, 121, 184, 0.8) 100%)'
                          }"
                          class="btn btn-success"
                          title="{{ 'STREAMING.STREAMING' | translate }}: {{
                            (processingFacade.selectedFormula$ | async)?.name
                          }}"
                          disabled
                        >
                          <clr-spinner clrInline clrSmall clrInverse></clr-spinner>
                          <span style="text-transform: uppercase">
                            {{ 'STREAMING.STREAMING' | translate }}
                          </span>
                        </button>
                        <button
                          data-cy="CANCEL_STREAMING"
                          class="btn cancelButton"
                          (click)="streamingFacade.stopStreaming(); cancelProcessing$.next(true)"
                        >
                          <clr-icon shape="ban"></clr-icon>
                        </button>
                      </div>
                    </div>

                    <!-- Error during Processing -->
                    <div class="centeredbutton" *ngIf="(this.runButtonState$ | async) === 'ERROR'">
                      <div class="btn-group">
                        <button
                          id="errorIndicator"
                          class="btn btn-danger-outline"
                          [title]="runButtonErrorTitle$ | async"
                          (click)="
                            changeFormulaParameters$.next(true);
                            changeFormulaSources$.next(true);
                            startProcessing$.next(true)
                          "
                          (mouseover)="errorShape = 'repeat'"
                          (mouseout)="errorShape = 'error-standard'"
                          data-cy="ERROR_RETRY"
                        >
                          <clr-icon class="is-error" [attr.shape]="errorShape"></clr-icon>
                          <span style="text-transform: uppercase">
                            {{ 'PROCESSING.FAILED' | translate }}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <ng-template #noDatasets>
                <ng-container *ngIf="(processingFacade.selectedFormula$ | async) !== null">
                  <ng-container *ngIf="(processingFacade.filteredProcessingDatasetsLoading$ | async) === false">
                    {{ 'PROCESSING.NODATASETS' | translate }}
                  </ng-container>
                  <div
                    *ngIf="(processingFacade.filteredProcessingDatasetsLoading$ | async) === true"
                    data-cy="LoadingDatasetsSpinner"
                  >
                    <clr-spinner clrInline clrSmall clrInverse></clr-spinner>
                    {{ 'PROCESSING.LOADINGDATASETS' | translate }}
                  </div>
                </ng-container>
              </ng-template>
            </div>
          </as-split-area>
          <as-split-area [size]="'*'" [order]="2">
            <cloud-integrated-streaming #streamingComponent></cloud-integrated-streaming>
          </as-split-area>
        </as-split>
      </div>
    </div>
  </clr-modal>
</div>
