// NOTE: Currently inactive, to be re-activated after implementing OpenReplay or compareable

import { Component } from '@angular/core';
import { AppFacade } from '../../+state/app.facade';
import { smileys } from './smiley/smiley.component';
@Component({
  selector: 'cloud-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent {
  showThankYouMessage = false;

  constructor(public appFacade: AppFacade) {}

  sendFeedback(feedbackContent: string, smiley: smileys | undefined) {
    if (feedbackContent.length > 0 && smiley) {
      // this.logRocketService.sendFeedback(feedbackContent, smiley);
      this.showThankYouMessage = true;
      setTimeout(() => {
        this.showThankYouMessage = false;
      }, 3000);
    }
  }

  preventSpaceDismiss(event: KeyboardEvent): void {
    // keyCode is deprecated, its replacement event.key is however not yet fully available.
    // Check https://medium.com/@uistephen/keyboardevent-key-for-cross-browser-key-press-check-61dbad0a067a for more info
    // tslint:disable-next-line: deprecation
    const key = event.key || event.keyCode;
    if (key === ' ' || key === 32) {
      event.stopPropagation();
    }
  }
}
