import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect, concatLatestFrom } from '@ngrx/effects';

import { from } from 'rxjs';
import { map, delay } from 'rxjs/operators';

import { MeasurementsPartialState } from './measurements.reducer';
import * as MeasurementsAction from './measurements.actions';

import { MeasurementsDownloadService } from '../download/download.service';

import { createAppError } from '../../app.factories';
import { fetch } from '@nrwl/angular';
import { MeasurementsFacade } from './measurements.facade';

// key used to store user's attributeDisplayType in localStorage
export const USER_SAVED_ATTRIBUTE_DISPLAY_TYPE = 'user-attribute-display-type';
export const TOKEN_VALIDITY_CHECK_INTERVAL_MINUTES = 5; // 5 minutes
export const TOKEN_VALIDITY_CHECK_INTERVAL_MS = TOKEN_VALIDITY_CHECK_INTERVAL_MINUTES * 60 * 1000; // 5 minutes in milliseconds

@Injectable()
export class MeasurementsDownloadEffects {
  constructor(
    private actions$: Actions,
    private measurementFacade: MeasurementsFacade,
    private measurementDownloadService: MeasurementsDownloadService
  ) {}

  requestDownload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementsAction.RequestDownload),
      fetch({
        run: (action: ReturnType<typeof MeasurementsAction.RequestDownload>, state: MeasurementsPartialState) => {
          return from(this.measurementDownloadService.requestDownload(action.downloadRequest)).pipe(
            map((result) => {
              return MeasurementsAction.DownloadRequested({ downloadRequest: result });
            })
          );
        },

        onError: (action: ReturnType<typeof MeasurementsAction.RequestDownload>, error) => {
          return createAppError(error);
        }
      })
    )
  );

  downloadRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementsAction.DownloadRequested),
      fetch({
        run: (action: ReturnType<typeof MeasurementsAction.DownloadRequested>, state: MeasurementsPartialState) => {
          return MeasurementsAction.MonitorDownloadRequest({ downloadRequest: action.downloadRequest });
        },

        onError: (action: ReturnType<typeof MeasurementsAction.DownloadRequested>, error) => {
          return createAppError(error);
        }
      })
    )
  );

  monitorDownload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementsAction.MonitorDownloadRequest),
      concatLatestFrom(() => this.measurementFacade.downloads$),
      fetch({
        run: (action: ReturnType<typeof MeasurementsAction.MonitorDownloadRequest>, downloads) => {
          return from(this.measurementDownloadService.monitorDownload(action.downloadRequest)).pipe(
            delay(500),
            map((result) => {
              //Cancellation comes from outside, thus we need to get this information from the store:
              const storedDownloadReq = downloads?.[result.id];

              if (!storedDownloadReq) {
                return MeasurementsAction.ExitDownloadRequestMonitoring();
              } else if (result.hasDownloadStarted) {
                return MeasurementsAction.CleanUpDownloadRequest({ downloadRequest: result });
              } else if (result.error) {
                return MeasurementsAction.DownloadRequestFailed({ downloadRequest: result });
              } else {
                return MeasurementsAction.MonitorDownloadRequest({ downloadRequest: result });
              }
            })
          );
        },
        onError: (action: ReturnType<typeof MeasurementsAction.MonitorDownloadRequest>, error) => {
          return createAppError(error);
        }
      })
    )
  );

  cancelDownloadRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementsAction.CancelDownloadRequest),
      fetch({
        run: (action: ReturnType<typeof MeasurementsAction.CancelDownloadRequest>, state: MeasurementsPartialState) => {
          return from(this.measurementDownloadService.cancelDownloadRequest(action.downloadRequest)).pipe(
            map((result) => {
              return MeasurementsAction.CleanUpDownloadRequest({ downloadRequest: result });
            })
          );
        },
        onError: (action: ReturnType<typeof MeasurementsAction.CancelDownloadRequest>, error) => {
          return createAppError(error);
        }
      })
    )
  );
}
