import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { translateLoader } from '../app.translate';

import { MultiLangDirective } from './directives/multi-lang.directive';
import { BlockTextSelectDirective } from './directives/block-text-select-on-modifier-keys.directive';

import { TranslateAttributePipe } from './pipes/translate-attribute.pipe';
import { TranslateAttributeGroupPipe } from './pipes/translate-attributegroup.pipe';
import { FindAttributeByIdPipe } from './pipes/find-attribute-by-id.pipe';
import { FormatDateTimePipe } from './pipes/format-datetime.pipe';
import { TimeAgoPipe } from './pipes/format-timeago.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { FormatFileSizePipe } from './pipes/format-filesize.pipe';
import { BoolToTranslateStringPipe } from './pipes/bool-to-translate-string.pipe';
import { FormatMeasurementNamePipe } from './pipes/format-measurement-name.pipe';
import { PreventSavePersonalAndPaymentsDataDirective } from './directives/preventSavePersonalAndPaymentsData.directive';
import { QueryParamsHandlingDirective } from './directives/default-query-params-handing-router-link.directive.ts.directive';

// Components
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { PanelContentTreeComponent } from './components/panel-detail-content/panel-content-tree/panel-content-tree.component';
import { PanelContentTreeNodeComponent } from './components/panel-detail-content/panel-content-tree/panel-content-tree-node/panel-content-tree-node.component';
import { PanelDetailKeyValueDisplayComponent } from './components/panel-detail-content/panel-detail-key-value-display/panel-detail-key-value-display.component';
import { PanelDetailContentComponent } from './components/panel-detail-content/panel-detail-content.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TableSortIconComponent } from './components/table-sort-icon/table-sort-icon.component';

import { ClarityModule } from '@clr/angular';
import { FormsModule } from '@angular/forms';
import { AngularSplitModule } from 'angular-split';
import { RouterLink } from '@angular/router';

import { CanActivateAdmin } from './guards/admin.guard';

import { StoreModule } from '@ngrx/store';
import * as fromAttributes from './+state/attributes/attributes.reducer';
import { AttributesFacade } from './+state/attributes/attributes.facade';
import { AttributesEffects } from './+state/attributes/attributes.effects';
import { EffectsModule } from '@ngrx/effects';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { PickListComponent } from './components/pick-list/pick-list.component';
import { SvgIconCustomizableComponent } from './components/svg-icon/svg-icon-customizable.component';
import { DndModule } from 'ngx-drag-drop';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ClickOutsideModule } from 'ng-click-outside';

@NgModule({
  declarations: [
    MultiLangDirective,
    BlockTextSelectDirective,
    PreventSavePersonalAndPaymentsDataDirective,
    QueryParamsHandlingDirective,
    TranslateAttributePipe,
    FindAttributeByIdPipe,
    FormatDateTimePipe,
    FormatFileSizePipe,
    BoolToTranslateStringPipe,
    SafePipe,
    TranslateAttributeGroupPipe,
    FormatMeasurementNamePipe,
    TimeAgoPipe,
    ToggleButtonComponent,
    PanelDetailContentComponent,
    PanelDetailKeyValueDisplayComponent,
    PanelContentTreeComponent,
    PanelContentTreeNodeComponent,
    TableSortIconComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild(translateLoader),
    AngularSplitModule,
    ClarityModule,
    StoreModule.forFeature(fromAttributes.ATTRIBUTES_FEATURE_KEY, fromAttributes.reducer),
    EffectsModule.forFeature([AttributesEffects]),
    DragDropModule,
    ClickOutsideModule,
    SvgIconComponent,
    SvgIconCustomizableComponent,
    SpinnerComponent,
    PickListComponent
  ],
  exports: [
    MultiLangDirective,
    BlockTextSelectDirective,
    PreventSavePersonalAndPaymentsDataDirective,
    QueryParamsHandlingDirective,
    TranslateAttributePipe,
    FindAttributeByIdPipe,
    FormatDateTimePipe,
    FormatFileSizePipe,
    BoolToTranslateStringPipe,
    TranslateAttributeGroupPipe,
    FormatMeasurementNamePipe,
    SafePipe,
    TimeAgoPipe,
    ToggleButtonComponent,
    PanelDetailContentComponent,
    PanelDetailKeyValueDisplayComponent,
    PanelContentTreeComponent,
    PanelContentTreeNodeComponent,
    TableSortIconComponent,
    SpinnerComponent,
    SvgIconComponent,
    PickListComponent,
    SvgIconCustomizableComponent
  ],
  providers: [FormatMeasurementNamePipe, CanActivateAdmin, RouterLink, AttributesFacade]
})
export class SharedModule {}
