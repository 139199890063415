<h3>{{ 'APP.IMPRINT' | translate }}</h3>

<div x-ms-format-detection="phone" class="clr-row">
  <div class="clr-col-xl-8 clr-col-12">
    <div class="card">
      <div class="card-block">
        <div class="card-title">Müller-BBM VibroAkustik Systeme GmbH</div>
        <div class="card-text">
          <div *cloudMultiLang="'de'">
            <p>
              Helmut-A.-Müller-Straße 1 - 5<br />
              82152 Planegg, Deutschland
            </p>
            <p>
              Tel.: <a HREF="tel:+49 89 264860-0">+49 89 264860-0</a><br />
              Fax: <a HREF="tel:+49 89 264860-450">+49 89 264860-450</a><br />
              E-Mail: <a HREF="mailto:info.de@mbbm-vas.com">info.de@mbbm-vas.com</a>
            </p>
            <p>Geschäftsführer: Günter Lang, Per Pildner von Steinburg</p>
            <p>
              Handelsregisternummer: HRB 115031<br />
              Umsatzsteueridentifikations-Nr.: DE183657166<br />
              Registergericht: Amtsgericht München
            </p>
            <p>
              Presse- und Öffentlichkeitsarbeit:<br />
              Pressemitteilungen und weiteres Informationsmaterial können Sie über unsere Abteilung für "Corporate
              Communications" anfordern.<br />
              Tel.: <a HREF="tel:+49 89 264860-0">+49 89 264860-0</a><br />
              E-Mail: <a HREF="mailto:info.de@mbbm-vas.com">info.de@mbbm-vas.com</a>
            </p>
            <p>
              Alle Inhalte unter:
              <a HREF="https://www.mbbm-vas.com/" target="_BLANK"
                >https://www.mbbm-vas.com/<clr-icon shape="link"></clr-icon
              ></a>
            </p>
          </div>
          <div *cloudMultiLang="'NOT de'">
            <p>
              Helmut-A.-Müller-Straße 1 - 5<br />
              82152 Planegg, Germany
            </p>
            <p>
              Tel.: <a HREF="tel:+49 89 264860-0">+49 89 264860-0</a><br />
              Fax: <a HREF="tel:+49 89 264860-450">+49 89 264860-450</a><br />
              E-Mail: <a HREF="mailto:info.de@mbbm-vas.com">info.de@mbbm-vas.com</a>
            </p>
            <p>Managing Directors: Günter Lang, Per Pildner von Steinburg</p>
            <p>
              Commercial Register No.: HRB 115031<br />
              VAT Identification Number.: DE183657166<br />
              Court of Registration: Amtsgericht München
            </p>
            <p>
              Media and Publicity:<br />
              Press announcements and additional information materials can be obtained through our Corporate
              Communication department.<br />
              Tel.: <a HREF="tel:+49 89 264860-0">+49 89 264860-0</a><br />
              E-Mail: <a HREF="mailto:info.de@mbbm-vas.com" rel="noopener noreferrer">info.de@mbbm-vas.com</a>
            </p>
            <p>
              All content available at:
              <a HREF="https://www.mbbm-vas.com/" target="_BLANK" rel="noopener noreferrer"
                >https://www.mbbm-vas.com/<clr-icon shape="link"></clr-icon
              ></a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
