import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PanelContentTree, PanelContentTreeNode } from '../panel-detail-content.component';

@Component({
  selector: 'cloud-panel-order-content-tree',
  templateUrl: './panel-content-tree.component.html',
  styleUrls: ['./panel-content-tree.component.css']
})
export class PanelContentTreeComponent {
  @Input() tree: PanelContentTree;
  @Output() nodeSelected: EventEmitter<PanelContentTreeNode> = new EventEmitter<PanelContentTreeNode>();

  constructor() {}
}
