import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect, concatLatestFrom } from '@ngrx/effects';

import { EMPTY, from, merge, timer } from 'rxjs';
import { map, catchError, switchMap, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { MeasurementsPartialState } from './measurements.reducer';
import { MeasurementsActionTypes } from './measurements.actions';
import * as MeasurementsAction from './measurements.actions';

import { MeasurementsService } from '../measurements.service';
import { dynamicComponentIdentifiers, attributeDisplayType } from '../measurements.types';

import { createAppError } from '../../app.factories';
import { fetch } from '@nrwl/angular';
import { MeasurementsFacade } from './measurements.facade';
import { SearchResultEntry } from '../../shared/types/search.types';

import { SetSelectableTracks, TriggerTokenLookup } from '../../streaming/+state/streaming.actions';
import { encodeMeasurementTokenContainerID } from '../../streaming/utils/streaming-token.types';
import { DepotSearchActionTypes } from '../../depot-search/+state/depot-search.actions';

// key used to store user's attributeDisplayType in localStorage
export const USER_SAVED_ATTRIBUTE_DISPLAY_TYPE = 'user-attribute-display-type';
export const TOKEN_VALIDITY_CHECK_INTERVAL_MINUTES = 5; // 5 minutes
export const TOKEN_VALIDITY_CHECK_INTERVAL_MS = TOKEN_VALIDITY_CHECK_INTERVAL_MINUTES * 60 * 1000; // 5 minutes in milliseconds

@Injectable()
export class MeasurementsEffects {
  constructor(
    private actions$: Actions,
    private measurementService: MeasurementsService,
    private measurementFacade: MeasurementsFacade,
    private store: Store<MeasurementsPartialState>
  ) {}

  fetchSpecificMeasurementTile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementsActionTypes.FetchSpecificMeasurementTile),
      concatLatestFrom(() => this.measurementFacade.measurements$),
      fetch({
        run: (action: ReturnType<typeof MeasurementsAction.FetchSpecificMeasurementTile>, measurements) => {
          const selectedMeasurement = measurements[action.measurement.measurementId];
          const identifier: dynamicComponentIdentifiers = action.identifier;
          if (selectedMeasurement === undefined) {
            // If the measurement no longer exists, then return an empty shell, instead of waiting indefinitely
            return MeasurementsAction.MeasurementReceived({
              measurement: action.measurement,
              identifier: action.identifier,
              resource: []
            });
          }

          if (!(selectedMeasurement.loading.includes(identifier) || selectedMeasurement.loaded.includes(identifier))) {
            this.store.dispatch(
              MeasurementsAction.StartMeasurementLoading({
                measurementId: action.measurement.measurementId,
                identifier: identifier
              })
            );
            return from(
              this.measurementService.fetchMeasurement(action.measurement.measurementBrowseUrl, action.identifier)
            ).pipe(
              switchMap((measurementResult) =>
                from(this.measurementService.fetchDepotId(action.measurement.measurementBrowseUrl)).pipe(
                  map((depotId) => ({ measurementResult, depotId }))
                )
              ),
              map((result) =>
                MeasurementsAction.MeasurementReceived({
                  measurement: action.measurement,
                  identifier: action.identifier,
                  resource: result.measurementResult,
                  depotId: result.depotId
                })
              )
            );
          }
        },

        onError: (action: ReturnType<typeof MeasurementsAction.FetchSpecificMeasurementTile>, error) => {
          let serializableError = error;
          if (error instanceof HttpErrorResponse) {
            serializableError = {
              error: error.error,
              message: error.message,
              status: error.status,
              name: error.name
            };
          }
          return MeasurementsAction.FetchSpecificMeasurementTileError({
            measurement: action.measurement,
            identifier: action.identifier,
            error: serializableError
          });
        }
      })
    )
  );

  passOnTileError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementsActionTypes.FetchSpecificMeasurementTileError),
      map((action: ReturnType<typeof MeasurementsAction.FetchSpecificMeasurementTileError>) => {
        return createAppError(action.error);
      })
    )
  );

  showMeasurement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementsActionTypes.ShowMeasurement),
      concatLatestFrom(() => this.measurementFacade.activeDynamicComponentForSelectedMeasurement$),
      fetch({
        run: (action: ReturnType<typeof MeasurementsAction.ShowMeasurement>, selectedDynamicComponent) => {
          let dynamicComponent: dynamicComponentIdentifiers | undefined = selectedDynamicComponent?.selectedComponent;

          if (!dynamicComponent) {
            dynamicComponent = 'overview';
          }
          this.store.dispatch(
            MeasurementsAction.FetchSpecificMeasurementTile({
              measurement: action.measurement,
              identifier: dynamicComponent
            })
          );

          if (dynamicComponent !== 'overview') {
            this.store.dispatch(
              MeasurementsAction.FetchSpecificMeasurementTile({
                measurement: action.measurement,
                identifier: 'overview'
              })
            );
          }
        }
      })
    )
  );

  ensureStreamingTokenOnSelectedMeasurement$ = createEffect(() =>
    merge(
      this.actions$.pipe(ofType(MeasurementsAction.ShowMeasurement, MeasurementsAction.MeasurementReceived)),
      this.actions$.pipe(ofType(DepotSearchActionTypes.ViewMeasurementOverviewList)).pipe(map((_) => null))
    ).pipe(
      switchMap((action) =>
        action
          ? timer(0, TOKEN_VALIDITY_CHECK_INTERVAL_MS).pipe(
              switchMap(() => this.measurementFacade.selectedMeasurement$),
              filter(
                (selectedMeasurement) =>
                  selectedMeasurement !== undefined &&
                  selectedMeasurement?.depotId !== undefined &&
                  selectedMeasurement?.measurementId !== undefined
              ),
              map((selectedMeasurement) => {
                const depotId = selectedMeasurement?.depotId;
                const measurementId = selectedMeasurement?.measurementId;
                const tokenContainerId = encodeMeasurementTokenContainerID(depotId!, measurementId!);
                return TriggerTokenLookup({ tokenContainerId });
              })
            )
          : EMPTY
      )
    )
  );

  resetTracksOnMeasurementChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementsActionTypes.ShowMeasurement),
      map((_) => SetSelectableTracks({ selectableTracks: [] }))
    )
  );

  showMeasurementComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementsActionTypes.ShowDynamicComponentForSelectedEntry),
      concatLatestFrom(() => [
        this.measurementFacade.selectedMeasurement$,
        this.measurementFacade.activeDynamicComponentForSelectedMeasurement$
      ]),
      fetch({
        run: (
          action: ReturnType<typeof MeasurementsAction.ShowDynamicComponentForSelectedEntry>,
          selectedMeasurement,
          selectedComponent
        ) => {
          const measurement = selectedMeasurement;
          const dynamicComponent: dynamicComponentIdentifiers | undefined = selectedComponent?.selectedComponent;
          if (measurement && dynamicComponent) {
            const searchResultEntry: SearchResultEntry = {
              measurementBrowseUrl: measurement.measurementBrowseUrl,
              measurementId: measurement.measurementId
            };
            this.store.dispatch(
              MeasurementsAction.FetchSpecificMeasurementTile({
                measurement: searchResultEntry,
                identifier: dynamicComponent
              })
            );
          }
        },

        onError: (action: ReturnType<typeof MeasurementsAction.ShowDynamicComponentForSelectedEntry>, error) => {
          return createAppError(error);
        }
      })
    )
  );

  writeUserAttributeDisplayTypeToStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementsAction.ChangeAttributeButtonState),
      map((action) => {
        this.writeUserAttributeDisplayTypeToStorage(action.shownAttribs);
        return MeasurementsAction.AttributeDisplayTypeSaved();
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  initDefaultAttributeDisplayType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementsAction.InitDefaultAttributeDisplayType),
      map((_) => {
        const displayType: attributeDisplayType = this.loadUserAttributeDisplayTypeFromStorageOrUseDefault();
        return MeasurementsAction.AttributeDisplayTypeInitialized({ attrDisplayType: displayType });
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  writeUserAttributeDisplayTypeToStorage(attrDisplayType: attributeDisplayType) {
    const stringToSave = attrDisplayType;
    localStorage.setItem(USER_SAVED_ATTRIBUTE_DISPLAY_TYPE, stringToSave);
  }

  loadUserAttributeDisplayTypeFromStorageOrUseDefault(): attributeDisplayType {
    const userSavedAttributeDisplayTypes = localStorage.getItem(USER_SAVED_ATTRIBUTE_DISPLAY_TYPE);
    if (userSavedAttributeDisplayTypes) {
      return userSavedAttributeDisplayTypes as attributeDisplayType;
    }
    return 'all';
  }
}
