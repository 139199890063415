// common
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { AngularSplitModule } from 'angular-split';

// NGRX

import { SharedModule } from '../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  PROCESSING_FEATURE_KEY,
  initialState as measurementInitialState,
  reducer as processingReducer
} from './+state/processing.reducer';

import { ProcessingEffects } from './+state/processing.effects';
import { ProcessingFacade } from './+state/processing.facade';
import { TranslateModule } from '@ngx-translate/core';

// VAS
import { ProcessingComponent } from './processing.component';
import { translateLoader } from '../app.translate';
import { AnalysisEditorComponent } from './analysis-editor/analysis-editor.component';
import { SourceselectionDropdownComponent } from './sourceselection-dropdown/sourceselection-dropdown.component';
import { FormulaParameterValidatorDirective } from './formula-parameter-validator.directive';
import { StreamingModule } from '../streaming/streaming.module';

export const processingImports: any[] = [
  CommonModule,
  StoreModule.forFeature(PROCESSING_FEATURE_KEY, processingReducer, {
    initialState: measurementInitialState
  }),
  EffectsModule.forFeature([ProcessingEffects]),
  TranslateModule.forChild(translateLoader),
  AngularSplitModule,
  StreamingModule,
  SharedModule
];

export const processingDeclarations: any[] = [
  ProcessingComponent,
  AnalysisEditorComponent,
  SourceselectionDropdownComponent,
  FormulaParameterValidatorDirective
];

@NgModule({
  declarations: processingDeclarations,
  imports: [FormsModule, processingImports, ClarityModule],
  exports: [ProcessingComponent, AnalysisEditorComponent, SourceselectionDropdownComponent],
  providers: [ProcessingFacade]
})
export class ProcessingModule {}
