<clr-modal [(clrModalOpen)]="visible">
  <h3 class="modal-title">{{ 'CONTENTCOLLECTION.MODALS.RENAME.RENAMEDIALOGTITLE' | translate }}</h3>
  <div class="modal-body">
    <form clrForm>
      <div class="clr-control-container" [ngClass]="{ 'clr-error': nameCollision }">
        <input
          #CCNameInput
          id="CCNameInput"
          placeholder="{{ 'CONTENTCOLLECTION.MODALS.CREATE.ENTERNEWNAME' | translate }}"
          name="CCNameInput"
          data-cy="CCNameInput"
          [ngModel]="editName"
          (ngModelChange)="handleNameChanged($event)"
          type="text"
          (keyup.enter)="confirmEditCollection($event)"
        />
        <span *ngIf="nameCollision" class="clr-subtext clr-error">{{
          'CONTENTCOLLECTION.MODALS.GENERAL.NAMEALREADYEXISTS' | translate
        }}</span>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="cancelEditCollection($event)">
      {{ 'CONTENTCOLLECTION.MODALS.GENERAL.CANCELBUTTON' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="editName.length === 0 || nameCollision"
      data-cy="doCCEditButton"
      (click)="confirmEditCollection($event)"
    >
      {{ 'CONTENTCOLLECTION.MODALS.RENAME.RENAMEBUTTON' | translate }}
    </button>
  </div>
</clr-modal>
