import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighlightPipe } from './highlight/highlight.pipe';
import { EscapeDangerousCharactersPipe } from './escape-dangerous-characters/escape-dangerous-characters.pipe';

export { HighlightPipe, EscapeDangerousCharactersPipe };

@NgModule({
  imports: [CommonModule],
  declarations: [HighlightPipe, EscapeDangerousCharactersPipe],
  exports: [HighlightPipe, EscapeDangerousCharactersPipe]
})
export class VasPipesModule {}
