import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USERS_FEATURE_KEY, UsersState } from './users.reducer';
import { rolesQuery } from '../roles/roles.selectors';
import { licensesQuery } from '../licenses/licenses.selectors';
import { UserWithRolesAndLicenses } from '@root/apps/cloud/src/app/user/user.type';
import { LicenseInfo } from '../../types/license.types';
import { RoleInfo } from '../../types/role.types';

const getUsersState = createFeatureSelector<UsersState>(USERS_FEATURE_KEY);

const getLoaded = createSelector(getUsersState, (state: UsersState) => {
  return state.loaded;
});

const getUsers = createSelector(getUsersState, getLoaded, (state: UsersState, isLoaded) => {
  return isLoaded ? state.users : undefined;
});

const getError = createSelector(getUsersState, (state: UsersState) => state.error);

const getUsersFull = createSelector(
  getUsers,
  rolesQuery.getRoles,
  rolesQuery.getRoleToUserRelations,
  licensesQuery.getLicenses,
  (users, roles, roleToUserRelations, licenses) => {
    const fullUsers: UserWithRolesAndLicenses[] =
      users?.map((user) => {
        const relations = roleToUserRelations?.filter((relation) => {
          return relation.user.id === user.id;
        });
        const userRoles: RoleInfo[] = relations
          ?.map((relation) => {
            return roles?.find((role) => relation.role.id === role.id);
          })
          .filter((role) => !!role) as RoleInfo[];

        const licensesFiltered: LicenseInfo[] = licenses?.filter((license) => {
          return (
            license.roles.length > 0 &&
            license.roles.some((role) => relations?.find((relation) => relation.role.id === role))
          );
        });

        const fullUser = {
          ...user,
          roles: userRoles ?? [],
          licenses: licensesFiltered ?? []
        };

        return fullUser;
      }) ?? [];
    return fullUsers;
  }
);

export const usersQuery = {
  getLoaded,
  getError,
  getUsers,
  getUsersFull
};
