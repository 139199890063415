import { DepotAttribute, SemanticDepotAttribute } from '../shared/+state/attributes/attributes.types';
import { AggregateTree } from '../shared/types/aggregateTree.types';
import { SearchParameters } from '../shared/types/search.types';
import { SortDirection } from '../shared/types/sorting.types';

export interface DepotSearchParameters extends SearchParameters {
  restrictToActiveCC: boolean;
  relevantCCItemKeys?: string[];
}

export const defaultSearchParams: DepotSearchParameters = {
  restrictToActiveCC: false,
  text: '',
  searchLimit: 100,
  offset: 0,
  searchFilters: []
};

export interface DepotSearchResultEntry {
  measurementBrowseUrl: string;
  measurementId: string;
  [index: string]: string;
}

export interface DepotSearchResult {
  numberOfEntries: number;
  numberOfTotalResults: number;
  entries: DepotSearchResultEntry[];
  aggregates?: AggregateTree[];
}

export interface MeasurementDeleteOrUndeleteData {
  fileType: 'PAK' | 'ZATFX';
  browseUrl: string; // url to the DeporBrowseContent that contains either the PAK measurement or the ZATFX file (not the measurement!)
  undeleteUrl: string | undefined; // url to the DepotPendingDeletion object, if the measurement is already pending deletion. Otherwise undefined
  title: string; // display string for the title of zatfx: Usually the same as the browse url or the measurement id, but something more readable for orders
  location: string[]; // Project/Job/Subtitle for PAK measurements; file location for ZATFX measurements
  names: string[]; // list of all names of the measurements (for zatfx measurements this is far more readable than raw ids)
  ids: string[]; // list of all ids of DepotBrowseContents of the actual measurements
}

export interface MeasurementToIgnore {
  id: string;
  description: string | undefined; // e.g. the workspace name
  href: string | undefined; // e.g. to link to order page
}

export interface MeasurementsToIgnore {
  [reason: string]: MeasurementToIgnore[];
}

export type SortOptions = {
  attribute: SemanticDepotAttribute | DepotAttribute;
  direction: SortDirection;
};
