import {
  Component,
  AfterViewInit,
  ComponentFactoryResolver,
  ViewChild,
  ViewChildren,
  QueryList,
  ViewContainerRef,
  ChangeDetectorRef,
  ComponentRef,
  ElementRef,
  OnDestroy,
  OnInit,
  ChangeDetectionStrategy
} from '@angular/core';

import { AppFacade } from '../+state/app.facade';
//check imports
import { MeasurementsDirective } from './measurements.directive';

//end check imports
import {
  MeasurementTile,
  ComponentDescription,
  measurementAttributes,
  dynamicComponentIdentifiers
} from './measurements.types';
import { MeasurementTileComponent } from './tiles/measurement-tile/measurement-tile.component';
import { MeasurementOverviewComponent } from './panels/measurement-overview/measurement-overview.component';
import { MeasurementUnitsUnderTestComponent } from './panels/measurement-units-under-test/measurement-units-under-test.component';
import { MeasurementTestSequenceComponent } from './panels/measurement-testsequence/measurement-testsequence.component';
import { MeasurementDatasetDescriptionComponent } from './panels/measurement-dataset-description/measurement-dataset-description.component';

import { TranslateService } from '@ngx-translate/core';
import { Subscription, merge } from 'rxjs';
import { SplitComponent } from 'angular-split';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import * as scaler from 'rect-scaler';
import { MeasurementsFacade } from './+state/measurements.facade';
import { DepotSearchFacade } from '../depot-search/+state/depot-search.facade';
import { CommonFacade } from '@vas/common';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'cloud-measurements',
  templateUrl: './measurements.component.html',
  styleUrls: ['./measurements.component.css', '../shared/styles/panels.css', '../shared/styles/tiles.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeasurementsComponent implements OnInit, AfterViewInit, OnDestroy {
  dynamicCompontents: ComponentDescription[] = [
    {
      identifier: 'overview',
      translationKey: _('DEPOTSEARCH.OVERVIEW'),
      attributes: {
        backgroundImage: 'assets/img/eye.jpg',
        selected$: this.measurementsFacade.componentIsSelectedDynamicComponent$('overview'),
        loading$: this.measurementsFacade.selectedEntryAttribIsLoading$('overview')
      },
      panelComponent: MeasurementOverviewComponent,
      tileComponent: MeasurementTileComponent
    },
    {
      identifier: 'unitsUnderTest',
      translationKey: _('DEPOTSEARCH.UNIT_UNDER_TEST'),
      attributes: {
        backgroundImage: 'assets/img/car.jpg',

        loading$: this.measurementsFacade.selectedEntryAttribIsLoading$('unitsUnderTest'),
        selected$: this.measurementsFacade.componentIsSelectedDynamicComponent$('unitsUnderTest')
      },
      panelComponent: MeasurementUnitsUnderTestComponent,
      tileComponent: MeasurementTileComponent
    },
    {
      identifier: 'testSequencesAndAnys',
      translationKey: _('DEPOTSEARCH.TESTSEQUENCE_AND_OTHER'),
      attributes: {
        backgroundImage: 'assets/img/brakes.jpg',

        loading$: this.measurementsFacade.selectedEntryAttribIsLoading$('testSequencesAndAnys'),
        selected$: this.measurementsFacade.componentIsSelectedDynamicComponent$('testSequencesAndAnys')
      },
      panelComponent: MeasurementTestSequenceComponent,
      tileComponent: MeasurementTileComponent
    },
    {
      identifier: 'datasetDescription',
      translationKey: _('DEPOTSEARCH.DATASETDESCRIPTION'),
      attributes: {
        backgroundImage: 'assets/img/sensors.jpeg',
        counter$: this.measurementsFacade.selectedEntryAttribCount$('datasetDescription'),
        loading$: this.measurementsFacade.selectedEntryAttribIsLoading$('datasetDescription'),
        selected$: this.measurementsFacade.componentIsSelectedDynamicComponent$('datasetDescription')
      },
      panelComponent: MeasurementDatasetDescriptionComponent,
      tileComponent: MeasurementTileComponent
    }
  ];

  @ViewChild(MeasurementsDirective, { static: true }) contentHost: MeasurementsDirective;
  @ViewChildren('tilesRef', { read: ViewContainerRef }) public tiles: QueryList<ViewContainerRef>;

  @ViewChild('tileContainer', { static: true }) tileContainer: ElementRef;
  @ViewChild('split', { static: true }) split: SplitComponent;
  tileContainerWidth: number;
  tileContainerHeight: number;
  tileWidth: number;
  tileHeight: number;

  subs: Subscription[] = [];

  constructor(
    public measurementsFacade: MeasurementsFacade,
    public depotSearchFacade: DepotSearchFacade,
    public commonFacade: CommonFacade,
    private componentFactoryResolver: ComponentFactoryResolver,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subs.push(
      merge(this.commonFacade.uiWidth$, this.commonFacade.uiHeight$, this.split.dragProgress$)
        .pipe(debounceTime(10))
        .subscribe(() => {
          const boundingRect: ClientRect = this.tileContainer.nativeElement.getBoundingClientRect();
          this.tileContainerWidth = boundingRect.width;
          this.tileContainerHeight = boundingRect.height;

          if (this.tileContainerWidth > 0) {
            // Base Value are used to calculate aspect Ratio calculation both locally and within the scalar-rect lib
            const widthBase = 220;
            const heightBase = 150;
            const aspectRatio = heightBase / widthBase;

            const scalingResults = scaler.largestRect(
              this.tileContainerWidth,
              this.tileContainerHeight,
              this.tiles.length,
              widthBase,
              heightBase
            );

            const gutter = 20;
            const maxWidth = 250;
            const minWidth = 125;
            const proposedWidth = scalingResults.width - gutter;

            if (proposedWidth > maxWidth) {
              this.tileWidth = maxWidth;
            } else if (proposedWidth < minWidth) {
              this.tileWidth = minWidth;
            } else {
              this.tileWidth = proposedWidth;
            }
            this.tileHeight = this.tileWidth * aspectRatio;

            this.cd.detectChanges();
          }
        })
    );

    this.subs.push(
      this.measurementsFacade.activeDynamicComponentForSelectedMeasurement$
        .pipe(distinctUntilChanged((prev, curr) => prev?.selectedComponent === curr?.selectedComponent))
        .subscribe((dynamicComponentIdentifier) => {
          if (dynamicComponentIdentifier) {
            const activeComponent = this.dynamicCompontents.filter(
              (comp) => comp.identifier === dynamicComponentIdentifier.selectedComponent
            )[0];
            if (activeComponent) {
              const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                activeComponent.panelComponent
              );
              const viewContainerRef = this.contentHost.viewContainerRef;
              viewContainerRef.clear();
              viewContainerRef.createComponent(componentFactory);
            } else {
              //nothing selected yet => select overview;
              const identifier: dynamicComponentIdentifiers = 'overview';
              this.measurementsFacade.showDynamicComponentForSelectedEntry(identifier);
            }
          }
        })
    );
  }

  selectTile(component: ComponentDescription) {
    this.measurementsFacade.showDynamicComponentForSelectedEntry(component.identifier);
  }

  initTranslations() {
    this.dynamicCompontents.forEach((cmp) => {
      this.translateService
        .get(cmp.translationKey)
        .subscribe((translatedTitle) => (cmp.attributes.title = translatedTitle));
    });
  }

  initTiles() {
    this.initTranslations();
    if (this.tiles !== undefined) {
      this.tiles.toArray().forEach((target) => {
        const componentIdentifier = target.element.nativeElement['id'];
        const tilesForIdentifier = this.dynamicCompontents.filter((cmp) => cmp.identifier === componentIdentifier);
        if (tilesForIdentifier.length !== 1) {
          throw new Error('Tiles must be unique, illegal number of tiles found for identifier');
        }
        const tile = tilesForIdentifier[0];

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(tile.tileComponent);
        target.clear();
        const componentRef: ComponentRef<MeasurementTile> = target.createComponent(componentFactory);

        // Propagate additional properties
        measurementAttributes.forEach((attributeKey) => {
          const value = tile.attributes[attributeKey];
          if (value) {
            componentRef.instance[attributeKey] = value;
          }
        });
      });
      this.cd.detectChanges();
    }
  }

  // -------------------

  ngAfterViewInit(): void {
    this.initTiles();
    this.subs.push(
      this.tiles.changes.subscribe(() => {
        this.initTiles();
      })
    );
    this.subs.push(this.translateService.onLangChange.subscribe(() => this.initTiles()));
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
