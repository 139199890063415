import { createFeatureSelector, createSelector } from '@ngrx/store';
import { APP_FEATURE_KEY, AppState, AppUserPrefs } from './app.reducer';
import { CloudAppNames } from '../app.types';

const getAppState = createFeatureSelector<AppState>(APP_FEATURE_KEY);

const getCookieNoticeAcknowledged = createSelector(getAppState, (state: AppState) => state.cookieAcknowledged);

const getLanguage = createSelector(getAppState, (state: AppState) => state.language);

const getAllAvailableApps = createSelector(getAppState, (state: AppState) => state.availableApps);

const getServerVersion = createSelector(getAppState, (state: AppState) => state.serverVersion);

const getClient = createSelector(getAppState, (state: AppState) => state.client);

const getIsCloudAppAvailable = (appName: CloudAppNames) =>
  createSelector(getAppState, (state: AppState) => state.availableApps.includes(appName));

const getCloudDepots = createSelector(getAppState, (state: AppState) => state.depots);

const getCloudDepotsLoaded = createSelector(getAppState, (state: AppState) => state.depotsLoaded);

const getIsUserAdmin = createSelector(getAppState, (state: AppState) => state.isUserAdmin);

const getIsDepotAdmin = createSelector(getAppState, (state: AppState) => state.isDepotAdmin);

const getIsTracking = createSelector(getAppState, (state: AppState) => state.tracking);

const getUserPreferences = createSelector(getAppState, (state: AppState) => {
  return state.userPreferences;
});

const getSpecificUserPreferences = (preference: AppUserPrefs) =>
  createSelector(getAppState, (state: AppState) => {
    return state.userPreferences[preference];
  });

export const appQuery = {
  getCookieNoticeAcknowledged,
  getLanguage,
  getAllAvailableApps,
  getServerVersion,
  getClient,
  getIsCloudAppAvailable,
  getCloudDepots,
  getCloudDepotsLoaded,
  getIsUserAdmin,
  getIsDepotAdmin,
  getIsTracking,
  getUserPreferences,
  getSpecificUserPreferences
};
