// @generated by protobuf-ts 2.8.2 with parameter long_type_string
// @generated from protobuf file "streamingService.proto" (package "BBMgRPCstreamingNS", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { DatasetStreamingService } from "./streamingService";
import type { StreamDatasetsResponse } from "./streamingService";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import type { DatasetMetaDataList } from "./streamingService";
import type { StreamDatasetsParameters } from "./streamingService";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { DatasetKeyDataList } from "./streamingService";
import type { EnumerateDatasetsParameters } from "./streamingService";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service BBMgRPCstreamingNS.DatasetStreamingService
 */
export interface IDatasetStreamingServiceClient {
    /**
     * @generated from protobuf rpc: EnumerateDatasets(BBMgRPCstreamingNS.EnumerateDatasetsParameters) returns (BBMgRPCstreamingNS.DatasetKeyDataList);
     */
    enumerateDatasets(input: EnumerateDatasetsParameters, options?: RpcOptions): UnaryCall<EnumerateDatasetsParameters, DatasetKeyDataList>;
    /**
     * @generated from protobuf rpc: DatasetsMetaData(BBMgRPCstreamingNS.StreamDatasetsParameters) returns (BBMgRPCstreamingNS.DatasetMetaDataList);
     */
    datasetsMetaData(input: StreamDatasetsParameters, options?: RpcOptions): UnaryCall<StreamDatasetsParameters, DatasetMetaDataList>;
    /**
     * @generated from protobuf rpc: StreamDatasets(BBMgRPCstreamingNS.StreamDatasetsParameters) returns (stream BBMgRPCstreamingNS.StreamDatasetsResponse);
     */
    streamDatasets(input: StreamDatasetsParameters, options?: RpcOptions): ServerStreamingCall<StreamDatasetsParameters, StreamDatasetsResponse>;
}
/**
 * @generated from protobuf service BBMgRPCstreamingNS.DatasetStreamingService
 */
export class DatasetStreamingServiceClient implements IDatasetStreamingServiceClient, ServiceInfo {
    typeName = DatasetStreamingService.typeName;
    methods = DatasetStreamingService.methods;
    options = DatasetStreamingService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: EnumerateDatasets(BBMgRPCstreamingNS.EnumerateDatasetsParameters) returns (BBMgRPCstreamingNS.DatasetKeyDataList);
     */
    enumerateDatasets(input: EnumerateDatasetsParameters, options?: RpcOptions): UnaryCall<EnumerateDatasetsParameters, DatasetKeyDataList> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<EnumerateDatasetsParameters, DatasetKeyDataList>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DatasetsMetaData(BBMgRPCstreamingNS.StreamDatasetsParameters) returns (BBMgRPCstreamingNS.DatasetMetaDataList);
     */
    datasetsMetaData(input: StreamDatasetsParameters, options?: RpcOptions): UnaryCall<StreamDatasetsParameters, DatasetMetaDataList> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<StreamDatasetsParameters, DatasetMetaDataList>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: StreamDatasets(BBMgRPCstreamingNS.StreamDatasetsParameters) returns (stream BBMgRPCstreamingNS.StreamDatasetsResponse);
     */
    streamDatasets(input: StreamDatasetsParameters, options?: RpcOptions): ServerStreamingCall<StreamDatasetsParameters, StreamDatasetsResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<StreamDatasetsParameters, StreamDatasetsResponse>("serverStreaming", this._transport, method, opt, input);
    }
}
