import { Inject, Injectable } from '@angular/core';
import { JsonApi, Spec } from '@muellerbbm-vas/grivet';
import { AngularHttpContext } from '@vas/angular-http-context';
import { v4 as uuid } from 'uuid';
import { ANGULAR_HTTP_CONTEXT } from '../app.tokens';
import { DepotSearchService } from '../depot-search/depot-search.service';
import { DepotSearchParameters, DepotSearchResultEntry, defaultSearchParams } from '../depot-search/depot-search.types';
import { CloudService } from '../services/cloud.service';
import { AttributesFacade } from '../shared/+state/attributes/attributes.facade';
import { SearchAttributeAndValue, SearchFilter } from '../shared/+state/attributes/attributes.types';
import { FormatMeasurementNamePipe } from '../shared/pipes/format-measurement-name.pipe';
import { ResultColumn } from '../shared/types/search.types';
import { firstValueTakeOne } from '../shared/utility-functions/firstValueTakeOne';
import {
  ContentCollection,
  ContentCollectionItem,
  ContentCollectionItemFromSearch,
  ContentCollectionShareInfo,
  ContentCollectionShareType
} from './content-collection.types';
import { copyContentCollectionToClipboard } from './copy-cc-to-clipboard';

interface CollectionItemMap<T = ContentCollectionItem> {
  id: string;
  items: T[];
}

export const contentCollectionContentAttributesMapping = {
  depot: 'depot_name',
  project: 'hrchypak.Project.name',
  job: 'hrchypak.Job.name',
  measurementName: 'hrchypak.Subtitle.name',
  startTime: 'measurement_properties.start_time'
};

@Injectable({
  providedIn: 'root'
})
export class ContentCollectionService {
  private contentCollectionListURL: URL;
  private browseContentRelationshipCache: { [itemId: string]: JsonApi.Relationship } = {};

  public copyContentCollectionToClipboard = copyContentCollectionToClipboard;

  constructor(
    @Inject(ANGULAR_HTTP_CONTEXT) private context: AngularHttpContext,
    private depotSearchService: DepotSearchService,
    private attributeFacade: AttributesFacade,
    private cloudService: CloudService,
    private formatMeasurementNamePipe: FormatMeasurementNamePipe
  ) {}

  async ensureDepotAppStart() {
    return await this.cloudService.getAppStart('depot', true);
  }

  async ensureContentCollectionListURL() {
    if (!this.contentCollectionListURL) {
      const appStart = await this.ensureDepotAppStart();
      this.contentCollectionListURL = appStart?.relationships['content_collection'].links?.related.url ?? new URL('');
    }
  }

  async loadAllContentCollections(): Promise<ContentCollection[]> {
    const collections: ContentCollection[] = [];
    const appStart = await this.ensureDepotAppStart();
    if (!appStart) {
      return [];
    }
    const contentCollections = await appStart.relatedResources['content_collection'];

    const collectionItemMapsFromSearch = await Promise.all(
      contentCollections.map(async (resource) => {
        // Pass ressources of this CC collection to enhance the search result with CC API Data
        const ccItemsFromCCapi = await this.loadContentCollectionItem(resource);

        return await this.searchContentCollectionItems(resource, ccItemsFromCCapi);
      })
    );

    for (const resource of contentCollections) {
      const collectionItems = collectionItemMapsFromSearch.find((e) => e.id === resource.id);

      const createdBy = (resource.rawData?.relationships?.created_by?.data as Spec.ResourceIdentifierObject)?.id;

      collections.push({
        guid: resource.id,
        name: resource.attributes?.['name'],
        itemsLoaded: collectionItems !== undefined,
        items: collectionItems?.items ?? [],
        createdBy: createdBy,
        myAccessInfo: await this.collectMyAccessInfo(resource),
        shareInfo: await this.collectSharedData(resource)
      });
    }

    return collections;
  }

  private async collectMyAccessInfo(resource: JsonApi.Resource) {
    const myAccessInfo = await resource.relatedResource['my_access_info'];

    return myAccessInfo
      ? {
          canAddItems: myAccessInfo.attributes?.['may_add_items'],
          canRemoveItems: myAccessInfo.attributes?.['may_remove_items'],
          validUntil: myAccessInfo.attributes?.['has_access_until']
        }
      : ({} as ContentCollectionShareInfo);
  }

  private async collectSharedData(resource: JsonApi.Resource) {
    const sharedWithUsers = await resource.relatedResources['shared_with_users'];
    const sharedWithRoles = await resource.relatedResources['shared_with_roles'];

    const sharedUserData = sharedWithUsers?.map((share) => {
      return {
        id: share.id,
        shareType: ContentCollectionShareType.User,
        sharedWith: (share.relationships?.['shared_with_user']?.data as Spec.ResourceIdentifierObject)?.id,
        canAddItems: share.attributes?.['may_add_items'],
        canRemoveItems: share.attributes?.['may_remove_items'],
        validUntil: share.attributes?.['valid_until']
      };
    });

    const sharedRoleData = sharedWithRoles?.map((share) => {
      return {
        id: share.id,
        shareType: ContentCollectionShareType.Role,
        sharedWith: (share.relationships?.['shared_with_role']?.data as Spec.ResourceIdentifierObject)?.id,
        canAddItems: share.attributes?.['may_add_items'],
        canRemoveItems: share.attributes?.['may_remove_items'],
        validUntil: share.attributes?.['valid_until']
      };
    });

    return sharedUserData.concat(sharedRoleData);
  }

  private async searchContentCollectionItems(
    collection: JsonApi.Resource,
    itemsFromAPI: CollectionItemMap
  ): Promise<CollectionItemMap> {
    // Use async await to make sure the attributes are loaded before continuing
    const depotAttribs = await firstValueTakeOne(this.attributeFacade.ensuredDepotAttributes);

    const baseIDnames = ['pk', 'depot_id'];

    Object.values(contentCollectionContentAttributesMapping).forEach((value) => {
      baseIDnames.push(value);
    });

    const resultColumns: Record<string, ResultColumn> = {};
    baseIDnames.forEach((idName) => {
      const attrib = depotAttribs?.find((attrib) => attrib.idName === idName);
      if (attrib) {
        resultColumns[idName] = { attribute: attrib, field: attrib.idName };
      }
    });

    // Prepare search filters
    const itemList = await collection.relatedResources['content_collection_items'];
    const individualPKfilters = itemList.map((item) => {
      const relatedMeasurementPK = item.relationships['content'].data?.['id'];
      const itemFilter: SearchAttributeAndValue = {
        id: uuid(),
        attribute: resultColumns['pk'].attribute,
        exact_match: true,
        searchAttributeValue: relatedMeasurementPK,
        searchAttributeBoolean: false
      };

      return itemFilter;
    });

    const searchFilter: SearchFilter = [
      {
        type: 'or',
        filters: individualPKfilters
      }
    ];

    const searchParameters: DepotSearchParameters = {
      ...defaultSearchParams,
      description: 'Content Collection Items Lookup Search',
      searchLimit: 1000,
      offset: 0,
      restrictToActiveCC: false,
      resultColumns: Object.values(resultColumns),
      searchFilters: searchFilter
    };

    // NOTE: Collections that do not have items should not attempt to search for them
    if (individualPKfilters.length === 0) {
      return { id: collection.id, items: [] };
    }

    const searchResult = await this.depotSearchService.doSearch(searchParameters);
    const itemsFromSearch: CollectionItemMap<ContentCollectionItemFromSearch> = await this.readItemsSearchResult(
      collection.id,
      searchResult.entries
    );

    const enrichedResults: CollectionItemMap = this.enrichItemsFromSearchResult(itemsFromAPI, itemsFromSearch);

    return enrichedResults;
  }

  private readItemsSearchResult(
    collectionId: string,
    itemsSearchResultEntries: DepotSearchResultEntry[]
  ): CollectionItemMap<ContentCollectionItemFromSearch> {
    const items: ContentCollectionItemFromSearch[] = [];

    for (const entry of itemsSearchResultEntries) {
      items.push({
        depotID: entry['depot_id'],
        measurementId: entry.measurementId,
        content: {
          depot: entry[contentCollectionContentAttributesMapping.depot],
          project: entry[contentCollectionContentAttributesMapping.project],
          job: entry[contentCollectionContentAttributesMapping.job],
          measurementName: entry[contentCollectionContentAttributesMapping.measurementName],
          measurementBrowseUrl: entry.measurementBrowseUrl,
          measurementStartTime: entry[contentCollectionContentAttributesMapping.startTime]
        }
      });
    }

    const result = { id: collectionId, items: items };
    return result;
  }

  private enrichItemsFromSearchResult(
    fromAPI: CollectionItemMap,
    fromSearch: CollectionItemMap<ContentCollectionItemFromSearch>
  ): CollectionItemMap {
    const enrichedItems = fromSearch.items.map((searchItem) => {
      const relatedAPIitem = fromAPI.items.find((apiItem) => apiItem.measurementId === searchItem.measurementId);
      const enrichedItem: ContentCollectionItem = {
        ...searchItem,
        itemID: relatedAPIitem?.itemID ?? ''
      };
      return enrichedItem;
    });

    return {
      id: fromSearch.id,
      items: enrichedItems
    };
  }

  async createContentCollection(newCollection: ContentCollection): Promise<ContentCollection> {
    await this.ensureContentCollectionListURL();
    const postRequest: Spec.ClientJsonApiDocument = this.createNewCollectionRequest(newCollection);
    const response = await this.context.postDocument(this.contentCollectionListURL, postRequest);
    const responseDocument = new JsonApi.Document(response, this.context);
    const newCollectionResource = responseDocument.resource;

    const result: ContentCollection = {
      guid: newCollectionResource?.id ?? '',
      name: newCollectionResource?.attributes?.['name'],
      itemsLoaded: true
    };
    return result;
  }

  async loadContentCollectionItem(collection: JsonApi.Resource): Promise<CollectionItemMap> {
    return collection.relatedResources['content_collection_items'].then((res) =>
      this.readItemResource(collection.id, res)
    );
  }

  private async readItemResource(collectionId: string, itemResources: JsonApi.Resource[]): Promise<CollectionItemMap> {
    const items: ContentCollectionItem[] = [];
    for (const resource of itemResources) {
      if (resource) {
        const depotId = resource.relationships['depot'].data?.['id'];
        const browseContentRelationship = resource.relationships['content'];
        const browseContentId = browseContentRelationship.data?.['id'];
        const measurementName = this.formatMeasurementNamePipe.transform(browseContentId);
        const pakRef = resource.attributes?.['direct_pak_reference'];
        items.push({
          itemID: resource.id,
          depotID: depotId,
          measurementId: browseContentId,
          pakRef: pakRef,
          content: {
            measurementName: measurementName,
            measurementBrowseUrl: browseContentRelationship.links?.related.url.href ?? ''
          }
        });
        this.browseContentRelationshipCache[resource.id] = browseContentRelationship;
      }
    }
    return { id: collectionId, items: items };
  }

  async deleteContentCollection(collectionToDelete: ContentCollection) {
    await this.ensureContentCollectionListURL();
    const collectionUrl = new URL(collectionToDelete.guid, this.contentCollectionListURL);
    await this.context.deleteDocument(collectionUrl);
  }

  async editContentCollection(collectionToEdit: ContentCollection, name: string) {
    await this.ensureContentCollectionListURL();
    const collectionUrl = new URL(collectionToEdit.guid + '/', this.contentCollectionListURL);
    const data = {
      data: {
        id: collectionToEdit.guid,
        type: 'DepotContentCollection',
        attributes: {
          name: name
        }
      }
    };
    await this.context.patchDocument(collectionUrl, data);
  }

  async shareContentCollection(collectionToShare: ContentCollection, shareInfo) {
    await this.ensureContentCollectionListURL();

    const shareType = shareInfo.shareType === ContentCollectionShareType.User ? 'user_shares' : 'role_shares';
    const collectionUrl = new URL(`${collectionToShare.guid}/${shareType}/`, this.contentCollectionListURL);

    const payload = {
      data: {
        type:
          shareInfo.shareType === ContentCollectionShareType.User
            ? 'DepotContentCollection2UserSharing'
            : 'DepotContentCollection2RoleSharing',
        attributes: {
          may_add_items: shareInfo.canEdit,
          may_remove_items: shareInfo.canEdit,
          valid_until: shareInfo.validUntil || null
        },
        relationships:
          shareInfo.shareType === ContentCollectionShareType.User
            ? {
                shared_with_user: { data: { id: shareInfo.shareTo, type: 'UserBasicInfo' } }
              }
            : {
                shared_with_role: { data: { id: shareInfo.shareTo, type: 'RoleBasicInfo' } }
              }
      }
    };

    const data = (await this.context.postDocument(collectionUrl, payload)).data as Spec.ResourceObject;

    const newShare = {
      id: data?.id,
      shareType: shareInfo.shareType,
      sharedWith: shareInfo.shareTo,
      canAddItems: shareInfo.canEdit,
      canRemoveItems: shareInfo.canEdit,
      validUntil: shareInfo.validUntil
    };

    return newShare;
  }

  async deleteContentCollectionShare(shareInfo: ContentCollectionShareInfo) {
    await this.ensureContentCollectionListURL();

    const shareType = shareInfo.shareType === ContentCollectionShareType.User ? 'user_shares' : 'role_shares';
    const shareUrl = new URL(`${shareType}/${shareInfo.id}`, this.contentCollectionListURL);

    await this.context.deleteDocument(shareUrl);
  }

  async editContentCollectionShare(collectionToEdit: ContentCollection, shareInfo: ContentCollectionShareInfo) {
    await this.ensureContentCollectionListURL();

    const shareType = this.isUserShare(shareInfo) ? 'user_shares' : 'role_shares';
    const shareUrl = new URL(`${shareType}/${shareInfo.id}`, this.contentCollectionListURL);

    console.log(shareInfo);

    const payload = {
      data: {
        id: shareInfo.id,
        type: this.isUserShare(shareInfo) ? 'DepotContentCollection2UserSharing' : 'DepotContentCollection2RoleSharing',
        attributes: {
          may_add_items: shareInfo.canAddItems,
          may_remove_items: shareInfo.canRemoveItems,
          valid_until: shareInfo.validUntil || null
        },
        shared_with_user: this.isUserShare(shareInfo)
          ? {
              data: {
                id: shareInfo.sharedWith,
                type: 'UserBasicInfo'
              }
            }
          : null,
        shared_with_role: !this.isUserShare(shareInfo)
          ? {
              data: {
                id: shareInfo.sharedWith,
                type: 'RoleBasicInfo'
              }
            }
          : null
      }
    };

    await this.context.patchDocument(shareUrl, payload);
  }

  async declineContentCollectionShare(collectionId: string) {
    const appStart = await this.ensureDepotAppStart();

    if (!appStart) {
      return [];
    }

    const shareDeclineUrl =
      appStart?.relationships?.['content_collection_sharing_declines']?.links?.related?.url ?? new URL('');
    const data = {
      data: {
        type: 'DepotContentCollectionSharingDecline',
        relationships: {
          content_collection: {
            data: {
              id: collectionId,
              type: 'DepotContentCollection'
            }
          }
        }
      }
    };

    await this.context.postDocument(shareDeclineUrl, data);
  }

  private isUserShare(shareInfo: ContentCollectionShareInfo): boolean {
    return shareInfo.shareType === ContentCollectionShareType.User;
  }

  async addItemToContentCollection(
    collectionItem: ContentCollectionItem,
    collectionGuid: string
  ): Promise<ContentCollectionItem> {
    await this.ensureContentCollectionListURL();
    const contentCollectionItemURL = new URL(collectionGuid.concat('/items/'), this.contentCollectionListURL);

    if (!collectionItem.measurementId || collectionItem.measurementId === '') {
      throw new Error('Adding measurement items to a content collection requires a measurement id.');
    }
    const addRequest: Spec.ClientJsonApiDocument = this.createAddItemRequest(collectionItem);
    const response = await this.context.postDocument(contentCollectionItemURL, addRequest);
    const newItem = this.createCloudCollectionItem(collectionItem, response);
    if (!newItem) {
      throw new Error('POST response for a new content collection item did not include a JSON API resource.');
    }
    return newItem;
  }

  async addMultipleItemsToContentCollection(
    collectionItems: ContentCollectionItem[],
    collectionGuid: string
  ): Promise<ContentCollectionItem[]> {
    await this.ensureContentCollectionListURL();
    const contentCollectionItemURL = new URL(collectionGuid.concat('/items/'), this.contentCollectionListURL);

    // POST all content collections via parallel requests
    const allItems = await Promise.all(
      collectionItems.map((item) => {
        if (!item.measurementId || item.measurementId === '') {
          return undefined;
        }
        const addRequest: Spec.ClientJsonApiDocument = this.createAddItemRequest(item);
        return this.context.postDocument(contentCollectionItemURL, addRequest).then((response) => {
          return this.createCloudCollectionItem(item, response);
        });
      })
    );
    const allNewItems = allItems.filter((item) => item !== undefined) as ContentCollectionItem[];
    return allNewItems;
  }

  private createCloudCollectionItem(
    desiredCollectionItem: ContentCollectionItem,
    postResponse: Spec.JsonApiDocument
  ): ContentCollectionItem | undefined {
    const newItemResource = new JsonApi.Document(postResponse, this.context).resource;
    if (!newItemResource) {
      return undefined;
    }

    this.browseContentRelationshipCache[newItemResource.id] = newItemResource.relationships['content'];
    return {
      itemID: newItemResource.id,
      depotID: desiredCollectionItem.depotID,
      measurementId: desiredCollectionItem.measurementId,
      pakRef: newItemResource.attributes?.['direct_pak_reference'],
      content: {
        depot: desiredCollectionItem.content.depot,
        job: desiredCollectionItem.content.job,
        measurementBrowseUrl: desiredCollectionItem.content.measurementBrowseUrl,
        measurementName: desiredCollectionItem.content.measurementName
      }
    };
  }

  async deleteItemFromContentCollection(itemToDelete: ContentCollectionItem, collectionGuid: string) {
    await this.ensureContentCollectionListURL();
    const contentCollectionItemURL = new URL(
      collectionGuid.concat('/items/').concat(itemToDelete.itemID).concat('/'),
      this.contentCollectionListURL
    );
    await this.context.deleteDocument(contentCollectionItemURL);
  }

  createNewCollectionRequest(newCollection: ContentCollection): Spec.ClientJsonApiDocument {
    const request = new JsonApi.ClientDocument('DepotContentCollection');
    request.setAttribute('name', newCollection.name);
    return request.data;
  }

  createAddItemRequest(item: ContentCollectionItem): Spec.ClientJsonApiDocument {
    const request = new JsonApi.ClientDocument('DepotContentCollectionItem');
    const depot: Spec.ResourceIdentifierObject = { id: item.depotID, type: 'Depot' };
    request.setRelationship('depot', depot);
    const content: Spec.ResourceIdentifierObject = { id: item.measurementId!, type: 'DepotBrowseContent' };
    request.setRelationship('content', content);
    return request.data;
  }
}
