import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'measurementAttributeFilter'
})
export class MeasurementAttributeFilterPipe implements PipeTransform {
  transform(attributes: any): any {
    return attributes ? attributes.filter((attribute) => attribute.value !== '') : null;
  }
}
