import { createAction, props } from '@ngrx/store';
import { AttributeGroup, DepotAttribute, DepotAttributesIdCache, SemanticDepotAttribute } from './attributes.types';

export enum AttributesActionTypes {
  DepotAttributesLoaded = '[Attributes] Loaded depot attributes needed for displaying orders',
  FetchDepotAttributesAndSemantics = '[Attributes] Fetch Depot Attributes and Semantic Depot Attributes (which include translations)',
  DepotAttributesAndSemanticsReceived = '[Attributes] Depot Attributes and Semantic Depot Attributes Received',
  FillDepotAttributesCache = '[Attributes] Fill DepotAttributes Cache'
}

export const DepotAttributesLoaded = createAction(
  AttributesActionTypes.DepotAttributesLoaded,
  props<{ cache: DepotAttributesIdCache }>()
);

export const FetchDepotAttributesAndSemantics = createAction(AttributesActionTypes.FetchDepotAttributesAndSemantics);

export const DepotAttributesAndSemanticsReceived = createAction(
  AttributesActionTypes.DepotAttributesAndSemanticsReceived,
  props<{ attribs: DepotAttribute[]; semantics: SemanticDepotAttribute[]; semanticGroups: AttributeGroup[] }>()
);

export const FillDepotAttributesCache = createAction(
  AttributesActionTypes.FillDepotAttributesCache,
  props<{ attribs: DepotAttribute[] }>()
);
