import { createAction, props } from '@ngrx/store';
import { GroupInfo } from '../../types/group.types';

export enum GroupActionTypes {
  LoadGroups = '[Groups] Load Groups',
  GroupsLoaded = '[Groups] Groups Loaded'
}

export const LoadGroups = createAction(GroupActionTypes.LoadGroups);
export const GroupsLoaded = createAction(GroupActionTypes.GroupsLoaded, props<{ groups: GroupInfo[] }>());
