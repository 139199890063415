import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect, concatLatestFrom } from '@ngrx/effects';

import { map, catchError, withLatestFrom, tap, switchMap, filter } from 'rxjs/operators';
import { from } from 'rxjs';
import isValid from 'date-fns/isValid';

import { createAppError, createAppErrorPayload } from '../../app.factories';
import {
  AvailableDepotSearchQueryParamIdentifiers,
  DepotSearchRouterEffects
} from '../../router/+state/depotsearch-router.effects';
import { DepotSearchPartialState } from './depot-search.reducer';
import { defaultSearchParams, DepotSearchParameters } from '../depot-search.types';
import { DepotSearchService } from '../depot-search.service';
import { DepotSearchFacade } from './depot-search.facade';
import { areAttributesEqual } from '../../shared/utility-functions/search.helpers';
import { addFinishedMeasurementConstraint } from '../../shared/utility-functions/search.meatemplate.helpers';

import * as DSActions from './depot-search.actions';
import * as AppActions from '../../+state/app.actions';
import * as AttributesActions from '../../shared/+state/attributes/attributes.actions';

import { v4 as uuid } from 'uuid';
import { AppError } from '../../+state/app.actions';
import { Store } from '@ngrx/store';
import { FormatMeasurementNamePipe } from '../../shared/pipes/format-measurement-name.pipe';
import { FeatureFlagsFacade } from '@vas/feature-flags';
import { NavigationFacade, NavigationOptions } from '../../router/+state/navigation.facade';
import { pendingDeletionAttrId, ResultColumn, SortAttributeAndValue } from '../../shared/types/search.types';
import { CloudAppNames } from '../../app.types';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { getFlatSearchFilters } from '../../order-management/order.service.helpers';
import {
  Aggregate,
  AggregateTree,
  AggregateTreeDef,
  AggregateType,
  TreeNode
} from '../../shared/types/aggregateTree.types';
import {
  findAggregateById,
  isValidAggregateTreeSortingMethod,
  isValidAggregateType
} from '../../shared/utility-functions/aggregateTree.helpers';
import { fetch } from '@nrwl/angular';
import {
  DepotAttribute,
  isDepotAttribute,
  isSemanticAttribute,
  SearchAttributeAndValue,
  SearchAttributeCompound,
  SearchFilter,
  SemanticDepotAttribute
} from '../../shared/+state/attributes/attributes.types';
import { AttributesFacade } from '../../shared/+state/attributes/attributes.facade';
import {
  LS_USER_AGGREGATE_TREE_SORT_METHOD,
  LS_USER_AGGREGATE_TREE_TYPE,
  LS_USER_SAVED_COLUMNS,
  LS_USER_SEARCH_AGGREGATE_TREE_WIDTH
} from '../../app.constants';
import { OrderSearchParameters } from '../../order-management/order-search.types';
import { featureFlagDefaults } from '../../app.feature-flags';

export const USER_SAVED_COLUMNS_DELIMITER = '|'; // delimiter to separate user's table columns in localStorage
const defaultResultColumnIds = ['hrchypak.Subtitle.name', 'hrchypak.Job.name', 'hrchypak.Project.name', 'depot_name'];

@Injectable()
export class DepotSearchEffects {
  constructor(
    public featureFlagFacade: FeatureFlagsFacade,
    private actions$: Actions,
    private depotSearchService: DepotSearchService,
    private depotSearchFacade: DepotSearchFacade,
    private attributesFacade: AttributesFacade,
    private store: Store,
    private formatMeasurementNamePipe: FormatMeasurementNamePipe,
    private navigationFacade: NavigationFacade
  ) {}

  fetchDepotAttributesAndSemanticsOnDepotAppAvailable = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.AvailableAppsReceived),
        filter((apps) => apps.availableApps.includes(CloudAppNames.depot)),
        map(() => this.attributesFacade.fetchDepotAttributesAndSemantics()),
        catchError((error, caught) => {
          this.store.dispatch(createAppError(error));
          return caught;
        })
      ),
    { dispatch: false }
  );

  sortParams$ = this.depotSearchFacade.sort$.pipe(
    withLatestFrom(this.attributesFacade.availableDepotAttributes$),
    map(([sort, depotAttributes]) => {
      const sortParams: SortAttributeAndValue[] = [];
      sort.forEach((sortDef) => {
        if (isDepotAttribute(sortDef.attribute)) {
          sortParams.push({
            id: uuid(),
            depot_attribute: sortDef.attribute,
            attribute: sortDef.direction
          });
        } else {
          sortDef.attribute.relatedDepotAttributeIds.forEach((relatedDepotAttributeId) => {
            const relatedDepotAttribute = depotAttributes?.find((attrib) => attrib.idName === relatedDepotAttributeId);
            if (relatedDepotAttribute) {
              sortParams.push({
                id: uuid(),
                depot_attribute: relatedDepotAttribute,
                attribute: sortDef.direction
              });
            }
          });
        }
      });
      return sortParams;
    })
  );

  triggerSearchOnRestrictCCChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.RestrictToActiveCC),
      withLatestFrom(this.depotSearchFacade.lastSearchParams$),
      switchMap(([action, lastSearchParams]) => {
        const searchParameters: DepotSearchParameters = {
          ...structuredClone(lastSearchParams),
          restrictToActiveCC: action.restrictToActiveCC
        };

        return [DSActions.SetLastSearchParameters({ params: searchParameters }), DSActions.PerformSearch({})];
      })
    )
  );

  performSearchAndSetLastSearchParameters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DSActions.PerformSearch,
        DSActions.PerformSearchOnInitialNavigation,
        DSActions.PerformSearchOnPopState,
        DSActions.PerformSearchOnRouteChange
      ),
      withLatestFrom(
        this.depotSearchFacade.searchText$,
        this.depotSearchFacade.searchBarFilters$,
        this.sortParams$,
        this.depotSearchFacade.lastSearchParams$
      ),
      switchMap(([_, searchText, searchBarFilters, sortParams, lastSearchParams]) => {
        let searchFilters: SearchFilter = searchBarFilters;
        if (searchFilters.length > 1) {
          searchFilters = [
            {
              type: 'and',
              filters: searchFilters
            }
          ];
        }

        const searchParameters: DepotSearchParameters = {
          ...structuredClone(lastSearchParams),
          text: searchText,
          searchFilters: searchFilters,
          resultColumns: [],
          aggregateTreeDefs: [],
          sortFilter: sortParams
        };
        return [
          DSActions.AddExtraFiltersToSearch({ params: searchParameters, isNewSearch: true }),
          DSActions.SetLastSearchParameters({ params: searchParameters })
        ];
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  modifySearchText$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DSActions.ModifySearchText),
        withLatestFrom(this.depotSearchFacade.searchInputModified$, this.depotSearchFacade.searchText$),
        filter(([action, _, searchTextInStore]) => action.value !== searchTextInStore), // otherwise on first blur in depotsearch the url is updated (instead of e.g. opening an attribute dropdown)
        tap(([action, isModified, _]) => {
          const config: NavigationOptions = {
            queryParams: {
              [AvailableDepotSearchQueryParamIdentifiers.SearchText]: action.value
            },
            queryParamsHandling: 'merge'
          };
          if (isModified) {
            config.replaceUrl = true;
          }
          this.navigationFacade.navigate(config);
        })
      ),
    { dispatch: false }
  );

  addSearchBarFilter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DSActions.AddSearchBarFilter),
        withLatestFrom(
          this.attributesFacade.availableDepotAttributes$,
          this.attributesFacade.availableSemanticDepotAttributes$,
          this.depotSearchFacade.searchBarFilters$
        ),
        map(([action, availableDepotAttrs, availableSemanticDepotAttrs, curSarchBarFilters]) => {
          const attribAndValue = action.attribAndValue;
          const availableDepotAttributes: DepotAttribute[] = availableDepotAttrs ?? [];
          const availableSemanticDepotAttributes: SemanticDepotAttribute[] = availableSemanticDepotAttrs ?? [];
          const newSearchBarFilters = JSON.parse(JSON.stringify(curSarchBarFilters));

          let attributeExists = false;
          if (isDepotAttribute(attribAndValue.attribute) && availableDepotAttributes) {
            const depotAttribute = attribAndValue.attribute;
            attributeExists = availableDepotAttributes.some((x) => x.idName === depotAttribute.idName);
          } else if (isSemanticAttribute(attribAndValue.attribute) && availableSemanticDepotAttributes) {
            const semanticDepotAttribute = attribAndValue.attribute;
            attributeExists = availableSemanticDepotAttributes.some((x) => x.id === semanticDepotAttribute.id);
          }

          if (attributeExists) {
            const newAttributeAndValue: SearchAttributeAndValue = {
              id: attribAndValue.id,
              attribute: attribAndValue.attribute,
              searchAttributeValue: attribAndValue.searchAttributeValue,
              exact_match: attribAndValue.exact_match
            };

            if (attribAndValue.attribute.type === 'Boolean') {
              newAttributeAndValue.searchAttributeBoolean = false;
            }
            if (attribAndValue.attribute.type === 'Date' && attribAndValue.searchAttributeValue) {
              const date = new Date(attribAndValue.searchAttributeValue);
              if (isValid(date)) {
                newAttributeAndValue.searchAttributeStart = date.toISOString();
                newAttributeAndValue.searchAttributeEnd = date.toISOString();
              }
            }
            if (attribAndValue.attribute.type === 'Boolean' && attribAndValue.searchAttributeValue) {
              newAttributeAndValue.searchAttributeBoolean =
                attribAndValue.searchAttributeValue.toLowerCase() === 'true';
            }

            const isDuplicateSearchBar = newSearchBarFilters.findIndex((x) =>
              areAttributesEqual(x.attribute, attribAndValue.attribute)
            );
            if (isDuplicateSearchBar === -1) {
              newSearchBarFilters.unshift(newAttributeAndValue);
            }
          }

          return newSearchBarFilters;
        }),
        withLatestFrom(this.depotSearchFacade.searchInputModified$),
        tap(([searchBarFilters, isModified]) => {
          const config: NavigationOptions = {
            queryParams: {
              [AvailableDepotSearchQueryParamIdentifiers.SearchAttribute]:
                DepotSearchRouterEffects.createSearchAttributesQueryParam(searchBarFilters)
            },
            queryParamsHandling: 'merge'
          };
          if (isModified) {
            config.replaceUrl = true;
          }
          this.navigationFacade.navigate(config);
        })
      ),
    { dispatch: false }
  );

  modifySearchBarFilter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DSActions.ModifySearchBarFilter),
        withLatestFrom(this.depotSearchFacade.searchBarFilters$),
        map(([action, searchBarFilters]) => {
          const newSearchBarFilters = JSON.parse(JSON.stringify(searchBarFilters));
          const modifiedFilter = JSON.parse(
            JSON.stringify(newSearchBarFilters.find((x) => x.id === action.attribAndValue.id))
          );
          return [action, newSearchBarFilters, modifiedFilter];
        }),
        filter(([action, searchBarFilters, modifiedFilter]) => modifiedFilter !== undefined),
        map(([action, searchBarFilters, modifiedFilter]) => {
          let changeCount = 0;
          const index = searchBarFilters.findIndex((x) => x.id === action.attribAndValue.id);
          const attribAndValue = action.attribAndValue;

          switch (attribAndValue.attribute.type) {
            case 'String':
              changeCount += modifiedFilter!.searchAttributeValue === attribAndValue.searchAttributeValue ? 0 : 1;
              changeCount += modifiedFilter!.exact_match === attribAndValue.exact_match ? 0 : 1;
              break;

            case 'Date':
              changeCount += modifiedFilter!.searchAttributeStart === attribAndValue.searchAttributeStart ? 0 : 1;
              changeCount += modifiedFilter!.searchAttributeEnd === attribAndValue.searchAttributeEnd ? 0 : 1;
              break;

            case 'Boolean':
              changeCount += modifiedFilter!.searchAttributeBoolean === attribAndValue.searchAttributeBoolean ? 0 : 1;
              break;
          }
          if (changeCount > 0) {
            searchBarFilters[index] = {
              ...modifiedFilter!,
              attribute: attribAndValue.attribute,
              searchAttributeValue: attribAndValue.searchAttributeValue,
              searchAttributeBoolean: attribAndValue.searchAttributeBoolean,
              searchAttributeStart: attribAndValue.searchAttributeStart,
              searchAttributeEnd: attribAndValue.searchAttributeEnd,
              exact_match: attribAndValue.exact_match
            };

            return searchBarFilters;
          }
        }),
        filter((searchBarFilters) => searchBarFilters !== undefined),
        withLatestFrom(this.depotSearchFacade.searchInputModified$),
        tap(([searchBarFilters, isModified]) => {
          const config: NavigationOptions = {
            queryParams: {
              [AvailableDepotSearchQueryParamIdentifiers.SearchAttribute]:
                DepotSearchRouterEffects.createSearchAttributesQueryParam(searchBarFilters)
            },
            queryParamsHandling: 'merge'
          };
          if (isModified) {
            config.replaceUrl = true;
          }
          this.navigationFacade.navigate(config);
        })
      ),
    { dispatch: false }
  );

  removeSearchBarFilter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DSActions.RemoveSearchBarFilter),
        withLatestFrom(this.depotSearchFacade.searchBarFilters$),
        map(([action, curSarchBarFilters]) => {
          const attribAndValue = action.attribAndValue;
          let newSearchBarFilters = JSON.parse(JSON.stringify(curSarchBarFilters));
          newSearchBarFilters = newSearchBarFilters.filter((currentAttrib) => currentAttrib.id !== attribAndValue.id);
          return newSearchBarFilters;
        }),
        withLatestFrom(this.depotSearchFacade.searchInputModified$),
        tap(([searchBarFilters, isModified]) => {
          const config: NavigationOptions = {
            queryParams: {
              [AvailableDepotSearchQueryParamIdentifiers.SearchAttribute]:
                DepotSearchRouterEffects.createSearchAttributesQueryParam(searchBarFilters)
            },
            queryParamsHandling: 'merge'
          };
          if (isModified) {
            config.replaceUrl = true;
          }
          this.navigationFacade.navigate(config);
        })
      ),
    { dispatch: false }
  );

  resetSearchBar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DSActions.ResetSearchBar),
        withLatestFrom(this.depotSearchFacade.searchInputModified$),
        tap(([searchBarFilters, isModified]) => {
          const config: NavigationOptions = {
            queryParams: {
              [AvailableDepotSearchQueryParamIdentifiers.SearchText]: '',
              [AvailableDepotSearchQueryParamIdentifiers.SearchAttribute]: []
            }
          };
          if (isModified) {
            config.replaceUrl = true;
          }
          this.navigationFacade.navigate(config);
        })
      ),
    { dispatch: false }
  );

  getAggregatesOnNewSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DSActions.PerformSearch,
        DSActions.PerformSearchOnInitialNavigation,
        DSActions.PerformSearchOnPopState,
        DSActions.PerformSearchOnRouteChange
      ),
      filter((action) => {
        if (action.type === DSActions.DepotSearchActionTypes.PerformSearch && action.suppressHierarchyTreeReload) {
          return false;
        } else {
          return true;
        }
      }),
      withLatestFrom(this.depotSearchFacade.aggregateTreeDefs$),
      map(([_, aggregateDefs]) => {
        return this.reduceAggsToOneLayer(aggregateDefs);
      }),
      map((reducedAggregateDefs) => DSActions.GetAggregates({ aggregateDefs: reducedAggregateDefs })),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  loadMoreResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.LoadMoreResults),
      withLatestFrom(this.depotSearchFacade.lastSearchParams$),
      map(([_, lastSearchParams]) => {
        const searchParameters: DepotSearchParameters = {
          ...lastSearchParams,
          description: 'PAK cloud WebApp: Load More DepotSearch Results',
          resultColumns: [],
          aggregateTreeDefs: []
        };
        return DSActions.AddExtraFiltersToSearch({ params: searchParameters, isNewSearch: false });
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  getAggregates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.GetAggregates),
      withLatestFrom(this.depotSearchFacade.lastSearchParams$),
      map(([action, lastSearchParams]) => {
        const searchParameters: DepotSearchParameters = {
          ...lastSearchParams,
          description: 'PAK cloud WebApp: Get Aggregates',
          searchLimit: 0,
          offset: 0,
          resultColumns: [],
          aggregateTreeDefs: action.aggregateDefs,
          sortFilter: undefined // Do not use sort filters from lastSearchParams
        };
        return DSActions.AddExtraFiltersToAggregates({ params: searchParameters });
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  fetchContentForNewColumn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.AddResultColumn),
      withLatestFrom(this.depotSearchFacade.lastSearchParams$),
      map(([action, lastSearchParams]) => {
        const newColumn = this.getColumnFromAttribute(action.attrib);
        const searchParameters: DepotSearchParameters = {
          ...lastSearchParams,
          description: 'PAK cloud WebApp: Fetch New DepotSearch Column Content',
          offset: 0,
          searchLimit: lastSearchParams.offset + lastSearchParams.searchLimit,
          resultColumns: [newColumn],
          aggregateTreeDefs: []
        };
        return DSActions.AddExtraFiltersToNewColumn({ params: searchParameters, column: newColumn });
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  loadChildAggregates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.LoadChildAggregates),
      withLatestFrom(this.depotSearchFacade.lastSearchParams$, this.depotSearchFacade.aggregateTreeDefs$),
      map(([action, lastSearchParams, aggregateTreeDefs]) => {
        const aggTreeDef = findChildAggregateDef(action.node.aggregate.guid, aggregateTreeDefs);

        let reducedAggTreeDef: AggregateTreeDef[] = [];
        if (aggTreeDef) {
          reducedAggTreeDef = this.reduceAggsToOneLayer([aggTreeDef]);
        }
        let searchParameters: DepotSearchParameters = {
          ...lastSearchParams,
          description: 'PAK cloud WebApp: Load Child Aggregates',
          searchLimit: 0,
          offset: 0,
          resultColumns: [],
          // aggregateTreeDefs: aggTreeDef ? [aggTreeDef] : []
          aggregateTreeDefs: reducedAggTreeDef
        };

        searchParameters = this.addTreeFilterToSearch(searchParameters, action.node);
        return DSActions.AddExtraFiltersToLoadChildAggs({ params: searchParameters, node: action.node });
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  addExtraSearchFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DSActions.AddExtraFiltersToSearch,
        DSActions.AddExtraFiltersToAggregates,
        DSActions.AddExtraFiltersToNewColumn,
        DSActions.AddExtraFiltersToLoadChildAggs
      ),
      withLatestFrom(this.depotSearchFacade.selectedTreeNode$, this.attributesFacade.availableDepotAttributes$),
      map(([action, selectedTreeNode, depotAttributes]) => {
        let paramsWithFilters: DepotSearchParameters = {
          ...action.params
        };
        if (
          selectedTreeNode &&
          action.type !== DSActions.DepotSearchActionTypes.AddExtraFiltersToAggregates &&
          action.type !== DSActions.DepotSearchActionTypes.AddExtraFiltersToLoadChildAggs
        ) {
          paramsWithFilters = this.addTreeFilterToSearch(paramsWithFilters, selectedTreeNode);
        }

        paramsWithFilters = addFinishedMeasurementConstraint(paramsWithFilters, depotAttributes ?? []);

        let result;
        switch (action.type) {
          case DSActions.DepotSearchActionTypes.AddExtraFiltersToSearch:
            result = DSActions.AddColumnsToSearch({ params: paramsWithFilters, isNewSearch: action.isNewSearch });
            break;
          case DSActions.DepotSearchActionTypes.AddExtraFiltersToAggregates:
            result = DSActions.SendAggregateRequest({ params: paramsWithFilters });
            break;
          case DSActions.DepotSearchActionTypes.AddExtraFiltersToNewColumn:
            result = DSActions.SendNewColumnRequest({ params: paramsWithFilters, column: action.column });
            break;
          case DSActions.DepotSearchActionTypes.AddExtraFiltersToLoadChildAggs:
            result = DSActions.SendChildAggregatesRequest({ params: paramsWithFilters, node: action.node });
            break;

          default:
            break;
        }

        return result;
      })
    )
  );

  addColumnsToSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.AddColumnsToSearch),
      concatLatestFrom(() => this.depotSearchFacade.resultColumns$),
      fetch({
        run: (action, resultColumns) => {
          const modifiedSearchParameters: DepotSearchParameters = {
            ...action.params,
            resultColumns: [...(resultColumns ?? [])]
          };
          return DSActions.AddPendingDeletionAttribute({
            params: modifiedSearchParameters,
            isNewSearch: action.isNewSearch
          });
        }
      })
    )
  );

  addPendingDeletionAttribute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.AddPendingDeletionAttribute),
      withLatestFrom(this.attributesFacade.availableDepotAttributes$),
      map(([action, attributes]) => {
        const modifiedSearchParameters: DepotSearchParameters = this.addPendingDeletionAttribute(
          action.params,
          attributes
        );
        return DSActions.SendSearchRequest({ params: modifiedSearchParameters, isNewSearch: action.isNewSearch });
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  sendSearchRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.SendSearchRequest),
      withLatestFrom(this.sortParams$),
      fetch({
        run: (action, sortParams) => {
          const searchParams: DepotSearchParameters = {
            ...action.params,
            sortFilter: sortParams
          };
          return from(this.depotSearchService.doSearch(searchParams)).pipe(
            map((result) =>
              DSActions.SearchResultReceived({ result, offset: action.params.offset, isNewSearch: action.isNewSearch })
            )
          );
        },
        onError: (action, error) => {
          return DSActions.SearchError({ error: createAppErrorPayload(error) });
        }
      })
    )
  );

  sendOrderSearchRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.SendOrderSearchRequest),
      withLatestFrom(this.sortParams$),
      fetch({
        run: (action, sortParams) => {
          const searchParams: OrderSearchParameters = {
            ...action.params,
            sortFilter: sortParams
          };

          return from(this.depotSearchService.doSearch(searchParams)).pipe(
            map((result) =>
              DSActions.OrderSearchResultReceived({
                result,
                offset: action.params.offset,
                isNewSearch: action.isNewSearch
              })
            )
          );
        },
        onError: (action, error) => {
          return DSActions.SearchError({ error: createAppErrorPayload(error) });
        }
      })
    )
  );

  sendAggregateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.SendAggregateRequest),
      concatLatestFrom(() => this.depotSearchFacade.aggregateTreeDefs$),
      fetch({
        run: (action: ReturnType<typeof DSActions.SendAggregateRequest>, aggregateTreeDefinitions) => {
          return from(this.depotSearchService.doSearch(action.params)).pipe(
            map((result) => result.aggregates ?? []),
            map((aggregates) => this.enrichWithChildStatusInfos(aggregates, aggregateTreeDefinitions)),
            map((aggregates) => DSActions.AggregatesReceived({ aggregateTrees: aggregates }))
          );
        },
        onError: (action: ReturnType<typeof DSActions.SendAggregateRequest>, error) => {
          return DSActions.AggregateError({ error: createAppErrorPayload(error) });
        }
      })
    )
  );

  sendNewColumnRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.SendNewColumnRequest),
      fetch({
        run: (action: ReturnType<typeof DSActions.SendNewColumnRequest>) => {
          return from(this.depotSearchService.doSearch(action.params)).pipe(
            map((result) => DSActions.ColumnContentReceived({ result: result, column: action.column }))
          );
        },
        onError: (action: ReturnType<typeof DSActions.SendNewColumnRequest>, error) => {
          return DSActions.NewColumnError({ error: createAppErrorPayload(error), column: action.column });
        }
      })
    )
  );

  sendChildAggregatesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.SendChildAggregatesRequest),
      fetch({
        run: (action: ReturnType<typeof DSActions.SendChildAggregatesRequest>) => {
          return from(this.depotSearchService.doSearch(action.params)).pipe(
            map((result) => {
              if (result.aggregates && result.aggregates.length === 1 && result.aggregates[0].nodes) {
                const children = result.aggregates[0].nodes;
                return DSActions.ChildAggregatesLoaded({ children: children, node: action.node });
              }
              const error = {
                message: 'Error while re-loading child nodes',
                translationKey: 'DEPOTSEARCH.LOADCHILDAGGREGATESERROR'
              };
              return DSActions.LoadChildAggregatesError({ error: createAppErrorPayload(error), node: action.node });
            })
          );
        },

        onError: (action: ReturnType<typeof DSActions.SendChildAggregatesRequest>, error) => {
          return DSActions.LoadChildAggregatesError({ error: createAppErrorPayload(error), node: action.node });
        }
      })
    )
  );

  reselectTreeNodeOnNewSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.AggregatesReceived),
      withLatestFrom(this.depotSearchFacade.selectedTreeNode$, this.depotSearchFacade.lazyLoadedNodes$),
      map(([action, previousSelectedNode, lazyLoadedNodes]) => {
        if (!previousSelectedNode) {
          return DSActions.ResetLazyLoadedNodesList();
        }
        const foundNode = this.findNodeInTrees(action.aggregateTrees, previousSelectedNode);
        if (foundNode) {
          return DSActions.UpdateSelectedNode({ node: foundNode });
        }
        const foundInLazyLoadedNodes = this.findNode(previousSelectedNode!, lazyLoadedNodes);
        if (foundInLazyLoadedNodes) {
          return DSActions.TryReselectLazyLoadedNode({ node: foundInLazyLoadedNodes });
        }
        return DSActions.SelectTreeNode({ node: undefined });
      })
    )
  );

  changeSelectedTreeNode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.SelectTreeNode),
      withLatestFrom(this.depotSearchFacade.lastSearchParams$),
      map(([_, lastSearchParams]) => {
        const searchParams: DepotSearchParameters = {
          ...lastSearchParams,
          description: 'PAK cloud WebApp: Change Selected Tree Node',
          offset: 0,
          searchLimit: defaultSearchParams.searchLimit
        };
        return DSActions.AddExtraFiltersToSearch({ params: searchParams, isNewSearch: false });
      })
    )
  );

  tryReselectLazyLoadedNode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.TryReselectLazyLoadedNode),
      concatLatestFrom(() => [
        this.depotSearchFacade.lastSearchParams$,
        this.attributesFacade.availableDepotAttributes$,
        this.depotSearchFacade.aggregateTrees$,
        this.depotSearchFacade.lazyLoadedNodes$
      ]),
      fetch({
        run: (
          action: ReturnType<typeof DSActions.TryReselectLazyLoadedNode>,
          lastSearchParameter,
          availableDepotAttribs,
          aggregateTrees,
          lazyLoadedNodes
        ) => {
          if (action.node.parent) {
            const aggTreeDef: AggregateTreeDef = {
              nameTranslationKey: '',
              aggregate: action.node.aggregate
            };

            let searchParameters: DepotSearchParameters = {
              ...lastSearchParameter,
              searchLimit: 0,
              offset: 0,
              resultColumns: [],
              aggregateTreeDefs: [aggTreeDef]
            };
            searchParameters = this.addTreeFilterToSearch(searchParameters, action.node.parent);
            searchParameters = addFinishedMeasurementConstraint(searchParameters, availableDepotAttribs ?? []);

            return from(this.depotSearchService.doSearch(searchParameters)).pipe(
              map((result) => {
                if (result.aggregates?.length === 1 && result.aggregates[0].nodes) {
                  const nodes = result.aggregates[0].nodes;
                  const selectionPath = this.getTopLevelParent(action.node, [action.node]);
                  selectionPath?.forEach((node, index) => {
                    const foundPathNode1 = this.findNodeInTrees(aggregateTrees ?? [], node);
                    const foundPathNode2 = this.findNode(node, lazyLoadedNodes ?? []);

                    const foundPathNode = foundPathNode1 ?? foundPathNode2;

                    if (index < selectionPath.length - 1 || foundPathNode?.hasChildren) {
                      const child =
                        this.findNode(selectionPath[index], lazyLoadedNodes) ??
                        this.findNodeInTrees(aggregateTrees ?? [], selectionPath[index]);
                      if (child) {
                        this.depotSearchFacade.loadNodeChildren(child);
                      }
                    }
                  });

                  nodes.forEach((node) => (node.parent = selectionPath![selectionPath!.length - 2]));

                  const foundNode = this.findNode(action.node, nodes);

                  if (foundNode) {
                    return DSActions.LazyLoadedNodeReselected({
                      selectedNode: foundNode,
                      loadedNodes: nodes,
                      selectionPath: selectionPath
                    });
                  }
                }
                return DSActions.SelectTreeNode({ node: undefined }); // previously selected node was not found in new search result
              })
            );
          } else {
            return DSActions.SelectTreeNode({ node: undefined }); // previously selected node has no parent but was loaded lazily => should never occure
          }
        },

        onError: (action: ReturnType<typeof DSActions.TryReselectLazyLoadedNode>, error) => {
          return DSActions.SelectTreeNode({ node: undefined }); // could not re-select node due to error response from cloud
        }
      })
    )
  );

  initDefaults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttributesActions.DepotAttributesAndSemanticsReceived),
      switchMap((_: ReturnType<typeof AttributesActions.DepotAttributesAndSemanticsReceived>) => [
        DSActions.InitDefaultResultColumns(),
        DSActions.InitDefaultAggregateTrees()
      ])
    )
  );

  writeSearchTreeWidthToStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DSActions.SetAggregateTreeWidth),
        tap((action) => {
          localStorage.setItem(LS_USER_SEARCH_AGGREGATE_TREE_WIDTH, action.width.toString());
        }),
        catchError((error, caught) => {
          this.store.dispatch(createAppError(error));
          return caught;
        })
      ),
    { dispatch: false }
  );

  writeUserColumnToStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DSActions.AddResultColumn, DSActions.RemoveResultColumn, DSActions.NewColumnError),
        withLatestFrom(this.depotSearchFacade.resultColumns$),
        map(([_, columns]) => {
          this.writeUserColumnToStorage(columns);
        }),
        catchError((error, caught) => {
          this.store.dispatch(createAppError(error));
          return caught;
        })
      ),
    { dispatch: false }
  );

  initDefaultColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.InitDefaultResultColumns),
      withLatestFrom(this.attributesFacade.availableDepotAttributes$),
      map(([_, depotAttributes]) => {
        let columns: ResultColumn[] = [];
        if (depotAttributes) {
          columns = this.loadUserColumnsFromStorage(depotAttributes);
          if (columns.length === 0) {
            columns = this.loadDefaultColumns(depotAttributes);
          }
        }
        return DSActions.ResultColumnsInitialized({ columns: columns });
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  initDefaultAggregateTrees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.InitDefaultAggregateTrees),
      withLatestFrom(
        this.attributesFacade.availableDepotAttributes$,
        this.featureFlagFacade.featureValue$('AGGREGATE_SIZE')
      ),
      map(([_, depotAttributes, aggregateSize]) => {
        if (!aggregateSize) {
          // NOTE: This shouldn't happen, but if it does, we want to have a fallback
          // There are some edge cases where the initialization of the feature flags is not finished yet (moslty in unit tests)
          aggregateSize = featureFlagDefaults.find((flag) => flag.id === 'AGGREGATE_SIZE')?.value;
        }
        return DSActions.AggregateTreesInitialized({
          aggregateDefs: this.createDefaultAggregateTree(depotAttributes, aggregateSize)
        });
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  restoreSelectedAggregateTreeType$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DSActions.InitDefaultAggregateTrees),
        map((_) => {
          const userAggregateTreeType = localStorage.getItem(LS_USER_AGGREGATE_TREE_TYPE);
          if (userAggregateTreeType) {
            this.store.dispatch(
              DSActions.SetAggregateTreeSelectedType({ aggregateType: userAggregateTreeType as AggregateType })
            );
          }
        }),
        catchError((error, caught) => {
          this.store.dispatch(createAppError(error));
          return caught;
        })
      ),
    { dispatch: false }
  );

  writeSelectedAggregateTreeToLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DSActions.SetAggregateTreeSelectedType),
        map((action) => {
          localStorage.setItem(LS_USER_AGGREGATE_TREE_TYPE, action.aggregateType);
        }),
        catchError((error, caught) => {
          this.store.dispatch(createAppError(error));
          return caught;
        })
      ),
    { dispatch: false }
  );

  sortAggregateTrees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DSActions.AggregatesReceived,
        DSActions.SetAggregateTreeSortingMethod,
        DSActions.LazyLoadedNodeReselected,
        DSActions.ChildAggregatesLoaded,
        DSActions.SelectTreeNode
      ),
      map((_) => {
        return DSActions.SortAggregateTrees();
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  restoreAggregateTreeSortMethods$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DSActions.InitDefaultAggregateTrees),
        map((_) => {
          const received = localStorage.getItem(LS_USER_AGGREGATE_TREE_SORT_METHOD);
          if (received) {
            try {
              // Parse string-encoded object from LocalStorage
              const methods = JSON.parse(received);

              for (const aggregateType in methods) {
                if (aggregateType) {
                  // Check if parsed keys are valid AggregateTreeTypes
                  if (!isValidAggregateType(aggregateType)) {
                    throw new Error(`Sort Methods contained invalid aggregate type: ${aggregateType}`);
                  }

                  // Check if the value of the respective keys are valid SortMethods
                  if (!isValidAggregateTreeSortingMethod(methods[aggregateType])) {
                    throw new Error(`Found invalid sort method: ${methods[aggregateType]}`);
                  }
                }
              }
              this.store.dispatch(DSActions.RestoreActiveAggregateTreeSortingMethods({ methods: methods }));
              // eslint-disable-next-line no-empty
            } catch (error) {}
          }
        }),
        catchError((error, caught) => {
          this.store.dispatch(createAppError(error));
          return caught;
        })
      ),
    { dispatch: false }
  );

  writeSortAggregateTreeMethodsToLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DSActions.SetAggregateTreeSortingMethod),
        withLatestFrom(this.depotSearchFacade.activeAggregateTreeSorting$),
        map(([_, currentSorting]) => {
          // Note: Using the Action value itself is not enough, as the state may actually change differently for each run (directional toggle)
          localStorage.setItem(LS_USER_AGGREGATE_TREE_SORT_METHOD, JSON.stringify(currentSorting));
        }),
        catchError((error, caught) => {
          this.store.dispatch(createAppError(error));
          return caught;
        })
      ),
    { dispatch: false }
  );

  deleteMeasurement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.DeleteMeasurement),
      fetch({
        id: (action: ReturnType<typeof DSActions.DeleteMeasurement>, state: DepotSearchPartialState) =>
          action.measurement.browseUrl,
        run: (action: ReturnType<typeof DSActions.DeleteMeasurement>, state: DepotSearchPartialState) => {
          return from(this.depotSearchService.deleteMeasurement(action.measurement)).pipe(
            map((result) => DSActions.MeasurementDeleted({ measurement: action.measurement }))
          );
        },

        onError: (action: ReturnType<typeof DSActions.DeleteMeasurement>, error) => {
          return DSActions.DeleteMeasurementError({ measurement: action.measurement });
        }
      })
    )
  );

  passOnDeleteError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.DeleteMeasurementError),
      map((action: ReturnType<typeof DSActions.DeleteMeasurementError>) => {
        const measurementName = this.formatMeasurementNamePipe.transform(action.measurement.browseUrl);
        const error = {
          message: 'Cannot delete measurement ' + measurementName,
          translationKey: _('DEPOTSEARCH.DELETE_MEASUREMENT.ERROR'),
          translationParameter: { mea: measurementName }
        };
        return createAppError(error);
      })
    )
  );

  undeleteMeasurement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.UndeleteMeasurement),
      fetch({
        // Important: Keep 'id' here, otherwise the undelete actions will *NOT* run in parallel!
        id: (action: ReturnType<typeof DSActions.UndeleteMeasurement>, state: DepotSearchPartialState) =>
          action.measurement.browseUrl,
        run: (action: ReturnType<typeof DSActions.UndeleteMeasurement>, state: DepotSearchPartialState) => {
          return from(this.depotSearchService.undeleteMeasurement(action.measurement)).pipe(
            map((result) => DSActions.MeasurementUndeleted({ measurement: action.measurement }))
          );
        },

        onError: (action: ReturnType<typeof DSActions.UndeleteMeasurement>, error) => {
          if ('status' in error && error.status === 404) {
            // This means deletion has already been undone for this measurement => no error
            return DSActions.MeasurementUndeleted({ measurement: action.measurement });
          }
          return DSActions.UndeleteMeasurementError({ measurement: action.measurement });
        }
      })
    )
  );

  passOnUndeleteError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.UndeleteMeasurementError),
      map((action: ReturnType<typeof DSActions.UndeleteMeasurementError>) => {
        const measurementName = this.formatMeasurementNamePipe.transform(action.measurement.browseUrl);
        const error = {
          message: 'Cannot undo deletion of measurement ' + measurementName,
          translationKey: _('DEPOTSEARCH.UNDELETE_MEASUREMENT.ERROR'),
          translationParameter: { mea: measurementName }
        };
        return createAppError(error);
      })
    )
  );

  passOnErrors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DSActions.SearchError,
        DSActions.AggregateError,
        DSActions.NewColumnError,
        DSActions.LoadChildAggregatesError
      ),
      map((action) => AppError({ err: action.error }))
    )
  );

  triggerSearchOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DSActions.SearchForOrder),

      map((action) => {
        const params: OrderSearchParameters = JSON.parse(JSON.stringify(defaultSearchParams));

        params.description = 'Order Search for order ' + action.orderId;

        const orderIdentifier = 'descriptive_ancestors.aotest.order_iid.order_guid';
        const orderAttribute: DepotAttribute = {
          discriminator: 'DepotAttribute',
          idName: orderIdentifier,
          searchable: true,
          type: 'String'
        };
        const orderFilter: SearchAttributeAndValue = {
          id: uuid(),
          attribute: orderAttribute,
          searchAttributeValue: action.orderId,
          exact_match: true
        };

        const searchParameters: OrderSearchParameters = {
          ...params,
          searchFilters: [
            {
              type: 'and',
              filters: [orderFilter]
            }
          ]
        };

        const searchresult = DSActions.SendOrderSearchRequest({ params: searchParameters, isNewSearch: true });
        return searchresult;
      })
    )
  );

  reduceAggsToOneLayer(aggregateDefs: AggregateTreeDef[]): AggregateTreeDef[] {
    return aggregateDefs.map((agg) => {
      const reducedAggregate = JSON.parse(JSON.stringify(agg));
      if (reducedAggregate.aggregate.child) {
        reducedAggregate.aggregate.child = undefined;
      }
      return reducedAggregate;
    });
  }

  reduceAggsToTwoLayers(aggregateDefs: AggregateTreeDef[]): AggregateTreeDef[] {
    return aggregateDefs.map((agg) => {
      const reducedAggregate = JSON.parse(JSON.stringify(agg));
      if (reducedAggregate.aggregate.child?.child) {
        reducedAggregate.aggregate.child.child = undefined;
      }
      return reducedAggregate;
    });
  }

  addTreeFilterToSearch(searchParameters: DepotSearchParameters, selectedTreeNode: TreeNode): DepotSearchParameters {
    const modifiedSearchParameters: DepotSearchParameters = { ...searchParameters };
    const treeFilters = this.getTreeFilters(selectedTreeNode);
    const modifiedSearchFilters: SearchFilter = [
      {
        type: 'and',
        filters: treeFilters
      }
    ];

    if (searchParameters.searchFilters) {
      (modifiedSearchFilters[0] as SearchAttributeCompound).filters = getFlatSearchFilters(
        searchParameters.searchFilters.concat(treeFilters)
      );
    }

    modifiedSearchParameters.searchFilters = modifiedSearchFilters;
    return modifiedSearchParameters;
  }

  addPendingDeletionAttribute(
    searchParameters: DepotSearchParameters,
    allDepotAttributes: DepotAttribute[] | undefined
  ): DepotSearchParameters {
    const modifiedSearchParameters: DepotSearchParameters = { ...searchParameters };
    if (this.findDeletionAttrInColumns(searchParameters.resultColumns)) {
      return modifiedSearchParameters;
    }

    if (this.existDeletionAttr(allDepotAttributes)) {
      const pendingDeletionColumn: ResultColumn = {
        attribute: {
          discriminator: 'DepotAttribute',
          idName: pendingDeletionAttrId,
          type: 'Boolean',
          searchable: true
        },
        field: pendingDeletionAttrId,
        contentLoaded: false
      };
      modifiedSearchParameters.resultColumns = [...(searchParameters.resultColumns ?? []), pendingDeletionColumn];
    }
    return modifiedSearchParameters;
  }

  findDeletionAttrInColumns(resultColumns: ResultColumn[] | undefined): boolean {
    if (resultColumns) {
      return resultColumns.some((column) => {
        if (isDepotAttribute(column.attribute)) {
          return column.attribute.idName === pendingDeletionAttrId;
        }
        return false;
      });
    }
    return false;
  }

  existDeletionAttr(allDepotAttributes: DepotAttribute[] | undefined): boolean {
    return allDepotAttributes?.some((attr) => attr.idName === pendingDeletionAttrId) ?? false;
  }

  getColumnFromAttribute(attribute: DepotAttribute | SemanticDepotAttribute): ResultColumn {
    return {
      attribute: attribute,
      field: isDepotAttribute(attribute) ? attribute.idName : attribute.id
    };
  }

  writeUserColumnToStorage(columnsToSave: ResultColumn[] | undefined) {
    if (columnsToSave) {
      let stringToSave = '';
      for (const [index, column] of columnsToSave.entries()) {
        stringToSave = stringToSave + column.attribute.discriminator + column.field;
        if (index !== columnsToSave.length - 1) {
          stringToSave += USER_SAVED_COLUMNS_DELIMITER;
        }
      }
      if (stringToSave) {
        localStorage.setItem(LS_USER_SAVED_COLUMNS, stringToSave);
      }
    }
  }

  loadUserColumnsFromStorage(availableDepotAttributes: DepotAttribute[]): ResultColumn[] {
    const resultColumns: ResultColumn[] = [];
    const userSavedColumns = localStorage.getItem(LS_USER_SAVED_COLUMNS);
    if (userSavedColumns) {
      const columnStrings = userSavedColumns.split(USER_SAVED_COLUMNS_DELIMITER);
      for (const columnString of columnStrings) {
        if (columnString.startsWith('DepotAttribute')) {
          const columnIdName = columnString.substring('DepotAttribute'.length);
          const depotAttribute = availableDepotAttributes.find((attr) => attr.idName === columnIdName);
          if (depotAttribute) {
            resultColumns.push({ attribute: depotAttribute, field: columnIdName, contentLoaded: false });
          }
        } else if (columnString.startsWith('SemanticDepotAttribute')) {
          const columnId = columnString.substring('SemanticDepotAttribute'.length);
          const depotAttribute = availableDepotAttributes.find((attr) =>
            attr.semanticAttribute ? attr.semanticAttribute.id === columnId : false
          );
          if (depotAttribute?.semanticAttribute) {
            resultColumns.push({ attribute: depotAttribute.semanticAttribute, field: columnId, contentLoaded: false });
          }
        }
      }
    }
    return resultColumns;
  }

  loadDefaultColumns(availableDepotAttributes: DepotAttribute[]): ResultColumn[] {
    const defaultColumns: ResultColumn[] = [];
    if (availableDepotAttributes) {
      for (const columnId of defaultResultColumnIds) {
        const attribute = availableDepotAttributes.find((attr) => attr.idName === columnId);
        if (attribute) {
          if (attribute.semanticAttribute) {
            defaultColumns.push({
              attribute: attribute.semanticAttribute,
              field: attribute.semanticAttribute.id,
              contentLoaded: false
            });
          } else {
            defaultColumns.push({ attribute: attribute, field: attribute.idName, contentLoaded: false });
          }
        }
      }
    }
    return defaultColumns;
  }

  createDefaultAggregateTree(
    availableDepotAttrs: DepotAttribute[] | undefined,
    aggregateSize: [number, number, number]
  ): AggregateTreeDef[] {
    const treeDefs: AggregateTreeDef[] = [];
    if (availableDepotAttrs) {
      let hierarchyTreeDef: AggregateTreeDef | null = null;
      const defaultHierarchyAttributes = ['depot_name', 'hrchypak.Project.name', 'hrchypak.Job.name'];

      defaultHierarchyAttributes.forEach((defaultAttr, i) => {
        const foundAttribute = availableDepotAttrs.find((attr) => attr.idName === defaultAttr);
        if (foundAttribute) {
          if (!hierarchyTreeDef) {
            hierarchyTreeDef = {
              nameTranslationKey: _('DEPOTSEARCH.HIERARCHY'),
              aggregate: this.createAggregate('RequestedTermAggregation', foundAttribute, {
                size: aggregateSize[i]
              })
            };
          } else {
            const lastChildAggr = this.findLastChildAggr(hierarchyTreeDef);
            lastChildAggr.child = this.createAggregate('RequestedTermAggregation', foundAttribute, {
              size: aggregateSize[i]
            });
          }
        }
      });
      if (hierarchyTreeDef) {
        treeDefs.push(hierarchyTreeDef);
      }

      const defaultChronoAttr = 'measurement_properties.start_time';
      const foundChronoAttr = availableDepotAttrs.find((attr) => attr.idName === defaultChronoAttr);
      if (foundChronoAttr) {
        const chronologyTreeDef: AggregateTreeDef = {
          nameTranslationKey: _('DEPOTSEARCH.CHRONOLOGY'),
          aggregate: this.createAggregate('RequestedDateHistogramAggregation', foundChronoAttr, { interval: '1y' })
        };
        chronologyTreeDef.aggregate.child = this.createAggregate('RequestedDateHistogramAggregation', foundChronoAttr, {
          interval: '1M'
        });
        treeDefs.push(chronologyTreeDef);
      }
    }
    return treeDefs;
  }

  createAggregate(type: AggregateType, depotAttribute: DepotAttribute, aggAttribute: object): Aggregate {
    return {
      guid: uuid(),
      type: type,
      depotAttribute: depotAttribute,
      attributes: aggAttribute
    };
  }

  findLastChildAggr(treeDef: AggregateTreeDef): Aggregate {
    let nextAggr = treeDef.aggregate;
    while (nextAggr.child) {
      nextAggr = nextAggr.child;
    }
    return nextAggr;
  }

  findNodeInTrees(trees: AggregateTree[], node?: TreeNode): TreeNode | undefined {
    if (node) {
      for (const tree of trees) {
        const foundNode = this.findNode(node, tree.nodes);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return undefined;
  }

  findNodeInLazyLoadedTrees(nodes: TreeNode[], node?: TreeNode): TreeNode | undefined {
    if (node) {
      nodes.forEach((listNode) => {
        if (listNode.aggregate === node.parent?.aggregate) {
          return listNode;
        }
      });
    }
    return undefined;
  }

  findNode(nodeToFind: TreeNode, nodes?: TreeNode[]): TreeNode | undefined {
    if (nodes) {
      for (const currentNode of nodes) {
        if (this.haveSameValueAttributeAndParents(currentNode, nodeToFind)) {
          return currentNode;
        }
        const childNodeFound = this.findNode(nodeToFind, currentNode.children);
        if (childNodeFound) {
          return childNodeFound;
        }
      }
    }
    return undefined;
  }

  haveSameValueAttributeAndParents(node1: TreeNode, node2: TreeNode): boolean {
    const sameValueAndAttr =
      node1.originalValue === node2.originalValue &&
      node1.aggregate.depotAttribute.idName === node2.aggregate.depotAttribute.idName;
    if (!sameValueAndAttr) {
      return false;
    }
    // if ((node1.parent && !node2.parent) || (!node1.parent && node2.parent)) {
    //   return false;
    // }
    if (node1.parent && node2.parent) {
      return this.haveSameValueAttributeAndParents(node1.parent, node2.parent);
    }
    return true;
  }

  getTreeFilters(node: TreeNode): SearchAttributeAndValue[] {
    const treeFilters: SearchAttributeAndValue[] = [];
    const treeFilter = this.createSearchFilterFromNode(node);
    if (treeFilter) {
      treeFilters.push(treeFilter);
    }
    if (node.parent) {
      treeFilters.push(...this.getTreeFilters(node.parent));
    }
    return treeFilters;
  }

  getTopLevelParent(node: TreeNode, nodes: TreeNode[]): TreeNode[] | undefined {
    if (node.parent) {
      nodes.push(node.parent);
      return this.getTopLevelParent(node.parent, nodes);
    }
    return nodes.reverse();
  }

  getParent(node: TreeNode): TreeNode | undefined {
    return node.parent ?? undefined;
  }
  createSearchFilterFromNode(node: TreeNode): SearchAttributeAndValue | null {
    if (node.aggregate.type === 'RequestedTermAggregation') {
      return this.createTermFilter(node);
    }
    if (node.aggregate.type === 'RequestedDateHistogramAggregation') {
      return this.createDateRangeFilter(node);
    }
    return null;
  }

  createTermFilter(node: TreeNode): SearchAttributeAndValue | null {
    // Ignore tree nodes from aggregation buckets with missing value
    if (node.originalValue.toString() === '<N/A>') {
      return null;
    }
    return {
      id: uuid(),
      attribute: node.aggregate.depotAttribute,
      exact_match: true,
      searchAttributeValue: node.originalValue,
      searchAttributeBoolean: false
    };
  }

  createDateRangeFilter(node: TreeNode): SearchAttributeAndValue | null {
    const rangeStart = new Date(parseInt(node.originalValue, 10));
    const year = rangeStart.getFullYear();
    const month = rangeStart.getMonth();
    const day = rangeStart.getDate();
    const hours = rangeStart.getHours();
    const minutes = rangeStart.getMinutes();
    let rangeEnd: Date;
    if (node.aggregate.attributes?.['interval'] === '1y') {
      rangeEnd = new Date(year + 1, month, day, hours, minutes);
    } else if (node.aggregate.attributes?.['interval'] === '1M') {
      rangeEnd = new Date(year, month + 1, day, hours, minutes);
    } else {
      return null;
    }
    return {
      id: uuid(),
      attribute: node.aggregate.depotAttribute,
      exact_match: true,
      searchAttributeValue: '',
      searchAttributeStart: rangeStart.toISOString(),
      searchAttributeEnd: rangeEnd.toISOString(),
      searchAttributeBoolean: false
    };
  }

  enrichWithChildStatusInfos(trees: AggregateTree[], treeDefs: AggregateTreeDef[]): AggregateTree[] {
    const result: AggregateTree[] = [];
    for (const tree of trees) {
      const currentTreeDef = treeDefs.find(
        (treeDef) => treeDef.nameTranslationKey === tree.definition.nameTranslationKey
      );
      if (currentTreeDef) {
        const currentTree: AggregateTree = { ...tree };
        if (currentTree.nodes) {
          this.addChildStatusInfos(currentTree.nodes, currentTreeDef);
        }
        result.push(currentTree);
      }
    }
    return result;
  }

  addChildStatusInfos(nodes: TreeNode[], treeDef: AggregateTreeDef) {
    for (const node of nodes) {
      if (node.children) {
        this.addChildStatusInfos(node.children, treeDef);
      }
      const aggregateDef = findAggregateById(node.aggregate.guid, treeDef.aggregate);
      if (aggregateDef) {
        if (aggregateDef.child) {
          node.hasChildren = true;
          node.childrenLoadingStatus = 'init';
        } else {
          node.hasChildren = false;
          node.childrenLoadingStatus = 'done';
        }
      }
    }
  }
}

export const findChildAggregateDef = (
  aggToFindId: string,
  treeDefs: AggregateTreeDef[]
): AggregateTreeDef | undefined => {
  let aggTreeDef: AggregateTreeDef | undefined = undefined;
  for (const treeDef of treeDefs) {
    const foundAgg = findAggregateById(aggToFindId, treeDef.aggregate);
    if (foundAgg && foundAgg.child) {
      aggTreeDef = {
        nameTranslationKey: treeDef.nameTranslationKey,
        aggregate: foundAgg.child
      };
      break;
    }
  }
  return aggTreeDef;
};
