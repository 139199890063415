import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { UserPartialState } from './user.reducer';
import { userQuery } from './user.selectors';
import { LoadUserSelf, LoadMyLicenses, LoadUsersSelfCanSee, LoadRolesSelfCanSee } from './user.actions';

@Injectable()
export class UserFacade {
  self$ = this.store.pipe(select(userQuery.getUserSelf));
  usersSelfCanSee$ = this.store.pipe(select(userQuery.getUsersSelfCanSee));
  rolesSelfCanSee$ = this.store.pipe(select(userQuery.getRolesSelfCanSee));
  loaded$ = this.store.pipe(select(userQuery.getLoaded));
  groups$ = this.store.pipe(select(userQuery.getUserGroups));
  isAdmin$ = this.store.pipe(select(userQuery.getUserIsAdmin));
  runsWindows$ = this.store.pipe(select(userQuery.getUserRunsWindows));
  myLicenses$ = this.store.select(userQuery.getUserLicenses);

  constructor(private store: Store<UserPartialState>) {}

  getUserSelf() {
    this.store.dispatch(LoadUserSelf());
  }

  getUsersSelfCanSee() {
    this.store.dispatch(LoadUsersSelfCanSee());
  }

  getRolesSelfCanSee() {
    this.store.dispatch(LoadRolesSelfCanSee());
  }

  loadMyLicenses() {
    this.store.dispatch(LoadMyLicenses());
  }
}
