import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'cloud-measurement-tile-title',
  templateUrl: './measurement-tile-title.component.html',
  styleUrls: ['./measurement-tile-title.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('showHide', [
      state(
        'show',
        style({
          opacity: 1
        })
      ),
      state(
        'hide',
        style({
          opacity: 0
        })
      ),
      transition('show => hide', animate('200ms ease-out')),
      transition('hide => show', animate('200ms ease-in'))
    ])
  ]
})
export class MeasurementTileTitleComponent {
  @Input() title: string;
  @Input() selected = false;
  @Input() counter: number;
  @Input() loading = false;
  constructor() {}
}
