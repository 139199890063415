import { createReducer, Action } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';

import * as FeatureFlagsActions from './feature-flags.actions';

import { ConcreteFeatureFlag } from '@vas/feature-flags';
import { FeatureFlag, concreteFeatureFlagPrecedence } from '../feature-flags.types';
import { assembleValidatorsForFlag, validateFeatureFlagValue } from '../validators/feature-flags.validators';

export const FEATUREFLAGS_FEATURE_KEY = 'featureFlags';

export interface FeatureFlagState {
  availableFeatureFlags: FeatureFlag[];
  featureFlags: Partial<Record<string, ConcreteFeatureFlag>>;
}

export interface FeatureFlagsPartialState {
  readonly [FEATUREFLAGS_FEATURE_KEY]: FeatureFlagState;
}

export const initialState: FeatureFlagState = {
  availableFeatureFlags: [],
  featureFlags: {}
};

const warnDeprecated = (flagName: string, useInstead?: string) => {
  if (useInstead) {
    console.warn(`Feature Flag ${flagName} has been deprecated, please use ${useInstead} instead`);
  } else {
    console.warn(`Feature Flag ${flagName} has been deprecated without replacement`);
  }
};

const featureFlagsReducer = createReducer(
  initialState,
  immerOn(FeatureFlagsActions.SetAvailableFeatureFlags, (state, { featureFlags }) => {
    state.availableFeatureFlags = featureFlags;
  }),
  immerOn(FeatureFlagsActions.SetFeatureFlag, (state, { flagName, value, precedence }) => {
    const featureFlag = state.availableFeatureFlags.find((ff) => {
      let isFeatureFlag = ff.id === flagName;
      // check if flagName is listed in deprecated IDs
      if (!isFeatureFlag && ff.deprecatedIds?.includes(flagName)) {
        warnDeprecated(flagName, ff.id);
      }

      if (ff.isDeprecated) {
        warnDeprecated(flagName);
      }
      return isFeatureFlag;
    });
    if (featureFlag) {
      // Check if feature flag was previously set with a higher precedence
      const featureFlagInState = state.featureFlags[flagName];
      if (featureFlagInState) {
        const featureFlagExtractedPrecedenceRank = concreteFeatureFlagPrecedence.indexOf(precedence);
        const featureFlagInStatePrecedenceRank = concreteFeatureFlagPrecedence.indexOf(featureFlagInState?.precedence);
        if (featureFlagExtractedPrecedenceRank < featureFlagInStatePrecedenceRank) {
          return;
        }
      }

      const validators = assembleValidatorsForFlag(featureFlag);

      // Check if value is valid
      if (validators.length > 0) {
        const isValid = validateFeatureFlagValue(featureFlag, value, validators);
        if (!isValid) {
          console.warn(`Attempted to set Feature Flag ${flagName} to invalid value ${value}`);
          return;
        }
      }

      if (featureFlag.datatype === 'number') {
        value = Number(value);
      }

      state.featureFlags[flagName] = {
        ...featureFlag,
        value: value,
        precedence: precedence
      };

      if (featureFlag.suppressValueConsoleLog) {
        console.log(`Feature Flag ${flagName} was set via ${precedence}`);
      } else if (precedence !== 'default') {
        const valueIsStringOrNumber = typeof value === 'string' || typeof value === 'number';
        const displayValue = valueIsStringOrNumber ? value : JSON.stringify(value);
        console.log(`Feature Flag ${flagName} was set to ${displayValue} via ${precedence}`);
      }
    } else {
      console.warn(`Attempted to enable unknown Feature Flag: ${flagName}`);
    }
  }),
  immerOn(FeatureFlagsActions.PersistFeatureFlagAsCookie, (state, { flagName, value }) => {
    const featureFlag = state.availableFeatureFlags.find((ff) => ff.id === flagName);
    if (featureFlag) {
      state.featureFlags[flagName] = {
        ...featureFlag,
        value: value,
        id: flagName,
        precedence: 'cookie'
      };
    }
  }),
  immerOn(FeatureFlagsActions.ClearFeatureFlagCookie, (state, { flagName }) => {
    const featureFlag = state.featureFlags[flagName];
    if (featureFlag) {
      state.featureFlags[flagName] = {
        ...featureFlag,
        precedence: 'runtime'
      };
    }
  })
);

export function reducer(state: FeatureFlagState | undefined, action: Action) {
  return featureFlagsReducer(state, action);
}
