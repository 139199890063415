import { XOR } from '../../shared/types/utility.types';

export interface NumberFormatterOptions {
  value: number;
  options: {
    digits: XOR<SignificantDigitsNumberFormatterOptions, FixedDecimalDigitsNumberFormatterOptions>;
    scientificNotation?: boolean;
    returnAsNumber?: boolean;
  };
}

interface SignificantDigitsNumberFormatterOptions {
  significantDigits: number;
}

interface FixedDecimalDigitsNumberFormatterOptions {
  fixDecimalDigits: number;
  scientificNotation?: boolean;
}

export const numberFormatter = ({ value, options }: NumberFormatterOptions): string => {
  let result: string;

  if (value === 0) {
    return '0';
  } else if (options.digits.fixDecimalDigits !== undefined) {
    result = value.toFixed(options.digits.fixDecimalDigits);
    if (result === '-0') {
      result = '0';
    }
  } else {
    const digitOptions: SignificantDigitsNumberFormatterOptions =
      options.digits as SignificantDigitsNumberFormatterOptions;
    if (value >= 1 || value <= -1) {
      const isNegative = value < 0;
      value = Math.abs(value);

      const integerPartLength = Math.floor(Math.log10(value)) + 1;
      const decimalDigits = Math.max(0, digitOptions.significantDigits - integerPartLength);
      result = value.toFixed(decimalDigits);

      if (isNegative) {
        result = '-' + result;
      }
    } else {
      const d = Math.ceil(Math.log10(Math.abs(value)));
      const power = digitOptions.significantDigits - d;
      const magnitude = Math.pow(10, power);
      const shifted = Math.round(value * magnitude);
      const calculationResult = shifted / magnitude;
      result = options.scientificNotation ? calculationResult.toExponential() : calculationResult.toString();
    }
  }

  return result;
};

export const determineSignificantDigits = (value): number => {
  let result: number;

  if (value === 0) {
    result = 0;
  } else {
    const logValue = Math.log10(value);
    const absLogValue = Math.abs(logValue);
    const numDigits = Math.ceil(absLogValue);
    result = numDigits;
  }

  return result;
};

export const decimalPlaces = (num: number): number => {
  num = num - Math.floor(num); // remove integer part
  if (num === 0) {
    return 0;
  }
  return Math.ceil(Math.abs(Math.log10(Math.abs(num))));
};

// return fractionDigits Number of digits after the decimal point.
export const distinguishableValuesFractionDigitsForMinimalDelta = (value: number | undefined): number => {
  if (value !== undefined) {
    const absValue = Math.abs(value);
    if (absValue >= 10) {
      return 0;
    } else if (absValue >= 1) {
      return 1;
    } else if (absValue === 0) {
      return 3;
    }
    return decimalPlaces(absValue) + 1; // add 1 to the real decimal places to account for display precision
  } else {
    //num undefined
    return 3;
  }
};
