// common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSplitModule } from 'angular-split';
import { ClarityModule } from '@clr/angular';
import { FormsModule } from '@angular/forms';

// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  MEASUREMENTS_FEATURE_KEY,
  initialState as measurementInitialState,
  reducer as measurementsReducer
} from './+state/measurements.reducer';
import { MeasurementsEffects } from './+state/measurements.effects';
import { MeasurementsDownloadEffects } from './+state/measurements.download.effects';
import { MeasurementsMoveEffects } from './+state/measurements.move.effects';
import { MeasurementsFacade } from './+state/measurements.facade';
import { TranslateModule } from '@ngx-translate/core';
import { PlottingModule } from '../plotting/plotting.module';

// VAS
import { translateLoader } from '../app.translate';
import { MeasurementsComponent } from './measurements.component';
import { MeasurementDatasetDescriptionComponent } from './panels/measurement-dataset-description/measurement-dataset-description.component';
import { MeasurementOverviewComponent } from './panels/measurement-overview/measurement-overview.component';
import { MeasurementUnitsUnderTestComponent } from './panels/measurement-units-under-test/measurement-units-under-test.component';
import { MeasurementTestSequenceComponent } from './panels/measurement-testsequence/measurement-testsequence.component';
import { MeasurementTileComponent } from './tiles/measurement-tile/measurement-tile.component';
import { MeasurementTileTitleComponent } from './tiles/measurement-tile-title/measurement-tile-title.component';
import { MeasurementsDirective } from './measurements.directive';
import { SharedModule } from '../shared/shared.module';
import { MeasurementAttributeFilterPipe } from './pipes/measurementAttributeFilter.pipe';
import { MeasurementsPositionFilterPipe } from './pipes/measurementsPositionFilter.pipe';
import { DownloadComponent } from './download/download.component';
import { MeasurementsMoveModalComponent } from './measurementsMoveModal/measurementsMoveModal.component';
import { PathPillComponent } from './measurementsMoveModal/path-pill/path-pill.component';
import { ProcessingModule } from '../processing/processing.module';
import { StreamingModule } from '../streaming/streaming.module';

export const measurementsImports: any[] = [
  CommonModule,
  FormsModule,
  StoreModule.forFeature(MEASUREMENTS_FEATURE_KEY, measurementsReducer, {
    initialState: measurementInitialState
  }),
  EffectsModule.forFeature([MeasurementsEffects, MeasurementsDownloadEffects, MeasurementsMoveEffects]),
  TranslateModule.forChild(translateLoader),
  AngularSplitModule,
  PlottingModule,
  StreamingModule,
  ProcessingModule,
  SharedModule
];

export const measurementsDeclarations: any[] = [
  MeasurementsComponent,
  MeasurementDatasetDescriptionComponent,
  MeasurementOverviewComponent,
  MeasurementUnitsUnderTestComponent,
  MeasurementTestSequenceComponent,
  MeasurementTileComponent,
  MeasurementTileTitleComponent,
  MeasurementsDirective,
  MeasurementAttributeFilterPipe,
  MeasurementsPositionFilterPipe,
  DownloadComponent,
  MeasurementsMoveModalComponent,
  PathPillComponent
];

@NgModule({
  declarations: measurementsDeclarations,
  imports: [measurementsImports, ClarityModule],
  exports: [MeasurementsComponent, DownloadComponent, MeasurementsMoveModalComponent, PathPillComponent],
  providers: [MeasurementsFacade]
})
export class MeasurementsModule {}
