<div
  id="layout-megamenu"
  class="clr-col-12 layout-megamenu"
  [ngClass]="{
    'layout-megamenu-active': active
  }"
>
  <div class="clr-col-12"></div>
  <div class="clr-col-12 clr-col-md-6 clr-col-lg-3 layout-megamenu-submenu">
    <ul>
      <li *ngFor="let entry of model">
        <a [routerLink]="entry.routerLink" (click)="closeTrigger.emit(true)">
          <i [class]="entry.icon"></i>
          <span>{{ entry.label }}</span>
        </a>
      </li>
    </ul>
    <div class="layout-megamenu-footer">
      <div *ngIf="(appFacade.language$ | async) === 'en'">
        <a HREF="javascript:void(0)" (click)="appFacade.setLanguage('de')">Deutsch</a>
      </div>
      <div *ngIf="(appFacade.language$ | async) === 'de'">
        <a HREF="javascript:void(0)" (click)="appFacade.setLanguage('en')">English</a>
      </div>
      <div>
        <a [routerLink]="['/about']" (click)="closeTrigger.emit(true)">{{ 'APP.IMPRINT' | translate }}</a>
      </div>
      <div>
        <a [routerLink]="['/privacy']" (click)="closeTrigger.emit(true)">{{ 'APP.PRIVACY' | translate }}</a>
      </div>
      <div>
        <a [routerLink]="['/copyright']" (click)="closeTrigger.emit(true)">{{ 'APP.COPYRIGHT' | translate }}</a>
      </div>
      <div>
        <a [routerLink]="['/tpl']" (click)="closeTrigger.emit(true)">{{ 'APP.THIRDPARTYLICENSES' | translate }}</a>
      </div>
    </div>
  </div>
</div>
