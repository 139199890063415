export type PakRefAvailabilityState = 'available' | 'unavailable' | 'unknown';

export type ContentCollectionDialog =
  | 'createCollection'
  | 'createCollectionWithItems'
  | 'deleteCollection'
  | 'deleteItem'
  | 'editCollection';
export interface ContentCollection {
  name: string;
  guid: string;
  itemsLoaded: boolean;
  items?: ContentCollectionItem[];
  isShared?: boolean;
  myAccessInfo?: ContentCollectionAccessInfo;
  shareInfo?: ContentCollectionShareInfo[];
  createdBy?: string;
}

export interface ContentCollectionShareInfo extends ContentCollectionAccessInfo {
  id: string;
  shareType: ContentCollectionShareType;
  sharedWith: string;
}

export interface ContentCollectionAccessInfo {
  canAddItems: boolean;
  canRemoveItems: boolean;
  validUntil: string | null;
}

export enum ContentCollectionShareType {
  User,
  Role
}

export interface ContentCollectionItem {
  itemID: string;
  depotID: string;
  measurementId?: string;
  pakRef?: string;
  content: {
    depot?: string;
    project?: string;
    job?: string;
    measurementName?: string;
    measurementBrowseUrl: string;
    measurementExistanceState?: boolean;
    measurementStartTime?: string;
  };
}

export type ContentCollectionItemFromSearch = Omit<ContentCollectionItem, 'itemID'> & {};
