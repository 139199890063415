<cloud-svg-icon
  *ngIf="license.periodType === 'Monthly' && license.userType !== 'Automation'"
  fileName="calendar"
  height="24px"
  width="24px"
></cloud-svg-icon>

<cloud-svg-icon
  *ngIf="license.periodType === 'Daily' && license.userType !== 'Automation'"
  fileName="24"
  height="24px"
  width="24px"
></cloud-svg-icon>

<cloud-svg-icon *ngIf="license.userType === 'Automation'" fileName="robot" height="24px" width="24px"></cloud-svg-icon>
