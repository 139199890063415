import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StreamingState, STREAMING_FEATURE_KEY } from './streaming.reducer';
import { AvailableAxis } from '../plot-handlers/lightningPlot.types';

const getStreamingState = createFeatureSelector<StreamingState>(STREAMING_FEATURE_KEY);

const getCachedTokens = createSelector(getStreamingState, (state: StreamingState) => state.tokenContainers);

/* const getTokenRequestInFlight = createSelector(getTokenState, (state: StreamingState) => {
  return undefined; // state.tokenRequestInFlight;
});

const getLatestToken = createSelector(getTokenState, (state: StreamingState) => state.token);

const getSelectedDataset = createSelector(getTokenState, (state: StreamingState) => state.selectedDataset);

const getIsProcessing = createSelector(
  getTokenState,
  (state: StreamingState) => state.currentActiveRequestIsProcessing
);

const getIsStopStreaming = createSelector(getTokenState, (state: StreamingState) => state.doStopStreaming);

const getStreamingStatus = createSelector(getTokenState, (state: StreamingState) => state.streamingStatus); */

const getStreamingPackages = createSelector(getStreamingState, (state: StreamingState) => state.streamingPackages);
const getCurrentStreamingPackage = createSelector(getStreamingPackages, (state) => state.current);
const getCurrentStreamingPackageIsStreaming = createSelector(
  getStreamingPackages,
  (state) => state.current?.status === 'streaming'
);
const getStreamingPackageHistory = createSelector(getStreamingPackages, (state) => state.history);

// Interactions
const getInteractions = createSelector(getStreamingState, (state: StreamingState) => state.interactions);
const getAxisInteractions = createSelector(getInteractions, (state) => state.axis);

const getInteractionsForAxis = (axisType: AvailableAxis) =>
  createSelector(getAxisInteractions, (state) => state[axisType]);

const getAvailableInteractionsForAxis = (axisType: AvailableAxis) =>
  createSelector(getInteractionsForAxis(axisType), (state) => state.available);
const getSelectedInteractionForAxis = (axisType: AvailableAxis) =>
  createSelector(getInteractionsForAxis(axisType), (state) => state.selected);

const getFullSize = createSelector(getInteractions, (state) => state.fullSize);
const getRefreshAvailable = createSelector(getInteractions, (state) => state.refreshAvailable);
const getShowYBar = createSelector(getInteractions, (state) => state.showYBar);

const getThruputInteractions = createSelector(getInteractions, (state) => state.thruput);
const getThruputParameters = createSelector(getThruputInteractions, (state) => {
  return { start: state.start, end: state.end, plotCompressed: state.plotCompressed };
});

const getTrackInteractions = createSelector(getInteractions, (state) => state.track);
const getShowTrackSelection = createSelector(getTrackInteractions, (state) => state.showTrackSelection);
const getSelectableTracks = createSelector(getTrackInteractions, (state) => state.selectableTracks);
const getSelectedTracks = createSelector(getTrackInteractions, (state) => state.selectedTrack);

export const streamingQuery = {
  getCachedTokens,
  getCurrentStreamingPackage,
  getCurrentStreamingPackageIsStreaming,
  getStreamingPackageHistory,
  getInteractions,
  getAxisInteractions,
  getInteractionsForAxis,
  getAvailableInteractionsForAxis,
  getSelectedInteractionForAxis,
  getFullSize,
  getRefreshAvailable,
  getShowYBar,
  getThruputParameters,
  getShowTrackSelection,
  getSelectableTracks,
  getSelectedTracks
};
