import { Environment } from '@vas/common';
import { AppConfig } from '../app/app.types';

export const environment: Environment = {
  appVersion: require('../../../../package.json').version + '-dev',
  production: false
};

export const config: AppConfig = {
  baseHost: window.location.origin,
  useBasicAuth: false
};

let local: any;
try {
  local = require('@root/.local.json');
  config.useBasicAuth = local?.app?.useBasicAuth ?? false;
  if (config.useBasicAuth === true) {
    config.basicAuthCredentials = {
      user: local?.app?.credentials?.user ?? '',
      pw: local?.app?.credentials?.pw ?? ''
    };
  }
  // eslint-disable-next-line no-empty
} catch {}
