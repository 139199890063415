import { Spec } from '@muellerbbm-vas/grivet';
import {
  Aggregate,
  AggregateTreeDef,
  AggregateTreeSortingMethod,
  AggregateTreeSortingMethodNames,
  AggregateType
} from '../types/aggregateTree.types';
import { SortDirection } from '../types/sorting.types';

export const findAggregateDefForNode = (
  aggToFindId: string,
  treeDefs: AggregateTreeDef[]
): AggregateTreeDef | undefined => {
  let aggTreeDef: AggregateTreeDef;
  for (const treeDef of treeDefs) {
    const foundAgg = findAggregateById(aggToFindId, treeDef.aggregate);
    if (foundAgg) {
      aggTreeDef = {
        nameTranslationKey: treeDef.nameTranslationKey,
        aggregate: foundAgg
      };
      break;
    }
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: variable 'aggTreeDef' is used before being assigned
  return aggTreeDef;
};

export const findAggregateById = (aggToFindId: string, aggregate: Aggregate): Aggregate | undefined => {
  if (aggregate.guid === aggToFindId) {
    return aggregate;
  }
  return aggregate.child ? findAggregateById(aggToFindId, aggregate.child) : undefined;
};

export const isValidAggregateType = (potentialAggregateType: any): potentialAggregateType is AggregateType => {
  const validAggregateTypes = ['RequestedTermAggregation', 'RequestedDateHistogramAggregation'];
  return validAggregateTypes.includes(potentialAggregateType);
};

export const isValidAggregateTreeSortingMethod = (
  potentialSortingMethod: any
): potentialSortingMethod is AggregateTreeSortingMethod => {
  const validDirections: SortDirection[] = ['asc', 'desc'];
  const validMethods: AggregateTreeSortingMethodNames[] = ['natural', 'numDocs', 'date'];

  const isValidDirection =
    potentialSortingMethod.direction && validDirections.includes(potentialSortingMethod.direction);
  const isValidMethod = potentialSortingMethod.method && validMethods.includes(potentialSortingMethod.method);

  return isValidDirection && isValidMethod;
};

export const createAggregateResources = (aggregateTreeDefs: AggregateTreeDef[]): Spec.ResourceObject[] => {
  const aggregateRessources: Spec.ResourceObject[] = [];
  for (const def of aggregateTreeDefs) {
    addAggregateRessource(def.aggregate, aggregateRessources);
  }
  return aggregateRessources;
};

const addAggregateRessource = (aggregate: Aggregate, resources: Spec.ResourceObject[]) => {
  const resource: Spec.ResourceObject = { id: aggregate.guid, type: aggregate.type };
  resource.relationships = {
    depot_attribute: { data: { id: aggregate.depotAttribute.idName, type: 'DepotAttribute' } }
  };
  resource.attributes = aggregate.attributes;

  if (aggregate.child) {
    resource.relationships['child_aggregations'] = {
      data: [{ id: aggregate.child.guid, type: aggregate.child.type }]
    };
    addAggregateRessource(aggregate.child, resources);
  }
  resources.push(resource);
};
