import { DatasetDescription } from '../../measurements/measurements.types';
import { TokenContainer, TokenContainerID } from './streaming-token.types';

export enum RequestState {
  NONE,
  PENDING,
  SUCCESS,
  FAILURE
}

export enum StreamingType {
  TRACKING = 'Tracking',
  MEASUREMENT = 'Measurement',
  PROCESSING = 'Processing'
}

export enum StreamingMeasurementStatus {
  PREP = 'prep',
  READY = 'ready',
  STREAMING = 'streaming',
  COMPLETED = 'completed',
  ERROR = 'error'
}

export interface StreamingPackage {
  id: string;
  type: StreamingType;
  status: StreamingMeasurementStatus;
  tokenContainer: TokenContainer | null;
  datasetContainer: {
    dataset?: DatasetDescription;
    requestState: RequestState;
  };
}

export interface StreamingPackageOptions {
  streamingType: StreamingType;
  tokenContainerId?: TokenContainerID;
  dataset?: DatasetDescription;
}

// PROCESSING
export enum ProcessingStatus {
  PREP = 'prep',
  READY = 'ready',
  STREAMING = 'streaming',
  COMPLETED = 'completed',
  ERROR = 'error'
}
/* export interface ProcessingPackage extends StreamingPackage {

} */
