// Core Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { translateLoader } from '../app.translate';
import { DndModule } from 'ngx-drag-drop';
import { ClickOutsideModule } from 'ng-click-outside';

// Clarity
import { ClarityModule } from '@clr/angular';

// VAS Imports
import { AppMegamenuComponent } from './mega-menu/app.megamenu.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { SmileyComponent } from './feedback/smiley/smiley.component';
import { UserModule } from '../user/user.module';
import { ContentCollectionModule } from '../content-collection/content-collection.module';
import { DepotSearchModule } from '../depot-search/depot-search.module';
import { AppFacade } from '../+state/app.facade';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { DebugHeaderComponent } from './debug-header/debug-header.component';
import { MeasurementsModule } from '../measurements/measurements.module';
import { SharedModule } from '../shared/shared.module';
import { ContentCollectionDropdownComponent } from './top-menu/content-collection-dropdown/content-collection-dropdown.component';
import { HeaderActionsComponent } from './top-menu/header-actions/header-actions.component';
import { HeaderMoveOperationsComponent } from './top-menu/header-move-operations/header-move-operations.component';
import { LicenseHeaderComponent } from './top-menu/header-actions/license-header/license-header.component';
import { DownloadModalComponent } from './top-menu/download-modal/download-modal.component';
import { CcdCollectionListComponent } from './top-menu/content-collection-dropdown/ccd-collection-list/ccd-collection-list.component';
import { CcdMeasurementsTableComponent } from './top-menu/content-collection-dropdown/ccd-measurements-table/ccd-measurements-table.component';
import { CcdShareStepComponent } from './top-menu/content-collection-dropdown/ccd-share-step/ccd-share-step.component';
import { ShareAutocompleteComponent } from './top-menu/content-collection-dropdown/ccd-share-step/share-autocomplete/share-autocomplete.component';

@NgModule({
  providers: [AppFacade],
  declarations: [
    AppMegamenuComponent,
    TopMenuComponent,
    DebugHeaderComponent,
    FeedbackComponent,
    SmileyComponent,
    ContentCollectionDropdownComponent,
    HeaderActionsComponent,
    HeaderMoveOperationsComponent,
    LicenseHeaderComponent,
    DownloadModalComponent,
    CcdCollectionListComponent,
    CcdMeasurementsTableComponent,
    CcdShareStepComponent,
    ShareAutocompleteComponent
  ],
  imports: [
    CommonModule,
    UserModule,
    SharedModule,
    ContentCollectionModule,
    DepotSearchModule,
    MeasurementsModule,
    TranslateModule.forChild(translateLoader),
    FormsModule,
    ClarityModule,
    RouterModule.forChild([]),
    DndModule,
    ClickOutsideModule
  ],
  exports: [AppMegamenuComponent, TopMenuComponent]
})
export class MenusModule {}
