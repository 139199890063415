import { TranslateService } from '@ngx-translate/core';
import {
  CheckedProcessingParameter,
  FormulaCondition,
  FormulaOperation,
  ProcessingFormula,
  ProcessingInputStream,
  ProcessingParameter,
  ProcessingParameterId
} from './../processing.types';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export function createTooltip(
  parameters: ProcessingParameter[],
  translationService: TranslateService,
  parents?: string[]
): string {
  let tooltip;

  if (parents) {
    tooltip = `${translationService.instant(_('PROCESSING.INPUTDEPENDSON'))} `;

    parents.forEach((parent) => {
      const parametername = parameters.find((parameter) => {
        return parameter.id === parent;
      });
      if (parametername?.label) {
        tooltip = tooltip + parametername?.label + ', ';
      } else {
        tooltip = tooltip + parametername?.name + ', ';
      }
    });
    tooltip = tooltip.slice(0, -2);
    tooltip = tooltip + '.';
    tooltip.replace(/,(?=[^,]*$)/, ` ${translationService.instant(_('PROCESSING.AND'))} `);
  }
  return tooltip;
}

export const checkParam = (
  param: ProcessingParameter,
  formula: ProcessingFormula
): { fullfillment: boolean; relevantParent: string[] | undefined } => {
  if (!param.condition) {
    return { fullfillment: true, relevantParent: undefined };
  }

  const conditionsAreFullfilled = checkOperand(param.condition, formula);
  return conditionsAreFullfilled;
};

export const checkSource = (
  source: ProcessingInputStream,
  formula: ProcessingFormula
): { fullfillment: boolean; relevantParent: string[] | undefined } => {
  if (!source.condition) {
    return { fullfillment: true, relevantParent: undefined };
  }

  const conditionsAreFullfilled = checkOperand(source.condition, formula);
  return conditionsAreFullfilled;
};

export const isFormulaOperation = (operand: FormulaCondition | FormulaOperation): operand is FormulaOperation => {
  return (operand as FormulaOperation).operands !== undefined;
};

const checkOperand = (
  operand: FormulaOperation | FormulaCondition,
  formula: ProcessingFormula,
  parents?: string[]
): { fullfillment: boolean; relevantParent: string[] | undefined } => {
  let conditionsForThisOperandFulfilled = false;
  let localParents: string[] | undefined = [];
  if (isFormulaOperation(operand)) {
    // Operand is Operation
    const operation: FormulaOperation = operand as FormulaOperation;
    const check = operation.operands.map((operand2) => {
      return checkOperand(operand2, formula, parents);
    });

    localParents = check
      .filter((operand2) => operand2.fullfillment)
      .flatMap((tuple) => {
        if (tuple.relevantParent !== undefined) {
          return tuple.relevantParent;
        }
      }) as string[] | undefined;
    if (operation.operator === 'AND') {
      conditionsForThisOperandFulfilled = check.every((operand2) => operand2.fullfillment);
    } else {
      conditionsForThisOperandFulfilled = check.some((operand2) => operand2.fullfillment);
    }
  } else {
    // Operand is Condition
    const condition: FormulaCondition = operand;
    conditionsForThisOperandFulfilled = checkCondition(condition, formula);
    if (parents !== undefined) {
      parents.push(operand.reference);
    } else {
      parents = [operand.reference];
    }

    return { fullfillment: conditionsForThisOperandFulfilled, relevantParent: parents };
  }
  return { fullfillment: conditionsForThisOperandFulfilled, relevantParent: localParents };
};

const checkCondition = (condition: FormulaCondition, formula: ProcessingFormula): boolean => {
  const referencedParam = formula.parameters.find((currentParam) => currentParam.id === condition.reference);

  // Check conditions of referenced param
  let referencedParamVisibilityConditionFulfilled = true;
  if (referencedParam) {
    if (referencedParam.condition) {
      referencedParamVisibilityConditionFulfilled = checkParam(referencedParam, formula).fullfillment;
    }
  } else {
    console.error('Referenced unknown parameter: ', referencedParam);
  }

  // Check own conditions against referenced param
  const valueConditionFulfilled = checkConcreteConditionValue(condition, referencedParam?.value);

  return valueConditionFulfilled && referencedParamVisibilityConditionFulfilled;
};

const checkConcreteConditionValue = (
  condition: FormulaCondition,
  valueOfReferencedParam: string | undefined
): boolean => {
  let result = false;
  if (!valueOfReferencedParam) {
    console.warn('No value, early return on condition check');
    return false;
  }

  const valueAsFloat = parseFloat(condition.value);
  const valueIsValidNumber = !Number.isNaN(valueAsFloat);

  if (!valueIsValidNumber) {
    if (condition.operator === '==') {
      result = condition.value === valueOfReferencedParam;
    } else if (condition.operator === '!=') {
      result = condition.value !== valueOfReferencedParam;
    } else {
      console.error(`Attempted to compare a NaN string: Value ${condition.value}, Operator: ${condition.operator}`);
    }
  } else {
    switch (condition.operator) {
      case '==':
        result = condition.value === valueOfReferencedParam;
        break;
      case '>':
        result = condition.value > valueOfReferencedParam;
        break;
      case '>=':
        result = condition.value >= valueOfReferencedParam;
        break;
      case '<':
        result = condition.value < valueOfReferencedParam;
        break;
      case '<=':
        result = condition.value <= valueOfReferencedParam;
        break;
      case '!=':
        result = condition.value !== valueOfReferencedParam;
        break;
    }
  }

  return result;
};

export const checkParameterDependencyType = (
  param: CheckedProcessingParameter,
  formula: ProcessingFormula
): 'none' | 'single' | 'multi' => {
  let result: ReturnType<typeof checkParameterDependencyType> = 'none';
  if (param.condition) {
    result = recursivelyCheckOperandDependencyType(param.condition, '', formula) ? 'single' : 'multi';
  }
  return result;
};

export const recursivelyCheckOperandDependencyType = (
  operand: FormulaOperation | FormulaCondition,
  previouslyReferencedParameterId: ProcessingParameterId,
  formula: ProcessingFormula
): boolean => {
  if (isFormulaOperation(operand)) {
    // Operand is Operation
    const operation: FormulaOperation = operand as FormulaOperation;
    return operation.operands.every((operand) =>
      recursivelyCheckOperandDependencyType(operand, previouslyReferencedParameterId, formula)
    );
  } else {
    // Operand is Condition
    const condition: FormulaCondition = operand;
    if (previouslyReferencedParameterId === '') {
      // Setting the referenced parameter when first encountered
      previouslyReferencedParameterId = condition.reference;
    }
    const conditionReferenceEqualPreviouslyReferencedParameterId =
      condition.reference === previouslyReferencedParameterId;
    let allReferencedParametersReferenceOnlyPreviouslyReferencedParameterId = true;
    if (condition.reference && conditionReferenceEqualPreviouslyReferencedParameterId) {
      const referencedParam = formula.parameters[condition.reference] as ProcessingParameter;
      if (referencedParam && referencedParam.condition) {
        allReferencedParametersReferenceOnlyPreviouslyReferencedParameterId = recursivelyCheckOperandDependencyType(
          referencedParam.condition,
          previouslyReferencedParameterId,
          formula
        );
      }
    }
    return (
      conditionReferenceEqualPreviouslyReferencedParameterId &&
      allReferencedParametersReferenceOnlyPreviouslyReferencedParameterId
    );
  }
};

export const recursivelyInjectSingleDependencyParamsIntoRespectiveSortedParams = (
  recursivChangedParams: CheckedProcessingParameter[],
  sortedParams: CheckedProcessingParameter[],
  startParams?: CheckedProcessingParameter[]
): CheckedProcessingParameter[] => {
  const remainingParams: CheckedProcessingParameter[] = [];
  if (!startParams) {
    startParams = recursivChangedParams;
  }
  startParams.forEach((param) => {
    const condition: FormulaCondition = param.condition!.operands[0] as FormulaCondition; // We know there is exactly one condition, casting confidently *shrug*
    const dependentOnParamAtIndex = sortedParams.findIndex((sortedParam) => sortedParam.id === condition.reference);
    if (dependentOnParamAtIndex !== -1) {
      sortedParams.splice(dependentOnParamAtIndex + 1, 0, param);
    } else {
      remainingParams.push(param);
    }
  });

  const numOfRemainingParamsWasReduced = remainingParams.length !== recursivChangedParams.length;
  const hasRemainingParams = remainingParams.length > 0;
  if (hasRemainingParams && numOfRemainingParamsWasReduced) {
    recursivelyInjectSingleDependencyParamsIntoRespectiveSortedParams(remainingParams, sortedParams, startParams);
  } else if (hasRemainingParams && !numOfRemainingParamsWasReduced) {
    console.warn('Prevented recursion in recursivelyInjectSingleDependencyParamsIntoRespectiveSortedParams');
  }
  return sortedParams;
};
