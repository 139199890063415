import { Component } from '@angular/core';
import { ContentCollectionFacade } from '../+state/content-collection.facade';
import { ContentCollection } from '../content-collection.types';

@Component({
  selector: 'cloud-collection-delete',
  templateUrl: './collection-delete.component.html',
  styleUrls: ['./collection-delete.component.css']
})
export class CollectionDeleteComponent {
  public visible = false;
  public collectionToDelete: ContentCollection;

  constructor(public contentCollectionFacade: ContentCollectionFacade) {}

  cancelDeleteCollection(event: MouseEvent) {
    event.stopPropagation();
    this.visible = false;
  }

  confirmDeleteCollection(event: MouseEvent) {
    event.stopPropagation();
    this.contentCollectionFacade.deleteContentCollection(this.collectionToDelete);
  }
}
