<ng-container *ngIf="showMeasurementMoveInterface$ | async">
  <clr-dropdown [clrCloseMenuOnItemClick]="false" class="header-move-operations dropdown-move-container">
    <button class="nav-icon" clrDropdownTrigger>
      <div class="move-dropdown">
        <cloud-svg-icon class="move-icon" fileName="{{ moveIcon }}"></cloud-svg-icon>
        <cloud-svg-icon
          *ngIf="showMoveDropdown; else caretDown"
          class="move-icon"
          fileName="caret_up"
          width="10px"
          height="5px"
        ></cloud-svg-icon>
        <ng-template #caretDown>
          <cloud-svg-icon class="caret" fileName="caret_down" width="10px" height="5px"></cloud-svg-icon>
        </ng-template>
      </div>
    </button>

    <ng-template [(clrIfOpen)]="showMoveDropdown">
      <clr-dropdown-menu clrPosition="bottom-right">
        <div class="clr-col-12">
          <div class="card-header dropdown-move title">{{ 'MEASUREMENTMOVE.MOVETITLE' | translate }}</div>

          <div *ngIf="listActiveMovings.length > 0" class="card-header dropdown-move category">
            {{ 'MEASUREMENTMOVE.MOVING' | translate }}
          </div>
          <ul class="moving-list">
            <ng-container *ngFor="let move of listActiveMovings">
              <li class="move-item">{{ move.subtitle }} - {{ move.progress }}%</li>
            </ng-container>
          </ul>

          <div *ngIf="listDoneMovings.length > 0" class="card-header dropdown-move category">
            {{ 'MEASUREMENTMOVE.MOVED' | translate }}
          </div>
          <ul class="moving-list">
            <ng-container *ngFor="let move of listDoneMovings">
              <li class="move-item">{{ move.subtitle }}</li>
            </ng-container>
          </ul>

          <div *ngIf="listErrorMovings.length > 0" class="card-header dropdown-move category">
            {{ 'MEASUREMENTMOVE.MOVEERROR' | translate }}
          </div>
          <ul class="moving-list">
            <ng-container *ngFor="let move of listErrorMovings">
              <li class="move-item">{{ move.subtitle }}</li>
            </ng-container>
          </ul>
        </div>
      </clr-dropdown-menu>
    </ng-template>
  </clr-dropdown>
</ng-container>
