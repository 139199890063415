import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UserFacade } from '../../../user/+state/user.facade';
import { FeatureFlagsFacade } from '@root/libs/feature-flags/src';
import { Observable, combineLatest, map } from 'rxjs';
import { ContentCollectionFacade } from '../../../content-collection/+state/content-collection.facade';

@Component({
  selector: 'cloud-download-modal',
  templateUrl: './download-modal.component.html',
  styleUrls: ['./download-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadModalComponent implements OnInit {
  @Input() showPAKinsightModal: boolean;
  @Input() PAKinsightName$: Observable<string>;

  showWidePAKinsightModal$: Observable<boolean>;
  PAKinsightOpenInLink$: Observable<string>;

  constructor(
    public userFacade: UserFacade,
    public featureFlagFacade: FeatureFlagsFacade,
    private contentCollectionFacade: ContentCollectionFacade
  ) {}

  ngOnInit(): void {
    this.showWidePAKinsightModal$ = combineLatest([
      this.featureFlagFacade.featureValue$('PAK_INSIGHT_DOWNLOAD'),
      this.featureFlagFacade.featureValue$('PAK_INSIGHT_DOWNLOADANDUPDATE')
    ]).pipe(map(([dl, dlAndUpdate]) => dl && dlAndUpdate));

    this.PAKinsightOpenInLink$ = this.contentCollectionFacade.activeContentCollection$.pipe(
      map((cc) => {
        const config = {
          scheme: 'pakinsight',
          queryParams: {
            scheme: 'https',
            host: window.location.hostname,
            port: '',
            path: '',
            userinfo: '',
            authenticationtype: 'OAuth2BearerToken',
            contentcollectionid: cc?.guid
          }
        };
        let link: string = `${config.scheme}:`;
        const queryParamEntries = Object.entries(config.queryParams);

        queryParamEntries.forEach((qp, idx) => {
          if (idx === 0) {
            link += '?';
          }
          link += `${qp[0]}=${qp[1]}`;
          if (idx !== queryParamEntries.length - 1) {
            link += '&';
          }
        });
        return link;
      })
    );
  }
}
