import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchBarHighlightFilter',
  standalone: true
})
export class SearchBarHighlightFilterPipe implements PipeTransform {
  transform(value: string, highlightText: string): string {
    if (!highlightText) {
      return value;
    }
    const highlight = new RegExp(highlightText, 'gi');
    return value.replace(highlight, '<b>$&</b>');
  }
}
