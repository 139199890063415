import {
  CheckedProcessingInputStream,
  CheckedProcessingParameter,
  ProcessingFormula,
  ProcessingInputDataset,
  ProcessingRequestSource,
  ProcessingStatus
} from './../../processing/processing.types';
import { createAction, props } from '@ngrx/store';
import { DatasetDescription } from '../../measurements/measurements.types';

export enum ProcessingActionTypes {
  // Formulas
  LoadAvailableFormulas = '[Processing] Load available formulas',
  AvailableFormulasLoaded = '[Processing] Available formulas loaded',
  SelectFormula = '[Processing] Select formula',
  FilteringProcessingDatasetsFailed = '[Processing] Error during processing datasets filter',
  SetFilteredProcessingDatasets = '[Processing] Set filtered processing datasets',
  ChangeFormulaParameters = '[Processing] Change formula parameters',
  ChangeSelectedFormulaParameters = '[Processing] Change selected formula parameters',
  ChangeFormulaInputSources = '[Processing] Change formula input sources',
  ChangeSelectedFormulaInputSources = '[Processing] Change selected formula input sources',

  // Processing Handling
  StartProcessing = '[Processing] Start Processing',
  SetLateProcessingToken = '[Processing] Set Late Processing Token',
  ProcessingDoneWithError = '[Processing] Processing Done with Error ',
  ProcessingDone = '[Processing] Processing Done',
  CancelProcessing = '[Processing] Cancel Processing',

  // Processing Status Polling
  PollStatus = '[Processing] Poll processing status',
  ReceivedStatus = '[Processing] Received processing status',
  StopPollingStatus = '[Processing] Stop polling processing status',
  PollNoOp = '[Processing] Noop'
}

export const LoadAvailableFormulas = createAction(ProcessingActionTypes.LoadAvailableFormulas);

export const AvailableFormulasLoaded = createAction(
  ProcessingActionTypes.AvailableFormulasLoaded,
  props<{ formulas: ProcessingFormula[] }>()
);

export const SelectFormula = createAction(
  ProcessingActionTypes.SelectFormula,
  props<{ formula: ProcessingFormula | null }>()
);

export const FilteringProcessingDatasetsFailed = createAction(
  ProcessingActionTypes.FilteringProcessingDatasetsFailed,
  props<{ errorMsg?: string }>()
);

export const SetFilteredProcessingDatasets = createAction(
  ProcessingActionTypes.SetFilteredProcessingDatasets,
  props<{ filteredDatasets?: ProcessingInputDataset[] }>()
);

export const ChangeFormulaParameters = createAction(
  ProcessingActionTypes.ChangeFormulaParameters,
  props<{ formulaId: string; parameters: CheckedProcessingParameter[] }>()
);

export const ChangeSelectedFormulaParameters = createAction(
  ProcessingActionTypes.ChangeSelectedFormulaParameters,
  props<{ parameters: CheckedProcessingParameter[] }>()
);

export const ChangeFormulaInputSources = createAction(
  ProcessingActionTypes.ChangeFormulaInputSources,
  props<{ formulaId: string; sources: CheckedProcessingInputStream[] }>()
);

export const ChangeSelectedFormulaInputSources = createAction(
  ProcessingActionTypes.ChangeSelectedFormulaInputSources,
  props<{
    sources: { sourceName: string; dataset: DatasetDescription | undefined /* i.e. remove the current dataset */ }[];
  }>()
);

// Processing Handling

export const StartProcessing = createAction(
  ProcessingActionTypes.StartProcessing,
  props<{ formula: ProcessingFormula; sources: ProcessingRequestSource[] }>()
);

export const SetLateProcessingToken = createAction(
  ProcessingActionTypes.SetLateProcessingToken,
  props<{ useLateProcessingToken: boolean }>()
);

export const ProcessingDoneWithError = createAction(
  ProcessingActionTypes.ProcessingDoneWithError,
  props<{ status: ProcessingStatus }>()
);

export const ProcessingDone = createAction(ProcessingActionTypes.ProcessingDone, props<{ status: ProcessingStatus }>());

export const CancelProcessing = createAction(
  ProcessingActionTypes.CancelProcessing,
  props<{ processingStatusUrl: string }>()
);

// Processing Status Polling

export const PollStatus = createAction(
  ProcessingActionTypes.PollStatus,
  props<{ processingUrl: string; doRequestToken: boolean }>()
);

export const ReceivedStatus = createAction(
  ProcessingActionTypes.ReceivedStatus,
  props<{ status: ProcessingStatus; processingUrl: string }>()
);

export const StopPollingStatus = createAction(
  ProcessingActionTypes.StopPollingStatus,
  props<{ processingUrl: string; finalStatus: ProcessingStatus }>()
);

export const PollNoOp = createAction(ProcessingActionTypes.PollNoOp);
