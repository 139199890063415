<clr-modal [(clrModalOpen)]="visible" [clrModalSize]="'xl'">
  <h3 class="modal-title plotting-title">{{ 'PLOTTING.MODALTITLE' | translate }} {{ getCurrentTitle() }}</h3>
  <div
    class="modal-body no-padding"
    [ngClass]="{
      'plotting-spinner-height': isPlottingInFlight,
      'svg-hide-scrollbar': plotSVGData$ | async
    }"
  >
    <div *ngIf="isPlottingInFlight">
      <cloud-spinner [size]="'medium'" class="plotting-spinner"></cloud-spinner>
    </div>
    <div *ngIf="plotSVGData$ | async">
      <div [innerHTML]="(plotSVGData$ | async)! | safe: 'html'"></div>
    </div>
  </div>
  <div class="modal-footer footer-flexbox-spacebetween no-padding" *ngIf="plotSVGData$ | async">
    <button type="button" class="btn btn-link" (click)="displayPreviousDataset()">
      <i class="fa fa-arrow-left fa-2x"></i>
    </button>
    <button type="button" class="btn btn-link" (click)="displayNextDataset()">
      <i class="fa fa-arrow-right fa-2x"></i>
    </button>
  </div>
</clr-modal>
