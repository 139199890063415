import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IOutputData } from 'angular-split/lib/interface';
import { Subscription } from 'rxjs';
import { AppFacade } from '../../../+state/app.facade';
import { attributeTreeWidthConstraints } from '../../../+state/app.reducer';

export interface PanelContent {
  title: string;
  hasContent: boolean;
  keyValueList: Record<string, string | number | boolean>;
}

export interface PanelContentTree {
  name: string;
  nodes: PanelContentTreeNode[];
}

export interface PanelContentTreeNode {
  name: string;
  label: string;
  expanded: boolean;
  selected: boolean;
  childNodes?: PanelContentTreeNode[];
  content?: PanelContent;
}
@Component({
  selector: 'cloud-panel-detail-content',
  templateUrl: './panel-detail-content.component.html',
  styleUrls: ['./panel-detail-content.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanelDetailContentComponent implements OnChanges, OnDestroy {
  @Input() trees: PanelContentTree[];

  subs: Subscription[] = [];

  selectedNode: PanelContentTreeNode;
  showContainer = false;
  atwConstraints = attributeTreeWidthConstraints;

  constructor(public translate: TranslateService, public appFacade: AppFacade) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['trees']) {
      if (this.trees?.[0]) {
        // Show container?
        this.showContainer = this.trees[0].nodes.length > 0;

        // Try to select first entry of first tree
        this.trySelectFirstNodeWithContent(this.trees[0]);
      }

      // Expand first level expandable entries
      if (this.trees) {
        this.trees.forEach((tree) => {
          expandFirstLevel(tree);
        });
      }
    }
  }

  searchAggregateWidthChanged(output: IOutputData) {
    this.appFacade.setUserPreference('attributeTreeWidth', output.sizes[0] as number);
  }

  selectNode(node: PanelContentTreeNode) {
    this.trees.forEach((tree) => clearTreeNodeSelection(tree));
    node.selected = true;
    this.selectedNode = node;
  }

  trySelectFirstNodeWithContent(tree: PanelContentTree) {
    const rootNode = this.trees?.[0]?.nodes?.[0];
    if (rootNode) {
      if (rootNode.content) {
        this.selectNode(rootNode);
      } else if (rootNode.childNodes?.length ?? 0 > 0) {
        this.selectNode(rootNode.childNodes![0]);
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

const expandFirstLevel = (tree: PanelContentTree) => {
  tree.nodes.forEach((rootNode) => {
    if (rootNode.childNodes?.length ?? 0 > 0) {
      rootNode.expanded = true;
    }
  });
};

const clearTreeNodeSelection = (tree: PanelContentTree) => {
  for (const treeNode of tree.nodes) {
    recursiveNodeDeselect(treeNode);
  }
};

const recursiveNodeDeselect = (node: PanelContentTreeNode): void => {
  if (node.selected) {
    node.selected = false;
  }
  if (node.childNodes) {
    for (const childNode of node.childNodes) {
      if (childNode) {
        recursiveNodeDeselect(childNode);
      }
    }
  }
};
