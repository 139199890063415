<div id="container" *ngIf="content?.keyValueList">
  <div id="header">
    <div id="title">
      {{ content?.title }}
    </div>
    <clr-toggle-wrapper id="hideEmptyValuesToggle">
      <input
        type="checkbox"
        clrToggle
        [checked]="(appFacade.hideEmptyValues$ | async) === true"
        name="options"
        (click)="appFacade.toggleUserPreference('hideEmptyValues')"
      />
    </clr-toggle-wrapper>
    <label id="hideEmptyValuesToggleLabel">{{ 'APP.CONTENT_HIDE_EMPTY_VALUES' | translate }}</label>
  </div>
  <div id="content" *ngIf="!(isEmpty$ | async)">
    <div class="keyValue" *ngFor="let kv of keyValuesToBeDisplayed$ | async | keyvalue">
      <div class="key">{{ kv.key }}</div>
      <div class="value">{{ kv.value }}</div>
    </div>
  </div>
  <div id="emptyContent" *ngIf="isEmpty$ | async">
    <div>{{ 'APP.CONTENT_NO_ENTRIES' | translate }}</div>
    <div *ngIf="hasHiddenKeyValuePairs$ | async">
      {{ 'APP.CONTENT_NO_ENTRIES_BUT_FILTERED_ONES' | translate }}
      <button class="btn btn-sm btn-link" (click)="appFacade.toggleUserPreference('hideEmptyValues')">
        {{ 'APP.CONTENT_NO_ENTRIES_BUT_SHOW_FILTERED_ONES' | translate }}
      </button>
    </div>
  </div>
</div>
