import { Component } from '@angular/core';

export type smileys = 'frowny' | 'neutral' | 'smiley';

@Component({
  selector: 'cloud-smiley',
  templateUrl: './smiley.component.html',
  styleUrls: ['./smiley.component.css']
})
export class SmileyComponent {
  public selectedSmiley: smileys | undefined;

  constructor() {}
}
