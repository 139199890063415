import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PROCESSING_FEATURE_KEY, ProcessingState } from './processing.reducer';

const getProcessingState = createFeatureSelector<ProcessingState>(PROCESSING_FEATURE_KEY);

const getAvailableFormulas = createSelector(getProcessingState, (state: ProcessingState) => state.availableFormulas);
const getAvailableFormulasLoading = createSelector(
  getProcessingState,
  (state: ProcessingState) => state.availableFormulasLoading
);
const getSelectedFormula = createSelector(getProcessingState, (state: ProcessingState) =>
  state.availableFormulas.find((item) => item.id === state.selectedFormulaId)
);

const getFilteredDatasets = createSelector(getProcessingState, (state: ProcessingState) => state.filteredDatasets);
const getFilteredDatasetsLoading = createSelector(
  getProcessingState,
  (state: ProcessingState) => state.filteredDatasetsLoading
);

const getUseLateProcessingToken = createSelector(
  getProcessingState,
  (state: ProcessingState) => state.useLateProcessingToken
);

const getCurrentProcessing = createSelector(getProcessingState, (state: ProcessingState) => state.currentProcessing);

const getCurrentProcessingProgress = createSelector(getProcessingState, (state: ProcessingState) => {
  return ((state.currentProcessing.lastPollStatus?.progress ?? 0) * 100).toFixed(0);
});

const getActiveProcessingDataSources = createSelector(
  getProcessingState,
  (state: ProcessingState) => state.currentProcessing.sources
);

// Polling
const getPollingState = createSelector(
  getProcessingState,
  (state: ProcessingState) => state.currentProcessing.lastPollStatus
);

const getIsPolling = createSelector(
  getProcessingState,
  (state: ProcessingState) => state.currentProcessing.lastPollStatus.status === 'active'
);

export const processingQuery = {
  getAvailableFormulas,
  getAvailableFormulasLoading,
  getSelectedFormula,
  getFilteredDatasets,
  getFilteredDatasetsLoading,
  getUseLateProcessingToken,
  getCurrentProcessing,
  getCurrentProcessingProgress,
  getActiveProcessingDataSources,
  getPollingState,
  getIsPolling
};
