<clr-modal
  [clrModalOpen]="(this.pathsMatch$ | async) === true"
  [clrModalSize]="'xl'"
  (keyup.escape)="handleEscape($event)"
  (clrModalAlternateClose)="endMoveMeasurements($event)"
>
  <!-- (clrModalOpenChange)="endMoveMeasurements($event)" -->
  <h3 class="modal-title" *ngIf="(measurementsFacade.intermediateMeasurements$ | async)?.length > 1">{{
    'MEASUREMENTMOVE.MOVE_MEASUREMENTS' | translate
  }}</h3>
  <h3 class="modal-title" *ngIf="(measurementsFacade.intermediateMeasurements$ | async)?.length === 1">{{
    'MEASUREMENTMOVE.MOVE_MEASUREMENT' | translate
  }}</h3>
  <div class="modal-body">
    <form clrForm id="inputContainer" clrLayout="vertical">
      <clr-input-container id="depotInput">
        <label>{{ 'MEASUREMENTMOVE.RENAME_DEPOTNAME' | translate }} (fixed)</label>
        <input
          clrInput
          disabled
          #moveTargetDepotNameInput
          name="moveTargetDepotName no-margin"
          data-cy="moveTargetDepotName"
          placeholder="{{ 'MEASUREMENTMOVE.DEPOT' | translate }}"
          [value]="depotName$ | async"
        />
      </clr-input-container>
      <clr-input-container>
        <label>{{ 'MEASUREMENTMOVE.RENAME_PROJECTNAME' | translate }}</label>
        <input
          clrInput
          #moveTargetProjectNameInput
          name="moveTargetProjectName no-margin"
          data-cy="moveTargetProjectName"
          (ngModelChange)="moveTargetProjectNameTextChanged(moveTargetProjectNameInput.value)"
          placeholder="{{ 'MEASUREMENTMOVE.PROJECTNAME' | translate }}"
          [ngModel]="(measurementsFacade.targetPath$ | async)?.project"
          [class.clr-error]="!isValidFilePath((measurementsFacade.targetPath$ | async)?.project)"
        />
      </clr-input-container>
      <clr-input-container>
        <label>{{ 'MEASUREMENTMOVE.RENAME_JOBNAME' | translate }}</label>
        <input
          clrInput
          #moveTargetJobNameInput
          name="moveTargetJobName no-margin"
          data-cy="moveTargetJobName"
          (ngModelChange)="moveTargetJobNameTextChanged(moveTargetJobNameInput.value)"
          placeholder="{{ 'MEASUREMENTMOVE.JOBNAME' | translate }}"
          [ngModel]="(measurementsFacade.targetPath$ | async)?.job"
          [class.clr-error]="!isValidFilePath((measurementsFacade.targetPath$ | async)?.job)"
        />
      </clr-input-container>

      <clr-checkbox-container>
        <clr-checkbox-wrapper data-cy="OVERWRITE_CHECKBOX">
          <input
            type="checkbox"
            clrCheckbox
            name="allowOverwrite"
            [ngModel]="allowOverwrite"
            (ngModelChange)="handleAllowOverwriteChanged()"
          />

          <label>{{ 'MEASUREMENTMOVE.ALLOW_OVERWRITE' | translate }}</label>
        </clr-checkbox-wrapper>
      </clr-checkbox-container>
    </form>

    <ng-container
      *ngIf="{
        targetPath: measurementsFacade.targetPath$ | async,
        projectInvalid: !isValidFilePath((measurementsFacade.targetPath$ | async)?.project),
        jobInvalid: !isValidFilePath((measurementsFacade.targetPath$ | async)?.job),
        notEmpty:
          (measurementsFacade.targetPath$ | async)?.project !== '' &&
          (measurementsFacade.targetPath$ | async)?.job !== ''
      } as vars"
    >
      <ng-container *ngIf="(measurementsFacade.gatheringPathState$ | async) === 'done'">
        <ng-container *ngIf="(measurementsFacade.intermediateMeasurements$ | async)?.[0] as mea">
          <clr-alert
            *ngIf="
              vars.notEmpty && mea.path.project === vars.targetPath.project && mea.path.job === vars.targetPath.job
            "
            clrAlertType="warning"
            [clrAlertClosable]="false"
          >
            <clr-alert-item>
              <span class="alert-text">
                {{ 'MEASUREMENTMOVE.SOURCE_TARGET_IDENTICAL' | translate }}
              </span>
            </clr-alert-item>
          </clr-alert>
        </ng-container>
      </ng-container>

      <clr-alert
        *ngIf="vars.notEmpty && (vars.projectInvalid || vars.jobInvalid)"
        clrAlertType="danger"
        [clrAlertClosable]="false"
      >
        <clr-alert-item *ngIf="vars.projectInvalid">
          <span class="alert-text">
            {{ 'MEASUREMENTMOVE.INVALID_PROJECT_PATH' | translate }}
          </span>
        </clr-alert-item>
        <clr-alert-item *ngIf="vars.jobInvalid">
          <span class="alert-text">
            {{ 'MEASUREMENTMOVE.INVALID_JOB_PATH' | translate }}
          </span>
        </clr-alert-item>
      </clr-alert>
    </ng-container>

    <div id="measurementContainer">
      <div class="measurementList">
        <h5>Source Measurements</h5>
        <ul *ngIf="(measurementsFacade.gatheringPathState$ | async) === 'done'">
          <li *ngFor="let mea of measurementsFacade.intermediateMeasurements$ | async">
            <span class="depotName">
              {{ mea.path.depot_name }}
            </span>
            /{{ mea.path.project }}/{{ mea.path.job }}/{{ mea.path.subtitle }}
          </li>
        </ul>
      </div>

      <div class="measurementList">
        <h5
          >Target Preview
          <cloud-spinner *ngIf="(measurementsFacade.dryRunState$ | async) === 'inProgress'" [inline]="true">
          </cloud-spinner
        ></h5>
        <ul *ngIf="(measurementsFacade.gatheringPathState$ | async) === 'done'">
          <li
            *ngFor="let mea of (measurementsFacade.dryRunResults$ | async)?.dryRunMeasurements"
            [ngClass]="{
              loading: (measurementsFacade.dryRunState$ | async) === 'inProgress'
            }"
          >
            <span class="depotName">{{ mea.path.depot_name }}</span
            >/<cloud-path-pill
              [pathFragment]="(measurementsFacade.targetPath$ | async)?.project"
              [isNew]="(measurementsFacade.dryRunResults$ | async)?.projectExists === false"
              [isInvalid]="!isValidFilePath((measurementsFacade.targetPath$ | async)?.project)"
            ></cloud-path-pill
            >/<cloud-path-pill
              [pathFragment]="(measurementsFacade.targetPath$ | async)?.job"
              [isNew]="(measurementsFacade.dryRunResults$ | async)?.jobExists === false"
              [isInvalid]="!isValidFilePath((measurementsFacade.targetPath$ | async)?.job)"
            ></cloud-path-pill
            >/{{ mea.path.subtitle }}
            <cloud-path-pill *ngIf="mea.willOverWrite" [willOverwrite]="true"></cloud-path-pill>
          </li>
          <div *ngIf=""> Please input a target path </div>
        </ul>
      </div>
    </div>

    <div id="spinnerContainer">
      <cloud-spinner *ngIf="(measurementsFacade.gatheringPathState$ | async) === 'inProgress'"> </cloud-spinner>
    </div>

    <div>
      <form clrForm> </form>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" data-cy="cancelMoveButton" (click)="endMoveMeasurements($event)">
      {{ 'MEASUREMENTMOVE.CANCELBUTTON' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary"
      data-cy="doMoveButton"
      (click)="confirmMove($event)"
      [disabled]="(disableMoveButton$ | async) === true"
    >
      <!--
      -->
      <ng-container *ngIf="(measurementsFacade.measurementsToMove$ | async)?.length > 1">
        {{ 'MEASUREMENTMOVE.MOVE_MEASUREMENTS' | translate }}
      </ng-container>
      <ng-container *ngIf="(measurementsFacade.measurementsToMove$ | async)?.length === 1">
        {{ 'MEASUREMENTMOVE.MOVE_MEASUREMENT' | translate }}
      </ng-container>
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="(this.pathsMatch$ | async) === false"
  [clrModalSize]="'xl'"
  (keyup.escape)="handleEscape($event)"
  (clrModalAlternateClose)="endMoveMeasurements($event)"
>
  <!-- (clrModalOpenChange)="endMoveMeasurements($event)" -->
  <h3 class="modal-title">{{ 'MEASUREMENT.MOVE.CANNOT_MOVE_HEADER' | translate }}</h3>
  <div class="modal-body">
    <clr-alert id="pendingDeletionWarning" [clrAlertType]="'warning'" [clrAlertClosable]="false">
      <clr-alert-item>
        <span class="alert-text">
          {{ 'MEASUREMENT.MOVE.CANNOT_MOVE_SOURCE_PATH_MISMATCH' | translate }}
        </span>
      </clr-alert-item>
    </clr-alert>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="endMoveMeasurements($event)">
      {{ 'MEASUREMENT.MOVE.RETURN' | translate }}
    </button>
  </div>
</clr-modal>
