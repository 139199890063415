import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { AppPartialState, AppUserPrefs, AppUserPrefsBoolean } from './app.reducer';
import { appQuery } from './app.selectors';
import {
  AcknowledgeCookieNotice,
  SetLanguage,
  RequestAvailableApps,
  SetIsUserAdmin,
  SetIsDepotAdmin,
  GetCloudDepots,
  GetCloudServerVersion,
  SuccessMsg,
  ToggleUserPreference,
  SetTracking,
  SetUserPreference,
  GetClient
} from './app.actions';
import { availableLanguagesStrings } from '../app.locales';
import { createAppError } from '../app.factories';
import { CloudAppNames } from '../app.types';
import { map } from 'rxjs/operators';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Observable, of, merge, combineLatest } from 'rxjs';
import { CommonFacade } from '@vas/common';
import { FeatureFlagsFacade } from '@vas/feature-flags';

@Injectable()
export class AppFacade {
  cookieNoticeAcknowledged$ = this.store.pipe(select(appQuery.getCookieNoticeAcknowledged));

  languageState$ = this.store.pipe(select(appQuery.getLanguage));
  language$: Observable<availableLanguagesStrings> = merge(
    of(this.translate.currentLang),
    this.translate.onLangChange.pipe(map((event: LangChangeEvent) => event.lang))
  ).pipe(map((lang) => lang as availableLanguagesStrings));

  allAvailableApps$ = this.store.pipe(select(appQuery.getAllAvailableApps));
  serverVersion$ = this.store.pipe(select(appQuery.getServerVersion));
  client$ = this.store.pipe(select(appQuery.getClient));
  cloudDepots$ = this.store.pipe(select(appQuery.getCloudDepots));
  cloudDepotsLoaded$ = this.store.pipe(select(appQuery.getCloudDepotsLoaded));
  isUserAdmin$ = this.store.pipe(select(appQuery.getIsUserAdmin));
  isDepotAdmin$ = this.store.pipe(select(appQuery.getIsDepotAdmin));

  trackingEnabled$ = this.store.pipe(select(appQuery.getIsTracking));

  hideEmptyValues$ = this.store.pipe(
    select(appQuery.getSpecificUserPreferences('hideEmptyValues')),
    map((value) => value as boolean)
  );
  attributeTreeWidth$ = this.store.pipe(select(appQuery.getSpecificUserPreferences('attributeTreeWidth')));

  isDebugOrDev$ = combineLatest([
    this.commonFacade.production$,
    this.featureFlagsFacade.featureIsSet$('DEBUG_MODE')
  ]).pipe(map(([prod, debug]) => !prod || debug));

  constructor(
    private store: Store<AppPartialState>,
    private translate: TranslateService,
    private commonFacade: CommonFacade,
    private featureFlagsFacade: FeatureFlagsFacade
  ) {}

  // Dynamic Selectors
  getIsCloudAppAvailable$ = (appName: CloudAppNames) =>
    this.store.pipe(select(appQuery.getIsCloudAppAvailable(appName)));

  getUserPreference$ = (preference: AppUserPrefs) =>
    this.store.pipe(select(appQuery.getSpecificUserPreferences(preference)));

  // Methods
  acknowledgeCookie() {
    this.store.dispatch(AcknowledgeCookieNotice());
  }

  setLanguageAndReload(language: availableLanguagesStrings) {
    this.store.dispatch(SetLanguage({ lang: language }));
    window.location.reload();
  }

  setLanguage(language: availableLanguagesStrings) {
    this.store.dispatch(SetLanguage({ lang: language }));
  }

  setTracking(enabled: boolean) {
    this.store.dispatch(SetTracking({ enabled: enabled }));
  }

  getCloudDepots() {
    this.store.dispatch(GetCloudDepots());
  }

  requestAvailableApps() {
    this.store.dispatch(RequestAvailableApps());
  }

  getCloudServerVersion() {
    this.store.dispatch(GetCloudServerVersion());
  }

  getClientIdentifier() {
    this.store.dispatch(GetClient());
  }

  setIsUserAdmin() {
    this.store.dispatch(SetIsUserAdmin());
  }

  setIsDepotAdmin() {
    this.store.dispatch(SetIsDepotAdmin());
  }

  showError(errorMsg: string, translationKey: string = '') {
    this.store.dispatch(createAppError({ message: errorMsg, translationKey: translationKey }));
  }

  showSuccess(msg: string, translationKey?: string) {
    this.store.dispatch(SuccessMsg({ message: msg, translationKey: translationKey }));
  }

  passOnError(error: any) {
    this.store.dispatch(createAppError(error));
  }

  toggleUserPreference(preference: AppUserPrefsBoolean) {
    this.store.dispatch(ToggleUserPreference({ preference }));
  }

  setUserPreference(preference: AppUserPrefs, value: number | boolean) {
    this.store.dispatch(SetUserPreference({ preference, value }));
  }
}
