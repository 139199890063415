import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserInfo, UserWithRoles, UserWithRolesAndLicenses } from '@root/apps/cloud/src/app/user/user.type';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { GroupsFacade } from '../../+state/groups/groups.facade';
import { LicensesFacade } from '../../+state/licenses/licenses.facade';
import { RolesFacade } from '../../+state/roles/roles.facade';
import { UsersFacade } from '../../+state/users/users.facade';
import { lookupLicenseNames } from '../../services/licenses-name-lookup';
import { GroupInfo } from '../../types/group.types';
import { LicenseWithOrigin } from '../../types/license.types';
import { LicenseInfo } from '../../types/license.types';
import { EffectiveRole, RoleInfo } from '../../types/role.types';

type SubMenuState = 'details' | 'groups' | 'roles' | 'licenses';

@Component({
  selector: 'cloud-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  @Input() user: UserWithRolesAndLicenses;
  @Input() licenses: LicenseWithOrigin[] | undefined;
  @Input() subMenuState: SubMenuState;

  @Output() subMenuStateChange = new EventEmitter<SubMenuState>();
  @Output() licenseHovered = new EventEmitter();

  public lookupLicenseNames = lookupLicenseNames;

  availableGroups: GroupInfo[];
  userGroups: GroupInfo[];

  availableRoles: RoleInfo[];
  userRoles: RoleInfo[];

  private subs: Subscription[] = [];

  constructor(
    public translate: TranslateService,
    public usersFacade: UsersFacade,
    public groupsFacade: GroupsFacade,
    public rolesFacade: RolesFacade,
    public licensesFacade: LicensesFacade
  ) {}

  ngOnInit() {
    this.subs.push(
      this.groupsFacade.groups$.pipe(take(1)).subscribe((groups) => {
        this.setGroupsLists(groups as GroupInfo[], this.user);
      })
    );

    this.subs.push(
      this.rolesFacade.roles$.pipe(take(1)).subscribe((roles) => {
        this.setRolesLists(roles as RoleInfo[], this.user);
      })
    );
  }

  getEffectiveRoles(user: UserInfo): Observable<RoleInfo[] | undefined> {
    return combineLatest([this.rolesFacade.effectiveRoles$, this.rolesFacade.roles$, this.groupsFacade.groups$]).pipe(
      map(([effectiveRoles, roles, groups]) => {
        const userRoles = effectiveRoles
          ?.filter((efRole: EffectiveRole) => efRole.userId === user.id)
          .map((role: EffectiveRole) => {
            return { ...role, groups: groups?.filter((group) => role.groupIds?.includes(group.id)) };
          })
          .map((role: EffectiveRole) => ({ ...role, role: roles?.find((r) => r.id === role.roleId) }))
          .filter((role) => role.groupIds?.length > 0);

        return userRoles?.map((r) => r.role) as RoleInfo[];
      })
    );
  }

  getGroupsForEfRole(efRole: EffectiveRole) {
    return `${efRole.groups?.map((group) => group.name).join(', ')}`;
  }

  addGroupToUser(group, user: UserWithRolesAndLicenses) {
    const userGroups = group.items.map((group) => group.id);
    this.usersFacade.addGroupsToUser(user.id, userGroups);
    this.rolesFacade.getEffectiveRoles();
  }

  removeGroupFromUser(group, user: UserWithRolesAndLicenses) {
    const userGroups = group.items.map((group) => `${user.id}_${group.id}`);
    this.usersFacade.deleteUserGroups(user.id, userGroups);
    this.rolesFacade.getEffectiveRoles();
  }

  addRoleToUser(role, user: UserWithRolesAndLicenses) {
    const userRoles = role.items.map((role) => role.id);
    this.rolesFacade.addRolesToUser(user.id, userRoles);
  }

  removeRoleFromUser(role, user: UserWithRolesAndLicenses) {
    this.rolesFacade.deleteUserRoles(
      user.id,
      role.items.map((role: RoleInfo) => role.id)
    );
  }

  lookUpAndSortLicensePills(licenses: LicenseInfo[]): LicenseInfo[] {
    return licenses
      .map((license) => ({ ...license, name: lookupLicenseNames(license.id, { includePeriod: true }) }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  getGroupNamesForEfRole(role: EffectiveRole) {
    return role?.groups?.map((group) => `${group.name} (group)`).join(', ');
  }

  trackByLicenseId(index: number, license: LicenseWithOrigin) {
    return license.id;
  }

  private setGroupsLists(groups: GroupInfo[], user: UserWithRoles) {
    const groupsForUser = this.getGroupsForUser(user, groups);

    this.userGroups = groupsForUser;
    this.availableGroups = groups?.filter((group) => !groupsForUser.find((userGroup) => userGroup.id === group.id));
  }

  private setRolesLists(roles: RoleInfo[], user: UserWithRoles) {
    this.userRoles = user.roles as RoleInfo[];
    this.availableRoles = roles?.filter((role) => !this.userRoles.find((userRole) => userRole.id === role.id));
  }

  private getGroupsForUser(user: UserWithRoles, groups: GroupInfo[]): GroupInfo[] {
    return user.groups.map((groupName) => {
      return groups.find((group) => group.name === groupName) as GroupInfo;
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
