import { availableLanguagesStrings } from '../app.locales';

import { Client, CloudDepot } from '../app.types';
import { createReducer, Action } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';

import * as AppActions from './app.actions';
import { CloudAppNames } from '../app.types';
import { WEBAPP_CLIENT_NAME, WEBAPP_CLIENT_UUID } from '../app.constants';
import { exhaustiveMatchingGuard } from '../shared/utility-functions/exhaustiveMatchingGuard';

export const APP_FEATURE_KEY = 'app';

export const attributeTreeWidthConstraints = {
  min: 150,
  max: 450
};

export interface AppState {
  cookieAcknowledged: boolean;
  language: availableLanguagesStrings;
  availableApps: CloudAppNames[];
  client: Client;
  serverVersion?: string;
  tracking: boolean;
  depots: { [index: string]: CloudDepot };
  depotsLoaded: boolean;
  isUserAdmin: boolean;
  isDepotAdmin: boolean;

  // User preferences are currently kept in the app state, since they are not yet persisted in the backend
  userPreferences: {
    hideEmptyValues: boolean;
    showAdditionalAttributes: boolean;
    attributeTreeWidth: number;
    activateContentCollectionOnCreation: boolean;
  };
}

export type AppUserPrefs = keyof AppState['userPreferences'];

type BooleanKeys<T> = {
  [K in keyof T]: T[K] extends boolean ? K : never;
}[keyof T];

export type AppUserPrefsBoolean = BooleanKeys<AppState['userPreferences']>;

export interface AppPartialState {
  readonly [APP_FEATURE_KEY]: AppState;
}

export const initialState: AppState = {
  cookieAcknowledged: false,
  language: 'en',
  availableApps: [],
  client: {
    id: WEBAPP_CLIENT_UUID,
    name: WEBAPP_CLIENT_NAME,
    exists: false
  },
  serverVersion: '1.0.0',
  tracking: false,
  depots: {},
  depotsLoaded: false,
  isUserAdmin: false,
  isDepotAdmin: false,
  userPreferences: {
    hideEmptyValues: false,
    showAdditionalAttributes: false,
    attributeTreeWidth: 200,
    activateContentCollectionOnCreation: true
  }
};

const appReducer = createReducer(
  initialState,
  immerOn(AppActions.AcknowledgeCookieNotice, (state) => {
    state.cookieAcknowledged = true;
  }),
  immerOn(AppActions.LanguageSet, (state, { lang }) => {
    state.language = lang;
  }),
  immerOn(AppActions.AvailableAppsReceived, (state, { availableApps }) => {
    state.availableApps = availableApps;
  }),
  immerOn(AppActions.CloudServerVersionReceived, (state, { serverVersion }) => {
    state.serverVersion = serverVersion;
  }),
  immerOn(AppActions.ClientReceived, (state, { client }) => {
    state.client = client;
  }),
  immerOn(AppActions.SetTracking, (state, { enabled }) => {
    state.tracking = enabled;
  }),
  immerOn(AppActions.CloudDepotsReceived, (state, { receivedDepots }) => {
    receivedDepots.forEach((depot) => {
      state.depots[depot.id] = depot;
    });
    state.depotsLoaded = true;
  }),
  immerOn(AppActions.IsUserAdminReceived, (state, { isAdmin }) => {
    state.isUserAdmin = isAdmin;
  }),
  immerOn(AppActions.IsDepotAdminReceived, (state, { isAdmin }) => {
    state.isDepotAdmin = isAdmin;
  }),
  immerOn(AppActions.ToggleUserPreference, (state, { preference }) => {
    state.userPreferences[preference] = !state.userPreferences[preference];
  }),
  immerOn(AppActions.SetUserPreference, (state, { preference, value }) => {
    switch (preference) {
      case 'attributeTreeWidth':
        state.userPreferences.attributeTreeWidth = value as number;
        break;
      case 'hideEmptyValues':
        state.userPreferences.hideEmptyValues = value as boolean;
        break;
      case 'showAdditionalAttributes':
        state.userPreferences.showAdditionalAttributes = value as boolean;
        break;
      case 'activateContentCollectionOnCreation':
        state.userPreferences.activateContentCollectionOnCreation = value as boolean;
        break;
      default:
        exhaustiveMatchingGuard(preference);
        break;
    }
  })
);

export function reducer(state: AppState | undefined, action: Action) {
  return appReducer(state, action);
}
