import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AttributeGroup } from '../+state/attributes/attributes.types';
import { availableLanguagesStrings } from '../../app.locales';

@Pipe({
  name: 'translateAttributeGroup',
  pure: false
})
export class TranslateAttributeGroupPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: AttributeGroup): string {
    if (value) {
      const currentLanguage = this.translate.currentLang as availableLanguagesStrings;
      if (value.translations) {
        // language from cloud server matches language in client
        if (Object.prototype.hasOwnProperty.call(value.translations, currentLanguage)) {
          return value.translations[currentLanguage];
        }
        // language from cloud server has same suffix as language in client (e.g. "en_US" and "en")
        for (const languageKey in value.translations) {
          if (Object.prototype.hasOwnProperty.call(value.translations, languageKey)) {
            if (languageKey.startsWith(currentLanguage)) {
              return value.translations[languageKey];
            }
          }
        }
      }
      // Fallback: use attribute name if no explicit translations are defined
      if (value.name) {
        return value.name;
      }
      return '';
    }
    return '';
  }
}
