<div *ngIf="this.depotSearchFacade.aggregateTrees$ | async">
  <div class="button-container">
    <div *ngFor="let tree of this.depotSearchFacade.aggregateTrees$ | async">
      <button
        class="button"
        (click)="this.depotSearchFacade.setSelectedAggregateTreeType(tree.definition.aggregate.type)"
        [ngClass]="{ active: tree.definition.aggregate.type === (this.depotSearchFacade.selectedTreeType$ | async) }"
        [attr.data-cy]="'HIERARCHY_TYPE_' + tree.definition.nameTranslationKey"
      >
        {{ tree.definition.nameTranslationKey | translate }}
      </button>
    </div>
    <clr-dropdown id="sort" [clrCloseMenuOnItemClick]="false">
      <button clrDropdownTrigger data-cy="HIERARCHY_SORT_BUTTON">
        <clr-icon shape="sort-by" size="24"></clr-icon>
      </button>
      <clr-dropdown-menu *clrIfOpen>
        <h4 class="dropdown-header">{{ 'DEPOTSEARCH.HIERARCHYSORTHEADER' | translate }}</h4>
        <ng-container *ngIf="selectedSortMethodForAggregateTree$ | async as currentSortMethod">
          <div
            *ngFor="let method of this.availableSortMethodsForAggregateTree$ | async"
            clrDropdownItem
            (click)="
              method.method === currentSortMethod.method
                ? this.depotSearchFacade.setAggregateTreeSortingMethod(currentSortMethod)
                : this.depotSearchFacade.setAggregateTreeSortingMethod(method)
            "
            [ngClass]="{ active: method.method === currentSortMethod.method }"
            [attr.data-cy]="'HIERARCHY_SORT_ENTRY_' + method.methodNLS"
          >
            {{ method.methodNLS }}
            <ng-container *ngIf="method.method === currentSortMethod.method">
              <clr-icon *ngIf="currentSortMethod.direction === 'asc'" shape="arrow" size="12"></clr-icon>
              <clr-icon
                *ngIf="currentSortMethod.direction === 'desc'"
                shape="arrow"
                style="transform: rotate(180deg)"
                size="12"
              ></clr-icon>
            </ng-container>
          </div>
        </ng-container>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>

  <div
    *ngIf="this.depotSearchFacade.currentAggregateRequestIsInFlight$ | async; else elseBlock"
    style="text-align: center; margin-top: 20px"
  >
    <cloud-spinner class="inlineSpinner"></cloud-spinner>
    <span>
      {{ 'DEPOTSEARCH.BUILDINGSEARCHTREE' | translate }}
    </span>
  </div>
  <ng-template #elseBlock>
    <div *ngFor="let tree of this.depotSearchFacade.aggregateTrees$ | async">
      <div *ngIf="tree.definition.aggregate.type === (this.depotSearchFacade.selectedTreeType$ | async)">
        <div *ngIf="tree.nodes">
          <cloud-tree-node
            *ngFor="let node of tree.nodes"
            [root]="true"
            [node]="node"
            [selected]="node.id === (this.depotSearchFacade.selectedTreeNode$ | async)?.id"
            [title]="
              (this.attributesFacade.availableDepotAttributes$ | async)!
                | findAttributeById : node.aggregate.depotAttribute.idName
                | translateAttribute
            "
            (nodeSelected)="selectNode(node)"
            (nodeExpanded)="expandNode(node)"
            (nodeCollapsed)="collapseNode(node)"
          >
            <ng-container *ngIf="node.expanded">
              <div *ngIf="node.childrenLoadingStatus === 'active'" class="load-children-spinner">
                <div class="loading-node">
                  <cloud-spinner class="inlineSpinner"></cloud-spinner>
                  <span>
                    {{ 'DEPOTSEARCH.LOADING' | translate }}
                  </span>
                </div>
              </div>
              <cloud-tree-node
                *ngFor="let node of node.children"
                [node]="node"
                [title]="
                  (this.attributesFacade.availableDepotAttributes$ | async)!
                    | findAttributeById : node.aggregate.depotAttribute.idName
                    | translateAttribute
                "
                [selected]="node.id === (this.depotSearchFacade.selectedTreeNode$ | async)?.id"
                (nodeSelected)="selectNode(node)"
                (nodeExpanded)="expandNode(node)"
                (nodeCollapsed)="collapseNode(node)"
              >
                <ng-container *ngIf="node.expanded">
                  <div *ngIf="node.childrenLoadingStatus === 'active'" class="load-children-box">
                    <div class="loading-node">
                      <cloud-spinner class="inlineSpinner"></cloud-spinner>
                      <span>
                        {{ 'DEPOTSEARCH.LOADING' | translate }}
                      </span>
                    </div>
                  </div>
                  <cloud-tree-node
                    *ngFor="let node of node.children"
                    [node]="node"
                    [title]="
                      (this.attributesFacade.availableDepotAttributes$ | async)!
                        | findAttributeById : node.aggregate.depotAttribute.idName
                        | translateAttribute
                    "
                    [selected]="node.id === (this.depotSearchFacade.selectedTreeNode$ | async)?.id"
                    (nodeSelected)="selectNode(node)"
                    (nodeExpanded)="expandNode(node)"
                    (nodeCollapsed)="collapseNode(node)"
                  >
                  </cloud-tree-node>
                </ng-container>
              </cloud-tree-node>
            </ng-container>
          </cloud-tree-node>
        </div>
      </div>
    </div>
  </ng-template>
</div>
