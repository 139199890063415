import { translate } from './nls.utils';
import {
  DataType,
  DatasetMetaData,
  Shape,
  DataFormat,
  Quantity,
  DatasetThreeDimensionalMetaData,
  DatasetXValues,
  Quantities,
  DatasetThreeDimensionalMetaData_PsychoParams_PsychoType,
  DatasetThreeDimensionalMetaData_SpectrumParameters_CohenParams_CohenClassType,
  DatasetThreeDimensionalMetaData_CepstrumParams_CepstrumType,
  DataInfo
} from '../prototypes/DatasetMessages';

const generateOrdinalNumberAppendix = (val: number) => {
  let ret = '';
  if (val === 1) ret = translate('1st');
  else if (val === 2) ret = translate('2nd');
  else if (val === 3) ret = translate('3rd');
  else {
    ret = val.toString();
    ret += translate('th');
  }
  return ret;
};

const xEquiNumberStringForOrder = (xValues: DatasetXValues | undefined): string => {
  let result = '';
  const valuesOneOfKind = xValues?.xValues.oneofKind;
  if (valuesOneOfKind === 'xEquiValues') {
    const values = xValues?.xValues[valuesOneOfKind];
    const begin = values?.begin;
    if (begin) {
      result = generateOrdinalNumberAppendix(begin);
    }
  }
  return result;
};

const expandShape = (shape: Shape | undefined): string => {
  let ret = '';

  if (shape !== undefined) {
    if (shape !== Shape.Shape_Normal) {
      ret = ' ';
    }
    switch (shape) {
      case Shape.Shape_Cut:
        ret += translate('cut');
        break;
      case Shape.Shape_Bandpass:
        ret += translate('band pass');
        break;
      case Shape.Shape_Overall:
        ret += translate('overall level');
        break;
      case Shape.Shape_OpResult:
        ret += translate('combination');
        break;
    }
  }
  return ret;
};

const expandDataFormat = (shape: Shape | undefined, dataFormat: DataFormat | undefined): string => {
  let ret = '';

  if (dataFormat !== undefined && shape !== undefined) {
    if (shape === Shape.Shape_Normal || shape === Shape.Shape_Cut) {
      ret += ' ';
      switch (dataFormat) {
        case DataFormat.DF_Complex:
          ret += translate('complex');
          break;
        case DataFormat.DF_Real:
          ret += translate('real part');
          break;
        case DataFormat.DF_Imag:
          ret += translate('imaginary part');
          break;
        case DataFormat.DF_Mag:
          ret += translate('magnitude');
          break;
        case DataFormat.DF_Phase:
          ret += translate('phase');
          break;
        case DataFormat.DF_MagDir:
          ret += translate('magnitude');
          break;
      }
    }
  }
  return ret;
};

const determinePsychoTypeString = (
  shape: Shape | undefined,
  psychoType: DatasetThreeDimensionalMetaData_PsychoParams_PsychoType | undefined
): string => {
  let dt = '';
  if (shape === Shape.Shape_Overall) {
    switch (psychoType) {
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Loudness_Instat:
        dt = translate('Instationary loudness');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Loudness_Din:
        dt = translate('DIN loudness');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Roughness:
        dt = translate('Roughness');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Sharpness:
        dt = translate('Sharpness');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_EngineRoughness:
        dt = translate('Engine roughness');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Fluctuation:
        dt = translate('Fluctuation strength');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_AI_Closed:
        dt = translate('AI closed');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_AI_Open:
        dt = translate('AI open');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_AI_Mod:
        dt = translate('AI modified');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Loudness_Instat_Area:
        dt = translate('Integ. loudness');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Loudness_Din_Area:
        dt = translate('Integ. DIN loudness');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Roughness_Area:
        dt = translate('Integ. roughness');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Fluctuation_Area:
        dt = translate('Integ. fluctuation strength');
        break;
    }
  } else {
    switch (psychoType) {
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Loudness_Instat:
        dt = translate('Specific loudness');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Loudness_Din:
        dt = translate('DIN loudness spectrum');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Roughness:
        dt = translate('Specific roughness');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_EngineRoughness:
        dt = translate('Specific engine roughness');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Fluctuation:
        dt = translate('Specific fluctuation strength');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_AI_Closed:
        dt = translate('AI closed spectrum');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_AI_Open:
        dt = translate('AI open spectrum');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_AI_Mod:
        dt = translate('AI modified spectrum');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Loudness_Instat_Area:
        dt = translate('Integ. loudness');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Loudness_Din_Area:
        dt = translate('Integ. DIN loudness');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Roughness_Area:
        dt = translate('Integ. roughness');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Fluctuation_Area:
        dt = translate('Integ. fluctuation strength');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Tonality_Din:
        dt = translate('DIN tonal component');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Tone2Noise_Ansi:
        dt = translate('ANSI tone-to-noise ratio');
        break;
      case DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Prominence_Ansi:
        dt = translate('ANSI prominence ratio');
        break;
    }
  }

  if (shape === Shape.Shape_Cut) {
    dt += ' ';
    dt += translate('cut');
  }
  if (shape === Shape.Shape_Bandpass) {
    dt += ' ';
    dt += translate('band pass');
  }

  return dt;
};

const determineFFTString = (
  quantityX: Quantity | undefined,
  xValues: DatasetXValues | undefined,
  shape: Shape | undefined,
  dataFormat: DataFormat | undefined
): string => {
  let dt = '';
  if (quantityX?.name.includes('Order')) {
    switch (shape) {
      case Shape.Shape_Normal:
        dt = translate('Order FFT');
        break;
      case Shape.Shape_Cut:
        dt = xEquiNumberStringForOrder(xValues) + ' ' + translate('Order');
        break;
      case Shape.Shape_Bandpass:
        dt = translate('Order band');
        break;
      case Shape.Shape_Overall:
        dt = translate('Overall level');
        break;
      case Shape.Shape_OpResult:
        dt = translate('Order combination');
        break;
    }
  } else {
    switch (shape) {
      case Shape.Shape_Normal:
        dt = translate('FFT');
        break;
      case Shape.Shape_Cut:
        dt = translate('Frequency level');
        break;
      case Shape.Shape_Bandpass:
        dt = translate('Band pass');
        break;
      case Shape.Shape_Overall:
        dt = translate('Overall level');
        break;
      case Shape.Shape_OpResult:
        dt = translate('Frequency level combination');
        break;
    }
  }
  dt += expandDataFormat(shape, dataFormat);

  return dt;
};

const determineAPSString = (
  quantityX: Quantity | undefined,
  xValues: DatasetXValues | undefined,
  shape: Shape | undefined
): string => {
  let dt = '';
  if (quantityX?.name.includes('Order')) {
    switch (shape) {
      case Shape.Shape_Normal:
        dt = translate('Order APS');
        break;
      case Shape.Shape_Cut:
        dt = xEquiNumberStringForOrder(xValues) + ' ' + translate('Order');
        break;
      case Shape.Shape_Bandpass:
        dt = translate('Order band');
        break;
      case Shape.Shape_Overall:
        dt = translate('Overall level');
        break;
      case Shape.Shape_OpResult:
        dt = translate('Order combination');
        break;
    }
  } else {
    switch (shape) {
      case Shape.Shape_Normal:
        dt = translate('APS');
        break;
      case Shape.Shape_Cut:
        dt = translate('Frequency level');
        break;
      case Shape.Shape_Bandpass:
        dt = translate('Band pass');
        break;
      case Shape.Shape_Overall:
        dt = translate('Overall level');
        break;
      case Shape.Shape_OpResult:
        dt = translate('Frequency level combination');
        break;
    }
  }

  return dt;
};

const determineCPSString = (
  quantityX: Quantity | undefined,
  shape: Shape | undefined,
  dataFormat: DataFormat | undefined
): string => {
  let dt = '';
  if (quantityX?.name.includes('Order')) {
    switch (shape) {
      case Shape.Shape_Normal:
        dt = translate('Order CPS');
        break;
      case Shape.Shape_Cut:
        dt = translate('Cross order');
        break;
      case Shape.Shape_Bandpass:
        dt = translate('Order CPS band');
        break;
      case Shape.Shape_Overall:
        dt = translate('Order CPS overall level');
        break;
      case Shape.Shape_OpResult:
        dt = translate('Order CPS combination');
        break;
    }
  } else {
    switch (shape) {
      case Shape.Shape_Normal:
        dt = translate('CPS');
        break;
      case Shape.Shape_Cut:
        dt = translate('Cross frequency level');
        break;
      case Shape.Shape_Bandpass:
        dt = translate('CPS band pass');
        break;
      case Shape.Shape_Overall:
        dt = translate('CPS overall level');
        break;
      case Shape.Shape_OpResult:
        dt = translate('Cross frequency level combination');
        break;
    }
  }
  dt += expandDataFormat(shape, dataFormat);

  return dt;
};

const determinePRSString = (
  quantityX: Quantity | undefined,
  shape: Shape | undefined,
  dataFormat: DataFormat | undefined
): string => {
  let dt = '';
  if (quantityX?.name.includes('Order')) {
    switch (shape) {
      case Shape.Shape_Normal:
        dt = translate('Order PRS');
        break;
      case Shape.Shape_Cut:
        dt = translate('Phase referenced order');
        break;
      case Shape.Shape_Bandpass:
        dt = translate('Order PRS band');
        break;
      case Shape.Shape_Overall:
        dt = translate('Order PRS overall level');
        break;
      case Shape.Shape_OpResult:
        dt = translate('Order PRS combination');
        break;
    }
  } else {
    switch (shape) {
      case Shape.Shape_Normal:
        dt = translate('PRS');
        break;
      case Shape.Shape_Cut:
        dt = translate('Phase referenced frequency level');
        break;
      case Shape.Shape_Bandpass:
        dt = translate('PRS band pass');
        break;
      case Shape.Shape_Overall:
        dt = translate('PRS overall level');
        break;
      case Shape.Shape_OpResult:
        dt = translate('Phase referenced frequency level combination');
        break;
    }
  }
  dt += expandDataFormat(shape, dataFormat);

  return dt;
};

export const determineDatasetType = (
  dataType: DataType,
  metaData: DatasetMetaData,
  effectiveDataFormat?: DataFormat,
  quantities?: Quantities
): string => {
  let dt = '';

  let isSupported = true;
  switch (dataType) {
    case DataType.Type_CompressedThruput:
    case DataType.Type_CANData:
    case DataType.Type_FlexRayData:
    case DataType.Type_EtherCatData:
    case DataType.Type_GPSData:
    case DataType.Type_IRIGData:
    case DataType.Type_PTPData:
    case DataType.Type_XLabel:
    case DataType.Type_Contour:
    case DataType.Type_ContourSynth:
    case DataType.Type_EdgeBlock:
    case DataType.Type_HSQBase:
    case DataType.Type_HVQBase:
    case DataType.Type_MultipleSingleOrder:
    case DataType.Type_MultipleCrossOrder:
    case DataType.Type_MultiplePhaseRefOrder:
      isSupported = false;
      break;
  }
  if (!isSupported) {
    return dt;
  }

  const metaData3D: DatasetThreeDimensionalMetaData = metaData.data['ds3DMetadata'];
  const shape = metaData3D?.shape;
  const dataFormat = effectiveDataFormat ?? metaData3D?.typeSpecificData['spectrumParameters']?.dataFormat;
  const quantityXid = metaData3D?.quantityXId ?? 0;
  const quantityX = quantities?.quantities.filter((quantity) => quantity.subscribeId === quantityXid)[0];

  const xValues = metaData3D?.xValues;
  const nth = metaData3D?.typeSpecificData['octaveParameters']?.octaveNth;
  const psychoType = metaData3D?.typeSpecificData['psychoParameters']?.psychoType;
  const cepstrumType = metaData3D?.typeSpecificData['cepstrumParameters']?.cepstrumType;
  const cohenClassType = metaData3D?.typeSpecificData['spectrumParameters']?.cohenParams?.cohenClassType;

  switch (dataType) {
    case DataType.Type_Thruput:
      dt = translate('Throughput');
      break;
    case DataType.Type_SlowThruput:
      dt = translate('Slow Throughput');
      break;
    case DataType.Type_ThruputCA:
      dt = translate('Throughput (CA)');
      break;
    case DataType.Type_TimeSeries:
      dt = translate('Time Series');
      break;
    case DataType.Type_TachoEdges:
      dt = translate('Tacho Edges');
      break;
  }

  switch (dataType) {
    case DataType.Type_FFTSpectrum:
      dt = determineFFTString(quantityX, xValues, shape, dataFormat);
      break;
    case DataType.Type_APSSpectrum:
      dt = determineAPSString(quantityX, xValues, shape);
      break;
    case DataType.Type_CPSSpectrum:
      dt = determineCPSString(quantityX, shape, dataFormat);
      break;
    case DataType.Type_PRSSpectrum:
      dt = determinePRSString(quantityX, shape, dataFormat);
      break;
    case DataType.Type_FRFSpectrum:
      if (quantityX?.name.includes('Order')) {
        dt = translate('Order transfer function');
      } else {
        dt = translate('Transfer function');
      }
      dt += expandShape(shape);
      dt += expandDataFormat(shape, dataFormat);
      break;
    case DataType.Type_Detector:
      dt = translate('Detector');
      break;
    case DataType.Type_SlowQuantity:
    case DataType.Type_SlowQuantityRef:
      dt = translate('Slow Quantity');
      break;
    case DataType.Type_OctaveSpectrum:
      switch (nth) {
        case 1:
          dt = translate('Octave');
          break;
        case 2:
          dt = translate('3rd Octave');
          break;
        default:
          dt = nth?.toString() + translate('th Octave');
      }
      switch (shape) {
        case Shape.Shape_Cut:
          switch (nth) {
            case 1:
              dt = translate('Octave channel');
              break;
            case 2:
              dt = translate('3rd Octave channel');
              break;
            default:
              dt = nth?.toString() + translate('th Octave channel');
          }
          break;
        case Shape.Shape_Bandpass:
          dt += ' ';
          dt += translate('band pass');
          break;
        case Shape.Shape_Overall:
          dt += ' ';
          dt += translate('overall level');
          break;
        case Shape.Shape_OpResult:
          dt += ' ';
          dt += translate('combination');
          break;
      }
      break;
    case DataType.Type_OctaveSRS:
      dt = translate('SRS');
      break;
    case DataType.Type_OctaveTransfer:
      switch (nth) {
        case 1:
          dt = translate('Octave transfer ratio');
          break;
        case 2:
          dt = translate('3rd Octave transfer ratio');
          break;
        default:
          dt = nth?.toString() + translate('th Octave transfer ratio');
      }
      break;
    case DataType.Type_Unknown:
      dt = translate('Analysis result');
      break;
    case DataType.Type_Wavelet:
      if (quantityX?.name.includes('Order')) {
        dt = translate('Order wavelet');
        dt += expandShape(shape);
      } else {
        dt = translate('Wavelet');
        dt += expandShape(shape);
      }
      break;
    case DataType.Type_Trajectory:
      dt = translate('Trajectory');
      break;
    case DataType.Type_Psycho:
      dt = determinePsychoTypeString(shape, psychoType);
      break;
    case DataType.Type_TimeBlock:
      dt = translate('Time block');
      dt += expandShape(shape);
      break;
    case DataType.Type_VarTimeBlock:
      dt = translate('Variable time block');
      dt += expandShape(shape);
      break;
    case DataType.Type_AutoCorrelation:
      dt = translate('Auto correlation');
      dt += expandShape(shape);
      break;
    case DataType.Type_Window:
      dt = translate('Window');
      dt += expandShape(shape);
      break;
    case DataType.Type_Cepstrum:
      if (cepstrumType === DatasetThreeDimensionalMetaData_CepstrumParams_CepstrumType.Complex_Cepstrum) {
        dt = translate('Complex Cepstrum');
      } else {
        dt = translate('Power Cepstrum');
      }
      dt += expandShape(shape);
      break;
    case DataType.Type_CrossCorrelation:
      dt = translate('Cross correlation');
      dt += expandShape(shape);
      break;
    case DataType.Type_ImpulseResponse:
      dt = translate('Impulse response');
      dt += expandShape(shape);
      break;
    case DataType.Type_CohenClass:
      if (
        cohenClassType ===
        DatasetThreeDimensionalMetaData_SpectrumParameters_CohenParams_CohenClassType.CohenClass_WignerVille
      ) {
        dt = translate('Wigner-Ville distribution');
      } else {
        dt = translate('Choi-Williams distribution');
      }
      dt += expandShape(shape);
      break;
    case DataType.Type_MultipleCoherence:
      if (quantityX?.name.includes('Order')) {
        dt = translate('Order multiple coherence');
      } else {
        dt = translate('Multiple coherence');
      }
      dt += expandShape(shape);
      break;
    case DataType.Type_Coherence:
      if (quantityX?.name.includes('Order')) {
        dt = translate('Order coherence');
      } else {
        dt = translate('Coherence');
      }
      dt += expandShape(shape);
      break;
    case DataType.Type_PartialCoherence:
      if (quantityX?.name.includes('Order')) {
        dt = translate('Order partial coherence');
      } else {
        dt = translate('Partial coherence');
      }
      dt += expandShape(shape);
      break;
  }

  return dt;
};
