import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { ProcessingPartialState } from './processing.reducer';
import { processingQuery } from './processing.selectors';
import {
  LoadAvailableFormulas,
  ChangeFormulaParameters,
  SelectFormula,
  StartProcessing,
  SetLateProcessingToken,
  ChangeFormulaInputSources,
  ChangeSelectedFormulaInputSources,
  ChangeSelectedFormulaParameters,
  CancelProcessing
} from './processing.actions';

import {
  ProcessingFormula,
  ProcessingRequestSource,
  CheckedProcessingInputStream,
  CheckedProcessingParameter
} from '../../processing/processing.types';
import { DatasetDescription } from '../../measurements/measurements.types';

@Injectable()
export class ProcessingFacade {
  constructor(private store: Store<ProcessingPartialState>) {}

  availableFormulas$ = this.store.pipe(select(processingQuery.getAvailableFormulas));
  availableFormulasLoading$ = this.store.pipe(select(processingQuery.getAvailableFormulasLoading));

  selectedFormula$ = this.store.pipe(select(processingQuery.getSelectedFormula));

  filteredProcessingDatasets$ = this.store.pipe(select(processingQuery.getFilteredDatasets));
  filteredProcessingDatasetsLoading$ = this.store.pipe(select(processingQuery.getFilteredDatasetsLoading));

  useLateProcessingToken$ = this.store.pipe(select(processingQuery.getUseLateProcessingToken));

  currentProcessing$ = this.store.pipe(select(processingQuery.getCurrentProcessing));
  currentProcessingProgress$ = this.store.pipe(select(processingQuery.getCurrentProcessingProgress));

  activeProcessingDataSources$ = this.store.pipe(select(processingQuery.getActiveProcessingDataSources));

  pollingState$ = this.store.pipe(select(processingQuery.getPollingState));
  isPolling$ = this.store.pipe(select(processingQuery.getIsPolling));

  loadAvailableFormulas() {
    this.store.dispatch(LoadAvailableFormulas());
  }

  selectFormula(formula: ProcessingFormula) {
    this.store.dispatch(SelectFormula({ formula }));
  }

  changeFormulaParameters(formulaId: string, parameters: CheckedProcessingParameter[]) {
    this.store.dispatch(ChangeFormulaParameters({ formulaId, parameters }));
  }

  changeSelectedFormulaParameters(parameters: CheckedProcessingParameter[]) {
    this.store.dispatch(ChangeSelectedFormulaParameters({ parameters }));
  }

  changeFormulaInputSources(formulaId: string, sources: CheckedProcessingInputStream[]) {
    this.store.dispatch(ChangeFormulaInputSources({ formulaId, sources }));
  }

  changeSelectedFormulaInputSources(
    sources: { sourceName: string; dataset: DatasetDescription | undefined /* i.e. remove the current dataset */ }[]
  ) {
    this.store.dispatch(ChangeSelectedFormulaInputSources({ sources }));
  }

  startProcessing(formula: ProcessingFormula, sources: ProcessingRequestSource[]) {
    this.store.dispatch(StartProcessing({ formula, sources }));
  }

  cancelProcessing(processingStatusUrl: string) {
    this.store.dispatch(CancelProcessing({ processingStatusUrl }));
  }

  setLateProcessingToken(useLateProcessingToken: boolean) {
    this.store.dispatch(SetLateProcessingToken({ useLateProcessingToken: useLateProcessingToken }));
  }
}
