import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClrDatagridSortOrder } from '@clr/angular';
import { FeatureFlagsFacade } from '@root/libs/feature-flags/src';
import { map, withLatestFrom } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { ContentCollectionFacade } from '../../../../content-collection/+state/content-collection.facade';
import { contentCollectionContentAttributesMapping } from '../../../../content-collection/content-collection.service';
import { ContentCollection, ContentCollectionItem } from '../../../../content-collection/content-collection.types';
import { AttributesFacade } from '../../../../shared/+state/attributes/attributes.facade';
import { UserFacade } from '../../../../user/+state/user.facade';

@Component({
  selector: 'cloud-ccd-measurements-table',
  templateUrl: './ccd-measurements-table.component.html',
  styleUrls: ['./ccd-measurements-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CcdMeasurementsTableComponent implements OnInit {
  @Input() selectedCCGuid$: BehaviorSubject<string | undefined>;
  @Input() selectedCC$: Observable<ContentCollection | undefined>;

  @Output() editCC: EventEmitter<ContentCollection> = new EventEmitter();
  @Output() deleteCC: EventEmitter<ContentCollection> = new EventEmitter();
  @Output() deleteCCItemBulk: EventEmitter<{
    itemsToDelete: ContentCollectionItem[];
    contentCollection: ContentCollection;
  }> = new EventEmitter();
  @Output() deleteCCItem: EventEmitter<{
    itemToDelete: ContentCollectionItem;
    contentCollection: ContentCollection;
  }> = new EventEmitter();
  @Output() shareCC: EventEmitter<ContentCollection> = new EventEmitter();
  @Output() declineCCShare: EventEmitter<ContentCollection> = new EventEmitter();

  activeCollectionGuid$: Observable<string | undefined>;

  selectedItems$: BehaviorSubject<ContentCollectionItem[]> = new BehaviorSubject([]);
  clrSortOrder = ClrDatagridSortOrder;

  columns$;

  constructor(
    public userFacade: UserFacade,
    public contentCollectionFacade: ContentCollectionFacade,
    public featureFlagFacade: FeatureFlagsFacade,
    private attributesFacade: AttributesFacade
  ) {}

  ngOnInit(): void {
    this.activeCollectionGuid$ = this.contentCollectionFacade.activeContentCollectionGuid$;

    const relevantAttributes$ = this.attributesFacade.availableDepotAttributes$
      .pipe(
        map((attribs) => {
          const relevantAttributes = {};
          Object.values(contentCollectionContentAttributesMapping).forEach((ccContentAttributeID) => {
            if (attribs) {
              const relevantAttribute = attribs.find((a) => a.idName === ccContentAttributeID);
              if (relevantAttribute) {
                relevantAttributes[ccContentAttributeID] = relevantAttribute;
              }
            }
          });
          return relevantAttributes;
        })
      )
      .subscribe();
  }

  activateContentCollection(collectionGuid: string, event: MouseEvent) {
    event.preventDefault();
    this.contentCollectionFacade.activateContentCollection(collectionGuid);
  }

  removeSingle(item: ContentCollectionItem, contentCollection: ContentCollection) {
    this.deleteCCItem.emit({ itemToDelete: item, contentCollection: contentCollection });
  }

  removeSelected(contentCollection: ContentCollection) {
    this.deleteCCItemBulk.emit({ itemsToDelete: this.selectedItems$.getValue(), contentCollection: contentCollection });
  }

  declineShare(contentCollection: ContentCollection) {
    this.declineCCShare.emit(contentCollection);
  }

  canShowEditDelete(contentCollection: ContentCollection): Observable<boolean> {
    return this.featureFlagFacade.featureValue$('CONTENT_COLLECTION_SHARE').pipe(
      withLatestFrom(this.userFacade.self$),
      map(([shareEnabled, user]) => (shareEnabled ? contentCollection.createdBy === user?.id : true))
    );
  }

  getSharedFromName(contentCollection: ContentCollection): Observable<string | undefined> {
    return this.userFacade.usersSelfCanSee$.pipe(
      map((users) => users.find((user) => user.id === contentCollection.createdBy)?.userName)
    );
  }
}
