import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { DepotSearchPartialState, DepotSearchSortDefinition } from './depot-search.reducer';
import { depotSearchQuery } from './depot-search.selectors';
import {
  PerformSearch,
  SetAggregateTreeWidth,
  AddSearchBarFilter,
  AddResultColumn,
  ModifySearchBarFilter,
  RemoveSearchBarFilter,
  ResetSearchBar,
  ViewMeasurementDetails,
  ViewMeasurementOverviewList,
  SetMarkedResultLine,
  SetNumberShownResultLines,
  SelectTreeNode,
  RemoveResultColumn,
  DeleteMeasurement,
  UndeleteMeasurement,
  SearchForOrder,
  LoadMoreResults,
  ToggleTreeNode,
  UnselectTreeNode,
  LoadChildAggregates,
  SetSelectedMeasurementIDs,
  RevertSearchAttributeChanges,
  SetAggregateTreeSelectedType,
  SetAggregateTreeSortingMethod,
  ActivateSearchBarFilter,
  DeactivateSearchBarFilter,
  ModifySearchText,
  SetSort,
  RestrictToActiveCC
} from './depot-search.actions';

import { v4 as uuid } from 'uuid';
import { ResultColumn, SearchResultEntry } from '../../shared/types/search.types';
import { AggregateTreeSortingMethod, AggregateType, TreeNode } from '../../shared/types/aggregateTree.types';
import { MeasurementDeleteOrUndeleteData } from '../depot-search.types';
import {
  DepotAttribute,
  SearchAttributeAndValue,
  SemanticDepotAttribute
} from '../../shared/+state/attributes/attributes.types';

@Injectable()
export class DepotSearchFacade {
  // -- Search request related --
  // searchText: current searchText
  searchText$ = this.store.pipe(select(depotSearchQuery.getSearchText));
  // DepotSearchParameters: All search parameters used for the latest search
  lastSearchParams$ = this.store.pipe(select(depotSearchQuery.getLastSearchParams));
  // Boolean: Should we only search in active CCs?;
  searchIsRestrictedToActiveCC$ = this.store.pipe(select(depotSearchQuery.getRestrictToActiveCC));
  // SearchAttributesAndValue[]: A list of all search bar filters
  searchBarFilters$ = this.store.pipe(select(depotSearchQuery.getSearchBarFilters));
  // string | null: Id of the attribute of the active search bar filter, null if none is active
  activeSearchBarFilterAttrId$ = this.store.pipe(select(depotSearchQuery.getActiveSearchBarFilterAttrId));
  sort$ = this.store.pipe(select(depotSearchQuery.getSort));
  // AggregateTreeDef[]: The definitions for aggregation trees (without actual tree node data!)
  aggregateTreeDefs$ = this.store.pipe(select(depotSearchQuery.getAggregateTreeDefs));
  // AggregateTree[]: The aggregate trees with the current data received from the cloud
  aggregateTrees$ = this.store.pipe(select(depotSearchQuery.getAggregateTrees));
  // AggregateTreeSortingMethod[]: Available Tree Sort Methods
  availableTreeSorts$ = this.store.pipe(select(depotSearchQuery.getAvailableAggregateTreeSortingMethods));

  selectedTreeType$ = this.store.pipe(select(depotSearchQuery.getSelectedAggregateTreeType));
  activeAggregateTreeSorting$ = this.store.pipe(select(depotSearchQuery.getActiveAggregateTreeSortingMethod));

  // Number: The width of the searchTreeAggreggate Tree
  aggregateTreeWidth$ = this.store.pipe(select(depotSearchQuery.getAggregateTreeWidth));
  // TreeNode: Node in the aggregate tree, that the user has currently selected
  selectedTreeNode$ = this.store.pipe(select(depotSearchQuery.getSelectedTreeNode));
  // TreeNode[]: List of aggregate tree nodes which were loaded lazily
  lazyLoadedNodes$ = this.store.pipe(select(depotSearchQuery.getLazyLoadedNodes));
  // Boolean: Did the user modify the search parameters in the GUI without starting a new search?
  searchInputModified$ = this.store.pipe(select(depotSearchQuery.getSearchInputModificationState));
  // Boolean: Did we load all secondary data (like depot attributes), that we are ready so POST search requests?
  depotSearchReady$ = this.store.pipe(select(depotSearchQuery.getDepotSearchReady));

  // -- Search response related --
  // DepotSearchResult: Latest search result that we got
  currentDepotSearchResult$ = this.store.pipe(select(depotSearchQuery.getDepotSearchResult));
  // Offset of last search result we got
  numberOfResultEntries$ = this.store.pipe(select(depotSearchQuery.getNumberOfResultEntries));
  // Boolean: Are we currently loading new search results from the cloud?
  currentDepotSearchIsInFlight$ = this.store.pipe(select(depotSearchQuery.getSelectedSearchIsInFlight));
  // Boolean: Are we currently loading new aggregates from the cloud?
  currentAggregateRequestIsInFlight$ = this.store.pipe(select(depotSearchQuery.getAggregateRequestIsInFlight));
  // Boolean: Do we have results of our very first search?
  initialSearchPerformed$ = this.store.pipe(select(depotSearchQuery.getInitialSearchPerformed));

  // -- Table View related --
  // ResultColumn[]: A list of all result columns that should be displayed in the search table
  resultColumns$ = this.store.pipe(select(depotSearchQuery.getResultColumns));
  // Boolean: Do we currently load another column of depot attributes for all search results?
  resultColumnIsLoading$ = this.store.pipe(select(depotSearchQuery.getResultColumnIsLoading));
  // Number: Number of currently shown result entries
  numberShownResultLines$ = this.store.pipe(select(depotSearchQuery.getNumberShownResultLines));
  // Number: Index of the last result line, that was not yet loaded (used for "LoadMoreResults" functionality)
  markedResultLine$ = this.store.pipe(select(depotSearchQuery.getMarkedResultLine));
  // string[]: List of all measurement IDs that are selected in the depot search table
  selectedMeasurementIDs$ = this.store.pipe(select(depotSearchQuery.getSelectedMeasurementIDs));

  // -- Detail View related --
  // SearchResultEntry: Result entry used in the measurement detail view
  detailSearchResultEntry$ = this.store.pipe(select(depotSearchQuery.getSelectedSearchResultEntry));
  // Boolean: Do we currently show the measurement detail view?
  showResultDetailView$ = this.store.pipe(select(depotSearchQuery.getResultViewShowState));

  // -- Related to indiviual search results
  // List of measurement IDs for which deletion requests are pending
  deletionsInProgess$ = this.store.pipe(select(depotSearchQuery.getDeletionsInProgess));
  // List of measurement IDs for which undeletion requests are pending
  undeletionsInProgess$ = this.store.pipe(select(depotSearchQuery.getUndeletionsInProgess));

  constructor(private store: Store<DepotSearchPartialState>) {}

  performSearch() {
    this.store.dispatch(PerformSearch({}));
  }

  performSearchWithoutHierarchyTreeReload() {
    this.store.dispatch(PerformSearch({ suppressHierarchyTreeReload: true }));
  }

  loadMoreResults() {
    this.store.dispatch(LoadMoreResults());
  }

  setAggregateTreeWidth(width: number) {
    this.store.dispatch(SetAggregateTreeWidth({ width: width }));
  }

  setSelectedAggregateTreeType(aggregateType: AggregateType) {
    this.store.dispatch(SetAggregateTreeSelectedType({ aggregateType: aggregateType }));
  }

  setAggregateTreeSortingMethod(method: AggregateTreeSortingMethod) {
    this.store.dispatch(SetAggregateTreeSortingMethod({ method: method }));
  }

  selectNode(node: TreeNode) {
    this.store.dispatch(SelectTreeNode({ node: node }));
  }

  unselectNode() {
    this.store.dispatch(UnselectTreeNode());
  }

  toggleNode(node: TreeNode, expanded: boolean) {
    this.store.dispatch(ToggleTreeNode({ node: node, expanded: expanded }));
  }

  loadNodeChildren(node: TreeNode) {
    this.store.dispatch(LoadChildAggregates({ node: node }));
  }

  addResultColumn(attribute: DepotAttribute | SemanticDepotAttribute) {
    this.store.dispatch(AddResultColumn({ attrib: attribute }));
  }

  removeResultColumn(columnToDelete: ResultColumn) {
    this.store.dispatch(RemoveResultColumn({ column: columnToDelete }));
  }

  modifySearchText(value: string) {
    this.store.dispatch(ModifySearchText({ value: value }));
  }

  addSearchBarFilter(attribute: DepotAttribute | SemanticDepotAttribute, value = '') {
    let typedValue: string | boolean | Date = value;

    let newSearchAttribute: SearchAttributeAndValue;

    switch (attribute.type) {
      case 'Boolean':
        if (value.toLocaleLowerCase() === 'true') {
          typedValue = true;
        } else {
          typedValue = false;
        }
        newSearchAttribute = {
          id: uuid(),
          attribute: attribute,
          searchAttributeBoolean: typedValue,
          exact_match: false
        };
        break;
      case 'String':
        newSearchAttribute = {
          id: uuid(),
          attribute: attribute,
          searchAttributeValue: typedValue,
          exact_match: false
        };
        break;
      case 'Date':
        newSearchAttribute = {
          id: uuid(),
          attribute: attribute,
          exact_match: false
        };
        break;
    }

    if (newSearchAttribute) {
      this.store.dispatch(AddSearchBarFilter({ attribAndValue: newSearchAttribute }));
    }
  }

  removeSearchBarFilter(attribute: SearchAttributeAndValue) {
    this.store.dispatch(RemoveSearchBarFilter({ attribAndValue: attribute }));
  }

  resetSearchBar() {
    this.store.dispatch(ResetSearchBar());
  }

  modifySearchBarFilter(attribute: SearchAttributeAndValue) {
    const attrib: SearchAttributeAndValue = JSON.parse(JSON.stringify(attribute));
    this.store.dispatch(ModifySearchBarFilter({ attribAndValue: attrib }));
  }

  revertSearchBarFilterChanges() {
    this.store.dispatch(RevertSearchAttributeChanges());
  }

  activateSearchBarFilter(attributeId: string) {
    this.store.dispatch(ActivateSearchBarFilter({ attributeId: attributeId }));
  }

  deactivateSearchBarFilter() {
    this.store.dispatch(DeactivateSearchBarFilter());
  }

  restrictToActiveCC(restrictToActiveCC: boolean) {
    this.store.dispatch(RestrictToActiveCC({ restrictToActiveCC }));
  }

  deleteMeasurements(measurementData: MeasurementDeleteOrUndeleteData[]) {
    for (const measurement of measurementData) {
      this.store.dispatch(DeleteMeasurement({ measurement: measurement }));
    }
  }

  undeleteMeasurements(measurementData: MeasurementDeleteOrUndeleteData[]) {
    for (const measurement of measurementData) {
      this.store.dispatch(UndeleteMeasurement({ measurement: measurement }));
    }
  }

  performSearchForOrder(orderId: string) {
    console.log('facade - orderID: ', orderId);
    this.store.dispatch(SearchForOrder({ orderId }));
  }

  viewMeasurementDetails(SearchResultEntry: SearchResultEntry) {
    this.store.dispatch(ViewMeasurementDetails({ result: SearchResultEntry }));
  }

  viewMeasurementOverviewList() {
    this.store.dispatch(ViewMeasurementOverviewList());
  }

  setNumberShownResultLines(windowHeigt: number) {
    const lines: number = Math.floor((windowHeigt - 223) / 36);
    this.store.dispatch(SetNumberShownResultLines({ numberOfResultLines: lines }));
  }
  markResultLine(line: number) {
    this.store.dispatch(SetMarkedResultLine({ markedResultLine: line }));
  }

  setSelectedMeasurementIDs(measurementIDs: string[]) {
    this.store.dispatch(SetSelectedMeasurementIDs({ measurementIDs: measurementIDs }));
  }

  setSearchSort(sort?: DepotSearchSortDefinition[]) {
    this.store.dispatch(SetSort({ sort }));
  }
}
