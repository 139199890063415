import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { availableLanguagesStrings } from '../../app.locales';

export const NO_VALUE = '-';

@Pipe({
  name: 'formatDateTime',
  pure: false
})
export class FormatDateTimePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string | number | Date | undefined): string {
    if (value === undefined || value === null) {
      return NO_VALUE;
    }
    // The formatting is mostly the Angular default "short", but with time zone information
    const currentLanguage = this.translate.currentLang as availableLanguagesStrings;
    try {
      if (currentLanguage === 'en') {
        return formatDate(value, 'M/d/y, h:mma ZZZZZ', 'en');
      } else if (currentLanguage === 'de') {
        return formatDate(value, 'd.M.y, HH:mmZZZZZ', 'de');
      } else if (currentLanguage === 'ja') {
        return formatDate(value, 'y年M月d日, HH:mmZZZZZ', 'ja');
      } else {
        return formatDate(value, 'M/d/y, h:mm a ZZZZZ', currentLanguage);
      }
    } catch (error) {
      console.error('There is a problem formatting the date =', value, '- the error is', error);
      return NO_VALUE;
    }
  }
}
