import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { UsersPartialState } from './users.reducer';
import { usersQuery } from './users.selectors';
import { LoadUsers, DeleteUserGroups, AddGroupsToUser } from './users.actions';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UsersFacade {
  users$ = this.store.pipe(select(usersQuery.getUsers));
  usersFull$ = this.store.pipe(select(usersQuery.getUsersFull));
  loaded$ = this.store.pipe(select(usersQuery.getLoaded));
  isActiveFilter$ = new BehaviorSubject<boolean>(true);

  constructor(private store: Store<UsersPartialState>) {}

  getUsers() {
    this.store.dispatch(LoadUsers());
  }

  deleteUserGroups(userId: string, groupIds: string[]) {
    this.store.dispatch(DeleteUserGroups({ userId, groupIds }));
  }

  addGroupsToUser(userId: string, groupIds: string[]) {
    this.store.dispatch(AddGroupsToUser({ userId, groupIds }));
  }
}
