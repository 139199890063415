import { Component, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppFacade } from '../../../../+state/app.facade';
import { NO_VALUE } from '../../../pipes/format-datetime.pipe';
import { PanelContent } from '../panel-detail-content.component';

type KVList = Record<string, string | number | boolean>;

@Component({
  selector: 'cloud-panel-detail-key-value-display',
  templateUrl: './panel-detail-key-value-display.component.html',
  styleUrls: ['./panel-detail-key-value-display.component.css']
})
export class PanelDetailKeyValueDisplayComponent implements OnInit, OnChanges {
  @Input() content: PanelContent;

  rawKeyValues$: BehaviorSubject<KVList> = new BehaviorSubject<KVList>({});
  keyValuesToBeDisplayed$: Observable<KVList> = new Observable<KVList>();

  isEmpty$: Observable<boolean> = new Observable<boolean>();
  hasHiddenKeyValuePairs$: Observable<boolean> = new Observable<boolean>();

  constructor(public appFacade: AppFacade, public translate: TranslateService) {}

  ngOnInit() {
    this.keyValuesToBeDisplayed$ = combineLatest([this.rawKeyValues$, this.appFacade.hideEmptyValues$]).pipe(
      map(([keyValueList, hideEmptyValues]) => {
        const cleanKeyValueList: KVList = JSON.parse(JSON.stringify(keyValueList));
        Object.entries(cleanKeyValueList).forEach((entry) => {
          const key = entry[0];
          const value = entry[1];
          const emptyValue = !value || value === NO_VALUE;
          if (emptyValue && hideEmptyValues) {
            delete cleanKeyValueList[key];
          } else if (!value) {
            cleanKeyValueList[key] = NO_VALUE;
          }
        });
        return cleanKeyValueList;
      })
    );

    this.isEmpty$ = this.keyValuesToBeDisplayed$.pipe(map((kv) => Object.keys(kv).length === 0));
    this.hasHiddenKeyValuePairs$ = combineLatest([this.appFacade.hideEmptyValues$, this.rawKeyValues$]).pipe(
      map(([hideEmpty, raw]) => {
        return hideEmpty && Object.keys(raw).length > 0;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['content']) {
      const content = changes['content'].currentValue as PanelContent;
      if (content) {
        this.rawKeyValues$.next(content.keyValueList);
      }
    }
  }
}
