import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { FeatureFlagsFacade } from '@root/libs/feature-flags/src';
import { CommonFacade } from '@vas/common';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  filter,
  firstValueFrom,
  map,
  take,
  withLatestFrom
} from 'rxjs';
import { AppFacade } from '../../../+state/app.facade';
import { ContentCollectionFacade } from '../../../content-collection/+state/content-collection.facade';
import { ContentCollection } from '../../../content-collection/content-collection.types';
import { UserFacade } from '../../../user/+state/user.facade';
import { ContentCollectionItem } from './../../../content-collection/content-collection.types';
import { firstValueTakeOne } from '../../../shared/utility-functions/firstValueTakeOne';
import { DepotSearchFacade } from '../../../depot-search/+state/depot-search.facade';
import { ClickOutsideExceptions } from './click-outside-exceptions';

@Component({
  selector: 'cloud-content-collection-dropdown',
  templateUrl: './content-collection-dropdown.component.html',
  styleUrls: ['./content-collection-dropdown.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentCollectionDropdownComponent implements OnInit, OnDestroy {
  @Output() editCC: EventEmitter<ContentCollection> = new EventEmitter();
  @Output() deleteCC: EventEmitter<ContentCollection> = new EventEmitter();
  @Output() declineCCShare: EventEmitter<ContentCollection> = new EventEmitter();

  shareActive = false;

  showDropdown$ = new BehaviorSubject<boolean>(false);

  hasContentCollections$: Observable<boolean>;
  contentCollectionItemsLoaded$: Observable<boolean>;

  selectedCC$: Observable<ContentCollection | undefined>;
  selectedCCGuid$: BehaviorSubject<string | undefined> = new BehaviorSubject(undefined);

  activeCollectionGuid$: Observable<string | undefined>;
  activeCollection$: Observable<ContentCollection | undefined>;
  activeCollectionName$: Observable<string | undefined>;

  subs: Subscription[] = [];

  constructor(
    public contentCollectionFacade: ContentCollectionFacade,
    public commonFacade: CommonFacade,
    public appFacade: AppFacade,
    private depotSearchFacade: DepotSearchFacade,
    public userFacade: UserFacade,
    public featureFlagFacade: FeatureFlagsFacade
  ) {
    this.featureFlagFacade
      .featureValue$('CONTENT_COLLECTION_SHARE')
      .pipe(take(1))
      .subscribe((value) => {
        if (value) {
          this.userFacade.getUsersSelfCanSee();
          this.userFacade.getRolesSelfCanSee();
        }
      })
      .unsubscribe();
  }

  ngOnInit(): void {
    this.activeCollection$ = this.contentCollectionFacade.activeContentCollection$;
    this.activeCollectionName$ = this.contentCollectionFacade.activeContentCollection$.pipe(
      map((collection) => collection?.name)
    );
    this.activeCollectionGuid$ = this.contentCollectionFacade.activeContentCollectionGuid$;

    this.selectedCC$ = combineLatest([this.selectedCCGuid$, this.contentCollectionFacade.contentCollections$]).pipe(
      map(([id, contentCollection]) => contentCollection.find((item) => item.guid === id))
    );

    this.subs.push(
      this.activeCollectionGuid$.pipe(filter((value) => !!value)).subscribe((guid) => {
        guid && this.selectedCCGuid$.next(guid);
      }),
      this.showDropdown$
        .pipe(
          filter((isOpen) => isOpen === true),
          withLatestFrom(this.activeCollectionGuid$, this.selectedCCGuid$)
        )
        .subscribe(([_, activeCC, selectedCC]) => {
          if (activeCC !== selectedCC) {
            this.selectedCCGuid$.next(activeCC);
          }
        })
    );

    this.hasContentCollections$ = this.contentCollectionFacade.contentCollections$.pipe(
      map((collections) => collections && collections.length > 0)
    );
    this.contentCollectionItemsLoaded$ = this.contentCollectionFacade.activeContentCollectionLoaded$;
  }

  activateContentCollection(collectionGuid: string, event: MouseEvent) {
    event.preventDefault();
    this.contentCollectionFacade.activateContentCollection(collectionGuid);
  }

  async activateAndSearch(event: MouseEvent) {
    const activeContentCollectionGUID = await firstValueTakeOne(this.selectedCCGuid$);
    if (activeContentCollectionGUID) {
      this.activateContentCollection(activeContentCollectionGUID, event);
      this.depotSearchFacade.restrictToActiveCC(true);
      this.showDropdown$.next(false);
    }
  }

  addNewCollectionClicked() {
    this.contentCollectionFacade.showDialog('createCollection');
  }

  deleteCCItemClicked(data: { itemToDelete: ContentCollectionItem; contentCollection: ContentCollection }) {
    this.contentCollectionFacade.deleteContentCollectionItem(data.itemToDelete, data.contentCollection.guid);
  }

  deleteCCItemBulkClicked(data: { itemsToDelete: ContentCollectionItem[]; contentCollection: ContentCollection }) {
    data.itemsToDelete.forEach((item) =>
      this.contentCollectionFacade.deleteContentCollectionItem(item, data.contentCollection.guid)
    );
  }

  shareCCClicked(cc: ContentCollection) {
    this.shareActive = true;
  }

  declineCCShareClicked(cc: ContentCollection) {
    this.declineCCShare.emit(cc);
  }

  backClicked() {
    this.shareActive = false;
  }

  async handleDropDownToggle(event: MouseEvent, closeOnly = false) {
    // Exclude from ng-click-outside can not handle some exceptions for some reason
    if (this.hasExceptions(event)) {
      return;
    }

    const showDropdown = await firstValueFrom(this.showDropdown$.pipe(take(1)));

    // clicking outside the dropdown should always close it
    if (closeOnly) {
      this.showDropdown$.next(false);
    } else {
      this.showDropdown$.next(!showDropdown);
    }

    this.shareActive = false;

    // If the dropdown is open, we need to prevent the click event from bubbling up to the document
    event.stopPropagation();
  }

  async handleDrop() {
    const selectedContentCollection = await firstValueFrom(this.selectedCC$.pipe(take(1)));
    const activeContentCollection = await this.getActiveContentCollection();

    const targetContentCollection = selectedContentCollection ?? activeContentCollection;

    if (!targetContentCollection?.myAccessInfo?.canAddItems) {
      this.appFacade.showError(
        'You have no rights to add items to this content collection',
        _('CONTENTCOLLECTION.GENERAL.NORIGHTSTOADD')
      );
      return;
    }

    if (targetContentCollection) {
      const draggedItems = await firstValueFrom(
        this.contentCollectionFacade.contentCollectionItemsDragged$.pipe(take(1))
      );

      const newItems = draggedItems.filter(
        (newItem) =>
          targetContentCollection.items?.find((ccItem) => newItem.measurementId === ccItem.measurementId) === undefined
      );

      newItems.length > 0 &&
        this.contentCollectionFacade.addMultipleContentCollectionItems(newItems, targetContentCollection.guid);
    } else {
      this.appFacade.showError('No content collection is defined or active', _('CONTENTCOLLECTION.GENERAL.UNDEFINED'));
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  private async getActiveContentCollection() {
    const activeContentCollectionGuid = await firstValueFrom(
      this.contentCollectionFacade.activeContentCollectionGuid$.pipe(take(1))
    );
    const contentCollections = await firstValueFrom(this.contentCollectionFacade.contentCollections$.pipe(take(1)));
    return contentCollections.find((cc) => cc.guid === activeContentCollectionGuid);
  }

  private hasExceptions(event: MouseEvent) {
    return Object.values(ClickOutsideExceptions).some((ex) => event.target?.['classList']?.contains?.(ex));
  }
}
