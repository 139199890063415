import { UserInfo } from './../../../../../user/user.type';

import { createAction, props } from '@ngrx/store';

export enum UsersActionTypes {
  LoadUsers = '[Users] Load Users',
  UsersLoaded = '[Users] Users Loaded',
  LoadVibroLicenseUsers = '[Users] Load Vibro License Users',
  VibroLicenseUsersLoaded = '[Users] Vibro License Users Loaded',
  DeleteUserGroups = '[Users] Delete User Groups',
  DeleteUserGroupsSuccess = '[Users] User Groups Deleted',
  AddGroupsToUser = '[Users] Add Group To User',
  AddGroupsToUserSuccess = '[Users] AddGroupsToUserSuccess'
}

export const LoadUsers = createAction(UsersActionTypes.LoadUsers);
export const UsersLoaded = createAction(UsersActionTypes.UsersLoaded, props<{ users: UserInfo[] }>());

export const LoadVibroLicenseUsers = createAction(UsersActionTypes.LoadVibroLicenseUsers);
export const VibroLicenseUsersLoaded = createAction(
  UsersActionTypes.VibroLicenseUsersLoaded,
  props<{ usersIDs: string[] }>()
);

export const DeleteUserGroups = createAction(
  UsersActionTypes.DeleteUserGroups,
  props<{ userId: string; groupIds: string[] }>()
);
export const DeleteUserGroupsSuccess = createAction(
  UsersActionTypes.DeleteUserGroupsSuccess,
  props<{ userId: string; groups: string[] }>()
);
export const AddGroupsToUser = createAction(
  UsersActionTypes.AddGroupsToUser,
  props<{ userId: string; groupIds: string[] }>()
);
export const AddGroupsToUserSuccess = createAction(
  UsersActionTypes.AddGroupsToUserSuccess,
  props<{ userId: string; groups: string[] }>()
);
