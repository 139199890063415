<section id="tplHeader">
  <h3>{{ 'APP.THIRDPARTYLICENSES' | translate }}</h3>
  <span id="tplLoading" *ngIf="active" class="spinner spinner-inline"> {{ 'APP.LOADING' | translate }} </span>
</section>

<section x-ms-format-detection="phone" id="tplBody">
  <clr-tabs>
    <clr-tab>
      <button clrTabLink>PAK cloud</button>
      <clr-tab-content *clrIfActive>
        <a
          [href]="featureFlagFacade.featureValue$('CLOUDTPL') | async"
          target="_BLANK"
          rel="noopener noreferrer"
          *ngIf="featureFlagFacade.featureIsSet$('CLOUDTPL') | async"
          >{{ 'APP.LISTOFTPL' | translate }}</a
        >
      </clr-tab-content>
    </clr-tab>
    <clr-tab>
      <button clrTabLink>PAK cloud WebApp</button>
      <clr-tab-content *clrIfActive>
        <span *ngIf="!active">
          <div style="white-space: pre-wrap">{{ licenses.intro }}</div>
          <details class="tpldetails" *ngFor="let license of licenses.licenses | keyvalue">
            <summary>{{ license.key }}</summary>
            <div class="tplindent">
              <p>
                {{ 'License Type: ' + license.value.type }}
              </p>
              <p>
                {{ 'Repository: ' + license.value.repo }}
              </p>
              <p style="white-space: pre-wrap">
                {{ license.value.license }}
              </p>
            </div>
          </details>
        </span>
      </clr-tab-content>
    </clr-tab>
  </clr-tabs>
</section>
