import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContentCollectionItem } from '../content-collection.types';
import { CONTENTCOLLECTION_FEATURE_KEY, ContentCollectionState } from './content-collection.reducer';

const getContentCollectionState = createFeatureSelector<ContentCollectionState>(CONTENTCOLLECTION_FEATURE_KEY);

const getContentCollectionUI = createSelector(getContentCollectionState, (state: ContentCollectionState) => state.ui);
const getContentCollectionActiveDialog = createSelector(getContentCollectionUI, (ui) => ui.showDialog);
const getCreateContentCollectionPrepItems = createSelector(getContentCollectionUI, (ui) => ui.itemsForCreation);
const getContentCollectionItemsDragged = createSelector(getContentCollectionUI, (ui) => ui.itemsDragged);

const contentCollectionsLoaded = createSelector(
  getContentCollectionState,
  (state: ContentCollectionState) => state.loaded
);

const getError = createSelector(getContentCollectionState, (state: ContentCollectionState) => state.error);

const getAllContentCollections = createSelector(
  getContentCollectionState,
  contentCollectionsLoaded,
  (state: ContentCollectionState, isLoaded) => {
    return isLoaded ? state.contentCollections : [];
  }
);

const getOwnContentCollections = createSelector(
  getContentCollectionState,
  contentCollectionsLoaded,
  (state: ContentCollectionState, isLoaded) => {
    return isLoaded ? state.contentCollections.filter((c) => !c.isShared) : [];
  }
);

const getSharedContentCollections = createSelector(
  getContentCollectionState,
  contentCollectionsLoaded,
  (state: ContentCollectionState, isLoaded) => {
    return isLoaded ? state.contentCollections.filter((c) => c.isShared) : [];
  }
);

const getActiveContentCollectionGuid = createSelector(getContentCollectionState, (state: ContentCollectionState) =>
  state.activeContentCollectionGuid ? state.activeContentCollectionGuid : undefined
);

const getActiveContentCollection = createSelector(
  getContentCollectionState,
  getActiveContentCollectionGuid,
  (state: ContentCollectionState, activeCollectionGuid) => {
    if (activeCollectionGuid) {
      return state.contentCollections.find((c) => c.guid === activeCollectionGuid);
    }
    return undefined;
  }
);

const getPakRefAvailability = createSelector(
  getActiveContentCollection,
  contentCollectionsLoaded,
  (activeCollection, isLoaded) => {
    if (!isLoaded) {
      return 'unknown';
    }
    if (activeCollection?.items) {
      return activeCollection.items.some((item) => item.pakRef) ? 'available' : 'unavailable';
    }
    return 'unavailable';
  }
);

const getActiveContentCollectionLoaded = createSelector(
  getContentCollectionState,
  contentCollectionsLoaded,
  (state: ContentCollectionState, isLoaded) => {
    return isLoaded && state.itemsLoading.length === 0;
  }
);

const getPakCopySuccess = createSelector(
  getContentCollectionState,
  (state: ContentCollectionState) => state.pakCopySuccess
);

const getItemInActiveContentCollection = (item: ContentCollectionItem) => {
  return createSelector(
    getContentCollectionState,
    getActiveContentCollection,
    (state: ContentCollectionState, activeContentCollection) => {
      return (
        activeContentCollection?.items?.some((currentItemInCollection) => {
          return currentItemInCollection.measurementId === item.measurementId;
        }) ?? false
      );
    }
  );
};

export const contentCollectionQuery = {
  getContentCollectionActiveDialog,
  getCreateContentCollectionPrepItems,
  contentCollectionsLoaded,
  getError,
  getAllContentCollections,
  getSharedContentCollections,
  getOwnContentCollections,
  getActiveContentCollectionGuid,
  getActiveContentCollection,
  getPakRefAvailability,
  getActiveContentCollectionLoaded,
  getPakCopySuccess,
  getItemInActiveContentCollection,
  getContentCollectionItemsDragged
};
