import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { LicensesPartialState } from './licenses.reducer';
import { licensesQuery } from './licenses.selectors';
import { AddLicensesToRole, LoadLicenses, RemoveLicensesFromRole } from './licenses.actions';

@Injectable({ providedIn: 'root' })
export class LicensesFacade {
  licenses$ = this.store.pipe(select(licensesQuery.getLicenses));
  loaded$ = this.store.pipe(select(licensesQuery.getLoaded));

  constructor(private store: Store<LicensesPartialState>) {}

  getLicenses() {
    this.store.dispatch(LoadLicenses());
  }

  addLicensesToRole(roleId: string, licenses: string[]) {
    this.store.dispatch(AddLicensesToRole({ roleId, licenses }));
  }

  removeLicensesFromRole(roleId: string, licenses: string[]) {
    this.store.dispatch(RemoveLicensesFromRole({ roleId, licenses }));
  }
}
