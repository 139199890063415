import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as UserAction from './user.actions';
import { UserService } from '../user.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { createAppError } from '../../app.factories';

import { Store } from '@ngrx/store';

import { UserPartialState } from './user.reducer';
import { fetch } from '@nrwl/angular';
import { LicensesService } from '../../admin/admin/license/services/licenses.service';
import { EMPTY, from } from 'rxjs';

export const loadUser$ = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService)) => {
    return actions$.pipe(
      ofType(UserAction.LoadUserSelf),
      fetch({
        run: (action: ReturnType<typeof UserAction.LoadUserSelf>, state: UserPartialState) => {
          return userService.getUser().pipe(map((user) => UserAction.UserLoaded({ user: user })));
        },

        onError: (action: ReturnType<typeof UserAction.LoadUserSelf>, error) => {
          return createAppError(error);
        }
      })
    );
  },
  { functional: true }
);

export const loadUsersSelfCanSee$ = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService)) => {
    return actions$.pipe(
      ofType(UserAction.LoadUsersSelfCanSee),
      fetch({
        run: (action: ReturnType<typeof UserAction.LoadUsersSelfCanSee>, state: UserPartialState) => {
          return from(userService.getUsersSelfCanSee()).pipe(
            map((users) => {
              return UserAction.UsersSelfCanSeeLoaded({ users });
            })
          );
        },

        onError: (action: ReturnType<typeof UserAction.LoadUsersSelfCanSee>, error) => {
          return createAppError(error);
        }
      })
    );
  },
  { functional: true }
);

export const loadRolesSelfCanSee$ = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService)) => {
    return actions$.pipe(
      ofType(UserAction.LoadRolesSelfCanSee),
      fetch({
        run: (action: ReturnType<typeof UserAction.LoadRolesSelfCanSee>, state: UserPartialState) => {
          return from(userService.getRolesSelfCanSee()).pipe(
            map((roles) => {
              return UserAction.RolesSelfCanSeeLoaded({ roles });
            })
          );
        },

        onError: (action: ReturnType<typeof UserAction.LoadRolesSelfCanSee>, error) => {
          return createAppError(error);
        }
      })
    );
  },
  { functional: true }
);

export const initUserAgent$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(UserAction.LoadUserSelf),
      map(() => {
        return UserAction.AgentIdentified({
          os: navigator.platform
        });
      }),
      catchError((error, caught) => {
        store.dispatch(createAppError(error));
        return caught;
      })
    );
  },
  { functional: true }
);

export const LoadMyLicenses$ = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(UserAction.UserLoaded),
      map(() => UserAction.LoadMyLicenses())
    );
  },
  { functional: true }
);

export const myLicensesLoaded$ = createEffect(
  (actions$ = inject(Actions), licensesService = inject(LicensesService), store = inject(Store)) => {
    return actions$.pipe(
      ofType(UserAction.LoadMyLicenses),
      switchMap(() => {
        return licensesService.getMyLicenses().pipe(
          map((myLicenses) => UserAction.MyLicensesLoaded({ myLicenses })),
          catchError((error) => {
            store.dispatch(createAppError(error));
            return EMPTY;
          })
        );
      })
    );
  },
  { functional: true }
);
