import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GROUPS_FEATURE_KEY, GroupsState } from './groups.reducer';

const getGroupsState = createFeatureSelector<GroupsState>(GROUPS_FEATURE_KEY);

const getLoaded = createSelector(getGroupsState, (state: GroupsState) => {
  return state.loaded;
});

const getGroups = createSelector(getGroupsState, getLoaded, (state: GroupsState, isLoaded) => {
  return isLoaded ? state.groups : undefined;
});

const getError = createSelector(getGroupsState, (state: GroupsState) => state.error);

export const groupsQuery = {
  getLoaded,
  getError,
  getGroups
};
