import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom, take } from 'rxjs';
import { GroupsFacade } from './+state/groups/groups.facade';
import { LicensesFacade } from './+state/licenses/licenses.facade';
import { RolesFacade } from './+state/roles/roles.facade';
import { UsersFacade } from './+state/users/users.facade';
import { LicensingSearchService } from './services/licensing-search.service';

@Component({
  selector: 'cloud-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LicenseComponent implements OnInit {
  constructor(
    public usersFacade: UsersFacade,
    public groupsFacade: GroupsFacade,
    public rolesFacade: RolesFacade,
    public licensesFacade: LicensesFacade,
    public searchService: LicensingSearchService,
    public router: Router
  ) {}

  ngOnInit() {
    this.usersFacade.getUsers();
    this.groupsFacade.getGroups();
    this.rolesFacade.getRoles();
    this.licensesFacade.getLicenses();
  }

  public async toggleActiveUsers() {
    const isActiveFilter = await firstValueFrom(this.usersFacade.isActiveFilter$.pipe(take(1)));
    this.usersFacade.isActiveFilter$.next(!isActiveFilter);
  }
}
