import { Injectable } from '@angular/core';
import { AppFacade } from '../+state/app.facade';
import { OperatorFunction } from 'rxjs';
import { withLatestFrom, tap, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DebugService {
  constructor(private appFacade: AppFacade) {}

  pipeLog = (content) => this.pipeDebug(content, 'log');
  pipeWarn = (content) => this.pipeDebug(content, 'warn');
  pipeError = (content) => this.pipeDebug(content, 'error');

  pipeDebug<T>(message?: string, level: 'log' | 'warn' | 'error' = 'log'): OperatorFunction<T, T> {
    const result = (input$: any) =>
      input$.pipe(
        withLatestFrom(this.appFacade.isDebugOrDev$),
        filter(([_, isDebugOrDev]) => isDebugOrDev),
        tap(([e, _]) => {
          switch (level) {
            case 'log':
              console.log(message, e);
              break;
            case 'warn':
              console.warn(message, e);
              break;
            case 'error':
              console.error(message, e);
              break;
            default:
              break;
          }
        })
      );
    return result;
  }
}
