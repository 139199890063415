import { UserInfo } from './../../../../../user/user.type';

import * as UsersAction from './users.actions';
import { createReducer, Action } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';

export const USERS_FEATURE_KEY = 'users';

export interface UsersState {
  users: UserInfo[];
  loaded: boolean;
  error?: any;
}

export interface UsersPartialState {
  readonly [USERS_FEATURE_KEY]: UsersState;
}

export const initialUsers: UserInfo[] = [];

export const initialState: UsersState = {
  users: initialUsers,
  loaded: false
};

const UsersReducer = createReducer(
  initialState,
  immerOn(UsersAction.UsersLoaded, (state, { users }) => {
    state.users = users;
    state.loaded = true;
  }),
  immerOn(UsersAction.VibroLicenseUsersLoaded, (state, { usersIDs }) => {
    state.users = state.users.map((user) => {
      user.hasVibroLicense = usersIDs.includes(user.id);
      return user;
    });
  }),
  immerOn(UsersAction.DeleteUserGroupsSuccess, (state, { userId, groups }) => {
    const user = state.users.find((user) => user.id === userId);
    if (user) {
      user.groups = user.groups.filter((group) => !groups.find((gr) => group === gr));
    }
  }),
  immerOn(UsersAction.AddGroupsToUserSuccess, (state, { userId, groups }) => {
    const user = state.users.find((user) => user.id === userId);
    if (user) {
      user.groups = [...user.groups, ...groups];
    }
  })
);

export function reducer(state: UsersState | undefined, action: Action) {
  return UsersReducer(state, action);
}
