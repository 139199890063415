import * as fromRouter from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CLOUDROUTER_FEATURE_KEY, CloudRouterState } from './router.reducer';

export const NGROUTER_FEATURE_KEY = 'router';

export interface RouterPartialState {
  readonly [NGROUTER_FEATURE_KEY]: fromRouter.RouterReducerState;
}
const getNgRouterState = createFeatureSelector<fromRouter.RouterReducerState>(NGROUTER_FEATURE_KEY);
// const getRouteParams = createSelector(
//   getNgRouterState,
//   (routerReducerState) => routerReducerState?.state?.root?.queryParams
// );
// const getRouteParam = (param: string) => {
//   return createSelector(getRouteParams, (params) => params[param]);
// };

const getRootRoute = createSelector(
  getNgRouterState,
  (routerReducerState) => routerReducerState?.state?.root?.firstChild?.routeConfig?.path
);

const getRouterState = createFeatureSelector<CloudRouterState>(CLOUDROUTER_FEATURE_KEY);

const getCurrentNavigationErrors = createSelector(getRouterState, (routerState) => routerState.userNavigation?.errors);

const getCurrentNavigationInfos = createSelector(getRouterState, (routerState) => routerState.userNavigation?.infos);

const getCurrentNavigationId = createSelector(getRouterState, (routerState) => routerState.userNavigation?.id);

const getPersistedQueryParams = createSelector(getRouterState, (routerState) => routerState.routerPersistance);

export const routerQuery = {
  // getNgRouterState,
  // getRouteParams,
  // getRouteParam,
  getRootRoute,
  getCurrentNavigationErrors,
  getCurrentNavigationInfos,
  getCurrentNavigationId,
  getPersistedQueryParams
};
