import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions, concatLatestFrom } from '@ngrx/effects';
import { OrderSearchSortDefinition, WorkspacePartialState } from './workspace.reducer';
import {
  catchError,
  delay,
  filter,
  map,
  mergeMap,
  pairwise,
  startWith,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import * as WorkspaceActions from './workspace.actions';
import * as OrderActions from '../../order-management/+state/orders.actions';
import * as AppActions from '../../+state/app.actions';
import { createAppError } from '../../app.factories';
import { WorkspaceService } from '../workspace.service';
import { Action, Store } from '@ngrx/store';
import {
  AttributesConfig,
  OrderTableConfig,
  PassByNormConfiguration,
  WorkspaceConfigIdentifier,
  WORKSPACE_TAG_TYPE_NAME
} from '../../workspace/config/workspace.config.types';
import { WorkspaceFacade } from './workspace.facade';
import { combineLatest, zip } from 'rxjs';
import { AppFacade } from '../../+state/app.facade';
import { ConfigurationTypeIdentifiers } from '../workspace.types';
import { fetch } from '@nrwl/angular';

import * as OrdersSearchActions from '../../order-management/+state/orders-search.actions';
import * as AttributesActions from '../../shared/+state/attributes/attributes.actions';
import { LS_USER_ORDER_TABLE_COLUMN_ORDER, LS_USER_PREFERRED_WORKSPACE } from '../../app.constants';
import { exhaustiveMatchingGuard } from '../../shared/utility-functions/exhaustiveMatchingGuard';

@Injectable()
export class WorkspaceEffects {
  getWorkspaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.GetWorkspaces),
      fetch({
        run: () => {
          return this.workspaceService
            .getWorkspaces()
            .pipe(map((workspaces) => WorkspaceActions.WorkspacesReceived({ receivedWorkspaces: workspaces })));
        },

        onError: (action: ReturnType<typeof WorkspaceActions.GetWorkspaces>, error: Error) => {
          return createAppError(error, true);
        }
      })
    )
  );

  getWorkspaceType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.SelectedWorkspaceChanged),
      fetch({
        run: (action: ReturnType<typeof WorkspaceActions.SelectedWorkspaceChanged>) => {
          return this.workspaceService.getWorkspaceTags(action.workspaceId).pipe(
            map((tags) => tags.filter((tag) => tag.tagTypeName === WORKSPACE_TAG_TYPE_NAME)),
            map((tags) => {
              let workspaceType = WorkspaceConfigIdentifier.BASE;
              for (const wid in WorkspaceConfigIdentifier) {
                if (wid) {
                  const foundTag = tags.find((t) => t.value.toLocaleLowerCase() === wid.toLocaleLowerCase());
                  if (foundTag) {
                    workspaceType = WorkspaceConfigIdentifier[wid];
                    break;
                  }
                }
              }
              return WorkspaceActions.WorkspaceTypeReceived({
                workspaceId: action.workspaceId,
                workspaceType: workspaceType,
                workspaceTags: tags
              });
            })
          );
        },
        onError: (action: ReturnType<typeof WorkspaceActions.SelectedWorkspaceChanged>, error: Error) => {
          return createAppError(error);
        }
      })
    )
  );

  getWorkspaceConfigs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.SelectedWorkspaceChanged, WorkspaceActions.ApplyConfigToServerDONE),
      withLatestFrom(this.appFacade.client$, this.workspaceFacade.selectedWorkspaceId$),
      switchMap(([_, client, selectedWorkspaceId]) =>
        this.workspaceService.getWorkspaceConfigurations(selectedWorkspaceId!, client.id)
      ),
      mergeMap((configs) => {
        const actions: Action[] = [];
        configs.forEach((config) => {
          const locallyFoundConfigSource = Object.values(ConfigurationTypeIdentifiers).find(
            (type) => (config.type as ConfigurationTypeIdentifiers) === type
          );
          if (locallyFoundConfigSource) {
            switch (locallyFoundConfigSource) {
              case ConfigurationTypeIdentifiers.ATTRIBUTES:
                actions.push(
                  WorkspaceActions.SetAttributeConfig({
                    content: config.content as any as AttributesConfig,
                    source: 'server'
                  })
                );
                break;
              case ConfigurationTypeIdentifiers.NORMS:
                actions.push(
                  WorkspaceActions.SetNormsConfig({
                    content: config.content as any as PassByNormConfiguration,
                    source: 'server'
                  })
                );
                break;
              case ConfigurationTypeIdentifiers.TABLE:
                actions.push(
                  WorkspaceActions.SetTableConfig({
                    content: config.content as any as OrderTableConfig,
                    source: 'server'
                  })
                );
                break;
              default:
                exhaustiveMatchingGuard(locallyFoundConfigSource);
            }
          }
        });
        actions.push(OrdersSearchActions.InitDefaultResultColumns());
        actions.push(WorkspaceActions.ReceivedWorkspaceConfigs());
        return actions;
      })
    )
  );

  getWorkspaceStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.SelectedWorkspaceChanged),
      switchMap((action) => {
        return this.workspaceService
          .getWorkspaceStates(action.workspaceId)
          .pipe(map((states) => ({ states, workspaceId: action.workspaceId })));
      }),
      map((result) =>
        OrderActions.WorkspaceOrderStatesReceived({
          workspaceId: result.workspaceId,
          workspaceOrderStates: result.states
        })
      )
    )
  );

  getDefaultTableConfigForWorkspaceType$ = createEffect(
    () =>
      this.actions$.pipe(ofType(WorkspaceActions.WorkspaceTypeReceived)).pipe(
        concatLatestFrom(() => this.workspaceFacade.selectedWorkspaceConfig$),
        tap(([_, config]) => {
          if (config.customDefaultConfigs.table) {
            this.store.dispatch(
              WorkspaceActions.SetTableConfig({
                content: config.customDefaultConfigs.table as OrderTableConfig,
                source: 'default'
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  prepareApplyConfigToServer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.ApplyConfigToServer),
      withLatestFrom(this.appFacade.client$),
      map(([action, client]) => {
        let result: Action;
        if (client.exists) {
          result = WorkspaceActions.PerformApplyConfigToServer({
            content: action.content,
            configType: action.configType
          });
        } else {
          result = WorkspaceActions.CreateClientAndApplyConfig({
            content: action.content,
            configType: action.configType
          });
        }
        return result;
      })
    )
  );

  createClientAndContinue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.CreateClientAndApplyConfig),
      withLatestFrom(this.appFacade.client$),
      switchMap(([action, client]) =>
        this.workspaceService.createConfigurationClient(client).pipe(map((success) => ({ action, success })))
      ),
      mergeMap((result) => {
        const actions: Action[] = [];
        actions.push(AppActions.GetClient());
        actions.push(
          WorkspaceActions.PerformApplyConfigToServer({
            content: result.action.content,
            configType: result.action.configType
          })
        );
        return actions;
      })
    )
  );

  performApplyConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.PerformApplyConfigToServer),
      withLatestFrom(this.workspaceFacade.selectedWorkspaceId$, this.appFacade.client$),
      switchMap(([action, selectedWorkspaceId, client]) =>
        this.workspaceService
          .applyConfiguration(selectedWorkspaceId!, client.id, {
            content: action.content,
            configType: action.configType
          })
          .pipe(map((success) => ({ action, success })))
      ),
      map((result) => {
        if (result.success) {
          return WorkspaceActions.ApplyConfigToServerSUCCESS({
            content: result.action.content,
            configType: result.action.configType
          });
        } else {
          return WorkspaceActions.ApplyConfigToServerFAILED({
            content: result.action.content,
            configType: result.action.configType
          });
        }
      })
    )
  );

  revertConfigStateAfterSuccesOrFailure = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.ApplyConfigToServerSUCCESS, WorkspaceActions.ApplyConfigToServerFAILED),
      delay(2500),
      map((_) => WorkspaceActions.ApplyConfigToServerDONE())
    )
  );

  workspaceLoadComplete$ = createEffect(() =>
    combineLatest([
      zip(
        this.actions$.pipe(ofType(WorkspaceActions.WorkspaceTypeReceived)),
        this.actions$.pipe(ofType(OrderActions.WorkspaceOrderStatesReceived)),
        this.actions$.pipe(ofType(WorkspaceActions.SetTableConfig))
      ),
      this.actions$.pipe(ofType(AttributesActions.DepotAttributesAndSemanticsReceived))
    ]).pipe(map(([action]) => WorkspaceActions.WorkspaceLoadComplete({ workspaceId: action[0].workspaceId })))
  );

  deriveSortFromConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.SetTableConfig),
      withLatestFrom(
        this.workspaceFacade.resultingConfigForIdentifier$(ConfigurationTypeIdentifiers.TABLE),
        this.workspaceFacade.selectedWorkspaceConfig$
      ),
      map(([_, tableConfig, workspaceConfig]) => {
        const actualTableConfig: OrderTableConfig = tableConfig as OrderTableConfig;
        let sort: OrderSearchSortDefinition = {
          attributeLookup: 'lastModified',
          direction: 'desc'
        };
        if (actualTableConfig.sort) {
          const column = actualTableConfig.columns[actualTableConfig.sort.columnIdentifier];
          const attributeID = column.content.primary?.attributeID;
          if (attributeID) {
            sort = {
              attributeIdentifier: attributeID,
              direction: actualTableConfig.sort.sortingDirection === 'ascending' ? 'asc' : 'desc'
            };
          }
        }
        return WorkspaceActions.SetSort({ sort });
      })
    )
  );

  writePreferredWorkspaceToLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkspaceActions.SelectedWorkspaceChanged),
        map((action) => {
          localStorage.setItem(LS_USER_PREFERRED_WORKSPACE, action.workspaceId);
        }),
        catchError((error, caught) => {
          this.store.dispatch(createAppError(error));
          return caught;
        })
      ),
    { dispatch: false }
  );

  recoverOrderTableColumnOrderFromLocalStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.WorkspacesReceived),
      map((_) => {
        const result = JSON.parse(localStorage.getItem(LS_USER_ORDER_TABLE_COLUMN_ORDER) ?? '{}');
        return WorkspaceActions.RecoverOrderTableColumnOrder({ columnOrderPerWorkspace: result });
      })
    )
  );

  writeOrderTableColumnOrderToLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkspaceActions.SetOrderTableColumnOrder),
        switchMap((_) => this.workspaceFacade.workspaceTableColumns$),
        map((columns) => {
          localStorage.setItem(LS_USER_ORDER_TABLE_COLUMN_ORDER, JSON.stringify(columns));
        })
      ),
    { dispatch: false }
  );

  hasWorkspaceChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.SetSelectedWorkspaceByRouter),
      map((action) => action.workspaceId),
      startWith(undefined),
      pairwise(),
      filter(([prev, curr]) => prev !== curr),
      map(([_, curr]) => WorkspaceActions.SelectedWorkspaceChanged({ workspaceId: curr! }))
    )
  );

  constructor(
    private actions$: Actions,
    private workspaceFacade: WorkspaceFacade,
    private workspaceService: WorkspaceService,
    private appFacade: AppFacade,
    private store: Store<WorkspacePartialState>
  ) {}
}
