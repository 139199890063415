<as-split [direction]="'vertical'" [unit]="'pixel'" #split>
  <as-split-area size="*">
    <div id="contentPanel">
      <ng-template cloudMeasurement> </ng-template>
    </div>
  </as-split-area>

  <as-split-area size="150" minSize="100" maxSize="300" #tileContainer>
    <div id="tileContainer" class="tileContainer">
      <ng-container *ngFor="let component of dynamicCompontents">
        <div
          class="tile"
          [attr.data-cy]="'DYNAMIC_COMPONENT_' + component.identifier"
          [style.width.px]="tileWidth"
          [style.height.px]="tileHeight"
          *ngIf="(component.available | async) !== false"
          (click)="selectTile(component)"
        >
          <span #tilesRef [id]="component.identifier"></span>
        </div>
      </ng-container>
    </div>
  </as-split-area>
</as-split>
