import { Inject, Injectable } from '@angular/core';
import { JsonApi, Spec } from '@muellerbbm-vas/grivet';
import { AngularHttpContext } from '@root/libs/vas-angular-http-context/src';
import { Observable, from, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ANGULAR_HTTP_CONTEXT } from '../app.tokens';
import { Client } from '../app.types';
import { OrderState } from '../order-management/order.types';
import { CloudService } from '../services/cloud.service';
import { ConfigurationTypeIdentifiers, RawWorkspaceConfiguration, Workspace, WorkspaceTag } from './workspace.types';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {
  constructor(private cloudService: CloudService, @Inject(ANGULAR_HTTP_CONTEXT) private context: AngularHttpContext) {}

  getWorkspaces(): Observable<Workspace[]> {
    return this.cloudService.getAppStartObs('ods_order').pipe(
      switchMap((res) => (res ? from(res.relatedResources['workspaces']) : of([]))),
      map((workspaces) => this.createWorkspaces(workspaces))
    );
  }

  createWorkspaces(workspaceDocs: JsonApi.Resource[]): Workspace[] {
    const workspaces: Workspace[] = [];
    for (const doc of workspaceDocs) {
      const workspaceId: string = doc.id;
      const wsAttribs: Spec.AttributesObject | undefined = doc.attributes;

      const workspace: Workspace = {
        id: workspaceId,
        tags: [],
        orderTableColumns: [],
        name: wsAttribs?.['name'],
        label: wsAttribs?.['label_nls'] ? wsAttribs['label_nls'] : wsAttribs?.['name']
      };
      workspaces.push(workspace);
    }
    return workspaces;
  }

  getWorkspace(workspaceId: string): Observable<JsonApi.Resource | undefined> {
    return this.cloudService.getAppStartObs('ods_order', true).pipe(
      switchMap((res) => (res ? res.relatedResources['workspaces'] : [])),
      map((workspaces) => {
        return workspaces.find((workspace) => workspace.id === workspaceId);
      })
    );
  }

  getWorkspaceStates(workspaceId: string): Observable<OrderState[]> {
    return this.getWorkspace(workspaceId).pipe(
      switchMap((workspaceResource) => {
        return from(workspaceResource ? workspaceResource.relatedResources['states'] : []).pipe(
          map((states) => {
            const result: OrderState[] = states.map((state) => {
              const wsState: OrderState = {
                id: state.id ?? '',
                name: state.attributes?.['name'],
                label_nls: state.attributes?.['label_nls']
              };
              return wsState;
            });
            return result;
          })
        );
      })
    );
  }

  getWorkspaceConfigurations(workspaceId: string, clientId: string): Observable<RawWorkspaceConfiguration[]> {
    return this.getWorkspace(workspaceId).pipe(
      switchMap((workspaceResource) => {
        workspaceResource?.links;
        // TODO: Filter for client and relevant config type
        return from(workspaceResource?.relatedResources['client_configurations'] ?? []).pipe(
          map((configs) => {
            const result: RawWorkspaceConfiguration[] = [];
            configs.forEach((config) => {
              result.push({
                id: config.id,
                content: config.attributes?.['content'] ?? '',
                type: config.attributes?.['content_type'] ?? ''
              });
            });
            return result;
          })
        );
      })
    );
  }

  createConfigurationClient(client: Client) {
    return this.cloudService.getAppStartObs('client').pipe(
      switchMap(async (clientApp) => await clientApp?.relationships['clients'].links?.related.url),
      switchMap(async (clientListUrl) => {
        let success = false;
        try {
          if (clientListUrl) {
            const data = {
              data: {
                id: client.id,
                type: 'Client',
                attributes: {
                  name: client.name
                }
              }
            };
            await this.context.postDocument(clientListUrl, data);
          }
          success = true;
        } catch (error) {
          console.error(error);
        }
        return success;
      })
    );
  }

  applyConfiguration(
    workspaceId: string,
    clientId: string,
    config: { content: any; configType: ConfigurationTypeIdentifiers }
  ) {
    return this.getWorkspace(workspaceId).pipe(
      switchMap(async (workspaceResource) => {
        let success = false;
        try {
          const clientConfigListURL = await workspaceResource?.relationships['client_configurations'].links?.related
            .url;

          if (clientConfigListURL) {
            const searchURL = new URL(clientConfigListURL!);

            searchURL?.searchParams.append('filter[simple][primary][client_id]', clientId);
            searchURL?.searchParams.append('filter[simple][primary][content_type]', config.configType);

            const docs = JsonApi.Document.fromURL(searchURL!, this.context);
            const resources = (await docs).resources;

            const hasExistingConfig = resources.length === 1;

            if (hasExistingConfig) {
              const selfUrl = resources[0].links.self.url;
              const data = {
                data: {
                  type: 'OdsOrderWorkspaceClientConfiguration',
                  id: resources[0].id,
                  attributes: {
                    content: config.content
                  }
                }
              };
              await this.context.patchDocument(selfUrl, data);
            } else {
              const data = {
                data: {
                  type: 'OdsOrderWorkspaceClientConfiguration',
                  attributes: {
                    content: config.content,
                    content_type: config.configType
                  },
                  relationships: {
                    client: {
                      data: {
                        id: clientId,
                        type: 'Client'
                      }
                    }
                  }
                }
              };
              await this.context.postDocument(clientConfigListURL, data);
            }
          }
          success = true;
        } catch (err) {
          console.log(err);
        }

        return success;
      })
    );
  }

  getWorkspaceTags(workspaceId: string): Observable<WorkspaceTag[]> {
    return this.cloudService.getAppStartObs('ods_order').pipe(
      switchMap((orderAppStart) => (orderAppStart ? orderAppStart.relatedResources['workspaces'] : [])),
      map((_) => _ as JsonApi.PrimaryResource[]),
      map((workspaces) => workspaces.find((workspace) => workspace.id === workspaceId)),
      switchMap((workspaceResource) => {
        return workspaceResource ? workspaceResource.relatedResources['tags'] : [];
      }),
      map((tags) => tags.filter((tag) => tag.type === 'OdsOrderWorkspaceTag')),
      switchMap((workspaceTags) => {
        return Promise.all(
          workspaceTags.map(async (workspaceTag) => {
            const tagType = await workspaceTag.relatedResource['tag_type'];
            const wstag: WorkspaceTag = {
              tagTypeName: tagType.attributes ? tagType.attributes['name'] : '',
              value: workspaceTag.attributes ? workspaceTag.attributes['value'] : ''
            };
            return wstag;
          })
        );
      })
    );
  }
}
