import { Component } from '@angular/core';
import { AppFacade } from '../../+state/app.facade';
import { DepotSearchFacade } from '../+state/depot-search.facade';
import { CommonFacade } from '@vas/common';

@Component({
  selector: 'cloud-search-aggregate-tree-wrapper',
  templateUrl: './search-aggregate-tree-wrapper.component.html',
  styleUrls: ['./search-aggregate-tree-wrapper.component.css']
})
export class SearchAggregateTreeWrapperComponent {
  constructor(
    public appFacade: AppFacade,
    public commonFacade: CommonFacade,
    public depotSearchFacade: DepotSearchFacade
  ) {}
}
