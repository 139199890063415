import { Component, OnInit } from '@angular/core';
import { MeasurementsFacade } from '../../+state/measurements.facade';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  PanelContentTree,
  PanelContentTreeNode
} from '@root/apps/cloud/src/app/shared/components/panel-detail-content/panel-detail-content.component';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'cloud-measurement-testsequence',
  templateUrl: './measurement-testsequence.component.html',
  styleUrls: ['./measurement-testsequence.component.css']
})
export class MeasurementTestSequenceComponent implements OnInit {
  trees$: Observable<PanelContentTree[]>;

  constructor(public measurementsFacade: MeasurementsFacade, public translate: TranslateService) {}

  ngOnInit() {
    this.trees$ = this.measurementsFacade.selectedMeasurement$.pipe(
      filter((meas) => !!meas),
      map((meas) => meas?.resources.testSequencesAndAnys),
      map((testSequences) => {
        const testSequencesTree: PanelContentTree = {
          name: this.translate.instant(_('DEPOTSEARCH.TESTSEQUENCE')),
          nodes: []
        };
        const anysTree: PanelContentTree = {
          name: this.translate.instant(_('DEPOTSEARCH.AOANY')),
          nodes: []
        };

        testSequences!.forEach((instance) => {
          const rootNode: PanelContentTreeNode = {
            name: instance.name as string,
            label: instance.name as string,
            expanded: true,
            selected: false,
            content: {
              title: instance.name as string,
              hasContent: true,
              keyValueList: instance.attributes
            }
          };

          Object.keys(instance.parts).forEach((key) => {
            const part = instance.parts[key];

            const node: PanelContentTreeNode = {
              name: key,
              label: key,
              expanded: false,
              selected: false,
              content: {
                title: key as string,
                hasContent: true,
                keyValueList: part.attributes
              }
            };
            if (!rootNode.childNodes) {
              rootNode.childNodes = [];
            }
            rootNode.childNodes.push(node);
          });

          if (instance.aoBasename.toLowerCase() === 'aotestsequence') {
            testSequencesTree.nodes.push(rootNode);
          } else {
            anysTree.nodes.push(rootNode);
          }
        });
        if (anysTree.nodes.length > 0) {
          return [testSequencesTree, anysTree];
        } else {
          return [testSequencesTree];
        }
      })
    );
  }
}
