import { Axis, ChartXY, Dashboard } from '@arction/lcjs';
import { Subscription } from 'rxjs';
import { StreamingChart } from '../utils/streaming-chart.types';
import { LCconstructorBaseOptions, ScalesConfig, TrackType } from './lightningPlot.types';
import { DataType, DatasetStepValues, Position, Quantity } from '../prototypes/DatasetMessages';
import { ExternalTrackValues } from '../integrated-streaming/integrated-streaming.component';
import { LCinfoMapping, LCinfoMappingOptions, generateLCinfoMapping } from './infoMapping';
import { TranslateService } from '@ngx-translate/core';
import { StreamingFacade } from '../+state/streaming.facade';
import { transformFromISOtoUserByQuantity } from './genericTransformationFunctions';
import { dataYIsoAdjustAppearance } from './appearanceTransformationFunctions';
import { distinguishableValuesFractionDigitsForMinimalDelta } from '../utils/formatting.utils';

export function LogGroup(enabled: boolean = true) {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      if (enabled) {
        console.group(`Class: ${target.constructor.name}, Method: ${propertyKey}`);
      }
      const result = originalMethod.apply(this, args);
      if (enabled) {
        console.groupEnd();
      }
      return result;
    };

    return descriptor;
  };
}

export const createExternalTrackValuesFromStepValues = (stepValues: StepValues): ExternalTrackValues | undefined => {
  const { steps, times } = stepValues;

  if (steps.length < times.length) {
    console.error('steps.length must be at least times.length');
    return undefined;
  }

  const trackValues: ExternalTrackValues = { absoluteInt128Time: [], values: [] };
  for (let i = 0; i < Math.min(steps.length, times.length); i++) {
    const step = steps[i];
    const time = times[i];

    trackValues.absoluteInt128Time.push(time);
    trackValues.values.push(step.trackValue);
  }

  return trackValues;
};

export interface StepValues {
  steps: DatasetStepValues[];
  times: bigint[];
  zValues: number[];
}

export interface LocalValues {
  externalTrackValues?: ExternalTrackValues;
}

export interface LocalValues2D extends LocalValues {
  dataValues: number[];
  times: bigint[];
  minMaxValues: number[]; // for compressed data
  trackValues?: number[];
}

export interface LocalValues3D2D extends LocalValues {
  dataValues: number[];
  stepValues: StepValues;
}

export interface LocalValues3D extends LocalValues {
  dataValues: number[][];
  stepValues: StepValues;
}

export abstract class LightningPlot {
  htmlElementId: string;
  chartType: StreamingChart.ChartType;
  licenseConfig: StreamingChart.LightningConfig;

  tpDatasetParams: StreamingChart.DatasetParaContainer;
  calibration: StreamingChart.DatasetCalibration | undefined;

  values: LocalValues2D | LocalValues3D | LocalValues3D2D;

  dashBoard: Dashboard;
  chart: ChartXY;

  scalesConfig: ScalesConfig = {
    xAxis: 'lin',
    yAxis: 'lin',
    color: 'lin'
  };

  infoMapping: LCinfoMapping;
  is3DPlot = false;

  trackQuantity: Quantity | undefined;
  trackPosition: Position | undefined;
  selectedTrackIsTime: boolean | undefined;

  initDone = { frequencyValues: false, axisTitles: false };
  subs: Subscription[] = [];
  disposed = false;

  translate: TranslateService;
  trackType: TrackType;
  streamingFacade: StreamingFacade;

  yAxisInterval: { start: number | undefined; end: number | undefined } = { start: undefined, end: undefined };
  xAxisInterval: { start: number | undefined; end: number | undefined } = { start: undefined, end: undefined };
  // Limit zoom out => reaching ZOOM_OUT_REBOUNCE_LEVEL => reset to +- ZOOM_OUT_MAX_FACTOR
  ZOOM_OUT_REBOUNCE_LEVEL = 0.15;
  ZOOM_OUT_MAX_FACTOR = 0.05;

  MINIMUM_X_DELTA: number | undefined = undefined;
  MINIMUM_Y_DELTA: number | undefined = undefined;
  MINIMUM_Z_DELTA: number | undefined = undefined;
  DECIMAL_PLACES_X = 3;
  DECIMAL_PLACES_Y = 3;
  lastTrackValue: number | undefined = undefined;
  lastexternalTrackValue: number | undefined = undefined;

  constructor(options: LCconstructorBaseOptions, translate: TranslateService, streamingFacade: StreamingFacade) {
    this.htmlElementId = options.htmlElementId;
    this.licenseConfig = options.licenseConfig;
    this.chartType = options.chartType;
    this.is3DPlot = this.chartType === StreamingChart.ChartType.CHART3D;

    this.scalesConfig = options.scalesConfig;
    this.tpDatasetParams = options.tpDatasetParams;

    this.translate = translate;
    this.trackType = 'time';
    this.streamingFacade = streamingFacade;

    this.setupPlot(this.scalesConfig);
  }

  // Init functions
  protected abstract setupPlot(scalesConfig?: ScalesConfig): void;
  protected abstract generatePlotTitle(parameter: StreamingChart.DatasetParameters): string;

  // Update functions
  public abstract handleScaleConfigChange(scalesConfig: ScalesConfig, calledFromExternTrackValues: boolean): void;
  public setScalesConfig(scalesConfig: ScalesConfig) {
    this.scalesConfig = scalesConfig;
    this.handleScaleConfigChange(scalesConfig, false);
  }

  public updateInfoMapping(): void {
    const options: LCinfoMappingOptions = {
      localValues: this.values,
      datasetParams: this.tpDatasetParams,
      trackQuantity: this.trackQuantity,
      trackPosition: this.trackPosition,
      selectedTrackIsTime: this.selectedTrackIsTime,
      scalesConfig: this.scalesConfig
    };
    if (options.localValues) {
      const ret = generateLCinfoMapping(options);
      this.infoMapping = ret.infoMapping;
      this.trackType = ret.trackType;
      this.applyLCinfoMapping();
    }
  }
  protected applyLCinfoMapping() {
    if (this.chart) {
      const defaultAxisY = this.chart.getDefaultAxisY();
      const defaultAxisX = this.chart.getDefaultAxisX();
      if (defaultAxisY) {
        this.chart.getDefaultAxisY().setTitle(this.infoMapping.axis.Y.title);
      }
      if (defaultAxisX) {
        this.chart.getDefaultAxisX().setTitle(this.infoMapping.axis.X.title);
      }
    }
  }

  public updateDatasetStepValues = (datasetStepValues: DatasetStepValues) => {
    if ('stepValues' in this.values) {
      // console.log(`step value : '${this.values.stepValues?.steps.length}' = '${datasetStepValues.stepIndex}': '${datasetStepValues.trackValue}' , '${datasetStepValues.orderRpmValue}'`);
      this.values.stepValues?.steps.push(datasetStepValues);
    }
    // this.updateInfoMapping();
  };

  public updateOriginalTrackQuantityAndPosition = (
    trackQuantity: Quantity,
    trackPosition: Position | undefined,
    selectedTrackIsTime: boolean | undefined
  ) => {
    this.trackQuantity = trackQuantity;
    this.trackPosition = trackPosition;
    this.selectedTrackIsTime = selectedTrackIsTime;
    this.updateInfoMapping();
  };

  public updateExternalTrackValues = (externalTrackValues: ExternalTrackValues) => {
    // Appearance
    if (this.trackQuantity) {
      const { isoData } = dataYIsoAdjustAppearance(
        DataType.Type_SlowQuantity,
        false, // isSquared
        this.trackQuantity,
        externalTrackValues.values
      );
      externalTrackValues.values = isoData;
    }

    if (this.trackQuantity) {
      // From ISO Transformation
      externalTrackValues.values = transformFromISOtoUserByQuantity(
        externalTrackValues.values,
        this.trackQuantity
      ) as number[];
    }

    // no Scale-Type transformation - track data are allways 'lin'

    this.values.externalTrackValues = externalTrackValues;
    if (externalTrackValues.values.length > 0) {
      this.lastTrackValue = undefined;
      this.MINIMUM_Y_DELTA = undefined;
      externalTrackValues.values.forEach((value) => {
        if (this.lastTrackValue !== undefined) {
          if (
            this.MINIMUM_Y_DELTA === undefined ||
            (Math.abs(value - this.lastTrackValue) < this.MINIMUM_Y_DELTA && Math.abs(value - this.lastTrackValue) > 0)
          ) {
            this.MINIMUM_Y_DELTA = Math.abs(value - this.lastTrackValue);
          }
        }
        this.lastTrackValue = value;
      });
    }
    this.DECIMAL_PLACES_Y = distinguishableValuesFractionDigitsForMinimalDelta(this.MINIMUM_Y_DELTA);

    this.updateInfoMapping();
    if (
      this.tpDatasetParams.currentDatasetParams.plottingParameters?.chartType === StreamingChart.ChartType.CHART3D2D ||
      this.tpDatasetParams.currentDatasetParams.plottingParameters?.chartType === StreamingChart.ChartType.CHART3D
    ) {
      this.handleScaleConfigChange(this.scalesConfig, true);
    }
  };

  public updateCalibration(calibration: Partial<StreamingChart.DatasetCalibration>) {
    this.calibration = {
      calibfact: calibration.calibfact ?? 1,
      calibofs: calibration.calibofs ?? 0,
      calibscale: calibration.calibscale ?? 1
    };
    this.updateInfoMapping();
  }

  checkRebounceOnIntervallChange(
    valueRangeStart: number | undefined,
    valueRangeEnd: number | undefined,
    defaultAxis: Axis,
    start: number,
    end: number
  ) {
    if (valueRangeEnd && valueRangeStart) {
      const valueRange = Math.abs(valueRangeEnd - valueRangeStart);
      const valueToRangeRatio =
        valueRange === 0 ? Infinity : Math.abs(valueRangeStart + valueRangeEnd) / 2 / valueRange;
      const MAX_VALUE_TO_RANGE_RATIO = 10000; // lightningchart's axis interval fit is wider for very small ranges. don't change axis in this case
      if (valueToRangeRatio > MAX_VALUE_TO_RANGE_RATIO) {
        // console.log('Value to range ratio ' + valueToRangeRatio + ' is too high. Not changing axis');
      } else {
        const valueRangeRebounceDiff = valueRange * this.ZOOM_OUT_REBOUNCE_LEVEL;
        const offset = valueRange * this.ZOOM_OUT_MAX_FACTOR;
        let newValues: { start: number; end: number } | undefined;
        if (start < end) {
          if (start < valueRangeStart - valueRangeRebounceDiff || end > valueRangeEnd + valueRangeRebounceDiff) {
            newValues = { start: valueRangeStart - offset, end: valueRangeEnd + offset };
          }
        } else {
          if (end < valueRangeEnd - valueRangeRebounceDiff || start > valueRangeStart + valueRangeRebounceDiff) {
            newValues = { start: valueRangeStart + offset, end: valueRangeEnd - offset };
          }
        }
        if (newValues) {
          defaultAxis.setInterval({
            start: newValues.start,
            end: newValues.end
          });
        }
      }
    }
  }

  // Utitlity
  public rerender = () => {
    window.dispatchEvent(new Event('resize')); // NOTE: Workaround, trigger a windows resize (which implicitly causes LightningChart to rerender)
  };

  public abstract resetAxis(): void;

  public resetFullData() {}

  public resetCompressedData() {}

  // Cleanup
  public dispose() {
    this.subs.forEach((sub) => sub.unsubscribe());
    [this.chart, this.dashBoard].forEach((obj) => {
      if (obj) {
        obj.dispose();
      }
    });
    this.additionalDispose();
    this.disposed = true;
  }
  protected additionalDispose() {}
}

export const findMatchingTimestamp = (timestampToFind: bigint, trackValues: ExternalTrackValues): number => {
  const i = trackValues.absoluteInt128Time.findIndex((value: bigint) => {
    if (value >= timestampToFind) {
      return true;
    }
  });
  return i;
};

export const hasCompressedData = (values: LocalValues): boolean => {
  const values2D = values as LocalValues2D;
  if (values2D !== undefined && values2D?.minMaxValues !== undefined && values2D.minMaxValues.length > 0) {
    return true;
  }
  return false;
};
