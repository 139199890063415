import { OrderCheckoutToken, Orders, TestplanType } from './../order.types';
import { createAction, props } from '@ngrx/store';
import { OrderState, Order, DeviceAssignment } from '../order.types';
import { orderResourceIdentifiers } from '../order.config';
import { ComponentDescription } from '../order-detail/order-detail.types';
import { Spec } from '@muellerbbm-vas/grivet';
import { OrderDeletionInteraction, OrderRestorationInteraction } from './orders.reducer';

export enum OrdersActionTypes {
  // Loading / Init
  LoadingDone = '[Orders] Loading Orders Done',
  OrderLoaded = '[Orders] Order Loaded',
  LoadOrderDisplayAttributes = '[Orders] Complete Order',
  LoadOrderDisplayAttributesDone = '[Orders] Order completed',
  TestplanTypeDetermined = '[Orders] Determined Testplan Type',
  WorkspaceOrderStatesReceived = '[Orders] Load Workspace Specific Order States Done',

  // Order Selection
  SelectOrder = '[Order] Set selected order Id',
  SetSelectedOrderByRouter = '[Orders] [Router] Set Selected Order',
  SelectedOrderChanged = '[Order] Selected Order Changed',
  PreviewOrder = '[Order] Preview Order',

  // Order States
  LoadOrderStates = '[Orders] Load Order States',
  LoadOrderStatesDone = '[Orders] Load Order States Done',

  // Device Assignments
  StartPollingDeviceAssignments = '[Orders] START Polling Device Assignments',
  PollDeviceAssignments = '[Orders] Poll Device Assignments',
  StopPollingDeviceAssignments = '[Orders] STOP Polling Device Assignments',
  AssignDevice = '[Orders] Assign Device',
  DeviceAssigned = '[Orders] Device assigned',
  AssignDeviceUndo = '[Orders] UNDO Device assign',
  RemoveDeviceAssignment = '[Orders] Remove Device Assignment',
  DeviceAssignmentRemoved = '[Orders] Device Assignment Removed',
  RemoveDeviceAssignmentUndo = '[Orders] UNDO Device Assignment Remove',

  // Order Creation
  CreateOrder = '[Orders] Create Order',
  OrderCreated = '[Orders] Order Created',
  CreateOrderUndo = '[Orders] UNDO Create Order',

  // Order Deletion
  DeleteOrder = '[Orders] Delete Order',
  OrderDeleted = '[Orders] Order Deleted',
  DeleteOrderUndo = '[Orders] UNDO Delete Order',

  // Deleted Order Restore
  RestoreDeletedOrder = '[Orders] Restore deleted Order',
  DeletedOrderRestored = '[Orders] Deleted Order Restored',
  RestoreDeletedOrderUndo = '[Orders] UNDO Restore deleted Order',

  // Resource Gathering
  PrepGatherResource = '[Orders] Prepare Gather Resource',
  GatherResource = '[Orders] Gather Resource',
  ResourceGathered = '[Orders] Resource Gathered',
  GatherResourceUndo = '[Orders] UNDO Gather Resource',

  // Dynamic Component Org
  SetActiveDynamicComponentForSelectedOrderByRouter = '[Orders] [Router] Set active dynamic component for order',
  SetActiveDynamicComponentForSelectedOrder = '[Orders] Set active dynamic component for order',
  AddCustomDynamicComponentForOrder = '[Orders] Add a custom dynamic component to an order',
  RemoveCustomDynamicComponentsForOrder = '[Orders] Remove all custom dynamic component',
  OrderDetailDeterminedAvailableDynamicComponentsForOrder = '[Orders] [Detail] Determined available dynamic components',

  ChangeOrderState = '[Orders] Change order state',
  ChangeOrderStateDone = '[Orders] Change order state done',
  OrderStateIDForOrderReceived = '[Orders] Order State ID for Order received',

  // Order LockState
  SetOrderLockState = '[Orders] Set Order LockState',
  OrderLockStateSet = '[Orders] Order LockState set',

  // Order Download
  TriggerOrderDownload = '[Orders] Trigger Order Download',
  CheckOrderDownloadPrepareState = '[Orders] Check Order Download State',
  PerformOrderDownload = '[Orders] Perform Order Download',
  ReportOrderDownloadProgress = '[Orders] Report Order Download Progress',
  OrderDownloadSuccess = '[Orders] Order Download Finished Succesfully',
  OrderDownloadError = '[Orders] Order Download Failed',
  CancelOrderDownload = '[Orders] Cancel Order Download',
  ResetOrderDownloadState = '[Orders] Reset Order Download State',

  // Order Checkout/Lock state
  PollCheckoutTokensSTART = '[Orders] Start Polling Order Checkout Tokens ',
  PollCheckoutTokens = '[Orders] Poll Order Checkout Tokens ',
  PollCheckoutTokensSTOP = '[Orders] Stop Polling Order Checkout Tokens',
  CheckoutTokensReceived = '[Orders] Received Order Checkout Tokens',

  // Pending Deletions List
  PendingDeletionsReceived = '[Orders] Pending Deletions List received',

  // Order PDF Download
  PerformOrderPDFDownload = '[Orders] Perform Order PDF Download',

  ShowOrderDetailFullscreen = '[Orders] Set Order Detail Fullscreen state',

  SetOrderDeletionInteraction = '[Orders] Set Order Deletion Interaction',
  SetOrderRestorationInteraction = '[Orders] Set Order Restoration Interaction'
}

export const LoadOrderStates = createAction(OrdersActionTypes.LoadOrderStates);
export const LoadOrderStatesDone = createAction(
  OrdersActionTypes.LoadOrderStatesDone,
  props<{ orderStates: OrderState[] }>()
);
export const WorkspaceOrderStatesReceived = createAction(
  OrdersActionTypes.WorkspaceOrderStatesReceived,
  props<{ workspaceId: string; workspaceOrderStates: OrderState[] }>()
);

export const LoadingDone = createAction(OrdersActionTypes.LoadingDone);

export const OrderLoaded = createAction(OrdersActionTypes.OrderLoaded, props<{ order: Order }>());
export const LoadOrderDisplayAttributes = createAction(
  OrdersActionTypes.LoadOrderDisplayAttributes,
  props<{ order: Order }>()
);
export const LoadOrderDisplayAttributesDone = createAction(
  OrdersActionTypes.LoadOrderDisplayAttributesDone,
  props<{ order: Order }>()
);

export const StartPollingDeviceAssignments = createAction(OrdersActionTypes.StartPollingDeviceAssignments);
export const PollDeviceAssignments = createAction(OrdersActionTypes.PollDeviceAssignments, props<{ order: Order }>());
export const StopPollingDeviceAssignments = createAction(OrdersActionTypes.StopPollingDeviceAssignments);

export const AssignDevice = createAction(
  OrdersActionTypes.AssignDevice,
  props<{ deviceAssignment: DeviceAssignment }>()
);
export const DeviceAssigned = createAction(
  OrdersActionTypes.DeviceAssigned,
  props<{ deviceAssignment: DeviceAssignment }>()
);
export const AssignDeviceUndo = createAction(
  OrdersActionTypes.AssignDeviceUndo,
  props<{ deviceAssignment: DeviceAssignment }>()
);
export const RemoveDeviceAssignment = createAction(
  OrdersActionTypes.RemoveDeviceAssignment,
  props<{ deviceAssignment: DeviceAssignment }>()
);
export const DeviceAssignmentRemoved = createAction(
  OrdersActionTypes.DeviceAssignmentRemoved,
  props<{ deviceAssignment: DeviceAssignment }>()
);
export const RemoveDeviceAssignmentUndo = createAction(
  OrdersActionTypes.RemoveDeviceAssignmentUndo,
  props<{ deviceAssignment: DeviceAssignment }>()
);

export const CreateOrder = createAction(OrdersActionTypes.CreateOrder, props<{ initialOrder: Order }>());
export const OrderCreated = createAction(
  OrdersActionTypes.OrderCreated,
  props<{ orderId: string; orderStateId: string; initialOrder: Order }>()
);
export const CreateOrderUndo = createAction(OrdersActionTypes.CreateOrderUndo);

export const DeleteOrder = createAction(OrdersActionTypes.DeleteOrder, props<{ order: Order }>());
export const OrderDeleted = createAction(OrdersActionTypes.OrderDeleted, props<{ order: Order }>());
export const DeleteOrderUndo = createAction(OrdersActionTypes.DeleteOrderUndo, props<{ order: Order }>());

export const RestoreDeletedOrder = createAction(OrdersActionTypes.RestoreDeletedOrder, props<{ order: Order }>());
export const DeletedOrderRestored = createAction(OrdersActionTypes.DeletedOrderRestored, props<{ order: Order }>());
export const RestoreDeletedOrderUndo = createAction(
  OrdersActionTypes.RestoreDeletedOrderUndo,
  props<{ order: Order }>()
);

export const SetSelectedOrderByRouter = createAction(
  OrdersActionTypes.SetSelectedOrderByRouter,
  props<{ orderId: string | null; navigationInfo: any }>()
);
export const SelectedOrderChanged = createAction(
  OrdersActionTypes.SelectedOrderChanged,
  props<{ orderId: string | null }>()
);

export const PreviewOrder = createAction(OrdersActionTypes.PreviewOrder, props<{ orderId: string }>());

export const PrepGatherResource = createAction(
  OrdersActionTypes.PrepGatherResource,
  props<{
    orderId: string;
    identifier: orderResourceIdentifiers;
  }>()
);

export const GatherResource = createAction(
  OrdersActionTypes.GatherResource,
  props<{
    orderId: string;
    identifier: orderResourceIdentifiers;
    orderRawDoc: Spec.JsonApiDocument;
    orderOptionsRawDoc?: any;
    testSequenceParameters?: string;
  }>()
);

export const ResourceGathered = createAction(
  OrdersActionTypes.ResourceGathered,
  props<{
    orderId: string;
    identifier: orderResourceIdentifiers;
    resource: any;
  }>()
);

export const GatherResourceUndo = createAction(
  OrdersActionTypes.GatherResourceUndo,
  props<{ orderId: string; identifier: orderResourceIdentifiers }>()
);

export const TestplanTypeDetermined = createAction(
  OrdersActionTypes.TestplanTypeDetermined,
  props<{ orderId: string; testplanType: TestplanType }>()
);

export const SetActiveDynamicComponentForSelectedOrderByRouter = createAction(
  OrdersActionTypes.SetActiveDynamicComponentForSelectedOrderByRouter,
  props<{ dynamicComponentIdentifier: string; navigationInfo: any }>()
);

export const SetActiveDynamicComponentForSelectedOrder = createAction(
  OrdersActionTypes.SetActiveDynamicComponentForSelectedOrder,
  props<{ dynamicComponentIdentifier: string }>()
);

export const AddCustomDynamicComponentForOrder = createAction(
  OrdersActionTypes.AddCustomDynamicComponentForOrder,
  props<{ component: ComponentDescription; orderId: string }>()
);

export const RemoveCustomDynamicComponentsForOrder = createAction(
  OrdersActionTypes.RemoveCustomDynamicComponentsForOrder,
  props<{ orderId: string }>()
);

export const OrderDetailDeterminedAvailableDynamicComponentsForOrder = createAction(
  OrdersActionTypes.OrderDetailDeterminedAvailableDynamicComponentsForOrder,
  props<{
    availableDynamicComponents: { identifier: string; title: string }[];
    orderId: string;
  }>()
);

export const ChangeOrderState = createAction(
  OrdersActionTypes.ChangeOrderState,
  props<{ order: Order; orderState: OrderState }>()
);
export const ChangeOrderStateDone = createAction(
  OrdersActionTypes.ChangeOrderStateDone,
  props<{ order: Order; orderState: OrderState }>()
);
export const OrderStateIDForOrderReceived = createAction(
  OrdersActionTypes.OrderStateIDForOrderReceived,
  props<{ orderId: string; orderStateId: string }>()
);

export const SelectOrder = createAction(OrdersActionTypes.SelectOrder, props<{ orderId: string }>());

export const SetOrderLockState = createAction(
  OrdersActionTypes.SetOrderLockState,
  props<{ orderId: string; locked: boolean }>()
);

export const TriggerOrderDownload = createAction(
  OrdersActionTypes.TriggerOrderDownload,
  props<{ orderId: string; readonly: boolean }>()
);

export const CheckOrderDownloadPrepareState = createAction(
  OrdersActionTypes.CheckOrderDownloadPrepareState,
  props<{ orderId: string; orderDownloadUrl: URL; readonly: boolean }>()
);
export const PerformOrderDownload = createAction(
  OrdersActionTypes.PerformOrderDownload,
  props<{ orderId: string; orderDownloadUrl: URL; readonly: boolean }>()
);
export const ReportOrderDownloadProgress = createAction(
  OrdersActionTypes.ReportOrderDownloadProgress,
  props<{ orderId: string; loaded: number; total: number }>()
);

export const OrderDownloadSuccess = createAction(OrdersActionTypes.OrderDownloadSuccess, props<{ orderId: string }>());
export const OrderDownloadError = createAction(
  OrdersActionTypes.OrderDownloadError,
  props<{ orderId: string; reason?: string }>()
);

export const CancelOrderDownload = createAction(OrdersActionTypes.CancelOrderDownload, props<{ orderId: string }>());
export const ResetOrderDownloadState = createAction(
  OrdersActionTypes.ResetOrderDownloadState,
  props<{ orderId: string }>()
);

export const PollCheckoutTokensSTART = createAction(OrdersActionTypes.PollCheckoutTokensSTART);
export const PollCheckoutTokens = createAction(OrdersActionTypes.PollCheckoutTokens);
export const PollCheckoutTokensSTOP = createAction(OrdersActionTypes.PollCheckoutTokensSTOP);
export const CheckoutTokensReceived = createAction(
  OrdersActionTypes.CheckoutTokensReceived,
  props<{ tokens: OrderCheckoutToken[] }>()
);
export const PendingDeletionsReceived = createAction(
  OrdersActionTypes.PendingDeletionsReceived,
  props<{ pendingDeletionOrderIDs: string[] }>()
);

export const PerformOrderPDFDownload = createAction(
  OrdersActionTypes.PerformOrderPDFDownload,
  props<{ url: string; name?: string }>()
);

export const ShowOrderDetailFullscreen = createAction(
  OrdersActionTypes.ShowOrderDetailFullscreen,
  props<{ fullscreen: boolean }>()
);

export const SetOrderDeletionInteraction = createAction(
  OrdersActionTypes.SetOrderDeletionInteraction,
  props<{ interaction: OrderDeletionInteraction }>()
);

export const SetOrderRestorationInteraction = createAction(
  OrdersActionTypes.SetOrderRestorationInteraction,
  props<{ interaction: OrderRestorationInteraction }>()
);
