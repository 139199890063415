// TODO: SearchAttributeNLS?

import { OrderTableConfig, OrderTableContentIdentifiers, WorkspaceConfigList } from './workspace.config.types';

import * as BaseConfig from './workspace.config.base';
import * as PassByConfig from './workspace.config.passby';
import { ConfigurationSource, ConfigurationTypeIdentifiers } from '../workspace.types';

// TODO: check if searchAttribute hierarchy might be different depending on the backend, e.g. descriptive_ancestors.2.order_iid.workspace_id
export const WORKSPACE_CONFIG: WorkspaceConfigList = {
  base: {
    searchFilterAttributeWorkspaceId: 'descriptive.order_iid.workspace_id',
    metaAttributesHideList: BaseConfig.metaAttributesHideList,
    attributeLookups: {
      order: 'descriptive.order_iid.order_guid',
      orderName: 'hrchypak.Project.name',
      state: 'descriptive.order_iid.state_id',
      lastModified: 'descriptive.order_iid.last_modified_date',
      project: 'hrchypak.Project.name',
      description: 'descriptive.order_iid.description'
    },
    customDefaultConfigs: {
      table: <ConfigurationSource<OrderTableConfig>['default']>BaseConfig.baseTableDefaultConfig
    },
    additionalSearchAttributeIdNames: [],
    sideBarFilterConfig: BaseConfig.sideBarFilterConfig,
    requireDevices: true,
    availableConfigurableTypes: [ConfigurationTypeIdentifiers.TABLE]
  },
  passby: {
    searchFilterAttributeWorkspaceId: 'descriptive_ancestors.aotest.order_iid.workspace_id',
    metaAttributesHideList: BaseConfig.metaAttributesHideList,
    attributeLookups: {
      order: 'descriptive_ancestors.aotest.order_iid.order_guid',
      orderName: 'descriptive_ancestors.aotest.order_iid.iname',
      state: 'descriptive_ancestors.aotest.order_iid.state_id',
      lastModified: 'descriptive_ancestors.aotest.order_iid.last_modified_date',
      project: 'hrchypak.Project.name',
      standards: 'descriptive_ancestors.aosubtest0.test_sequence.norm_name',
      testseries: 'descriptive_ancestors.aosubtest0.test_sequence.name'
    },
    customDefaultConfigs: {
      table: <ConfigurationSource<OrderTableConfig>['default']>PassByConfig.passByTableDefaultConfig
    },
    additionalSearchAttributeIdNames: ['descriptive_ancestors.aotest.order_iid.order_date'],
    searchAttributesAggregates: [
      {
        idName: 'descriptive_ancestors.aotest.order_iid.order_guid',
        child: {
          idName: 'descriptive_ancestors.aosubtest0.test_sequence.norm_name',
          child: {
            idName: 'descriptive_ancestors.aosubtest0.test_sequence.norm_version' // TODO:
          }
        }
      }
    ],
    addToOrderFromAggregation: PassByConfig.getPassByNormsFromAggregation,
    sideBarFilterConfig: {
      ...BaseConfig.sideBarFilterConfig,
      ...PassByConfig.sideBarFilterConfig
    },
    requireDevices: false,
    availableConfigurableTypes: [
      ConfigurationTypeIdentifiers.TABLE,
      ConfigurationTypeIdentifiers.ATTRIBUTES,
      ConfigurationTypeIdentifiers.NORMS
    ]
  }
};
