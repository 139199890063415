<div #contentMeasurementDatasets id="contentMeasurementDatasets">
  <h3>
    {{ 'MEASUREMENT.DATASETDESCRIPTION' | translate }}
  </h3>
  <ng-container *ngIf="appFacade.isDebugOrDev$ | async">
    <clr-toggle-container>
      <clr-toggle-wrapper>
        <input #lateTokenToggle (click)="toggleLateToken()" type="checkbox" clrToggle />
        <label>Use Token only when Processing is done</label>
      </clr-toggle-wrapper>
    </clr-toggle-container>
  </ng-container>
  <div id="measurementRelatedButtons" *ngIf="this.dataSets$ | async">
    <div class="btn-group btn-outline-primary btn-sm">
      <button
        *ngFor="let datatype of datatypes$ | async; let i = index"
        [attr.data-cy]="'DATATYPES_SHOW_' + datatype"
        [hidden]="i > showFilterButtons - 1"
        (click)="datatypeFilter$.next(datatype)"
        [ngClass]="datatype === (datatypeFilter$ | async) ? 'btn btn-primary' : 'btn'"
      >
        {{ datatype }}
      </button>

      <clr-dropdown>
        <button
          *ngIf="(datatypes$ | async) && (datatypes$ | async)!.length > showFilterButtons"
          data-cy="DATATYPES_MORE"
          class="btn btn-outline-primary btn-more-ellipsis"
          clrDropdownTrigger
          aria-label="..."
        >
          <clr-icon shape="ellipsis-horizontal"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen>
          <div
            clrDropdownItem
            [hidden]="n < showFilterButtons"
            *ngFor="let datatype of datatypes$ | async; let n = index"
            [attr.data-cy]="'DATATYPES_SHOW_' + datatype"
            (click)="datatypeFilter$.next(datatype)"
          >
            {{ datatype }}
          </div>
        </clr-dropdown-menu>
      </clr-dropdown>

      <button
        (click)="datatypeFilter$.next('')"
        data-cy="DATATYPES_SHOW_ALL"
        *ngIf="datatypes$ | async"
        [ngClass]="(datatypeFilter$ | async) === '' ? 'btn btn-primary' : 'btn'"
      >
        {{ 'MEASUREMENT.ALL' | translate }}
      </button>
    </div>
    <button
      id="formulaButton"
      *ngIf="isProcessingAvailable$ | async"
      class="btn btn-sm btn-primary"
      data-cy="SHOW_ANALYSIS_EDITOR_BUTTON"
      (click)="showFormulaInput$.next(true); showAnalysisEditor$.next(true)"
    >
      {{ 'PROCESSING.SELECT_ANALYSES' | translate }}
    </button>
  </div>

  <table id="measurementDatasetsTable" class="table" data-cy="measurementDatasetsTable" *ngIf="this.dataSets$ | async">
    <thead [ngClass]="{ 'right-padding-on-table-overflow': this.datasetTableIsOverflowing$ | async }">
      <th class="icon" *ngIf="displayPlotButton$ | async"></th>
      <th class="icon" *ngIf="displayStreamingButton$ | async"></th>
      <th class="icon"></th>
      <th class="left">{{ 'MEASUREMENT.POSITION' | translate }}</th>
      <th class="left">{{ 'MEASUREMENT.DIRECTION' | translate }}</th>
      <th class="left">{{ 'MEASUREMENT.QUANTITY' | translate }}</th>
      <th class="left">{{ 'MEASUREMENT.DESCRIPTION' | translate }}</th>
      <th class="left">{{ 'MEASUREMENT.DATATYPE' | translate }}</th>
    </thead>

    <tbody
      #datasetTable
      [style.max-height.px]="datasetTableMaxHeight$ | async"
      *ngIf="
        (processingFacade.filteredProcessingDatasetsLoading$ | async) === false &&
        (filteredDataSets$ | async)?.length > 0 &&
        (measurementsFacade.selectedMeasurement$ | async) as selectedMeasurement
      "
    >
      <tr *ngFor="let dataset of filteredDataSets$ | async">
        <td class="icon" *ngIf="displayPlotButton$ | async">
          <button
            class="btn btn-link btn-icon"
            [title]="'MEASUREMENT.SHOW_PLOT' | translate"
            (click)="showPlot$.next(dataset)"
          >
            <clr-icon shape="image"></clr-icon>
          </button>
        </td>
        <td class="icon" *ngIf="displayStreamingButton$ | async">
          <button
            class="btn btn-link btn-icon"
            *ngIf="dataset.attributes['hidePlay'] === false"
            [title]="'Stream'"
            (click)="
              this.prepareStream(dataset, selectedMeasurement);
              showFormulaInput$.next(false);
              showAnalysisEditor$.next(true)
            "
            [attr.disabled]="(processingFacade.currentProcessing$ | async)!.status === 'active' || null"
            [attr.data-cy]="
              (
                'streamingButton_' +
                dataset.attributes['name'] +
                '_' +
                dataset.attributes['direction'] +
                '_' +
                dataset.attributes['quantity'] +
                '_' +
                dataset.attributes['datatype']
              ).replace(' ', '_')
            "
          >
            <clr-icon shape="play"></clr-icon>
          </button>
        </td>

        <td class="icon">
          <clr-signpost *ngIf="dataset.PAKParameters!.length > 0">
            <button class="btn btn-link btn-icon" clrSignpostTrigger [title]="'MEASUREMENT.SHOW_DETAILS' | translate">
              <clr-icon shape="details"> </clr-icon>
            </button>
            <clr-signpost-content *clrIfOpen>
              <div *ngFor="let type of dataset.PAKParameters | keyvalue" class="attributeItem">
                <h4>
                  {{
                    (attributesFacade.availableDepotAttributes$ | async)!
                      | findAttributeById : type.key : 'AoMeasurement' : (selectedMeasurementPath$ | async)!
                      | translateAttribute
                  }}:
                </h4>
                <table>
                  <tr *ngFor="let parameter of type.value | keyvalue | measurementAttributeFilter">
                    <td>
                      {{
                        (attributesFacade.availableDepotAttributes$ | async)!
                          | findAttributeById : parameter.key : 'AoMeasurement' : (selectedMeasurementPath$ | async)!
                          | translateAttribute
                      }}:
                    </td>
                    <td>
                      <div *ngIf="parameter.value.toString() !== '[object Object]'; else elseBlock">
                        <b>
                          {{ parameter.value }}
                        </b>
                      </div>
                      <ng-template #elseBlock>
                        <div *ngFor="let dict of parameter.value | keyvalue | measurementAttributeFilter">
                          <div *ngIf="dict.value !== ''">
                            {{
                              (attributesFacade.availableDepotAttributes$ | async)!
                                | findAttributeById : dict.key : 'AoMeasurement' : (selectedMeasurementPath$ | async)!
                                | translateAttribute
                            }}:<b>
                              {{ dict.value }}
                            </b>
                          </div>
                        </div></ng-template
                      >
                    </td>
                  </tr>
                </table>
              </div>
            </clr-signpost-content>
          </clr-signpost>
        </td>
        <td [title]="dataset.attributes['name']">{{ dataset.attributes['name'] }}</td>
        <td [title]="dataset.attributes['direction']">{{ dataset.attributes['direction'] }}</td>
        <td [title]="dataset.attributes['quantity']">{{ dataset.attributes['quantity'] }}</td>
        <td [title]="dataset.attributes['description']">{{ dataset.attributes['description'] }}</td>
        <td [title]="dataset.attributes['datatype']">{{ dataset.attributes['datatype'] }}</td>
      </tr>
    </tbody>
    <tbody *ngIf="(processingFacade.filteredProcessingDatasetsLoading$ | async) === true">
      <div id="emptyContent">
        <cloud-spinner [size]="'medium'"></cloud-spinner>
      </div>
    </tbody>
    <tbody
      *ngIf="
        (processingFacade.filteredProcessingDatasetsLoading$ | async) === false &&
        (this.filteredDataSets$ | async)?.length === 0
      "
    >
      <div id="emptyContent">
        {{ 'PROCESSING.NOAPPLICABLEDATASETS' | translate }}
      </div>
    </tbody>
  </table>
</div>

<cloud-analysis-editor
  *ngIf="showAnalysisEditor$ | async"
  [showAnalysisEditor$]="showAnalysisEditor$"
  [showFormulaInput$]="showFormulaInput$"
></cloud-analysis-editor>

<cloud-plotting #plottingComponent [datasetInfos]="plottingDatasets$ | async"></cloud-plotting>
