import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import {
  AddRolesToGroup,
  AddRolesToUser,
  DeleteRolesFromGroup,
  DeleteUserRoles,
  LoadEffectiveRoles,
  LoadRoles
} from './roles.actions';
import { RolesPartialState } from './roles.reducer';
import { rolesQuery } from './roles.selectors';

@Injectable()
export class RolesFacade {
  roles$ = this.store.pipe(select(rolesQuery.getRoles));
  effectiveRoles$ = this.store.pipe(select(rolesQuery.getEffectiveRoles));
  loaded$ = this.store.pipe(select(rolesQuery.getLoaded));
  effectiveRolesLoaded$ = this.store.pipe(select(rolesQuery.getEffectiveRolesLoaded));
  roleToUserRelations$ = this.store.pipe(select(rolesQuery.getRoleToUserRelations));

  constructor(private store: Store<RolesPartialState>) {}

  getRoles() {
    this.store.dispatch(LoadRoles());
  }

  getEffectiveRoles() {
    this.store.dispatch(LoadEffectiveRoles());
  }

  deleteUserRoles(userId: string, roleIds: string[]) {
    this.store.dispatch(DeleteUserRoles({ userId, roleIds }));
  }

  addRolesToUser(userId: string, roleIds: string[]) {
    this.store.dispatch(AddRolesToUser({ userId, roleIds }));
  }

  addRolesToGroup(groupId: string, roleIds: string[]) {
    this.store.dispatch(AddRolesToGroup({ groupId, roleIds }));
  }

  removeRolesFromGroup(groupId: string, roleIds: string[]) {
    this.store.dispatch(DeleteRolesFromGroup({ groupId, roleIds }));
  }
}
