import {
  AbsoluteTimeValues,
  DataFormat,
  DatasetChunk,
  DatasetThreeDimensionalChunk,
  DatasetTwoDimensionalEquiTimeChunk,
  DatasetTwoDimensionalNonEquiTimeChunk
} from '../prototypes/DatasetMessages';
import { StreamingChart } from './streaming-chart.types';
import { convertAbsoluteTimeToInt128 } from './time.utils';

export interface DeserializerOptions {
  initialSampleCounter?: number;
  plottingParameters?: StreamingChart.PlottingParameters;
}

export const deserializeChunkForCHART2D = (
  chunk: DatasetChunk,
  options?: DeserializerOptions
): (StreamingChart.DataPoint2D | StreamingChart.DataPoint3D2D)[] => {
  if (chunk.data['ds2DEquiTime']) {
    return deserializeChunk2dEqui(chunk, options);
  }
  if (chunk.data['ds2DNonEquiTime']) {
    return deserializeChunk2dNonEqui(chunk);
  }
  if (chunk.data['ds3D']) {
    const plottingParameters = options?.plottingParameters;
    // NOTE: This is a special case where 3D data is plotted as 2D
    const data3D2D = deserializeChunk3d(chunk!);
    const data: (StreamingChart.DataPoint2D | StreamingChart.DataPoint3D2D)[] = [];

    // NOTE: This will break for non-time based tracking values
    // Pairing x/y chunks will break when the additional 2D Chunk for tracking arrives async
    if (plottingParameters?.rangeZ?.num === 1) {
      const nLines = plottingParameters?.rangeX?.num ?? 0;
      if (nLines > 0) {
        console.warn('3D data plotted as 2D with tracking value not yet supported');
        const start = plottingParameters?.rangeX?.start ?? 0;
        const end = plottingParameters?.rangeX?.stop ?? nLines;
        const delta = nLines > 1 ? (end - start) / (nLines - 1) : 0;
        for (let i = 0; i < nLines; i++) {
          const xValue = start + delta * i;
          // FIXME: This is a hack to get the correct x value for the tracking value
          data.push({ x: xValue, y: data3D2D.magnitudes[0][i] });
        }
      }
    } else if (
      // WIP MultipleSingleOrder
      plottingParameters?.quantityX?.name == 'Order' &&
      plottingParameters?.isComplex == true &&
      plottingParameters?.effectiveDataFormat == DataFormat.DF_Mag
    ) {
      const ORDER_INDEX = 0;
      data3D2D.zValues.map((trackValue, index) => {
        data[index] = {
          trackvalue: trackValue,
          steptime: data3D2D.timeValues[index],
          y: Math.sqrt(
            Math.pow(data3D2D.magnitudes[index][ORDER_INDEX], 2) +
              Math.pow(data3D2D.magnitudes[index][ORDER_INDEX + 1], 2)
          )
        };
      });
    } else {
      //TODO: y would be wrong for complex data
      data3D2D.zValues.map((trackValue, index) => {
        data[index] = {
          trackvalue: trackValue,
          steptime: data3D2D.timeValues[index],
          y: data3D2D.magnitudes[index][0]
        };
      });
    }
    return data;
  }
  return [];
};

export const deserializeChunkForCHART3D = (chunk: DatasetChunk): StreamingChart.MagnitudesAndTrackValues => {
  let result: StreamingChart.MagnitudesAndTrackValues = {
    magnitudes: [],
    zValues: [],
    timeValues: []
  };
  if (chunk.data['ds3D']) {
    result = deserializeChunk3d(chunk);
  }
  return result;
};

const deserializeChunk2dEqui = (chunk: DatasetChunk, options?: DeserializerOptions): StreamingChart.DataPoint2D[] => {
  const result: StreamingChart.DataPoint2D[] = [];
  if (!chunk.data['ds2DEquiTime']) {
    return result;
  }
  const dataSet: DatasetTwoDimensionalEquiTimeChunk = chunk.data['ds2DEquiTime'];
  if (dataSet) {
    const dataBlocks = dataSet.dataBlock;
    for (const dataBlock of dataBlocks) {
      let yValues: number[] = [];
      switch (dataBlock.yValues['oneofKind']) {
        case 'yDoubleValues':
          yValues = dataBlock.yValues.yDoubleValues.data;
          break;
        case 'yFloatValues':
          yValues = dataBlock.yValues.yFloatValues.data;
          break;
        case 'yInt32Values':
          yValues = dataBlock.yValues.yInt32Values.data;
          break;
        default:
          // NOTE: other datatypes not yet supported: CanMessages, Ethercat, flexray, gps, ptp, irig, angleValues
          console.warn('data values type not supported: ', dataBlock.yValues['oneofKind']);
          break;
      }
      for (const yVal of yValues) {
        result.push({
          // FIXME: This is a hack to get the correct x value for the tracking value
          x: BigInt(0), //BigInt(startPoint + initialSampleCounter * deltaTime),
          y: yVal
        });
        // initialSampleCounter++;
      }
    }
  }
  return result;
};

const deserializeChunk2dNonEqui = (chunk: DatasetChunk): StreamingChart.DataPoint2D[] => {
  const result: StreamingChart.DataPoint2D[] = [];

  const dataSet: DatasetTwoDimensionalNonEquiTimeChunk = chunk.data['ds2DNonEquiTime'];
  if (dataSet) {
    const dataBlocks = dataSet.dataBlock;
    for (const dataBlock of dataBlocks) {
      if (dataBlock.xValues['timeValues']) {
        const timeValues: AbsoluteTimeValues = dataBlock.xValues['timeValues'];
        let yValues: number[] = [];
        if (dataBlock.yValues['oneofKind']) {
          switch (dataBlock.yValues['oneofKind']) {
            case 'yDoubleValues':
              yValues = dataBlock.yValues.yDoubleValues.data;
              break;
            case 'yFloatValues':
              yValues = dataBlock.yValues['yFloatValues']?.data;
              break;
            case 'yInt32Values':
              yValues = dataBlock.yValues['yInt32Values']?.data;
              break;
            default:
              // other datatypes not yet supported: CanMessages, Ethercat, flexray, gps, ptp, irig
              console.warn('data values type not supported: ', dataBlock.yValues['oneofKind']);
              break;
          }
        }

        let i = 0;
        const timeValsList = timeValues.timeVals;
        for (const val of timeValsList) {
          const absoluteTimeVal = convertAbsoluteTimeToInt128(val);
          if (absoluteTimeVal) {
            const res: StreamingChart.DataPoint2D = {
              x: absoluteTimeVal,
              y: 1
            };
            if (yValues.length > i) {
              res.y = yValues[i];
            }
            if (res) {
              result.push(res);
            }
          }
          i++;
        }
      }
    }
  }
  return result;
};

const deserializeChunk3d = (chunk: DatasetChunk): StreamingChart.MagnitudesAndTrackValues => {
  let magnitudes: number[][] = [];
  const zValues: number[] = [];
  const timeValues: bigint[] = [];

  if (chunk.data['ds3D']) {
    const dataSet: DatasetThreeDimensionalChunk = chunk.data['ds3D'];
    if (dataSet) {
      const steps = dataSet.step;
      for (const [index, step] of steps.entries()) {
        zValues.push(step.zValue);

        const stepTime = convertAbsoluteTimeToInt128(step.time);
        if (stepTime !== undefined) {
          timeValues.push(stepTime);
        } else {
          console.error('time step value not available');
          timeValues.push(BigInt(0));
        }

        let stepMagnitudes: number[] = [];

        const oneOfKind = step.yValues['oneofKind'];
        if (oneOfKind) {
          stepMagnitudes = step.yValues[oneOfKind].data;
        } else if (!oneOfKind || step.yValues[oneOfKind] === undefined) {
          console.warn('data values type not supported: ', oneOfKind);
        }

        // tslint:disable-next-line: forin
        for (const magIndex in stepMagnitudes) {
          if (magnitudes.length === 0) {
            magnitudes = Array.from(Array(steps.length)).map(() => Array(stepMagnitudes.length));
          }
          magnitudes[index][magIndex] = stepMagnitudes[magIndex];
        }
      }
    }
  }

  return {
    timeValues: timeValues,
    magnitudes: magnitudes,
    zValues: zValues
  };
};
