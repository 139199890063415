// Angular Locales
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeJa from '@angular/common/locales/ja';
import de from 'date-fns/locale/de';
import enUS from 'date-fns/locale/en-US';
import ja from 'date-fns/locale/ja';

// Date Parser
import parse from 'date-fns/parse';

export type availableLanguagesStrings = 'en' | 'de' | 'ja';
export const availableLanguages: availableLanguagesStrings[] = ['en', 'de', 'ja'];
export const defaultLanguage: availableLanguagesStrings = 'en';

type localeMap = { [key in availableLanguagesStrings]: any };

export const localeMap: localeMap = {
  de: localeDe,
  en: localeEn,
  ja: localeJa
};

export const deriveDateFNsLocale = (translationServiceLocale: string): Locale => {
  switch (translationServiceLocale) {
    case 'de':
      return de;
    case 'ja':
      return ja;
    default:
      return enUS;
  }
};

const formatDistanceLocaleLookup: {
  [lang in availableLanguagesStrings]?: { [token: string]: { [count in 'one' | 'other']: string } };
} = {
  en: {
    lessThanXSeconds: {
      one: 'just now',
      other: 'just now'
    },
    xSeconds: {
      one: 'just now',
      other: 'just now'
    },
    halfAMinute: {
      one: '30 seconds',
      other: '30 seconds'
    },
    lessThanXMinutes: {
      one: '1 minute',
      other: '{{count}} minutes'
    },
    xMinutes: {
      one: '1 minute',
      other: '{{count}} minutes'
    },
    aboutXHours: {
      one: '1 hour',
      other: '{{count}} hours'
    },
    xHours: {
      one: '1 hour',
      other: '{{count}} hours'
    },
    xDays: {
      one: '1 day',
      other: '{{count}} days'
    },
    aboutXWeeks: {
      one: '1 week',
      other: '{{count}} weeks'
    },
    xWeeks: {
      one: '1 week',
      other: '{{count}} weeks'
    },
    aboutXMonths: {
      one: '1 month',
      other: '{{count}} months'
    },
    xMonths: {
      one: '1 month',
      other: '{{count}} months'
    },
    aboutXYears: {
      one: '1 year',
      other: '{{count}} years'
    },
    xYears: {
      one: '1 year',
      other: '{{count}} years'
    },
    overXYears: {
      one: '1 year',
      other: '{{count}} years'
    },
    almostXYears: {
      one: '1 year',
      other: '{{count}} years'
    }
  },
  de: {
    lessThanXSeconds: {
      one: 'gerade eben',
      other: 'gerade eben'
    },
    xSeconds: {
      one: 'gerade eben',
      other: 'gerade eben'
    },
    halfAMinute: {
      one: 'gerade eben',
      other: 'gerade eben'
    },
    lessThanXMinutes: {
      one: '1 Minute',
      other: '{{count}} Minuten'
    },
    xMinutes: {
      one: '1 Minute',
      other: '{{count}} Minuten'
    },
    aboutXHours: {
      one: '1 Stunde',
      other: '{{count}} Stunden'
    },
    xHours: {
      one: '1 Stunde',
      other: '{{count}} Stunden'
    },
    xDays: {
      one: '1 Tag',
      other: '{{count}} Tage'
    },
    aboutXWeeks: {
      one: '1 Woche',
      other: '{{count}} Wochen'
    },
    xWeeks: {
      one: '1 Woche',
      other: '{{count}} Wochen'
    },
    aboutXMonths: {
      one: '1 Monat',
      other: '{{count}} Monate'
    },
    xMonths: {
      one: '1 Monat',
      other: '{{count}} Monate'
    },
    aboutXYears: {
      one: '1 Jahr',
      other: '{{count}} Jahre'
    },
    xYears: {
      one: '1 Jahr',
      other: '{{count}} Jahre'
    },
    overXYears: {
      one: '1 Jahr',
      other: '{{count}} Jahre'
    },
    almostXYears: {
      one: '1 Jahr',
      other: '{{count}} Jahre'
    }
  }
};

export const formatDistanceShort = (lang: 'de' | 'en') => (token, count, _) => {
  let result;
  const tokenValue = formatDistanceLocaleLookup[lang]![token];
  if (count === 1) {
    result = tokenValue.one;
  } else {
    result = tokenValue.other.replace('{{count}}', String(count));
  }
  return result;
};

export function localeDateStringToDate(locale: string, value: string): Date {
  // Could be extended to get the format string from Angular (https://stackoverflow.com/a/52115357/1792564)

  let formatString;
  switch (locale) {
    case 'de':
      formatString = 'dd.MM.yyyy';
      break;
    case 'en':
      formatString = 'MM/dd/yyyy';
      break;
    case 'ja':
      formatString = 'yyyy/MM/dd';
      break;
    default:
      console.error('Tried to convert date with Invalid language locale');
  }
  return parse(value, formatString, new Date());
}
