import { Component, EventEmitter, Output } from '@angular/core';
import { StreamingFacade } from '../../+state/streaming.facade';
import { Observable, map } from 'rxjs';
import { AvailableAxis, AxisScalingTypes, ColorScalingTypes } from '../../plot-handlers/lightningPlot.types';
import { SelectableTrack } from '../integrated-streaming.component';

@Component({
  selector: 'cloud-external-streaming-interactions',
  templateUrl: './external-streaming-interactions.component.html',
  styleUrls: ['./external-streaming-interactions.component.css']
})
export class ExternalStreamingInteractionsComponent {
  @Output() resetAxis = new EventEmitter<boolean>();

  xAxisOptions$: Observable<AxisScalingTypes[]> = this.streamingFacade.availableInteractionsForAxis$('xAxis');
  selectedXAxisOption$: Observable<AxisScalingTypes> = this.streamingFacade.selectedInteractionForAxis$('xAxis');
  showXAxisOptions$: Observable<boolean> = this.xAxisOptions$.pipe(map((options) => options.length > 0));

  yAxisOptions$: Observable<AxisScalingTypes[]> = this.streamingFacade.availableInteractionsForAxis$('yAxis');
  selectedYAxisOption$: Observable<AxisScalingTypes> = this.streamingFacade.selectedInteractionForAxis$('yAxis');
  showYAxisOptions$: Observable<boolean> = this.yAxisOptions$.pipe(map((options) => options.length > 0));

  colorAxisOptions$: Observable<ColorScalingTypes[]> = this.streamingFacade
    .availableInteractionsForAxis$('color')
    .pipe(map((options) => options as ColorScalingTypes[]));
  selectedColorAxisOption$: Observable<ColorScalingTypes> = this.streamingFacade
    .selectedInteractionForAxis$('color')
    .pipe(map((option) => option as ColorScalingTypes));
  showColorAxisOptions$: Observable<boolean> = this.colorAxisOptions$.pipe(map((options) => options.length > 0));

  constructor(public streamingFacade: StreamingFacade) {}

  setAxisOption(axis: AvailableAxis, option: AxisScalingTypes) {
    if (axis === 'color') {
      this.streamingFacade.setSelectedColorInteraction(option as ColorScalingTypes);
    } else {
      this.streamingFacade.setSelectedAxisInteraction(axis, option);
    }
  }

  setTrack(track?: SelectableTrack) {
    this.streamingFacade.selectTrack(track);
  }
}
