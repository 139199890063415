import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map } from 'rxjs/operators';
import { LicensesLoaded } from './licenses.actions';

import { Store } from '@ngrx/store';
import * as UsersAction from '../users/users.actions';
import * as LicenseAction from './licenses.actions';

import { Spec } from '@muellerbbm-vas/grivet';
import { fetch } from '@nrwl/angular';
import { from } from 'rxjs/internal/observable/from';
import { createAppError } from '../../../../../app.factories';
import { LicensesService } from '../../services/licenses.service';
import { UsersPartialState } from '../users/users.reducer';
import { LicensesPartialState } from './licenses.reducer';

@Injectable()
export class LicensesEffects {
  loadLicenses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LicenseAction.LoadLicenses),
      fetch({
        run: (action: ReturnType<typeof LicenseAction.LoadLicenses>, state: LicensesPartialState) => {
          return from(this.licensesService.getLicenses()).pipe(
            map((licenses) => LicensesLoaded({ licenses: licenses }))
          );
        },

        onError: (action: ReturnType<typeof LicenseAction.LoadLicenses>, error) => {
          return createAppError(error);
        }
      })
    )
  );

  loadSpecialUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.LoadVibroLicenseUsers),
      fetch({
        run: (action: ReturnType<typeof UsersAction.LoadVibroLicenseUsers>, state: UsersPartialState) => {
          return from(this.licensesService.getSpecialUsersIDs()).pipe(
            map((usersIDs) => UsersAction.VibroLicenseUsersLoaded({ usersIDs }))
          );
        },

        onError: (action: ReturnType<typeof UsersAction.LoadVibroLicenseUsers>, error) => {
          return createAppError(error);
        }
      })
    )
  );

  addLicensesToRole = createEffect(() =>
    this.actions$.pipe(
      ofType(LicenseAction.AddLicensesToRole),
      fetch({
        run: (action: ReturnType<typeof LicenseAction.AddLicensesToRole>) => {
          return from(
            this.licensesService.addLicensesToRole(action.roleId, action.licenses).pipe(
              map((res: Spec.JsonApiDocument[]) => {
                return LicenseAction.AddLicensesToRoleSuccess({
                  roleId: action.roleId,
                  licenses: action.licenses
                });
              })
            )
          );
        },
        onError: (action: ReturnType<typeof LicenseAction.AddLicensesToRole>, error) => {
          return createAppError(error);
        }
      })
    )
  );

  removeLicensesFromRole = createEffect(() =>
    this.actions$.pipe(
      ofType(LicenseAction.RemoveLicensesFromRole),
      fetch({
        run: (action: ReturnType<typeof LicenseAction.RemoveLicensesFromRole>) => {
          return from(
            this.licensesService.removeLicensesFromRole(action.roleId, action.licenses).pipe(
              map((res: Spec.JsonApiDocument[]) => {
                return LicenseAction.RemoveLicensesFromRoleSuccess({
                  roleId: action.roleId,
                  licenses: action.licenses
                });
              })
            )
          );
        },
        onError: (action: ReturnType<typeof LicenseAction.RemoveLicensesFromRole>, error) => {
          return createAppError(error);
        }
      })
    )
  );

  constructor(private licensesService: LicensesService, private actions$: Actions, private store: Store) {}
}
