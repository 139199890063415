import { RouterNavigatedPayload } from '@ngrx/router-store';
import { noop, UnaryFunction } from 'rxjs';

export const getCurrentRouteIdentifier = (currPayload: RouterNavigatedPayload) => {
  return currPayload.routerState.root.firstChild?.routeConfig?.path;
};

export const getQueryParams = (currPayload: RouterNavigatedPayload) => {
  return currPayload.routerState.root.queryParams;
};
