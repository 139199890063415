// The Feature Flag Token is used to identify Local Storage / Cookie values
export const TOKEN_FEATURE_FLAG = 'FEATURE_';

/*
Feature Flag Areas
* user: flag enables or disables user-facing UI Elements
* dev: flag changes behavior or settings that are used for debugging / performance research
* test: flag changes behavior or settings that are exclusively used for (automated) testing purposes. Not exposed in debug mode.
*/
export type FeatureFlagArea = 'user' | 'dev' | 'test';

/*
Feature Flag Datatype
*/
export type FeatureFlagDatatype =
  | 'string'
  | 'number'
  | 'positiveNumber'
  | 'boolean'
  | 'url'
  | 'path'
  | 'color'
  | 'object'
  | 'array'
  | 'aggregateSize'
  | 'customAppsArray';

/*
Feature Flag Default Values per Datatype
*/
type FeatureFlagDefaults = {
  string: string;
  number: number;
  positiveNumber: number;
  boolean: boolean;
  url: URL;
  path: string;
  color: string;
  object: Record<string, unknown>;
  array: unknown[];
  aggregateSize: [number, number, number];
  customAppsArray: unknown[];
};

export const featureFlagDatatypeDefaults: Record<FeatureFlagDatatype, FeatureFlagDefaults[FeatureFlagDatatype]> = {
  string: '',
  number: 0,
  positiveNumber: 1,
  boolean: false,
  url: new URL('http://www.pak-cloud.com/'),
  path: '/',
  color: '#333333',
  object: {},
  array: [],
  aggregateSize: [1000, 1000, 1000],
  customAppsArray: []
};

/*
interface FeatureFlag
* id: the Feature Flag Id, aka FlagName
* deprecatedIds?: A list of IDs that this flag may have previously held
* descr?: A user-friendly description
* area: The FeatureFlagArea
* suppressValueConsoleLog?: While not really hiding the vlaue of certain flags, we should probably suppress their propagation in the console.
*/
export interface FeatureFlag {
  id: string;
  isDeprecated?: boolean;
  deprecatedIds?: string[];
  descr?: string;
  area: FeatureFlagArea;
  datatype: FeatureFlagDatatype;
  suppressValueConsoleLog?: boolean;
  defaultValue: any;
}

export type FeatureFlagContentValidatorFn = (value: unknown, ...args: unknown[]) => boolean;
export type FeatureFlagTypeValidatorFn<T> = (value: unknown) => value is T;
export type FeatureFlagValidatorFn = FeatureFlagTypeValidatorFn<any> | FeatureFlagContentValidatorFn;

// A map assigning validators to feature flag identifiers
export type FeatureFlagValidatorMappingFn = Partial<Record<string, FeatureFlagValidatorFn[]>>;

// Feature Flag Value Type
export type FeatureFlagValueType = string | boolean | object | number;

// Available Feature Flag Precedences
export type FeatureFlagPrecedence = 'default' | 'instance' | 'cookie' | 'runtime';

// Feature flag precedence order, later overrules earlier
export const concreteFeatureFlagPrecedence: FeatureFlagPrecedence[] = ['default', 'instance', 'cookie', 'runtime'];

// A concrete Feature Flag with precedence and value
export interface ConcreteFeatureFlag extends Omit<FeatureFlag, 'defaultValue'> {
  precedence: FeatureFlagPrecedence;
  value: any;
}
