import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, QueryParamsHandling, Router } from '@angular/router';
import { from, Observable } from 'rxjs';

export interface NavigationOptions {
  queryParams?: Params;
  queryParamsHandling?: QueryParamsHandling;
  // TODO: Extend discardQueryParams with mutually explusive 'keepQueryParams' as allowed List?
  // Would eliminate errors that occur because query params get extended without legacy code discarding them
  discardQueryParams?: string[];
  backToRoot?: boolean;
  replaceUrl?: boolean;
  state?: {
    [k: string]: any;
  };
}

@Injectable({
  providedIn: 'root'
})
export class NavigationFacade {
  constructor(private route: ActivatedRoute, private router: Router) {}

  navigate(config: NavigationOptions): Observable<boolean> {
    if (config.backToRoot) {
      return from(
        this.router.navigate([], {
          relativeTo: this.route.root,
          queryParams: {},
          replaceUrl: config.replaceUrl ?? false
        })
      );
    } else {
      if (config.discardQueryParams) {
        config.discardQueryParams.forEach((discardParam) => {
          if (config.queryParams) {
            config.queryParams[discardParam] = undefined;
          } else {
            console.warn(`Attempted to discard unset query parameter ${discardParam}`);
          }
        });
      }
      return from(
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: config.queryParams ?? this.route.snapshot.params,
          queryParamsHandling: config.queryParamsHandling ?? 'merge',
          replaceUrl: config.replaceUrl ?? false,
          state: config.state
        })
      );
    }
  }
}
