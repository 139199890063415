<ng-container *ngFor="let licenseDefinition of licensesDefinition">
  <h6>
    <i>{{ licenseDefinition.nls_key | translate }}</i> {{ 'LICENSING.LICENSES' | translate }}
  </h6>
  <clr-datagrid class="licensing-datagrid">
    <clr-dg-column [clrDgSortBy]="'name'" [clrDgSortOrder]="ascSort" class="licenseColumn">{{
      'LICENSING.OBTAINMENT_RIGHT' | translate
    }}</clr-dg-column>
    <clr-dg-column>{{ 'LICENSING.ROLES' | translate }}</clr-dg-column>

    <clr-dg-row
      *clrDgItems="let license of licenseDefinition.licenses$ | async; trackBy: trackById"
      [clrDgExpanded]="license.id === rowExpanded"
      class="license-row"
      [clrDgItem]="license"
      (click)="expandRow(license)"
      [ngClass]="{ expanded: license.id === rowExpanded }"
    >
      <clr-dg-cell class="name-cell"
        ><span [innerHTML]="license.name | searchBarHighlightFilter : (searchFilter$ | async)!"> </span
      ></clr-dg-cell>
      <clr-dg-cell class="role-wrapper">
        <ng-container *ngIf="rolesFacade.roles$ | async as roles">
          <span class="role-pill" *ngFor="let role of getRolesForLicense(license, roles); trackBy: trackById">
            <span [innerHTML]="role.name | searchBarHighlightFilter : (searchFilter$ | async)!"></span>
          </span>
        </ng-container>
      </clr-dg-cell>

      <clr-dg-row-detail *clrIfExpanded class="expanded-row" (click)="cancelBubble($event)">
        <clr-dg-cell class="pick-list-col">
          <cloud-pick-list
            sourceListIconFileName="slide_text_person"
            [sourceList]="availableRoles"
            [sourceListHeader]="'LICENSING.PICK_LISTS.AVAILABLE_ROLES' | translate"
            targetListIconFileName="slide_text_person"
            [targetList]="licenseRoles"
            [targetListHeader]="'LICENSING.PICK_LISTS.APPLIED_ROLES' | translate"
            (movedToTarget)="addRolesToLicense($event, license)"
            (movedToSource)="removeRoleFromLicense($event, license)"
            itemKey="name"
          ></cloud-pick-list>
        </clr-dg-cell>
      </clr-dg-row-detail>
    </clr-dg-row>

    <clr-dg-footer
      >{{ (licenseDefinition.licenses$ | async)?.length }} {{ 'LICENSING.LICENSES' | translate }}</clr-dg-footer
    >
  </clr-datagrid>
</ng-container>
