import { Action, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { ContentCollection, ContentCollectionDialog, ContentCollectionItem } from '../content-collection.types';
import * as CCActions from './content-collection.actions';

export const CONTENTCOLLECTION_FEATURE_KEY = 'contentCollection';

export interface ContentCollectionState {
  contentCollections: ContentCollection[]; // list of ContentCollections
  activeContentCollectionGuid?: string; // guid of selected ContentCollection
  loaded: boolean; // has the ContentCollection list been loaded
  itemsLoading: ContentCollectionItem[]; //copy to pak: ongoing item additions
  pakCopySuccess: boolean; //copy to clipboard successfull
  error?: any; // last none error (if any)
  ui: {
    showDialog: ContentCollectionDialog | null;
    itemsForCreation: ContentCollectionItem[];
    itemsDragged: ContentCollectionItem[];
  };
}

export interface ContentCollectionPartialState {
  readonly [CONTENTCOLLECTION_FEATURE_KEY]: ContentCollectionState;
}

export const initialState: ContentCollectionState = {
  contentCollections: [],
  itemsLoading: [],
  pakCopySuccess: false,
  loaded: false,
  ui: {
    showDialog: null,
    itemsForCreation: [],
    itemsDragged: []
  }
};

const CCReducer = createReducer(
  initialState,
  immerOn(CCActions.ShowContentCollectionDialog, (state, { dialog }) => {
    state.ui.showDialog = dialog;
  }),
  immerOn(CCActions.HideContentCollectionDialog, (state) => {
    state.ui.showDialog = null;
  }),
  immerOn(CCActions.PrepareContentCollectionCreationWithItems, (state, { items }) => {
    state.ui.showDialog = 'createCollectionWithItems';
    state.ui.itemsForCreation = items;
  }),
  immerOn(CCActions.EndContentCollectionCreationWithItems, (state) => {
    state.ui.itemsForCreation = [];
  }),
  immerOn(CCActions.ContentCollectionsLoaded, (state, { collections }) => {
    state.contentCollections = collections;
    state.loaded = true;
  }),
  immerOn(CCActions.ContentCollectionLoadError, (state, { error }) => {
    state.loaded = false;
    state.error = error['message'];
  }),
  immerOn(CCActions.CreateContentCollection, (state, { collection }) => {
    state.contentCollections.push({ ...collection, items: [] });
    state.ui.itemsForCreation = [];
  }),
  immerOn(CCActions.UndoCreateContentCollection, (state, { collection }) => {
    state.contentCollections = state.contentCollections.filter((c) => c.guid !== collection.guid);
  }),
  immerOn(CCActions.ContentCollectionCreated, (state, { clientGuid, returnedCollection }) => {
    const collection = state.contentCollections.find((cc) => cc.guid === clientGuid);
    if (collection) {
      collection.guid = returnedCollection.guid;
    }
    if (state?.activeContentCollectionGuid === clientGuid) {
      state.activeContentCollectionGuid = returnedCollection.guid;
    }
  }),
  immerOn(CCActions.DeleteContentCollection, (state, { collection }) => {
    state.contentCollections = state.contentCollections.filter((c) => c.guid !== collection.guid);
  }),
  immerOn(CCActions.UndoDeleteContentCollection, (state, { collection }) => {
    state.contentCollections.push(collection);
  }),
  immerOn(CCActions.EditContentCollection, (state, { collection, name }) => {
    const c = state.contentCollections.find((cc) => cc.guid === collection.guid);
    if (c) {
      c.name = name;
    }
  }),
  immerOn(CCActions.UndoEditContentCollection, (state, { collection }) => {
    const c = state.contentCollections.find((cc) => cc.guid === collection.guid);
    if (c) {
      c.name = collection.name;
    }
  }),
  immerOn(CCActions.ActivateContentCollection, (state, { collectionGuid }) => {
    state.activeContentCollectionGuid = collectionGuid;
  }),
  immerOn(CCActions.ContentCollectionShared, (state, { collection, shareInfo }) => {
    const c = state.contentCollections.find((cc) => cc.guid === collection.guid);
    if (c) {
      c.shareInfo?.push(shareInfo);
    }
  }),
  immerOn(CCActions.DeleteContentCollectionShare, (state, { collection, shareInfo }) => {
    const c = state.contentCollections.find((cc) => cc.guid === collection.guid);
    if (c) {
      c.shareInfo = c.shareInfo?.filter((s) => s.id !== shareInfo.id);
    }
  }),
  immerOn(CCActions.ContentCollectionShareEdited, (state, { collection, shareInfo }) => {
    const c = state.contentCollections.find((cc) => cc.guid === collection.guid);
    if (c) {
      c.shareInfo = c.shareInfo?.map((s) => (s.id === shareInfo.id ? shareInfo : s));
    }
  }),
  immerOn(CCActions.ContentCollectionShareDeclined, (state, { collectionId }) => {
    state.contentCollections = state.contentCollections.filter((c) => c.guid !== collectionId);

    if ((state.activeContentCollectionGuid = collectionId)) {
      state.activeContentCollectionGuid = state.contentCollections[0]?.guid;
    }
  }),

  immerOn(CCActions.AddContentCollectionItem, (state, { collectionGuid, item }) => {
    const collection = state.contentCollections.find((cc) => cc.guid === collectionGuid);
    collection?.items?.push(item);

    if (collection) {
      if (!state.itemsLoading.find((flightItem) => flightItem.itemID === item.itemID)) {
        state.itemsLoading.push(item);
      }
    }
  }),
  immerOn(CCActions.ContentCollectionItemAdded, (state, { collectionGuid, clientItemID, result }) => {
    const collection = state.contentCollections.find((cc) => cc.guid === collectionGuid);
    const item = collection?.items?.find((it) => it.itemID === clientItemID);
    if (item) {
      // Remove pak ref flight state
      state.itemsLoading = state.itemsLoading.filter((flightItems) => flightItems.itemID !== item.itemID);
      // Set correct new id and pak ref
      item.itemID = result.itemID;
      item.pakRef = result.pakRef;
    }
  }),
  immerOn(CCActions.AddMultipleContentCollectionItems, (state, { items, collectionGuid }) => {
    // We will load PAK references, so set this flight state for collection here
    const collection = state.contentCollections.find((cc) => cc.guid === collectionGuid);
    if (collection) {
      for (const item of items) {
        if (!state.itemsLoading.find((flightItem) => flightItem.itemID === item.itemID)) {
          state.itemsLoading.push(item);
        }
      }
    }
  }),
  immerOn(CCActions.MultipleContentCollectionItemsAdded, (state, { results, collectionGuid }) => {
    // Note: As we don't do an optimistic update in the Effect, we have just this one method in the reducer
    const collection = state.contentCollections.find((cc) => cc.guid === collectionGuid);
    if (collection) {
      collection.items = collection.items ? collection.items.concat(results) : results;
      state.itemsLoading = state.itemsLoading.filter(
        (flightItems) => !results.some((item) => flightItems.itemID !== item.itemID)
      );
    }
  }),
  immerOn(CCActions.UndoAddContentCollectionItem, (state, { collectionGuid, item }) => {
    const collection = state.contentCollections.find((cc) => cc.guid === collectionGuid);
    if (collection) {
      collection.items = collection?.items?.filter((it) => it.itemID !== item.itemID);
    }
    // Ensure, that we no longer try to get PAK references for this item
    state.itemsLoading = state.itemsLoading.filter((flightItems) => flightItems.itemID !== item.itemID);
  }),
  immerOn(CCActions.ContentCollectionItemRemoved, (state, { collectionGuid, item }) => {
    const collection = state.contentCollections.find((cc) => cc.guid === collectionGuid);
    if (collection) {
      collection.items = collection.items?.filter((it) => it.measurementId !== item.measurementId);
    }
    // Ensure, that we no longer try to get PAK references for this item
    state.itemsLoading = state.itemsLoading.filter((flightItems) => flightItems.itemID !== item.itemID);
  }),

  immerOn(CCActions.CopyContentCollectionToClipboardFeedback, (state, { success }) => {
    state.pakCopySuccess = success;
  }),

  immerOn(CCActions.ContentCollectionItemsDragged, (state, { contentCollectionItems }) => {
    state.ui.itemsDragged = contentCollectionItems;
  })
);

export function reducer(state: ContentCollectionState | undefined, action: Action) {
  return CCReducer(state, action);
}
