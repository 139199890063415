import * as WorkspaceActions from './workspace.actions';
import { createReducer, Action } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { ConfigurationSource, ConfigurationTypeIdentifiers, Workspace } from '../workspace.types';
import {
  PassByNormConfiguration,
  AttributesConfig,
  OrderTableConfig,
  WorkspaceConfig
} from '../config/workspace.config.types';

import normsDefaultConfig from 'apps/cloud/src/assets/config/.norms.json';
import attributesDefaultConfig from 'apps/cloud/src/assets/config/.attributeGroups.json';
import tableDefaultConfig from 'apps/cloud/src/assets/config/.orderTableConfig.json';
import { SortDirection } from '../../shared/types/sorting.types';
import { RequiredFieldsOnly } from '../../shared/types/utility.types';
import { XOR } from '@muellerbbm-vas/grivet/lib/typeHelpers';

export const WORKSPACE_FEATURE_KEY = 'workspace';

export enum WorkspaceConfigState {
  DEFAULT = 'Default',
  LOADING = 'Loading',
  SAVING = 'Saving',
  CREATING_CLIENT = 'Creating Client',
  SUCCESS = 'Success',
  FAILED = 'Failed'
}

type requiredWorkspaceConfigAttributeLookups = keyof RequiredFieldsOnly<WorkspaceConfig['attributeLookups']>;

export type OrderSearchSortDefinition = XOR<
  { attributeLookup: requiredWorkspaceConfigAttributeLookups },
  { attributeIdentifier: string }
> & { direction: SortDirection };

export type OrderSearchSortDefinitions = { primary: OrderSearchSortDefinition; secondary?: OrderSearchSortDefinition };

const defaultLastModifiedOrderSearchSortDefinition: OrderSearchSortDefinition = {
  attributeLookup: 'lastModified',
  direction: 'desc'
};

export interface WorkspaceState {
  workspaces: { [index: string]: Workspace };
  selectedWorkspaceId?: string;

  config: {
    STATE: WorkspaceConfigState;
    [ConfigurationTypeIdentifiers.NORMS]: ConfigurationSource<PassByNormConfiguration>;
    [ConfigurationTypeIdentifiers.ATTRIBUTES]: ConfigurationSource<AttributesConfig>;
    [ConfigurationTypeIdentifiers.TABLE]: ConfigurationSource<OrderTableConfig>;
  };

  sort: {
    default: OrderSearchSortDefinitions;
    user?: OrderSearchSortDefinitions;
  };
}

export interface WorkspacePartialState {
  readonly [WORKSPACE_FEATURE_KEY]: WorkspaceState;
}

export const initialState: WorkspaceState = {
  selectedWorkspaceId: '',
  workspaces: {},

  config: {
    STATE: WorkspaceConfigState.DEFAULT,
    norms: {
      default: normsDefaultConfig
    },
    attributes: {
      default: attributesDefaultConfig
    },
    table: {
      default: <ConfigurationSource<OrderTableConfig>['default']>tableDefaultConfig
    }
  },

  sort: {
    default: {
      primary: defaultLastModifiedOrderSearchSortDefinition
    }
  }
};

const WorkspaceReducer = createReducer(
  initialState,
  immerOn(WorkspaceActions.WorkspacesReceived, (state, { receivedWorkspaces }) => {
    receivedWorkspaces.forEach((ws) => {
      state.workspaces[ws.id] = ws;
    });
  }),
  immerOn(WorkspaceActions.SetSelectedWorkspaceByRouter, (state, { workspaceId }) => {
    state.selectedWorkspaceId = workspaceId;
    state.config.STATE = WorkspaceConfigState.LOADING;
  }),
  immerOn(WorkspaceActions.WorkspaceTypeReceived, (state, { workspaceId, workspaceType, workspaceTags }) => {
    state.workspaces[workspaceId].type = workspaceType;
    state.workspaces[workspaceId].tags = workspaceTags;
  }),
  immerOn(WorkspaceActions.SetNormsConfig, (state, { content, source }) => {
    state.config.norms[source] = content;
    if (source === 'server') {
      delete state.config.attributes['preview'];
    }
  }),
  immerOn(WorkspaceActions.SetAttributeConfig, (state, { content, source }) => {
    state.config.attributes[source] = content;
    if (source === 'server') {
      delete state.config.attributes['preview'];
    }
  }),
  immerOn(WorkspaceActions.SetTableConfig, (state, { content, source }) => {
    state.config.table[source] = content;
    if (source === 'server') {
      delete state.config.attributes['preview'];
    }
  }),
  immerOn(WorkspaceActions.ReceivedWorkspaceConfigs, (state) => {
    state.config.STATE = WorkspaceConfigState.DEFAULT;
  }),
  immerOn(WorkspaceActions.CreateClientAndApplyConfig, (state) => {
    state.config.STATE = WorkspaceConfigState.CREATING_CLIENT;
  }),
  immerOn(WorkspaceActions.PerformApplyConfigToServer, (state) => {
    state.config.STATE = WorkspaceConfigState.SAVING;
  }),
  immerOn(WorkspaceActions.ApplyConfigToServerSUCCESS, (state) => {
    state.config.STATE = WorkspaceConfigState.SUCCESS;
  }),
  immerOn(WorkspaceActions.ApplyConfigToServerFAILED, (state) => {
    state.config.STATE = WorkspaceConfigState.FAILED;
  }),
  immerOn(WorkspaceActions.ApplyConfigToServerDONE, (state) => {
    state.config.STATE = WorkspaceConfigState.DEFAULT;
  }),
  immerOn(WorkspaceActions.SetOrderTableColumnOrder, (state, { columnOrder }) => {
    if (state.selectedWorkspaceId) {
      state.workspaces[state.selectedWorkspaceId].orderTableColumns = columnOrder;
    } else {
      console.warn('Tried to set OrderTableColumnOrder without selected Workspace');
    }
  }),
  immerOn(WorkspaceActions.RecoverOrderTableColumnOrder, (state, { columnOrderPerWorkspace }) => {
    for (const [workspaceId, columnOrder] of Object.entries(columnOrderPerWorkspace)) {
      const currentWorkspace = state.workspaces[workspaceId];
      if (currentWorkspace) {
        currentWorkspace.orderTableColumns = columnOrder;
      }
    }
  }),
  immerOn(WorkspaceActions.SetSort, (state, { sort }) => {
    const sameSort = JSON.stringify(state.sort.user?.primary) === JSON.stringify(sort);
    if (sameSort && state.sort.user) {
      state.sort.user.primary = defaultLastModifiedOrderSearchSortDefinition;
    } else {
      if (sort.attributeIdentifier) {
        state.sort.user = {
          primary: {
            attributeIdentifier: sort.attributeIdentifier,
            direction: sort.direction
          }
        };
      } else if (sort.attributeLookup) {
        state.sort.user = {
          primary: {
            attributeLookup: sort.attributeLookup,
            direction: sort.direction
          }
        };
      }
    }
    if (state.sort.user) {
      state.sort.user.secondary = defaultLastModifiedOrderSearchSortDefinition;
    }
  })
);

export function workspaceReducer(state: WorkspaceState | undefined, action: Action) {
  return WorkspaceReducer(state, action);
}
