<div class="node-container" [ngStyle]="{ 'padding-left.px': node.childNodes ? 15 : 25 }" [attr.data-cy]="'Treenode_'+node.label">
  <ng-container *ngIf="node.childNodes">
    <clr-icon class="caret" shape="caret" dir="down" *ngIf="node.expanded" (click)="toggleExpansion()"></clr-icon>
    <clr-icon class="caret" shape="caret" dir="right" *ngIf="!node.expanded" (click)="toggleExpansion()"></clr-icon>
  </ng-container>
  <div
    class="node-content"
    [ngClass]="{ selected: node.selected, empty: !node.content?.hasContent, noChildren: !node.childNodes }"
    title="
      {{ title ? title + ': ' + node.label : node.label }} &nbsp; &nbsp; &nbsp;
    "
    (click)="nodeClicked(node)"
  >
    <div class="node-label">
      {{ node.label }}
    </div>
  </div>
  <div class="node-children" *ngIf="node.expanded">
    <cloud-panel-order-content-tree-node
      *ngFor="let childNode of node.childNodes"
      [node]="childNode"
      (nodeSelected)="nodeSelected.emit($event)"
    ></cloud-panel-order-content-tree-node>
  </div>
</div>
