export type languages = 'en' | 'de' | 'ja';

let currentLanguage: languages = 'en';
export const setCurrentLanguage = (lang: languages) => {
  currentLanguage = lang;
};

export const translate = (value: string, lang: languages = currentLanguage): string => {
  return translations[lang]?.[value] ?? value;
};

export const translations = {
  de: {
    '1st': '1-te',
    '2nd': '2-te',
    '3rd': '3-te',
    'th:': '-te',
    'Throughput': 'Zeitrohdaten',
    'Slow Throughput': 'Langsame Zeitrohdaten',
    'Throughput (CA)': 'Zeitrohdaten (KW)',
    'Time Series': 'Zeitreihe',
    'Tacho Edges': 'Drehzahlflanken',
    'Order APS': 'Ordnungs-APS',
    'Order': 'Ordnung',
    'Order band': 'Ordnungsband',
    'Overall level': 'Gesamtpegel',
    'Order combination': 'Ordnungs-Verknüpfung',
    'APS': 'APS',
    'Frequency level': 'Frequenzpegel',
    'Band pass': 'Bandpass',
    'Frequency level combination': 'Frequenzpegel-Verknüpfung',
    'Detector': 'Detektor',
    'Slow Quantity': 'Langsame Größe',
    'Octave': 'Oktaven',
    '3rd Octave': 'Terzen',
    'th Octave': '-tel-Oktaven',
    'Octave channel': 'Oktavkanal',
    '3rd Octave channel': 'Terzkanal',
    'th Octave channel': '-tel-Oktavkanal',
    'band pass': 'Bandpass',
    'overall level': 'Gesamtpegel',
    'combination': 'Verknüpfung',
    'SRS': 'SRS',
    'Octave transfer ratio': 'Oktav-Übertragungsverhältnis',
    '3rd Octave transfer ratio': 'Terz-Übertragungsverhältnis',
    'th Octave transfer ratio': '-tel-Oktav-Übertragungsverhältnis',
    'Analysis result': 'Analyse-Ergebnis',
    'cut': 'Schnitt',
    'Trajectory': 'Ortskurve',
    'Instationary loudness': 'Instationäre Lautheit',
    'DIN loudness': 'DIN-Lautheit',
    'Roughness': 'Rauigkeit',
    'Sharpness': 'Schärfe',
    'Engine roughness': 'Motorrauigkeit',
    'Fluctuation strength': 'Schwankungsstärke',
    'AI closed': 'AI geschlossen',
    'AI open': 'AI offen',
    'AI modified': 'AI modifiziert',
    'Integ. loudness': 'Integ. Lautheit',
    'Integ. DIN loudness': 'Integ. DIN-Lautheit',
    'Integ. roughness': 'Integ. Rauigkeit',
    'Integ. fluctuation strength': 'Integ. Schwankungsstärke',
    'Specific loudness': 'Spezifische Lautheit',
    'DIN loudness spectrum': 'DIN Lautheitsspektrum',
    'Specific roughness': 'Spezifische Rauigkeit',
    'Specific engine roughness': 'Spezifische Motorrauigkeit',
    'Specific fluctuation strength': 'Spezifische Schwankungsstärke',
    'AI closed spectrum': 'AI geschlossen Spektrum',
    'AI open spectrum': 'AI offen Spektrum',
    'AI modified spectrum': 'AI modifiziert Spektrum',
    'DIN tonal component': 'Tonhaltigkeit (DIN)',
    'ANSI tone-to-noise ratio': 'Tone to Noise Ratio',
    'ANSI prominence ratio': 'Prominence Ratio',
    'Time block': 'Zeitblock',
    'Variable time block': 'Variabler Zeitblock',
    'Auto correlation': 'Autokorrelation',
    'Window': 'Window',
    'Complex Cepstrum': 'Komplexes Cepstrum',
    'Power Cepstrum': 'Power Cepstrum',
    'Cross correlation': 'Kreuzkorrelation',
    'Impulse response': 'Impulsantwort',
    'complex': 'komplex',
    'real part': 'Realteil',
    'imaginary part': 'Imaginärteil',
    'magnitude': 'Magnitude',
    'phase': 'Phase',
    'Wigner-Ville distribution': 'Wigner-Ville Distribution',
    'Choi-Williams distribution': 'Choi-Williams Distribution',
    'Order multiple coherence': 'Ordnung multiple Kohärenz',
    'Multiple coherence': 'Multiple Kohärenz',
    'Order FFT': 'Ordnungs-FFT',
    'FFT': 'FFT',
    'Order CPS': 'Ordnungs-CPS',
    'Cross order': 'Kreuz-Ordnung',
    'Order CPS band': 'Ordnungs-CPS Bandpass',
    'Order CPS overall level': 'Ordnungs-CPS Gesamtpegel',
    'Order CPS combination': 'Ordnungs-CPS Verknüpfung',
    'CPS': 'CPS',
    'Cross frequency level': 'Kreuz-Frequenzpegel',
    'CPS band pass': 'CPS Bandpass',
    'CPS overall level': 'CPS Gesamtpegel',
    'Cross frequency level combination': 'Kreuz-Frequenzpegel Verknüpfung',
    'Order PRS': 'Ordnungs-PRS',
    'Phase referenced order': 'Phasenreferenzierte Ordnung',
    'Order PRS band': 'Ordnungs-PRS Bandpass',
    'Order PRS overall level': 'Ordnungs-PRS Gesamtpegel',
    'Order PRS combination': 'Ordnungs-PRS Verknüpfung',
    'PRS': 'PRS',
    'Phase referenced frequency level': 'Phasenreferenzierter Frequenzpegel',
    'PRS band pass': 'PRS Bandpass',
    'PRS overall level': 'PRS Gesamtpegel',
    'Phase referenced frequency level combination': 'Phasenreferenzierter Frequenzpegel Verknüpfung',
    'Order transfer function': 'Ordnungs-Übertragungsfunktion',
    'Transfer function': 'Übertragungsfunktion'
  }
};
