import { Injectable, Inject } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { JsonApi, Spec } from '@muellerbbm-vas/grivet';
import { AngularHttpContext } from '@vas/angular-http-context';
import { ANGULAR_HTTP_CONTEXT } from '../app.tokens';
import { CloudService } from '../services/cloud.service';
import { CloudDepot, CloudDepotType } from '../app.types';
import {
  DepotSearchParameters,
  DepotSearchResult,
  MeasurementDeleteOrUndeleteData,
  MeasurementToIgnore,
  MeasurementsToIgnore,
  defaultSearchParams
} from './depot-search.types';
import { Workspace, WorkspaceTag } from '../workspace/workspace.types';
import { TranslateService } from '@ngx-translate/core';
import { DepotSearchParser } from './parser/depot-search-parser';
import { DepotAttributeParser } from './parser/depot-attributes-parser';
import {
  isDepotSearchParams,
  pendingDeletionAttrId,
  ResultColumn,
  SearchResultEntry
} from '../shared/types/search.types';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { createRequest } from '../shared/utility-functions/search.helpers';
import {
  DepotAttribute,
  isDepotAttribute,
  SearchAttributeAndValue,
  SearchAttributeCompound,
  SearchFilter,
  SemanticDepotAttribute
} from '../shared/+state/attributes/attributes.types';

import { Observable, firstValueFrom, map, switchMap, take } from 'rxjs';
import { OrderSearchParameters } from '../order-management/order-search.types';
import { v4 as uuid } from 'uuid';
import { AttributesFacade } from '../shared/+state/attributes/attributes.facade';
import { ContentCollectionFacade } from '../content-collection/+state/content-collection.facade';
import { isSearchAttributeCompound } from '../order-management/order.service.helpers';

// internal helper class while preparing measurements for (un)deletion
interface AtfxFileMetaData {
  fileBrowseContentUrl: string;
  fileUndeleteUrl: string | undefined;
  fileBrowseContentId: string;
  depotId: string;
  depotName: string;
  relativePath: string[];
  originalMeasurementIds: string[];
}

// internal helper class while preparing measurement for (un)deletion
interface DeletePrepareSearchResult {
  measurementIds: string[];
  measurementNames: string[];
  orderGuid: string | undefined;
}

interface OrderMeasurementRelatedData {
  atfxFileMetaData: AtfxFileMetaData;
  measurementDeleteOrUndeleteData: MeasurementDeleteOrUndeleteData;
  orderGuid: string;
}

type MeasurementsToBeDeleted = [MeasurementDeleteOrUndeleteData[], MeasurementsToIgnore];

@Injectable({
  providedIn: 'root'
})
export class DepotSearchService {
  private depotAttributesDocument: JsonApi.Document;
  private cachedDepotAttributes: DepotAttribute[] = [];

  constructor(
    @Inject(ANGULAR_HTTP_CONTEXT) private context: AngularHttpContext,
    @Inject(APP_BASE_HREF) private baseHref: string,
    private cloudService: CloudService,
    private translate: TranslateService,
    private attributesFacade: AttributesFacade,
    private contentCollectionFacade: ContentCollectionFacade
  ) {}

  async ensureDepotAppStart() {
    return await this.cloudService.getAppStart('depot');
  }

  async doSearch(params: DepotSearchParameters | OrderSearchParameters): Promise<DepotSearchResult> {
    const parameters = structuredClone(params);

    const appStart = await this.ensureDepotAppStart();
    const depotSearchURL = appStart?.relationships['searches'].links?.related.url ?? new URL('');

    const result: DepotSearchResult = {
      numberOfEntries: 0,
      numberOfTotalResults: 0,
      entries: [],
      aggregates: []
    };

    // Restrict search to active content collection
    if (isDepotSearchParams(parameters) && parameters.restrictToActiveCC) {
      const onlyActiveCC = await firstValueFrom(this.contentCollectionFacade.activeContentCollection$);
      const relevantCCItemKeys = onlyActiveCC?.items?.map((item) => item.measurementId ?? '');
      if (parameters.searchFilters && relevantCCItemKeys) {
        parameters.searchFilters = await this.appendSearchFilterForActiveCCItems(
          parameters.searchFilters,
          relevantCCItemKeys
        );
      }
    }

    const searchRequest: Spec.ClientJsonApiDocument = await createRequest(parameters);
    const response = await this.context.postDocument(depotSearchURL, searchRequest);
    const responseDocument = new JsonApi.Document(response, this.context);

    const parser = new DepotSearchParser(this.translate, parameters.aggregateTreeDefs);
    parser.parseResponse(responseDocument.rawData);

    result.numberOfEntries = parser.getNumResults();
    result.numberOfTotalResults = parser.getNumTotalResults();
    result.entries = parser.getResultEntries();

    if (parameters.aggregateTreeDefs) {
      result.aggregates = parser.getResponseTrees();
    }

    return result;
  }

  async appendSearchFilterForActiveCCItems(
    searchFilters: SearchFilter,
    relevantCCItemKeys: string[]
  ): Promise<SearchFilter> {
    const depotAttribs = await firstValueFrom(this.attributesFacade.ensuredDepotAttributes.pipe(take(1)));

    const primaryKeyAttributeBaseName = 'pk';
    const pkAttribute = depotAttribs?.find((attrib) => attrib.idName === primaryKeyAttributeBaseName);

    if (pkAttribute) {
      const generateSearchAttributeAndValue = (key: string): SearchAttributeAndValue => ({
        id: uuid(),
        attribute: pkAttribute,
        exact_match: true,
        searchAttributeValue: key,
        searchAttributeBoolean: false
      });

      const individualPKfilters = relevantCCItemKeys.map((key) => generateSearchAttributeAndValue(key));

      if (individualPKfilters.length === 0) {
        // We need to add a dummy filter to prevent the search from returning all results
        individualPKfilters.push(
          generateSearchAttributeAndValue('DummyPrimaryKeyToPreventAnyResultsInSearchForEmptyCCs')
        );
      }

      const filter: SearchAttributeCompound = {
        type: 'or',
        filters: individualPKfilters
      };
      if (isSearchAttributeCompound(searchFilters[0])) {
        searchFilters[0].filters.push(filter);
      } else {
        searchFilters = [{ type: 'and', filters: [searchFilters[0], filter] }];
      }
    }

    return searchFilters;
  }

  async fetchDepotAttributes(): Promise<DepotAttribute[]> {
    await this.ensureDepotAttributesDocument();
    const parser = new DepotAttributeParser();
    parser.parseResponse(this.depotAttributesDocument.rawData);
    this.cachedDepotAttributes = parser.getAllAttributes();
    return this.cachedDepotAttributes;
  }

  async fetchDepotAttributesAndSemantics() {
    await this.ensureDepotAttributesDocument();
    const parser = new DepotAttributeParser();
    parser.parseResponse(this.depotAttributesDocument.rawData);
    this.cachedDepotAttributes = parser.getAllAttributes();

    return {
      attributes: this.cachedDepotAttributes,
      result: parser.getAllSemanticAttributes(),
      groups: parser.getAllSemanticContainers()
    };
  }

  async ensureDepotAttributesDocument(): Promise<void> {
    if (!this.depotAttributesDocument) {
      const appStart = await this.ensureDepotAppStart();
      const attributesUrl = appStart?.relationships['attributes'].links?.related.url;
      if (attributesUrl) {
        if (!attributesUrl.searchParams.has('include')) {
          attributesUrl.searchParams.append(
            'include',
            'depots,parent.parent.parent,semantic_attribute.container,semantic_attribute.depot_attributes'
          );
        }
        this.depotAttributesDocument = await JsonApi.Document.fromURL(attributesUrl, this.context);
      }
    }
  }

  async prepareForDeletionOrUndeletion(
    measurements: SearchResultEntry[],
    cloudDepots: { [index: string]: CloudDepot },
    isDeletion: boolean
  ): Promise<MeasurementsToBeDeleted> {
    let returnData: MeasurementsToBeDeleted = [[], {}];
    // Step 1: Check for pending deletion flags and handle PAK measurements
    const atfxMeasurements = await this.prepareForDeletionPAKData(measurements, returnData, cloudDepots, isDeletion);
    if (atfxMeasurements.length === 0) {
      // Early return as we can ignore all atfx-specific stuff
      return returnData;
    }

    // Step 2: Collect all zatfx measurement file data and mark atfx measurements as "to be ignored"
    const atfxMetaData = await this.prepareForDeletionCollectZatfxMetaData(atfxMeasurements, returnData);

    // Step 3: Get all other measurement contained in the zatfx files and handle all non-orders
    // For this first ensure that we have up-to-date cached depot attributes
    if (atfxMetaData.length > 0 && this.cachedDepotAttributes.length === 0) {
      await this.fetchDepotAttributesAndSemantics();
    }
    const orderMeasurements = await this.prepareForDeletionSimpleZatfxData(atfxMetaData, returnData);

    // Step 4: Order-specific checks come here, as we may not delete any orders in active workspaces...

    await this.prepareForDeletionOrderData(orderMeasurements, returnData);
    return returnData;
  }

  private ignoreMeasurementForDeletion(returnData: MeasurementsToBeDeleted, measurementIds: string[], reason: string) {
    const ignoreData = returnData[1];
    const measIgnoreDatas: MeasurementToIgnore[] = measurementIds.map((id) => {
      return { id: id, description: undefined, href: undefined };
    });
    if (reason in ignoreData) {
      measIgnoreDatas.forEach((meas) => ignoreData[reason].push(meas));
    } else {
      ignoreData[reason] = measIgnoreDatas;
    }
  }

  private ignoreOrderForDeletion(
    returnData: MeasurementsToBeDeleted,
    measurementIds: string[],
    orderGuid: string,
    workspace: Workspace,
    reason: string
  ) {
    const ignoreData = returnData[1];
    const measIgnoreDatas: MeasurementToIgnore[] = measurementIds.map((measId) => {
      const description = `Workspace: ${workspace.name}, Order: ${orderGuid}.zatfx`;
      const href = `${this.baseHref}orders/${workspace.id}/${orderGuid}`;
      return { id: measId, description: description, href: href };
    });
    if (reason in ignoreData) {
      measIgnoreDatas.forEach((meas) => ignoreData[reason].push(meas));
    } else {
      ignoreData[reason] = measIgnoreDatas;
    }
  }

  private async prepareForDeletionPAKData(
    measurements: SearchResultEntry[],
    returnData: MeasurementsToBeDeleted,
    cloudDepots: { [index: string]: CloudDepot },
    isDeletion: boolean
  ): Promise<SearchResultEntry[]> {
    // Prepare all PAK measurements for deletion and return all Atfx measurement for further treatment
    const atfxMeasurements: SearchResultEntry[] = [];
    await Promise.all(
      measurements.map(async (measurement) => {
        const isPendingDeletion = measurement[pendingDeletionAttrId]?.toLowerCase() === 'true';
        const doIgnoreMeasurement = isDeletion === isPendingDeletion;
        const depotType = await this.getDepotType(measurement, cloudDepots);
        if (doIgnoreMeasurement) {
          const reason = isDeletion
            ? _('DEPOTSEARCH.DELETE_MEASUREMENT.REASON_ALREADY_PENDING_DELETION')
            : _('DEPOTSEARCH.UNDELETE_MEASUREMENT.REASON_NO_PENDING_DELETION');
          this.ignoreMeasurementForDeletion(returnData, [measurement.measurementId], reason);
        } else if (depotType === undefined) {
          const reason = _('DEPOTSEARCH.DELETE_MEASUREMENT.REASON_UNKNOWN_DEPOT');
          this.ignoreMeasurementForDeletion(returnData, [measurement.measurementId], reason);
        } else if (depotType === 'PAKDepot') {
          // PAK measurements are simple
          const undeleteUrl = isDeletion ? undefined : await this.findPendingDeletionUrl(measurement);
          const relativePath = measurement.measurementId.split('/'); // TODO: do not parse measurementId
          relativePath.pop(); // remove measurement name
          relativePath[0] = cloudDepots[relativePath[0]]?.name ?? ''; // replace depod id with depot name
          returnData[0].push({
            fileType: 'PAK',
            browseUrl: measurement.measurementBrowseUrl,
            undeleteUrl: undeleteUrl,
            title: measurement.measurementId,
            location: relativePath,
            names: [measurement.measurementId],
            ids: [measurement.measurementId]
          });
        } else {
          // Atfx measurements are far more difficult, so we deal with them later...
          atfxMeasurements.push(measurement);
        }
      })
    );
    return atfxMeasurements;
  }

  private async prepareForDeletionCollectZatfxMetaData(
    atfxMeasurements: SearchResultEntry[],
    returnData: MeasurementsToBeDeleted
  ): Promise<AtfxFileMetaData[]> {
    // Collect meta data for all atfx measurements
    const atfxFileMetaData: AtfxFileMetaData[] = [];
    // First step is to find best guesses for browse urls of the zatfx measurements
    const guessedAtfxMeasurements = this.guessZATFXDepotBrowseContentURLs(atfxMeasurements);
    // Check now all the guessed urls...
    const remainingAtfxMeasurements: SearchResultEntry[] = [];
    await Promise.all(
      Object.entries(guessedAtfxMeasurements).map(async ([guessedUrl, measurements]) => {
        if (guessedUrl === '') {
          measurements.forEach((meas) => remainingAtfxMeasurements.push(meas));
          return;
        }
        const [guessedZatfxMetaData] = await this.checkURLForZatfxFile(new URL(guessedUrl)).catch(() => {
          return [undefined, undefined];
        });
        if (!guessedZatfxMetaData) {
          // Our guess seemingly was incorrect
          measurements.forEach((meas) => remainingAtfxMeasurements.push(meas));
          return;
        }

        // Store recevied atfx meta data for later use, while checking for duplicates
        const existingZatfxMetaData = atfxFileMetaData.find(
          (data) => data.fileBrowseContentUrl === guessedZatfxMetaData.fileBrowseContentUrl
        );
        if (existingZatfxMetaData) {
          measurements.forEach((meas) => existingZatfxMetaData.originalMeasurementIds.push(meas.measurementId));
        } else {
          measurements.forEach((meas) => guessedZatfxMetaData.originalMeasurementIds.push(meas.measurementId));
          atfxFileMetaData.push(guessedZatfxMetaData);
        }
      })
    );

    // Deal with all the other measurements, where our guess was incorrect
    await Promise.all(
      remainingAtfxMeasurements.map(async (measurement) => {
        const atfxMetaData = await this.findZATFXDepotBrowseContentURL(measurement).catch(() => null);
        if (!atfxMetaData) {
          // Measurement does no longer exist or is an atfx-measurement
          let reason: string = _('DEPOTSEARCH.DELETE_MEASUREMENT.REASON_MEASUREMENT_DOES_NOT_EXIST');
          if (atfxMetaData) {
            reason = _('DEPOTSEARCH.DELETE_MEASUREMENT.REASON_ATFX_MEASUREMENT');
          }
          this.ignoreMeasurementForDeletion(returnData, [measurement.measurementId], reason);
        } else {
          // Check for duplicates
          const existingZatfxMetaData = atfxFileMetaData.find(
            (data) => data.fileBrowseContentUrl === atfxMetaData.fileBrowseContentUrl
          );
          if (existingZatfxMetaData) {
            existingZatfxMetaData.originalMeasurementIds.push(measurement.measurementId);
          } else {
            atfxMetaData.originalMeasurementIds.push(measurement.measurementId);
            atfxFileMetaData.push(atfxMetaData);
          }
        }
      })
    );
    return atfxFileMetaData;
  }

  private async prepareForDeletionSimpleZatfxData(
    atfxFileMetaData: AtfxFileMetaData[],
    returnData: MeasurementsToBeDeleted
  ): Promise<OrderMeasurementRelatedData[]> {
    const orderMeasurements: OrderMeasurementRelatedData[] = [];
    await Promise.all(
      atfxFileMetaData.map(async (atfxMetaData) => {
        // In order to search for all measurements in this file, we need its path
        // Parsing the resource id is not ideal, but the only source for this piece of information
        const filePath = atfxMetaData.fileBrowseContentId.substring(atfxMetaData.depotId.length + 1);
        // Search for all measurements in this zatfx file
        const searchResult = await this.findAllMeasurementsInPath(atfxMetaData.depotId, filePath);
        let measurementTitle = atfxMetaData.fileBrowseContentUrl;
        if (searchResult.orderGuid) {
          measurementTitle = atfxMetaData.fileBrowseContentUrl + ' (Order)';
        }
        const deleteData: MeasurementDeleteOrUndeleteData = {
          fileType: 'ZATFX',
          browseUrl: atfxMetaData.fileBrowseContentUrl,
          undeleteUrl: atfxMetaData.fileUndeleteUrl,
          title: measurementTitle,
          location: atfxMetaData.relativePath,
          names: searchResult.measurementNames,
          ids: searchResult.measurementIds
        };
        // Reserve any orders for later treatment: They are second-class citizens with public health insurance, so they have to wait even longer!
        if (searchResult.orderGuid) {
          orderMeasurements.push({
            atfxFileMetaData: atfxMetaData,
            measurementDeleteOrUndeleteData: deleteData,
            orderGuid: searchResult.orderGuid
          });
        } else {
          returnData[0].push(deleteData);
        }
      })
    );
    return orderMeasurements;
  }

  private async prepareForDeletionOrderData(
    orderMeasurements: OrderMeasurementRelatedData[],
    returnData: MeasurementsToBeDeleted
  ) {
    // How it works:
    // search (without collapse) for orders
    // as we doen't know the exact attribute identifiers for workspace_id and order_id we have to search for the substring. But we know at least the substring
    // we ignore duplicate workspaces and also empty workspace_id results
    // if we have a workspace we get the tags for it
    // if there is no tag it can't be an passby workspace => delete it, if it has tags, we check if there is a "passby" tag and ignore it, otherwise we also delete it

    if (orderMeasurements.length === 0) {
      return;
    }

    // check every measurement of the selected measurements
    for (const orderMeasurement of orderMeasurements) {
      // search for related orders to the measurement
      // we only need the distinction passby order measurement versus anything else:
      // any measurement of the passby order has the relevant attributes since it is one huge zatfx file
      // therefore we do not need 1000 search results, actually even 1 should be enough
      let searchResult = await this.orderSearch(orderMeasurement.orderGuid, 10);

      if (searchResult) {
        if (searchResult && typeof searchResult === 'object') {
          const orders: DepotSearchResult = searchResult as DepotSearchResult;
          const entries = orders.entries;
          const workspaceIds: Set<string> = new Set();

          // get the workspace related to the given measurement. As the search gives a list, remove duplicate workspace IDs
          for (const entry of entries) {
            const workspaceIdKey = Object.keys(entry).find(
              (key) => key.includes('.workspace_id') && entry[key] !== '' && entry[key] !== 'None'
            );
            if (workspaceIdKey) {
              const workspaceId = entry[workspaceIdKey];
              workspaceIds.add(workspaceId);
            }
          }
          if (workspaceIds.size > 0) {
            const returnDataPromises = Array.from(workspaceIds).map((workspaceId) =>
              this.processMeasurementsBasedOnWorkspaceType(workspaceId, orderMeasurement)
            );
            const returnDataS = await Promise.all(returnDataPromises);
            returnDataS.forEach((element) => {
              returnData[0].push(...element[0]);
              returnData[1] = { ...returnData[1], ...element[1] };
            });
          } else {
            // this seems to be a zatfx that was created by an order but is no longer managed by any active workspace, therefore allow deletion
            returnData[0].push(orderMeasurement.measurementDeleteOrUndeleteData);
            //returnData[1] = { ...returnData[1], ...{ ['DEPOTSEARCH.DELETE_MEASUREMENT.REASON_UNKNOWN_ERROR']: [] } };
          }
        } else {
          // be permissive, just in case, although I do not know why the searchResult should not be an object
          returnData[0].push(orderMeasurement.measurementDeleteOrUndeleteData);
        }
      } else {
        // be permissive in case we do not find the order; the PAK cloud backend won't allow the deletion of orders in the depot anyway and it is annoying not to be able to delete something
        returnData[0].push(orderMeasurement.measurementDeleteOrUndeleteData);
      }
    }
  }

  private async processMeasurementsBasedOnWorkspaceType(
    workspaceID: string,
    orderMeasurement: OrderMeasurementRelatedData
  ): Promise<MeasurementsToBeDeleted> {
    const returnData: MeasurementsToBeDeleted = [[], {}];

    // get the actual workspace for every workspaceID and get the tags
    const workspaceResource = await firstValueFrom(this.getWorkspace(workspaceID));

    if (workspaceResource) {
      const tagsPromise = workspaceResource?.relatedResources?.['tags'];
      const tags = await tagsPromise;

      if (tags.length > 0) {
        const promises = tags.map(async (workspaceTag) => {
          const tagType = await workspaceTag.relatedResource['tag_type'];
          const wstag: WorkspaceTag = {
            tagTypeName: tagType.attributes ? tagType.attributes['name'] : '',
            value: workspaceTag.attributes ? workspaceTag.attributes['value'] : ''
          };

          const workspace: Workspace = {
            id: workspaceResource.id,
            tags: [wstag],
            orderTableColumns: [],
            name: workspaceResource.attributes?.['name']
          };
          if (wstag.value === 'passby') {
            const reason = _('DEPOTSEARCH.DELETE_MEASUREMENT.REASON_ACTIVE_PASSBY_ORDER');
            this.ignoreOrderForDeletion(
              returnData,
              orderMeasurement.atfxFileMetaData.originalMeasurementIds,
              orderMeasurement.orderGuid,
              workspace,
              reason
            );
          } else {
            returnData[0].push(orderMeasurement.measurementDeleteOrUndeleteData);
          }
          return returnData;
        });
        await Promise.all(promises);
      } else {
        returnData[0].push(orderMeasurement.measurementDeleteOrUndeleteData);
      }
    } else {
      // be permissive in case we do not find the workspace
      returnData[0].push(orderMeasurement.measurementDeleteOrUndeleteData);
    }
    return returnData;
  }

  private getWorkspace(workspaceId: string): Observable<JsonApi.Resource | undefined> {
    return this.cloudService.getAppStartObs('ods_order', true).pipe(
      switchMap((res) => (res ? res.relatedResources['workspaces'] : [])),
      map((workspaces) => {
        return workspaces.find((workspace) => workspace.id === workspaceId);
      })
    );
  }

  private async orderSearch(orderGuid: string, searchLimit?: number): Promise<DepotSearchResult | undefined> {
    const request = await this.createOrderSearchRequest(orderGuid, searchLimit);
    if (request) {
      return await this.doSearch(request);
    } else {
      return undefined;
    }
  }

  private async createOrderSearchRequest(
    orderGuid: string,
    searchLimit?: number
  ): Promise<OrderSearchParameters | undefined> {
    const params: OrderSearchParameters = JSON.parse(JSON.stringify(defaultSearchParams));
    params.searchLimit = searchLimit ? searchLimit : 1000;
    params.description = 'Order Search for order ' + orderGuid;

    let workspaceIdentifierList: DepotAttribute[] = [];
    let orderIdentifierList: DepotAttribute[] = [];

    const availableDepotAttributes = await firstValueFrom(this.attributesFacade.availableDepotAttributes$);

    if (availableDepotAttributes) {
      workspaceIdentifierList = availableDepotAttributes.filter((attribute: DepotAttribute) =>
        attribute.idName.includes('.workspace_id')
      );
      orderIdentifierList = availableDepotAttributes.filter((attribute: DepotAttribute) =>
        attribute.idName.includes('.order_guid')
      );
    }

    // If no identifier is found unknown is propagated up and results in an unknown error message
    if (workspaceIdentifierList.length < 1 || orderIdentifierList.length < 1) {
      return undefined;
    }

    const columns: ResultColumn[] = [];

    workspaceIdentifierList.forEach((workspaceIdetifier) => {
      const workspaceColumn: ResultColumn = {
        attribute: {
          discriminator: 'DepotAttribute',
          idName: workspaceIdetifier.idName,
          searchable: true,
          type: 'String',
          aoBaseName: ''
        },
        field: 'idName',
        contentLoaded: false
      };
      columns.push(workspaceColumn);
    });

    const orderFilters: SearchAttributeAndValue[] = [];

    orderIdentifierList.forEach((orderIdentifier) => {
      const orderAttribute: DepotAttribute = {
        discriminator: 'DepotAttribute',
        idName: orderIdentifier.idName,
        searchable: true,
        type: 'String'
      };
      const orderFilter: SearchAttributeAndValue = {
        id: uuid(),
        attribute: orderAttribute,
        searchAttributeValue: orderGuid,
        exact_match: true
      };

      orderFilters.push(orderFilter);
    });

    const searchParameters: OrderSearchParameters = {
      ...params,
      searchFilters: [
        {
          type: 'or',
          filters: orderFilters
        }
      ],
      resultColumns: columns
    };
    return searchParameters;
  }

  private async getDepotType(
    measurement: SearchResultEntry,
    cloudDepots: { [index: string]: CloudDepot }
  ): Promise<CloudDepotType | undefined> {
    const measurementDepotId = measurement.measurementId.split('/')[0]; // TODO: do not parse measurementId
    // Check cache...
    const cachedDepot = cloudDepots[measurementDepotId]?.depotType;
    if (cachedDepot) {
      return cachedDepot;
    }
    // ...or fall back to cloud api
    const allDepots = await this.cloudService.getDepots().toPromise();
    return allDepots?.find((depot) => depot.id === measurementDepotId)?.depotType;
  }

  private async findPendingDeletionUrl(measurement: SearchResultEntry): Promise<string | undefined> {
    const browseContentUrl = new URL(measurement.measurementBrowseUrl);
    browseContentUrl.searchParams.append('include', ''); // include nothing, we need just a relationship url
    const browseContent = await JsonApi.Document.fromURL(browseContentUrl, this.context);
    return browseContent.resource?.relationships?.['pending_deletion']?.links?.['related']?.url?.href;
  }

  private guessZATFXDepotBrowseContentURLs(atfxMeasurements: SearchResultEntry[]): {
    [guessedUrl: string]: SearchResultEntry[];
  } {
    // This guesses the url based on the measurement url
    // This hopefully prevents browsing through the browse API, which should speed up the process considerably
    // Note: It is very important, that the return URL does NOT end with a slash!!!
    const sortedAtfxMeasurements: { [guessedUrl: string]: SearchResultEntry[] } = {};
    for (const measurement of atfxMeasurements) {
      const measurementUrl = measurement.measurementBrowseUrl;
      const guessedZATFXIndex = measurementUrl.lastIndexOf('.zatfx/');
      const guessedZATFXUrl =
        guessedZATFXIndex > 0 ? measurementUrl.substring(0, guessedZATFXIndex + '.zatfx/'.length - 1) : '';
      if (guessedZATFXUrl in sortedAtfxMeasurements) {
        sortedAtfxMeasurements[guessedZATFXUrl].push(measurement);
      } else {
        sortedAtfxMeasurements[guessedZATFXUrl] = [measurement];
      }
    }
    return sortedAtfxMeasurements;
  }

  // May throw exceptions for 404-responses. Callers of this methods should catch those and treat them as "measurement no longer available"
  private async findZATFXDepotBrowseContentURL(measurement: SearchResultEntry): Promise<AtfxFileMetaData | undefined> {
    let currentBrowseUrl: URL | undefined = new URL(measurement.measurementBrowseUrl);
    while (currentBrowseUrl) {
      // We let 404-Http-Errors bubble up to signal already deleted measurements
      const [currentZatfxMetaData, nextBrowseUrl] = await this.checkURLForZatfxFile(currentBrowseUrl).catch((err) => {
        throw err;
      });
      if (currentZatfxMetaData) {
        // Success! We found a deletable zatfx file
        return currentZatfxMetaData;
      }
      // Otherwise check parent browse url (as we need the headers, we cannot use the grivet shortcuts here!)
      currentBrowseUrl = nextBrowseUrl;
    }

    // No deletable parent node found -> no url for a zatfx file there (probably because it is a .atfx file)
    return undefined;
  }

  private async checkURLForZatfxFile(browseContentUrl: URL): Promise<[AtfxFileMetaData | undefined, URL | undefined]> {
    // We simply return undefined on any errors, as we sometimes call this with guessed urls which may not exist
    // Note: Do *NOT* modify the browseContentUrl itself, as it gets all the way to the deletion confirmation dialog and is (partially) displayed there
    const browseContentUrlCopy = new URL(browseContentUrl.href);
    browseContentUrlCopy.searchParams.append('include', 'parent,depot'); // no need to include pending_deletion, as we only need its url
    const contentDocumentRaw = await this.context.getDocumentWithHeaders(browseContentUrlCopy).catch((err) => {
      if (err.name === 'HttpErrorResponse' && err.status === 404) {
        throw err;
      }
      return undefined;
    });
    if (!contentDocumentRaw?.body) {
      return [undefined, undefined];
    }

    const contentDocument = new JsonApi.Document(contentDocumentRaw.body, this.context);
    const allowHeader = contentDocumentRaw?.headers.get('Allow');
    if (allowHeader?.includes('DELETE')) {
      // Success: This corresponds to a zatfx-file, so extract the required meta data
      // Note: Do not use the self-links as it contains the include parameters, which mess up the display of the confirmation dialog
      const selfId = contentDocument.resource?.id;
      const depotResource = await contentDocument.resource?.relatedResource['depot'];
      const depotId = depotResource?.id;
      const depotName = depotResource?.attributes?.['name'];
      const relativePath = selfId?.split('/') ?? [];
      relativePath.pop(); // remove zatfx file name
      relativePath[0] = depotName; // replace depot id with depot name.
      const undeleteUrl = contentDocument.resource?.relationships?.['pending_deletion']?.links?.['related']?.url?.href;
      if (selfId && depotId) {
        const atfxMetaData: AtfxFileMetaData = {
          fileBrowseContentUrl: browseContentUrl.href,
          fileUndeleteUrl: undeleteUrl,
          fileBrowseContentId: selfId,
          depotId: depotId,
          depotName: depotName,
          relativePath: relativePath,
          originalMeasurementIds: []
        };
        // We could extract the parent url here, but there is currently no need to do so...
        return [atfxMetaData, undefined];
      } else {
        return [undefined, undefined];
      }
    } else {
      // Failure: Extract the parent url, as we may check there
      // It is fine, if the parent resource is null. This just means that there is no deletable resource (e.g. for .atfx files)
      const parentResource = await contentDocument.resource?.relatedResource['parent'];
      const parentResourceUrl = parentResource?.relationships['content']?.links?.['related'].url;
      return [undefined, parentResourceUrl];
    }
  }

  private async findAllMeasurementsInPath(depotId: string, path: string): Promise<DeletePrepareSearchResult> {
    const depotIdAttribute = this.cachedDepotAttributes.find((item) => item.idName === 'depot_id');
    const pathAttribute = this.cachedDepotAttributes.find((item) => item.idName === 'path');
    const startTimeAttribute = this.cachedDepotAttributes.find(
      (item) => item.idName === 'measurement_properties.start_time'
    );
    if (!depotIdAttribute || !pathAttribute || !startTimeAttribute) {
      throw Error(
        'Searching for deletable measurements requires depot attributes "depot_id", "path" and "measurement_properties.start_time"'
      );
    }
    const depotFilter: SearchAttributeAndValue = {
      id: '0',
      attribute: depotIdAttribute,
      searchAttributeValue: depotId,
      exact_match: true
    };
    const pathFilter: SearchAttributeAndValue = {
      id: '1',
      attribute: pathAttribute,
      searchAttributeValue: path,
      exact_match: false
    };
    // Avoid displaying measurement templates without actual measurement
    // --> same hack as in depot-search.effects 'addFinishedMeasurementConstraint'
    const startTimeFilter: SearchAttributeAndValue = {
      id: '2',
      attribute: startTimeAttribute,
      searchAttributeValue: '',
      exact_match: false
    };
    const params: DepotSearchParameters = {
      restrictToActiveCC: false,
      text: '',
      searchLimit: 1000, // we really don't expect so many measurements in one file, otherwise we have a huge problem here...
      offset: 0,
      searchFilters: [
        {
          type: 'and',
          filters: [depotFilter, pathFilter, startTimeFilter]
        }
      ],
      resultColumns: [] // required to be initalized here
    };

    // Now add all optional requested attributes
    // Subtitle for displayed measurement names
    const subtitleAttribute = this.cachedDepotAttributes.find((item) => item.idName === 'hrchypak.Subtitle.name');
    if (subtitleAttribute) {
      params.resultColumns!.push({
        attribute: subtitleAttribute,
        field: 'idName'
      });
    }
    // order_guid to determine whether this is an order (and potentially check whether it is still in an active workspace)
    const orderGuidAttributes = this.cachedDepotAttributes.filter((item) => item.idName.endsWith('order_guid'));
    orderGuidAttributes.forEach((item) =>
      params.resultColumns!.push({
        attribute: item,
        field: 'idName'
      })
    );

    const searchResult = await this.doSearch(params);
    const measurementIds = searchResult.entries.map((entry) => entry.measurementId).sort();
    const measurementNames = searchResult.entries.map((entry) => entry['hrchypak.Subtitle.name']).sort();
    let orderGuid: string | undefined;
    if (searchResult.entries.length > 0) {
      // All measurements should be contained in the one and only zatfx, so their orderGuids attributes should all be identical.
      // So we just check the first element...
      const entry = searchResult.entries[0];
      const possibleGuids = orderGuidAttributes.map((item) => entry[item.idName]).filter((value) => !!value);
      if (possibleGuids.length > 0) {
        // This assumes that all order-guids in the zatfx are identical. Otherwise we have a rather large problem, and not only with deleting measurements...
        orderGuid = possibleGuids[0];
      }
    }
    return {
      measurementIds: measurementIds,
      measurementNames: measurementNames,
      orderGuid: orderGuid
    };
  }

  async deleteMeasurement(measurementToDelete: MeasurementDeleteOrUndeleteData) {
    const measurementUrl = new URL(measurementToDelete.browseUrl);
    await this.context.deleteDocument(measurementUrl);
  }

  async undeleteMeasurement(measurementToUndelete: MeasurementDeleteOrUndeleteData) {
    if (!measurementToUndelete.undeleteUrl) {
      throw Error('Failed to undelete the measurement: No undelete url available.');
    }
    const undeleteUrl = new URL(measurementToUndelete.undeleteUrl);
    await this.context.deleteDocument(undeleteUrl);
  }

  getRelationshipFromAttribute(attribute: DepotAttribute | SemanticDepotAttribute): Spec.RelationshipObject {
    return isDepotAttribute(attribute)
      ? { data: { id: attribute.idName, type: 'DepotAttribute' } }
      : { data: { id: attribute.id, type: 'SemanticDepotAttribute' } };
  }
}
