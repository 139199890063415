import { OperatorFunction } from 'rxjs';
import { ClrLoadingState } from '@clr/angular';
import { pairwise, distinctUntilChanged, map, startWith } from 'rxjs/operators';

export function deriveClrLoadingState<T>(): OperatorFunction<T, ClrLoadingState> {
  return (input$) =>
    input$.pipe(
      pairwise(),
      distinctUntilChanged(),
      map(([prev, curr]) => {
        let ret = ClrLoadingState.DEFAULT;
        if (!prev && curr) {
          ret = ClrLoadingState.LOADING;
        } else if (prev && !curr) {
          ret = ClrLoadingState.SUCCESS;
        }
        return ret;
      }),
      startWith(ClrLoadingState.DEFAULT)
    );
}
