import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ClrDatagridSortOrder } from '@clr/angular';
import { Observable, combineLatest, map, shareReplay, take } from 'rxjs';
import { GroupsFacade } from '../+state/groups/groups.facade';
import { LicensesFacade } from '../+state/licenses/licenses.facade';
import { RolesFacade } from '../+state/roles/roles.facade';
import { UsersFacade } from '../+state/users/users.facade';
import { UserWithRolesAndLicenses } from '../../../../user/user.type';
import { lookupLicenseNames } from '../services/licenses-name-lookup';
import { LicensingSearchService } from '../services/licensing-search.service';
import { GroupInfo } from '../types/group.types';
import { LicenseInfo } from '../types/license.types';
import { RoleInfo } from '../types/role.types';

@Component({
  selector: 'cloud-groups-list',
  templateUrl: './groups-list.component.html',
  styleUrls: ['./groups-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupsListComponent implements OnInit {
  public groups$: Observable<GroupInfo[] | undefined>;
  public stringFilter = '';
  public rowExpanded: string | null;
  public showControlButtons = false;
  public lookupLicenseNames = lookupLicenseNames;
  public ascSort = ClrDatagridSortOrder.ASC;

  availableRoles: RoleInfo[];
  groupRoles: RoleInfo[];

  searchFilter$: Observable<string>;

  constructor(
    public usersFacade: UsersFacade,
    public groupsFacade: GroupsFacade,
    public rolesFacade: RolesFacade,
    public licensesFacade: LicensesFacade,
    public searchService: LicensingSearchService
  ) {}

  ngOnInit() {
    this.searchFilter$ = this.searchService.searchFilter$.pipe(shareReplay(1));

    this.groups$ = combineLatest([
      this.groupsFacade.groups$,
      this.searchService.searchFilter$,
      this.usersFacade.usersFull$,
      this.licensesFacade.licenses$
    ]).pipe(
      map(([groups, filter, users, licenses]) => {
        const filteredGroups = groups?.filter((group) => {
          const groupNameMatches = group.name.toLowerCase().includes(filter.toLowerCase());
          const rolesMatch = group.roles.some((role) => role.toLowerCase().includes(filter.toLowerCase()));
          const usersMatch = this.getUsersForGroup(group, users).some((user) =>
            user.userName.toLowerCase().includes(filter.toLowerCase())
          );
          const licenseMatch = this.getLicensesForGroup(group, licenses).some((license) =>
            license.name.toLowerCase().includes(filter.toLowerCase())
          );

          const matchesVibro = users.some((user) => user.hasVibroLicense && filter.toLowerCase().includes('vibro'));

          return groupNameMatches || rolesMatch || usersMatch || licenseMatch || matchesVibro;
        });
        return filteredGroups;
      })
    );
  }

  expandRow(group: GroupInfo) {
    this.rowExpanded = this.rowExpanded === group.id ? null : group.id;

    this.rolesFacade.roles$.pipe(take(1)).subscribe((roles: RoleInfo[]) => {
      this.setRolesLists(roles, group);
    });
  }

  addRoleToGroup(roles, group: GroupInfo) {
    const roleIds = roles.items.map((role: RoleInfo) => role.id);
    this.rolesFacade.addRolesToGroup(group.id, roleIds);
  }

  removeRoleFromGroup(roles, group: GroupInfo) {
    const roleIds = roles.items.map((role: RoleInfo) => role.id);
    this.rolesFacade.removeRolesFromGroup(group.id, roleIds);
  }

  getUsersForGroup(group: GroupInfo, users: UserWithRolesAndLicenses[]): UserWithRolesAndLicenses[] {
    return users.filter((user) => user.groups.includes(group.name));
  }

  getRolesForGroup(group: GroupInfo): Observable<string> {
    return this.rolesFacade.roles$.pipe(
      map((roles) => {
        return (
          roles
            ?.filter((role) => group.roles.includes(role.id))
            .map((role) => role.name)
            .join(', ') || ''
        );
      })
    );
  }

  /*   private setRolesLists(roles: RoleInfo[], group: GroupInfo) {
    this.groupRoles = group.roles.map((roleId) => roles.find((role) => role.id === roleId)) as RoleInfo[];
    this.availableRoles = roles?.filter((role) => !this.groupRoles.find((userRole) => userRole.id === role.id));
  }

  lookUpAndSortLicensePills(licenseIds: string[]): string[] {
    return licenseIds
      .map((licenseId) => lookupLicenseNames(licenseId, { includePeriod: true }))
      .sort((a, b) => a.localeCompare(b));
  } */

  private setRolesLists(roles: RoleInfo[], group: GroupInfo) {
    this.groupRoles = group.roles.map((roleId) => roles.find((role) => role.id === roleId)) as RoleInfo[];
    this.availableRoles = roles?.filter((role) => !this.groupRoles.find((userRole) => userRole.id === role.id));
  }

  lookUpAndSortLicensePills(licenses: Observable<LicenseInfo[]>): Observable<string[]> {
    return licenses.pipe(map((licenses) => licenses.map((license) => license.name).sort((a, b) => a.localeCompare(b))));
  }

  getLicensesForGroupObs(group: GroupInfo): Observable<LicenseInfo[]> {
    return this.licensesFacade.licenses$.pipe(map((licenses) => this.getLicensesForGroup(group, licenses)));
  }

  getLicensesForGroup(group: GroupInfo, licenses: LicenseInfo[]): LicenseInfo[] {
    return licenses.filter((license) => license.roles.some((role) => group.roles.includes(role)));
  }

  public getUserNames(users: UserWithRolesAndLicenses[]) {
    return users.map((user) => user.userName);
  }

  public cancelBubble(event: Event) {
    event.stopPropagation();
  }

  public trackById(index: number, item: object & { id: string }) {
    return item.id;
  }

  public trackByString(index: number, name: string) {
    return name;
  }
}
