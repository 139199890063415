import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { ToastrService } from 'ngx-toastr';

import { AppPartialState } from './app.reducer';
import { createAppError } from '../app.factories';
import * as AppActions from './app.actions';
import { availableLanguagesStrings, localeMap } from '../app.locales';
import { of, from } from 'rxjs';
import { catchError, filter, map, withLatestFrom } from 'rxjs/operators';
import { CloudService } from '../services/cloud.service';
import { Store } from '@ngrx/store';
import { AppFacade } from './app.facade';
import { fetch } from '@nrwl/angular';
import { LS_lang, LS_USER_ATTRIBUTE_TREE_WIDTH, LS_USER_HIDE_EMPTY_VALUES } from '../app.constants';

@Injectable()
export class AppEffects {
  setCookieAcknowledgedCookie$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.AcknowledgeCookieNotice),
      fetch({
        run: () => {
          this.cookieService.set('cookie_notice_closed', 'true', 3650, '/');
        },

        onError: (action: ReturnType<typeof AppActions.AcknowledgeCookieNotice>, error: Error) => {
          return createAppError(error);
        }
      })
    )
  );

  tracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.SetTracking),
        map((action) => {
          this.cookieService.set('FEATURE_TRACKING_ENABLED', action.enabled ? 'true' : 'false', 3650, '/');
          if (!action.enabled) {
            location.reload(); // Disabling Tracking requires page reload
          }
        }),
        catchError((error, caught) => {
          this.store.dispatch(createAppError(error));
          return caught;
        })
      ),
    { dispatch: false }
  );

  handleLangChangeEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.SetLanguage),
      fetch({
        run: (action) => {
          const newLang: availableLanguagesStrings = action.lang;
          this.translate.use(newLang);
          localStorage.setItem(LS_lang, newLang);
          registerLocaleData(localeMap[action.lang]);
          return AppActions.LanguageSet({ lang: action.lang });
        },

        onError: (action, error: Error) => {
          return createAppError(error);
        }
      })
    )
  );

  getAvailableApps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.RequestAvailableApps),
      fetch({
        run: (action) => {
          return from(this.cloudService.getAvailableCloudServices()).pipe(
            map((result) => AppActions.AvailableAppsReceived({ availableApps: result }))
          );
        },
        onError: (action, error: Error) => {
          return createAppError(error);
        }
      })
    )
  );

  getCloudServerVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.GetCloudServerVersion),
      fetch({
        run: () => {
          return from(this.cloudService.getCloudSeverVersion()).pipe(
            map((result) => AppActions.CloudServerVersionReceived({ serverVersion: result }))
          );
        },
        onError: (_, error: Error) => {
          return createAppError(error);
        }
      })
    )
  );

  getClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.GetClient),
      fetch({
        run: () => {
          return from(this.cloudService.getClient()).pipe(map((client) => AppActions.ClientReceived({ client })));
        },
        onError: (_, error: Error) => {
          return createAppError(error);
        }
      })
    )
  );

  getCloudDepotsOnLangChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.SetLanguage),
      fetch({
        run: () => {
          return this.cloudService
            .getDepots()
            .pipe(map((depots) => AppActions.CloudDepotsReceived({ receivedDepots: depots })));
        },

        onError: (_, error: Error) => {
          return createAppError(error);
        }
      })
    )
  );

  getCloudDepots$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.GetCloudDepots),
      fetch({
        run: (action: ReturnType<typeof AppActions.GetCloudDepots>, state: AppPartialState) => {
          return this.cloudService
            .getDepots()
            .pipe(map((depots) => AppActions.CloudDepotsReceived({ receivedDepots: depots })));
        },

        onError: (action: ReturnType<typeof AppActions.GetCloudDepots>, error: Error) => {
          return createAppError(error);
        }
      })
    )
  );

  getIsUserAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.SetIsUserAdmin),
      fetch({
        run: () => {
          return this.cloudService
            .getHasUserAdminPermissions()
            .pipe(map((isAdmin) => AppActions.IsUserAdminReceived({ isAdmin: isAdmin })));
        },

        onError: () => {
          return of(AppActions.IsUserAdminReceived({ isAdmin: false }));
        }
      })
    )
  );

  getIsDepotAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.SetIsDepotAdmin),
      fetch({
        run: () => {
          return this.cloudService
            .getHasDepotAdminPermissions()
            .pipe(map((isAdmin) => AppActions.IsDepotAdminReceived({ isAdmin: isAdmin })));
        },

        onError: () => {
          return of(AppActions.IsDepotAdminReceived({ isAdmin: false }));
        }
      })
    )
  );

  handleErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.AppError),
        map((action: ReturnType<typeof AppActions.AppError>) => {
          let errorTitle = '';
          this.translate.get('APP.ERROR').subscribe((trans) => {
            errorTitle = trans;
          });

          let unknownErrorTranslated = '';
          this.translate.get('APP.ERR.UNKNOWN').subscribe((trans) => {
            unknownErrorTranslated = trans;
          });
          let errorMsg = action.err.message || unknownErrorTranslated;
          if (action.err.translationKey) {
            const errorTranslated = this.translate.instant(
              action.err.translationKey,
              action.err.translationParameter ?? {}
            );
            if (errorTranslated) {
              errorMsg = errorTranslated;
            }
          }
          this.toastrService.error(errorMsg, errorTitle);
        })
      ),
    { dispatch: false }
  );

  handleSuccessMsg$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.SuccessMsg),
        map((action: ReturnType<typeof AppActions.SuccessMsg>) => {
          let msg = action.message;
          if (action.translationKey) {
            const msgTranslated = this.translate.instant(action.translationKey);
            if (msgTranslated) {
              msg = msgTranslated;
            }
          }
          this.toastrService.success(msg, 'Progess', { enableHtml: true });
        })
      ),
    { dispatch: false }
  );

  restoreHideEmptyValuesFromLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.AvailableAppsReceived),
        map((_) => {
          const userHideEmptyValues = localStorage.getItem(LS_USER_HIDE_EMPTY_VALUES);
          const isValidBoolean =
            userHideEmptyValues?.toLocaleLowerCase() === 'true' || userHideEmptyValues?.toLocaleLowerCase() === 'false';

          if (userHideEmptyValues && isValidBoolean) {
            let boolUserHideEmptyValues: boolean;
            if (userHideEmptyValues?.toLocaleLowerCase() === 'true') {
              boolUserHideEmptyValues = true;
            } else {
              boolUserHideEmptyValues = false;
            }
            this.store.dispatch(
              AppActions.SetUserPreference({ preference: 'hideEmptyValues', value: boolUserHideEmptyValues })
            );
          }
        }),
        catchError((error, caught) => {
          this.store.dispatch(createAppError(error));
          return caught;
        })
      ),
    { dispatch: false }
  );

  writeHideEmptyValuesToLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.SetUserPreference, AppActions.ToggleUserPreference),
        filter((action) => action.preference === 'hideEmptyValues'),
        withLatestFrom(this.appFacade.hideEmptyValues$),
        map(([_, hideEmptyValues]) => {
          localStorage.setItem(LS_USER_HIDE_EMPTY_VALUES, hideEmptyValues.toString());
        }),
        catchError((error, caught) => {
          this.store.dispatch(createAppError(error));
          return caught;
        })
      ),
    { dispatch: false }
  );

  restoreAttributeTreeWidthFromLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.AvailableAppsReceived),
        map((_) => {
          const userAttributeTreeWidthFromLS = localStorage.getItem(LS_USER_ATTRIBUTE_TREE_WIDTH);
          if (userAttributeTreeWidthFromLS) {
            const userAttributeTreeWidth = parseInt(userAttributeTreeWidthFromLS, 10);
            const isValidNumber = !isNaN(userAttributeTreeWidth);
            if (isValidNumber) {
              this.store.dispatch(
                AppActions.SetUserPreference({ preference: 'attributeTreeWidth', value: userAttributeTreeWidth })
              );
            }
          }
        }),
        catchError((error, caught) => {
          this.store.dispatch(createAppError(error));
          return caught;
        })
      ),
    { dispatch: false }
  );

  writeAttributeTreeWidthToLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.SetUserPreference),
        map((action) => {
          localStorage.setItem(LS_USER_ATTRIBUTE_TREE_WIDTH, action.value.toString());
        }),
        catchError((error, caught) => {
          this.store.dispatch(createAppError(error));
          return caught;
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private cloudService: CloudService,
    private appFacade: AppFacade,
    private cookieService: CookieService,
    private translate: TranslateService,
    private toastrService: ToastrService,
    private store: Store<AppPartialState>
  ) {}
}
