import { Spec } from '@muellerbbm-vas/grivet';
import { AggregateTree, Aggregate, TreeNode, AggregateTreeDef } from '../../shared/types/aggregateTree.types';
import { TranslateService } from '@ngx-translate/core';
import { SearchResultEntry } from '../../shared/types/search.types';

interface SearchResult {
  measurementId: string;
  measurementBrowseUrl?: URL;
  receivedAttributesIDs: string[];
}

interface ReceivedAttributes {
  [id: string]: {
    value: string;
    requestedAttributeID: string;
  };
}

interface BucketData {
  [id: string]: {
    key: string;
    displayKey: string;
    value: string;
    childID?: string;
  };
}

interface ReceivedAggregations {
  [id: string]: {
    bucketIDs: string[];
    requestedAggregateID: string;
  };
}

export class DepotSearchParser {
  constructor(private translate: TranslateService, requestedAggregateDefs?: AggregateTreeDef[]) {
    this.requestedAggregateDefs = requestedAggregateDefs ?? [];
    requestedAggregateDefs?.forEach((aggTree) => this.extractChildAggregates(aggTree.aggregate));
  }

  private requestedAggregateDefs: AggregateTreeDef[] = [];
  private allRequestedAggregates: { [id: string]: Aggregate } = {};
  private allSearchResults: SearchResult[] = [];
  private allReceivedAttributes: ReceivedAttributes = {};
  private allBucketData: BucketData = {};
  private allReceivedAggsData: ReceivedAggregations = {};
  private attributeRequest2AttributeMap: { [id: string]: string } = {};
  private aggregateRequest2ReceivedMap: { [id: string]: string } = {};
  private numFurtherResults: number;

  public parseResponse(rawData: Spec.JsonApiDocument): void {
    this.numFurtherResults = (rawData.data as Spec.ResourceObject).attributes!['num_further_results'];

    const includedData: Spec.ResourceObject[] = rawData.included ?? [];
    for (const resourceData of includedData) {
      switch (resourceData.type) {
        case 'SearchResult':
          {
            const measID = this.extractRelatedID(resourceData, 'measurement');
            const measURL = this.extractRelatedURL(resourceData, 'measurement');
            const recAttr = this.extractRelatedIDs(resourceData, 'received_attributes');
            this.allSearchResults.push({
              measurementId: measID,
              measurementBrowseUrl: measURL,
              receivedAttributesIDs: recAttr
            });
          }
          break;

        case 'RequestedAttribute':
          {
            const attributeID = this.extractRelatedID(resourceData, 'depot_attribute');
            this.attributeRequest2AttributeMap[resourceData.id] = attributeID;
          }
          break;

        case 'ReceivedAttribute':
          {
            const reqAttrID = this.extractRelatedID(resourceData, 'requested_attribute');
            this.allReceivedAttributes[resourceData.id] = {
              value: resourceData.attributes!['value'],
              requestedAttributeID: reqAttrID
            };
          }
          break;

        case 'ReceivedTermAggregation':
        case 'ReceivedDateHistogramAggregation':
          {
            const buckets = this.extractRelatedIDs(resourceData, 'buckets');
            const reqAggID = this.extractRelatedID(resourceData, 'requested_aggregation');
            this.allReceivedAggsData[resourceData.id] = {
              bucketIDs: buckets,
              requestedAggregateID: reqAggID
            };
            this.aggregateRequest2ReceivedMap[reqAggID] = resourceData.id;
          }
          break;

        case 'TermAggregationBucket':
        case 'DateHistogramAggregationBucket':
          {
            const childID: string | undefined = this.extractRelatedIDs(resourceData, 'child_aggregations')[0];
            this.allBucketData[resourceData.id] = {
              key: resourceData.attributes!['key'],
              displayKey: resourceData.attributes!['key_as_string'],
              value: resourceData.attributes!['doc_count'],
              childID: childID
            };
          }
          break;
      }
    }
  }

  public getNumResults(): number {
    return this.allSearchResults.length;
  }

  public getNumTotalResults(): number {
    return this.allSearchResults.length + this.numFurtherResults;
  }

  public getResultEntries(): SearchResultEntry[] {
    return this.allSearchResults.map((result) => {
      const entry: SearchResultEntry = {
        measurementBrowseUrl: result.measurementBrowseUrl?.href ?? '',
        measurementBrowsePath: result.measurementBrowseUrl?.pathname ?? '',
        measurementId: result.measurementId
      };
      for (const receivedAttributeID of result.receivedAttributesIDs) {
        const receivedAttribute = this.allReceivedAttributes[receivedAttributeID];
        const depotAttributeID = this.attributeRequest2AttributeMap[receivedAttribute.requestedAttributeID];
        entry[depotAttributeID] = receivedAttribute.value;
      }
      return entry;
    });
  }

  public getResponseTrees(): AggregateTree[] {
    return this.requestedAggregateDefs.map((aggregateTreeDef) => {
      const tree: AggregateTree = {
        definition: aggregateTreeDef
      };
      const receivedAggregateID = this.aggregateRequest2ReceivedMap[aggregateTreeDef.aggregate.guid];
      tree.nodes = this.getResponseTreeNode(receivedAggregateID, undefined);
      return tree;
    });
  }

  private getResponseTreeNode(receivedAggregateID: string, parentNode: TreeNode | undefined): TreeNode[] {
    const receivedAggregate = this.allReceivedAggsData[receivedAggregateID];
    const requestAggregate: Aggregate = this.allRequestedAggregates[receivedAggregate.requestedAggregateID];
    const keyFormatFct = this.getKeyFormatFct(requestAggregate);

    return receivedAggregate.bucketIDs
      .map((bucketId) => {
        const bucket = this.allBucketData[bucketId];
        const label = keyFormatFct(bucket.displayKey);
        const node: TreeNode = {
          id: bucketId,
          label: label,
          docCount: parseInt(bucket.value, 10),
          originalValue: bucket.key,
          aggregate: requestAggregate,
          expanded: false,
          parent: parentNode
        };
        // Add children to first level
        if (bucket.childID) {
          // To be able to save the parent node to the state, we have to make a deep copy (but without any children!)
          node.hasChildren = true;
          node.childrenLoadingStatus = 'done';
          const copyNode = JSON.parse(JSON.stringify(node));
          node.children = this.getResponseTreeNode(bucket.childID, copyNode);
        }
        return node;
      })
      .filter((node) => node.docCount > 0);
  }

  private extractRelatedID(resourceData: Spec.ResourceObject, relationshipKey: string): string {
    const data = resourceData.relationships?.[relationshipKey].data;
    return Array.isArray(data) ? '' : data?.id ?? '';
  }

  private extractRelatedIDs(resourceData: Spec.ResourceObject, relationshipKey: string): string[] {
    const data = resourceData.relationships?.[relationshipKey].data;
    return Array.isArray(data) ? data.map((item) => item.id) : [];
  }

  private extractRelatedURL(resourceData: Spec.ResourceObject, relationshipKey: string): URL | undefined {
    const measLink: Spec.Link | undefined = resourceData.relationships?.[relationshipKey].links?.['related'];
    if (typeof measLink === 'string') {
      return new URL(measLink);
    } else if (measLink === undefined) {
      return undefined;
    } else {
      return new URL(measLink.href);
    }
  }

  private getKeyFormatFct(requestAggregate: Aggregate): (key: string) => string {
    let keyFormatFct = (key: string) => key;
    if (requestAggregate.type === 'RequestedDateHistogramAggregation') {
      const interval = requestAggregate.attributes?.['interval'];
      if (interval === '1y') {
        keyFormatFct = (key: string) => new Date(key).getFullYear().toString();
      } else if (interval === '1M') {
        keyFormatFct = (key: string) => new Date(key).toLocaleString(this.translate.currentLang, { month: 'long' });
      }
    }
    return keyFormatFct;
  }

  private extractChildAggregates(aggregate: Aggregate): void {
    this.allRequestedAggregates[aggregate.guid] = aggregate;
    if (aggregate.child) {
      this.extractChildAggregates(aggregate.child);
    }
  }
}
