import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatResultCount',
  pure: false
})
export class FormatResultCountPipe implements PipeTransform {
  transform(value: number | undefined): string {
    let result: string;
    if (value === undefined) {
      return '';
    }

    if (value >= 100000) {
      result = '100k+';
    } else if (value >= 10000) {
      result = '10k+';
    } else if (value >= 1000) {
      result = '1k+';
    } else {
      result = value.toString();
    }

    return result;
  }
}
