<clr-modal [(clrModalOpen)]="visible">
  <h3 class="modal-title">
    {{ 'CONTENTCOLLECTION.MODALS.DELETE.DELETEDIALOGTITLE' | translate }}
  </h3>
  <div class="modal-body">
    {{ 'CONTENTCOLLECTION.MODALS.DELETE.REALLYDELETE' | translate }}
    <div class="collectionNameToBeDeleted" data-cy="CCNameToBeDeleted">
      {{ collectionToDelete?.name }}
    </div>
    {{ 'CONTENTCOLLECTION.MODALS.GENERAL.IRREVERSIBLE_ACTION' | translate }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="cancelDeleteCollection($event)">
      {{ 'CONTENTCOLLECTION.MODALS.GENERAL.CANCELBUTTON' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-danger"
      data-cy="doCCDeleteButton"
      (click)="confirmDeleteCollection($event); visible = false"
    >
      {{ 'CONTENTCOLLECTION.MODALS.DELETE.DELETEBUTTON' | translate }}
    </button>
  </div>
</clr-modal>
