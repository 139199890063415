import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { availableLanguagesStrings, formatDistanceShort } from '../../app.locales';

import locale_en from 'date-fns/locale/en-US';
import locale_de from 'date-fns/locale/de';
import locale_ja from 'date-fns/locale/ja';
import { NO_VALUE } from './format-datetime.pipe';

@Pipe({
  name: 'timeago',
  pure: false
})
export class TimeAgoPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  public transform(value: string | number | Date | undefined): string {
    let result = NO_VALUE;
    const currentLanguage = this.translate.currentLang as availableLanguagesStrings;

    if (!value) {
      return result;
    }

    try {
      const date = new Date(value);
      let locale = locale_en;
      switch (currentLanguage) {
        case 'de':
          locale = { ...locale_de, formatDistance: formatDistanceShort('de') };
          break;
        case 'ja':
          locale = locale_ja;
          break;
        default:
          locale = { ...locale_de, formatDistance: formatDistanceShort('en') };
          break;
      }

      result = formatDistanceToNow(date, { locale });
    } catch (error) {
      console.error('There is a problem formatting the date =', value, '- the error is', error);
    }
    return result;
  }
}
