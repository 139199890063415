import { Injectable, Inject } from '@angular/core';
import { ANGULAR_HTTP_CONTEXT } from '../../app.tokens';
import { HttpClient } from '@angular/common/http';
import { AngularHttpContext } from '@vas/angular-http-context';
import { CloudService } from '../../services/cloud.service';
import { JsonApi, Spec } from '@muellerbbm-vas/grivet';
import { DepotBrowseContent, MeasurementDownload, MeasurementDownloadConversion } from '../measurements.types';

@Injectable({
  providedIn: 'root'
})
export class MeasurementsDownloadService {
  private depotDownloadListUrl: URL;

  constructor(
    @Inject(ANGULAR_HTTP_CONTEXT) private context: AngularHttpContext,
    private cloudService: CloudService,
    private httpClient: HttpClient
  ) {}

  async ensureDepotAppStart() {
    return await this.cloudService.getAppStart('depot');
  }

  async ensureDepotDownloadListUrl() {
    if (!this.depotDownloadListUrl) {
      const appStart = await this.ensureDepotAppStart();
      this.depotDownloadListUrl = appStart?.relationships['downloads'].links?.related.url ?? new URL('');
    }
  }

  createDownloadRequest(
    browseContents: DepotBrowseContent[],
    measurementDownloadConversion: MeasurementDownloadConversion
  ): Spec.ClientJsonApiDocument {
    const request = new JsonApi.ClientDocument('DepotDownload');
    request.setAttribute('description', 'Measurement download started from web client');
    const contents: Spec.ResourceIdentifierObject[] = [];
    browseContents.map((content) => contents.push({ id: content.id, type: 'DepotBrowseContent' }));
    request.setRelationship('contents', contents);
    const conversion: Spec.ResourceIdentifierObject = {
      id: measurementDownloadConversion,
      type: 'DepotDownloadConversion'
    };
    request.setRelationship('conversion', conversion);
    return request.data;
  }

  createDownloadRequestCancellation(): Spec.ClientJsonApiDocument {
    const request = new JsonApi.ClientDocument('JobCancellationRequest');
    return request.data;
  }

  // Extracts the filename from the 'Content-Disposition' response header.
  private getFilenameFromContentDisposition(contentDisposition: string): string {
    const result = contentDisposition.split(';')[1].trim().split('=')[1];
    return result.replace(/"/g, '');
  }

  async requestDownload(measurementDownload: MeasurementDownload): Promise<MeasurementDownload> {
    await this.ensureDepotDownloadListUrl();
    const downloadRequest: Spec.ClientJsonApiDocument = this.createDownloadRequest(
      measurementDownload.contents,
      measurementDownload.conversion
    );
    const response = await this.context.postDocument(this.depotDownloadListUrl, downloadRequest);
    const responseDocument = new JsonApi.Document(response, this.context);
    const responseResource = responseDocument.resource;
    const retMeasurementDownload: MeasurementDownload = {
      ...measurementDownload,
      progress: 0,
      monitorUrl: responseResource?.selfLink?.url.href,
      cancelUrl: responseResource?.relationships['cancellation_requests'].links?.related.url.href
    };

    return retMeasurementDownload;
  }

  async monitorDownload(measurementDownload: MeasurementDownload): Promise<MeasurementDownload> {
    const monitorUrl = measurementDownload.monitorUrl;
    const response = await this.context.getDocument(new URL(monitorUrl ?? ''));
    const responseDocument = new JsonApi.Document(response, this.context);
    const responseResource = await responseDocument.resource;

    const retMeasurementDownload: MeasurementDownload = {
      ...measurementDownload
    };

    //self link changed -> 303 redirect occured. The browser follows the redirection automatically,
    //so this seems to be a simple way to detect it
    if (monitorUrl !== responseResource?.selfLink?.url.href) {
      const downloadUrl = responseResource!.metaLinks['download'].url.href;
      const contextHeaders = this.context.headers;
      const modifiedHeader = contextHeaders.set('Accept', 'application/json');

      //use "observe: 'response'", otherwise the header will not be included in data
      const data = await this.httpClient
        .get(downloadUrl, { headers: modifiedHeader, responseType: 'blob', observe: 'response' })
        .toPromise();

      //If 'Content-Disposition' header is null then the backend probably is missing 'Access-Control-Expose-Headers'.
      //The browser will not pass such custom headers to the code if they are not explicitly exposed on backend side.
      const contentDisposition = data?.headers.get('Content-Disposition');
      if (!contentDisposition) {
        throw new Error(
          "'Content-Disposition' header is null. Check backend settings for 'Access-Control-Expose-Headers'."
        );
      }
      const filename = this.getFilenameFromContentDisposition(contentDisposition);

      // https://stackoverflow.com/a/45069603
      const url = URL.createObjectURL(data?.body ?? new Blob());
      const anchor = document.createElement('a');
      anchor.download = filename;
      anchor.href = url;
      anchor.click();

      retMeasurementDownload.progress = 1;
      retMeasurementDownload.hasDownloadStarted = true;
    } else {
      retMeasurementDownload.progress = responseResource?.attributes?.['progress'];
      retMeasurementDownload.error = responseResource?.attributes?.['error'] ?? null;
    }
    return retMeasurementDownload;
  }

  async cancelDownloadRequest(measurementDownload: MeasurementDownload) {
    const retMeasurementDownload: MeasurementDownload = {
      ...measurementDownload
    };
    await this.context.postDocument(
      new URL(measurementDownload.cancelUrl ?? ''),
      this.createDownloadRequestCancellation()
    );
    return retMeasurementDownload;
  }
}
