import { availableLanguagesStrings } from '../../app.locales';
import { translateSemanticDepotAttribute, translateSemanticDepotAttributeGroup } from './translateAttribute';
import { compareStringWithNumbers } from './compareStringWithNumbers';
import { isOrderSearchParams, pendingDeletionAttrId, ResultColumn } from '../types/search.types';
import { JsonApi, Spec } from '@muellerbbm-vas/grivet';
import { isSearchAttributeAndValue, isSearchAttributeCompound } from '../../order-management/order.service.helpers';
import { v4 as uuid } from 'uuid';
import { OrderSearchParameters } from '../../order-management/order-search.types';
import { createAggregateResources } from './aggregateTree.helpers';
import {
  AttributeGroup,
  DepotAttribute,
  isDepotAttribute,
  isSemanticAttribute,
  SearchAttributeAndValue,
  SearchAttributeForValues,
  SearchFilter,
  SemanticDepotAttribute
} from '../+state/attributes/attributes.types';
import { SortDirection } from '../types/sorting.types';
import {
  OrderTableConfig,
  OrderTableContentIdentifiers,
  WorkspaceConfig
} from '../../workspace/config/workspace.config.types';
import { ConfigurationSource } from '../../workspace/workspace.types';
import { DepotSearchParameters } from '../../depot-search/depot-search.types';

const NOT_AVAILABLE = 'N/A';

export const createRequest = async (
  params: DepotSearchParameters | OrderSearchParameters
): Promise<Spec.ClientJsonApiDocument> => {
  const request = new JsonApi.ClientDocument('DepotSearch');
  request.setAttribute('description', params.description || 'PAK cloud WebApp: Generic Search');
  request.setAttribute('query_text', params.text);
  request.setAttribute('limit', params.searchLimit.toString());
  request.setAttribute('offset', params.offset.toString());

  let searchFilters: SearchFilter | undefined = JSON.parse(JSON.stringify(params.searchFilters));

  if (params.aggregateTreeDefs) {
    const aggregates: Spec.ResourceIdentifierObject[] = [];
    for (const aggregateTreeDef of params.aggregateTreeDefs) {
      aggregates.push({ id: aggregateTreeDef.aggregate.guid, type: aggregateTreeDef.aggregate.type });
    }
    request.setRelationship('requested_aggregations', aggregates);
    request.includeResources(createAggregateResources(params.aggregateTreeDefs));
  }

  if (params.resultColumns) {
    const requestedAttributes: Spec.ResourceIdentifierObject[] = [];
    const attributeResources: Spec.ResourceObject[] = [];
    for (const column of params.resultColumns) {
      const newGuid = uuid();
      requestedAttributes.push({ id: newGuid, type: 'RequestedAttribute' });
      attributeResources.push(createAttributeResource(newGuid, column.attribute));
    }
    request.setRelationship('requested_attributes', requestedAttributes);
    request.includeResources(attributeResources);
  }

  if (searchFilters && searchFilters.length > 0) {
    searchFilters = rewriteSearchFilterTreeForIsPendingDeletionBooleanSearchAttributes(searchFilters);
    const resources = createSearchResourcesForSearchFilterTree(searchFilters);

    let queryTreeRoot: Spec.ResourceIdentifierObject;
    if (resources.includedResources.length === 1) {
      queryTreeRoot = {
        id: resources.includedResources[0].id,
        type: resources.includedResources[0].type
      };
    } else {
      queryTreeRoot = resources.directDescendentsForQueryTree[0];
    }
    request.setRelationship('query_tree', queryTreeRoot);
    request.includeResources(resources.includedResources);
  }

  if (isOrderSearchParams(params) && params.collapse) {
    const idName = params.collapse;
    const resource: Spec.ResourceObject = { id: uuid(), type: 'SearchResultCollapse' };
    resource.relationships = {
      depot_attribute: { data: { id: idName, type: 'DepotAttribute' } } // NOTE: backend does not support SemanticDepotAttribute
    };
    const resourceIdentifier: Spec.ResourceIdentifierObject = { id: resource.id, type: resource.type };
    request.setRelationship('collapse', resourceIdentifier);
    request.includeResource(resource);
  }

  if (params.sortFilter) {
    const requestedAttributes: Spec.ResourceObject[] = [];
    const attributeResources: Spec.ResourceObject[] = [];
    for (const sortFilter of params.sortFilter) {
      const newGuid = uuid();

      requestedAttributes.push({ id: newGuid, type: 'SearchSortByDepotAttribute' });
      const idName = isSemanticAttribute(sortFilter.depot_attribute)
        ? sortFilter.depot_attribute.relatedDepotAttributeIds[0]
        : sortFilter.depot_attribute.idName;

      const depotAttribute: DepotAttribute = {
        discriminator: 'DepotAttribute',
        idName: idName,
        searchable: true,
        type: sortFilter.depot_attribute.type
      };
      attributeResources.push(createSortAttributeResource(newGuid, depotAttribute, sortFilter.attribute));
    }
    request.setRelationship('sort', requestedAttributes);
    request.includeResources(attributeResources);
  }

  return request.data;
};

const sortSemanticAttributeGroups = (
  attributeGroups: AttributeGroup[],
  currentLang: availableLanguagesStrings
): AttributeGroup[] => {
  return attributeGroups.slice().sort((a, b) => {
    // Sort by translation if available
    const translationA = translateSemanticDepotAttributeGroup(a, currentLang);
    const translationB = translateSemanticDepotAttributeGroup(b, currentLang);
    if (translationA !== '' || translationB !== '') {
      return compareStringWithNumbers(translationA, translationB);
    }
    // Sort by ID otherwise
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  });
};

const sortSemanticAttributes = (
  attributes: SemanticDepotAttribute[],
  currentLang: availableLanguagesStrings
): SemanticDepotAttribute[] => {
  return attributes.slice().sort((a, b) => {
    // Sort by translation if available
    const translationA = translateSemanticDepotAttribute(a, currentLang);
    const translationB = translateSemanticDepotAttribute(b, currentLang);
    if (translationA !== '' || translationB !== '') {
      return compareStringWithNumbers(translationA, translationB);
    }
    // Sort by ID otherwise
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  });
};

const sortDepotAttributes = (attributes: DepotAttribute[]): DepotAttribute[] => {
  return attributes.slice().sort((a, b) => {
    if (a.idName < b.idName) {
      return -1;
    }
    if (a.idName > b.idName) {
      return 1;
    }
    return 0;
  });
};

export const mapSearchAttributesToResultColumns = (
  availableDepotAttributes: DepotAttribute[],
  availableSemanticDepotAttributes: SemanticDepotAttribute[],
  searchAttributeIdNames: string[]
): ResultColumn[] => {
  const resultColumns: ResultColumn[] = [];

  for (const columnId of searchAttributeIdNames) {
    const attribute = availableDepotAttributes?.find((attr) => attr.idName === columnId);
    if (attribute) {
      // changing the DepotAttribute to a SemanticDepotAttribute impedes matching with the TableConfig
      // if (attribute.semanticAttribute) {
      //   resultColumns.push({
      //     attribute: attribute.semanticAttribute,
      //     field: attribute.semanticAttribute.id,
      //     contentLoaded: false
      //   });
      // } else {
      resultColumns.push({ attribute: attribute, field: attribute.idName, contentLoaded: false });
      // }
    } else {
      const attribute = availableSemanticDepotAttributes?.find((attr) => attr.id === columnId);
      if (attribute) {
        resultColumns.push({
          attribute: attribute,
          field: attribute.id,
          contentLoaded: false
        });
      }
    }
  }
  return resultColumns;
};

export const createSearchAttributeIdNamesFromConfig = (
  workspaceConfig: WorkspaceConfig,
  orderTableConfig?: ConfigurationSource<OrderTableConfig>
) => {
  const workspaceSpecificSearchAttributeIdNames = workspaceConfig.additionalSearchAttributeIdNames ?? [];

  // 2. The admin-editable tableConfig can have additional entries that need to be included in order searches
  const additionalSearchAttributeIdNamesFromOrderTableConfig: string[] = [];

  if (orderTableConfig) {
    const relevantOrderTableConfigTypes: any[] = [];
    if (orderTableConfig.server) {
      relevantOrderTableConfigTypes.push('server');
    }
    if (orderTableConfig.preview) {
      relevantOrderTableConfigTypes.push('preview');
    }
    if (relevantOrderTableConfigTypes.length === 0) {
      relevantOrderTableConfigTypes.push('default');
    }

    for (const configType of relevantOrderTableConfigTypes) {
      const config = orderTableConfig[configType] as OrderTableConfig;
      Object.values(config.columns).forEach((column) => {
        Object.values(OrderTableContentIdentifiers).forEach((contentIdentifier) => {
          const attributeID = column.content[contentIdentifier]?.attributeID;
          if (attributeID) {
            additionalSearchAttributeIdNamesFromOrderTableConfig.push(attributeID);
          }
        });
      });
    }
  }

  const searchAttributeIDnamesFromAttributeLookups = Object.values(workspaceConfig.attributeLookups);
  const searchAttributeIDnamesFromWorkspaceLookup = [workspaceConfig.searchFilterAttributeWorkspaceId];

  // Combine and unique-ify both searchAttributeIdName lists
  const combinedSearchAttributeIdNames = [
    ...new Set([
      'depot_id',
      ...workspaceSpecificSearchAttributeIdNames,
      ...additionalSearchAttributeIdNamesFromOrderTableConfig,
      ...searchAttributeIDnamesFromAttributeLookups,
      ...searchAttributeIDnamesFromWorkspaceLookup
    ])
  ];
  return combinedSearchAttributeIdNames;
};

const endsWithNumber = (idName: string): boolean => {
  const lastPart = idName.split('.').pop() ?? '';
  const lastPartAsInt = parseInt(lastPart, 10);
  return lastPart.length === 1 && !isNaN(lastPartAsInt) && lastPartAsInt > 0;
};

const isSuitableDepotAttribute = (depotAttribute: DepotAttribute, mustBeSearchable: boolean): boolean => {
  const isSearchable = !mustBeSearchable || depotAttribute.searchable;
  return (
    isSearchable &&
    !depotAttribute.semanticAttribute &&
    depotAttribute.idName !== 'path' &&
    depotAttribute.idName !== 'pk' &&
    depotAttribute.idName !== 'depot_id' &&
    depotAttribute.idName !== 'name' &&
    depotAttribute.idName.split('.').pop() !== 'distinct_name' &&
    depotAttribute.idName.split('.').pop() !== '_name' &&
    depotAttribute.idName.split('.').pop() !== 'iname' &&
    depotAttribute.idName.split('.').pop() !== 'mime_type' &&
    !depotAttribute.idName.endsWith('_iid') &&
    !endsWithNumber(depotAttribute.idName)
  );
};

export const suggestAttributeGroups = (
  allAttributeGroups: AttributeGroup[],
  currentLang: availableLanguagesStrings
): AttributeGroup[] => {
  return sortSemanticAttributeGroups(allAttributeGroups, currentLang);
};

export const suggestSemanticAttributes = (
  allSemanticAttributes: SemanticDepotAttribute[],
  currentLang: availableLanguagesStrings
): SemanticDepotAttribute[] => {
  if (allSemanticAttributes && allSemanticAttributes.length !== 0) {
    allSemanticAttributes = sortSemanticAttributes(allSemanticAttributes, currentLang);
  } else {
    allSemanticAttributes = [];
  }

  return allSemanticAttributes;
};

export const suggestDepotAttributes = (
  allDepotAttributes: DepotAttribute[],
  currentLang: availableLanguagesStrings,
  mustBeSearchable: boolean
): DepotAttribute[] => {
  allDepotAttributes = allDepotAttributes
    ? allDepotAttributes.filter((attrib) => isSuitableDepotAttribute(attrib, mustBeSearchable))
    : [];

  if (allDepotAttributes && allDepotAttributes.length !== 0) {
    allDepotAttributes = sortDepotAttributes(allDepotAttributes);
  }
  return allDepotAttributes;
};

export const suggestAttributes = (
  allSemanticAttributes: SemanticDepotAttribute[],
  allDepotAttributes: DepotAttribute[],
  currentLang: availableLanguagesStrings,
  mustBeSearchable: boolean
): (SemanticDepotAttribute | DepotAttribute)[] => {
  if (allSemanticAttributes && allSemanticAttributes.length !== 0) {
    allSemanticAttributes = sortSemanticAttributes(allSemanticAttributes, currentLang);
  } else {
    allSemanticAttributes = [];
  }

  allDepotAttributes = allDepotAttributes
    ? allDepotAttributes.filter((attrib) => isSuitableDepotAttribute(attrib, mustBeSearchable))
    : [];

  if (allDepotAttributes && allDepotAttributes.length !== 0) {
    allDepotAttributes = sortDepotAttributes(allDepotAttributes);
  }
  return [...allSemanticAttributes, ...allDepotAttributes];
};

const areDepotAttributesEqual = (attribute1: DepotAttribute, attribute2: DepotAttribute) => {
  return attribute1.idName === attribute2.idName;
};

const areSemanticAttributesEqual = (attribute1: SemanticDepotAttribute, attribute2: SemanticDepotAttribute) => {
  return attribute1.id === attribute2.id;
};

export const areAttributesEqual = (
  attribute1: DepotAttribute | SemanticDepotAttribute,
  attribute2: DepotAttribute | SemanticDepotAttribute
) => {
  if (isDepotAttribute(attribute1) && isDepotAttribute(attribute2)) {
    return areDepotAttributesEqual(attribute1, attribute2);
  } else if (isSemanticAttribute(attribute1) && isSemanticAttribute(attribute2)) {
    return areSemanticAttributesEqual(attribute1, attribute2);
  } else {
    return false;
  }
};

export const getAttributeId = (searchBarFilter: SearchAttributeAndValue | SearchAttributeForValues): string => {
  if (isDepotAttribute(searchBarFilter.attribute)) {
    return searchBarFilter.attribute.idName;
  } else {
    return searchBarFilter.attribute.id;
  }
};

/*
 * This function rewrites a Tree of SearchFilters to better represent searches that include a (falsy) PendingDeletion Attribute
 *
 * Searching for 'PendingDeletion = false' will not discover DataSets whose PendingDeletion value is not set.
 * Searching for (falsy) PendingDeletion is instead replaced by finding all DataSets whose value is 'not' true by replacing the
 * original SearchFilter with a 'not' SearchCompound.
 *
 * Original Authors: Team Circus 2019 (mos, npr et al.), refactored for multiple SearchCompounds by hab in 2022
 */
export const rewriteSearchFilterTreeForIsPendingDeletionBooleanSearchAttributes = (
  filters: SearchFilter
): SearchFilter => {
  filters.forEach((filter, index) => {
    if (isSearchAttributeAndValue(filter)) {
      const isPendingDeletionAttrib = attributeIsPendingDeletion(filter);
      const isFalse = filter.searchAttributeBoolean === false;
      if (isPendingDeletionAttrib && isFalse) {
        filters[index] = {
          type: 'not',
          filters: [
            {
              ...filter,
              searchAttributeBoolean: true
            }
          ]
        };
      }
    } else {
      rewriteSearchFilterTreeForIsPendingDeletionBooleanSearchAttributes(filter.filters);
    }
  });
  return filters;
};

export const createSearchResourcesForSearchFilterTree = (
  filters: SearchFilter
): {
  includedResources: Spec.ResourceObject[];
  directDescendentsForQueryTree: Spec.ResourceIdentifierObject[];
} => {
  const result: ReturnType<typeof createSearchResourcesForSearchFilterTree> = {
    includedResources: [],
    directDescendentsForQueryTree: []
  };

  filters.forEach((filter) => {
    if (isSearchAttributeCompound(filter)) {
      const { directDescendentsForQueryTree, includedResources } = createSearchResourcesForSearchFilterTree(
        filter.filters
      );
      result.includedResources.push(...includedResources);

      // Gather SearchCompoundChildren
      const newUUID = uuid();
      result.directDescendentsForQueryTree.push({
        id: newUUID,
        type: 'SearchCompound'
      });

      // Create SearchCompoundResource
      const search_compound_resource: Spec.ResourceObject = {
        id: newUUID,
        type: 'SearchCompound',
        attributes: { operator: filter.type },
        relationships: { children: { data: directDescendentsForQueryTree } }
      };
      result.includedResources.push(search_compound_resource);
    } else {
      const { searchNode, resource } = createSearchNodesAndResources(filter);
      if (resource.attributes?.value !== NOT_AVAILABLE) {
        result.includedResources.push(resource);
        result.directDescendentsForQueryTree.push(searchNode);
      }
    }
  });

  return result;
};

const attributeIsPendingDeletion = (attribute: SearchAttributeAndValue): boolean => {
  if (isSemanticAttribute(attribute.attribute)) {
    const attr = attribute.attribute as SemanticDepotAttribute;
    const result = attr.relatedDepotAttributeIds.find((id) => id === pendingDeletionAttrId);
    return result ? true : false;
  } else {
    const attr = attribute.attribute as DepotAttribute;
    return attr.idName === pendingDeletionAttrId;
  }
};

const createSearchNodesAndResources = (
  attributeAndValue: SearchAttributeAndValue
): {
  searchNode: Spec.ResourceIdentifierObject;
  resource: Spec.ResourceObject;
} => {
  let searchNode: Spec.ResourceIdentifierObject;
  let resource: Spec.ResourceObject;
  const newGuid = uuid();
  if (attributeAndValue.attribute.type === 'String') {
    searchNode = { id: newGuid, type: 'SearchTerm' };
    resource = createSearchTermResource(newGuid, attributeAndValue);
  } else if (attributeAndValue.attribute.type === 'Date') {
    searchNode = { id: newGuid, type: 'SearchDateRange' };
    resource = createSearchDateRangeResource(newGuid, attributeAndValue);
  } else if (attributeAndValue.attribute.type === 'Boolean') {
    searchNode = { id: newGuid, type: 'SearchBoolean' };
    resource = createSearchBoolean(newGuid, attributeAndValue);
  } else {
    console.error('Error during Search: Failed to create SearchTerm for: ', attributeAndValue);
    searchNode = { id: newGuid, type: 'SearchTerm' };
    resource = { id: newGuid, type: 'SearchTerm' };
  }
  return {
    searchNode,
    resource
  };
};

const createSearchTermResource = (guid: string, attributeAndValue: SearchAttributeAndValue): Spec.ResourceObject => {
  const resource: Spec.ResourceObject = {
    id: guid,
    type: 'SearchTerm',
    attributes: { value: attributeAndValue.searchAttributeValue, exact_match: attributeAndValue.exact_match }
  };
  resource.relationships = { attribute: getRelationshipFromAttribute(attributeAndValue.attribute) };
  return resource;
};

const createSearchDateRangeResource = (
  guid: string,
  attributeAndValue: SearchAttributeAndValue
): Spec.ResourceObject => {
  const rangeStart = attributeAndValue.searchAttributeStart
    ? new Date(attributeAndValue.searchAttributeStart).toISOString()
    : undefined;
  const rangeEnd = attributeAndValue.searchAttributeEnd
    ? new Date(attributeAndValue.searchAttributeEnd).toISOString()
    : undefined;
  const resource: Spec.ResourceObject = {
    id: guid,
    type: 'SearchDateRange',
    attributes: {
      value_start: rangeStart,
      value_end: rangeEnd
    }
  };
  resource.relationships = { attribute: getRelationshipFromAttribute(attributeAndValue.attribute) };
  return resource;
};

const createSearchBoolean = (guid: string, attributeAndValue: SearchAttributeAndValue): Spec.ResourceObject => {
  const bool = attributeAndValue.searchAttributeBoolean;

  const resource: Spec.ResourceObject = {
    id: guid,
    type: 'SearchBoolean',
    attributes: {
      value: bool
    }
  };
  resource.relationships = { attribute: getRelationshipFromAttribute(attributeAndValue.attribute) };
  return resource;
};

const getRelationshipFromAttribute = (attribute: DepotAttribute | SemanticDepotAttribute): Spec.RelationshipObject => {
  return isDepotAttribute(attribute)
    ? { data: { id: attribute.idName, type: 'DepotAttribute' } }
    : { data: { id: attribute.id, type: 'SemanticDepotAttribute' } };
};

export const createAttributeResource = (
  guid: string,
  attribute: DepotAttribute | SemanticDepotAttribute
): Spec.ResourceObject => {
  const resource: Spec.ResourceObject = { id: guid, type: 'RequestedAttribute' };

  resource.relationships = {
    depot_attribute: getRelationshipFromAttribute(attribute)
  };
  resource.attributes = {};
  return resource;
};

export const createSortAttributeResource = (
  guid: string,
  attribute: DepotAttribute | SemanticDepotAttribute,
  sort: SortDirection
): Spec.ResourceObject => {
  const resource: Spec.ResourceObject = { id: guid, type: 'SearchSortByDepotAttribute' };

  resource.relationships = {
    depot_attribute: getRelationshipFromAttribute(attribute)
  };
  resource.attributes = { order: sort };
  return resource;
};
