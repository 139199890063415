import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { Subject, fromEvent } from 'rxjs';

/*
 *
 * This directive prevents text selection when modifier keys are pressed.
 * Without this directive, Shift+Click selection in the SearchResultTable
 * would also select text on the page.
 *
 */

@Directive({
  selector: '[cloudBlockTextSelect]'
})
export class BlockTextSelectDirective implements OnDestroy {
  private keydown$ = fromEvent<KeyboardEvent>(document, 'keydown');
  private keyup$ = fromEvent<KeyboardEvent>(document, 'keyup');
  private destroy$ = new Subject<void>();

  constructor(private el: ElementRef) {
    this.el.nativeElement.style.userSelect = 'none';

    this.keydown$.subscribe((e) => {
      if (e.shiftKey || e.ctrlKey || e.metaKey) {
        this.el.nativeElement.style.userSelect = 'none';
      }
    });

    this.keyup$.subscribe((e) => {
      if (!e.shiftKey && !e.ctrlKey && !e.metaKey) {
        this.el.nativeElement.style.userSelect = 'auto';
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
