import { Orders, OrderState, Standard } from './../order.types';
import { OrderProject, OrderSearchParameters, OrderSearchResult } from './../order-search.types';
import { createAction, props } from '@ngrx/store';
import { AppErrorPayload } from '../../app.types';
import { ResultColumn } from '../../shared/types/search.types';
import { OrderFilterStateSlot } from './orders.reducer';
import { SortingMethod } from '../order-filter-list/sorting/sorting.types';
import { FilterIdentifier, QuickFilter } from '../../workspace/config/workspace.config.types';
import { DepotAttribute, SemanticDepotAttribute } from '../../shared/+state/attributes/attributes.types';
import { SortDirection } from '../../shared/types/sorting.types';

export enum OrdersSearchActionTypes {
  TriggerSearchOnUI = '[OrdersSearch] UI Trigger Search',
  TriggerSearchForSpecificOrder = '[OrdersSearch] Trigger search for specific Order',
  TriggerSearchOnInitialNavigation = '[OrdersSearch] [Initial Navigation] Trigger Search',
  TriggerSearchOnPopState = '[OrdersSearch] [Pop Navigation History State] Trigger Search',
  TriggerSearchOnRouteChange = '[OrdersSearch] [Route Changed To OrderSearch] Trigger Search',
  PrepareSearchRequest = '[OrdersSearch] Prepare Search Request',

  ModifySearchText = '[OrdersSearch] Modify Order Search Text',
  SetSearchTextByRouter = '[OrdersSearch] [Router] Set Search Text',
  SetSearchText = '[OrdersSearch] Set Search Text',
  ResetSearch = '[OrdersSearch] Reset Search',
  SetSearchLimitByRouter = '[OrdersSearch] [Router] Set Search Limit',

  SetRecentlyChangedFilter = '[OrdersSearch - Filter] [Router] Set Recently Changed Filter',

  ProjectsReceived = '[OrdersSearch - Filter] Available Projects received',
  SetProjectsFilter = '[OrdersSearch - Filter] Set Projects Filter',
  SetProjectsFilterByRouter = '[OrdersSearch - Filter] [Router] Set Projects Filter',

  SetStateFilter = '[OrdersSearch - Filter] Set State Filter',
  AvailableStatesReceived = '[OrdersSearch - Filter] Available States received',
  SetStateFilterByRouter = '[OrdersSearch - Filter] [Router] Set State Filter',

  QuickFilterValuesReceived = '[OrdersSearch - Filter] Available QuickFilter Values received',
  SetQuickFilter = '[OrdersSearch - Filter] Set Quick Filter',
  SetQuickFiltersByRouter = '[OrdersSearch - Filter] [Router] Set Quick Filter',

  StandardsReceived = '[OrdersSearch - Filter] Available Standards received',
  SetStandardsFilter = '[OrdersSearch - Filter] Set Standards Filter',
  SetStandardsFilterByRouter = '[OrdersSearch - Filter] [Router] Set Standards Filter',

  GetBaseFilters = '[OrdersSearch - Filter] Get Base Filters',
  SetSortingMethodForFilter = '[OrdersSearch - Filter] Set SortingMethod for Filter',
  SetExtensionStateForFilter = '[OrdersSearch - Filter] Set ExtensionState for Filter',
  ResetFilters = '[OrdersSearch - Filter] Reset Filters',

  SendSearchRequest = '[OrdersSearch] Send Order Search Request',
  LoadMoreOrders = '[OrdersSearch] Load More Orders',
  AddResultColumn = '[OrdersSearch] Add new Result Column',
  SearchResultReceived = '[OrdersSearch] Order Search Result Received',
  SearchError = '[OrdersSearch] Error during Order Search',

  BaseSearchResultsReceived = '[OrdersSearch] Base Search Result Received',

  // Search Basics
  InitDefaultResultColumns = '[OrdersSearch] Init Default Result Columns',
  ResultColumnsInitialized = '[OrdersSearch] Result Columns loaded from localStorage (or default)',

  // Specific Search
  OrderListReceived = '[OrdersSearch] Order List Received',
  AddColumnsToSearch = '[OrdersSearch] Add Columns To Search Params',
  SendNewColumnRequest = '[OrdersSearch] Send Search Request for Content of New Column',
  AddSortFilterToSearch = '[OrdersSearch] Send Search Request for Sorted Content of Column',
  ColumnContentReceived = '[OrdersSearch] Content for New Column Received',
  NewColumnError = '[OrdersSearch] Error during New Column Search',
  RemoveResultColumn = '[OrdersSearch] Remove Result Column',
  AddPendingDeletionAttribute = '[OrdersSearch] Add Pending Deletions Attribute',

  AddExtraFiltersToSearch = '[OrdersSearch] Add Filters and Starttime Constraint To Search Params',
  SetLastSearchParameters = '[OrdersSearch] Set Last Search Parameters',
  AddExtraFiltersToNewColumn = '[OrdersSearch] Add Filters and Starttime Constraint to New Column Search',

  UnsetSpecificOrderMode = '[OrdersSearch] Unset Specific Order Mode'
}

export const TriggerSearchUI = createAction(
  OrdersSearchActionTypes.TriggerSearchOnUI,
  props<{ resetSelectedOrder: boolean }>()
);
export const TriggerSearchForSpecificOrder = createAction(
  OrdersSearchActionTypes.TriggerSearchForSpecificOrder,
  props<{ orderId: string }>()
);

export const TriggerSearchOnInitialNavigation = createAction(OrdersSearchActionTypes.TriggerSearchOnInitialNavigation);
export const TriggerSearchOnPopState = createAction(OrdersSearchActionTypes.TriggerSearchOnPopState);
export const TriggerSearchOnRouteChange = createAction(OrdersSearchActionTypes.TriggerSearchOnRouteChange);
export const PrepareSearchRequest = createAction(
  OrdersSearchActionTypes.PrepareSearchRequest,
  props<{ triggeredBy: string }>()
);

export const SetSearchTextByRouter = createAction(
  OrdersSearchActionTypes.SetSearchTextByRouter,
  props<{ searchText: string; navigationInfo: any }>()
);
export const SetSearchText = createAction(OrdersSearchActionTypes.SetSearchText, props<{ searchText: string }>());
export const ModifySearchText = createAction(OrdersSearchActionTypes.ModifySearchText, props<{ value: string }>());
export const ResetSearch = createAction(OrdersSearchActionTypes.ResetSearch);
export const SetSearchLimitByRouter = createAction(
  OrdersSearchActionTypes.SetSearchLimitByRouter,
  props<{ searchLimit: string; navigationInfo: any }>()
);

export const SetRecentlyChangedFilter = createAction(
  OrdersSearchActionTypes.SetRecentlyChangedFilter,
  props<{ value: boolean }>()
);

// Projects Filter
export const ProjectsReceived = createAction(
  OrdersSearchActionTypes.ProjectsReceived,
  props<{ projects: OrderProject[]; slot: OrderFilterStateSlot }>()
);

export const SetProjectsFilter = createAction(
  OrdersSearchActionTypes.SetProjectsFilter,
  props<{ projects: string[] }>()
);
export const SetProjectsFilterByRouter = createAction(
  OrdersSearchActionTypes.SetProjectsFilterByRouter,
  props<{ projects: string[] }>()
);

// States Filter
export const SetStateFilter = createAction(OrdersSearchActionTypes.SetStateFilter, props<{ states: string[] }>());
export const AvailableStatesReceived = createAction(
  OrdersSearchActionTypes.AvailableStatesReceived,
  props<{ states: OrderState[] }>()
);
export const SetStateFilterByRouter = createAction(
  OrdersSearchActionTypes.SetStateFilterByRouter,
  props<{ states: string[] }>()
);

// Quick Filter
export const SetQuickFilter = createAction(OrdersSearchActionTypes.SetQuickFilter, props<{ filters: QuickFilter[] }>());
export const SetQuickFiltersByRouter = createAction(
  OrdersSearchActionTypes.SetQuickFiltersByRouter,
  props<{ filters: QuickFilter[] }>()
);

// Standards Filter
export const SetStandardsFilter = createAction(
  OrdersSearchActionTypes.SetStandardsFilter,
  props<{ standards: string[] }>()
);
export const StandardsReceived = createAction(
  OrdersSearchActionTypes.StandardsReceived,
  props<{ standards: Standard[]; slot: OrderFilterStateSlot }>()
);
export const SetStandardsFilterByRouter = createAction(
  OrdersSearchActionTypes.SetStandardsFilterByRouter,
  props<{ standards: string[] }>()
);

export const GetBaseFilters = createAction(OrdersSearchActionTypes.GetBaseFilters);
export const ResetFilters = createAction(OrdersSearchActionTypes.ResetFilters);
export const SetSortingMethodForFilter = createAction(
  OrdersSearchActionTypes.SetSortingMethodForFilter,
  props<{ filterIdentifier: FilterIdentifier; method: SortingMethod }>()
);
export const SetExtensionStateForFilter = createAction(
  OrdersSearchActionTypes.SetExtensionStateForFilter,
  props<{ filterIdentifier: FilterIdentifier; extended: boolean }>()
);

export const SendSearchRequest = createAction(
  OrdersSearchActionTypes.SendSearchRequest,
  props<{ params: OrderSearchParameters; isNewSearch: boolean }>()
);

export const LoadMoreOrders = createAction(OrdersSearchActionTypes.LoadMoreOrders);

export const AddResultColumn = createAction(
  OrdersSearchActionTypes.AddResultColumn,
  props<{ attrib: DepotAttribute | SemanticDepotAttribute }>()
);

export const AddExtraFiltersToSearch = createAction(
  OrdersSearchActionTypes.AddExtraFiltersToSearch,
  props<{ params: OrderSearchParameters; isNewSearch: boolean }>()
);

export const SetLastSearchParameters = createAction(
  OrdersSearchActionTypes.SetLastSearchParameters,
  props<{ params: OrderSearchParameters }>()
);

export const AddExtraFiltersToNewColumn = createAction(
  OrdersSearchActionTypes.AddExtraFiltersToNewColumn,
  props<{ params: OrderSearchParameters; column: ResultColumn }>()
);

export const SearchResultReceived = createAction(
  OrdersSearchActionTypes.SearchResultReceived,
  props<{ result: OrderSearchResult; offset: number; isNewSearch: boolean }>()
);

export const BaseSearchResultsReceived = createAction(
  OrdersSearchActionTypes.BaseSearchResultsReceived,
  props<{ result: OrderSearchResult }>()
);

export const OrderListReceived = createAction(
  OrdersSearchActionTypes.OrderListReceived,
  props<{ orders: Orders; append?: boolean }>()
);

export const SearchError = createAction(OrdersSearchActionTypes.SearchError, props<{ error: AppErrorPayload }>());

export const InitDefaultResultColumns = createAction(OrdersSearchActionTypes.InitDefaultResultColumns);

export const ResultColumnsInitialized = createAction(
  OrdersSearchActionTypes.ResultColumnsInitialized,
  props<{ columns: ResultColumn[] }>()
);

export const AddColumnsToSearch = createAction(
  OrdersSearchActionTypes.AddColumnsToSearch,
  props<{ params: OrderSearchParameters; isNewSearch: boolean }>()
);

export const SendNewColumnRequest = createAction(
  OrdersSearchActionTypes.SendNewColumnRequest,
  props<{ params: OrderSearchParameters; column: ResultColumn }>()
);

export const AddSortFilterToSearch = createAction(
  OrdersSearchActionTypes.AddSortFilterToSearch,
  props<{ params: OrderSearchParameters; column: ResultColumn; sort: SortDirection }>()
);

export const ColumnContentReceived = createAction(
  OrdersSearchActionTypes.ColumnContentReceived,
  props<{ result: OrderSearchResult; column: ResultColumn }>()
);

export const NewColumnError = createAction(
  OrdersSearchActionTypes.NewColumnError,
  props<{ error: AppErrorPayload; column: ResultColumn }>()
);

export const RemoveResultColumn = createAction(
  OrdersSearchActionTypes.RemoveResultColumn,
  props<{ column: ResultColumn }>()
);

export const AddPendingDeletionAttribute = createAction(
  OrdersSearchActionTypes.AddPendingDeletionAttribute,
  props<{ params: OrderSearchParameters; isNewSearch: boolean }>()
);

export const UnsetSpecificOrderMode = createAction(OrdersSearchActionTypes.UnsetSpecificOrderMode);
