import { Component, Input } from '@angular/core';

@Component({
  selector: 'cloud-path-pill',
  templateUrl: './path-pill.component.html',
  styleUrls: ['./path-pill.component.css']
})
export class PathPillComponent {
  actualPathFragment: string;

  @Input()
  get pathFragment(): string {
    return this.actualPathFragment;
  }

  set pathFragment(value: string) {
    this.actualPathFragment = value;
  }

  @Input() isNew: boolean = false;
  @Input() willOverwrite: boolean = false;
  @Input() isInvalid: boolean = false;
}
