import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATUREFLAGS_FEATURE_KEY, FeatureFlagState, FeatureFlagsPartialState } from './feature-flags.reducer';

const getFeatureFlagsState = createFeatureSelector<FeatureFlagState>(FEATUREFLAGS_FEATURE_KEY);

const getFeatureFlags = createSelector(getFeatureFlagsState, (state: FeatureFlagState) => state.featureFlags);

const getFeatureFlag = (flagName: string) =>
  createSelector(getFeatureFlagsState, (state: FeatureFlagState) => state.featureFlags[flagName] ?? undefined);

const getFeatureFlagValue = (flagName: string) =>
  createSelector(getFeatureFlagsState, (state: FeatureFlagState) => state.featureFlags?.[flagName]?.value ?? undefined);

const getFeatureFlagIsSet = (flagName: string) =>
  createSelector(getFeatureFlagsState, (state: FeatureFlagState) => (state.featureFlags[flagName] ? true : false));

export const featureFlagQuery = {
  getFeatureFlags,
  getFeatureFlag,
  getFeatureFlagValue,
  getFeatureFlagIsSet
};
