export const PROCESSING_FEATURE_KEY = 'processing';

import * as ProcessingAction from './processing.actions';
import { createReducer, Action } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import {
  ProcessingFormula,
  ProcessingInputDataset,
  ProcessingRequestSource,
  ProcessingStatus
} from '../../processing/processing.types';

export interface ProcessingState {
  availableFormulas: ProcessingFormula[];
  availableFormulasLoading: boolean;
  selectedFormulaId: string | null;
  filteredDatasets: ProcessingInputDataset[];
  filteredDatasetsLoading: boolean;

  useLateProcessingToken: boolean;
  currentProcessing: {
    status: 'active' | 'error' | 'none' | 'canceled';
    errorMessage?: string;
    sources: ProcessingRequestSource[] | null;
    url: string | null;
    lastPollStatus: ProcessingStatus;
  };
}

export interface ProcessingPartialState {
  readonly [PROCESSING_FEATURE_KEY]: ProcessingState;
}

export const initialState: ProcessingState = {
  // Analysis Editor State
  availableFormulas: [],
  availableFormulasLoading: false,
  selectedFormulaId: null,
  filteredDatasets: [],
  filteredDatasetsLoading: false,

  // Processing
  useLateProcessingToken: false,
  currentProcessing: {
    status: 'none',
    sources: null,
    url: null,
    lastPollStatus: {
      processingJobId: null,
      status: 'none'
    }
  }
};

const ProcessingReducer = createReducer(
  initialState,
  immerOn(ProcessingAction.LoadAvailableFormulas, (state) => {
    state.availableFormulasLoading = true;
  }),
  immerOn(ProcessingAction.AvailableFormulasLoaded, (state, { formulas }) => {
    state.availableFormulas = formulas;
    state.availableFormulasLoading = false;
  }),
  immerOn(ProcessingAction.SelectFormula, (state, { formula }) => {
    state.selectedFormulaId = formula ? formula?.id : null;
    state.filteredDatasetsLoading = true;
    state.currentProcessing = {
      status: 'none',
      sources: null,
      url: null,
      lastPollStatus: {
        processingJobId: null,
        status: 'none'
      }
    };
  }),
  immerOn(ProcessingAction.FilteringProcessingDatasetsFailed, (state) => {
    state.filteredDatasets = [];
    state.filteredDatasetsLoading = false;
  }),
  immerOn(ProcessingAction.SetFilteredProcessingDatasets, (state, { filteredDatasets }) => {
    state.filteredDatasets = filteredDatasets ?? [];
    state.filteredDatasetsLoading = false;
  }),
  immerOn(ProcessingAction.ChangeFormulaParameters, (state, { formulaId, parameters }) => {
    const formula = state.availableFormulas.find((item) => item.id === formulaId);
    if (formula) {
      parameters.forEach((param) => {
        const paramToUpdate = formula.parameters.find((p) => p.name === param.name);
        if (!paramToUpdate) {
          console.warn(
            `Attempted to update parameter ${param.name} which does not exist on formula with id ${formulaId}`
          );
        } else {
          if (param.value) {
            paramToUpdate.value = param.value;
          }
          paramToUpdate.default_is_set = param.default_is_set;
        }
      });
    } else {
      console.warn(`Attempted to change parameter for invalid formula id  ${formulaId}`);
    }
  }),
  immerOn(ProcessingAction.ChangeSelectedFormulaParameters, (state, { parameters }) => {
    const formula = state.availableFormulas.find((item) => item.id === state.selectedFormulaId);
    if (formula) {
      parameters.forEach((parameter) => {
        const param = formula.parameters.find((p) => p.name === parameter.name);
        if (param === undefined) {
          console.warn(
            'Attempted to change parameter ' +
              parameter.name +
              ' for selected formula but the selected formula does not have this parameter'
          );
        } else {
          param.value = parameter.value;
          param.default_is_set = parameter.default_is_set;
        }
      });
    }
  }),
  immerOn(ProcessingAction.ChangeFormulaInputSources, (state, { formulaId, sources }) => {
    const formula = state.availableFormulas.find((item) => item.id === formulaId);
    if (formula) {
      sources.forEach((source) => {
        const formulaSource = formula.sources.find((sourceItem) => sourceItem.source_name === source.source_name);
        if (formulaSource === undefined) {
          console.warn(`Attempted to change unknown source ${source.source_name} for formula ${formulaId}`);
        } else {
          formulaSource.value = source.value;
          formulaSource.conditionsFullfilled = source.conditionsFullfilled;
        }
      });
    } else {
      console.warn(`Attempted to change input sources for invalid formula id  ${formulaId}`);
    }
  }),
  immerOn(ProcessingAction.ChangeSelectedFormulaInputSources, (state, { sources }) => {
    const formula = state.availableFormulas.find((item) => item.id === state.selectedFormulaId);
    if (formula) {
      sources.forEach((source) => {
        const selectedFormulaSource = formula.sources.find(
          (formulaSource) => formulaSource.source_name === source.sourceName
        );
        if (selectedFormulaSource === undefined) {
          console.warn(`Attempted to change unknown source ${source.sourceName} of the selected formula`);
        } else {
          selectedFormulaSource.value = source.dataset;
        }
      });
    }
  }),
  immerOn(ProcessingAction.SetLateProcessingToken, (state, { useLateProcessingToken }) => {
    state.useLateProcessingToken = useLateProcessingToken;
  }),
  immerOn(ProcessingAction.StartProcessing, (state, { sources }) => {
    state.currentProcessing.status = 'active';
    state.currentProcessing.sources = sources;
    state.currentProcessing.lastPollStatus = { processingJobId: null, status: 'active' }; // if a previous processing was canceled, the status bar of the run button otherwise starts at the last percentage instead of 0
    state.currentProcessing.url = null;
  }),
  immerOn(ProcessingAction.PollStatus, (state, { processingUrl }) => {
    state.currentProcessing.lastPollStatus.status = 'active';
    state.currentProcessing.url = processingUrl;
  }),
  immerOn(ProcessingAction.ReceivedStatus, (state, { status, processingUrl }) => {
    state.currentProcessing.lastPollStatus = status;
    state.currentProcessing.url = processingUrl;
  }),
  immerOn(ProcessingAction.StopPollingStatus, (state) => {
    state.currentProcessing.lastPollStatus.status = 'done';
    state.currentProcessing.url = null;
  }),
  immerOn(ProcessingAction.ProcessingDone, (state) => {
    state.currentProcessing.status = 'none';
  }),
  immerOn(ProcessingAction.ProcessingDoneWithError, (state, { status }) => {
    state.currentProcessing.status = 'error';
    state.currentProcessing.errorMessage = status.errorMsg;
  }),
  immerOn(ProcessingAction.CancelProcessing, (state) => {
    state.currentProcessing.status = 'canceled';
  })
);

export function reducer(state: ProcessingState | undefined, action: Action) {
  return ProcessingReducer(state, action);
}
