// This is a more advanced string comparison method, to be used as a comparator in sort()
// It takes numerical ordering into account, e.g. places "a2" before "a10".
// Algorithm taken from http://cwestblog.com/2013/04/10/javascript-comparing-and-sorting-strings-with-numbers/

// Regular expression to separate the digit string from the non-digit strings.
const reParts = /\d+|\D+/g;
// Regular expression to test if the string has a digit.
const reDigit = /\d/;

export function compareStringWithNumbers(a: string, b: string): number {
  // Separates the strings into substrings that have only digits and those that have no digits.
  const aParts = a.toLowerCase().match(reParts);
  const bParts = b.toLowerCase().match(reParts);

  // Should never happen, but use the usual string-compare as a fallback
  if (aParts === null || bParts === null) {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }

  const len = Math.min(aParts.length, bParts.length);
  for (let i = 0; i < len; i++) {
    const aPart = aParts[i];
    const bPart = bParts[i];
    if (aPart !== bPart) {
      if (reDigit.test(aPart) && reDigit.test(bPart)) {
        // Compare first differing part as numbers
        return Number(aPart) - Number(bPart);
      } else {
        // Compare first differing part as strings
        return aPart.localeCompare(bPart);
      }
    }
  }

  // If one of the string is simply longer and all the parts are equal,
  // then just compare the number of parts to determine which string is longer
  return aParts.length - bParts.length;
}
