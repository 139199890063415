import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ClrDatagridSortOrder } from '@clr/angular';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, shareReplay, take } from 'rxjs/operators';
import { LicensesFacade } from '../+state/licenses/licenses.facade';
import { RolesFacade } from '../+state/roles/roles.facade';
import { lookupLicenseNames } from '../services/licenses-name-lookup';
import { LicensingSearchService } from '../services/licensing-search.service';
import { LicenseInfo } from '../types/license.types';
import { RoleInfo } from '../types/role.types';

@Component({
  selector: 'cloud-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RolesListComponent implements OnInit {
  public roles$: Observable<RoleInfo[]>;

  public stringFilter = '';
  public rowExpanded: string | null;
  public ascSort = ClrDatagridSortOrder.ASC;

  availableLicenses: LicenseInfo[];
  roleLicenses: LicenseInfo[];

  lookupLicenseNames = lookupLicenseNames;
  searchFilter$: Observable<string>;

  constructor(
    public rolesFacade: RolesFacade,
    public licensesFacade: LicensesFacade,
    public searchService: LicensingSearchService
  ) {}

  ngOnInit() {
    this.searchFilter$ = this.searchService.searchFilter$.pipe(shareReplay(1));

    this.roles$ = combineLatest([
      this.rolesFacade.roles$,
      this.searchService.searchFilter$,
      this.licensesFacade.licenses$
    ]).pipe(
      map(([roles, filter, licenses]) => {
        const filteredRoles = roles?.filter((role) => {
          const roleNameMatches = role.name.toLowerCase().includes(filter.toLowerCase());
          const licenseMatch = this.getLicensesForRole(role, licenses).some((license) =>
            license.name.toLowerCase().includes(filter.toLowerCase())
          );

          return roleNameMatches || licenseMatch;
        });
        return filteredRoles ?? [];
      })
    );
  }

  expandRow(role: RoleInfo) {
    this.rowExpanded = this.rowExpanded === role.id ? null : role.id;

    this.licensesFacade.licenses$.pipe(take(1)).subscribe((licenses: LicenseInfo[]) => {
      this.setLicensesList(licenses as LicenseInfo[], role);
    });
  }

  addLicenseToRole(licenses, role: RoleInfo) {
    const licenseIds = licenses.items.map((license: LicenseInfo) => license.id);
    this.licensesFacade.addLicensesToRole(role.id, licenseIds);
  }

  removeLicenseFromRole(licenses, role: RoleInfo) {
    const licenseIds = licenses.items.map((license: LicenseInfo) => license.id);
    this.licensesFacade.removeLicensesFromRole(role.id, licenseIds);
  }

  private setLicensesList(licenses: LicenseInfo[], role: RoleInfo) {
    const licensesForUser = this.getLicensesForRole(role, licenses);

    this.roleLicenses = licensesForUser;
    this.availableLicenses = licenses?.filter(
      (role) => !licensesForUser.find((roleLicenses) => roleLicenses.id === role.id)
    );
  }

  getLicensesForRole(role: RoleInfo, licenses: LicenseInfo[]): LicenseInfo[] {
    return licenses.filter((license: LicenseInfo) => {
      return license.roles.find((r) => r === role.id);
    });
  }

  sortLicensePills(licenses: LicenseInfo[]): LicenseInfo[] {
    return licenses.sort((a, b) => a.name.localeCompare(b.name));
  }

  public cancelBubble(event: Event) {
    event.stopPropagation();
  }

  public trackById(index, item: { id: string }) {
    return item.id;
  }
}
