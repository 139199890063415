import { Component, OnInit, OnDestroy, LOCALE_ID, ViewChild, ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { DepotSearchFacade } from '../+state/depot-search.facade';
import { ContentCollectionFacade } from '../../content-collection/+state/content-collection.facade';
import { DepotSearchResult } from '../depot-search.types';
import { AppFacade } from '../../+state/app.facade';
import {
  Subscription,
  Observable,
  Subject,
  combineLatest,
  BehaviorSubject,
  from,
  timer,
  zip,
  firstValueFrom
} from 'rxjs';
import { v4 as uuid } from 'uuid';
import { ContentCollection, ContentCollectionItem } from '../../content-collection/content-collection.types';
import { MeasurementsFacade } from '../../measurements/+state/measurements.facade';
import { MeasurementDownload, MeasurementMove } from '../../measurements/measurements.types';
import { TranslateService } from '@ngx-translate/core';
import { langFactory } from '../search-base/search-base.component';
import {
  map,
  tap,
  withLatestFrom,
  filter,
  take,
  shareReplay,
  pairwise,
  skip,
  distinctUntilChanged,
  share,
  switchMap,
  startWith
} from 'rxjs/operators';
import { MeasurementDeleteOrUndeleteComponent } from '../measurement-delete-or-undelete-dialog/measurement-delete-or-undelete.component';

import { trigger, style, transition, animate } from '@angular/animations';
import { CloudDepot } from '../../app.types';
import { eventPath } from '../../shared/utility-functions/eventPath';

import { CommonFacade } from '@vas/common';
import { pendingDeletionAttrId, SearchResultEntry } from '../../shared/types/search.types';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { suggestAttributes } from '../../shared/utility-functions/search.helpers';
import {
  DepotAttribute,
  isDepotAttribute,
  SemanticDepotAttribute
} from '../../shared/+state/attributes/attributes.types';
import { AttributesFacade } from '../../shared/+state/attributes/attributes.facade';
import { SortDirection } from '../../shared/types/sorting.types';
import { DepotSearchSortDefinition } from '../+state/depot-search.reducer';
import { FeatureFlagsFacade } from '@root/libs/feature-flags/src';
import { ClrDropdown } from '@clr/angular';

interface DepotSearchClickEvent {
  event: MouseEvent;
  measurement?: SearchResultEntry;
  shouldBeSuppressed: boolean;
}

interface DropDownTracker {
  open: boolean;
  position: 'bottom-left' | 'top-left';
}
[];

type ColumnIdentifiableSearchSort = DepotSearchSortDefinition & { columnIdentifier?: string };

@Component({
  selector: 'cloud-search-result-table',
  templateUrl: './search-result-table.component.html',
  styleUrls: ['./search-result-table.component.css', './search-result-table-datagrid-overwrites.css'],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useFactory: langFactory()
    }
  ],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('300ms ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('200ms ease-in', style({ opacity: 0 }))])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultTableComponent implements OnInit, OnDestroy {
  showTable$: Observable<boolean>;
  resetTable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showDetailView$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  downloadItem$: Subject<SearchResultEntry> = new Subject<SearchResultEntry>();

  handleMeasurementClick$: Subject<[MouseEvent, SearchResultEntry | undefined]> = new Subject<
    [MouseEvent, SearchResultEntry | undefined]
  >();
  preprocessedMeasurementClick$: Observable<DepotSearchClickEvent> = new Observable<DepotSearchClickEvent>();

  // prettier-ignore
  possibleColumnAttribs$: Observable<(SemanticDepotAttribute | DepotAttribute)[]>;

  selectedSearchResults$: BehaviorSubject<SearchResultEntry[]> = new BehaviorSubject<[]>([]);
  lastSelectedSearchResult$: Observable<SearchResultEntry | undefined>;
  selectNextIncomingResults$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  keepSelectionNextIncomingResults$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  depotSearchResults$: Observable<DepotSearchResult | undefined>;
  results$: Observable<SearchResultEntry[]>;
  detailColumnAttribute$: Observable<SemanticDepotAttribute | DepotAttribute | undefined>;
  detailColumnAttributeId$: Observable<string | undefined>;

  MAX_DELETE_UNDELETE_COUNT = 100;
  deletableSelectedResults$: Observable<SearchResultEntry[]>;
  showBatchDelete$: Observable<boolean>;
  tooManyResultsForBatchDelete$: Observable<boolean>;

  undeletableSelectedResults$: Observable<SearchResultEntry[]>;
  showBatchUndelete$: Observable<boolean>;
  tooManyResultsForBatchUndelete$: Observable<boolean>;

  allSelected$: Observable<boolean>;
  remainingResults$: Observable<number>;
  loadableMoreResults$: Observable<number>;
  sortingMoreThank10k$: Observable<boolean>;

  nonActiveContentCollections$: Observable<ContentCollection[]>;
  collectionsForSpecificEntry$: BehaviorSubject<{ collection: ContentCollection; isInCollection: boolean }[]> =
    new BehaviorSubject<{ collection: ContentCollection; isInCollection: boolean }[]>([]);

  sort$: Observable<ColumnIdentifiableSearchSort | false>;
  showSort$: Observable<boolean>;

  subscriptionContainer: Subscription[] = [];

  cloudDepots: { [index: string]: CloudDepot } = {};

  dropdownOpen: DropDownTracker[] = [];
  multiSelectDropdown: boolean = false;

  @ViewChild(MeasurementDeleteOrUndeleteComponent)
  private deleteOrUndeleteDialog: MeasurementDeleteOrUndeleteComponent;

  constructor(
    public depotSearchFacade: DepotSearchFacade,
    public attributesFacade: AttributesFacade,
    public contentCollectionFacade: ContentCollectionFacade,
    public measurementsFacade: MeasurementsFacade,
    public appFacade: AppFacade,
    public commonFacade: CommonFacade,
    private featureFlagFacade: FeatureFlagsFacade
  ) {}

  ngOnInit() {
    this.depotSearchResults$ = this.depotSearchFacade.currentDepotSearchResult$.pipe(
      filter((results) => {
        return !!results;
      })
    );

    this.detailColumnAttribute$ = combineLatest([this.depotSearchFacade.resultColumns$]).pipe(
      map(([resultColumns]) => resultColumns?.[0]?.attribute ?? undefined)
    );

    this.detailColumnAttributeId$ = combineLatest([this.detailColumnAttribute$]).pipe(
      map(([attribute]) => {
        if (attribute) {
          return isDepotAttribute(attribute) ? attribute.idName : attribute.id;
        }
        return undefined;
      })
    );

    this.nonActiveContentCollections$ = combineLatest([
      this.contentCollectionFacade.contentCollections$,
      this.contentCollectionFacade.activeContentCollectionGuid$
    ]).pipe(
      map(([collections, activeGuid]) => {
        return collections.filter((c) => c.guid !== activeGuid);
      })
    );

    this.showSort$ = this.featureFlagFacade.featureValue$('DEPOT_SEARCH_SORTING');

    this.sort$ = this.depotSearchFacade.sort$.pipe(
      withLatestFrom(this.depotSearchFacade.resultColumns$),
      map(([sort, resultColumns]) => {
        let result: ColumnIdentifiableSearchSort | false = false;
        if (resultColumns) {
          for (const column of resultColumns) {
            if (isDepotAttribute(column.attribute)) {
              const res = sort.find((sortDef) => {
                if (isDepotAttribute(sortDef.attribute)) {
                  return sortDef.attribute.idName === column.field;
                } else {
                  return sortDef.attribute.relatedDepotAttributeIds.find(
                    (relatedAttributeId) => relatedAttributeId === column.field
                  );
                }
              });
              if (res) {
                result = { ...res, columnIdentifier: column.field };
                // console.log('DA', column, res);
                break;
              }
            } else {
              const res = sort.find((sortDef) => {
                if (isDepotAttribute(sortDef.attribute)) {
                  return sortDef.attribute.idName === column.field;
                } else {
                  return sortDef.attribute.id === column.field;
                }
              });

              if (res) {
                result = { ...res, columnIdentifier: column.field };
                // console.log('SA', column, res);
                break;
              }
            }
          }
        }
        return result;
      })
    );

    this.results$ = combineLatest([
      this.depotSearchResults$,
      this.contentCollectionFacade.activeContentCollectionGuid$
    ]).pipe(
      switchMap(async ([results, _]) => {
        if (results) {
          return await Promise.all(
            results.entries.map(async (entry) => {
              const result: SearchResultEntry = { ...entry };
              result.entryId = entry.measurementId ?? entry.measurementBrowsePath;
              result.isInActiveCC = (await firstValueFrom(
                this.contentCollectionFacade.itemInActiveContentCollection$(this.transformToCCItem(entry))
              ))
                ? 'true'
                : 'false';
              return result;
            })
          );
        } else {
          return [];
        }
      }),
      shareReplay(1)
    );

    this.allSelected$ = combineLatest([this.results$, this.selectedSearchResults$]).pipe(
      map(([results, selected]) => results.length === selected.length)
    );

    this.remainingResults$ = combineLatest([this.depotSearchResults$, this.selectedSearchResults$]).pipe(
      map(([results, selected]) => (results?.numberOfTotalResults ?? 0) - selected.length)
    );

    this.loadableMoreResults$ = this.remainingResults$.pipe(
      map((remainingResults) => Math.min(remainingResults, 100)) // TODO: Make this configurable
    );

    this.sortingMoreThank10k$ = combineLatest([this.depotSearchResults$, this.depotSearchFacade.sort$]).pipe(
      map(([results, sort]) => {
        return (results?.numberOfTotalResults ?? 0) > 10_000 && sort?.length > 0;
      })
    );

    this.lastSelectedSearchResult$ = this.selectedSearchResults$.pipe(
      startWith([]),
      pairwise(),
      filter(([prev, curr]) => prev.length !== curr.length),
      map(([prev, curr]) => {
        const prevSet = new Set(prev);
        const currSet = new Set(curr);

        let lastSelectedItem;

        const newInSet = new Set([...currSet].filter((x) => !prevSet.has(x)));
        if (newInSet.size > 0) {
          lastSelectedItem = [...newInSet].pop();
        }

        const removedFromSet = new Set([...prevSet].filter((x) => !currSet.has(x)));
        if (removedFromSet.size > 0) {
          lastSelectedItem = [...currSet].pop();
        }
        return lastSelectedItem;
      }),
      shareReplay(1)
    );

    // Set initial measurement selection on revisiting the depot search page, and when someone explicitely changed the value (e.g. on search button presses)
    this.subscriptionContainer.push(
      this.depotSearchFacade.selectedMeasurementIDs$
        .pipe(
          distinctUntilChanged((x: string[], y: string[]) => JSON.stringify(x) === JSON.stringify(y)),
          withLatestFrom(this.results$),
          map(([measurementIDs, searchResults]) =>
            searchResults.filter((item) => measurementIDs.includes(item.measurementId))
          )
        )
        .subscribe((selectedSearchResults) => this.selectedSearchResults$.next(selectedSearchResults))
    );

    // On every search, we have to restore selected entries so that clarity datagrid can identify them
    this.subscriptionContainer.push(
      this.results$
        .pipe(
          withLatestFrom(
            this.selectedSearchResults$,
            this.selectNextIncomingResults$,
            this.keepSelectionNextIncomingResults$
          ),
          tap(([results, selectedEntries, selectNextIncoming, keepSelection]) => {
            const newSelectedEntries = results.filter(
              (result) => selectedEntries.findIndex((e) => e.id === result.id) !== -1
            );
            if (selectNextIncoming) {
              this.selectNextIncomingResults$.next(false);
              this.selectedSearchResults$.next(results);
            } else if (keepSelection) {
              this.keepSelectionNextIncomingResults$.next(false);
              this.selectedSearchResults$.next(newSelectedEntries);
            }
          })
        )
        .subscribe()
    );
    this.deletableSelectedResults$ = this.selectedSearchResults$.pipe(
      map((results) => results?.filter((r) => this.isDeletable(r)) ?? [])
    );

    this.showBatchDelete$ = this.deletableSelectedResults$.pipe(
      map((results) => results.length > 1 && results.length <= this.MAX_DELETE_UNDELETE_COUNT)
    );
    this.tooManyResultsForBatchDelete$ = this.deletableSelectedResults$.pipe(
      map((results) => results.length > this.MAX_DELETE_UNDELETE_COUNT)
    );

    this.undeletableSelectedResults$ = this.selectedSearchResults$.pipe(
      map((results) => results?.filter((r) => this.isUndeletable(r)) ?? [])
    );
    this.showBatchUndelete$ = this.undeletableSelectedResults$.pipe(
      map((results) => results.length > 1 && results.length <= this.MAX_DELETE_UNDELETE_COUNT)
    );
    this.tooManyResultsForBatchUndelete$ = this.undeletableSelectedResults$.pipe(
      map((results) => results.length > this.MAX_DELETE_UNDELETE_COUNT)
    );

    this.subscriptionContainer.push(
      this.selectedSearchResults$
        .pipe(
          map((selectedSearchResults) => selectedSearchResults.map((item) => item.measurementId)),
          distinctUntilChanged((x: string[], y: string[]) => JSON.stringify(x) === JSON.stringify(y)),
          tap((selectedMeasurementIDs) => this.depotSearchFacade.setSelectedMeasurementIDs(selectedMeasurementIDs))
        )
        .subscribe()
    );

    this.subscriptionContainer.push(
      this.depotSearchFacade.resultColumnIsLoading$
        .pipe(
          pairwise(),
          filter(([prev, curr]) => prev === true && curr === false),
          switchMap(() => zip(from([true, false]), timer(0, 1))),
          map(([x, _ignoredTimerNumber]) => x),
          skip(1) // Note: Skip(1) is required to prevent the table flickering initial load
        )
        .subscribe(this.resetTable$)
    );

    this.showTable$ = this.commonFacade.uiWidth$.pipe(map((width) => width >= 650));

    this.possibleColumnAttribs$ = combineLatest([
      this.appFacade.language$,
      this.attributesFacade.availableSemanticDepotAttributes$,
      this.attributesFacade.availableDepotAttributes$
    ]).pipe(
      filter(([_, semanticAttributes, attributes]) => !!semanticAttributes && !!attributes),
      map(([lang, semanticAttributes, attributes]) =>
        suggestAttributes(semanticAttributes ?? [], attributes ?? [], lang, false)
      )
    );

    this.subscriptionContainer.push(this.depotSearchFacade.showResultDetailView$.subscribe(this.showDetailView$));

    this.subscriptionContainer.push(
      this.downloadItem$
        .pipe(
          tap((item) => {
            const measurementDownload: MeasurementDownload = {
              id: uuid(),
              contents: [{ id: item.id as string }],
              conversion: 'b0338830-520a-4172-a181-fed3b92c03c0'
            };
            this.measurementsFacade.requestDownload(measurementDownload);
          })
        )
        .subscribe()
    );

    this.subscriptionContainer.push(this.appFacade.cloudDepots$.subscribe((depots) => (this.cloudDepots = depots)));

    this.measurementsFacade.initializeAttributeDisplayType();

    this.preprocessedMeasurementClick$ = this.handleMeasurementClick$.pipe(
      map(([event, measurement]) => {
        const paths = eventPath(event);

        const blockedElements = [
          'div.datagrid-select', // ignore clicks around the select checkbox
          'clr-icon', // ignore clicks on icons (add filters, action button)
          'prevent-select-click', // ignore clicks in the area around the action button
          'dropdown-item' // ignore clicks in the action dropdown
        ];

        let shouldIgnoreEvent = blockedElements.some((element) => {
          return paths.some((path) => {
            const tagName = path['localName'];
            const tagWithClassName = `${tagName}.${path['className']}`;
            return tagWithClassName.includes(element);
          });
        });

        const hasSuppressedModifierKey = this.hasSuppressedModifierKey(event);
        shouldIgnoreEvent = shouldIgnoreEvent && !hasSuppressedModifierKey;

        const allowedElements = ['detail-cell-text'];

        let isAllowedElement = false;
        if (shouldIgnoreEvent) {
          isAllowedElement = allowedElements.some((element) => {
            return paths.some((path) => {
              const tagName = path['localName'];
              const tagWithClassName = `${tagName}.${path['className']}`;
              return tagWithClassName.includes(element);
            });
          });
        }

        return {
          event: event,
          measurement: measurement,
          shouldBeSuppressed: shouldIgnoreEvent && !isAllowedElement
        };
      }),
      share()
    );

    this.subscriptionContainer.push(
      // Handle measurement clicks that need to be ignored
      // => clicks on elements inside the table that should not count as a measurement selection click
      this.preprocessedMeasurementClick$
        .pipe(filter((depotSearchClickEvent) => depotSearchClickEvent.shouldBeSuppressed === true))
        .subscribe((depotSearchClickEvent) => depotSearchClickEvent.event.stopPropagation()),

      // Ignore clicks that have CTRL/COMMAND or SHIFT pressed
      this.preprocessedMeasurementClick$
        .pipe(
          filter((depotSearchClickEvent) => depotSearchClickEvent.shouldBeSuppressed === false),
          filter((depotSearchClickEvent) => this.hasSuppressedModifierKey(depotSearchClickEvent.event)),
          tap((depotSearchClickEvent) => {
            if (depotSearchClickEvent.measurement) {
              if (depotSearchClickEvent.event.ctrlKey || depotSearchClickEvent.event.metaKey) {
                this.toggleSearchResultSelectedState(depotSearchClickEvent.measurement);
              } else if (depotSearchClickEvent.event.shiftKey) {
                this.handleShiftClickSelection(depotSearchClickEvent.measurement);
              }
            }
          })
        )
        .subscribe((depotSearchClickEvent) => depotSearchClickEvent.event.stopPropagation()),

      // Handle measurement clicks that need to trigger measurement selections
      this.preprocessedMeasurementClick$
        .pipe(
          filter((depotSearchClickEvent) => depotSearchClickEvent.shouldBeSuppressed === false),
          filter((depotSearchClickEvent) => !this.hasSuppressedModifierKey(depotSearchClickEvent.event)),
          withLatestFrom(this.depotSearchFacade.detailSearchResultEntry$),
          map(([depotSearchClickEvent, currentDetailSearchResultEntry]) => {
            if (depotSearchClickEvent.measurement === currentDetailSearchResultEntry) {
              // The clicked measurement was already selected, deselect
              depotSearchClickEvent.measurement = undefined;
            }
            return depotSearchClickEvent;
          })
        )
        .subscribe((depotSearchClickEvent) => this.viewMeasurementDetails(depotSearchClickEvent.measurement))
    );
  }

  hasSuppressedModifierKey = (event: MouseEvent): boolean => {
    const suppressedModifierKeys = ['ctrlKey', 'shiftKey', 'metaKey'];
    return suppressedModifierKeys.some((key) => event[key]);
  };

  addSearchBarFilter(event: MouseEvent, attribute: DepotAttribute | SemanticDepotAttribute, value: string) {
    this.depotSearchFacade.addSearchBarFilter(attribute, value);
    event.stopPropagation();
  }

  viewMeasurementDetails(SearchResultEntry: SearchResultEntry | boolean | undefined) {
    if (SearchResultEntry && typeof SearchResultEntry !== 'boolean') {
      this.measurementsFacade.showMeasurement(SearchResultEntry);
      this.depotSearchFacade.viewMeasurementDetails(SearchResultEntry);
    } else {
      this.depotSearchFacade.viewMeasurementOverviewList();
    }
  }

  async getMoreResults({ extendSelection, keepSelection }) {
    const results = await firstValueFrom(this.depotSearchResults$.pipe(take(1)));
    if (results?.numberOfEntries) {
      if (extendSelection) {
        this.selectNextIncomingResults$.next(true);
      }
      if (keepSelection) {
        this.keepSelectionNextIncomingResults$.next(true);
      }
      this.depotSearchFacade.markResultLine(results.numberOfEntries - 1);
      this.depotSearchFacade.loadMoreResults();
    }
  }

  async handleOtherClick(entry: SearchResultEntry) {
    const nonActiveContentCollections = await firstValueFrom(this.nonActiveContentCollections$.pipe(take(1)));
    const collectionsForSpecificEntry = nonActiveContentCollections.map((collection) => {
      return {
        collection,
        isInCollection: collection.items?.some((item) => item.measurementId === entry.measurementId) ?? false
      };
    });
    this.collectionsForSpecificEntry$.next(collectionsForSpecificEntry);
  }

  async collectionHasItem(item: SearchResultEntry, collectionGuid: string): Promise<boolean> {
    let result: boolean = false;
    const contentCollections = await firstValueFrom(this.contentCollectionFacade.contentCollections$.pipe(take(1)));
    const specificContentCollection = contentCollections.find((cc) => cc.guid === collectionGuid);
    if (specificContentCollection) {
      result = specificContentCollection.items?.some((ccItem) => ccItem.measurementId === item.measurementId) ?? false;
    }

    return result;
  }

  async addItemToNewCollection(searchEntry: SearchResultEntry) {
    const items = this.transformToCCItem(searchEntry);
    this.contentCollectionFacade.prepContentCollectionCreation([items]);
  }

  async addItemsToNewCollection(searchEntry: SearchResultEntry) {
    const selectedEntries = await firstValueFrom(this.selectedSearchResults$.pipe(take(1)));
    const itemsFromSelection = selectedEntries.map((item) => this.transformToCCItem(item));

    const clickedItem = this.transformToCCItem(searchEntry);
    const clickedItemInSelection = itemsFromSelection.find((item) => item.measurementId === clickedItem.measurementId);
    if (!clickedItemInSelection) {
      itemsFromSelection.push(clickedItem);
    }

    this.contentCollectionFacade.prepContentCollectionCreation(itemsFromSelection);
  }

  async addItemToSpecificCollection(searchEntry: SearchResultEntry, collectionGuid: string) {
    const contentCollections = await firstValueFrom(this.contentCollectionFacade.contentCollections$.pipe(take(1)));
    const specificContentCollection = contentCollections.find((cc) => cc.guid === collectionGuid);

    if (specificContentCollection) {
      const isNewItem =
        specificContentCollection.items?.find((item) => item.measurementId === searchEntry.measurementId) === undefined;
      if (isNewItem) {
        const contentCollectionItem = this.transformToCCItem(searchEntry);
        this.contentCollectionFacade.addContentCollectionItem(contentCollectionItem, collectionGuid);
      }
    } else {
      this.appFacade.showError('No content collection is defined', _('CONTENTCOLLECTION.GENERAL.UNDEFINED'));
    }
  }

  async addItemToActiveCollection(searchEntry: SearchResultEntry) {
    const activeContentCollection = await firstValueFrom(
      this.contentCollectionFacade.activeContentCollectionGuid$.pipe(take(1))
    );
    if (activeContentCollection) {
      const contentCollectionItem = this.transformToCCItem(searchEntry);
      this.contentCollectionFacade.addContentCollectionItem(contentCollectionItem, activeContentCollection);
    } else {
      this.appFacade.showError('No content collection is defined', _('CONTENTCOLLECTION.GENERAL.UNDEFINED'));
    }
  }

  async addSelectedSearchResultsToSpecificCollection(collectionGuid: string) {
    const searchEntries = await firstValueFrom(this.selectedSearchResults$.pipe(take(1)));
    const contentCollections = await firstValueFrom(this.contentCollectionFacade.contentCollections$.pipe(take(1)));
    const specificContentCollection = contentCollections.find((cc) => cc.guid === collectionGuid);
    if (specificContentCollection) {
      const newItems = searchEntries.filter(
        (newItem) =>
          specificContentCollection.items?.find((ccItem) => newItem.measurementId === ccItem.measurementId) ===
          undefined
      );

      const contentCollectionItems = newItems.map((item) => this.transformToCCItem(item));
      this.contentCollectionFacade.addMultipleContentCollectionItems(contentCollectionItems, collectionGuid);
    } else {
      this.appFacade.showError('No content collection is defined', _('CONTENTCOLLECTION.GENERAL.UNDEFINED'));
    }
  }

  async addSelectedSearchResultsToActiveCollection() {
    const activeContentCollection = await firstValueFrom(
      this.contentCollectionFacade.activeContentCollectionGuid$.pipe(take(1))
    );
    if (activeContentCollection) {
      this.addSelectedSearchResultsToSpecificCollection(activeContentCollection);
    } else {
      this.appFacade.showError('No content collection is defined or active', _('CONTENTCOLLECTION.GENERAL.UNDEFINED'));
    }
  }

  searchResultEntryInActiveCollection$ = (entry: SearchResultEntry) => {
    return this.contentCollectionFacade.itemInActiveContentCollection$(this.transformToCCItem(entry));
  };

  private transformToCCItem(item: SearchResultEntry): ContentCollectionItem {
    const depotId = item.measurementId.split('/')[0]; // TODO: do not parse measurementId
    // TODO: Fetch distinct name of measurement/subtitle instead of this nasty string hack
    const measurementName = item.measurementId.substr(item.measurementId.lastIndexOf('/') + 1);
    return {
      itemID: uuid(),
      depotID: depotId,
      measurementId: item.measurementId,
      content: {
        measurementBrowseUrl: item.measurementBrowseUrl,
        measurementName: measurementName.replace('[mea]', '')
      }
    };
  }

  async handleShiftClickSelection(entry: SearchResultEntry) {
    const searchResults = await firstValueFrom(this.results$.pipe(take(1)));

    const hasSelectedElements = (await firstValueFrom(this.selectedSearchResults$.pipe(take(1)))).length > 0;

    // Get the clicked element
    const clickedElement = entry;
    const clickedElementIndex = searchResults.findIndex((e) => e === clickedElement);

    if (hasSelectedElements) {
      // Get last selected element
      const lastSelectedElement = await firstValueFrom(this.lastSelectedSearchResult$.pipe(take(1)));
      const lastSelectedElementIndex = searchResults.findIndex((e) => e === lastSelectedElement);

      // Add all entries between the last selected element and the clicked element to the selected entries
      const selectedEntries = await firstValueFrom(this.selectedSearchResults$.pipe(take(1)));
      const newSelectedEntries = new Set(selectedEntries);
      if (lastSelectedElementIndex > clickedElementIndex) {
        for (let i = clickedElementIndex; i <= lastSelectedElementIndex; i++) {
          newSelectedEntries.add(searchResults[i]);
        }
      } else {
        for (let i = lastSelectedElementIndex; i <= clickedElementIndex; i++) {
          newSelectedEntries.add(searchResults[i]);
        }
      }
      this.selectedSearchResults$.next(Array.from(newSelectedEntries));
    } else {
      // If no element was selected before, just select the clicked element
      this.selectedSearchResults$.next([clickedElement]);
    }
  }

  async toggleSearchResultSelectedState(entry: SearchResultEntry) {
    const selectedEntries = await firstValueFrom(this.selectedSearchResults$.pipe(take(1)));
    const entryIndex = selectedEntries.findIndex((e) => e === entry);
    if (entryIndex >= 0) {
      selectedEntries.splice(entryIndex, 1);
    } else {
      selectedEntries.push(entry);
    }
    this.selectedSearchResults$.next(selectedEntries);
  }

  async requestMultipleMeasurementMove() {
    const items = await firstValueFrom(this.selectedSearchResults$);
    this.measurementsFacade.prepareMove(items);
  }

  async requestMultipleMeasurementsDownload() {
    const items = await firstValueFrom(this.selectedSearchResults$);
    items.forEach((item) => this.downloadItem$.next(item));
  }

  deleteMeasurements(results: SearchResultEntry | SearchResultEntry[]): void {
    const resultsList = Array.isArray(results) ? results : [results];
    if (resultsList) {
      this.deleteOrUndeleteDialog.isDeletion = true;
      this.deleteOrUndeleteDialog.showComponent(resultsList, this.cloudDepots);
    }
  }

  isDeletable(result: SearchResultEntry) {
    return !((result[pendingDeletionAttrId] as string)?.toLowerCase() === 'true'); // && this.isPakMeasurement(result)
  }

  undeleteMeasurements(results: SearchResultEntry | SearchResultEntry[]): void {
    if (results) {
      const measurements = Array.isArray(results) ? results : [results];
      this.deleteOrUndeleteDialog.isDeletion = false;
      this.deleteOrUndeleteDialog.showComponent(measurements, this.cloudDepots);
    }
  }

  isUndeletable(result: SearchResultEntry) {
    return (result[pendingDeletionAttrId] as string)?.toLowerCase() === 'true'; // && this.isPakMeasurement(result);
  }

  handleDropdownChange(i: number | 'MULTISELECTDROPDOWN', event: boolean, dropdown: ClrDropdown) {
    const open: { open: boolean; position: 'bottom-left' | 'top-left' }[] = Array(this.dropdownOpen.length).fill({
      open: false,
      position: 'bottom-left'
    });
    this.multiSelectDropdown = false;
    if (i === 'MULTISELECTDROPDOWN') {
      this.multiSelectDropdown = event;
    } else {
      open[i] = { open: event, position: 'bottom-left' };
    }
    this.dropdownOpen = open;

    if (dropdown) {
      const clickY = (dropdown?.toggleService?.originalEvent as MouseEvent)?.clientY;
      if (clickY) {
        const distanceToBottom = window.innerHeight - (clickY + window.scrollY);
        if (distanceToBottom < 350) {
          open[i].position = 'top-left';
        }
      }
    }
  }

  checkCheckBoxClick(event: MouseEvent) {
    console.log(event);
  }

  /*   determineDropdownPosition(event: any) {
    console.log(event);
  } */

  async startDrag(data: SearchResultEntry) {
    const contentCollectionItem = this.transformToCCItem(data);

    const selectedEntries = await firstValueFrom(this.selectedSearchResults$.pipe(take(1)));
    const itemsFromSelection = selectedEntries.map((item) => this.transformToCCItem(item));

    const isDraggedSelected = itemsFromSelection.find(
      (item) => contentCollectionItem.measurementId === item.measurementId
    );
    const itemsToDrag = isDraggedSelected ? itemsFromSelection : [...itemsFromSelection, contentCollectionItem];

    this.contentCollectionFacade.contentCollectionItemsDragged(itemsToDrag);
  }

  async stopDrag() {
    this.contentCollectionFacade.contentCollectionItemsDragged([]);
  }

  ngOnDestroy() {
    for (const sub of this.subscriptionContainer) {
      sub.unsubscribe();
    }
  }

  sortColumn(attribute: SemanticDepotAttribute | DepotAttribute, direction: SortDirection) {
    const sortDef: DepotSearchSortDefinition[] = [{ attribute, direction }];
    this.depotSearchFacade.setSearchSort(sortDef);
    this.depotSearchFacade.performSearchWithoutHierarchyTreeReload();
  }
}
