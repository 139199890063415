import { AttributeGroup, DepotAttribute, SemanticDepotAttribute } from '../+state/attributes/attributes.types';
import { availableLanguagesStrings } from '../../app.locales';

export function translateSemanticDepotAttributeGroup(
  value: AttributeGroup,
  currentLang: availableLanguagesStrings
): string {
  if (value.translations) {
    // language from cloud server matches language in client
    if (Object.prototype.hasOwnProperty.call(value.translations, currentLang)) {
      return value.translations[currentLang];
    }
    // language from cloud server has same prefix as language in client (e.g. "en_US" and "en")
    for (const languageKey of Object.keys(value.translations)) {
      if (languageKey.startsWith(currentLang)) {
        return value.translations[languageKey];
      }
    }
  }
  // Fallback: use attribute name if no explicit translations are defined
  return value.name;
}

export function translateSemanticDepotAttribute(
  value: SemanticDepotAttribute,
  currentLang: availableLanguagesStrings
): string {
  if (value.translations) {
    // language from cloud server matches language in client
    if (Object.prototype.hasOwnProperty.call(value.translations, currentLang)) {
      return value.translations[currentLang];
    }
    // language from cloud server has same prefix as language in client (e.g. "en_US" and "en")
    for (const languageKey of Object.keys(value.translations)) {
      if (languageKey.startsWith(currentLang)) {
        return value.translations[languageKey];
      }
    }
  }
  // Fallback: use attribute name if no explicit translations are defined
  return value.name;
}

export function translateDepotAttribute(attribute: DepotAttribute, currentLang: availableLanguagesStrings): string {
  if (attribute.semanticAttribute) {
    return translateSemanticDepotAttribute(attribute.semanticAttribute, currentLang);
  }
  return attribute.idName;
}
