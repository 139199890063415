<ng-container *ngIf="measurementsFacade.activeDownloads$ | async as downloads">
  <clr-dropdown [clrCloseMenuOnItemClick]="false" class="header-action dropdown-download-container">
    <button class="nav-icon" clrDropdownTrigger>
      <cloud-svg-icon fileName="download"></cloud-svg-icon>
      <span
        class="badge"
        [ngClass]="{
          alert: (measurementsFacade.downloadsErrorCount$ | async)! > 0
        }"
      >
        {{ downloads.length }}
      </span>
    </button>

    <clr-dropdown-menu clrPosition="bottom-right">
      <div class="clr-col-12">
        <div class="card-header dropdown-download title">
          <h4>
            <ng-container
              *ngIf="(measurementsFacade.downloadsErrorCount$ | async)! < downloads!.length; else errorBlock"
            >
              <span class="spinner spinner-sm"></span>
            </ng-container>
            <ng-template #errorBlock>
              <span>
                <clr-icon shape="warning-standard" size="20" class="is-error"></clr-icon>
              </span>
            </ng-template>
            <span> {{ 'APP.PREPAREDOWNLOADS' | translate }}... </span>
          </h4>
        </div>
        <div class="dropdown-download list">
          <ng-container *ngFor="let download of downloads">
            <ng-container *ngIf="download.error; else noErrorBlock">
              <div class="alert alert-danger" role="alert">
                <div class="alert-items dropdown-download list-item-text truncate">
                  <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                      <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                    </div>
                    <span class="dropdown-download alert-text">
                      <div class="dropdown-download bold">{{ download.contents[0].id.split('/').pop() }}</div>
                      {{ download.error.details }}
                    </span>
                  </div>
                </div>
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  (click)="measurementsFacade.cancelDownloadRequest(download)"
                >
                  <clr-icon aria-hidden="true" shape="close"></clr-icon>
                </button>
              </div>
            </ng-container>
            <ng-template #noErrorBlock>
              <div class="alert dropdown-download alert-info" role="alert">
                <div class="alert-items dropdown-download list-item-text truncate">
                  <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                      <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
                    </div>
                    <span class="alert-text">{{ download.contents[0].id.split('/').pop() }}</span>
                  </div>
                </div>
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  (click)="measurementsFacade.cancelDownloadRequest(download)"
                >
                  <clr-icon aria-hidden="true" shape="close"></clr-icon>
                </button>
              </div>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </clr-dropdown-menu>
  </clr-dropdown>
</ng-container>

<clr-dropdown
  *ngIf="
    (featureFlagFacade.featureValue$('ORDERS') | async) && (cloudRouterFacade.currentRootRoute$ | async) === 'orders'
  "
  class="header-action workspace-dropdown"
>
  <button class="top-right-nav-icon" clrDropdownTrigger data-cy="selectWorkspace">
    <clr-icon shape="tasks"></clr-icon>
    <span class="workspace">
      <ng-container *ngIf="!(workspaceFacade.selectedWorkspace$ | async)?.label">
        {{ 'ORDER.WORKSPACE' | translate }}
      </ng-container>
      {{ (workspaceFacade.selectedWorkspace$ | async)?.label }}
    </span>
    <cloud-svg-icon
      *ngIf="showWorkspaceDropdown; else caretDown"
      class="caret"
      fileName="caret_up"
      width="10px"
      height="5px"
    ></cloud-svg-icon>
    <ng-template #caretDown>
      <cloud-svg-icon class="caret" fileName="caret_down" width="10px" height="5px"></cloud-svg-icon>
    </ng-template>
  </button>

  <ng-template [(clrIfOpen)]="showWorkspaceDropdown">
    <clr-dropdown-menu clrPosition="bottom-right">
      <h4 class="dropdown-header">{{ 'APP.SELECTWORKSPACE' | translate }}</h4>
      <ng-container *ngIf="workspaceFacade.workspaces$ | async as workspaces">
        <a
          *ngFor="let workspace of workspaces | keyvalue"
          (click)="selectWorkspace(workspace.key)"
          [ngClass]="{
            active: (workspaceFacade.selectedWorkspace$ | async)?.id === workspace.key
          }"
          clrDropdownItem
          [attr.data-cy]="workspace.key"
          >{{ workspace.value.label }}</a
        >
      </ng-container>
    </clr-dropdown-menu>
  </ng-template>
</clr-dropdown>

<clr-dropdown *ngIf="userFacade.self$ | async as user" class="header-action user-dropdown">
  <button class="top-right-nav-icon" clrDropdownTrigger data-cy="userOverview">
    <cloud-svg-icon fileName="ph_user-white" height="16px" width="16px"></cloud-svg-icon>
    <span class="user-name">{{ user.userName }}</span>
    <cloud-svg-icon
      *ngIf="showUserDropdown; else caretDown"
      fileName="caret_up"
      height="5px"
      width="10px"
      class="caret"
    ></cloud-svg-icon>
    <ng-template #caretDown>
      <cloud-svg-icon fileName="caret_down" class="caret" height="5px" width="10px"></cloud-svg-icon>
    </ng-template>
  </button>

  <ng-template [(clrIfOpen)]="showUserDropdown">
    <clr-dropdown-menu class="user-dropdown">
      <a href="/user_management" target="_BLANK" clrDropdownItem>
        <cloud-svg-icon fileName="ph_user-dark" height="16px" width="16px"></cloud-svg-icon>
        Account
      </a>

      <a (click)="handleLogout()" clrDropdownItem class="logout-btn">
        <cloud-svg-icon fileName="sign_out" height="16px" width="16px"></cloud-svg-icon>
        {{ 'NAV.LOGOUT' | translate }}</a
      >

      <cloud-license-header> </cloud-license-header>
    </clr-dropdown-menu>
  </ng-template>
</clr-dropdown>

<clr-dropdown class="header-action app-settings">
  <button class="top-right-nav-icon" clrDropdownTrigger data-cy="menuMore">
    <clr-icon shape="ellipsis-vertical"></clr-icon>
  </button>
  <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
    <h4 class="dropdown-header">
      <cloud-svg-icon fileName="translate"></cloud-svg-icon>
      {{ 'APP.CHANGELANG' | translate }}
    </h4>
    <div
      ><a
        (click)="appFacade.setLanguage('en')"
        [ngClass]="{ active: (appFacade.languageState$ | async) === 'en' }"
        clrDropdownItem
        data-cy="language_en"
        >English</a
      >
      <a
        (click)="appFacade.setLanguage('de')"
        [ngClass]="{ active: (appFacade.languageState$ | async) === 'de' }"
        clrDropdownItem
        data-cy="language_de"
        >German</a
      >
      <a
        (click)="appFacade.setLanguage('ja')"
        [ngClass]="{ active: (appFacade.languageState$ | async) === 'ja' }"
        clrDropdownItem
        data-cy="language_ja"
        >Japanese</a
      >
    </div>

    <ng-container *ngIf="showAppsSections$ | async">
      <h4 class="dropdown-header" data-cy="CUSTOM_APPS">
        <cloud-svg-icon fileName="applications"></cloud-svg-icon>
        {{ 'APP.APPS.APPS_HEADER' | translate }}
      </h4>
      <div *ngIf="((PAKinsightName$ | async) && (showPAKinsightLink$ | async)) || (customApps$ | async)">
        <a
          *ngIf="(PAKinsightName$ | async) && (showPAKinsightLink$ | async)"
          (click)="showPAKinsightModal.emit()"
          data-cy="CUSTOM_APP_PAK_INSIGHT"
          clrDropdownItem
          >{{ PAKinsightName$ | async }}</a
        >
        <a
          *ngFor="let app of customApps$ | async"
          [href]="app.url"
          target="_BLANK"
          rel="noopener noreferrer"
          [attr.data-cy]="'CUSTOM_APP_' + app.name"
          clrDropdownItem
          >{{ app.name }}
        </a>
      </div>
    </ng-container>

    <h4 class="dropdown-header" *ngIf="shouldDisplayDocsHeader$ | async">
      <cloud-svg-icon fileName="question_mark"></cloud-svg-icon>
      {{ 'APP.SUPPORT' | translate }}
    </h4>
    <div
      ><a [routerLink]="['/docs']" clrDropdownItem *ngIf="featureFlagFacade.featureValue$('DOCS') | async">{{
        'NAV.DOCUMENTATION' | translate
      }}</a>
      <a
        [href]="featureFlagFacade.featureValue$('TUTORIAL') | async"
        target="_BLANK"
        rel="noopener noreferrer"
        clrDropdownItem
        *ngIf="featureFlagFacade.featureIsSet$('TUTORIAL') | async"
        >{{ 'NAV.TUTORIAL' | translate }}</a
      >
      <a href="/api" target="_BLANK" clrDropdownItem *ngIf="featureFlagFacade.featureValue$('APIDOCS') | async">{{
        'APP.APIDOCS' | translate
      }}</a>
    </div>

    <ng-container *ngIf="(appFacade.isDepotAdmin$ | async) || (appFacade.isUserAdmin$ | async)">
      <h4 class="dropdown-header">
        <cloud-svg-icon fileName="gear_icon" height="20px" width="20px"></cloud-svg-icon>
        {{ 'APP.ADMINISTRATION' | translate }}
      </h4>
      <div
        ><a
          [routerLink]="['/admin/statistics/live-view']"
          *ngIf="(appFacade.isDepotAdmin$ | async) || (appFacade.isUserAdmin$ | async)"
          clrDropdownItem
          >{{ 'APP.LICENSING' | translate }}</a
        >
        <a *ngIf="appFacade.isDepotAdmin$ | async" href="/depot/admin" target="_BLANK" clrDropdownItem>{{
          'APP.ADMINDEPOT' | translate
        }}</a>
        <a *ngIf="appFacade.isUserAdmin$ | async" href="/user_management/admin" target="_BLANK" clrDropdownItem>{{
          'APP.ADMINUSERS' | translate
        }}</a>
      </div>
    </ng-container>

    <h4 class="dropdown-header">
      <cloud-svg-icon fileName="additional_resources"></cloud-svg-icon>
      {{ 'APP.ADDITIONALRESOURCES' | translate }}
    </h4>
    <div
      ><a [routerLink]="['/about']" clrDropdownItem>{{ 'APP.IMPRINT' | translate }}</a>
      <a *ngIf="!(featureFlagFacade.featureIsSet$('PRIVACY') | async)" [routerLink]="['/privacy']" clrDropdownItem>{{
        'APP.PRIVACY' | translate
      }}</a>
      <a
        *ngIf="featureFlagFacade.featureIsSet$('PRIVACY') | async"
        [href]="featureFlagFacade.featureValue$('PRIVACY') | async"
        target="_BLANK"
        rel="noopener noreferrer"
        clrDropdownItem
        >{{ 'APP.PRIVACY' | translate }}</a
      >
      <a
        [href]="featureFlagFacade.featureValue$('TERMSOFUSE') | async"
        target="_BLANK"
        rel="noopener noreferrer"
        clrDropdownItem
        *ngIf="featureFlagFacade.featureIsSet$('TERMSOFUSE') | async"
        >{{ 'APP.TERMSOFUSE' | translate }}</a
      >
      <a [routerLink]="['/copyright']" clrDropdownItem>{{ 'APP.COPYRIGHT' | translate }}</a>
      <a [routerLink]="['/tpl']" clrDropdownItem>{{ 'APP.THIRDPARTYLICENSES' | translate }}</a>
    </div>

    <ng-container *ngIf="!(commonFacade.production$ | async)">
      <h4 class="dropdown-header">
        <cloud-svg-icon fileName="versions"></cloud-svg-icon>
        Versions
      </h4>
      <div
        ><a clrDropdownItem [clrDisabled]="true">WebApp Version: {{ commonFacade.appVersion$ | async }}</a>
        <a clrDropdownItem [clrDisabled]="true"
          >Server Version:
          {{
            (appFacade.serverVersion$ | async) !== undefined
              ? (appFacade.serverVersion$ | async)
              : ('APP.UNKNOWN' | translate)
          }}</a
        >
      </div>
    </ng-container>
  </clr-dropdown-menu>
</clr-dropdown>
