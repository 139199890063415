import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { LoadGroups } from './groups.actions';
import { GroupsPartialState } from './groups.reducer';
import { groupsQuery } from './groups.selectors';

@Injectable()
export class GroupsFacade {
  groups$ = this.store.pipe(select(groupsQuery.getGroups));
  loaded$ = this.store.pipe(select(groupsQuery.getLoaded));

  constructor(private store: Store<GroupsPartialState>) {}

  getGroups() {
    this.store.dispatch(LoadGroups());
  }
}
