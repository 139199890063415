// Usage:  <div *cloudMultiLang="'languageString'">. T
// The languageString can either be:
// - a comma-separated list of language codes, one of which has to match; e.g. "en" or "en, de"
// - the keyword "NOT" followed by a comma-separated list, none of which may match; e.g. "NOT en" or "NOT en, de"

import { Directive, ViewContainerRef, OnInit, OnDestroy, TemplateRef, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Directive({
  selector: '[cloudMultiLang]'
})
export class MultiLangDirective implements OnInit, OnDestroy {
  private sub: Subscription;
  private hasView = false;
  @Input('cloudMultiLang') lang: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.checkView(this.translate.currentLang);
    this.sub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.checkView(event.lang);
    });
  }

  checkView(currentLang: string) {
    const condition = this.isConditionMet(currentLang);
    if (condition && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!condition && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  private isConditionMet(currentLang: string): boolean {
    const hasNotOperator = this.lang.startsWith('NOT');
    const languageStrings = this.lang
      .replace('NOT', '')
      .split(',')
      .map((str) => str.trim());
    const hasLanguageMatch = languageStrings.includes(currentLang);
    return hasNotOperator ? !hasLanguageMatch : hasLanguageMatch;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
