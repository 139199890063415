import { Injectable, Inject } from '@angular/core';
import { ANGULAR_HTTP_CONTEXT } from '../../app.tokens';
import { HttpClient } from '@angular/common/http';
import { AngularHttpContext } from '@vas/angular-http-context';
import { CloudService } from '../../services/cloud.service';
import { JsonApi, Spec } from '@muellerbbm-vas/grivet';
import {
  DepotBrowseContent,
  DryRunMeasurementDetails,
  IntermediateMeasuementDetailsBeforeMove,
  MeasurementMove,
  MeasurementMoveDryRunResults,
  MeasurementPath
} from '../measurements.types';
import { ResultColumn, SearchResultEntry } from '../../shared/types/search.types';
import { filter, firstValueFrom, take } from 'rxjs';
import { AttributesFacade } from '../../shared/+state/attributes/attributes.facade';
import { DepotAttribute, SearchAttributeAndValue, SearchFilter } from '../../shared/+state/attributes/attributes.types';
import { v4 as uuid } from 'uuid';
import { DepotSearchParameters, defaultSearchParams } from '../../depot-search/depot-search.types';
import { DepotSearchService } from '../../depot-search/depot-search.service';

export const pathAttributesMapping: {
  [key in keyof MeasurementPath]: string;
} = {
  depot_name: 'depot_name',
  depot_id: 'depot_id',
  project: 'hrchypak.Project.name',
  job: 'hrchypak.Job.name',
  subtitle: 'hrchypak.Subtitle.name'
};

export const reversePathAttributesMapping = Object.entries(pathAttributesMapping).reduce((acc, [key, value]) => {
  acc[value] = key;
  return acc;
}, {});

@Injectable({
  providedIn: 'root'
})
export class MeasurementsMoveService {
  private transferListUrl: URL;

  constructor(
    @Inject(ANGULAR_HTTP_CONTEXT) private context: AngularHttpContext,
    private cloudService: CloudService,
    private attributesFacade: AttributesFacade,
    private depotSearchService: DepotSearchService,
    private httpClient: HttpClient
  ) {}

  async ensureDepotAppStart() {
    return await this.cloudService.getAppStart('depot');
  }

  async ensureTransferListUrl() {
    if (!this.transferListUrl) {
      const appStart = await this.ensureDepotAppStart();
      this.transferListUrl = appStart?.relationships['transfer_measurement'].links?.related.url ?? new URL('');
    }
  }

  private createTransferRequest(measurementMove: MeasurementMove): Spec.ClientJsonApiDocument {
    if (measurementMove.targetPath === undefined) {
      throw new Error('target path is undefined');
    }

    const request = new JsonApi.ClientDocument('TransferMeasurement');
    request.setAttribute('delete_source', 'true');
    request.setAttribute('allow_overwrite', measurementMove.overwrite ? 'true' : 'false');

    // const path_to_destination = measurementMove.targetPath.split('/'); // something like ['project', 'job', 'measurement']
    const path_to_destination = [
      measurementMove.targetPath.project,
      measurementMove.targetPath.job,
      measurementMove.targetPath.subtitle
    ];
    request.setAttribute('path_to_destination', '[]');
    request.data.data.attributes!['path_to_destination'] = path_to_destination;

    const originContent: DepotBrowseContent = { id: measurementMove.measurementId }; // HINT: improve type safety in MeasurementMove:source measurement as DepotBrowseContent
    const origin_measurement: Spec.ResourceIdentifierObject = { id: originContent.id, type: 'DepotBrowseContent' };
    request.setRelationship('source_measurement', origin_measurement);

    const destination_depot: Spec.ResourceIdentifierObject = {
      id: measurementMove.destinationDepotId,
      type: 'Depot'
    };
    request.setRelationship('destination_depot', destination_depot);

    return request.data;
  }

  private createTransferRequestCancellation(): Spec.ClientJsonApiDocument {
    const request = new JsonApi.ClientDocument('JobCancellationRequest');
    return request.data;
  }

  async requestMeasurementMove(measurementMove: MeasurementMove): Promise<MeasurementMove> {
    await this.ensureTransferListUrl();
    const transferRequest: Spec.ClientJsonApiDocument = this.createTransferRequest(measurementMove);
    const response = await this.context.postDocument(this.transferListUrl, transferRequest);
    const responseDocument = new JsonApi.Document(response, this.context);
    const responseResource = responseDocument.resource;
    const retMeasuremenMove: MeasurementMove = {
      ...measurementMove,
      progress: 0,
      statusUrl: responseResource?.selfLink?.url.href,
      cancelUrl: responseResource?.relationships['cancellation_requests'].links?.related.url.href
    };

    return retMeasuremenMove;
  }

  private createErrorMessage(error: any): string {
    let errorMsg = 'Measurement transfer error';
    if (error['message']) {
      errorMsg = errorMsg + ': ' + error['message'];
    }
    const details = error['details'];
    if (details) {
      if (typeof details === 'string') {
        errorMsg = errorMsg + ': \n' + details;
      } else if (Array.isArray(details)) {
        details.filter((detail) => detail.length).forEach((detail) => (errorMsg = errorMsg + '\n' + detail));
      } else {
        Object.keys(details).forEach((key) => {
          if (details[key].length) {
            errorMsg = errorMsg + '\n' + key + ': ' + details[key];
          }
        });
      }
    }
    return errorMsg;
  }
  async pollMoveStatus(measurementMove: MeasurementMove): Promise<MeasurementMove> {
    const statusUrl = measurementMove.statusUrl;
    const response = await this.context.getDocument(new URL(statusUrl ?? ''));
    const statusDocument = new JsonApi.Document(response, this.context);
    const statusResource = await statusDocument.resource;

    const retMeasurementMove: MeasurementMove = {
      ...measurementMove
    };

    let error;
    let errorMessage = '';
    if (!statusResource) {
      retMeasurementMove.moveState = 'error';
      errorMessage = 'Could not fetch measurement move status';
    } else {
      errorMessage = '';
      error = statusResource?.attributes?.['error'];
      if (error) {
        retMeasurementMove.moveState = 'error';
        errorMessage = this.createErrorMessage(error);
      } else {
        const isPending = statusResource.attributes?.['is_pending'] ?? false;
        if (isPending) {
          const progress = (statusResource.attributes?.['progress'] as number) * 100 ?? 0;
          retMeasurementMove.progress = progress;
          retMeasurementMove.moveState = 'moving';
        } else {
          retMeasurementMove.moveState = 'done';
        }
      }
    }
    retMeasurementMove['errorMsg'] = errorMessage;
    return retMeasurementMove;
  }

  async cancelMoveRequest(measurementMove: MeasurementMove) {
    const retMeasurementMove: MeasurementMove = {
      ...measurementMove
    };
    await this.context.postDocument(new URL(measurementMove.cancelUrl ?? ''), this.createTransferRequestCancellation());
    return retMeasurementMove;
  }

  async getAvailableDepotAttributes(): Promise<DepotAttribute[] | undefined> {
    return firstValueFrom(
      this.attributesFacade.availableDepotAttributes$.pipe(
        filter((attributes) => {
          let result = false;
          if (attributes?.length ?? 0 > 0) {
            result = true;
          }
          return result;
        }),
        take(1)
      )
    );
  }

  // Move Preparation Search
  public async getMeasurementPaths(
    measurements: SearchResultEntry[]
  ): Promise<IntermediateMeasuementDetailsBeforeMove[]> {
    const depotAttribs = await this.getAvailableDepotAttributes();

    // Prepare relevant attributes and ResultColumns
    const baseIDnames = ['pk'];

    Object.values(pathAttributesMapping).forEach((value) => {
      baseIDnames.push(value);
    });

    const resultColumns: Record<string, ResultColumn> = {};
    baseIDnames.forEach((idName) => {
      const attrib = depotAttribs?.find((attrib) => attrib.idName === idName);
      if (attrib) {
        resultColumns[idName] = { attribute: attrib, field: attrib.idName };
      }
    });

    // Prepare search filters
    const individualPKfilters = measurements.map((mea) => {
      const itemFilter: SearchAttributeAndValue = {
        id: uuid(),
        attribute: resultColumns['pk'].attribute,
        exact_match: true,
        searchAttributeValue: mea.measurementId,
        searchAttributeBoolean: false
      };

      return itemFilter;
    });

    const searchFilter: SearchFilter = [
      {
        type: 'or',
        filters: individualPKfilters
      }
    ];

    const searchParameters: DepotSearchParameters = {
      ...defaultSearchParams,
      description: 'Measurement Move - Lookup Search',
      searchLimit: 1000,
      offset: 0,
      resultColumns: Object.values(resultColumns),
      searchFilters: searchFilter
    };

    const searchResult = await this.depotSearchService.doSearch(searchParameters);

    const measurementDetails: IntermediateMeasuementDetailsBeforeMove[] = searchResult.entries.map((searchResult) => {
      const path: MeasurementPath = {
        depot_id: searchResult[pathAttributesMapping['depot_id']],
        depot_name: searchResult[pathAttributesMapping['depot_name']],
        project: searchResult[pathAttributesMapping['project']],
        job: searchResult[pathAttributesMapping['job']],
        subtitle: searchResult[pathAttributesMapping['subtitle']]
      };

      const details: IntermediateMeasuementDetailsBeforeMove = {
        measurementId: searchResult.measurementId,
        path: path
      };
      return details;
    });

    return measurementDetails;
  }

  // Move Dry Run
  public async performMoveDryRun(
    measurements: IntermediateMeasuementDetailsBeforeMove[],
    targetPath: MeasurementPath
  ): Promise<MeasurementMoveDryRunResults> {
    const targetPathCheck = await this.checkIfProjectAndJobExists(targetPath.project, targetPath.job);
    if (targetPathCheck.projectExists === true && targetPathCheck.jobExists === true) {
      // check each intermediate measurement for overwrite conflict
      const dryRunMeasurements: DryRunMeasurementDetails[] = await this.batchCheckMeasurementsForOverwriteConflict(
        measurements
      );
      const result: MeasurementMoveDryRunResults = {
        dryRunMeasurements,
        projectExists: true,
        jobExists: true
      };
      return result;
    } else {
      // target project/job path does not exist, therefore no need to check for overwrite conflicts
      const result: MeasurementMoveDryRunResults = {
        dryRunMeasurements: measurements.map((mea) => {
          return {
            measurementId: mea.measurementId,
            path: mea.path,
            willOverWrite: false
          };
        }),
        projectExists: targetPathCheck.projectExists,
        jobExists: targetPathCheck.jobExists
      };
      return result;
    }
  }

  public async checkIfProjectAndJobExists(
    project: string,
    job: string
  ): Promise<{ projectExists: boolean; jobExists: boolean }> {
    const result: { projectExists: boolean; jobExists: boolean } = { projectExists: false, jobExists: false };

    const depotAttribs = await this.getAvailableDepotAttributes();

    // check if project exists

    // Prepare relevant attributes and ResultColumns
    const projectResultColumnAttrib: DepotAttribute | undefined = depotAttribs?.find(
      (attrib) => attrib.idName === pathAttributesMapping['project']
    );
    if (projectResultColumnAttrib) {
      const specificProjectFilter: SearchAttributeAndValue = {
        id: uuid(),
        attribute: projectResultColumnAttrib,
        exact_match: true,
        searchAttributeValue: project,
        searchAttributeBoolean: false
      };

      const projectCheckParams: DepotSearchParameters = {
        ...defaultSearchParams,
        description: 'Measurement Move - Target Project Dry Run',
        searchLimit: 1,
        offset: 0,
        searchFilters: [specificProjectFilter]
      };
      const projectCheckResults = await this.depotSearchService.doSearch(projectCheckParams);

      result.projectExists = projectCheckResults.numberOfEntries > 0;

      // check if job exists within project
      const jobResultColumnAttrib: DepotAttribute | undefined = depotAttribs?.find(
        (attrib) => attrib.idName === pathAttributesMapping['job']
      );
      if (jobResultColumnAttrib) {
        const specificJobFilter: SearchAttributeAndValue = {
          id: uuid(),
          attribute: jobResultColumnAttrib,
          exact_match: true,
          searchAttributeValue: job,
          searchAttributeBoolean: false
        };

        const jobFilter: SearchFilter = [
          {
            type: 'and',
            filters: [specificProjectFilter, specificJobFilter]
          }
        ];

        const jobCheckParams: DepotSearchParameters = {
          ...defaultSearchParams,
          description: 'Measurement Move - Target Project/Job Dry Run',
          searchLimit: 1,
          offset: 0,
          searchFilters: jobFilter
        };
        const jobCheckResults = await this.depotSearchService.doSearch(jobCheckParams);
        result.jobExists = jobCheckResults.numberOfEntries > 0;
      } else {
        console.error('Project attribute not found while checking if project exists');
        return result;
      }
    } else {
      console.error('Project attribute not found while checking if project exists');
      return result;
    }

    return result;
  }

  public async batchCheckMeasurementsForOverwriteConflict(
    intermediateMeasurements: IntermediateMeasuementDetailsBeforeMove[]
  ): Promise<DryRunMeasurementDetails[]> {
    const depotAttribs = await this.getAvailableDepotAttributes();
    const attributeIDs = [
      pathAttributesMapping.depot_id,
      pathAttributesMapping.project,
      pathAttributesMapping.job,
      pathAttributesMapping.subtitle
    ];

    const attributes = depotAttribs?.filter((attrib) => attributeIDs.includes(attrib.idName));

    const pathFilters: SearchFilter = intermediateMeasurements.map((mea) => {
      const filters: SearchAttributeAndValue[] = attributes?.map((attrib) => {
        const targetPathKeyLookup = reversePathAttributesMapping[attrib.idName];
        return {
          id: uuid(),
          attribute: attrib,
          exact_match: true,
          searchAttributeValue: mea.path[targetPathKeyLookup],
          searchAttributeBoolean: false
        };
      }) as SearchAttributeAndValue[];

      return {
        type: 'and',
        filters: filters
      };
    });

    const overwriteCheckParams: DepotSearchParameters = {
      ...defaultSearchParams,
      description: 'Measurement Move - Overwrite Check',
      searchLimit: 1000,
      offset: 0,
      searchFilters: [
        {
          type: 'or',
          filters: pathFilters
        }
      ]
    };
    const overwriteCheckResult = await this.depotSearchService.doSearch(overwriteCheckParams);

    return intermediateMeasurements.map((mea) => {
      return {
        measurementId: mea.measurementId,
        path: mea.path,
        willOverWrite: overwriteCheckResult.entries.some((entry) => entry.measurementId === mea.measurementId)
      };
    });
  }
}
