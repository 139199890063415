import { RequestState } from './streaming.types';
import { encode, decode } from 'js-base64';

export type MeasurementTokenContainerID = string;
export type ProcessingTokenContainerID = string;
export type TokenContainerID = MeasurementTokenContainerID | ProcessingTokenContainerID;

export type Token = string | null;
export type TokenContainer = ProcessingTokenContainer | MeasurementTokenContainer;
interface BaseTokenContainer {
  webgrpcServerURL?: string;
  requestState: RequestState;
  token: Token;
  validUntilISO: string;
}

export interface ProcessingTokenContainer extends BaseTokenContainer {
  id: ProcessingTokenContainerID;
  type: 'Processing';
}

export interface MeasurementTokenContainer extends BaseTokenContainer {
  id: MeasurementTokenContainerID;
  type: 'Measurement';
  depotId?: string;
  measurementId?: string;
}

export const encodeMeasurementTokenContainerID = (depotId: string, measurementId: string): string => {
  return encode(JSON.stringify({ depotId, measurementId }));
};
export const decodeMeasurementTokenContainerID = (
  measurementTokenContainerID: MeasurementTokenContainerID
): { depotId: string; measurementId: string } => JSON.parse(decode(measurementTokenContainerID));

export const isProcessingToken = (tokenContainer: TokenContainer): tokenContainer is ProcessingTokenContainer =>
  tokenContainer.type === 'Processing';
export const isMeasurementToken = (tokenContainer: TokenContainer): tokenContainer is MeasurementTokenContainer =>
  tokenContainer.type === 'Measurement';
