import { Inject, Injectable } from '@angular/core';
import { JsonApi, Spec } from '@muellerbbm-vas/grivet';
import { AngularHttpContext } from '@root/libs/vas-angular-http-context/src';
import { forkJoin, of, toArray } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { from } from 'rxjs/internal/observable/from';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { map } from 'rxjs/operators';
import { ANGULAR_HTTP_CONTEXT } from '../../../../app.tokens';
import { CloudService } from '../../../../services/cloud.service';
import { UserInfo } from '../../../../user/user.type';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(@Inject(ANGULAR_HTTP_CONTEXT) private context: AngularHttpContext, private cloudService: CloudService) {}

  getUsers(): Observable<UserInfo[]> {
    return this.cloudService.getAppStartObs('user_management').pipe(
      switchMap((res) => {
        const baseUsersURL = new URL(res?.relationships['users'].links?.related.url.href ?? 'https://example.com');
        baseUsersURL.searchParams.append('include', 'user2role, memberships.group');

        return from(JsonApi.Document.fromURL(baseUsersURL, this.context));
      }),
      mergeMap((res) => {
        return from(res.resources).pipe(
          toArray(),
          map((users) => users.map((data) => this.createUserModel(data['rawData'], res.includedResources.Group)))
        );
      })
    );
  }

  deleteUserGroups(userId: string, groupIds: string[]): Observable<any> {
    return this.cloudService.getAppStartObs('user_management').pipe(
      switchMap((res: JsonApi.Resource | null) => (res ? from(res?.relatedResources['users']) : of([]))),
      map((users) => {
        return users.find((user) => user.id === userId);
      }),
      switchMap((res: JsonApi.Resource | null) => (res ? from(res?.relatedResources['memberships']) : of([]))),
      switchMap((groups) => {
        const groupsToDelete = groups.filter((group) => groupIds.includes(group.id));

        const deleteObservables = groupsToDelete.map((group) => {
          const url = group.rawData?.links?.['self'] as URL;
          return this.context.deleteDocument(new URL(url));
        });

        return forkJoin(deleteObservables);
      })
    );
  }

  addGroupsToUser(userId: string, groupIds: string[]) {
    return this.cloudService.getAppStartObs('user_management').pipe(
      switchMap((res: JsonApi.Resource | null) => (res ? from(res?.relatedResources['users']) : of([]))),
      map((users) => {
        return users.find((user) => user.id === userId);
      }),
      switchMap((res) => {
        // @ts-ignore
        const url = res?.relationships?.['memberships']?.rawData?.links?.['related'] as URL;

        const observables = groupIds.map((groupId) => {
          const data = {
            attributes: {},
            included: [],
            relationships: {
              group: {
                data: {
                  id: groupId,
                  type: 'Group'
                }
              }
            },
            type: 'UserMembership'
          };

          return this.context.postDocument(new URL(url), { data });
        });

        return forkJoin(observables);
      })
    );
  }

  private createUserModel(userDoc: JsonApi.Resource['rawData'], groupsRes: { [id: string]: any }): UserInfo {
    const groups: string[] = [];
    const memberships = userDoc?.relationships?.['memberships'].data as Spec.ResourceIdentifierObject[];

    for (const membership of memberships) {
      const group = Object.values(groupsRes).find((group) => membership.id.split('_')[1] === group.id)?.attributes;

      group && groups.push(group?.name);
    }

    const user: UserInfo = {
      id: userDoc.id,
      hasVibroLicense: false,
      userName: userDoc?.attributes?.['username'],
      firstName: userDoc?.attributes?.['first_name'],
      lastName: userDoc?.attributes?.['last_name'],
      email: userDoc?.attributes?.['email'],
      isActive: userDoc?.attributes?.['is_active'],
      joined: userDoc?.attributes?.['date_joined'] ?? undefined,
      lastLogin: userDoc?.attributes?.['last_login'] ?? undefined,
      groups: groups
    };

    return user;
  }
}
