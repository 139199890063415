import { DatasetDescription } from '../../measurements/measurements.types';
import { StreamingType } from './streaming.types';
import { StreamingChart } from './streaming-chart.types';
import * as StreamingServicePb from './../prototypes/streamingService';
import { DatasetStreamingServiceClient } from './../prototypes/streamingService.client';
import { GrpcWebFetchTransport, GrpcWebOptions } from '@protobuf-ts/grpcweb-transport';
import { determineDatasetType } from './datatype.utils';
import { convertAbsoluteTimeToInt128 } from './time.utils';
import {
  deserializeDoubleScale,
  deserializePosition,
  deserializeQuantity,
  deserializeStepDelta3D,
  deserializeXValues,
  extractFreqWeight
} from './dataset.deserializers';
import {
  AbsoluteTimeRange,
  DataFormat,
  DatasetThreeDimensionalMetaData,
  DatasetThreeDimensionalMetaData_PsychoParams_PsychoType,
  DatasetTwoDimensionalMetaData,
  DataType,
  EquiTimeScale,
  Quantities,
  ReferencedInfo
} from '../prototypes/DatasetMessages';
import { Token } from './streaming-token.types';
import { determineSignificantDigits } from './formatting.utils';

export const getDatasetKeyDataList = async (
  token: Token,
  serverUrl: string
): Promise<StreamingServicePb.DatasetKeyDataList> => {
  const identifier: StreamingServicePb.DataSourceIdentifier = StreamingServicePb.DataSourceIdentifier.create();
  identifier.identifiers = {
    oneofKind: 'token',
    token: token!
  };
  const streamingParams = StreamingServicePb.EnumerateDatasetsParameters.create();
  streamingParams.dataSource = identifier;

  const streamingServiceClient = createStreamingServiceClient(serverUrl);
  let result: StreamingServicePb.DatasetKeyDataList = { items: [] };
  const datasets = await enumerateDatasetsFully(streamingServiceClient, streamingParams);
  if (datasets) {
    result = datasets;
  }
  return result;
};

export const getDatasetMetaData = async (
  token: Token,
  serverUrl: string,
  datasetKeyListItems: StreamingServicePb.DatasetKeyDataList_Item[]
): Promise<{
  metaDataList: StreamingServicePb.DatasetMetaDataList;
  subscribeIdToLocatorMap: Record<number, StreamingServicePb.DatasetLocator>;
}> => {
  const streamingServiceClient = createStreamingServiceClient(serverUrl);

  const identifier: StreamingServicePb.DataSourceIdentifier = StreamingServicePb.DataSourceIdentifier.create();
  identifier.identifiers = {
    oneofKind: 'token',
    token: token!
  };
  const streamingParams = StreamingServicePb.StreamDatasetsParameters.create();
  const subscribeIdToLocatorMap: Record<number, StreamingServicePb.DatasetLocator> = {};
  streamingParams.subscribedDatasets = datasetKeyListItems.map((dataset, i) => {
    if (dataset.locator) {
      subscribeIdToLocatorMap[i] = dataset.locator;
    }
    const subscribedDataset: StreamingServicePb.StreamDatasetsParameters_SubscribedDataset = {
      locator: dataset.locator,
      subscribeId: i,
      streamDataType: StreamingServicePb.StreamDatasetsParameters_SubscribedDataset_StreamDataType.StreamFullData
    };
    return subscribedDataset;
  });
  // streamingParams.dataSource = identifier;

  return new Promise((resolve, reject) => {
    const { response } = streamingServiceClient.datasetsMetaData(streamingParams);
    response
      .then((res) => {
        const result = { metaDataList: res, subscribeIdToLocatorMap };
        resolve(result);
      })
      .catch((err) => {
        reject('err');
      });
  });
};

export const getStreamingChartDatasetParams = async (
  token: Token,
  serverUrl: string,
  streamingType: StreamingType,
  selectedDataset?: DatasetDescription
): Promise<StreamingChart.DatasetParameters[]> => {
  const identifier: StreamingServicePb.DataSourceIdentifier = StreamingServicePb.DataSourceIdentifier.create();
  identifier.identifiers = {
    oneofKind: 'token',
    token: token!
  };
  const streamingParams = StreamingServicePb.EnumerateDatasetsParameters.create();
  streamingParams.dataSource = identifier;

  const streamingServiceClient = createStreamingServiceClient(serverUrl);

  let datasetParams: StreamingChart.DatasetParameters[] = [];
  let remainingRetries = 20;
  let done = false;
  const pauseBetweenRetries = 500;
  let enumerateErr: any;
  do {
    try {
      //console.log('Trying EnumerateDataSets');
      //console.log(streamingServiceClient, streamingParams, streamingType, selectedDataset);

      const tryDatasetParams = await getStreamingChartDatasetParamsFromDatasetDescription(
        streamingServiceClient,
        streamingParams,
        streamingType,
        selectedDataset
      );
      if (tryDatasetParams) {
        datasetParams = tryDatasetParams!;
        done = true;
        break;
      }
    } catch (err) {
      enumerateErr = err;
      if (err.code === 3) {
        // Token expired -> give up re-trying
        throw err;
      }
      console.warn('Retry EnumerateDataSets', err, remainingRetries);
    }
    await new Promise((resolve) => setTimeout(resolve, pauseBetweenRetries));
    remainingRetries--;
  } while (!done && remainingRetries > 0);

  if (!done) {
    const msg = 'Maximum retries of enumerateDataSets exceeded';
    console.error(msg);
    if (typeof enumerateErr !== 'undefined') {
      throw enumerateErr;
    } else {
      throw new Error(msg);
    }
  }

  return datasetParams;
};

export const enrichDatasetParamsWithPlottingParams = async (
  token: Token,
  serverUrl: string,
  datasetRegister: StreamingChart.DatasetRegister,
  defaultAccelerationdBreferenceFactor: number | undefined
): Promise<void> => {
  const identifier: StreamingServicePb.DataSourceIdentifier = {
    identifiers: {
      oneofKind: 'token',
      token: token!
    }
  };

  const datasets: StreamingServicePb.StreamDatasetsParameters_SubscribedDataset[] =
    datasetRegister.datasatParamsList.map((params, idx) => ({
      subscribeId: idx,
      locator: { id: params.id, dataSource: identifier },
      streamDataType: StreamingServicePb.StreamDatasetsParameters_SubscribedDataset_StreamDataType.StreamFullData
    }));

  const streamingParams = StreamingServicePb.StreamDatasetsParameters.create();
  streamingParams.subscribedDatasets.push(...datasets);

  const streamingServiceClient = createStreamingServiceClient(serverUrl);

  return new Promise<void>((resolve, reject) => {
    const { response } = streamingServiceClient.datasetsMetaData(streamingParams);
    response
      .then((res) => {
        const quantities = res.quantities;
        for (const dset of res.items) {
          const metaData = dset.metadata;
          const datasetParams: StreamingChart.DatasetParameters = datasetRegister.datasatParamsList[dset.subscribeId];
          if (metaData) {
            switch (metaData.data['oneofKind']) {
              case 'ds2DMetadata':
                {
                  const metaData2D = metaData.data.ds2DMetadata;
                  datasetParams.plottingParameters = extract2dPlottingParams(datasetParams, metaData2D, quantities);
                }
                break;
              case 'ds3DMetadata':
                {
                  const metaData3D = metaData.data.ds3DMetadata;
                  datasetParams.plottingParameters = extract3dPlottingParams(datasetParams, metaData3D, quantities);
                }
                break;
              default:
                console.warn('unknown metaData type: ', metaData.data['oneofKind']);
                break;
            }
            if (datasetParams?.plottingParameters && defaultAccelerationdBreferenceFactor !== undefined) {
              datasetParams.plottingParameters.defaultAccelerationdBreferenceFactor =
                defaultAccelerationdBreferenceFactor;
            }
            datasetParams.type = determineDatasetType(
              datasetRegister.datasatParamsList[dset.subscribeId].rawDataType,
              metaData,
              datasetParams.plottingParameters?.effectiveDataFormat,
              quantities
            );
          }
        }
        resolve();
      })
      .catch((err) => {
        reject('err');
      });
  });
};

const enumerateDatasetsFully = async (
  streamingServiceClient: DatasetStreamingServiceClient,
  streamingParams: StreamingServicePb.EnumerateDatasetsParameters
): Promise<StreamingServicePb.DatasetKeyDataList | undefined> => {
  return new Promise<StreamingServicePb.DatasetKeyDataList | undefined>((resolve, reject) => {
    const { response } = streamingServiceClient.enumerateDatasets(streamingParams);
    response
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject('err');
      });
  });
};

const getStreamingChartDatasetParamsFromDatasetDescription = async (
  streamingServiceClient: DatasetStreamingServiceClient,
  streamingParams: StreamingServicePb.EnumerateDatasetsParameters,
  streamingType: StreamingType,
  selectedDataset?: DatasetDescription
): Promise<StreamingChart.DatasetParameters[] | undefined> => {
  return new Promise<StreamingChart.DatasetParameters[] | undefined>((resolve, reject) => {
    const { response } = streamingServiceClient.enumerateDatasets(streamingParams);
    response
      .then((res) => {
        const SC_datasetParams: StreamingChart.DatasetParameters[] = [];
        for (const datasetItem of res.items) {
          const locator = datasetItem.locator;
          if (locator !== undefined) {
            const isProcessing = streamingType === 'Processing';
            const isMeasurement =
              streamingType === 'Measurement' && selectedDataset && isSelectedDataset(selectedDataset, datasetItem);
            const isTracking = streamingType === 'Tracking';
            if (isProcessing || isMeasurement || isTracking) {
              SC_datasetParams.push(SCDatasetParamsFromKeyDataListItem(datasetItem));
            }
          }
        }
        resolve(SC_datasetParams.length > 0 ? SC_datasetParams : undefined);
      })
      .catch((err) => {
        reject('err');
      });
  });
};

export const SCDatasetParamsFromKeyDataListItem = (
  datasetItem: StreamingServicePb.DatasetKeyDataList_Item
): StreamingChart.DatasetParameters => {
  return {
    id: datasetItem.locator!.id,
    label: datasetItem.keydata!.labelDir!.label,
    direction: datasetItem.keydata!.labelDir!.direction as StreamingChart.Direction,
    rawDataType: datasetItem.keydata!.type,
    type: extractDatasetTypeFromLocatorId(datasetItem.locator?.id)
  };
};

export const isSelectedDatasetByDatasetParams = (
  selectedDataset: DatasetDescription,
  datasetParams: StreamingChart.DatasetParameters
): boolean => {
  const label = datasetParams.label;
  const direction = datasetParams.direction;
  if ('attributes' in selectedDataset) {
    const attributes = selectedDataset['attributes'];
    let datasetsMatch = true;

    if ('name' in attributes) {
      datasetsMatch = datasetsMatch && attributes['name'] === label;
    } else {
      datasetsMatch = false;
    }
    if ('direction' in attributes) {
      datasetsMatch = datasetsMatch && attributes['direction'] === direction;
    } else {
      datasetsMatch = false;
    }
    if ('datatype' in attributes) {
      datasetsMatch = datasetsMatch && attributes['datatype'] === extractDatasetTypeFromLocatorId(datasetParams.id);
    } else {
      datasetsMatch = false;
    }
    return datasetsMatch;
  }

  return false;
};

export const isSelectedDataset = (
  selectedDataset: DatasetDescription,
  dataset: StreamingServicePb.DatasetKeyDataList_Item
): boolean => {
  const label = dataset.keydata?.labelDir?.label;
  const direction = dataset.keydata?.labelDir?.direction;
  if ('attributes' in selectedDataset) {
    const attributes = selectedDataset['attributes'];
    let datasetsMatch = true;

    if ('name' in attributes) {
      datasetsMatch = datasetsMatch && attributes['name'] === label;
    } else {
      datasetsMatch = false;
    }
    if ('direction' in attributes) {
      datasetsMatch = datasetsMatch && attributes['direction'] === direction;
    } else {
      datasetsMatch = false;
    }
    if ('datatype' in attributes) {
      datasetsMatch = datasetsMatch && attributes['datatype'] === extractDatasetTypeFromLocatorId(dataset.locator?.id);
    } else {
      datasetsMatch = false;
    }
    return datasetsMatch;
  }

  return false;
};

const extractDatasetTypeFromLocatorId = (id?: string): string => {
  if (id) {
    const index1 = id.lastIndexOf('[');
    const index2 = id.lastIndexOf(']');
    const dt = id.substring(index1 + 1, index2);
    return dt;
  }
  return 'Unknown';
};

// FIXME: Mapping seems smelly
const determineChartType = (dataType: DataType): StreamingChart.ChartType => {
  // TODO: ChartType needs to be able to handle cases:
  // Also properly handle Track / Führungsgröße, may be necessary to rethink 1:1 relation
  switch (dataType) {
    case DataType.Type_TachoEdges:
      return StreamingChart.ChartType.CHART2DTacho;

    case DataType.Type_TimeSeries:
    case DataType.Type_SlowQuantity:
      return StreamingChart.ChartType.CHART2DNonEqui;
  }
  return StreamingChart.ChartType.CHART2DEqui;
};

export const getDbRefAndDbFactor = (parameterFor3dPlot: StreamingChart.PlottingParameters | undefined) => {
  const isSquared = parameterFor3dPlot?.effectiveIsSquared ?? parameterFor3dPlot?.isSquared ?? false;
  const isPowerType = parameterFor3dPlot?.isPowerType;

  // NOTE: I wrote this code against my will.
  const isAccelerationQuantity =
    parameterFor3dPlot?.quantityY?.rawQuantity.meterExponent === 1 &&
    parameterFor3dPlot?.quantityY?.rawQuantity.meterExponentDenominator === 1 &&
    parameterFor3dPlot?.quantityY?.rawQuantity.secondExponent === -2 &&
    parameterFor3dPlot?.quantityY?.rawQuantity.secondExponentDenominator === 1 &&
    parameterFor3dPlot?.quantityY?.rawQuantity.kilogramExponent === 0 &&
    parameterFor3dPlot?.quantityY?.rawQuantity.kilogramExponentDenominator === 1 &&
    parameterFor3dPlot?.quantityY?.rawQuantity.ampereExponent === 0 &&
    parameterFor3dPlot?.quantityY?.rawQuantity.ampereExponentDenominator === 1 &&
    parameterFor3dPlot?.quantityY?.rawQuantity.kelvinExponent === 0 &&
    parameterFor3dPlot?.quantityY?.rawQuantity.kelvinExponentDenominator === 1 &&
    parameterFor3dPlot?.quantityY?.rawQuantity.molExponent === 0 &&
    parameterFor3dPlot?.quantityY?.rawQuantity.molExponentDenominator === 1 &&
    parameterFor3dPlot?.quantityY?.rawQuantity.candelaExponent === 0 &&
    parameterFor3dPlot?.quantityY?.rawQuantity.candelaExponentDenominator === 1;

  let dbRef = 1.0;
  if (isAccelerationQuantity && parameterFor3dPlot.defaultAccelerationdBreferenceFactor) {
    dbRef = parameterFor3dPlot.defaultAccelerationdBreferenceFactor;
  } else if (parameterFor3dPlot?.quantityY?.dbReferenceFactor) {
    dbRef = parameterFor3dPlot.quantityY.dbReferenceFactor;
  }

  if (isSquared) {
    dbRef = dbRef * dbRef;
  }
  const dbFactor = isSquared || isPowerType ? 10.0 : 20.0;
  return { dbRef, dbFactor };
};

const extractReferencedInfo = (
  referencedInfo: ReferencedInfo,
  quantities: Quantities | undefined
): StreamingChart.ReferenceDataset | undefined => {
  if (referencedInfo) {
    const refChannelId = referencedInfo.channelId;
    const refPosition = deserializePosition(referencedInfo.position);
    const refquantityY1Id = referencedInfo.quantityY1Id;
    const refquantityY1 = deserializeQuantity(
      quantities?.quantities.filter((quantity) => quantity.subscribeId === refquantityY1Id)[0]
    );
    const refquantityY2Id = referencedInfo?.quantityY2Id;
    const refquantityY2 = deserializeQuantity(
      quantities?.quantities.filter((quantity) => quantity.subscribeId === refquantityY2Id)[0]
    );
    const varPosMode = referencedInfo.varPosMode;

    if (refPosition === undefined || refquantityY1 === undefined || refquantityY2 === undefined) {
      return undefined;
    } else {
      const referenceDataset = {
        refChannelId,
        refPosition,
        refquantityY1,
        refquantityY2,
        isvVarPosMode: varPosMode !== 0
      };
      return referenceDataset;
    }
  }
};

const extract2dPlottingParams = (
  datasetParams: StreamingChart.DatasetParameters,
  metadata?: DatasetTwoDimensionalMetaData,
  quantities?: Quantities
): StreamingChart.PlottingParameters => {
  const plottingParameters: StreamingChart.PlottingParameters = {
    chartType: determineChartType(datasetParams.rawDataType)
  };
  if (metadata) {
    datasetParams.position = deserializePosition(metadata.position);
    plottingParameters.nPoints = Number(metadata.npoints);

    plottingParameters.freqWeight = extractFreqWeight(metadata.freqWeight);

    const quantityXid = metadata.quantityXId;
    plottingParameters.quantityX = deserializeQuantity(
      quantities?.quantities.filter((quantity) => quantity.subscribeId === quantityXid)[0]
    );
    const quantityYid = metadata.quantityYId;
    plottingParameters.quantityY = deserializeQuantity(
      quantities?.quantities.filter((quantity) => quantity.subscribeId === quantityYid)[0]
    );

    if (metadata.referencedInfo) {
      datasetParams.referenceDataset = extractReferencedInfo(metadata.referencedInfo, quantities);
    }

    plottingParameters.rangeY = {
      start: metadata.scaleRangeY?.start,
      stop: metadata.scaleRangeY?.stop
    };
    plottingParameters.measZeroPoint = convertAbsoluteTimeToInt128(metadata?.measurementStartTime?.utcTime);

    plottingParameters.isCompressed = false;

    const scaleXOneOfKind = metadata.scaleX['oneofKind'];
    if (scaleXOneOfKind === 'equiTimeScale') {
      plottingParameters.timeScale = getTimeScale(metadata.scaleRangeX, metadata.scaleX['equiTimeScale']);
      const srNominator = metadata.scaleX['equiTimeScale'].samplingRateNominator;
      const srDenominator = metadata.scaleX['equiTimeScale'].samplingRateDenominator;
      plottingParameters.deltaX = srDenominator / srNominator;
      plottingParameters.significantDeltaX = determineSignificantDigits(plottingParameters.deltaX);
    } else if (scaleXOneOfKind === 'compressedEquiTimeScale') {
      plottingParameters.timeScale = getTimeScale(metadata.scaleRangeX, metadata.scaleX['compressedEquiTimeScale']);
      plottingParameters.isCompressed = true;
      const srNominator = metadata.scaleX['compressedEquiTimeScale'].samplingRateNominator;
      const srDenominator = metadata.scaleX['compressedEquiTimeScale'].samplingRateDenominator;
      plottingParameters.deltaX = srDenominator / srNominator;
      plottingParameters.significantDeltaX = determineSignificantDigits(plottingParameters.deltaX);
    }
  }
  return plottingParameters;
};

const extract3dPlottingParams = (
  datasetParams: StreamingChart.DatasetParameters,
  metadata?: DatasetThreeDimensionalMetaData,
  quantities?: Quantities
): StreamingChart.PlottingParameters => {
  const plottingParameters: StreamingChart.PlottingParameters = { chartType: StreamingChart.ChartType.CHART3D };
  if (metadata) {
    plottingParameters.hasOctave = metadata.typeSpecificData['octaveParameters'];
    if (plottingParameters.hasOctave) {
      plottingParameters.octaveNth = metadata.typeSpecificData['octaveParameters'].octaveNth;
    }

    plottingParameters.freqWeight = extractFreqWeight(metadata.freqWeight);
    // console.log('metadata', metadata);

    datasetParams.position = deserializePosition(metadata.position);
    const spectrumParameters = metadata.typeSpecificData['spectrumParameters'];
    // single order kalman with xNonequiValues order number array (e.g. [2, 3], y data chunks with interleaved complex real/imaginary values (e.g. [r1, i1, r2, i2, ...]))
    plottingParameters.rangeX = deserializeXValues(metadata.xValues);
    plottingParameters.rangeZ = deserializeDoubleScale(Number(metadata.numPotentialSteps), metadata.scaleRangeZ);
    plottingParameters.deltaX = deserializeStepDelta3D(metadata);

    const quantityXid = metadata.quantityXId;
    plottingParameters.quantityX = deserializeQuantity(
      quantities?.quantities.filter((quantity) => quantity.subscribeId === quantityXid)[0]
    );
    const quantityYid = metadata.quantityYId;
    plottingParameters.quantityY = deserializeQuantity(
      quantities?.quantities.filter((quantity) => quantity.subscribeId === quantityYid)[0]
    );
    const quantityZid = metadata.quantityZId;
    plottingParameters.quantityZ = deserializeQuantity(
      quantities?.quantities.filter((quantity) => quantity.subscribeId === quantityZid)[0]
    );

    if (metadata.referencedInfo) {
      datasetParams.referenceDataset = extractReferencedInfo(metadata.referencedInfo, quantities);
    }

    const shape = Number(metadata.shape);
    if (shape > 1) {
      // case 3D data as 2D: cut, overall, ...
      plottingParameters.chartType = StreamingChart.ChartType.CHART3D2D;
      if (metadata.quantityXId === undefined) {
        plottingParameters.quantityX = deserializeQuantity(
          quantities?.quantities.filter((quantity) => quantity.subscribeId === quantityZid)[0]
        );
        plottingParameters.quantityZ = undefined;
        plottingParameters.rangeX = plottingParameters.rangeZ;
        plottingParameters.rangeZ = undefined;
      }
    } else if (Number(metadata.numPotentialSteps) === 1) {
      // case 3D data as 2D: average spectrum
      plottingParameters.chartType = StreamingChart.ChartType.CHART3D2D;
    } else if (
      metadata.type === DataType.Type_MultipleSingleOrder ||
      metadata.type === DataType.Type_MultipleCrossOrder ||
      metadata.type === DataType.Type_MultiplePhaseRefOrder
    ) {
      // case 3D data as 2D: overall
      plottingParameters.chartType = StreamingChart.ChartType.CHART3D2D;
    }
    const rawDataFormat = spectrumParameters?.dataFormat;
    if (rawDataFormat === DataFormat.DF_Complex) {
      plottingParameters.effectiveDataFormat = DataFormat.DF_Mag; // calculate magnitude for display
    }
    plottingParameters.isComplex = rawDataFormat === DataFormat.DF_Complex ?? false;

    plottingParameters.isSquared = metadata.yDataIsSquared;
    plottingParameters.isPowerType = plottingParameters.quantityY?.isPowerType;
    if (datasetParams.rawDataType === DataType.Type_CPSSpectrum) {
      plottingParameters.isPowerType = true;
    } else if (
      datasetParams.rawDataType === DataType.Type_Psycho &&
      (metadata.typeSpecificData['psychoParameters']?.psychoType ===
        DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Tone2Noise_Ansi ||
        metadata.typeSpecificData['psychoParameters']?.psychoType ===
          DatasetThreeDimensionalMetaData_PsychoParams_PsychoType.Psycho_Prominence_Ansi)
    ) {
      plottingParameters.isPowerType = true;
    }
    plottingParameters.measZeroPoint = convertAbsoluteTimeToInt128(metadata?.measurementStartTime?.utcTime);

    // Extract quantity of referenced track channel
    plottingParameters.dataInfo = metadata.dataInfo;
  }
  return plottingParameters;
};

const getTimeScale = (
  timeRange?: AbsoluteTimeRange,
  timeScale?: EquiTimeScale
): StreamingChart.TimeScale | undefined => {
  if (!timeRange && !timeScale) {
    return undefined;
  }

  const result: StreamingChart.TimeScale = {};
  if (timeRange) {
    result.estimatedRange = {
      start: convertAbsoluteTimeToInt128(timeRange.start),
      stop: convertAbsoluteTimeToInt128(timeRange.stop)
    };
  }
  if (timeScale) {
    result.firstSampleTime = convertAbsoluteTimeToInt128(timeScale.firstSampleTime);
    result.samplingRate = timeScale.samplingRateNominator / timeScale.samplingRateDenominator;
  }

  return result;
};

export const createStreamingServiceClient = (serverUrl: string, abortController?: any) => {
  const options: GrpcWebOptions = {
    baseUrl: serverUrl,
    deadline: 360000,
    format: 'binary',
    abort: abortController?.signal
    // see `RpcInterceptor` documentation for how to add auth headers to each request
  };
  const transport = new GrpcWebFetchTransport(options);
  const client = new DatasetStreamingServiceClient(transport);
  return client;
};
