import { StreamingChart } from '../utils/streaming-chart.types';
import { AxisScalingTypes } from './lightningPlot.types';

export function getSuperscript(toSuper: number): string {
  if (!toSuper || Math.abs(toSuper) === Infinity) {
    return '';
  }
  let superscript = '';

  // check for negative values
  if (toSuper < 0) {
    superscript = superscript + '\u{207B}'; //printing "-" in superscript via unicode
    toSuper = Math.abs(toSuper);
  }

  //workaround for small numbers as they are converted to n e- m by toString()
  if (toSuper < 0.000001) {
    const base = Number(toSuper.toString().split('e')[0]);
    const rest = Number(toSuper.toString().split('e')[1].substring(1));
    //printing base and rest arround "e-" in superscript via unicode
    return getSuperscript(base) + '\u{1D49}\u{207B}' + getSuperscript(rest);
  } else {
    // tslint:disable-next-line:no-bitwise
    const length = Math.ceil(Math.log10(toSuper + 1));
    for (let i = 1; i <= length; i++) {
      // tslint:disable-next-line:no-bitwise
      const digit = (toSuper / Math.pow(10, length - i)) % 10 | 0;
      switch (digit) {
        // printing "case" in superscript via unicode
        case 0:
          superscript = superscript + '\u{2070}';
          break;
        case 1:
          superscript = superscript + '\u{00B9}';
          break;
        case 2:
          superscript = superscript + '\u{00B2}';
          break;
        case 3:
          superscript = superscript + '\u{00B3}';
          break;
        case 4:
          superscript = superscript + '\u{2074}';
          break;
        case 5:
          superscript = superscript + '\u{2075}';
          break;
        case 6:
          superscript = superscript + '\u{2076}';
          break;
        case 7:
          superscript = superscript + '\u{2077}';
          break;
        case 8:
          superscript = superscript + '\u{2078}';
          break;
        case 9:
          superscript = superscript + '\u{2079}';
          break;
      }
    }

    // check for decimal places
    if (toSuper % 1 !== 0 && toSuper !== 0) {
      superscript = superscript + '\u{22C5}'; // representing '.' in superscript via unicode
      let decimal = toSuper.toString().split('.')[1];

      for (let i = 0; i < 8 && decimal.charAt(0) === '0'; i++) {
        superscript = superscript + '\u{2070}'; // representing '0' in superscript via unicode
        decimal = decimal.substring(1);
      }

      if (decimal.charAt(0) !== '0') {
        superscript = superscript + getSuperscript(Number(decimal));
      } else if (decimal.charAt(1) !== '0') {
        superscript = superscript + ' \u{00B9}'; //just add 1
      }
    }
    return superscript;
  }
}
