<h6>
  {{ 'LICENSING.USERS' | translate }}
</h6>
<clr-datagrid class="users-datagrid" [clrDgLoading]="usersLoading$ | async">
  <clr-dg-column [clrDgSortBy]="'userName'" [clrDgSortOrder]="ascSort">{{
    'LICENSING.LICENSE_FIXED_NAMES.USER' | translate
  }}</clr-dg-column>
  <clr-dg-column>{{ 'LICENSING.GROUPS' | translate }}</clr-dg-column>
  <clr-dg-column>{{ 'LICENSING.ROLES' | translate }}</clr-dg-column>
  <clr-dg-column>{{ 'LICENSING.OBTAINMENT_RIGHTS' | translate }}</clr-dg-column>

  <clr-dg-row
    *clrDgItems="let user of users$ | async; trackBy: trackById"
    [clrDgExpanded]="user.id === rowExpanded"
    (click)="expandRow(user)"
    class="user-row"
    [clrDgItem]="user"
    [ngClass]="{ inactive: !user.isActive, expanded: user.id === rowExpanded }"
  >
    <clr-dg-cell class="name-cell">
      <div
        [ngClass]="{ expanded: user.id === rowExpanded }"
        [innerHTML]="user.userName | searchBarHighlightFilter : (searchFilter$ | async)!"
      ></div>
      <clr-icon
        *ngIf="user.hasVibroLicense"
        shape="crown"
        [title]="'LICENSING.VIBROLICENSE_MESSAGE' | translate"
      ></clr-icon>
      {{ !user.isActive ? ' (inactive)' : '' }}
    </clr-dg-cell>

    <clr-dg-cell class="groups-cell">
      <span
        *ngFor="let group of user.groups; trackBy: trackById"
        [innerHTML]="group | searchBarHighlightFilter : (searchFilter$ | async)!"
      ></span>
    </clr-dg-cell>
    <clr-dg-cell class="roles-cell">
      <span
        *ngFor="let efRole of removeDuplicateRoles(getAllRolesForUser(user)) | async; trackBy: trackById"
        [innerHTML]="efRole.role.name | searchBarHighlightFilter : (searchFilter$ | async)!"
      ></span>
    </clr-dg-cell>
    <clr-dg-cell class="licenses-cell" *ngIf="!user.hasVibroLicense">
      <clr-tooltip *ngFor="let license of lookUpAndSortLicensePills(user.licenses); trackBy: trackById">
        <span class="license-pill" clrTooltipTrigger>
          <ng-container
            *ngIf="lookupLicenseNames(license.id, { includePeriod: true, includeUserType: true }) as licenseName"
          >
            <cloud-license-icon [license]="license"></cloud-license-icon>
            <span [innerHTML]="licenseName | searchBarHighlightFilter : (searchFilter$ | async)!"></span>
          </ng-container>
        </span>
        <clr-tooltip-content [clrPosition]="'top-left'" [clrSize]="'lg'" class="cloud-tooltip">
          <p *ngFor="let role of license.roles" class="role-tooltip"
            ><span>{{ role.role.name }} (role)</span>
            <cloud-svg-icon fileName="union" *ngIf="getGroupNamesForEfRole(role)?.length"></cloud-svg-icon>
            <span>{{ getGroupNamesForEfRole(role) }}</span></p
          >
        </clr-tooltip-content>
      </clr-tooltip>
    </clr-dg-cell>
    <clr-dg-cell class="licenses-cell" *ngIf="user.hasVibroLicense">
      <span class="vibro-license">{{ 'LICENSING.VIBROLICENSE_MESSAGE' | translate }}</span>
    </clr-dg-cell>

    <cloud-user-details
      *clrIfExpanded
      ngProjectAs="clr-dg-row-detail"
      [licenses]="lookUpAndSortLicensePills(user.licenses)"
      [user]="user"
      [(subMenuState)]="subMenuState"
      (click)="cancelBubble($event)"
    ></cloud-user-details>
  </clr-dg-row>

  <clr-dg-footer>{{ (users$ | async)?.length }} {{ 'LICENSING.USERS' | translate }}</clr-dg-footer>
</clr-datagrid>
