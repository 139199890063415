<h3>{{ 'APP.PRIVACY' | translate }}</h3>

<div x-ms-format-detection="phone" class="clr-row">
  <div class="clr-col-xl-8 clr-col-12">
    <div class="card">
      <div class="card-block">
        <div class="card-text">
          <div *cloudMultiLang="'NOT de'">
            <h3>Data protection</h3>

            <p>
              Protecting your personal data is very important to Müller&#45;BBM VibroAkustik Systeme GmbH.
              Müller&#45;BBM VibroAkustik Systeme GmbH processes personal data in accordance with applicable data
              protection laws and other legal provisions, and only for restricted, explicit, and lawful purposes.
            </p>

            <p>
              The personal data that is processed is limited to the data that is required to achieve the business
              objective for which the personal data was collected. We normally collect personal data directly from data
              subjects. However, data may also be obtained from third parties in accordance with the statutory
              provisions.
            </p>

            <p>
              Use of the PAK cloud website is possible both with and without the provision of personal data. As far as
              is possible, we ensure that the personal data collected on our website can be provided by you on a
              voluntary basis.
            </p>

            <p>
              Please be advised that the transmission of data over the internet (e.g. communication via email) cannot be
              entirely secure. It is not possible to protect this data completely from access by third parties.
            </p>

            <h3>Name and address of Data Protection Officer</h3>

            <p>Data Protection Officer for Müller&#45;BBM VibroAkustik Systeme GmbH</p>
            <p>
              c/o activeMind AG<br />
              Potsdamer Str. 3<br />
              80802 Munich, Germany<br />
              Tel. <a HREF="tel:+49 89 418560170">+49 89 418560170</a><br />
              <a HREF="mailto:datenschutz@mbbm-vas.com">datenschutz@mbbm-vas.com</a>
            </p>
            <p>
              Any data subject may contact our Data Protection Officer directly with questions and suggestions
              concerning data protection at any time or contact us by email.
            </p>
            <h3>Information on collection and use of data</h3>

            <p>
              Every time that you visit the PAK cloud website, we collect certain information. This information is used
              for the purposes listed below.
            </p>

            <h3>Information collected automatically</h3>

            <p>
              If you use the PAK cloud website without registering, you do not have to actively provide any personal
              data; however, we collect and process the following information automatically:
            </p>

            <ul>
              <li>technical data about the session and connection, including the IP address of your device;</li>
              <li>browser type;</li>
              <li>information about your device, hardware, and software;</li>
              <li>user settings;</li>
              <li>date and time of your visit.</li>
            </ul>

            <p>
              We process and use the aforementioned data for the following purposes: (1) providing the website and its
              functions; (2) identifying and preventing website misuse and malfunction.
            </p>

            <p>
              The collection of this type of information is often aided by cookies, the nature and use of which are
              explained in the “Cookies” section.
            </p>

            <h3>Account creation and registration</h3>

            <p>
              If you create a user account for the PAK cloud, we collect the following data, which you provide as part
              of the registration process:
            </p>

            <ul>
              <li>User name</li>
              <li>First name and surname</li>
              <li>Email address</li>
            </ul>

            <p>
              We process and use the aforementioned data for the following purposes: (1) checking whether you meet the
              prerequisites for a PAK cloud account, and for managing the account; (2) meeting the terms of use for the
              website and any rights and duties contained therein; (3) communicating with you by sending technical
              information, updates, security advice, support and administration messages, answering your questions, and
              providing customer service.
            </p>

            <p>
              The processing and use of data is based upon legal provisions that permit these actions because: (1) the
              processing is necessary in order to comply with the contract, including the terms of use for the PAK cloud
              website; (2) Müller&#45;BBM VibroAkustik Systeme has a legitimate interest in such data in order to secure
              the functionality of the PAK cloud website.
            </p>
            <p>
              When you create a user account for the PAK cloud website, or log in through a user account, we use your
              login details (user name and password) to provide and manage your access to the PAK cloud website and, if
              applicable, to other online services provided by Müller-BBM VibroAkustik Systeme GmbH or by third parties
              that you can access via the PAK cloud website. The information (user name and password) that you enter
              during registration will be processed in order to check and confirm your authorization.
            </p>
            <h3>Cookies</h3>

            <p>
              We use cookies – a standard internet technology – to store and access login and other usage information
              for all users of the PAK cloud website. “Cookies” are small text files that your web browser saves on your
              computer’s hard disk. They enable the saving of user settings, among other things. The website uses both
              session cookies and persistent cookies. Session cookies are temporary cookies that expire (and are
              automatically deleted) when you close your browser. Persistent cookies remain in your browser until they
              expire, or until you delete them.
            </p>
            <p>
              You can set your browser to always inform you about the placement of cookies and allow you to decide case
              by case to accept cookies, to accept cookies in specific cases or disable cookies in general, and to
              automatically delete cookies upon closing the browser. Disabling cookies can restrict the functionality of
              this website.
            </p>
            <h3>Server log files</h3>
            <p>
              The provider of the PAK cloud website automatically collects and saves information in server log files
              that your browser automatically transmits to us, including:
            </p>
            <ul>
              <li>browser type and browser version;</li>
              <li>operating system used;</li>
              <li>referrer URL;</li>
              <li>host name of the accessing computer; and</li>
              <li>time of the server request.</li>
            </ul>

            <p>
              This data cannot be attributed to a specific person. This data will not be merged with other data sources.
              We reserve the right to check this data at a later time, should there be concrete evidence of illegal use.
            </p>

            <h3>Cloud log files and backup copies</h3>
            <p>
              The PAK cloud installation on the PAK cloud website automatically collects and stores information in dash
              log files that your browser automatically transmits to us.
            </p>
            <ul>
              <li>Cloud server protocol</li>
            </ul>

            <p>
              Usage data is also collected during your use of the PAK cloud. This data allows us to identify and remedy
              any errors quickly, and to continue developing our products. The usage data collected is deleted
              automatically after 7 days.
            </p>

            <h3>SSL encryption</h3>

            <p>
              For reasons of security, and in order to safeguard the transmission of confidential content, such as
              inquiries that you send to us as the operator of the website, this website uses SSL encryption. You can
              identify an encrypted connection by the fact that the address bar in your browser changes from “http://”
              to “https://” and displays a lock symbol.
            </p>
            <p>When SSL encryption is activated, the data that you transmit to us cannot be read by third parties.</p>
            <h3>Recipient of personal data</h3>

            <p>
              The recipient of personal data is Müller&#45;BBM VibroAkustik Systeme GmbH, for the aforementioned
              purposes.
            </p>

            <p>
              Authorization to access personal data is always issued on a functional basis, meaning that authorization
              to access personal data on personal grounds is never granted. Service providers and processors shall only
              receive personal data for the purposes set out in their service or processing contract with Müller&#45;BBM
              VibroAkustik Systeme.
            </p>

            <h3>Transmission of data to third parties</h3>
            <p>
              The provider of the cloud server and web hosting for the PAK cloud website shall primarily provide the
              contractually agreed data processing in a member state of the European Union (EU), or in another state
              that is party to the Agreement on the European Economic Area (EEA). However, in individual cases this may
              also take place in a third country.
            </p>
            <p>
              Furthermore, the provider may commission affiliated or external companies that are based in member states
              of the EU or of the EEA and/or in third countries to maintain and update the computing center structure,
              telecommunications services, or user service in order to ensure rendering of the contractually agreed
              services. These sub-contractors shall be updated on a quarterly basis and made accessible to Müller-BBM
              VibroAkustik Systeme GmbH through the provider’s customer portal.
            </p>
            <p>
              Any transmission of your personal data to third countries (i.e. countries that are not members of the
              EU/EEA) shall take place in accordance with the applicable data protection laws. These countries may be
              subject to different data protection standards.
            </p>

            <p>
              A processing contract pursuant to Art. 28 GDPR has been concluded with the provider of the cloud server
              and the web hosting for the PAK cloud website.
            </p>

            <h3>Retention of personal data</h3>

            <p>
              Müller&#45;BBM VibroAkustik Systeme GmbH shall retain your personal data for no longer than is permitted
              by the applicable data protection laws or other legal provisions, or for no longer than is justified for
              the purposes for which the data was originally collected. The collected data shall generally be deleted as
              soon as the business relationship with the customer/business partner ceases to exist.
            </p>

            <p>However, collected data may be subject to a retention period under the applicable legal provisions.</p>

            <h3>Right to information, erasure, blocking</h3>

            <p>
              Alongside your right to information, you are entitled to be notified about the data stored on you, to have
              your data erased, and to have your data blocked. In addition, you may object to processing of your data at
              any time.
            </p>

            <p>
              You can contact us at any time in regard to matters concerning personal data by using the address provided
              in the legal notice, or via our Data Protection Officer.
            </p>

            <h3>Changes to this privacy policy</h3>
            <p>
              Müller-BBM VibroAkustik Systeme GmbH reserves the right to change this privacy policy so that it always
              complies with current legal requirements, or in order to reflect changes to our services in the privacy
              policy. The new privacy policy shall apply if you visit the PAK cloud website again.
            </p>
          </div>

          <div *cloudMultiLang="'de'">
            <h3>Datenschutz</h3>

            <p>
              Der Schutz Ihrer persönlichen Daten hat für die Müller-BBM VibroAkustik Systeme GmbH einen hohen
              Stellenwert. Müller-BBM VibroAkustik Systeme GmbH verarbeitet personenbezogene Daten unter Einhaltung der
              geltenden Datenschutzgesetze und sonstigen Rechtsvorschriften sowie nur zu begrenzten, expliziten und
              rechtmäßigen Zwecken.
            </p>

            <p>
              Die verarbeiteten personenbezogenen Daten beschränken sich auf die Daten, die zur Umsetzung des
              Geschäftszweckes notwendig sind, für den diese personenbezogenen Daten erfasst werden. Wir erfassen
              personenbezogene Daten grundsätzlich direkt von den betroffenen Personen. Daten können jedoch unter
              Einhaltung der gesetzlichen Bestimmungen ebenfalls von Dritten gesammelt werden.
            </p>

            <p>
              Die Nutzung der PAK cloud-Website ist mit und ohne Angabe personenbezogener Daten möglich. Soweit auf
              unseren Seiten personenbezogene Daten erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger
              Basis.
            </p>

            <p>
              Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
              Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
              möglich.
            </p>

            <h3>Name und Anschrift des Datenschutzbeauftragten</h3>

            <p>Datenschutzbeauftragter der Müller-BBM VibroAkustik Systeme GmbH</p>

            <p>
              c/o activeMind AG<br />
              Potsdamer Str. 3<br />
              80802 München<br />
              Tel. <a HREF="tel:+49 89 418560170">+49 89 418560170</a><br />
              <a HREF="mailto:datenschutz@mbbm-vas.com">datenschutz@mbbm-vas.com</a>
            </p>

            <p>
              Jede betroffene Person kann sich jederzeit bei allen Fragen und Anregungen zum Datenschutz direkt an
              unseren Datenschutzbeauftragten wenden oder uns per E-Mail kontaktieren.
            </p>

            <h3>Informationen zur Erhebung und Nutzung von Daten</h3>

            <p>
              Jedes Mal, wenn Sie die PAK cloud-Webseite besuchen, sammeln wir bestimmte Informationen. Diese
              Informationen werden für die im Folgenden aufgelisteten Zwecke verwendet.
            </p>

            <h3>Automatisch gesammelte Informationen</h3>

            <p>
              Bei der Nutzung PAK cloud-Website ohne Registrierung müssen Sie aktiv keine personenbezogenen Daten
              angeben; wir erheben und verarbeiten jedoch folgende Informationen automatisch:
            </p>

            <ul>
              <li>technische Daten zur Sitzung und Verbindung einschließlich der IP-Adresse Ihres Geräts</li>
              <li>Browsertyp</li>
              <li>Informationen über Ihr Gerät, Hardware und Software</li>
              <li>Benutzereinstellungen</li>
              <li>Datum und Uhrzeit Ihres Besuchs</li>
            </ul>

            <p>
              Die oben angegebenen Daten verarbeiten und nutzen wir für folgende Zwecke: (1) Zurverfügungstellung der
              Webseite und ihrer Funktionen; (2) Erkennen und Verhindern von Missbrauch und Fehlfunktionen der Webseite.
            </p>

            <p>
              Die Sammlung dieser Art von Informationen erfolgt unter anderem mit Hilfe von Cookies, deren Art und
              Anwendung im Kapitel "Cookies" erläutert werden.
            </p>

            <h3>Kontoerstellung und Anmeldung</h3>

            <p>
              Wenn Sie ein Benutzerkonto für die PAK cloud erstellen, erheben wir folgende von Ihnen angegebene Daten im
              Rahmen des Registrierungsprozesses:
            </p>

            <ul>
              <li>Nutzername</li>
              <li>Vor- und Nachname</li>
              <li>E-Mail-Adresse</li>
            </ul>

            <p>
              Wir verarbeiten und nutzen die oben genannten Daten für folgende Zwecke: (1) Überprüfung, ob Sie die
              Voraussetzungen für ein PAK cloud-Konto erfüllen, und Verwaltung dieses Kontos, (2) Erfüllung der
              Nutzungsbedingungen für die Webseite und jeglicher darin enthaltenen Rechte und Pflichten, (3)
              Kommunikation mit Ihnen durch das Versenden von technischen Informationen, Aktualisierungen,
              Sicherheitshinweisen, Support- und Administrationsmeldungen, die Beantwortung Ihrer Fragen und die
              Bereitstellung von Kundenservice.
            </p>

            <p>
              Die Datenverarbeitung und -nutzung basiert auf Rechtsvorschriften, die diese zulassen, da (1) die
              Verarbeitung zur Erfüllung des Vertrags einschließlich Nutzungsbedingungen für die PAK cloud-Webseite
              notwendig ist oder (2) Müller-BBM VibroAkustik Systeme ein legitimes Interesse daran hat, die
              Funktionalität der PAK cloud-Webseite sicherzustellen.
            </p>

            <p>
              Wenn Sie ein Benutzerkonto für die PAK cloud-Webseite erstellen oder sich mit einem solchen anmelden,
              verwenden wir Ihre Login-Daten (Benutzername und Passwort) dafür, Ihnen Zugang zu der PAK cloud-Webseite
              und, falls zutreffend, zu weiteren Online-Diensten durch Müller-BBM VibroAkustik Systeme GmbH oder Dritte,
              auf welche Sie über die PAK cloud-Webseite zugreifen können, zu gewähren und diesen Zugang zu verwalten.
              Die von Ihnen bei der Registrierung eingegebenen Informationen (Benutzername und Passwort) werden
              verarbeitet, um Ihre Berechtigung zu prüfen und zu bestätigen.
            </p>

            <h3>Cookies</h3>

            <p>
              Wir verwenden Cookies, eine Standard-Internettechnologie, zur Speicherung und zum Abrufen von Login- und
              anderen Nutzungsinformationen für alle Benutzer der PAK cloud-Website. „Cookies“ sind kleine Textdateien,
              die von Ihrem Webbrowser auf der Festplatte Ihres Computers gespeichert werden. Sie ermöglichen es, u. a.
              Benutzereinstellungen zu speichern. Die Website benutzt sowohl Session-Cookies als auch dauerhafte
              Cookies. Session-Cookies sind temporäre Cookies, die ablaufen (und automatisch gelöscht werden), wenn Sie
              Ihren Browser schließen. Dauerhafte Cookies verbleiben in Ihrem Brow-ser bis sie ablaufen oder bis Sie
              diese löschen.
            </p>

            <p>
              Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies
              nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
              das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von
              Cookies kann die Funktionalität dieser Website eingeschränkt sein.
            </p>

            <h3>Server-Log-Files</h3>

            <p>
              Der Provider der PAK cloud-Webseite erhebt und speichert automatisch Informationen in Server-Log Files,
              die Ihr Browser automatisch an uns übermittelt. Dies sind:
            </p>

            <ul>
              <li>Browsertyp und Browserversion</li>
              <li>verwendetes Betriebssystem</li>
              <li>Referrer URL</li>
              <li>Hostname des zugreifenden Rechners</li>
              <li>Uhrzeit der Serveranfrage</li>
            </ul>

            <p>
              Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen
              Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns
              konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.
            </p>

            <h3>Cloud-Log-Files und Sicherungskopien</h3>

            <p>
              Die PAK cloud Installation auf der PAK cloud-Webseite erhebt und speichert automatisch Informationen in
              Dash-Log Files, die Ihr Browser automatisch an uns übermittelt.
            </p>

            <ul>
              <li>Cloud-Server-Protokoll</li>
            </ul>

            <p>
              Darüber hinaus werden während der Nutzung der PAK cloud Nutzungsdaten erfasst. Mit diesen Daten ist es uns
              möglich, auftretende Fehler schnell zu erkennen und zu beheben und unsere Produkte weiterzuentwickeln. Die
              erhobenen Nutzungsdaten werden automatisch nach 7 Tagen gelöscht.
            </p>

            <h3>SSL-Verschlüsselung</h3>

            <p>
              Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher Inhalte, wie zum
              Beispiel der Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-Verschlüsselung. Eine
              verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von "http://" auf
              "https://" wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
            </p>

            <p>
              Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von
              Dritten mitgelesen werden.
            </p>

            <h3>Empfänger personenbezogener Daten</h3>

            <p>
              Empfänger der personenbezogenen Daten ist die Müller-BBM VibroAkustik Systeme GmbH zu den vorgenannten
              Zwecken.
            </p>

            <p>
              Die Berechtigung zum Zugriff auf personenbezogene Daten wird immer funktionsbezogen erteilt, so dass keine
              Berechtigungen zum Zugriff auf personenbezogene Daten auf persönlicher Grundlage gewährt werden.
              Dienstleister und Auftragsverarbeiter erhalten personenbezogene Daten nur gemäß der in ihrem jeweiligen
              Dienstleistungs- bzw. Auftragsverarbeitungsvertrag mit Müller-BBM VibroAkustik Systeme vorgesehenen
              Zwecke.
            </p>

            <h3>Weitergabe von Daten an Dritte</h3>

            <p>
              Der Provider des Cloud Servers und des Webhostings für die PAK cloud-Webseite erbringt die vertraglich
              vereinbarte Datenverarbeitung überwiegend in einem Mitgliedstaat der Europäischen Union (EU) oder einem
              anderen Vertragsstaat des Abkommens über den Europäischen Wirtschaftsraum (EWR). In einzelnen Fällen kann
              dies auch in einem Drittstaat stattfinden.
            </p>

            <p>
              Darüber hinaus kann der Provider für die Erbringung der vertraglich vereinbarten Leistungen verbundene
              oder fremde Unternehmen, ansässig in Mitgliedstaaten der EU und des EWR bzw. Drittstaaten, zur Wartung und
              Pflege der Rechenzentrumsstruktur, Telekommunikationsdienstleistungen und Benutzerservice hinzuziehen.
              Diese Unterauftragnehmer werden quartalsweise aktualisiert und der Müller-BBM VibroAkustik Systeme GmbH
              durch das Kundenportal des Providers zum Abruf zur Verfügung gestellt.
            </p>

            <p>
              Jede Übermittlung Ihrer personenbezogenen Daten in Drittländer (d. h. in Länder, die nicht Mitglied der
              EU/des EWR sind) geschieht unter Einhaltung der geltenden Datenschutzgesetze. In diesen Ländern können
              ggf. andere Datenschutzstandards gelten.
            </p>

            <p>
              Mit dem Provider des Cloud Servers und des Webhostings der PAK cloud-Webseite ist ein entsprechender
              Vertrag zur Auftragsverarbeitung gemäß Art. 28 DSGVO geschlossen worden.
            </p>

            <h3>Aufbewahrung personenbezogener Daten</h3>

            <p>
              Müller-BBM VibroAkustik Systeme GmbH bewahrt Ihre personenbezogenen Daten nicht länger auf, als gemäß den
              geltenden Datenschutzgesetzen und sonstigen Rechtsvorschriften zulässig bzw. nicht länger als es für die
              Zwecke gerechtfertigt ist, für die diese Daten ursprünglich erhoben wurden. Grundsätzlich werden die
              erhobenen Daten gelöscht, sobald mit dem Kunden/Geschäftspartner keine Geschäftsbeziehung mehr besteht.
            </p>

            <p>
              Erhobene Daten können jedoch gemäß geltender Rechtsbestimmungen einer Aufbewahrungspflicht unterliegen.
            </p>

            <h3>Recht auf Auskunft, Löschung, Sperrung</h3>

            <p>
              Neben dem Auskunftsrecht steht Ihnen ein Benachrichtigungsrecht bezüglich der über Sie gespeicherten
              Daten, ein Löschungsrecht und ein Sperrungsrecht Ihrer Daten zu. Des Weiteren können Sie jederzeit gegen
              diese Verarbeitung Widerspruch einlegen.
            </p>

            <p>
              Zu allen Fragen zum Thema "Personenbezogene Daten" können Sie sich jederzeit unter der im Impressum
              angegebenen Adresse oder über unseren Datenschutzbeauftragten an uns wenden.
            </p>

            <h3>Änderung dieser Datenschutzbestimmungen</h3>

            <p>
              Müller-BBM VibroAkustik Systeme GmbH behält sich vor, diese Datenschutzerklärung anzupassen, damit sie
              stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der
              Datenschutzerklärung umzusetzen. Für den erneuten Besuch der PAK cloud-Webseite gilt dann die neue
              Datenschutzerklärung.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
