import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PanelContentTreeNode } from '../../panel-detail-content.component';

@Component({
  selector: 'cloud-panel-order-content-tree-node',
  templateUrl: './panel-content-tree-node.component.html',
  styleUrls: ['./panel-content-tree-node.component.css']
})
export class PanelContentTreeNodeComponent {
  @Input() node: PanelContentTreeNode;
  @Input() title: string;
  @Output() nodeSelected: EventEmitter<PanelContentTreeNode> = new EventEmitter<PanelContentTreeNode>();
  @Output() nodeExpanded: EventEmitter<PanelContentTreeNode> = new EventEmitter<PanelContentTreeNode>();
  @Output() nodeCollapsed: EventEmitter<PanelContentTreeNode> = new EventEmitter<PanelContentTreeNode>();

  constructor() {}

  toggleExpansion() {
    this.node.expanded = this.node.expanded ? false : true;
  }

  nodeClicked(node: PanelContentTreeNode) {
    if (node.content?.hasContent) {
      this.nodeSelected.emit(this.node);
    } else {
      this.toggleExpansion();
    }
  }
}
