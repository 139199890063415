import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRoutingModule } from './user-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { translateLoader } from '../app.translate';

// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { USER_FEATURE_KEY, initialState as userInitialState, reducer as userReducer } from './+state/user.reducer';
import * as UserEffects from './+state/user.effects';
import { UserFacade } from './+state/user.facade';

export const imports = [
  CommonModule,
  UserRoutingModule,
  TranslateModule.forChild(translateLoader),
  StoreModule.forFeature(USER_FEATURE_KEY, userReducer, { initialState: userInitialState }),
  EffectsModule.forFeature(UserEffects)
];

@NgModule({
  imports: imports,
  declarations: [],
  exports: [],
  providers: [UserFacade]
})
export class UserModule {}
