import { Component, OnInit } from '@angular/core';
import { MeasurementsFacade } from '../../+state/measurements.facade';
import { TranslateService } from '@ngx-translate/core';
import { DepotSearchFacade } from '../../../depot-search/+state/depot-search.facade';
import { FindAttributeByIdPipe } from '../../../shared/pipes/find-attribute-by-id.pipe';
import { TranslateAttributePipe } from '../../../shared/pipes/translate-attribute.pipe';
import { Observable } from 'rxjs';

import {
  PanelContentTree,
  PanelContentTreeNode
} from '@root/apps/cloud/src/app/shared/components/panel-detail-content/panel-detail-content.component';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { DepotAttribute } from '../../../shared/+state/attributes/attributes.types';
import { AttributesFacade } from '../../../shared/+state/attributes/attributes.facade';

@Component({
  selector: 'cloud-measurement-units-under-test',
  templateUrl: './measurement-units-under-test.component.html',
  styleUrls: ['./measurement-units-under-test.component.css']
})
export class MeasurementUnitsUnderTestComponent implements OnInit {
  trees$: Observable<PanelContentTree[]>;

  constructor(
    public measurementsFacade: MeasurementsFacade,
    public depotSearchFacade: DepotSearchFacade,
    public attributesFacade: AttributesFacade,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.trees$ = this.measurementsFacade.selectedMeasurement$.pipe(
      filter((meas) => !!meas),
      withLatestFrom(this.attributesFacade.availableDepotAttributes$),
      map(([meas, availableDepotAttribs]) => {
        const content = meas?.resources.unitsUnderTest;

        const tree: PanelContentTree = {
          name: content.typename ?? this.translate.instant('DEPOTSEARCH.UNIT_UNDER_TEST'),
          nodes: []
        };

        content.forEach((instance) => {
          const attribs = {};
          instance.attributes.forEach((attrib) => {
            let attribName = attrib.name;
            if (availableDepotAttribs) {
              attribName = this.translateAttributeName(
                attribName,
                'AoUnitUnderTest',
                availableDepotAttribs,
                meas!.measurementBrowseUrl
              );
            }
            attribs[attribName] = attrib.value;
          });

          const rootNode: PanelContentTreeNode = {
            name: instance['name'] as string,
            label: instance['name'] as string,
            expanded: false,
            selected: false,
            content: {
              title: instance['name'] as string,
              hasContent: true,
              keyValueList: attribs
            }
          };

          Object.keys(instance.parts).forEach((key) => {
            const part = instance.parts[key];

            const partAttribs = {};
            part.forEach((partAttrib) => {
              let partAttribName = partAttrib.name;
              if (availableDepotAttribs) {
                partAttribName = this.translateAttributeName(
                  partAttribName,
                  'AoUnitUnderTestPart',
                  availableDepotAttribs,
                  meas!.measurementBrowseUrl
                );
              }
              partAttribs[partAttribName] = partAttrib.value;
            });

            const node: PanelContentTreeNode = {
              name: key,
              label: key,
              expanded: false,
              selected: false,
              content: {
                title: key as string,
                hasContent: true,
                keyValueList: partAttribs
              }
            };
            if (!rootNode.childNodes) {
              rootNode.childNodes = [];
            }
            rootNode.childNodes.push(node);
          });
          tree.nodes.push(rootNode);
        });
        return [tree];
      })
    );
  }

  translateAttributeName(attributeName: string, aoBaseName: string, depotAttribs: DepotAttribute[], measPath: string) {
    const FindAttributePipe = new FindAttributeByIdPipe();

    const attributeById = FindAttributePipe.transform(depotAttribs, attributeName, aoBaseName, measPath);
    const TranslationPipe = new TranslateAttributePipe(this.translate);
    const translation = TranslationPipe.transform(attributeById);
    const idName = (attributeById as DepotAttribute).idName;
    if (idName === translation && idName !== attributeName) {
      // attribute does not have a translation but idName of attributeById is different from attributeName
      return attributeName;
    } else {
      return translation;
    }
  }
}
