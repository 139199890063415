import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { UserInfo } from './user/user.type';
import { initialUser } from './user/+state/user.reducer';
import { AppFacade } from './+state/app.facade';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { availableLanguagesStrings, availableLanguages, defaultLanguage } from './app.locales';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { auditTime } from 'rxjs/operators';
import { FeatureFlagsFacade } from '@vas/feature-flags';
import { CommonComponent, CommonFacade, UIDimensions, LS_usesMouse } from '@vas/common';
import { environment } from '../environments/environment';
import { WorkspaceFacade } from './workspace/+state/workspace.facade';
import { LS_lang } from './app.constants';

@Component({
  selector: 'cloud-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent extends CommonComponent implements OnInit, OnDestroy, AfterViewInit {
  user: UserInfo = initialUser;

  public listenForMouseEvents = true;

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  constructor(
    public appFacade: AppFacade,
    public workspaceFacade: WorkspaceFacade,
    public translate: TranslateService,
    public featureFlagFacade: FeatureFlagsFacade,
    public cookieService: CookieService,
    public commonFacade: CommonFacade
  ) {
    super(featureFlagFacade, cookieService, commonFacade, environment);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.cookieService.get('cookie_notice_closed')) {
      this.appFacade.acknowledgeCookie();
    }

    this.workspaceFacade.getWorkspaces();

    this.appFacade.requestAvailableApps();
    this.appFacade.getCloudServerVersion();
    this.appFacade.getClientIdentifier();

    this.initLanguage();
    this.initResponsiveness();
    this.initTouchDeviceDetection();
  }

  ngAfterViewInit(): void {
    setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
  }

  private initLanguage() {
    let languageToBeSet = defaultLanguage;

    const currentBrowserLang: availableLanguagesStrings = this.translate.getBrowserLang() as availableLanguagesStrings;
    if (currentBrowserLang && availableLanguages.includes(currentBrowserLang)) {
      languageToBeSet = currentBrowserLang;
    }

    const currentCookieLang: availableLanguagesStrings = localStorage.getItem(LS_lang) as availableLanguagesStrings;
    if (currentCookieLang && availableLanguages.includes(currentCookieLang)) {
      languageToBeSet = currentCookieLang;
    }

    this.appFacade.setLanguage(languageToBeSet);
  }

  private initResponsiveness() {
    this.resizeObservable$ = fromEvent(window, 'resize').pipe(auditTime(100));
    this.resizeSubscription$ = this.resizeObservable$.subscribe((evt) => {
      const dims: UIDimensions = {
        width: (evt.target as Window).innerWidth,
        height: (evt.target as Window).innerHeight
      };
      this.commonFacade.setUIDimensions(dims);
    });
  }

  private initTouchDeviceDetection() {
    const forceTouch: boolean = localStorage.getItem('test-force-touch') === 'true';
    if (forceTouch) {
      this.listenForMouseEvents = false;
    } else {
      const hasUsesMouseLocalStorageSet: boolean = localStorage.getItem(LS_usesMouse) === 'true';
      if (hasUsesMouseLocalStorageSet) {
        this.commonFacade.mouseDeviceDetected();
        this.listenForMouseEvents = false;
      }
    }
  }

  ngOnDestroy() {
    this.resizeSubscription$.unsubscribe();
  }
}
