import { Injectable, Inject } from '@angular/core';
import { RequestState } from './utils/streaming.types';
import { CloudService } from '../services/cloud.service';
import { ANGULAR_HTTP_CONTEXT } from '../app.tokens';
import { AngularHttpContext } from '@vas/angular-http-context';
import { JsonApi, Spec } from '@muellerbbm-vas/grivet';
import { v4 as uuid } from 'uuid';
import { dateSerializer } from '../shared/utility-functions/date.helpers';
import {
  MeasurementTokenContainer,
  TokenContainer,
  decodeMeasurementTokenContainerID
} from './utils/streaming-token.types';
import { addSeconds } from 'date-fns';

export const MIN_TOKEN_VALIDITY_DURATION_SECONDS = 10 * 60;

@Injectable({
  providedIn: 'root'
})
export class StreamingService {
  constructor(@Inject(ANGULAR_HTTP_CONTEXT) private context: AngularHttpContext, private cloudService: CloudService) {}

  createTokenRequest(measurementContainerId: string): Spec.ClientJsonApiDocument {
    const { depotId, measurementId } = decodeMeasurementTokenContainerID(measurementContainerId);
    const contentReferenceUUID = uuid();
    const contentReferencResource: Spec.ResourceObject = {
      id: contentReferenceUUID,
      type: 'DepotStreamingContentReference',
      relationships: {
        depot: { data: { id: depotId, type: 'Depot' } },
        content: { data: { id: measurementId, type: 'DepotBrowseContent' } }
      }
    };

    const request = new JsonApi.ClientDocument('DepotStreamingTokenRequest');
    request.setAttribute('one_measurement_per_token', 'True');
    request.setRelationship('references', [{ id: contentReferenceUUID, type: 'DepotStreamingContentReference' }]);
    request.includeResource(contentReferencResource);

    return request.data;
  }

  async getToken(measurementContainerId: string): Promise<MeasurementTokenContainer> {
    const streamingAppStart = await this.cloudService.getAppStart('depot');
    const streamingListUrl = streamingAppStart?.relationships['streaming_tokens']?.links?.related?.url;
    if (!streamingListUrl) {
      throw new Error('Token is not available');
    }
    const request = this.createTokenRequest(measurementContainerId);

    const postResponse = await this.context.postDocument(streamingListUrl, request);
    const postResponseDocument = new JsonApi.Document(postResponse, this.context);

    const applicResource = postResponseDocument.resource;
    if (!applicResource) {
      throw new Error('POST response for token did not include a JSON API resource');
    }
    const { depotId, measurementId } = decodeMeasurementTokenContainerID(measurementContainerId);
    const tokenExpirationTimeSeconds =
      applicResource.rawData.attributes?.['token_expiration_time'] ?? MIN_TOKEN_VALIDITY_DURATION_SECONDS;

    const results = await postResponseDocument.resource?.relatedResources['results'];
    const receivedToken = results?.[0].attributes?.token;
    const webgrpcServerURL = results?.[0]?.attributes?.web_server_url;
    const success = receivedToken && webgrpcServerURL;

    const tokenContainer: TokenContainer = {
      id: measurementContainerId,
      token: receivedToken ?? '',
      type: 'Measurement',
      webgrpcServerURL: webgrpcServerURL ?? '',
      depotId: depotId,
      measurementId: measurementId,
      requestState: success ? RequestState.SUCCESS : RequestState.FAILURE,
      validUntilISO: dateSerializer(addSeconds(new Date(), tokenExpirationTimeSeconds))
    };

    return tokenContainer;
  }
}
