import { StreamingChart } from '../../utils/streaming-chart.types';
import { ChartXY, AxisScrollStrategies, AxisTickStrategies } from '@arction/lcjs';
import { timeDifference } from '../../utils/time.utils';
import { ScalesConfig2D } from '../lightningPlot.types';
import { dataYIsoAdjustAppearanceSingleValue } from '../appearanceTransformationFunctions';
import { transformFromISOtoUserByQuantity } from '../genericTransformationFunctions';
import { applyScaleTypeTransformation } from '../scalingTransformationFunctions';
import { decimalPlaces, distinguishableValuesFractionDigitsForMinimalDelta } from '../../utils/formatting.utils';

export const calc2DYValues = (
  yValuesInput: number[],
  scalesConfig: ScalesConfig2D,
  dataParams: StreamingChart.DatasetParameters,
  calibration?: StreamingChart.DatasetCalibration | undefined
): number[] => {
  const yValuesResult: number[] = [];
  for (const yValueInput of yValuesInput) {
    let yValue = yValueInput;
    // Calibration
    if (calibration) {
      yValue = calibration.calibfact * (calibration.calibscale * yValue + calibration.calibofs);
    }

    // Appearance
    if (dataParams.plottingParameters && dataParams.plottingParameters.quantityY) {
      const { isoData } = dataYIsoAdjustAppearanceSingleValue(
        dataParams.rawDataType,
        dataParams.plottingParameters?.isSquared ?? false,
        dataParams.plottingParameters?.quantityY?.rawQuantity,
        yValue
      );
      yValue = isoData;
    }

    // From ISO Transformation
    if (dataParams.plottingParameters) {
      yValue = transformFromISOtoUserByQuantity(yValue, dataParams.plottingParameters?.quantityY) as number;
    }

    // Scaling
    if (scalesConfig) {
      yValue = applyScaleTypeTransformation(yValue, 'yAxis', scalesConfig, dataParams.plottingParameters);
    }
    yValuesResult.push(yValue);
  }
  return yValuesResult;
};
