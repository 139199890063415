import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USER_FEATURE_KEY, UserState } from './user.reducer';

// Lookup the 'User' feature state managed by NgRx
const getUserState = createFeatureSelector<UserState>(USER_FEATURE_KEY);

const getLoaded = createSelector(getUserState, (state: UserState) => {
  return state.loaded;
});

const getError = createSelector(getUserState, (state: UserState) => state.error);

const getUserSelf = createSelector(getUserState, getLoaded, (state: UserState, isLoaded) => {
  return isLoaded ? state.user : undefined;
});

const getUsersSelfCanSee = createSelector(getUserState, getLoaded, (state: UserState, isLoaded) => {
  return isLoaded ? state.usersSelfCanSee : [];
});

const getRolesSelfCanSee = createSelector(getUserState, getLoaded, (state: UserState, isLoaded) => {
  return isLoaded ? state.rolesSelfCanSee : [];
});

const getUserGroups = createSelector(getUserState, getLoaded, (state: UserState, isLoaded) => {
  return isLoaded ? state.user.groups : [];
});

const getUserIsAdmin = createSelector(getUserGroups, (groups) => {
  return groups.some((group) => group === 'admins');
});
const getUserRunsWindows = createSelector(getUserState, (state: UserState) => {
  return state.agent.os !== 'unknown' && state.agent.os.toLocaleLowerCase().includes('win');
});

const getUserLicenses = createSelector(getUserState, (state: UserState) => state.myLicenses);

export const userQuery = {
  getLoaded,
  getError,
  getUserSelf,
  getUserGroups,
  getUserIsAdmin,
  getUserRunsWindows,
  getUserLicenses,
  getUsersSelfCanSee,
  getRolesSelfCanSee
};
