import { format, parseISO } from 'date-fns';

export const dateSerializer = (date: Date) => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ssxxx");
};

// Deserialize string to date object
export const dateDeserializer = (dateStr: string) => {
  return parseISO(dateStr);
};
