import { exhaustiveMatchingGuard } from '../../shared/utility-functions/exhaustiveMatchingGuard';
import { Quantity } from '../prototypes/DatasetMessages';
import { getDbRefAndDbFactor } from '../utils/dataset.utils';
import { StreamingChart } from '../utils/streaming-chart.types';
import { dataYIsoAdjustAppearance } from './appearanceTransformationFunctions';
import { AvailableAxis } from './lightningPlot.types';

export const applyCalibration = (value: number, calibration: StreamingChart.DatasetCalibration): number => {
  return calibration.calibfact * (calibration.calibscale * value + calibration.calibofs);
};

export const transformFromISOtoUserByQuantity = (
  value: number | number[] | number[][],
  quantity?: Quantity | StreamingChart.Quantity
): number | number[] | number[][] => {
  let result;

  const isoOffset = quantity?.isoOffset ?? 0;
  const isoFactor = quantity?.isoFactor ?? 1;

  // Early return if no transformation necessary
  if (isoFactor === 1 && isoOffset === 0) {
    return value;
  }

  if (Array.isArray(value)) {
    result = value.map((val) => {
      if (Array.isArray(val)) {
        return val.map((v) => v / isoFactor - isoOffset);
      } else {
        return val / isoFactor - isoOffset;
      }
    });
  } else {
    result = value / isoFactor - isoOffset;
  }

  return result;
};

export const transformFromISOtoUser = (
  value: number | number[] | number[][],
  axis: AvailableAxis,
  plottingParameters: StreamingChart.PlottingParameters
): number | number[] | number[][] => {
  switch (axis) {
    case 'xAxis':
      return transformFromISOtoUserByQuantity(value, plottingParameters?.quantityX);
      break;
    case 'yAxis':
    case 'color':
      return transformFromISOtoUserByQuantity(value, plottingParameters?.quantityY);
      break;
    default:
      exhaustiveMatchingGuard(axis);
  }

  return value;
};

/*
 *
 * This function transforms the original values into the actual values that are displayed in the heatmap
 * This transformation respects (order matters!)
 * - Appearance (RMS/squared handling and so forth)
 * - ISO corrections (NOT YET IMPLEMENTED)
 * - the scale config (lin/log/db)
 *
 */
export const transformOriginalValuesIntoDisplayValues = (
  values: number[][],
  colorScaleType: 'lin' | 'log' | 'db',
  datasetParams: StreamingChart.DatasetParameters
) => {
  let result: {
    values: number[][];
    min: number;
    max: number;
  } = {
    values: [],
    min: 0,
    max: 0
  };

  if (datasetParams.plottingParameters === undefined) {
    throw new Error('Missing plottingParameters3D');
  }

  if (datasetParams.plottingParameters.isComplex) {
    const clonedValues = structuredClone(values); // don't touch the original values
    for (let rowIndex = 0; rowIndex < values.length; rowIndex++) {
      const origLength = clonedValues[rowIndex].length;
      for (let columnIndex = 0; columnIndex < origLength; columnIndex += 2) {
        const x = clonedValues[rowIndex][columnIndex];
        const y = clonedValues[rowIndex][columnIndex + 1];
        clonedValues[rowIndex][columnIndex / 2] = Math.sqrt(x * x + y * y);
      }
      clonedValues[rowIndex].splice(origLength / 2, origLength / 2);
    }
    values = clonedValues;
  }

  const { userValues, effectiveIsSquared } = calcUserValues(values, datasetParams);
  datasetParams.plottingParameters.effectiveIsSquared = effectiveIsSquared;

  if (colorScaleType === 'lin') {
    const minMax = findMinMax(userValues);
    result = { values: userValues, min: minMax.min, max: minMax.max };
  }
  if (colorScaleType === 'log') {
    const minMax = findMinMax(userValues);
    result = { values: userValues, min: minMax.min, max: minMax.max };
  }
  if (colorScaleType === 'db' && datasetParams.plottingParameters) {
    const { dbRef, dbFactor } = getDbRefAndDbFactor(datasetParams.plottingParameters);
    const valuesdB = userValues.map((x) => {
      const row = x.map((y) => {
        if (y <= 0) {
          return -400;
        }
        const val = dbFactor * Math.log10(y / dbRef);
        return val < -400 ? -400 : val;
      });
      return row;
    });

    const minMax = findMinMax(valuesdB);

    result = { values: valuesdB, min: minMax.min, max: minMax.max };
  }

  return result;
};

export const findMinMax = (matrix: number[][]): { min: number; max: number } => {
  return matrix.reduce(
    (result, row) => {
      const rowMin = Math.min(...row);
      const rowMax = Math.max(...row);

      result.min = result.min === undefined ? rowMin : Math.min(result.min, rowMin);
      result.max = result.max === undefined ? rowMax : Math.max(result.max, rowMax);

      return result;
    },
    { min: 0, max: 0 }
  );
};

export const calcUserValues = (
  originalValues: number[][],
  datasetParams: StreamingChart.DatasetParameters
): { userValues: number[][]; effectiveIsSquared: boolean } => {
  let appearanceMatrix: number[][] = [[]];
  let dataIsSquared = false;
  if (datasetParams.plottingParameters && datasetParams.plottingParameters.quantityY) {
    appearanceMatrix = originalValues.map((x) => {
      const { isoData, effectiveIsSquared } = dataYIsoAdjustAppearance(
        datasetParams.rawDataType,
        datasetParams.plottingParameters?.isSquared ?? false,
        datasetParams.plottingParameters!.quantityY!.rawQuantity,
        x
      );
      dataIsSquared = effectiveIsSquared;
      return isoData;
    });
  }

  if (datasetParams.plottingParameters) {
    appearanceMatrix = transformFromISOtoUserByQuantity(
      appearanceMatrix,
      // 'color',
      datasetParams.plottingParameters?.quantityY
    ) as number[][];
  }

  return { userValues: appearanceMatrix, effectiveIsSquared: dataIsSquared };
};
