// USAGE INFORMATION:
// 1) Set list of possible datasets via datasetInfos
// 2) Whenever triggering to show this component call forceVisible with the index of the desired dataset
// Note: Avoid resetting the datasetInfos, if the datasets did not actually change, because this will destroy all cached plots.

import { Component, Input } from '@angular/core';

import { Observable, EMPTY, of, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlottingService } from './plotting.service';
import { PlottingDataset } from './plotting.types';
import { AppFacade } from '../+state/app.facade';

@Component({
  selector: 'cloud-plotting',
  templateUrl: './plotting.component.html',
  styleUrls: ['./plotting.component.css']
})
export class PlottingComponent {
  @Input() datasetInfos: PlottingDataset[] | undefined;
  currentDatasetIndex = 0;
  plotSVGData$: Observable<string | undefined>;
  isPlottingInFlight = false;
  visible = false;

  constructor(public plottingService: PlottingService, private appFacade: AppFacade) {}

  getCurrentTitle(): string | undefined {
    if (!this.datasetInfos || this.datasetInfos.length <= this.currentDatasetIndex) {
      return undefined;
    }
    return this.datasetInfos[this.currentDatasetIndex].title;
  }

  private refreshPlotData() {
    if (!this.datasetInfos || this.datasetInfos.length <= this.currentDatasetIndex) {
      this.isPlottingInFlight = false;
      this.plotSVGData$ = EMPTY;
      return;
    }

    const currentDataset = this.datasetInfos[this.currentDatasetIndex];
    if (!currentDataset) {
      this.isPlottingInFlight = false;
      this.plotSVGData$ = EMPTY;
      return;
    }

    this.visible = true;
    // Check whether we already cached this plot
    const cachedPlot = this.datasetInfos[this.currentDatasetIndex]?.svg;
    if (cachedPlot) {
      this.plotSVGData$ = of(cachedPlot);
    } else {
      this.isPlottingInFlight = true;
      const plotPromise = this.plottingService.fetchPlotSVG(currentDataset.depotId, currentDataset.id).catch((err) => {
        this.isPlottingInFlight = false;
        return this.appFacade.passOnError(err);
      });
      this.plotSVGData$ = from(plotPromise).pipe(
        map((svgResponse) => (svgResponse ? this.handlePlotResponse(...svgResponse) : ''))
      );
    }
  }

  private handlePlotResponse(rawData: string, datasetId: string): string {
    const extractedData = this.extractSVGData(rawData);
    // It we have gotten so far, then we are ready to display the plot, so reset the spinner
    this.isPlottingInFlight = false;
    // Ensure that we still have the same index
    if (!this.datasetInfos) {
      return '';
    } else if (
      this.datasetInfos.length > this.currentDatasetIndex &&
      this.datasetInfos[this.currentDatasetIndex].id === datasetId
    ) {
      this.datasetInfos[this.currentDatasetIndex].svg = extractedData;
      return extractedData;
    } else {
      // Fill in plot cache, but display nothing in order to avoid misleading information
      const actualDataset = Object.values(this.datasetInfos).find((item) => item.id === datasetId);
      if (actualDataset) {
        const actualIndex = this.datasetInfos.indexOf(actualDataset);
        this.datasetInfos[actualIndex].svg = extractedData;
      }
      return '';
    }
  }

  private extractSVGData(rawResponse: string): string {
    // extract start svg tag
    const svgStartIndex = rawResponse.indexOf('<svg');
    const svgEndIndex = rawResponse.indexOf('>', svgStartIndex) + 1;
    const svgStartTag = rawResponse.slice(svgStartIndex, svgEndIndex);
    // extract actual plotting data
    const plotStartIndex = rawResponse.indexOf('<g id="bbmdiagram"');
    const roughPlotEndIndex = rawResponse.indexOf('<rect id="bbmeventframe"');
    const plotEndIndex = rawResponse.indexOf('</g>', roughPlotEndIndex) + 4;
    const plotData = rawResponse.slice(plotStartIndex, plotEndIndex);
    return svgStartTag + plotData + '</svg>';
  }

  public forceVisible(startIndex: number): void {
    if (this.datasetInfos && startIndex < this.datasetInfos.length) {
      this.currentDatasetIndex = startIndex;
      this.refreshPlotData();
      this.visible = true;
    }
  }

  displayPreviousDataset(): void {
    this.currentDatasetIndex = this.currentDatasetIndex - 1;
    if (this.currentDatasetIndex < 0) {
      this.currentDatasetIndex = this.datasetInfos ? this.datasetInfos.length - 1 : 0;
    }
    this.refreshPlotData();
  }

  displayNextDataset(): void {
    this.currentDatasetIndex = this.currentDatasetIndex + 1;
    if (this.datasetInfos && this.currentDatasetIndex >= this.datasetInfos.length) {
      this.currentDatasetIndex = 0;
    }
    this.refreshPlotData();
  }
}
