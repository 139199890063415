<div id="addAttributeDropdown">
  <input
    name="searchText"
    #textSearchInput
    clrInput
    placeholder="{{ 'DEPOTSEARCH.ATTRIBUTESEARCH' | translate }}"
    [ngModel]=""
    data-cy="searchAttributeDropdownInput"
    (ngModelChange)="searchText$.next($event)"
    (keydown)="preventSpaceDismiss($event)"
  />

  <div *ngFor="let group of this.allAttributeGroups$ | async">
    <div
      *ngFor="
        let attribute of (filteredSemanticSearchAttributes$ | async)! | searchBarAttributeGroupFilter: group;
        first as isFirst
      "
    >
      <h6 *ngIf="isFirst">{{ group | translateAttributeGroup }}</h6>
      <button
        type="button"
        clrDropdownItem
        data-cy="searchAttributeResultListEntry"
        (click)="selectAttribute($event, attribute)"
      >
        <div
          innerHTML="{{
            attribute
              | translateAttribute
              | escapeDangerousCharacters
              | searchBarHighlightFilter: (searchText$ | async)!
          }}"
        ></div>
      </button>
    </div>
  </div>
  <div
    *ngFor="
      let attribute of (filteredSemanticSearchAttributes$ | async)! | searchBarAttributeGroupFilter: null;
      first as isFirst
    "
  >
    <h6 *ngIf="isFirst">{{ 'DEPOTSEARCH.NOGROUP' | translate }}</h6>
    <button
      type="button"
      clrDropdownItem
      data-cy="searchAttributeResultListEntry"
      (click)="selectAttribute($event, attribute)"
    >
      <div
        innerHTML="{{
          attribute | translateAttribute | escapeDangerousCharacters | searchBarHighlightFilter: (searchText$ | async)!
        }}"
      ></div>
    </button>
  </div>
  <div *ngFor="let attribute of filteredDepotSearchAttributes$ | async; first as isFirst">
    <h6 *ngIf="isFirst">{{ 'DEPOTSEARCH.NOTRANSLATIONAVAILABLE' | translate }}</h6>
    <button
      type="button"
      clrDropdownItem
      data-cy="searchAttributeResultListEntry"
      (click)="selectAttribute($event, attribute)"
    >
      <div
        innerHTML="{{
          attribute | translateAttribute | escapeDangerousCharacters | searchBarHighlightFilter: (searchText$ | async)!
        }}"
      ></div>
    </button>
  </div>
</div>
