import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { StreamingPartialState } from './streaming.reducer';

import {
  RequestToken,
  TriggerTokenLookup,
  SetProcessingToken,
  SelectDataset,
  UnsetProcessingToken,
  StopStreaming,
  SetIsStreaming,
  SetAvailableAxisInteraction,
  SelectColorInteraction,
  SelectAxisInteraction,
  SetAvailableColorInteraction,
  EnableRefresh,
  DisableRefresh,
  CreateStreamingPackage,
  ShowYBar,
  HideYBar,
  SetSelectableTracks,
  SelectedTrack,
  ShowTrackSelection,
  SetFullSize,
  SetReducedSize,
  SetThruputParameters,
  ResetThruputParameters
} from './streaming.actions';
import { streamingQuery } from './streaming.selectors';
import { DatasetDescription } from '../../measurements/measurements.types';
import { StreamingPackageOptions, StreamingType } from '../utils/streaming.types';
import { AvailableAxis, AxisScalingTypes, ColorScalingTypes } from '../plot-handlers/lightningPlot.types';
import { TokenContainer, MeasurementTokenContainerID, TokenContainerID } from '../utils/streaming-token.types';
import { SelectableTrack } from '../integrated-streaming/integrated-streaming.component';

@Injectable()
export class StreamingFacade {
  tokens$ = this.store.pipe(select(streamingQuery.getCachedTokens));
  /*   getTokenRequestInFlight$ = this.store.pipe(select(streamingQuery.getTokenRequestInFlight));
  getLatestToken$ = this.store.pipe(select(streamingQuery.getLatestToken));
  getSelectedDataset$ = this.store.pipe(select(streamingQuery.getSelectedDataset));
  isProcessing$ = this.store.pipe(select(streamingQuery.getIsProcessing));
  isStopStreaming$ = this.store.pipe(select(streamingQuery.getIsStopStreaming));
  getStreamingStatus$ = this.store.pipe(select(streamingQuery.getStreamingStatus)); */

  currentStreamingPackage$ = this.store.pipe(select(streamingQuery.getCurrentStreamingPackage));
  currentStreamingPackageIsStreaming$ = this.store.pipe(select(streamingQuery.getCurrentStreamingPackageIsStreaming));
  streamingPackageHistory$ = this.store.pipe(select(streamingQuery.getStreamingPackageHistory));

  fullSize$ = this.store.pipe(select(streamingQuery.getFullSize));

  refreshAvailable$ = this.store.pipe(select(streamingQuery.getRefreshAvailable));

  axisInteractions$ = this.store.pipe(select(streamingQuery.getAxisInteractions));
  showYBar$ = this.store.pipe(select(streamingQuery.getShowYBar));

  showTrackSelection$ = this.store.pipe(select(streamingQuery.getShowTrackSelection));
  selectableTracks$ = this.store.pipe(select(streamingQuery.getSelectableTracks));
  selectedTrack$ = this.store.pipe(select(streamingQuery.getSelectedTracks));

  thruputParameters$ = this.store.pipe(select(streamingQuery.getThruputParameters));

  availableInteractionsForAxis$ = (axisType: AvailableAxis) =>
    this.store.pipe(select(streamingQuery.getAvailableInteractionsForAxis(axisType)));
  selectedInteractionForAxis$ = (axisType: AvailableAxis) =>
    this.store.pipe(select(streamingQuery.getSelectedInteractionForAxis(axisType)));

  constructor(private store: Store<StreamingPartialState>) {}

  requestToken(tokenContainerId: MeasurementTokenContainerID) {
    this.store.dispatch(RequestToken({ tokenContainerId }));
  }

  lookupToken(tokenContainerId: MeasurementTokenContainerID) {
    this.store.dispatch(TriggerTokenLookup({ tokenContainerId }));
  }

  /* TODO: DEPRECATE */
  selectDataset(dataset: DatasetDescription) {
    this.store.dispatch(SelectDataset({ dataset }));
  }

  setProcessingToken(tokenContainer: TokenContainer) {
    this.store.dispatch(SetProcessingToken({ tokenContainer }));
  }

  unsetToken() {
    this.store.dispatch(UnsetProcessingToken());
  }

  setStreamingStatus(value: boolean) {
    this.store.dispatch(SetIsStreaming({ value }));
  }

  stopStreaming() {
    this.store.dispatch(StopStreaming());
  }

  // Streaming Package Handling
  createStreamingPackage(options: StreamingPackageOptions) {
    this.store.dispatch(CreateStreamingPackage({ options }));
  }

  // Axis Interactions
  setAvailableAxisInteraction(axisType: AvailableAxis, interactions: AxisScalingTypes[]) {
    this.store.dispatch(SetAvailableAxisInteraction({ axisType, availableAxisInteractions: interactions }));
  }

  setSelectedAxisInteraction(axisType: AvailableAxis, interaction: AxisScalingTypes) {
    this.store.dispatch(SelectAxisInteraction({ axisType, selectedAxisInteraction: interaction }));
  }

  setAvailableColorInteraction(interactions: ColorScalingTypes[]) {
    this.store.dispatch(SetAvailableColorInteraction({ availableColorInteractions: interactions }));
  }

  setSelectedColorInteraction(interaction: ColorScalingTypes) {
    this.store.dispatch(SelectColorInteraction({ selectedColorInteraction: interaction }));
  }

  setFullSize(fullSize: boolean) {
    if (fullSize) {
      this.store.dispatch(SetFullSize());
    } else {
      this.store.dispatch(SetReducedSize());
    }
  }

  setRefreshAvailable(available: boolean) {
    if (available) {
      this.store.dispatch(EnableRefresh());
    } else {
      this.store.dispatch(DisableRefresh());
    }
  }

  setYBarAxisVisible(visible: boolean) {
    if (visible) {
      this.store.dispatch(ShowYBar());
    } else {
      this.store.dispatch(HideYBar());
    }
  }

  setThruputParameters(start: number, end: number, plotCompressed: boolean) {
    console.log('setThruputParameters', start, end, plotCompressed ? 'compressed' : 'full');
    this.store.dispatch(SetThruputParameters({ start, end, plotCompressed }));
  }

  resetThruputParameters() {
    this.store.dispatch(ResetThruputParameters());
  }

  showTrackSelection(showTrackSelection: boolean) {
    this.store.dispatch(ShowTrackSelection({ showTrackSelection }));
  }
  setSelectableTracks(tracks: SelectableTrack[]) {
    this.store.dispatch(SetSelectableTracks({ selectableTracks: tracks }));
  }
  selectTrack(track?: SelectableTrack) {
    this.store.dispatch(SelectedTrack({ track: track }));
  }
}
