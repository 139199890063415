import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, map, switchMap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { ContentCollectionFacade } from '../../../../content-collection/+state/content-collection.facade';
import { ContentCollection } from '../../../../content-collection/content-collection.types';
import { UserFacade } from '../../../../user/+state/user.facade';
import { FeatureFlagsFacade } from '@root/libs/feature-flags/src';

@Component({
  selector: 'cloud-ccd-collection-list',
  templateUrl: './ccd-collection-list.component.html',
  styleUrls: ['./ccd-collection-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CcdCollectionListComponent implements OnInit {
  @Input() selectedCCGuid$: BehaviorSubject<string | undefined>;
  @Input() selectedCC$: Observable<ContentCollection | undefined>;

  @Output() addNewCollection: EventEmitter<null> = new EventEmitter();

  activeCollection$: Observable<ContentCollection | undefined>;
  activeCollectionGuid$: Observable<string | undefined>;

  myCollectionsActive = true;
  sharedCollectionsActive = true;

  ownContentCollections$: Observable<ContentCollection[]>;
  sharedContentCollections$: Observable<ContentCollection[]>;

  constructor(
    public contentCollectionFacade: ContentCollectionFacade,
    public userFacade: UserFacade,
    public featureFlagFacade: FeatureFlagsFacade
  ) {}

  ngOnInit(): void {
    this.activeCollection$ = this.contentCollectionFacade.activeContentCollection$;
    this.activeCollectionGuid$ = this.contentCollectionFacade.activeContentCollectionGuid$;

    this.ownContentCollections$ = this.contentCollectionFacade.contentCollections$.pipe(
      switchMap((collections) =>
        this.userFacade.self$.pipe(map((user) => collections.filter((c) => c.createdBy === user?.id)))
      )
    );

    this.sharedContentCollections$ = this.contentCollectionFacade.contentCollections$.pipe(
      switchMap((collections) =>
        this.userFacade.self$.pipe(map((user) => collections.filter((c) => c.createdBy !== user?.id)))
      )
    );
  }

  toggleMyCollections() {
    this.myCollectionsActive = !this.myCollectionsActive;
  }

  toggleSharedCollections() {
    this.sharedCollectionsActive = !this.sharedCollectionsActive;
  }

  selectContentCollection(guid: string) {
    this.selectedCCGuid$.next(guid);
  }

  activateContentCollection(collectionGuid: string, event: MouseEvent) {
    event.stopPropagation();
    this.contentCollectionFacade.activateContentCollection(collectionGuid);
  }

  addNewCollectionClicked() {
    this.addNewCollection.emit();
  }
}
