import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MEASUREMENTS_FEATURE_KEY, MeasurementsState } from './measurements.reducer';
import { dynamicComponentIdentifiers } from '../measurements.types';

// Lookup the 'Measurements' feature state managed by NgRx
const getMeasurementsState = createFeatureSelector<MeasurementsState>(MEASUREMENTS_FEATURE_KEY);

const getSelectedId = createSelector(getMeasurementsState, (state: MeasurementsState) => state.selectedMeasurement);

const getMeasurements = createSelector(getMeasurementsState, (state: MeasurementsState) => state.measurements);

const getSelectedMeasurement = createSelector(
  getMeasurementsState,
  getSelectedId,
  (state: MeasurementsState, measurement) => {
    if (measurement) {
      return state.measurements[measurement.measurementId];
    }
  }
);

const getComponentIsSelectedDynamicComponent = (dc: dynamicComponentIdentifiers) => {
  return createSelector(getMeasurementsState, getSelectedMeasurement, (state: MeasurementsState, measurement) => {
    let isSelectedDynamicComponent = false;
    if (measurement) {
      isSelectedDynamicComponent = state.selectedDynamicComponent === dc;
    }
    return isSelectedDynamicComponent;
  });
};

const getSelectedEntryAttribIsLoading = (attribute: string) => {
  return createSelector(
    getMeasurementsState,
    getSelectedMeasurement,
    (state: MeasurementsState, selectedMeasurement) => {
      let attribIsLoading = false;
      if (selectedMeasurement && selectedMeasurement.loading) {
        attribIsLoading = selectedMeasurement.loading.includes(attribute);
      }
      return attribIsLoading;
    }
  );
};

const getSelectedEntryAttribCount = (attribute: string) => {
  return createSelector(
    getMeasurementsState,
    getSelectedMeasurement,
    (state: MeasurementsState, selectedMeasurement) => {
      let count = 0;
      if (selectedMeasurement && selectedMeasurement.resources && selectedMeasurement.resources[attribute]) {
        const attrib = selectedMeasurement.resources[attribute];
        if (Array.isArray(attrib)) {
          count = selectedMeasurement.resources[attribute].length;
          if (attribute === 'datasetDescription') {
            count = selectedMeasurement.resources.overview['numberDataSets'];
          }
        } else {
          count =
            selectedMeasurement.resources[attribute][Object.keys(selectedMeasurement.resources[attribute])].length;
        }
      }
      return count;
    }
  );
};

const getSelectedMeasurementActiveDynamicComponent = createSelector(
  getMeasurementsState,
  getSelectedMeasurement,
  (state: MeasurementsState, measurement) => {
    const selectedComponent = state.selectedDynamicComponent;
    return selectedComponent ? { selectedComponent, measurement } : null;
  }
);

// Downloads

const getDownloads = createSelector(getMeasurementsState, (state: MeasurementsState) => {
  return state.downloads;
});

const getActiveDownloads = createSelector(getMeasurementsState, (state: MeasurementsState) => {
  if (state.downloads) {
    return Object.values(state.downloads);
  }
  return null;
});

const getDownloadsErrorCount = createSelector(getMeasurementsState, (state: MeasurementsState) => {
  return state.downloadsErrorCounter;
});

const getDisplayedAttributesState = createSelector(getMeasurementsState, (state: MeasurementsState) => {
  return state.displayAttributesState;
});

// Move - Basics
const getMovingState = createSelector(getMeasurementsState, (state: MeasurementsState) => state.moving);
const getShowMoveModal = createSelector(getMovingState, (state) => state.prep.showMoveDialog);

// Move - Preparation
const getMeasurementsToMove = createSelector(getMovingState, (state) => state.prep.searchResultsToMove);
const getTargetPath = createSelector(getMovingState, (state) => state.prep.targetPath);

const getIntermediateMeasurements = createSelector(
  getMovingState,
  (state) => state.prep.intermediateMeasurementDetails
);

// Move - Dry Run
const getDryRunResults = createSelector(getMovingState, (state) => state.prep.dryRunCheckResults);
const getDryRunState = createSelector(getMovingState, (state) => state.prep.dryRunMoveState);

// Move - Moving
const getMovingMeasurements = createSelector(getMovingState, (state) => state.actual.movingMeasurements);
const getMovingStateGatheringPathState = createSelector(getMovingState, (state) => state.prep.gatheringPathState);

export const measurementsQuery = {
  getSelectedId,
  getMeasurements,
  getSelectedMeasurement,
  getComponentIsSelectedDynamicComponent,
  getSelectedEntryAttribIsLoading,
  getSelectedEntryAttribCount,
  getSelectedMeasurementActiveDynamicComponent,
  // Downloads
  getDownloads,
  getActiveDownloads,
  getDownloadsErrorCount,
  getDisplayedAttributesState,
  // Move
  getShowMoveModal,
  getMeasurementsToMove,
  getTargetPath,
  getIntermediateMeasurements,
  getDryRunResults,
  getDryRunState,
  getMovingMeasurements,
  getMovingStateGatheringPathState
};
