// common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ClarityModule } from '@clr/angular';

// NGRX
import { PlottingComponent } from './plotting.component';
import { translateLoader } from '../app.translate';
import { SharedModule } from '../shared/shared.module';

export const plottingImports: any[] = [CommonModule, TranslateModule.forChild(translateLoader), SharedModule];

export const plottingDeclarations: any[] = [PlottingComponent];

@NgModule({
  declarations: plottingDeclarations,
  imports: [plottingImports, ClarityModule],
  exports: [PlottingComponent],
  providers: []
})
export class PlottingModule {}
