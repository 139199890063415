import { Injectable } from '@angular/core';
import { Spec } from '@muellerbbm-vas/grivet';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RoleToUserRelation } from './../../types/role.types';

import { map, withLatestFrom } from 'rxjs/operators';
import { LoadEffectiveRolesSuccess, RolesLoaded } from './roles.actions';

import * as RoleAction from './roles.actions';

import { fetch } from '@nrwl/angular';
import { from } from 'rxjs/internal/observable/from';
import { createAppError } from '../../../../../app.factories';
import { RolesService } from '../../services/roles.service';
import { RolesFacade } from './roles.facade';
import { RolesPartialState } from './roles.reducer';

@Injectable()
export class RolesEffects {
  loadRoles = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleAction.LoadRoles),
      fetch({
        run: (action: ReturnType<typeof RoleAction.LoadRoles>, state: RolesPartialState) => {
          return from(this.rolesService.getRoles()).pipe(
            map((res) => {
              return RolesLoaded({ roles: res.roles, roleToUserRelations: res.roleToUserRelations });
            })
          );
        },
        onError: (action: ReturnType<typeof RoleAction.LoadRoles>, error) => {
          return createAppError(error);
        }
      })
    )
  );

  loadEffectiveRoles = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleAction.LoadEffectiveRoles),
      fetch({
        run: (action: ReturnType<typeof RoleAction.LoadEffectiveRoles>, state: RolesPartialState) => {
          return from(this.rolesService.getEffectiveRoles()).pipe(
            map((res) => {
              return LoadEffectiveRolesSuccess({ roles: res });
            })
          );
        },
        onError: (action: ReturnType<typeof RoleAction.LoadEffectiveRoles>, error) => {
          return createAppError(error);
        }
      })
    )
  );

  addRolesToUser = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleAction.AddRolesToUser),
      fetch({
        run: (action: ReturnType<typeof RoleAction.AddRolesToUser>) => {
          return from(this.rolesService.addRolesToUser(action.userId, action.roleIds)).pipe(
            map((res: Spec.JsonApiDocument[]) => {
              const roleToUserRelations = res.map((res: Spec.JsonApiDocument) => {
                const relation = res?.data as Spec.ResourceObject;

                return {
                  id: relation?.id,
                  user: relation?.relationships?.user?.data,
                  role: relation?.relationships?.role?.data
                } as RoleToUserRelation;
              });

              return RoleAction.AddRolesToUserSuccess({
                userId: action.userId,
                roleToUserRelations: roleToUserRelations
              });
            })
          );
        },
        onError: (action: ReturnType<typeof RoleAction.AddRolesToUser>, error) => {
          return createAppError(error);
        }
      })
    )
  );

  deleteUserRoles = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleAction.DeleteUserRoles),
      fetch({
        run: (action: ReturnType<typeof RoleAction.DeleteUserRoles>) => {
          return this.rolesService.deleteUserRoles(action.userId, action.roleIds).pipe(
            withLatestFrom(this.rolesFacade.roleToUserRelations$),
            map(([res, roleRelations]) => {
              const relationIds =
                roleRelations
                  ?.filter((relation) => {
                    if (relation.user.id === action.userId) {
                      if (action.roleIds.find((id) => id === relation.role.id)) {
                        return true;
                      }
                    }
                    return false;
                  })
                  .map((relation) => relation.id) || [];

              return RoleAction.DeleteUserRolesSuccess({ userId: action.userId, relationIds: relationIds });
            })
          );
        },

        onError: (action: ReturnType<typeof RoleAction.DeleteUserRoles>, error) => {
          return createAppError(error);
        }
      })
    )
  );

  addRolesToGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleAction.AddRolesToGroup),
      fetch({
        run: (action: ReturnType<typeof RoleAction.AddRolesToGroup>) => {
          return from(this.rolesService.addRolesToGroup(action.groupId, action.roleIds)).pipe(
            map((res: Spec.JsonApiDocument[]) =>
              RoleAction.AddRolesToGroupSuccess({ groupId: action.groupId, roleIds: action.roleIds })
            )
          );
        },
        onError: (action: ReturnType<typeof RoleAction.AddRolesToGroup>, error) => {
          return createAppError(error);
        }
      })
    )
  );

  removeRolesFromGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleAction.DeleteRolesFromGroup),
      fetch({
        run: (action: ReturnType<typeof RoleAction.DeleteRolesFromGroup>) => {
          return from(this.rolesService.deleteGroupRoles(action.groupId, action.roleIds)).pipe(
            map((res: Spec.JsonApiDocument[]) => {
              return RoleAction.DeleteRolesFromGroupSuccess({ groupId: action.groupId, roleIds: action.roleIds });
            })
          );
        },
        onError: (action: ReturnType<typeof RoleAction.DeleteRolesFromGroup>, error) => {
          return createAppError(error);
        }
      })
    )
  );

  constructor(private rolesService: RolesService, private actions$: Actions, private rolesFacade: RolesFacade) {}
}
