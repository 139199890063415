<clr-main-container
  (mouseenter)="listenForMouseEvents ? commonFacade.mouseDeviceDetected() : false"
  data-cy="root"
  tabindex="1"
>
  <clr-alert
    id="cookie-banner"
    [clrAlertType]="'info'"
    [clrAlertAppLevel]="true"
    *ngIf="!(appFacade.cookieNoticeAcknowledged$ | async)"
  >
    <clr-alert-item>
      <span class="alert-text cookie-alert">
        <span *cloudMultiLang="'NOT de'"
          >This website uses cookies in accordance with our
          <a *ngIf="!(featureFlagFacade.featureIsSet$('PRIVACY') | async)" [routerLink]="['/privacy']" class="link">{{
            'APP.PRIVACY' | translate
          }}</a>
          <a
            *ngIf="featureFlagFacade.featureIsSet$('PRIVACY') | async"
            [href]="featureFlagFacade.featureValue$('PRIVACY') | async"
            target="_BLANK"
            rel="noopener noreferrer"
            class="link"
            >{{ 'APP.PRIVACY' | translate }}</a
          >
          . By continuing to use this website you agree to this use of cookies.
        </span>
        <span *cloudMultiLang="'de'"
          >Diese Website nutzt Cookies im Einklang mit unserer
          <a *ngIf="!(featureFlagFacade.featureIsSet$('PRIVACY') | async)" [routerLink]="['/privacy']" class="link">{{
            'APP.PRIVACY' | translate
          }}</a>
          <a
            *ngIf="featureFlagFacade.featureIsSet$('PRIVACY') | async"
            [href]="featureFlagFacade.featureValue$('PRIVACY') | async"
            target="_BLANK"
            rel="noopener noreferrer"
            class="link"
            >{{ 'APP.PRIVACY' | translate }}</a
          >. Indem Sie diese Website weiter nutzen, stimmen Sie dieser Nutzung von Cookies zu.
        </span>
      </span>
      <div class="alert-actions">
        <button class="btn alert-action" (click)="appFacade.acknowledgeCookie()" data-cy="acknowledgeCookieButton">
          OK
        </button>
      </div>
    </clr-alert-item>
  </clr-alert>

  <cloud-router-error></cloud-router-error>
  <cloud-router-info></cloud-router-info>

  <cloud-menu-top></cloud-menu-top>

  <div class="content-container">
    <div class="content-area">
      <div class="clr-row">
        <div class="clr-col"><router-outlet></router-outlet></div>
      </div>
    </div>
  </div>
</clr-main-container>
