<h3>{{ 'APP.COPYRIGHT' | translate }}</h3>

<div x-ms-format-detection="phone" class="clr-row clr-col-xl-8 clr-col-12 card">
  <div class="card-block">
    <div class="card-title">
      {{ 'APP.COPYRIGHT_TITLE' | translate }}
    </div>
    <div class="card-text">
      <span *ngIf="translate.currentLang === 'de'">
        <p>Copyright © Müller-BBM VibroAkustik Systeme GmbH</p>
        <p>
          Alle Rechte vorbehalten. Dieses Handbuch sowie die beschriebenen Programme sind mit einem Copyright geschützt.
          Es ist ausdrücklich verboten, sie teilweise oder im Gesamten zu kopieren, ohne die schriftliche Erlaubnis des
          Verlegers und Eigentümers des Copyrights erworben zu haben.
        </p>
      </span>
      <span *ngIf="translate.currentLang === 'en'">
        <p>Copyright © Müller-BBM VibroAkustik Systeme GmbH</p>
        <p>
          All rights reserved. This manual and the described programs are protected by copyright. No part of this
          publication may be copied without prior permission in writing from the publisher and the owner of the
          copyright.
        </p>
      </span>
    </div>
  </div>
  <div class="card-block">
    <div class="card-title">
      {{ 'APP.COPYRIGHT_REVOCATION' | translate }}
    </div>
    <div class="card-text">
      <span *cloudMultiLang="'de'">
        <p>
          Obwohl die Müller-BBM VibroAkustik Systeme GmbH die Programme und Handbücher sorgfältig geprüft hat, kann sie
          das einwandfreie Funktionieren und die reibungslose Durchführung der Programme nicht gewährleisten. Diese
          Dokumentationen können jederzeit ohne Bekanntmachung geändert werden.
        </p>
      </span>
      <span *cloudMultiLang="'NOT de'">
        <p>
          Although Müller-BBM VibroAkustik Systeme GmbH has carefully examined the programs and manuals, it cannot
          guarantee the proper functioning and smooth running of the programs. These documentations are subject to
          change without notice.
        </p>
      </span>
    </div>
  </div>
  <div class="card-block">
    <div class="card-title">
      {{ 'APP.COPYRIGHT_TRADEMARKS' | translate }}
    </div>
    <div class="card-text">
      <span *cloudMultiLang="'de'">
        <p>
          5tModularity<sup>®</sup> ist eine eingetragene Gemeinschaftsmarke der Müller-BBM VibroAkustik Systeme GmbH in
          der Europäischen Union und eine eingetragene internationale Marke der Müller-BBM VibroAkustik Systeme GmbH in
          den Ländern Australien, China, Japan und USA. Markenanmeldungen wurden in den Ländern Indien und Schweiz
          eingereicht.
        </p>
        <p>Active Directory<sup>®</sup> ist ein eingetragenes Warenzeichen der Microsoft Corporation.</p>
        <p>ADAT<sup>®</sup> ist ein eingetragenes Warenzeichen der Alesis Corporation.</p>
        <p>Adobe<sup>®</sup> ist ein eingetragenes Warenzeichen von Adobe Systems, Inc.</p>
        <p>
          Amazon Web Services<sup>®</sup>, das Amazon Web Services Logo, AWS<sup>®</sup>, das Powered by Amazon Web
          Services Logo und Amazon EC2<sup>®</sup> sind eingetragene Marken oder Warenzeichen von Amazon Technologies,
          Inc. oder ihrer Tochtergesellschaften in den USA und/oder anderen Ländern.
        </p>
        <p>AMD, Athlon, Opteron, AMD Athlon und AMD Opteron sind Warenzeichen von Advanced Micro Devices, Inc.</p>
        <p>
          APACHE<sup>®</sup>, HADOOP<sup>®</sup>, OPENOFFICE<sup>®</sup>, FLEX<sup>®</sup>, LUCENE<sup>®</sup>
          und alle mit den vorgenannten verwandte Marken, Warenzeichen und grafischen Logos sind Marken oder
          eingetragene Marken der Apache Software Foundation (ASF) in den USA und/oder anderen Ländern.
        </p>
        <p>
          Apple<sup>®</sup>, App Store<sup>®</sup>, Safari<sup>®</sup>, Bonjour<sup>®</sup>, iPod<sup>®</sup>,
          iPhone<sup>®</sup>, iPad<sup>®</sup> sind eingetragene Marken oder Warenzeichen von Apple Inc. in den USA
          und/oder anderen Ländern.
        </p>
        <p>AUTOSAR ist eine Handelsmarke der AUTOSAR GbR.</p>
        <p>
          Brüel & Kjær<sup>®</sup> ist eine eingetragene internationale Marke der Brüel & Kjær Sound & Vibration
          Measurement A/S, Nærum, Dänemark, in der Europäischen Union, China, Japan, Norwegen und USA.
        </p>
        <p>
          Celeron<sup>®</sup> ist ein eingetragenes Warenzeichen der Intel Corporation oder ihrer Tochtergesellschaften
          in den USA oder anderen Ländern.
        </p>
        <p>
          Computronix<sup>®</sup> ist eine eingetragene Marke von Computronix (Canada) Ltd. in den USA und/oder anderen
          Ländern.
        </p>
        <p>CORBA<sup>®</sup> ist ein eingetragenes Warenzeichen der Object Management Group, Inc.</p>
        <p>
          Creative Commons<sup>®</sup>, CC<sup>®</sup>, CC+<sup>®</sup> sind eingetragene Marken der Creative Commons
          Corporation in den Vereinigten Staaten und/oder anderen Ländern.
        </p>
        <p>
          Debian ist ein registriertes US-Markenzeichen der Software in the Public Interest, Inc., verwaltet vom
          Debian-Projekt
        </p>
        <p>
          Django<sup>®</sup> ist eine eingetragene Marke der Django Software Foundation (DSF) in den USA und/oder
          anderen Ländern.
        </p>
        <p>
          edp Müller-BBM<sup>®</sup> ist eine eingetragene Bild-Gemeinschaftsmarke der Müller-BBM VibroAkustik Systeme
          GmbH in der Europäischen Union und eine eingetragene internationale Bildmarke der Müller-BBM VibroAkustik
          Systeme GmbH in den Ländern Australien, Indien, Japan, Norwegen, Südafrika, Südkorea und USA.
          Markenanmeldungen wurden in den Ländern China und Schweiz eingereicht.
        </p>
        <p>
          Elastic, Elastic Cloud, Elastic Cloud Enterprise, Elasticsearch, Elastic {{ '{ON}' }}, Kibana und Logstash
          sind Marken von Elasticsearch B.V., eingetragen in den USA und in anderen Ländern.
        </p>
        <p>
          EtherCAT<sup>®</sup> ist ein eingetragenes Warenzeichen der EtherCAT Technology Group, Nürnberg, Deutschland.
        </p>
        <p>
          Flexera und InstallShield<sup>®</sup> sind eingetragene Marken und Warenzeichen der Flexera Software LLC in
          den USA und/oder anderen Ländern.
        </p>
        <p>FlexRay™ ist eine Marke des FlexRay Consortium.</p>
        <p>Gill<sup>®</sup> ist ein eingetragenes Warenzeichen der Gill-Unternehmensgruppe.</p>
        <p>
          GITHUB, OCTOCAT, ELECTRON und ATOM sind Marken oder eingetragene Marken von GitHub, Inc. in den USA und/oder
          anderen Ländern.
        </p>
        <p>
          GNU, FSF und Free Software Foundation sind eingetragene Marken der Free Software Foundation Corporation in den
          USA und/oder anderen Ländern.
        </p>
        <p>
          Google™, Google Play™, Google Chrome™, Chrome™, Android™ sind eingetragene Marken oder Warenzeichen von Google
          LLC in den USA und/oder anderen Ländern.
        </p>
        <p>HEAD acoustics<sup>®</sup> ist ein eingetragenes Warenzeichen der HEAD acoustics GmbH.</p>
        <p>
          HEWLETT PACKARD ENTERPRISE, HEWLETT PACKARD, HPE, das Element Logo, das Hewlett Packard Enterprise Logo, das
          HPE Logo, das Hewlett Packard Labs Logo, OpenGL und das oval Logo sowie weitere von Hewlett Packard Enterprise
          genutzte Marken sind eingetragene Marken oder Warenzeichen der Hewlett Packard Enterprise Company und/oder
          ihrer Tochtergesellschaften in den USA und/oder anderen Ländern.
        </p>
        <p>Hydropuls<sup>®</sup> ist ein eingetragenes Warenzeichen der Instron Corporation.</p>
        <p>IBM<sup>®</sup> ist ein eingetragenes Warenzeichen der IBM Corporation, USA.</p>
        <p>ICP<sup>®</sup> ist ein eingetragenes Warenzeichen der PCB Group.</p>
        <p>
          Intel<sup>®</sup> ist ein eingetragenes Warenzeichen der Intel Corporation oder ihrer Tochtergesellschaften in
          den USA oder anderen Ländern.
        </p>
        <p>
          jQuery<sup>®</sup>, jQuery Mobile<sup>®</sup>, jQuery UI<sup>®</sup>, Moment.js<sup>®</sup> und JS
          Foundation<sup>®</sup> sind eingetragene Marken der OpenJS Foundation in den USA und/oder anderen Ländern.
        </p>
        <p>Kerberos ist eine Marke des Massachusetts Institute of Technology.</p>
        <p>Kinect<sup>®</sup> ist eine eingetragene Marke der Microsoft Corporation.</p>
        <p>Linux<sup>®</sup> ist eingetragenes Warenzeichen von Linus Torvalds.</p>
        <p>
          Massachusetts Institute of Technology und MIT sind eingetragene Marken des Massachusetts Institute of
          Technology in den USA und/oder anderen Ländern.
        </p>
        <p>
          MATLAB<sup>®</sup> ist ein eingetragenes Warenzeichen von The Mathworks, Inc. in den USA und/oder anderen
          Ländern.
        </p>
        <p>
          Microsoft<sup>®</sup>, Windows<sup>®</sup>, Windows Vista<sup>®</sup>, Windows Server<sup>®</sup>, Office
          365<sup>®</sup>, SharePoint<sup>®</sup>, Active Directory<sup>®</sup>, Microsoft Edge<sup>®</sup>, Internet
          Explorer<sup>®</sup>, Excel<sup>®</sup>, PowerPoint<sup>®</sup>, Outlook<sup>®</sup> , Visual Basic<sup
            >®</sup
          >
          , Visual Studio<sup>®</sup> und Microsoft<sup>®</sup> SQL Server<sup>®</sup> sind eingetragene Marken oder
          Warenzeichen der Microsoft Corporation in den USA und/oder anderen Ländern.
        </p>
        <p>Mongoose Web Server ist ein Warenzeichen der Cesanta Software Limited.</p>
        <p>
          Mozilla<sup>®</sup>, Firefox und das Firefox Logo sind eingetragene Marken oder Warenzeichen der Mozilla
          Foundation in den USA und/oder anderen Ländern.
        </p>
        <p>
          MTG<sup>®</sup> ist eine eingetragene deutsche Wort-/Bildmarke der Firma Microtech Gefell GmbH, Gefell,
          Deutschland.
        </p>
        <p>
          Müller-BBM VibroAkustik Systeme<sup>®</sup>, MBBM-VAS<sup>®</sup>, PAK<sup>®</sup>, PAK Müller-BBM<sup>®</sup>
          und VAS Graphics2Go<sup>®</sup> sind Marken oder eingetragene Marken der Müller-BBM VibroAkustik Systeme GmbH
          in der Europäischen Union, den USA und/oder anderen Ländern.
        </p>
        <p>NGINX<sup>®</sup> ist ein eingetragenes Warenzeichen von NGINX, Inc. in den USA und/oder anderen Ländern.</p>
        <p>OpenGL<sup>®</sup> ist ein eingetragenes Warenzeichen der Firma Silicon Graphics.</p>
        <p>OpenLDAP ist ein Warenzeichen der OpenLDAP Foundation.</p>
        <p>
          OpenSSL<sup>®</sup> ist eine eingetragene Marke der OpenSSL Software Foundation in den USA und/oder anderen
          Ländern.
        </p>
        <p>OpenStreetMap® ist eine eingetragene Marke der OpenStreetMap Foundation.</p>
        <p>
          OpenVPN<sup>®</sup> ist ein eingetragenes Warenzeichen der OpenVPN Inc. in den USA und/oder anderen Ländern.
        </p>
        <p>
          Oracle<sup>®</sup>, Oracle<sup>®</sup> Application Server, MySQL<sup>®</sup>, JavaScript<sup>®</sup>
          und Java™ sind eingetragene Marken oder Warenzeichen der Oracle Corporation und/oder ihrer
          Tochtergesellschaften in den USA und/oder anderen Ländern.
        </p>
        <p>
          OSI, Open Source Initiative, Open Source Initiative Approved und das OSI Logo, einzeln oder in Kombination,
          sind Marken der Open Source Initiative in den Vereinigten Staaten und/oder anderen Ländern.
        </p>
        <p>
          PCB<sup>®</sup> und ICP<sup>®</sup> sind eingetragene Marken oder Warenzeichen der PCB Group, Inc. in den USA
          und/oder anderen Ländern.
        </p>
        <p>
          Pentium<sup>®</sup> ist ein eingetragenes Warenzeichen der Intel Corporation oder ihrer Tochtergesellschaften
          in den USA oder anderen Ländern.
        </p>
        <p>
          PostgreSQL, Postgres und das PostgreSQL Elefant-Logo (Slonik) sind eingetragene Marken der PostgreSQL
          Community Association of Canada in den USA und/oder anderen Ländern.
        </p>
        <p>PostScript<sup>®</sup> ist ein eingetragenes Warenzeichen von Adobe Systems Inc. u. a. in USA.</p>
        <p>
          Project Clarity ist ein Open Source Design-System, bereitgestellt von VMware, Inc. unter
          https://clarity.design/. VMware und das vmware Logo sind eingetragene Marken von VMware, Inc. in den USA
          und/oder anderen Ländern.
        </p>
        <p>
          Python<sup>®</sup>, PyCon und die Python-Logos sind Marken oder eingetragene Marken der Python Software
          Foundation (PSF) in den USA und/oder anderen Ländern.
        </p>
        <p>
          QuantusSeries und das Q-Symbol sind Marken oder eingetragene Marken von Mecalc Holdings (Pty) Limited in der
          Europäischen Union und/oder anderen Ländern.
        </p>
        <p>Samsung ist eine Marke von Samsung Electronics Co., Ltd.</p>
        <p>Saxon und Saxonica, sind Warenzeichen der Saxonica Limited.</p>
        <p>
          SourceForge.net<sup>®</sup>, SourceForge und Slashdot sind eingetragene Marken von SourceForge Media, LLC. in
          den USA und/oder anderen Ländern.
        </p>
        <p>TclPro und Ajuba Solutions sind Marken von Ajuba Solutions Inc. in den USA und/oder anderen Ländern.</p>
        <p>
          Vive, HTC, das HTC Logo und alle anderen HTC Produkt- und Dienstnamen sind eingetragene oder nicht
          eingetragene Handelsmarken der HTC Corporation und ihrer Tochtergesellschaften in den USA und/oder anderen
          Ländern.
        </p>
        <p>WebSphere<sup>®</sup> ist eine eingetragene Marke von IBM Corporation, USA.</p>
        <p>
          Xeon<sup>®</sup> ist eine Marke der Intel Corporation oder ihrer Tochtergesellschaften in den USA oder anderen
          Ländern.
        </p>
        <p></p>
        <p>
          Weitere Namen, Beschreibungen oder Zeichen können Marken anderer entsprechender Eigentümer in den jeweiligen
          Ländern sein.
        </p>
      </span>
      <span *cloudMultiLang="'NOT de'">
        <p>
          5tModularity<sup>®</sup>; is a registered community mark of Müller-BBM VibroAkustik Systeme GmbH in the
          European Union and a registered international mark of Müller-BBM VibroAkustik Systeme GmbH in the countries
          Australia, China, Japan, and USA. Trademark applications have been filled in the countries India and
          Switzerland.
        </p>
        <p>Active Directory<sup>®</sup> is a registered trademark of Microsoft Corporation.</p>
        <p>ADAT<sup>®</sup> is a registered trademark of the Alesis Corporation.</p>
        <p>Adobe<sup>®</sup> is a registered trademark of Adobe Systems, Inc.</p>
        <p>
          Amazon Web Services<sup>®</sup>, the Amazon Web Services logo, AWS<sup>®</sup>, the Powered by Amazon Web
          Services logo, and Amazon EC2<sup>®</sup> are trademarks or registered trademarks of Amazon Technologies, Inc.
          or its affiliates in the United States and/or other countries.
        </p>
        <p>AMD, Athlon, Opteron, AMD Athlon, and AMD Opteron are trademarks of Advanced Micro Devices, Inc.</p>
        <p>
          APACHE<sup>®</sup>, HADOOP<sup>®</sup>, OPENOFFICE<sup>®</sup>, FLEX<sup>®</sup>, LUCENE<sup>®</sup>
          and all aforesaid-related trademarks, service marks, and graphic logos are trademarks or registered trademarks
          of The Apache Software Foundation (ASF) in the United States and/or other countries.
        </p>
        <p>
          Apple<sup>®</sup>, Safari<sup>®</sup>, Bonjour<sup>®</sup>, iPod<sup>®</sup>, iPhone<sup>®</sup>, and iPad<sup
            >®</sup
          >
          are trademarks or registered trademarks of Apple Inc. in the United States and/or other countries.
        </p>
        <p>AUTOSAR is a trademark of AUTOSAR GbR.</p>
        <p>
          Brüel & Kjær® is a registered international trademark of Brüel & Kjær Sound & Vibration Measurement A/S,
          Nærum, Denmark in the European Community, China, Japan, Norway and US.
        </p>
        <p>
          Celeron<sup>®</sup> is a registered trademark of the Intel Corporation or subsidiaries of Intel in the USA or
          other countries.
        </p>
        <p>
          Computronix<sup>®</sup> is a registered trademark of Computronix (Canada) Ltd. in the United States and/or
          other countries.
        </p>
        <p>CORBA<sup>®</sup> is a registered trademark of Object Management Group, Inc.</p>
        <p>
          Creative Commons<sup>®</sup>, CC<sup>®</sup>, CC+<sup>®</sup> are registered trademarks of Creative Commons
          Corporation in the United States and/or other countries.
        </p>
        <p>
          Debian is a registered United States trademark of Software in the Public Interest, Inc., managed by the Debian
          project.
        </p>
        <p>
          Django<sup>®</sup> is a registered trademark of the Django Software Foundation (DSF) in the United States
          and/or other countries.
        </p>
        <p>
          edp Müller-BBM<sup>®</sup> is a registered figurative community mark of Müller-BBM VibroAkustik Systeme GmbH
          in the European Union and a registered international figurative mark of Müller-BBM VibroAkustik Systeme GmbH
          in the countries Australia, India, Japan, Norway, South Africa, South Korea, and USA. Trademark applications
          have been filled in the countries China and Switzerland.
        </p>
        <p>
          Elastic, Elastic Cloud, Elastic Cloud Enterprise, Elasticsearch, Elastic{{ '{ON}' }}, Kibana, and Logstash are
          trademarks of Elasticsearch B.V., registered in the U.S. and in other countries.
        </p>
        <p>EtherCAT<sup>®</sup> is a registered trademark of EtherCAT Technology Group, Nuremberg, Germany.</p>
        <p>
          Flexera and InstallShield<sup>®</sup> are trademarks or registered trademarks of Flexera Software in the
          United States and/or other countries.
        </p>
        <p>FlexRay™ is a trademark of the FlexRay Consortium.</p>
        <p>Gill<sup>®</sup> is a registered trademark of the Gill group of companies.</p>
        <p>
          GITHUB, OCTOCAT, ELECTRON, and ATOM are are trademarks or registered trademarks of GitHub, Inc. in the United
          States and/or other countries.
        </p>
        <p>
          GNU, FSF, and Free Software Foundation are registered trademarks of Free Software Foundation Corporation in
          the United States and/or other countries.
        </p>
        <p>
          Google™, Google Play™, Google Chrome™, Chrome™, Android™ are trademarks or registered trademarks of Google LLC
          in the United States and/or other countries.
        </p>
        <p>HEAD acoustics<sup>®</sup> is a registered trademark of HEAD acoustics GmbH.</p>
        <p>
          HEWLETT PACKARD ENTERPRISE, HEWLETT PACKARD, HPE, the Element Logo, the Hewlett Packard Enterprise Logo, the
          HPE Logo, the Hewlett Packard Labs Logo, variations of the foregoing logos, OpenGL, the oval logo, and other
          trademarks used by Hewlett Packard Enterprise are registered trademarks of Hewlett Packard Enterprise Company
          and/or its affiliates in the United States and/or other countries.
        </p>
        <p>Hydropuls<sup>®</sup> is a registered trademark of Instron Corporation.</p>
        <p>IBM<sup>®</sup> is a registered trademark of IBM Corporation, USA.</p>
        <p>ICP<sup>®</sup> is a registered trademark of PCB Group.</p>
        <p>
          Intel<sup>®</sup> is a registered trademark of the Intel Corporation or subsidiaries of Intel in the USA or
          other countries.
        </p>
        <p>
          jQuery<sup>®</sup>, jQuery Mobile<sup>®</sup>, jQuery UI<sup>®</sup>, Moment.js<sup>®</sup> and JS
          Foundation<sup>®</sup> are registered trademarks of the OpenJS Foundation in the United States and/or other
          countries.
        </p>
        <p>Kerberos is a trademark of Massachusetts Institute of Technology.</p>
        <p>Kinect<sup>®</sup> is a registered trademark of Microsoft Corporation.</p>
        <p>Linux<sup>®</sup> is a registered trademark of Linus Torvalds.</p>
        <p>
          Massachusetts Institute of Technology and MIT are registered trademarks of the Massachusetts Institute of
          Technology in the United States and/or other countries.
        </p>
        <p>
          MATLAB<sup>®</sup> is a registered trademark of The Mathworks, Inc. Foundation in the United States and/or
          other countries.
        </p>
        <p>
          Microsoft<sup>®</sup>, Windows<sup>®</sup>, Windows Vista<sup>®</sup>, Windows Server<sup>®</sup>, Office
          365<sup>®</sup>, SharePoint<sup>®</sup>, Active Directory<sup>®</sup>, Microsoft Edge<sup>®</sup>, Internet
          Explorer<sup>®</sup>, Excel<sup>®</sup>, PowerPoint<sup>®</sup>, Outlook<sup>®</sup>, Visual
          Basic<sup>®</sup>, Visual Studio<sup>®</sup>, and Microsoft<sup>®</sup> SQL Server<sup>®</sup> are registered
          trademarks of Microsoft Corporation in the United States and/or other countries.
        </p>
        <p>Mongoose Web Server is a trademark of Cesanta Software Limited.</p>
        <p>
          Mozilla<sup>®</sup>, Firefox and the Firefox logo are registered trademarks of the Mozilla Foundation in the
          United States and/or other countries.
        </p>
        <p>
          MTG<sup>®</sup> is a registered German word/figurative trademark of Microtech Gefell GmbH, Gefell, Germany.
        </p>
        <p>
          Müller-BBM VibroAkustik Systeme<sup>®</sup>, MBBM-VAS<sup>®</sup>, PAK<sup>®</sup>, PAK Müller-BBM<sup>®</sup>
          and VAS Graphics2Go<sup>®</sup> are trademarks or registered trademarks of Müller-BBM VibroAkustik Systeme
          GmbH in the European Union, the United States, and/or other countries.
        </p>
        <p>NGINX<sup>®</sup> is a registered trademark of NGINX, Inc. in the United States and/or other countries.</p>
        <p>OpenGL<sup>®</sup> is a registered trademark of Silicon Graphics.</p>
        <p>OpenLDAP is a trademark of the OpenLDAP Foundation.</p>
        <p>
          OpenSSL<sup>®</sup> is a registered trademark of the OpenSSL Software Foundation in the United States and/or
          other countries.
        </p>
        <p>OpenStreetMap<sup>®</sup> is a registered trademark of the OpenStreetMap Foundation.</p>
        <p>
          OpenVPN<sup>®</sup> is a registered trademark of OpenVPN Inc. in the United States and/or other countries.
        </p>
        <p>
          Oracle<sup>®</sup>, Oracle<sup>®</sup> Application Server, MySQL<sup>®</sup>, JavaScript<sup>®</sup>, and
          Java™ are registered trademarks of Oracle Corporation and/or its affiliates in the United States and/or other
          countries.
        </p>
        <p>
          OSI, Open Source Initiative, Open Source Initiative Approved, and the OSI logo, either separately or in
          combination, are trademarks of the Open Source Initiative in the United States and/or other countries.
        </p>
        <p>
          PCB<sup>®</sup> and ICP<sup>®</sup> are registered trademarks of PCB Group, Inc. in the United States and/or
          other countries.
        </p>
        <p>
          Pentium<sup>®</sup> is a registered trademark of the Intel Corporation or subsidiaries of Intel in the USA or
          other countries.
        </p>
        <p>
          PostgreSQL, Postgres, and the PostgreSQL elephant logo (Slonik) are registered trademarks of the PostgreSQL
          Community Association of Canada in the United States and/or other countries.
        </p>
        <p>PostScript<sup>®</sup> is a trademark of Adobe Systems Inc. in the USA and other countries.</p>
        <p>
          Project Clarity is an open source design system, provided by VMware, Inc. at https://clarity.design/. VMware
          and the vmware logo are registered trademarks of VMware, Inc. in the United States and/or other countries.
        </p>
        <p>
          Python<sup>®</sup>, PyCon and the Python logos are trademarks or registered trademarks of the Python Software
          Foundation (PSF) in the United States and/or other countries.
        </p>
        <p>
          QuantusSeries and the Q icon are trademarks or registered trademarks of Mecalc Holdings (Pty) Limited in the
          European Union and/or other countries.
        </p>
        <p>Samsung is a trademark of Samsung Electronics Co., Ltd.</p>
        <p>Saxon and Saxonica, are trademarks of Saxonica Limited.</p>
        <p>
          SourceForge.net<sup>®</sup>, SourceForge, and Slashdot are registered trademarks of SourceForge Media, LLC. in
          the United States and/or other countries.
        </p>
        <p>
          TclPro and Ajuba Solutions are trademarks of Ajuba Solutions Inc. in the United States and/or other countries.
        </p>
        <p>
          Vive, HTC, the HTC logo, and all other HTC product and service names are the trademarks in the U.S. and/or
          other countries of HTC Corporation and its affiliates.
        </p>
        <p>WebSphere<sup>®</sup> is a registered trademark of IBM Corporation, USA.</p>
        <p>
          Xeon<sup>®</sup> is a registered trademark of the Intel Corporation or subsidiaries of Intel in the USA or
          other countries.
        </p>
        <p></p>
        <p>
          Other names, descriptions or marks may be trademarks of their respective owners in the respective countries.
        </p>
      </span>
    </div>
  </div>
</div>
