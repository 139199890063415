import { Type } from '@angular/core';
import { Observable } from 'rxjs';

// Base type for Components that intend to be displayed as Tiles
export interface MeasurementTile {
  title: string;
  iconClass: string;
  selected$: Observable<boolean>;
  backgroundImage: string;
  counter$: Observable<number>;
  loading$: Observable<boolean>;
}

export const measurementAttributes = ['title', 'iconClass', 'backgroundImage', 'selected$', 'counter$', 'loading$'];

// Describes valid component identifiers for dynamic measurement-detail components
export type dynamicComponentIdentifiers = 'overview' | 'unitsUnderTest' | 'testSequencesAndAnys' | 'datasetDescription';

export type attributeDisplayType = 'all' | 'nonempty';

// This interface and a list of these entries enable descriptively creating the structure of the measurement-details component
export interface ComponentDescription {
  identifier: dynamicComponentIdentifiers;
  available?: Observable<boolean>;
  translationKey: string;
  attributes: Partial<MeasurementTile>;
  panelComponent: Type<any>;
  tileComponent: Type<MeasurementTile>;
}

export interface Measurement {
  measurementBrowseUrl: string;
  measurementId: string;
  depotId?: string;
  loading: string[];
  loaded: string[];
  selectedDynamicComponent?: dynamicComponentIdentifiers;
  resources: { [key in dynamicComponentIdentifiers]: any };
}

export enum archivedStatus {
  default,
  archived,
  restored
}

export interface MeasurementOverview {
  name: string;
  measurementId: string;
  size: number;
  start?: string;
  numberDataSets: number;
  archivedStatus: archivedStatus;
  unitUnderTests: string;
  generalData: Attributes;
}

export interface DatasetDescriptions {
  datasets: DatasetDescription[];
}

export interface DatasetDescription {
  id: string;
  depotId: string;
  attributes: any;
  PAKParameters: any;
}

export interface UnitUnderTest {
  id: string;
  name: string;
  type: string;
  attributes: Attributes;
  parts: Parts;
  extended: boolean; //keep tabs open when even if OnInit() is triggered for detail view (e.g. by swiching displayed attributes)
}

export interface UnitsUnderTestParts {
  [index: string]: UnitsUnderTestPart[];
}

export interface UnitsUnderTestPart {
  id: string;
  type: string;
  name: string;
  attributes: Attributes;
  parts: UnitsUnderTestParts;
}

export interface TestSequence {
  id: string;
  type: string;
  name: string;
  aoBasename: string;
  attributes: Attributes;
  parts: TestSequenceParts;
  extended: boolean; //keep tabs open when even if OnInit() is triggered for detail view (e.g. by swiching displayed attributes)
}

export interface TestSequenceParts {
  [index: string]: TestSequence;
}

export interface Attributes {
  [index: string]: AttributeValue;
}

export interface Parts {
  [index: string]: Attributes;
}

export type AttributeValue = string | number | boolean | null;

export interface DepotBrowseContent {
  id: string;
}

// Describes valid component identifiers for dynamic measurement-detail components
export type MeasurementDownloadConversion =
  | 'b0338830-520a-4172-a181-fed3b92c03c0' //keep
  | 'f8ca9ee6-efc4-4c9c-b3ad-ee8db92d4cd6' //ZATFX (zip bundle)
  | '27d2801f-aaee-4900-abee-b2d9823574a2'; //ZATFX (single)

export interface MeasurementDownload {
  id: string;
  contents: DepotBrowseContent[];
  conversion: MeasurementDownloadConversion;
  progress?: number;
  monitorUrl?: string;
  cancelUrl?: string;
  error?: string | null;
  hasDownloadStarted?: boolean;
}

export type MeasurementPath = {
  depot_id: string;
  depot_name: string;
  project: string;
  job: string;
  subtitle: string;
};

export interface IntermediateMeasuementDetailsBeforeMove {
  measurementId: string;
  path: MeasurementPath;
}

export interface DryRunMeasurementDetails extends IntermediateMeasuementDetailsBeforeMove {
  willOverWrite: boolean;
}

export interface MeasurementMoveDryRunResults {
  dryRunMeasurements: DryRunMeasurementDetails[];
  projectExists: boolean;
  jobExists: boolean;
}

export interface MeasurementMove {
  id: string;
  measurementId: string;
  subtitle: string;
  description?: string;
  sourcePath?: string;
  targetPath?: MeasurementPath;
  destinationDepotId: string;
  overwrite?: boolean;
  // deleteSource?: boolean;
  moveState: 'init' | 'moving' | 'done' | 'error';
  errorMsg?: string;
  statusUrl?: string; //URL;
  cancelUrl?: string; //URL;
  progress?: number;
  indexUpdateState?: {
    source: 'init' | 'updating' | 'done' | 'error';
    target: 'init' | 'updating' | 'done' | 'error';
  };
}
