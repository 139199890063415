import { Token as LightningToken } from '@arction/eventer';
import {
  Axis,
  AxisOptions,
  AxisTickStrategies,
  ChartXY,
  ColorHEX,
  CustomTick,
  Dashboard,
  HeatmapGridSeriesIntensityValues,
  HeatmapScrollingGridSeriesIntensityValues,
  PalettedFill,
  SolidFill,
  SolidLine,
  Themes,
  emptyLine,
  lightningChart
} from '@arction/lcjs';
import { TranslateService } from '@ngx-translate/core';
import { exhaustiveMatchingGuard } from '../../../shared/utility-functions/exhaustiveMatchingGuard';
import { pakColorPalette } from '../../utils/colorPalettes';
import { AxisScale } from '../../utils/former-streaming-messages.types';
import { StreamingChart } from '../../utils/streaming-chart.types';
import { transformOriginalValuesIntoDisplayValues } from '../genericTransformationFunctions';
import { getSuperscript } from '../lightningChartCustomCalculations';
import { LightningPlot, LocalValues3D } from '../lightningPlot';
import { DeepReadonly, LCconstructorOptions3D, ScalesConfig3D } from '../lightningPlot.types';
import { calcColorPaletteII } from './lightningChartCustomCalculations3D';
import { StreamingFacade } from '../../+state/streaming.facade';
import { generateFreqWeightString } from '../infoMapping';
import { timeDifference } from '../../utils/time.utils';

const ENABLE_AXIS_CURSOR_LABEL = false;

export type Update3Ddata = {
  dataValues: number[][];
  zValues: number[];
  timeValues: bigint[];
  eofReached: boolean;
};

export class LightningPlot3DHeatmap extends LightningPlot {
  scrollingHeatMap: HeatmapScrollingGridSeriesIntensityValues | undefined;

  values: LocalValues3D = {
    dataValues: [],
    externalTrackValues: {
      absoluteInt128Time: [],
      values: []
    },
    stepValues: {
      steps: [],
      zValues: [],
      times: []
    }
  };

  yBar: ChartXY | undefined;
  yBarYAxis: Axis;
  xAxisScale: AxisScale = { direction: 'x', scale: 'lin' };
  yAxisScale: AxisScale = { direction: 'y', scale: 'lin' };
  yBarTouched: boolean = false;
  yBarIgnoreProgrammaticScaleChange: boolean = false;
  yBarOnScaleChangeCallBackIdentifier: LightningToken | undefined;
  yHeatMap?: HeatmapGridSeriesIntensityValues;

  appearanceMin: number;
  appearanceMax: number;

  customYTicks: CustomTick[] = []; //array value defines custom of tick for y axis
  frequencyValues3D: number[];
  Y_HEATMAP_STEPSIZE: number = 101;

  yBarElementId: DeepReadonly<string>;

  constructor(options: LCconstructorOptions3D, translate: TranslateService, streamingFacade: StreamingFacade) {
    super(options, translate, streamingFacade);
    this.yBarElementId = options.yBarElementId;
    this.setupPlot();
  }

  protected override setupPlot() {
    this.lastTrackValue = undefined;

    if (!this.disposed) {
      this.dashBoard = this.createDashboard();
      this.chart = this.createChart();

      this.updateInfoMapping();
      this.yBar = this.createYBarChart();
    }
  }

  private createDashboard(): Dashboard {
    const dashBoard = lightningChart(this.licenseConfig.license, {
      appTitle: this.licenseConfig.appTitle,
      company: this.licenseConfig.company
    }).Dashboard({
      numberOfRows: 1,
      numberOfColumns: 1,
      container: this.htmlElementId,
      theme: Themes.light
    });
    dashBoard.setColumnWidth(0, 11);
    dashBoard.setColumnWidth(1, 1);
    dashBoard.setSplitterStyle(emptyLine);
    return dashBoard;
  }

  private createChart(): ChartXY {
    // TODO: Extract / simplify mapping

    let axisYConfig: AxisOptions = { type: 'linear' };
    if (this.scalesConfig && this.scalesConfig.yAxis) {
      switch (this.scalesConfig.yAxis) {
        case 'lin': {
          axisYConfig = { type: 'linear' };
          break;
        }
        case 'log': {
          axisYConfig = { type: 'logarithmic', base: 10 };
          break;
        }
        case 'db': {
          axisYConfig = { type: 'logarithmic', base: 10 };
          break;
        }
        case 'third': {
          axisYConfig = { type: 'linear' };
          break;
        }
        default:
          exhaustiveMatchingGuard(this.scalesConfig.yAxis);
      }
    }

    let axisXConfig: AxisOptions = { type: 'linear' };
    if (this.scalesConfig && this.scalesConfig.xAxis) {
      switch (this.scalesConfig.xAxis) {
        case 'lin': {
          axisXConfig = { type: 'linear' };
          break;
        }
        case 'log': {
          axisXConfig = { type: 'logarithmic', base: 10 };
          break;
        }
        case 'db': {
          axisXConfig = { type: 'logarithmic', base: 10 };
          break;
        }
        case 'third': {
          axisXConfig = { type: 'linear' };
          break;
        }
        default:
          exhaustiveMatchingGuard(this.scalesConfig.xAxis);
      }
    }

    const chart = this.dashBoard
      .createChartXY({
        columnIndex: 0,
        columnSpan: 1,
        rowIndex: 0,
        rowSpan: 1,
        defaultAxisX: axisXConfig as AxisOptions,
        defaultAxisY: axisYConfig as AxisOptions
      })
      .setAnimationsEnabled(false)
      .setBackgroundFillStyle(new SolidFill({ color: ColorHEX('#ffffff00') }))
      .setTitle(this.generatePlotTitle(this.tpDatasetParams.currentDatasetParams));

    this.scrollingHeatMap = chart
      ?.addHeatmapScrollingGridSeries({
        resolution: this.tpDatasetParams.currentDatasetParams.plottingParameters?.rangeX?.num ?? 100,
        scrollDimension: 'rows'
      })
      .setWireframeStyle(emptyLine)
      .setPixelInterpolationMode('disabled');

    this.scrollingHeatMap?.setName('');
    return chart;
  }

  yBarAxisScaleChangeHandler(axis, start, end) {
    if (this.yBarIgnoreProgrammaticScaleChange) {
      this.yBarIgnoreProgrammaticScaleChange = false;
    } else {
      setTimeout(() => {
        this.setLUCColor({ direction: 'color', scale: this.scalesConfig.color }, start, end, false);
      }, 0);
    }
  }

  private createYBarChart(): ChartXY {
    const NUMBER_COLUMS = 2;

    const yBarOptions = {
      columns: NUMBER_COLUMS,
      rows: this.Y_HEATMAP_STEPSIZE,
      start: { x: 0, y: 0 },
      end: { x: NUMBER_COLUMS - 1, y: 100 },
      pixelate: false
    };

    const yBar = lightningChart(this.licenseConfig.license, {
      appTitle: this.licenseConfig.appTitle,
      company: this.licenseConfig.company
    }).ChartXY({
      container: this.yBarElementId,
      theme: Themes.light
    });

    this.yHeatMap = yBar
      .addHeatmapGridSeries({
        dataOrder: 'rows',
        columns: NUMBER_COLUMS, // we need to colums to avoid flickering
        rows: this.Y_HEATMAP_STEPSIZE
      })
      .setFillStyle(new PalettedFill({ lut: pakColorPalette(1.0) }))
      .setWireframeStyle(emptyLine)
      .setCursorEnabled(false)
      .setMouseInteractions(false);

    this.yHeatMap?.setMouseInteractions(false);
    // .setMouseInteractionWheelZoom(false);

    yBar
      .setPadding({ bottom: 63, left: 0, right: 10, top: 40 })
      .setAnimationsEnabled(false)
      .setBackgroundFillStyle(new SolidFill({ color: ColorHEX('#ffffff00') }))
      .setTitle('')
      // .setMouseInteractionWheelZoom(false)
      .setMouseInteractions(false);

    // Disable default x and y axis for color scale bar
    yBar
      .getDefaultAxisX()
      .setInterval({ start: yBarOptions.start.x, end: yBarOptions.end.x })
      .setTickStrategy(AxisTickStrategies.Empty)
      .setTitleMargin(0)
      .setScrollStrategy(undefined)
      .setMouseInteractions(false);
    yBar
      .getDefaultAxisY()
      .setInterval({ start: yBarOptions.start.y, end: yBarOptions.end.y })
      .setTickStrategy(AxisTickStrategies.Empty)
      .setTitleMargin(0)
      .setScrollStrategy(undefined)
      .setMouseInteractions(false);

    this.handleScaleConfigChange(this.scalesConfig);

    this.setupYBarOnScaleChangeCallBacks();
    // this.yBarYAxis.onIntervalChange((axis, start, end) => this.yBarAxisScaleChangeHandler(axis, start, end));

    // const step = 1.0 / this.Y_HEATMAP_STEPSIZE;
    // const matrixForYBar: number[][] = Array.from({ length: this.Y_HEATMAP_STEPSIZE }, (_, i) => [i * step, i * step]);
    //this.yHeatMap.invalidateIntensityValues(matrixForYBar);

    return yBar;
  }

  setupAxisFormatters() {
    this.chart.getDefaultAxisY().setTickStrategy(AxisTickStrategies.Numeric, (numericTicks) =>
      numericTicks
        .setCursorFormatter((value_or_index) => {
          if (ENABLE_AXIS_CURSOR_LABEL) {
            return this.infoMapping.axis.Y.formatter({ value_or_index, isStepIndex: true, includeUnit: true });
          } else {
            return '';
          }
        })
        .setMajorFormattingFunction((value_or_index) =>
          this.infoMapping.axis.Y.formatter({ value_or_index, isStepIndex: true, includeUnit: false })
        )
        .setMinorFormattingFunction((value_or_index) =>
          this.infoMapping.axis.Y.formatter({ value_or_index, isStepIndex: true, includeUnit: false })
        )
    );
    this.chart
      .getDefaultAxisX()
      .setTickStrategy(AxisTickStrategies.Numeric, (numericTicks) =>
        numericTicks
          .setCursorFormatter((value_or_index) => {
            if (ENABLE_AXIS_CURSOR_LABEL) {
              return this.infoMapping.axis.X.formatter({ value_or_index, isStepIndex: false, includeUnit: true });
            } else {
              return '';
            }
          })
          .setMajorFormattingFunction((value_or_index) =>
            this.infoMapping.axis.X.formatter({ value_or_index, isStepIndex: false, includeUnit: false })
          )
          .setMinorFormattingFunction((value_or_index) =>
            this.infoMapping.axis.X.formatter({ value_or_index, isStepIndex: false, includeUnit: false })
          )
      )
      .setNibMousePickingAreaSize(0);

    this.chart.getDefaultAxisY().onIntervalChange((defaultAxisY, start, end) => {
      this.checkRebounceOnIntervallChange(this.yAxisInterval.start, this.yAxisInterval.end, defaultAxisY, start, end);
    });
    this.chart.getDefaultAxisX().onIntervalChange((defaultAxisX, start, end) => {
      this.checkRebounceOnIntervallChange(this.xAxisInterval.start, this.xAxisInterval.end, defaultAxisX, start, end);
    });
  }

  public override handleScaleConfigChange(scalesConfig: ScalesConfig3D) {
    // Handle Y-Bar Type depending on scalesConfig
    this.yBarYAxis?.dispose();

    if (scalesConfig.color === 'log') {
      this.yBarYAxis = this.yHeatMap!.chart.addAxisY({
        type: 'logarithmic',
        base: 10,
        opposite: true
      });
    } else {
      this.yBarYAxis = this.yHeatMap!.chart.addAxisY({
        opposite: true
      });
    }

    this.yBarTouched = false;
    this.yBarYAxis.setNibMousePickingAreaSize(0);
    this.setupYBarOnScaleChangeCallBacks();

    this.updateInfoMapping();

    if (this.tpDatasetParams.currentDatasetParams.plottingParameters && this.values?.dataValues?.length > 0) {
      this.redrawHeatmap();
    }
  }

  // update functions
  public update3Ddata = (data: Update3Ddata) => {
    const fill = new PalettedFill({
      lut: pakColorPalette(1)
    });

    this.scrollingHeatMap?.setFillStyle(fill);

    this.values.dataValues.push(...data.dataValues);

    const startPoint: bigint = this.tpDatasetParams.currentDatasetParams.plottingParameters?.measZeroPoint ?? BigInt(0);
    data.timeValues.forEach((timeValue) => {
      const relTime = timeDifference(timeValue, startPoint);
      if (this.lastTrackValue !== undefined) {
        if (this.MINIMUM_Y_DELTA === undefined || Math.abs(relTime - this.lastTrackValue) < this.MINIMUM_Y_DELTA) {
          this.MINIMUM_Y_DELTA = Math.abs(relTime - this.lastTrackValue);
        }
      }
      this.lastTrackValue = relTime;
      this.values.stepValues?.times.push(timeValue);
    });

    data.zValues.forEach((zValue) => {
      this.values.stepValues?.zValues.push(zValue);
    });

    // console.log(
    //   'update3Ddata',
    //   ' values:' +
    //     data.dataValues.length +
    //     '; times:' +
    //     data.timeValues.length +
    //     ' zValues: ' +
    //     data.zValues.length +
    //     ' eof: ' +
    //     data.eofReached
    // );

    if (!this.initDone.axisTitles) {
      this.setupAxisFormatters(); // Move to setupPlot?
      this.initDone.axisTitles = true;
    }

    if (!this.initDone.frequencyValues) {
      let xStart = 0;
      let xDelta;
      if (this.tpDatasetParams.currentDatasetParams.plottingParameters?.hasOctave) {
        this.initNthOctaveXIndices();
        xDelta = 1;
        xStart = -xDelta / 2;
      } else {
        this.initFrequencyValues();
        xDelta = this.getFrequencyDelta();
        xStart = this.frequencyValues3D[0] - xDelta / 2;
      }
      this.initDone.frequencyValues = true;

      const yStart = -0.5; // 0; // 0.5;
      const yStep = 1;

      const chart = this.scrollingHeatMap?.chart;
      this.scrollingHeatMap?.dispose();
      this.scrollingHeatMap = chart
        ?.addHeatmapScrollingGridSeries({
          resolution: this.tpDatasetParams.currentDatasetParams.plottingParameters?.rangeX?.num ?? 100,
          scrollDimension: 'rows',
          start: { x: xStart, y: yStart },
          step: { x: xDelta, y: yStep }
        })
        .setWireframeStyle(emptyLine)
        .setPixelInterpolationMode('disabled');
    }

    const displayValues = transformOriginalValuesIntoDisplayValues(
      data.dataValues,
      this.scalesConfig.color,
      this.tpDatasetParams.currentDatasetParams
    );

    this.maybeUpdateAppearance(displayValues);

    this.scrollingHeatMap?.addIntensityValues(displayValues.values);

    this.setLUCColor(
      { direction: 'color', scale: this.scalesConfig.color },
      this.appearanceMin,
      this.appearanceMax,
      true
    );

    this.yAxisInterval = { start: this.scrollingHeatMap?.getYMin(), end: this.scrollingHeatMap?.getYMax() };
    this.xAxisInterval = { start: this.scrollingHeatMap?.getXMin(), end: this.scrollingHeatMap?.getXMax() };

    this.updateInfoMapping();
    this.initCursorFormatter();
    if (data.eofReached) {
      this.resetAxis();
    }
  };

  getNumLines(): number {
    return this.tpDatasetParams.currentDatasetParams.plottingParameters?.rangeX?.num ?? 0;
  }

  getFrequencyDelta(): number {
    const nLines = this.getNumLines();
    if (nLines > 1) {
      const startFrequency = this.tpDatasetParams.currentDatasetParams.plottingParameters?.rangeX?.start ?? 0;
      const endFrequency = this.tpDatasetParams.currentDatasetParams.plottingParameters?.rangeX?.stop ?? nLines;
      return (endFrequency - startFrequency) / (nLines - 1);
    }
    return 0;
  }

  setupYBarOnScaleChangeCallBacks() {
    if (this.yBarOnScaleChangeCallBackIdentifier) {
      this.yBarYAxis.offIntervalChange(this.yBarOnScaleChangeCallBackIdentifier);
    }
    this.yBarOnScaleChangeCallBackIdentifier = this.yBarYAxis.onIntervalChange((axis, start, end) =>
      this.YBarOnScaleChangeCallBack(axis, start, end)
    );
  }

  YBarOnScaleChangeCallBack(_, start, end) {
    if (this.yBarIgnoreProgrammaticScaleChange) {
      this.yBarIgnoreProgrammaticScaleChange = false;
    } else {
      const isLog = this.scalesConfig.color === 'log';
      this.yBarTouched = true;
      if (isLog) {
        this.setLUCColor(
          { direction: 'color', scale: this.scalesConfig.color },
          Math.log10(start),
          Math.log10(end),
          true
        );
      } else {
        this.setLUCColor({ direction: 'color', scale: this.scalesConfig.color }, start, end, true);
      }
    }
  }

  setLUCColor(scale: AxisScale, intensityMin: number, intensityMax: number, doSetColorBarInterval: boolean) {
    if (this.chart && scale.direction === 'color') {
      this.customYTicks.forEach((tick) => tick.dispose());

      // NOTE: Limit the dB spread of the yBar to 80dB until touched by the user
      let tMin = intensityMin;
      let tMax = intensityMax;
      if (!this.yBarTouched) {
        if (scale.scale === 'db') {
          const MIN_DB_VALUE = intensityMax - 80;
          tMin = Math.max(intensityMin, MIN_DB_VALUE);
        } else if (scale.scale === 'log') {
          const floorMax = intensityMax > 0 ? Math.floor(Math.log10(intensityMax)) + 1 : 1;
          let floorMin = 0;
          if (intensityMin > 0) {
            floorMin = Math.floor(Math.log10(intensityMin));
          } else {
            floorMin = floorMax - 4;
          }
          tMax = Math.pow(10, floorMax);
          tMin = Math.pow(10, floorMin);
        }
      }
      const LUTpalette = calcColorPaletteII(scale.scale, tMin, tMax);
      this.yHeatMap?.setFillStyle(
        new PalettedFill({
          lut: LUTpalette
        })
      );

      this.scrollingHeatMap?.setFillStyle(
        new PalettedFill({
          lut: LUTpalette
        })
      );

      const isLog10 = scale.scale === 'log';

      if (isLog10) {
        this.setColorbarLogAxisTicks(intensityMin, intensityMax);
      }

      const matrixForYBar: number[][] = LUTpalette.getSteps().map((value) => {
        return [value.value, value.value];
      });

      this.yHeatMap!.invalidateIntensityValues(matrixForYBar);
      if (doSetColorBarInterval) {
        this.yBarIgnoreProgrammaticScaleChange = true;
        this.yBarYAxis.setInterval({ start: tMin, end: tMax });
      }

      let title = 'lin';
      if (scale.scale === 'db') {
        title = '[dB]';
        if (this.tpDatasetParams.currentDatasetParams.plottingParameters?.freqWeight) {
          title = `[dB ${generateFreqWeightString(
            this.tpDatasetParams.currentDatasetParams.plottingParameters?.freqWeight
          )}]`;
        }
      } else {
        title = this.tpDatasetParams.currentDatasetParams.plottingParameters?.quantityY?.unitDisplayName ?? 'lin';
        if (title !== undefined) {
          title = '[' + title + ']';
        }
      }
      this.yBar?.setTitle(title);
    }
  }

  setColorbarLogAxisTicks(intensityMin: number, intensityMax: number) {
    const floorMax = intensityMax > 0 ? Math.floor(Math.log10(intensityMax)) + 1 : 1;

    let floorMin = 0;
    if (intensityMin > 0) {
      floorMin = Math.floor(Math.log10(intensityMin));
    } else {
      floorMin = floorMax - 4;
    }
    const majorTicksPosition: number[] = [];
    const minorTicksPosition: number[] = [];

    for (let i = floorMin; i < floorMax; i++) {
      majorTicksPosition.push(Math.pow(10, i));

      for (let j = 2; j < 10; j++) {
        minorTicksPosition.push(Math.pow(10, i) * j);
      }
    }

    majorTicksPosition.forEach((val) => {
      const setVal = val;
      const exponent = Math.round(Math.log10(val));

      const showvalLabel = exponent === 0 ? '1 ' : '10' + getSuperscript(exponent) + ' ';

      const marker = this.yBarYAxis
        ?.addCustomTick()
        .setValue(setVal)
        .setTextFormatter((position) => showvalLabel.toString())
        .setGridStrokeStyle(
          new SolidLine({ thickness: 0.75, fillStyle: new SolidFill({ color: ColorHEX('#A0A0A0A0') }) })
        );
      this.customYTicks.push(marker!);
    });

    minorTicksPosition.forEach((val) => {
      const setVal = val;
      const showvalLabel = '      ';

      const marker = this.yBarYAxis
        ?.addCustomTick()
        .setValue(setVal)
        .setTextFormatter((position) => showvalLabel.toString())
        .setGridStrokeStyle(
          new SolidLine({ thickness: 0.5, fillStyle: new SolidFill({ color: ColorHEX('#A0A0A0A0') }) })
        );

      this.customYTicks.push(marker!);
    });

    this.yBarYAxis.setTickStrategy(AxisTickStrategies.Empty);
  }

  redrawHeatmap = () => {
    if (this.tpDatasetParams.currentDatasetParams.plottingParameters === undefined) {
      throw new Error('Missing plottingParameters3D');
    }
    // this.setupAxisTitles();
    this.chart.setAnimationsEnabled(false);
    this.initCursorFormatter();

    const colorScale = this.scalesConfig.color;
    const axisScale: AxisScale = {
      direction: 'color',
      scale: colorScale
    };

    const values = transformOriginalValuesIntoDisplayValues(
      this.values.dataValues,
      colorScale,
      this.tpDatasetParams.currentDatasetParams
    );
    this.maybeUpdateAppearance(values);

    if (values) {
      this.scrollingHeatMap?.clear();
      this.scrollingHeatMap?.addIntensityValues(values.values);
      this.setLUCColor(axisScale, values.min, values.max, true);
    } else {
      this.setLUCColor({ direction: 'color', scale: 'lin' }, this.appearanceMin, this.appearanceMax, true);
    }
  };

  initCursorFormatter() {
    this.scrollingHeatMap?.setCursorResultTableFormatter((builder, _, dataPoint) => {
      return builder
        .addRow(
          `x: ${this.infoMapping.axis.X.formatter({
            value_or_index: dataPoint.x,
            isStepIndex: false,
            includeUnit: true
          })}`
        )
        .addRow(
          `y: ${this.infoMapping.axis.Y.formatter({
            value_or_index: dataPoint.y,
            isStepIndex: true,
            includeUnit: true,
            decimalPlaces: this.DECIMAL_PLACES_Y
          })}`
        )
        .addRow(
          `value: ${this.infoMapping.axis.Z?.formatter({
            value_or_index: dataPoint.intensity,
            isStepIndex: false,
            includeUnit: true
          })}`
        );
    });
  }

  initFrequencyValues(): void {
    this.frequencyValues3D = [];
    const nLines = this.getNumLines();
    if (nLines > 0) {
      const startFrequency = this.tpDatasetParams.currentDatasetParams.plottingParameters?.rangeX?.start ?? 0;
      const frequencyDelta = this.getFrequencyDelta();
      for (let i = 0; i < nLines + 1; i++) {
        const val = startFrequency + frequencyDelta * i;
        this.frequencyValues3D.push(val);
      }
    }
  }

  initNthOctaveXIndices(): void {
    this.frequencyValues3D = [];
    const nLines = this.getNumLines();
    const rangeX = this.tpDatasetParams.currentDatasetParams.plottingParameters?.rangeX;
    if (rangeX) {
      for (let i = 0; i < nLines; i++) {
        this.frequencyValues3D.push(i);
      }
    }
  }

  maybeUpdateAppearance(values: { min: number; max: number }, redrawColorScale = false) {
    // NOTE: Side-Effect, we need to update the global min/max values for all values after their
    // ScaleConfig dependent recalculation
    // TODO: THERE MUST BE A BETTER WAY TO DO THIS
    if (!this.appearanceMin || values.min < this.appearanceMin || redrawColorScale) {
      this.appearanceMin = values.min;
    }
    if (!this.appearanceMax || values.max > this.appearanceMax || redrawColorScale) {
      this.appearanceMax = values.max;
    }
  }

  // Parent class overrides

  resetAxis() {
    this.yBarTouched = false;
    this.setLUCColor(
      { direction: 'color', scale: this.scalesConfig.color },
      this.appearanceMin,
      this.appearanceMax,
      true
    );
    this.chart.getDefaultAxisX().fit(true);
    this.chart.getDefaultAxisY().fit(true);
  }

  protected override generatePlotTitle(parameter: StreamingChart.DatasetParameters): string {
    const quantityName: string = parameter.plottingParameters?.quantityY?.name ?? '';
    let refString: string = '';
    if (parameter.referenceDataset !== undefined) {
      const direction = StreamingChart.directionString(parameter.referenceDataset.refPosition);
      const refLabel = parameter.referenceDataset.refPosition.coordinateSystemName ?? '';
      refString = ' / [' + this.translate.instant('STREAMING.REFCHANNEL') + ': ' + refLabel + ', ' + direction + ']';
    }
    return (
      this.translate.instant('STREAMING.CHANNEL') +
      ': ' +
      parameter.label +
      ', ' +
      parameter.direction +
      refString +
      ' - ' +
      quantityName +
      ' - ' +
      parameter.type
    );
  }

  protected override additionalDispose(): void {
    this.yBarYAxis?.dispose();
    this.yBar?.dispose();
    this.yHeatMap?.dispose();
    this.scrollingHeatMap?.dispose();
  }
}
