import { SearchResultEntry } from '../shared/types/search.types';
import { WorkspaceConfig } from '../workspace/config/workspace.config.types';
import { Workspace } from '../workspace/workspace.types';
import { rawOrder } from './+state/orders.reducer';
import {
  Entity,
  RelationTarget,
  EntityContainer,
  AoBasetype,
  EntityType,
  Relation,
  RelationType,
  Order
} from './order.types';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { NO_VALUE } from '../shared/pipes/format-datetime.pipe';
import { DepotAttribute, SemanticDepotAttribute } from '../shared/+state/attributes/attributes.types';

//
// convenience methods for working with descriptive and administrative parts of the order
//

/** entityIdentifier as used as key in EntityContainer.entities */
export const entityIdentifier = (id: string, apiType: string): string => {
  return apiType + '/' + id;
};

/** entityTypeIdentifier as used as key in EntityContainer.entityTypes */
export const entityTypeIdentifier = (apiType: string): string => {
  return apiType;
};

/** relationTypeIdentifier as used as key in EntityContainer.relationTypes */
export const relationTypeIdentifier = (sourceApiType: string, relationName: string): string => {
  return relationName + '/' + sourceApiType;
};

/** flat list of all children of entity, no matter of which type they are and no matter what the relation to them is called */
export const children = (entity: Entity): RelationTarget[] => {
  const targetsLists = entity.children.map((relationToChild) => relationToChild.targets);
  return targetsLists.flat();
};

/** full entity of this target */
export const entity = (target: RelationTarget, container: EntityContainer): Entity => {
  return container.entities[entityIdentifier(target.id, target.apiType)];
};

/** full entities of these targets */
export const entities = (targets: RelationTarget[], container: EntityContainer): Entity[] => {
  return targets.map((target) => entity(target, container));
};

/** flat list of targets of the info-relations of entity  */
export const infoRelatedWithBasetype = (
  entity: Entity,
  aoBasetype: AoBasetype,
  container: EntityContainer
): RelationTarget[] => {
  const targetsLists = entity.infoRelateds.map((relation) => relation.targets);
  return targetsLists
    .filter((targetsList) => targetsList.length > 0)
    .filter((targetsList) => {
      const targetAoBasetype = container.entityTypes[entityTypeIdentifier(targetsList[0].apiType)].aoBasetype;
      return aoBasetype === targetAoBasetype;
    })
    .flat();
};

export const infoRelateds = (entity: Entity): RelationTarget[] => {
  const targetsLists = entity.infoRelateds.map((relation) => relation.targets);
  return targetsLists.filter((targetsList) => targetsList.length > 0).flat();
};

/** flat list of targets (and their ancestors) of the info-relations of entity and of all its ancestors to entities of a type derived form aoBasetype or aoBasetype-children,
 * e.g. return all test sequences and test sequence parts that are related to a measurement, no matter if the relations to follow are directly info or parent-info or info-parent or ...
 */
export const relatedsDeep = (
  currentEntity: Entity,
  aoBasetype: AoBasetype.AoTest | AoBasetype.AoTestSequence | AoBasetype.AoUnitUnderTest,
  container: EntityContainer
): RelationTarget[] => {
  const aoBasetypes: AoBasetype[] = [aoBasetype];
  if (aoBasetype === AoBasetype.AoTest) {
    aoBasetypes.push(AoBasetype.AoSubTest);
  } else if (aoBasetype === AoBasetype.AoTestSequence) {
    aoBasetypes.push(AoBasetype.AoTestSequencePart);
  } else {
    // i.e. AoBasetype.AoUnitUnderTest
    aoBasetypes.push(AoBasetype.AoUnitUnderTestPart);
  }
  const targetsLists = aoBasetypes.map((basetype) => infoRelatedWithBasetype(currentEntity, basetype, container));
  while (currentEntity.parent) {
    const parentEntity = entity(currentEntity.parent, container);
    aoBasetypes.forEach((basetype) => {
      targetsLists.push(infoRelatedWithBasetype(parentEntity, basetype, container));
    });
    currentEntity = parentEntity;
  }
  const ret = makeUnique(
    entities(targetsLists.flat(), container)
      .map((curEntity) => entityWithAncestors(curEntity, container))
      .flat()
  );
  return ret;
};

const makeUnique = (targets: RelationTarget[]): RelationTarget[] => {
  return targets.reduce(
    (acc, cur) => (acc.find((a) => a.id === cur.id && a.apiType === cur.apiType) ? acc : [...acc, cur]),
    [] as RelationTarget[]
  );
};

/** list of all ancestors of currentEntity */
export const entityWithAncestors = (currentEntity: Entity, container: EntityContainer): RelationTarget[] => {
  const ancestorList: RelationTarget[] = [{ id: currentEntity.id, apiType: currentEntity.apiType }];
  while (currentEntity.parent) {
    ancestorList.push(currentEntity.parent);
    currentEntity = entity(currentEntity.parent, container);
  }
  return ancestorList;
};

/** entity type of entity */
export const entityType = (entity: Entity | RelationTarget, container: EntityContainer): EntityType => {
  return container.entityTypes[entity.apiType];
};

/** relation type of relation */
export const relationType = (relation: Relation, container: EntityContainer): RelationType => {
  return container.relationTypes[relationTypeIdentifier(relation.sourceApiType, relation.relationName)];
};

/** merge container2 into container assuming no merge conflicts can occur (this is a reasonable assumption for containers from the same order) */
export const mergeContainers = (container: EntityContainer, container2: EntityContainer) => {
  Object.keys(container2.entities).forEach((key) => {
    container.entities[key] = container2.entities[key];
  });
  Object.keys(container2.entityTypes).forEach((key) => {
    container.entityTypes[key] = container2.entityTypes[key];
  });
  Object.keys(container2.relationTypes).forEach((key) => {
    container.relationTypes[key] = container2.relationTypes[key];
  });
};

/** return all entities which are instances of a type derived from aoBasetype */
export const entitiesForAoBasetype = (aoBasetype: AoBasetype, container: EntityContainer): Entity[] => {
  // which apiTypes are derived for aoBasetype?
  const apiTypes: string[] =
    Object.values(container.entityTypes)
      .filter((curEntityType) => curEntityType.aoBasetype === aoBasetype)
      .map((curEntityType) => curEntityType.apiType) ?? [];

  // which entities have one of these apiTypes?
  return Object.values(container.entities).filter((curEntity) => apiTypes.includes(curEntity.apiType));
};

/** return all entities which are instances of a type derived from aoBasetype */
export const entitiesForApiType = (apiType: string, container: EntityContainer): Entity[] => {
  return Object.values(container.entities).filter((curEntity) => curEntity.apiType === apiType);
};

/** return all entity types which are derived from aoBasetype */
export const entityTypesforAoBasetype = (aoBasetype: AoBasetype, container: EntityContainer): EntityType[] => {
  return Object.values(container.entityTypes).filter((curEntityType) => {
    return curEntityType.aoBasetype === aoBasetype;
  });
};

export const entityTypesforAtfxTypeName = (atfxTypeName: string, container: EntityContainer): EntityType[] => {
  return Object.values(container.entityTypes).filter((curEntityType) => {
    return curEntityType.atfxTypeName === atfxTypeName;
  });
};

/** apiTypes that the children of parents of parentType can have*/
export const childApiTypes = (parentType: EntityType | string, container: EntityContainer): string[] => {
  if (typeof parentType !== 'string') {
    parentType = parentType.apiType;
  }
  const apiTypesWithDuplicates: string[] = Object.values(container.relationTypes)
    .filter((curRelationType) => curRelationType.sourceApiType === parentType && curRelationType.axis === 'child')
    .map((curRelationType) => curRelationType.targetApiType);
  return [...new Set(apiTypesWithDuplicates)];
};

//
// more concrete helpers
//

export const getProject = (container: EntityContainer): Entity => {
  const projects = entitiesForAoBasetype(AoBasetype.AoTest, container);
  return projects[0]; // we assume there is exactly one project
};

export const getTestseries = (container: EntityContainer): Entity[] => {
  const testseriesTargets = children(getProject(container));
  return testseriesTargets.map((target) => entity(target, container));
};

export const getMeas = (container: EntityContainer): Entity[] => {
  return getTestseries(container)
    .map((tstser) => children(tstser))
    .map((meaTargets) => entities(meaTargets, container))
    .flat();
};

// returns the ids of all related target entities of an entity
export const getInfoRelatedTargetEntityIds = (ent: Entity): string[] => {
  const targetIds: string[] = [];
  ent.infoRelateds.forEach((infoRelated) => {
    infoRelated.targets.forEach((target) => targetIds.push(target.id));
  });
  return targetIds;
};

// returns the related target entities of an entity, filtered by an EntityType and entity name
export const getInfoRelatedsEntity = (
  ent: Entity,
  eType: EntityType,
  entityContainerContent: EntityContainer,
  entityName: string
): Entity | undefined => {
  const targetIds = getInfoRelatedTargetEntityIds(ent);
  const infoRelatedEntities: Entity[] = [];
  const parameterSets = entitiesForApiType(eType.apiType, entityContainerContent);
  // TODO: support results from more than one result entity
  const resultEntity = parameterSets.find((elem) => {
    const isSameName = elem.name === entityName;
    return isSameName && targetIds.includes(elem.id);
  });

  return resultEntity;
};

export const getRelatedEntitiesByAtfxTypename = (
  ent: Entity,
  atfxTypeName: string,
  containerContent: EntityContainer
): Entity[] => {
  const entityTypes = entityTypesforAtfxTypeName(atfxTypeName, containerContent);
  if (entityTypes.length !== 1) {
    return [];
  }
  const apiType = entityTypes[0].apiType;
  const ignoreParent = (e) => e.apiType !== ent.apiType && e.id !== ent.id;
  const notUndefined = (e) => e !== undefined;

  const relatedEntities = entities([...children(ent), ...infoRelateds(ent)], containerContent)
    .filter(notUndefined)
    .filter(ignoreParent);
  if (relatedEntities.length === 0) {
    return [];
  }
  const matchingRelatedEnties = relatedEntities.filter((e) => e.apiType === apiType);
  if (matchingRelatedEnties.length > 0) {
    return matchingRelatedEnties;
  }
  // return relatedEntities.map((e) => getEntityByAtfxTypenameRecursive(e, atfxTypeName, containerContent)).flat();
  return [];
};

export const getRelatedEntityByApiType = (
  ent: Entity,
  apiType: string, // e.g. OdsOrderUUT_7f19d958-f38b-11eb-a1e6-98fa9bfed662_m_testcarrierdatafix
  entityContainerContent: EntityContainer
) => {
  const relatetEntities = findAllThatAreRelated(ent, 2, apiType, entityContainerContent);
  const uniqueEntities = [...new Map(relatetEntities.map((item) => [item.id, item])).values()];
  return uniqueEntities;
};

export const findAllThatAreRelated = (
  ent: Entity,
  depth: number,
  apiType: string,
  entityContainerContent: EntityContainer
): Entity[] => {
  if (depth <= 0 || ent === undefined) {
    return [];
  }
  const relations: Relation[] = [...ent.children, ...ent.infoRelateds];
  const relationTargets = relations.flatMap((r) => r.targets);
  const directRelatedEntities = entities(relationTargets, entityContainerContent);
  const matchingEntities = directRelatedEntities.filter((ente) => ente.apiType === apiType);
  if (matchingEntities.length > 0) {
    return matchingEntities;
  }
  const recursiveEntities = directRelatedEntities
    .filter((e) => e.id !== ent.id) // skip the entity that we ware coming from
    .flatMap((e) => findAllThatAreRelated(e, depth - 1, apiType, entityContainerContent));

  return recursiveEntities;
};

//
// helpers for finding strings in arrays of strings
//

export const findAllThatEqual = (desiredString: string, list: string[]): string[] => {
  const result: string[] = [];
  list.forEach((elem) => {
    if (elem === desiredString) {
      result.push(elem);
    }
  });
  return result;
};

export const findAllThatEqualOneOf = (desiredStrings: string[], list: string[]): string[] => {
  const result: string[] = [];
  list.forEach((elem) => {
    if (desiredStrings.includes(elem)) {
      result.push(elem);
    }
  });
  return result;
};

//
// additional helpers
//

export const sortAttributeValues = (a, b) => {
  const valA = a.nls_key ?? a.label;
  const valB = b.nls_key ?? b.label;
  return valA.localeCompare(valB);
};

export const createOrderObjectFromSearchResult = (
  searchResult: SearchResultEntry,
  workspace: Workspace,
  workspaceConfig: WorkspaceConfig,
  availableDepotAttributes: DepotAttribute[] | undefined,
  availableSemanticDepotAttributes: SemanticDepotAttribute[] | undefined,
  selectedLanguage: string
): Order => {
  const orderGUID = searchResult[workspaceConfig.attributeLookups.order];
  const orderName = searchResult[workspaceConfig.attributeLookups.orderName] ?? '';
  const stateID = searchResult[workspaceConfig.attributeLookups.state];
  const lastModified = searchResult[workspaceConfig.attributeLookups.lastModified];
  const isoDate = convertSearchApiDateToISODate(lastModified);

  let detailAttributes = {};
  if (searchResult && availableDepotAttributes && availableSemanticDepotAttributes) {
    for (const entry in searchResult) {
      if (entry) {
        const attributIdentifier = entry;
        const attrib = availableDepotAttributes.find((x) => x.idName === entry);
        const semanticAttrib = availableSemanticDepotAttributes.find((x) => x.id === entry);
        let label: string | undefined;
        if (semanticAttrib) {
          label = semanticAttrib.translations[selectedLanguage];
        } else {
          label = attrib?.displayName;
        }
        if (label) {
          const value = searchResult[entry] ? searchResult[entry] : NO_VALUE;

          detailAttributes = {
            ...detailAttributes,
            [attributIdentifier]: { type: attrib?.type, label: label, value: value }
          };
        }
      }
    }
  }
  const order: Order = {
    ...rawOrder(),
    // TODO: get orderResource.id from search
    // TODO: Q: Is above still current? orderGUID is based on a searchResultEntry now
    id: orderGUID,
    name: orderName,
    lock: { locked: false },
    relations: {
      orderStateId: stateID,
      workspaceId: workspace.id
    },
    attributes: {
      ...detailAttributes,
      lastModified: {
        type: 'Date',
        label: 'Date',
        value: isoDate,
        nls_key: _('ORDER.LASTMODIFIED')
      }
    }
  };
  return order;
};

export const convertSearchApiDateToISODate = (apiDate: string): string => {
  let result = '';
  if (apiDate && apiDate.length === 14) {
    // convert last modified into ISO String => expected output: YYYY-MM-DDThh:mm:ss.000Z
    result =
      apiDate.substring(0, 4) +
      '-' +
      apiDate.substring(4, 6) +
      '-' +
      apiDate.substring(6, 8) +
      'T' +
      apiDate.substring(8, 10) +
      ':' +
      apiDate.substring(10, 12) +
      ':' +
      apiDate.substring(12, 14) +
      '.000Z';
  }
  return result;
};
