import { createAction, props } from '@ngrx/store';
import { availableLanguagesStrings } from '../app.locales';
import { AppErrorPayload, Client, CloudAppNames, CloudDepot } from '../app.types';
import { AppUserPrefs, AppUserPrefsBoolean } from './app.reducer';

export enum AppActionTypes {
  // Candidates for extraction to Common lib
  AcknowledgeCookieNotice = '[App] Acknowledge Cookie Notice',
  SetLanguage = '[App] Set Language',
  LanguageSet = '[App] Language set',
  AppError = '[App] Error',
  SuccessMsg = '[App] Success',
  SetFeatureFlag = '[App] Set Feature Flag',
  SetInstanceConfig = '[App] Set Instance Config',
  SetTracking = '[App] Set Tracking',
  NOOP = '[App] NOOP',

  // Cloud WebApp specific
  GetCloudDepots = '[App] Fetch all available cloud depots',
  CloudDepotsReceived = '[App] Cloud depots received',

  SetIsUserAdmin = '[App] Set admin for user management flag',
  IsUserAdminReceived = '[App] Admin for user management info received',

  SetIsDepotAdmin = '[App] Set admin for depot management flag',
  IsDepotAdminReceived = '[App] Admin for depot management info received',

  RequestAvailableApps = '[App] Get a list of all available cloud apps',
  AvailableAppsReceived = '[App] List of all available cloud apps received',

  GetCloudServerVersion = '[App] Get cloud server version',
  CloudServerVersionReceived = '[App] Cloud server version received',

  GetClient = '[App] Get Client',
  ClientReceived = '[App] Client received',

  // Order / Depotsearch Shared
  ToggleUserPreference = '[App] Toggle User Preference Value',
  SetUserPreference = '[App] Set User Preference'
}

export const AcknowledgeCookieNotice = createAction(AppActionTypes.AcknowledgeCookieNotice);

export const SetLanguage = createAction(AppActionTypes.SetLanguage, props<{ lang: availableLanguagesStrings }>());
export const LanguageSet = createAction(AppActionTypes.LanguageSet, props<{ lang: availableLanguagesStrings }>());

export const AppError = createAction(AppActionTypes.AppError, props<{ err: AppErrorPayload; silent?: boolean }>());

export const SetTracking = createAction(AppActionTypes.SetTracking, props<{ enabled: boolean }>());

export const SuccessMsg = createAction(
  AppActionTypes.SuccessMsg,
  props<{ message: string; translationKey?: string }>()
);

export const NOOP = createAction(AppActionTypes.NOOP);

export const GetCloudDepots = createAction(AppActionTypes.GetCloudDepots);

export const CloudDepotsReceived = createAction(
  AppActionTypes.CloudDepotsReceived,
  props<{ receivedDepots: CloudDepot[] }>()
);

export const RequestAvailableApps = createAction(AppActionTypes.RequestAvailableApps);
export const AvailableAppsReceived = createAction(
  AppActionTypes.AvailableAppsReceived,
  props<{ availableApps: CloudAppNames[] }>()
);

export const GetCloudServerVersion = createAction(AppActionTypes.GetCloudServerVersion);
export const CloudServerVersionReceived = createAction(
  AppActionTypes.CloudServerVersionReceived,
  props<{ serverVersion: string | undefined }>()
);

export const GetClient = createAction(AppActionTypes.GetClient);
export const ClientReceived = createAction(AppActionTypes.ClientReceived, props<{ client: Client }>());
//
export const SetIsUserAdmin = createAction(AppActionTypes.SetIsUserAdmin);
export const IsUserAdminReceived = createAction(AppActionTypes.IsUserAdminReceived, props<{ isAdmin: boolean }>());

export const SetIsDepotAdmin = createAction(AppActionTypes.SetIsDepotAdmin);
export const IsDepotAdminReceived = createAction(AppActionTypes.IsDepotAdminReceived, props<{ isAdmin: boolean }>());

export const ToggleUserPreference = createAction(
  AppActionTypes.ToggleUserPreference,
  props<{ preference: AppUserPrefsBoolean }>()
);

export const SetUserPreference = createAction(
  AppActionTypes.SetUserPreference,
  props<{ preference: AppUserPrefs; value: number | boolean }>()
);
