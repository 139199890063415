<ng-container *ngIf="measurementsFacade.activeDownloads$ | async as downloads">
  <ng-container *ngIf="downloads.length > 0">
    <div class="clr-col-4">
      <div class="card">
        <div class="card-block">
          <h4 class="card-title">
            <span class="spinner spinner-sm"> </span>
            <span>
              Prepare Downloads...
            </span>
          </h4>
          <div>
            <ng-container *ngFor="let download of downloads">
              <div>
                <ng-container *ngIf="download.error">
                  <div class="alert alert-danger" role="alert">
                    <div class="alert-items">
                      <div class="alert-item static">
                        <div class="alert-icon-wrapper">
                          <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                        </div>
                        <span class="alert-text">
                          {{ download.error.details }}
                        </span>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <button class="btn btn-sm btn-link" (click)="measurementsFacade.cancelDownloadRequest(download)">
                  <clr-icon shape="ban"></clr-icon>
                </button>

                {{ download.contents[0].id.split('/').pop() }}
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
