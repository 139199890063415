import { Pipe, PipeTransform } from '@angular/core';
import { UserInfo } from '../../../../user/user.type';

@Pipe({
  name: 'usersFilter'
})
export class UsersFilterPipe implements PipeTransform {
  transform(users: UserInfo[] | null, userInput = '', isActive: boolean): UserInfo[] {
    if (!users || !users.length) {
      return [];
    }

    let filtered = [...users];
    if (isActive) {
      filtered = filtered.filter((user) => user.isActive);
    }

    if (userInput.trim().length) {
      return (filtered = filtered.filter(
        (user) =>
          `${user.lastName ?? ''}${user.firstName ?? ''}${user.userName}`
            .toLowerCase()
            .indexOf(userInput.toLowerCase()) >= 0
      ));
    }

    return filtered;
  }
}
