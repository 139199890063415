import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, combineLatest, filter, map, take } from 'rxjs';
import {
  ContentCollection,
  ContentCollectionShareInfo,
  ContentCollectionShareType
} from '../../../../content-collection/content-collection.types';
import { UserFacade } from '../../../../user/+state/user.facade';
import { TranslateService } from '@ngx-translate/core';
import { ContentCollectionFacade } from '../../../../content-collection/+state/content-collection.facade';

@Component({
  selector: 'cloud-ccd-share-step',
  templateUrl: './ccd-share-step.component.html',
  styleUrls: ['./ccd-share-step.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CcdShareStepComponent {
  @Input() selectedCC$: Observable<ContentCollection | undefined>;

  @Output() backClicked: EventEmitter<void> = new EventEmitter();

  shareTypes = ContentCollectionShareType;
  minDate: string;
  mode: 'add' | 'view' = 'view';
  newShare: { canEdit: boolean; shareTo: string; validUntil: string | null; shareType: ContentCollectionShareType } = {
    canEdit: false,
    shareTo: '',
    validUntil: null,
    shareType: ContentCollectionShareType.User
  };

  constructor(
    public userFacade: UserFacade,
    public translate: TranslateService,
    private contentCollectionFacade: ContentCollectionFacade
  ) {
    const today = new Date();
    this.minDate = today.toISOString().split('T')[0];

    this.mode = 'view';
    this.newShare = { canEdit: false, shareTo: '', validUntil: null, shareType: ContentCollectionShareType.User };
  }

  stopProp(event: Event) {
    event.stopPropagation();
  }

  getSharedToName(item: ContentCollectionShareInfo) {
    return combineLatest([this.userFacade.usersSelfCanSee$, this.userFacade.rolesSelfCanSee$]).pipe(
      map(([users, roles]) => {
        return item.shareType === ContentCollectionShareType.User
          ? users.find((user) => user.id === item.sharedWith)?.userName
          : roles.find((role) => role.id === item.sharedWith)?.name;
      })
    );
  }

  shareToChanged(selectedCC, data) {
    this.newShare.shareTo = data?.id;
    this.newShare.shareType = data?.shareType;

    this.contentCollectionFacade.shareContentCollection(selectedCC, this.newShare);
    this.newShare = { canEdit: false, shareTo: '', validUntil: null, shareType: ContentCollectionShareType.User };
  }

  getDate(date: string) {
    if (date === null) {
      return null;
    }

    return new Date(date);
  }

  shareContentCollection(selectedCC: ContentCollection) {
    this.contentCollectionFacade.shareContentCollection(selectedCC, this.newShare);
    this.newShare = { canEdit: false, shareTo: '', validUntil: null, shareType: ContentCollectionShareType.User };
  }

  rightsChanged(event: MouseEvent, cc: ContentCollection, shareInfo: ContentCollectionShareInfo, checked: boolean) {
    this.contentCollectionFacade.editContentCollectionShare(cc, {
      ...shareInfo,
      canAddItems: checked,
      canRemoveItems: checked
    });
  }

  validUntilChanged(validUntil, cc: ContentCollection, shareInfo: ContentCollectionShareInfo) {
    this.contentCollectionFacade.editContentCollectionShare(cc, {
      ...shareInfo,
      validUntil
    });
  }

  deleteContentCollectionShare(event: MouseEvent, selectedCC: ContentCollection, shareInfo) {
    event.stopPropagation();
    this.contentCollectionFacade.deleteContentCollectionShare(selectedCC, shareInfo);
  }
}
