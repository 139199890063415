<form class="clr-form">
  <clr-input-container id="searchField" class="no-margin">
    <label>
      <input
        clrInput
        #searchInput
        name="searchText no-margin"
        data-cy="searchField"
        (blur)="searchTextChanged(searchInput.value)"
        placeholder="{{ 'DEPOTSEARCH.SEARCHTEXT' | translate }}"
        [ngModel]="depotSearchFacade.searchText$ | async"
      />

      <div id="searchButtons" class="btn-group">
        <button
          id="searchButtonsSpinner"
          class="btn btn-sm btn-info-outline"
          *ngIf="!(isSearchingPossible$ | async)"
          data-cy="searchButtonDuringSearch"
          ><cloud-spinner [size]="'small'"></cloud-spinner
        ></button>
        <button
          class="btn btn-sm btn-icon btn-primary btn-fixed-width"
          [ngClass]="{ 'btn-highlight': depotSearchFacade.searchInputModified$ | async }"
          title="{{ 'DEPOTSEARCH.SEARCH' | translate }}"
          data-cy="PERFORM_SEARCH"
          (click)="search()"
          *ngIf="isSearchingPossible$ | async"
        >
          <clr-icon shape="search"></clr-icon>
        </button>
        <button
          class="btn btn-sm btn-icon btn-info btn-fixed-width"
          title="{{ 'DEPOTSEARCH.NEWSEARCH' | translate }}"
          data-cy="RESET_SEARCH"
          (click)="reset()"
          *ngIf="isSearchingPossible$ | async"
        >
          <clr-icon shape="replay-all"></clr-icon>
        </button>
      </div>
    </label>
  </clr-input-container>
</form>

<div id="badgebar">
  <clr-dropdown [clrCloseMenuOnItemClick]="true" id="addAttributeDropdownButton">
    <button
      type="button"
      class="btn btn-primary btn-sm badgebarItem"
      data-cy="searchAttributeDropdownButton"
      clrDropdownTrigger
    >
      <clr-icon shape="plus"></clr-icon>
      {{ 'DEPOTSEARCH.SELECTATTRIBUTE' | translate }}
      <clr-icon shape="caret down"></clr-icon></button
    ><clr-dropdown-menu *clrIfOpen>
      <cloud-autocomplete-dropdown
        [searchable]="true"
        (attributeSelected)="addSearchBarFilter($event)"
      ></cloud-autocomplete-dropdown>
    </clr-dropdown-menu>
  </clr-dropdown>

  <ng-container *ngFor="let searchAttribute of searchAttributes$ | async" ; trackBy:trackByFn>
    <ng-container *ngIf="searchAttribute.attribute">
      <cloud-search-bar-attribute-button
        [searchAttribute]="searchAttribute"
        [active]="searchAttribute.id === activeSearchAttribute"
        (triggerSearch)="search()"
        (isDirty)="isAttributeDirty(searchAttribute, $event)"
      ></cloud-search-bar-attribute-button>
    </ng-container>
  </ng-container>

  <cloud-toggle-button
    [text]="'Limit to active Content Collection'"
    [small]="true"
    (clicked)="toggleRestrictToActiveCCState()"
    [toggleState]="(depotSearchFacade.searchIsRestrictedToActiveCC$ | async) === true ? 'on' : 'off'"
  ></cloud-toggle-button>

  <!-- [toggleState]="(usersFacade.isActiveFilter$ | async) ? 'off' : 'on'" -->
</div>
