<clr-modal [(clrModalOpen)]="visible">
  <h3 class="modal-title">
    {{ 'CONTENTCOLLECTION.MODALS.DECLINE.DECLINEDIALOGTITLE' | translate }}
  </h3>
  <div class="modal-body">
    {{ 'CONTENTCOLLECTION.MODALS.DECLINE.REALLYDECLINE' | translate }}
    <div class="collectionNameToBeDeleted" data-cy="CCNameToBeDeclined">
      {{ collectionToDecline?.name }}
    </div>
    {{ 'CONTENTCOLLECTION.MODALS.GENERAL.IRREVERSIBLE_ACTION' | translate }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="cancelDeclineCollection($event)">
      {{ 'CONTENTCOLLECTION.MODALS.GENERAL.CANCELBUTTON' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-danger"
      data-cy="doCCDeclineButton"
      (click)="confirmDeclineCollection($event); visible = false"
    >
      {{ 'CONTENTCOLLECTION.MODALS.DECLINE.DECLINEBUTTON' | translate }}
    </button>
  </div>
</clr-modal>
