import { DatasetDescription, Measurement } from './../../measurements.types';
import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';

import { PlottingDataset } from '../../../plotting/plotting.types';
import { PlottingComponent } from '../../../plotting/plotting.component';
import { MeasurementsFacade } from '../../+state/measurements.facade';
import { TranslateService } from '@ngx-translate/core';
import { DepotSearchFacade } from '../../../depot-search/+state/depot-search.facade';
import { map, filter, auditTime, tap, withLatestFrom } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { AppFacade } from '../../../+state/app.facade';
import { CloudAppNames } from '../../../app.types';
import { ProcessingDataset, ProcessingFormula } from '../../../processing/processing.types';
import { ProcessingService } from './../../../processing/processing.service';
import { ProcessingFacade } from '../../../processing/+state/processing.facade';
import { StreamingFacade } from '../../../streaming/+state/streaming.facade';
import { FeatureFlagsFacade } from '@vas/feature-flags';
import { CommonFacade } from '@vas/common';
import { AttributesFacade } from '../../../shared/+state/attributes/attributes.facade';
import { StreamingType } from '../../../streaming/utils/streaming.types';
import { encodeMeasurementTokenContainerID } from '../../../streaming/utils/streaming-token.types';

@Component({
  selector: 'cloud-measurement-dataset-description',
  templateUrl: './measurement-dataset-description.component.html',
  styleUrls: ['./measurement-dataset-description.component.css']
})
export class MeasurementDatasetDescriptionComponent implements OnInit, OnDestroy {
  ANALYSES_DATATYPE = 'analyses';

  public dataSets$: Observable<DatasetDescription[] | undefined>;
  public filteredDataSets$: Observable<DatasetDescription[]> = new Observable();
  public datatypes$: Observable<string[]>;
  public selectedMeasurementPath$: Observable<string>;
  public selectedMeasurementId$: Observable<string>;

  public datasetTableMaxHeight$: Observable<number>;
  public datasetTableIsOverflowing$: Observable<boolean>;

  public isPlottingAvailable$: Observable<boolean>;
  public plottingDatasets$: Observable<PlottingDataset[]>;
  public isProcessingAvailable$: Observable<boolean>;
  public processingDatasets$: Observable<ProcessingDataset[]>;

  public datatypeFilter$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public showPlot$: BehaviorSubject<DatasetDescription | null> = new BehaviorSubject(null);

  displayPlotButton$: Observable<boolean>;
  displayStreamingButton$: Observable<boolean>;
  sortedFormulas$: Observable<ProcessingFormula[]>;
  showAnalysisEditor$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showFormulaInput$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @ViewChild('plottingComponent', { static: true }) plottingComponent: PlottingComponent;
  @ViewChild('contentMeasurementDatasets', { static: true }) contentMeasurementDatasets: ElementRef;
  @ViewChild('datasetTable', { static: true }) datasetTable;
  @ViewChild('lateTokenToggle') lateTokenToggle;
  public trackSlowQuantityToggle: boolean = true;

  // Inner padding offset in px for #contentMeasurementDatasets
  magicInnerPadding = 110;

  // How many buttons directly shown, the rest is hidden in dropdown
  showFilterButtons = 5;

  errorShape = 'error-standard';

  subs: Subscription[] = [];

  constructor(
    public measurementsFacade: MeasurementsFacade,
    public depotSearchFacade: DepotSearchFacade,
    public attributesFacade: AttributesFacade,
    public streamingFacade: StreamingFacade,
    public translate: TranslateService,
    public appFacade: AppFacade,
    public processingFacade: ProcessingFacade,
    public processing: ProcessingService,
    public featureFlagFacade: FeatureFlagsFacade,
    public commonFacade: CommonFacade
  ) {}

  ngOnInit() {
    this.trackSlowQuantityToggle = true;

    this.datasetTableMaxHeight$ = combineLatest([
      this.commonFacade.uiHeight$,
      this.commonFacade.uiWidth$,
      this.showPlot$
    ]).pipe(map(() => this.contentMeasurementDatasets.nativeElement.offsetHeight - this.magicInnerPadding));

    this.isPlottingAvailable$ = this.appFacade.getIsCloudAppAvailable$(CloudAppNames.plotting);

    this.displayPlotButton$ = combineLatest([
      this.isPlottingAvailable$,
      this.datatypeFilter$,
      this.featureFlagFacade.featureValue$('SHOW_SVG_PLOT')
    ]).pipe(
      map(([available, datatypeFilter, showPlotButton]) => {
        return showPlotButton && available && datatypeFilter !== this.ANALYSES_DATATYPE;
      })
    );

    this.isProcessingAvailable$ = combineLatest([
      this.featureFlagFacade.featureValue$('PROCESSING_FORMULA'),
      this.appFacade.getIsCloudAppAvailable$(CloudAppNames.processing)
    ]).pipe(
      map(([featureProcessing, cloudProcessing]) => {
        return featureProcessing && cloudProcessing;
      })
    );

    this.displayStreamingButton$ = combineLatest([
      this.featureFlagFacade.featureIsSet$('LIGHTNING_LICENSE'),
      this.featureFlagFacade.featureValue$('LIGHTNING_LICENSE')
    ]).pipe(
      filter(([isSet, value]) => !!isSet && value !== ''),
      map((_) => true)
    );

    this.selectedMeasurementPath$ = this.measurementsFacade.selectedMeasurement$.pipe(
      map((selectedMeasurement) => selectedMeasurement?.measurementBrowseUrl ?? '')
    );

    this.selectedMeasurementId$ = this.measurementsFacade.selectedMeasurement$.pipe(
      map((selectedMeasurement) => selectedMeasurement?.resources?.overview?.measurementId ?? '')
    );

    this.dataSets$ = this.measurementsFacade.selectedMeasurement$.pipe(
      map((selectedMeasurement) => selectedMeasurement?.resources?.['datasetDescription']?.datasets),
      map((set) => structuredClone(set))
    );

    const tempBannedDatasets = ['Order'];

    this.filteredDataSets$ = combineLatest([this.dataSets$, this.datatypeFilter$]).pipe(
      map(([datasets, datatypeFilter]) => {
        let filteredDatasets: DatasetDescription[] = [];
        if (datatypeFilter === '') {
          filteredDatasets = datasets ?? [];
        } else {
          filteredDatasets = datasets?.filter((set) => set.attributes['datatype'] === datatypeFilter) ?? [];
        }
        filteredDatasets = filteredDatasets.map((set) => {
          if (!set.attributes.hasOwnProperty('hidePlay')) {
            set.attributes['hidePlay'] = tempBannedDatasets.includes(set.attributes['datatype']);
          }
          return set;
        });
        return filteredDatasets;
      })
    );

    this.datatypes$ = combineLatest([this.dataSets$, this.selectedMeasurementId$]).pipe(
      map(([datasets, _]) => datasets),
      map((datasets) => {
        let sortetDataTypes: string[] = [];
        if (datasets) {
          const datatypes = datasets?.map((set) => set.attributes['datatype'] as string) ?? [];
          sortetDataTypes = [...new Set(datatypes)].sort();
        }
        return sortetDataTypes;
      })
    );

    this.plottingDatasets$ = this.filteredDataSets$.pipe(
      filter((dataset) => !!dataset),
      map((datasets) => datasets!.map((dataset) => this.createPlotDataset(dataset)))
    );

    this.datasetTableMaxHeight$ = combineLatest([
      this.commonFacade.uiHeight$,
      this.commonFacade.uiWidth$,
      this.showPlot$
    ]).pipe(map(() => this.contentMeasurementDatasets.nativeElement.offsetHeight - this.magicInnerPadding));

    this.datasetTableIsOverflowing$ = combineLatest([
      this.commonFacade.uiHeight$,
      this.commonFacade.uiWidth$,
      this.showPlot$,
      this.filteredDataSets$
    ]).pipe(
      auditTime(200),
      map(() => this.datasetTable?.nativeElement.scrollHeight > this.datasetTable?.nativeElement.clientHeight ?? false)
    );

    this.subs.push(
      this.datatypes$
        .pipe(
          filter((datatypes) => datatypes.length > 0),
          withLatestFrom(this.datatypeFilter$)
        )
        .subscribe(([datatypes, datatypeFilter]) => {
          if (!datatypes.includes(datatypeFilter)) {
            this.datatypeFilter$.next('');
          }
        }),

      this.isProcessingAvailable$
        .pipe(
          filter((val) => val === true),
          tap(() => this.processingFacade.loadAvailableFormulas())
        )
        .subscribe()
    );

    this.subs.push(
      this.showPlot$
        .pipe(
          filter((showPlot) => !!showPlot),
          withLatestFrom(this.plottingDatasets$),
          map(([dataset, plottingDatasets]) => {
            const datasetPlotInfo = plottingDatasets?.find((item) => item.id === dataset!.id);
            if (datasetPlotInfo) {
              const datasetIndex = plottingDatasets.indexOf(datasetPlotInfo);
              this.plottingComponent.forceVisible(datasetIndex);
            }
          })
        )
        .subscribe()
    );
  }

  toggleLateToken(): void {
    this.processingFacade.setLateProcessingToken(this.lateTokenToggle.nativeElement.checked);
  }

  private createPlotDataset(dataset: DatasetDescription): PlottingDataset {
    const id = dataset.id;
    const depotId = dataset.depotId;
    const attrs = dataset.attributes;
    const title = `${attrs['name']}[${attrs['direction']}] - ${attrs['quantity']} - ${attrs['datatype']}`;
    return { id: id, depotId: depotId, title: title };
  }

  prepareStream(dataset: DatasetDescription, measurement: Measurement) {
    if (measurement.depotId) {
      const tokenContainerId = encodeMeasurementTokenContainerID(measurement.depotId, measurement.measurementId);
      this.streamingFacade.createStreamingPackage({
        streamingType: StreamingType.MEASUREMENT,
        tokenContainerId,
        dataset
      });
    } else {
      console.error('DepotID missing in Measurement', measurement);
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
