<ng-container *ngIf="myLicenses$ | async as myLicenses">
  <ng-container *ngIf="myLicenses?.length">
    <div class="license-item license-item-icon">
      <cloud-svg-icon fileName="certificate-thin" height="16px" width="16px"></cloud-svg-icon>
      {{ 'NAV.ACTIVE_LICENSES' | translate }}
    </div>

    <div
      class="license-item"
      *ngFor="let license of myLicenses"
      [title]="
        license.userDisplayName +
        ' [' +
        ('NAV.VALID_UNTIL' | translate) +
        ' ' +
        (license.valid_until | date : 'long' : '' : translate.currentLang) +
        ']'
      "
    >
      <span class="license-name">{{ license.userDisplayName ?? license.name }}</span>
      <span class="license-label" [ngClass]="license.timeClass">{{ license.remainingTime }}</span>
    </div>
  </ng-container>
</ng-container>
