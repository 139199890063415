// @generated by protobuf-ts 2.8.2 with parameter long_type_string
// @generated from protobuf file "DatasetMessages.proto" (package "BBMgRPCstreamingNS", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// ------------ Metadata to identify a dataset ---------

/**
 * @generated from protobuf message BBMgRPCstreamingNS.ChannelLabelDir
 */
export interface ChannelLabelDir {
    /**
     * @generated from protobuf field: string label = 1;
     */
    label: string;
    /**
     * @generated from protobuf field: string direction = 2;
     */
    direction: string;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetKeyData
 */
export interface DatasetKeyData {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.ChannelLabelDir label_dir = 1;
     */
    labelDir?: ChannelLabelDir;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataType type = 2;
     */
    type: DataType;
}
// ------------ Streaming ------------------------------

/**
 * @generated from protobuf message BBMgRPCstreamingNS.NonEquiInt32Values
 */
export interface NonEquiInt32Values {
    /**
     * @generated from protobuf field: repeated sint32 data = 1;
     */
    data: number[];
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.NonEquiFloatValues
 */
export interface NonEquiFloatValues {
    /**
     * @generated from protobuf field: repeated float data = 1;
     */
    data: number[];
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.NonEquiDoubleValues
 */
export interface NonEquiDoubleValues {
    /**
     * @generated from protobuf field: repeated double data = 1;
     */
    data: number[];
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.LabelAndDoubleValues
 */
export interface LabelAndDoubleValues {
    /**
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.LabelAndDoubleValues.LabelAndDoubleValue data = 3;
     */
    data: LabelAndDoubleValues_LabelAndDoubleValue[];
    /**
     * @generated from protobuf field: bool is_equivalent = 4;
     */
    isEquivalent: boolean;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.LabelAndDoubleValues.LabelAndDoubleValue
 */
export interface LabelAndDoubleValues_LabelAndDoubleValue {
    /**
     * @generated from protobuf field: string label = 1;
     */
    label: string;
    /**
     * @generated from protobuf field: double assigned_value = 2;
     */
    assignedValue: number;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.CANMessages
 */
export interface CANMessages {
    /**
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.CANMessages.CANMessage data = 3;
     */
    data: CANMessages_CANMessage[];
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.CANMessages.CANMessage
 */
export interface CANMessages_CANMessage {
    /**
     * @generated from protobuf field: uint32 identifier = 1;
     */
    identifier: number; // unsigned int identifier() const { return m_identifier; }
    /**
     * @generated from protobuf field: bytes data_field = 2;
     */
    dataField: Uint8Array; // size_t nDataBytes() const { return m_dataField.size(); }  const unsigned char* dataField() const { return m_dataField.data(); }
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.FlexRayMessages
 */
export interface FlexRayMessages {
    /**
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.FlexRayMessages.FlexRayMessage data = 5;
     */
    data: FlexRayMessages_FlexRayMessage[];
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.FlexRayMessages.FlexRayMessage
 */
export interface FlexRayMessages_FlexRayMessage {
    /**
     * @generated from protobuf field: uint32 identifier = 1;
     */
    identifier: number; // unsigned int identifier() const { return m_identifier; }
    /**
     * @generated from protobuf field: uint32 bustime = 2;
     */
    bustime: number; // unsigned long                m_bustime; //contains Buscycle(0..63) & Macrotick
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.FlexRayMessages.FLXChannel_AorB flx_channel = 3;
     */
    flxChannel: FlexRayMessages_FLXChannel_AorB; // ! the Flexray channel
    /**
     * @generated from protobuf field: bytes data_field = 4;
     */
    dataField: Uint8Array; // std::vector<unsigned short>  m_dataField; //! the payload
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.FlexRayMessages.FLXChannel_AorB
 */
export enum FlexRayMessages_FLXChannel_AorB {
    /**
     * @generated from protobuf enum value: FLXChannel_A = 0;
     */
    FLXChannel_A = 0,
    /**
     * @generated from protobuf enum value: FLXChannel_B = 1;
     */
    FLXChannel_B = 1
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.EtherCatMessages
 */
export interface EtherCatMessages {
    /**
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.EtherCatMessages.EtherCatMessage data = 4;
     */
    data: EtherCatMessages_EtherCatMessage[];
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.EtherCatMessages.EtherCatMessage
 */
export interface EtherCatMessages_EtherCatMessage {
    /**
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.EtherCatMessages.EtherCatMessage.EtherCatChannelData data_field = 3;
     */
    dataField: EtherCatMessages_EtherCatMessage_EtherCatChannelData[];
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.EtherCatMessages.EtherCatMessage.EtherCatChannelData
 */
export interface EtherCatMessages_EtherCatMessage_EtherCatChannelData {
    /**
     * @generated from protobuf field: bytes data = 1;
     */
    data: Uint8Array; // unsigned char m_data[4];
    /**
     * @generated from protobuf field: bytes is_nan = 2;
     */
    isNan: Uint8Array; // unsigned char m_isNan;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.NmeaMessageChannelData
 */
export interface NmeaMessageChannelData {
    /**
     * @generated from protobuf field: bytes msg_type = 1;
     */
    msgType: Uint8Array; //      char m_msgtype[8];
    /**
     * @generated from protobuf field: bytes payload = 2;
     */
    payload: Uint8Array; // char m_payload[87];
    /**
     * @generated from protobuf field: bytes check_sum = 3;
     */
    checkSum: Uint8Array; // unsigned char m_checksum;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.NmeaMessage
 */
export interface NmeaMessage {
    /**
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.NmeaMessageChannelData data_field = 3;
     */
    dataField: NmeaMessageChannelData[];
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.IRIGMessages
 */
export interface IRIGMessages {
    /**
     * BBMNmeaMessage
     *
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.NmeaMessage data = 1;
     */
    data: NmeaMessage[];
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.PTPMessages
 */
export interface PTPMessages {
    /**
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.PTPMessages.PTPMessage data = 1;
     */
    data: PTPMessages_PTPMessage[];
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.PTPMessages.PTPMessage
 */
export interface PTPMessages_PTPMessage {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.PTPMessages.PTPMessage.Mode_enum mode = 1;
     */
    mode: PTPMessages_PTPMessage_Mode_enum;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.PTPMessages.PTPMessage.SyncState_enum syncState = 2;
     */
    syncState: PTPMessages_PTPMessage_SyncState_enum;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.RelativeTime accuracy = 3;
     */
    accuracy?: RelativeTime; // BBMRelativeTime
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.PTPMessages.PTPMessage.Mode_enum
 */
export enum PTPMessages_PTPMessage_Mode_enum {
    /**
     * @generated from protobuf enum value: Mode_SLAVE = 0;
     */
    Mode_SLAVE = 0,
    /**
     * @generated from protobuf enum value: Mode_INVALID = -1;
     */
    Mode_INVALID = -1,
    /**
     * @generated from protobuf enum value: Mode_MASTER = 1;
     */
    Mode_MASTER = 1
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.PTPMessages.PTPMessage.SyncState_enum
 */
export enum PTPMessages_PTPMessage_SyncState_enum {
    /**
     * @generated from protobuf enum value: SyncState_NOT_SYNCED = 0;
     */
    SyncState_NOT_SYNCED = 0,
    /**
     * @generated from protobuf enum value: SyncState_INVALID = -1;
     */
    SyncState_INVALID = -1,
    /**
     * @generated from protobuf enum value: SyncState_BAD_SYNCED = 1;
     */
    SyncState_BAD_SYNCED = 1,
    /**
     * @generated from protobuf enum value: SyncState_SYNCED = 2;
     */
    SyncState_SYNCED = 2
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.GPSMessages
 */
export interface GPSMessages {
    /**
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.GPSMessages.GPSMessage data = 1;
     */
    data: GPSMessages_GPSMessage[];
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.GPSMessages.GPSMessage
 */
export interface GPSMessages_GPSMessage {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.NmeaMessage msg_data = 1;
     */
    msgData?: NmeaMessage;
    /**
     * @generated from protobuf field: sint32 num_extra_leap_seconds = 2;
     */
    numExtraLeapSeconds: number;
    /**
     * @generated from protobuf field: bool has_gps_leap_seconds = 3;
     */
    hasGpsLeapSeconds: boolean; //   bool m_hasGPSLeapSeconds; //!< true if leap seconds are from GPS, i.e. NMEA payload is leap corrected.
    /**
     * @generated from protobuf field: bool has_extra_leap_seconds = 4;
     */
    hasExtraLeapSeconds: boolean; // m_hasExtraLeapSeconds; //!< true if leap seconds are from a priori knowledge; not the same as (m_numExtraLeapSeconds != 0).
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.EquiDoubleValues
 */
export interface EquiDoubleValues {
    /**
     * @generated from protobuf field: double delta = 1;
     */
    delta: number;
    /**
     * @generated from protobuf field: double begin = 2;
     */
    begin: number;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.NEquiDoubleValues
 */
export interface NEquiDoubleValues {
    /**
     * @generated from protobuf field: double delta = 1;
     */
    delta: number;
    /**
     * @generated from protobuf field: double begin = 2;
     */
    begin: number;
    /**
     * @generated from protobuf field: uint32 num_lines = 3;
     */
    numLines: number;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.AbsoluteTime
 */
export interface AbsoluteTime {
    /**
     * @generated from protobuf field: int64 first = 1 [jstype = JS_STRING];
     */
    first: string;
    /**
     * @generated from protobuf field: int64 second = 2 [jstype = JS_STRING];
     */
    second: string;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DateTime
 */
export interface DateTime {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.AbsoluteTime utc_time = 1;
     */
    utcTime?: AbsoluteTime;
    /**
     * @generated from protobuf field: int32 time_zone = 2;
     */
    timeZone: number; // long : delta time in seconds between UTC and the original time (OT) (OT = UTC - m_timeZone + m_dayLight*3600)
    /**
     * @generated from protobuf field: int32 day_light = 3;
     */
    dayLight: number; // short : flag whether the daylight shift of one hour if active (m_dayLight=1) or not (m_dayLight=0) (OT)
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.RelativeTime
 */
export interface RelativeTime {
    /**
     * @generated from protobuf field: int64 first = 1 [jstype = JS_STRING];
     */
    first: string;
    /**
     * @generated from protobuf field: int64 second = 2 [jstype = JS_STRING];
     */
    second: string;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.AbsoluteTimeRange
 */
export interface AbsoluteTimeRange {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.AbsoluteTime start = 1;
     */
    start?: AbsoluteTime;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.AbsoluteTime stop = 2;
     */
    stop?: AbsoluteTime;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.AbsoluteTimeValues
 */
export interface AbsoluteTimeValues {
    /**
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.AbsoluteTime time_vals = 1;
     */
    timeVals: AbsoluteTime[];
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.CrankAngleValues
 */
export interface CrankAngleValues {
    /**
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.AbsoluteTime time_vals = 1;
     */
    timeVals: AbsoluteTime[];
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.EquiDoubleValues angle_vals = 2;
     */
    angleVals?: EquiDoubleValues;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DoubleRange
 */
export interface DoubleRange {
    /**
     * @generated from protobuf field: double start = 1;
     */
    start: number;
    /**
     * @generated from protobuf field: double stop = 2;
     */
    stop: number;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.EquiTimeScale
 */
export interface EquiTimeScale {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.AbsoluteTime first_sample_time = 1;
     */
    firstSampleTime?: AbsoluteTime;
    /**
     * @generated from protobuf field: sint32 sampling_rate_nominator = 2;
     */
    samplingRateNominator: number; // if samplingRateNominator > 0 then data has equi time scale else non equi time scale
    /**
     * @generated from protobuf field: sint32 sampling_rate_denominator = 3;
     */
    samplingRateDenominator: number; // sampling rate as sr = samplingRateNominator / samplingRateDenominator
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.CompressedEquiTimeScale
 */
export interface CompressedEquiTimeScale {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.AbsoluteTime first_sample_time = 1;
     */
    firstSampleTime?: AbsoluteTime;
    /**
     * @generated from protobuf field: sint32 sampling_rate_nominator = 2;
     */
    samplingRateNominator: number; // if samplingRateNominator > 0 then data has equi time scale else non equi time scale
    /**
     * @generated from protobuf field: sint32 sampling_rate_denominator = 3;
     */
    samplingRateDenominator: number; // sampling rate as sr = samplingRateNominator / samplingRateDenominator
    /**
     * @generated from protobuf field: sint32 orig_sampling_rate_nominator = 4;
     */
    origSamplingRateNominator: number; // to en/decode uncompressedDeltaSampleTime
    /**
     * @generated from protobuf field: sint32 orig_sampling_rate_denominator = 5;
     */
    origSamplingRateDenominator: number; // sampling rate as sr = samplingRateNominator / samplingRateDenominator
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataType orig_type = 6;
     */
    origType: DataType;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.Quantity
 */
export interface Quantity {
    /**
     * @generated from protobuf field: uint32 subscribe_id = 26;
     */
    subscribeId: number; // internal unique subscribtion number ; used in datasets meta data
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * physical dimensions - the resulting unit is m^(meter_exponent/meter_exponent_denominator)*kg^(kilogram_exponent/kilogram_exponent_denominator)*...
     *
     * @generated from protobuf field: int32 meter_exponent = 2;
     */
    meterExponent: number;
    /**
     * @generated from protobuf field: int32 meter_exponent_denominator = 3;
     */
    meterExponentDenominator: number;
    /**
     * @generated from protobuf field: int32 kilogram_exponent = 4;
     */
    kilogramExponent: number;
    /**
     * @generated from protobuf field: int32 kilogram_exponent_denominator = 5;
     */
    kilogramExponentDenominator: number;
    /**
     * @generated from protobuf field: int32 second_exponent = 6;
     */
    secondExponent: number;
    /**
     * @generated from protobuf field: int32 second_exponent_denominator = 7;
     */
    secondExponentDenominator: number;
    /**
     * @generated from protobuf field: int32 ampere_exponent = 8;
     */
    ampereExponent: number;
    /**
     * @generated from protobuf field: int32 ampere_exponent_denominator = 9;
     */
    ampereExponentDenominator: number;
    /**
     * @generated from protobuf field: int32 kelvin_exponent = 10;
     */
    kelvinExponent: number;
    /**
     * @generated from protobuf field: int32 kelvin_exponent_denominator = 11;
     */
    kelvinExponentDenominator: number;
    /**
     * @generated from protobuf field: int32 mol_exponent = 12;
     */
    molExponent: number;
    /**
     * @generated from protobuf field: int32 mol_exponent_denominator = 13;
     */
    molExponentDenominator: number;
    /**
     * @generated from protobuf field: int32 candela_exponent = 14;
     */
    candelaExponent: number;
    /**
     * @generated from protobuf field: int32 candela_exponent_denominator = 15;
     */
    candelaExponentDenominator: number;
    /**
     * additional information for the physical dimensions which influence the interpretation but do not change the unit:
     *
     * @generated from protobuf field: int32 original_angle_exponent = 16;
     */
    originalAngleExponent: number;
    /**
     * @generated from protobuf field: int32 original_angle_exponent_denominator = 17;
     */
    originalAngleExponentDenominator: number;
    /**
     * @generated from protobuf field: int32 original_rotational_speed_exponent = 18;
     */
    originalRotationalSpeedExponent: number;
    /**
     * @generated from protobuf field: int32 original_rotational_speed_exponent_denominator = 19;
     */
    originalRotationalSpeedExponentDenominator: number;
    /**
     * @generated from protobuf field: int32 original_strain_exponent = 20;
     */
    originalStrainExponent: number;
    /**
     * @generated from protobuf field: int32 original_strain_exponent_denominator = 21;
     */
    originalStrainExponentDenominator: number;
    /**
     * information for the presentation of the data in db:
     *
     * @generated from protobuf field: double db_reference_factor = 22;
     */
    dbReferenceFactor: number;
    /**
     * @generated from protobuf field: bool db_with_orientation = 27;
     */
    dbWithOrientation: boolean; // true if negative values means that the value is a vector-value (not skalar) in opposite direction, s. Intensity
    /**
     * @generated from protobuf field: bool is_power_type = 23;
     */
    isPowerType: boolean; // true if power quantity whose dB value will be 10*log10 instead of 20*log10
    // db_with_orientation -> what is this?

    /**
     * information for the calculation of the data in iso data
     *
     * @generated from protobuf field: double iso_factor = 24;
     */
    isoFactor: number;
    /**
     * @generated from protobuf field: double iso_offset = 25;
     */
    isoOffset: number;
    /**
     * --------------- Units -----------------------------------
     * With the physical dimension you can evaluate an unit string s. PhysicalDimension::isoUnitName() .
     *
     * @generated from protobuf field: string unit_iso_name = 28;
     */
    unitIsoName: string;
    /**
     * This unit string is not allways the expected one .
     * Therefor see BBMQuantity::displayUnitName(SiPrefix::SI_PREFIX siPrefix) .
     *
     * @generated from protobuf field: string unit_display_name = 29;
     */
    unitDisplayName: string; // unit_name with special convertions, e.g. 'SQR(x)' -> 'x^2'
    /**
     * Some Quantities do have their own unit string, different from PhysicalDimension::isoUnitName() e.g. Pa [kg/(m*s*s)]
     * Display unit strings often build with prefix . e.g. kPa, hPa ,
     *
     * @generated from protobuf field: string unit_base_name = 30;
     */
    unitBaseName: string; // unit base name , e.g. 'Pa'
    /**
     * @generated from protobuf field: string unit_name = 31;
     */
    unitName: string; // SiPrefix::AXIS_REPRESENTATION(siPrefix) + _unitBaseName; SiPrefix::SYMBOL(siPrefix) + _unitBaseName -> e.g. kPa, hPa
    /**
     * To get the same represantation for every connected client we need some additional information.
     * These are :
     *  SiPrefix::SI_PREFIX _siPrefix;
     *                   ISO Faktor    Name      Symbol     Displaydarstellung
     *            AUTO,  -1.0,         "Auto",   "Auto",     ""
     *            YOTTA, 1e24,         "yotta",  "Y",        "Y"
     *            ZETTA, 1e21,         "zetta",  "Z",        "Z"
     *            EXA,   1e18,         "exa",    "E",        "E"
     *            PETA,  1e15,         "peta",   "P",        "P"
     *            TERA,  1e12,         "tera",   "T",        "T"
     *            GIGA,  1e9,          "giga",   "G",        "G"
     *            MEGA,  1e6,          "mega",   "M",        "M"
     *            KILO,  1e3,          "kilo",   "k",        "k"
     *            HECTO, 1e2,          "hecto",  "h",        "h"
     *            DECA,  1e1,          "deca",   "da",       "da"
     *            DECI,  1e-1,         "deci",   "d",        "d"
     *            CENTI, 1e-2,         "centi",  "c",        "c"
     *            MILLI, 1e-3,         "milli",  "m",        "m"
     *            MICRO, 1e-6,         "micro",  "u",        QString(QChar(L'\x00B5'))
     *            NANO,  1e-9,         "nano",   "n",        "n"
     *            PICO,  1e-12,        "pico",   "p",        "p"
     *            FEMTO, 1e-15,        "femto",  "f",        "f"
     *            ATTO,  1e-18,        "atto",   "a",        "a"
     *            ZEPTO, 1e-21,        "zepto",  "z",        "z"
     *            YOCTO, 1e-24,        "yocto",  "y",        "y"
     *            ENA,   1.0,          "ena",    "1",        ""
     *
     * @generated from protobuf field: string unit_si_prefix = 32;
     */
    unitSiPrefix: string;
    /**
     *  Representation : AXIS_SCALING     {  LINEAR, DB, LOGARITHMIC  };
     *
     * @generated from protobuf field: string axis_scaling = 33;
     */
    axisScaling: string; // "db" , "logarithmic", "linear"
    /**
     *                   Y_AMPLITUDE      {  PEAK,  RMS, PEAK_TO_PEAK };
     *
     * @generated from protobuf field: string y_amplitude = 34;
     */
    yAmplitude: string; // "peak-to-peak", "rms", "peak"
    /**
     *                   DECIMAL_PLACES   {  DP_AUTO = -1, DP_0 = 0, DP_1 = 1, DP_2 = 2, DP_3 = 3, DP_4 = 4, DP_5 = 5, DP_6 = 6, DP_7 = 7, DP_8 = 8, DP_9 = 9,    DP_10 = 10, DP_11 = 11, DP_12 = 12, DP_13 = 13, DP_14 = 14, DP_15 = 15, DP_16 = 16,    DP_MAX = 16
     *
     * @generated from protobuf field: string decimal_places = 35;
     */
    decimalPlaces: string; // "auto", "0", ... , "16"
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.Quantities
 */
export interface Quantities {
    /**
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.Quantity quantities = 1;
     */
    quantities: Quantity[];
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.Position
 */
export interface Position {
    /**
     * @generated from protobuf field: string measurement_point_text = 1;
     */
    measurementPointText: string;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.Position.CoordinateSystemType coordinate_system_type = 2;
     */
    coordinateSystemType: Position_CoordinateSystemType;
    /**
     * cartesian coordinates of this position, i.e. coordinates of the point where the data was measured
     *
     * @generated from protobuf field: double coordinate_x = 3;
     */
    coordinateX: number;
    /**
     * @generated from protobuf field: double coordinate_y = 4;
     */
    coordinateY: number;
    /**
     * @generated from protobuf field: double coordinate_z = 5;
     */
    coordinateZ: number;
    /**
     * @generated from protobuf field: string coordinate_name = 6;
     */
    coordinateName: string;
    /**
     * @generated from protobuf field: double direction_x = 7;
     */
    directionX: number;
    /**
     * @generated from protobuf field: double direction_y = 8;
     */
    directionY: number;
    /**
     * @generated from protobuf field: double direction_z = 9;
     */
    directionZ: number;
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.Position.CoordinateSystemType
 */
export enum Position_CoordinateSystemType {
    /**
     * @generated from protobuf enum value: coord_sys_undefined = 0;
     */
    coord_sys_undefined = 0,
    /**
     * @generated from protobuf enum value: coord_sys_cartesian = 1;
     */
    coord_sys_cartesian = 1,
    /**
     * @generated from protobuf enum value: coord_sys_cylinder_x = 2;
     */
    coord_sys_cylinder_x = 2,
    /**
     * @generated from protobuf enum value: coord_sys_cylinder_y = 3;
     */
    coord_sys_cylinder_y = 3,
    /**
     * @generated from protobuf enum value: coord_sys_cylinder_z = 4;
     */
    coord_sys_cylinder_z = 4,
    /**
     * @generated from protobuf enum value: coord_sys_spherical_x = 5;
     */
    coord_sys_spherical_x = 5,
    /**
     * @generated from protobuf enum value: coord_sys_spherical_y = 6;
     */
    coord_sys_spherical_y = 6,
    /**
     * @generated from protobuf enum value: coord_sys_spherical_z = 7;
     */
    coord_sys_spherical_z = 7
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetCalibration
 */
export interface DatasetCalibration {
    /**
     * values that have to be applied to the data in order to get values in SI units by computing scale * data + offset:
     *
     * @generated from protobuf field: double calibscale = 1;
     */
    calibscale: number;
    /**
     * @generated from protobuf field: double calibfact = 2;
     */
    calibfact: number;
    /**
     * @generated from protobuf field: double calibofs = 3;
     */
    calibofs: number;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetRecLevelQuality
 */
export interface DatasetRecLevelQuality {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.AbsoluteTime fromtime = 1;
     */
    fromtime?: AbsoluteTime;
    /**
     * @generated from protobuf field: bool has_integritystate_ok = 2;
     */
    hasIntegritystateOk: boolean;
    /**
     * @generated from protobuf field: bool has_integritystate_ovld = 3;
     */
    hasIntegritystateOvld: boolean;
    /**
     * @generated from protobuf field: bool has_integritystate_open = 4;
     */
    hasIntegritystateOpen: boolean;
    /**
     * @generated from protobuf field: bool has_integritystate_shortcircuit = 5;
     */
    hasIntegritystateShortcircuit: boolean;
    /**
     * @generated from protobuf field: bool has_integritystate_shorttoground = 6;
     */
    hasIntegritystateShorttoground: boolean;
    /**
     * @generated from protobuf field: bool has_integritystate_invalid = 7;
     */
    hasIntegritystateInvalid: boolean;
    /**
     * @generated from protobuf field: sint32 level_db = 8;
     */
    levelDb: number;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetXValues
 */
export interface DatasetXValues {
    /**
     * @generated from protobuf oneof: x_values
     */
    xValues: {
        oneofKind: "xEquiValues";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.NEquiDoubleValues x_equi_values = 1;
         */
        xEquiValues: NEquiDoubleValues;
    } | {
        oneofKind: "xNonequiValues";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.NonEquiDoubleValues x_nonequi_values = 2;
         */
        xNonequiValues: NonEquiDoubleValues;
    } | {
        oneofKind: "xLabelValues";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.LabelAndDoubleValues x_label_values = 3;
         */
        xLabelValues: LabelAndDoubleValues;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetEOFTime
 */
export interface DatasetEOFTime {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.AbsoluteTime eof_time = 1;
     */
    eofTime?: AbsoluteTime;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetBOFTime
 */
export interface DatasetBOFTime {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.AbsoluteTime start_time = 1;
     */
    startTime?: AbsoluteTime;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.AbsoluteTime first_sample_time = 2;
     */
    firstSampleTime?: AbsoluteTime;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetNextStopTime
 */
export interface DatasetNextStopTime {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.AbsoluteTime stop_time = 1;
     */
    stopTime?: AbsoluteTime;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetStepValues
 */
export interface DatasetStepValues {
    /**
     * @generated from protobuf field: uint32 step_index = 1;
     */
    stepIndex: number;
    /**
     * @generated from protobuf field: double track_value = 2;
     */
    trackValue: number;
    /**
     * @generated from protobuf field: double average_value = 3;
     */
    averageValue: number;
    /**
     * @generated from protobuf field: double order_rpm_value = 4;
     */
    orderRpmValue: number;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetRecLevelQuality rlq = 5;
     */
    rlq?: DatasetRecLevelQuality;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetChangesChunk
 */
export interface DatasetChangesChunk {
    /**
     * @generated from protobuf oneof: data
     */
    data: {
        oneofKind: "datasetCalibration";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetCalibration dataset_calibration = 2;
         */
        datasetCalibration: DatasetCalibration;
    } | {
        oneofKind: "datasetReclevelquality";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetRecLevelQuality dataset_reclevelquality = 3;
         */
        datasetReclevelquality: DatasetRecLevelQuality;
    } | {
        oneofKind: "datasetXValues";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetXValues dataset_x_values = 4;
         */
        datasetXValues: DatasetXValues;
    } | {
        oneofKind: "datasetEofTime";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetEOFTime dataset_eof_time = 5;
         */
        datasetEofTime: DatasetEOFTime;
    } | {
        oneofKind: "datasetStepValues";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetStepValues dataset_step_values = 6;
         */
        datasetStepValues: DatasetStepValues;
    } | {
        oneofKind: "datasetBofTime";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetBOFTime dataset_bof_time = 7;
         */
        datasetBofTime: DatasetBOFTime;
    } | {
        oneofKind: "datasetNextStopTime";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetNextStopTime dataset_next_stop_time = 8;
         */
        datasetNextStopTime: DatasetNextStopTime;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.MeasChainFilter
 */
export interface MeasChainFilter {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.MeasChainFilter.FilterType filter_type = 1;
     */
    filterType: MeasChainFilter_FilterType;
    /**
     * @generated from protobuf field: double LowerCutoff = 2 [json_name = "LowerCutoff"];
     */
    lowerCutoff: number; // Hz
    /**
     * @generated from protobuf field: double UpperCutoff = 3 [json_name = "UpperCutoff"];
     */
    upperCutoff: number; // Hz
    /**
     * @generated from protobuf field: double LowerRolloff = 4 [json_name = "LowerRolloff"];
     */
    lowerRolloff: number; // dB
    /**
     * @generated from protobuf field: double UpperRolloff = 5 [json_name = "UpperRolloff"];
     */
    upperRolloff: number; // dB
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.MeasChainFilter.FilterType
 */
export enum MeasChainFilter_FilterType {
    /**
     * @generated from protobuf enum value: Filter_None = 0;
     */
    Filter_None = 0,
    /**
     * @generated from protobuf enum value: Filter_Highpass = 1;
     */
    Filter_Highpass = 1,
    /**
     * @generated from protobuf enum value: Filter_Lowpass = 2;
     */
    Filter_Lowpass = 2,
    /**
     * @generated from protobuf enum value: Filter_Bandpass = 3;
     */
    Filter_Bandpass = 3
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.MeasChainDevice
 */
export interface MeasChainDevice {
    /**
     * @generated from protobuf field: uint32 meas_chain_device_id = 1;
     */
    measChainDeviceId: number; // reference id - not to use outside, starting from 1, 0 := 'not used'
    // uint32 parent_meas_chain_device_id = 2; // reference id of parent device ;  0 := not available or end of chain

    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.MeasChainDevice.DeviceType device_type = 3;
     */
    deviceType: MeasChainDevice_DeviceType;
    /**
     * @generated from protobuf field: string manufactor = 4;
     */
    manufactor: string;
    /**
     * @generated from protobuf field: string type = 5;
     */
    type: string;
    /**
     * @generated from protobuf field: string serial_number = 6;
     */
    serialNumber: string;
    /**
     * @generated from protobuf field: string software_revision = 7;
     */
    softwareRevision: string;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.MeasChainDevice.EQMState eqm_state = 8;
     */
    eqmState: MeasChainDevice_EQMState;
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.MeasChainDevice.DeviceType
 */
export enum MeasChainDevice_DeviceType {
    /**
     * @generated from protobuf enum value: Controller = 0;
     */
    Controller = 0,
    /**
     * SC card
     *
     * @generated from protobuf enum value: IntfModule = 1;
     */
    IntfModule = 1,
    /**
     * @generated from protobuf enum value: Module = 2;
     */
    Module = 2,
    /**
     * e.g. preamplifier
     *
     * @generated from protobuf enum value: SigCon1 = 3;
     */
    SigCon1 = 3,
    /**
     * other signal conditioning
     *
     * @generated from protobuf enum value: SigCon2 = 4;
     */
    SigCon2 = 4,
    /**
     * @generated from protobuf enum value: Sensor = 5;
     */
    Sensor = 5,
    /**
     * @generated from protobuf enum value: Mainframe = 6;
     */
    Mainframe = 6,
    /**
     * @generated from protobuf enum value: PMCBoard = 7;
     */
    PMCBoard = 7,
    /**
     * @generated from protobuf enum value: Backplane = 8;
     */
    Backplane = 8,
    /**
     * @generated from protobuf enum value: Cable1 = 9;
     */
    Cable1 = 9,
    /**
     * @generated from protobuf enum value: Cable2 = 10;
     */
    Cable2 = 10,
    /**
     * @generated from protobuf enum value: Cable3 = 11;
     */
    Cable3 = 11,
    /**
     * @generated from protobuf enum value: PAKlive = 12;
     */
    PAKlive = 12
}
/**
 * the following state values are stored in the EQM files and should therefore never be changed
 * 'suspect' means the equipment showed some unexpected effects, which might even occur only under special circumstances
 *
 * @generated from protobuf enum BBMgRPCstreamingNS.MeasChainDevice.EQMState
 */
export enum MeasChainDevice_EQMState {
    /**
     * the equipment is not managed with the equipment manager
     *
     * @generated from protobuf enum value: State_NotRegistered = 0;
     */
    State_NotRegistered = 0,
    /**
     * the equipment does not require a calibration
     *
     * @generated from protobuf enum value: State_NoCalibrationRequired = 1;
     */
    State_NoCalibrationRequired = 1,
    /**
     * the equipment was reference-calibrated recently
     *
     * @generated from protobuf enum value: State_Green = 2;
     */
    State_Green = 2,
    /**
     * the equipment is due for calibration in the near future
     *
     * @generated from protobuf enum value: State_Yellow = 3;
     */
    State_Yellow = 3,
    /**
     * the equipment is needs recalibration
     *
     * @generated from protobuf enum value: State_Red = 4;
     */
    State_Red = 4,
    /**
     * the equipment is damaged
     *
     * @generated from protobuf enum value: State_Invalid = 5;
     */
    State_Invalid = 5,
    /**
     * NotRegistered && Suspect
     *
     * @generated from protobuf enum value: State_Suspect = 16;
     */
    State_Suspect = 16,
    /**
     * NoCalibrationRequired && Suspect
     *
     * @generated from protobuf enum value: State_NoCalibrationRequired_Suspect = 17;
     */
    State_NoCalibrationRequired_Suspect = 17,
    /**
     * Green && Suspect
     *
     * @generated from protobuf enum value: State_Green_Suspect = 18;
     */
    State_Green_Suspect = 18,
    /**
     * Yellow && Suspect
     *
     * @generated from protobuf enum value: State_Yellow_Suspect = 19;
     */
    State_Yellow_Suspect = 19,
    /**
     * Red && Suspect
     *
     * @generated from protobuf enum value: State_Red_Suspect = 20;
     */
    State_Red_Suspect = 20
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.MeasChainDevices
 */
export interface MeasChainDevices {
    /**
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.MeasChainDevice meas_chain_devices = 1;
     */
    measChainDevices: MeasChainDevice[];
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.MeasChain
 */
export interface MeasChain {
    /**
     * @generated from protobuf field: repeated uint32 meas_chain_device_ids = 1;
     */
    measChainDeviceIds: number[]; // reference id's - not to use outside, starting from 1, 0 := 'not used'
    /**
     * @generated from protobuf field: bool range_calibrated = 2;
     */
    rangeCalibrated: boolean;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.MeasChain.EqualizationType equalization_type = 3;
     */
    equalizationType: MeasChain_EqualizationType;
    /**
     * @generated from protobuf field: double device_reference_calibration = 4;
     */
    deviceReferenceCalibration: number;
    /**
     * @generated from protobuf field: double device_reference_offset = 5;
     */
    deviceReferenceOffset: number;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.MeasChainFilter meas_chain_filter = 6;
     */
    measChainFilter?: MeasChainFilter;
    /**
     * @generated from protobuf field: double pre_gain_db = 7;
     */
    preGainDb: number; // string            pak_live_sw_rev;
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.MeasChain.EqualizationType
 */
export enum MeasChain_EqualizationType {
    /**
     * @generated from protobuf enum value: Equalization_None = 0;
     */
    Equalization_None = 0,
    /**
     * @generated from protobuf enum value: Equalization_Unknown = 1;
     */
    Equalization_Unknown = 1,
    /**
     * @generated from protobuf enum value: Equalization_Head_Linear = 2;
     */
    Equalization_Head_Linear = 2,
    /**
     * @generated from protobuf enum value: Equalization_Head_FreeField = 3;
     */
    Equalization_Head_FreeField = 3,
    /**
     * @generated from protobuf enum value: Equalization_Head_DiffuseField = 4;
     */
    Equalization_Head_DiffuseField = 4,
    /**
     * @generated from protobuf enum value: Equalization_Head_IndependentDirection = 5;
     */
    Equalization_Head_IndependentDirection = 5,
    /**
     * @generated from protobuf enum value: Equalization_Cortex_Linear = 6;
     */
    Equalization_Cortex_Linear = 6,
    /**
     * @generated from protobuf enum value: Equalization_Cortex_FreeField = 7;
     */
    Equalization_Cortex_FreeField = 7,
    /**
     * @generated from protobuf enum value: Equalization_Cortex_DiffuseField = 8;
     */
    Equalization_Cortex_DiffuseField = 8,
    /**
     * @generated from protobuf enum value: Equalization_Cortex_IndependentDirection = 9;
     */
    Equalization_Cortex_IndependentDirection = 9,
    /**
     * @generated from protobuf enum value: Equalization_Cortex_EqualPerception = 10;
     */
    Equalization_Cortex_EqualPerception = 10
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.Channel
 */
export interface Channel {
    /**
     * @generated from protobuf field: uint32 channel_id = 1;
     */
    channelId: number; // internal unique subscribtion number ; used in datasets meta data
    /**
     * channel number (like BBMChanNr) is NOT used here, because the channel is full qualified with the measurement chain and/or 'channel name'
     *
     * @generated from protobuf field: string channel_name = 2;
     */
    channelName: string; // optional unique name of the channel
    /**
     * @generated from protobuf field: string meas_point_type = 3;
     */
    measPointType: string; // measurement-point type , like 'LS' (LuftSchall), 'KS' (KoerperSchall), ...
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.MeasChain meas_chain = 4;
     */
    measChain?: MeasChain; // measurement chain with all hardware/software components starting from sensor
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.Channels
 */
export interface Channels {
    /**
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.Channel channels = 1;
     */
    channels: Channel[];
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.ReferencedInfo
 */
export interface ReferencedInfo {
    /**
     * @generated from protobuf field: uint32 channel_id = 1;
     */
    channelId: number; //    Channel channel = 1;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.Position position = 2;
     */
    position?: Position;
    /**
     * @generated from protobuf field: uint32 quantity_y1_id = 6;
     */
    quantityY1Id: number; // := Quantity.subscribe_id
    /**
     * @generated from protobuf field: uint32 quantity_y2_id = 7;
     */
    quantityY2Id: number; // := Quantity.subscribe_id
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.ReferencedInfo.VariablePositionMode var_pos_mode = 5;
     */
    varPosMode: ReferencedInfo_VariablePositionMode;
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.ReferencedInfo.VariablePositionMode
 */
export enum ReferencedInfo_VariablePositionMode {
    /**
     * @generated from protobuf enum value: VarPos_None = 0;
     */
    VarPos_None = 0,
    /**
     * @generated from protobuf enum value: VarPos_VariablePos = 1;
     */
    VarPos_VariablePos = 1,
    /**
     * @generated from protobuf enum value: VarPos_VariableCoord = 2;
     */
    VarPos_VariableCoord = 2
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.WindowInfo
 */
export interface WindowInfo {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.WindowInfo.WindowType window_type = 1;
     */
    windowType: WindowInfo_WindowType;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.WindowInfo.WindowCorrection window_correction = 2;
     */
    windowCorrection: WindowInfo_WindowCorrection;
    /**
     * @generated from protobuf field: double broad_band_factor = 3;
     */
    broadBandFactor: number; // !< the correction factor from the value in Correction to WindowCorrection::BroadBand
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.WindowInfo.WindowType
 */
export enum WindowInfo_WindowType {
    /**
     * @generated from protobuf enum value: WindowType_Undefined = 0;
     */
    WindowType_Undefined = 0,
    /**
     * @generated from protobuf enum value: WindowType_Uniform = 1;
     */
    WindowType_Uniform = 1,
    /**
     * @generated from protobuf enum value: WindowType_Hanning = 2;
     */
    WindowType_Hanning = 2,
    /**
     * @generated from protobuf enum value: WindowType_Flattop = 3;
     */
    WindowType_Flattop = 3,
    /**
     * @generated from protobuf enum value: WindowType_Hamming = 4;
     */
    WindowType_Hamming = 4,
    /**
     * @generated from protobuf enum value: WindowType_Kaiser_Bessel = 5;
     */
    WindowType_Kaiser_Bessel = 5,
    /**
     * @generated from protobuf enum value: WindowType_Blackman = 6;
     */
    WindowType_Blackman = 6,
    /**
     * @generated from protobuf enum value: WindowType_Expo = 7;
     */
    WindowType_Expo = 7,
    /**
     * @generated from protobuf enum value: WindowType_User_Rect = 8;
     */
    WindowType_User_Rect = 8,
    /**
     * @generated from protobuf enum value: WindowType_Force = 9;
     */
    WindowType_Force = 9,
    /**
     * @generated from protobuf enum value: WindowType_User_Expo = 10;
     */
    WindowType_User_Expo = 10,
    /**
     * @generated from protobuf enum value: WindowType_User_Defined = 11;
     */
    WindowType_User_Defined = 11
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.WindowInfo.WindowCorrection
 */
export enum WindowInfo_WindowCorrection {
    /**
     * @generated from protobuf enum value: WindowCorr_Undefined = 0;
     */
    WindowCorr_Undefined = 0,
    /**
     * @generated from protobuf enum value: WindowCorr_None = 1;
     */
    WindowCorr_None = 1,
    /**
     * @generated from protobuf enum value: WindowCorr_NarrowBand = 2;
     */
    WindowCorr_NarrowBand = 2,
    /**
     * @generated from protobuf enum value: WindowCorr_BroadBand = 3;
     */
    WindowCorr_BroadBand = 3
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DataInfo
 */
export interface DataInfo {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataInfo.ChannelInfo track_info = 1;
     */
    trackInfo?: DataInfo_ChannelInfo;
    /**
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.DataInfo.ChannelInfo rpm_infos = 2;
     */
    rpmInfos: DataInfo_ChannelInfo[];
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataInfo.SamplingGroupInfo sampling_group_infos = 3;
     */
    samplingGroupInfos?: DataInfo_SamplingGroupInfo;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataInfo.TimeSourceInfo time_source_info = 4;
     */
    timeSourceInfo?: DataInfo_TimeSourceInfo;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataInfo.TputCompatInfo tput_compat_into = 5;
     */
    tputCompatInto?: DataInfo_TputCompatInfo;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataInfo.MSListIndexInfo ms_list_index_info = 6;
     */
    msListIndexInfo?: DataInfo_MSListIndexInfo;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataInfo.OriginalTrackInfo original_track_info = 7;
     */
    originalTrackInfo?: DataInfo_OriginalTrackInfo;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DataInfo.ChannelInfo
 */
export interface DataInfo_ChannelInfo {
    /**
     * @generated from protobuf field: uint32 channel_id = 1;
     */
    channelId: number; //    Channel channel = 1;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.Position position = 2;
     */
    position?: Position;
    /**
     * @generated from protobuf field: uint32 quantity_id = 4;
     */
    quantityId: number; // := Quantity.subscribe_id
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DataInfo.SamplingGroupInfo
 */
export interface DataInfo_SamplingGroupInfo {
    /**
     * @generated from protobuf field: int32 index = 1;
     */
    index: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DataInfo.TimeSourceInfo
 */
export interface DataInfo_TimeSourceInfo {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataInfo.TimeSourceInfo.TimeSourceType time_source_type = 1;
     */
    timeSourceType: DataInfo_TimeSourceInfo_TimeSourceType;
    /**
     * @generated from protobuf field: string id = 2;
     */
    id: string;
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.DataInfo.TimeSourceInfo.TimeSourceType
 */
export enum DataInfo_TimeSourceInfo_TimeSourceType {
    /**
     * the clock is not synchronized with external time sources
     *
     * @generated from protobuf enum value: TimeSourceType_Internal = 0;
     */
    TimeSourceType_Internal = 0,
    /**
     * the clock uses synclink for synchronization
     *
     * @generated from protobuf enum value: TimeSourceType_Synclink = 1;
     */
    TimeSourceType_Synclink = 1,
    /**
     * the clock uses GPS for synchronization
     *
     * @generated from protobuf enum value: TimeSourceType_Gps = 2;
     */
    TimeSourceType_Gps = 2,
    /**
     * the clock uses PTP for synchronization
     *
     * @generated from protobuf enum value: TimeSourceType_Ptp = 3;
     */
    TimeSourceType_Ptp = 3,
    /**
     * the clock uses an unknown method for synchronization
     *
     * @generated from protobuf enum value: TimeSourceType_Unknown = 7;
     */
    TimeSourceType_Unknown = 7
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DataInfo.MSListIndexInfo
 */
export interface DataInfo_MSListIndexInfo {
    /**
     * @generated from protobuf field: int32 index = 1;
     */
    index: number;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DataInfo.TputCompatInfo
 */
export interface DataInfo_TputCompatInfo {
    /**
     * @generated from protobuf field: int32 device = 1;
     */
    device: number;
    /**
     * @generated from protobuf field: int32 slot = 2;
     */
    slot: number;
    /**
     * @generated from protobuf field: int32 range = 3;
     */
    range: number;
    /**
     * @generated from protobuf field: int32 type = 4;
     */
    type: number;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DataInfo.OriginalTrackInfo
 */
export interface DataInfo_OriginalTrackInfo {
    /**
     * @generated from protobuf field: uint32 channel_id = 1;
     */
    channelId: number; //    Channel channel = 1;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.Position position = 2;
     */
    position?: Position;
}
/**
 * DatasetThreeDimensionalChunk represents three-dimensional data, e.g. an APS
 * careful: this is not the same as Dataset3D in PAK, e.g. a SlowQuantity is not three-dimensional
 *
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData
 */
export interface DatasetThreeDimensionalMetaData {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.Shape shape = 14;
     */
    shape: Shape;
    /**
     * @generated from protobuf field: bool y_data_is_squared = 15;
     */
    yDataIsSquared: boolean; // if this value is true, it should always be sent by the server because the default is false
    /**
     * @generated from protobuf field: uint32 quantity_x_id = 41;
     */
    quantityXId: number; // := Quantity.subscribe_id
    /**
     * @generated from protobuf field: uint32 quantity_y_id = 42;
     */
    quantityYId: number; // := Quantity.subscribe_id
    /**
     * @generated from protobuf field: uint32 quantity_z_id = 43;
     */
    quantityZId: number; // := Quantity.subscribe_id
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DateTime measurement_start_time = 19;
     */
    measurementStartTime?: DateTime;
    /**
     * @generated from protobuf field: uint32 channel_id = 44;
     */
    channelId: number; //    Channel channel = 1;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataType type = 20;
     */
    type: DataType;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.Position position = 21;
     */
    position?: Position;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.ReferencedInfo referenced_info = 22;
     */
    referencedInfo?: ReferencedInfo;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetXValues x_values = 23;
     */
    xValues?: DatasetXValues;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DoubleRange scale_range_y = 45;
     */
    scaleRangeY?: DoubleRange;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DoubleRange scale_range_z = 24;
     */
    scaleRangeZ?: DoubleRange;
    /**
     * @generated from protobuf field: uint64 num_potential_steps = 25;
     */
    numPotentialSteps: string;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.AverageType avg_type = 26;
     */
    avgType: AverageType;
    /**
     * @generated from protobuf field: double avg_value = 27;
     */
    avgValue: number;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.FrequenzWeight freq_weight = 28;
     */
    freqWeight: FrequenzWeight;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataInfo data_info = 29;
     */
    dataInfo?: DataInfo;
    /**
     * @generated from protobuf oneof: type_specific_data
     */
    typeSpecificData: {
        oneofKind: "spectrumParameters";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters spectrum_parameters = 30;
         */
        spectrumParameters: DatasetThreeDimensionalMetaData_SpectrumParameters;
    } | {
        oneofKind: "psychoParameters";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams psycho_parameters = 31;
         */
        psychoParameters: DatasetThreeDimensionalMetaData_PsychoParams;
    } | {
        oneofKind: "cepstrumParameters";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.CepstrumParams cepstrum_parameters = 32;
         */
        cepstrumParameters: DatasetThreeDimensionalMetaData_CepstrumParams;
    } | {
        oneofKind: "octaveParameters";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.OctaveParams octave_parameters = 33;
         */
        octaveParameters: DatasetThreeDimensionalMetaData_OctaveParams;
    } | {
        oneofKind: "edgeBlockParameters";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.TachoValueParams edge_block_parameters = 34;
         */
        edgeBlockParameters: TachoValueParams;
    } | {
        oneofKind: "trajectoryParameters";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.TrajectoryParams trajectory_parameters = 35;
         */
        trajectoryParameters: DatasetThreeDimensionalMetaData_TrajectoryParams;
    } | {
        oneofKind: "unknownParameters";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.UnknownParams unknown_parameters = 36;
         */
        unknownParameters: DatasetThreeDimensionalMetaData_UnknownParams;
    } | {
        oneofKind: "waveletParameters";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.WaveletParams wavelet_parameters = 37;
         */
        waveletParameters: DatasetThreeDimensionalMetaData_WaveletParams;
    } | {
        oneofKind: "detectorParameters";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.DetectorParams detector_parameters = 38;
         */
        detectorParameters: DatasetThreeDimensionalMetaData_DetectorParams;
    } | {
        oneofKind: "xlabelParameters";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.XLabelParams xlabel_parameters = 39;
         */
        xlabelParameters: DatasetThreeDimensionalMetaData_XLabelParams;
    } | {
        oneofKind: "contourBaseParameters";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.ContourBaseParams contour_base_parameters = 40;
         */
        contourBaseParameters: DatasetThreeDimensionalMetaData_ContourBaseParams;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters
 */
export interface DatasetThreeDimensionalMetaData_SpectrumParameters {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.WindowInfo window_info = 1;
     */
    windowInfo?: WindowInfo;
    /**
     * @generated from protobuf field: bool kalman = 2;
     */
    kalman: boolean;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataFormat data_format = 3;
     */
    dataFormat: DataFormat;
    /**
     * @generated from protobuf oneof: type_specific_data
     */
    typeSpecificData: {
        oneofKind: "cohenParams";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters.CohenParams cohen_params = 11;
         */
        cohenParams: DatasetThreeDimensionalMetaData_SpectrumParameters_CohenParams;
    } | {
        oneofKind: "msoParams";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters.MultipleSingleOrderParams mso_params = 12;
         */
        msoParams: DatasetThreeDimensionalMetaData_SpectrumParameters_MultipleSingleOrderParams;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters.MultipleSingleOrderParams
 */
export interface DatasetThreeDimensionalMetaData_SpectrumParameters_MultipleSingleOrderParams {
    /**
     * @generated from protobuf field: repeated double band_width_values = 1;
     */
    bandWidthValues: number[];
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters.CohenParams
 */
export interface DatasetThreeDimensionalMetaData_SpectrumParameters_CohenParams {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters.CohenParams.CohenClassType cohen_class_type = 1;
     */
    cohenClassType: DatasetThreeDimensionalMetaData_SpectrumParameters_CohenParams_CohenClassType;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.WindowInfo smooth_window_info = 2;
     */
    smoothWindowInfo?: WindowInfo;
    /**
     * @generated from protobuf field: int32 smooth_size = 3;
     */
    smoothSize: number;
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters.CohenParams.CohenClassType
 */
export enum DatasetThreeDimensionalMetaData_SpectrumParameters_CohenParams_CohenClassType {
    /**
     * @generated from protobuf enum value: CohenClass_None = 0;
     */
    CohenClass_None = 0,
    /**
     * @generated from protobuf enum value: CohenClass_WignerVille = 1;
     */
    CohenClass_WignerVille = 1,
    /**
     * @generated from protobuf enum value: CohenClass_ChoiWilliams = 2;
     */
    CohenClass_ChoiWilliams = 2,
    /**
     * must be last
     *
     * @generated from protobuf enum value: CohenClass_NumOf = 7;
     */
    CohenClass_NumOf = 7
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams
 */
export interface DatasetThreeDimensionalMetaData_PsychoParams {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.PsychoType psycho_type = 1;
     */
    psychoType: DatasetThreeDimensionalMetaData_PsychoParams_PsychoType;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.PsychoCoreType psycho_core_type = 2;
     */
    psychoCoreType: DatasetThreeDimensionalMetaData_PsychoParams_PsychoCoreType;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.SoundField sound_field = 3;
     */
    soundField: DatasetThreeDimensionalMetaData_PsychoParams_SoundField;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.LoudnessScale loudness_scale = 4;
     */
    loudnessScale: DatasetThreeDimensionalMetaData_PsychoParams_LoudnessScale;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.SharpnessType sharpness_type = 5;
     */
    sharpnessType: DatasetThreeDimensionalMetaData_PsychoParams_SharpnessType;
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.PsychoType
 */
export enum DatasetThreeDimensionalMetaData_PsychoParams_PsychoType {
    /**
     * @generated from protobuf enum value: Psycho_None = 0;
     */
    Psycho_None = 0,
    /**
     * @generated from protobuf enum value: Psycho_Loudness_Instat = 1;
     */
    Psycho_Loudness_Instat = 1,
    /**
     * @generated from protobuf enum value: Psycho_Loudness_Din = 2;
     */
    Psycho_Loudness_Din = 2,
    /**
     * @generated from protobuf enum value: Psycho_Sharpness = 3;
     */
    Psycho_Sharpness = 3,
    /**
     * @generated from protobuf enum value: Psycho_Roughness = 4;
     */
    Psycho_Roughness = 4,
    /**
     * @generated from protobuf enum value: Psycho_EngineRoughness = 5;
     */
    Psycho_EngineRoughness = 5,
    /**
     * @generated from protobuf enum value: Psycho_Fluctuation = 6;
     */
    Psycho_Fluctuation = 6,
    /**
     * @generated from protobuf enum value: Psycho_AI_Closed = 7;
     */
    Psycho_AI_Closed = 7,
    /**
     * @generated from protobuf enum value: Psycho_AI_Open = 8;
     */
    Psycho_AI_Open = 8,
    /**
     * @generated from protobuf enum value: Psycho_AI_Mod = 9;
     */
    Psycho_AI_Mod = 9,
    /**
     * @generated from protobuf enum value: Psycho_Loudness_Instat_Area = 10;
     */
    Psycho_Loudness_Instat_Area = 10,
    /**
     * @generated from protobuf enum value: Psycho_Loudness_Din_Area = 11;
     */
    Psycho_Loudness_Din_Area = 11,
    /**
     * @generated from protobuf enum value: Psycho_Roughness_Area = 12;
     */
    Psycho_Roughness_Area = 12,
    /**
     * @generated from protobuf enum value: Psycho_Fluctuation_Area = 13;
     */
    Psycho_Fluctuation_Area = 13,
    /**
     * @generated from protobuf enum value: Psycho_HSQ_Mon = 14;
     */
    Psycho_HSQ_Mon = 14,
    /**
     * @generated from protobuf enum value: Psycho_HSQ_Bin = 15;
     */
    Psycho_HSQ_Bin = 15,
    /**
     * @generated from protobuf enum value: Psycho_HSQ_Mon_Attribute = 16;
     */
    Psycho_HSQ_Mon_Attribute = 16,
    /**
     * @generated from protobuf enum value: Psycho_HSQ_Bin_Attribute = 17;
     */
    Psycho_HSQ_Bin_Attribute = 17,
    /**
     * @generated from protobuf enum value: Psycho_HVQ_Mon = 18;
     */
    Psycho_HVQ_Mon = 18,
    /**
     * @generated from protobuf enum value: Psycho_Tonality_Din = 19;
     */
    Psycho_Tonality_Din = 19,
    /**
     * @generated from protobuf enum value: Psycho_Tone2Noise_Ansi = 20;
     */
    Psycho_Tone2Noise_Ansi = 20,
    /**
     * @generated from protobuf enum value: Psycho_Prominence_Ansi = 21;
     */
    Psycho_Prominence_Ansi = 21,
    /**
     * @generated from protobuf enum value: Psycho_NumOf = 25;
     */
    Psycho_NumOf = 25
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.PsychoCoreType
 */
export enum DatasetThreeDimensionalMetaData_PsychoParams_PsychoCoreType {
    /**
     * @generated from protobuf enum value: Psycho_CoreUndefined = 0;
     */
    Psycho_CoreUndefined = 0,
    /**
     * @generated from protobuf enum value: Psycho_Core = 1;
     */
    Psycho_Core = 1,
    /**
     * @generated from protobuf enum value: Psycho_CoreTrail = 2;
     */
    Psycho_CoreTrail = 2,
    /**
     * @generated from protobuf enum value: Psycho_3rdOctaves = 3;
     */
    Psycho_3rdOctaves = 3,
    /**
     * @generated from protobuf enum value: Psycho_3rdOctavesTrail = 4;
     */
    Psycho_3rdOctavesTrail = 4
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.SoundField
 */
export enum DatasetThreeDimensionalMetaData_PsychoParams_SoundField {
    /**
     * @generated from protobuf enum value: SoundField_Undefined = 0;
     */
    SoundField_Undefined = 0,
    /**
     * @generated from protobuf enum value: SoundField_Free = 1;
     */
    SoundField_Free = 1,
    /**
     * @generated from protobuf enum value: SoundField_Diffuse = 2;
     */
    SoundField_Diffuse = 2
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.LoudnessScale
 */
export enum DatasetThreeDimensionalMetaData_PsychoParams_LoudnessScale {
    /**
     * @generated from protobuf enum value: LoudnessScale_Undefined = 0;
     */
    LoudnessScale_Undefined = 0,
    /**
     * @generated from protobuf enum value: LoudnessScale_DIN = 1;
     */
    LoudnessScale_DIN = 1,
    /**
     * @generated from protobuf enum value: LoudnessScale_MBBM = 2;
     */
    LoudnessScale_MBBM = 2
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.SharpnessType
 */
export enum DatasetThreeDimensionalMetaData_PsychoParams_SharpnessType {
    /**
     * @generated from protobuf enum value: Sharpness_Undefined = 0;
     */
    Sharpness_Undefined = 0,
    /**
     * @generated from protobuf enum value: Sharpness_Aures = 1;
     */
    Sharpness_Aures = 1,
    /**
     * @generated from protobuf enum value: Sharpness_Widmann = 2;
     */
    Sharpness_Widmann = 2,
    /**
     * @generated from protobuf enum value: Sharpness_Bismarck = 3;
     */
    Sharpness_Bismarck = 3
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.CepstrumParams
 */
export interface DatasetThreeDimensionalMetaData_CepstrumParams {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.CepstrumParams.CepstrumType cepstrum_type = 1;
     */
    cepstrumType: DatasetThreeDimensionalMetaData_CepstrumParams_CepstrumType;
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.CepstrumParams.CepstrumType
 */
export enum DatasetThreeDimensionalMetaData_CepstrumParams_CepstrumType {
    /**
     * @generated from protobuf enum value: Power_Cepstrum = 0;
     */
    Power_Cepstrum = 0,
    /**
     * @generated from protobuf enum value: Complex_Cepstrum = 1;
     */
    Complex_Cepstrum = 1,
    /**
     * @generated from protobuf enum value: Cepstrum_NumOf = 5;
     */
    Cepstrum_NumOf = 5
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.OctaveParams
 */
export interface DatasetThreeDimensionalMetaData_OctaveParams {
    /**
     * @generated from protobuf field: uint32 octave_nth = 1;
     */
    octaveNth: number;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PhaseInfo
 */
export interface DatasetThreeDimensionalMetaData_PhaseInfo {
    /**
     * @generated from protobuf field: double phase_from = 1;
     */
    phaseFrom: number;
    /**
     * @generated from protobuf field: double phase_to = 2;
     */
    phaseTo: number;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.TrajectoryParams
 */
export interface DatasetThreeDimensionalMetaData_TrajectoryParams {
    /**
     * @generated from protobuf field: uint32 quantity_s_id = 6;
     */
    quantitySId: number; // := Quantity.subscribe_id
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetXValues s_values = 2;
     */
    sValues?: DatasetXValues;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataType base_type = 3;
     */
    baseType: DataType;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.Shape base_shape = 4;
     */
    baseShape: Shape;
    /**
     * @generated from protobuf field: bool represents_nyquist = 5;
     */
    representsNyquist: boolean;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.UnknownParams
 */
export interface DatasetThreeDimensionalMetaData_UnknownParams {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataFormat data_format = 1;
     */
    dataFormat: DataFormat;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.UnknownParams.Subtype subtype = 2;
     */
    subtype: DatasetThreeDimensionalMetaData_UnknownParams_Subtype;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.WindowInfo window_info = 3;
     */
    windowInfo?: WindowInfo;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PhaseInfo phase_info = 4;
     */
    phaseInfo?: DatasetThreeDimensionalMetaData_PhaseInfo;
    /**
     * @generated from protobuf field: bool kalman = 5;
     */
    kalman: boolean;
    /**
     * @generated from protobuf field: bool requires_peak = 6;
     */
    requiresPeak: boolean;
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.UnknownParams.Subtype
 */
export enum DatasetThreeDimensionalMetaData_UnknownParams_Subtype {
    /**
     * @generated from protobuf enum value: SubType_Unkown = 0;
     */
    SubType_Unkown = 0,
    /**
     * @generated from protobuf enum value: SubType_OrderGroup = 1;
     */
    SubType_OrderGroup = 1,
    /**
     * @generated from protobuf enum value: SubType_OrderPeriod = 2;
     */
    SubType_OrderPeriod = 2,
    /**
     * @generated from protobuf enum value: SubType_OrderPeriodMaxPhase = 3;
     */
    SubType_OrderPeriodMaxPhase = 3,
    /**
     * @generated from protobuf enum value: Subtype_MaxAmpFreq = 4;
     */
    Subtype_MaxAmpFreq = 4,
    /**
     * @generated from protobuf enum value: Subtype_XYData = 5;
     */
    Subtype_XYData = 5
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.WaveletParams
 */
export interface DatasetThreeDimensionalMetaData_WaveletParams {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataFormat data_format = 1;
     */
    dataFormat: DataFormat;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.WaveletParams.WaveletType wavelet_type = 2;
     */
    waveletType: DatasetThreeDimensionalMetaData_WaveletParams_WaveletType;
    /**
     * @generated from protobuf field: int32 wavelet_periods = 3;
     */
    waveletPeriods: number;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PhaseInfo phase_info = 4;
     */
    phaseInfo?: DatasetThreeDimensionalMetaData_PhaseInfo;
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.WaveletParams.WaveletType
 */
export enum DatasetThreeDimensionalMetaData_WaveletParams_WaveletType {
    /**
     * @generated from protobuf enum value: Wavelet_Unknown = 0;
     */
    Wavelet_Unknown = 0,
    /**
     * @generated from protobuf enum value: Wavelet_Morlet = 1;
     */
    Wavelet_Morlet = 1,
    /**
     * @generated from protobuf enum value: Wavelet_Morlet2 = 2;
     */
    Wavelet_Morlet2 = 2,
    /**
     * @generated from protobuf enum value: Wavelet_GaussGeneral = 3;
     */
    Wavelet_GaussGeneral = 3,
    /**
     * @generated from protobuf enum value: Wavelet_Laplace = 4;
     */
    Wavelet_Laplace = 4,
    /**
     * @generated from protobuf enum value: Wavelet_GaussNDeriv = 5;
     */
    Wavelet_GaussNDeriv = 5,
    /**
     * @generated from protobuf enum value: Wavelet_MexicanHat = 6;
     */
    Wavelet_MexicanHat = 6,
    /**
     * @generated from protobuf enum value: Wavelet_Goupillaud = 7;
     */
    Wavelet_Goupillaud = 7
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.DetectorParams
 */
export interface DatasetThreeDimensionalMetaData_DetectorParams {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.DetectorParams.DetectorType detector_type = 1;
     */
    detectorType: DatasetThreeDimensionalMetaData_DetectorParams_DetectorType;
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.DetectorParams.DetectorType
 */
export enum DatasetThreeDimensionalMetaData_DetectorParams_DetectorType {
    /**
     * !< exponential averaging of the squared signal
     *
     * @generated from protobuf enum value: Detector_RMS = 0;
     */
    Detector_RMS = 0,
    /**
     * !< peak value with exponential decay
     *
     * @generated from protobuf enum value: Detector_Peak = 1;
     */
    Detector_Peak = 1,
    /**
     * !< absolute value with exponential averaging
     *
     * @generated from protobuf enum value: Detector_Avg = 2;
     */
    Detector_Avg = 2,
    /**
     * !< energy equivalent sound pressure level with linear averaging
     *
     * @generated from protobuf enum value: Detector_LEQ = 3;
     */
    Detector_LEQ = 3,
    /**
     * !< absolute peak of a time interval without exponential averaging
     *
     * @generated from protobuf enum value: Detector_AbsPeak = 4;
     */
    Detector_AbsPeak = 4,
    /**
     * must be last
     *
     * @generated from protobuf enum value: Detector_NumOf = 7;
     */
    Detector_NumOf = 7
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.XLabelParams
 */
export interface DatasetThreeDimensionalMetaData_XLabelParams {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataType type_orig = 1;
     */
    typeOrig: DataType;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.Shape shape_orig = 2;
     */
    shapeOrig: Shape;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.ContourBaseParams
 */
export interface DatasetThreeDimensionalMetaData_ContourBaseParams {
    /**
     * @generated from protobuf field: string dt_string = 1;
     */
    dtString: string;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataType dt_orig = 2;
     */
    dtOrig: DataType;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.Shape shape_orig = 3;
     */
    shapeOrig: Shape;
    /**
     * @generated from protobuf field: uint32 quantityX_orig_id = 6;
     */
    quantityXOrigId: number; // := Quantity.subscribe_id
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.ContourBaseParams.ContourParams contour_parameters = 5;
     */
    contourParameters?: DatasetThreeDimensionalMetaData_ContourBaseParams_ContourParams;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.ContourBaseParams.ContourParams
 */
export interface DatasetThreeDimensionalMetaData_ContourBaseParams_ContourParams {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataType dt = 1;
     */
    dt: DataType;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.StandardValueTypeParams
 */
export interface StandardValueTypeParams {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DatasetCalibration scale_calib = 1;
     */
    scaleCalib?: DatasetCalibration;
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DigBusValueTypeParams
 */
export interface DigBusValueTypeParams {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DigBusValueTypeParams.DigBusMessageType dig_bus_message_type = 1;
     */
    digBusMessageType: DigBusValueTypeParams_DigBusMessageType;
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.DigBusValueTypeParams.DigBusMessageType
 */
export enum DigBusValueTypeParams_DigBusMessageType {
    /**
     * @generated from protobuf enum value: CAN_Message = 0;
     */
    CAN_Message = 0,
    /**
     * @generated from protobuf enum value: FlexRay_Message = 1;
     */
    FlexRay_Message = 1,
    /**
     * @generated from protobuf enum value: EtherCat_Message = 2;
     */
    EtherCat_Message = 2,
    /**
     * @generated from protobuf enum value: PTP_Message = 3;
     */
    PTP_Message = 3,
    /**
     * @generated from protobuf enum value: IRIG_Message = 4;
     */
    IRIG_Message = 4,
    /**
     * @generated from protobuf enum value: GPS_Message = 5;
     */
    GPS_Message = 5
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.TachoValueParams
 */
export interface TachoValueParams {
    /**
     * @generated from protobuf field: double imp_per_period = 2;
     */
    impPerPeriod: number;
    /**
     * @generated from protobuf field: double hz_per_eu = 3;
     */
    hzPerEu: number;
    /**
     * @generated from protobuf field: double tacho_base_frequency = 4;
     */
    tachoBaseFrequency: number;
    /**
     * @generated from protobuf field: uint32 num_potential_lines = 5;
     */
    numPotentialLines: number; // Used in EdgeBlock prototypes, not in 1D Tacho
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.GPSValueParams
 */
export interface GPSValueParams {
    /**
     * @generated from protobuf field: sint32 leap_seconds = 1;
     */
    leapSeconds: number;
}
/**
 * DatasetTwoDimensionalChunk represents two-dimensional data, e.g. an Throughput, Slows
 * careful: this is not the same as Dataset3D in PAK, e.g. a SlowQuantity is not three-dimensional
 *
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetTwoDimensionalMetaData
 */
export interface DatasetTwoDimensionalMetaData {
    /**
     * @generated from protobuf oneof: scale_x
     */
    scaleX: {
        oneofKind: "equiTimeScale";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.EquiTimeScale equi_time_scale = 10;
         */
        equiTimeScale: EquiTimeScale;
    } | {
        oneofKind: "compressedEquiTimeScale";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.CompressedEquiTimeScale compressed_equi_time_scale = 11;
         */
        compressedEquiTimeScale: CompressedEquiTimeScale;
    } | {
        oneofKind: "angleVals";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.EquiDoubleValues angle_vals = 12;
         */
        angleVals: EquiDoubleValues;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf oneof: scale_y
     */
    scaleY: {
        oneofKind: "standardValueTypeParams";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.StandardValueTypeParams standard_value_type_params = 20;
         */
        standardValueTypeParams: StandardValueTypeParams;
    } | {
        oneofKind: "digBusValueTypeParams";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DigBusValueTypeParams dig_bus_value_type_params = 21;
         */
        digBusValueTypeParams: DigBusValueTypeParams;
    } | {
        oneofKind: "tachoValueParams";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.TachoValueParams tacho_value_params = 22;
         */
        tachoValueParams: TachoValueParams;
    } | {
        oneofKind: "gpsValueParams";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.GPSValueParams gps_value_params = 23;
         */
        gpsValueParams: GPSValueParams;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.FrequenzWeight freq_weight = 28;
     */
    freqWeight: FrequenzWeight;
    /**
     * @generated from protobuf field: uint64 npoints = 34;
     */
    npoints: string;
    /**
     * @generated from protobuf field: uint32 quantity_x_id = 43;
     */
    quantityXId: number; // := Quantity.subscribe_id
    /**
     * @generated from protobuf field: uint32 quantity_y_id = 44;
     */
    quantityYId: number; // := Quantity.subscribe_id
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.AbsoluteTimeRange scale_range_x = 46;
     */
    scaleRangeX?: AbsoluteTimeRange;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DoubleRange scale_range_y = 47;
     */
    scaleRangeY?: DoubleRange;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DoubleRange scale_range_z = 48;
     */
    scaleRangeZ?: DoubleRange;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DateTime measurement_start_time = 51;
     */
    measurementStartTime?: DateTime;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataType type = 61;
     */
    type: DataType;
    /**
     * @generated from protobuf field: uint32 channel_id = 75;
     */
    channelId: number; //    Channel channel = 1;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.Position position = 71;
     */
    position?: Position;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.ReferencedInfo referenced_info = 72;
     */
    referencedInfo?: ReferencedInfo;
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.AverageType avg_type = 73;
     */
    avgType: AverageType; // mainly for SlowQuantity
    /**
     * @generated from protobuf field: double avg_value = 74;
     */
    avgValue: number; // mainly for SlowQuantity
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.DataInfo data_info = 81;
     */
    dataInfo?: DataInfo;
}
/**
 * DatasetMessage is the base element for streaming
 * each DatasetMessage contains a chunk of one dataset
 *
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetMetaData
 */
export interface DatasetMetaData {
    /**
     * @generated from protobuf oneof: data
     */
    data: {
        oneofKind: "ds3DMetadata";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData ds3d_metadata = 2 [json_name = "ds3dMetadata"];
         */
        ds3DMetadata: DatasetThreeDimensionalMetaData;
    } | {
        oneofKind: "ds2DMetadata";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetTwoDimensionalMetaData ds2d_metadata = 3 [json_name = "ds2dMetadata"];
         */
        ds2DMetadata: DatasetTwoDimensionalMetaData;
    } | {
        oneofKind: undefined;
    };
}
/**
 * DatasetThreeDimensionalChunk represents three-dimensional data, e.g. an APS
 * careful: this is not the same as Dataset3D in PAK, e.g. a SlowQuantity is not three-dimensional
 *
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalChunk
 */
export interface DatasetThreeDimensionalChunk {
    /**
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.DatasetThreeDimensionalChunk.Step step = 1;
     */
    step: DatasetThreeDimensionalChunk_Step[];
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalChunk.Step
 */
export interface DatasetThreeDimensionalChunk_Step {
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.AbsoluteTime time = 1;
     */
    time?: AbsoluteTime;
    /**
     * @generated from protobuf field: double z_value = 2;
     */
    zValue: number;
    /**
     * @generated from protobuf oneof: y_values
     */
    yValues: {
        oneofKind: "yInt32Values";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.NonEquiInt32Values y_int32_values = 3;
         */
        yInt32Values: NonEquiInt32Values;
    } | {
        oneofKind: "yFloatValues";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.NonEquiFloatValues y_float_values = 4;
         */
        yFloatValues: NonEquiFloatValues;
    } | {
        oneofKind: "yDoubleValues";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.NonEquiDoubleValues y_double_values = 5;
         */
        yDoubleValues: NonEquiDoubleValues;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.ValueType value_type = 10;
     */
    valueType: ValueType;
}
/**
 * DatasetTwoDimensionalChunk represents two-dimensional data, e.g. an Throughput, Slows
 * careful: this is not the same as Dataset3D in PAK, e.g. a SlowQuantity is not three-dimensional
 *
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetTwoDimensionalEquiTimeChunk
 */
export interface DatasetTwoDimensionalEquiTimeChunk {
    // e.g. time values in the case of an Thruput

    /**
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.DatasetTwoDimensionalEquiTimeChunk.DataBlock data_block = 1;
     */
    dataBlock: DatasetTwoDimensionalEquiTimeChunk_DataBlock[];
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetTwoDimensionalEquiTimeChunk.DataBlock
 */
export interface DatasetTwoDimensionalEquiTimeChunk_DataBlock {
    /**
     * @generated from protobuf oneof: y_values
     */
    yValues: {
        oneofKind: "yInt32Values";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.NonEquiInt32Values y_int32_values = 10;
         */
        yInt32Values: NonEquiInt32Values;
    } | {
        oneofKind: "yFloatValues";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.NonEquiFloatValues y_float_values = 11;
         */
        yFloatValues: NonEquiFloatValues;
    } | {
        oneofKind: "yDoubleValues";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.NonEquiDoubleValues y_double_values = 12;
         */
        yDoubleValues: NonEquiDoubleValues;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.ValueType value_type = 20;
     */
    valueType: ValueType;
}
/**
 * DatasetTwoDimensionalChunk represents two-dimensional data, e.g. an Throughput, Slows
 * careful: this is not the same as Dataset3D in PAK, e.g. a SlowQuantity is not three-dimensional
 *
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetTwoDimensionalNonEquiTimeChunk
 */
export interface DatasetTwoDimensionalNonEquiTimeChunk {
    // e.g. time values in the case of an Thruput

    /**
     * @generated from protobuf field: repeated BBMgRPCstreamingNS.DatasetTwoDimensionalNonEquiTimeChunk.DataBlock data_block = 1;
     */
    dataBlock: DatasetTwoDimensionalNonEquiTimeChunk_DataBlock[];
}
/**
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetTwoDimensionalNonEquiTimeChunk.DataBlock
 */
export interface DatasetTwoDimensionalNonEquiTimeChunk_DataBlock {
    /**
     * @generated from protobuf oneof: x_values
     */
    xValues: {
        oneofKind: "emptyTimeRange";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.AbsoluteTimeRange empty_time_range = 2;
         */
        emptyTimeRange: AbsoluteTimeRange;
    } | {
        oneofKind: "timeValues";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.AbsoluteTimeValues time_values = 3;
         */
        timeValues: AbsoluteTimeValues;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf oneof: y_values
     */
    yValues: {
        oneofKind: "yInt32Values";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.NonEquiInt32Values y_int32_values = 10;
         */
        yInt32Values: NonEquiInt32Values;
    } | {
        oneofKind: "yFloatValues";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.NonEquiFloatValues y_float_values = 11;
         */
        yFloatValues: NonEquiFloatValues;
    } | {
        oneofKind: "yDoubleValues";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.NonEquiDoubleValues y_double_values = 12;
         */
        yDoubleValues: NonEquiDoubleValues;
    } | {
        oneofKind: "yCanMessages";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.CANMessages y_can_messages = 13;
         */
        yCanMessages: CANMessages;
    } | {
        oneofKind: "yFlexrayMessages";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.FlexRayMessages y_flexray_messages = 14;
         */
        yFlexrayMessages: FlexRayMessages;
    } | {
        oneofKind: "yEthercatMessages";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.EtherCatMessages y_ethercat_messages = 15;
         */
        yEthercatMessages: EtherCatMessages;
    } | {
        oneofKind: "yIrigMessages";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.IRIGMessages y_irig_messages = 16;
         */
        yIrigMessages: IRIGMessages;
    } | {
        oneofKind: "yPtpMessages";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.PTPMessages y_ptp_messages = 17;
         */
        yPtpMessages: PTPMessages;
    } | {
        oneofKind: "yGpsMessages";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.GPSMessages y_gps_messages = 18;
         */
        yGpsMessages: GPSMessages;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: BBMgRPCstreamingNS.ValueType value_type = 20;
     */
    valueType: ValueType;
}
/**
 * DatasetMessage is the base element for streaming
 * each DatasetMessage contains a chunk of one dataset
 *
 * @generated from protobuf message BBMgRPCstreamingNS.DatasetChunk
 */
export interface DatasetChunk {
    /**
     * @generated from protobuf oneof: data
     */
    data: {
        oneofKind: "ds3D";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetThreeDimensionalChunk ds3d = 2 [json_name = "ds3d"];
         */
        ds3D: DatasetThreeDimensionalChunk;
    } | {
        oneofKind: "ds2DEquiTime";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetTwoDimensionalEquiTimeChunk ds2d_equi_time = 3 [json_name = "ds2dEquiTime"];
         */
        ds2DEquiTime: DatasetTwoDimensionalEquiTimeChunk;
    } | {
        oneofKind: "ds2DNonEquiTime";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetTwoDimensionalNonEquiTimeChunk ds2d_non_equi_time = 5 [json_name = "ds2dNonEquiTime"];
         */
        ds2DNonEquiTime: DatasetTwoDimensionalNonEquiTimeChunk;
    } | {
        oneofKind: "datasetChanges";
        /**
         * @generated from protobuf field: BBMgRPCstreamingNS.DatasetChangesChunk dataset_changes = 4;
         */
        datasetChanges: DatasetChangesChunk;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.DataType
 */
export enum DataType {
    /**
     * @generated from protobuf enum value: Type_Undefined = 0;
     */
    Type_Undefined = 0,
    /**
     * @generated from protobuf enum value: Type_Thruput = 2;
     */
    Type_Thruput = 2,
    /**
     * @generated from protobuf enum value: Type_CompressedThruput = 37;
     */
    Type_CompressedThruput = 37,
    /**
     * @generated from protobuf enum value: Type_SlowThruput = 3;
     */
    Type_SlowThruput = 3,
    /**
     * @generated from protobuf enum value: Type_ThruputCA = 4;
     */
    Type_ThruputCA = 4,
    /**
     * @generated from protobuf enum value: Type_TimeSeries = 5;
     */
    Type_TimeSeries = 5,
    /**
     * Type_1DMessage             = 7;
     *
     * @generated from protobuf enum value: Type_TachoEdges = 6;
     */
    Type_TachoEdges = 6,
    /**
     *  BBMDataSet1DMessage<BBMCANMessage>                   (1D)
     *
     * @generated from protobuf enum value: Type_CANData = 7;
     */
    Type_CANData = 7,
    /**
     *  BBMDataSet1DMessage<BBMFlexRayMessage>           (1D)
     *
     * @generated from protobuf enum value: Type_FlexRayData = 8;
     */
    Type_FlexRayData = 8,
    /**
     *  BBMDataSet1DMessage<BBMEtherCatMessage>         (1D)
     *
     * @generated from protobuf enum value: Type_EtherCatData = 9;
     */
    Type_EtherCatData = 9,
    /**
     *  BBMDataSet1DMessage<BBMGPSMessage>                   (1D)
     *
     * @generated from protobuf enum value: Type_GPSData = 10;
     */
    Type_GPSData = 10,
    /**
     *  BBMDataSet1DMessage<BBMIRIGMessage>                 (1D)
     *
     * @generated from protobuf enum value: Type_IRIGData = 11;
     */
    Type_IRIGData = 11,
    /**
     *  BBMDataSet1DMessage<BBMPTPMessage>                   (1D)
     *
     * @generated from protobuf enum value: Type_PTPData = 12;
     */
    Type_PTPData = 12,
    /**
     * BBMDataSet3D                                          (3D)
     *
     * @generated from protobuf enum value: Type_XLabel = 13;
     */
    Type_XLabel = 13,
    /**
     * BBMDataSet3D, public ISquarable                        (3D)
     *
     * @generated from protobuf enum value: Type_Detector = 14;
     */
    Type_Detector = 14,
    /**
     * BBMDataSet3DSlow                                      (3D)
     *
     * @generated from protobuf enum value: Type_SlowQuantity = 15;
     */
    Type_SlowQuantity = 15,
    /**
     * BBMDataSet3DSlow                                      (3D)
     *
     * @generated from protobuf enum value: Type_SlowQuantityRef = 16;
     */
    Type_SlowQuantityRef = 16,
    /**
     * BBMDataSet_ContourBase                                (3D)
     *
     * @generated from protobuf enum value: Type_Contour = 17;
     */
    Type_Contour = 17,
    /**
     * BBMDataSet_ContourBase                                (3D)
     *
     * @generated from protobuf enum value: Type_ContourSynth = 18;
     */
    Type_ContourSynth = 18,
    /**
     * BBMDataSet3DOctave                                    (3D)
     *
     * @generated from protobuf enum value: Type_OctaveSpectrum = 19;
     */
    Type_OctaveSpectrum = 19,
    /**
     * BBMDataSet3DOctave                                    (3D)
     *
     * @generated from protobuf enum value: Type_OctaveSRS = 20;
     */
    Type_OctaveSRS = 20,
    /**
     * BBMDataSet3DOctave                                    (3D)
     *
     * @generated from protobuf enum value: Type_OctaveTransfer = 21;
     */
    Type_OctaveTransfer = 21,
    /**
     * BBMDataSet3DNonEqui                                   (3D)
     *
     * @generated from protobuf enum value: Type_Unknown = 22;
     */
    Type_Unknown = 22,
    /**
     * BBMDataSet3DNonEqui                                   (3D)
     *
     * @generated from protobuf enum value: Type_Wavelet = 23;
     */
    Type_Wavelet = 23,
    /**
     * BBMDataSet3DNonEqui                                   (3D)
     *
     * @generated from protobuf enum value: Type_EdgeBlock = 24;
     */
    Type_EdgeBlock = 24,
    /**
     * BBMDataSet3DNonEqui                                   (3D)
     *
     * @generated from protobuf enum value: Type_Trajectory = 25;
     */
    Type_Trajectory = 25,
    /**
     * BBMDataSet3DEqui                                      (3D)
     *
     * @generated from protobuf enum value: Type_Psycho = 26;
     */
    Type_Psycho = 26,
    /**
     * BBMDataSet3DEqui                                      (3D)
     *
     * @generated from protobuf enum value: Type_HSQBase = 27;
     */
    Type_HSQBase = 27,
    /**
     * BBMDataSet3DEqui                                      (3D)
     *
     * @generated from protobuf enum value: Type_HVQBase = 28;
     */
    Type_HVQBase = 28,
    /**
     * BBMDataSet3DEqui                                      (3D)
     *
     * @generated from protobuf enum value: Type_TimeBlock = 29;
     */
    Type_TimeBlock = 29,
    /**
     * BBMDataSet3DEqui                                      (3D)
     *
     * @generated from protobuf enum value: Type_VarTimeBlock = 30;
     */
    Type_VarTimeBlock = 30,
    /**
     * BBMDataSet3DEqui                                      (3D)
     *
     * @generated from protobuf enum value: Type_AutoCorrelation = 31;
     */
    Type_AutoCorrelation = 31,
    /**
     * BBMDataSet3DEqui                                      (3D)
     *
     * @generated from protobuf enum value: Type_Window = 32;
     */
    Type_Window = 32,
    /**
     * BBMDataSet3DEqui                                      (3D)
     *
     * @generated from protobuf enum value: Type_Cepstrum = 33;
     */
    Type_Cepstrum = 33,
    /**
     * BBMDataSet3DEquiTimeRef                               (3D)
     *
     * @generated from protobuf enum value: Type_CrossCorrelation = 34;
     */
    Type_CrossCorrelation = 34,
    /**
     * BBMDataSet3DEquiTimeRef                               (3D)
     *
     * @generated from protobuf enum value: Type_ImpulseResponse = 35;
     */
    Type_ImpulseResponse = 35,
    /**
     * BBMDataSet3DEquiSpec                                  (3D)
     *
     * @generated from protobuf enum value: Type_FFTSpectrum = 36;
     */
    Type_FFTSpectrum = 36,
    /**
     * BBMDataSet3DEquiSpec, public ISquarable               (3D)
     *
     * @generated from protobuf enum value: Type_APSSpectrum = 1;
     */
    Type_APSSpectrum = 1,
    /**
     * BBMDataSet3DEquiSpec                                  (3D)
     *
     * @generated from protobuf enum value: Type_CohenClass = 38;
     */
    Type_CohenClass = 38,
    /**
     * BBMDataSet3DEquiSpec                                  (3D)
     *
     * @generated from protobuf enum value: Type_MultipleCoherence = 39;
     */
    Type_MultipleCoherence = 39,
    /**
     * BBMDataSet3DEquiSpec                                  (3D)
     *
     * @generated from protobuf enum value: Type_MultipleSingleOrder = 40;
     */
    Type_MultipleSingleOrder = 40,
    /**
     * BBMDataSet3DEquiSpecRef                               (3D)
     *
     * @generated from protobuf enum value: Type_CPSSpectrum = 41;
     */
    Type_CPSSpectrum = 41,
    /**
     * BBMDataSet3DEquiSpecRef                               (3D)
     *
     * @generated from protobuf enum value: Type_PRSSpectrum = 42;
     */
    Type_PRSSpectrum = 42,
    /**
     * BBMDataSet3DEquiSpecRef                               (3D)
     *
     * @generated from protobuf enum value: Type_FRFSpectrum = 43;
     */
    Type_FRFSpectrum = 43,
    /**
     * BBMDataSet3DEquiSpecRef                               (3D)
     *
     * @generated from protobuf enum value: Type_MultipleCrossOrder = 44;
     */
    Type_MultipleCrossOrder = 44,
    /**
     * BBMDataSet3DEquiSpecRef                               (3D)
     *
     * @generated from protobuf enum value: Type_MultiplePhaseRefOrder = 45;
     */
    Type_MultiplePhaseRefOrder = 45,
    /**
     * BBMDataSet3DEquiSpecRefCoh                            (3D)
     *
     * @generated from protobuf enum value: Type_Coherence = 46;
     */
    Type_Coherence = 46,
    /**
     * BBMDataSet3DEquiSpecRefCoh                            (3D)
     *
     * @generated from protobuf enum value: Type_PartialCoherence = 47;
     */
    Type_PartialCoherence = 47
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.Shape
 */
export enum Shape {
    /**
     * ! No shape specified
     *
     * @generated from protobuf enum value: Shape_None = 0;
     */
    Shape_None = 0,
    /**
     * ! Has one or more lines, representing an spectrum or data block
     *
     * @generated from protobuf enum value: Shape_Normal = 1;
     */
    Shape_Normal = 1,
    /**
     * ! Has one line, representing a cut through a spectrum at a constant x value
     *
     * @generated from protobuf enum value: Shape_Cut = 2;
     */
    Shape_Cut = 2,
    /**
     * ! Has one line, representing an energetical sum of the amplitude for a restricted range of x values
     *
     * @generated from protobuf enum value: Shape_Bandpass = 3;
     */
    Shape_Bandpass = 3,
    /**
     * ! Has one line, representing an energetical sum of the amplitudes for all x values
     *
     * @generated from protobuf enum value: Shape_Overall = 4;
     */
    Shape_Overall = 4,
    /**
     * ! Has one line, representing a result of an arithmetical operation
     *
     * @generated from protobuf enum value: Shape_OpResult = 5;
     */
    Shape_OpResult = 5
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.DataFormat
 */
export enum DataFormat {
    /**
     * @generated from protobuf enum value: DF_None = 0;
     */
    DF_None = 0,
    /**
     * @generated from protobuf enum value: DF_Complex = 1;
     */
    DF_Complex = 1,
    /**
     * @generated from protobuf enum value: DF_Real = 2;
     */
    DF_Real = 2,
    /**
     * @generated from protobuf enum value: DF_Imag = 3;
     */
    DF_Imag = 3,
    /**
     * @generated from protobuf enum value: DF_Mag = 4;
     */
    DF_Mag = 4,
    /**
     * @generated from protobuf enum value: DF_Phase = 5;
     */
    DF_Phase = 5,
    /**
     * @generated from protobuf enum value: DF_MagDir = 6;
     */
    DF_MagDir = 6,
    /**
     * must be last
     *
     * @generated from protobuf enum value: DF_NumOf = 15;
     */
    DF_NumOf = 15
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.FrequenzWeight
 */
export enum FrequenzWeight {
    /**
     * @generated from protobuf enum value: FreqWeight_Undefined = 0;
     */
    FreqWeight_Undefined = 0,
    /**
     * @generated from protobuf enum value: FreqWeight_None = 1;
     */
    FreqWeight_None = 1,
    /**
     * @generated from protobuf enum value: FreqWeight_A = 2;
     */
    FreqWeight_A = 2,
    /**
     * @generated from protobuf enum value: FreqWeight_B = 3;
     */
    FreqWeight_B = 3,
    /**
     * @generated from protobuf enum value: FreqWeight_C = 4;
     */
    FreqWeight_C = 4,
    /**
     * @generated from protobuf enum value: FreqWeight_D = 5;
     */
    FreqWeight_D = 5
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.AverageType
 */
export enum AverageType {
    /**
     * @generated from protobuf enum value: AvgType_Undefined = 0;
     */
    AvgType_Undefined = 0,
    /**
     * @generated from protobuf enum value: AvgType_None = 1;
     */
    AvgType_None = 1,
    /**
     * @generated from protobuf enum value: AvgType_Lin_Time = 2;
     */
    AvgType_Lin_Time = 2,
    /**
     * @generated from protobuf enum value: AvgType_Lin_Num = 3;
     */
    AvgType_Lin_Num = 3,
    /**
     * @generated from protobuf enum value: AvgType_Expo_Time = 4;
     */
    AvgType_Expo_Time = 4,
    /**
     * @generated from protobuf enum value: AvgType_Expo_Num = 5;
     */
    AvgType_Expo_Num = 5,
    /**
     * @generated from protobuf enum value: AvgType_MaxHold_Time = 6;
     */
    AvgType_MaxHold_Time = 6,
    /**
     * @generated from protobuf enum value: AvgType_MaxHold_Num = 7;
     */
    AvgType_MaxHold_Num = 7,
    /**
     * @generated from protobuf enum value: AvgType_MinHold_Time = 8;
     */
    AvgType_MinHold_Time = 8,
    /**
     * @generated from protobuf enum value: AvgType_MinHold_Num = 9;
     */
    AvgType_MinHold_Num = 9,
    /**
     * @generated from protobuf enum value: AvgType_Rotation = 10;
     */
    AvgType_Rotation = 10,
    /**
     * @generated from protobuf enum value: AvgType_StdDeviation_Num = 11;
     */
    AvgType_StdDeviation_Num = 11,
    /**
     * @generated from protobuf enum value: AvgType_StdDeviation_Time = 12;
     */
    AvgType_StdDeviation_Time = 12
}
/**
 * @generated from protobuf enum BBMgRPCstreamingNS.ValueType
 */
export enum ValueType {
    /**
     * Unknown must be the default value, so older messages, which don't have it set, still work
     *
     * @generated from protobuf enum value: ValueType_Unknown = 0;
     */
    ValueType_Unknown = 0,
    /**
     * @generated from protobuf enum value: ValueType_Int8 = 1;
     */
    ValueType_Int8 = 1,
    /**
     * @generated from protobuf enum value: ValueType_Int16 = 2;
     */
    ValueType_Int16 = 2,
    /**
     * @generated from protobuf enum value: ValueType_Int24 = 3;
     */
    ValueType_Int24 = 3,
    /**
     * @generated from protobuf enum value: ValueType_Int32 = 4;
     */
    ValueType_Int32 = 4,
    /**
     * @generated from protobuf enum value: ValueType_Int64 = 5;
     */
    ValueType_Int64 = 5,
    /**
     * @generated from protobuf enum value: ValueType_Float32 = 6;
     */
    ValueType_Float32 = 6,
    /**
     * @generated from protobuf enum value: ValueType_Float64 = 7;
     */
    ValueType_Float64 = 7
}
// @generated message type with reflection information, may provide speed optimized methods
class ChannelLabelDir$Type extends MessageType<ChannelLabelDir> {
    constructor() {
        super("BBMgRPCstreamingNS.ChannelLabelDir", [
            { no: 1, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "direction", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ChannelLabelDir>): ChannelLabelDir {
        const message = { label: "", direction: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChannelLabelDir>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChannelLabelDir): ChannelLabelDir {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string label */ 1:
                    message.label = reader.string();
                    break;
                case /* string direction */ 2:
                    message.direction = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChannelLabelDir, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string label = 1; */
        if (message.label !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.label);
        /* string direction = 2; */
        if (message.direction !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.direction);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.ChannelLabelDir
 */
export const ChannelLabelDir = new ChannelLabelDir$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetKeyData$Type extends MessageType<DatasetKeyData> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetKeyData", [
            { no: 1, name: "label_dir", kind: "message", T: () => ChannelLabelDir },
            { no: 2, name: "type", kind: "enum", T: () => ["BBMgRPCstreamingNS.DataType", DataType] }
        ]);
    }
    create(value?: PartialMessage<DatasetKeyData>): DatasetKeyData {
        const message = { type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetKeyData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetKeyData): DatasetKeyData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.ChannelLabelDir label_dir */ 1:
                    message.labelDir = ChannelLabelDir.internalBinaryRead(reader, reader.uint32(), options, message.labelDir);
                    break;
                case /* BBMgRPCstreamingNS.DataType type */ 2:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetKeyData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.ChannelLabelDir label_dir = 1; */
        if (message.labelDir)
            ChannelLabelDir.internalBinaryWrite(message.labelDir, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DataType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetKeyData
 */
export const DatasetKeyData = new DatasetKeyData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NonEquiInt32Values$Type extends MessageType<NonEquiInt32Values> {
    constructor() {
        super("BBMgRPCstreamingNS.NonEquiInt32Values", [
            { no: 1, name: "data", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 17 /*ScalarType.SINT32*/ }
        ]);
    }
    create(value?: PartialMessage<NonEquiInt32Values>): NonEquiInt32Values {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NonEquiInt32Values>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NonEquiInt32Values): NonEquiInt32Values {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated sint32 data */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.data.push(reader.sint32());
                    else
                        message.data.push(reader.sint32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NonEquiInt32Values, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated sint32 data = 1; */
        if (message.data.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.data.length; i++)
                writer.sint32(message.data[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.NonEquiInt32Values
 */
export const NonEquiInt32Values = new NonEquiInt32Values$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NonEquiFloatValues$Type extends MessageType<NonEquiFloatValues> {
    constructor() {
        super("BBMgRPCstreamingNS.NonEquiFloatValues", [
            { no: 1, name: "data", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<NonEquiFloatValues>): NonEquiFloatValues {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NonEquiFloatValues>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NonEquiFloatValues): NonEquiFloatValues {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated float data */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.data.push(reader.float());
                    else
                        message.data.push(reader.float());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NonEquiFloatValues, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated float data = 1; */
        if (message.data.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.data.length; i++)
                writer.float(message.data[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.NonEquiFloatValues
 */
export const NonEquiFloatValues = new NonEquiFloatValues$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NonEquiDoubleValues$Type extends MessageType<NonEquiDoubleValues> {
    constructor() {
        super("BBMgRPCstreamingNS.NonEquiDoubleValues", [
            { no: 1, name: "data", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<NonEquiDoubleValues>): NonEquiDoubleValues {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NonEquiDoubleValues>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NonEquiDoubleValues): NonEquiDoubleValues {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated double data */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.data.push(reader.double());
                    else
                        message.data.push(reader.double());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NonEquiDoubleValues, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated double data = 1; */
        if (message.data.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.data.length; i++)
                writer.double(message.data[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.NonEquiDoubleValues
 */
export const NonEquiDoubleValues = new NonEquiDoubleValues$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LabelAndDoubleValues$Type extends MessageType<LabelAndDoubleValues> {
    constructor() {
        super("BBMgRPCstreamingNS.LabelAndDoubleValues", [
            { no: 3, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LabelAndDoubleValues_LabelAndDoubleValue },
            { no: 4, name: "is_equivalent", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<LabelAndDoubleValues>): LabelAndDoubleValues {
        const message = { data: [], isEquivalent: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LabelAndDoubleValues>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LabelAndDoubleValues): LabelAndDoubleValues {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BBMgRPCstreamingNS.LabelAndDoubleValues.LabelAndDoubleValue data */ 3:
                    message.data.push(LabelAndDoubleValues_LabelAndDoubleValue.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool is_equivalent */ 4:
                    message.isEquivalent = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LabelAndDoubleValues, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BBMgRPCstreamingNS.LabelAndDoubleValues.LabelAndDoubleValue data = 3; */
        for (let i = 0; i < message.data.length; i++)
            LabelAndDoubleValues_LabelAndDoubleValue.internalBinaryWrite(message.data[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bool is_equivalent = 4; */
        if (message.isEquivalent !== false)
            writer.tag(4, WireType.Varint).bool(message.isEquivalent);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.LabelAndDoubleValues
 */
export const LabelAndDoubleValues = new LabelAndDoubleValues$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LabelAndDoubleValues_LabelAndDoubleValue$Type extends MessageType<LabelAndDoubleValues_LabelAndDoubleValue> {
    constructor() {
        super("BBMgRPCstreamingNS.LabelAndDoubleValues.LabelAndDoubleValue", [
            { no: 1, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "assigned_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<LabelAndDoubleValues_LabelAndDoubleValue>): LabelAndDoubleValues_LabelAndDoubleValue {
        const message = { label: "", assignedValue: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LabelAndDoubleValues_LabelAndDoubleValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LabelAndDoubleValues_LabelAndDoubleValue): LabelAndDoubleValues_LabelAndDoubleValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string label */ 1:
                    message.label = reader.string();
                    break;
                case /* double assigned_value */ 2:
                    message.assignedValue = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LabelAndDoubleValues_LabelAndDoubleValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string label = 1; */
        if (message.label !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.label);
        /* double assigned_value = 2; */
        if (message.assignedValue !== 0)
            writer.tag(2, WireType.Bit64).double(message.assignedValue);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.LabelAndDoubleValues.LabelAndDoubleValue
 */
export const LabelAndDoubleValues_LabelAndDoubleValue = new LabelAndDoubleValues_LabelAndDoubleValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CANMessages$Type extends MessageType<CANMessages> {
    constructor() {
        super("BBMgRPCstreamingNS.CANMessages", [
            { no: 3, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CANMessages_CANMessage }
        ]);
    }
    create(value?: PartialMessage<CANMessages>): CANMessages {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CANMessages>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CANMessages): CANMessages {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BBMgRPCstreamingNS.CANMessages.CANMessage data */ 3:
                    message.data.push(CANMessages_CANMessage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CANMessages, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BBMgRPCstreamingNS.CANMessages.CANMessage data = 3; */
        for (let i = 0; i < message.data.length; i++)
            CANMessages_CANMessage.internalBinaryWrite(message.data[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.CANMessages
 */
export const CANMessages = new CANMessages$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CANMessages_CANMessage$Type extends MessageType<CANMessages_CANMessage> {
    constructor() {
        super("BBMgRPCstreamingNS.CANMessages.CANMessage", [
            { no: 1, name: "identifier", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "data_field", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<CANMessages_CANMessage>): CANMessages_CANMessage {
        const message = { identifier: 0, dataField: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CANMessages_CANMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CANMessages_CANMessage): CANMessages_CANMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 identifier */ 1:
                    message.identifier = reader.uint32();
                    break;
                case /* bytes data_field */ 2:
                    message.dataField = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CANMessages_CANMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 identifier = 1; */
        if (message.identifier !== 0)
            writer.tag(1, WireType.Varint).uint32(message.identifier);
        /* bytes data_field = 2; */
        if (message.dataField.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.dataField);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.CANMessages.CANMessage
 */
export const CANMessages_CANMessage = new CANMessages_CANMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlexRayMessages$Type extends MessageType<FlexRayMessages> {
    constructor() {
        super("BBMgRPCstreamingNS.FlexRayMessages", [
            { no: 5, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FlexRayMessages_FlexRayMessage }
        ]);
    }
    create(value?: PartialMessage<FlexRayMessages>): FlexRayMessages {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FlexRayMessages>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlexRayMessages): FlexRayMessages {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BBMgRPCstreamingNS.FlexRayMessages.FlexRayMessage data */ 5:
                    message.data.push(FlexRayMessages_FlexRayMessage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlexRayMessages, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BBMgRPCstreamingNS.FlexRayMessages.FlexRayMessage data = 5; */
        for (let i = 0; i < message.data.length; i++)
            FlexRayMessages_FlexRayMessage.internalBinaryWrite(message.data[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.FlexRayMessages
 */
export const FlexRayMessages = new FlexRayMessages$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlexRayMessages_FlexRayMessage$Type extends MessageType<FlexRayMessages_FlexRayMessage> {
    constructor() {
        super("BBMgRPCstreamingNS.FlexRayMessages.FlexRayMessage", [
            { no: 1, name: "identifier", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "bustime", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "flx_channel", kind: "enum", T: () => ["BBMgRPCstreamingNS.FlexRayMessages.FLXChannel_AorB", FlexRayMessages_FLXChannel_AorB] },
            { no: 4, name: "data_field", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<FlexRayMessages_FlexRayMessage>): FlexRayMessages_FlexRayMessage {
        const message = { identifier: 0, bustime: 0, flxChannel: 0, dataField: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FlexRayMessages_FlexRayMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlexRayMessages_FlexRayMessage): FlexRayMessages_FlexRayMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 identifier */ 1:
                    message.identifier = reader.uint32();
                    break;
                case /* uint32 bustime */ 2:
                    message.bustime = reader.uint32();
                    break;
                case /* BBMgRPCstreamingNS.FlexRayMessages.FLXChannel_AorB flx_channel */ 3:
                    message.flxChannel = reader.int32();
                    break;
                case /* bytes data_field */ 4:
                    message.dataField = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlexRayMessages_FlexRayMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 identifier = 1; */
        if (message.identifier !== 0)
            writer.tag(1, WireType.Varint).uint32(message.identifier);
        /* uint32 bustime = 2; */
        if (message.bustime !== 0)
            writer.tag(2, WireType.Varint).uint32(message.bustime);
        /* BBMgRPCstreamingNS.FlexRayMessages.FLXChannel_AorB flx_channel = 3; */
        if (message.flxChannel !== 0)
            writer.tag(3, WireType.Varint).int32(message.flxChannel);
        /* bytes data_field = 4; */
        if (message.dataField.length)
            writer.tag(4, WireType.LengthDelimited).bytes(message.dataField);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.FlexRayMessages.FlexRayMessage
 */
export const FlexRayMessages_FlexRayMessage = new FlexRayMessages_FlexRayMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EtherCatMessages$Type extends MessageType<EtherCatMessages> {
    constructor() {
        super("BBMgRPCstreamingNS.EtherCatMessages", [
            { no: 4, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EtherCatMessages_EtherCatMessage }
        ]);
    }
    create(value?: PartialMessage<EtherCatMessages>): EtherCatMessages {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EtherCatMessages>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EtherCatMessages): EtherCatMessages {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BBMgRPCstreamingNS.EtherCatMessages.EtherCatMessage data */ 4:
                    message.data.push(EtherCatMessages_EtherCatMessage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EtherCatMessages, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BBMgRPCstreamingNS.EtherCatMessages.EtherCatMessage data = 4; */
        for (let i = 0; i < message.data.length; i++)
            EtherCatMessages_EtherCatMessage.internalBinaryWrite(message.data[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.EtherCatMessages
 */
export const EtherCatMessages = new EtherCatMessages$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EtherCatMessages_EtherCatMessage$Type extends MessageType<EtherCatMessages_EtherCatMessage> {
    constructor() {
        super("BBMgRPCstreamingNS.EtherCatMessages.EtherCatMessage", [
            { no: 3, name: "data_field", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EtherCatMessages_EtherCatMessage_EtherCatChannelData }
        ]);
    }
    create(value?: PartialMessage<EtherCatMessages_EtherCatMessage>): EtherCatMessages_EtherCatMessage {
        const message = { dataField: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EtherCatMessages_EtherCatMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EtherCatMessages_EtherCatMessage): EtherCatMessages_EtherCatMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BBMgRPCstreamingNS.EtherCatMessages.EtherCatMessage.EtherCatChannelData data_field */ 3:
                    message.dataField.push(EtherCatMessages_EtherCatMessage_EtherCatChannelData.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EtherCatMessages_EtherCatMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BBMgRPCstreamingNS.EtherCatMessages.EtherCatMessage.EtherCatChannelData data_field = 3; */
        for (let i = 0; i < message.dataField.length; i++)
            EtherCatMessages_EtherCatMessage_EtherCatChannelData.internalBinaryWrite(message.dataField[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.EtherCatMessages.EtherCatMessage
 */
export const EtherCatMessages_EtherCatMessage = new EtherCatMessages_EtherCatMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EtherCatMessages_EtherCatMessage_EtherCatChannelData$Type extends MessageType<EtherCatMessages_EtherCatMessage_EtherCatChannelData> {
    constructor() {
        super("BBMgRPCstreamingNS.EtherCatMessages.EtherCatMessage.EtherCatChannelData", [
            { no: 1, name: "data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "is_nan", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<EtherCatMessages_EtherCatMessage_EtherCatChannelData>): EtherCatMessages_EtherCatMessage_EtherCatChannelData {
        const message = { data: new Uint8Array(0), isNan: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EtherCatMessages_EtherCatMessage_EtherCatChannelData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EtherCatMessages_EtherCatMessage_EtherCatChannelData): EtherCatMessages_EtherCatMessage_EtherCatChannelData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes data */ 1:
                    message.data = reader.bytes();
                    break;
                case /* bytes is_nan */ 2:
                    message.isNan = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EtherCatMessages_EtherCatMessage_EtherCatChannelData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes data = 1; */
        if (message.data.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.data);
        /* bytes is_nan = 2; */
        if (message.isNan.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.isNan);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.EtherCatMessages.EtherCatMessage.EtherCatChannelData
 */
export const EtherCatMessages_EtherCatMessage_EtherCatChannelData = new EtherCatMessages_EtherCatMessage_EtherCatChannelData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NmeaMessageChannelData$Type extends MessageType<NmeaMessageChannelData> {
    constructor() {
        super("BBMgRPCstreamingNS.NmeaMessageChannelData", [
            { no: 1, name: "msg_type", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "payload", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 3, name: "check_sum", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<NmeaMessageChannelData>): NmeaMessageChannelData {
        const message = { msgType: new Uint8Array(0), payload: new Uint8Array(0), checkSum: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NmeaMessageChannelData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NmeaMessageChannelData): NmeaMessageChannelData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes msg_type */ 1:
                    message.msgType = reader.bytes();
                    break;
                case /* bytes payload */ 2:
                    message.payload = reader.bytes();
                    break;
                case /* bytes check_sum */ 3:
                    message.checkSum = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NmeaMessageChannelData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes msg_type = 1; */
        if (message.msgType.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.msgType);
        /* bytes payload = 2; */
        if (message.payload.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.payload);
        /* bytes check_sum = 3; */
        if (message.checkSum.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.checkSum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.NmeaMessageChannelData
 */
export const NmeaMessageChannelData = new NmeaMessageChannelData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NmeaMessage$Type extends MessageType<NmeaMessage> {
    constructor() {
        super("BBMgRPCstreamingNS.NmeaMessage", [
            { no: 3, name: "data_field", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NmeaMessageChannelData }
        ]);
    }
    create(value?: PartialMessage<NmeaMessage>): NmeaMessage {
        const message = { dataField: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NmeaMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NmeaMessage): NmeaMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BBMgRPCstreamingNS.NmeaMessageChannelData data_field */ 3:
                    message.dataField.push(NmeaMessageChannelData.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NmeaMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BBMgRPCstreamingNS.NmeaMessageChannelData data_field = 3; */
        for (let i = 0; i < message.dataField.length; i++)
            NmeaMessageChannelData.internalBinaryWrite(message.dataField[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.NmeaMessage
 */
export const NmeaMessage = new NmeaMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IRIGMessages$Type extends MessageType<IRIGMessages> {
    constructor() {
        super("BBMgRPCstreamingNS.IRIGMessages", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NmeaMessage }
        ]);
    }
    create(value?: PartialMessage<IRIGMessages>): IRIGMessages {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IRIGMessages>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IRIGMessages): IRIGMessages {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BBMgRPCstreamingNS.NmeaMessage data */ 1:
                    message.data.push(NmeaMessage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IRIGMessages, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BBMgRPCstreamingNS.NmeaMessage data = 1; */
        for (let i = 0; i < message.data.length; i++)
            NmeaMessage.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.IRIGMessages
 */
export const IRIGMessages = new IRIGMessages$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PTPMessages$Type extends MessageType<PTPMessages> {
    constructor() {
        super("BBMgRPCstreamingNS.PTPMessages", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PTPMessages_PTPMessage }
        ]);
    }
    create(value?: PartialMessage<PTPMessages>): PTPMessages {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PTPMessages>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PTPMessages): PTPMessages {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BBMgRPCstreamingNS.PTPMessages.PTPMessage data */ 1:
                    message.data.push(PTPMessages_PTPMessage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PTPMessages, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BBMgRPCstreamingNS.PTPMessages.PTPMessage data = 1; */
        for (let i = 0; i < message.data.length; i++)
            PTPMessages_PTPMessage.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.PTPMessages
 */
export const PTPMessages = new PTPMessages$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PTPMessages_PTPMessage$Type extends MessageType<PTPMessages_PTPMessage> {
    constructor() {
        super("BBMgRPCstreamingNS.PTPMessages.PTPMessage", [
            { no: 1, name: "mode", kind: "enum", T: () => ["BBMgRPCstreamingNS.PTPMessages.PTPMessage.Mode_enum", PTPMessages_PTPMessage_Mode_enum] },
            { no: 2, name: "syncState", kind: "enum", T: () => ["BBMgRPCstreamingNS.PTPMessages.PTPMessage.SyncState_enum", PTPMessages_PTPMessage_SyncState_enum] },
            { no: 3, name: "accuracy", kind: "message", T: () => RelativeTime }
        ]);
    }
    create(value?: PartialMessage<PTPMessages_PTPMessage>): PTPMessages_PTPMessage {
        const message = { mode: 0, syncState: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PTPMessages_PTPMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PTPMessages_PTPMessage): PTPMessages_PTPMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.PTPMessages.PTPMessage.Mode_enum mode */ 1:
                    message.mode = reader.int32();
                    break;
                case /* BBMgRPCstreamingNS.PTPMessages.PTPMessage.SyncState_enum syncState */ 2:
                    message.syncState = reader.int32();
                    break;
                case /* BBMgRPCstreamingNS.RelativeTime accuracy */ 3:
                    message.accuracy = RelativeTime.internalBinaryRead(reader, reader.uint32(), options, message.accuracy);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PTPMessages_PTPMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.PTPMessages.PTPMessage.Mode_enum mode = 1; */
        if (message.mode !== 0)
            writer.tag(1, WireType.Varint).int32(message.mode);
        /* BBMgRPCstreamingNS.PTPMessages.PTPMessage.SyncState_enum syncState = 2; */
        if (message.syncState !== 0)
            writer.tag(2, WireType.Varint).int32(message.syncState);
        /* BBMgRPCstreamingNS.RelativeTime accuracy = 3; */
        if (message.accuracy)
            RelativeTime.internalBinaryWrite(message.accuracy, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.PTPMessages.PTPMessage
 */
export const PTPMessages_PTPMessage = new PTPMessages_PTPMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GPSMessages$Type extends MessageType<GPSMessages> {
    constructor() {
        super("BBMgRPCstreamingNS.GPSMessages", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GPSMessages_GPSMessage }
        ]);
    }
    create(value?: PartialMessage<GPSMessages>): GPSMessages {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GPSMessages>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GPSMessages): GPSMessages {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BBMgRPCstreamingNS.GPSMessages.GPSMessage data */ 1:
                    message.data.push(GPSMessages_GPSMessage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GPSMessages, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BBMgRPCstreamingNS.GPSMessages.GPSMessage data = 1; */
        for (let i = 0; i < message.data.length; i++)
            GPSMessages_GPSMessage.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.GPSMessages
 */
export const GPSMessages = new GPSMessages$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GPSMessages_GPSMessage$Type extends MessageType<GPSMessages_GPSMessage> {
    constructor() {
        super("BBMgRPCstreamingNS.GPSMessages.GPSMessage", [
            { no: 1, name: "msg_data", kind: "message", T: () => NmeaMessage },
            { no: 2, name: "num_extra_leap_seconds", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 3, name: "has_gps_leap_seconds", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "has_extra_leap_seconds", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GPSMessages_GPSMessage>): GPSMessages_GPSMessage {
        const message = { numExtraLeapSeconds: 0, hasGpsLeapSeconds: false, hasExtraLeapSeconds: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GPSMessages_GPSMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GPSMessages_GPSMessage): GPSMessages_GPSMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.NmeaMessage msg_data */ 1:
                    message.msgData = NmeaMessage.internalBinaryRead(reader, reader.uint32(), options, message.msgData);
                    break;
                case /* sint32 num_extra_leap_seconds */ 2:
                    message.numExtraLeapSeconds = reader.sint32();
                    break;
                case /* bool has_gps_leap_seconds */ 3:
                    message.hasGpsLeapSeconds = reader.bool();
                    break;
                case /* bool has_extra_leap_seconds */ 4:
                    message.hasExtraLeapSeconds = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GPSMessages_GPSMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.NmeaMessage msg_data = 1; */
        if (message.msgData)
            NmeaMessage.internalBinaryWrite(message.msgData, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* sint32 num_extra_leap_seconds = 2; */
        if (message.numExtraLeapSeconds !== 0)
            writer.tag(2, WireType.Varint).sint32(message.numExtraLeapSeconds);
        /* bool has_gps_leap_seconds = 3; */
        if (message.hasGpsLeapSeconds !== false)
            writer.tag(3, WireType.Varint).bool(message.hasGpsLeapSeconds);
        /* bool has_extra_leap_seconds = 4; */
        if (message.hasExtraLeapSeconds !== false)
            writer.tag(4, WireType.Varint).bool(message.hasExtraLeapSeconds);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.GPSMessages.GPSMessage
 */
export const GPSMessages_GPSMessage = new GPSMessages_GPSMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EquiDoubleValues$Type extends MessageType<EquiDoubleValues> {
    constructor() {
        super("BBMgRPCstreamingNS.EquiDoubleValues", [
            { no: 1, name: "delta", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "begin", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<EquiDoubleValues>): EquiDoubleValues {
        const message = { delta: 0, begin: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EquiDoubleValues>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EquiDoubleValues): EquiDoubleValues {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double delta */ 1:
                    message.delta = reader.double();
                    break;
                case /* double begin */ 2:
                    message.begin = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EquiDoubleValues, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double delta = 1; */
        if (message.delta !== 0)
            writer.tag(1, WireType.Bit64).double(message.delta);
        /* double begin = 2; */
        if (message.begin !== 0)
            writer.tag(2, WireType.Bit64).double(message.begin);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.EquiDoubleValues
 */
export const EquiDoubleValues = new EquiDoubleValues$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NEquiDoubleValues$Type extends MessageType<NEquiDoubleValues> {
    constructor() {
        super("BBMgRPCstreamingNS.NEquiDoubleValues", [
            { no: 1, name: "delta", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "begin", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "num_lines", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<NEquiDoubleValues>): NEquiDoubleValues {
        const message = { delta: 0, begin: 0, numLines: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NEquiDoubleValues>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NEquiDoubleValues): NEquiDoubleValues {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double delta */ 1:
                    message.delta = reader.double();
                    break;
                case /* double begin */ 2:
                    message.begin = reader.double();
                    break;
                case /* uint32 num_lines */ 3:
                    message.numLines = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NEquiDoubleValues, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double delta = 1; */
        if (message.delta !== 0)
            writer.tag(1, WireType.Bit64).double(message.delta);
        /* double begin = 2; */
        if (message.begin !== 0)
            writer.tag(2, WireType.Bit64).double(message.begin);
        /* uint32 num_lines = 3; */
        if (message.numLines !== 0)
            writer.tag(3, WireType.Varint).uint32(message.numLines);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.NEquiDoubleValues
 */
export const NEquiDoubleValues = new NEquiDoubleValues$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AbsoluteTime$Type extends MessageType<AbsoluteTime> {
    constructor() {
        super("BBMgRPCstreamingNS.AbsoluteTime", [
            { no: 1, name: "first", kind: "scalar", T: 3 /*ScalarType.INT64*/ },
            { no: 2, name: "second", kind: "scalar", T: 3 /*ScalarType.INT64*/ }
        ]);
    }
    create(value?: PartialMessage<AbsoluteTime>): AbsoluteTime {
        const message = { first: "0", second: "0" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AbsoluteTime>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AbsoluteTime): AbsoluteTime {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 first = 1 [jstype = JS_STRING];*/ 1:
                    message.first = reader.int64().toString();
                    break;
                case /* int64 second = 2 [jstype = JS_STRING];*/ 2:
                    message.second = reader.int64().toString();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AbsoluteTime, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 first = 1 [jstype = JS_STRING]; */
        if (message.first !== "0")
            writer.tag(1, WireType.Varint).int64(message.first);
        /* int64 second = 2 [jstype = JS_STRING]; */
        if (message.second !== "0")
            writer.tag(2, WireType.Varint).int64(message.second);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.AbsoluteTime
 */
export const AbsoluteTime = new AbsoluteTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DateTime$Type extends MessageType<DateTime> {
    constructor() {
        super("BBMgRPCstreamingNS.DateTime", [
            { no: 1, name: "utc_time", kind: "message", T: () => AbsoluteTime },
            { no: 2, name: "time_zone", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "day_light", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<DateTime>): DateTime {
        const message = { timeZone: 0, dayLight: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DateTime>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DateTime): DateTime {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.AbsoluteTime utc_time */ 1:
                    message.utcTime = AbsoluteTime.internalBinaryRead(reader, reader.uint32(), options, message.utcTime);
                    break;
                case /* int32 time_zone */ 2:
                    message.timeZone = reader.int32();
                    break;
                case /* int32 day_light */ 3:
                    message.dayLight = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DateTime, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.AbsoluteTime utc_time = 1; */
        if (message.utcTime)
            AbsoluteTime.internalBinaryWrite(message.utcTime, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 time_zone = 2; */
        if (message.timeZone !== 0)
            writer.tag(2, WireType.Varint).int32(message.timeZone);
        /* int32 day_light = 3; */
        if (message.dayLight !== 0)
            writer.tag(3, WireType.Varint).int32(message.dayLight);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DateTime
 */
export const DateTime = new DateTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RelativeTime$Type extends MessageType<RelativeTime> {
    constructor() {
        super("BBMgRPCstreamingNS.RelativeTime", [
            { no: 1, name: "first", kind: "scalar", T: 3 /*ScalarType.INT64*/ },
            { no: 2, name: "second", kind: "scalar", T: 3 /*ScalarType.INT64*/ }
        ]);
    }
    create(value?: PartialMessage<RelativeTime>): RelativeTime {
        const message = { first: "0", second: "0" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RelativeTime>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RelativeTime): RelativeTime {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 first = 1 [jstype = JS_STRING];*/ 1:
                    message.first = reader.int64().toString();
                    break;
                case /* int64 second = 2 [jstype = JS_STRING];*/ 2:
                    message.second = reader.int64().toString();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RelativeTime, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 first = 1 [jstype = JS_STRING]; */
        if (message.first !== "0")
            writer.tag(1, WireType.Varint).int64(message.first);
        /* int64 second = 2 [jstype = JS_STRING]; */
        if (message.second !== "0")
            writer.tag(2, WireType.Varint).int64(message.second);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.RelativeTime
 */
export const RelativeTime = new RelativeTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AbsoluteTimeRange$Type extends MessageType<AbsoluteTimeRange> {
    constructor() {
        super("BBMgRPCstreamingNS.AbsoluteTimeRange", [
            { no: 1, name: "start", kind: "message", T: () => AbsoluteTime },
            { no: 2, name: "stop", kind: "message", T: () => AbsoluteTime }
        ]);
    }
    create(value?: PartialMessage<AbsoluteTimeRange>): AbsoluteTimeRange {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AbsoluteTimeRange>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AbsoluteTimeRange): AbsoluteTimeRange {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.AbsoluteTime start */ 1:
                    message.start = AbsoluteTime.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* BBMgRPCstreamingNS.AbsoluteTime stop */ 2:
                    message.stop = AbsoluteTime.internalBinaryRead(reader, reader.uint32(), options, message.stop);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AbsoluteTimeRange, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.AbsoluteTime start = 1; */
        if (message.start)
            AbsoluteTime.internalBinaryWrite(message.start, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.AbsoluteTime stop = 2; */
        if (message.stop)
            AbsoluteTime.internalBinaryWrite(message.stop, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.AbsoluteTimeRange
 */
export const AbsoluteTimeRange = new AbsoluteTimeRange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AbsoluteTimeValues$Type extends MessageType<AbsoluteTimeValues> {
    constructor() {
        super("BBMgRPCstreamingNS.AbsoluteTimeValues", [
            { no: 1, name: "time_vals", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AbsoluteTime }
        ]);
    }
    create(value?: PartialMessage<AbsoluteTimeValues>): AbsoluteTimeValues {
        const message = { timeVals: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AbsoluteTimeValues>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AbsoluteTimeValues): AbsoluteTimeValues {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BBMgRPCstreamingNS.AbsoluteTime time_vals */ 1:
                    message.timeVals.push(AbsoluteTime.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AbsoluteTimeValues, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BBMgRPCstreamingNS.AbsoluteTime time_vals = 1; */
        for (let i = 0; i < message.timeVals.length; i++)
            AbsoluteTime.internalBinaryWrite(message.timeVals[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.AbsoluteTimeValues
 */
export const AbsoluteTimeValues = new AbsoluteTimeValues$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CrankAngleValues$Type extends MessageType<CrankAngleValues> {
    constructor() {
        super("BBMgRPCstreamingNS.CrankAngleValues", [
            { no: 1, name: "time_vals", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AbsoluteTime },
            { no: 2, name: "angle_vals", kind: "message", T: () => EquiDoubleValues }
        ]);
    }
    create(value?: PartialMessage<CrankAngleValues>): CrankAngleValues {
        const message = { timeVals: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CrankAngleValues>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CrankAngleValues): CrankAngleValues {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BBMgRPCstreamingNS.AbsoluteTime time_vals */ 1:
                    message.timeVals.push(AbsoluteTime.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* BBMgRPCstreamingNS.EquiDoubleValues angle_vals */ 2:
                    message.angleVals = EquiDoubleValues.internalBinaryRead(reader, reader.uint32(), options, message.angleVals);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CrankAngleValues, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BBMgRPCstreamingNS.AbsoluteTime time_vals = 1; */
        for (let i = 0; i < message.timeVals.length; i++)
            AbsoluteTime.internalBinaryWrite(message.timeVals[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.EquiDoubleValues angle_vals = 2; */
        if (message.angleVals)
            EquiDoubleValues.internalBinaryWrite(message.angleVals, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.CrankAngleValues
 */
export const CrankAngleValues = new CrankAngleValues$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DoubleRange$Type extends MessageType<DoubleRange> {
    constructor() {
        super("BBMgRPCstreamingNS.DoubleRange", [
            { no: 1, name: "start", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "stop", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<DoubleRange>): DoubleRange {
        const message = { start: 0, stop: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DoubleRange>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DoubleRange): DoubleRange {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double start */ 1:
                    message.start = reader.double();
                    break;
                case /* double stop */ 2:
                    message.stop = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DoubleRange, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double start = 1; */
        if (message.start !== 0)
            writer.tag(1, WireType.Bit64).double(message.start);
        /* double stop = 2; */
        if (message.stop !== 0)
            writer.tag(2, WireType.Bit64).double(message.stop);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DoubleRange
 */
export const DoubleRange = new DoubleRange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EquiTimeScale$Type extends MessageType<EquiTimeScale> {
    constructor() {
        super("BBMgRPCstreamingNS.EquiTimeScale", [
            { no: 1, name: "first_sample_time", kind: "message", T: () => AbsoluteTime },
            { no: 2, name: "sampling_rate_nominator", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 3, name: "sampling_rate_denominator", kind: "scalar", T: 17 /*ScalarType.SINT32*/ }
        ]);
    }
    create(value?: PartialMessage<EquiTimeScale>): EquiTimeScale {
        const message = { samplingRateNominator: 0, samplingRateDenominator: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EquiTimeScale>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EquiTimeScale): EquiTimeScale {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.AbsoluteTime first_sample_time */ 1:
                    message.firstSampleTime = AbsoluteTime.internalBinaryRead(reader, reader.uint32(), options, message.firstSampleTime);
                    break;
                case /* sint32 sampling_rate_nominator */ 2:
                    message.samplingRateNominator = reader.sint32();
                    break;
                case /* sint32 sampling_rate_denominator */ 3:
                    message.samplingRateDenominator = reader.sint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EquiTimeScale, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.AbsoluteTime first_sample_time = 1; */
        if (message.firstSampleTime)
            AbsoluteTime.internalBinaryWrite(message.firstSampleTime, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* sint32 sampling_rate_nominator = 2; */
        if (message.samplingRateNominator !== 0)
            writer.tag(2, WireType.Varint).sint32(message.samplingRateNominator);
        /* sint32 sampling_rate_denominator = 3; */
        if (message.samplingRateDenominator !== 0)
            writer.tag(3, WireType.Varint).sint32(message.samplingRateDenominator);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.EquiTimeScale
 */
export const EquiTimeScale = new EquiTimeScale$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompressedEquiTimeScale$Type extends MessageType<CompressedEquiTimeScale> {
    constructor() {
        super("BBMgRPCstreamingNS.CompressedEquiTimeScale", [
            { no: 1, name: "first_sample_time", kind: "message", T: () => AbsoluteTime },
            { no: 2, name: "sampling_rate_nominator", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 3, name: "sampling_rate_denominator", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 4, name: "orig_sampling_rate_nominator", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 5, name: "orig_sampling_rate_denominator", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 6, name: "orig_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.DataType", DataType] }
        ]);
    }
    create(value?: PartialMessage<CompressedEquiTimeScale>): CompressedEquiTimeScale {
        const message = { samplingRateNominator: 0, samplingRateDenominator: 0, origSamplingRateNominator: 0, origSamplingRateDenominator: 0, origType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompressedEquiTimeScale>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompressedEquiTimeScale): CompressedEquiTimeScale {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.AbsoluteTime first_sample_time */ 1:
                    message.firstSampleTime = AbsoluteTime.internalBinaryRead(reader, reader.uint32(), options, message.firstSampleTime);
                    break;
                case /* sint32 sampling_rate_nominator */ 2:
                    message.samplingRateNominator = reader.sint32();
                    break;
                case /* sint32 sampling_rate_denominator */ 3:
                    message.samplingRateDenominator = reader.sint32();
                    break;
                case /* sint32 orig_sampling_rate_nominator */ 4:
                    message.origSamplingRateNominator = reader.sint32();
                    break;
                case /* sint32 orig_sampling_rate_denominator */ 5:
                    message.origSamplingRateDenominator = reader.sint32();
                    break;
                case /* BBMgRPCstreamingNS.DataType orig_type */ 6:
                    message.origType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompressedEquiTimeScale, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.AbsoluteTime first_sample_time = 1; */
        if (message.firstSampleTime)
            AbsoluteTime.internalBinaryWrite(message.firstSampleTime, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* sint32 sampling_rate_nominator = 2; */
        if (message.samplingRateNominator !== 0)
            writer.tag(2, WireType.Varint).sint32(message.samplingRateNominator);
        /* sint32 sampling_rate_denominator = 3; */
        if (message.samplingRateDenominator !== 0)
            writer.tag(3, WireType.Varint).sint32(message.samplingRateDenominator);
        /* sint32 orig_sampling_rate_nominator = 4; */
        if (message.origSamplingRateNominator !== 0)
            writer.tag(4, WireType.Varint).sint32(message.origSamplingRateNominator);
        /* sint32 orig_sampling_rate_denominator = 5; */
        if (message.origSamplingRateDenominator !== 0)
            writer.tag(5, WireType.Varint).sint32(message.origSamplingRateDenominator);
        /* BBMgRPCstreamingNS.DataType orig_type = 6; */
        if (message.origType !== 0)
            writer.tag(6, WireType.Varint).int32(message.origType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.CompressedEquiTimeScale
 */
export const CompressedEquiTimeScale = new CompressedEquiTimeScale$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Quantity$Type extends MessageType<Quantity> {
    constructor() {
        super("BBMgRPCstreamingNS.Quantity", [
            { no: 26, name: "subscribe_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "meter_exponent", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "meter_exponent_denominator", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "kilogram_exponent", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "kilogram_exponent_denominator", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "second_exponent", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "second_exponent_denominator", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "ampere_exponent", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "ampere_exponent_denominator", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "kelvin_exponent", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "kelvin_exponent_denominator", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "mol_exponent", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "mol_exponent_denominator", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "candela_exponent", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "candela_exponent_denominator", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "original_angle_exponent", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "original_angle_exponent_denominator", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "original_rotational_speed_exponent", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 19, name: "original_rotational_speed_exponent_denominator", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 20, name: "original_strain_exponent", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 21, name: "original_strain_exponent_denominator", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 22, name: "db_reference_factor", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 27, name: "db_with_orientation", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 23, name: "is_power_type", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 24, name: "iso_factor", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 25, name: "iso_offset", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 28, name: "unit_iso_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "unit_display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "unit_base_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 31, name: "unit_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 32, name: "unit_si_prefix", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 33, name: "axis_scaling", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 34, name: "y_amplitude", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 35, name: "decimal_places", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Quantity>): Quantity {
        const message = { subscribeId: 0, name: "", meterExponent: 0, meterExponentDenominator: 0, kilogramExponent: 0, kilogramExponentDenominator: 0, secondExponent: 0, secondExponentDenominator: 0, ampereExponent: 0, ampereExponentDenominator: 0, kelvinExponent: 0, kelvinExponentDenominator: 0, molExponent: 0, molExponentDenominator: 0, candelaExponent: 0, candelaExponentDenominator: 0, originalAngleExponent: 0, originalAngleExponentDenominator: 0, originalRotationalSpeedExponent: 0, originalRotationalSpeedExponentDenominator: 0, originalStrainExponent: 0, originalStrainExponentDenominator: 0, dbReferenceFactor: 0, dbWithOrientation: false, isPowerType: false, isoFactor: 0, isoOffset: 0, unitIsoName: "", unitDisplayName: "", unitBaseName: "", unitName: "", unitSiPrefix: "", axisScaling: "", yAmplitude: "", decimalPlaces: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Quantity>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Quantity): Quantity {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 subscribe_id */ 26:
                    message.subscribeId = reader.uint32();
                    break;
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* int32 meter_exponent */ 2:
                    message.meterExponent = reader.int32();
                    break;
                case /* int32 meter_exponent_denominator */ 3:
                    message.meterExponentDenominator = reader.int32();
                    break;
                case /* int32 kilogram_exponent */ 4:
                    message.kilogramExponent = reader.int32();
                    break;
                case /* int32 kilogram_exponent_denominator */ 5:
                    message.kilogramExponentDenominator = reader.int32();
                    break;
                case /* int32 second_exponent */ 6:
                    message.secondExponent = reader.int32();
                    break;
                case /* int32 second_exponent_denominator */ 7:
                    message.secondExponentDenominator = reader.int32();
                    break;
                case /* int32 ampere_exponent */ 8:
                    message.ampereExponent = reader.int32();
                    break;
                case /* int32 ampere_exponent_denominator */ 9:
                    message.ampereExponentDenominator = reader.int32();
                    break;
                case /* int32 kelvin_exponent */ 10:
                    message.kelvinExponent = reader.int32();
                    break;
                case /* int32 kelvin_exponent_denominator */ 11:
                    message.kelvinExponentDenominator = reader.int32();
                    break;
                case /* int32 mol_exponent */ 12:
                    message.molExponent = reader.int32();
                    break;
                case /* int32 mol_exponent_denominator */ 13:
                    message.molExponentDenominator = reader.int32();
                    break;
                case /* int32 candela_exponent */ 14:
                    message.candelaExponent = reader.int32();
                    break;
                case /* int32 candela_exponent_denominator */ 15:
                    message.candelaExponentDenominator = reader.int32();
                    break;
                case /* int32 original_angle_exponent */ 16:
                    message.originalAngleExponent = reader.int32();
                    break;
                case /* int32 original_angle_exponent_denominator */ 17:
                    message.originalAngleExponentDenominator = reader.int32();
                    break;
                case /* int32 original_rotational_speed_exponent */ 18:
                    message.originalRotationalSpeedExponent = reader.int32();
                    break;
                case /* int32 original_rotational_speed_exponent_denominator */ 19:
                    message.originalRotationalSpeedExponentDenominator = reader.int32();
                    break;
                case /* int32 original_strain_exponent */ 20:
                    message.originalStrainExponent = reader.int32();
                    break;
                case /* int32 original_strain_exponent_denominator */ 21:
                    message.originalStrainExponentDenominator = reader.int32();
                    break;
                case /* double db_reference_factor */ 22:
                    message.dbReferenceFactor = reader.double();
                    break;
                case /* bool db_with_orientation */ 27:
                    message.dbWithOrientation = reader.bool();
                    break;
                case /* bool is_power_type */ 23:
                    message.isPowerType = reader.bool();
                    break;
                case /* double iso_factor */ 24:
                    message.isoFactor = reader.double();
                    break;
                case /* double iso_offset */ 25:
                    message.isoOffset = reader.double();
                    break;
                case /* string unit_iso_name */ 28:
                    message.unitIsoName = reader.string();
                    break;
                case /* string unit_display_name */ 29:
                    message.unitDisplayName = reader.string();
                    break;
                case /* string unit_base_name */ 30:
                    message.unitBaseName = reader.string();
                    break;
                case /* string unit_name */ 31:
                    message.unitName = reader.string();
                    break;
                case /* string unit_si_prefix */ 32:
                    message.unitSiPrefix = reader.string();
                    break;
                case /* string axis_scaling */ 33:
                    message.axisScaling = reader.string();
                    break;
                case /* string y_amplitude */ 34:
                    message.yAmplitude = reader.string();
                    break;
                case /* string decimal_places */ 35:
                    message.decimalPlaces = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Quantity, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 subscribe_id = 26; */
        if (message.subscribeId !== 0)
            writer.tag(26, WireType.Varint).uint32(message.subscribeId);
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* int32 meter_exponent = 2; */
        if (message.meterExponent !== 0)
            writer.tag(2, WireType.Varint).int32(message.meterExponent);
        /* int32 meter_exponent_denominator = 3; */
        if (message.meterExponentDenominator !== 0)
            writer.tag(3, WireType.Varint).int32(message.meterExponentDenominator);
        /* int32 kilogram_exponent = 4; */
        if (message.kilogramExponent !== 0)
            writer.tag(4, WireType.Varint).int32(message.kilogramExponent);
        /* int32 kilogram_exponent_denominator = 5; */
        if (message.kilogramExponentDenominator !== 0)
            writer.tag(5, WireType.Varint).int32(message.kilogramExponentDenominator);
        /* int32 second_exponent = 6; */
        if (message.secondExponent !== 0)
            writer.tag(6, WireType.Varint).int32(message.secondExponent);
        /* int32 second_exponent_denominator = 7; */
        if (message.secondExponentDenominator !== 0)
            writer.tag(7, WireType.Varint).int32(message.secondExponentDenominator);
        /* int32 ampere_exponent = 8; */
        if (message.ampereExponent !== 0)
            writer.tag(8, WireType.Varint).int32(message.ampereExponent);
        /* int32 ampere_exponent_denominator = 9; */
        if (message.ampereExponentDenominator !== 0)
            writer.tag(9, WireType.Varint).int32(message.ampereExponentDenominator);
        /* int32 kelvin_exponent = 10; */
        if (message.kelvinExponent !== 0)
            writer.tag(10, WireType.Varint).int32(message.kelvinExponent);
        /* int32 kelvin_exponent_denominator = 11; */
        if (message.kelvinExponentDenominator !== 0)
            writer.tag(11, WireType.Varint).int32(message.kelvinExponentDenominator);
        /* int32 mol_exponent = 12; */
        if (message.molExponent !== 0)
            writer.tag(12, WireType.Varint).int32(message.molExponent);
        /* int32 mol_exponent_denominator = 13; */
        if (message.molExponentDenominator !== 0)
            writer.tag(13, WireType.Varint).int32(message.molExponentDenominator);
        /* int32 candela_exponent = 14; */
        if (message.candelaExponent !== 0)
            writer.tag(14, WireType.Varint).int32(message.candelaExponent);
        /* int32 candela_exponent_denominator = 15; */
        if (message.candelaExponentDenominator !== 0)
            writer.tag(15, WireType.Varint).int32(message.candelaExponentDenominator);
        /* int32 original_angle_exponent = 16; */
        if (message.originalAngleExponent !== 0)
            writer.tag(16, WireType.Varint).int32(message.originalAngleExponent);
        /* int32 original_angle_exponent_denominator = 17; */
        if (message.originalAngleExponentDenominator !== 0)
            writer.tag(17, WireType.Varint).int32(message.originalAngleExponentDenominator);
        /* int32 original_rotational_speed_exponent = 18; */
        if (message.originalRotationalSpeedExponent !== 0)
            writer.tag(18, WireType.Varint).int32(message.originalRotationalSpeedExponent);
        /* int32 original_rotational_speed_exponent_denominator = 19; */
        if (message.originalRotationalSpeedExponentDenominator !== 0)
            writer.tag(19, WireType.Varint).int32(message.originalRotationalSpeedExponentDenominator);
        /* int32 original_strain_exponent = 20; */
        if (message.originalStrainExponent !== 0)
            writer.tag(20, WireType.Varint).int32(message.originalStrainExponent);
        /* int32 original_strain_exponent_denominator = 21; */
        if (message.originalStrainExponentDenominator !== 0)
            writer.tag(21, WireType.Varint).int32(message.originalStrainExponentDenominator);
        /* double db_reference_factor = 22; */
        if (message.dbReferenceFactor !== 0)
            writer.tag(22, WireType.Bit64).double(message.dbReferenceFactor);
        /* bool db_with_orientation = 27; */
        if (message.dbWithOrientation !== false)
            writer.tag(27, WireType.Varint).bool(message.dbWithOrientation);
        /* bool is_power_type = 23; */
        if (message.isPowerType !== false)
            writer.tag(23, WireType.Varint).bool(message.isPowerType);
        /* double iso_factor = 24; */
        if (message.isoFactor !== 0)
            writer.tag(24, WireType.Bit64).double(message.isoFactor);
        /* double iso_offset = 25; */
        if (message.isoOffset !== 0)
            writer.tag(25, WireType.Bit64).double(message.isoOffset);
        /* string unit_iso_name = 28; */
        if (message.unitIsoName !== "")
            writer.tag(28, WireType.LengthDelimited).string(message.unitIsoName);
        /* string unit_display_name = 29; */
        if (message.unitDisplayName !== "")
            writer.tag(29, WireType.LengthDelimited).string(message.unitDisplayName);
        /* string unit_base_name = 30; */
        if (message.unitBaseName !== "")
            writer.tag(30, WireType.LengthDelimited).string(message.unitBaseName);
        /* string unit_name = 31; */
        if (message.unitName !== "")
            writer.tag(31, WireType.LengthDelimited).string(message.unitName);
        /* string unit_si_prefix = 32; */
        if (message.unitSiPrefix !== "")
            writer.tag(32, WireType.LengthDelimited).string(message.unitSiPrefix);
        /* string axis_scaling = 33; */
        if (message.axisScaling !== "")
            writer.tag(33, WireType.LengthDelimited).string(message.axisScaling);
        /* string y_amplitude = 34; */
        if (message.yAmplitude !== "")
            writer.tag(34, WireType.LengthDelimited).string(message.yAmplitude);
        /* string decimal_places = 35; */
        if (message.decimalPlaces !== "")
            writer.tag(35, WireType.LengthDelimited).string(message.decimalPlaces);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.Quantity
 */
export const Quantity = new Quantity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Quantities$Type extends MessageType<Quantities> {
    constructor() {
        super("BBMgRPCstreamingNS.Quantities", [
            { no: 1, name: "quantities", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Quantity }
        ]);
    }
    create(value?: PartialMessage<Quantities>): Quantities {
        const message = { quantities: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Quantities>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Quantities): Quantities {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BBMgRPCstreamingNS.Quantity quantities */ 1:
                    message.quantities.push(Quantity.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Quantities, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BBMgRPCstreamingNS.Quantity quantities = 1; */
        for (let i = 0; i < message.quantities.length; i++)
            Quantity.internalBinaryWrite(message.quantities[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.Quantities
 */
export const Quantities = new Quantities$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Position$Type extends MessageType<Position> {
    constructor() {
        super("BBMgRPCstreamingNS.Position", [
            { no: 1, name: "measurement_point_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "coordinate_system_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.Position.CoordinateSystemType", Position_CoordinateSystemType] },
            { no: 3, name: "coordinate_x", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "coordinate_y", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "coordinate_z", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "coordinate_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "direction_x", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 8, name: "direction_y", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 9, name: "direction_z", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<Position>): Position {
        const message = { measurementPointText: "", coordinateSystemType: 0, coordinateX: 0, coordinateY: 0, coordinateZ: 0, coordinateName: "", directionX: 0, directionY: 0, directionZ: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Position>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Position): Position {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string measurement_point_text */ 1:
                    message.measurementPointText = reader.string();
                    break;
                case /* BBMgRPCstreamingNS.Position.CoordinateSystemType coordinate_system_type */ 2:
                    message.coordinateSystemType = reader.int32();
                    break;
                case /* double coordinate_x */ 3:
                    message.coordinateX = reader.double();
                    break;
                case /* double coordinate_y */ 4:
                    message.coordinateY = reader.double();
                    break;
                case /* double coordinate_z */ 5:
                    message.coordinateZ = reader.double();
                    break;
                case /* string coordinate_name */ 6:
                    message.coordinateName = reader.string();
                    break;
                case /* double direction_x */ 7:
                    message.directionX = reader.double();
                    break;
                case /* double direction_y */ 8:
                    message.directionY = reader.double();
                    break;
                case /* double direction_z */ 9:
                    message.directionZ = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Position, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string measurement_point_text = 1; */
        if (message.measurementPointText !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.measurementPointText);
        /* BBMgRPCstreamingNS.Position.CoordinateSystemType coordinate_system_type = 2; */
        if (message.coordinateSystemType !== 0)
            writer.tag(2, WireType.Varint).int32(message.coordinateSystemType);
        /* double coordinate_x = 3; */
        if (message.coordinateX !== 0)
            writer.tag(3, WireType.Bit64).double(message.coordinateX);
        /* double coordinate_y = 4; */
        if (message.coordinateY !== 0)
            writer.tag(4, WireType.Bit64).double(message.coordinateY);
        /* double coordinate_z = 5; */
        if (message.coordinateZ !== 0)
            writer.tag(5, WireType.Bit64).double(message.coordinateZ);
        /* string coordinate_name = 6; */
        if (message.coordinateName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.coordinateName);
        /* double direction_x = 7; */
        if (message.directionX !== 0)
            writer.tag(7, WireType.Bit64).double(message.directionX);
        /* double direction_y = 8; */
        if (message.directionY !== 0)
            writer.tag(8, WireType.Bit64).double(message.directionY);
        /* double direction_z = 9; */
        if (message.directionZ !== 0)
            writer.tag(9, WireType.Bit64).double(message.directionZ);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.Position
 */
export const Position = new Position$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetCalibration$Type extends MessageType<DatasetCalibration> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetCalibration", [
            { no: 1, name: "calibscale", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "calibfact", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "calibofs", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<DatasetCalibration>): DatasetCalibration {
        const message = { calibscale: 0, calibfact: 0, calibofs: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetCalibration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetCalibration): DatasetCalibration {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double calibscale */ 1:
                    message.calibscale = reader.double();
                    break;
                case /* double calibfact */ 2:
                    message.calibfact = reader.double();
                    break;
                case /* double calibofs */ 3:
                    message.calibofs = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetCalibration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double calibscale = 1; */
        if (message.calibscale !== 0)
            writer.tag(1, WireType.Bit64).double(message.calibscale);
        /* double calibfact = 2; */
        if (message.calibfact !== 0)
            writer.tag(2, WireType.Bit64).double(message.calibfact);
        /* double calibofs = 3; */
        if (message.calibofs !== 0)
            writer.tag(3, WireType.Bit64).double(message.calibofs);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetCalibration
 */
export const DatasetCalibration = new DatasetCalibration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetRecLevelQuality$Type extends MessageType<DatasetRecLevelQuality> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetRecLevelQuality", [
            { no: 1, name: "fromtime", kind: "message", T: () => AbsoluteTime },
            { no: 2, name: "has_integritystate_ok", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "has_integritystate_ovld", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "has_integritystate_open", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "has_integritystate_shortcircuit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "has_integritystate_shorttoground", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "has_integritystate_invalid", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "level_db", kind: "scalar", T: 17 /*ScalarType.SINT32*/ }
        ]);
    }
    create(value?: PartialMessage<DatasetRecLevelQuality>): DatasetRecLevelQuality {
        const message = { hasIntegritystateOk: false, hasIntegritystateOvld: false, hasIntegritystateOpen: false, hasIntegritystateShortcircuit: false, hasIntegritystateShorttoground: false, hasIntegritystateInvalid: false, levelDb: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetRecLevelQuality>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetRecLevelQuality): DatasetRecLevelQuality {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.AbsoluteTime fromtime */ 1:
                    message.fromtime = AbsoluteTime.internalBinaryRead(reader, reader.uint32(), options, message.fromtime);
                    break;
                case /* bool has_integritystate_ok */ 2:
                    message.hasIntegritystateOk = reader.bool();
                    break;
                case /* bool has_integritystate_ovld */ 3:
                    message.hasIntegritystateOvld = reader.bool();
                    break;
                case /* bool has_integritystate_open */ 4:
                    message.hasIntegritystateOpen = reader.bool();
                    break;
                case /* bool has_integritystate_shortcircuit */ 5:
                    message.hasIntegritystateShortcircuit = reader.bool();
                    break;
                case /* bool has_integritystate_shorttoground */ 6:
                    message.hasIntegritystateShorttoground = reader.bool();
                    break;
                case /* bool has_integritystate_invalid */ 7:
                    message.hasIntegritystateInvalid = reader.bool();
                    break;
                case /* sint32 level_db */ 8:
                    message.levelDb = reader.sint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetRecLevelQuality, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.AbsoluteTime fromtime = 1; */
        if (message.fromtime)
            AbsoluteTime.internalBinaryWrite(message.fromtime, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool has_integritystate_ok = 2; */
        if (message.hasIntegritystateOk !== false)
            writer.tag(2, WireType.Varint).bool(message.hasIntegritystateOk);
        /* bool has_integritystate_ovld = 3; */
        if (message.hasIntegritystateOvld !== false)
            writer.tag(3, WireType.Varint).bool(message.hasIntegritystateOvld);
        /* bool has_integritystate_open = 4; */
        if (message.hasIntegritystateOpen !== false)
            writer.tag(4, WireType.Varint).bool(message.hasIntegritystateOpen);
        /* bool has_integritystate_shortcircuit = 5; */
        if (message.hasIntegritystateShortcircuit !== false)
            writer.tag(5, WireType.Varint).bool(message.hasIntegritystateShortcircuit);
        /* bool has_integritystate_shorttoground = 6; */
        if (message.hasIntegritystateShorttoground !== false)
            writer.tag(6, WireType.Varint).bool(message.hasIntegritystateShorttoground);
        /* bool has_integritystate_invalid = 7; */
        if (message.hasIntegritystateInvalid !== false)
            writer.tag(7, WireType.Varint).bool(message.hasIntegritystateInvalid);
        /* sint32 level_db = 8; */
        if (message.levelDb !== 0)
            writer.tag(8, WireType.Varint).sint32(message.levelDb);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetRecLevelQuality
 */
export const DatasetRecLevelQuality = new DatasetRecLevelQuality$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetXValues$Type extends MessageType<DatasetXValues> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetXValues", [
            { no: 1, name: "x_equi_values", kind: "message", oneof: "xValues", T: () => NEquiDoubleValues },
            { no: 2, name: "x_nonequi_values", kind: "message", oneof: "xValues", T: () => NonEquiDoubleValues },
            { no: 3, name: "x_label_values", kind: "message", oneof: "xValues", T: () => LabelAndDoubleValues }
        ]);
    }
    create(value?: PartialMessage<DatasetXValues>): DatasetXValues {
        const message = { xValues: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetXValues>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetXValues): DatasetXValues {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.NEquiDoubleValues x_equi_values */ 1:
                    message.xValues = {
                        oneofKind: "xEquiValues",
                        xEquiValues: NEquiDoubleValues.internalBinaryRead(reader, reader.uint32(), options, (message.xValues as any).xEquiValues)
                    };
                    break;
                case /* BBMgRPCstreamingNS.NonEquiDoubleValues x_nonequi_values */ 2:
                    message.xValues = {
                        oneofKind: "xNonequiValues",
                        xNonequiValues: NonEquiDoubleValues.internalBinaryRead(reader, reader.uint32(), options, (message.xValues as any).xNonequiValues)
                    };
                    break;
                case /* BBMgRPCstreamingNS.LabelAndDoubleValues x_label_values */ 3:
                    message.xValues = {
                        oneofKind: "xLabelValues",
                        xLabelValues: LabelAndDoubleValues.internalBinaryRead(reader, reader.uint32(), options, (message.xValues as any).xLabelValues)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetXValues, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.NEquiDoubleValues x_equi_values = 1; */
        if (message.xValues.oneofKind === "xEquiValues")
            NEquiDoubleValues.internalBinaryWrite(message.xValues.xEquiValues, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.NonEquiDoubleValues x_nonequi_values = 2; */
        if (message.xValues.oneofKind === "xNonequiValues")
            NonEquiDoubleValues.internalBinaryWrite(message.xValues.xNonequiValues, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.LabelAndDoubleValues x_label_values = 3; */
        if (message.xValues.oneofKind === "xLabelValues")
            LabelAndDoubleValues.internalBinaryWrite(message.xValues.xLabelValues, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetXValues
 */
export const DatasetXValues = new DatasetXValues$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetEOFTime$Type extends MessageType<DatasetEOFTime> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetEOFTime", [
            { no: 1, name: "eof_time", kind: "message", T: () => AbsoluteTime }
        ]);
    }
    create(value?: PartialMessage<DatasetEOFTime>): DatasetEOFTime {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetEOFTime>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetEOFTime): DatasetEOFTime {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.AbsoluteTime eof_time */ 1:
                    message.eofTime = AbsoluteTime.internalBinaryRead(reader, reader.uint32(), options, message.eofTime);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetEOFTime, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.AbsoluteTime eof_time = 1; */
        if (message.eofTime)
            AbsoluteTime.internalBinaryWrite(message.eofTime, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetEOFTime
 */
export const DatasetEOFTime = new DatasetEOFTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetBOFTime$Type extends MessageType<DatasetBOFTime> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetBOFTime", [
            { no: 1, name: "start_time", kind: "message", T: () => AbsoluteTime },
            { no: 2, name: "first_sample_time", kind: "message", T: () => AbsoluteTime }
        ]);
    }
    create(value?: PartialMessage<DatasetBOFTime>): DatasetBOFTime {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetBOFTime>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetBOFTime): DatasetBOFTime {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.AbsoluteTime start_time */ 1:
                    message.startTime = AbsoluteTime.internalBinaryRead(reader, reader.uint32(), options, message.startTime);
                    break;
                case /* BBMgRPCstreamingNS.AbsoluteTime first_sample_time */ 2:
                    message.firstSampleTime = AbsoluteTime.internalBinaryRead(reader, reader.uint32(), options, message.firstSampleTime);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetBOFTime, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.AbsoluteTime start_time = 1; */
        if (message.startTime)
            AbsoluteTime.internalBinaryWrite(message.startTime, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.AbsoluteTime first_sample_time = 2; */
        if (message.firstSampleTime)
            AbsoluteTime.internalBinaryWrite(message.firstSampleTime, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetBOFTime
 */
export const DatasetBOFTime = new DatasetBOFTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetNextStopTime$Type extends MessageType<DatasetNextStopTime> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetNextStopTime", [
            { no: 1, name: "stop_time", kind: "message", T: () => AbsoluteTime }
        ]);
    }
    create(value?: PartialMessage<DatasetNextStopTime>): DatasetNextStopTime {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetNextStopTime>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetNextStopTime): DatasetNextStopTime {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.AbsoluteTime stop_time */ 1:
                    message.stopTime = AbsoluteTime.internalBinaryRead(reader, reader.uint32(), options, message.stopTime);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetNextStopTime, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.AbsoluteTime stop_time = 1; */
        if (message.stopTime)
            AbsoluteTime.internalBinaryWrite(message.stopTime, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetNextStopTime
 */
export const DatasetNextStopTime = new DatasetNextStopTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetStepValues$Type extends MessageType<DatasetStepValues> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetStepValues", [
            { no: 1, name: "step_index", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "track_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "average_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "order_rpm_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "rlq", kind: "message", T: () => DatasetRecLevelQuality }
        ]);
    }
    create(value?: PartialMessage<DatasetStepValues>): DatasetStepValues {
        const message = { stepIndex: 0, trackValue: 0, averageValue: 0, orderRpmValue: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetStepValues>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetStepValues): DatasetStepValues {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 step_index */ 1:
                    message.stepIndex = reader.uint32();
                    break;
                case /* double track_value */ 2:
                    message.trackValue = reader.double();
                    break;
                case /* double average_value */ 3:
                    message.averageValue = reader.double();
                    break;
                case /* double order_rpm_value */ 4:
                    message.orderRpmValue = reader.double();
                    break;
                case /* BBMgRPCstreamingNS.DatasetRecLevelQuality rlq */ 5:
                    message.rlq = DatasetRecLevelQuality.internalBinaryRead(reader, reader.uint32(), options, message.rlq);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetStepValues, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 step_index = 1; */
        if (message.stepIndex !== 0)
            writer.tag(1, WireType.Varint).uint32(message.stepIndex);
        /* double track_value = 2; */
        if (message.trackValue !== 0)
            writer.tag(2, WireType.Bit64).double(message.trackValue);
        /* double average_value = 3; */
        if (message.averageValue !== 0)
            writer.tag(3, WireType.Bit64).double(message.averageValue);
        /* double order_rpm_value = 4; */
        if (message.orderRpmValue !== 0)
            writer.tag(4, WireType.Bit64).double(message.orderRpmValue);
        /* BBMgRPCstreamingNS.DatasetRecLevelQuality rlq = 5; */
        if (message.rlq)
            DatasetRecLevelQuality.internalBinaryWrite(message.rlq, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetStepValues
 */
export const DatasetStepValues = new DatasetStepValues$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetChangesChunk$Type extends MessageType<DatasetChangesChunk> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetChangesChunk", [
            { no: 2, name: "dataset_calibration", kind: "message", oneof: "data", T: () => DatasetCalibration },
            { no: 3, name: "dataset_reclevelquality", kind: "message", oneof: "data", T: () => DatasetRecLevelQuality },
            { no: 4, name: "dataset_x_values", kind: "message", oneof: "data", T: () => DatasetXValues },
            { no: 5, name: "dataset_eof_time", kind: "message", oneof: "data", T: () => DatasetEOFTime },
            { no: 6, name: "dataset_step_values", kind: "message", oneof: "data", T: () => DatasetStepValues },
            { no: 7, name: "dataset_bof_time", kind: "message", oneof: "data", T: () => DatasetBOFTime },
            { no: 8, name: "dataset_next_stop_time", kind: "message", oneof: "data", T: () => DatasetNextStopTime }
        ]);
    }
    create(value?: PartialMessage<DatasetChangesChunk>): DatasetChangesChunk {
        const message = { data: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetChangesChunk>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetChangesChunk): DatasetChangesChunk {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.DatasetCalibration dataset_calibration */ 2:
                    message.data = {
                        oneofKind: "datasetCalibration",
                        datasetCalibration: DatasetCalibration.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).datasetCalibration)
                    };
                    break;
                case /* BBMgRPCstreamingNS.DatasetRecLevelQuality dataset_reclevelquality */ 3:
                    message.data = {
                        oneofKind: "datasetReclevelquality",
                        datasetReclevelquality: DatasetRecLevelQuality.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).datasetReclevelquality)
                    };
                    break;
                case /* BBMgRPCstreamingNS.DatasetXValues dataset_x_values */ 4:
                    message.data = {
                        oneofKind: "datasetXValues",
                        datasetXValues: DatasetXValues.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).datasetXValues)
                    };
                    break;
                case /* BBMgRPCstreamingNS.DatasetEOFTime dataset_eof_time */ 5:
                    message.data = {
                        oneofKind: "datasetEofTime",
                        datasetEofTime: DatasetEOFTime.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).datasetEofTime)
                    };
                    break;
                case /* BBMgRPCstreamingNS.DatasetStepValues dataset_step_values */ 6:
                    message.data = {
                        oneofKind: "datasetStepValues",
                        datasetStepValues: DatasetStepValues.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).datasetStepValues)
                    };
                    break;
                case /* BBMgRPCstreamingNS.DatasetBOFTime dataset_bof_time */ 7:
                    message.data = {
                        oneofKind: "datasetBofTime",
                        datasetBofTime: DatasetBOFTime.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).datasetBofTime)
                    };
                    break;
                case /* BBMgRPCstreamingNS.DatasetNextStopTime dataset_next_stop_time */ 8:
                    message.data = {
                        oneofKind: "datasetNextStopTime",
                        datasetNextStopTime: DatasetNextStopTime.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).datasetNextStopTime)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetChangesChunk, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.DatasetCalibration dataset_calibration = 2; */
        if (message.data.oneofKind === "datasetCalibration")
            DatasetCalibration.internalBinaryWrite(message.data.datasetCalibration, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetRecLevelQuality dataset_reclevelquality = 3; */
        if (message.data.oneofKind === "datasetReclevelquality")
            DatasetRecLevelQuality.internalBinaryWrite(message.data.datasetReclevelquality, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetXValues dataset_x_values = 4; */
        if (message.data.oneofKind === "datasetXValues")
            DatasetXValues.internalBinaryWrite(message.data.datasetXValues, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetEOFTime dataset_eof_time = 5; */
        if (message.data.oneofKind === "datasetEofTime")
            DatasetEOFTime.internalBinaryWrite(message.data.datasetEofTime, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetStepValues dataset_step_values = 6; */
        if (message.data.oneofKind === "datasetStepValues")
            DatasetStepValues.internalBinaryWrite(message.data.datasetStepValues, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetBOFTime dataset_bof_time = 7; */
        if (message.data.oneofKind === "datasetBofTime")
            DatasetBOFTime.internalBinaryWrite(message.data.datasetBofTime, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetNextStopTime dataset_next_stop_time = 8; */
        if (message.data.oneofKind === "datasetNextStopTime")
            DatasetNextStopTime.internalBinaryWrite(message.data.datasetNextStopTime, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetChangesChunk
 */
export const DatasetChangesChunk = new DatasetChangesChunk$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MeasChainFilter$Type extends MessageType<MeasChainFilter> {
    constructor() {
        super("BBMgRPCstreamingNS.MeasChainFilter", [
            { no: 1, name: "filter_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.MeasChainFilter.FilterType", MeasChainFilter_FilterType] },
            { no: 2, name: "LowerCutoff", kind: "scalar", jsonName: "LowerCutoff", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "UpperCutoff", kind: "scalar", jsonName: "UpperCutoff", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "LowerRolloff", kind: "scalar", jsonName: "LowerRolloff", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "UpperRolloff", kind: "scalar", jsonName: "UpperRolloff", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<MeasChainFilter>): MeasChainFilter {
        const message = { filterType: 0, lowerCutoff: 0, upperCutoff: 0, lowerRolloff: 0, upperRolloff: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MeasChainFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MeasChainFilter): MeasChainFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.MeasChainFilter.FilterType filter_type */ 1:
                    message.filterType = reader.int32();
                    break;
                case /* double LowerCutoff = 2 [json_name = "LowerCutoff"];*/ 2:
                    message.lowerCutoff = reader.double();
                    break;
                case /* double UpperCutoff = 3 [json_name = "UpperCutoff"];*/ 3:
                    message.upperCutoff = reader.double();
                    break;
                case /* double LowerRolloff = 4 [json_name = "LowerRolloff"];*/ 4:
                    message.lowerRolloff = reader.double();
                    break;
                case /* double UpperRolloff = 5 [json_name = "UpperRolloff"];*/ 5:
                    message.upperRolloff = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MeasChainFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.MeasChainFilter.FilterType filter_type = 1; */
        if (message.filterType !== 0)
            writer.tag(1, WireType.Varint).int32(message.filterType);
        /* double LowerCutoff = 2 [json_name = "LowerCutoff"]; */
        if (message.lowerCutoff !== 0)
            writer.tag(2, WireType.Bit64).double(message.lowerCutoff);
        /* double UpperCutoff = 3 [json_name = "UpperCutoff"]; */
        if (message.upperCutoff !== 0)
            writer.tag(3, WireType.Bit64).double(message.upperCutoff);
        /* double LowerRolloff = 4 [json_name = "LowerRolloff"]; */
        if (message.lowerRolloff !== 0)
            writer.tag(4, WireType.Bit64).double(message.lowerRolloff);
        /* double UpperRolloff = 5 [json_name = "UpperRolloff"]; */
        if (message.upperRolloff !== 0)
            writer.tag(5, WireType.Bit64).double(message.upperRolloff);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.MeasChainFilter
 */
export const MeasChainFilter = new MeasChainFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MeasChainDevice$Type extends MessageType<MeasChainDevice> {
    constructor() {
        super("BBMgRPCstreamingNS.MeasChainDevice", [
            { no: 1, name: "meas_chain_device_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "device_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.MeasChainDevice.DeviceType", MeasChainDevice_DeviceType] },
            { no: 4, name: "manufactor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "serial_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "software_revision", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "eqm_state", kind: "enum", T: () => ["BBMgRPCstreamingNS.MeasChainDevice.EQMState", MeasChainDevice_EQMState] }
        ]);
    }
    create(value?: PartialMessage<MeasChainDevice>): MeasChainDevice {
        const message = { measChainDeviceId: 0, deviceType: 0, manufactor: "", type: "", serialNumber: "", softwareRevision: "", eqmState: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MeasChainDevice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MeasChainDevice): MeasChainDevice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 meas_chain_device_id */ 1:
                    message.measChainDeviceId = reader.uint32();
                    break;
                case /* BBMgRPCstreamingNS.MeasChainDevice.DeviceType device_type */ 3:
                    message.deviceType = reader.int32();
                    break;
                case /* string manufactor */ 4:
                    message.manufactor = reader.string();
                    break;
                case /* string type */ 5:
                    message.type = reader.string();
                    break;
                case /* string serial_number */ 6:
                    message.serialNumber = reader.string();
                    break;
                case /* string software_revision */ 7:
                    message.softwareRevision = reader.string();
                    break;
                case /* BBMgRPCstreamingNS.MeasChainDevice.EQMState eqm_state */ 8:
                    message.eqmState = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MeasChainDevice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 meas_chain_device_id = 1; */
        if (message.measChainDeviceId !== 0)
            writer.tag(1, WireType.Varint).uint32(message.measChainDeviceId);
        /* BBMgRPCstreamingNS.MeasChainDevice.DeviceType device_type = 3; */
        if (message.deviceType !== 0)
            writer.tag(3, WireType.Varint).int32(message.deviceType);
        /* string manufactor = 4; */
        if (message.manufactor !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.manufactor);
        /* string type = 5; */
        if (message.type !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.type);
        /* string serial_number = 6; */
        if (message.serialNumber !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.serialNumber);
        /* string software_revision = 7; */
        if (message.softwareRevision !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.softwareRevision);
        /* BBMgRPCstreamingNS.MeasChainDevice.EQMState eqm_state = 8; */
        if (message.eqmState !== 0)
            writer.tag(8, WireType.Varint).int32(message.eqmState);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.MeasChainDevice
 */
export const MeasChainDevice = new MeasChainDevice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MeasChainDevices$Type extends MessageType<MeasChainDevices> {
    constructor() {
        super("BBMgRPCstreamingNS.MeasChainDevices", [
            { no: 1, name: "meas_chain_devices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MeasChainDevice }
        ]);
    }
    create(value?: PartialMessage<MeasChainDevices>): MeasChainDevices {
        const message = { measChainDevices: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MeasChainDevices>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MeasChainDevices): MeasChainDevices {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BBMgRPCstreamingNS.MeasChainDevice meas_chain_devices */ 1:
                    message.measChainDevices.push(MeasChainDevice.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MeasChainDevices, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BBMgRPCstreamingNS.MeasChainDevice meas_chain_devices = 1; */
        for (let i = 0; i < message.measChainDevices.length; i++)
            MeasChainDevice.internalBinaryWrite(message.measChainDevices[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.MeasChainDevices
 */
export const MeasChainDevices = new MeasChainDevices$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MeasChain$Type extends MessageType<MeasChain> {
    constructor() {
        super("BBMgRPCstreamingNS.MeasChain", [
            { no: 1, name: "meas_chain_device_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "range_calibrated", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "equalization_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.MeasChain.EqualizationType", MeasChain_EqualizationType] },
            { no: 4, name: "device_reference_calibration", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "device_reference_offset", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "meas_chain_filter", kind: "message", T: () => MeasChainFilter },
            { no: 7, name: "pre_gain_db", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<MeasChain>): MeasChain {
        const message = { measChainDeviceIds: [], rangeCalibrated: false, equalizationType: 0, deviceReferenceCalibration: 0, deviceReferenceOffset: 0, preGainDb: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MeasChain>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MeasChain): MeasChain {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated uint32 meas_chain_device_ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.measChainDeviceIds.push(reader.uint32());
                    else
                        message.measChainDeviceIds.push(reader.uint32());
                    break;
                case /* bool range_calibrated */ 2:
                    message.rangeCalibrated = reader.bool();
                    break;
                case /* BBMgRPCstreamingNS.MeasChain.EqualizationType equalization_type */ 3:
                    message.equalizationType = reader.int32();
                    break;
                case /* double device_reference_calibration */ 4:
                    message.deviceReferenceCalibration = reader.double();
                    break;
                case /* double device_reference_offset */ 5:
                    message.deviceReferenceOffset = reader.double();
                    break;
                case /* BBMgRPCstreamingNS.MeasChainFilter meas_chain_filter */ 6:
                    message.measChainFilter = MeasChainFilter.internalBinaryRead(reader, reader.uint32(), options, message.measChainFilter);
                    break;
                case /* double pre_gain_db */ 7:
                    message.preGainDb = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MeasChain, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated uint32 meas_chain_device_ids = 1; */
        if (message.measChainDeviceIds.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.measChainDeviceIds.length; i++)
                writer.uint32(message.measChainDeviceIds[i]);
            writer.join();
        }
        /* bool range_calibrated = 2; */
        if (message.rangeCalibrated !== false)
            writer.tag(2, WireType.Varint).bool(message.rangeCalibrated);
        /* BBMgRPCstreamingNS.MeasChain.EqualizationType equalization_type = 3; */
        if (message.equalizationType !== 0)
            writer.tag(3, WireType.Varint).int32(message.equalizationType);
        /* double device_reference_calibration = 4; */
        if (message.deviceReferenceCalibration !== 0)
            writer.tag(4, WireType.Bit64).double(message.deviceReferenceCalibration);
        /* double device_reference_offset = 5; */
        if (message.deviceReferenceOffset !== 0)
            writer.tag(5, WireType.Bit64).double(message.deviceReferenceOffset);
        /* BBMgRPCstreamingNS.MeasChainFilter meas_chain_filter = 6; */
        if (message.measChainFilter)
            MeasChainFilter.internalBinaryWrite(message.measChainFilter, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* double pre_gain_db = 7; */
        if (message.preGainDb !== 0)
            writer.tag(7, WireType.Bit64).double(message.preGainDb);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.MeasChain
 */
export const MeasChain = new MeasChain$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Channel$Type extends MessageType<Channel> {
    constructor() {
        super("BBMgRPCstreamingNS.Channel", [
            { no: 1, name: "channel_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "channel_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "meas_point_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "meas_chain", kind: "message", T: () => MeasChain }
        ]);
    }
    create(value?: PartialMessage<Channel>): Channel {
        const message = { channelId: 0, channelName: "", measPointType: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Channel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Channel): Channel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 channel_id */ 1:
                    message.channelId = reader.uint32();
                    break;
                case /* string channel_name */ 2:
                    message.channelName = reader.string();
                    break;
                case /* string meas_point_type */ 3:
                    message.measPointType = reader.string();
                    break;
                case /* BBMgRPCstreamingNS.MeasChain meas_chain */ 4:
                    message.measChain = MeasChain.internalBinaryRead(reader, reader.uint32(), options, message.measChain);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Channel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 channel_id = 1; */
        if (message.channelId !== 0)
            writer.tag(1, WireType.Varint).uint32(message.channelId);
        /* string channel_name = 2; */
        if (message.channelName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.channelName);
        /* string meas_point_type = 3; */
        if (message.measPointType !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.measPointType);
        /* BBMgRPCstreamingNS.MeasChain meas_chain = 4; */
        if (message.measChain)
            MeasChain.internalBinaryWrite(message.measChain, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.Channel
 */
export const Channel = new Channel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Channels$Type extends MessageType<Channels> {
    constructor() {
        super("BBMgRPCstreamingNS.Channels", [
            { no: 1, name: "channels", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Channel }
        ]);
    }
    create(value?: PartialMessage<Channels>): Channels {
        const message = { channels: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Channels>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Channels): Channels {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BBMgRPCstreamingNS.Channel channels */ 1:
                    message.channels.push(Channel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Channels, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BBMgRPCstreamingNS.Channel channels = 1; */
        for (let i = 0; i < message.channels.length; i++)
            Channel.internalBinaryWrite(message.channels[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.Channels
 */
export const Channels = new Channels$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReferencedInfo$Type extends MessageType<ReferencedInfo> {
    constructor() {
        super("BBMgRPCstreamingNS.ReferencedInfo", [
            { no: 1, name: "channel_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "position", kind: "message", T: () => Position },
            { no: 6, name: "quantity_y1_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "quantity_y2_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "var_pos_mode", kind: "enum", T: () => ["BBMgRPCstreamingNS.ReferencedInfo.VariablePositionMode", ReferencedInfo_VariablePositionMode] }
        ]);
    }
    create(value?: PartialMessage<ReferencedInfo>): ReferencedInfo {
        const message = { channelId: 0, quantityY1Id: 0, quantityY2Id: 0, varPosMode: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReferencedInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReferencedInfo): ReferencedInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 channel_id */ 1:
                    message.channelId = reader.uint32();
                    break;
                case /* BBMgRPCstreamingNS.Position position */ 2:
                    message.position = Position.internalBinaryRead(reader, reader.uint32(), options, message.position);
                    break;
                case /* uint32 quantity_y1_id */ 6:
                    message.quantityY1Id = reader.uint32();
                    break;
                case /* uint32 quantity_y2_id */ 7:
                    message.quantityY2Id = reader.uint32();
                    break;
                case /* BBMgRPCstreamingNS.ReferencedInfo.VariablePositionMode var_pos_mode */ 5:
                    message.varPosMode = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReferencedInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 channel_id = 1; */
        if (message.channelId !== 0)
            writer.tag(1, WireType.Varint).uint32(message.channelId);
        /* BBMgRPCstreamingNS.Position position = 2; */
        if (message.position)
            Position.internalBinaryWrite(message.position, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* uint32 quantity_y1_id = 6; */
        if (message.quantityY1Id !== 0)
            writer.tag(6, WireType.Varint).uint32(message.quantityY1Id);
        /* uint32 quantity_y2_id = 7; */
        if (message.quantityY2Id !== 0)
            writer.tag(7, WireType.Varint).uint32(message.quantityY2Id);
        /* BBMgRPCstreamingNS.ReferencedInfo.VariablePositionMode var_pos_mode = 5; */
        if (message.varPosMode !== 0)
            writer.tag(5, WireType.Varint).int32(message.varPosMode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.ReferencedInfo
 */
export const ReferencedInfo = new ReferencedInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WindowInfo$Type extends MessageType<WindowInfo> {
    constructor() {
        super("BBMgRPCstreamingNS.WindowInfo", [
            { no: 1, name: "window_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.WindowInfo.WindowType", WindowInfo_WindowType] },
            { no: 2, name: "window_correction", kind: "enum", T: () => ["BBMgRPCstreamingNS.WindowInfo.WindowCorrection", WindowInfo_WindowCorrection] },
            { no: 3, name: "broad_band_factor", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<WindowInfo>): WindowInfo {
        const message = { windowType: 0, windowCorrection: 0, broadBandFactor: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WindowInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WindowInfo): WindowInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.WindowInfo.WindowType window_type */ 1:
                    message.windowType = reader.int32();
                    break;
                case /* BBMgRPCstreamingNS.WindowInfo.WindowCorrection window_correction */ 2:
                    message.windowCorrection = reader.int32();
                    break;
                case /* double broad_band_factor */ 3:
                    message.broadBandFactor = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WindowInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.WindowInfo.WindowType window_type = 1; */
        if (message.windowType !== 0)
            writer.tag(1, WireType.Varint).int32(message.windowType);
        /* BBMgRPCstreamingNS.WindowInfo.WindowCorrection window_correction = 2; */
        if (message.windowCorrection !== 0)
            writer.tag(2, WireType.Varint).int32(message.windowCorrection);
        /* double broad_band_factor = 3; */
        if (message.broadBandFactor !== 0)
            writer.tag(3, WireType.Bit64).double(message.broadBandFactor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.WindowInfo
 */
export const WindowInfo = new WindowInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataInfo$Type extends MessageType<DataInfo> {
    constructor() {
        super("BBMgRPCstreamingNS.DataInfo", [
            { no: 1, name: "track_info", kind: "message", T: () => DataInfo_ChannelInfo },
            { no: 2, name: "rpm_infos", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DataInfo_ChannelInfo },
            { no: 3, name: "sampling_group_infos", kind: "message", T: () => DataInfo_SamplingGroupInfo },
            { no: 4, name: "time_source_info", kind: "message", T: () => DataInfo_TimeSourceInfo },
            { no: 5, name: "tput_compat_into", kind: "message", T: () => DataInfo_TputCompatInfo },
            { no: 6, name: "ms_list_index_info", kind: "message", T: () => DataInfo_MSListIndexInfo },
            { no: 7, name: "original_track_info", kind: "message", T: () => DataInfo_OriginalTrackInfo }
        ]);
    }
    create(value?: PartialMessage<DataInfo>): DataInfo {
        const message = { rpmInfos: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DataInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DataInfo): DataInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.DataInfo.ChannelInfo track_info */ 1:
                    message.trackInfo = DataInfo_ChannelInfo.internalBinaryRead(reader, reader.uint32(), options, message.trackInfo);
                    break;
                case /* repeated BBMgRPCstreamingNS.DataInfo.ChannelInfo rpm_infos */ 2:
                    message.rpmInfos.push(DataInfo_ChannelInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* BBMgRPCstreamingNS.DataInfo.SamplingGroupInfo sampling_group_infos */ 3:
                    message.samplingGroupInfos = DataInfo_SamplingGroupInfo.internalBinaryRead(reader, reader.uint32(), options, message.samplingGroupInfos);
                    break;
                case /* BBMgRPCstreamingNS.DataInfo.TimeSourceInfo time_source_info */ 4:
                    message.timeSourceInfo = DataInfo_TimeSourceInfo.internalBinaryRead(reader, reader.uint32(), options, message.timeSourceInfo);
                    break;
                case /* BBMgRPCstreamingNS.DataInfo.TputCompatInfo tput_compat_into */ 5:
                    message.tputCompatInto = DataInfo_TputCompatInfo.internalBinaryRead(reader, reader.uint32(), options, message.tputCompatInto);
                    break;
                case /* BBMgRPCstreamingNS.DataInfo.MSListIndexInfo ms_list_index_info */ 6:
                    message.msListIndexInfo = DataInfo_MSListIndexInfo.internalBinaryRead(reader, reader.uint32(), options, message.msListIndexInfo);
                    break;
                case /* BBMgRPCstreamingNS.DataInfo.OriginalTrackInfo original_track_info */ 7:
                    message.originalTrackInfo = DataInfo_OriginalTrackInfo.internalBinaryRead(reader, reader.uint32(), options, message.originalTrackInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DataInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.DataInfo.ChannelInfo track_info = 1; */
        if (message.trackInfo)
            DataInfo_ChannelInfo.internalBinaryWrite(message.trackInfo, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated BBMgRPCstreamingNS.DataInfo.ChannelInfo rpm_infos = 2; */
        for (let i = 0; i < message.rpmInfos.length; i++)
            DataInfo_ChannelInfo.internalBinaryWrite(message.rpmInfos[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DataInfo.SamplingGroupInfo sampling_group_infos = 3; */
        if (message.samplingGroupInfos)
            DataInfo_SamplingGroupInfo.internalBinaryWrite(message.samplingGroupInfos, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DataInfo.TimeSourceInfo time_source_info = 4; */
        if (message.timeSourceInfo)
            DataInfo_TimeSourceInfo.internalBinaryWrite(message.timeSourceInfo, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DataInfo.TputCompatInfo tput_compat_into = 5; */
        if (message.tputCompatInto)
            DataInfo_TputCompatInfo.internalBinaryWrite(message.tputCompatInto, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DataInfo.MSListIndexInfo ms_list_index_info = 6; */
        if (message.msListIndexInfo)
            DataInfo_MSListIndexInfo.internalBinaryWrite(message.msListIndexInfo, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DataInfo.OriginalTrackInfo original_track_info = 7; */
        if (message.originalTrackInfo)
            DataInfo_OriginalTrackInfo.internalBinaryWrite(message.originalTrackInfo, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DataInfo
 */
export const DataInfo = new DataInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataInfo_ChannelInfo$Type extends MessageType<DataInfo_ChannelInfo> {
    constructor() {
        super("BBMgRPCstreamingNS.DataInfo.ChannelInfo", [
            { no: 1, name: "channel_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "position", kind: "message", T: () => Position },
            { no: 4, name: "quantity_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<DataInfo_ChannelInfo>): DataInfo_ChannelInfo {
        const message = { channelId: 0, quantityId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DataInfo_ChannelInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DataInfo_ChannelInfo): DataInfo_ChannelInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 channel_id */ 1:
                    message.channelId = reader.uint32();
                    break;
                case /* BBMgRPCstreamingNS.Position position */ 2:
                    message.position = Position.internalBinaryRead(reader, reader.uint32(), options, message.position);
                    break;
                case /* uint32 quantity_id */ 4:
                    message.quantityId = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DataInfo_ChannelInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 channel_id = 1; */
        if (message.channelId !== 0)
            writer.tag(1, WireType.Varint).uint32(message.channelId);
        /* BBMgRPCstreamingNS.Position position = 2; */
        if (message.position)
            Position.internalBinaryWrite(message.position, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* uint32 quantity_id = 4; */
        if (message.quantityId !== 0)
            writer.tag(4, WireType.Varint).uint32(message.quantityId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DataInfo.ChannelInfo
 */
export const DataInfo_ChannelInfo = new DataInfo_ChannelInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataInfo_SamplingGroupInfo$Type extends MessageType<DataInfo_SamplingGroupInfo> {
    constructor() {
        super("BBMgRPCstreamingNS.DataInfo.SamplingGroupInfo", [
            { no: 1, name: "index", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DataInfo_SamplingGroupInfo>): DataInfo_SamplingGroupInfo {
        const message = { index: 0, name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DataInfo_SamplingGroupInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DataInfo_SamplingGroupInfo): DataInfo_SamplingGroupInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 index */ 1:
                    message.index = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DataInfo_SamplingGroupInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 index = 1; */
        if (message.index !== 0)
            writer.tag(1, WireType.Varint).int32(message.index);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DataInfo.SamplingGroupInfo
 */
export const DataInfo_SamplingGroupInfo = new DataInfo_SamplingGroupInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataInfo_TimeSourceInfo$Type extends MessageType<DataInfo_TimeSourceInfo> {
    constructor() {
        super("BBMgRPCstreamingNS.DataInfo.TimeSourceInfo", [
            { no: 1, name: "time_source_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.DataInfo.TimeSourceInfo.TimeSourceType", DataInfo_TimeSourceInfo_TimeSourceType] },
            { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DataInfo_TimeSourceInfo>): DataInfo_TimeSourceInfo {
        const message = { timeSourceType: 0, id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DataInfo_TimeSourceInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DataInfo_TimeSourceInfo): DataInfo_TimeSourceInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.DataInfo.TimeSourceInfo.TimeSourceType time_source_type */ 1:
                    message.timeSourceType = reader.int32();
                    break;
                case /* string id */ 2:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DataInfo_TimeSourceInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.DataInfo.TimeSourceInfo.TimeSourceType time_source_type = 1; */
        if (message.timeSourceType !== 0)
            writer.tag(1, WireType.Varint).int32(message.timeSourceType);
        /* string id = 2; */
        if (message.id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DataInfo.TimeSourceInfo
 */
export const DataInfo_TimeSourceInfo = new DataInfo_TimeSourceInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataInfo_MSListIndexInfo$Type extends MessageType<DataInfo_MSListIndexInfo> {
    constructor() {
        super("BBMgRPCstreamingNS.DataInfo.MSListIndexInfo", [
            { no: 1, name: "index", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<DataInfo_MSListIndexInfo>): DataInfo_MSListIndexInfo {
        const message = { index: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DataInfo_MSListIndexInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DataInfo_MSListIndexInfo): DataInfo_MSListIndexInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 index */ 1:
                    message.index = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DataInfo_MSListIndexInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 index = 1; */
        if (message.index !== 0)
            writer.tag(1, WireType.Varint).int32(message.index);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DataInfo.MSListIndexInfo
 */
export const DataInfo_MSListIndexInfo = new DataInfo_MSListIndexInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataInfo_TputCompatInfo$Type extends MessageType<DataInfo_TputCompatInfo> {
    constructor() {
        super("BBMgRPCstreamingNS.DataInfo.TputCompatInfo", [
            { no: 1, name: "device", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "slot", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "range", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "type", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<DataInfo_TputCompatInfo>): DataInfo_TputCompatInfo {
        const message = { device: 0, slot: 0, range: 0, type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DataInfo_TputCompatInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DataInfo_TputCompatInfo): DataInfo_TputCompatInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 device */ 1:
                    message.device = reader.int32();
                    break;
                case /* int32 slot */ 2:
                    message.slot = reader.int32();
                    break;
                case /* int32 range */ 3:
                    message.range = reader.int32();
                    break;
                case /* int32 type */ 4:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DataInfo_TputCompatInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 device = 1; */
        if (message.device !== 0)
            writer.tag(1, WireType.Varint).int32(message.device);
        /* int32 slot = 2; */
        if (message.slot !== 0)
            writer.tag(2, WireType.Varint).int32(message.slot);
        /* int32 range = 3; */
        if (message.range !== 0)
            writer.tag(3, WireType.Varint).int32(message.range);
        /* int32 type = 4; */
        if (message.type !== 0)
            writer.tag(4, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DataInfo.TputCompatInfo
 */
export const DataInfo_TputCompatInfo = new DataInfo_TputCompatInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataInfo_OriginalTrackInfo$Type extends MessageType<DataInfo_OriginalTrackInfo> {
    constructor() {
        super("BBMgRPCstreamingNS.DataInfo.OriginalTrackInfo", [
            { no: 1, name: "channel_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "position", kind: "message", T: () => Position }
        ]);
    }
    create(value?: PartialMessage<DataInfo_OriginalTrackInfo>): DataInfo_OriginalTrackInfo {
        const message = { channelId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DataInfo_OriginalTrackInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DataInfo_OriginalTrackInfo): DataInfo_OriginalTrackInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 channel_id */ 1:
                    message.channelId = reader.uint32();
                    break;
                case /* BBMgRPCstreamingNS.Position position */ 2:
                    message.position = Position.internalBinaryRead(reader, reader.uint32(), options, message.position);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DataInfo_OriginalTrackInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 channel_id = 1; */
        if (message.channelId !== 0)
            writer.tag(1, WireType.Varint).uint32(message.channelId);
        /* BBMgRPCstreamingNS.Position position = 2; */
        if (message.position)
            Position.internalBinaryWrite(message.position, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DataInfo.OriginalTrackInfo
 */
export const DataInfo_OriginalTrackInfo = new DataInfo_OriginalTrackInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetThreeDimensionalMetaData$Type extends MessageType<DatasetThreeDimensionalMetaData> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData", [
            { no: 14, name: "shape", kind: "enum", T: () => ["BBMgRPCstreamingNS.Shape", Shape] },
            { no: 15, name: "y_data_is_squared", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 41, name: "quantity_x_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 42, name: "quantity_y_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 43, name: "quantity_z_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 19, name: "measurement_start_time", kind: "message", T: () => DateTime },
            { no: 44, name: "channel_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 20, name: "type", kind: "enum", T: () => ["BBMgRPCstreamingNS.DataType", DataType] },
            { no: 21, name: "position", kind: "message", T: () => Position },
            { no: 22, name: "referenced_info", kind: "message", T: () => ReferencedInfo },
            { no: 23, name: "x_values", kind: "message", T: () => DatasetXValues },
            { no: 45, name: "scale_range_y", kind: "message", T: () => DoubleRange },
            { no: 24, name: "scale_range_z", kind: "message", T: () => DoubleRange },
            { no: 25, name: "num_potential_steps", kind: "scalar", T: 4 /*ScalarType.UINT64*/ },
            { no: 26, name: "avg_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.AverageType", AverageType] },
            { no: 27, name: "avg_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 28, name: "freq_weight", kind: "enum", T: () => ["BBMgRPCstreamingNS.FrequenzWeight", FrequenzWeight] },
            { no: 29, name: "data_info", kind: "message", T: () => DataInfo },
            { no: 30, name: "spectrum_parameters", kind: "message", oneof: "typeSpecificData", T: () => DatasetThreeDimensionalMetaData_SpectrumParameters },
            { no: 31, name: "psycho_parameters", kind: "message", oneof: "typeSpecificData", T: () => DatasetThreeDimensionalMetaData_PsychoParams },
            { no: 32, name: "cepstrum_parameters", kind: "message", oneof: "typeSpecificData", T: () => DatasetThreeDimensionalMetaData_CepstrumParams },
            { no: 33, name: "octave_parameters", kind: "message", oneof: "typeSpecificData", T: () => DatasetThreeDimensionalMetaData_OctaveParams },
            { no: 34, name: "edge_block_parameters", kind: "message", oneof: "typeSpecificData", T: () => TachoValueParams },
            { no: 35, name: "trajectory_parameters", kind: "message", oneof: "typeSpecificData", T: () => DatasetThreeDimensionalMetaData_TrajectoryParams },
            { no: 36, name: "unknown_parameters", kind: "message", oneof: "typeSpecificData", T: () => DatasetThreeDimensionalMetaData_UnknownParams },
            { no: 37, name: "wavelet_parameters", kind: "message", oneof: "typeSpecificData", T: () => DatasetThreeDimensionalMetaData_WaveletParams },
            { no: 38, name: "detector_parameters", kind: "message", oneof: "typeSpecificData", T: () => DatasetThreeDimensionalMetaData_DetectorParams },
            { no: 39, name: "xlabel_parameters", kind: "message", oneof: "typeSpecificData", T: () => DatasetThreeDimensionalMetaData_XLabelParams },
            { no: 40, name: "contour_base_parameters", kind: "message", oneof: "typeSpecificData", T: () => DatasetThreeDimensionalMetaData_ContourBaseParams }
        ]);
    }
    create(value?: PartialMessage<DatasetThreeDimensionalMetaData>): DatasetThreeDimensionalMetaData {
        const message = { shape: 0, yDataIsSquared: false, quantityXId: 0, quantityYId: 0, quantityZId: 0, channelId: 0, type: 0, numPotentialSteps: "0", avgType: 0, avgValue: 0, freqWeight: 0, typeSpecificData: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetThreeDimensionalMetaData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetThreeDimensionalMetaData): DatasetThreeDimensionalMetaData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.Shape shape */ 14:
                    message.shape = reader.int32();
                    break;
                case /* bool y_data_is_squared */ 15:
                    message.yDataIsSquared = reader.bool();
                    break;
                case /* uint32 quantity_x_id */ 41:
                    message.quantityXId = reader.uint32();
                    break;
                case /* uint32 quantity_y_id */ 42:
                    message.quantityYId = reader.uint32();
                    break;
                case /* uint32 quantity_z_id */ 43:
                    message.quantityZId = reader.uint32();
                    break;
                case /* BBMgRPCstreamingNS.DateTime measurement_start_time */ 19:
                    message.measurementStartTime = DateTime.internalBinaryRead(reader, reader.uint32(), options, message.measurementStartTime);
                    break;
                case /* uint32 channel_id */ 44:
                    message.channelId = reader.uint32();
                    break;
                case /* BBMgRPCstreamingNS.DataType type */ 20:
                    message.type = reader.int32();
                    break;
                case /* BBMgRPCstreamingNS.Position position */ 21:
                    message.position = Position.internalBinaryRead(reader, reader.uint32(), options, message.position);
                    break;
                case /* BBMgRPCstreamingNS.ReferencedInfo referenced_info */ 22:
                    message.referencedInfo = ReferencedInfo.internalBinaryRead(reader, reader.uint32(), options, message.referencedInfo);
                    break;
                case /* BBMgRPCstreamingNS.DatasetXValues x_values */ 23:
                    message.xValues = DatasetXValues.internalBinaryRead(reader, reader.uint32(), options, message.xValues);
                    break;
                case /* BBMgRPCstreamingNS.DoubleRange scale_range_y */ 45:
                    message.scaleRangeY = DoubleRange.internalBinaryRead(reader, reader.uint32(), options, message.scaleRangeY);
                    break;
                case /* BBMgRPCstreamingNS.DoubleRange scale_range_z */ 24:
                    message.scaleRangeZ = DoubleRange.internalBinaryRead(reader, reader.uint32(), options, message.scaleRangeZ);
                    break;
                case /* uint64 num_potential_steps */ 25:
                    message.numPotentialSteps = reader.uint64().toString();
                    break;
                case /* BBMgRPCstreamingNS.AverageType avg_type */ 26:
                    message.avgType = reader.int32();
                    break;
                case /* double avg_value */ 27:
                    message.avgValue = reader.double();
                    break;
                case /* BBMgRPCstreamingNS.FrequenzWeight freq_weight */ 28:
                    message.freqWeight = reader.int32();
                    break;
                case /* BBMgRPCstreamingNS.DataInfo data_info */ 29:
                    message.dataInfo = DataInfo.internalBinaryRead(reader, reader.uint32(), options, message.dataInfo);
                    break;
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters spectrum_parameters */ 30:
                    message.typeSpecificData = {
                        oneofKind: "spectrumParameters",
                        spectrumParameters: DatasetThreeDimensionalMetaData_SpectrumParameters.internalBinaryRead(reader, reader.uint32(), options, (message.typeSpecificData as any).spectrumParameters)
                    };
                    break;
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams psycho_parameters */ 31:
                    message.typeSpecificData = {
                        oneofKind: "psychoParameters",
                        psychoParameters: DatasetThreeDimensionalMetaData_PsychoParams.internalBinaryRead(reader, reader.uint32(), options, (message.typeSpecificData as any).psychoParameters)
                    };
                    break;
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.CepstrumParams cepstrum_parameters */ 32:
                    message.typeSpecificData = {
                        oneofKind: "cepstrumParameters",
                        cepstrumParameters: DatasetThreeDimensionalMetaData_CepstrumParams.internalBinaryRead(reader, reader.uint32(), options, (message.typeSpecificData as any).cepstrumParameters)
                    };
                    break;
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.OctaveParams octave_parameters */ 33:
                    message.typeSpecificData = {
                        oneofKind: "octaveParameters",
                        octaveParameters: DatasetThreeDimensionalMetaData_OctaveParams.internalBinaryRead(reader, reader.uint32(), options, (message.typeSpecificData as any).octaveParameters)
                    };
                    break;
                case /* BBMgRPCstreamingNS.TachoValueParams edge_block_parameters */ 34:
                    message.typeSpecificData = {
                        oneofKind: "edgeBlockParameters",
                        edgeBlockParameters: TachoValueParams.internalBinaryRead(reader, reader.uint32(), options, (message.typeSpecificData as any).edgeBlockParameters)
                    };
                    break;
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.TrajectoryParams trajectory_parameters */ 35:
                    message.typeSpecificData = {
                        oneofKind: "trajectoryParameters",
                        trajectoryParameters: DatasetThreeDimensionalMetaData_TrajectoryParams.internalBinaryRead(reader, reader.uint32(), options, (message.typeSpecificData as any).trajectoryParameters)
                    };
                    break;
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.UnknownParams unknown_parameters */ 36:
                    message.typeSpecificData = {
                        oneofKind: "unknownParameters",
                        unknownParameters: DatasetThreeDimensionalMetaData_UnknownParams.internalBinaryRead(reader, reader.uint32(), options, (message.typeSpecificData as any).unknownParameters)
                    };
                    break;
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.WaveletParams wavelet_parameters */ 37:
                    message.typeSpecificData = {
                        oneofKind: "waveletParameters",
                        waveletParameters: DatasetThreeDimensionalMetaData_WaveletParams.internalBinaryRead(reader, reader.uint32(), options, (message.typeSpecificData as any).waveletParameters)
                    };
                    break;
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.DetectorParams detector_parameters */ 38:
                    message.typeSpecificData = {
                        oneofKind: "detectorParameters",
                        detectorParameters: DatasetThreeDimensionalMetaData_DetectorParams.internalBinaryRead(reader, reader.uint32(), options, (message.typeSpecificData as any).detectorParameters)
                    };
                    break;
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.XLabelParams xlabel_parameters */ 39:
                    message.typeSpecificData = {
                        oneofKind: "xlabelParameters",
                        xlabelParameters: DatasetThreeDimensionalMetaData_XLabelParams.internalBinaryRead(reader, reader.uint32(), options, (message.typeSpecificData as any).xlabelParameters)
                    };
                    break;
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.ContourBaseParams contour_base_parameters */ 40:
                    message.typeSpecificData = {
                        oneofKind: "contourBaseParameters",
                        contourBaseParameters: DatasetThreeDimensionalMetaData_ContourBaseParams.internalBinaryRead(reader, reader.uint32(), options, (message.typeSpecificData as any).contourBaseParameters)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetThreeDimensionalMetaData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.Shape shape = 14; */
        if (message.shape !== 0)
            writer.tag(14, WireType.Varint).int32(message.shape);
        /* bool y_data_is_squared = 15; */
        if (message.yDataIsSquared !== false)
            writer.tag(15, WireType.Varint).bool(message.yDataIsSquared);
        /* uint32 quantity_x_id = 41; */
        if (message.quantityXId !== 0)
            writer.tag(41, WireType.Varint).uint32(message.quantityXId);
        /* uint32 quantity_y_id = 42; */
        if (message.quantityYId !== 0)
            writer.tag(42, WireType.Varint).uint32(message.quantityYId);
        /* uint32 quantity_z_id = 43; */
        if (message.quantityZId !== 0)
            writer.tag(43, WireType.Varint).uint32(message.quantityZId);
        /* BBMgRPCstreamingNS.DateTime measurement_start_time = 19; */
        if (message.measurementStartTime)
            DateTime.internalBinaryWrite(message.measurementStartTime, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* uint32 channel_id = 44; */
        if (message.channelId !== 0)
            writer.tag(44, WireType.Varint).uint32(message.channelId);
        /* BBMgRPCstreamingNS.DataType type = 20; */
        if (message.type !== 0)
            writer.tag(20, WireType.Varint).int32(message.type);
        /* BBMgRPCstreamingNS.Position position = 21; */
        if (message.position)
            Position.internalBinaryWrite(message.position, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.ReferencedInfo referenced_info = 22; */
        if (message.referencedInfo)
            ReferencedInfo.internalBinaryWrite(message.referencedInfo, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetXValues x_values = 23; */
        if (message.xValues)
            DatasetXValues.internalBinaryWrite(message.xValues, writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DoubleRange scale_range_y = 45; */
        if (message.scaleRangeY)
            DoubleRange.internalBinaryWrite(message.scaleRangeY, writer.tag(45, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DoubleRange scale_range_z = 24; */
        if (message.scaleRangeZ)
            DoubleRange.internalBinaryWrite(message.scaleRangeZ, writer.tag(24, WireType.LengthDelimited).fork(), options).join();
        /* uint64 num_potential_steps = 25; */
        if (message.numPotentialSteps !== "0")
            writer.tag(25, WireType.Varint).uint64(message.numPotentialSteps);
        /* BBMgRPCstreamingNS.AverageType avg_type = 26; */
        if (message.avgType !== 0)
            writer.tag(26, WireType.Varint).int32(message.avgType);
        /* double avg_value = 27; */
        if (message.avgValue !== 0)
            writer.tag(27, WireType.Bit64).double(message.avgValue);
        /* BBMgRPCstreamingNS.FrequenzWeight freq_weight = 28; */
        if (message.freqWeight !== 0)
            writer.tag(28, WireType.Varint).int32(message.freqWeight);
        /* BBMgRPCstreamingNS.DataInfo data_info = 29; */
        if (message.dataInfo)
            DataInfo.internalBinaryWrite(message.dataInfo, writer.tag(29, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters spectrum_parameters = 30; */
        if (message.typeSpecificData.oneofKind === "spectrumParameters")
            DatasetThreeDimensionalMetaData_SpectrumParameters.internalBinaryWrite(message.typeSpecificData.spectrumParameters, writer.tag(30, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams psycho_parameters = 31; */
        if (message.typeSpecificData.oneofKind === "psychoParameters")
            DatasetThreeDimensionalMetaData_PsychoParams.internalBinaryWrite(message.typeSpecificData.psychoParameters, writer.tag(31, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.CepstrumParams cepstrum_parameters = 32; */
        if (message.typeSpecificData.oneofKind === "cepstrumParameters")
            DatasetThreeDimensionalMetaData_CepstrumParams.internalBinaryWrite(message.typeSpecificData.cepstrumParameters, writer.tag(32, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.OctaveParams octave_parameters = 33; */
        if (message.typeSpecificData.oneofKind === "octaveParameters")
            DatasetThreeDimensionalMetaData_OctaveParams.internalBinaryWrite(message.typeSpecificData.octaveParameters, writer.tag(33, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.TachoValueParams edge_block_parameters = 34; */
        if (message.typeSpecificData.oneofKind === "edgeBlockParameters")
            TachoValueParams.internalBinaryWrite(message.typeSpecificData.edgeBlockParameters, writer.tag(34, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.TrajectoryParams trajectory_parameters = 35; */
        if (message.typeSpecificData.oneofKind === "trajectoryParameters")
            DatasetThreeDimensionalMetaData_TrajectoryParams.internalBinaryWrite(message.typeSpecificData.trajectoryParameters, writer.tag(35, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.UnknownParams unknown_parameters = 36; */
        if (message.typeSpecificData.oneofKind === "unknownParameters")
            DatasetThreeDimensionalMetaData_UnknownParams.internalBinaryWrite(message.typeSpecificData.unknownParameters, writer.tag(36, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.WaveletParams wavelet_parameters = 37; */
        if (message.typeSpecificData.oneofKind === "waveletParameters")
            DatasetThreeDimensionalMetaData_WaveletParams.internalBinaryWrite(message.typeSpecificData.waveletParameters, writer.tag(37, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.DetectorParams detector_parameters = 38; */
        if (message.typeSpecificData.oneofKind === "detectorParameters")
            DatasetThreeDimensionalMetaData_DetectorParams.internalBinaryWrite(message.typeSpecificData.detectorParameters, writer.tag(38, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.XLabelParams xlabel_parameters = 39; */
        if (message.typeSpecificData.oneofKind === "xlabelParameters")
            DatasetThreeDimensionalMetaData_XLabelParams.internalBinaryWrite(message.typeSpecificData.xlabelParameters, writer.tag(39, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.ContourBaseParams contour_base_parameters = 40; */
        if (message.typeSpecificData.oneofKind === "contourBaseParameters")
            DatasetThreeDimensionalMetaData_ContourBaseParams.internalBinaryWrite(message.typeSpecificData.contourBaseParameters, writer.tag(40, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData
 */
export const DatasetThreeDimensionalMetaData = new DatasetThreeDimensionalMetaData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetThreeDimensionalMetaData_SpectrumParameters$Type extends MessageType<DatasetThreeDimensionalMetaData_SpectrumParameters> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters", [
            { no: 1, name: "window_info", kind: "message", T: () => WindowInfo },
            { no: 2, name: "kalman", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "data_format", kind: "enum", T: () => ["BBMgRPCstreamingNS.DataFormat", DataFormat] },
            { no: 11, name: "cohen_params", kind: "message", oneof: "typeSpecificData", T: () => DatasetThreeDimensionalMetaData_SpectrumParameters_CohenParams },
            { no: 12, name: "mso_params", kind: "message", oneof: "typeSpecificData", T: () => DatasetThreeDimensionalMetaData_SpectrumParameters_MultipleSingleOrderParams }
        ]);
    }
    create(value?: PartialMessage<DatasetThreeDimensionalMetaData_SpectrumParameters>): DatasetThreeDimensionalMetaData_SpectrumParameters {
        const message = { kalman: false, dataFormat: 0, typeSpecificData: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetThreeDimensionalMetaData_SpectrumParameters>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetThreeDimensionalMetaData_SpectrumParameters): DatasetThreeDimensionalMetaData_SpectrumParameters {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.WindowInfo window_info */ 1:
                    message.windowInfo = WindowInfo.internalBinaryRead(reader, reader.uint32(), options, message.windowInfo);
                    break;
                case /* bool kalman */ 2:
                    message.kalman = reader.bool();
                    break;
                case /* BBMgRPCstreamingNS.DataFormat data_format */ 3:
                    message.dataFormat = reader.int32();
                    break;
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters.CohenParams cohen_params */ 11:
                    message.typeSpecificData = {
                        oneofKind: "cohenParams",
                        cohenParams: DatasetThreeDimensionalMetaData_SpectrumParameters_CohenParams.internalBinaryRead(reader, reader.uint32(), options, (message.typeSpecificData as any).cohenParams)
                    };
                    break;
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters.MultipleSingleOrderParams mso_params */ 12:
                    message.typeSpecificData = {
                        oneofKind: "msoParams",
                        msoParams: DatasetThreeDimensionalMetaData_SpectrumParameters_MultipleSingleOrderParams.internalBinaryRead(reader, reader.uint32(), options, (message.typeSpecificData as any).msoParams)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetThreeDimensionalMetaData_SpectrumParameters, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.WindowInfo window_info = 1; */
        if (message.windowInfo)
            WindowInfo.internalBinaryWrite(message.windowInfo, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool kalman = 2; */
        if (message.kalman !== false)
            writer.tag(2, WireType.Varint).bool(message.kalman);
        /* BBMgRPCstreamingNS.DataFormat data_format = 3; */
        if (message.dataFormat !== 0)
            writer.tag(3, WireType.Varint).int32(message.dataFormat);
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters.CohenParams cohen_params = 11; */
        if (message.typeSpecificData.oneofKind === "cohenParams")
            DatasetThreeDimensionalMetaData_SpectrumParameters_CohenParams.internalBinaryWrite(message.typeSpecificData.cohenParams, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters.MultipleSingleOrderParams mso_params = 12; */
        if (message.typeSpecificData.oneofKind === "msoParams")
            DatasetThreeDimensionalMetaData_SpectrumParameters_MultipleSingleOrderParams.internalBinaryWrite(message.typeSpecificData.msoParams, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters
 */
export const DatasetThreeDimensionalMetaData_SpectrumParameters = new DatasetThreeDimensionalMetaData_SpectrumParameters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetThreeDimensionalMetaData_SpectrumParameters_MultipleSingleOrderParams$Type extends MessageType<DatasetThreeDimensionalMetaData_SpectrumParameters_MultipleSingleOrderParams> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters.MultipleSingleOrderParams", [
            { no: 1, name: "band_width_values", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<DatasetThreeDimensionalMetaData_SpectrumParameters_MultipleSingleOrderParams>): DatasetThreeDimensionalMetaData_SpectrumParameters_MultipleSingleOrderParams {
        const message = { bandWidthValues: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetThreeDimensionalMetaData_SpectrumParameters_MultipleSingleOrderParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetThreeDimensionalMetaData_SpectrumParameters_MultipleSingleOrderParams): DatasetThreeDimensionalMetaData_SpectrumParameters_MultipleSingleOrderParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated double band_width_values */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.bandWidthValues.push(reader.double());
                    else
                        message.bandWidthValues.push(reader.double());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetThreeDimensionalMetaData_SpectrumParameters_MultipleSingleOrderParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated double band_width_values = 1; */
        if (message.bandWidthValues.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.bandWidthValues.length; i++)
                writer.double(message.bandWidthValues[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters.MultipleSingleOrderParams
 */
export const DatasetThreeDimensionalMetaData_SpectrumParameters_MultipleSingleOrderParams = new DatasetThreeDimensionalMetaData_SpectrumParameters_MultipleSingleOrderParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetThreeDimensionalMetaData_SpectrumParameters_CohenParams$Type extends MessageType<DatasetThreeDimensionalMetaData_SpectrumParameters_CohenParams> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters.CohenParams", [
            { no: 1, name: "cohen_class_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters.CohenParams.CohenClassType", DatasetThreeDimensionalMetaData_SpectrumParameters_CohenParams_CohenClassType] },
            { no: 2, name: "smooth_window_info", kind: "message", T: () => WindowInfo },
            { no: 3, name: "smooth_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<DatasetThreeDimensionalMetaData_SpectrumParameters_CohenParams>): DatasetThreeDimensionalMetaData_SpectrumParameters_CohenParams {
        const message = { cohenClassType: 0, smoothSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetThreeDimensionalMetaData_SpectrumParameters_CohenParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetThreeDimensionalMetaData_SpectrumParameters_CohenParams): DatasetThreeDimensionalMetaData_SpectrumParameters_CohenParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters.CohenParams.CohenClassType cohen_class_type */ 1:
                    message.cohenClassType = reader.int32();
                    break;
                case /* BBMgRPCstreamingNS.WindowInfo smooth_window_info */ 2:
                    message.smoothWindowInfo = WindowInfo.internalBinaryRead(reader, reader.uint32(), options, message.smoothWindowInfo);
                    break;
                case /* int32 smooth_size */ 3:
                    message.smoothSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetThreeDimensionalMetaData_SpectrumParameters_CohenParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters.CohenParams.CohenClassType cohen_class_type = 1; */
        if (message.cohenClassType !== 0)
            writer.tag(1, WireType.Varint).int32(message.cohenClassType);
        /* BBMgRPCstreamingNS.WindowInfo smooth_window_info = 2; */
        if (message.smoothWindowInfo)
            WindowInfo.internalBinaryWrite(message.smoothWindowInfo, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int32 smooth_size = 3; */
        if (message.smoothSize !== 0)
            writer.tag(3, WireType.Varint).int32(message.smoothSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.SpectrumParameters.CohenParams
 */
export const DatasetThreeDimensionalMetaData_SpectrumParameters_CohenParams = new DatasetThreeDimensionalMetaData_SpectrumParameters_CohenParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetThreeDimensionalMetaData_PsychoParams$Type extends MessageType<DatasetThreeDimensionalMetaData_PsychoParams> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams", [
            { no: 1, name: "psycho_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.PsychoType", DatasetThreeDimensionalMetaData_PsychoParams_PsychoType] },
            { no: 2, name: "psycho_core_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.PsychoCoreType", DatasetThreeDimensionalMetaData_PsychoParams_PsychoCoreType] },
            { no: 3, name: "sound_field", kind: "enum", T: () => ["BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.SoundField", DatasetThreeDimensionalMetaData_PsychoParams_SoundField] },
            { no: 4, name: "loudness_scale", kind: "enum", T: () => ["BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.LoudnessScale", DatasetThreeDimensionalMetaData_PsychoParams_LoudnessScale] },
            { no: 5, name: "sharpness_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.SharpnessType", DatasetThreeDimensionalMetaData_PsychoParams_SharpnessType] }
        ]);
    }
    create(value?: PartialMessage<DatasetThreeDimensionalMetaData_PsychoParams>): DatasetThreeDimensionalMetaData_PsychoParams {
        const message = { psychoType: 0, psychoCoreType: 0, soundField: 0, loudnessScale: 0, sharpnessType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetThreeDimensionalMetaData_PsychoParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetThreeDimensionalMetaData_PsychoParams): DatasetThreeDimensionalMetaData_PsychoParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.PsychoType psycho_type */ 1:
                    message.psychoType = reader.int32();
                    break;
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.PsychoCoreType psycho_core_type */ 2:
                    message.psychoCoreType = reader.int32();
                    break;
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.SoundField sound_field */ 3:
                    message.soundField = reader.int32();
                    break;
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.LoudnessScale loudness_scale */ 4:
                    message.loudnessScale = reader.int32();
                    break;
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.SharpnessType sharpness_type */ 5:
                    message.sharpnessType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetThreeDimensionalMetaData_PsychoParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.PsychoType psycho_type = 1; */
        if (message.psychoType !== 0)
            writer.tag(1, WireType.Varint).int32(message.psychoType);
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.PsychoCoreType psycho_core_type = 2; */
        if (message.psychoCoreType !== 0)
            writer.tag(2, WireType.Varint).int32(message.psychoCoreType);
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.SoundField sound_field = 3; */
        if (message.soundField !== 0)
            writer.tag(3, WireType.Varint).int32(message.soundField);
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.LoudnessScale loudness_scale = 4; */
        if (message.loudnessScale !== 0)
            writer.tag(4, WireType.Varint).int32(message.loudnessScale);
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams.SharpnessType sharpness_type = 5; */
        if (message.sharpnessType !== 0)
            writer.tag(5, WireType.Varint).int32(message.sharpnessType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PsychoParams
 */
export const DatasetThreeDimensionalMetaData_PsychoParams = new DatasetThreeDimensionalMetaData_PsychoParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetThreeDimensionalMetaData_CepstrumParams$Type extends MessageType<DatasetThreeDimensionalMetaData_CepstrumParams> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.CepstrumParams", [
            { no: 1, name: "cepstrum_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.CepstrumParams.CepstrumType", DatasetThreeDimensionalMetaData_CepstrumParams_CepstrumType] }
        ]);
    }
    create(value?: PartialMessage<DatasetThreeDimensionalMetaData_CepstrumParams>): DatasetThreeDimensionalMetaData_CepstrumParams {
        const message = { cepstrumType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetThreeDimensionalMetaData_CepstrumParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetThreeDimensionalMetaData_CepstrumParams): DatasetThreeDimensionalMetaData_CepstrumParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.CepstrumParams.CepstrumType cepstrum_type */ 1:
                    message.cepstrumType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetThreeDimensionalMetaData_CepstrumParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.CepstrumParams.CepstrumType cepstrum_type = 1; */
        if (message.cepstrumType !== 0)
            writer.tag(1, WireType.Varint).int32(message.cepstrumType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.CepstrumParams
 */
export const DatasetThreeDimensionalMetaData_CepstrumParams = new DatasetThreeDimensionalMetaData_CepstrumParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetThreeDimensionalMetaData_OctaveParams$Type extends MessageType<DatasetThreeDimensionalMetaData_OctaveParams> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.OctaveParams", [
            { no: 1, name: "octave_nth", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<DatasetThreeDimensionalMetaData_OctaveParams>): DatasetThreeDimensionalMetaData_OctaveParams {
        const message = { octaveNth: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetThreeDimensionalMetaData_OctaveParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetThreeDimensionalMetaData_OctaveParams): DatasetThreeDimensionalMetaData_OctaveParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 octave_nth */ 1:
                    message.octaveNth = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetThreeDimensionalMetaData_OctaveParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 octave_nth = 1; */
        if (message.octaveNth !== 0)
            writer.tag(1, WireType.Varint).uint32(message.octaveNth);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.OctaveParams
 */
export const DatasetThreeDimensionalMetaData_OctaveParams = new DatasetThreeDimensionalMetaData_OctaveParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetThreeDimensionalMetaData_PhaseInfo$Type extends MessageType<DatasetThreeDimensionalMetaData_PhaseInfo> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PhaseInfo", [
            { no: 1, name: "phase_from", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "phase_to", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<DatasetThreeDimensionalMetaData_PhaseInfo>): DatasetThreeDimensionalMetaData_PhaseInfo {
        const message = { phaseFrom: 0, phaseTo: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetThreeDimensionalMetaData_PhaseInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetThreeDimensionalMetaData_PhaseInfo): DatasetThreeDimensionalMetaData_PhaseInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double phase_from */ 1:
                    message.phaseFrom = reader.double();
                    break;
                case /* double phase_to */ 2:
                    message.phaseTo = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetThreeDimensionalMetaData_PhaseInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double phase_from = 1; */
        if (message.phaseFrom !== 0)
            writer.tag(1, WireType.Bit64).double(message.phaseFrom);
        /* double phase_to = 2; */
        if (message.phaseTo !== 0)
            writer.tag(2, WireType.Bit64).double(message.phaseTo);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PhaseInfo
 */
export const DatasetThreeDimensionalMetaData_PhaseInfo = new DatasetThreeDimensionalMetaData_PhaseInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetThreeDimensionalMetaData_TrajectoryParams$Type extends MessageType<DatasetThreeDimensionalMetaData_TrajectoryParams> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.TrajectoryParams", [
            { no: 6, name: "quantity_s_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "s_values", kind: "message", T: () => DatasetXValues },
            { no: 3, name: "base_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.DataType", DataType] },
            { no: 4, name: "base_shape", kind: "enum", T: () => ["BBMgRPCstreamingNS.Shape", Shape] },
            { no: 5, name: "represents_nyquist", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<DatasetThreeDimensionalMetaData_TrajectoryParams>): DatasetThreeDimensionalMetaData_TrajectoryParams {
        const message = { quantitySId: 0, baseType: 0, baseShape: 0, representsNyquist: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetThreeDimensionalMetaData_TrajectoryParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetThreeDimensionalMetaData_TrajectoryParams): DatasetThreeDimensionalMetaData_TrajectoryParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 quantity_s_id */ 6:
                    message.quantitySId = reader.uint32();
                    break;
                case /* BBMgRPCstreamingNS.DatasetXValues s_values */ 2:
                    message.sValues = DatasetXValues.internalBinaryRead(reader, reader.uint32(), options, message.sValues);
                    break;
                case /* BBMgRPCstreamingNS.DataType base_type */ 3:
                    message.baseType = reader.int32();
                    break;
                case /* BBMgRPCstreamingNS.Shape base_shape */ 4:
                    message.baseShape = reader.int32();
                    break;
                case /* bool represents_nyquist */ 5:
                    message.representsNyquist = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetThreeDimensionalMetaData_TrajectoryParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 quantity_s_id = 6; */
        if (message.quantitySId !== 0)
            writer.tag(6, WireType.Varint).uint32(message.quantitySId);
        /* BBMgRPCstreamingNS.DatasetXValues s_values = 2; */
        if (message.sValues)
            DatasetXValues.internalBinaryWrite(message.sValues, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DataType base_type = 3; */
        if (message.baseType !== 0)
            writer.tag(3, WireType.Varint).int32(message.baseType);
        /* BBMgRPCstreamingNS.Shape base_shape = 4; */
        if (message.baseShape !== 0)
            writer.tag(4, WireType.Varint).int32(message.baseShape);
        /* bool represents_nyquist = 5; */
        if (message.representsNyquist !== false)
            writer.tag(5, WireType.Varint).bool(message.representsNyquist);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.TrajectoryParams
 */
export const DatasetThreeDimensionalMetaData_TrajectoryParams = new DatasetThreeDimensionalMetaData_TrajectoryParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetThreeDimensionalMetaData_UnknownParams$Type extends MessageType<DatasetThreeDimensionalMetaData_UnknownParams> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.UnknownParams", [
            { no: 1, name: "data_format", kind: "enum", T: () => ["BBMgRPCstreamingNS.DataFormat", DataFormat] },
            { no: 2, name: "subtype", kind: "enum", T: () => ["BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.UnknownParams.Subtype", DatasetThreeDimensionalMetaData_UnknownParams_Subtype] },
            { no: 3, name: "window_info", kind: "message", T: () => WindowInfo },
            { no: 4, name: "phase_info", kind: "message", T: () => DatasetThreeDimensionalMetaData_PhaseInfo },
            { no: 5, name: "kalman", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "requires_peak", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<DatasetThreeDimensionalMetaData_UnknownParams>): DatasetThreeDimensionalMetaData_UnknownParams {
        const message = { dataFormat: 0, subtype: 0, kalman: false, requiresPeak: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetThreeDimensionalMetaData_UnknownParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetThreeDimensionalMetaData_UnknownParams): DatasetThreeDimensionalMetaData_UnknownParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.DataFormat data_format */ 1:
                    message.dataFormat = reader.int32();
                    break;
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.UnknownParams.Subtype subtype */ 2:
                    message.subtype = reader.int32();
                    break;
                case /* BBMgRPCstreamingNS.WindowInfo window_info */ 3:
                    message.windowInfo = WindowInfo.internalBinaryRead(reader, reader.uint32(), options, message.windowInfo);
                    break;
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PhaseInfo phase_info */ 4:
                    message.phaseInfo = DatasetThreeDimensionalMetaData_PhaseInfo.internalBinaryRead(reader, reader.uint32(), options, message.phaseInfo);
                    break;
                case /* bool kalman */ 5:
                    message.kalman = reader.bool();
                    break;
                case /* bool requires_peak */ 6:
                    message.requiresPeak = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetThreeDimensionalMetaData_UnknownParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.DataFormat data_format = 1; */
        if (message.dataFormat !== 0)
            writer.tag(1, WireType.Varint).int32(message.dataFormat);
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.UnknownParams.Subtype subtype = 2; */
        if (message.subtype !== 0)
            writer.tag(2, WireType.Varint).int32(message.subtype);
        /* BBMgRPCstreamingNS.WindowInfo window_info = 3; */
        if (message.windowInfo)
            WindowInfo.internalBinaryWrite(message.windowInfo, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PhaseInfo phase_info = 4; */
        if (message.phaseInfo)
            DatasetThreeDimensionalMetaData_PhaseInfo.internalBinaryWrite(message.phaseInfo, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bool kalman = 5; */
        if (message.kalman !== false)
            writer.tag(5, WireType.Varint).bool(message.kalman);
        /* bool requires_peak = 6; */
        if (message.requiresPeak !== false)
            writer.tag(6, WireType.Varint).bool(message.requiresPeak);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.UnknownParams
 */
export const DatasetThreeDimensionalMetaData_UnknownParams = new DatasetThreeDimensionalMetaData_UnknownParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetThreeDimensionalMetaData_WaveletParams$Type extends MessageType<DatasetThreeDimensionalMetaData_WaveletParams> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.WaveletParams", [
            { no: 1, name: "data_format", kind: "enum", T: () => ["BBMgRPCstreamingNS.DataFormat", DataFormat] },
            { no: 2, name: "wavelet_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.WaveletParams.WaveletType", DatasetThreeDimensionalMetaData_WaveletParams_WaveletType] },
            { no: 3, name: "wavelet_periods", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "phase_info", kind: "message", T: () => DatasetThreeDimensionalMetaData_PhaseInfo }
        ]);
    }
    create(value?: PartialMessage<DatasetThreeDimensionalMetaData_WaveletParams>): DatasetThreeDimensionalMetaData_WaveletParams {
        const message = { dataFormat: 0, waveletType: 0, waveletPeriods: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetThreeDimensionalMetaData_WaveletParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetThreeDimensionalMetaData_WaveletParams): DatasetThreeDimensionalMetaData_WaveletParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.DataFormat data_format */ 1:
                    message.dataFormat = reader.int32();
                    break;
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.WaveletParams.WaveletType wavelet_type */ 2:
                    message.waveletType = reader.int32();
                    break;
                case /* int32 wavelet_periods */ 3:
                    message.waveletPeriods = reader.int32();
                    break;
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PhaseInfo phase_info */ 4:
                    message.phaseInfo = DatasetThreeDimensionalMetaData_PhaseInfo.internalBinaryRead(reader, reader.uint32(), options, message.phaseInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetThreeDimensionalMetaData_WaveletParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.DataFormat data_format = 1; */
        if (message.dataFormat !== 0)
            writer.tag(1, WireType.Varint).int32(message.dataFormat);
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.WaveletParams.WaveletType wavelet_type = 2; */
        if (message.waveletType !== 0)
            writer.tag(2, WireType.Varint).int32(message.waveletType);
        /* int32 wavelet_periods = 3; */
        if (message.waveletPeriods !== 0)
            writer.tag(3, WireType.Varint).int32(message.waveletPeriods);
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.PhaseInfo phase_info = 4; */
        if (message.phaseInfo)
            DatasetThreeDimensionalMetaData_PhaseInfo.internalBinaryWrite(message.phaseInfo, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.WaveletParams
 */
export const DatasetThreeDimensionalMetaData_WaveletParams = new DatasetThreeDimensionalMetaData_WaveletParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetThreeDimensionalMetaData_DetectorParams$Type extends MessageType<DatasetThreeDimensionalMetaData_DetectorParams> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.DetectorParams", [
            { no: 1, name: "detector_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.DetectorParams.DetectorType", DatasetThreeDimensionalMetaData_DetectorParams_DetectorType] }
        ]);
    }
    create(value?: PartialMessage<DatasetThreeDimensionalMetaData_DetectorParams>): DatasetThreeDimensionalMetaData_DetectorParams {
        const message = { detectorType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetThreeDimensionalMetaData_DetectorParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetThreeDimensionalMetaData_DetectorParams): DatasetThreeDimensionalMetaData_DetectorParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.DetectorParams.DetectorType detector_type */ 1:
                    message.detectorType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetThreeDimensionalMetaData_DetectorParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.DetectorParams.DetectorType detector_type = 1; */
        if (message.detectorType !== 0)
            writer.tag(1, WireType.Varint).int32(message.detectorType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.DetectorParams
 */
export const DatasetThreeDimensionalMetaData_DetectorParams = new DatasetThreeDimensionalMetaData_DetectorParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetThreeDimensionalMetaData_XLabelParams$Type extends MessageType<DatasetThreeDimensionalMetaData_XLabelParams> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.XLabelParams", [
            { no: 1, name: "type_orig", kind: "enum", T: () => ["BBMgRPCstreamingNS.DataType", DataType] },
            { no: 2, name: "shape_orig", kind: "enum", T: () => ["BBMgRPCstreamingNS.Shape", Shape] }
        ]);
    }
    create(value?: PartialMessage<DatasetThreeDimensionalMetaData_XLabelParams>): DatasetThreeDimensionalMetaData_XLabelParams {
        const message = { typeOrig: 0, shapeOrig: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetThreeDimensionalMetaData_XLabelParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetThreeDimensionalMetaData_XLabelParams): DatasetThreeDimensionalMetaData_XLabelParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.DataType type_orig */ 1:
                    message.typeOrig = reader.int32();
                    break;
                case /* BBMgRPCstreamingNS.Shape shape_orig */ 2:
                    message.shapeOrig = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetThreeDimensionalMetaData_XLabelParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.DataType type_orig = 1; */
        if (message.typeOrig !== 0)
            writer.tag(1, WireType.Varint).int32(message.typeOrig);
        /* BBMgRPCstreamingNS.Shape shape_orig = 2; */
        if (message.shapeOrig !== 0)
            writer.tag(2, WireType.Varint).int32(message.shapeOrig);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.XLabelParams
 */
export const DatasetThreeDimensionalMetaData_XLabelParams = new DatasetThreeDimensionalMetaData_XLabelParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetThreeDimensionalMetaData_ContourBaseParams$Type extends MessageType<DatasetThreeDimensionalMetaData_ContourBaseParams> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.ContourBaseParams", [
            { no: 1, name: "dt_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "dt_orig", kind: "enum", T: () => ["BBMgRPCstreamingNS.DataType", DataType] },
            { no: 3, name: "shape_orig", kind: "enum", T: () => ["BBMgRPCstreamingNS.Shape", Shape] },
            { no: 6, name: "quantityX_orig_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "contour_parameters", kind: "message", T: () => DatasetThreeDimensionalMetaData_ContourBaseParams_ContourParams }
        ]);
    }
    create(value?: PartialMessage<DatasetThreeDimensionalMetaData_ContourBaseParams>): DatasetThreeDimensionalMetaData_ContourBaseParams {
        const message = { dtString: "", dtOrig: 0, shapeOrig: 0, quantityXOrigId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetThreeDimensionalMetaData_ContourBaseParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetThreeDimensionalMetaData_ContourBaseParams): DatasetThreeDimensionalMetaData_ContourBaseParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string dt_string */ 1:
                    message.dtString = reader.string();
                    break;
                case /* BBMgRPCstreamingNS.DataType dt_orig */ 2:
                    message.dtOrig = reader.int32();
                    break;
                case /* BBMgRPCstreamingNS.Shape shape_orig */ 3:
                    message.shapeOrig = reader.int32();
                    break;
                case /* uint32 quantityX_orig_id */ 6:
                    message.quantityXOrigId = reader.uint32();
                    break;
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.ContourBaseParams.ContourParams contour_parameters */ 5:
                    message.contourParameters = DatasetThreeDimensionalMetaData_ContourBaseParams_ContourParams.internalBinaryRead(reader, reader.uint32(), options, message.contourParameters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetThreeDimensionalMetaData_ContourBaseParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string dt_string = 1; */
        if (message.dtString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.dtString);
        /* BBMgRPCstreamingNS.DataType dt_orig = 2; */
        if (message.dtOrig !== 0)
            writer.tag(2, WireType.Varint).int32(message.dtOrig);
        /* BBMgRPCstreamingNS.Shape shape_orig = 3; */
        if (message.shapeOrig !== 0)
            writer.tag(3, WireType.Varint).int32(message.shapeOrig);
        /* uint32 quantityX_orig_id = 6; */
        if (message.quantityXOrigId !== 0)
            writer.tag(6, WireType.Varint).uint32(message.quantityXOrigId);
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.ContourBaseParams.ContourParams contour_parameters = 5; */
        if (message.contourParameters)
            DatasetThreeDimensionalMetaData_ContourBaseParams_ContourParams.internalBinaryWrite(message.contourParameters, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.ContourBaseParams
 */
export const DatasetThreeDimensionalMetaData_ContourBaseParams = new DatasetThreeDimensionalMetaData_ContourBaseParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetThreeDimensionalMetaData_ContourBaseParams_ContourParams$Type extends MessageType<DatasetThreeDimensionalMetaData_ContourBaseParams_ContourParams> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.ContourBaseParams.ContourParams", [
            { no: 1, name: "dt", kind: "enum", T: () => ["BBMgRPCstreamingNS.DataType", DataType] }
        ]);
    }
    create(value?: PartialMessage<DatasetThreeDimensionalMetaData_ContourBaseParams_ContourParams>): DatasetThreeDimensionalMetaData_ContourBaseParams_ContourParams {
        const message = { dt: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetThreeDimensionalMetaData_ContourBaseParams_ContourParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetThreeDimensionalMetaData_ContourBaseParams_ContourParams): DatasetThreeDimensionalMetaData_ContourBaseParams_ContourParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.DataType dt */ 1:
                    message.dt = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetThreeDimensionalMetaData_ContourBaseParams_ContourParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.DataType dt = 1; */
        if (message.dt !== 0)
            writer.tag(1, WireType.Varint).int32(message.dt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData.ContourBaseParams.ContourParams
 */
export const DatasetThreeDimensionalMetaData_ContourBaseParams_ContourParams = new DatasetThreeDimensionalMetaData_ContourBaseParams_ContourParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StandardValueTypeParams$Type extends MessageType<StandardValueTypeParams> {
    constructor() {
        super("BBMgRPCstreamingNS.StandardValueTypeParams", [
            { no: 1, name: "scale_calib", kind: "message", T: () => DatasetCalibration }
        ]);
    }
    create(value?: PartialMessage<StandardValueTypeParams>): StandardValueTypeParams {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StandardValueTypeParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StandardValueTypeParams): StandardValueTypeParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.DatasetCalibration scale_calib */ 1:
                    message.scaleCalib = DatasetCalibration.internalBinaryRead(reader, reader.uint32(), options, message.scaleCalib);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StandardValueTypeParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.DatasetCalibration scale_calib = 1; */
        if (message.scaleCalib)
            DatasetCalibration.internalBinaryWrite(message.scaleCalib, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.StandardValueTypeParams
 */
export const StandardValueTypeParams = new StandardValueTypeParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DigBusValueTypeParams$Type extends MessageType<DigBusValueTypeParams> {
    constructor() {
        super("BBMgRPCstreamingNS.DigBusValueTypeParams", [
            { no: 1, name: "dig_bus_message_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.DigBusValueTypeParams.DigBusMessageType", DigBusValueTypeParams_DigBusMessageType] }
        ]);
    }
    create(value?: PartialMessage<DigBusValueTypeParams>): DigBusValueTypeParams {
        const message = { digBusMessageType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DigBusValueTypeParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DigBusValueTypeParams): DigBusValueTypeParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.DigBusValueTypeParams.DigBusMessageType dig_bus_message_type */ 1:
                    message.digBusMessageType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DigBusValueTypeParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.DigBusValueTypeParams.DigBusMessageType dig_bus_message_type = 1; */
        if (message.digBusMessageType !== 0)
            writer.tag(1, WireType.Varint).int32(message.digBusMessageType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DigBusValueTypeParams
 */
export const DigBusValueTypeParams = new DigBusValueTypeParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TachoValueParams$Type extends MessageType<TachoValueParams> {
    constructor() {
        super("BBMgRPCstreamingNS.TachoValueParams", [
            { no: 2, name: "imp_per_period", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "hz_per_eu", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "tacho_base_frequency", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "num_potential_lines", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<TachoValueParams>): TachoValueParams {
        const message = { impPerPeriod: 0, hzPerEu: 0, tachoBaseFrequency: 0, numPotentialLines: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TachoValueParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TachoValueParams): TachoValueParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double imp_per_period */ 2:
                    message.impPerPeriod = reader.double();
                    break;
                case /* double hz_per_eu */ 3:
                    message.hzPerEu = reader.double();
                    break;
                case /* double tacho_base_frequency */ 4:
                    message.tachoBaseFrequency = reader.double();
                    break;
                case /* uint32 num_potential_lines */ 5:
                    message.numPotentialLines = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TachoValueParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double imp_per_period = 2; */
        if (message.impPerPeriod !== 0)
            writer.tag(2, WireType.Bit64).double(message.impPerPeriod);
        /* double hz_per_eu = 3; */
        if (message.hzPerEu !== 0)
            writer.tag(3, WireType.Bit64).double(message.hzPerEu);
        /* double tacho_base_frequency = 4; */
        if (message.tachoBaseFrequency !== 0)
            writer.tag(4, WireType.Bit64).double(message.tachoBaseFrequency);
        /* uint32 num_potential_lines = 5; */
        if (message.numPotentialLines !== 0)
            writer.tag(5, WireType.Varint).uint32(message.numPotentialLines);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.TachoValueParams
 */
export const TachoValueParams = new TachoValueParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GPSValueParams$Type extends MessageType<GPSValueParams> {
    constructor() {
        super("BBMgRPCstreamingNS.GPSValueParams", [
            { no: 1, name: "leap_seconds", kind: "scalar", T: 17 /*ScalarType.SINT32*/ }
        ]);
    }
    create(value?: PartialMessage<GPSValueParams>): GPSValueParams {
        const message = { leapSeconds: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GPSValueParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GPSValueParams): GPSValueParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* sint32 leap_seconds */ 1:
                    message.leapSeconds = reader.sint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GPSValueParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* sint32 leap_seconds = 1; */
        if (message.leapSeconds !== 0)
            writer.tag(1, WireType.Varint).sint32(message.leapSeconds);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.GPSValueParams
 */
export const GPSValueParams = new GPSValueParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetTwoDimensionalMetaData$Type extends MessageType<DatasetTwoDimensionalMetaData> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetTwoDimensionalMetaData", [
            { no: 10, name: "equi_time_scale", kind: "message", oneof: "scaleX", T: () => EquiTimeScale },
            { no: 11, name: "compressed_equi_time_scale", kind: "message", oneof: "scaleX", T: () => CompressedEquiTimeScale },
            { no: 12, name: "angle_vals", kind: "message", oneof: "scaleX", T: () => EquiDoubleValues },
            { no: 20, name: "standard_value_type_params", kind: "message", oneof: "scaleY", T: () => StandardValueTypeParams },
            { no: 21, name: "dig_bus_value_type_params", kind: "message", oneof: "scaleY", T: () => DigBusValueTypeParams },
            { no: 22, name: "tacho_value_params", kind: "message", oneof: "scaleY", T: () => TachoValueParams },
            { no: 23, name: "gps_value_params", kind: "message", oneof: "scaleY", T: () => GPSValueParams },
            { no: 28, name: "freq_weight", kind: "enum", T: () => ["BBMgRPCstreamingNS.FrequenzWeight", FrequenzWeight] },
            { no: 34, name: "npoints", kind: "scalar", T: 4 /*ScalarType.UINT64*/ },
            { no: 43, name: "quantity_x_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 44, name: "quantity_y_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 46, name: "scale_range_x", kind: "message", T: () => AbsoluteTimeRange },
            { no: 47, name: "scale_range_y", kind: "message", T: () => DoubleRange },
            { no: 48, name: "scale_range_z", kind: "message", T: () => DoubleRange },
            { no: 51, name: "measurement_start_time", kind: "message", T: () => DateTime },
            { no: 61, name: "type", kind: "enum", T: () => ["BBMgRPCstreamingNS.DataType", DataType] },
            { no: 75, name: "channel_id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 71, name: "position", kind: "message", T: () => Position },
            { no: 72, name: "referenced_info", kind: "message", T: () => ReferencedInfo },
            { no: 73, name: "avg_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.AverageType", AverageType] },
            { no: 74, name: "avg_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 81, name: "data_info", kind: "message", T: () => DataInfo }
        ]);
    }
    create(value?: PartialMessage<DatasetTwoDimensionalMetaData>): DatasetTwoDimensionalMetaData {
        const message = { scaleX: { oneofKind: undefined }, scaleY: { oneofKind: undefined }, freqWeight: 0, npoints: "0", quantityXId: 0, quantityYId: 0, type: 0, channelId: 0, avgType: 0, avgValue: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetTwoDimensionalMetaData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetTwoDimensionalMetaData): DatasetTwoDimensionalMetaData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.EquiTimeScale equi_time_scale */ 10:
                    message.scaleX = {
                        oneofKind: "equiTimeScale",
                        equiTimeScale: EquiTimeScale.internalBinaryRead(reader, reader.uint32(), options, (message.scaleX as any).equiTimeScale)
                    };
                    break;
                case /* BBMgRPCstreamingNS.CompressedEquiTimeScale compressed_equi_time_scale */ 11:
                    message.scaleX = {
                        oneofKind: "compressedEquiTimeScale",
                        compressedEquiTimeScale: CompressedEquiTimeScale.internalBinaryRead(reader, reader.uint32(), options, (message.scaleX as any).compressedEquiTimeScale)
                    };
                    break;
                case /* BBMgRPCstreamingNS.EquiDoubleValues angle_vals */ 12:
                    message.scaleX = {
                        oneofKind: "angleVals",
                        angleVals: EquiDoubleValues.internalBinaryRead(reader, reader.uint32(), options, (message.scaleX as any).angleVals)
                    };
                    break;
                case /* BBMgRPCstreamingNS.StandardValueTypeParams standard_value_type_params */ 20:
                    message.scaleY = {
                        oneofKind: "standardValueTypeParams",
                        standardValueTypeParams: StandardValueTypeParams.internalBinaryRead(reader, reader.uint32(), options, (message.scaleY as any).standardValueTypeParams)
                    };
                    break;
                case /* BBMgRPCstreamingNS.DigBusValueTypeParams dig_bus_value_type_params */ 21:
                    message.scaleY = {
                        oneofKind: "digBusValueTypeParams",
                        digBusValueTypeParams: DigBusValueTypeParams.internalBinaryRead(reader, reader.uint32(), options, (message.scaleY as any).digBusValueTypeParams)
                    };
                    break;
                case /* BBMgRPCstreamingNS.TachoValueParams tacho_value_params */ 22:
                    message.scaleY = {
                        oneofKind: "tachoValueParams",
                        tachoValueParams: TachoValueParams.internalBinaryRead(reader, reader.uint32(), options, (message.scaleY as any).tachoValueParams)
                    };
                    break;
                case /* BBMgRPCstreamingNS.GPSValueParams gps_value_params */ 23:
                    message.scaleY = {
                        oneofKind: "gpsValueParams",
                        gpsValueParams: GPSValueParams.internalBinaryRead(reader, reader.uint32(), options, (message.scaleY as any).gpsValueParams)
                    };
                    break;
                case /* BBMgRPCstreamingNS.FrequenzWeight freq_weight */ 28:
                    message.freqWeight = reader.int32();
                    break;
                case /* uint64 npoints */ 34:
                    message.npoints = reader.uint64().toString();
                    break;
                case /* uint32 quantity_x_id */ 43:
                    message.quantityXId = reader.uint32();
                    break;
                case /* uint32 quantity_y_id */ 44:
                    message.quantityYId = reader.uint32();
                    break;
                case /* BBMgRPCstreamingNS.AbsoluteTimeRange scale_range_x */ 46:
                    message.scaleRangeX = AbsoluteTimeRange.internalBinaryRead(reader, reader.uint32(), options, message.scaleRangeX);
                    break;
                case /* BBMgRPCstreamingNS.DoubleRange scale_range_y */ 47:
                    message.scaleRangeY = DoubleRange.internalBinaryRead(reader, reader.uint32(), options, message.scaleRangeY);
                    break;
                case /* BBMgRPCstreamingNS.DoubleRange scale_range_z */ 48:
                    message.scaleRangeZ = DoubleRange.internalBinaryRead(reader, reader.uint32(), options, message.scaleRangeZ);
                    break;
                case /* BBMgRPCstreamingNS.DateTime measurement_start_time */ 51:
                    message.measurementStartTime = DateTime.internalBinaryRead(reader, reader.uint32(), options, message.measurementStartTime);
                    break;
                case /* BBMgRPCstreamingNS.DataType type */ 61:
                    message.type = reader.int32();
                    break;
                case /* uint32 channel_id */ 75:
                    message.channelId = reader.uint32();
                    break;
                case /* BBMgRPCstreamingNS.Position position */ 71:
                    message.position = Position.internalBinaryRead(reader, reader.uint32(), options, message.position);
                    break;
                case /* BBMgRPCstreamingNS.ReferencedInfo referenced_info */ 72:
                    message.referencedInfo = ReferencedInfo.internalBinaryRead(reader, reader.uint32(), options, message.referencedInfo);
                    break;
                case /* BBMgRPCstreamingNS.AverageType avg_type */ 73:
                    message.avgType = reader.int32();
                    break;
                case /* double avg_value */ 74:
                    message.avgValue = reader.double();
                    break;
                case /* BBMgRPCstreamingNS.DataInfo data_info */ 81:
                    message.dataInfo = DataInfo.internalBinaryRead(reader, reader.uint32(), options, message.dataInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetTwoDimensionalMetaData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.EquiTimeScale equi_time_scale = 10; */
        if (message.scaleX.oneofKind === "equiTimeScale")
            EquiTimeScale.internalBinaryWrite(message.scaleX.equiTimeScale, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.CompressedEquiTimeScale compressed_equi_time_scale = 11; */
        if (message.scaleX.oneofKind === "compressedEquiTimeScale")
            CompressedEquiTimeScale.internalBinaryWrite(message.scaleX.compressedEquiTimeScale, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.EquiDoubleValues angle_vals = 12; */
        if (message.scaleX.oneofKind === "angleVals")
            EquiDoubleValues.internalBinaryWrite(message.scaleX.angleVals, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.StandardValueTypeParams standard_value_type_params = 20; */
        if (message.scaleY.oneofKind === "standardValueTypeParams")
            StandardValueTypeParams.internalBinaryWrite(message.scaleY.standardValueTypeParams, writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DigBusValueTypeParams dig_bus_value_type_params = 21; */
        if (message.scaleY.oneofKind === "digBusValueTypeParams")
            DigBusValueTypeParams.internalBinaryWrite(message.scaleY.digBusValueTypeParams, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.TachoValueParams tacho_value_params = 22; */
        if (message.scaleY.oneofKind === "tachoValueParams")
            TachoValueParams.internalBinaryWrite(message.scaleY.tachoValueParams, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.GPSValueParams gps_value_params = 23; */
        if (message.scaleY.oneofKind === "gpsValueParams")
            GPSValueParams.internalBinaryWrite(message.scaleY.gpsValueParams, writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.FrequenzWeight freq_weight = 28; */
        if (message.freqWeight !== 0)
            writer.tag(28, WireType.Varint).int32(message.freqWeight);
        /* uint64 npoints = 34; */
        if (message.npoints !== "0")
            writer.tag(34, WireType.Varint).uint64(message.npoints);
        /* uint32 quantity_x_id = 43; */
        if (message.quantityXId !== 0)
            writer.tag(43, WireType.Varint).uint32(message.quantityXId);
        /* uint32 quantity_y_id = 44; */
        if (message.quantityYId !== 0)
            writer.tag(44, WireType.Varint).uint32(message.quantityYId);
        /* BBMgRPCstreamingNS.AbsoluteTimeRange scale_range_x = 46; */
        if (message.scaleRangeX)
            AbsoluteTimeRange.internalBinaryWrite(message.scaleRangeX, writer.tag(46, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DoubleRange scale_range_y = 47; */
        if (message.scaleRangeY)
            DoubleRange.internalBinaryWrite(message.scaleRangeY, writer.tag(47, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DoubleRange scale_range_z = 48; */
        if (message.scaleRangeZ)
            DoubleRange.internalBinaryWrite(message.scaleRangeZ, writer.tag(48, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DateTime measurement_start_time = 51; */
        if (message.measurementStartTime)
            DateTime.internalBinaryWrite(message.measurementStartTime, writer.tag(51, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DataType type = 61; */
        if (message.type !== 0)
            writer.tag(61, WireType.Varint).int32(message.type);
        /* uint32 channel_id = 75; */
        if (message.channelId !== 0)
            writer.tag(75, WireType.Varint).uint32(message.channelId);
        /* BBMgRPCstreamingNS.Position position = 71; */
        if (message.position)
            Position.internalBinaryWrite(message.position, writer.tag(71, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.ReferencedInfo referenced_info = 72; */
        if (message.referencedInfo)
            ReferencedInfo.internalBinaryWrite(message.referencedInfo, writer.tag(72, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.AverageType avg_type = 73; */
        if (message.avgType !== 0)
            writer.tag(73, WireType.Varint).int32(message.avgType);
        /* double avg_value = 74; */
        if (message.avgValue !== 0)
            writer.tag(74, WireType.Bit64).double(message.avgValue);
        /* BBMgRPCstreamingNS.DataInfo data_info = 81; */
        if (message.dataInfo)
            DataInfo.internalBinaryWrite(message.dataInfo, writer.tag(81, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetTwoDimensionalMetaData
 */
export const DatasetTwoDimensionalMetaData = new DatasetTwoDimensionalMetaData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetMetaData$Type extends MessageType<DatasetMetaData> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetMetaData", [
            { no: 2, name: "ds3d_metadata", kind: "message", jsonName: "ds3dMetadata", oneof: "data", T: () => DatasetThreeDimensionalMetaData },
            { no: 3, name: "ds2d_metadata", kind: "message", jsonName: "ds2dMetadata", oneof: "data", T: () => DatasetTwoDimensionalMetaData }
        ]);
    }
    create(value?: PartialMessage<DatasetMetaData>): DatasetMetaData {
        const message = { data: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetMetaData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetMetaData): DatasetMetaData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData ds3d_metadata = 2 [json_name = "ds3dMetadata"];*/ 2:
                    message.data = {
                        oneofKind: "ds3DMetadata",
                        ds3DMetadata: DatasetThreeDimensionalMetaData.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).ds3DMetadata)
                    };
                    break;
                case /* BBMgRPCstreamingNS.DatasetTwoDimensionalMetaData ds2d_metadata = 3 [json_name = "ds2dMetadata"];*/ 3:
                    message.data = {
                        oneofKind: "ds2DMetadata",
                        ds2DMetadata: DatasetTwoDimensionalMetaData.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).ds2DMetadata)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetMetaData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalMetaData ds3d_metadata = 2 [json_name = "ds3dMetadata"]; */
        if (message.data.oneofKind === "ds3DMetadata")
            DatasetThreeDimensionalMetaData.internalBinaryWrite(message.data.ds3DMetadata, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetTwoDimensionalMetaData ds2d_metadata = 3 [json_name = "ds2dMetadata"]; */
        if (message.data.oneofKind === "ds2DMetadata")
            DatasetTwoDimensionalMetaData.internalBinaryWrite(message.data.ds2DMetadata, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetMetaData
 */
export const DatasetMetaData = new DatasetMetaData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetThreeDimensionalChunk$Type extends MessageType<DatasetThreeDimensionalChunk> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetThreeDimensionalChunk", [
            { no: 1, name: "step", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DatasetThreeDimensionalChunk_Step }
        ]);
    }
    create(value?: PartialMessage<DatasetThreeDimensionalChunk>): DatasetThreeDimensionalChunk {
        const message = { step: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetThreeDimensionalChunk>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetThreeDimensionalChunk): DatasetThreeDimensionalChunk {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BBMgRPCstreamingNS.DatasetThreeDimensionalChunk.Step step */ 1:
                    message.step.push(DatasetThreeDimensionalChunk_Step.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetThreeDimensionalChunk, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BBMgRPCstreamingNS.DatasetThreeDimensionalChunk.Step step = 1; */
        for (let i = 0; i < message.step.length; i++)
            DatasetThreeDimensionalChunk_Step.internalBinaryWrite(message.step[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalChunk
 */
export const DatasetThreeDimensionalChunk = new DatasetThreeDimensionalChunk$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetThreeDimensionalChunk_Step$Type extends MessageType<DatasetThreeDimensionalChunk_Step> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetThreeDimensionalChunk.Step", [
            { no: 1, name: "time", kind: "message", T: () => AbsoluteTime },
            { no: 2, name: "z_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "y_int32_values", kind: "message", oneof: "yValues", T: () => NonEquiInt32Values },
            { no: 4, name: "y_float_values", kind: "message", oneof: "yValues", T: () => NonEquiFloatValues },
            { no: 5, name: "y_double_values", kind: "message", oneof: "yValues", T: () => NonEquiDoubleValues },
            { no: 10, name: "value_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.ValueType", ValueType] }
        ]);
    }
    create(value?: PartialMessage<DatasetThreeDimensionalChunk_Step>): DatasetThreeDimensionalChunk_Step {
        const message = { zValue: 0, yValues: { oneofKind: undefined }, valueType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetThreeDimensionalChunk_Step>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetThreeDimensionalChunk_Step): DatasetThreeDimensionalChunk_Step {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.AbsoluteTime time */ 1:
                    message.time = AbsoluteTime.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                case /* double z_value */ 2:
                    message.zValue = reader.double();
                    break;
                case /* BBMgRPCstreamingNS.NonEquiInt32Values y_int32_values */ 3:
                    message.yValues = {
                        oneofKind: "yInt32Values",
                        yInt32Values: NonEquiInt32Values.internalBinaryRead(reader, reader.uint32(), options, (message.yValues as any).yInt32Values)
                    };
                    break;
                case /* BBMgRPCstreamingNS.NonEquiFloatValues y_float_values */ 4:
                    message.yValues = {
                        oneofKind: "yFloatValues",
                        yFloatValues: NonEquiFloatValues.internalBinaryRead(reader, reader.uint32(), options, (message.yValues as any).yFloatValues)
                    };
                    break;
                case /* BBMgRPCstreamingNS.NonEquiDoubleValues y_double_values */ 5:
                    message.yValues = {
                        oneofKind: "yDoubleValues",
                        yDoubleValues: NonEquiDoubleValues.internalBinaryRead(reader, reader.uint32(), options, (message.yValues as any).yDoubleValues)
                    };
                    break;
                case /* BBMgRPCstreamingNS.ValueType value_type */ 10:
                    message.valueType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetThreeDimensionalChunk_Step, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.AbsoluteTime time = 1; */
        if (message.time)
            AbsoluteTime.internalBinaryWrite(message.time, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* double z_value = 2; */
        if (message.zValue !== 0)
            writer.tag(2, WireType.Bit64).double(message.zValue);
        /* BBMgRPCstreamingNS.NonEquiInt32Values y_int32_values = 3; */
        if (message.yValues.oneofKind === "yInt32Values")
            NonEquiInt32Values.internalBinaryWrite(message.yValues.yInt32Values, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.NonEquiFloatValues y_float_values = 4; */
        if (message.yValues.oneofKind === "yFloatValues")
            NonEquiFloatValues.internalBinaryWrite(message.yValues.yFloatValues, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.NonEquiDoubleValues y_double_values = 5; */
        if (message.yValues.oneofKind === "yDoubleValues")
            NonEquiDoubleValues.internalBinaryWrite(message.yValues.yDoubleValues, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.ValueType value_type = 10; */
        if (message.valueType !== 0)
            writer.tag(10, WireType.Varint).int32(message.valueType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetThreeDimensionalChunk.Step
 */
export const DatasetThreeDimensionalChunk_Step = new DatasetThreeDimensionalChunk_Step$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetTwoDimensionalEquiTimeChunk$Type extends MessageType<DatasetTwoDimensionalEquiTimeChunk> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetTwoDimensionalEquiTimeChunk", [
            { no: 1, name: "data_block", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DatasetTwoDimensionalEquiTimeChunk_DataBlock }
        ]);
    }
    create(value?: PartialMessage<DatasetTwoDimensionalEquiTimeChunk>): DatasetTwoDimensionalEquiTimeChunk {
        const message = { dataBlock: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetTwoDimensionalEquiTimeChunk>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetTwoDimensionalEquiTimeChunk): DatasetTwoDimensionalEquiTimeChunk {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BBMgRPCstreamingNS.DatasetTwoDimensionalEquiTimeChunk.DataBlock data_block */ 1:
                    message.dataBlock.push(DatasetTwoDimensionalEquiTimeChunk_DataBlock.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetTwoDimensionalEquiTimeChunk, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BBMgRPCstreamingNS.DatasetTwoDimensionalEquiTimeChunk.DataBlock data_block = 1; */
        for (let i = 0; i < message.dataBlock.length; i++)
            DatasetTwoDimensionalEquiTimeChunk_DataBlock.internalBinaryWrite(message.dataBlock[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetTwoDimensionalEquiTimeChunk
 */
export const DatasetTwoDimensionalEquiTimeChunk = new DatasetTwoDimensionalEquiTimeChunk$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetTwoDimensionalEquiTimeChunk_DataBlock$Type extends MessageType<DatasetTwoDimensionalEquiTimeChunk_DataBlock> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetTwoDimensionalEquiTimeChunk.DataBlock", [
            { no: 10, name: "y_int32_values", kind: "message", oneof: "yValues", T: () => NonEquiInt32Values },
            { no: 11, name: "y_float_values", kind: "message", oneof: "yValues", T: () => NonEquiFloatValues },
            { no: 12, name: "y_double_values", kind: "message", oneof: "yValues", T: () => NonEquiDoubleValues },
            { no: 20, name: "value_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.ValueType", ValueType] }
        ]);
    }
    create(value?: PartialMessage<DatasetTwoDimensionalEquiTimeChunk_DataBlock>): DatasetTwoDimensionalEquiTimeChunk_DataBlock {
        const message = { yValues: { oneofKind: undefined }, valueType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetTwoDimensionalEquiTimeChunk_DataBlock>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetTwoDimensionalEquiTimeChunk_DataBlock): DatasetTwoDimensionalEquiTimeChunk_DataBlock {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.NonEquiInt32Values y_int32_values */ 10:
                    message.yValues = {
                        oneofKind: "yInt32Values",
                        yInt32Values: NonEquiInt32Values.internalBinaryRead(reader, reader.uint32(), options, (message.yValues as any).yInt32Values)
                    };
                    break;
                case /* BBMgRPCstreamingNS.NonEquiFloatValues y_float_values */ 11:
                    message.yValues = {
                        oneofKind: "yFloatValues",
                        yFloatValues: NonEquiFloatValues.internalBinaryRead(reader, reader.uint32(), options, (message.yValues as any).yFloatValues)
                    };
                    break;
                case /* BBMgRPCstreamingNS.NonEquiDoubleValues y_double_values */ 12:
                    message.yValues = {
                        oneofKind: "yDoubleValues",
                        yDoubleValues: NonEquiDoubleValues.internalBinaryRead(reader, reader.uint32(), options, (message.yValues as any).yDoubleValues)
                    };
                    break;
                case /* BBMgRPCstreamingNS.ValueType value_type */ 20:
                    message.valueType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetTwoDimensionalEquiTimeChunk_DataBlock, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.NonEquiInt32Values y_int32_values = 10; */
        if (message.yValues.oneofKind === "yInt32Values")
            NonEquiInt32Values.internalBinaryWrite(message.yValues.yInt32Values, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.NonEquiFloatValues y_float_values = 11; */
        if (message.yValues.oneofKind === "yFloatValues")
            NonEquiFloatValues.internalBinaryWrite(message.yValues.yFloatValues, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.NonEquiDoubleValues y_double_values = 12; */
        if (message.yValues.oneofKind === "yDoubleValues")
            NonEquiDoubleValues.internalBinaryWrite(message.yValues.yDoubleValues, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.ValueType value_type = 20; */
        if (message.valueType !== 0)
            writer.tag(20, WireType.Varint).int32(message.valueType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetTwoDimensionalEquiTimeChunk.DataBlock
 */
export const DatasetTwoDimensionalEquiTimeChunk_DataBlock = new DatasetTwoDimensionalEquiTimeChunk_DataBlock$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetTwoDimensionalNonEquiTimeChunk$Type extends MessageType<DatasetTwoDimensionalNonEquiTimeChunk> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetTwoDimensionalNonEquiTimeChunk", [
            { no: 1, name: "data_block", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DatasetTwoDimensionalNonEquiTimeChunk_DataBlock }
        ]);
    }
    create(value?: PartialMessage<DatasetTwoDimensionalNonEquiTimeChunk>): DatasetTwoDimensionalNonEquiTimeChunk {
        const message = { dataBlock: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetTwoDimensionalNonEquiTimeChunk>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetTwoDimensionalNonEquiTimeChunk): DatasetTwoDimensionalNonEquiTimeChunk {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BBMgRPCstreamingNS.DatasetTwoDimensionalNonEquiTimeChunk.DataBlock data_block */ 1:
                    message.dataBlock.push(DatasetTwoDimensionalNonEquiTimeChunk_DataBlock.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetTwoDimensionalNonEquiTimeChunk, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BBMgRPCstreamingNS.DatasetTwoDimensionalNonEquiTimeChunk.DataBlock data_block = 1; */
        for (let i = 0; i < message.dataBlock.length; i++)
            DatasetTwoDimensionalNonEquiTimeChunk_DataBlock.internalBinaryWrite(message.dataBlock[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetTwoDimensionalNonEquiTimeChunk
 */
export const DatasetTwoDimensionalNonEquiTimeChunk = new DatasetTwoDimensionalNonEquiTimeChunk$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetTwoDimensionalNonEquiTimeChunk_DataBlock$Type extends MessageType<DatasetTwoDimensionalNonEquiTimeChunk_DataBlock> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetTwoDimensionalNonEquiTimeChunk.DataBlock", [
            { no: 2, name: "empty_time_range", kind: "message", oneof: "xValues", T: () => AbsoluteTimeRange },
            { no: 3, name: "time_values", kind: "message", oneof: "xValues", T: () => AbsoluteTimeValues },
            { no: 10, name: "y_int32_values", kind: "message", oneof: "yValues", T: () => NonEquiInt32Values },
            { no: 11, name: "y_float_values", kind: "message", oneof: "yValues", T: () => NonEquiFloatValues },
            { no: 12, name: "y_double_values", kind: "message", oneof: "yValues", T: () => NonEquiDoubleValues },
            { no: 13, name: "y_can_messages", kind: "message", oneof: "yValues", T: () => CANMessages },
            { no: 14, name: "y_flexray_messages", kind: "message", oneof: "yValues", T: () => FlexRayMessages },
            { no: 15, name: "y_ethercat_messages", kind: "message", oneof: "yValues", T: () => EtherCatMessages },
            { no: 16, name: "y_irig_messages", kind: "message", oneof: "yValues", T: () => IRIGMessages },
            { no: 17, name: "y_ptp_messages", kind: "message", oneof: "yValues", T: () => PTPMessages },
            { no: 18, name: "y_gps_messages", kind: "message", oneof: "yValues", T: () => GPSMessages },
            { no: 20, name: "value_type", kind: "enum", T: () => ["BBMgRPCstreamingNS.ValueType", ValueType] }
        ]);
    }
    create(value?: PartialMessage<DatasetTwoDimensionalNonEquiTimeChunk_DataBlock>): DatasetTwoDimensionalNonEquiTimeChunk_DataBlock {
        const message = { xValues: { oneofKind: undefined }, yValues: { oneofKind: undefined }, valueType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetTwoDimensionalNonEquiTimeChunk_DataBlock>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetTwoDimensionalNonEquiTimeChunk_DataBlock): DatasetTwoDimensionalNonEquiTimeChunk_DataBlock {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.AbsoluteTimeRange empty_time_range */ 2:
                    message.xValues = {
                        oneofKind: "emptyTimeRange",
                        emptyTimeRange: AbsoluteTimeRange.internalBinaryRead(reader, reader.uint32(), options, (message.xValues as any).emptyTimeRange)
                    };
                    break;
                case /* BBMgRPCstreamingNS.AbsoluteTimeValues time_values */ 3:
                    message.xValues = {
                        oneofKind: "timeValues",
                        timeValues: AbsoluteTimeValues.internalBinaryRead(reader, reader.uint32(), options, (message.xValues as any).timeValues)
                    };
                    break;
                case /* BBMgRPCstreamingNS.NonEquiInt32Values y_int32_values */ 10:
                    message.yValues = {
                        oneofKind: "yInt32Values",
                        yInt32Values: NonEquiInt32Values.internalBinaryRead(reader, reader.uint32(), options, (message.yValues as any).yInt32Values)
                    };
                    break;
                case /* BBMgRPCstreamingNS.NonEquiFloatValues y_float_values */ 11:
                    message.yValues = {
                        oneofKind: "yFloatValues",
                        yFloatValues: NonEquiFloatValues.internalBinaryRead(reader, reader.uint32(), options, (message.yValues as any).yFloatValues)
                    };
                    break;
                case /* BBMgRPCstreamingNS.NonEquiDoubleValues y_double_values */ 12:
                    message.yValues = {
                        oneofKind: "yDoubleValues",
                        yDoubleValues: NonEquiDoubleValues.internalBinaryRead(reader, reader.uint32(), options, (message.yValues as any).yDoubleValues)
                    };
                    break;
                case /* BBMgRPCstreamingNS.CANMessages y_can_messages */ 13:
                    message.yValues = {
                        oneofKind: "yCanMessages",
                        yCanMessages: CANMessages.internalBinaryRead(reader, reader.uint32(), options, (message.yValues as any).yCanMessages)
                    };
                    break;
                case /* BBMgRPCstreamingNS.FlexRayMessages y_flexray_messages */ 14:
                    message.yValues = {
                        oneofKind: "yFlexrayMessages",
                        yFlexrayMessages: FlexRayMessages.internalBinaryRead(reader, reader.uint32(), options, (message.yValues as any).yFlexrayMessages)
                    };
                    break;
                case /* BBMgRPCstreamingNS.EtherCatMessages y_ethercat_messages */ 15:
                    message.yValues = {
                        oneofKind: "yEthercatMessages",
                        yEthercatMessages: EtherCatMessages.internalBinaryRead(reader, reader.uint32(), options, (message.yValues as any).yEthercatMessages)
                    };
                    break;
                case /* BBMgRPCstreamingNS.IRIGMessages y_irig_messages */ 16:
                    message.yValues = {
                        oneofKind: "yIrigMessages",
                        yIrigMessages: IRIGMessages.internalBinaryRead(reader, reader.uint32(), options, (message.yValues as any).yIrigMessages)
                    };
                    break;
                case /* BBMgRPCstreamingNS.PTPMessages y_ptp_messages */ 17:
                    message.yValues = {
                        oneofKind: "yPtpMessages",
                        yPtpMessages: PTPMessages.internalBinaryRead(reader, reader.uint32(), options, (message.yValues as any).yPtpMessages)
                    };
                    break;
                case /* BBMgRPCstreamingNS.GPSMessages y_gps_messages */ 18:
                    message.yValues = {
                        oneofKind: "yGpsMessages",
                        yGpsMessages: GPSMessages.internalBinaryRead(reader, reader.uint32(), options, (message.yValues as any).yGpsMessages)
                    };
                    break;
                case /* BBMgRPCstreamingNS.ValueType value_type */ 20:
                    message.valueType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetTwoDimensionalNonEquiTimeChunk_DataBlock, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.AbsoluteTimeRange empty_time_range = 2; */
        if (message.xValues.oneofKind === "emptyTimeRange")
            AbsoluteTimeRange.internalBinaryWrite(message.xValues.emptyTimeRange, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.AbsoluteTimeValues time_values = 3; */
        if (message.xValues.oneofKind === "timeValues")
            AbsoluteTimeValues.internalBinaryWrite(message.xValues.timeValues, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.NonEquiInt32Values y_int32_values = 10; */
        if (message.yValues.oneofKind === "yInt32Values")
            NonEquiInt32Values.internalBinaryWrite(message.yValues.yInt32Values, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.NonEquiFloatValues y_float_values = 11; */
        if (message.yValues.oneofKind === "yFloatValues")
            NonEquiFloatValues.internalBinaryWrite(message.yValues.yFloatValues, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.NonEquiDoubleValues y_double_values = 12; */
        if (message.yValues.oneofKind === "yDoubleValues")
            NonEquiDoubleValues.internalBinaryWrite(message.yValues.yDoubleValues, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.CANMessages y_can_messages = 13; */
        if (message.yValues.oneofKind === "yCanMessages")
            CANMessages.internalBinaryWrite(message.yValues.yCanMessages, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.FlexRayMessages y_flexray_messages = 14; */
        if (message.yValues.oneofKind === "yFlexrayMessages")
            FlexRayMessages.internalBinaryWrite(message.yValues.yFlexrayMessages, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.EtherCatMessages y_ethercat_messages = 15; */
        if (message.yValues.oneofKind === "yEthercatMessages")
            EtherCatMessages.internalBinaryWrite(message.yValues.yEthercatMessages, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.IRIGMessages y_irig_messages = 16; */
        if (message.yValues.oneofKind === "yIrigMessages")
            IRIGMessages.internalBinaryWrite(message.yValues.yIrigMessages, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.PTPMessages y_ptp_messages = 17; */
        if (message.yValues.oneofKind === "yPtpMessages")
            PTPMessages.internalBinaryWrite(message.yValues.yPtpMessages, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.GPSMessages y_gps_messages = 18; */
        if (message.yValues.oneofKind === "yGpsMessages")
            GPSMessages.internalBinaryWrite(message.yValues.yGpsMessages, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.ValueType value_type = 20; */
        if (message.valueType !== 0)
            writer.tag(20, WireType.Varint).int32(message.valueType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetTwoDimensionalNonEquiTimeChunk.DataBlock
 */
export const DatasetTwoDimensionalNonEquiTimeChunk_DataBlock = new DatasetTwoDimensionalNonEquiTimeChunk_DataBlock$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DatasetChunk$Type extends MessageType<DatasetChunk> {
    constructor() {
        super("BBMgRPCstreamingNS.DatasetChunk", [
            { no: 2, name: "ds3d", kind: "message", jsonName: "ds3d", oneof: "data", T: () => DatasetThreeDimensionalChunk },
            { no: 3, name: "ds2d_equi_time", kind: "message", jsonName: "ds2dEquiTime", oneof: "data", T: () => DatasetTwoDimensionalEquiTimeChunk },
            { no: 5, name: "ds2d_non_equi_time", kind: "message", jsonName: "ds2dNonEquiTime", oneof: "data", T: () => DatasetTwoDimensionalNonEquiTimeChunk },
            { no: 4, name: "dataset_changes", kind: "message", oneof: "data", T: () => DatasetChangesChunk }
        ]);
    }
    create(value?: PartialMessage<DatasetChunk>): DatasetChunk {
        const message = { data: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DatasetChunk>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DatasetChunk): DatasetChunk {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BBMgRPCstreamingNS.DatasetThreeDimensionalChunk ds3d = 2 [json_name = "ds3d"];*/ 2:
                    message.data = {
                        oneofKind: "ds3D",
                        ds3D: DatasetThreeDimensionalChunk.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).ds3D)
                    };
                    break;
                case /* BBMgRPCstreamingNS.DatasetTwoDimensionalEquiTimeChunk ds2d_equi_time = 3 [json_name = "ds2dEquiTime"];*/ 3:
                    message.data = {
                        oneofKind: "ds2DEquiTime",
                        ds2DEquiTime: DatasetTwoDimensionalEquiTimeChunk.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).ds2DEquiTime)
                    };
                    break;
                case /* BBMgRPCstreamingNS.DatasetTwoDimensionalNonEquiTimeChunk ds2d_non_equi_time = 5 [json_name = "ds2dNonEquiTime"];*/ 5:
                    message.data = {
                        oneofKind: "ds2DNonEquiTime",
                        ds2DNonEquiTime: DatasetTwoDimensionalNonEquiTimeChunk.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).ds2DNonEquiTime)
                    };
                    break;
                case /* BBMgRPCstreamingNS.DatasetChangesChunk dataset_changes */ 4:
                    message.data = {
                        oneofKind: "datasetChanges",
                        datasetChanges: DatasetChangesChunk.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).datasetChanges)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DatasetChunk, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BBMgRPCstreamingNS.DatasetThreeDimensionalChunk ds3d = 2 [json_name = "ds3d"]; */
        if (message.data.oneofKind === "ds3D")
            DatasetThreeDimensionalChunk.internalBinaryWrite(message.data.ds3D, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetTwoDimensionalEquiTimeChunk ds2d_equi_time = 3 [json_name = "ds2dEquiTime"]; */
        if (message.data.oneofKind === "ds2DEquiTime")
            DatasetTwoDimensionalEquiTimeChunk.internalBinaryWrite(message.data.ds2DEquiTime, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetTwoDimensionalNonEquiTimeChunk ds2d_non_equi_time = 5 [json_name = "ds2dNonEquiTime"]; */
        if (message.data.oneofKind === "ds2DNonEquiTime")
            DatasetTwoDimensionalNonEquiTimeChunk.internalBinaryWrite(message.data.ds2DNonEquiTime, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* BBMgRPCstreamingNS.DatasetChangesChunk dataset_changes = 4; */
        if (message.data.oneofKind === "datasetChanges")
            DatasetChangesChunk.internalBinaryWrite(message.data.datasetChanges, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BBMgRPCstreamingNS.DatasetChunk
 */
export const DatasetChunk = new DatasetChunk$Type();
