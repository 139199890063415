export interface AppErrorPayload {
  message: string;
  translationKey?: string;
  translationParameter?: object;
}

export enum CloudAppNames {
  depot,
  processing,
  plotting,
  depot_management,
  user_management,
  roles,
  depot_access_right,
  ods_order,
  device,
  tags,
  localization
}

export interface Client {
  id: string;
  exists: boolean;
  name: string;
}

export type CloudDepotType = 'PAKDepot' | 'ATFXDepot';

export interface CloudDepot {
  id: string;
  name?: string;
  label?: string;
  depotType: CloudDepotType;
  browseURL: string;
}

export interface AppConfig {
  baseHost: string;
  useBasicAuth: boolean;
  basicAuthCredentials?: {
    user: string;
    pw: string;
  };
}
