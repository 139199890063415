import { Component, OnInit } from '@angular/core';
import { AppFacade } from '../../+state/app.facade';
import { Observable } from 'rxjs';
import { availableFeatureFlags } from '../../app.feature-flags';
import { filter, map } from 'rxjs/operators';
import {
  FeatureFlagsFacade,
  ConcreteFeatureFlag,
  FeatureFlag,
  assembleValidatorsForFlag,
  validateFeatureFlagValue
} from '@vas/feature-flags';
import { CommonFacade } from '@vas/common';
@Component({
  selector: 'cloud-debug-header',
  templateUrl: './debug-header.component.html',
  styleUrls: ['./debug-header.component.css']
})
export class DebugHeaderComponent implements OnInit {
  activeFeatureFlags$: Observable<Partial<Record<string, ConcreteFeatureFlag>>>;
  remainingFeatureFlags$: Observable<FeatureFlag[]>;

  flagsWithInvalidValues: string[] = [];

  constructor(
    public appFacade: AppFacade,
    public commonFacade: CommonFacade,
    public featureFlagFacade: FeatureFlagsFacade
  ) {}

  ngOnInit() {
    this.activeFeatureFlags$ = this.featureFlagFacade.featureFlags$;
    this.remainingFeatureFlags$ = this.activeFeatureFlags$.pipe(
      map((flags) => {
        const entries = Object.values(flags).map((entry) => entry?.id);
        return availableFeatureFlags.filter((ff) => !entries.includes(ff.id));
      })
    );
  }

  handleFlagClicked(event: Event, flag: ConcreteFeatureFlag) {
    this.featureFlagFacade.setFeatureFlag({
      flagName: flag.id,
      value: !flag.value,
      precedence: 'runtime'
    });
  }

  addFlag(flag: FeatureFlag) {
    this.featureFlagFacade.setFeatureFlag({
      flagName: flag.id,
      precedence: 'runtime',
      value: flag.defaultValue
    });
  }

  inputValueChanged(event: KeyboardEvent, flag: ConcreteFeatureFlag) {
    const flagValue = (event.target as HTMLInputElement).value;
    const validators = assembleValidatorsForFlag(flag);
    const isValid = validateFeatureFlagValue(flag, flagValue, validators);

    if (isValid) {
      if (this.isInvalidValue(flag)) {
        this.flagsWithInvalidValues = this.flagsWithInvalidValues.filter((invalidFlag) => invalidFlag !== flag.id);
      }
      this.featureFlagFacade.setFeatureFlag({
        flagName: flag.id,
        value: flagValue,
        precedence: 'runtime'
      });
    } else {
      if (!this.isInvalidValue(flag)) {
        this.flagsWithInvalidValues.push(flag.id);
      }
    }
  }

  persistAsCookie(flag: ConcreteFeatureFlag) {
    this.featureFlagFacade.persistFeatureFlagAsCookie({
      flagName: flag.id,
      value: flag.value
    });
  }

  clearCookie(flag: ConcreteFeatureFlag) {
    this.featureFlagFacade.clearFeatureFlagCookie({
      flagName: flag.id
    });
  }

  trackByFn(index, _) {
    return index;
  }

  isInvalidValue(flag: ConcreteFeatureFlag): boolean {
    return this.flagsWithInvalidValues.includes(flag.id);
  }
}
