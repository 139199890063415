import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { UserDetailsComponent } from './users-list/user-details/user-details.component';

import { GroupsEffects } from './+state/groups/groups.effects';
import { GroupsFacade } from './+state/groups/groups.facade';
import {
  GROUPS_FEATURE_KEY,
  initialState as groupsInitialState,
  reducer as groupsReducer
} from './+state/groups/groups.reducer';
import { LicensesEffects } from './+state/licenses/licenses.effects';
import { LicensesFacade } from './+state/licenses/licenses.facade';
import {
  LICENSES_FEATURE_KEY,
  initialState as licensesInitialState,
  reducer as licensesReducer
} from './+state/licenses/licenses.reducer';
import { RolesEffects } from './+state/roles/roles.effects';
import { RolesFacade } from './+state/roles/roles.facade';
import {
  ROLES_FEATURE_KEY,
  initialState as rolesInitialState,
  reducer as rolesReducer
} from './+state/roles/roles.reducer';
import { UsersEffects } from './+state/users/users.effects';
import { UsersFacade } from './+state/users/users.facade';
import {
  USERS_FEATURE_KEY,
  initialState as usersInitialState,
  reducer as usersReducer
} from './+state/users/users.reducer';

import { SharedModule } from '../../../shared/shared.module';
import { LicenseRoutingModule } from './license.routing.module';

import { translateLoader } from '../../../app.translate';
import { SearchBarHighlightFilterPipe } from '../../../depot-search/autocomplete-dropdown/pipes/search-bar-highlight.pipe';
import { GroupsListComponent } from './groups-list/groups-list.component';
import { LicenseComponent } from './license.component';
import { LicensesListComponent } from './licenses-list/licenses-list.component';
import { NamesFilterPipe } from './pipes/names-filter.pipe';
import { UsersFilterPipe } from './pipes/users-filter.pipe';
import { RolesListComponent } from './roles-list/roles-list.component';
import { UsersListComponent } from './users-list/users-list.component';
import { LicenseIconComponent } from './license-icon/license-icon.component';

@NgModule({
  imports: [
    CommonModule,
    ClarityModule,
    LicenseRoutingModule,
    SharedModule,
    TranslateModule.forChild(translateLoader),
    StoreModule.forFeature(USERS_FEATURE_KEY, usersReducer, { initialState: usersInitialState }),
    EffectsModule.forFeature([UsersEffects]),
    StoreModule.forFeature(GROUPS_FEATURE_KEY, groupsReducer, { initialState: groupsInitialState }),
    EffectsModule.forFeature([GroupsEffects]),
    StoreModule.forFeature(ROLES_FEATURE_KEY, rolesReducer, { initialState: rolesInitialState }),
    EffectsModule.forFeature([RolesEffects]),
    StoreModule.forFeature(LICENSES_FEATURE_KEY, licensesReducer, { initialState: licensesInitialState }),
    EffectsModule.forFeature([LicensesEffects]),
    SearchBarHighlightFilterPipe,
    FormsModule
  ],
  declarations: [
    LicenseComponent,
    UsersFilterPipe,
    UsersListComponent,
    GroupsListComponent,
    RolesListComponent,
    NamesFilterPipe,
    LicensesListComponent,
    UserDetailsComponent,
    LicenseIconComponent
  ],
  providers: [UsersFacade, GroupsFacade, RolesFacade, LicensesFacade]
})
export class LicenseModule {}
