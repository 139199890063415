import { createAction, createActionGroup, props } from '@ngrx/store';
import { UserInfo } from '../user.type';
import { MyLicenseInfo } from '../../admin/admin/license/types/license.types';
import { RoleInfo } from '../../admin/admin/license/types/role.types';

export enum UserActionTypes {
  LoadUserSelf = '[User] Load UserSelf',
  UserLoaded = '[User] User Loaded',
  LoadUsersSelfCanSee = '[User] Get Users Self Can See',
  UsersSelfCanSeeLoaded = '[User] Users Self Can See Loaded',
  LoadRolesSelfCanSee = '[User] Get Roles Self Can See',
  RolesSelfCanSeeLoaded = '[User] Roles Self Can See Loaded',
  AgentIdentified = '[User] User Agent identified',
  LoadMyLicenses = '[User] User Load My Licenses',
  MyLicensesLoaded = '[User] User My Licenses Loaded'
}

export const LoadUserSelf = createAction(UserActionTypes.LoadUserSelf);
export const UserLoaded = createAction(UserActionTypes.UserLoaded, props<{ user: UserInfo }>());
export const LoadUsersSelfCanSee = createAction(UserActionTypes.LoadUsersSelfCanSee);
export const UsersSelfCanSeeLoaded = createAction(
  UserActionTypes.UsersSelfCanSeeLoaded,
  props<{ users: Partial<UserInfo>[] }>()
);
export const LoadRolesSelfCanSee = createAction(UserActionTypes.LoadRolesSelfCanSee);
export const RolesSelfCanSeeLoaded = createAction(
  UserActionTypes.RolesSelfCanSeeLoaded,
  props<{ roles: Partial<RoleInfo>[] }>()
);
export const AgentIdentified = createAction(UserActionTypes.AgentIdentified, props<{ os?: string }>());
export const LoadMyLicenses = createAction(UserActionTypes.LoadMyLicenses);
export const MyLicensesLoaded = createAction(
  UserActionTypes.MyLicensesLoaded,
  props<{ myLicenses: MyLicenseInfo[] }>()
);
