import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MeasurementTile, attributeDisplayType } from '../../measurements.types';
import { Observable } from 'rxjs';

@Component({
  selector: 'cloud-measurement-tile',
  templateUrl: './measurement-tile.component.html',
  styleUrls: ['./measurement-tile.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeasurementTileComponent implements MeasurementTile {
  public title = 'Default';
  public iconClass;
  public backgroundImage;
  public selected$: Observable<boolean>;
  public counter$: Observable<number>;
  public loading$: Observable<boolean>;
  public dynamicTitle$: Observable<string>;
  public attributeDisplayType$: Observable<attributeDisplayType>;
  constructor() {}
}
