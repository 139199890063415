<div class="list-container">
  <div class="list collection-list">
    <h4 class="dropdown-header">
      {{ 'CONTENTCOLLECTION.GENERAL.COLLECTIONS' | translate }}
    </h4>
    <div class="dropdown-divider" role="separator"></div>

    <ng-container *ngIf="activeCollection$ | async as activeCollection; else noCollections">
      <div class="content-collection-selection">
        <cloud-svg-icon fileName="box_with_checkmark"></cloud-svg-icon>

        <h4 class="content-collection-selection"> {{ 'CONTENTCOLLECTION.GENERAL.ACTIVECOLLECTION' | translate }}</h4>
      </div>
      <p
        class="list-item"
        [ngClass]="{
          'selected': activeCollection.guid === (selectedCCGuid$ | async),
          'has-share':
            activeCollection?.createdBy === (userFacade.self$ | async)?.id && activeCollection.shareInfo?.length > 0
        }"
        (click)="selectContentCollection(activeCollection.guid)"
      >
        <span data-cy="CCActive" class="truncate">
          <ng-container *ngIf="activeCollection?.createdBy === (userFacade.self$ | async)?.id">
            <cloud-svg-icon fileName="share" *ngIf="activeCollection.shareInfo?.length > 0"></cloud-svg-icon>
          </ng-container>
          {{ activeCollection.name }}
        </span>

        <span
          *ngIf="activeCollection.items.length < 1000"
          class="label"
          [attr.data-cy]="'CC_MEA_COUNT_' + activeCollection.name + '_' + activeCollection.items.length"
          >{{ activeCollection.items.length }}</span
        >
        <span *ngIf="activeCollection.items.length > 999" class="label">999+</span>
      </p>

      <div
        class="content-collection-selection"
        (click)="toggleMyCollections()"
        *ngIf="(contentCollectionFacade.contentCollections$ | async)?.length > 1"
      >
        <cloud-svg-icon fileName="my_collections"></cloud-svg-icon>

        <h4>{{ 'CONTENTCOLLECTION.GENERAL.MYCOLLECTIONS' | translate }}</h4>

        <cloud-svg-icon-customizable
          [fileName]="myCollectionsActive ? 'caret_up' : 'caret_down'"
          fill="#646A70"
          class="caret-icon"
          width="20px"
          height="25px"
        ></cloud-svg-icon-customizable>
      </div>

      <ng-container *ngIf="contentCollectionFacade.contentCollections$ | async as allCollections">
        <ng-container *ngIf="myCollectionsActive">
          <ul class="list-unstyled">
            <ng-container
              *ngFor="
                let collection of !!(featureFlagFacade.featureValue$('CONTENT_COLLECTION_SHARE') | async)
                  ? (ownContentCollections$ | async)
                  : allCollections
              "
            >
              <ng-container *ngIf="(activeCollectionGuid$ | async) !== collection.guid">
                <li
                  class="list-item collection-name"
                  [ngClass]="{
                    'selected': collection.guid === (this.selectedCCGuid$ | async),
                    'has-share': collection.shareInfo?.length > 0
                  }"
                  data-cy="CCBlock"
                  (click)="selectContentCollection(collection.guid)"
                >
                  <span [attr.data-cy]="'CC_' + collection.name" class="truncate" [title]="collection.name">
                    <cloud-svg-icon fileName="share" *ngIf="collection.shareInfo?.length > 0"></cloud-svg-icon>
                    {{ collection.name }}
                  </span>

                  <div class="right-side">
                    <cloud-svg-icon
                      fileName="play"
                      data-cy="CCActivate"
                      (click)="activateContentCollection(collection.guid, $event)"
                    ></cloud-svg-icon>
                    <span
                      *ngIf="collection.items.length < 1000"
                      class="label"
                      [attr.data-cy]="'CC_MEA_COUNT_' + collection.name + '_' + collection.items.length"
                      >{{ collection.items.length }}</span
                    >
                    <span *ngIf="collection.items.length > 999" class="label">999+</span>
                  </div>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </ng-container>
      </ng-container>

      <div
        class="content-collection-selection"
        (click)="toggleSharedCollections()"
        *ngIf="
          !!(featureFlagFacade.featureValue$('CONTENT_COLLECTION_SHARE') | async) &&
          (sharedContentCollections$ | async)?.length > 0
        "
      >
        <cloud-svg-icon fileName="shared_with_me"></cloud-svg-icon>

        <h4>{{ 'CONTENTCOLLECTION.SHARING.SHAREDWITHME' | translate }}</h4>

        <cloud-svg-icon-customizable
          [fileName]="sharedCollectionsActive ? 'caret_up' : 'caret_down'"
          fill="#646A70"
          class="caret-icon"
          width="20px"
          height="25px"
        ></cloud-svg-icon-customizable>
      </div>

      <ng-container
        *ngIf="!!(featureFlagFacade.featureValue$('CONTENT_COLLECTION_SHARE') | async) && sharedCollectionsActive"
      >
        <ng-container *ngIf="sharedContentCollections$ | async as collections">
          <ul class="list-unstyled">
            <ng-container *ngFor="let collection of collections">
              <ng-container *ngIf="(activeCollectionGuid$ | async) !== collection.guid">
                <li
                  class="list-item collection-name"
                  [ngClass]="{
                    selected: collection.guid === (this.selectedCCGuid$ | async)
                  }"
                  data-cy="CCBlock"
                  (click)="selectContentCollection(collection.guid)"
                >
                  <span [attr.data-cy]="'CC_' + collection.name" class="truncate" [title]="collection.name">
                    {{ collection.name }}
                  </span>

                  <div class="right-side">
                    <cloud-svg-icon
                      fileName="play"
                      data-cy="CCActivate"
                      (click)="activateContentCollection(collection.guid, $event)"
                    ></cloud-svg-icon>
                    <span
                      *ngIf="collection.items.length < 1000"
                      class="label"
                      [attr.data-cy]="'CC_MEA_COUNT_' + collection.name + '_' + collection.items.length"
                      >{{ collection.items.length }}</span
                    >
                    <span *ngIf="collection.items.length > 999" class="label">999+</span>
                  </div>
                </li>
              </ng-container>
            </ng-container>
          </ul></ng-container
        >
      </ng-container>
    </ng-container>

    <ng-template #noCollections>
      <div class="no-cc-available">
        <h4 class="content-collection-selection">
          <cloud-svg-icon fileName="box_with_checkmark_gray"></cloud-svg-icon>
          {{ 'CONTENTCOLLECTION.GENERAL.ACTIVECOLLECTION' | translate }}</h4
        >

        <p class="no-content-collections-info"
          >{{ 'CONTENTCOLLECTION.GENERAL.NOAVAILABLE.0' | translate }}<br />
          <a (click)="addNewCollectionClicked()">{{ 'CONTENTCOLLECTION.GENERAL.NOAVAILABLE.1' | translate }}</a>
        </p>
      </div>
    </ng-template>
  </div>

  <button class="btn btn-sm add-new-btn" (click)="addNewCollectionClicked()" data-cy="createCCButton"
    >{{ 'CONTENTCOLLECTION.GENERAL.CREATENEW' | translate }}
    <cloud-svg-icon-customizable fill="#0072A3" fileName="add_cc"></cloud-svg-icon-customizable
  ></button>
</div>
