// common
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';

// NGRX

import { SharedModule } from '../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

// VAS
import { translateLoader } from '../app.translate';
import { RouterErrorComponent } from './router-error/router-error.component';
import { RouterInfoComponent } from './router-info/router-info.component';
import { RouterFacade } from './+state/router.facade';
import {
  CLOUDROUTER_FEATURE_KEY,
  reducer as cloudRouterReducer,
  initialState as cloudRouterInitialState
} from './+state/router.reducer';
import { RouterTitleEffects } from './+state/router.title.effects';
import { EffectsModule } from '@ngrx/effects';

export const imports: any[] = [
  CommonModule,
  StoreModule.forFeature(CLOUDROUTER_FEATURE_KEY, cloudRouterReducer, {
    initialState: cloudRouterInitialState
  }),
  EffectsModule.forFeature([RouterTitleEffects]),
  TranslateModule.forChild(translateLoader),
  SharedModule
];

export const declarations: any[] = [RouterErrorComponent, RouterInfoComponent];

@NgModule({
  declarations: declarations,
  imports: [FormsModule, imports, ClarityModule],
  exports: [RouterErrorComponent, RouterInfoComponent],
  providers: [RouterFacade]
  // entryComponents: [ProcessingComponent]
})
export class CloudRouterModule {}
