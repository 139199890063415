import { StreamingChart } from '../../utils/streaming-chart.types';
import { pakColorPalette } from '../../utils/colorPalettes';
import { LUT } from '@arction/lcjs';
import { AxisScale } from '../../utils/former-streaming-messages.types';

export function calcColorPaletteII(targetType: AxisScale['scale'], intensityMin: number, intensityMax: number) {
  const palette = pakColorPalette(1.0);

  switch (targetType) {
    case 'lin':
    case 'db': {
      const deltaLin = intensityMax - intensityMin;
      const stepLin = deltaLin / palette.steps.length;
      const steps = palette.steps.map((step, index) => {
        const val = index * stepLin + intensityMin;
        return { color: step.color, value: val };
      });
      return new LUT({ steps: steps, interpolate: true });
      // break;
    }
    case 'log': {
      intensityMax = intensityMax > 0 ? intensityMax : 0;
      intensityMin = intensityMin > 0 ? intensityMin : 0;
      const logMax = intensityMax > 0 ? Math.log10(intensityMax) : 0;
      let logMin = intensityMin > 0 ? Math.log10(intensityMin) : 0;
      if (logMax - logMin > 5) {
        logMin = logMax - 5;
      }
      if (logMax - logMin <= 0) {
        logMin = logMax - 1;
      }
      const deltaLog = logMax - logMin;
      const stepLog = deltaLog / (palette.steps.length - 1);
      const steps = palette.steps.map((step, index) => {
        const val = Math.pow(10, logMax - (palette.steps.length - 1 - index) * stepLog);
        return { color: step.color, value: val };
      });
      return new LUT({ steps: steps, interpolate: true });
    }
    default: {
      throw new Error('Unknown scale type' + targetType);
    }
  }
  throw new Error('Unknown scale type' + targetType);
}

//returns a lookup matrix for 1/3 octave bands
const thirdBands: number[][] = [[]];

export function getThirdBands(): number[][] {
  //Center, lower, and upper frequencies for standard set of 1/3 octave bands covering the audible frequency range (according to EN ISO 266)

  if (thirdBands.length < 2) {
    thirdBands[11] = [11.14, 12.5, 14.25];
    thirdBands[12] = [14.25, 16, 17.82];
    thirdBands[13] = [17.82, 20, 22.4];

    thirdBands[14] = [22.4, 25, 28.2];
    thirdBands[15] = [28.2, 31.5, 35.5];
    thirdBands[16] = [35.5, 40, 44.7];

    thirdBands[17] = [44.7, 50, 56.2];
    thirdBands[18] = [56.2, 63, 70.8];
    thirdBands[19] = [70.8, 80, 89.1];

    thirdBands[20] = [89.1, 100, 112];
    thirdBands[21] = [112, 125, 141];
    thirdBands[22] = [141, 160, 178];

    thirdBands[23] = [178, 200, 224];
    thirdBands[24] = [244, 250, 282];
    thirdBands[25] = [282, 315, 355];

    thirdBands[26] = [355, 400, 447];
    thirdBands[27] = [447, 500, 562];
    thirdBands[28] = [562, 630, 708];

    thirdBands[29] = [708, 800, 891];
    thirdBands[30] = [891, 1000, 1122];
    thirdBands[31] = [1122, 1250, 1413];

    thirdBands[32] = [1413, 1600, 1778];
    thirdBands[33] = [1778, 2000, 2239];
    thirdBands[34] = [2239, 2500, 2818];

    thirdBands[35] = [2818, 3150, 3548];
    thirdBands[36] = [3548, 4000, 4467];
    thirdBands[37] = [4467, 5000, 5623];

    thirdBands[38] = [5623, 6300, 7079];
    thirdBands[39] = [7079, 8000, 8913];
    thirdBands[40] = [8913, 10000, 11220];

    thirdBands[41] = [11220, 12500, 14130];
    thirdBands[42] = [14130, 16000, 17780];
    thirdBands[43] = [17780, 20000, 22390];
  }

  return thirdBands;
}

export function getNthOctaveMiddleFrequency(channelDecimal: number): number {
  //Middle frequency of the "channelDecimal" channel
  const GD_TERZ_REF_FREQ = 1000.0;
  const GD_TERZ_REF_KANAL = 30.0;
  const frequency = GD_TERZ_REF_FREQ * Math.pow(10.0, (channelDecimal - GD_TERZ_REF_KANAL) / 10.0);

  return frequency;
}

export function getNthOctaveDelta(octaveType: number) {
  switch (octaveType) {
    case 1: {
      return 3;
    }
    case 3: {
      return 1;
    }
    case 6: {
      return 0.5;
    }
    case 12: {
      return 0.25;
    }
    case 24: {
      return 0.125;
    }
    default:
      throw new Error('Not supported octave type.');
  }
}
