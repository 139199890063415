import { Device } from '../device-management/devices.types';
import { orderResourceIdentifiers } from './order.config';
import { ComponentDescription } from './order-detail/order-detail.types';
import { UserInfo } from '../user/user.type';
import { AttributeType, SearchAttributeAndValue } from '../shared/+state/attributes/attributes.types';

export const ORDER_NAME_DEFAULT = 'order information';
export const ORDER_FILESUFFIX_READONLY = '_READONLY';
export const ORDER_FILEEXTENSION_ZATFX = '.zatfx';

export const enum LoadingState {
  INIT = 'INIT',
  LOADING = 'LOADING',
  LOADED = 'LOADED'
}

export type CheckoutTokenState = 'returned' | 'unlocked' | 'given_out';

export type Bytes = number;
export type OrderDownloadStateCondition =
  | 'none'
  | 'prepare'
  | 'ready'
  | 'active'
  | 'done'
  | 'unlocking'
  | 'cancel'
  | 'error';

export interface OrderLockState {
  locked: boolean;
  byUser?: UserInfo;
  since?: string;
}

export type OrderDownloadState = {
  condition: OrderDownloadStateCondition;
  readonly?: boolean;
  progress?: {
    loaded: Bytes;
    total: Bytes;
    percentage: number;
  };
  error?: string;
};

// NOTE: ErrorState is currently unused, introduced as preparation for more extensive error handling
export interface ErrorState {
  errorMsg: string;
}

export type CallState = LoadingState | ErrorState;

export interface OrderState {
  id: string;
  name: string;
  label_nls: string;
}

export interface OrderCheckoutToken {
  id: string;
  orderId: string;
  user: UserInfo;
  checkout_time: string; // ISO Date
  give_back_time?: string; // ISO Date
  state?: CheckoutTokenState;
}

export const initialResource = (content = {}) => {
  return {
    loading: false,
    content: content
  };
};

type initialResourceAssemblyFN = () => { [key in orderResourceIdentifiers]: OrderResource };

export const initialResources: initialResourceAssemblyFN = () => {
  return {
    deviceAssignments: initialResource([]),
    testInstrumentations: initialResource([]),
    measurements: initialResource({}),
    checkOutHistory: initialResource([]),
    entityContainer: initialResource({}),
    testSequences: initialResource({}),
    passbyTestSequenceParameters: initialResource({})
  };
};

export const initialEntityContainer = (): EntityContainer => {
  return {
    entities: {},
    entityTypes: {},
    relationTypes: {}
  };
};

export interface PendingState {
  load: boolean;
  create: boolean;
  delete: boolean;
  restore: boolean;
  deviceAssignments: boolean;
  displayAttributes: boolean;
  resources: boolean;
  orderState: boolean;
}

export interface Standard {
  name: string;
  version?: number;
  count?: number;
}

export type TestplanType = 'not_a_testplan' | 'tseq1_tseq2_step' | 'tseq_step';

export interface OrderAttribute<T extends AttributeType = 'String'> {
  type: T;
  label: string;
  value: string;
  nls_key?: string;
}

export interface Order {
  initialId?: string;
  id?: string;
  name?: string;
  lock: OrderLockState;
  pendingDeletion: boolean;
  attributes: {
    [key: string]: OrderAttribute<AttributeType>;
  };
  computedProps: {
    pending: PendingState;
    download: OrderDownloadState;
    testplanType: TestplanType;
    standards?: Standard[];
  };
  relations: {
    orderStateId?: string;
    workspaceId?: string;
  };
  resources: { [key in orderResourceIdentifiers]: OrderResource };
  dynamicComponents: {
    availableDynamicComponents?: { identifier: string; title: string }[];
    customDynamicComponents: ComponentDescription[];
    selectedDynamicComponent?: string;
  };
}

export interface Orders {
  [index: string]: Order;
}

export interface OrderResource {
  loading: boolean;
  content: any;
}

export interface OrderedMeasurement {
  mea_guid: string;
  template: {
    // this assumes there is only one template for this mea_guid in this order
    name: string;
  };
  done: { name: string }[];
}

export interface OrderedMeasurements {
  measurements: {
    [mea_guid: string]: OrderedMeasurement;
  };
  orderIidAttribute?: SearchAttributeAndValue;
}

export interface DeviceAssignment {
  initialId?: string;
  id?: string;
  device?: Device;
  orderId: string;
}

export interface TestInstrumentation {
  id: string;
  name: string;
  chainsOfDevices?: ChainOfDevices[];
}

export interface ChainOfDevices {
  [index: string]: string | number;
}

export interface Attributes {
  [index: string]: AttributeValue;
}
export type AttributeValue = string | number | boolean;

export const enum AoBasetype {
  AoUnitUnderTest = 'AoUnitUnderTest',
  AoUnitUnderTestPart = 'AoUnitUnderTestPart',
  AoTestSequence = 'AoTestSequence',
  AoTestSequencePart = 'AoTestSequencePart',
  AoTest = 'AoTest',
  AoSubTest = 'AoSubTest',
  AoParameterSet = 'AoParameterSet',
  AoParameter = 'AoParameter'
}

export interface RelationTarget {
  id: string;
  apiType: string; // the resource type of entity
}

export interface Relation {
  relationName: string;
  sourceApiType: string;
  targets: RelationTarget[];
  targetUrl: string;
}

export interface Entity {
  id: string;
  apiType: string;
  // NOTE add atfxTypename
  name: string; // e.g.
  nameNLS: string;
  internalAttributes: Attributes; // e.g. m_mime_type and m_mea_guid as mimeType and meaGuid
  displayAttributes: Attributes; // this does not include the name property since e.g. for UnitUnderTestParts we do not want to show them; if you want to display the name somewhere use entity.nameNLS: entity.name
  parent?: RelationTarget;
  children: Relation[];
  infoRelateds: Relation[];
  selfUrl?: string; // undefined if no self link exists
}

export interface EntityType {
  id: string; // the resource id, e.g. "7f19d958-f38b-11eb-a1e6-98fa9bfed662/test_sequence_parameter_set"
  apiType: string; // the resource type of entity, e.g. "OdsOrderPARAMSET_7f19d958-f38b-11eb-a1e6-98fa9bfed662_m_test_sequence_parameter_set"
  typeName: string; // translated human-readable type (if available), e.g. "test_sequence_parameter_set"
  atfxTypeName: string; // type as in the atfx model, e.g. "test_sequence_parameter_set"
  aoBasetype: AoBasetype; // e.g. "AoParameterSet"
  attributes: {
    apiKey: string;
    label: string; // translated human-readable attribute name (if available)
    type: string;
    required: boolean;
    read_only: boolean;
    immutable: boolean;
  }[];
}

export const enum RelationAxis {
  CHILD = 'child',
  INFO = 'info',
  PARENT = 'parent'
}

export interface RelationType {
  id: string;
  relationName: string;
  sourceApiType: string;
  targetApiType: string;
  aoBasename?: string;
  inverseName: string;
  minOccurs: number;
  maxOccurs: number | 'no_limit';
  axis: RelationAxis;
}

export interface EntityContainer {
  // -> one for all descriptive and hierarchical order elements (i.e. all elements which are
  // derived from AoTest, AoSubTest, AoUnitUnderTest, AoUnitUnderTestPart, AoTestSequence and AoTestSequencePart)
  entities: {
    [typeId: string]: Entity; // key is apiType + '/' + id
  };
  entityTypes: {
    [apiType: string]: EntityType;
  };
  relationTypes: {
    [relationNameAndSourceApiType: string]: RelationType; // key is relationName + '/' + sourceApiType
  };
}
