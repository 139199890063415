import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonComponent } from './common/common.component';
import { StoreModule } from '@ngrx/store';
import * as fromCommon from './+state/common.reducer';
import { CommonFacade } from './+state/common.facade';
import { CommonEffects } from './+state/common.effects';
import { BrowserModule } from '@angular/platform-browser';
import { FeatureFlagsFacade } from '@root/libs/feature-flags/src';
import { CookieService } from 'ngx-cookie-service';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    StoreModule.forFeature(fromCommon.COMMON_FEATURE_KEY, fromCommon.reducer),
    EffectsModule.forFeature([CommonEffects])
  ],
  declarations: [CommonComponent],
  providers: [CommonFacade, FeatureFlagsFacade, CookieService]
})
export class VASCommonModule {}
