// Polyfill to enable composedPath functionality for Legacy Edge
// Inspired by https://gist.github.com/leofavre/d029cdda0338d878889ba73c88319295
// Adapted for Basic TypeScript support

export const eventPath = (evt: MouseEvent): EventTarget[] => {
  let path = (evt.composedPath && evt.composedPath()) || evt['path'];
  if (path != null) {
    path = path.indexOf(window) < 0 ? path.concat([window]) : path;
    return path;
  }

  const target: EventTarget | null = evt.target;
  if (target === window) {
    return [window];
  } else if (target === null) {
    return [];
  }
  const parents = getParents(target as Element);
  const targetWithParents = [target as EventTarget].concat(parents);
  const result = targetWithParents.concat([window]);
  return result;
};

const getParents = (node: Element, memo?: EventTarget[]): EventTarget[] => {
  memo = memo || [];
  const parentNode = node.parentNode;

  if (!parentNode) {
    return memo;
  } else {
    return getParents(parentNode as Element, memo.concat([parentNode]));
  }
};
