import * as StreamingActions from './streaming.actions';

import { createReducer, Action } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { RequestState, StreamingMeasurementStatus, StreamingPackage, StreamingType } from '../utils/streaming.types';
import {
  MeasurementTokenContainer,
  ProcessingTokenContainer,
  TokenContainer,
  TokenContainerID,
  decodeMeasurementTokenContainerID
} from '../utils/streaming-token.types';
import { AxisInteractions } from '../plot-handlers/lightningPlot.types';
import { v4 as uuid } from 'uuid';
import { exhaustiveMatchingGuard } from '../../shared/utility-functions/exhaustiveMatchingGuard';
import { SelectableTrack, TIME_TRACK } from '../integrated-streaming/integrated-streaming.component';
import { dateSerializer } from '../../shared/utility-functions/date.helpers';
import { addMinutes } from 'date-fns';

export const STREAMING_FEATURE_KEY = 'streaming';

export type ThruputMode = 'compressed' | 'full';

export interface StreamingState {
  interactions: {
    axis: AxisInteractions;
    refreshAvailable: boolean;
    showYBar: boolean;
    fullSize: boolean;
    track: {
      showTrackSelection: boolean;
      selectableTracks: SelectableTrack[];
      selectedTrack?: SelectableTrack;
    };
    thruput: {
      start?: number;
      end?: number;
      plotCompressed?: boolean;
    };
  };

  streamingPackages: {
    current: StreamingPackage | null;
    history: StreamingPackage[];
  };

  tokenContainers: Record<TokenContainerID, ProcessingTokenContainer | MeasurementTokenContainer>;
}

export interface StreamingPartialState {
  readonly [STREAMING_FEATURE_KEY]: StreamingState;
}

export const initialState: StreamingState = {
  interactions: {
    axis: {
      xAxis: {
        available: [],
        selected: 'lin'
      },
      yAxis: {
        available: [],
        selected: 'lin'
      },
      color: {
        available: [],
        selected: 'lin'
      }
    },
    refreshAvailable: false,
    showYBar: true,
    fullSize: false,
    track: {
      showTrackSelection: false,
      selectableTracks: []
    },
    thruput: {}
  },

  streamingPackages: {
    current: null,
    history: []
  },

  tokenContainers: {}
};

const StreamingReducer = createReducer(
  initialState,
  immerOn(StreamingActions.RequestToken, (state, { tokenContainerId }) => {
    const { depotId, measurementId } = decodeMeasurementTokenContainerID(tokenContainerId);
    state.tokenContainers[tokenContainerId] = {
      id: tokenContainerId,
      type: 'Measurement',
      token: null,
      depotId,
      measurementId,
      requestState: RequestState.NONE,
      validUntilISO: dateSerializer(addMinutes(new Date(), 10))
    };
  }),
  immerOn(StreamingActions.RequestTokenSuccess, (state, { tokenContainer }) => {
    state.tokenContainers[tokenContainer.id] = tokenContainer;
  }),
  immerOn(StreamingActions.LookupTokenSuccess, (state, { tokenContainer }) => {
    state.tokenContainers[tokenContainer.id] = tokenContainer;
  }),

  // Streaming Package
  immerOn(StreamingActions.CreateStreamingPackage, (state, { options }) => {
    if (state.streamingPackages.current !== null) {
      state.streamingPackages.history.push(state.streamingPackages.current);
      state.streamingPackages.current = null;
    }

    let streamingPackage: StreamingPackage | undefined = undefined;

    switch (options.streamingType) {
      case StreamingType.MEASUREMENT:
      case StreamingType.TRACKING:
        {
          let tokenContainer: TokenContainer | undefined = undefined;
          if (options.tokenContainerId) {
            tokenContainer = state.tokenContainers[options.tokenContainerId];
            if (!tokenContainer) {
              console.error(
                'TokenContainer Timing Trouble!, TokenContainer has not yet been found when preparing a Streaming Package'
              );
              // NOTE: We'll leave this in here for now to figure out whether we have timing trouble
            }
          }

          streamingPackage = {
            id: uuid(),
            type: options.streamingType,
            status: StreamingMeasurementStatus.READY,
            tokenContainer: tokenContainer!, // FIXME: Check this assertion, it may depent on the timing of the tokenContainer
            datasetContainer: {
              requestState: RequestState.NONE
            }
          };
        }

        break;
      case StreamingType.PROCESSING:
        {
          const tokenContainer: ProcessingTokenContainer = {
            id: uuid(),
            type: StreamingType.PROCESSING,
            token: null,
            requestState: RequestState.NONE,
            validUntilISO: dateSerializer(addMinutes(new Date(), 10))
          };

          streamingPackage = {
            id: uuid(),
            type: StreamingType.PROCESSING,
            status: StreamingMeasurementStatus.PREP,
            tokenContainer: tokenContainer,
            datasetContainer: {
              requestState: RequestState.NONE
            }
          };
        }
        break;
      default:
        exhaustiveMatchingGuard(options.streamingType);
    }

    if (options.dataset && streamingPackage) {
      streamingPackage.datasetContainer = {
        dataset: options.dataset,
        requestState: RequestState.SUCCESS
      };
    }

    if (streamingPackage) {
      state.streamingPackages.current = streamingPackage;
    } else {
      console.error('Streaming Package could not be created');
    }
  }),

  immerOn(StreamingActions.AddTokenToStreamingPackage, (state, { tokenContainer }) => {
    if (state.streamingPackages.current?.tokenContainer) {
      state.streamingPackages.current.tokenContainer = tokenContainer;
    }
  }),

  immerOn(StreamingActions.StreamingPackageCompleted, (state) => {
    if (state.streamingPackages.current) {
      state.streamingPackages.current.status = StreamingMeasurementStatus.COMPLETED;
    }
  }),

  // Interactions
  immerOn(StreamingActions.SetAvailableAxisInteraction, (state, { axisType, availableAxisInteractions }) => {
    state.interactions.axis[axisType].available = availableAxisInteractions;
  }),
  immerOn(StreamingActions.SelectAxisInteraction, (state, { axisType, selectedAxisInteraction }) => {
    state.interactions.axis[axisType].selected = selectedAxisInteraction;
  }),
  immerOn(StreamingActions.SetAvailableColorInteraction, (state, { availableColorInteractions }) => {
    state.interactions.axis.color.available = availableColorInteractions;
  }),
  immerOn(StreamingActions.SelectColorInteraction, (state, { selectedColorInteraction }) => {
    state.interactions.axis.color.selected = selectedColorInteraction;
  }),
  immerOn(StreamingActions.SetFullSize, (state) => {
    state.interactions.fullSize = true;
  }),
  immerOn(StreamingActions.SetReducedSize, (state) => {
    state.interactions.fullSize = false;
  }),
  immerOn(StreamingActions.EnableRefresh, (state) => {
    state.interactions.refreshAvailable = true;
  }),
  immerOn(StreamingActions.DisableRefresh, (state) => {
    state.interactions.refreshAvailable = false;
  }),
  immerOn(StreamingActions.ShowYBar, (state) => {
    state.interactions.showYBar = true;
  }),
  immerOn(StreamingActions.HideYBar, (state) => {
    state.interactions.showYBar = false;
  }),

  // Thruput Interactions
  immerOn(StreamingActions.SetThruputParameters, (state, { start, end, plotCompressed }) => {
    state.interactions.thruput.start = start;
    state.interactions.thruput.end = end;
    state.interactions.thruput.plotCompressed = plotCompressed;
  }),
  immerOn(StreamingActions.ResetThruputParameters, (state) => {
    state.interactions.thruput.start = undefined;
    state.interactions.thruput.end = undefined;
    state.interactions.thruput.plotCompressed = undefined;
  }),
  // Track Interactions
  immerOn(StreamingActions.ShowTrackSelection, (state, { showTrackSelection }) => {
    state.interactions.track.showTrackSelection = showTrackSelection;
  }),
  immerOn(StreamingActions.SetSelectableTracks, (state, { selectableTracks }) => {
    state.interactions.track.selectableTracks = [TIME_TRACK, ...selectableTracks];
  }),
  immerOn(StreamingActions.SelectedTrack, (state, { track }) => {
    state.interactions.track.selectedTrack = track;
  })
);

export function reducer(state: StreamingState | undefined, action: Action) {
  return StreamingReducer(state, action);
}
