import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, firstValueFrom } from 'rxjs';
import { debounceTime, filter, map, share, shareReplay, startWith, take, tap, withLatestFrom } from 'rxjs/operators';
import { MeasurementsFacade } from '../+state/measurements.facade';
import { emptyMeasurementPath } from '../+state/measurements.reducer';
import { MeasurementMove, MeasurementPath } from '../measurements.types';
import { is } from 'immer/dist/internal';
import { log } from 'console';
import path from 'path';

@Component({
  selector: 'cloud-measurements-move-modal',
  templateUrl: './measurementsMoveModal.component.html',
  styleUrls: ['./measurementsMoveModal.component.css']
})
export class MeasurementsMoveModalComponent implements OnInit, AfterViewInit {
  // @ViewChild('CCNameInput') CCNameInput: ElementRef;

  subs: Subscription[] = [];

  allowOverwrite: boolean = true;
  allowOverwriteChanged$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  depotName$: Observable<string>;
  path$: Observable<string>;
  pathsMatch$: Observable<boolean>;

  showModal$: Observable<boolean>;
  doMove$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  disableMoveButton$: Observable<boolean>;

  @ViewChild('moveTargetJobNameInput', { static: true }) moveTargetJobNameInput: ElementRef;
  private targetPathChanged = new Subject<MeasurementPath>();

  constructor(public measurementsFacade: MeasurementsFacade) {}

  ngOnInit() {
    this.depotName$ = this.measurementsFacade.intermediateMeasurements$.pipe(
      map((meas) => {
        const firstMea = Object.values(meas)[0];
        return firstMea?.path.depot_name ?? 'no depot';
      }),
      startWith('')
    );

    this.subs.push(
      combineLatest([this.measurementsFacade.gatheringPathState$, this.measurementsFacade.intermediateMeasurements$])
        .pipe(
          filter(([state, meas]) => state === 'done' && meas && Object.keys(meas).length > 0),
          tap(async ([state, meas]) => {
            await this.changeTargetPath(['project', 'job'], meas[Object.keys(meas)[0]].path);
          }),
          take(1)
        )
        .subscribe()
    );

    this.path$ = this.measurementsFacade.intermediateMeasurements$.pipe(
      map((mea) => {
        const firstMea = Object.values(mea)[0];
        const depot = firstMea.path.depot_name || 'no depot';
        const project = firstMea.path.project || 'no project';
        const job = firstMea.path.job ?? 'no job';
        const path = `${depot}/${project}/${job}`;
        return path;
      }),
      shareReplay(1)
    );

    this.pathsMatch$ = this.measurementsFacade.intermediateMeasurements$.pipe(
      map((meas) => {
        try {
          Object.values(meas).reduce((acc, mea, index, arr) => {
            if (index === 0) {
              return mea; // For the first measurement, return it as is
            }

            const depotMatches = mea.path.depot_id === arr[0].path.depot_id;
            const projectMatches = mea.path.project === arr[0].path.project;
            const jobMatches = mea.path.job === arr[0].path.job;

            // For subsequent measurements, check if they match the first one
            if (depotMatches && projectMatches && jobMatches) {
              return acc; // If they match, return the accumulated value
            }

            // If they don't match, throw an error
            throw new Error('Depot/Project/Jobs do not match for all measurements');
          }, '');

          // If no error was thrown, return true
          return true;
        } catch (error) {
          // If an error was thrown, return false
          return false;
        }
      }),
      shareReplay(1)
    );

    this.showModal$ = this.measurementsFacade.showMoveModal$.pipe(
      withLatestFrom(this.pathsMatch$, this.measurementsFacade.gatheringPathState$),
      map(([_, pathsMatch, gatheringPathState]) => {
        return pathsMatch && gatheringPathState === 'done';
      }),
      share()
    );

    this.disableMoveButton$ = combineLatest([
      this.measurementsFacade.dryRunResults$,
      this.measurementsFacade.targetPath$,
      this.allowOverwriteChanged$
    ]).pipe(
      map(([dryRunResults, targetPath, allowOverwrite]) => {
        const hasMeas = dryRunResults?.dryRunMeasurements?.length > 0;
        const hasOverwrites = dryRunResults?.dryRunMeasurements?.some((mea) => mea.willOverWrite);
        const overwriteCheck = this.allowOverwrite || !hasOverwrites;

        const isProjectValid = this.isValidFilePath(targetPath.project);
        const isJobValid = this.isValidFilePath(targetPath.job);

        return !hasMeas || !overwriteCheck || !isProjectValid || !isJobValid;
      }),
      startWith(true)
    );

    const targetPathChangedSub = this.targetPathChanged.pipe(debounceTime(300)).subscribe((targetPath) => {
      this.measurementsFacade.setTargetPath(targetPath);
    });

    const doMoveSub = this.doMove$
      .pipe(
        filter((doMove) => doMove),
        withLatestFrom(this.measurementsFacade.intermediateMeasurements$, this.measurementsFacade.targetPath$),
        filter((measurements) => measurements.length > 0),
        tap(([_, measurements, targetPath]) => {
          measurements.forEach((measurement) => {
            const destinationDepotId = measurement.path.depot_id; // same depot for now
            const moveRequest: MeasurementMove = {
              id: measurement.measurementId, //Maybe change id to something else later
              subtitle: measurement.path.subtitle,
              measurementId: measurement.measurementId,
              targetPath: { ...targetPath, subtitle: measurement.path.subtitle },
              destinationDepotId: destinationDepotId,
              moveState: 'init',
              overwrite: true
            };
            this.measurementsFacade.requestMove(moveRequest);
          });
        })
      )
      .subscribe();

    this.subs.push(targetPathChangedSub, doMoveSub);
  }

  handleEscape(event) {
    this.endMoveMeasurements(event);
  }

  async endMoveMeasurements(event: MouseEvent) {
    event && event.stopPropagation();
    this.measurementsFacade.setTargetPath(emptyMeasurementPath);
    this.measurementsFacade.requestDryRun([]);
    this.measurementsFacade.showMoveModal(false);
  }

  confirmMove(event: Event) {
    event && event.stopPropagation();
    this.doMove$.next(true);
    setTimeout(() => {
      this.measurementsFacade.setTargetPath(emptyMeasurementPath);
      this.measurementsFacade.requestDryRun([]);
    }, 1000);

    this.measurementsFacade.showMoveModal(false);
  }

  moveTargetProjectNameTextChanged(project: string) {
    this.changeTargetPath(['project'], { project });
  }

  moveTargetJobNameTextChanged(job: string) {
    this.changeTargetPath(['job'], { job });
  }

  async changeTargetPath(pathElements: Exclude<keyof MeasurementPath, 'depot'>[], path: Record<string, string>) {
    const targetPath = await firstValueFrom(this.measurementsFacade.targetPath$.pipe(take(1)));

    const newPath = structuredClone(pathElements).reduce((acc, pathElement) => {
      acc[pathElement] = path[pathElement];
      return acc;
    }, {} as MeasurementPath);

    this.targetPathChanged.next({ ...targetPath, ...newPath });
  }

  async requestDryRun() {
    const intermediateMeasurements = await firstValueFrom(
      this.measurementsFacade.intermediateMeasurements$.pipe(take(1))
    );
    this.measurementsFacade.requestDryRun(intermediateMeasurements);
  }

  handleAllowOverwriteChanged() {
    this.allowOverwrite = this.allowOverwrite ? false : true;
    this.allowOverwriteChanged$.next(true);
  }

  isValidFilePath(folderName: string) {
    const folderNamePattern = /^(?!-)[a-zA-Z0-9_-]+(?!-)$/;

    return folderName !== '' && folderNamePattern.test(folderName);
  }

  ngAfterViewInit() {
    this.moveTargetJobNameInput.nativeElement.focus();
  }

  ngOnDestroy() {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }
}
