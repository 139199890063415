import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'namesFilter'
})
export class NamesFilterPipe<T extends { name: string }> implements PipeTransform {
  transform(entities: T[] | null, userInput = ''): T[] {
    if (!entities || !entities.length) {
      return [];
    }

    if (!userInput.trim().length) {
      return entities;
    }

    return entities.filter((entity) => entity.name.toLowerCase().indexOf(userInput.toLowerCase()) >= 0);
  }
}
