<ng-container *ngIf="showChart$ | async">
  <cloud-external-streaming-interactions (resetAxis)="handleAxisReset()"></cloud-external-streaming-interactions>
  <div
    id="cancelMessage"
    *ngIf="(cancellationMessage$ | async) !== false && (shouldCancel$ | async)?.shouldCancel === true"
    >{{ cancellationMessage$ | async }}</div
  >
  <div id="chartContainer">
    <div id="chartId" #chartId></div>
    <div *ngIf="streamingFacade.showYBar$ | async" id="yBarId" #yBarId></div>
  </div>
</ng-container>
