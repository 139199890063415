import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { FetchDepotAttributesAndSemantics } from './attributes.actions';

import { AttributesPartialState } from './attributes.reducer';
import { attributesQuery } from './attributes.selectors';
import { filter } from 'rxjs';

@Injectable()
export class AttributesFacade {
  availableDepotAttributesLoaded$ = this.store.pipe(select(attributesQuery.getDepotAttributesLoaded));
  availableDepotAttributes$ = this.store.pipe(select(attributesQuery.getAvailableDepotAttributes));
  availableSemanticDepotAttributes$ = this.store.pipe(select(attributesQuery.getAvailableSemanticDepotAttributes));
  depotAttributesCache$ = this.store.pipe(select(attributesQuery.getDepotAttributesCache));
  availableAttributeGroups$ = this.store.pipe(select(attributesQuery.getAvailableAttributeGroups));

  ensuredDepotAttributes = this.availableDepotAttributes$.pipe(filter((attributes) => (attributes?.length ?? 0) > 0));

  constructor(private store: Store<AttributesPartialState>) {}

  fetchDepotAttributesAndSemantics() {
    this.store.dispatch(FetchDepotAttributesAndSemantics());
  }
}
