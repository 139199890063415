import { AppErrorPayload } from './app.types';
import { AppError, AppActionTypes } from './+state/app.actions';
import { TypedAction } from '@ngrx/store/src/models';
import { HttpErrorResponse } from '@angular/common/http';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export class WebAppError extends Error {
  constructor(message?: string, public translationKey?: string, public translationParameter?: object) {
    // 'Error' breaks prototype chain here, so restore it manually (cf. https://stackoverflow.com/questions/41102060/typescript-extending-error-class)
    super(message);
    const actualProto = new.target.prototype;
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto);
    }
  }
}

export function createAppError(error: any, silent = false): TypedAction<AppActionTypes.AppError> {
  return AppError({ err: createAppErrorPayload(error), silent });
}

export function createAppErrorPayload(error: any): AppErrorPayload {
  let errorMsg = '';
  let errorTransKey = '';
  let errorTramsParams = {};
  const errorObjects: any[] = [];

  if (error) {
    // HttpErrorReponse is not serializable, so we sometimes cannot pass them => Check for "status" , too
    if (error instanceof HttpErrorResponse || 'status' in error) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMsg = 'PAK cloud returned an error';
      if ('status' in error) {
        switch (error.status) {
          case 400:
            errorTransKey = _('APP.ERR.400_BAD_REQUEST');
            break;
          case 401:
            errorTransKey = _('APP.ERR.401_UNAUTHORIZED');
            break;
          case 403:
            errorTransKey = _('APP.ERR.403_PERMISSION_DENIED');
            break;
          case 404:
            errorTransKey = _('APP.ERR.404_NOT_FOUND');
            break;
          case 500:
            errorTransKey = _('APP.ERR.500_SERVER_ERROR');
            break;
          case 502:
            errorTransKey = _('APP.ERR.502_BAD_GATEWAY');
            break;
          case 503:
            errorTransKey = _('APP.ERR.503_UNAVAILABLE');
            break;
          default:
            errorTransKey = _('APP.ERR.SERVER');
            break;
        }

        let statusError = ': ' + error.status;
        if ('statusText' in error) {
          statusError += ' (' + error.statusText + ')';
        }
        errorObjects.push(statusError);
      } else {
        errorTransKey = _('APP.ERR.SERVER');
      }
      if (Array.isArray(error.error?.errors)) {
        error.error.errors.forEach((err) => {
          errorObjects.push('\n' + err.detail);
        });
      }
      if ('stack' in error) {
        errorObjects.push('\n' + error);
      }
    } else if (error instanceof WebAppError) {
      // A webapp error occured, where a specific message should be displayed to the end user
      // (possibly because they can do something about it)
      errorMsg = error.message ?? '';
      errorTransKey = error.translationKey ?? '';
      errorTramsParams = error.translationParameter ?? {};
      if ('stack' in error) {
        errorObjects.push('\n' + error.stack);
      }
    } else {
      // A client-side or network error occurred. Handle it accordingly.
      errorMsg = 'A browser or network error occurred';
      errorTransKey = _('APP.ERR.CLIENT');
      if ('message' in error) {
        errorMsg = error.message;
        errorTransKey = '';
      }
      if ('translationKey' in error) {
        errorTransKey = error.translationKey;
      }
      if ('translationParameter' in error) {
        errorTramsParams = error.translationParameter;
      }
      if ('stack' in error) {
        errorObjects.push('\n' + error.stack);
      }
    }
  }

  //console.error(errorMsg, ...errorObjects);
  return { message: errorMsg, translationKey: errorTransKey, translationParameter: errorTramsParams };
}
