import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { DepotSearchFacade } from '../+state/depot-search.facade';
import { translateSemanticDepotAttribute } from '../../shared/utility-functions/translateAttribute';
import { AppFacade } from '../../+state/app.facade';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import {
  suggestAttributeGroups,
  suggestDepotAttributes,
  suggestSemanticAttributes
} from '../../shared/utility-functions/search.helpers';
import {
  AttributeGroup,
  DepotAttribute,
  SemanticDepotAttribute
} from '../../shared/+state/attributes/attributes.types';
import { AttributesFacade } from '../../shared/+state/attributes/attributes.facade';

@Component({
  selector: 'cloud-autocomplete-dropdown',
  templateUrl: './autocomplete-dropdown.component.html',
  styleUrls: ['./autocomplete-dropdown.component.css']
})
export class AutocompleteDropdownComponent implements OnInit, AfterViewInit {
  @Input() searchable: boolean;
  @Output() attributeSelected = new EventEmitter<DepotAttribute | SemanticDepotAttribute>();

  @ViewChild('textSearchInput', { read: ElementRef })
  textSearchInput: ElementRef;

  allAttributeGroups$: Observable<AttributeGroup[]>;
  possibleSemanticSearchAttributes$: Observable<SemanticDepotAttribute[]>;
  possibleDepotSearchAttributes$: Observable<DepotAttribute[]>;
  filteredSemanticSearchAttributes$: Observable<SemanticDepotAttribute[]>;
  filteredDepotSearchAttributes$: Observable<DepotAttribute[]>;

  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    public depotSearchFacade: DepotSearchFacade,
    public appFacade: AppFacade,
    public attributesFacade: AttributesFacade
  ) {}

  ngOnInit() {
    this.allAttributeGroups$ = combineLatest([
      this.attributesFacade.availableAttributeGroups$,
      this.appFacade.languageState$
    ]).pipe(
      filter(([groups, _]) => !!groups),
      map(([groups, lang]) => suggestAttributeGroups(groups!, lang))
    );

    this.possibleSemanticSearchAttributes$ = combineLatest([
      this.attributesFacade.availableSemanticDepotAttributes$,
      this.appFacade.languageState$
    ]).pipe(
      filter(([semanticSearchAttributes, _]) => !!semanticSearchAttributes),
      map(([semanticSearchAttributes, lang]) => suggestSemanticAttributes(semanticSearchAttributes!, lang))
    );

    this.filteredSemanticSearchAttributes$ = combineLatest([
      this.searchText$,
      this.possibleSemanticSearchAttributes$
    ]).pipe(
      withLatestFrom(this.appFacade.languageState$),
      map(([[searchText, attributes], lang]) => {
        return attributes.filter((attribute) => {
          const attr = translateSemanticDepotAttribute(attribute, lang).toLocaleLowerCase();
          return attr.includes(searchText.toLocaleLowerCase().trim());
        });
      })
    );

    this.possibleDepotSearchAttributes$ = combineLatest([
      this.attributesFacade.availableDepotAttributes$,
      this.appFacade.languageState$
    ]).pipe(
      filter(([depotAttributes, _]) => !!depotAttributes),
      map(([depotAttributes, lang]) => suggestDepotAttributes(depotAttributes!, lang, this.searchable))
    );

    this.filteredDepotSearchAttributes$ = combineLatest([this.searchText$, this.possibleDepotSearchAttributes$]).pipe(
      map(([searchText, attributes]) => {
        return attributes.filter((attribute) => {
          const attr = attribute.idName.toLocaleLowerCase();
          return attr.includes(searchText.toLocaleLowerCase());
        });
      })
    );
  }

  preventSpaceDismiss(event: KeyboardEvent): void {
    // keyCode is deprecated, its replacement event.key is however not yet fully available.
    // Check https://medium.com/@uistephen/keyboardevent-key-for-cross-browser-key-press-check-61dbad0a067a for more info
    // tslint:disable-next-line: deprecation
    const key = event.key || event.keyCode;
    if (key === ' ' || key === 32) {
      event.stopPropagation();
    }
  }

  selectAttribute(event: Event, attribute: DepotAttribute | SemanticDepotAttribute) {
    event.stopPropagation();
    this.attributeSelected.emit(attribute);
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.textSearchInput.nativeElement.focus(), 0);
  }
}
