<h6>
  {{ 'LICENSING.ROLES' | translate }}
</h6>
<clr-datagrid class="roles-datagrid">
  <clr-dg-column [clrDgSortBy]="'name'" [clrDgSortOrder]="ascSort">{{ 'LICENSING.ROLE' | translate }}</clr-dg-column>
  <clr-dg-column>{{ 'LICENSING.OBTAINMENT_RIGHTS' | translate }}</clr-dg-column>

  <clr-dg-row
    *clrDgItems="let role of roles$ | async; trackBy: trackById"
    [clrDgExpanded]="role.id === rowExpanded"
    class="role-row"
    [clrDgItem]="role"
    (click)="expandRow(role)"
    [ngClass]="{ expanded: role.id === rowExpanded }"
  >
    <clr-dg-cell class="name-cell"
      ><span [innerHTML]="role.name | searchBarHighlightFilter : (searchFilter$ | async)!"></span
    ></clr-dg-cell>
    <clr-dg-cell class="license-wrapper">
      <ng-container *ngIf="licensesFacade.licenses$ | async as licenses">
        <span
          class="license-pill"
          *ngFor="let license of sortLicensePills(getLicensesForRole(role, licenses)); trackBy: trackById"
        >
          <ng-container
            *ngIf="lookupLicenseNames(license.id, { includePeriod: true, includeUserType: true }) as licenseName"
          >
            <cloud-license-icon [license]="license"></cloud-license-icon>

            <span [innerHTML]="licenseName | searchBarHighlightFilter : (searchFilter$ | async)!"></span>
          </ng-container>
        </span>
      </ng-container>
    </clr-dg-cell>

    <clr-dg-row-detail *clrIfExpanded class="expanded-row" (click)="cancelBubble($event)">
      <clr-dg-cell class="pick-list-col">
        <cloud-pick-list
          sourceListIconFileName="slide_text_person"
          [sourceList]="availableLicenses"
          [sourceListHeader]="'LICENSING.PICK_LISTS.AVAILABLE_LICENSES' | translate"
          targetListIconFileName="slide_text_person"
          [targetList]="roleLicenses"
          [targetListHeader]="'LICENSING.PICK_LISTS.APPLIED_LICENSES' | translate"
          (movedToTarget)="addLicenseToRole($event, role)"
          (movedToSource)="removeLicenseFromRole($event, role)"
          itemKey="name"
        ></cloud-pick-list>
      </clr-dg-cell>
    </clr-dg-row-detail>
  </clr-dg-row>

  <clr-dg-footer>{{ (roles$ | async)?.length }} {{ 'LICENSING.ROLES' | translate }}</clr-dg-footer>
</clr-datagrid>
