import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LICENSES_FEATURE_KEY, LicensesState } from './licenses.reducer';

const getLicensesState = createFeatureSelector<LicensesState>(LICENSES_FEATURE_KEY);

const getLoaded = createSelector(getLicensesState, (state: LicensesState) => {
  return state.loaded;
});

const getLicenses = createSelector(getLicensesState, getLoaded, (state: LicensesState, isLoaded) => {
  return isLoaded ? state.licenses : [];
});

const getError = createSelector(getLicensesState, (state: LicensesState) => state.error);

export const licensesQuery = {
  getLoaded,
  getError,
  getLicenses
};
