import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LS_usesMouse } from '../common.types';
import * as CommonActions from './common.actions';
import { tap } from 'rxjs/operators';

@Injectable()
export class CommonEffects {
  setMouseDeviceCookie$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommonActions.MouseDeviceDetected),
        tap(() => localStorage.setItem(LS_usesMouse, 'true'))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions) {}
}
