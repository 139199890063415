import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ClrDatagridSortOrder } from '@clr/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription, combineLatest, map, of, shareReplay, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GroupsFacade } from '../+state/groups/groups.facade';
import { LicensesFacade } from '../+state/licenses/licenses.facade';
import { RolesFacade } from '../+state/roles/roles.facade';
import { UsersFacade } from '../+state/users/users.facade';
import { UserInfo, UserWithRolesAndLicenses } from '../../../../user/user.type';
import { lookupLicenseNames } from '../services/licenses-name-lookup';
import { LicensingSearchService } from '../services/licensing-search.service';
import { LicenseInfo, LicenseWithOrigin } from '../types/license.types';
import { EffectiveRole, RoleInfo } from '../types/role.types';

type SubMenuState = 'details' | 'groups' | 'roles' | 'licenses';

@Component({
  selector: 'cloud-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersListComponent implements OnInit, OnDestroy {
  public users$: Observable<UserWithRolesAndLicenses[]>;
  public rowExpanded: string | null;
  public subMenuState: SubMenuState = 'details';
  public lookupLicenseNames = lookupLicenseNames;
  public ascSort = ClrDatagridSortOrder.ASC;

  public usersLoading$ = new BehaviorSubject<boolean>(true);

  searchFilter$: Observable<string>;

  public highlighted$ = new BehaviorSubject<{ userId: string | null; roles: string[]; groups: string[] } | null>({
    userId: null,
    roles: [],
    groups: []
  });

  private subs$: Subscription[] = [];

  constructor(
    public usersFacade: UsersFacade,
    public groupsFacade: GroupsFacade,
    public rolesFacade: RolesFacade,
    public licensesFacade: LicensesFacade,
    public searchService: LicensingSearchService,
    public translate: TranslateService
  ) {
    this.subs$.push(
      this.rolesFacade.loaded$
        .pipe(
          switchMap((loaded) => {
            return loaded ? this.rolesFacade.effectiveRolesLoaded$ : of(false);
          }),
          filter((value) => {
            return !!value;
          })
        )
        .subscribe((value) => {
          value && this.usersLoading$.next(false);
        })
    );
  }

  ngOnInit() {
    this.searchFilter$ = this.searchService.searchFilter$.pipe(shareReplay(1));
    this.rolesFacade.getEffectiveRoles();

    this.users$ = combineLatest([
      this.getUsersFull(),
      this.searchService.searchFilter$,
      this.usersFacade.isActiveFilter$
    ]).pipe(
      map(([users, filter, hideInactive]) => {
        const filteredUsers = users.filter((user) => {
          if (hideInactive && !user.isActive) {
            return false;
          }

          const usernameMatches = user.userName.toLowerCase().includes(filter.toLowerCase());
          const rolesMatch = user.roles.some((role) => role.name.toLowerCase().includes(filter.toLowerCase()));
          const groupsMatch = user.groups.some((group) => group.toLowerCase().includes(filter.toLowerCase()));
          const licenseMatch = user.licenses.some((license) =>
            license.name.toLowerCase().includes(filter.toLowerCase())
          );

          const matchesVibro = user.hasVibroLicense && filter.toLowerCase().includes('vibro');

          return usernameMatches || rolesMatch || groupsMatch || licenseMatch || matchesVibro;
        });

        return filteredUsers;
      })
    );
  }

  cancelBubble(event: Event) {
    event.stopPropagation();
  }

  expandRow(user: UserWithRolesAndLicenses) {
    this.rowExpanded = this.rowExpanded === user.id ? null : user.id;
  }

  subMenuStateChange(state: SubMenuState) {
    this.subMenuState = state;
  }

  getEffectiveRoles(user: UserInfo): Observable<EffectiveRole[] | undefined> {
    return combineLatest([this.rolesFacade.effectiveRoles$, this.rolesFacade.roles$, this.groupsFacade.groups$]).pipe(
      map(([effectiveRoles, roles, groups]) => {
        const userRoles: EffectiveRole[] | undefined = effectiveRoles
          ?.filter((efRole: EffectiveRole) => efRole.userId === user.id)
          .map((role: EffectiveRole) => {
            return { ...role, groups: groups?.filter((group) => role.groupIds?.includes(group.id)) };
          })
          .map((role: EffectiveRole) => ({ ...role, role: roles?.find((r) => r.id === role.roleId) }))
          .filter((role) => role.groupIds?.length > 0);

        return userRoles;
      })
    );
  }

  getAllRolesForUser(user: UserWithRolesAndLicenses): Observable<EffectiveRole[]> {
    return this.getEffectiveRoles(user).pipe(
      map((effectiveRoles) => {
        const rolesToReturn: EffectiveRole[] = [];

        effectiveRoles
          ?.filter((efRole: EffectiveRole) => efRole.userId === user.id)
          .forEach((efRole: EffectiveRole) => {
            rolesToReturn.push(efRole);
          });

        (user.roles as RoleInfo[]).forEach((role: RoleInfo) => {
          const effectiveRole: EffectiveRole = {
            userId: user.id,
            roleId: role.id,
            groupIds: [],
            role: { ...role },
            groups: []
          };

          rolesToReturn.push(effectiveRole);
        });

        return rolesToReturn;
      })
    );
  }

  getAllLicensesForUser(user: UserWithRolesAndLicenses): Observable<LicenseWithOrigin[]> {
    return this.getAllRolesForUser(user).pipe(
      switchMap((roles: EffectiveRole[]) => {
        return this.licensesFacade.licenses$.pipe(
          map((licenses) => {
            const allLicenses = licenses
              ?.filter((license) => {
                return (
                  license.roles.length > 0 &&
                  license.roles.some((role: string) =>
                    roles?.find((userRole: EffectiveRole) => userRole.roleId === role)
                  )
                );
              })
              .map((license) => {
                const licenseWithOrigin: LicenseWithOrigin = { ...license, roles: [], groups: [] };

                roles.forEach((role) => {
                  if (license.roles.includes(role.roleId)) {
                    licenseWithOrigin.roles.push(role);
                    licenseWithOrigin.groups = role.groups?.map((group) => group.name) ?? [];
                  }
                });

                return licenseWithOrigin;
              });

            return [...new Set(allLicenses.map((obj) => obj.id))].map((id) => {
              return allLicenses.find((obj) => obj.id === id);
            }) as LicenseWithOrigin[];
          })
        );
      })
    );
  }

  removeDuplicateRoles(roles: Observable<EffectiveRole[]>): Observable<EffectiveRole[]> {
    return roles.pipe(
      map((roles) => {
        return [...new Set(roles.map((role) => role.roleId))].map((id) => {
          return roles.find((role) => role.roleId === id);
        });
      })
    ) as Observable<EffectiveRole[]>;
  }

  lookUpAndSortLicensePills(licenses: LicenseInfo[]): LicenseInfo[] {
    return licenses
      .map((license) => ({ ...license, name: lookupLicenseNames(license.id, { includePeriod: true }) }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  getGroupNamesForEfRole(role: EffectiveRole) {
    return role?.groups?.map((group) => `${group.name} (group)`).join(', ');
  }

  trackById(index: number, item: { id: string }) {
    return item.id;
  }

  private getUsersFull() {
    return combineLatest([
      this.usersFacade.users$,
      this.rolesFacade.roles$,
      this.rolesFacade.roleToUserRelations$,
      this.licensesFacade.licenses$
    ]).pipe(
      map(([users, roles, roleToUserRelations, licenses]) => {
        const fullUsers: UserWithRolesAndLicenses[] =
          users?.map((user) => {
            const relations = roleToUserRelations?.filter((relation) => {
              return relation.user.id === user.id;
            });
            const userRoles = relations?.map((relation) => {
              const role = roles?.find((role) => relation.role.id === role.id);
              return role;
            }) as RoleInfo[];

            const userLicenses = licenses?.filter((license) => {
              return (
                license.roles.length > 0 &&
                license.roles.some((role) => relations?.find((relation) => relation.role.id === role))
              );
            }) as LicenseInfo[];

            const fullUser = {
              ...user,
              roles: userRoles ?? [],
              licenses: userLicenses ?? []
            };

            return fullUser;
          }) ?? [];

        return fullUsers;
      }),
      switchMap((fullUsers: UserWithRolesAndLicenses[]) => {
        const updatedUsers$ = fullUsers.map((user) =>
          this.getAllLicensesForUser(user).pipe(map((licenses) => ({ ...user, licenses })))
        );

        return combineLatest(updatedUsers$);
      })
    );
  }

  ngOnDestroy(): void {
    this.subs$.forEach((sub) => sub.unsubscribe());
    this.usersLoading$.complete();
  }
}
