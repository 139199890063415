import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ClrDatagridSortOrder } from '@clr/angular';
import { Observable, combineLatest, map, shareReplay, take } from 'rxjs';
import { GroupsFacade } from '../+state/groups/groups.facade';
import { LicensesFacade } from '../+state/licenses/licenses.facade';
import { RolesFacade } from '../+state/roles/roles.facade';
import { UsersFacade } from '../+state/users/users.facade';
import { LicensingSearchService } from '../services/licensing-search.service';
import { LicenseInfo } from '../types/license.types';
import { RoleInfo } from '../types/role.types';

type LicenseListDefinition = {
  identifier: string;
  nls_key: string;
  licenses$: Observable<LicenseInfo[] | undefined>;
};

@Component({
  selector: 'cloud-licenses-list',
  templateUrl: './licenses-list.component.html',
  styleUrls: ['./licenses-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LicensesListComponent implements OnInit {
  public licensesDefinition: LicenseListDefinition[] = [];

  public rowExpanded: string | null;
  public ascSort = ClrDatagridSortOrder.ASC;

  availableRoles: RoleInfo[];
  licenseRoles: RoleInfo[];

  searchFilter$: Observable<string>;

  constructor(
    public usersFacade: UsersFacade,
    public groupsFacade: GroupsFacade,
    public rolesFacade: RolesFacade,
    public licensesFacade: LicensesFacade,
    public searchService: LicensingSearchService
  ) {}

  ngOnInit() {
    this.searchFilter$ = this.searchService.searchFilter$.pipe(shareReplay(1));
    const filteredLicenses$ = combineLatest([
      this.licensesFacade.licenses$,
      this.rolesFacade.roles$,
      this.searchFilter$
    ]).pipe(
      map(([licenses, roles, searchFilter]) => {
        const filteredLicenses = licenses?.filter((license) => {
          const licenseNameMatches = license.name.toLowerCase().includes(searchFilter.toLowerCase());

          let roleNameMatches = false;
          if (roles) {
            const rolesForLicense = this.getRolesForLicense(license, roles);
            roleNameMatches = rolesForLicense?.some((role) => {
              return role.name.toLowerCase().includes(searchFilter.toLowerCase());
            });
          }

          return licenseNameMatches || roleNameMatches;
        });
        return filteredLicenses ?? [];
      })
    );

    const userLicenses$ = filteredLicenses$.pipe(
      map((licenses) => licenses?.filter((license) => license.userType === 'User'))
    );

    const automationLicenses$ = filteredLicenses$.pipe(
      map((licenses) => licenses?.filter((license) => license.userType === 'Automation'))
    );

    this.licensesDefinition.push({
      identifier: 'user',
      nls_key: 'LICENSING.LICENSE_FIXED_NAMES.USER',
      licenses$: userLicenses$
    });
    this.licensesDefinition.push({
      identifier: 'automation',
      nls_key: 'LICENSING.LICENSE_FIXED_NAMES.AUTOMATION',
      licenses$: automationLicenses$
    });
  }

  expandRow(license: LicenseInfo) {
    this.rowExpanded = this.rowExpanded === license.id ? null : license.id;

    this.rolesFacade.roles$.pipe(take(1)).subscribe((roles: RoleInfo[]) => {
      this.setRolesList(roles as RoleInfo[], license);
    });
  }

  addRolesToLicense(roles, license: LicenseInfo) {
    const roleIds: string[] = roles.items.map((role: RoleInfo) => role.id);
    roleIds.forEach((roleId) => {
      this.licensesFacade.addLicensesToRole(roleId, [license.id]);
    });
  }

  removeRoleFromLicense(roles, license: LicenseInfo) {
    const roleIds: string[] = roles.items.map((role: RoleInfo) => role.id);
    roleIds.forEach((roleId) => {
      this.licensesFacade.removeLicensesFromRole(roleId, [license.id]);
    });
  }

  private setRolesList(roles: RoleInfo[], license: LicenseInfo) {
    const licensesForUser = this.getRolesForLicense(license, roles);

    this.licenseRoles = licensesForUser;
    this.availableRoles = roles?.filter((role) => !licensesForUser.find((roleLicenses) => roleLicenses.id === role.id));
  }

  getRolesForLicense(license: LicenseInfo, roles: RoleInfo[]): RoleInfo[] {
    return roles.filter((role: RoleInfo) => {
      return license.roles.find((r) => r === role.id);
    });
  }

  public cancelBubble(event: Event) {
    event.stopPropagation();
  }

  public trackById(role: { id: string }) {
    return role.id;
  }
}
