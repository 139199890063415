import { Component, OnInit, HostListener, Inject, OnDestroy } from '@angular/core';
import { Subscription, Subject, combineLatest } from 'rxjs';
import { bufferCount, map, filter } from 'rxjs/operators';
import { FeatureFlagsFacade, TOKEN_FEATURE_FLAG } from '@vas/feature-flags';
import { CookieService } from 'ngx-cookie-service';
import { CommonFacade } from '../+state/common.facade';
import { Environment } from '../common.types';

@Component({
  selector: 'vas-common',
  template: ''
})
export class CommonComponent implements OnInit, OnDestroy {
  konamiSubscription$: Subscription;
  keysPressed$: Subject<string> = new Subject<string>();
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key) {
      this.keysPressed$.next(event.key);
    }
  }

  debugOrDevSubscription$: Subscription;
  persistedConsoleFunctions = {
    log: console.log,
    info: console.info,
    warn: console.warn
  };

  constructor(
    @Inject(FeatureFlagsFacade) public featureFlagFacade: FeatureFlagsFacade,
    @Inject(CookieService) public cookieService: CookieService,
    @Inject(CommonFacade) public commonFacade: CommonFacade,
    @Inject('ENVIRONMENT') private readonly environment?: Environment
  ) {}

  ngOnInit() {
    this.initEnvironment();
    this.initConsoleHandling();
    this.initKonamiCodeDetection();
    this.initFeatureFlagDetection();
  }

  private initEnvironment() {
    this.commonFacade.setEnvironment(this.environment?.appVersion ?? '-', this.environment?.production ?? true);
  }

  private initConsoleHandling() {
    this.debugOrDevSubscription$ = combineLatest([
      this.commonFacade.production$,
      this.featureFlagFacade.featureValue$('DEBUG_MODE')
    ])
      .pipe(map(([prod, debug]) => !prod || debug))
      .subscribe((isDebugOrDev) => {
        if (isDebugOrDev) {
          console.log = this.persistedConsoleFunctions.log;
          console.info = this.persistedConsoleFunctions.info;
          console.warn = this.persistedConsoleFunctions.warn;
        } else {
          console.log = console.info = console.warn = () => {};
        }
      });
  }

  private initKonamiCodeDetection() {
    const codes = [
      'ArrowUp',
      'ArrowUp',
      'ArrowDown',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'ArrowLeft',
      'ArrowRight',
      'a',
      'b'
    ];

    this.konamiSubscription$ = this.keysPressed$
      .pipe(
        bufferCount(10, 1),
        map((keys) => JSON.stringify(keys) === JSON.stringify(codes)),
        filter((valid) => valid === true)
      )
      .subscribe(() =>
        this.featureFlagFacade.setFeatureFlag({ flagName: 'DEBUG_MODE', value: true, precedence: 'runtime' })
      );
  }

  private initFeatureFlagDetection() {
    const cookies = this.cookieService.getAll();
    for (const cookie in cookies) {
      if (cookie) {
        if (cookie.includes(TOKEN_FEATURE_FLAG)) {
          let value: string | boolean = cookies[cookie];
          if (value === 'true' || value === 'false') {
            value = value === 'true';
          }
          const extractedFlagName = cookie.split(TOKEN_FEATURE_FLAG)[1] as string;
          this.featureFlagFacade.setFeatureFlag({ flagName: extractedFlagName, value: value, precedence: 'cookie' });
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.debugOrDevSubscription$.unsubscribe();
    this.konamiSubscription$.unsubscribe();
  }
}
