import { Action } from '@ngrx/store';
import { OrderFilterStateSlot, OrdersState, SideBarFilterState } from '../../order-management/+state/orders.reducer';
import { OrderFilter } from '../../order-management/order-filter-list/order-filter.types';
import { OrderSearchResult } from '../../order-management/order-search.types';
import { Order } from '../../order-management/order.types';
import { AggregateTree } from '../../shared/types/aggregateTree.types';
import { ConfigurationTypeIdentifiers, WorkspaceConfigurations } from '../workspace.types';
import { TableContentOrderLookupIdentifiers, TableContentPreprocessorIdentifiers } from './workspace.config.helpers';

export interface VehicleDetailConfig {
  searchAttributeIdNames?: string[];
}

export interface TestHierarchyAPImap {
  [entity: string]: string;
}

export interface TableRow {
  [columnIdentifier: string]: TableCell;
}

export type TableCell = {
  [key in OrderTableContentIdentifiers]?: TableCellEntry;
};

export type TableCellEntry = {
  originalValue?: string;
  processedValue?: string;
  modifiedValueForDisplay?: string;
  finalValue?: string;
  highlight?: boolean;
  downpop?: boolean;
  unit?: string;
};

export interface IdentifiableTableRow {
  content: TableRow;
  order_guid?: string;
}

type OrderStateToTableContentMapper = (state: OrdersState, order: Order) => any;
export enum OrderTableContentIdentifiers {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  QUATERNARY = 'quaternary',
  QUINARY = 'quinary'
}

export enum OrderTableContentValueSelectors {
  ORIGINAL = 'originalValue',
  PROCESSED = 'processedValue',
  DISPLAY = 'modifiedValueForDisplay',
  FINAL = 'finalValue'
}

export enum OrderTableContentSortingDirection {
  ASCENDING = 'ascending',
  DESCENDING = 'descending'
}

/* Workspace Types */
export enum WorkspaceConfigIdentifier {
  BASE = 'base',
  PASSBY = 'passby'
}

export const WORKSPACE_TAG_TYPE_NAME = 'VAS Order Workspace Type';

export interface SearchAttributeAggregateConfig {
  idName: string;
  child?: SearchAttributeAggregateConfig;
}

export type FuncAddToOrderFromSearchResultAggregation = (aggregates: AggregateTree[], order: Order) => void;

// TODO: Consolidate / ReWork Configuration
export interface WorkspaceConfig {
  table?: any;
  filters?: any;
  metaAttributesHideList: string[];
  searchFilterAttributeWorkspaceId: string;
  attributeLookups: {
    order: string;
    orderName: string;
    state: string;
    project: string;
    lastModified: string;
    description?: string;
    standards?: string; // lookup key for Standards, required for PassBy Norms
    testseries?: string;
  };
  customDefaultConfigs: {
    [identifier in ConfigurationTypeIdentifiers]?: WorkspaceConfigurations;
  };
  additionalSearchAttributeIdNames?: string[];
  searchAttributesAggregates?: SearchAttributeAggregateConfig[];
  addToOrderFromAggregation?: FuncAddToOrderFromSearchResultAggregation;
  testHierarchyAPImap?: TestHierarchyAPImap;
  sideBarFilterConfig?: SideBarFilterConfig;
  requireDevices: boolean;
  availableConfigurableTypes: ConfigurationTypeIdentifiers[];
}
export type WorkspaceConfigList = {
  [key in WorkspaceConfigIdentifier]: WorkspaceConfig;
};

export interface TestSeriesDetails {
  name: string;
  path: string;
}

export interface NormDefinition {
  testSeriesDetails: string[];
  resultInterpretations: NormParameters[];
}

export interface NormParameters {
  friendlyName: string;
  set: string;
  parameter: string;
  group: string;
  unit?: string;
}

export interface PassByNormConfiguration {
  testSeriesDetailSets?: { [lookupIdentifier: string]: TestSeriesDetails[] };
  normDefinitions?: { [normIdentifier: string]: NormDefinition };
  normMimeTypeTranslations?: { [mimeType: string]: string };
}

export interface NormValidationError {
  path: string;
  keyword: string;
  message: string;
  params: object;
}

export type NormLoadingState = 'Init' | 'NormsLoading' | 'NormsLoadSuccess' | 'NormLoadError' | 'NormValidationError';

// Attributes

// AttributeConfigGroup is read from .attributeConfig.json to group order attributes in the order detail view
// It is unrelated to the also available 'AttributeGroup'
interface AttributeConfigGroup {
  title: string;
  attributes: string[];
}

export type AttributesConfig = { attributeGroups: AttributeConfigGroup[] };

// OrderTableConfig

export type TableContentOrderLookupFn = (order: Order, state: OrdersState) => any;
export type TableContentOrderLookupsMapping = {
  [mappingIdentifier in TableContentOrderLookupIdentifiers]: TableContentOrderLookupFn;
};

export type TableContentPreprocessorFn = (value: string, order: Order, state: OrdersState) => any;
export type TableContentPreprocessorsMapping = {
  [mappingIdentifier in TableContentPreprocessorIdentifiers]: TableContentPreprocessorFn;
};
export enum TableContentDisplayModifiers {
  DATE = 'date',
  HIGHLIGHT = 'highlight',
  DOWNPOP = 'downpop'
}

export interface OrderTableConfig {
  columns: {
    [columnIdentifier: string]: {
      content: {
        [key in OrderTableContentIdentifiers]?: {
          attributeID?: string;
          lookup?: TableContentOrderLookupIdentifiers;
          processors?: TableContentPreprocessorIdentifiers[];
          displayModifiers?: TableContentDisplayModifiers[];
          unit?: string;
        };
      };
      header: {
        de: string;
        en: string;
        fr?: string;
        ja?: string;
      };
      fixed?: boolean;
      width?: number;
      minWidth?: number;
    };
  };
  sort?: {
    columnIdentifier: string;
    sortingDirection?: OrderTableContentSortingDirection;
  };
}

// Sidebars and Filters

export enum FilterIdentifier {
  'QUICK',
  'STATES',
  'PROJECTS',
  'STANDARDS'
}

export enum QuickFilterIdentifier {
  PendingDeletion = 'pending_deletion',
  RecentlyChanged = 'recently_changed'
}

export interface QuickFilter {
  identifier: QuickFilterIdentifier;
  value: boolean;
}

export type FilterMappingFunction = (
  result: OrderSearchResult,
  filterAgg: AggregateTree,
  slot?: OrderFilterStateSlot
) => Action;

export type OrderFilterMappingFunction = (state: SideBarFilterState) => OrderFilter;

export type SideBarFilterConfig = {
  [identifier in FilterIdentifier]?: {
    filterAggregateSearchResultToStateMapping: FilterMappingFunction;
    filterStateToOrderFilterMapping?: OrderFilterMappingFunction;
  };
};
