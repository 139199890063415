import { UserFacade } from './../../../../../user/+state/user.facade';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map, withLatestFrom } from 'rxjs/operators';
import { UsersLoaded } from './users.actions';

import { Store } from '@ngrx/store';
import * as UsersAction from './users.actions';

import { fetch } from '@nrwl/angular';
import { from } from 'rxjs/internal/observable/from';
import { createAppError } from '../../../../../app.factories';
import { UsersService } from '../../services/users.service';
import { UsersPartialState } from './users.reducer';
import { GroupsFacade } from '../groups/groups.facade';

@Injectable()
export class UsersEffects {
  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.LoadUsers),
      fetch({
        run: (action: ReturnType<typeof UsersAction.LoadUsers>, state: UsersPartialState) => {
          return from(this.usersService.getUsers()).pipe(map((users) => UsersLoaded({ users: users })));
        },

        onError: (action: ReturnType<typeof UsersAction.LoadUsers>, error) => {
          return createAppError(error);
        }
      })
    )
  );

  triggerLoadSpecialUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.UsersLoaded),
      map(() => UsersAction.LoadVibroLicenseUsers())
    )
  );

  addGroupsToUser = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.AddGroupsToUser),
      fetch({
        run: (action: ReturnType<typeof UsersAction.AddGroupsToUser>) => {
          return this.usersService.addGroupsToUser(action.userId, action.groupIds).pipe(
            map((groupResponse) => {
              const groupNames = groupResponse.map((res) => {
                return res.included?.find((data) => data.type === 'Group')?.attributes?.name || '';
              });

              return UsersAction.AddGroupsToUserSuccess({ userId: action.userId, groups: groupNames });
            })
          );
        },

        onError: (action: ReturnType<typeof UsersAction.AddGroupsToUser>, error) => {
          return createAppError(error);
        }
      })
    )
  );

  deleteUserGroups = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.DeleteUserGroups),
      withLatestFrom(this.userFacade.self$),
      map(([action, user]) => {
        if (user?.id === action.userId) {
          throw Error('Cannot remove groups from self');
        }

        return action;
      }),
      fetch({
        run: (action: ReturnType<typeof UsersAction.DeleteUserGroups>) => {
          return this.usersService.deleteUserGroups(action.userId, action.groupIds).pipe(
            withLatestFrom(this.groupsFacade.groups$),
            map(([res, groups]) => {
              const groupsToDelete = groups
                ?.filter(
                  (group) => action.groupIds.map((membershipId) => membershipId.split('_')[1]).indexOf(group.id) !== -1
                )
                .map((group) => group.name) as string[];
              return UsersAction.DeleteUserGroupsSuccess({ userId: action.userId, groups: groupsToDelete });
            })
          );
        },

        onError: (action: ReturnType<typeof UsersAction.DeleteUserGroups>, error) => {
          return createAppError(error);
        }
      })
    )
  );

  constructor(
    private usersService: UsersService,
    private actions$: Actions,
    private store: Store,
    private userFacade: UserFacade,
    private groupsFacade: GroupsFacade
  ) {}
}
