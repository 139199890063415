<cloud-search-bar></cloud-search-bar>
<div id="searchContent">
  <as-split
    unit="pixel"
    [direction]="'horizontal'"
    [disabled]="(commonFacade.uiWidth$ | async)! <= 1200 || !(depotSearchFacade.depotSearchReady$ | async)!"
    (dragEnd)="searchAggregateWidthChanged($event)"
  >
    <div *ngIf="(commonFacade.uiWidth$ | async)! > 1200; then expandedTree; else collapsedTree"></div>
    <ng-template #expandedTree>
      <as-split-area minSize="250" [size]="depotSearchFacade.aggregateTreeWidth$ | async" maxSize="600" [order]="0">
        <div id="searchAggregateTree" *ngIf="this.shouldDisplayAggregateTree$ | async">
          <cloud-search-aggregate-tree-wrapper></cloud-search-aggregate-tree-wrapper>
        </div>
      </as-split-area>
    </ng-template>
    <ng-template #collapsedTree>
      <as-split-area size="40" [order]="0">
        <div id="searchAggregateTree" *ngIf="this.shouldDisplayAggregateTree$ | async">
          <cloud-search-aggregate-tree-wrapper></cloud-search-aggregate-tree-wrapper>
        </div>
      </as-split-area>
    </ng-template>

    <as-split-area size="*" [order]="1">
      <div id="searchResultsTable">
        <cloud-search-result-table></cloud-search-result-table>
      </div>
    </as-split-area>
  </as-split>
</div>
