export const CLOUDROUTER_FEATURE_KEY = 'cloudRouter';

import * as RouterAction from './router.actions';
import { createReducer, Action } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { v4 as uuid } from 'uuid';

import { CloudRouterErrorDetail, CloudRouterInfoDetail, CloudRouterQueryParamErrorDetail } from './router.types';
import { getCurrentRouteIdentifier, getQueryParams } from './router.helpers';

export interface CloudRouterState {
  userNavigation?: {
    id: number;
    errors?: { details: (CloudRouterErrorDetail | CloudRouterQueryParamErrorDetail)[]; errorIdentifier: string };
    infos?: { details: CloudRouterInfoDetail[]; infoIdentifier: string };
    isRunning: boolean;
  };
  routerPersistance: {
    [routeIdentifier: string]: {
      [queryParamName: string]: string | string[];
    };
  };
}

export interface CloudRouterPartialState {
  readonly [CLOUDROUTER_FEATURE_KEY]: CloudRouterState;
}

export const initialState: CloudRouterState = { routerPersistance: {} };

const RouterReducer = createReducer(
  initialState,
  immerOn(RouterAction.UserNavigationStarted, (state, { userNavigationId, payload }) => {
    state.userNavigation = { id: userNavigationId, isRunning: true };
    const routeIdentifier = getCurrentRouteIdentifier(payload);
    if (routeIdentifier) {
      state.routerPersistance[routeIdentifier] = getQueryParams(payload);
    }
  }),
  immerOn(RouterAction.UserNavigationWasRedirected, (state, { userNavigationId, payload }) => {
    const routeIdentifier = getCurrentRouteIdentifier(payload);
    if (routeIdentifier) {
      state.routerPersistance[routeIdentifier] = getQueryParams(payload);
    }
  }),
  immerOn(RouterAction.CloudRouterError, RouterAction.CloudRouterQueryParamsError, (state, { error }) => {
    if (state.userNavigation!.errors) {
      state.userNavigation!.errors.details.push(error);
    } else {
      state.userNavigation!.errors = { details: [error], errorIdentifier: uuid() };
    }
  }),

  immerOn(RouterAction.CloudRouterInfo, (state, { info }) => {
    if (state.userNavigation!.infos) {
      state.userNavigation!.infos.details.push(info);
    } else {
      state.userNavigation!.infos = { details: [info], infoIdentifier: uuid() };
    }
  }),
  // immerOn(RouterAction.CloudRouterErrorHandled, (state, { error, changedTo }) => {
  //   const handledError = state.userNavigation!.errors.find(
  //     (err) =>
  //       err.routeIdentifier === error.routeIdentifier &&
  //       err.parameterName === error.parameterName &&
  //       err.requestedValue === error.requestedValue
  //   );
  //   if (!handledError) {
  //     console.error(
  //       `error for url parameter ${error.parameterName}=${error.requestedValue} on route ${error.routeIdentifier} was handled but it does not exist in the store`
  //     );
  //   } else {
  //     handledError.handled = changedTo;
  //   }
  // }),
  immerOn(RouterAction.UserNavigationFinishedSuccessfully, (state, { id, payload }) => {
    state.userNavigation!.isRunning = false;
  }),
  immerOn(RouterAction.CloudRouterTimeout, (state, { userNavigationId }) => {
    state.userNavigation!.isRunning = false;
    const error: CloudRouterErrorDetail = {
      routeIdentifier: '',
      reason: 'timeout'
    };
    if (state.userNavigation!.errors) {
      state.userNavigation!.errors.details.push(error);
    } else {
      state.userNavigation!.errors = { details: [error], errorIdentifier: uuid() };
    }
  })
);

export function reducer(state: CloudRouterState | undefined, action: Action) {
  return RouterReducer(state, action);
}
