<clr-modal
  #evtModal
  id="evtModal"
  [ngClass]="{ wide: showWidePAKinsightModal$ | async }"
  [(clrModalOpen)]="showPAKinsightModal"
  [clrModalStaticBackdrop]="false"
>
  <h3 class="modal-title plotting-title">
    {{ 'APP.APPS.PAK_INSIGHT_MODAL.HEADER' | translate : { app: PAKinsightName$ | async } }}
  </h3>
  <div class="modal-body">
    <div id="evtOpenWrapper">
      <ng-container *ngIf="userFacade.runsWindows$ | async">
        <a [href]="(PAKinsightOpenInLink$ | async)! | safe : 'url'">
          <div class="evtOpenButton enabled">
            <div class="evtHeader">
              <h6>
                {{ 'APP.APPS.PAK_INSIGHT_MODAL.OPEN_BUTTON_HEADER' | translate : { app: PAKinsightName$ | async } }}
              </h6>
              <clr-icon shape="pop-out"></clr-icon>
            </div>
            <p>
              {{ 'APP.APPS.PAK_INSIGHT_MODAL.OPEN_BUTTON_TEXT' | translate : { app: PAKinsightName$ | async } }}
            </p>
          </div>
        </a>

        <a
          *ngIf="featureFlagFacade.featureIsSet$('PAK_INSIGHT_DOWNLOAD') | async"
          [href]="featureFlagFacade.featureValue$('PAK_INSIGHT_DOWNLOAD') | async"
        >
          <div class="evtOpenButton enabled">
            <div class="evtHeader">
              <h6>
                {{ 'APP.APPS.PAK_INSIGHT_MODAL.INSTALL_BUTTON_HEADER' | translate : { app: PAKinsightName$ | async } }}
              </h6>

              <clr-icon shape="install"></clr-icon>
            </div>
            <p>
              {{ 'APP.APPS.PAK_INSIGHT_MODAL.INSTALL_BUTTON_TEXT' | translate : { app: PAKinsightName$ | async } }}
            </p>
          </div>
        </a>

        <a
          *ngIf="featureFlagFacade.featureIsSet$('PAK_INSIGHT_DOWNLOADANDUPDATE') | async"
          [href]="featureFlagFacade.featureValue$('PAK_INSIGHT_DOWNLOADANDUPDATE') | async"
        >
          <div class="evtOpenButton enabled">
            <div class="evtHeader">
              <h6>
                {{
                  'APP.APPS.PAK_INSIGHT_MODAL.DOWNLOAD_AND_UPDATE_BUTTON_HEADER'
                    | translate : { app: PAKinsightName$ | async }
                }}
              </h6>

              <clr-icon shape="recycle"></clr-icon>
            </div>
            <p>
              {{ 'APP.APPS.PAK_INSIGHT_MODAL.DOWNLOAD_AND_UPDATE_TEXT' | translate : { app: PAKinsightName$ | async } }}
            </p>
          </div>
        </a>
      </ng-container>
      <ng-container *ngIf="!(userFacade.runsWindows$ | async)">
        <div class="evtOpenButton disabled">
          <div class="evtHeader">
            <h6>{{
              'APP.APPS.PAK_INSIGHT_MODAL.OPEN_BUTTON_HEADER' | translate : { app: PAKinsightName$ | async }
            }}</h6>
            <clr-icon shape="pop-out"></clr-icon>
          </div>
          <p>
            {{ 'APP.APPS.PAK_INSIGHT_MODAL.OPEN_BUTTON_TEXT' | translate : { app: PAKinsightName$ | async } }}
          </p>
        </div>
        <a
          *ngIf="featureFlagFacade.featureIsSet$('PAK_INSIGHT_DOWNLOAD') | async"
          [href]="featureFlagFacade.featureValue$('PAK_INSIGHT_DOWNLOAD') | async"
        >
          <div class="evtOpenButton enabled">
            <div class="evtHeader">
              <h6>
                {{ 'APP.APPS.PAK_INSIGHT_MODAL.DOWNLOAD_BUTTON_HEADER' | translate : { app: PAKinsightName$ | async } }}
              </h6>

              <clr-icon shape="install"></clr-icon>
            </div>
            <p>
              {{ 'APP.APPS.PAK_INSIGHT_MODAL.DOWNLOAD_BUTTON_TEXT' | translate : { app: PAKinsightName$ | async } }}
            </p>
          </div>
        </a>
        <div
          class="evtOpenButton disabled"
          *ngIf="featureFlagFacade.featureIsSet$('PAK_INSIGHT_DOWNLOADANDUPDATE') | async"
        >
          <div class="evtHeader">
            <h6>
              {{
                'APP.APPS.PAK_INSIGHT_MODAL.DOWNLOAD_AND_UPDATE_BUTTON_HEADER'
                  | translate : { app: PAKinsightName$ | async }
              }}
            </h6>

            <clr-icon shape="recycle"></clr-icon>
          </div>
          <p>
            {{ 'APP.APPS.PAK_INSIGHT_MODAL.DOWNLOAD_AND_UPDATE_TEXT' | translate : { app: PAKinsightName$ | async } }}
          </p>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="modal-footer footer-flexbox-spacebetween no-padding justify-center">
    <p *ngIf="!(userFacade.runsWindows$ | async)">
      <sup>*</sup>{{ 'APP.APPS.PAK_INSIGHT_MODAL.REQUIRES_WINDOWS' | translate : { app: PAKinsightName$ | async } }}
    </p>
  </div>
</clr-modal>
