import { Pipe, PipeTransform } from '@angular/core';
import { DepotAttribute, SemanticDepotAttribute } from '../+state/attributes/attributes.types';

@Pipe({
  name: 'findAttributeById'
})
export class FindAttributeByIdPipe implements PipeTransform {
  constructor() {}

  // Explanation for the parameters:
  //   allAttributes: A list of all available DepotAttributes
  //   attributeId: The desired end of the id of the DepotAttribute we are looking for...
  //   aoBaseName: The ASAM-type, where the attribute should be found
  //   measurementPath: Any string that contains the depot id, in which the attribute should occur
  transform(
    allAttributes: DepotAttribute[],
    attributeId?: string,
    aoBaseName?: string,
    measurementPath?: string
  ): DepotAttribute | SemanticDepotAttribute | undefined {
    if (allAttributes && attributeId) {
      const exactMatch = allAttributes.find((attr) => attr.idName === attributeId);
      if (exactMatch) {
        return exactMatch;
      }
      const bestMatch = this.tryFindMatchingAttribute(allAttributes, attributeId, aoBaseName, measurementPath);
      if (bestMatch) {
        return bestMatch;
      }
    }
    if (attributeId) {
      return { discriminator: 'DepotAttribute', idName: attributeId, type: 'String', searchable: true };
    }
    return undefined;
  }

  tryFindMatchingAttribute(
    allAttributes: DepotAttribute[],
    attributeId: string,
    aoBaseName?: string,
    measurementPath?: string
  ): DepotAttribute | undefined {
    const allMatches = allAttributes.filter((attr) =>
      this.isMatchingAttribute(attr, attributeId, aoBaseName, measurementPath)
    );
    if (allMatches.length > 1) {
      console.warn('Multiple matching depot attributes found for id ' + attributeId);
    }
    return allMatches.pop();
  }

  isMatchingAttribute(
    attribute: DepotAttribute,
    attributeId: string,
    aoBaseName?: string,
    measurementPath?: string
  ): boolean {
    if (aoBaseName && measurementPath) {
      const aoBaseNameMatches = attribute.aoBaseName?.toLowerCase() === aoBaseName.toLowerCase();
      const usedInDepot = attribute.usedInDepots?.some((depot) => measurementPath.includes(depot.id)) ?? false;
      const idNameEndsWithAttributeId =
        attribute.idName === attributeId || attribute.idName.endsWith('.' + attributeId);
      return aoBaseNameMatches && usedInDepot && idNameEndsWithAttributeId;
    }
    return false;
  }
}
