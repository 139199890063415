import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import {
  ActivateContentCollection,
  AddContentCollectionItem,
  AddMultipleContentCollectionItems,
  ContentCollectionItemsDragged,
  CopyContentCollectionToClipboard,
  CopyContentCollectionToClipboardFeedback,
  CreateContentCollection,
  DeclineContentCollectionShare,
  DeleteContentCollection,
  DeleteContentCollectionShare,
  EditContentCollection,
  EditContentCollectionShare,
  EndContentCollectionCreationWithItems,
  HideContentCollectionDialog,
  LoadContentCollections,
  PrepareContentCollectionCreationWithItems,
  RemoveContentCollectionItem,
  ShareContentCollection,
  ShowContentCollectionDialog
} from './content-collection.actions';
import { ContentCollectionPartialState } from './content-collection.reducer';
import { contentCollectionQuery } from './content-collection.selectors';

import {
  ContentCollection,
  ContentCollectionDialog,
  ContentCollectionItem,
  ContentCollectionShareInfo
} from '../content-collection.types';

@Injectable()
export class ContentCollectionFacade {
  activeDialog$ = this.store.pipe(select(contentCollectionQuery.getContentCollectionActiveDialog));
  itemsForCreation$ = this.store.pipe(select(contentCollectionQuery.getCreateContentCollectionPrepItems));

  loaded$ = this.store.pipe(select(contentCollectionQuery.contentCollectionsLoaded));
  contentCollections$ = this.store.pipe(select(contentCollectionQuery.getAllContentCollections));
  ownContentCollections$ = this.store.pipe(select(contentCollectionQuery.getOwnContentCollections));
  sharedContentCollections$ = this.store.pipe(select(contentCollectionQuery.getSharedContentCollections));
  activeContentCollectionGuid$ = this.store.pipe(select(contentCollectionQuery.getActiveContentCollectionGuid));
  activeContentCollection$ = this.store.pipe(select(contentCollectionQuery.getActiveContentCollection));
  pakReferenceAvailable$ = this.store.pipe(select(contentCollectionQuery.getPakRefAvailability));
  activeContentCollectionLoaded$ = this.store.pipe(select(contentCollectionQuery.getActiveContentCollectionLoaded));
  pakCopySuccess$ = this.store.pipe(select(contentCollectionQuery.getPakCopySuccess));
  contentCollectionItemsDragged$ = this.store.pipe(select(contentCollectionQuery.getContentCollectionItemsDragged));

  itemInActiveContentCollection$ = (item: ContentCollectionItem) =>
    this.store.pipe(select(contentCollectionQuery.getItemInActiveContentCollection(item)));

  constructor(private store: Store<ContentCollectionPartialState>) {}

  loadContentCollections() {
    this.store.dispatch(LoadContentCollections());
  }

  showDialog(dialog: ContentCollectionDialog) {
    this.store.dispatch(ShowContentCollectionDialog({ dialog }));
  }

  hideDialog() {
    this.store.dispatch(HideContentCollectionDialog());
  }

  prepContentCollectionCreation(items: ContentCollectionItem[]) {
    this.store.dispatch(PrepareContentCollectionCreationWithItems({ items }));
  }

  endContentCollectionCreation() {
    this.store.dispatch(EndContentCollectionCreationWithItems());
  }

  createContentCollection(newCollection: ContentCollection, activate: boolean = false) {
    this.store.dispatch(CreateContentCollection({ collection: newCollection, activate: activate }));
  }

  deleteContentCollection(collectionToDelete: ContentCollection) {
    this.store.dispatch(DeleteContentCollection({ collection: collectionToDelete }));
  }

  editContentCollection(collectionToEdit: ContentCollection, name: string) {
    this.store.dispatch(EditContentCollection({ collection: collectionToEdit, name: name }));
  }

  activateContentCollection(collectionGuid: string) {
    this.store.dispatch(ActivateContentCollection({ collectionGuid: collectionGuid }));
  }

  shareContentCollection(collection: ContentCollection, shareInfo) {
    this.store.dispatch(ShareContentCollection({ collection, shareInfo }));
  }

  deleteContentCollectionShare(collection: ContentCollection, shareInfo) {
    this.store.dispatch(DeleteContentCollectionShare({ collection, shareInfo }));
  }

  editContentCollectionShare(collection: ContentCollection, shareInfo: ContentCollectionShareInfo) {
    this.store.dispatch(EditContentCollectionShare({ collection, shareInfo }));
  }

  declineContentCollectionShare(collectionId: string) {
    this.store.dispatch(DeclineContentCollectionShare({ collectionId }));
  }

  unDeclineContentCollectionShare(collectionId: string) {
    this.store.dispatch(DeclineContentCollectionShare({ collectionId }));
  }

  addContentCollectionItem(contentCollectionItem: ContentCollectionItem, collectionGuid: string) {
    this.store.dispatch(AddContentCollectionItem({ item: contentCollectionItem, collectionGuid: collectionGuid }));
  }

  addMultipleContentCollectionItems(contentCollectionItems: ContentCollectionItem[], collectionGuid: string) {
    this.store.dispatch(
      AddMultipleContentCollectionItems({ items: contentCollectionItems, collectionGuid: collectionGuid })
    );
  }

  deleteContentCollectionItem(contentCollectionItem: ContentCollectionItem, collectionGuid: string) {
    this.store.dispatch(RemoveContentCollectionItem({ item: contentCollectionItem, collectionGuid: collectionGuid }));
  }

  copyToClipboard(contentCollection: ContentCollection) {
    this.store.dispatch(CopyContentCollectionToClipboard({ contentCollection: contentCollection }));
  }

  resetCopySuccess(successsState: boolean) {
    this.store.dispatch(CopyContentCollectionToClipboardFeedback({ success: successsState }));
  }

  contentCollectionItemsDragged(contentCollectionItems: ContentCollectionItem[]) {
    this.store.dispatch(ContentCollectionItemsDragged({ contentCollectionItems }));
  }
}
