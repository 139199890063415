import { LicenseInfo, MyLicenseInfo } from './../types/license.types';
import { Inject, Injectable } from '@angular/core';
import { JsonApi, Spec } from '@muellerbbm-vas/grivet';
import { AngularHttpContext } from '@root/libs/vas-angular-http-context/src';
import { Observable, switchMap, from, mergeMap, toArray, map, of, forkJoin, tap } from 'rxjs';
import { ANGULAR_HTTP_CONTEXT } from '../../../../app.tokens';
import { CloudService } from '../../../../services/cloud.service';
import { lookupLicenseNames, lookupLicenseUserType } from './licenses-name-lookup';

@Injectable({
  providedIn: 'root'
})
export class LicensesService {
  constructor(@Inject(ANGULAR_HTTP_CONTEXT) private context: AngularHttpContext, private cloudService: CloudService) {}

  async ensureLicensesManagementAppStart() {
    return await this.cloudService.getAppStart('license');
  }

  getLicenses(): Observable<LicenseInfo[]> {
    return this.cloudService.getAppStartObs('license').pipe(
      switchMap((res) => {
        const licenseURL = new URL(
          res?.relationships['license_obtainment_rights'].links?.['related'].url.href ?? 'https://example.com'
        );

        return from(JsonApi.Document.fromURL(licenseURL, this.context));
      }),
      mergeMap((res) => {
        const licenses = res['includedResources']['CloudLicenseType'];

        return from(res.resources).pipe(
          toArray(),
          map((res) => {
            return res
              .map((data) => this.createLicense(data['rawData'], licenses))
              .filter((license) => !!license) as LicenseInfo[];
          })
        );
      })
    );
  }

  getSpecialUsersIDs(): Observable<any> {
    return this.cloudService.getAppStartObs('license').pipe(
      switchMap((res) => {
        const baseVibroLicenseURL = new URL(
          res?.relationships['vibro_licenses'].links?.related.url.href ?? 'https://example.com'
        );
        return from(JsonApi.Document.fromURL(baseVibroLicenseURL, this.context));
      }),
      map((res) => {
        const users = res.resources.map((item) => item.relationships?.['user']?.data) as any;
        const userIDs = users.map((user) => user.id);
        return userIDs;
      })
    );
  }

  getMyLicenses(): Observable<MyLicenseInfo[]> {
    return this.cloudService.getAppStartObs('license').pipe(
      switchMap((res) => {
        const licenseURL = new URL(
          res?.relationships['my_license_usage'].links?.['related'].url.href ?? 'https://example.com'
        );
        return from(JsonApi.Document.fromURL(licenseURL, this.context));
      }),
      switchMap(async (res) => {
        const myLicensesPromises: Promise<MyLicenseInfo>[] = res.resources.map(async (item) => {
          const relatedLicense = await item.relatedResource['license_type'];
          const userDisplayName = lookupLicenseNames(relatedLicense.id, { includePeriod: true });
          const adminDisplayName = lookupLicenseNames(relatedLicense.id, {
            includePeriod: true,
            includeUserType: true
          });
          const myLicense: MyLicenseInfo = {
            id: item.id,
            licenseTypeId: relatedLicense.id,
            name: item.attributes?.['name'],
            userDisplayName,
            adminDisplayName,
            start_time: item.attributes?.['start_time'],
            valid_until: item.attributes?.['valid_until']
          };
          return myLicense;
        });

        const myLicenses = await Promise.all(myLicensesPromises);
        return myLicenses;
      })
    );
  }

  addLicensesToRole(roleId: string, licenses: string[]) {
    return this.cloudService.getAppStartObs('license').pipe(
      switchMap((res: JsonApi.Resource | null) =>
        res ? from(res?.relatedResources['license_obtainment_rights']) : of([])
      ),
      toArray(),
      switchMap(([obtainmentRights]) => {
        const observables = licenses.map((licenseId) => {
          const obtainmentRight = obtainmentRights.find(
            (right) => (right?.relationships?.license_type?.data as Spec.ResourceObject)?.id === licenseId
          );

          const url = obtainmentRight?.rawData?.links?.self as string | URL;
          const roles = obtainmentRight?.rawData?.relationships?.roles?.data;

          const data = {
            id: obtainmentRight?.id,
            relationships: {
              roles: {
                data: [
                  ...(roles as Spec.ResourceIdentifierObject[]),
                  {
                    id: roleId,
                    type: 'Role'
                  }
                ]
              }
            },
            type: 'LicenseObtainmentRight'
          };

          return this.context.getDocumentWithHeaders(new URL(url)).then((res) => {
            const etag = res.headers.get('etag');

            const reqHeader = {
              'if-match': etag
            } as Record<string, string>;

            return this.context.patchDocument(new URL(url), { data }, reqHeader);
          });
        });

        return forkJoin(observables);
      })
    );
  }

  removeLicensesFromRole(roleId: string, licenses: string[]): Observable<any> {
    return this.cloudService.getAppStartObs('license').pipe(
      switchMap((res: JsonApi.Resource | null) =>
        res ? from(res?.relatedResources['license_obtainment_rights']) : of([])
      ),
      toArray(),
      switchMap(([obtainmentRights]) => {
        const observables = licenses.map((licenseId) => {
          const obtainmentRight = obtainmentRights.find(
            (right) => (right?.relationships?.license_type?.data as Spec.ResourceObject)?.id === licenseId
          );

          const url = obtainmentRight?.rawData?.links?.self as string | URL;
          const roles = (obtainmentRight?.rawData?.relationships?.roles?.data as Spec.ResourceIdentifierObject[]) || [];

          const data = {
            id: obtainmentRight?.id,
            relationships: {
              roles: {
                data: roles.filter((r) => r.id !== roleId)
              }
            },
            type: 'LicenseObtainmentRight'
          };

          return this.context.getDocumentWithHeaders(new URL(url)).then((res) => {
            const etag = res.headers.get('etag');

            const reqHeader = {
              'if-match': etag
            } as Record<string, string>;

            return this.context.patchDocument(new URL(url), { data }, reqHeader);
          });
        });

        return forkJoin(observables);
      })
    );
  }

  createLicense(licenseDoc: JsonApi.Resource['rawData'], licenseMap): LicenseInfo | null {
    const licenseTypeId: string = licenseDoc.relationships?.['license_type'].data?.['id'];

    let durationType = licenseMap[licenseTypeId]?.attributes?.duration_type;
    durationType = durationType === 'daily' ? 'Daily' : 'Monthly';

    const roleIds: string[] = (licenseDoc.relationships?.['roles']?.data as JsonApi.RelatedResource[]).map((r) => r.id);
    const userType = lookupLicenseUserType(licenseTypeId);
    const shouldIncludePeriodTypeInName = userType !== 'Automation';
    const name = lookupLicenseNames(licenseTypeId, {
      includePeriod: shouldIncludePeriodTypeInName,
      includeUserType: true
    });

    const license: LicenseInfo = {
      id: licenseTypeId,
      name: name,
      periodType: durationType,
      userType: userType,
      roles: roleIds
    };

    if (!license.name) {
      return null;
    }

    return license;
  }
}
