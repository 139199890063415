import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DepotAttribute, SemanticDepotAttribute } from '../shared/+state/attributes/attributes.types';
import validateAttributesSchema from '../workspace/config/attributeGroups.schema.json';
import validateNormsSchema from '../workspace/config/norms.schema.json';
import validateTableConfigSchema from '../workspace/config/orderTableConfig.schema.json';
// import { editor, languages, Range as MonacoRange } from 'monaco-editor';
// import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

export const MONACO_LOADED_EVENT = 'MONACO_LOADED';

@Injectable({
  providedIn: 'root'
})
export class MonacoService {
  public monaco: any;
  loaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  depotAttributes: DepotAttribute[] = [];
  semanticDepotAttributes: SemanticDepotAttribute[] = [];

  constructor() {
    const controller = new AbortController();
    window.addEventListener(
      'message',
      (event) => {
        if (event.data === MONACO_LOADED_EVENT) {
          controller.abort();
          this.onMonacoLoad();
        }
      },
      { signal: controller.signal }
    );
  }

  onMonacoLoad() {
    this.monaco = (window as any).monaco;

    // console.log('onMonacoLoad');

    // https://microsoft.github.io/monaco-editor/playground.html#extending-language-services-completion-provider-example
    const createDepotSearchAttributeProposals = (range: any) => {
      // returning a static list of proposals, not even looking at the prefix (filtering is done by the Monaco editor),
      // here you could do a server side lookup
      return [
        ...this.depotAttributes.map((attrib) => {
          return {
            label: attrib.displayName ? attrib.displayName + '(' + attrib.idName + ')' : attrib.idName,
            kind: 14, // languages.CompletionItemKind.Function, // languages.CompletionItemKind.Function = 1,     Value = 13,  Constant = 14,
            // documentation: 'The passby norm.',
            detail: 'Detail ' + attrib.idName,
            insertText: attrib.idName,
            range: range
          };
        }),
        ...this.semanticDepotAttributes.map((attrib) => {
          return {
            //  'Semantic '
            label: 'Semantic: ' + attrib.name + '(' + attrib.id + ')',
            kind: 14, // languages.CompletionItemKind.Function = 1,     Value = 13,  Constant = 14,
            // documentation: 'The passby norm.',
            detail: 'Detail ' + attrib.id,
            insertText: attrib.id,
            range: range
          };
        })
      ];
    };

    this.monaco.languages.registerCompletionItemProvider('json', {
      provideCompletionItems: function (model, position) {
        const textUntilPosition = model.getValueInRange({
          startLineNumber: 1,
          startColumn: 1,
          endLineNumber: position.lineNumber,
          endColumn: position.column
        });
        // const re = /"name"\s*:\s*\{\s*("[^"]*"\s*:\s*"[^"]*"\s*,\s*)*([^"]*)?$/;
        const re = /\s*"attributeID".*/; //TODO match dependent on configuration type
        // const match = textUntilPosition.match(re);

        const lineContent = model.getLineContent(position.lineNumber).trim();
        const match = lineContent.match(re);
        const wordAtPosition = model.getWordAtPosition(position);
        const word = model.getWordUntilPosition(position);
        console.log({ match }, { position }, { wordAtPosition }, { lineContent }, { model });
        if (!match) {
          return { suggestions: [] };
        }

        const range = {
          startLineNumber: position.lineNumber,
          endLineNumber: position.lineNumber,
          startColumn: word.startColumn,
          endColumn: word.endColumn
        };
        return {
          suggestions: JSON.parse(JSON.stringify(createDepotSearchAttributeProposals(range)))
        };
      }
    });

    // this.monaco.languages.registerHoverProvider('json', {
    //   provideHover: function (model, position) {
    //     const wordAtPosition = model.getWordAtPosition(position);
    //     console.log('Hover ', wordAtPosition);
    //     return wordAtPosition;
    //     return {
    //       range: new monaco.Range(1, 1, 1, 4),
    //       contents: [{ value: 'a' }, { value: 'b' }]
    //     };
    //   }
    // });

    this.monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [
        {
          fileMatch: ['**attributes**'],
          uri: 'schema://attributesSchema',
          schema: validateAttributesSchema
        },
        {
          fileMatch: ['**norms**'],
          uri: 'schema://normsSchema',
          schema: validateNormsSchema
        },
        {
          fileMatch: ['**table**'],
          uri: 'schema://table',
          schema: validateTableConfigSchema
        }
      ]
    });

    this.loaded$.next(true);
  }

  setDepotAttributeCompletions(depotAttributes: DepotAttribute[], semanticDepotAttributes: SemanticDepotAttribute[]) {
    this.depotAttributes = depotAttributes;
    this.semanticDepotAttributes = semanticDepotAttributes;
  }
}
