import { createAction, props } from '@ngrx/store';
import { RoleInfo, RoleToUserRelation } from '../../types/role.types';

export enum RoleActionTypes {
  LoadRoles = '[Roles] Load Roles',
  RolesLoaded = '[Roles] Roles Loaded',
  LoadEffectiveRoles = '[Roles] Load Effective Roles',
  EffectiveRolesLoaded = '[Roles] Effective Roles Loaded',
  DeleteUserRoles = '[Roles] Delete User Roles',
  DeleteUserRolesSuccess = '[Roles] User Roles Deleted',
  AddRolesToUser = '[Roles] Add Role To User',
  AddRolesToUserSuccess = '[Roles] Role To User Added',
  AddRolesToGroup = '[Roles] Add Roles To Group',
  AddRolesToGroupSuccess = '[Roles] Roles Added To Group',
  DeleteRolesFromGroup = '[Roles] Delete Roles From Group',
  DeleteRolesFromGroupSuccess = '[Roles] Roles Deleted From Group'
}

export const LoadRoles = createAction(RoleActionTypes.LoadRoles);
export const RolesLoaded = createAction(
  RoleActionTypes.RolesLoaded,
  props<{ roles: RoleInfo[]; roleToUserRelations?: RoleToUserRelation[] }>()
);
export const LoadEffectiveRoles = createAction(RoleActionTypes.LoadEffectiveRoles);
export const LoadEffectiveRolesSuccess = createAction(RoleActionTypes.EffectiveRolesLoaded, props<{ roles: any }>());
export const DeleteUserRoles = createAction(
  RoleActionTypes.DeleteUserRoles,
  props<{ userId: string; roleIds: string[] }>()
);
export const DeleteUserRolesSuccess = createAction(
  RoleActionTypes.DeleteUserRolesSuccess,
  props<{ userId: string; relationIds: string[] }>()
);
export const AddRolesToUser = createAction(
  RoleActionTypes.AddRolesToUser,
  props<{ userId: string; roleIds: string[] }>()
);
export const AddRolesToUserSuccess = createAction(
  RoleActionTypes.AddRolesToUserSuccess,
  props<{ userId: string; roleToUserRelations: RoleToUserRelation[] }>()
);
export const AddRolesToGroup = createAction(
  RoleActionTypes.AddRolesToGroup,
  props<{ groupId: string; roleIds: string[] }>()
);
export const AddRolesToGroupSuccess = createAction(
  RoleActionTypes.AddRolesToGroupSuccess,
  props<{ groupId: string; roleIds: string[] }>()
);
export const DeleteRolesFromGroup = createAction(
  RoleActionTypes.DeleteRolesFromGroup,
  props<{ groupId: string; roleIds: string[] }>()
);
export const DeleteRolesFromGroupSuccess = createAction(
  RoleActionTypes.DeleteRolesFromGroupSuccess,
  props<{ groupId: string; roleIds: string[] }>()
);
