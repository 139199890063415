import { noop, of, OperatorFunction, UnaryFunction, zip } from 'rxjs';
import { map, concatMap } from 'rxjs/operators';

// for typing chains like pipe and keepInput there might be a solution someday
// cf. https://github.com/microsoft/TypeScript/issues/30370
// currently support as many overloads as pipe

export function pipeFromArray<T, R>(fns: Array<UnaryFunction<T, R>>): UnaryFunction<T, R> {
  if (!fns) {
    return noop as UnaryFunction<any, any>;
  }

  if (fns.length === 1) {
    return fns[0];
  }

  return function piped(input: T): R {
    return fns.reduce((prev: any, fn: UnaryFunction<T, R>) => fn(prev), input);
  };
}

export function keepInput<A>(): OperatorFunction<A, [A]>;
export function keepInput<A, B>(todo1: OperatorFunction<A, B>): OperatorFunction<A, [A, B]>;
export function keepInput<A, B, C>(
  todo1: OperatorFunction<A, B>,
  todo2: OperatorFunction<B, C>
): OperatorFunction<A, [A, C]>;
export function keepInput<A, B, C, D>(
  todo1: OperatorFunction<A, B>,
  todo2: OperatorFunction<B, C>,
  todo3: OperatorFunction<C, D>
): OperatorFunction<A, [A, D]>;
export function keepInput<A, B, C, D, E>(
  todo1: OperatorFunction<A, B>,
  todo2: OperatorFunction<B, C>,
  todo3: OperatorFunction<C, D>,
  todo4: OperatorFunction<D, E>
): OperatorFunction<A, [A, E]>;
export function keepInput<A, B, C, D, E, F>(
  todo1: OperatorFunction<A, B>,
  todo2: OperatorFunction<B, C>,
  todo3: OperatorFunction<C, D>,
  todo4: OperatorFunction<D, E>,
  todo5: OperatorFunction<E, F>
): OperatorFunction<A, [A, F]>;
export function keepInput<A, B, C, D, E, F, G>(
  todo1: OperatorFunction<A, B>,
  todo2: OperatorFunction<B, C>,
  todo3: OperatorFunction<C, D>,
  todo4: OperatorFunction<D, E>,
  todo5: OperatorFunction<E, F>,
  todo6: OperatorFunction<F, G>
): OperatorFunction<A, [A, G]>;
export function keepInput<A, B, C, D, E, F, G, H>(
  todo1: OperatorFunction<A, B>,
  todo2: OperatorFunction<B, C>,
  todo3: OperatorFunction<C, D>,
  todo4: OperatorFunction<D, E>,
  todo5: OperatorFunction<E, F>,
  todo6: OperatorFunction<F, G>,
  todo7: OperatorFunction<G, H>
): OperatorFunction<A, [A, H]>;
export function keepInput<A, B, C, D, E, F, G, H, I>(
  todo1: OperatorFunction<A, B>,
  todo2: OperatorFunction<B, C>,
  todo3: OperatorFunction<C, D>,
  todo4: OperatorFunction<D, E>,
  todo5: OperatorFunction<E, F>,
  todo6: OperatorFunction<F, G>,
  todo7: OperatorFunction<G, H>,
  todo8: OperatorFunction<H, I>
): OperatorFunction<A, [A, I]>;
export function keepInput<A, B, C, D, E, F, G, H, I, J>(
  todo1: OperatorFunction<A, B>,
  todo2: OperatorFunction<B, C>,
  todo3: OperatorFunction<C, D>,
  todo4: OperatorFunction<D, E>,
  todo5: OperatorFunction<E, F>,
  todo6: OperatorFunction<F, G>,
  todo7: OperatorFunction<G, H>,
  todo8: OperatorFunction<H, I>,
  todo9: OperatorFunction<I, J>
): OperatorFunction<A, [A, J]>;
export function keepInput<A, B, C, D, E, F, G, H, I, J>(
  todo1: OperatorFunction<A, B>,
  todo2: OperatorFunction<B, C>,
  todo3: OperatorFunction<C, D>,
  todo4: OperatorFunction<D, E>,
  todo5: OperatorFunction<E, F>,
  todo6: OperatorFunction<F, G>,
  todo7: OperatorFunction<G, H>,
  todo8: OperatorFunction<H, I>,
  todo9: OperatorFunction<I, J>,
  ...todos: OperatorFunction<any, any>[]
): OperatorFunction<A, [A, any]>;
export function keepInput(...todo: OperatorFunction<any, any>[]): OperatorFunction<any, any> {
  return keepInputModified((input: any) => input, ...todo);
}

export function keepFirstInput<A, T extends any[]>(): OperatorFunction<[A, ...T], [A]>;
export function keepFirstInput<A, T extends any[], B>(
  todo1: OperatorFunction<[A, ...T], B>
): OperatorFunction<[A, ...T], [A, B]>;
export function keepFirstInput<A, T extends any[], B, C>(
  todo1: OperatorFunction<[A, ...T], B>,
  todo2: OperatorFunction<B, C>
): OperatorFunction<[A, ...T], [A, C]>;
export function keepFirstInput<A, T extends any[], B, C, D>(
  todo1: OperatorFunction<[A, ...T], B>,
  todo2: OperatorFunction<B, C>,
  todo3: OperatorFunction<C, D>
): OperatorFunction<[A, ...T], [A, D]>;
export function keepFirstInput<A, T extends any[], B, C, D, E>(
  todo1: OperatorFunction<[A, ...T], B>,
  todo2: OperatorFunction<B, C>,
  todo3: OperatorFunction<C, D>,
  todo4: OperatorFunction<D, E>
): OperatorFunction<[A, ...T], [A, E]>;
export function keepFirstInput<A, T extends any[], B, C, D, E, F>(
  todo1: OperatorFunction<[A, ...T], B>,
  todo2: OperatorFunction<B, C>,
  todo3: OperatorFunction<C, D>,
  todo4: OperatorFunction<D, E>,
  todo5: OperatorFunction<E, F>
): OperatorFunction<[A, ...T], [A, F]>;
export function keepFirstInput<A, T extends any[], B, C, D, E, F, G>(
  todo1: OperatorFunction<[A, ...T], B>,
  todo2: OperatorFunction<B, C>,
  todo3: OperatorFunction<C, D>,
  todo4: OperatorFunction<D, E>,
  todo5: OperatorFunction<E, F>,
  todo6: OperatorFunction<F, G>
): OperatorFunction<[A, ...T], [A, G]>;
export function keepFirstInput<A, T extends any[], B, C, D, E, F, G, H>(
  todo1: OperatorFunction<[A, ...T], B>,
  todo2: OperatorFunction<B, C>,
  todo3: OperatorFunction<C, D>,
  todo4: OperatorFunction<D, E>,
  todo5: OperatorFunction<E, F>,
  todo6: OperatorFunction<F, G>,
  todo7: OperatorFunction<G, H>
): OperatorFunction<[A, ...T], [A, H]>;
export function keepFirstInput<A, T extends any[], B, C, D, E, F, G, H, I>(
  todo1: OperatorFunction<[A, ...T], B>,
  todo2: OperatorFunction<B, C>,
  todo3: OperatorFunction<C, D>,
  todo4: OperatorFunction<D, E>,
  todo5: OperatorFunction<E, F>,
  todo6: OperatorFunction<F, G>,
  todo7: OperatorFunction<G, H>,
  todo8: OperatorFunction<H, I>
): OperatorFunction<[A, ...T], [A, I]>;
export function keepFirstInput<A, T extends any[], B, C, D, E, F, G, H, I, J>(
  todo1: OperatorFunction<[A, ...T], B>,
  todo2: OperatorFunction<B, C>,
  todo3: OperatorFunction<C, D>,
  todo4: OperatorFunction<D, E>,
  todo5: OperatorFunction<E, F>,
  todo6: OperatorFunction<F, G>,
  todo7: OperatorFunction<G, H>,
  todo8: OperatorFunction<H, I>,
  todo9: OperatorFunction<I, J>
): OperatorFunction<[A, ...T], [A, J]>;
export function keepFirstInput<A, T extends any[], B, C, D, E, F, G, H, I, J>(
  todo1: OperatorFunction<[A, ...T], B>,
  todo2: OperatorFunction<B, C>,
  todo3: OperatorFunction<C, D>,
  todo4: OperatorFunction<D, E>,
  todo5: OperatorFunction<E, F>,
  todo6: OperatorFunction<F, G>,
  todo7: OperatorFunction<G, H>,
  todo8: OperatorFunction<H, I>,
  todo9: OperatorFunction<I, J>,
  ...todos: OperatorFunction<any, any>[]
): OperatorFunction<[A, ...T], [A, any]>;
export function keepFirstInput(...todo: OperatorFunction<any, any>[]): OperatorFunction<any, any> {
  return keepInputModified((input: any) => {
    try {
      return input[Symbol.iterator]().next().value;
    } catch {
      return input;
    }
  }, ...todo);
}

// unfortunately the following overloads do not work
// keepInput and keepFirstInput are then unable to pass ...todo
// error message is "Expected at least 1 arguments, but got 1 or more"
// export function keepInputModified<A, Z>(inputReducer: (input: A) => Z): OperatorFunction<A, [Z]>;
// export function keepInputModified<A, B, Z>(
//   inputReducer: (input: A) => Z,
//   todo1: OperatorFunction<A, B>
// ): OperatorFunction<A, [Z, B]>;
// export function keepInputModified<A, B, C, Z>(
//   inputReducer: (input: A) => Z,
//   todo1: OperatorFunction<A, B>,
//   todo2: OperatorFunction<B, C>
// ): OperatorFunction<A, [Z, C]>;
// export function keepInputModified<A, B, C, D, Z>(
//   inputReducer: (input: A) => Z,
//   todo1: OperatorFunction<A, B>,
//   todo2: OperatorFunction<B, C>,
//   todo3: OperatorFunction<C, D>
// ): OperatorFunction<A, [Z, D]>;
// export function keepInputModified<A, B, C, D, E, Z>(
//   inputReducer: (input: A) => Z,
//   todo1: OperatorFunction<A, B>,
//   todo2: OperatorFunction<B, C>,
//   todo3: OperatorFunction<C, D>,
//   todo4: OperatorFunction<D, E>
// ): OperatorFunction<A, [Z, E]>;
// export function keepInputModified<A, B, C, D, E, F, Z>(
//   inputReducer: (input: A) => Z,
//   todo1: OperatorFunction<A, B>,
//   todo2: OperatorFunction<B, C>,
//   todo3: OperatorFunction<C, D>,
//   todo4: OperatorFunction<D, E>,
//   todo5: OperatorFunction<E, F>
// ): OperatorFunction<A, [Z, F]>;
// export function keepInputModified<A, B, C, D, E, F, G, Z>(
//   inputReducer: (input: A) => Z,
//   todo1: OperatorFunction<A, B>,
//   todo2: OperatorFunction<B, C>,
//   todo3: OperatorFunction<C, D>,
//   todo4: OperatorFunction<D, E>,
//   todo5: OperatorFunction<E, F>,
//   todo6: OperatorFunction<F, G>
// ): OperatorFunction<A, [Z, G]>;
// export function keepInputModified<A, B, C, D, E, F, G, H, Z>(
//   inputReducer: (input: A) => Z,
//   todo1: OperatorFunction<A, B>,
//   todo2: OperatorFunction<B, C>,
//   todo3: OperatorFunction<C, D>,
//   todo4: OperatorFunction<D, E>,
//   todo5: OperatorFunction<E, F>,
//   todo6: OperatorFunction<F, G>,
//   todo7: OperatorFunction<G, H>
// ): OperatorFunction<A, [Z, H]>;
// export function keepInputModified<A, B, C, D, E, F, G, H, I, Z>(
//   inputReducer: (input: A) => Z,
//   todo1: OperatorFunction<A, B>,
//   todo2: OperatorFunction<B, C>,
//   todo3: OperatorFunction<C, D>,
//   todo4: OperatorFunction<D, E>,
//   todo5: OperatorFunction<E, F>,
//   todo6: OperatorFunction<F, G>,
//   todo7: OperatorFunction<G, H>,
//   todo8: OperatorFunction<H, I>
// ): OperatorFunction<A, [Z, I]>;
// export function keepInputModified<A, B, C, D, E, F, G, H, I, J, Z>(
//   inputReducer: (input: A) => Z,
//   todo1: OperatorFunction<A, B>,
//   todo2: OperatorFunction<B, C>,
//   todo3: OperatorFunction<C, D>,
//   todo4: OperatorFunction<D, E>,
//   todo5: OperatorFunction<E, F>,
//   todo6: OperatorFunction<F, G>,
//   todo7: OperatorFunction<G, H>,
//   todo8: OperatorFunction<H, I>,
//   todo9: OperatorFunction<I, J>
// ): OperatorFunction<A, [Z, J]>;
// export function keepInputModified<A, B, C, D, E, F, G, H, I, J, Z>(
//   inputReducer: (input: A) => Z,
//   todo1: OperatorFunction<A, B>,
//   todo2: OperatorFunction<B, C>,
//   todo3: OperatorFunction<C, D>,
//   todo4: OperatorFunction<D, E>,
//   todo5: OperatorFunction<E, F>,
//   todo6: OperatorFunction<F, G>,
//   todo7: OperatorFunction<G, H>,
//   todo8: OperatorFunction<H, I>,
//   todo9: OperatorFunction<I, J>,
//   ...todos: OperatorFunction<any, any>[]
// ): OperatorFunction<A, [Z, any]>;
export function keepInputModified(
  inputReducer: (input: any) => any,
  ...todo: OperatorFunction<any, any>[]
): OperatorFunction<any, any> {
  if (todo.length === 0) {
    return (input$) => input$.pipe(map((input) => [inputReducer(input)]));
  }

  // sadly it is currently not possible to use spread arguments with pipe
  // cf. https://github.com/ReactiveX/rxjs/issues/3989
  return (input$) =>
    input$.pipe(
      concatMap((input) => zip(of(inputReducer(input)), pipeFromArray([...todo])(of(input)))) // would be nice if input could be used inside todo
    );
}
