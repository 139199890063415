import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { TranslateModule } from '@ngx-translate/core';

import { IntegratedStreamingComponent } from './integrated-streaming/integrated-streaming.component';
import { ExternalStreamingInteractionsComponent } from './integrated-streaming/external-streaming-interactions/external-streaming-interactions.component';

import { translateLoader } from '../app.translate';
import { SharedModule } from '../shared/shared.module';
import { StreamingFacade } from './+state/streaming.facade';
import { StoreModule } from '@ngrx/store';
import {
  STREAMING_FEATURE_KEY,
  initialState as streamingInitialState,
  reducer as streamingReducer
} from './+state/streaming.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StreamingEffects } from './+state/streaming.effects';

export const streamingImports: any[] = [
  CommonModule,
  ClarityModule,
  TranslateModule.forChild(translateLoader),
  SharedModule,
  StoreModule.forFeature(STREAMING_FEATURE_KEY, streamingReducer, { initialState: streamingInitialState }),
  EffectsModule.forFeature([StreamingEffects])
];

@NgModule({
  declarations: [IntegratedStreamingComponent, ExternalStreamingInteractionsComponent],
  imports: streamingImports,
  exports: [IntegratedStreamingComponent],
  providers: [StreamingFacade],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class StreamingModule {}
