<div id="debugHeader" data-cy="debugHeader">
  <clr-dropdown [clrCloseMenuOnItemClick]="false" data-cy="debugDropdown">
    <button clrDropdownTrigger class="debug-btn btn" aria-label="Debug Dropdown button"
      ><clr-icon shape="bug"></clr-icon>
    </button>
    <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
      <label class="dropdown-header">Version Info</label>
      <div id="versions">
        <div>WebApp Version: {{ commonFacade.appVersion$ | async }}</div>
        <div>
          Server Version:
          {{
            (appFacade.serverVersion$ | async) !== undefined
              ? (appFacade.serverVersion$ | async)
              : ('APP.UNKNOWN' | translate)
          }}</div
        >
      </div>

      <div id="flagContainer">
        <div id="activeFeatureFlags">
          <label class="dropdown-header">Active Feature Flags</label>
          <table class="table table-compact table-noborder">
            <thead>
              <tr>
                <th class="left">FlagName</th>
                <th class="left">Value</th>
                <th>Precedence</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let flag of activeFeatureFlags$ | async | keyvalue; trackBy: trackByFn">
                <tr *ngIf="flag.value?.area !== 'test'">
                  <td class="left">
                    <span [title]="flag.value?.descr" [ngClass]="{ invalidFlagValue: isInvalidValue(flag.value) }">{{
                      flag.key
                    }}</span>
                    <clr-tooltip>
                      <clr-icon
                        class="info-icon"
                        clrTooltipTrigger
                        shape="info-circle"
                        size="16"
                        aria-label="Tooltip"
                      ></clr-icon>
                      <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
                        <span>{{ flag.value?.descr }}</span>
                      </clr-tooltip-content>
                    </clr-tooltip>
                  </td>
                  <td class="value" *ngIf="flag.value?.datatype === 'boolean'; else nonBooleanFlag">
                    <div
                      class="booleanFlagToggle"
                      [ngClass]="{ off: flag.value.value === false, on: flag.value.value === true }"
                      [attr.data-cy]="flag.key"
                      (click)="handleFlagClicked($event, flag.value)"
                    >
                      {{ flag.value.value }}
                    </div>
                  </td>
                  <ng-template #nonBooleanFlag>
                    <td class="value" *ngIf="!flag.value.suppressValueConsoleLog; else suppressValue">
                      <div [ngClass]="{ 'clr-control-container': true, 'clr-error': isInvalidValue(flag.value) }">
                        <div class="clr-input-wrapper">
                          <input
                            clrInput
                            class="inlineInputField"
                            type="text"
                            [value]="flag.value.value"
                            (keyup)="inputValueChanged($event, flag.value)"
                          />
                        </div>
                      </div>
                    </td>
                    <ng-template #suppressValue>
                      <td class="value">
                        <div title="Value is hidden">Hidden</div>
                      </td>
                    </ng-template>
                  </ng-template>
                  <td>{{ flag.value?.precedence }}</td>
                  <td>
                    <clr-icon
                      shape="floppy"
                      *ngIf="flag.value?.precedence === 'runtime' && !isInvalidValue(flag.value)"
                      class="interactionIcon"
                      title="Persist value as cookie"
                      (click)="persistAsCookie(flag.value)"
                    ></clr-icon>
                    <clr-icon
                      shape="trash"
                      *ngIf="flag.value?.precedence === 'cookie'"
                      class="interactionIcon"
                      title="Remove cookie"
                      (click)="clearCookie(flag.value)"
                    ></clr-icon>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>

        <div id="otherFeatureFlags">
          <label class="dropdown-header">Other available Flags</label>
          <table class="table table-compact table-noborder">
            <thead>
              <tr>
                <th class="left">FlagName</th>
                <th>Type</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let flag of remainingFeatureFlags$ | async">
                <tr *ngIf="flag.value?.area !== 'test'">
                  <td class="left">
                    <span [title]="flag.descr">{{ flag.id }}</span>
                    <clr-tooltip>
                      <clr-icon
                        class="info-icon"
                        clrTooltipTrigger
                        shape="info-circle"
                        size="16"
                        aria-label="Tooltip"
                      ></clr-icon>
                      <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
                        <span>{{ flag.descr }}</span>
                      </clr-tooltip-content>
                    </clr-tooltip>
                  </td>
                  <td>{{ flag.datatype }}</td>
                  <td>
                    <clr-icon
                      shape="plus-circle"
                      class="is-solid interactionIcon"
                      title="Activate Feature Flag"
                      (click)="addFlag(flag)"
                    ></clr-icon>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </clr-dropdown-menu>
  </clr-dropdown>
</div>
