<as-split
  [direction]="'horizontal'"
  [unit]="'pixel'"
  #split
  *ngIf="showContainer"
  (dragEnd)="searchAggregateWidthChanged($event)"
>
  <as-split-area
    [size]="appFacade.attributeTreeWidth$ | async"
    [minSize]="atwConstraints.min"
    [maxSize]="atwConstraints.max"
  >
    <ng-container *ngFor="let tree of trees">
      <h4 [attr.data-cy]="'Treename_' + tree.name">{{ tree.name }}</h4>
      <cloud-panel-order-content-tree
        [tree]="tree"
        (nodeSelected)="selectNode($event)"
      ></cloud-panel-order-content-tree>
    </ng-container>
  </as-split-area>
  <as-split-area size="*">
    <cloud-panel-detail-key-value-display [content]="selectedNode?.content"></cloud-panel-detail-key-value-display>
  </as-split-area>
</as-split>
