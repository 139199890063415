import { ProcessingFacade } from './../../processing/+state/processing.facade';
import {
  CheckedProcessingInputStream,
  CheckedProcessingParameter,
  ProcessingFormula,
  ProcessingInputDataset,
  ProcessingRequestSource,
  ValueTypeConstraint
} from './../processing.types';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, combineLatest, merge, Observable, Subject, Subscription } from 'rxjs';
import { auditTime, debounceTime, filter, map, pairwise, startWith, tap, withLatestFrom } from 'rxjs/operators';
import { DatasetDescription, Measurement } from '../../measurements/measurements.types';
import { MeasurementsFacade } from '../../measurements/+state/measurements.facade';
import { StreamingFacade } from '../../streaming/+state/streaming.facade';
import { FeatureFlagsFacade } from '@root/libs/feature-flags/src';
import { AppFacade } from '../../+state/app.facade';
import {
  createTooltip,
  checkParam,
  checkSource,
  checkParameterDependencyType,
  recursivelyInjectSingleDependencyParamsIntoRespectiveSortedParams
} from './dynamic-formula.calculations';

import { CloudAppNames } from '../../app.types';
import { keepInput } from '../../shared/operators';
import { UntypedFormControl, NgForm } from '@angular/forms';
import { FormulaParameterValidatorDirective } from '../formula-parameter-validator.directive';
import { IntegratedStreamingComponent } from '../../streaming/integrated-streaming/integrated-streaming.component';
import { encodeMeasurementTokenContainerID } from '../../streaming/utils/streaming-token.types';
import { StreamingType } from '../../streaming/utils/streaming.types';

type RunButtonState = 'DISABLED' | 'READY' | 'PROCESSING' | 'STREAMING' | 'SUCCESS' | 'ERROR';

@Component({
  selector: 'cloud-analysis-editor',
  templateUrl: './analysis-editor.component.html',
  styleUrls: ['./analysis-editor.component.css']
})
export class AnalysisEditorComponent implements OnInit, OnDestroy {
  ANALYSES_DATATYPE = 'analyses';
  errorShape = 'error-standard';
  @Input() showAnalysisEditor$: BehaviorSubject<boolean>;
  @Input() showFormulaInput$: BehaviorSubject<boolean>;
  changeFormulaParameters$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  changeFormulaSources$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  subs: Subscription[] = [];

  dropdownOpen: boolean[] = []; // Keeps track of open dropdowns

  public dataSets$: Observable<DatasetDescription[] | undefined>;
  public filteredDataSets$: Observable<ProcessingInputDataset[]> = new Observable();
  public selectedMeasurementId$: Observable<string>;
  public datatypes$: Observable<string[]>;
  public datatypeFilter$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public isProcessingAvailable$: Observable<boolean>;
  public allSelectedFormulasHaveValue$: Observable<boolean>;
  public runButtonTitle$: Observable<string>;
  public runButtonErrorTitle$: Observable<string>;

  dataset: DatasetDescription;
  sourceInput: DatasetDescription;
  selectedDataset$: BehaviorSubject<DatasetDescription | undefined>;
  selectedDatasetID$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  showAnalysisEditorBar$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  sortedFormulas$: Observable<ProcessingFormula[]>;
  conditionCheckedParameters$: Observable<CheckedProcessingParameter[]>;
  sortedAndConditionCheckedParameters$: Observable<CheckedProcessingParameter[]>;
  conditionCheckedSources$: Observable<CheckedProcessingInputStream[]>;
  sortedAndConditionCheckedSources$: Observable<CheckedProcessingInputStream[]>;

  selectFormula$: BehaviorSubject<ProcessingFormula | null> = new BehaviorSubject(null);
  startProcessing$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  cancelProcessing$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  sourceChanged$: BehaviorSubject<[string, string]> = new BehaviorSubject(['', '']);
  parameterChanged$: BehaviorSubject<CheckedProcessingParameter | undefined> = new BehaviorSubject(undefined);
  resetAvailable$: Observable<boolean>;
  resetForm$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  useLateToken$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  processingState$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  runButtonState$: Observable<RunButtonState>;

  manualTriggerDatasetStream$: Subject<DatasetDescription> = new Subject<DatasetDescription>();
  streamDataset$: Observable<boolean> = new Observable();
  streamProcessing$: Observable<boolean> = new Observable();
  showStream$: Observable<boolean> = new Observable();

  @ViewChild('streamingComponent', { static: true }) streamingComponent: IntegratedStreamingComponent;
  @ViewChild('parameterForm', { static: false }) parameterForm: NgForm;

  vconstraints$: Observable<{ [name: string]: (ValueTypeConstraint | undefined)[] }>;
  hasConstraints$: Observable<{ [name: string]: boolean }>;

  constructor(
    public appFacade: AppFacade,
    public translate: TranslateService,
    public processingFacade: ProcessingFacade,
    public measurementsFacade: MeasurementsFacade,
    public streamingFacade: StreamingFacade,
    public featureFlagFacade: FeatureFlagsFacade,
    public cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.runButtonState$ = combineLatest([
      this.streamingFacade.currentStreamingPackageIsStreaming$,
      this.processingFacade.currentProcessing$
    ]).pipe(
      auditTime(250),
      map(([streaming, processing]) => {
        let result: RunButtonState = 'READY';
        if (processing.status === 'canceled') {
          result = 'READY';
        } else if (processing.status === 'active') {
          result = 'PROCESSING';
        } else if (processing.status === 'error') {
          result = 'ERROR';
        } else if (streaming) {
          result = 'STREAMING';
        }

        return result;
      })
    );

    this.sortedFormulas$ = this.processingFacade.availableFormulas$.pipe(
      filter((formulas) => !!formulas),
      map((formulas) => formulas.map((item) => JSON.parse(JSON.stringify(item)))), // decouple from store
      map((formulas) => formulas.slice().sort((a, b) => a.name.localeCompare(b.name)))
    );

    this.conditionCheckedParameters$ = this.processingFacade.selectedFormula$.pipe(
      filter((formula) => formula !== undefined),
      map((formula) => {
        const checkedParameters: CheckedProcessingParameter[] = formula!.parameters.map((parameter) => {
          const checkresult = checkParam(parameter, formula!);
          const result: CheckedProcessingParameter = {
            ...parameter,
            conditionsFullfilled: checkresult.fullfillment,
            dependent: parameter.condition !== undefined,
            parents: checkresult.relevantParent,
            dependencyToolTip: createTooltip(formula!.parameters, this.translate, checkresult.relevantParent)
          };
          return result;
        });
        return checkedParameters;
      })
    );

    this.conditionCheckedSources$ = this.processingFacade.selectedFormula$.pipe(
      filter((formula) => formula !== undefined),
      map((formula) => {
        const checkedSources: CheckedProcessingInputStream[] = formula!.sources.map((source) => {
          const checkresult = checkSource(source, formula!);
          const result: CheckedProcessingInputStream = {
            ...source,
            conditionsFullfilled: checkresult.fullfillment,
            parents: checkresult.relevantParent,
            dependencyToolTip: createTooltip(formula!.parameters, this.translate, checkresult.relevantParent)
          };
          return result;
        });
        this.processingFacade.changeFormulaInputSources(formula!.id, checkedSources);
        return checkedSources.filter((item) => item.conditionsFullfilled);
      })
    );

    this.sortedAndConditionCheckedParameters$ = this.conditionCheckedParameters$.pipe(
      withLatestFrom(this.processingFacade.selectedFormula$),
      filter(([_, formula]) => formula !== null),
      map(([parameters, formula]) => {
        const independentParameters = parameters.filter((param) => param.condition === undefined);
        const dependentParameters = parameters.filter((param) => param.condition !== undefined);

        const singleDependencyParameters = dependentParameters.filter(
          (param) => checkParameterDependencyType(param, formula!) === 'single'
        );
        const multiDependencyParameters = dependentParameters.filter(
          (param) => checkParameterDependencyType(param, formula!) === 'multi'
        );

        let sortedParameters = independentParameters.sort((paramA, paramB) =>
          paramA.name.toLocaleLowerCase > paramB.name.toLocaleLowerCase ? -1 : 1
        );

        sortedParameters = recursivelyInjectSingleDependencyParamsIntoRespectiveSortedParams(
          singleDependencyParameters,
          sortedParameters
        );

        sortedParameters.push(
          ...multiDependencyParameters.sort((paramA, paramB) =>
            paramA.name.toLocaleLowerCase > paramB.name.toLocaleLowerCase ? -1 : 1
          )
        );

        return sortedParameters;
      })
    );

    this.resetAvailable$ = this.processingFacade.selectedFormula$.pipe(
      filter((formula) => formula !== undefined),
      keepInput(map((formula) => formula!.parameters.some((param) => !param.default_is_set && param.value !== ''))),
      keepInput(map(([formula, _]) => formula!.sources.some((source) => source.value !== undefined))),
      map(([[_, parameterResetAvailable], sourceResetAvailable]) => parameterResetAvailable || sourceResetAvailable)
    );

    this.isProcessingAvailable$ = combineLatest([
      this.featureFlagFacade.featureIsSet$('PROCESSING_FORMULA'),
      this.appFacade.getIsCloudAppAvailable$(CloudAppNames.processing)
    ]).pipe(
      map(([featureProcessing, cloudProcessing]) => {
        return featureProcessing && cloudProcessing;
      })
    );

    this.allSelectedFormulasHaveValue$ = this.processingFacade.selectedFormula$.pipe(
      map((selectedFormula) => {
        const unsetSourceFound = selectedFormula?.sources.some((source) => {
          return source.conditionsFullfilled ? !source.value : false;
        });
        return !unsetSourceFound;
      })
    );

    this.runButtonTitle$ = combineLatest([this.allSelectedFormulasHaveValue$, this.parameterChanged$]).pipe(
      withLatestFrom(this.processingFacade.selectedFormula$),
      map(([[hasSources, _], selectedFormula]) => {
        const basicMessage = `${this.translate.instant('PROCESSING.RUNPROCESSING')}: ${selectedFormula?.name}`;
        const errorMessages: string[] = [];
        if (!hasSources) {
          errorMessages.push(this.translate.instant('PROCESSING.SOURCENOTSET'));
        }
        if (!(this.parameterForm?.valid ?? true)) {
          errorMessages.push(this.translate.instant('PROCESSING.INVALIDPARAMS'));
        }

        let message = basicMessage;
        errorMessages.forEach((err) => (message = message.concat('\n\n| ' + err)));

        return message;
      })
    );

    this.runButtonErrorTitle$ = combineLatest([
      this.processingFacade.selectedFormula$,
      this.processingFacade.currentProcessing$
    ]).pipe(
      map(([selectedFormula, currentProcessing]) => {
        let message = `${this.translate.instant('PROCESSING.FAILED')}: ${selectedFormula?.name}`;
        if (currentProcessing.status === 'error' && currentProcessing.errorMessage) {
          message = message.concat('\n' + currentProcessing.errorMessage);
        }
        return message;
      })
    );

    this.selectedMeasurementId$ = this.measurementsFacade.selectedMeasurement$.pipe(
      map((selectedMeasurement) => selectedMeasurement?.resources?.overview?.measurementId ?? '')
    );

    this.dataSets$ = this.measurementsFacade.selectedMeasurement$.pipe(
      map((selectedMeasurement) => selectedMeasurement?.resources?.['datasetDescription']?.datasets)
    );

    this.datatypes$ = combineLatest([this.dataSets$, this.selectedMeasurementId$]).pipe(
      map(([datasets, _]) => datasets),
      map((datasets) => {
        let sortetDataTypes: string[] = [];
        if (datasets) {
          const datatypes: string[] = datasets?.map((set) => set.attributes['datatype'] as string) ?? [];
          sortetDataTypes = [...new Set(datatypes)].sort();
        }
        return sortetDataTypes;
      })
    );

    this.filteredDataSets$ = this.processingFacade.filteredProcessingDatasets$.pipe(
      map((filteredProcessingDataSets) => {
        let filteredDatasets: ProcessingInputDataset[] = [];
        filteredDatasets = filteredProcessingDataSets.length > 0 ? filteredProcessingDataSets : [];
        return filteredDatasets;
      })
    );

    this.vconstraints$ = this.processingFacade.selectedFormula$.pipe(
      filter((formula) => formula !== null),
      map((formula) => formula!.parameters),
      map((parameters) => {
        const vconstraints: { [paramName: string]: (ValueTypeConstraint | undefined)[] } = {};
        parameters.forEach(
          (param) => (vconstraints[param.name] = param.value_types.flatMap((item) => item.constraints))
        );
        return vconstraints;
      })
    );

    this.hasConstraints$ = this.vconstraints$.pipe(
      map((vconstraints) => {
        const ret = {};
        Object.keys(vconstraints).forEach(
          (name) => (ret[name] = vconstraints[name] ? vconstraints[name][0] !== undefined : false)
        );
        return ret;
      })
    );

    this.subs.push(
      this.datatypes$
        .pipe(
          filter((datatypes) => datatypes.length > 0),
          withLatestFrom(this.datatypeFilter$)
        )
        .subscribe(([datatypes, datatypeFilter]) => {
          if (!datatypes.includes(datatypeFilter)) {
            this.datatypeFilter$.next('');
          }
        }),
      this.showFormulaInput$.subscribe((showbar) => {
        this.showAnalysisEditorBar$.next(showbar);
      })
      /*       this.streamingFacade.getStreamingStatus$.subscribe((val) => {
        setTimeout(() => {
          this.cdRef.detectChanges(); // necessary for the changeDetection to work
        }, 0);
      }) */
    );

    this.subs.push(
      this.startProcessing$
        .pipe(
          filter((shouldStart) => shouldStart),
          withLatestFrom(this.processingFacade.selectedFormula$),
          map(([_, selectedFormula]) => selectedFormula),
          filter((selectedFormula) => selectedFormula !== null),
          keepInput(
            map((selectedFormula) => (selectedFormula as ProcessingFormula)!.sources),
            map((sources) => {
              return sources.filter((source) => {
                return source.conditionsFullfilled;
              });
            }),

            filter((sources) => sources.every((source) => source.value)),

            map((sources) => {
              return sources.map((source) => {
                return {
                  sourceName: source.source_name,
                  depotId: source.value?.depotId,
                  datasetId: source.value?.id
                } as ProcessingRequestSource;
              });
            })
          ),
          tap(([selectedFormula, sources]) => {
            this.prepareProcessingStream();
          }),
          tap(([selectedFormula, sources]) => this.processingFacade.startProcessing(selectedFormula!, sources))
        )
        .subscribe()
    );

    this.subs.push(
      this.cancelProcessing$
        .pipe(
          filter((shouldCancel) => shouldCancel),
          withLatestFrom(this.processingFacade.currentProcessing$),
          map(([_, processing]) => processing.url),
          filter((url) => url !== null),
          tap((url) => this.processingFacade.cancelProcessing(url!))
        )
        .subscribe()
    );

    this.subs.push(
      this.sourceChanged$
        .pipe(
          //(datasetID: string, inputSource: string)
          keepInput(
            withLatestFrom(this.dataSets$),
            map(([[datasetID, _], datasets]) => {
              return datasets?.find((item) => item.id === datasetID);
            }),
            filter((dataset) => dataset !== undefined),
            map((dataset) => {
              return {
                id: dataset!.id,
                depotId: dataset!.depotId,
                attributes: dataset!.attributes,
                PAKParameters: dataset!.PAKParameters
              };
            })
          ),
          tap(([[_, inputSource], dataset]) => {
            this.processingFacade.changeSelectedFormulaInputSources([{ sourceName: inputSource, dataset: dataset }]);
          }),
          withLatestFrom(this.processingFacade.selectedFormula$), // also update available formulas
          filter(([_, selectedFormula]) => selectedFormula !== null),
          tap(([[[_, inputSource], dataset], selectedFormula]) => {
            const source = selectedFormula?.sources.find((s) => s.source_name === inputSource);
            if (source) {
              const sourceToSet: CheckedProcessingInputStream = JSON.parse(JSON.stringify(source));
              sourceToSet.value = dataset;
              this.processingFacade.changeFormulaInputSources(selectedFormula!.id, [sourceToSet]);
            }
          })
        )
        .subscribe()
    );

    this.subs.push(
      this.parameterChanged$
        .pipe(
          filter((parameter) => parameter !== undefined),
          tap((parameter) => this.processingFacade.changeSelectedFormulaParameters([parameter!])),
          withLatestFrom(this.processingFacade.selectedFormula$), // also update available formulas
          filter(([_, selectedFormula]) => selectedFormula !== null),
          keepInput(
            map(([param, _]) => {
              const validator = new FormulaParameterValidatorDirective(this.translate);
              validator.param = param!;
              const control = new UntypedFormControl(param!.value);
              return !validator.validate(control)?.isParameterValidationError;
            })
          ),
          tap(([[parameter, formula], isValid]) => {
            if (isValid) {
              this.processingFacade.changeFormulaParameters(formula!.id, [parameter!]);
            }
          })
        )
        .subscribe()
    );

    this.subs.push(
      this.resetForm$
        .pipe(
          filter((shouldReset) => shouldReset),
          withLatestFrom(this.processingFacade.selectedFormula$),
          filter(([_, formula]) => formula !== null),
          map(([_, formula]) => formula!.parameters),
          filter((parametersToReset) => parametersToReset.length > 0),
          map((parameters) => {
            return parameters.map((param) => {
              return {
                ...param,
                value: param.default_value?.value?.toString() ?? '',
                default_is_set: true
              } as CheckedProcessingParameter;
            });
          }),
          tap(() => this.resetForm()),
          tap(
            (parameters) =>
              this.processingFacade.changeSelectedFormulaParameters(
                parameters.map((param) => {
                  return { ...param, value: '', default_is_set: false };
                })
              ) // unfortunately necessary in order to update the parameters in the form which have not changed but were removed by resetForm
          ),
          debounceTime(100), // again unfortunately necessary
          tap((parameters) => this.processingFacade.changeSelectedFormulaParameters(parameters))
        )
        .subscribe()
    );

    this.subs.push(
      this.resetForm$
        .pipe(
          filter((shouldReset) => shouldReset),
          withLatestFrom(this.processingFacade.selectedFormula$),
          filter(([_, formula]) => formula !== null),
          map(([_, formula]) => formula!.sources),
          map((sources) => sources.map((source) => source.source_name)),
          map((sourceNames) =>
            sourceNames.map((name) => {
              return { sourceName: name, dataset: undefined };
            })
          ),
          tap((sources) => this.processingFacade.changeSelectedFormulaInputSources(sources))
        )
        .subscribe()
    );

    this.subs.push(
      this.selectFormula$
        .pipe(
          filter((formula) => formula !== null),
          tap((nextFormula) => this.processingFacade.selectFormula(nextFormula!)),
          debounceTime(100),
          withLatestFrom(this.processingFacade.selectedFormula$),
          tap(() => this.resetForm()), // unfortunately necessary in order to update the parameters in the form in order to remove validation errors when switching formulas
          tap(([formulaToSwitchTo, formula]) => {
            if (formulaToSwitchTo!.id === formula!.id) {
              this.processingFacade.changeSelectedFormulaParameters(
                formula!.parameters.map((param) => {
                  return { ...param, value: '', default_is_set: false };
                })
              );
            }
          }),
          debounceTime(100), // again unfortunately necessary
          tap(
            (
              [formulaToSwitchTo, selectedFormula] // again unfortunately necessary
            ) => this.processingFacade.changeSelectedFormulaParameters(formulaToSwitchTo!.parameters)
          )
        )
        .subscribe()
    );

    this.subs.push(
      this.showAnalysisEditor$.subscribe((show) => {
        if (!show) {
          // this.resetStream();
        }
      })
    );

    this.subs.push(
      this.processingFacade.currentProcessing$
        .pipe(
          withLatestFrom(this.processingFacade.useLateProcessingToken$),
          filter(([processing, useLateToken]) => {
            const finishedProcessing = processing.status === 'none' && processing.lastPollStatus.status === 'done';
            const streamingRunningProcessing = !useLateToken && processing.status === 'active';
            return finishedProcessing || streamingRunningProcessing;
          })
        )
        .subscribe((processing) => this.selectedDatasetID$.next('processed'))
    );
  }

  prepareStream(dataset: DatasetDescription, measurement: Measurement) {
    if (measurement.depotId) {
      const tokenContainerId = encodeMeasurementTokenContainerID(measurement.depotId, measurement.measurementId);
      this.streamingFacade.createStreamingPackage({
        streamingType: StreamingType.MEASUREMENT,
        tokenContainerId,
        dataset
      });
    } else {
      console.error('DepotID missing in Measurement', measurement);
    }
  }

  prepareProcessingStream() {
    this.streamingFacade.createStreamingPackage({ streamingType: StreamingType.PROCESSING });
  }

  toggleAnalysisEditorBar() {
    this.showAnalysisEditorBar$.next(!this.showAnalysisEditorBar$.value);
  }

  resetForm() {
    if (this.parameterForm) {
      this.parameterForm.resetForm();
    }
  }

  inputChanged(event: FocusEvent, parameter: CheckedProcessingParameter, value: any) {
    const param = { ...parameter };
    param.value = value;
    param.default_is_set = false;
    this.parameterChanged$.next(param);
  }

  selectionChanged(selection: { description: DatasetDescription; source: string }) {
    this.dropdownOpen = this.dropdownOpen.map(() => false);
    this.sourceChanged$.next([selection.description.id, selection.source]);
  }

  trackByFn(index: any, item: any) {
    return item.id;
  }

  ngOnDestroy() {
    this.cancelProcessing$.next(true);
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }
}
