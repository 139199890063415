import { Action, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { GroupInfo } from '../../types/group.types';
import * as RolesAction from '../roles/roles.actions';
import * as GroupsAction from './groups.actions';

export const GROUPS_FEATURE_KEY = 'groups';

export interface GroupsState {
  groups: GroupInfo[];
  loaded: boolean;
  error?: any;
}

export interface GroupsPartialState {
  readonly [GROUPS_FEATURE_KEY]: GroupsState;
}

export const initialGroups: GroupInfo[] = [];

export const initialState: GroupsState = {
  groups: initialGroups,
  loaded: false
};

const GroupsReducer = createReducer(
  initialState,
  immerOn(GroupsAction.GroupsLoaded, (state, { groups }) => {
    state.groups = groups;
    state.loaded = true;
  }),
  immerOn(RolesAction.AddRolesToGroupSuccess, (state, { groupId, roleIds }) => {
    const group = state.groups.find((group) => group.id === groupId);
    if (group) {
      group.roles = group.roles.concat(roleIds);
    }
  }),
  immerOn(RolesAction.DeleteRolesFromGroupSuccess, (state, { groupId, roleIds }) => {
    const group = state.groups.find((group) => group.id === groupId);
    if (group) {
      group.roles = group.roles.filter((role) => !roleIds.includes(role));
    }
  })
);

export function reducer(state: GroupsState | undefined, action: Action) {
  return GroupsReducer(state, action);
}
