import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type toggleState = 'on' | 'off' | 'loading' | 'disabled';
@Component({
  selector: 'cloud-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.css']
})
export class ToggleButtonComponent {
  @Input() toggleState: toggleState = 'disabled';
  @Input() text: string; // Always visible title
  @Input() hint: string; // HTML 'title', hover-hint
  @Input() small: boolean = false;
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();
}
