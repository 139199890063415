import { StreamingChart } from './streaming-chart.types';
import {
  DatasetXValues,
  Position,
  Position_CoordinateSystemType,
  Quantity as ProtoBufQuantity,
  DoubleRange,
  DatasetCalibration,
  DatasetThreeDimensionalMetaData,
  DatasetEOFTime,
  DatasetBOFTime
} from '../prototypes/DatasetMessages';
import { convertAbsoluteTimeToInt128 } from './time.utils';

export const deserializePosition = (position?: Position): StreamingChart.Position | undefined => {
  if (position) {
    return {
      measurementPointText: position.measurementPointText,
      coordinateSystemType: deserializeCoordinateSystemType(position.coordinateSystemType),
      coordinateSystemName: position.coordinateName,
      coordinateX: position.coordinateX,
      coordinateY: position.coordinateY,
      coordinateZ: position.coordinateZ,
      directionX: position.directionX,
      directionY: position.directionY,
      directionZ: position.directionZ
    };
  }
};

export const deserializeQuantity = (quantity?: ProtoBufQuantity): StreamingChart.Quantity | undefined => {
  if (quantity) {
    return {
      name: quantity.name,
      unitDisplayName: quantity.unitDisplayName,
      dbReferenceFactor: quantity.dbReferenceFactor,
      isPowerType: quantity.isPowerType,
      isoFactor: quantity.isoFactor,
      isoOffset: quantity.isoOffset,
      rawQuantity: quantity
    };
  }
  return undefined;
};

export const deserializeCalibration = (datasetCalibration: DatasetCalibration): StreamingChart.DatasetCalibration => {
  return {
    calibscale: datasetCalibration.calibscale,
    calibfact: datasetCalibration.calibfact,
    calibofs: datasetCalibration.calibofs
  };
};

export const deserializeEofTime = (datasetEofTime: DatasetEOFTime): StreamingChart.EofInfo => {
  return {
    eofTime: convertAbsoluteTimeToInt128(datasetEofTime.eofTime)
  };
};

export const deserializeBofTime = (datasetBofTime: DatasetBOFTime): StreamingChart.BofInfo => {
  return {
    startTime: convertAbsoluteTimeToInt128(datasetBofTime.startTime),
    firstSampleTime: convertAbsoluteTimeToInt128(datasetBofTime.firstSampleTime)
  };
};

export const deserializeCoordinateSystemType = (
  coordSysType?: Position_CoordinateSystemType
): StreamingChart.CoordinateSystemType | undefined => {
  if (coordSysType) {
    switch (coordSysType) {
      case Position_CoordinateSystemType.coord_sys_cartesian:
        return 'Cartesian';
      case Position_CoordinateSystemType.coord_sys_cylinder_x:
        return 'Cylinder_X';
      case Position_CoordinateSystemType.coord_sys_cylinder_y:
        return 'Cylinder_Y';
      case Position_CoordinateSystemType.coord_sys_cylinder_z:
        return 'Cylinder_Z';
      case Position_CoordinateSystemType.coord_sys_spherical_x:
        return 'Spherical_X';
      case Position_CoordinateSystemType.coord_sys_spherical_y:
        return 'Spherical_Y';
      case Position_CoordinateSystemType.coord_sys_spherical_z:
        return 'Spherical_Z';
      default:
        console.warn('unknown coordSysType: ', coordSysType);
        break;
    }
  }
  return undefined;
};

export const deserializeXValues = (xValues?: DatasetXValues): StreamingChart.PlotRange | undefined => {
  if (xValues) {
    switch (xValues.xValues['oneofKind']) {
      case 'xEquiValues': {
        const equiXValues = xValues.xValues;
        const equiStop =
          equiXValues.xEquiValues.begin + equiXValues.xEquiValues.delta * (equiXValues.xEquiValues.numLines - 1);
        return {
          start: equiXValues.xEquiValues.begin,
          stop: equiStop,
          num: equiXValues.xEquiValues.numLines
        };
      }
      case 'xNonequiValues':
      // TODO: not implemented
      // fallsthrough
      case 'xLabelValues':
      // TODO: not implemented
      // fallsthrough
      default:
        console.warn('unknown xValues type: ', xValues.xValues['oneofKind']);
    }
  }
  return undefined;
};

export const deserializeDoubleScale = (num: number, scale?: DoubleRange): StreamingChart.PlotRange | undefined => {
  if (scale) {
    return {
      start: scale.start,
      stop: scale.stop,
      num: num
    };
  }
  return undefined;
};

export const deserializeStepDelta3D = (metaData: DatasetThreeDimensionalMetaData): number | undefined => {
  const xValues = metaData.xValues;
  const oneOfKind = xValues?.['oneofKind'];
  const values = xValues?.[oneOfKind];

  return values?.delta;
};

export const extractFreqWeight = (freqWeight: number): StreamingChart.FreqWeight | undefined => {
  switch (freqWeight) {
    case 1:
      return undefined;
    case 2:
      return 'A';
    case 3:
      return 'B';
    case 4:
      return 'C';
    case 5:
      return 'D';
    default:
      return undefined;
  }
};
