import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { JsonApi, Spec } from '@muellerbbm-vas/grivet';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

export interface HeaderEntry {
  [index: string]: string;
}

@Injectable()
export class AppHeaders {
  public headersCache: HeaderEntry = {};
  public headers: HttpHeaders = new HttpHeaders();
  constructor(
    private readonly translate: TranslateService,
    @Inject('ADDITIONAL_HEADERS') private readonly additionalHeaders?: HeaderEntry
  ) {
    // Basic Headers
    this.addHeader('Accept', 'application/vnd.api+json');

    // Additonal Headers
    if (this.additionalHeaders) {
      const keys = Object.keys(this.additionalHeaders);
      for (const key of keys) {
        if (this.additionalHeaders[key]) {
          this.addHeader(key, this.additionalHeaders[key]);
        }
      }
    }
  }

  addHeader(name: string, value: string) {
    this.headersCache[name] = value;
  }

  buildHttpHeaders(): HttpHeaders {
    // Note: Despite what the translate libraries typedefinition tells you, this.translate.currentLang may return "undefined"!
    const hasCurrentLanguage = this.translate.currentLang !== undefined && this.translate.currentLang !== '';
    this.addHeader('Accept-Language', hasCurrentLanguage ? this.translate.currentLang : 'en');
    return new HttpHeaders(this.headersCache);
  }

  buildSVGHttpHeaders(): HttpHeaders {
    // Note: Despite what the translate libraries typedefinition tells you, this.translate.currentLang may return "undefined"!
    const hasCurrentLanguage = this.translate.currentLang !== undefined && this.translate.currentLang !== '';
    this.addHeader('Accept-Language', hasCurrentLanguage ? this.translate.currentLang : 'en');
    // and override the usual accept type: The return value will always be 'image/svg+xml', but the cloud requires that we accept anything!
    this.addHeader('Accept', '*/*');
    return new HttpHeaders(this.headersCache);
  }
}

@Injectable({
  providedIn: 'root'
})
export class AngularHttpContext implements JsonApi.Context {
  public headers: HttpHeaders = new HttpHeaders();
  constructor(
    private readonly http: HttpClient,
    @Inject('HEADERS') private readonly appHeaders?: AppHeaders,
    @Inject('BASE_HOST') private readonly baseHost?: string
  ) {
    this.buildRequestHeaders();
  }
  async getDocument(url: URL, customHeaders?: Record<string, string>): Promise<Spec.JsonApiDocument> {
    const origUrl = url;
    url = new URL(origUrl.pathname, this.baseHost);
    url = new URL(url.href + origUrl.search);
    this.buildRequestHeaders(customHeaders);

    return lastValueFrom(this.http.get<Spec.JsonApiDocument>(url.href, { headers: this.headers }));
  }
  async getDocumentWithHeaders(
    url: URL,
    customHeaders?: Record<string, string>
  ): Promise<HttpResponse<Spec.JsonApiDocument>> {
    const origUrl = url;
    url = new URL(origUrl.pathname, this.baseHost);
    url = new URL(url.href + origUrl.search);
    this.buildRequestHeaders(customHeaders);

    return lastValueFrom(this.http.get<Spec.JsonApiDocument>(url.href, { headers: this.headers, observe: 'response' }));
  }
  async postDocument(url: URL, data: any, customHeaders?: Record<string, string>): Promise<Spec.JsonApiDocument> {
    const origUrl = url;
    url = new URL(origUrl.pathname, this.baseHost);
    url = new URL(url.href + origUrl.search);
    this.buildRequestHeaders(customHeaders);

    return lastValueFrom(this.http.post<Spec.JsonApiDocument>(url.href, data, { headers: this.headers }));
  }

  async putDocument(url: URL, data: any, customHeaders?: Record<string, string>): Promise<Spec.JsonApiDocument> {
    const origUrl = url;
    url = new URL(origUrl.pathname, this.baseHost);
    url = new URL(url.href + origUrl.search);
    this.buildRequestHeaders(customHeaders);

    return lastValueFrom(this.http.put<Spec.JsonApiDocument>(url.href, data, { headers: this.headers }));
  }

  async deleteDocument(url: URL, customHeaders?: Record<string, string>): Promise<Spec.JsonApiDocument> {
    url = new URL(url.pathname, this.baseHost);
    this.buildRequestHeaders(customHeaders);

    return lastValueFrom(this.http.delete<Spec.JsonApiDocument>(url.href, { headers: this.headers }));
  }
  async optionsDocument(url: URL, customHeaders?: Record<string, string>): Promise<Spec.JsonApiDocument> {
    const origUrl = url;
    url = new URL(origUrl.pathname, this.baseHost);
    url = new URL(url.href + origUrl.search);
    this.buildRequestHeaders(customHeaders);

    return lastValueFrom(this.http.options<Spec.JsonApiDocument>(url.href, { headers: this.headers }));
  }
  async optionsDocumentWithHeaders(
    url: URL,
    customHeaders?: Record<string, string>
  ): Promise<HttpResponse<Spec.JsonApiDocument>> {
    const origUrl = url;
    url = new URL(origUrl.pathname, this.baseHost);
    url = new URL(url.href + origUrl.search);
    this.buildRequestHeaders(customHeaders);

    return lastValueFrom(
      this.http.options<Spec.JsonApiDocument>(url.href, { headers: this.headers, observe: 'response' })
    );
  }
  async patchDocument(url: URL, data: any, customHeaders?: Record<string, string>): Promise<Spec.JsonApiDocument> {
    url = new URL(url.pathname, this.baseHost);
    this.buildRequestHeaders(customHeaders);

    return lastValueFrom(this.http.patch<Spec.JsonApiDocument>(url.href, data, { headers: this.headers }));
  }

  async getSVG(url: URL): Promise<string> {
    url = new URL(url.pathname, this.baseHost);
    if (this.appHeaders === undefined) {
      return 'unknown headers';
    }
    const newHeaders = this.appHeaders.buildSVGHttpHeaders();
    return lastValueFrom(this.http.get(url.href, { headers: newHeaders, responseType: 'text' }));
  }

  private buildRequestHeaders(customHeaders?: Record<string, string>) {
    if (this.appHeaders !== undefined) {
      this.headers = this.appHeaders.buildHttpHeaders();
    }
    if (customHeaders) {
      Object.keys(customHeaders).forEach((key) => {
        this.headers = this.headers.append(key, customHeaders[key]);
      });
    }
  }
}

export const angularHttpContextFactory = (
  http: HttpClient,
  appHeaders?: AppHeaders,
  baseHost?: string
): JsonApi.Context => {
  return new AngularHttpContext(http, appHeaders, baseHost);
};
