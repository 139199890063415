import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommonPartialState, CommonState, COMMON_FEATURE_KEY } from './common.reducer';

const getCommonState = createFeatureSelector<CommonState>(COMMON_FEATURE_KEY);

const getAppVersion = createSelector(getCommonState, (state: CommonState) => state.appVersion);

const getProduction = createSelector(getCommonState, (state: CommonState) => state.production);

const getUIWidth = createSelector(getCommonState, (state: CommonState) => state.uiWidth);

const getUIHeight = createSelector(getCommonState, (state: CommonState) => state.uiHeight);

const getIsTouchDevice = createSelector(getCommonState, (state: CommonState) => state.isTouchDevice);

export const commonQuery = {
  getAppVersion,
  getProduction,
  getUIWidth,
  getUIHeight,
  getIsTouchDevice
};
