import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WorkspaceConfigIdentifier } from '../../workspace/config/workspace.config.types';
import { WORKSPACE_CONFIG } from '../config/workspace.config';
import { ConfigurationTypeIdentifiers, Workspace } from '../workspace.types';
import { WorkspaceState, WORKSPACE_FEATURE_KEY } from './workspace.reducer';

const getWorkspaceState = createFeatureSelector<WorkspaceState>(WORKSPACE_FEATURE_KEY);

const getWorkspaces = createSelector(getWorkspaceState, (state) => state.workspaces);

const getSelectedWorkspaceId = createSelector(getWorkspaceState, (state) => state.selectedWorkspaceId);

const getSelectedWorkspace = createSelector(getWorkspaceState, getSelectedWorkspaceId, (state, selectedId) => {
  let selectedWorkspace: Workspace | undefined;
  if (selectedId) {
    selectedWorkspace = state.workspaces[selectedId] ? state.workspaces[selectedId] : undefined;
  }
  return selectedWorkspace;
});

const getSelectedWorkspaceType = createSelector(getWorkspaceState, getSelectedWorkspaceId, (state, selectedId) => {
  let typeOfSelectedWorkspace = WorkspaceConfigIdentifier.BASE;
  if (selectedId) {
    typeOfSelectedWorkspace = state.workspaces[selectedId].type ?? WorkspaceConfigIdentifier.BASE;
  }
  return typeOfSelectedWorkspace;
});

const getSelectedWorkspaceConfig = createSelector(getSelectedWorkspace, (selectedWorkspace) => {
  let wsConfigIdentifier = WorkspaceConfigIdentifier.BASE;
  if (selectedWorkspace) {
    wsConfigIdentifier = selectedWorkspace.type ?? WorkspaceConfigIdentifier.BASE;
  }
  return WORKSPACE_CONFIG[wsConfigIdentifier] ?? WORKSPACE_CONFIG['base']!;
});

const getTableColumns = createSelector(getWorkspaceState, (state) => {
  const result = {};
  Object.entries(state.workspaces).forEach(([workspaceId, workspace]) => {
    result[workspaceId] = workspace.orderTableColumns;
  });
  return result;
});

const getWorkspaceConfigState = createSelector(getWorkspaceState, (state: WorkspaceState) => state.config.STATE);

const getSelectedWorkspaceTableColumns = createSelector(
  getWorkspaceState,
  getSelectedWorkspaceId,
  (state, selectedId) => {
    let orderTableColumns: string[] = [];
    if (selectedId) {
      orderTableColumns = state.workspaces[selectedId].orderTableColumns;
    }
    return orderTableColumns;
  }
);

const getAallConfigsForIdentifier = (configIdentifier: ConfigurationTypeIdentifiers) =>
  createSelector(getWorkspaceState, (state: WorkspaceState) => state.config[configIdentifier]);

const getResultingConfigForIdentifier = (
  configIdentifier: ConfigurationTypeIdentifiers,
  includePreview: boolean = false
) =>
  createSelector(getWorkspaceState, (state: WorkspaceState) => {
    let config = state.config[configIdentifier]['default'];

    if (state.config[configIdentifier]['server']) {
      config = state.config[configIdentifier]['server']!;
    }

    if (includePreview && state.config[configIdentifier]['preview']) {
      config = state.config[configIdentifier]['preview']!;
    }

    return config;
  });

const getSort = createSelector(getWorkspaceState, (state) => state.sort);

export const workspaceQuery = {
  getWorkspaces,
  getSelectedWorkspace,
  getSelectedWorkspaceType,
  getSelectedWorkspaceConfig,
  getWorkspaceConfigState,
  getTableColumns,
  getSelectedWorkspaceTableColumns,
  getSelectedWorkspaceId,
  getAallConfigsForIdentifier,
  getResultingConfigForIdentifier,
  getSort
};
