<div class="node-container" [ngStyle]="{ 'padding-left.px': root ? 0 : 20 }">
  <clr-icon
    class="caret"
    shape="caret"
    dir="down"
    *ngIf="node.expanded"
    (click)="nodeCollapsed.emit(this.node)"
    [attr.data-cy]="'HIERARCHY_NODE_OPENCHEVRON_' + node.label"
  ></clr-icon>
  <clr-icon
    class="caret"
    shape="caret"
    dir="right"
    *ngIf="!node.expanded && node.hasChildren"
    [attr.data-cy]="'HIERARCHY_NODE_CLOSEDCHEVRON_' + node.label"
    (click)="nodeExpanded.emit(this.node)"
  ></clr-icon>
  <div *ngIf="!node.hasChildren" class="padding-left: 10px;"></div>
  <div
    class="node-content"
    [ngClass]="{ selected: selected }"
    title="
      {{ title ? title + ': ' + node.label : node.label }} &nbsp; &nbsp; &nbsp;
    "
    [attr.data-cy]="'HIERARCHY_NODE_LABEL_' + node.label"
    (click)="nodeSelected.emit(this.node)"
  >
    <div class="node-label">{{ node.label }}</div>
    <div class="badge" [title]="node.docCount" [attr.data-cy]="'HIERARCHY_NODE_COUNT_' + node.docCount">
      {{ node.docCount < 100000 ? node.docCount : '100k+' }}
    </div>
  </div>
  <div class="node-children" *ngIf="node.expanded">
    <ng-content></ng-content>
  </div>
</div>
