import { props, createAction } from '@ngrx/store';
import { AppErrorPayload } from '../../app.types';
import { StreamingPackageOptions, StreamingType } from '../utils/streaming.types';
import { DatasetDescription } from '../../measurements/measurements.types';
import { AvailableAxis, AxisScalingTypes, ColorScalingTypes } from '../plot-handlers/lightningPlot.types';
import { TokenContainer, MeasurementTokenContainerID, TokenContainerID, Token } from '../utils/streaming-token.types';
import { SelectableTrack } from '../integrated-streaming/integrated-streaming.component';

export enum StreamingActionTypes {
  TriggerTokenLookup = '[Streaming] Get Stored Streaming Token',
  LookupTokenSuccess = '[Streaming] Token Lookup Success',
  LookupTokenError = '[Streaming] Error requesting Token',

  RequestToken = '[Streaming] Request Streaming Token from Backend',
  RequestTokenSuccess = '[Streaming] Received Token',
  RequestTokenError = '[Streaming] Error requesting Token',

  SelectDataset = '[Streaming] Select Dataset',
  SetProcessingToken = '[Streaming] Set Token to Stream Processing Result',
  UnsetProcessingToken = '[Streaming] Unset Processing Token',
  SetIsStreaming = '[Streaming] Set streaming status',
  StopStreaming = '[Streaming] Stop the current streaming',

  // StreamingPackage Actions
  CreateStreamingPackage = '[Streaming] Create Streaming Package',
  StreamingPackageReady = '[Streaming] Streaming Package Ready',
  StreamingPackageCompleted = '[Streaming] Streaming Package Completed',
  AddTokenToStreamingPackage = '[Streaming] Add Token to Streaming Package',

  // Interactions
  SetAvailableAxisInteraction = '[Streaming] Set Available Axis Interaction',
  SelectAxisInteraction = '[Streaming] Set Selected Axis Interaction',
  SetAvailableColorInteraction = '[Streaming] Set Available Color Interaction',
  SelectColorInteraction = '[Streaming] Set Selected Color Interaction',

  SetFullSize = '[Streaming] Set Full Size',
  SetReducedSize = '[Streaming] Set Reduced Size',
  EnableRefresh = '[Streaming] Enable Refresh',
  DisableRefresh = '[Streaming] Disable Refresh',
  ShowYBar = '[Streaming] Show Y Bar',
  HideYBar = '[Streaming] Hide Y Bar',

  SetThruputParameters = '[Streaming] Set Thruput Parameters for streaming range',
  ResetThruputParameters = '[Streaming] Reset Thruput Parameters for streaming range',

  ShowTrackSelection = '[Streaming] Show Track Selection',
  SetSelectableTracks = '[Streaming] Set Selectable Tracks',
  SelectedTrack = '[Streaming] Select Track'
}

export const RequestToken = createAction(
  StreamingActionTypes.RequestToken,
  props<{ tokenContainerId: MeasurementTokenContainerID }>()
);

export const RequestTokenSuccess = createAction(
  StreamingActionTypes.RequestTokenSuccess,
  props<{ tokenContainer: TokenContainer }>()
);

export const RequestTokenError = createAction(
  StreamingActionTypes.RequestTokenError,
  props<{ error: AppErrorPayload }>()
);

export const TriggerTokenLookup = createAction(
  StreamingActionTypes.TriggerTokenLookup,
  props<{ tokenContainerId: MeasurementTokenContainerID }>()
);

export const LookupTokenSuccess = createAction(
  StreamingActionTypes.LookupTokenSuccess,
  props<{ tokenContainer: TokenContainer }>()
);

export const LookupTokenError = createAction(
  StreamingActionTypes.LookupTokenError,
  props<{ error: AppErrorPayload }>()
);

export const SelectDataset = createAction(StreamingActionTypes.SelectDataset, props<{ dataset: DatasetDescription }>());

export const SetProcessingToken = createAction(
  StreamingActionTypes.SetProcessingToken,
  props<{ tokenContainer: TokenContainer | null }>()
);

export const UnsetProcessingToken = createAction(StreamingActionTypes.UnsetProcessingToken);

export const StopStreaming = createAction(StreamingActionTypes.StopStreaming);

export const SetIsStreaming = createAction(StreamingActionTypes.SetIsStreaming, props<{ value: boolean }>());

// Streaming package actions

export const CreateStreamingPackage = createAction(
  StreamingActionTypes.CreateStreamingPackage,
  props<{ options: StreamingPackageOptions }>()
);

export const StreamingPackageReady = createAction(StreamingActionTypes.StreamingPackageReady);

export const AddTokenToStreamingPackage = createAction(
  StreamingActionTypes.AddTokenToStreamingPackage,
  props<{ tokenContainer: TokenContainer }>()
);

export const StreamingPackageCompleted = createAction(StreamingActionTypes.StreamingPackageCompleted);

// Interaction Actions
export const SetAvailableAxisInteraction = createAction(
  StreamingActionTypes.SetAvailableAxisInteraction,
  props<{ axisType: AvailableAxis; availableAxisInteractions: AxisScalingTypes[] }>()
);

export const SelectAxisInteraction = createAction(
  StreamingActionTypes.SelectAxisInteraction,
  props<{ axisType: AvailableAxis; selectedAxisInteraction: AxisScalingTypes }>()
);

export const SetAvailableColorInteraction = createAction(
  StreamingActionTypes.SetAvailableColorInteraction,
  props<{ availableColorInteractions: ColorScalingTypes[] }>()
);

export const SelectColorInteraction = createAction(
  StreamingActionTypes.SelectColorInteraction,
  props<{ selectedColorInteraction: ColorScalingTypes }>()
);

export const SetFullSize = createAction(StreamingActionTypes.SetFullSize);
export const SetReducedSize = createAction(StreamingActionTypes.SetReducedSize);

export const EnableRefresh = createAction(StreamingActionTypes.EnableRefresh);
export const DisableRefresh = createAction(StreamingActionTypes.DisableRefresh);

export const ShowYBar = createAction(StreamingActionTypes.ShowYBar);
export const HideYBar = createAction(StreamingActionTypes.HideYBar);

export const SetThruputParameters = createAction(
  StreamingActionTypes.SetThruputParameters,
  props<{ start: number; end: number; plotCompressed: boolean }>()
);

export const ResetThruputParameters = createAction(StreamingActionTypes.ResetThruputParameters);

export const ShowTrackSelection = createAction(
  StreamingActionTypes.ShowTrackSelection,
  props<{ showTrackSelection: boolean }>()
);
export const SetSelectableTracks = createAction(
  StreamingActionTypes.SetSelectableTracks,
  props<{ selectableTracks: SelectableTrack[] }>()
);
export const SelectedTrack = createAction(StreamingActionTypes.SelectedTrack, props<{ track?: SelectableTrack }>());
