import {
  AreaPoint,
  AreaRangeSeries,
  AxisOptions,
  AxisScrollStrategies,
  AxisTickStrategies,
  ChartXY,
  ColorHEX,
  Dashboard,
  LineSeries,
  Point,
  RectanglePositionAndSize,
  RectangleSeries,
  SolidFill,
  SolidLine,
  Themes,
  disableThemeEffects,
  emptyLine,
  lightningChart
} from '@arction/lcjs';
import { TranslateService } from '@ngx-translate/core';
import { exhaustiveMatchingGuard } from '../../../shared/utility-functions/exhaustiveMatchingGuard';
import { StreamingChart } from '../../utils/streaming-chart.types';
import { LightningPlot, LocalValues2D } from '../lightningPlot';
import { LCconstructorOptions2D, ScalesConfig2D } from '../lightningPlot.types';
import { timeDifference } from '../../utils/time.utils';
import { calc2DYValues } from './lightningChartCustomCalculations2D';
import { StreamingFacade } from '../../+state/streaming.facade';
import { ExternalTrackValues } from '../../integrated-streaming/integrated-streaming.component';
import { distinguishableValuesFractionDigitsForMinimalDelta } from '../../utils/formatting.utils';
import { ThruputMode } from '../../+state/streaming.reducer';
import { DataType } from '../../prototypes/DatasetMessages';

const seriesColor = '#0072A3';
const ENABLE_AXIS_CURSOR_LABEL = false;

export interface Update2DData {
  startTime: bigint | undefined;
  firstSampleTime: bigint | undefined;
  dataValues: number[];
  absTimeValues?: bigint[];
  trackOrXValues?: number[];
  bofReached: boolean;
  eofReached: boolean;
}

export class LightningPlot2D extends LightningPlot {
  lineSeries?: LineSeries;
  rectangleSeries?: RectangleSeries;
  areaRange?: AreaRangeSeries;

  values: LocalValues2D = {
    dataValues: [],
    times: [],
    minMaxValues: [],
    externalTrackValues: {
      absoluteInt128Time: [],
      values: []
    }
  };

  constructor(options: LCconstructorOptions2D, translate: TranslateService, streamingFacade: StreamingFacade) {
    super(options, translate, streamingFacade);
  }

  protected override setupPlot(scalesConfig?: ScalesConfig2D) {
    if (!this.disposed && (this.dashBoard === undefined || this.chart === undefined)) {
      this.dashBoard = this.createDashboard();
      this.chart = this.createChart(scalesConfig ?? undefined);

      this.initChart();
      this.setupAxisFormatters();
      this.setupAxisBehavior();
    }
  }

  private createDashboard(): Dashboard {
    const dashBoard = lightningChart(this.licenseConfig.license, {
      appTitle: this.licenseConfig.appTitle,
      company: this.licenseConfig.company
    }).Dashboard({
      numberOfRows: 1,
      numberOfColumns: 1,
      container: this.htmlElementId,
      theme: disableThemeEffects(Themes.light)
    });

    dashBoard.setColumnWidth(0, 11);
    dashBoard.setColumnWidth(1, 1);
    dashBoard.setSplitterStyle(emptyLine);
    return dashBoard;
  }

  private createChart(scalesConfig?: ScalesConfig2D): ChartXY {
    // TODO: Extract / simplify mapping
    let axisYConfig: AxisOptions = { type: 'linear' };
    if (scalesConfig && scalesConfig.yAxis) {
      switch (scalesConfig.yAxis) {
        case 'lin': {
          axisYConfig = { type: 'linear' };
          break;
        }
        case 'log': {
          axisYConfig = { type: 'logarithmic', base: 10 };
          break;
        }
        case 'db': {
          axisYConfig = { type: 'linear' };
          break;
        }
        case 'third':
          console.error('Chart2D with third octave at y-axis will not implemented');
          break;
        default:
          exhaustiveMatchingGuard(scalesConfig.yAxis);
      }
    }

    let axisXConfig: AxisOptions = { type: 'linear' };

    const chart = this.dashBoard
      .createChartXY({
        columnIndex: 0,
        columnSpan: 1,
        rowIndex: 0,
        rowSpan: 1,
        defaultAxisX: axisXConfig as AxisOptions,
        defaultAxisY: axisYConfig as AxisOptions
      })
      .setAnimationsEnabled(false)
      .setSeriesHighlightOnHover(false);

    return chart;
  }

  private initChart() {
    this.chart.setTitle(this.generatePlotTitle(this.tpDatasetParams.currentDatasetParams));

    // member variables initialization
    this.lastTrackValue = undefined;

    switch (this.chartType) {
      case StreamingChart.ChartType.CHART2DEqui:
      case StreamingChart.ChartType.CHART2DNonEqui: {
        this.areaRange = this.chart.addAreaRangeSeries({});
        this.lineSeries = this.chart.addLineSeries({
          dataPattern: {
            pattern: 'ProgressiveX',
            regularProgressiveStep: false
          }
        });
        this.lineSeries?.setName('');
        this.lineSeries?.setStrokeStyle(new SolidLine({ fillStyle: new SolidFill({ color: ColorHEX(seriesColor) }) }));
        this.lineSeries?.setCursorInterpolationEnabled(false);
        this.chart.getDefaultAxisX().setScrollStrategy(AxisScrollStrategies.fitting); // TODO set undefined after intervalChange by user
        break;
      }
      case StreamingChart.ChartType.CHART2DTacho: {
        this.rectangleSeries = this.chart.addRectangleSeries();
        this.rectangleSeries?.setDefaultStyle((figure) => {
          figure.setFillStyle(new SolidFill({ color: ColorHEX(seriesColor) }));
        });
        this.rectangleSeries?.setName('');
        this.rectangleSeries?.setMouseInteractions(false);
        break;
      }
      case StreamingChart.ChartType.CHART3D:
      case StreamingChart.ChartType.CHART3D2D:
        break;
      default:
        exhaustiveMatchingGuard(this.chartType);
    }
  }

  private isAreaRangeUsed(): boolean {
    let isAreaRangeUsed = false;
    if (this.chartType === StreamingChart.ChartType.CHART2DEqui) {
      isAreaRangeUsed = this.areaRange ? this.areaRange.getPointAmount() > 0 : false;
    }
    return isAreaRangeUsed;
  }

  private setupAxisFormatters() {
    this.chart.getDefaultAxisY().setTickStrategy(AxisTickStrategies.Numeric, (numericTicks) =>
      numericTicks
        .setCursorFormatter((tickPosition) => {
          if (ENABLE_AXIS_CURSOR_LABEL) {
            return this.infoMapping.axis.Y.formatter({
              value_or_index: tickPosition,
              isStepIndex: false,
              includeUnit: true
            });
          } else {
            return '';
          }
        })
        .setMajorFormattingFunction((tickPosition) => {
          if (this.infoMapping?.axis?.Y?.formatter) {
            return this.infoMapping.axis.Y.formatter({
              value_or_index: tickPosition,
              isStepIndex: false,
              includeUnit: false
            });
          } else {
            return '';
          }
        })
        .setMinorFormattingFunction((tickPosition) => {
          if (this.infoMapping?.axis?.Y?.formatter) {
            return this.infoMapping.axis.Y.formatter({
              value_or_index: tickPosition,
              isStepIndex: false,
              includeUnit: false
            });
          } else {
            return '';
          }
        })
    );

    this.chart.getDefaultAxisX().setTickStrategy(AxisTickStrategies.Numeric, (numericTicks) =>
      numericTicks
        .setCursorFormatter((tickPosition) => {
          if (ENABLE_AXIS_CURSOR_LABEL) {
            return this.infoMapping.axis.X.formatter({
              value_or_index: tickPosition,
              isStepIndex: false,
              includeUnit: true
            });
          } else {
            return '';
          }
        })
        .setMajorFormattingFunction((tickPosition) => {
          if (this.infoMapping?.axis?.X?.formatter) {
            return this.infoMapping.axis.X.formatter({
              value_or_index: tickPosition,
              isStepIndex: false,
              includeUnit: false
            });
          } else {
            return '';
          }
        })
        .setMinorFormattingFunction((tickPosition) => {
          if (this.infoMapping?.axis?.X?.formatter) {
            return this.infoMapping.axis.X.formatter({
              value_or_index: tickPosition,
              isStepIndex: false,
              includeUnit: false
            });
          } else {
            return '';
          }
        })
    );

    this.areaRange?.setCursorResultTableFormatter((builder, _, position, high, low) => {
      // console.log('2D cursor x:', x, ' y:', y, ' xv:', datapoint.x, ' yv:', datapoint.y);
      return builder
        .addRow(
          `position: ${this.infoMapping.axis.X.formatter({
            value_or_index: position,
            isStepIndex: false,
            includeUnit: true,
            decimalPlaces: this.DECIMAL_PLACES_X
          })}`
        )
        .addRow(
          `high: ${this.infoMapping.axis.Y.formatter({
            value_or_index: high,
            isStepIndex: false,
            includeUnit: true
            // decimalPlaces: this.DECIMAL_PLACES_X
          })}`
        )
        .addRow(
          `low: ${this.infoMapping.axis.Y.formatter({
            value_or_index: low,
            isStepIndex: false,
            includeUnit: true
          })}`
        );
    });

    this.lineSeries?.setCursorResultTableFormatter((builder, _, x, y, datapoint) => {
      // console.log('2D cursor x:', x, ' y:', y, ' xv:', datapoint.x, ' yv:', datapoint.y);
      return builder
        .addRow(
          `x: ${this.infoMapping.axis.X.formatter({
            value_or_index: datapoint.x,
            isStepIndex: false,
            includeUnit: true,
            decimalPlaces: this.DECIMAL_PLACES_X
          })}`
        )
        .addRow(
          `y: ${this.infoMapping.axis.Y.formatter({
            value_or_index: datapoint.y,
            isStepIndex: false,
            includeUnit: true
          })}`
        );
    });

    this.rectangleSeries?.setCursorResultTableFormatter((builder, series, figure) => {
      const rectangleDimensions = figure.getDimensionsTwoPoints();
      return builder.addRow(
        `x: ${this.infoMapping.axis.X.formatter({
          value_or_index: rectangleDimensions.x1,
          isStepIndex: false,
          includeUnit: true,
          decimalPlaces: this.DECIMAL_PLACES_X
        })}`
      );
    });

    this.chart.getDefaultAxisY().onIntervalChange((defaultAxis, start, end) => {
      if (!this.isAreaRangeUsed()) {
        this.checkRebounceOnIntervallChange(
          this.lineSeries?.getYMin(),
          this.lineSeries?.getYMax(),
          defaultAxis,
          start,
          end
        );
      }
    });

    this.chart.getDefaultAxisX().onIntervalChange((defaultAxis, start, end) => {
      if (this.chartType === StreamingChart.ChartType.CHART2DEqui) {
        const isAreaRangeUsed = this.areaRange ? this.areaRange.getPointAmount() > 0 : false;
        const currentXMin = isAreaRangeUsed ? this.areaRange?.getXMin() : this.lineSeries?.getXMin();
        const currentXMax = isAreaRangeUsed ? this.areaRange?.getXMax() : this.lineSeries?.getXMax();
        const newRangeIsUserTriggered =
          currentXMin !== undefined && currentXMax !== undefined && (start !== currentXMin || end !== currentXMax);
        let plotCompressed = this.tpDatasetParams.currentDatasetParams.rawDataType === DataType.Type_CompressedThruput;

        const plotCompressedPara = this.tpDatasetParams.compressedDatasetParams?.plottingParameters;
        if (plotCompressedPara !== undefined && plotCompressedPara.deltaX !== undefined) {
          const numberOfCompressedSamplesInRange = Math.round((end - start) / plotCompressedPara.deltaX);
          const pixelThreshold = window.innerWidth / 2;
          plotCompressed = numberOfCompressedSamplesInRange < pixelThreshold ? false : true;
          console.log(
            'plotCompressed: ',
            plotCompressed,
            ' pixelThreshold: ',
            pixelThreshold,
            'numberofSamples: ',
            numberOfCompressedSamplesInRange
          );
        }

        console.log(
          `onIntervalChange: isAreaRangeUsed: ${isAreaRangeUsed}, newRangeIsUserTriggered: ${newRangeIsUserTriggered}, plotCompressed: ${plotCompressed}, usedRawDataType: ${this.tpDatasetParams.usedRawDataType}, start: ${start}, end: ${end}, currentXMin: ${currentXMin}, currentXMax: ${currentXMax}`
        );
        if (
          plotCompressed &&
          this.tpDatasetParams.usedRawDataType === DataType.Type_Thruput &&
          this.values.minMaxValues.length > 0
        ) {
          // this.resetFullData();
          // this.datasetParams.usedRawDataType = DataType.Type_CompressedThruput;
          // this.handleScaleConfigChange(this.scalesConfig);
          this.streamingFacade.setThruputParameters(start, end, plotCompressed);
        } else {
          const changePlotDataType =
            (!plotCompressed && this.tpDatasetParams.usedRawDataType === DataType.Type_CompressedThruput) ||
            (plotCompressed && this.tpDatasetParams.usedRawDataType === DataType.Type_Thruput);
          const timeRangeIsOutside =
            currentXMin !== undefined && currentXMax !== undefined && (start < currentXMin || end > currentXMax);

          if (changePlotDataType || (!plotCompressed && timeRangeIsOutside)) {
            this.streamingFacade.setThruputParameters(start, end, plotCompressed);
          }
        }
      } else {
        this.checkRebounceOnIntervallChange(
          this.lineSeries?.getXMin(),
          this.lineSeries?.getXMax(),
          defaultAxis,
          start,
          end
        );
      }
    });
  }

  private setupAxisBehavior() {
    this.chart.getDefaultAxisY().setNibMousePickingAreaSize(0);
    this.chart.getDefaultAxisX().setNibMousePickingAreaSize(0);
    this.chart.setBackgroundFillStyle(new SolidFill({ color: ColorHEX('#ffffff00') }));

    const translucentFillStyle = seriesColor + '11';
    this.areaRange?.setLowFillStyle(new SolidFill({ color: ColorHEX(translucentFillStyle) }));
    this.areaRange?.setHighFillStyle(new SolidFill({ color: ColorHEX(translucentFillStyle) }));
    this.areaRange?.setLowStrokeStyle(new SolidLine({ fillStyle: new SolidFill({ color: ColorHEX(seriesColor) }) }));
    this.areaRange?.setHighStrokeStyle(new SolidLine({ fillStyle: new SolidFill({ color: ColorHEX(seriesColor) }) }));
  }

  private equiTimeScaleParams(plottingParams: StreamingChart.PlottingParameters | undefined): {
    firstSampleTime: bigint | undefined;
    deltaX: number | undefined;
  } {
    return {
      firstSampleTime: plottingParams?.timeScale?.firstSampleTime,
      deltaX: plottingParams?.deltaX
    };
  }

  public override handleScaleConfigChange(scalesConfig: ScalesConfig2D) {
    this.setupPlot(scalesConfig);
    switch (this.trackType) {
      case 'time':
        {
          if (this.chartType === StreamingChart.ChartType.CHART2DEqui) {
            if (this.values.dataValues.length > 0 || this.values.minMaxValues.length > 0) {
              let relativeTimes: number[] = [];
              const startPoint: bigint =
                this.tpDatasetParams.currentDatasetParams.plottingParameters?.measZeroPoint ?? BigInt(0);
              // console.log('handleScaleConfigChange: ', this.values.dataValues, this.values.minMaxValues);
              if (
                this.values.dataValues.length > 0 &&
                this.tpDatasetParams.usedRawDataType !== DataType.Type_CompressedThruput
              ) {
                const pPara = this.equiTimeScaleParams(this.tpDatasetParams.currentDatasetParams.plottingParameters);
                if (pPara.firstSampleTime !== undefined && pPara.deltaX !== undefined) {
                  this.DECIMAL_PLACES_X = distinguishableValuesFractionDigitsForMinimalDelta(pPara.deltaX);
                  const relStartTime = timeDifference(pPara.firstSampleTime, startPoint);
                  this.values.dataValues.forEach((_, index) => {
                    relativeTimes.push(relStartTime + index * pPara.deltaX!);
                  });
                  this.updateSeries(this.values.dataValues, relativeTimes, 'full');
                } else {
                  console.error('2D equi time : unknown delta time / first sample time');
                }
              } else if (
                this.values.minMaxValues.length > 0 &&
                this.tpDatasetParams.usedRawDataType === DataType.Type_CompressedThruput
              ) {
                const pPara = this.equiTimeScaleParams(
                  this.tpDatasetParams.compressedDatasetParams?.plottingParameters
                );
                if (pPara.firstSampleTime && pPara.deltaX) {
                  this.DECIMAL_PLACES_X = distinguishableValuesFractionDigitsForMinimalDelta(pPara.deltaX);
                  const relStartTime = timeDifference(pPara.firstSampleTime, startPoint);
                  this.values.minMaxValues.forEach((_, index) => {
                    if (index % 2 === 0) relativeTimes.push(relStartTime + index * pPara.deltaX!); // TODO: ?? Better (index / 2) * dt - because of min- & max value at one line
                  });
                  this.updateSeries(this.values.minMaxValues, relativeTimes, 'compressed');
                } else {
                  console.error('2D equi time : unknown delta time / first sample time');
                }
              }
            }
          } else {
            if (this.values.dataValues.length > 0) {
              let relativeTimes: number[] = [];
              const startPoint: bigint =
                this.tpDatasetParams.currentDatasetParams.plottingParameters?.measZeroPoint ?? BigInt(0);
              this.values.times.forEach((value) => {
                relativeTimes.push(timeDifference(value, startPoint));
              });
              this.updateSeries(this.values.dataValues, relativeTimes, 'full');
            }
          }
        }
        break;
      case 'originalTrack':
        if (this.values.dataValues.length > 0) {
          let xValues: number[] = [];
          this.values.times.forEach((val) => {
            const i = this.values.externalTrackValues?.absoluteInt128Time.findIndex((value: bigint, index: number) => {
              if (value >= val) {
                return true;
              }
            });
            if (i !== undefined && i >= 0) {
              if (this.values.externalTrackValues?.values[i]) {
                xValues.push(this.values.externalTrackValues?.values[i]);
              } else {
                console.error('didnt found track value for time ', val);
              }
            }
          });

          this.updateSeries(this.values.dataValues, xValues, 'full');
        }
        break;
      case 'track':
      case 'orderRPM':
      case 'zValues':
        if (this.values.dataValues.length > 0) {
          if (this.values.trackValues) {
            this.updateSeries(this.values.dataValues, this.values.trackValues, 'full');
          } else {
            console.error('didnt found track values for track type ', this.trackType);
          }
        }
        break;
      case 'step':
        if (this.values.dataValues.length > 0) {
          const xvals: number[] = [0];
          this.updateSeries(this.values.dataValues, xvals, 'full');
        }
        break;
    }
    this.updateInfoMapping();
  }

  updateSeries(dataValues: number[], xvals: number[], thruputMode: ThruputMode) {
    if (this.tpDatasetParams.currentDatasetParams) {
      const additionalYvals = calc2DYValues(
        dataValues,
        this.scalesConfig,
        this.tpDatasetParams.currentDatasetParams,
        this.calibration
      );
      if (thruputMode === 'full') {
        let additionalPoints: Point[];
        if (xvals.length === 1 && dataValues.length > 1) {
          additionalPoints = additionalYvals.map((value, index) => {
            return { x: xvals[0] + index, y: value };
          });
        } else {
          additionalPoints = additionalYvals.map((value, index) => {
            return { x: xvals[index], y: value };
          });
        }
        console.log(
          `updateSeries: thruputMode: ${thruputMode}, lineSeries: ${this.lineSeries?.getPointAmount()}, additionalPoints: ${
            additionalPoints.length
          }`
        );

        this.lineSeries?.add(additionalPoints);
      } else if (thruputMode === 'compressed') {
        const highLow = additionalYvals.reduce((acc, val, idx) => {
          if (idx % 2 === 0) {
            acc.push({ position: idx / 2, low: val });
          } else {
            acc[acc.length - 1].high = val;
          }
          return acc;
        }, [] as Array<{ position: number; high?: number; low?: number }>);

        if (highLow.length !== xvals.length) {
          console.error('highLow.length !== xvals.length', highLow.length, xvals.length);
        }
        console.log(
          `updateSeries: thruputMode: ${thruputMode}, areaRange: ${this.areaRange?.getPointAmount()}, highLow.length: ${
            highLow.length
          }`
        );
        let additionalPoints: AreaPoint[];
        additionalPoints = highLow.map((point) => {
          if (point.high === undefined || point.low === undefined) {
            console.error('high or low value is undefined');
          } else if (Number.isNaN(point.high) || Number.isNaN(point.low)) {
            console.error('high or low value is NaN');
          }
          return { position: xvals[point.position], high: point.high!, low: point.low! };
        });
        this.areaRange?.add(additionalPoints);
      }
    }
  }

  updateRectangleSeries(dataValues: number[]) {
    if (this.tpDatasetParams.currentDatasetParams) {
      dataValues.forEach((value) => {
        const rectangle: RectanglePositionAndSize = {
          x: value, //* this.power10ScalingX,
          y: 0,
          width: 0.0002,
          height: 1
        };
        this.rectangleSeries?.add(rectangle);
      });
    }
  }

  // add-* functions

  public add2dSlowQuantityDataIncrement = (data: Update2DData) => {
    if (data.bofReached) {
      this.resetFullData();
    }
    const xStart = this.values.dataValues.length;
    // console.log( 'slows     : x-start: ', xStart, ' new points :', data.dataValues.length);
    data.dataValues.forEach((value) => {
      this.values.dataValues.push(value);
    });

    data.absTimeValues?.forEach((value) => {
      this.values.times.push(value);
    });
    if (data.trackOrXValues) {
      if (!this.values.trackValues) {
        this.values.trackValues = [] as number[];
      }
      if (this.values.trackValues) {
        data.trackOrXValues.forEach((value) => {
          this.values.trackValues?.push(value);
        });
      }
    }

    if (data.dataValues.length > 0) {
      const plotParams = this.tpDatasetParams.currentDatasetParams.plottingParameters;
      switch (this.trackType) {
        case 'time':
          {
            let relativeTimes: number[] = [];
            const startPoint: bigint = plotParams?.measZeroPoint ?? BigInt(0);
            data.absTimeValues?.forEach((value) => {
              const relTime = timeDifference(value, startPoint);
              if (this.lastTrackValue !== undefined) {
                const diff = Math.abs(relTime - this.lastTrackValue);
                if (this.MINIMUM_X_DELTA === undefined || diff < this.MINIMUM_X_DELTA) {
                  this.MINIMUM_X_DELTA = diff;
                }
              }
              this.lastTrackValue = relTime;

              this.values.times.push(value);

              relativeTimes.push(relTime);
            });

            this.DECIMAL_PLACES_X = distinguishableValuesFractionDigitsForMinimalDelta(this.MINIMUM_X_DELTA);
            this.updateSeries(data.dataValues, relativeTimes, 'full');
          }
          break;
        case 'originalTrack':
          {
            if (data.absTimeValues) {
              data.absTimeValues.forEach((value) => {
                this.values.times.push(value); // !! the value is allways 0 because we need only the index of the value
              });
            }
            let xValues = useAbsTimeValuesToDeriveXValuesFromExternalTrackValues(
              data.absTimeValues,
              this.values.externalTrackValues
            );
            xValues.forEach((value) => {
              if (this.lastTrackValue !== undefined) {
                const diff = Math.abs(value - this.lastTrackValue);
                if (this.MINIMUM_X_DELTA === undefined || diff < this.MINIMUM_X_DELTA) {
                  this.MINIMUM_X_DELTA = diff;
                }
              }
              this.lastTrackValue = value;
            });
            this.DECIMAL_PLACES_X = distinguishableValuesFractionDigitsForMinimalDelta(this.MINIMUM_X_DELTA);
            this.updateSeries(data.dataValues, xValues, 'full');
          }
          break;
        case 'track':
        case 'orderRPM':
        case 'zValues':
          {
            if (data.trackOrXValues) {
              data.trackOrXValues.forEach((value) => {
                if (this.lastTrackValue !== undefined) {
                  const diff = Math.abs(value - this.lastTrackValue);
                  if (this.MINIMUM_X_DELTA === undefined || diff < this.MINIMUM_X_DELTA) {
                    this.MINIMUM_X_DELTA = diff;
                  }
                }
                this.lastTrackValue = value;
              });
              this.DECIMAL_PLACES_X = distinguishableValuesFractionDigitsForMinimalDelta(this.MINIMUM_X_DELTA);
              this.updateSeries(data.dataValues, data.trackOrXValues, 'full');
            } else {
              console.error('didnt found track values for track type ', this.trackType);
            }
          }
          break;
        case 'step':
          {
            const xvals: number[] = [xStart];
            this.DECIMAL_PLACES_X = 0;
            this.updateSeries(data.dataValues, xvals, 'full');
          }
          break;
      }
    }
    // console.log( 'end slows : x-start: ', xStart, ' new points :', data.dataValues.length);
    this.updateInfoMapping();

    if (this.lineSeries) {
      this.yAxisInterval = { start: this.lineSeries.getYMax(), end: this.lineSeries.getYMin() };
      this.xAxisInterval = { start: this.lineSeries.getXMax(), end: this.lineSeries.getXMin() };
    } else {
      this.yAxisInterval = { start: this.rectangleSeries?.getYMax(), end: this.rectangleSeries?.getYMin() };
      this.xAxisInterval = { start: this.rectangleSeries?.getXMax(), end: this.rectangleSeries?.getXMin() };
    }
    if (data.eofReached) {
      this.resetAxis();
    }
  };

  public add2dEquiDataIncrement = (data: Update2DData) => {
    if (data.bofReached) {
      this.resetFullData();
      this.resetCompressedData();
    }
    const xStart = this.values.dataValues.length ?? 0;
    // console.log( 'equi data     : x-start: ', xStart, ' new points :', data.dataValues.length);
    data.dataValues.forEach((value) => {
      this.values.dataValues.push(value);
    });

    if (data.absTimeValues) {
      data.absTimeValues.forEach((value) => {
        this.values.times.push(value); // !! the value is allways 0 because we need only the index of the value
      });
    }

    if (data.trackOrXValues) {
      if (!this.values.trackValues) {
        this.values.trackValues = [] as number[];
      }
      if (this.values.trackValues) {
        data.trackOrXValues.forEach((value) => {
          this.values.trackValues?.push(value);
        });
      }
    }

    if (data.dataValues.length > 0) {
      switch (this.trackType) {
        case 'time':
          if (
            data.firstSampleTime !== undefined &&
            this.tpDatasetParams.currentDatasetParams.plottingParameters?.timeScale?.firstSampleTime !== undefined
          ) {
            this.tpDatasetParams.currentDatasetParams.plottingParameters.timeScale.firstSampleTime =
              data.firstSampleTime;
          }
          const pPara = this.equiTimeScaleParams(this.tpDatasetParams.currentDatasetParams.plottingParameters);
          if (pPara.firstSampleTime && pPara.deltaX) {
            let relativeTimes: number[] = [];
            const startPoint: bigint =
              this.tpDatasetParams.currentDatasetParams.plottingParameters?.measZeroPoint ?? BigInt(0);
            const relStartTime = timeDifference(pPara.firstSampleTime, startPoint);
            data.dataValues.forEach((value, index) => {
              relativeTimes.push(relStartTime + (xStart + index) * pPara.deltaX!);
            });
            this.DECIMAL_PLACES_X = distinguishableValuesFractionDigitsForMinimalDelta(pPara.deltaX);
            const isCorrectNumPoints = this.lineSeries!.getPointAmount() === xStart;
            if (!isCorrectNumPoints) {
              console.error(
                '2D equi time : number of points is not correct',
                xStart,
                this.lineSeries!.getPointAmount()
              );
            }
            this.updateSeries(data.dataValues, relativeTimes, 'full');
          } else {
            console.error('2D equi time : unknown delta time or unknown first sample time ');
          }
          break;
        case 'originalTrack':
          {
            let xValues: number[] = [];
            if (data.absTimeValues) {
              xValues = useAbsTimeValuesToDeriveXValuesFromExternalTrackValues(
                data.absTimeValues,
                this.values.externalTrackValues
              );
            } else if (data.trackOrXValues) {
              if (data.trackOrXValues.length === data.dataValues.length) {
                xValues = data.trackOrXValues;
              } else {
                console.error(
                  `length of track values ${data.trackOrXValues?.length} is different of length of data values ${data.dataValues.length} for 2D plot`
                );
              }
            } else {
              console.error(`no time or track values available when plotting 2D plot`);
            }

            xValues.forEach((value) => {
              if (this.lastTrackValue !== undefined) {
                const diff = Math.abs(value - this.lastTrackValue);
                if (this.MINIMUM_X_DELTA === undefined || diff < this.MINIMUM_X_DELTA) {
                  this.MINIMUM_X_DELTA = diff;
                }
              }
              this.lastTrackValue = value;
            });
            this.DECIMAL_PLACES_X = distinguishableValuesFractionDigitsForMinimalDelta(this.MINIMUM_X_DELTA);

            this.updateSeries(data.dataValues, xValues, 'full');
          }
          break;
        case 'track':
        case 'orderRPM':
        case 'zValues':
          if (data.trackOrXValues) {
            this.updateSeries(data.dataValues, data.trackOrXValues, 'full');
          } else {
            console.error('didnt found track values for track type ', this.trackType);
          }
          break;
        case 'step':
          const xvals: number[] = [xStart];
          this.DECIMAL_PLACES_X = 0;
          this.updateSeries(data.dataValues, xvals, 'full');
          break;
      }
    }
    // console.log( 'end equi data : x-start: ', xStart, ' new points :', data.dataValues.length);
    this.updateInfoMapping();

    if (this.lineSeries) {
      this.yAxisInterval = { start: this.lineSeries.getYMax(), end: this.lineSeries.getYMin() };
      this.xAxisInterval = { start: this.lineSeries.getXMax(), end: this.lineSeries.getXMin() };
    } else {
      this.yAxisInterval = { start: this.rectangleSeries?.getYMax(), end: this.rectangleSeries?.getYMin() };
      this.xAxisInterval = { start: this.rectangleSeries?.getXMax(), end: this.rectangleSeries?.getXMin() };
    }
    if (data.eofReached) {
      this.resetAxis();
    }
  };

  public add2dCompressedDataIncrement = (data: Update2DData) => {
    if (data.bofReached) {
      this.resetFullData();
      this.resetCompressedData();
    }
    if (data.dataValues.length > 1) {
      const xStart = this.values.minMaxValues.length;
      // handle the case if the first low value is missing: skip the first value
      const skipFirstHighValue = data.dataValues.length % 2 === 1 && data.dataValues[0] > data.dataValues[1];
      if (skipFirstHighValue) {
        data.dataValues.shift();
        data.absTimeValues?.shift();
      } else {
        const skipLastValue = data.dataValues.length % 2 === 1;
        if (skipLastValue) {
          data.dataValues.pop();
          data.absTimeValues?.pop();
        }
      }
      data.dataValues.forEach((value) => {
        this.values.minMaxValues?.push(value);
      });

      const plottParams = this.tpDatasetParams.compressedDatasetParams?.plottingParameters;
      if (data.firstSampleTime !== undefined && plottParams !== undefined) {
        if (plottParams.timeScale?.firstSampleTime) {
          if (plottParams.timeScale.firstSampleTime !== data.firstSampleTime) {
            plottParams.timeScale.firstSampleTime = data.firstSampleTime;
          }
        }
      }
      if (this.values.minMaxValues.length > 0 && plottParams !== undefined) {
        const pPara = this.equiTimeScaleParams(plottParams);
        if (pPara.firstSampleTime && pPara.deltaX) {
          let relativeTimes: number[] = [];
          const startPoint: bigint = plottParams.measZeroPoint ?? BigInt(0);
          const relStartTime = timeDifference(pPara.firstSampleTime, startPoint);
          data.dataValues.forEach((value, index) => {
            if (index % 2 === 0) {
              relativeTimes.push(relStartTime + (xStart + index) * pPara.deltaX!); // TODO: ?? Better ((xStart + index) / 2) * dt - because of min- & max value at one line
            }
          });
          console.log(
            `compressed block: len: ${relativeTimes.length}, xStart / 2: ${xStart / 2}, [${
              relativeTimes[0]
            }, ${relativeTimes.at(relativeTimes.length - 1)}]`
          );
          this.DECIMAL_PLACES_X = distinguishableValuesFractionDigitsForMinimalDelta(pPara.deltaX);
          this.updateSeries(data.dataValues, relativeTimes, 'compressed');
        } else {
          console.error('2D equi time : unknown delta time or unknown first sample time ');
        }
      }

      this.updateInfoMapping();
    }
  };

  public add2dTachoIncrement = (data: Update2DData) => {
    if (data.bofReached) {
      this.resetFullData();
    }

    if (data.absTimeValues) {
      data.absTimeValues.forEach((value) => {
        this.values.times.push(value);
      });

      const plottParams = this.tpDatasetParams.currentDatasetParams.plottingParameters;
      const startPoint: bigint = plottParams?.measZeroPoint ?? BigInt(0);

      const relativeTimes = data.absTimeValues.map((time) => {
        return timeDifference(time, startPoint);
      });
      relativeTimes.forEach((relTime) => {
        if (this.lastTrackValue !== undefined) {
          const diff = Math.abs(relTime - this.lastTrackValue);
          if (this.MINIMUM_X_DELTA === undefined || diff < this.MINIMUM_X_DELTA) {
            this.MINIMUM_X_DELTA = diff;
          }
        }
        this.lastTrackValue = relTime;
      });
      this.DECIMAL_PLACES_X = distinguishableValuesFractionDigitsForMinimalDelta(this.MINIMUM_X_DELTA);
      this.updateRectangleSeries(relativeTimes);
    }
    this.updateInfoMapping();

    if (this.lineSeries) {
      this.yAxisInterval = { start: this.lineSeries.getYMax(), end: this.lineSeries.getYMin() };
      this.xAxisInterval = { start: this.lineSeries.getXMax(), end: this.lineSeries.getXMin() };
    } else {
      this.yAxisInterval = { start: this.rectangleSeries?.getYMax(), end: this.rectangleSeries?.getYMin() };
      this.xAxisInterval = { start: this.rectangleSeries?.getXMax(), end: this.rectangleSeries?.getXMin() };
    }
    if (data.eofReached) {
      this.resetAxis();
    }

    // NOTE:  Tacho Edges do not currently offer interactions that require a re-calculation of the rawValues
    // this.rawValues = this.rawValues.concat(additionalPoints);
  };

  // Parent class overrides

  override resetAxis() {
    console.log('resetAxis');
    this.chart.getDefaultAxisX().fit(true);
    this.chart.getDefaultAxisY().fit(true);
  }

  override resetFullData() {
    console.log('resetFullData');
    this.values.dataValues = [];
    this.values.times = [];
    this.values.trackValues = [];
    this.lineSeries?.clear();
  }

  override resetCompressedData() {
    console.log('resetCompressedData');
    this.areaRange?.clear();
  }

  protected override generatePlotTitle(parameter: StreamingChart.DatasetParameters): string {
    const quantityName: string = parameter.plottingParameters?.quantityY?.name ?? ''; // TODO reference quantityY and position
    return 'Channel ' + parameter.label + ' [' + parameter.direction + '] ' + quantityName + ' - ' + parameter.type;
  }

  protected override additionalDispose(): void {
    console.log('LightningPlot2D.dispose');
    this.streamingFacade.resetThruputParameters();
    this.lineSeries?.dispose();
    this.areaRange?.dispose();
    this.rectangleSeries?.dispose();
  }
}

const useAbsTimeValuesToDeriveXValuesFromExternalTrackValues = (
  absTimeValues?: bigint[],
  externalTrackValues?: ExternalTrackValues
): number[] => {
  let xValues: number[] = [];
  if (absTimeValues && externalTrackValues) {
    absTimeValues.forEach((absTimeValue) => {
      const extTrackAbsTimeIndex = externalTrackValues.absoluteInt128Time.findIndex(
        (extTrackAbsTimeValue: bigint) => extTrackAbsTimeValue >= absTimeValue
      );
      if (extTrackAbsTimeIndex >= 0 && externalTrackValues.values[extTrackAbsTimeIndex]) {
        xValues.push(externalTrackValues.values[extTrackAbsTimeIndex]);
      } else {
        console.error('Failed to find track value for time ', absTimeValue);
      }
    });
  }
  return xValues;
};
