import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map } from 'rxjs';

@Injectable()
export class PickListStore<T> {
  itemKey: string;

  #sourceList$ = new BehaviorSubject<T[]>([]);
  #targetList$ = new BehaviorSubject<T[]>([]);
  #disabledList$ = new BehaviorSubject<T[]>([]);

  #sourceQuery$ = new BehaviorSubject<string>('');
  #targetQuery$ = new BehaviorSubject<string>('');
  #disabledQuery$ = new BehaviorSubject<string>('');

  sourceQuery$ = this.#sourceQuery$.asObservable();
  targetQuery$ = this.#targetQuery$.asObservable();
  disabledQuery$ = this.#disabledQuery$.asObservable();

  sourceList$ = combineLatest([this.#sourceList$, this.#sourceQuery$]).pipe(
    map(([data, query]) => {
      const filtered = data?.filter((item) => {
        const name = item?.[this.itemKey]?.toLowerCase();
        return name.includes(query);
      });

      const sorted = filtered?.sort((a, b) => a[this.itemKey].localeCompare(b[this.itemKey]));
      return sorted;
    })
  );

  targetList$ = combineLatest([this.#targetList$, this.#targetQuery$]).pipe(
    map(([data, query]) => {
      const filtered = data?.filter((item) => {
        const name = item?.[this.itemKey]?.toLowerCase();
        return name.includes(query);
      });

      const sorted = filtered?.sort((a, b) => a[this.itemKey].localeCompare(b[this.itemKey]));
      return sorted;
    })
  );

  disabledList$ = combineLatest([this.#disabledList$, this.#disabledQuery$]).pipe(
    map(([data, query]) => {
      const filtered = data?.filter((item) => {
        const name = item?.[this.itemKey]?.toLowerCase();
        return name.includes(query);
      });

      const sorted = filtered?.sort((a, b) => a[this.itemKey].localeCompare(b[this.itemKey]));
      return sorted;
    })
  );

  setSourceList(data: T[]) {
    this.#sourceList$.next(data);
  }

  setTargetList(data: T[]) {
    this.#targetList$.next(data);
  }

  setDisabledList(data: T[]) {
    this.#disabledList$.next(data);
  }

  setSourceQuery(query: string) {
    const trimmed = query.trim().toLowerCase();
    this.#sourceQuery$.next(trimmed);
  }

  setTargetQuery(query: string) {
    const trimmed = query.trim().toLowerCase();
    this.#targetQuery$.next(trimmed);
  }

  setDisabledQuery(query: string) {
    const trimmed = query.trim().toLowerCase();
    this.#disabledQuery$.next(trimmed);
  }

  moveToTarget(selectedItems: T[]) {
    const sourceList = this.#sourceList$.getValue();
    const targetList = this.#targetList$.getValue();

    const newSourceList = sourceList.filter(
      (item) => !selectedItems.some((selectedItem) => selectedItem[this.itemKey] === item[this.itemKey])
    );

    const newTargetList = [...targetList, ...selectedItems];

    this.setSourceList(newSourceList);
    this.setTargetList(newTargetList);
  }

  moveToSource(selectedItems: T[]) {
    const sourceList = this.#sourceList$.getValue();
    const targetList = this.#targetList$.getValue();

    const newTargetList = targetList.filter(
      (item) => !selectedItems.some((selectedItem) => selectedItem[this.itemKey] === item[this.itemKey])
    );

    const newSourceList = [...sourceList, ...selectedItems];

    this.setSourceList(newSourceList);
    this.setTargetList(newTargetList);
  }
}
