import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { AppFacade } from '../../+state/app.facade';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { RouterFacade } from '../+state/router.facade';
import { filter, map, switchMapTo, tap } from 'rxjs/operators';
import { NavigationFacade } from '../+state/navigation.facade';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'cloud-router-info',
  templateUrl: './router-info.component.html',
  styleUrls: ['./router-info.component.css']
})
export class RouterInfoComponent implements OnInit {
  navigationInfos$: any;
  constructor(
    public appFacade: AppFacade,
    public translate: TranslateService,
    public routerFacade: RouterFacade,
    public navigationFacade: NavigationFacade
  ) {}

  infoModalClosed$: BehaviorSubject<boolean> = new BehaviorSubject<true>(true);
  reason: string;
  has_no_license: boolean = false;
  showInfoModal$: Observable<boolean>;
  infoTitle$: Observable<string>;
  infoID$: Observable<string>;
  infoDescription$: Observable<string>;

  ngOnInit() {
    this.infoDescription$ = this.routerFacade.currentNavigationInfos$.pipe(
      map((err) => err?.details[0].description ?? '')
    );

    // For now, this just handles license problems but can easily be extended to handle other info reasons
    //  => add switch for title and modify body in template
    const hasNavigationInfos$ = this.routerFacade.currentNavigationInfos$.pipe(
      map((infos) => {
        infos?.details.forEach((detail) => {
          if (detail.reason === 'no_license') {
            this.has_no_license = true;
          }
        });
        return !!infos;
      }),
      filter((hasInfos) => {
        return hasInfos && this.has_no_license;
      })
    );

    this.showInfoModal$ = merge(hasNavigationInfos$, this.infoModalClosed$.pipe(map((closed) => !closed)));
    this.infoTitle$ = this.showInfoModal$.pipe(
      filter((show) => show),
      map(() => {
        return this.translate.instant(`APP.URLINF.TITLES.LIC`);
      })
    );

    this.infoID$ = this.routerFacade.currentNavigationInfos$.pipe(map((err) => err?.infoIdentifier ?? ''));
  }

  back() {
    this.navigationFacade.navigate({ queryParams: {}, backToRoot: true });
  }

  reload() {
    location.reload();
  }
}
