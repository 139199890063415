import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { AttributesPartialState } from './attributes.reducer';
import * as AttributesActions from './attributes.actions';
import { Store } from '@ngrx/store';
import { DepotSearchService } from '../../../depot-search/depot-search.service';
import { from, map, switchMap } from 'rxjs';
import { createAppError } from '../../../app.factories';
import { fetch } from '@nrwl/angular';

@Injectable()
export class AttributesEffects {
  initDefaults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttributesActions.DepotAttributesAndSemanticsReceived),
      switchMap((action: ReturnType<typeof AttributesActions.DepotAttributesAndSemanticsReceived>) => [
        AttributesActions.FillDepotAttributesCache({ attribs: action.attribs })
      ])
    )
  );

  fetchDepotAttributesAndSemantics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttributesActions.FetchDepotAttributesAndSemantics),
      fetch({
        run: (
          action: ReturnType<typeof AttributesActions.FetchDepotAttributesAndSemantics>,
          state: AttributesPartialState
        ) => {
          return from(this.depotSearchService.fetchDepotAttributesAndSemantics()).pipe(
            map((result) =>
              AttributesActions.DepotAttributesAndSemanticsReceived({
                attribs: result.attributes,
                semantics: result.result,
                semanticGroups: result.groups
              })
            )
          );
        },

        onError: (action: ReturnType<typeof AttributesActions.FetchDepotAttributesAndSemantics>, error) => {
          return createAppError(error);
        }
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AttributesPartialState>,
    private depotSearchService: DepotSearchService
  ) {}
}
