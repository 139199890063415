import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CloudRouterState } from './router.reducer';
import { routerQuery } from './router.selectors';
import { CloudRouterErrorDetail, CloudRouterInfoDetail, CloudRouterQueryParamErrorDetail } from './router.types';

@Injectable()
export class RouterFacade {
  currentRootRoute$ = this.store.pipe(select(routerQuery.getRootRoute));

  currentNavigationErrors$: Observable<
    | {
        details: (CloudRouterErrorDetail | CloudRouterQueryParamErrorDetail)[];
        errorIdentifier: string;
      }
    | undefined
  > = this.store.pipe(select(routerQuery.getCurrentNavigationErrors));

  currentNavigationInfos$: Observable<
    | {
        details: CloudRouterInfoDetail[];
        infoIdentifier: string;
      }
    | undefined
  > = this.store.pipe(select(routerQuery.getCurrentNavigationInfos));

  currentUserNavigationId$: Observable<number | undefined> = this.store.pipe(
    select(routerQuery.getCurrentNavigationId)
  );

  queryParamsForRoute$ = (route: string) =>
    this.store
      .pipe(select(routerQuery.getPersistedQueryParams))
      .pipe(map((routerPersistance) => routerPersistance[route] ?? {}));

  constructor(private store: Store<CloudRouterState>) {}
}
