import { OrderState } from '../../order-management/order.types';
import {
  SideBarFilterConfig,
  FilterIdentifier,
  QuickFilterIdentifier,
  QuickFilter,
  OrderTableConfig
} from './workspace.config.types';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import * as OrdersSearchActions from '../../order-management/+state/orders-search.actions';
import { OrderProject } from '../../order-management/order-search.types';
import {
  OrderFilter,
  OrderFilterChangeEvent,
  OrderFilterEntry
} from '../../order-management/order-filter-list/order-filter.types';
import { OrderFilterState, SideBarFilterState } from '../../order-management/+state/orders.reducer';
import { NOOP } from '../../+state/app.actions';

import tableDefaultConfig from 'apps/cloud/src/assets/config/.orderTableConfig.json';
export const baseTableDefaultConfig = tableDefaultConfig;

// Configures what order meta attributes are hidden for a given workspace
export const metaAttributesHideList: string[] = ['order_guid', 'workspace_id', 'state_id'];

// Table configuration for a given workspace
export const fixedColumns: OrderTableConfig = {
  columns: {
    state: {
      width: 170,
      minWidth: 170,
      fixed: true,
      content: {
        primary: {
          lookup: 'stateId'
        },
        secondary: {
          lookup: 'progressBlob'
        },
        tertiary: {
          lookup: 'orderLock'
        },
        quaternary: {
          lookup: 'orderPendingDeletion'
        }
      },
      header: {
        de: 'Status',
        en: 'State',
        ja: '状態'
      }
    }
  }
};

/*
  # Philosophy of SideBarFilters
  SideBarFilters and their configuration have turned out to be a non-trivial problem during development.
  This paragraph is intended to shed some light on the inner workings, code parts, and philosophy behind this functionality.

  ## High-Level Overview

  There are several code-regions with different purposes involved

  * Workspace Config (this file and it's workspace specific additions like workspace.config.passby.ts )
  Supply Filter-specific mapping functions
    [filterAggregateSearchResultToStateMapping]: How to map received SearchResults and aggregates to the SideBarFilter State
    [filterStateToOrderFilterMapping]: How to generate an UI OrderFilter from the SideBarFilter State

  * Order State (in order.reducer) ->  SideBarFilter State
  Contains all state related to the configured OrderFilter. There are three types of relevant state entries:
    * base: Every OrderFilter Entry found for an unlimited broad search across all available measurements
    * available: OrderFilter Entries found within the current (possibly) limited OrderSearch
    * actual: a list of strings based on the respective URL filter, is empty if the filter is inactive
  Also contains extended / sorting state for each filter

  * OrderSearch Effects
  Handles triggering and preparing searches
  Preparation includes creating SearchFilters and SearchAggregate based on the current SideBar FilterState

  * OrderSearch Service
  Actually performs searches

  * OrderRouter Effects
  Handles mapping of filter-related URL params to their respective OrderState

  * OrderFilter List & Element
  Generate the actual UI OrderFilter Elements based on the configuration supplied by the Workspace Config and the current SideBarFilter State

  ## A: Search / State / OrderFilter Process

  Each Interaction with the OrderSearch and the OrderFilters triggers the following process
    1. An unlimited broad base search that creates the base filter info:
       OrderSearchEffects.getBaseFilters$ -> generate SearchAggregates -> OrderSearch -> [Filter-*]Received Action for 'base' slot -> 'base' entries written to SideBarFilterState
    2. A 'normal' limited search based on the current search parameters (e.g.: SearchText)
       OrderSearchEffects.triggerSearch$ -> generate SearchFilters and SearchAggregates -> [Filter-*]Received Action for 'available' slot -> 'available' entries written to SideBarFilterState
    3. The OrderFilter UI generates the Filters based on state -> filter-specific 'filterStateToOrderFilterMapping' function

  ## B: Filter-Interaction Roundtrip

  Interacting with a filter triggers the following Roundtrip
    1. The Filter-Interaction triggers one of the handlers described in the OrderFilter Configuration
    2. The Handler returns an Action that is then dispatched
    3. A modify-* Effect in order-search Effects triggers a navigation that changes the URL (1)
    4. The URL change and its parameters are handled by OrderRouter Effects, feeding their changes into the SideBarFilter State (as filterState.actual)
    5. Based on this change, the process described in [A] above is triggerd

  (1) Where applicable, the URL is considered the single-source of truth. As such, all changes to URL-dependent state are triggered by actual Navigations

*/

export const sideBarFilterConfig: SideBarFilterConfig = {
  [FilterIdentifier.PROJECTS]: {
    filterAggregateSearchResultToStateMapping: (_, filterAgg, slot) => {
      const projects: OrderProject[] = [];
      if (filterAgg && filterAgg.nodes) {
        filterAgg.nodes.forEach((res) => {
          projects.push({ name: res.label, count: res.children?.length ?? 0 });
        });
      }
      return OrdersSearchActions.ProjectsReceived({ projects, slot: slot ?? 'base' });
    },
    filterStateToOrderFilterMapping: (sideBarState: SideBarFilterState) => {
      const projectsFilterState: OrderFilterState<OrderProject> = sideBarState[FilterIdentifier.PROJECTS];

      let entries: OrderFilterEntry[] = [];
      const projectsFilter = projectsFilterState.actual;
      const availableProjects: OrderProject[] = projectsFilterState.available;
      const baseProjects = projectsFilterState.base;

      entries = baseProjects.map((project) => {
        const hasNoFilteredProjects = projectsFilter.length === 0;
        const projectIsInFilteredProjects = projectsFilter.includes(project.name);
        const appearChecked = hasNoFilteredProjects || projectIsInFilteredProjects;
        const availableCount = availableProjects.find((available) => available.name === project.name)?.count;

        return {
          name: project.name,
          toggleState: appearChecked,
          count: availableCount ?? 0,
          total: project.count
        };
      });

      const filter: OrderFilter = {
        content: {
          title: 'Projects',
          title_nls_key: 'ORDER.FILTERS.PROJECTS',
          entries: entries
        },
        behavior: {
          offerAll: true,
          offerOnly: true,
          collapsible: true,
          collapsed: false,
          extend: {
            extendable: true,
            initialLimit: 15
          },
          sorting: {
            enabled: true,
            initial: {
              method: 'numDocs',
              direction: 'desc'
            },
            methods: [
              {
                method: 'numDocs',
                direction: 'desc'
              },
              {
                method: 'numDocsTotal',
                direction: 'desc'
              },
              {
                method: 'natural',
                direction: 'desc'
              }
            ]
          },
          handleClickedAll: () => OrdersSearchActions.SetProjectsFilter({ projects: [] }),
          handleClickedOnly: (entry: OrderFilterEntry) => {
            if (entry.name) {
              return OrdersSearchActions.SetProjectsFilter({ projects: [entry.name] });
            }
          },
          handleClickedEntry: (event: OrderFilterChangeEvent) => {
            const affectedEntry = event.entries.find((entry) => event.affectedEntry.name === entry.name);
            if (affectedEntry) {
              affectedEntry.toggleState = event.affectedEntry.toggleState;
            }

            const result: string[] = event.entries
              .filter((entry) => entry.toggleState === true)
              .map((entry) => entry.name);
            return OrdersSearchActions.SetProjectsFilter({ projects: result });
          }
        }
      };
      return filter;
    }
  },
  [FilterIdentifier.STATES]: {
    filterAggregateSearchResultToStateMapping: (_, filterAgg) => {
      const states: OrderState[] = [];
      if (filterAgg && filterAgg.nodes) {
        filterAgg.nodes.forEach((res) => {
          const stateInStates = states.some((state) => state.id === res.originalValue);
          if (!stateInStates) {
            states.push({ name: res.label, id: res.originalValue, label_nls: res.label });
          }
        });
      }
      return OrdersSearchActions.AvailableStatesReceived({ states });
    },
    filterStateToOrderFilterMapping: (sideBarState: SideBarFilterState) => {
      const stateFilterState: OrderFilterState<OrderState> = sideBarState[FilterIdentifier.STATES];

      const statesFilter = stateFilterState.actual;
      const baseStates = stateFilterState.base;

      let entries: OrderFilterEntry[] = [];
      if (baseStates.length > 0) {
        entries = baseStates.map((state) => {
          const hasNoFilteredStates = statesFilter.length === 0;
          const stateIsInFilteredStates = statesFilter.includes(state.id);
          const appearChecked = hasNoFilteredStates || stateIsInFilteredStates;

          return {
            name: state.label_nls,
            toggleState: appearChecked,
            value: state.id
          };
        });
      }

      const filter: OrderFilter = {
        content: {
          title: 'States',
          title_nls_key: 'ORDER.FILTERS.STATES',
          entries: entries
        },
        behavior: {
          offerAll: true,
          offerOnly: true,
          collapsible: false,
          collapsed: false,
          handleClickedAll: () => OrdersSearchActions.SetStateFilter({ states: [] }),
          handleClickedOnly: (entry: OrderFilterEntry) => {
            if (entry.value) {
              return OrdersSearchActions.SetStateFilter({ states: [entry.value] });
            }
          },
          handleClickedEntry: (event: OrderFilterChangeEvent) => {
            const affectedEntry = event.entries.find((entry) => event.affectedEntry.value === entry.value);
            if (affectedEntry) {
              affectedEntry.toggleState = event.affectedEntry.toggleState;
            }

            const result: string[] = event.entries
              .filter((entry) => entry.toggleState === true)
              .map((entry) => entry.value!);
            return OrdersSearchActions.SetStateFilter({ states: result });
          }
        }
      };
      return filter;
    }
  },
  [FilterIdentifier.QUICK]: {
    filterAggregateSearchResultToStateMapping: (_, filterAgg) => {
      // TODO: Implement, requires bug 30388 to be fixed
      console.log('QUICK filterAggregateSearchResultToStateMapping: ', filterAgg);
      return NOOP;
    },
    filterStateToOrderFilterMapping: (sideBarState: SideBarFilterState) => {
      const quickFilterState: OrderFilterState<OrderFilterEntry> = sideBarState[FilterIdentifier.QUICK];

      const entries = quickFilterState.base;

      const filter: OrderFilter = {
        content: {
          title: 'Quick',
          title_nls_key: 'ORDER.FILTERS.QUICK',
          entries: entries
        },
        behavior: {
          offerAll: false,
          offerOnly: false,
          collapsible: false,
          collapsed: false,
          handleClickedAll: () => undefined,
          handleClickedOnly: () => undefined,
          handleClickedEntry: (event: OrderFilterChangeEvent) => {
            const filters: QuickFilter[] = [];

            const availableFilters = Object.entries(QuickFilterIdentifier).map((filter) => ({
              enumIdentifier: filter[0],
              stringIdentifier: filter[1]
            }));

            event.entries.forEach((entry) => {
              const respectiveEntry = event.entries.find((e) => e.name === entry.name);
              if (respectiveEntry && respectiveEntry.identifier) {
                const respectiveEntryIsAffectedEntry = respectiveEntry.name === event.affectedEntry.name;
                const newToggleState = respectiveEntryIsAffectedEntry
                  ? !respectiveEntry.toggleState
                  : respectiveEntry.toggleState;

                const respectiveEnumIdentifier = availableFilters.find(
                  (filter) => filter.stringIdentifier === respectiveEntry.identifier
                )?.enumIdentifier;

                filters.push({
                  identifier: QuickFilterIdentifier[respectiveEnumIdentifier!],
                  value: newToggleState
                });
              } else {
                console.error('Failed to find changed OrderFilterElement - this must not happen');
              }
            });

            return OrdersSearchActions.SetQuickFilter({
              filters
            });
          }
        }
      };
      return filter;
    }
  }
};
