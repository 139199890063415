import { createAction, props } from '@ngrx/store';
import { SearchResultEntry } from '../../shared/types/search.types';
import {
  dynamicComponentIdentifiers,
  MeasurementDownload,
  attributeDisplayType,
  MeasurementMove,
  IntermediateMeasuementDetailsBeforeMove,
  DryRunMeasurementDetails,
  MeasurementMoveDryRunResults,
  MeasurementPath
} from '../measurements.types';

export enum MeasurementsActionTypes {
  ShowMeasurement = '[Measurements] Show Measurement',
  FetchSpecificMeasurementTile = '[Measurements] Fetch a measurement based on the measurementID ',
  FetchSpecificMeasurementTileError = '[Measurements] Error while fetching a measurement based on the measurementID ',
  MeasurementReceived = '[Measurements] Measurement Received',
  ShowDynamicComponentForSelectedEntry = '[Measurements] Show dynamic component for search',
  StartMeasurementLoading = '[Measurements] Start loading Measurement',

  // Measurement Download
  RequestDownload = '[Measurement] Request Download',
  DownloadRequested = '[Measurement] Download Requested',
  MonitorDownloadRequest = '[Measurement] Monitor Download Request',
  CancelDownloadRequest = '[Measurement] Cancel Download Request',
  DownloadRequestFailed = '[Measurement] Download Request Failed',
  CleanUpDownloadRequest = '[Measurement] Clean Up Download Requests',
  ExitDownloadRequestMonitoring = '[Measurement] Exit Download Request Monitoring',

  // Move Measurement
  PrepareMove = '[Measurement] Prepare Move',
  ShowMeasurementMoveModal = '[Measurement] Show Move Modal',
  SetTargetPath = '[Measurement] Set Target Path',

  GetPathAttributesForMeasurementsViaSearch = '[Measurement] Get Path For Measurements',
  PathAttributesForMeasurementsReceived = '[Measurement] Path For Measurements Received',

  RequestMoveDryRun = '[Measurement] Request Move Dry Run',
  MoveDryRunReturned = '[Measurement] Move Dry Run Returned',

  RequestMove = '[Measurement] Request Move',
  MoveRequested = '[Measurement] Move Requested',
  MonitorMoveRequest = '[Measurement] Monitor Move Request',
  ExitMoveRequestMonitoring = '[Measurement] Exit Move Request Monitoring',
  CleanUpMoveRequest = '[Measurement] Clean Up Move Request',
  MoveRequestFailed = '[Measurement] Move Request Failed',

  // Attribute Display
  ChangeAttributeButtonState = '[Measurement] Toggle between showing or hiding empty attributes',
  ChangeExtended = '[Meassurement] Toggle between extended and collapsed view of a specific panel',
  AttributeDisplayTypeSaved = '[Measurement] Attribute Display Type saved to localStorage',
  AttributeDisplayTypeInitialized = '[Measurement] Attribute Display Type loaded from localStorage',
  InitDefaultAttributeDisplayType = '[Measurement] Init Default Attribute Display Type'
}

export const ShowMeasurement = createAction(
  MeasurementsActionTypes.ShowMeasurement,
  props<{ measurement: SearchResultEntry }>()
);

export const FetchSpecificMeasurementTile = createAction(
  MeasurementsActionTypes.FetchSpecificMeasurementTile,
  props<{ measurement: SearchResultEntry; identifier: dynamicComponentIdentifiers }>()
);
export const FetchSpecificMeasurementTileError = createAction(
  MeasurementsActionTypes.FetchSpecificMeasurementTileError,
  props<{ measurement: SearchResultEntry; identifier: dynamicComponentIdentifiers; error: any }>()
);
export const MeasurementReceived = createAction(
  MeasurementsActionTypes.MeasurementReceived,
  props<{
    measurement: SearchResultEntry;
    identifier: dynamicComponentIdentifiers;
    resource: any;
    depotId?: string;
  }>()
);
export const ShowDynamicComponentForSelectedEntry = createAction(
  MeasurementsActionTypes.ShowDynamicComponentForSelectedEntry,
  props<{ dynamicComponentId: dynamicComponentIdentifiers }>()
);
export const StartMeasurementLoading = createAction(
  MeasurementsActionTypes.StartMeasurementLoading,
  props<{ measurementId: string; identifier: dynamicComponentIdentifiers }>()
);

// Measurement Download
export const RequestDownload = createAction(
  MeasurementsActionTypes.RequestDownload,
  props<{
    downloadRequest: MeasurementDownload;
  }>()
);
export const DownloadRequested = createAction(
  MeasurementsActionTypes.DownloadRequested,
  props<{
    downloadRequest: MeasurementDownload;
  }>()
);
export const MonitorDownloadRequest = createAction(
  MeasurementsActionTypes.MonitorDownloadRequest,
  props<{
    downloadRequest: MeasurementDownload;
  }>()
);
export const CancelDownloadRequest = createAction(
  MeasurementsActionTypes.CancelDownloadRequest,
  props<{
    downloadRequest: MeasurementDownload;
  }>()
);
export const DownloadRequestFailed = createAction(
  MeasurementsActionTypes.DownloadRequestFailed,
  props<{
    downloadRequest: MeasurementDownload;
  }>()
);
export const CleanUpDownloadRequest = createAction(
  MeasurementsActionTypes.CleanUpDownloadRequest,
  props<{
    downloadRequest: MeasurementDownload;
  }>()
);
export const ExitDownloadRequestMonitoring = createAction(MeasurementsActionTypes.ExitDownloadRequestMonitoring);

// Move Measurement
export const PrepareMove = createAction(
  MeasurementsActionTypes.PrepareMove,
  props<{
    searchResults: SearchResultEntry[];
  }>()
);

export const ShowMeasurementMoveModal = createAction(
  MeasurementsActionTypes.ShowMeasurementMoveModal,
  props<{ show: boolean }>()
);

export const SetTargetPath = createAction(
  MeasurementsActionTypes.SetTargetPath,
  props<{ targetPath: MeasurementPath }>()
);

export const GetPathAttributesForMeasurementsViaSearch = createAction(
  MeasurementsActionTypes.GetPathAttributesForMeasurementsViaSearch,
  props<{
    searchResults: SearchResultEntry[];
  }>()
);

export const PathAttributesForMeasurementsReceived = createAction(
  MeasurementsActionTypes.PathAttributesForMeasurementsReceived,
  props<{ intermediateMeasurementDetails: IntermediateMeasuementDetailsBeforeMove[] }>()
);

export const RequestMoveDryRun = createAction(
  MeasurementsActionTypes.RequestMoveDryRun,
  props<{ intermediateMeasurementDetails: IntermediateMeasuementDetailsBeforeMove[] }>()
);

export const MoveDryRunReturned = createAction(
  MeasurementsActionTypes.MoveDryRunReturned,
  props<{ dryRunResults: MeasurementMoveDryRunResults }>()
);

export const RequestMove = createAction(
  MeasurementsActionTypes.RequestMove,
  props<{
    moveRequest: MeasurementMove;
  }>()
);

export const MoveRequested = createAction(
  MeasurementsActionTypes.MoveRequested,
  props<{
    moveRequest: MeasurementMove;
  }>()
);

export const MonitorMoveRequest = createAction(
  MeasurementsActionTypes.MonitorMoveRequest,
  props<{
    moveRequest: MeasurementMove;
  }>()
);

export const ExitMoveRequestMonitoring = createAction(MeasurementsActionTypes.ExitMoveRequestMonitoring);

export const CleanUpMoveRequest = createAction(
  MeasurementsActionTypes.CleanUpMoveRequest,
  props<{
    moveRequest: MeasurementMove;
  }>()
);
export const MoveRequestFailed = createAction(
  MeasurementsActionTypes.MoveRequestFailed,
  props<{
    moveRequest: MeasurementMove;
  }>()
);

// Display

export const ChangeAttributeButtonState = createAction(
  MeasurementsActionTypes.ChangeAttributeButtonState,
  props<{
    shownAttribs: attributeDisplayType;
  }>()
);

export const ChangeExtended = createAction(
  MeasurementsActionTypes.ChangeExtended,
  props<{
    extended: boolean;
    index: number;
    identifier: dynamicComponentIdentifiers;
  }>()
);

export const AttributeDisplayTypeSaved = createAction(MeasurementsActionTypes.AttributeDisplayTypeSaved);

export const AttributeDisplayTypeInitialized = createAction(
  MeasurementsActionTypes.AttributeDisplayTypeInitialized,
  props<{ attrDisplayType: attributeDisplayType }>()
);

export const InitDefaultAttributeDisplayType = createAction(MeasurementsActionTypes.InitDefaultAttributeDisplayType);
