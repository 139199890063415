import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FeatureFlagsFacade } from '@root/libs/feature-flags/src';
import {
  animationFrameScheduler,
  BehaviorSubject,
  bufferTime,
  combineLatest,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  from,
  map,
  Observable,
  of,
  scan,
  share,
  shareReplay,
  Subject,
  Subscription,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs';
import { StreamingFacade } from '../+state/streaming.facade';
import { RequestState, StreamingMeasurementStatus, StreamingPackage, StreamingType } from '../utils/streaming.types';

import { TranslateService } from '@ngx-translate/core';
import { exhaustiveMatchingGuard } from '../../shared/utility-functions/exhaustiveMatchingGuard';
import { LightningPlot2D, Update2DData } from '../plot-handlers/2D/lightningChart2D';
import { LightningPlot3D2D } from '../plot-handlers/2D/lightningChart3D2D';
import { LightningPlot3DHeatmap, Update3Ddata } from '../plot-handlers/3D/lightningChart3DHeatmap';
import { LightningPlot, LocalValues2D, hasCompressedData } from '../plot-handlers/lightningPlot';
import { AxisScalingTypes, ColorScalingTypes, ScalesConfig } from '../plot-handlers/lightningPlot.types';
import {
  AbsoluteTime,
  DatasetChangesChunk,
  DatasetChunk,
  DatasetStepValues,
  DataType
} from '../prototypes/DatasetMessages';
import { DatasetLocator, DatasetMetaDataList_Item } from '../prototypes/streamingService';
import { deserializeChunkForCHART2D, deserializeChunkForCHART3D } from '../utils/chunk.deserializers';
import {
  deserializeCalibration,
  deserializeEofTime,
  deserializeBofTime,
  deserializePosition
} from '../utils/dataset.deserializers';
import * as DatasetUtils from '../utils/dataset.utils';
import { prepareStream, TimeRangeAbs } from '../utils/stream.handlers';
import { StreamingChart } from '../utils/streaming-chart.types';
import { TokenContainer } from '../utils/streaming-token.types';
import {
  BBM_ABSOLUTE_TIME_MAX,
  addRelativeTime,
  convertAbsoluteTimeToInt128,
  convertInt128ToTimeStr
} from '../utils/time.utils';
import { ThruputMode } from '../+state/streaming.reducer';

type ThruputModeAndParameters = {
  actualThruputMode: ThruputMode | 'unknown';
  parameters?: {
    start?: number;
    end?: number;
  };
};

type cancelReasonTranslationMarker =
  | 'STREAMING.CANCEL_REASON.SAMPLES'
  | 'STREAMING.CANCEL_REASON.TIME'
  | 'STREAMING.CANCEL_REASON.USER';
interface Cancellation {
  shouldCancel: boolean;
  reason?: cancelReasonTranslationMarker;
}

export interface ExternalTrackValues {
  absoluteInt128Time: bigint[];
  values: number[];
}

export const TIME_TRACK: SelectableTrack = {
  locator: 'TIME_TRACK',
  name: 'Time'
};

export interface SelectableTrack {
  locator: string;
  name: string;
  quantityName?: string;
  isOriginalTrack?: boolean;
}

type pushTo2DChartHandlingFunction = (options: Update2DData) => void;
type pushTo3DChartHandlingFunction = (options: Update3Ddata) => void;

type PlottingContainer = {
  tpDatasetParams: StreamingChart.DatasetParaContainer;
  pusher: pushTo2DChartHandlingFunction | pushTo3DChartHandlingFunction | undefined;
};

const MAX_SAMPLES = 5_000_000 * 3;
@Component({
  selector: 'cloud-integrated-streaming',
  templateUrl: './integrated-streaming.component.html',
  styleUrls: ['./integrated-streaming.component.css']
})
export class IntegratedStreamingComponent implements OnInit, OnDestroy {
  @ViewChild('chartId', { static: true }) chartElement: ElementRef;

  // Control
  showYAxisRefreshButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Prep & Init
  abortController: AbortController = new AbortController();
  license$: Observable<StreamingChart.LightningConfig>;

  isPlotting: boolean = false;

  isPlottingContainer$: Subject<PlottingContainer> = new Subject<PlottingContainer>();

  streamingPackage$: Observable<StreamingPackage>;
  tokenContainer$: Observable<TokenContainer>;
  streamingType$: Observable<StreamingType>;

  showChart$: Observable<boolean>;

  // lightningPlot: LightningPlot2D | LightningPlot3D;
  lightningPlot$: BehaviorSubject<LightningPlot2D | LightningPlot3D2D | LightningPlot3DHeatmap | null> =
    new BehaviorSubject<LightningPlot2D | LightningPlot3DHeatmap | null>(null);

  // Data Handling
  chunks$: Subject<DatasetChunk> = new Subject<DatasetChunk>();
  calibrations$: Subject<StreamingChart.DatasetCalibration> = new Subject<StreamingChart.DatasetCalibration>();
  datasetStepValueStream$: Subject<DatasetStepValues> = new Subject<DatasetStepValues>();
  datasetStepValues$: Observable<DatasetStepValues[]> = new Observable<DatasetStepValues[]>();

  streamStatus$: BehaviorSubject<StreamingChart.StreamingStatus> = new BehaviorSubject<StreamingChart.StreamingStatus>(
    'init'
  );
  shouldCancel$: BehaviorSubject<Cancellation> = new BehaviorSubject<Cancellation>({ shouldCancel: false });
  cancellationMessage$: Observable<false | string> = new Observable<false | string>();

  // Org
  subs: Subscription[] = [];
  destroy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  shouldDestroy$ = this.destroy$.pipe(filter((shouldDestroy) => shouldDestroy === true));

  constructor(
    public streamingFacade: StreamingFacade,
    private featureFlagFacade: FeatureFlagsFacade,
    private translate: TranslateService,
    private ngZone: NgZone
  ) {}

  private datasetsParamsFct(
    currentDatasetParams: StreamingChart.DatasetParameters | undefined,
    associatedCompressedThruputDatasetParams: StreamingChart.DatasetParameters | undefined,
    thruputModeAndParameters: ThruputModeAndParameters
  ): StreamingChart.DatasetParaContainer {
    // console.log(`datasetsParamsFct - thruputModeAndParameters: `, thruputModeAndParameters);
    let datasetParams: StreamingChart.DatasetParameters | undefined = undefined;
    if (thruputModeAndParameters.actualThruputMode === 'compressed') {
      datasetParams = associatedCompressedThruputDatasetParams;
    } else {
      datasetParams = currentDatasetParams;
    }
    const usedRawDataType = datasetParams?.rawDataType;

    const zeroPoint = datasetParams?.plottingParameters?.measZeroPoint;
    if (
      zeroPoint !== undefined &&
      datasetParams !== undefined &&
      thruputModeAndParameters.parameters?.start !== undefined &&
      thruputModeAndParameters.parameters?.end !== undefined
    ) {
      datasetParams.desiredTimeRange = {
        start: addRelativeTime(zeroPoint, thruputModeAndParameters.parameters?.start ?? 0),
        stop: addRelativeTime(zeroPoint, thruputModeAndParameters.parameters?.end ?? 0)
      };
      console.log(
        'desiredTimeRange: ' + convertInt128ToTimeStr(datasetParams.desiredTimeRange.start),
        convertInt128ToTimeStr(datasetParams.desiredTimeRange.stop)
      );
    }
    // console.log(`datasetsParams - usedRawDataType: ${usedRawDataType}`);
    if (thruputModeAndParameters.actualThruputMode === 'compressed') {
      return {
        usedRawDataType: usedRawDataType ?? DataType.Type_Unknown,
        currentDatasetParams: currentDatasetParams,
        compressedDatasetParams: datasetParams
      } as StreamingChart.DatasetParaContainer;
    } else {
      return {
        usedRawDataType: usedRawDataType ?? DataType.Type_Unknown,
        currentDatasetParams: datasetParams,
        compressedDatasetParams: associatedCompressedThruputDatasetParams
      } as StreamingChart.DatasetParaContainer;
    }
  }

  ngOnInit() {
    this.cancellationMessage$ = this.shouldCancel$.pipe(
      filter((cancellation) => cancellation.shouldCancel === true),
      map((cancellation) => this.translate.instant(cancellation.reason!)),
      switchMap((message) => {
        return new Observable<string | false>((subscriber) => {
          subscriber.next(message);
          setTimeout(() => {
            subscriber.next(false);
          }, 5000);
        });
      }),
      shareReplay(1)
    );

    this.license$ = this.featureFlagFacade.featureValue$('LIGHTNING_LICENSE').pipe(
      map((license) => {
        const result: StreamingChart.LightningConfig = {
          license: license,
          appTitle: 'PAKcloud',
          company: 'Müller-BBM VibroAkustik Systeme GmbH'
        };
        return result;
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      shareReplay(1)
    );

    this.streamingPackage$ = this.streamingFacade.currentStreamingPackage$.pipe(
      filter((streamingPackage) => streamingPackage !== null),
      map((streamingPackage) => streamingPackage as StreamingPackage),
      shareReplay(1)
    );

    this.tokenContainer$ = this.streamingPackage$.pipe(
      map((streamingPackage) => streamingPackage.tokenContainer),
      filter((tokenContainer) => tokenContainer !== null && tokenContainer.requestState === RequestState.SUCCESS),
      map((tokenContainer) => {
        const tc: TokenContainer = JSON.parse(JSON.stringify(tokenContainer));
        if (!tc.webgrpcServerURL!.startsWith('http')) {
          tc.webgrpcServerURL = 'https://' + tc.webgrpcServerURL; // Use https as default if protocol is not specified by PAK cloud
        }
        return tc;
      }),
      shareReplay(1)
    );

    const streamSetup$ = this.streamingPackage$.pipe(
      filter((streamingPackage) => {
        const tokenContainerSuccess = streamingPackage.tokenContainer?.requestState === RequestState.SUCCESS; // FIXME: Check these States on re-streaming something else
        const streamingPackageCompleted = streamingPackage.status === StreamingMeasurementStatus.COMPLETED;
        return tokenContainerSuccess && streamingPackageCompleted;
      }),

      withLatestFrom(this.tokenContainer$),
      takeUntil(this.shouldDestroy$),
      shareReplay(1)
    );

    // NOTE: Let's not keep LC instances around for longer than one plot.
    this.subs.push(
      streamSetup$.pipe(withLatestFrom(this.lightningPlot$)).subscribe(([_, plot]) => {
        if (plot) {
          this.lightningPlot$.next(null);
          plot.dispose();
        }
      })
    );

    // Metadata
    // Everything everywhere all at once

    // KeyDataList contains the list of datasets available for the current measurement
    const datasetKeyDataList$ = streamSetup$.pipe(
      switchMap(([streamingPackage, tokenContainer]) =>
        DatasetUtils.getDatasetKeyDataList(streamingPackage.tokenContainer!.token, tokenContainer!.webgrpcServerURL!)
      ),
      shareReplay(1)
    );

    const datasetRegister$ = datasetKeyDataList$.pipe(
      withLatestFrom(streamSetup$),
      switchMap(([datasetKeyDataList, [streamingPackage, tokenContainer]]) => {
        return from(
          DatasetUtils.getDatasetMetaData(
            streamingPackage.tokenContainer!.token,
            tokenContainer.webgrpcServerURL!,
            datasetKeyDataList.items
          )
        ).pipe(map((datasetMetaDataList) => ({ datasetMetaDataList, datasetKeyDataList })));
      }),
      map(({ datasetMetaDataList, datasetKeyDataList }) => {
        const datasatParamsList = datasetKeyDataList.items.map((item) =>
          DatasetUtils.SCDatasetParamsFromKeyDataListItem(item)
        );

        const datasetRegister: StreamingChart.DatasetRegister = {
          metaDataList: datasetMetaDataList.metaDataList,
          subscribeIdToLocatorMap: datasetMetaDataList.subscribeIdToLocatorMap,
          datasatParamsList
        };

        return datasetRegister;
      }),
      withLatestFrom(
        this.tokenContainer$,
        this.featureFlagFacade.featureValue$('ACCELERATION_STREAMING_DB_REFERENCE_FACTOR')
      ),
      switchMap(([datasetRegister, TokenContainer, defaultAccelerationdBreferenceFactor]) => {
        return from(
          DatasetUtils.enrichDatasetParamsWithPlottingParams(
            TokenContainer.token,
            TokenContainer.webgrpcServerURL!,
            datasetRegister,
            defaultAccelerationdBreferenceFactor as number | undefined
          )
        ).pipe(map(() => datasetRegister));
      }),
      shareReplay(1)
    );

    const datasetInfos$ = datasetRegister$.pipe(
      map((datasetRegister) => {
        console.log(`datasetRegister: ${datasetRegister}`);
        const datasetInfo: StreamingChart.DatasetInfos = {};

        Object.entries(datasetRegister.subscribeIdToLocatorMap).forEach(([subscribeID, locator]) => {
          let relatedDatasetParams = datasetRegister.datasatParamsList.find((item) => {
            return item.id === locator.id;
          });

          if (relatedDatasetParams) {
            const relevantMetaData = datasetRegister.metaDataList.items.find(
              (item) => item.subscribeId === Number(subscribeID)
            );
            const metaDataOneOfKind = relevantMetaData?.metadata?.data.oneofKind ?? '';
            const metaData = relevantMetaData?.metadata?.data?.[metaDataOneOfKind];
            relatedDatasetParams = structuredClone(relatedDatasetParams);
            relatedDatasetParams.metaData = structuredClone(metaData);
            datasetInfo[locator.id] = relatedDatasetParams;
          }
        });
        return datasetInfo;
      }),

      shareReplay(1)
    );

    const combinedDatasetParams$ = datasetInfos$.pipe(
      withLatestFrom(this.streamingPackage$, datasetRegister$),
      map(([datasetInfos, streamingPackage, datasetRegister]) => {
        console.log(
          'combinedDatasetParams:',
          Object.keys(datasetInfos).length,
          datasetInfos,
          streamingPackage,
          datasetRegister
        );

        let currentDatasetParams: StreamingChart.DatasetParameters | undefined = undefined;
        let associatedCompressedThruputDatasetParams: StreamingChart.DatasetParameters | undefined = undefined;

        switch (streamingPackage.type) {
          case StreamingType.TRACKING:
            break; // Do nothing here
          case StreamingType.MEASUREMENT:
            const streamingPackageDataset = streamingPackage.datasetContainer.dataset;
            if (streamingPackageDataset) {
              const foundParams = Object.values(datasetInfos).filter((datasetInfo) => {
                return DatasetUtils.isSelectedDatasetByDatasetParams(streamingPackageDataset, datasetInfo);
              });
              if (foundParams.length === 1) {
                currentDatasetParams = foundParams[0];
              } else {
                console.warn('Failed to find exactly one dataSetParams for the current dataset');
              }
            }
            break;
          case StreamingType.PROCESSING:
            const keys = Object.keys(datasetInfos) as string[];
            if (keys.length === 1) {
              currentDatasetParams = datasetInfos[keys[0]];
            }
            if (currentDatasetParams === undefined) {
              console.warn('Failed to find exactly one dataSetParams for the processing dataset');
            }
            break;
          default:
            exhaustiveMatchingGuard(streamingPackage.type);
        }

        /* Check whether we need to find the associated Compresse Thruput Dataset */
        if (currentDatasetParams?.rawDataType === DataType.Type_Thruput) {
          const compressedThruputDatasetParams = Object.values(datasetInfos).find((params) => {
            const hasCorrectType = params.metaData?.type === DataType.Type_CompressedThruput;
            let hasSamePositionLabel = false;
            let hasSamePositionDirection = false;
            if (hasCorrectType) {
              const position = params.metaData?.position;
              if (position !== undefined) {
                hasSamePositionLabel = params.metaData?.position?.coordinateName === currentDatasetParams?.label;
              }
              if (hasSamePositionLabel) {
                const direction = deserializePosition(position);
                if (direction !== undefined) {
                  hasSamePositionDirection =
                    StreamingChart.directionString(direction) === currentDatasetParams?.direction;
                }
              }
            }

            return hasCorrectType && hasSamePositionLabel && hasSamePositionDirection;
          });

          associatedCompressedThruputDatasetParams = compressedThruputDatasetParams;
        }

        return { currentDatasetParams, associatedCompressedThruputDatasetParams };
      }),
      shareReplay(1)
    );

    // HOW TO
    // Decide what to show
    // -> Selected Dataset via Measurement Datasetlist UI -> ends up in streaming package
    // -> If selected Dataset is Thruput, show Thruput UI, use Thruput UI as basis for compressed/full decision

    this.showChart$ = this.streamingPackage$.pipe(
      map((streamingPackage) => streamingPackage.status === StreamingMeasurementStatus.COMPLETED)
    );

    const thruputModeAndParameters$: Observable<ThruputModeAndParameters> = combineLatest([
      combinedDatasetParams$,
      this.streamingFacade.thruputParameters$
    ]).pipe(
      withLatestFrom(this.streamStatus$),
      map(([[combinedDatasetParams, thruputParameters], _]) => {
        let actualThruputMode: ThruputMode | 'unknown' = 'unknown';
        const compressedAvailable = combinedDatasetParams.associatedCompressedThruputDatasetParams !== undefined;
        if (
          thruputParameters.plotCompressed !== undefined &&
          thruputParameters.end !== undefined &&
          thruputParameters.start !== undefined
        ) {
          actualThruputMode = compressedAvailable && thruputParameters.plotCompressed ? 'compressed' : 'full';
        } else {
          actualThruputMode = compressedAvailable ? 'compressed' : 'full';
        }
        console.log(`thruputModeAndParameters - actualThruputMode: ${actualThruputMode}`);
        return { actualThruputMode, parameters: thruputParameters };
      }),
      takeUntil(this.shouldDestroy$),
      shareReplay(1)
    );

    // #endregion

    // #region Track

    // NOTE:
    // * FALLS originalTrackInfo vorhanden
    //  -> UND channelID > 0 && position vorhanden (=> trackInfo "besetzt")
    // * DANN
    //  -> Im Ergebnis der enumerateDatasets die Listeneinträge sammeln, die der channelId und position der trackInfos entsprechen
    //  => sind vermutlich 1-2 Ergebnisse (1 für SlowQuantity / TimeSeries, Drehzahl aus TachoEdges evtl. 2)
    //  => Bei CAN Datensätzen mangels dekodierInfo aufgeben
    // * DANN
    //  -> Für den SlowQuantity oder TimeSeries Kanal die DatasetMetadata abrufen
    //  -> Ggf. auf Basis der Metadaten der reduzierten Satz an Kanälen weiter reduzieren / eineindeutig identifizieren
    // * DANN
    //  -> Eigentliche Daten für den Datansatz abrufen
    //  => In diesem Stream erhältlich man hoffentlich (sic!) soviele Stufen wie im Messdatensatz vorhanden

    const selectableTracks$ = combineLatest([datasetRegister$, this.streamingFacade.selectedTrack$]).pipe(
      map(([{ metaDataList, subscribeIdToLocatorMap }, userSelectedTrack]) => {
        const validSlowChannelsMetaData = metaDataList.items.filter((item) => {
          const kind = item.metadata?.data.oneofKind ?? '';
          const hasCorrectType = item.metadata?.data?.[kind].type === DataType.Type_SlowQuantity;
          return hasCorrectType;
        });
        const validSlowChannelsSelectableTracks: SelectableTrack[] = validSlowChannelsMetaData.map((item) => {
          const kind = item.metadata?.data.oneofKind ?? '';
          const name = item.metadata?.data?.[kind].position.coordinateName ?? ('' as string);

          const quantityId = item.metadata?.data?.[kind].quantityYId;
          const quantity = metaDataList.quantities?.quantities.find((quantity) => {
            return quantity.subscribeId === quantityId;
          });

          const subscribeId = item.subscribeId;
          const locator = subscribeIdToLocatorMap[subscribeId];

          const result: SelectableTrack = {
            locator: locator?.id,
            name: name,
            quantityName: quantity?.name
          };

          return result;
        });

        return validSlowChannelsSelectableTracks;
      }),
      takeUntil(this.shouldDestroy$)
    );

    const originalTrackChannelDataset$ = combineLatest([
      combinedDatasetParams$,
      this.streamingFacade.selectedTrack$
    ]).pipe(
      filter(([combinedDatasetParams, _]) => {
        return combinedDatasetParams.currentDatasetParams !== undefined;
      }),
      withLatestFrom(datasetRegister$, thruputModeAndParameters$, this.streamingFacade.selectableTracks$),
      map(
        ([[combinedDatasetParams, userSelectedTrack], datasetRegister, thruputModeAndParameters, selectableTracks]) => {
          const metaDataList = datasetRegister.metaDataList;
          const subscribeIdToLocatorMap = datasetRegister.subscribeIdToLocatorMap;

          const tpDatasetParams = this.datasetsParamsFct(
            combinedDatasetParams.currentDatasetParams,
            combinedDatasetParams.associatedCompressedThruputDatasetParams,
            thruputModeAndParameters
          ) as StreamingChart.DatasetParaContainer;

          const currentDataSetLocatorId = tpDatasetParams.currentDatasetParams.id;
          const mapEntryWithMatchingLocatorId = Object.entries(subscribeIdToLocatorMap).find(
            ([_, locator]) => locator.id === currentDataSetLocatorId
          );

          // Using this subscribeId, find the respective item in the datasetList
          const subscribeId = mapEntryWithMatchingLocatorId ? mapEntryWithMatchingLocatorId[0] : undefined;
          const currentDatasetMetaData = metaDataList.items.find((item) => item.subscribeId.toString() === subscribeId);

          // In this dataset, find the originalTrackInfo
          const datasetOneOfKind = currentDatasetMetaData?.metadata?.data.oneofKind ?? '';
          const currentDatasetOriginalTrackInfo =
            currentDatasetMetaData?.metadata?.data?.[datasetOneOfKind]?.dataInfo.originalTrackInfo;

          // Use the channelId to find the DataSet of the originalTrack
          let trackChannelDataset: DatasetMetaDataList_Item | undefined = undefined;

          const hasSlowQuantity = selectableTracks.some((track) => track.locator !== 'TIME_TRACK');

          let actualTrackType: 'userSelected' | 'original' | 'firstSlow' | 'defaultTime';
          let selectedTrackIsTime = false;
          if (userSelectedTrack) {
            actualTrackType = 'userSelected';
            selectedTrackIsTime = userSelectedTrack.locator === 'TIME_TRACK';
          } else if (currentDatasetOriginalTrackInfo) {
            actualTrackType = 'original';
          } else if (hasSlowQuantity) {
            actualTrackType = 'firstSlow';
          } else {
            actualTrackType = 'defaultTime';
          }

          switch (actualTrackType) {
            case 'userSelected':
              if (!selectedTrackIsTime) {
                trackChannelDataset = metaDataList.items.find((item) => {
                  const locator = subscribeIdToLocatorMap[item.subscribeId];
                  return locator?.id === userSelectedTrack?.locator && locator?.id !== undefined;
                });
              }
              break;
            case 'original': {
              trackChannelDataset = metaDataList.items.find((item) => {
                const originalTrackChannelKind = item.metadata?.data.oneofKind ?? '';

                const hasCorrectType =
                  item.metadata?.data?.[originalTrackChannelKind].type === DataType.Type_SlowQuantity;
                if (!hasCorrectType) {
                  return false;
                }

                const itemChannelId = item.metadata?.data?.[originalTrackChannelKind].channelId;
                const channelIdMatches = currentDatasetOriginalTrackInfo.channelId === itemChannelId;

                const itemPosition = item.metadata?.data?.[originalTrackChannelKind].position;
                const positionMatches =
                  JSON.stringify(currentDatasetOriginalTrackInfo?.position) === JSON.stringify(itemPosition);
                if (channelIdMatches && positionMatches) {
                  return true;
                }
                return false;
              });
              break;
            }
            case 'firstSlow': {
              trackChannelDataset = metaDataList.items.find((item) => {
                const originalTrackChannelKind = item.metadata?.data.oneofKind ?? '';

                const hasCorrectType =
                  item.metadata?.data?.[originalTrackChannelKind].type === DataType.Type_SlowQuantity;
                if (!hasCorrectType) {
                  return false;
                }
                return true;
              });
              break;
            }
            case 'defaultTime': {
              trackChannelDataset = undefined;
              break;
            }
            default:
              exhaustiveMatchingGuard(actualTrackType);
          }

          return { originalTrackChannelDataset: trackChannelDataset, selectedTrackIsTime: selectedTrackIsTime };
        }
      ),
      share()
    );

    // Generelles RxJs Gotcha:
    // Subscriptions werden DEPTH FIRST behandelt
    // A als Basis
    // B subscribed auf A
    // C subscribed auf B, mit einem withLatestFrom auf A
    // ----
    // A emitted
    // B bekommt event von A
    // C bekommt event von B und versucht mit withLatestFrom auf A zu reagieren -> A hat aus Sicht von C noch nicht emitted!
    // C wird als 2. Subscriber auf A erst nach dem gesamten Durchlauf der Kette von B platziert
    // -> combineLatest/zip bevorzugen statt mit observable$/withLatestFrom
    const trackDataSetLocator$ = combineLatest([originalTrackChannelDataset$, datasetRegister$]).pipe(
      map(([{ originalTrackChannelDataset }, { subscribeIdToLocatorMap }]) => {
        const originalTrackLocator: DatasetLocator =
          subscribeIdToLocatorMap[originalTrackChannelDataset?.subscribeId ?? -1];
        return originalTrackLocator;
      })
    );

    const trackQuantity$ = combineLatest([originalTrackChannelDataset$, datasetRegister$]).pipe(
      map(([{ originalTrackChannelDataset }, { metaDataList }]) => {
        const originalTrackChannelDatasetKind = originalTrackChannelDataset?.metadata?.data.oneofKind ?? '';
        const quantityId = originalTrackChannelDataset?.metadata?.data?.[originalTrackChannelDatasetKind]?.quantityYId;
        const quantity = metaDataList.quantities?.quantities.find((quantity) => {
          return quantity.subscribeId === quantityId;
        });
        return quantity;
      })
    );

    const pushTrackChannel$ = combineLatest([trackQuantity$, this.lightningPlot$]).pipe(
      withLatestFrom(originalTrackChannelDataset$),
      filter(([[_, plot]]) => plot !== null),
      tap(([[trackQuantity, plot], trackChannelDataset]) => {
        const kind = trackChannelDataset?.originalTrackChannelDataset?.metadata?.data.oneofKind ?? '';
        const position = trackChannelDataset?.originalTrackChannelDataset?.metadata?.data?.[kind]?.position;
        plot!.updateOriginalTrackQuantityAndPosition(
          trackQuantity!,
          position,
          trackChannelDataset?.selectedTrackIsTime
        );
      })
    );

    this.subs.push(pushTrackChannel$.subscribe());

    const externalTrackValues$ = trackDataSetLocator$.pipe(
      withLatestFrom(this.tokenContainer$),
      switchMap(async ([trackDataSetLocator, tokenContainer]) => {
        if (!trackDataSetLocator?.id) {
          const externalTrackValues: ExternalTrackValues = {
            absoluteInt128Time: [],
            values: []
          };
          return externalTrackValues;
        } else {
          let tr = { startTime: 0n, stopTime: 0n } as TimeRangeAbs;
          const stream = prepareStream(tokenContainer, trackDataSetLocator.id, tr, this.abortController);

          const externalTrackValues: ExternalTrackValues = {
            absoluteInt128Time: [],
            values: []
          };

          let lastCalibration: StreamingChart.DatasetCalibration = {
            calibscale: 1.0,
            calibfact: 1.0,
            calibofs: 0.0
          };

          for await (const response of stream.responses) {
            if (response.type['datasets']) {
              response.type['datasets'].datasets.forEach((element) => {
                const chunk: DatasetChunk = element.chunk;
                if (chunk) {
                  if (chunk.data['datasetChanges']) {
                    const datasetChanges: DatasetChangesChunk = chunk.data['datasetChanges'];
                    if (datasetChanges.data['datasetCalibration']) {
                      lastCalibration = deserializeCalibration(datasetChanges.data['datasetCalibration']);
                    }
                  } else if (chunk.data['ds2DNonEquiTime']) {
                    const ds2DNonEquiTimeBlock = chunk.data['ds2DNonEquiTime'];
                    const chunkStepDataBlocks = ds2DNonEquiTimeBlock['dataBlock'];

                    chunkStepDataBlocks.forEach((chunkStepDataBlock) => {
                      const timeValues: AbsoluteTime[] = chunkStepDataBlock.xValues['timeValues']['timeVals'];
                      let yValues: number[] = [];
                      switch (chunkStepDataBlock.yValues['oneofKind']) {
                        case 'yDoubleValues':
                          yValues = chunkStepDataBlock.yValues.yDoubleValues.data;
                          break;
                        case 'yFloatValues':
                          yValues = chunkStepDataBlock.yValues.yFloatValues.data;
                          break;
                        case 'yInt32Values':
                          yValues = chunkStepDataBlock.yValues.yInt32Values.data;
                          break;
                        default:
                          console.error('data values type not supported: ', chunkStepDataBlock.yValues['oneofKind']);
                          break;
                      }
                      if (timeValues.length === yValues.length) {
                        timeValues.forEach((timeVal, i) => {
                          const time = convertAbsoluteTimeToInt128(timeVal as AbsoluteTime);
                          let relatedStepValue = yValues[i];
                          if (time) {
                            externalTrackValues.absoluteInt128Time.push(time);
                            // Calibration
                            if (lastCalibration) {
                              relatedStepValue =
                                lastCalibration.calibfact *
                                (lastCalibration.calibscale * relatedStepValue + lastCalibration.calibofs);
                            }

                            externalTrackValues.values.push(relatedStepValue);
                          } else {
                            console.error('Failed to convert timeVal to absoluteTime', timeVal);
                          }
                        });
                      } else {
                        console.error('xValues and yValues of chunk have different lengths');
                      }
                    });
                  }
                }
              });
            }
          }
          return externalTrackValues;
        }
      }),
      takeUntil(this.shouldDestroy$),
      shareReplay(1)
    );

    const pushExternalTrackValues = combineLatest([this.lightningPlot$, externalTrackValues$]).pipe(
      filter(([plot, externalTrackValues]) => plot !== null && externalTrackValues !== undefined),
      tap(([plot, externalTrackValues]) => {
        plot!.updateExternalTrackValues(externalTrackValues!);
      })
    );

    this.subs.push(pushExternalTrackValues.subscribe());

    // #endregion

    // Chart Type
    const chartType$ = combinedDatasetParams$.pipe(
      map((combinedDatasetParams) => {
        return getChartType(combinedDatasetParams.currentDatasetParams);
      }),
      filter((chartType) => chartType !== undefined),
      map((chartType) => chartType as StreamingChart.ChartType),
      shareReplay(1)
    );

    const getChartType = (currentDatasetParams: StreamingChart.DatasetParameters | undefined) => {
      return currentDatasetParams?.plottingParameters?.chartType;
    };

    // #region UI Interaction

    this.subs.push(
      selectableTracks$.subscribe((validSlowChannelsSelectableTracks) => {
        if (validSlowChannelsSelectableTracks.length > 0) {
          this.streamingFacade.setSelectableTracks(validSlowChannelsSelectableTracks);
        }
      })
    );

    this.subs.push(
      chartType$.subscribe((chartType) => {
        this.streamingFacade.showTrackSelection(chartType === 'chart3D' || chartType === 'chart3D2D');
      })
    );

    // Display appropriate scaling / axis options for chart type
    this.subs.push(
      combineLatest([chartType$, combinedDatasetParams$])
        .pipe(
          withLatestFrom(thruputModeAndParameters$),
          map(([[chartType, combinedDatasetParams], thruputModeAndParameters]) => {
            this.showYAxisRefreshButton$.next(false);

            const tpDatasetParams = this.datasetsParamsFct(
              combinedDatasetParams.currentDatasetParams,
              combinedDatasetParams.associatedCompressedThruputDatasetParams,
              thruputModeAndParameters
            ) as StreamingChart.DatasetParaContainer;

            const plotParams = tpDatasetParams.currentDatasetParams?.plottingParameters;
            switch (chartType) {
              case StreamingChart.ChartType.CHART2DEqui:
                this.streamingFacade.setAvailableAxisInteraction('xAxis', ['lin']);
                this.streamingFacade.setSelectedAxisInteraction('xAxis', 'lin');
                this.streamingFacade.setAvailableAxisInteraction('yAxis', ['lin']);
                this.streamingFacade.setSelectedAxisInteraction('yAxis', 'lin');
                this.streamingFacade.setAvailableColorInteraction([]);
                break;
              case StreamingChart.ChartType.CHART2DNonEqui: {
                this.streamingFacade.setAvailableAxisInteraction('xAxis', ['lin']);
                this.streamingFacade.setSelectedAxisInteraction('xAxis', 'lin');
                this.streamingFacade.setAvailableAxisInteraction('yAxis', ['lin', 'log', 'db']);

                this.streamingFacade.setAvailableColorInteraction([]);

                let desiredYBarConfig: AxisScalingTypes = 'log';
                switch (plotParams?.quantityY?.rawQuantity.axisScaling) {
                  case 'linear':
                    desiredYBarConfig = 'lin';
                    break;
                  case 'logarithmic':
                    desiredYBarConfig = 'log';
                    break;
                  case 'db':
                    desiredYBarConfig = 'db';
                    break;
                }

                this.streamingFacade.setSelectedAxisInteraction('yAxis', desiredYBarConfig);
                break;
              }
              case StreamingChart.ChartType.CHART2DTacho:
                this.streamingFacade.setAvailableAxisInteraction('xAxis', ['lin']);
                this.streamingFacade.setSelectedAxisInteraction('xAxis', 'lin');
                this.streamingFacade.setAvailableAxisInteraction('yAxis', ['lin']);
                this.streamingFacade.setSelectedAxisInteraction('yAxis', 'lin');
                this.streamingFacade.setAvailableColorInteraction([]);
                break;
              // TODO: Implement CHART2DNonEqui Handling?
              //              case StreamingChart.ChartType.CHART2DNonEqui:
              // this.streamingFacade.setAvailableAxisInteraction('xAxis', ['lin']);
              // this.streamingFacade.setSelectedAxisInteraction('xAxis', 'lin');
              // this.streamingFacade.setAvailableAxisInteraction('yAxis', ['lin']);
              // this.streamingFacade.setSelectedAxisInteraction('yAxis', 'lin');
              // this.streamingFacade.setAvailableColorInteraction([]);
              // break;

              case StreamingChart.ChartType.CHART3D: {
                if (plotParams?.hasOctave) {
                  this.streamingFacade.setAvailableAxisInteraction('xAxis', ['third']);
                  this.streamingFacade.setSelectedAxisInteraction('xAxis', 'third');
                } else {
                  this.streamingFacade.setAvailableAxisInteraction('xAxis', ['lin']);
                  this.streamingFacade.setSelectedAxisInteraction('xAxis', 'lin');
                }

                this.streamingFacade.setAvailableAxisInteraction('yAxis', ['lin']);
                this.streamingFacade.setSelectedAxisInteraction('yAxis', 'lin');

                this.streamingFacade.setAvailableColorInteraction(['lin', 'log', 'db']);

                let desiredColorInteraction: ColorScalingTypes = 'db';
                switch (plotParams?.quantityY?.rawQuantity.axisScaling) {
                  case 'db':
                    {
                      desiredColorInteraction = 'db';
                    }
                    break;
                  case 'logarithmic':
                    {
                      desiredColorInteraction = 'log';
                    }
                    break;
                  case 'linear':
                    {
                      desiredColorInteraction = 'lin';
                    }
                    break;
                }

                if (tpDatasetParams.usedRawDataType === DataType.Type_TimeBlock) {
                  desiredColorInteraction = 'lin';
                }

                this.streamingFacade.setSelectedColorInteraction(desiredColorInteraction);

                this.showYAxisRefreshButton$.next(true);

                break;
              }

              case StreamingChart.ChartType.CHART3D2D: {
                this.streamingFacade.setAvailableAxisInteraction('xAxis', ['lin']);
                this.streamingFacade.setSelectedAxisInteraction('xAxis', 'lin');
                this.streamingFacade.setAvailableAxisInteraction('yAxis', ['lin', 'log', 'db']);
                this.streamingFacade.setSelectedAxisInteraction('yAxis', 'lin');
                this.streamingFacade.setAvailableColorInteraction([]);

                let desiredYConfig: AxisScalingTypes = 'log';
                switch (plotParams?.quantityY?.rawQuantity.axisScaling) {
                  case 'linear':
                    desiredYConfig = 'lin';
                    break;
                  case 'logarithmic':
                    desiredYConfig = 'log';
                    break;
                  case 'db':
                    desiredYConfig = 'db';
                    break;
                }
                this.streamingFacade.setSelectedAxisInteraction('yAxis', desiredYConfig);

                break;
              }

              default:
                exhaustiveMatchingGuard(chartType);
            }
          })
        )
        .subscribe()
    );

    // #endregion

    // #region ScalesConfig

    const scalesConfig$ = this.streamingFacade.axisInteractions$.pipe(
      map((axisInteractions) => ({
        xAxis: axisInteractions.xAxis.selected,
        yAxis: axisInteractions.yAxis.selected,
        color: axisInteractions.color.selected
      })),
      takeUntil(this.shouldDestroy$),
      shareReplay(1)
    );

    const setScales$ = scalesConfig$.pipe(
      withLatestFrom(this.lightningPlot$),
      filter(([_, lightningPlot]) => lightningPlot !== null)
      // distinctUntilChanged(([a, _], [b, __]) => JSON.stringify(a) === JSON.stringify(b))
    );

    this.subs.push(
      setScales$.subscribe(([scalesConfig, lightningPlot]) => {
        console.log('setScalesConfig: ', scalesConfig);
        lightningPlot!.setScalesConfig(scalesConfig);
      })
    );

    const doPlot$ = combineLatest([combinedDatasetParams$, thruputModeAndParameters$]).pipe(
      filter(([combinedDatasetParams, _]) => {
        console.log(
          'doPlot filter - combinedDatasetParams.currentDatasetParams: ',
          combinedDatasetParams.currentDatasetParams
        );
        return combinedDatasetParams.currentDatasetParams !== undefined;
      }),
      withLatestFrom(scalesConfig$, this.lightningPlot$, this.streamStatus$),
      filter(([_, __, ___, streamStatus]) => {
        console.log('doPlot filter - isPlotting: ', this.isPlotting);
        return !this.isPlotting; // streamStatus !== 'streaming';
      }),
      // takeUntil
      map(([[combinedDatasetParams, thruputModeAndParameters], scalesConfig, plot, streamStatus]) => {
        console.log(
          'Current thruput parameters',
          thruputModeAndParameters?.parameters?.start,
          thruputModeAndParameters?.parameters?.end,
          thruputModeAndParameters?.actualThruputMode
        );
        console.log(`doPlot - isPlotting: ${this.isPlotting} ==> true`);
        this.isPlotting = true;

        if (streamStatus === 'streaming') {
          console.error(
            `doPlot - streaming in progress (streamStatus: ${streamStatus} , isPlotting ${this.isPlotting}) ==> false`
          );
          this.isPlotting = false;
          return;
        } else {
          console.log(`doPlot - streamStatus: ${streamStatus} `);
        }

        const tpDatasetParams = this.datasetsParamsFct(
          combinedDatasetParams.currentDatasetParams,
          combinedDatasetParams.associatedCompressedThruputDatasetParams,
          thruputModeAndParameters
        ) as StreamingChart.DatasetParaContainer;

        const plotCompressedPara = tpDatasetParams.compressedDatasetParams?.plottingParameters;
        let plotCompressed = plotCompressedPara !== undefined && plotCompressedPara.deltaX !== undefined;
        if (thruputModeAndParameters !== undefined) {
          plotCompressed = thruputModeAndParameters.actualThruputMode === 'compressed';
        }

        console.log(
          'doPlot plot: ',
          plot,
          ', plotCompressed: ',
          plotCompressed,
          'plot.tpDatasetParams.usedRawDataType: ',
          plot?.tpDatasetParams.usedRawDataType,
          'hasCompresedData: ',
          plot ? hasCompressedData(plot.values) : false
        );

        if (plot && plotCompressed && hasCompressedData(plot.values)) {
          plot.resetFullData();
          if (plot.tpDatasetParams.usedRawDataType !== DataType.Type_CompressedThruput) {
            plot.tpDatasetParams.usedRawDataType = DataType.Type_CompressedThruput;
            plot.handleScaleConfigChange(scalesConfig);
          }
          console.log(`doPlot eof - isPlotting: ${this.isPlotting} ==> false`);
          this.isPlotting = false;
          return undefined;
        } else {
          console.log('doPlot - tpDatasetParams: ', tpDatasetParams);
          return tpDatasetParams;
        }
      }),
      takeUntil(this.shouldDestroy$),
      shareReplay(1)
    );

    // #endregion

    const createStreamable$: Observable<void> = doPlot$.pipe(
      filter((tpDatasetParams) => {
        console.log('createStreamable tpDatasetParams: ', tpDatasetParams);
        const ret = tpDatasetParams !== undefined;
        if (!ret) {
          console.log(`createStreamable eof - isPlotting: ${this.isPlotting} ==> false`);
          this.isPlotting = false;
        }
        return ret;
      }),
      filter((tpDatasetParams) => getChartType(tpDatasetParams?.currentDatasetParams) !== undefined),
      tap((tpDatasetParams) => {
        console.log('doCreateStreamable$ tpDatasetParams: ', tpDatasetParams);
      }),
      withLatestFrom(this.license$, this.tokenContainer$, scalesConfig$, this.lightningPlot$),
      map(([_tpDatasetParams, license, tokenContainer, scalesConfig, plot]) => {
        const tpDatasetParams = _tpDatasetParams!;
        const chartType = getChartType(tpDatasetParams.currentDatasetParams)!;
        this.preparePlot(plot, chartType, tpDatasetParams!, license, scalesConfig);
        plot = this.lightningPlot$.getValue(); // getValue() may return null, if the lightningPlot$.next() event is delayed
        let locator =
          tpDatasetParams.usedRawDataType === DataType.Type_CompressedThruput
            ? tpDatasetParams.compressedDatasetParams?.id
            : tpDatasetParams.currentDatasetParams.id;
        console.log('prepare stream: ' + locator);

        let tr: TimeRangeAbs = {
          startTime:
            tpDatasetParams.usedRawDataType === DataType.Type_CompressedThruput
              ? tpDatasetParams.compressedDatasetParams?.desiredTimeRange?.start ?? 0n
              : tpDatasetParams.currentDatasetParams?.desiredTimeRange?.start ?? 0n,
          stopTime:
            tpDatasetParams.usedRawDataType === DataType.Type_CompressedThruput
              ? tpDatasetParams.compressedDatasetParams?.desiredTimeRange?.stop ??
                convertAbsoluteTimeToInt128(BBM_ABSOLUTE_TIME_MAX()) ??
                0n
              : tpDatasetParams.currentDatasetParams?.desiredTimeRange?.stop ??
                convertAbsoluteTimeToInt128(BBM_ABSOLUTE_TIME_MAX()) ??
                0n
        };
        const pusher = pushToChartHandlingFunction(
          chartType,
          plot!,
          tpDatasetParams.usedRawDataType === DataType.Type_CompressedThruput ? 'compressed' : 'full'
        );
        this.isPlottingContainer$.next({ tpDatasetParams: tpDatasetParams, pusher: pusher });

        const stream = prepareStream(tokenContainer, locator, tr, this.abortController);
        return stream;
      }),
      switchMap((stream) => {
        return of(stream).pipe(
          tap(async (stream) => {
            console.log('streaming started');
            this.streamStatus$.next('streaming');
            for await (const response of stream.responses) {
              const shouldCancel = await firstValueFrom(this.shouldCancel$);
              if (shouldCancel.shouldCancel) {
                console.log('streaming cancelled');
                this.streamStatus$.next('cancelled');
                this.abortController.abort();
                return;
              }

              if (response.type['datasets']) {
                response.type['datasets'].datasets.forEach((element) => {
                  const chunk: DatasetChunk = element.chunk;
                  if (chunk) {
                    this.chunks$.next(chunk);
                  }
                });
              }
            }
            console.log('streaming loop completed');
          }),
          map((_) => {})
        );
      }),
      takeUntil(this.shouldDestroy$),
      shareReplay(1)
    );

    // #region Chart Handling Functions

    const pushToChartHandlingFunction = (
      chartType: StreamingChart.ChartType,
      plot: LightningPlot,
      actualThruputMode: ThruputMode
    ): pushTo2DChartHandlingFunction | pushTo3DChartHandlingFunction | undefined => {
      switch (chartType) {
        case StreamingChart.ChartType.CHART3D:
          return (plot as LightningPlot3DHeatmap).update3Ddata;
        case StreamingChart.ChartType.CHART3D2D:
          return (plot as LightningPlot3D2D).add3D2Dincrement;
        case StreamingChart.ChartType.CHART2DEqui: // Echte Thruputs
          const isCompressedThruput = actualThruputMode === 'compressed';
          if (isCompressedThruput) {
            return (plot as LightningPlot2D).add2dCompressedDataIncrement;
          } else {
            return (plot as LightningPlot2D).add2dEquiDataIncrement;
          }
        case StreamingChart.ChartType.CHART2DTacho:
          return (plot as LightningPlot2D).add2dTachoIncrement;
        case StreamingChart.ChartType.CHART2DNonEqui: // Der große Rest / Name unpassend (hat auch TimeSeries mit x/y Wertepaaren
          return (plot as LightningPlot2D).add2dSlowQuantityDataIncrement;
        default:
          exhaustiveMatchingGuard(chartType);
      }
    };

    // #endregion

    this.subs.push(doPlot$.subscribe(/*(val) => console.log('doPlot triggered')*/));
    this.subs.push(createStreamable$.subscribe(/*(val) => console.log('createStreamable$ triggered')*/));

    // #region DatasetStepValues

    const datasetStepValues$ = streamSetup$.pipe(switchMap(() => this.datasetStepValueStream$));

    const pushDatasetStepValues$ = datasetStepValues$.pipe(
      withLatestFrom(this.lightningPlot$),
      filter(([datasetStepValues], plot) => plot !== null && datasetStepValues !== undefined),
      tap(([datasetStepValues, plot]) => {
        plot!.updateDatasetStepValues(datasetStepValues!);
      })
    );

    this.subs.push(pushDatasetStepValues$.subscribe());

    this.subs.push(
      this.calibrations$.pipe(withLatestFrom(this.lightningPlot$)).subscribe(([calibration, plot]) => {
        plot?.updateCalibration(calibration);
      })
    );

    // #endregion

    // #region 2D

    type Deserializer2DIntermediate = {
      length: number;
      deserializedChunk?: (
        | StreamingChart.DataPoint2D
        | StreamingChart.DataPoint3D2D
        | StreamingChart.EofInfo
        | StreamingChart.BofInfo
      )[];
    };
    const deserialized2DChunks$ = this.isPlottingContainer$.pipe(
      filter(
        (plottingContainer) =>
          getChartType(plottingContainer.tpDatasetParams.currentDatasetParams) !== StreamingChart.ChartType.CHART3D
      ),
      map((plottingcontainer) => plottingcontainer.tpDatasetParams.currentDatasetParams.plottingParameters),
      switchMap((plottingParameters) =>
        this.chunks$.pipe(
          scan(
            (acc, chunk): Deserializer2DIntermediate => {
              if (chunk.data['datasetChanges']) {
                const datasetChanges: DatasetChangesChunk = chunk.data['datasetChanges'];
                if (datasetChanges.data.oneofKind === 'datasetStepValues' && datasetChanges.data['datasetStepValues']) {
                  const stepValues = datasetChanges.data['datasetStepValues'];
                  // console.log(`'step value :'${stepValues.stepIndex}' '${stepValues.trackValue}' '${stepValues.orderRpmValue}`);
                  if (this.lightningPlot$.getValue()) {
                    this.lightningPlot$.getValue()?.updateDatasetStepValues(stepValues);
                  } else {
                    console.error('No plot chart availbe when step values reached');
                  }
                }
                if (datasetChanges.data['datasetCalibration']) {
                  this.calibrations$.next(deserializeCalibration(datasetChanges.data['datasetCalibration']));
                }
                if (datasetChanges.data['datasetEofTime']) {
                  let deserializedChunk: (
                    | StreamingChart.DataPoint2D
                    | StreamingChart.DataPoint3D2D
                    | StreamingChart.EofInfo
                    | StreamingChart.BofInfo
                  )[] = [];
                  deserializedChunk.push(deserializeEofTime(datasetChanges.data['datasetEofTime']));
                  console.log(`dataset Eof Time : ${deserializedChunk.at(deserializedChunk.length - 1)}`);
                  return { length: acc.length + deserializedChunk.length, deserializedChunk };
                }
                if (datasetChanges.data['datasetBofTime']) {
                  let deserializedChunk: (
                    | StreamingChart.DataPoint2D
                    | StreamingChart.DataPoint3D2D
                    | StreamingChart.EofInfo
                    | StreamingChart.BofInfo
                  )[] = [];
                  deserializedChunk.push(deserializeBofTime(datasetChanges.data['datasetBofTime']));
                  console.log(`dataset Bof Time : ${deserializedChunk.at(deserializedChunk.length - 1)}`);
                  return { length: acc.length + deserializedChunk.length, deserializedChunk };
                }
                return { length: acc.length, deserializedChunk: undefined };
              } else {
                const deserializedChunk = deserializeChunkForCHART2D(chunk, {
                  initialSampleCounter: acc.length,
                  plottingParameters: plottingParameters
                }) as (
                  | StreamingChart.DataPoint2D
                  | StreamingChart.DataPoint3D2D
                  | StreamingChart.EofInfo
                  | StreamingChart.BofInfo
                )[];
                const result = { length: acc.length + deserializedChunk.length, deserializedChunk };

                return result;
              }
            },
            { length: 0, deserializedChunk: undefined }
          ),
          takeUntil(this.shouldCancel$.pipe(filter((shouldCancel) => shouldCancel.shouldCancel)))
        )
      )
    );

    this.subs.push(
      deserialized2DChunks$.subscribe((intermediate) => {
        if (intermediate.length > MAX_SAMPLES) {
          this.shouldCancel$.next({ shouldCancel: true, reason: 'STREAMING.CANCEL_REASON.SAMPLES' });
        }
      })
    );

    const throttledDeserialized2DChunks$ = deserialized2DChunks$.pipe(
      bufferTime(0, animationFrameScheduler), // Throttling based on rendering capabilities of the browser
      filter((x) => x.length > 0)
    );

    const push2D = throttledDeserialized2DChunks$
      .pipe(withLatestFrom(this.isPlottingContainer$))
      .subscribe(([bufferedChunks, plottingContainer]) => {
        const pusher = plottingContainer.pusher;
        const deserializedChunk = bufferedChunks.reduce((acc, intermediate2D) => {
          if (intermediate2D.deserializedChunk) {
            acc = acc.concat(intermediate2D.deserializedChunk);
          }
          return acc;
        }, [] as (StreamingChart.DataPoint2D | StreamingChart.DataPoint3D2D | StreamingChart.EofInfo | StreamingChart.BofInfo)[]);

        let eofReached = false;
        let bofReached = false;
        let bofChunk: StreamingChart.BofInfo | undefined = undefined;
        if (deserializedChunk && deserializedChunk.length > 0) {
          const ind = deserializedChunk.findIndex((value, index) => {
            return StreamingChart.isBofInfo(value);
          });
          if (ind >= 0) {
            bofReached = true;
            bofChunk = deserializedChunk.splice(ind, 1).at(0) as StreamingChart.BofInfo;
          }
          if (deserializedChunk.at(-1) && StreamingChart.isEofInfo(deserializedChunk.at(-1)!)) {
            eofReached = true;
            deserializedChunk.pop();
          }
        }

        const chunksAre3D2D = deserializedChunk.every((chunk) => StreamingChart.isDataPoint3D2D(chunk));
        const chunksAre2D = deserializedChunk.every((chunk) => StreamingChart.isDataPoint2D(chunk));

        if (chunksAre3D2D) {
          const chunk3D2D = deserializedChunk as StreamingChart.DataPoint3D2D[];
          if (pusher) {
            (pusher as pushTo2DChartHandlingFunction)({
              startTime: bofChunk ? bofChunk.startTime : undefined,
              firstSampleTime: bofChunk ? bofChunk.firstSampleTime : undefined,
              dataValues: chunk3D2D.map((chunk) => chunk.y),
              absTimeValues: chunk3D2D.map((chunk) => chunk.steptime),
              trackOrXValues: chunk3D2D.map((chunk) => chunk.trackvalue),
              eofReached: eofReached,
              bofReached: bofReached
            });
          }
        } else if (chunksAre2D) {
          const chunk2D = deserializedChunk as StreamingChart.DataPoint2D[];
          if (pusher) {
            const hasTimeValues = chunk2D.at(0) ? typeof chunk2D.at(0)!.x === 'bigint' : false;
            if (hasTimeValues) {
              (pusher as pushTo2DChartHandlingFunction)({
                startTime: bofChunk ? bofChunk.startTime : undefined,
                firstSampleTime: bofChunk ? bofChunk.firstSampleTime : undefined,
                dataValues: chunk2D.map((chunk) => chunk.y),
                absTimeValues: chunk2D.map((chunk) => chunk.x as bigint), // TODO: distinguish between equi und non equi
                eofReached: eofReached,
                bofReached: bofReached
              });
            } else {
              (pusher as pushTo2DChartHandlingFunction)({
                startTime: bofChunk ? bofChunk.startTime : undefined,
                firstSampleTime: bofChunk ? bofChunk.firstSampleTime : undefined,
                dataValues: chunk2D.map((chunk) => chunk.y),
                trackOrXValues: chunk2D.map((chunk) => chunk.x as number), // TODO: distinguish between equi und non equi
                eofReached: eofReached,
                bofReached: bofReached
              });
            }
          }
        } else {
          console.error('Mixed 2D and 3D in one deserialized chunk block');
        }
        if (eofReached) {
          console.log(`streaming 2D eofReached - isPlotting: ${this.isPlotting} ==> false`);
          this.isPlotting = false;
          this.streamStatus$.next('eofReached');
        }
      });

    this.subs.push(push2D);

    // #endregion

    // #region 3D

    type Deserializer3DIntermediate = {
      length: number;
      deserializedChunk?: StreamingChart.MagnitudesAndTrackValues;
      eofReached: boolean;
    };
    const deserialized3DChunks$ = this.isPlottingContainer$.pipe(
      filter(
        (plottingContainer) =>
          getChartType(plottingContainer.tpDatasetParams.currentDatasetParams) === StreamingChart.ChartType.CHART3D
      ),
      switchMap((_) =>
        this.chunks$.pipe(
          scan(
            (acc, chunk): Deserializer3DIntermediate => {
              if (chunk.data['datasetChanges']) {
                const datasetChanges: DatasetChangesChunk = chunk.data['datasetChanges'];
                if (datasetChanges.data.oneofKind === 'datasetStepValues' && datasetChanges.data['datasetStepValues']) {
                  const stepValues = datasetChanges.data['datasetStepValues'];
                  // console.log(`'step value :'${stepValues.stepIndex}' '${stepValues.trackValue}' '${stepValues.orderRpmValue}`);
                  if (this.lightningPlot$.getValue()) {
                    this.lightningPlot$.getValue()?.updateDatasetStepValues(stepValues);
                  } else {
                    console.error('No plot chart availbe when step values reached');
                  }
                }
                if (datasetChanges.data['datasetCalibration']) {
                  this.calibrations$.next(deserializeCalibration(datasetChanges.data['datasetCalibration']));
                }
                if (datasetChanges.data['datasetEofTime']) {
                  return { length: acc.length, deserializedChunk: undefined, eofReached: true };
                }
                return { length: acc.length, deserializedChunk: undefined, eofReached: false };
              } else {
                const deserializedChunk = deserializeChunkForCHART3D(chunk) as StreamingChart.MagnitudesAndTrackValues;
                return { length: acc.length + deserializedChunk.zValues.length, deserializedChunk, eofReached: false };
              }
            },
            { length: 0, deserializedChunk: undefined, eofReached: false }
          )
        )
      )
    );

    const push3D = deserialized3DChunks$.pipe(
      bufferTime(50),
      filter((x) => x.length > 0),
      withLatestFrom(this.isPlottingContainer$),
      map(([chunkIntermediates, plottingContainer]) => {
        const pusherFunction = plottingContainer.pusher;
        const dataValues: number[][] = [];
        const zValues: number[] = [];
        const timeValues: bigint[] = [];
        let eofReached: boolean = false;
        chunkIntermediates.forEach((intermediate) => {
          if (intermediate.eofReached) {
            eofReached = true;
          }
          if (intermediate.deserializedChunk) {
            if (intermediate.deserializedChunk !== undefined && intermediate.deserializedChunk.magnitudes.length > 0) {
              const matrixToAdd = intermediate.deserializedChunk.magnitudes;
              for (let rowIndex = 0; rowIndex < matrixToAdd.length; rowIndex++) {
                dataValues.push(matrixToAdd[rowIndex]);
              }
              zValues.push(...intermediate.deserializedChunk.zValues);
              timeValues.push(...intermediate.deserializedChunk.timeValues);
            }
          }
        });

        if (pusherFunction) {
          const f = pusherFunction as pushTo3DChartHandlingFunction;
          f({
            zValues,
            timeValues,
            dataValues,
            eofReached: eofReached
          });
        }
        if (eofReached) {
          console.log(`streaming 3D eofReached - isPlotting: ${this.isPlotting} ==> false`);
          this.isPlotting = false;
          this.streamStatus$.next('eofReached');
        }
      })
    );

    this.subs.push(push3D.subscribe());

    // #endregion

    this.subs.push(
      this.destroy$
        .pipe(
          filter((shouldDestroy) => shouldDestroy),
          withLatestFrom(this.lightningPlot$)
        )
        .subscribe(([_, plot]) => {
          if (plot) {
            plot.dispose();
          }
        })
    );
  }

  private initLCPlot = async (
    chartType: StreamingChart.ChartType,
    tpDatasetParams: StreamingChart.DatasetParaContainer,
    licenseConfig: StreamingChart.LightningConfig,
    scalesConfig: ScalesConfig
  ) => {
    const htmlElementId = 'chartId';
    const yBarElementId = 'yBarId';

    console.log(`initLCPlot: ${chartType}, ${tpDatasetParams}, ${licenseConfig}, ${scalesConfig}`);
    let plot: LightningPlot2D | LightningPlot3D2D | LightningPlot3DHeatmap | null;
    switch (chartType) {
      case StreamingChart.ChartType.CHART2DEqui:
      case StreamingChart.ChartType.CHART2DTacho:
      case StreamingChart.ChartType.CHART2DNonEqui: {
        this.streamingFacade.setYBarAxisVisible(false);
        this.lightningPlot$.next(
          new LightningPlot2D(
            {
              htmlElementId,
              chartType,
              tpDatasetParams,
              licenseConfig,
              scalesConfig
            },
            this.translate,
            this.streamingFacade
          )
        );
        break;
      }
      case StreamingChart.ChartType.CHART3D2D: {
        this.streamingFacade.setYBarAxisVisible(false);
        this.lightningPlot$.next(
          new LightningPlot3D2D(
            {
              htmlElementId,
              chartType,
              tpDatasetParams,
              licenseConfig,
              scalesConfig
            },
            this.translate,
            this.streamingFacade
          )
        );
        break;
      }
      case StreamingChart.ChartType.CHART3D: {
        this.streamingFacade.setYBarAxisVisible(true);

        // NOTE: This is a workaround to prevent the chart from being initialized before the yBar is rendered
        // await firstValueFrom(this.ngZone.onMicrotaskEmpty.pipe(take(1))); // Await the next microtask / changedetection cycle to end
        // NOTE: uncommented this workaround, because it prevents plot to be accessible immediately
        this.lightningPlot$.next(
          new LightningPlot3DHeatmap(
            {
              htmlElementId,
              yBarElementId,
              chartType,
              tpDatasetParams,
              licenseConfig,
              scalesConfig
            },
            this.translate,
            this.streamingFacade
          )
        );
        break;
      }
      default: {
        exhaustiveMatchingGuard(chartType);
      }
    }
  };

  private preparePlot(
    plot: LightningPlot2D | LightningPlot3D2D | LightningPlot3DHeatmap | null,
    chartType: StreamingChart.ChartType,
    tpDatasetParam: StreamingChart.DatasetParaContainer,
    license: StreamingChart.LightningConfig,
    scalesConfig: { xAxis: AxisScalingTypes; yAxis: AxisScalingTypes; color: ColorScalingTypes }
  ) {
    if (!plot || plot.chartType !== chartType) {
      console.log('preparePlot - plot not initialized or chartType changed');
      plot?.dispose();
      this.initLCPlot(chartType, tpDatasetParam, license, scalesConfig);
    } else {
      console.log('preparePlot - plot initialized');
      plot.tpDatasetParams = tpDatasetParam;
      plot.scalesConfig = scalesConfig;
      plot.resetCompressedData();
      plot.resetFullData();
    }
  }

  async handleAxisReset() {
    const plot = (await firstValueFrom(this.lightningPlot$.pipe(take(1)))) as LightningPlot;
    if (plot) {
      plot.resetAxis();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
