import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect, concatLatestFrom } from '@ngrx/effects';

import { Store } from '@ngrx/store';

import { MeasurementsPartialState } from './measurements.reducer';
import * as MeasurementsAction from './measurements.actions';

import { MeasurementsMoveService } from '../measurementsMoveModal/measurementsMove.service';

import { catchError, combineLatestWith, concatMap, delay, from, map, switchMap, tap } from 'rxjs';
import { createAppError } from '../../app.factories';
import { MeasurementsFacade } from './measurements.facade';

// 0. User selects Measurement(s) to move and triggers the move
// 1. Gather Details/Content (depot/project/job = path) of selected Measurement(s)
// 2. Check whether all selected measurements have the same path
// 2E. If not, show error message, abort move
// 3. Show Move Modal
// / Shows Path
// / Shows selected Measurement(s) and their details
// / Shows Selectable Target Paths (only jobs that are in the same depot/project)
// / Alternatively, user can enter a new job name
// / Show "overwrite existing" checkbox
// / cancel/ok buttons accordingly
// 4. Trigger Move
// 5. Highlight 'active moves' in Top Menu -> Show interaction

@Injectable()
export class MeasurementsMoveEffects {
  constructor(
    private actions$: Actions,
    private store: Store<MeasurementsPartialState>,
    private measurementsFacade: MeasurementsFacade,
    private measurementsMoveService: MeasurementsMoveService
  ) {}

  showMoveModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementsAction.PrepareMove),
      map((action) => {
        console.log('PrepareMove', action.searchResults);
        return MeasurementsAction.ShowMeasurementMoveModal({ show: true });
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  triggerGetPathAttributes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementsAction.PrepareMove),
      map((action) =>
        MeasurementsAction.GetPathAttributesForMeasurementsViaSearch({ searchResults: action.searchResults })
      ),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  getPathAttributesViaSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementsAction.GetPathAttributesForMeasurementsViaSearch),
      switchMap((action) => {
        return this.measurementsMoveService.getMeasurementPaths(action.searchResults);
      }),
      map((intermediateMeasurementDetails) =>
        MeasurementsAction.PathAttributesForMeasurementsReceived({ intermediateMeasurementDetails })
      ),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  requestDryRun$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementsAction.SetTargetPath),
      concatLatestFrom(() => this.measurementsFacade.intermediateMeasurements$),
      map(([action, intermediates]) => {
        return MeasurementsAction.RequestMoveDryRun({ intermediateMeasurementDetails: intermediates });
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  performDryRun$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementsAction.RequestMoveDryRun),
      concatLatestFrom(() => [this.measurementsFacade.targetPath$]),
      switchMap(([action, path]) =>
        this.measurementsMoveService.performMoveDryRun(action.intermediateMeasurementDetails, path)
      ),
      map((dryRunResults) => {
        return MeasurementsAction.MoveDryRunReturned({ dryRunResults });
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  requestMove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementsAction.RequestMove),
      concatMap((action) => {
        return this.measurementsMoveService.requestMeasurementMove(action.moveRequest);
      }),
      map((moveRequest) => {
        return MeasurementsAction.MoveRequested({ moveRequest });
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  initMonitorMove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementsAction.MoveRequested),
      map((action) => {
        return MeasurementsAction.MonitorMoveRequest({ moveRequest: action.moveRequest });
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );

  monitorMove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurementsAction.MonitorMoveRequest),
      delay(500),
      concatMap((action) => {
        return this.measurementsMoveService.pollMoveStatus(action.moveRequest);
      }),
      map((measurementMove) => {
        if (!measurementMove) {
          return MeasurementsAction.ExitMoveRequestMonitoring();
        } else if (measurementMove.moveState === 'done') {
          return MeasurementsAction.CleanUpMoveRequest({ moveRequest: measurementMove });
        } else if (measurementMove.moveState === 'error') {
          return MeasurementsAction.MoveRequestFailed({ moveRequest: measurementMove });
        } else if (measurementMove.moveState === 'init') {
          return MeasurementsAction.MonitorMoveRequest({ moveRequest: measurementMove });
        } else if (measurementMove.moveState === 'moving') {
          return MeasurementsAction.MonitorMoveRequest({ moveRequest: measurementMove });
        } else {
          return MeasurementsAction.MonitorMoveRequest({ moveRequest: measurementMove });
        }
      }),
      catchError((error, caught) => {
        this.store.dispatch(createAppError(error));
        return caught;
      })
    )
  );
}
