<label class="container" [for]="activeListName" [ngClass]="{ disabled: isDisabled }">
  <ul
    class="pick-list"
    role="listbox"
    (clickOutside)="handleClickOutside($event, activeListName)"
    (click)="handleClickInside($event, activeListName)"
    [ngClass]="{
      active: activeList === activeListName
    }"
  >
    <li
      class="pick-list-filter"
      *ngIf="activeList === activeListName || query"
      [ngClass]="{
        'not-active-filter': activeList !== activeListName
      }"
    >
      <cloud-svg-icon class="cursor-none" fileName="pick_filter"></cloud-svg-icon>
      <input
        type="text"
        [name]="activeListName"
        [id]="activeListName"
        placeholder="filter..."
        [ngModel]="query"
        (ngModelChange)="onFilter($event)"
      />
      <cloud-svg-icon (click)="onResetFilter()" fileName="refresh"></cloud-svg-icon>
    </li>
    <ng-container *ngIf="title">
      <p class="pick-list-title">{{ title }}</p>
    </ng-container>
    <div *ngIf="query && !data.length" class="no-data-reset">
      No entries match that filter <span (click)="onResetFilter()">Reset</span>
    </div>
    <li
      class="pick-list-item"
      *ngFor="let item of data"
      [cdkDragDisabled]="isDisabled"
      cdkDrag
      [cdkDragData]="item"
      (click)="selectItem($event, item)"
      [ngClass]="{ selected: isSelected(item) }"
      innerHTML="{{ item[itemKey] | searchBarHighlightFilter : query }}"
    >
    </li>
  </ul>
</label>
