import { Inject, Injectable } from '@angular/core';
import { JsonApi, Spec } from '@muellerbbm-vas/grivet';

import { UserInfo } from './user.type';
import { CloudService } from '../services/cloud.service';

import { Observable, from, of } from 'rxjs';
import { switchMap, mergeMap, filter, tap, toArray, map } from 'rxjs/operators';
import { AngularHttpContext } from '@root/libs/vas-angular-http-context/src';
import { ANGULAR_HTTP_CONTEXT } from '../app.tokens';
import { RoleInfo } from '../admin/admin/license/types/role.types';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private cloudService: CloudService, @Inject(ANGULAR_HTTP_CONTEXT) private context: AngularHttpContext) {}

  getUser(): Observable<UserInfo> {
    return this.cloudService.getAppStartObs('user_management').pipe(
      filter((res) => !!res),
      switchMap((res) => from(res!.relatedResource['user_self'])),
      mergeMap((userDoc: JsonApi.Resource) => from(this.createUser(userDoc)))
    );
  }

  getUsersSelfCanSee(): Observable<Partial<UserInfo>[]> {
    return this.cloudService.getAppStartObs('user_management').pipe(
      filter((res) => !!res),
      switchMap((res) => from(res!.relatedResource['user_self'])),
      switchMap((res) => {
        const baseUsersURL = new URL(
          res?.relationships['users_self_may_see']?.links?.related.url.href ?? 'https://example.com'
        );

        return from(JsonApi.Document.fromURL(baseUsersURL, this.context));
      }),
      mergeMap((res) =>
        from(res.resources).pipe(
          toArray(),
          map((users) => users.map((data) => this.createSimpleUser(data['rawData'])))
        )
      )
    );
  }

  getRolesSelfCanSee(): Observable<Partial<RoleInfo>[]> {
    return this.cloudService.getAppStartObs('user_management').pipe(
      filter((res) => !!res),
      switchMap((res) => from(res!.relatedResource['user_self'])),
      switchMap((res) => {
        const baseUsersURL = new URL(
          res?.relationships['roles_self']?.links?.related.url.href ?? 'https://example.com'
        );

        return from(JsonApi.Document.fromURL(baseUsersURL, this.context));
      }),
      mergeMap((res) =>
        from(res.resources).pipe(
          toArray(),
          map((roles) => {
            return roles.map((data) => this.createSimpleRole(data['rawData']));
          })
        )
      )
    );
  }

  private createSimpleRole(data): Partial<RoleInfo> {
    return { id: data.id, name: data.attributes.name, usersCanSeeEachOther: data.attributes.users_may_see_each_other };
  }

  private createSimpleUser(data): Partial<UserInfo> {
    return { id: data.id, userName: data.attributes.username };
  }

  async createUser(userDoc: JsonApi.Resource): Promise<UserInfo> {
    const attribs: Spec.AttributesObject | undefined = userDoc.attributes;
    const groupsRes: JsonApi.Resource[] = await userDoc.relatedResources['groups'];
    const groups: string[] = [];
    for (const groupRes of groupsRes) {
      groups.push(await groupRes.attributes?.['name']);
    }

    const user: UserInfo = {
      id: userDoc.id,
      hasVibroLicense: false,
      userName: attribs?.['username'],
      firstName: attribs?.['first_name'],
      lastName: attribs?.['last_name'],
      email: attribs?.['email'],
      isActive: attribs?.['is_active'],
      groups: groups
    };

    return user;
  }
}
