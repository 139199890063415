<ng-container *ngIf="identifier === activeIdentifier && sortDirection === activeSortDirection; else inActive">
  <clr-icon
    class="activeSortDirection"
    shape="circle-arrow"
    size="16"
    [attr.style]="sortDirection === 'desc' ? 'transform: rotate(180deg)' : ''"
    [attr.data-cy]="'TABLE_SORT_' + identifier + '_' + sortDirection + '_ACTIVE'"
    [attr.title]="
      'GENERAL.TABLE.SORT.SORT_BY'
        | translate
          : {
              attributeName: translatedIdentifier,
              sortDirection:
                sortDirection === 'desc'
                  ? ('GENERAL.TABLE.SORT.DIRECTION_DESC' | translate)
                  : ('GENERAL.TABLE.SORT.DIRECTION_ASC' | translate)
            }
    "
  ></clr-icon>
</ng-container>
<ng-template #inActive>
  <clr-icon
    shape="arrow"
    size="16"
    [attr.style]="sortDirection === 'desc' ? 'transform: rotate(180deg)' : ''"
    [attr.data-cy]="'TABLE_SORT_' + identifier + '_' + sortDirection + '_INACTIVE'"
    [attr.title]="
      'GENERAL.TABLE.SORT.SORT_BY'
        | translate
          : {
              attributeName: translatedIdentifier,
              sortDirection:
                sortDirection === 'desc'
                  ? ('GENERAL.TABLE.SORT.DIRECTION_DESC' | translate)
                  : ('GENERAL.TABLE.SORT.DIRECTION_ASC' | translate)
            }
    "
  ></clr-icon>
</ng-template>
