import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutComponent } from './about/about.component';
import { ThirdPartyLicensesComponent } from './tpl/tpl.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CopyrightComponent } from './copyright/copyright.component';

import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { translateLoader } from '../app.translate';
import { ContextHelpComponent } from './context-help/context-help.component';
import { RouterModule } from '@angular/router';
import { ClarityModule } from '@clr/angular';

export const importModules = [
  CommonModule,
  RouterModule,
  TranslateModule.forChild(translateLoader),
  ClarityModule,
  SharedModule
];
@NgModule({
  declarations: [
    AboutComponent,
    ThirdPartyLicensesComponent,
    PrivacyComponent,
    CopyrightComponent,
    ContextHelpComponent
  ],
  imports: importModules,
  exports: [AboutComponent, ContextHelpComponent]
})
export class PagesModule {}
// TODO: Rename PagesModule to ToolsModule
