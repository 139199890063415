<div
  id="feedback"
  [attr.data-cy]="(appFacade.trackingEnabled$ | async) ? 'FEEDBACK_BUTTON_ACTIVE' : 'FEEDBACK_BUTTON_INACTIVE'"
  [ngClass]="{ tracking: appFacade.trackingEnabled$ | async }"
>
  <clr-dropdown [clrCloseMenuOnItemClick]="false" data-cy="logrocketDropdown">
    <button id="dropdownButton" clrDropdownTrigger aria-label="Debug Dropdown button">
      <clr-icon id="bug" shape="lightbulb"></clr-icon>
    </button>
    <clr-dropdown-menu *clrIfOpen clrPosition="top-right">
      <div id="feedbackWrapper">
        <div id="frostedWrapper" *ngIf="!(appFacade.trackingEnabled$ | async)" data-cy="FEEDBACK_FROSTING">
          <div id="frosting"></div>
          <div id="textWrapper">
            <span id="text">{{ 'APP.FEEDBACK.TRACKINGREQUIRED' | translate }}</span>
          </div>
        </div>
        <div id="thankYouWrapper" *ngIf="showThankYouMessage">
          <div id="frosting"></div>
          <div id="textWrapper">
            <span id="text">{{ 'APP.FEEDBACK.THANKS' | translate }}</span>
          </div>
        </div>
        <clr-toggle-container>
          <clr-toggle-wrapper>
            <input
              *ngIf="appFacade.trackingEnabled$ | async"
              data-cy="FEEDBACK_TOGGLE_ACTIVE"
              type="checkbox"
              clrToggle
              name="options"
              checked
            />
            <input
              *ngIf="!(appFacade.trackingEnabled$ | async)"
              data-cy="FEEDBACK_TOGGLE_INACTIVE"
              type="checkbox"
              clrToggle
              name="options"
            />
            <label *ngIf="appFacade.trackingEnabled$ | async"
              >{{ 'APP.FEEDBACK.TRACKINGON' | translate }}
              <span id="trackingReloads">{{ 'APP.FEEDBACK.TRACKINGRELOADS' | translate }}</span></label
            >
            <label *ngIf="!(appFacade.trackingEnabled$ | async)">{{ 'APP.FEEDBACK.TRACKINGOFF' | translate }}</label>
          </clr-toggle-wrapper>
        </clr-toggle-container>
        <div class="divider"></div>
        <h4>{{ 'APP.FEEDBACK.FEEDBACK' | translate }}</h4>
        <cloud-smiley #smiley></cloud-smiley>
        <textarea
          #feedbackArea
          data-cy="FEEDBACK_TEXT_AREA"
          clrTextarea
          cols="32"
          (keydown)="preventSpaceDismiss($event)"
        ></textarea>
        <div
          id="sendFeedbackButton"
          data-cy="FEEDBACK_SEND_BUTTON"
          (click)="
            sendFeedback(feedbackArea.value, smiley.selectedSmiley);
            smiley.selectedSmiley = undefined;
            feedbackArea.value = ''
          "
          [ngClass]="{ enabled: smiley.selectedSmiley !== undefined && feedbackArea.value !== '' }"
        >
          {{ 'APP.FEEDBACK.SENDFEEDBACK' | translate }}
        </div>
        <div id="trackingInfoWrapper">
          <div id="trackingInfoContent" [routerLink]="['/user-monitoring']">
            {{ 'APP.FEEDBACK.TRACKINGINFO' | translate }}
          </div>
        </div>
      </div>
    </clr-dropdown-menu>
  </clr-dropdown>
</div>
