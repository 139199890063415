import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PassByNormConfiguration, AttributesConfig, OrderTableConfig } from '../config/workspace.config.types';
import { ConfigurationSource, ConfigurationTypeIdentifiers, WorkspaceConfigurations } from '../workspace.types';
import {
  GetWorkspaces,
  SetNormsConfig,
  SetAttributeConfig,
  SetOrderTableColumnOrder,
  ApplyConfigToServer,
  SetTableConfig,
  SetSort
} from './workspace.actions';
import { OrderSearchSortDefinition, WorkspacePartialState } from './workspace.reducer';
import { workspaceQuery } from './workspace.selectors';

@Injectable()
export class WorkspaceFacade {
  workspaces$ = this.store.pipe(select(workspaceQuery.getWorkspaces));
  workspaceTableColumns$ = this.store.pipe(select(workspaceQuery.getTableColumns));

  selectedWorkspace$ = this.store.pipe(select(workspaceQuery.getSelectedWorkspace));
  selectedWorkspaceType$ = this.store.pipe(select(workspaceQuery.getSelectedWorkspaceType));
  selectedWorkspaceOrderTableColumns$ = this.store.pipe(select(workspaceQuery.getSelectedWorkspaceTableColumns));
  selectedWorkspaceId$ = this.store.pipe(select(workspaceQuery.getSelectedWorkspaceId));

  workspaceConfigState$ = this.store.pipe(select(workspaceQuery.getWorkspaceConfigState));

  selectedWorkspaceConfig$ = this.store.pipe(select(workspaceQuery.getSelectedWorkspaceConfig));
  selectedWorkspaceConfigs$ = this.store.pipe(select(workspaceQuery.getSelectedWorkspaceConfig));

  sort$ = this.store.pipe(select(workspaceQuery.getSort));

  allConfigsForIdentifier$ = (configIdentifier: ConfigurationTypeIdentifiers) =>
    this.store.pipe(select(workspaceQuery.getAallConfigsForIdentifier(configIdentifier)));
  resultingConfigForIdentifier$ = (configIdentifier: ConfigurationTypeIdentifiers, includePreview: boolean = false) =>
    this.store.pipe(select(workspaceQuery.getResultingConfigForIdentifier(configIdentifier, includePreview)));

  constructor(private store: Store<WorkspacePartialState>) {}

  getWorkspaces() {
    this.store.dispatch(GetWorkspaces());
  }

  setNormsConfig(content: PassByNormConfiguration, source: keyof ConfigurationSource<PassByNormConfiguration>) {
    this.store.dispatch(SetNormsConfig({ content, source }));
  }

  applyConfigToServer(content: WorkspaceConfigurations, configType: ConfigurationTypeIdentifiers) {
    this.store.dispatch(ApplyConfigToServer({ content, configType }));
  }

  setAttributesConfig(content: AttributesConfig, source: keyof ConfigurationSource<AttributesConfig>) {
    this.store.dispatch(SetAttributeConfig({ content, source }));
  }

  setTableConfig(content: OrderTableConfig, source: keyof ConfigurationSource<OrderTableConfig>) {
    this.store.dispatch(SetTableConfig({ content, source }));
  }

  setOrderTableColumnOrder(columnOrder: string[]) {
    this.store.dispatch(SetOrderTableColumnOrder({ columnOrder }));
  }

  setSearchSort(sort: OrderSearchSortDefinition) {
    this.store.dispatch(SetSort({ sort }));
  }
}
