import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatMeasurementName'
})
export class FormatMeasurementNamePipe implements PipeTransform {
  transform(measurementId?: string): string {
    if (measurementId) {
      const name = measurementId.substr(measurementId.lastIndexOf('/') + 1);
      return name.replace('[mea]', '');
    }
    return '';
  }
}
