import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppSpecificRouterEffects, QueryParams } from './router.effects';
import { Actions } from '@ngrx/effects';
import { Params, RouterState } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppFacade } from '../../+state/app.facade';
import { RouterFacade } from './router.facade';
import { DepotSearchFacade } from '../../depot-search/+state/depot-search.facade';
import {
  PerformSearchOnInitialNavigation,
  PerformSearchOnPopState,
  PerformSearchOnRouteChange,
  SetSearchAttributesFiltersByRouter,
  SetSearchTextByRouter
} from '../../depot-search/+state/depot-search.actions';
import { combineLatest, of } from 'rxjs';
import { NavigationFacade } from './navigation.facade';
import { AppError } from '../../+state/app.actions';
import { CloudRouterQueryParamErrorDetail } from './router.types';
import { FeatureFlagsFacade } from '@root/libs/feature-flags/src';
import { TitleService } from '../../services/title.service';
import { isDepotAttribute, SearchAttributeAndValue } from '../../shared/+state/attributes/attributes.types';
import { AttributesFacade } from '../../shared/+state/attributes/attributes.facade';
import { URL_LISTPARAM_SEPERATOR } from '../../app.constants';

export enum AvailableDepotSearchQueryParamIdentifiers {
  SearchText = 'searchtext',
  SearchAttribute = 'attr'
}

@Injectable()
export class DepotSearchRouterEffects extends AppSpecificRouterEffects<AvailableDepotSearchQueryParamIdentifiers> {
  constructor(
    actions$: Actions,
    store: Store<RouterState>,
    routerFacade: RouterFacade,
    navigationFacade: NavigationFacade,
    featureFlagsFacade: FeatureFlagsFacade,
    titleService: TitleService,
    private appFacade: AppFacade,
    private depotSearchFacade: DepotSearchFacade,
    private attributesFacade: AttributesFacade
  ) {
    super(actions$, store, routerFacade, navigationFacade, featureFlagsFacade, titleService, 'depotsearch');

    this.config = {
      [AvailableDepotSearchQueryParamIdentifiers.SearchText]: {
        action: (queryParamValue, info) => SetSearchTextByRouter({ searchText: queryParamValue, navigationInfo: info }),
        waitForStates: [this.depotsLoadedAndSearchReady],
        isFinishedSuccessfully: (queryParams) =>
          this.depotSearchFacade.searchText$.pipe(
            map(
              (text) =>
                text === queryParams[AvailableDepotSearchQueryParamIdentifiers.SearchText] ||
                (text === '' && queryParams[AvailableDepotSearchQueryParamIdentifiers.SearchText] === undefined)
            )
          ),
        onEmptyAction: (info) => SetSearchTextByRouter({ searchText: '', navigationInfo: info })
      },
      [AvailableDepotSearchQueryParamIdentifiers.SearchAttribute]: {
        action: (value, info) => SetSearchAttributesFiltersByRouter({ searchFilters: value, navigationInfo: info }),
        waitForStates: [this.depotsLoadedAndSearchReady, this.searchAttributesLoaded],
        parser: {
          inputOrigins: [
            this.attributesFacade.availableDepotAttributes$,
            this.attributesFacade.availableSemanticDepotAttributes$
          ],
          queryParamValueParser: this.parseAttributeQueryParam
        },
        isFinishedSuccessfully: (queryParams) => this.searchFiltersAreSet(queryParams),
        onEmptyAction: (info) => SetSearchAttributesFiltersByRouter({ searchFilters: [], navigationInfo: info })
      }
    };

    this.onInitialNavigation = {
      action: () => PerformSearchOnInitialNavigation(),
      waitForStates: [this.depotsLoadedAndSearchReady, this.searchTextIsSet, this.searchFiltersAreSet]
    };

    this.onPopState = {
      action: () => PerformSearchOnPopState(),
      waitForStates: [this.depotsLoadedAndSearchReady, this.searchTextIsSet, this.searchFiltersAreSet]
    };

    this.onChangeToRoute = {
      action: () => PerformSearchOnRouteChange(),
      condition: this.searchBarIsDirty$,
      waitForStates: [this.depotsLoadedAndSearchReady, this.searchTextIsSet, this.searchFiltersAreSet]
    };
  }

  searchBarIsDirty$ = this.depotSearchFacade.searchInputModified$;

  static createSearchAttributesQueryParam(searchBarFilters: SearchAttributeAndValue[]): string[] {
    return searchBarFilters.map((searchBarFilter) => {
      const attr = searchBarFilter.attribute;
      const id: string = isDepotAttribute(attr) ? attr.idName : attr.id;
      let value = '';
      if (searchBarFilter.searchAttributeValue) {
        value = searchBarFilter.searchAttributeValue;
      } else if (searchBarFilter.searchAttributeBoolean !== undefined) {
        value = searchBarFilter.searchAttributeBoolean.toString();
      } else if (searchBarFilter.searchAttributeStart || searchBarFilter.searchAttributeEnd) {
        value = (searchBarFilter.searchAttributeStart ?? '') + '/' + (searchBarFilter.searchAttributeEnd ?? '');
        // ISO 8601 specifies time intervals in this way if both start and end are defined -> here we also allow just one of them being defined
      }
      const exact: string = searchBarFilter.exact_match.toString();

      // make sure the URL_PARAM_SEPERATOR is replaced by a special character
      value = encodeURIComponent(value);

      return `${id},${value},${exact}`;
    });
  }

  routeSpecificTitleMapping = (queryParams: Params) => {
    const searchtext = queryParams['searchtext'];
    return searchtext ? of(searchtext) : of();
  };

  depotsLoadedAndSearchReady = (queryParams: QueryParams<AvailableDepotSearchQueryParamIdentifiers>) =>
    combineLatest([this.appFacade.cloudDepotsLoaded$, this.depotSearchFacade.depotSearchReady$]).pipe(
      map(([depotsLoaded, depotSearchReady]) => depotsLoaded && depotSearchReady)
    );

  searchAttributesLoaded = (queryParams: QueryParams<AvailableDepotSearchQueryParamIdentifiers>) =>
    this.attributesFacade.availableDepotAttributesLoaded$;

  searchTextIsSet = (queryParams: QueryParams<AvailableDepotSearchQueryParamIdentifiers>) =>
    this.depotSearchFacade.searchText$.pipe(
      map((searchText) => searchText === (queryParams[AvailableDepotSearchQueryParamIdentifiers.SearchText] ?? ''))
    );

  searchFiltersAreSet = (queryParams: QueryParams<AvailableDepotSearchQueryParamIdentifiers>) =>
    this.depotSearchFacade.searchBarFilters$.pipe(
      map((searchBarFilters) => {
        let queryParamValue = queryParams[AvailableDepotSearchQueryParamIdentifiers.SearchAttribute];
        if (queryParamValue === undefined) {
          return searchBarFilters.length === 0;
        }
        if (typeof queryParamValue === 'string') {
          queryParamValue = [queryParamValue];
        }

        const stringifiedSearchBarFilters = DepotSearchRouterEffects.createSearchAttributesQueryParam(searchBarFilters);
        return (
          queryParamValue.length === stringifiedSearchBarFilters.length &&
          queryParamValue.every((value, index) => value === stringifiedSearchBarFilters[index])
        );
      })
    );

  parseAttributeQueryParam(
    queryParamValue: string | string[],
    ...inputs: any[]
  ): { value: any; errors: CloudRouterQueryParamErrorDetail[] } {
    const errors: CloudRouterQueryParamErrorDetail[] = [];
    const [availableDepotAttributes, availableSemanticDepotAttributes] = inputs;
    if (availableDepotAttributes === undefined || availableSemanticDepotAttributes === undefined) {
      throw AppError({
        err: {
          message: 'parseAttributeQueryParam should run after loading of depot attributes and semantic depot attributes'
        }
      });
    }
    if (typeof queryParamValue === 'string') {
      queryParamValue = [queryParamValue];
    }

    const searchFilters: SearchAttributeAndValue[] = queryParamValue.reduce((result, singleAttr) => {
      const splittedValue = (singleAttr as string).split(URL_LISTPARAM_SEPERATOR);
      if (splittedValue.length !== 3) {
        errors.push({
          routeIdentifier: this.routeIdentifier,
          parameterName: AvailableDepotSearchQueryParamIdentifiers.SearchAttribute,
          requestedValue: singleAttr,
          reason: 'invalid_value'
        });
        return result;
      }
      const [attrId, attrValue, exact] = splittedValue;

      // Make sure the attribute value is URI decoded
      const decodedAttrValue = decodeURIComponent(attrValue);

      const semanticAttribute = availableSemanticDepotAttributes!.find((attr) => attr.id === attrId);
      const attribute = availableDepotAttributes!.find((attr) => attr.idName === attrId);
      if (semanticAttribute === undefined && attribute === undefined) {
        errors.push({
          routeIdentifier: this.routeIdentifier,
          parameterName: AvailableDepotSearchQueryParamIdentifiers.SearchAttribute,
          requestedValue: singleAttr,
          reason: 'not_found'
        });
        return result;
      }
      const normalOrSemanticAttribute = attribute ? attribute : semanticAttribute;

      const exactBool = { true: true, false: false }[exact];
      if (exactBool === undefined) {
        errors.push({
          routeIdentifier: this.routeIdentifier,
          parameterName: AvailableDepotSearchQueryParamIdentifiers.SearchAttribute,
          requestedValue: singleAttr,
          reason: 'invalid_value'
        });
        return result;
      }

      const searchFilter: SearchAttributeAndValue = {
        id: attrId,
        attribute: normalOrSemanticAttribute,
        exact_match: exactBool as boolean
      };

      if (normalOrSemanticAttribute!.type === 'String') {
        searchFilter.searchAttributeValue = decodedAttrValue;
      } else if (normalOrSemanticAttribute!.type === 'Boolean') {
        let booleanValue: boolean | undefined;
        if (decodedAttrValue === 'true' || decodedAttrValue === 'false') {
          booleanValue = { true: true, false: false }[decodedAttrValue];
        } else {
          errors.push({
            routeIdentifier: this.routeIdentifier,
            parameterName: AvailableDepotSearchQueryParamIdentifiers.SearchAttribute,
            requestedValue: singleAttr,
            reason: 'invalid_value'
          });
          return result;
        }
        searchFilter.searchAttributeBoolean = booleanValue;
      } else if (normalOrSemanticAttribute!.type === 'Date') {
        let startTimeValue: string | undefined;
        let endTimeValue: string | undefined;
        if (decodedAttrValue) {
          const parts = decodedAttrValue.split('/');
          if (parts.length !== 2) {
            errors.push({
              routeIdentifier: this.routeIdentifier,
              parameterName: AvailableDepotSearchQueryParamIdentifiers.SearchAttribute,
              requestedValue: singleAttr,
              reason: 'invalid_value'
            });
            return result;
          }
          [startTimeValue, endTimeValue] = parts;
          if (
            (!!startTimeValue && isNaN(new Date(startTimeValue).getTime())) ||
            (!!endTimeValue && isNaN(new Date(endTimeValue).getTime()))
          ) {
            errors.push({
              routeIdentifier: this.routeIdentifier,
              parameterName: AvailableDepotSearchQueryParamIdentifiers.SearchAttribute,
              requestedValue: singleAttr,
              reason: 'invalid_value'
            });
            return result;
          }
        }
        searchFilter.searchAttributeStart = startTimeValue;
        searchFilter.searchAttributeEnd = endTimeValue;
      }

      result.push(searchFilter);
      return result;
    }, [] as SearchAttributeAndValue[]);
    return { value: searchFilters, errors: errors };
  }
}
