import {
  FuncAddToOrderFromSearchResultAggregation,
  SideBarFilterConfig,
  FilterIdentifier
} from './workspace.config.types';
import { Order, Standard } from '../../order-management/order.types';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { AggregateTree } from '../../shared/types/aggregateTree.types';
import * as OrdersSearchActions from '../../order-management/+state/orders-search.actions';
import { OrderFilterState, SideBarFilterState } from '../../order-management/+state/orders.reducer';
import {
  OrderFilter,
  OrderFilterChangeEvent,
  OrderFilterEntry
} from '../../order-management/order-filter-list/order-filter.types';

import tableDefaultConfig from 'apps/cloud/src/assets/config/.orderTableConfig.passby.json';

export const NOT_AVAILABLE = 'N/A';
export const passByTableDefaultConfig = tableDefaultConfig;

export const getPassByNormsFromAggregation: FuncAddToOrderFromSearchResultAggregation = (
  aggregates: AggregateTree[],
  order: Order
): void => {
  let targetValues: Standard[] = [];
  // FIXME: get idNames from specificWorkspaceConfig
  const parentAggregateAttributeIdName = 'descriptive_ancestors.aotest.order_iid.order_guid';
  const childAggregateAttributeIdName = 'descriptive_ancestors.aosubtest0.test_sequence.norm_name';
  // const childChildAggregateAttributeIdName = 'descriptive_ancestors.aosubtest0.test_sequence.norm_version';
  // flatten result.aggregates once would be enough
  const treeNodes = aggregates?.flatMap((agg) => agg.nodes);
  const orderTreeNode = treeNodes?.find((node) => {
    const found =
      node !== undefined &&
      node.aggregate.depotAttribute.idName === parentAggregateAttributeIdName &&
      node.originalValue === order.id;
    return found;
  });
  const child = orderTreeNode?.aggregate.child;
  if (child?.depotAttribute.idName === childAggregateAttributeIdName) {
    targetValues =
      orderTreeNode?.children?.map((val) => {
        const childVal = Number(val.children?.find((item) => item.originalValue)?.originalValue) ?? '-99';
        const standard = {
          name: val.originalValue,
          version: childVal
        };
        return standard;
      }) ?? [];
  }

  // NOTE: Temporarily(?) disable 'N/A' Standards
  targetValues = targetValues.filter((targetValue) => targetValue?.name !== NOT_AVAILABLE);

  order.computedProps.standards = targetValues ?? [];
};

export interface DescriptiveMappingDetail {
  name: string;
  path: string;
}

// TODO group by parameter_set
export interface ParamsResultTableEntry {
  friendlyName: string;
  set: string;
  parameter: string;
  group: string;
  unit?: string;
}

export namespace PassbyResultEntityTypeNames {
  export const TestSequence = 'test_sequence';
  export const TestSequenceParameterSet = 'test_sequence_parameter_set';
  export const TestSequenceParameter = 'ts_par';
}

const showNAinStandardsFilter: boolean = false;
const STANDARD_NOT_AVAILABLE = '<N/A>';

export const sideBarFilterConfig: SideBarFilterConfig = {
  [FilterIdentifier.STANDARDS]: {
    filterAggregateSearchResultToStateMapping: (_, filterAgg, slot) => {
      let standards: Standard[] = [];
      if (filterAgg && filterAgg.nodes) {
        filterAgg.nodes.forEach((res) => {
          standards.push({ name: res.label, count: res.children?.length ?? 0, version: 0 });
        });
      }
      // NOTE: Temporarily(?) disable 'N/A' Standards
      standards = standards.filter((standard) => standard.name !== NOT_AVAILABLE);
      return OrdersSearchActions.StandardsReceived({ standards, slot: slot ?? 'base' });
    },
    filterStateToOrderFilterMapping: (sideBarState: SideBarFilterState) => {
      const standardFilterState: OrderFilterState<Standard> = sideBarState[FilterIdentifier.STANDARDS];

      let entries: OrderFilterEntry[] = [];
      const standardsFilter = standardFilterState.actual;
      const availableStandards: Standard[] = standardFilterState.available;
      const baseStandards = standardFilterState.base;

      entries = baseStandards.map((standard) => {
        const hasNoFilteredStandards = standardsFilter.length === 0;
        const standardIsInFilteredStandards = standardsFilter.includes(standard.name);
        const appearChecked = hasNoFilteredStandards || standardIsInFilteredStandards;
        const availableCount = availableStandards.find((available) => available.name === standard.name)?.count;
        return {
          name: standard.name,
          toggleState: appearChecked,
          count: availableCount ?? 0,
          total: standard.count
        };
      });

      if (!showNAinStandardsFilter) {
        entries = entries.filter((standard) => standard.name !== STANDARD_NOT_AVAILABLE);
      }

      const filter: OrderFilter = {
        content: {
          title: 'Standards',
          title_nls_key: 'ORDER.FILTERS.STANDARDS',
          entries: entries
        },
        behavior: {
          offerAll: true,
          offerOnly: true,
          collapsible: true,
          collapsed: false,
          extend: {
            extendable: true,
            initialLimit: 15
          },
          sorting: {
            enabled: true,
            initial: {
              method: 'numDocs',
              direction: 'desc'
            },
            methods: [
              {
                method: 'numDocs',
                direction: 'desc'
              },
              {
                method: 'numDocsTotal',
                direction: 'desc'
              },
              {
                method: 'natural',
                direction: 'desc'
              }
            ]
          },
          handleClickedAll: () => OrdersSearchActions.SetStandardsFilter({ standards: [] }),
          handleClickedOnly: (entry: OrderFilterEntry) => {
            if (entry.name) {
              return OrdersSearchActions.SetStandardsFilter({ standards: [entry.name] });
            }
          },
          handleClickedEntry: (event: OrderFilterChangeEvent) => {
            const affectedEntry = event.entries.find((entry) => event.affectedEntry.name === entry.name);
            if (affectedEntry) {
              affectedEntry.toggleState = event.affectedEntry.toggleState;
            }

            const result: string[] = event.entries
              .filter((entry) => entry.toggleState === true)
              .map((entry) => entry.name);
            return OrdersSearchActions.SetStandardsFilter({ standards: result });
          }
        }
      };
      return filter;
    }
  }
};
