import { createAction, props } from '@ngrx/store';
import { FeatureFlagPrecedence, FeatureFlag, FeatureFlagValueType } from '../feature-flags.types';

export enum FeatureFlagActionTypes {
  SetAvailableFeatureFlags = '[FeatureFlag] Set Available Feature Flags',
  SetFeatureFlagDefaults = '[FeatureFlag] Set Feature Flag Defaults',
  SetFeatureFlag = '[FeatureFlag] Set Feature Flag',
  PersistFeatureFlagAsCookie = '[FeatureFlag] Persist Feature Flag',
  ClearFeatureFlagCookie = '[FeatureFlag] Clear Feature Flag Cookie'
}

export const SetAvailableFeatureFlags = createAction(
  FeatureFlagActionTypes.SetAvailableFeatureFlags,
  props<{ featureFlags: FeatureFlag[] }>()
);

export const SetFeatureFlag = createAction(
  FeatureFlagActionTypes.SetFeatureFlag,
  props<{ flagName: string; value: FeatureFlagValueType; precedence: FeatureFlagPrecedence }>()
);

export const PersistFeatureFlagAsCookie = createAction(
  FeatureFlagActionTypes.PersistFeatureFlagAsCookie,
  props<{ flagName: string; value: string | boolean }>()
);

export const ClearFeatureFlagCookie = createAction(
  FeatureFlagActionTypes.ClearFeatureFlagCookie,
  props<{ flagName: string }>()
);
