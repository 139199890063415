import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';
import { FeatureFlagsFacade } from '@vas/feature-flags';
import { availableFeatureFlags, featureFlagDefaults } from '../app.feature-flags';
import { WorkspaceFacade } from '../workspace/+state/workspace.facade';

import normsConfigSchema from '../workspace/config/norms.schema.compiled';
import attributesConfigSchema from '../workspace/config/attributeGroups.schema.compiled';
import {
  PassByNormConfiguration,
  NormValidationError,
  AttributesConfig
} from '../workspace/config/workspace.config.types';

import { CloudService } from './cloud.service';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  constructor(
    private http: HttpClient,
    private cloudService: CloudService,
    @Inject(APP_BASE_HREF) private baseHref: string,
    private featureFlagFacade: FeatureFlagsFacade
  ) {}

  loadAppConfig() {
    this.featureFlagFacade.setAvailableFeatureFlags({ featureFlags: availableFeatureFlags });
    featureFlagDefaults.forEach((flag) => {
      this.featureFlagFacade.setFeatureFlag({ flagName: flag.id, value: flag.value, precedence: 'default' });
    });

    this.http
      .get(`${this.baseHref}config/.instance.json`)
      .toPromise()
      .then((instanceJson) => {
        // Feature Flags
        const featureFlags = (instanceJson as any)?.featureFlags;
        if (featureFlags) {
          for (const flagName in featureFlags) {
            if (flagName) {
              this.featureFlagFacade.setFeatureFlag({
                flagName: flagName,
                value: featureFlags[flagName],
                precedence: 'instance'
              });
            }
          }
        }
      })
      .catch((err) => console.warn(`Failed to retrieve .instance.json`, err));

    /*     const attributesDefaultConfigValid = attributesConfigSchema(attributesDefaultConfig);
    const groupConfig: AttributeConfig = (attributesDefaultConfig as any);
    if (groupConfig && attributesDefaultConfigValid) {
      this.workspaceFacade.setAttributesConfig(groupConfig, 'default');
    } else {
      const errors = (attributesConfigSchema as any).errors;
      const validationErrors: NormValidationError[] = errors.map((error) => ({
        path: error.instancePath,
        keyword: error.keyword,
        message: error.message ?? '',
        params: error.params
      }));
      console.error('Failed to validate default .attributeGroups.json', validationErrors);
    }

    const normsConfigSchemaValid = normsConfigSchema(normsDefaultConfig);

    const normConfig = normsDefaultConfig as PassByNormConfiguration;
    if (normConfig && normsConfigSchemaValid) {
      this.workspaceFacade.setNormsConfig(normConfig, 'default');
    } else {
      const errors = (normsConfigSchema as any).errors;
      if (errors) {
        const validationErrors: NormValidationError[] = errors.map((error) => ({
          path: error.instancePath,
          keyword: error.keyword,
          message: error.message ?? '',
          params: error.params
        }));
      }
      console.warn('Failed to validate default .norms.json', errors);
    } */
  }
}
