import { DepotSearchParameters } from '../../depot-search/depot-search.types';
import { v4 as uuid } from 'uuid';
import { getFlatSearchFilters } from '../../order-management/order.service.helpers';
import {
  DepotAttribute,
  isDepotAttribute,
  SearchAttributeAndValue,
  SearchFilter
} from '../+state/attributes/attributes.types';
import { OrderSearchParameters } from '../../order-management/order-search.types';

// When an order atfx is uploaded to the cloud, the measurement templates defined there are already
// included in the search index but the results should not show up yet.
// The measurement templates don't have a measurement start date so we use this attribute to
// distinguish between templates and actually finished measurements
// TODO: Think about a better solution when Cloud API is extended to search for certain result types only
export const addFinishedMeasurementConstraint = <T extends DepotSearchParameters | OrderSearchParameters>(
  searchParameters: T,
  availableDepotAttribs: DepotAttribute[],
  constraintType: 'exclude' | 'require' = 'exclude'
): T => {
  const modifiedSearchParameters: T = { ...searchParameters };
  let modifiedSearchFilters: SearchFilter | undefined = JSON.parse(
    JSON.stringify(modifiedSearchParameters.searchFilters)
  );
  if (modifiedSearchFilters) {
    const filters = getFlatSearchFilters(modifiedSearchFilters); // TODO: Check if flattening is really necessary here
    const foundMeasurementStarttime = checkIfOneOfAttributesHasMeasurementStarttime(filters);
    if (!foundMeasurementStarttime) {
      const startTimeAttr = getStartTimeSearchAttribute(availableDepotAttribs);

      if (startTimeAttr) {
        if (modifiedSearchFilters.length >= 1) {
          const searchFiltersForWrapping = JSON.parse(JSON.stringify(modifiedSearchFilters));

          if (constraintType === 'require') {
            modifiedSearchFilters = [
              {
                type: 'and',
                filters: [
                  ...searchFiltersForWrapping,
                  {
                    type: 'not',
                    filters: [startTimeAttr]
                  }
                ]
              }
            ];
          } else {
            modifiedSearchFilters = [
              {
                type: 'and',
                filters: [...searchFiltersForWrapping, startTimeAttr]
              }
            ];
          }
        } else {
          if (constraintType === 'require') {
            modifiedSearchFilters = [
              {
                type: 'not',
                filters: [startTimeAttr]
              }
            ];
          } else {
            modifiedSearchFilters.push(startTimeAttr);
          }
        }
        modifiedSearchParameters.searchFilters = modifiedSearchFilters;
      }
    }
  }
  return modifiedSearchParameters;
};

export const checkIfOneOfAttributesHasMeasurementStarttime = (searchAttributes: SearchAttributeAndValue[]): boolean => {
  return searchAttributes.some((value) => {
    if (isDepotAttribute(value.attribute)) {
      return value.attribute.idName === 'measurement_properties.start_time';
    } else {
      return value.attribute.name === 'start_time';
    }
  });
};

export const getStartTimeSearchAttribute = (
  availableDepotAttribs: DepotAttribute[] | undefined
): SearchAttributeAndValue | undefined => {
  const startTimeAttr = availableDepotAttribs?.find((attr) => attr.idName === 'measurement_properties.start_time');
  if (startTimeAttr) {
    return {
      id: uuid(),
      attribute: startTimeAttr,
      searchAttributeValue: '',
      searchAttributeBoolean: false,
      exact_match: false
    };
  }
  return undefined;
};
