import { Component, OnInit } from '@angular/core';
import { DepotSearchFacade } from '../+state/depot-search.facade';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { aggregateTreeSortingDefaultTranslationKeys } from './search-aggregate-tree.sorting';
import { AggregateTreeSortingMethod, TreeNode } from '../../shared/types/aggregateTree.types';
import { AttributesFacade } from '../../shared/+state/attributes/attributes.facade';

@Component({
  selector: 'cloud-search-aggregate-tree',
  templateUrl: './search-aggregate-tree.component.html',
  styleUrls: ['./search-aggregate-tree.component.css']
})
export class SearchAggregateTreeComponent implements OnInit {
  availableSortMethodsForAggregateTree$: Observable<AggregateTreeSortingMethod[]> = new Observable<
    AggregateTreeSortingMethod[]
  >();
  selectedSortMethodForAggregateTree$: Observable<AggregateTreeSortingMethod> =
    new Observable<AggregateTreeSortingMethod>();

  constructor(
    public depotSearchFacade: DepotSearchFacade,
    public translateService: TranslateService,
    public attributesFacade: AttributesFacade
  ) {}

  ngOnInit() {
    this.availableSortMethodsForAggregateTree$ = combineLatest([
      this.depotSearchFacade.selectedTreeType$,
      this.depotSearchFacade.availableTreeSorts$,
      this.depotSearchFacade.aggregateTrees$
    ]).pipe(
      map(([selectedType, availableSorts, _]) => availableSorts[selectedType!]),
      map((sortMethods) =>
        sortMethods.map((method) => {
          return {
            ...method,
            methodNLS: this.translateService.instant(aggregateTreeSortingDefaultTranslationKeys[method.method]),
            directionNLS: this.translateService.instant(aggregateTreeSortingDefaultTranslationKeys[method.direction])
          };
        })
      )
    );

    this.selectedSortMethodForAggregateTree$ = combineLatest([
      this.depotSearchFacade.selectedTreeType$,
      this.depotSearchFacade.activeAggregateTreeSorting$
    ]).pipe(map(([selectedType, activeSorts]) => activeSorts[selectedType!]));
  }

  selectNode(node: TreeNode) {
    this.depotSearchFacade.selectNode(node);
  }

  expandNode(node: TreeNode) {
    this.depotSearchFacade.toggleNode(node, true);
    if (node.hasChildren && (node.childrenLoadingStatus === 'init' || node.childrenLoadingStatus === 'error')) {
      this.depotSearchFacade.loadNodeChildren(node);
    }
  }

  collapseNode(node: TreeNode) {
    this.depotSearchFacade.toggleNode(node, false);
  }
}
