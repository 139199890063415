import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import * as fromFeatureFlags from './+state/feature-flags.reducer';
import { FeatureFlagsFacade } from './+state/feature-flags.facade';
import { FeatureFlagsEffects } from './+state/feature-flags.effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromFeatureFlags.FEATUREFLAGS_FEATURE_KEY, fromFeatureFlags.reducer),
    EffectsModule.forFeature([FeatureFlagsEffects])
  ],
  providers: [FeatureFlagsFacade, CookieService]
})
export class FeatureFlagsModule {}
