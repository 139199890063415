import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ContentCollectionFacade } from '../+state/content-collection.facade';
import { TranslateService } from '@ngx-translate/core';
import { ContentCollection } from '../content-collection.types';
import { v4 as uuid } from 'uuid';
import { firstValueFrom, take } from 'rxjs';
import { AppFacade } from '../../+state/app.facade';

@Component({
  selector: 'cloud-collection-create-with-items',
  templateUrl: './collection-create-with-items.component.html',
  styleUrls: ['./collection-create-with-items.component.css']
})
export class CollectionCreateWithItemsComponent implements AfterViewInit {
  @ViewChild('CCNameInput') CCNameInput: ElementRef;

  public newName = '';
  public shouldActivate: boolean = true;

  nameExists = false;

  constructor(
    public contentCollectionFacade: ContentCollectionFacade,
    public translate: TranslateService,
    private appFacade: AppFacade
  ) {}

  async ngAfterViewInit() {
    this.CCNameInput.nativeElement.focus();
    this.shouldActivate = (await firstValueFrom(
      this.appFacade.getUserPreference$('activateContentCollectionOnCreation').pipe(take(1))
    )) as boolean;
  }

  async handleNameChanged(event) {
    this.newName = event;
    const nameExists = await firstValueFrom(this.contentCollectionFacade.contentCollections$.pipe(take(1)));
    this.nameExists = nameExists.some((cc) => cc.name === this.newName);
  }

  handleShouldActivateChanged(event) {
    this.shouldActivate = event;
    this.appFacade.setUserPreference('activateContentCollectionOnCreation', this.shouldActivate);
  }

  handleEscape(event) {
    this.endCreateCollection(event);
  }

  endCreateCollection(event: MouseEvent) {
    event && event.stopPropagation();

    this.newName = '';
    this.contentCollectionFacade.endContentCollectionCreation();
    this.contentCollectionFacade.hideDialog();
  }

  async confirmCreateCollection(event: MouseEvent) {
    const items = await firstValueFrom(this.contentCollectionFacade.itemsForCreation$.pipe(take(1)));

    const newGuid = uuid();
    const newCollection: ContentCollection = {
      guid: newGuid,
      name: this.newName,
      itemsLoaded: true,
      items: items
    };
    this.contentCollectionFacade.createContentCollection(newCollection, this.shouldActivate);

    this.endCreateCollection(event);
  }
}
