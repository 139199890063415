<div class="autocomplete-container">
  <input
    #input
    id="autocomplete-input"
    type="text"
    autocomplete="off"
    [(ngModel)]="query"
    (input)="filterSubject.next(query)"
    (clickOutside)="onBlur()"
    (focus)="onFocus()"
  />
  <div *ngIf="(filteredOptions$ | async)?.length > 0 && isFocused" class="autocomplete-list">
    <div
      *ngFor="let option of filteredOptions$ | async"
      class="autocomplete-item"
      (mousedown)="selectionChanged(option)"
    >
      <cloud-svg-icon
        [fileName]="option.shareType === shareTypes.User ? 'person' : 'person_slide_text'"
      ></cloud-svg-icon>
      {{ option.name }}
    </div>
  </div>
</div>
