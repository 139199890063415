import { AfterViewInit, Component, ElementRef, OnChanges, ViewChild } from '@angular/core';
import { ContentCollection } from '../content-collection.types';
import { ContentCollectionFacade } from '../+state/content-collection.facade';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, take } from 'rxjs';

@Component({
  selector: 'cloud-collection-edit',
  templateUrl: './collection-edit.component.html',
  styleUrls: ['./collection-edit.component.css']
})
export class CollectionEditComponent implements AfterViewInit {
  @ViewChild('CCNameInput') CCNameInput: ElementRef;

  nameCollision = false;
  initialName = '';

  public editName = '';

  public visible = false;
  public selectedCollection: ContentCollection;

  constructor(public contentCollectionFacade: ContentCollectionFacade, public translate: TranslateService) {}

  public setVisibility(visible: boolean) {
    this.visible = visible;
    this.initialName = this.editName = this.selectedCollection?.name ?? '';
    this.nameCollision = false;
  }

  async ngAfterViewInit() {
    this.CCNameInput.nativeElement.focus();
  }

  async handleNameChanged(event) {
    this.editName = event;
    const CCs = await firstValueFrom(this.contentCollectionFacade.contentCollections$.pipe(take(1)));
    const nameExists = CCs.some((cc) => cc.name === this.editName);
    const isSelfName = this.editName === this.initialName;
    this.nameCollision = nameExists && !isSelfName;
  }

  cancelEditCollection(event: MouseEvent) {
    this.editName = '';
    event.stopPropagation();
    this.visible = false;
  }

  confirmEditCollection(event: MouseEvent) {
    event.stopPropagation();
    this.visible = false;

    if (this.editName !== this.initialName) {
      this.contentCollectionFacade.editContentCollection(this.selectedCollection, this.editName);
      this.editName = '';
    }
  }
}
