<!-- Display for wide screens - a table with all search results -->
<!-- This has also a mode for viewing measurement details -->
<div
  id="searchResults"
  *ngIf="((showTable$ | async) && !(resetTable$ | async)) || (showDetailView$ | async)"
  class="ui-panel"
  [@inOutAnimation]
>
  <span *ngIf="(results$ | async) && (results$ | async)!.length > 0">
    <clr-dropdown class="add-column-button" [clrCloseMenuOnItemClick]="true" *ngIf="!(showDetailView$ | async)">
      <button
        type="button"
        class="btn btn-icon btn-sm btn-primary"
        title="{{ 'DEPOTSEARCH.ADDNEWCOLUMN' | translate }}"
        clrDropdownTrigger
      >
        <clr-icon shape="plus"></clr-icon></button
      ><clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <cloud-autocomplete-dropdown
          [searchable]="false"
          (attributeSelected)="this.depotSearchFacade.addResultColumn($event)"
        ></cloud-autocomplete-dropdown>
      </clr-dropdown-menu>
    </clr-dropdown>

    <clr-datagrid
      cloudBlockTextSelect
      id="searchResults"
      [clrDgSelected]="selectedSearchResults$ | async"
      (clrDgSelectedChange)="selectedSearchResults$.next($event)"
      [ngClass]="{
        'datagrid-restricted-max-width': showDetailView$ | async,
        'datagrid-not-all-selected-node': (allSelected$ | async) && !(showDetailView$ | async),
        'datagrid-item-dragged': (this.contentCollectionFacade.contentCollectionItemsDragged$ | async)?.length > 0
      }"
    >
      <!-- Action buttons in the header (top left items) -->
      <clr-dg-column
        [ngClass]="{
          'symbol-fixed-width': !(showDetailView$ | async)
        }"
        class="datagrid-column-no-resize"
      >
        <div id="notAllNote" *ngIf="!(showDetailView$ | async)" [ngClass]="{ show: (allSelected$ | async) }">
          {{
            'DEPOTSEARCH.SELECT_ALL_NOTE'
              | translate
                : {
                    selected: (selectedSearchResults$ | async)?.length,
                    total: (depotSearchResults$ | async)?.numberOfTotalResults
                  }
          }}

          <button
            class="btn btn-sm btn-mini"
            *ngIf="(loadableMoreResults$ | async) ?? 0 > 0"
            (click)="getMoreResults({ extendSelection: true, keepSelection: false })"
            [attr.data-cy]="'SELECT_ALL_NOTE_LOAD_AND_EXTEND_' + (loadableMoreResults$ | async)"
          >
            <ng-container *ngIf="!(depotSearchFacade.currentDepotSearchIsInFlight$ | async)">
              {{
                'DEPOTSEARCH.SELECT_ALL_NOTE_LOAD_AND_EXTEND'
                  | translate : { loadableMoreResults: (loadableMoreResults$ | async) }
              }}
            </ng-container>
            <cloud-spinner
              *ngIf="depotSearchFacade.currentDepotSearchIsInFlight$ | async"
              [size]="'small'"
            ></cloud-spinner>
          </button>
        </div>

        <!-- Note: As the shrunken detail table only displays the first column (which is the action buttons),
                     we have to append further content in there if displaying measurement details -->
        <div
          *ngIf="depotSearchFacade.showResultDetailView$ | async"
          [ngClass]="{
            'detail-column-text': (selectedSearchResults$ | async)!.length < 2,
            'detail-column-text-with-ellipsis': (selectedSearchResults$ | async)!.length >= 2
          }"
        >
          {{ (detailColumnAttribute$ | async)! | translateAttribute }}
        </div>
      </clr-dg-column>

      <!-- Header texts: Attribute names (or their translations) -->
      <clr-dg-column
        *ngFor="let column of depotSearchFacade.resultColumns$ | async; let i = index"
        [title]="column.attribute | translateAttribute"
        [style.min-width.px]="150"
      >
        <ng-container>
          <div class="table-header">
            <div class="table-header-part">
              <span *ngIf="!column.contentLoaded" style="margin-right: 5px">
                <cloud-spinner></cloud-spinner>
              </span>
              <div [ngClass]="{ 'column-name': true, 'ja': (appFacade.languageState$ | async) === 'ja' }">
                <div>{{ column.attribute | translateAttribute }}</div>
              </div>
              <div
                class="tableSort"
                *ngIf="showSort$ | async"
                [ngClass]="{
                  activeColumn: column.field === (sort$ | async)?.columnIdentifier
                }"
              >
                <cloud-table-sort-icon
                  [identifier]="column.field"
                  [activeIdentifier]="(sort$ | async)?.columnIdentifier"
                  [translatedIdentifier]="column.attribute | translateAttribute"
                  [sortDirection]="'asc'"
                  [activeSortDirection]="(sort$ | async)?.direction"
                  (click)="sortColumn(column.attribute, 'asc')"
                ></cloud-table-sort-icon>
                <cloud-table-sort-icon
                  [identifier]="column.field"
                  [activeIdentifier]="(sort$ | async)?.columnIdentifier"
                  [translatedIdentifier]="column.attribute | translateAttribute"
                  [sortDirection]="'desc'"
                  [activeSortDirection]="(sort$ | async)?.direction"
                  (click)="sortColumn(column.attribute, 'desc')"
                ></cloud-table-sort-icon>

                <ng-container
                  *ngIf="(sortingMoreThank10k$ | async) === true && column.field === (sort$ | async)?.columnIdentifier"
                >
                  <clr-tooltip>
                    <clr-icon
                      clrTooltipTrigger
                      shape="exclamation-triangle"
                      class="is-solid more-than-10k"
                      size="20"
                      title=""
                      aria-label="Tooltip"
                    ></clr-icon>
                    <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
                      <span>{{ 'GENERAL.TABLE.SORT.10K_WARNING' | translate }}</span>
                    </clr-tooltip-content>
                  </clr-tooltip>
                </ng-container>
              </div>
            </div>
            <div class="table-header-part">
              <div
                class="removeColumnButton"
                *ngIf="
                  (depotSearchFacade.resultColumns$ | async) && (depotSearchFacade.resultColumns$ | async)!.length !== 1
                "
              >
                <button
                  type="button"
                  class="btn btn-icon btn-sm btn-link column-action-btn"
                  title="{{ 'DEPOTSEARCH.REMOVECOLUMN' | translate }}"
                  (click)="this.depotSearchFacade.removeResultColumn(column)"
                >
                  <clr-icon shape="times"></clr-icon>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </clr-dg-column>

      <!-- Table content: Measurement results -->
      <clr-dg-row
        *clrDgItems="let result of results$ | async; let i = index; let last = last"
        [clrDgItem]="result"
        [attr.data-cy]="'SEARCH_RESULT_ENTRY_' + result.measurementId"
        (click)="handleMeasurementClick$.next([$event, result])"
        dndDraggable
        (dndStart)="startDrag(result)"
        (dndEnd)="stopDrag()"
        dndDraggingClass="item-dragged"
        [ngClass]="{
          'marked': i === (this.depotSearchFacade.markedResultLine$ | async),
          'lastSelected': result.measurementId === (lastSelectedSearchResult$ | async)?.measurementId,
          'selected-measurement': result === (this.depotSearchFacade.detailSearchResultEntry$ | async),
          'deletedMeasurement':
            result['pending_deletion_properties.has_pending_deletion'] &&
            result['pending_deletion_properties.has_pending_deletion'].toLowerCase() === 'true'
        }"
      >
        <!-- First column: Action buttons for this specific measurement -->
        <!-- Note: The width has to be set here, because Clarity uses inline format templates to pass the width along,
                     which overwrites any css settings. This is fine for the table, but we need more space for the detail view -->
        <clr-dg-cell
          [style.display]="'inline-flex'"
          [style.width]="!(showDetailView$ | async) ? '42px' : 'inherit'"
          [style.min-width]="!(showDetailView$ | async) ? '42px' : 'inherit'"
          [style.max-width]="!(showDetailView$ | async) ? 'inherit' : '15rem'"
          class="no-padding prevent-select-click cell-actions-cell"
        >
          <!-- Do no show if there are selected elements and this element is not part of these selected elements -->
          <ng-container
            *ngIf="
              (selectedSearchResults$ | async)?.length === 0 || (selectedSearchResults$ | async)!.includes(result);
              else noActions
            "
          >
            <ng-container
              *ngIf="
                !(depotSearchFacade.deletionsInProgess$ | async)!.includes(result.measurementId) &&
                  !(depotSearchFacade.undeletionsInProgess$ | async)!.includes(result.measurementId);
                else deleteInPorgress
              "
            >
              <clr-dropdown #dropdown>
                <button
                  class="btn btn-link btn-icon no-margin nav-icon action-icon"
                  clrDropdownTrigger
                  [attr.data-cy]="'DROPDOWN_BUTTON_' + i"
                >
                  <clr-icon shape="ellipsis-vertical"></clr-icon>
                </button>
                <ng-template
                  [clrIfOpen]="dropdownOpen[i]?.open"
                  (clrIfOpenChange)="handleDropdownChange(i, $event, dropdown)"
                >
                  <clr-dropdown-menu [clrPosition]="dropdownOpen[i]?.position" [attr.data-cy]="'DROPDOWN_' + i">
                    <h4 class="dropdown-header">{{ 'DEPOTSEARCH.ACTIONS.SINGLE.HEADER' | translate }}</h4>
                    <a
                      *ngIf="!(searchResultEntryInActiveCollection$(result) | async); else itemAlreadyInCollection"
                      (click)="addItemToActiveCollection(result); handleDropdownChange(i, false, dropdown)"
                      clrDropdownItem
                      [disabled]="(contentCollectionFacade.activeContentCollection$ | async) === undefined"
                      data-cy="DROPDOWN_ADD_TO_COLLECTION"
                    >
                      <clr-icon shape="plus-circle"></clr-icon
                      >{{ 'DEPOTSEARCH.ACTIONS.SINGLE.ADDTOCOLLECTION.0' | translate }}
                      <i>{{ (contentCollectionFacade.activeContentCollection$ | async)?.name }}</i>
                      {{ 'DEPOTSEARCH.ACTIONS.SINGLE.ADDTOCOLLECTION.1' | translate }}
                    </a>
                    <ng-template #itemAlreadyInCollection>
                      <a [clrDisabled]="true" clrDropdownItem data-cy="DROPDOWN_ALREADY_IN_COLLECTION">
                        <clr-icon shape="plus-circle"></clr-icon
                        >{{ 'DEPOTSEARCH.ACTIONS.SINGLE.ALREADYINACTIVECOLLECTION' | translate }}
                      </a>
                    </ng-template>

                    <clr-dropdown>
                      <a clrDropdownTrigger (click)="handleOtherClick(result)" data-cy="DROPDOWN_ADD_OTHER_COLLECTION"
                        ><clr-icon shape="plus-circle"></clr-icon
                        >{{ 'DEPOTSEARCH.ACTIONS.SINGLE.OTHER' | translate }}</a
                      >
                      <clr-dropdown-menu class="scrollable-submenu">
                        <a
                          clrDropdownItem
                          (click)="addItemToNewCollection(result); handleDropdownChange(i, false, dropdown)"
                          data-cy="DROPDOWN_ADD_TO_NEW_COLLECTION"
                        >
                          <clr-icon shape="plus-circle"></clr-icon
                          >{{ 'DEPOTSEARCH.ACTIONS.SINGLE.ADDTONEWCOLLECTION' | translate }}
                        </a>
                        <ng-container *ngIf="(collectionsForSpecificEntry$ | async)?.length > 0">
                          <div class="dropdown-divider" role="separator"></div>
                          <a
                            clrDropdownItem
                            *ngFor="let entry of collectionsForSpecificEntry$ | async"
                            [clrDisabled]="
                              entry.isInCollectio ||
                              (contentCollectionFacade.activeContentCollection$ | async) === undefined
                            "
                            [attr.data-cy]="'DROPDOWN_ADD_TO_SPECIFIC_CC_' + entry.collection.name"
                            [attr.title]="
                              (entry.isInCollection
                                ? 'DEPOTSEARCH.ACTIONS.SINGLE.ALREADYINCOLLECTION'
                                : 'DEPOTSEARCH.ACTIONS.SINGLE.ADDTOCOLLECTION.INTERPOLATED'
                              ) | translate : { name: entry.collection.name }
                            "
                            (click)="
                              addItemToSpecificCollection(result, entry.collection.guid);
                              handleDropdownChange(i, false, dropdown)
                            "
                            >{{ entry.collection.name }}</a
                          >
                        </ng-container>
                      </clr-dropdown-menu>
                    </clr-dropdown>

                    <a (click)="measurementsFacade.prepareMove([result])" clrDropdownItem data-cy="DROPDOWN_MOVE">
                      <clr-icon shape="export"></clr-icon>{{ 'DEPOTSEARCH.ACTIONS.SINGLE.MOVE' | translate }}
                    </a>

                    <a (click)="downloadItem$.next(result)" clrDropdownItem data-cy="DROPDOWN_DOWNLOAD">
                      <clr-icon shape="download"></clr-icon>{{ 'DEPOTSEARCH.ACTIONS.SINGLE.DOWNLOAD' | translate }}
                    </a>
                    <!-- <a (click)="log($event, result)" clrDropdownItem
                            ><clr-icon shape="archive"></clr-icon>{{ 'DEPOTSEARCH.ACTIONS.SINGLE.ARCHIVE' | translate }}</a
                          > -->
                    <!-- <a (click)="log($event, result)" clrDropdownItem
                            ><clr-icon shape="switch"></clr-icon>{{ 'DEPOTSEARCH.ACTIONS.SINGLE.MOVETODEPOT' | translate }}</a
                          > -->

                    <a
                      *ngIf="isDeletable(result)"
                      (click)="deleteMeasurements(result)"
                      clrDropdownItem
                      class="delete-measurement"
                      data-cy="DROPDOWN_DELETE"
                    >
                      <clr-icon shape="trash"></clr-icon>{{ 'DEPOTSEARCH.ACTIONS.SINGLE.DELETE' | translate }}
                    </a>
                    <a
                      *ngIf="isUndeletable(result)"
                      (click)="undeleteMeasurements(result)"
                      clrDropdownItem
                      class="undelete-measurement"
                      data-cy="DROPDOWN_UNDELETE"
                    >
                      <clr-icon shape="recycle"></clr-icon>{{ 'DEPOTSEARCH.ACTIONS.SINGLE.UNDELETE' | translate }}
                    </a>

                    <ng-container *ngIf="selectedSearchResults$ | async as selectedSearchResults">
                      <ng-container *ngIf="selectedSearchResults!.length >= 2">
                        <div class="dropdown-divider large-divider" role="separator"></div>
                        <label class="dropdown-header">
                          {{
                            'DEPOTSEARCH.ACTIONS.SELECTION.HEADER'
                              | translate : { numMeasurements: selectedSearchResults!.length }
                          }}</label
                        >
                        <a
                          (click)="addSelectedSearchResultsToActiveCollection()"
                          clrDropdownItem
                          [disabled]="(contentCollectionFacade.activeContentCollection$ | async) === undefined"
                          ><clr-icon shape="plus-circle"></clr-icon
                          >{{ 'DEPOTSEARCH.ACTIONS.SELECTION.ADDTOCOLLECTION.0' | translate }}
                          <i>{{ (contentCollectionFacade.activeContentCollection$ | async)?.name }}</i>
                          {{ 'DEPOTSEARCH.ACTIONS.SELECTION.ADDTOCOLLECTION.1' | translate }}
                        </a>

                        <clr-dropdown>
                          <a clrDropdownTrigger (click)="handleOtherClick(result)"
                            ><clr-icon shape="plus-circle"></clr-icon
                            >{{ 'DEPOTSEARCH.ACTIONS.SELECTION.OTHER' | translate }}
                          </a>
                          <clr-dropdown-menu class="scrollable-submenu">
                            <a
                              clrDropdownItem
                              (click)="addItemsToNewCollection(result); handleDropdownChange(i, false, dropdown)"
                              data-cy="DROPDOWN_ADD_TO_NEW_COLLECTION"
                            >
                              <clr-icon shape="plus-circle"></clr-icon
                              >{{ 'DEPOTSEARCH.ACTIONS.SELECTION.ADDTONEWCOLLECTION' | translate }}
                            </a>
                            <ng-container *ngIf="(collectionsForSpecificEntry$ | async)?.length > 0">
                              <div class="dropdown-divider" role="separator"></div>
                              <a
                                clrDropdownItem
                                *ngFor="let entry of collectionsForSpecificEntry$ | async"
                                [attr.title]="
                                  'DEPOTSEARCH.ACTIONS.SELECTION.ADDTOCOLLECTION.INTERPOLATED'
                                    | translate : { name: entry.collection.name }
                                "
                                (click)="
                                  addSelectedSearchResultsToSpecificCollection(entry.collection.guid);
                                  handleDropdownChange(i, false, dropdown)
                                "
                                >{{ entry.collection.name }}</a
                              >
                            </ng-container>
                          </clr-dropdown-menu>
                        </clr-dropdown>

                        <a (click)="requestMultipleMeasurementMove()" clrDropdownItem data-cy="DROPDOWN_MOVE">
                          <clr-icon shape="export"></clr-icon
                          >{{
                            'DEPOTSEARCH.ACTIONS.SELECTION.MOVE'
                              | translate : { numMeasurements: selectedSearchResults!.length }
                          }}
                        </a>

                        <a (click)="requestMultipleMeasurementsDownload()" clrDropdownItem
                          ><clr-icon shape="download"></clr-icon
                          >{{
                            'DEPOTSEARCH.ACTIONS.SELECTION.DOWNLOAD'
                              | translate : { numMeasurements: selectedSearchResults!.length }
                          }}</a
                        >

                        <a
                          *ngIf="showBatchDelete$ | async"
                          (click)="deleteMeasurements(selectedSearchResults)"
                          clrDropdownItem
                          class="delete-measurement"
                          ><clr-icon shape="trash"></clr-icon
                          >{{
                            'DEPOTSEARCH.ACTIONS.SELECTION.DELETE'
                              | translate : { numMeasurements: selectedSearchResults!.length }
                          }}</a
                        >
                        <a *ngIf="tooManyResultsForBatchDelete$ | async" clrDropdownItem clrDisabled>
                          <clr-icon shape="trash"></clr-icon
                          >{{
                            'DEPOTSEARCH.ACTIONS.SELECTION.TOO_MANY_FOR_DELETE'
                              | translate : { maxMeas: MAX_DELETE_UNDELETE_COUNT }
                          }}
                        </a>
                        <a
                          *ngIf="this.showBatchUndelete$ | async"
                          (click)="undeleteMeasurements(selectedSearchResults)"
                          clrDropdownItem
                          class="undelete-measurement"
                          ><clr-icon shape="recycle"></clr-icon
                          >{{
                            'DEPOTSEARCH.ACTIONS.SELECTION.UNDELETE'
                              | translate : { numMeasurements: selectedSearchResults!.length }
                          }}</a
                        >
                        <a *ngIf="tooManyResultsForBatchUndelete$ | async" clrDropdownItem clrDisabled>
                          <clr-icon shape="recycle"></clr-icon
                          >{{
                            'DEPOTSEARCH.ACTIONS.SELECTION.TOO_MANY_FOR_UNDELETE'
                              | translate : { maxMeas: MAX_DELETE_UNDELETE_COUNT }
                          }}
                        </a>
                      </ng-container>
                    </ng-container>
                  </clr-dropdown-menu>
                </ng-template>
              </clr-dropdown>
              <div class="inActiveCCS" [attr.title]="'CONTENTCOLLECTION.GENERAL.INACTIVECC' | translate">
                <cloud-svg-icon-customizable
                  *ngIf="result.isInActiveCC === 'true'; else notInActiveCC"
                  fileName="content_collection_blue"
                ></cloud-svg-icon-customizable>
              </div>
              <ng-template #notInActiveCC>
                <div class="notInActiveCCSpacer"></div>
              </ng-template>
            </ng-container>
            <ng-template #deleteInPorgress>
              <span class="btn btn-link btn-icon no-margin nav-icon">
                <cloud-spinner></cloud-spinner> Delete/Undelete in progress
              </span>
            </ng-template>
          </ng-container>
          <ng-template #noActions>
            <div [attr.style]="'width: 1.6rem'"> </div>
          </ng-template>

          <div *ngIf="showDetailView$ | async" class="detail-cell-text">
            {{ result[(this.detailColumnAttributeId$ | async)!] }}
          </div>
        </clr-dg-cell>

        <!-- All other columns: Actual measurement contents -->
        <clr-dg-cell
          *ngFor="let column of depotSearchFacade.resultColumns$ | async"
          class="valueCell truncate"
          [attr.data-cy]="'TABLE_CONTENT_' + column.field + '_' + i + '_' + result[column.field]"
          [style.min-width.px]="150"
        >
          <ng-container *ngIf="depotSearchFacade.resultColumns$ | async as columns">
            <ng-container *ngIf="columns[0] as firstCol">
              <div dndDragImageRef class="dragged-item-img-ref">
                {{ result[firstCol.field] ?? ('DEPOTSEARCH.DRAG_AND_DROP.THISROW' | translate) }}
                <ng-container
                  *ngIf="this.contentCollectionFacade.contentCollectionItemsDragged$ | async as itemsDragged"
                >
                  <span class="badge" *ngIf="itemsDragged.length > 1"
                    >+ {{ itemsDragged.length - 1 }}
                    {{ 'DEPOTSEARCH.DRAG_AND_DROP.MORE' | translate | uppercase }}</span
                  >
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
          <span *ngIf="column.attribute.type === 'String'" class="truncate" style="display: inline-block">
            {{ result[column.field] }}
          </span>
          <span *ngIf="column.attribute.type === 'Date'" class="truncate" style="display: inline-block">
            {{ result[column.field] | formatDateTime }}
          </span>
          <span *ngIf="column.attribute.type === 'Boolean'" class="truncate" style="display: inline-block">
            {{ result[column.field] | boolToTranslateString | translate }}
          </span>
          <button
            *ngIf="result[column.field] !== null && column.attribute.type !== 'Date'"
            id="valueFilterButton"
            class="btn btn-link btn-icon no-margin nav-icon"
            (click)="addSearchBarFilter($event, column.attribute, result[column.field])"
            [ngClass]="{
              mouseDevice: !(commonFacade.isTouchDevice$ | async)
            }"
          >
            <clr-icon shape="filter-2"></clr-icon>
          </button>
        </clr-dg-cell>
      </clr-dg-row>

      <!-- Detail component -->
      <!-- Info: The existance of this creates the small "<<" buttons in the datagrid rows, which are used to access the detail view -->
      <ng-template [clrIfDetail]="showDetailView$ | async" let-detail>
        <clr-dg-detail class="datagrid-searchresult-detail">
          <clr-dg-detail-body class="datagrid-body-searchresult-detail">
            <cloud-measurements></cloud-measurements>
          </clr-dg-detail-body>
        </clr-dg-detail>
      </ng-template>

      <clr-dg-footer
        [ngClass]="{
          'footer-min-height': (selectedSearchResults$ | async)!.length > 0,
          'footer-search-table': true
        }"
      >
        <div class="loadmore-and-info" *ngIf="depotSearchResults$ | async as searchResults">
          <div class="load-more-container">
            <button
              [disabled]="searchResults.numberOfTotalResults === searchResults.numberOfEntries"
              (click)="getMoreResults({ extendSelection: false, keepSelection: true })"
              [attr.data-cy]="
                searchResults.numberOfTotalResults !== searchResults.numberOfEntries
                  ? 'LOAD_MORE_BUTTON_MORE_RESULTS'
                  : 'LOAD_MORE_BUTTON_NO_MORE_RESULTS'
              "
              [attr.title]="
                (searchResults.numberOfTotalResults !== searchResults.numberOfEntries
                  ? 'DEPOTSEARCH.LOADMORE'
                  : 'DEPOTSEARCH.LOADMORE_NO_MORE_RESULTS'
                ) | translate
              "
              class="btn"
            >
              <cloud-spinner
                *ngIf="depotSearchFacade.currentDepotSearchIsInFlight$ | async"
                [size]="'small'"
              ></cloud-spinner>
              <ng-container *ngIf="!(depotSearchFacade.currentDepotSearchIsInFlight$ | async)">
                <ng-container *ngIf="searchResults.numberOfTotalResults !== searchResults.numberOfEntries">
                  {{ 'DEPOTSEARCH.LOADMORE' | translate }}
                </ng-container>
                <ng-container *ngIf="searchResults.numberOfTotalResults === searchResults.numberOfEntries">
                  {{ 'DEPOTSEARCH.LOADMORE_NO_MORE_RESULTS' | translate }}
                </ng-container>
              </ng-container>
            </button>
          </div>
          <div *ngIf="searchResults.numberOfTotalResults === 1" data-cy="searchResultCount">
            {{
              'DEPOTSEARCH.RELATIVELOADCOUNT_SING'
                | translate
                  : {
                      loaded: searchResults.numberOfEntries,
                      totalresults: searchResults.numberOfTotalResults
                    }
            }}
          </div>
          <div
            *ngIf="searchResults.numberOfTotalResults > 1 && searchResults.numberOfTotalResults < 10000"
            data-cy="searchResultCount"
          >
            {{
              'DEPOTSEARCH.RELATIVELOADCOUNT'
                | translate
                  : {
                      loaded: searchResults.numberOfEntries,
                      totalresults: searchResults.numberOfTotalResults
                    }
            }}
          </div>
          <div *ngIf="searchResults.numberOfTotalResults >= 10000" data-cy="searchResultCount">
            {{
              'DEPOTSEARCH.RELATIVELOADCOUNT_MORE'
                | translate
                  : {
                      loaded: searchResults.numberOfEntries,
                      totalresults: searchResults.numberOfTotalResults
                    }
            }}
          </div>
        </div>
      </clr-dg-footer>
    </clr-datagrid>

    <h3
      class="noColumnSelectedNotice"
      *ngIf="(depotSearchFacade.resultColumns$ | async) && (depotSearchFacade.resultColumns$ | async)!.length === 0"
    >
      {{ 'DEPOTSEARCH.NOCOLUMNSELECTED' | translate }}
    </h3>
  </span>

  <h3
    *ngIf="
      !(depotSearchFacade.initialSearchPerformed$ | async) &&
      (depotSearchFacade.depotSearchReady$ | async) &&
      (appFacade.cloudDepotsLoaded$ | async) &&
      !(depotSearchFacade.currentDepotSearchIsInFlight$ | async)
    "
    style="text-align: center"
  >
    {{ 'DEPOTSEARCH.STARTNEWSEARCH' | translate }}
  </h3>
  <h3
    *ngIf="
      !(depotSearchFacade.initialSearchPerformed$ | async) &&
      (depotSearchFacade.depotSearchReady$ | async) &&
      (appFacade.cloudDepotsLoaded$ | async) &&
      (depotSearchFacade.currentDepotSearchIsInFlight$ | async)
    "
    style="text-align: center"
  >
    {{ 'DEPOTSEARCH.PERFORMSEARCHNOTICE' | translate }}
  </h3>
  <h3
    *ngIf="!(depotSearchFacade.depotSearchReady$ | async)! || !(appFacade.cloudDepotsLoaded$ | async)!"
    style="text-align: center"
  >
    <cloud-spinner [size]="'medium'"></cloud-spinner>
    <span data-cy="loadingSearchPageNotice">
      {{ 'DEPOTSEARCH.LOADINGSEARCHPAGE' | translate }}
    </span>
  </h3>
  <h3
    *ngIf="
      !(depotSearchFacade.currentDepotSearchIsInFlight$ | async) &&
      (results$ | async) &&
      (results$ | async)!.length === 0 &&
      (depotSearchFacade.initialSearchPerformed$ | async)
    "
    style="text-align: center"
    data-cy="NOSEARCHRESULTS"
  >
    {{ 'DEPOTSEARCH.NORESULTS' | translate }}
  </h3>
  <h3 *ngIf="depotSearchFacade.currentDepotSearchIsInFlight$ | async" style="text-align: center">
    <cloud-spinner [size]="'medium'"></cloud-spinner>
    <span data-cy="performingSearchNotice">
      {{ 'DEPOTSEARCH.SEARCHACTIVE' | translate }}
    </span>
  </h3>
</div>

<!-- Display for small screens - single cards instead of table -->
<!-- Note: This has currently no detail view -->
<span *ngIf="!(showTable$ | async) && !(depotSearchFacade.showResultDetailView$ | async)">
  <span *ngIf="(depotSearchFacade.resultColumns$ | async) && (depotSearchFacade.resultColumns$ | async)!.length > 0">
    <div class="clr-row">
      <div class="clr-col-12">
        <div
          class="card clickable"
          [ngClass]="{ selected: (selectedSearchResults$ | async)!.includes(entry) }"
          *ngFor="let entry of results$ | async"
          (click)="toggleSearchResultSelectedState(entry)"
        >
          <div class="card-block no-padding">
            <div class="card-title"></div>
            <div class="card-text">
              <ul class="cardList">
                <li *ngFor="let column of depotSearchFacade.resultColumns$ | async">
                  <h4>{{ column.attribute | translateAttribute }}</h4>
                  : {{ entry[column.field] }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </span>
  <ng-container *ngIf="selectedSearchResults$ | async as selectedSearchResults">
    <div id="fixedFooter" *ngIf="selectedSearchResults!.length > 0">
      <div id="footerCounter">
        <div class="selectionCounterText">
          <span *ngIf="selectedSearchResults.length === 1">
            {{ 'DEPOTSEARCH.ONEMEASUREMENTSELECTED' | translate }}
          </span>
          <span *ngIf="selectedSearchResults.length > 1">
            {{ selectedSearchResults.length }} {{ 'DEPOTSEARCH.MEASUREMENTSSELECTED' | translate }}
          </span>
        </div>
        <div class="button" (click)="addSelectedSearchResultsToActiveCollection()">
          <i class="plus-circle"></i>{{ 'DEPOTSEARCH.ACTIONS.SINGLE.ADDTOCOLLECTION.ACTIVE' | translate }}
        </div>
        <div class="button" (click)="requestMultipleMeasurementsDownload()">
          <i class="download"></i>{{ 'DEPOTSEARCH.ACTIONS.SINGLE.DOWNLOAD' | translate }}
        </div>
        <!-- <div class="button" (click)="log(selectedSearchResults)">
          <i class="archive"></i>{{ 'DEPOTSEARCH.ACTIONS.SINGLE.ARCHIVE' | translate }}
        </div> -->
        <!-- <div class="button" (click)="log(selectedSearchResults)">
          <i class="switch"></i>{{ 'DEPOTSEARCH.ACTIONS.SINGLE.MOVETODEPOT' | translate }}
        </div> -->
      </div>
    </div>
  </ng-container>
</span>

<cloud-measurement-delete-or-undelete></cloud-measurement-delete-or-undelete>
