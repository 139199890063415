import { Component, OnInit, OnDestroy } from '@angular/core';
import { MeasurementsFacade } from '../../+state/measurements.facade';
import { DepotSearchFacade } from '../../../depot-search/+state/depot-search.facade';
import { AppFacade } from '../../../+state/app.facade';
import { MeasurementOverview, AttributeValue, archivedStatus } from '../../measurements.types';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { compareStringWithNumbers } from '../../../shared/utility-functions/compareStringWithNumbers';

import { FindAttributeByIdPipe } from '../../../shared/pipes/find-attribute-by-id.pipe';
import { TranslateAttributePipe } from '../../../shared/pipes/translate-attribute.pipe';

import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DepotAttribute } from '../../../shared/+state/attributes/attributes.types';
import { AttributesFacade } from '../../../shared/+state/attributes/attributes.facade';

@Component({
  selector: 'cloud-measurement-overview',
  templateUrl: './measurement-overview.component.html',
  styleUrls: ['./measurement-overview.component.css']
})
export class MeasurementOverviewComponent implements OnInit, OnDestroy {
  measurementOverview$: Observable<MeasurementOverview>;
  generalDataDisplay$: Observable<[string, AttributeValue][]>;
  generalDataList$: Observable<[string, AttributeValue][]>;
  columns$: Observable<number>;

  showAllAttributes$ = new BehaviorSubject<boolean>(false);
  measurementDepotID$ = new BehaviorSubject<string>('');
  allDepotAttributes$ = new BehaviorSubject<DepotAttribute[] | undefined>(undefined);

  minEntriesPerColumn = 8;

  // Hold all state subscriptions in a container, since a lot of subscriptions are currently held in memory during the component lifetime and need to be cleaned up eventually
  subscriptionContainer: Subscription[] = [];

  constructor(
    public measurementsFacade: MeasurementsFacade,
    public depotSearchFacade: DepotSearchFacade,
    public attributesFacade: AttributesFacade,
    public translate: TranslateService,
    public appFacade: AppFacade
  ) {}

  ngOnInit() {
    this.subscriptionContainer.push(
      this.attributesFacade.availableDepotAttributes$.subscribe(this.allDepotAttributes$)
    );
    this.measurementOverview$ = this.measurementsFacade.selectedMeasurement$.pipe(
      map((meas) => meas?.resources?.overview)
    );
    this.subscriptionContainer.push(
      this.measurementsFacade.selectedMeasurement$
        .pipe(
          map((measurement) => measurement?.depotId),
          map((depotId) => (depotId ? depotId : ''))
        )
        .subscribe(this.measurementDepotID$)
    );
    this.subscriptionContainer.push(
      this.measurementsFacade.attributeDisplayType$.subscribe((displayState) => {
        if (displayState) {
          this.showAllAttributes$.next(displayState === 'all');
        }
      })
    );
    this.generalDataList$ = this.measurementOverview$.pipe(
      map((overview) => (overview?.generalData ? Object.entries(overview.generalData) : []))
    );
    this.generalDataDisplay$ = combineLatest([
      this.showAllAttributes$,
      this.generalDataList$,
      this.appFacade.language$
    ]).pipe(
      map(([showAllAttributes, list, currentLang]) =>
        showAllAttributes ? list : list.filter((value) => !this.isValueEmpty(value))
      ),
      map((list) => list.map((item) => [this.findAttributeTranslation(item[0]), item[1]] as [string, AttributeValue])),
      map((list) => list.sort((a, b) => compareStringWithNumbers(a[0], b[0])))
    );
    this.columns$ = this.generalDataDisplay$.pipe(
      map((list) => (list ? Math.ceil(list.length / this.minEntriesPerColumn) : 1))
    );
  }

  isValueEmpty(value: [string, AttributeValue]): boolean {
    const attrValue = value[1];
    if (attrValue === null) {
      return true;
    } else return attrValue.toString().trim() === '';
  }

  displayArchived(status: archivedStatus): string {
    if (status === archivedStatus.archived) {
      return _('MEASUREMENT.ARCHIVED_ARCHIVED');
    } else if (status === archivedStatus.restored) {
      return _('MEASUREMENT.ARCHIVED_RESTORED');
    } else {
      return _('MEASUREMENT.ARCHIVED_DEFAULT');
    }
  }

  findAttributeTranslation(attributeId: string): string {
    const allAttributes = this.allDepotAttributes$.value;
    if (allAttributes === undefined) {
      return attributeId;
    }
    const depotID = this.measurementDepotID$.value;
    const attributeById = new FindAttributeByIdPipe().transform(allAttributes, attributeId, 'AoSubTest', depotID);
    return new TranslateAttributePipe(this.translate).transform(attributeById);
  }

  ngOnDestroy() {
    for (const sub of this.subscriptionContainer) {
      sub.unsubscribe();
    }
  }
}
