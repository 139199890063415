import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, filter, map, Observable } from 'rxjs';
import { UserFacade } from '../../user/+state/user.facade';

@Injectable()
export class CanActivateAdmin implements CanActivate {
  constructor(private userFacade: UserFacade, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return combineLatest([
      this.userFacade.isAdmin$,
      this.userFacade.loaded$.pipe(filter((loaded) => loaded === true))
    ]).pipe(
      map(([isAdmin, _]) => {
        if (!isAdmin) {
          return this.router.parseUrl('/');
        }
        return true;
      })
    );
  }
}
