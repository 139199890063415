import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';
import { Observable } from 'rxjs';
import { FeatureFlagsFacade } from '@vas/feature-flags';

interface Licenses {
  intro: string;
  licenses: {
    [index: string]: {
      type: string;
      repo: string;
      license: string;
    };
  };
}
@Component({
  selector: 'cloud-tpl',
  templateUrl: './tpl.component.html',
  styleUrls: ['./tpl.component.css']
})
export class ThirdPartyLicensesComponent implements OnInit {
  public licenses: Licenses;
  public active = false;
  public url = `${this.baseHref}assets/3pl.json`;
  constructor(
    private http: HttpClient,
    public featureFlagFacade: FeatureFlagsFacade,
    @Inject(APP_BASE_HREF) private baseHref: string
  ) {}

  ngOnInit() {
    this.get3pl().subscribe((data) => {
      this.licenses = data;
      this.active = false;
    });
  }

  get3pl(): Observable<Licenses> {
    this.active = true;
    return this.http.get(this.url, { responseType: 'json' }) as Observable<Licenses>;
  }
}
