import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SearchBarHighlightFilterPipe } from '../../../../depot-search/autocomplete-dropdown/pipes/search-bar-highlight.pipe';
import { ClickOutsideModule } from 'ng-click-outside';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { ListType } from '../pick-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
  selector: 'cloud-ui-container',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SvgIconComponent,
    SearchBarHighlightFilterPipe,
    ClickOutsideModule,
    DragDropModule
  ],
  templateUrl: './ui-container.component.html',
  styleUrls: ['./ui-container.component.css']
})
export class UiContainerComponent {
  @Input() activeList: ListType;
  @Input() activeListName: string;
  @Input() data: any[];
  @Input() query = '';
  @Input() itemKey = '';
  @Input() selectedItems: any[] = [];
  @Input() title: string;
  @Input() isDisabled = false;

  @Output() activeListChange: EventEmitter<ListType | null> = new EventEmitter();
  @Output() selectedItemsChange: EventEmitter<any[]> = new EventEmitter();
  @Output() queryChange: EventEmitter<string> = new EventEmitter();
  @Output() resetFilter: EventEmitter<void> = new EventEmitter();

  onFilter(query: string) {
    this.queryChange.emit(query);
  }

  selectItem(event: any, item: any) {
    const isAlreadySelected = this.selectedItems?.find(
      (selectedItem) => selectedItem[this.itemKey] === item[this.itemKey]
    );
    let metaKey = event.metaKey || event.ctrlKey || event.shiftKey;
    if (isAlreadySelected && metaKey) {
      this.selectedItems = this.selectedItems?.filter(
        (selectedItem) => selectedItem[this.itemKey] !== item[this.itemKey]
      );
    } else {
      if (!metaKey) {
        this.selectedItems = [];
      }
      this.selectedItems = [...this.selectedItems, item];
    }

    this.selectedItemsChange.emit(this.selectedItems);
  }

  isSelected(item: any) {
    const isSelected = !!this.selectedItems?.find((selectedItem) => selectedItem[this.itemKey] === item[this.itemKey]);
    return isSelected;
  }

  handleClickInside(_event, listType: string) {
    this.activeListChange.emit(listType as ListType);
  }

  handleClickOutside(_event, listType: string) {
    if (this.activeList === listType) {
      this.activeListChange.emit(null);
    }
  }

  onResetFilter() {
    this.query = '';
    this.resetFilter.emit();
  }
}
