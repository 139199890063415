import { UserInfo } from '../user.type';

import * as UserAction from './user.actions';
import { createReducer, Action } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';

export const USER_FEATURE_KEY = 'user';

import { MyLicenseInfo } from '../../admin/admin/license/types/license.types';
import { RoleInfo } from '../../admin/admin/license/types/role.types';
export interface UserState {
  user: UserInfo;
  usersSelfCanSee: Partial<UserInfo>[];
  rolesSelfCanSee: Partial<RoleInfo>[];
  myLicenses: MyLicenseInfo[] | null;
  agent: {
    os: string | 'unknown';
  };
  loaded: boolean;
  error?: any;
}

export interface UserPartialState {
  readonly [USER_FEATURE_KEY]: UserState;
}

export const initialUser: UserInfo = {
  id: '',
  hasVibroLicense: false,
  userName: '',
  email: '',
  isActive: true,
  groups: []
};

export const initialState: UserState = {
  user: initialUser,
  usersSelfCanSee: [],
  rolesSelfCanSee: [],
  myLicenses: null,
  agent: {
    os: 'unknown'
  },
  loaded: false
};

const UserReducer = createReducer(
  initialState,
  immerOn(UserAction.UserLoaded, (state, { user }) => {
    state.user = user;
    state.loaded = true;
  }),
  immerOn(UserAction.UsersSelfCanSeeLoaded, (state, { users }) => {
    state.usersSelfCanSee = users;
  }),
  immerOn(UserAction.RolesSelfCanSeeLoaded, (state, { roles }) => {
    state.rolesSelfCanSee = roles;
  }),
  immerOn(UserAction.MyLicensesLoaded, (state, { myLicenses }) => {
    state.myLicenses = myLicenses;
  }),
  immerOn(UserAction.AgentIdentified, (state, { os }) => {
    state.agent.os = os ?? 'unknown';
  })
);

export function reducer(state: UserState | undefined, action: Action) {
  return UserReducer(state, action);
}
