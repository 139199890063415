import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'cloud-svg-icon',
  standalone: true,
  template: '<img [src]="pathToFile + fileName + fileExt" [alt]="alt" />',
  styleUrls: ['./svg-icon.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgIconComponent {
  defaultSVGPath = `assets/img/custom_icons/`;
  fileExt = '.svg';

  @HostBinding('style.height')
  private _height: string;

  @HostBinding('style.width')
  private _width: string;

  @Input() fileName: string;

  @Input() pathToFile: string = this.defaultSVGPath;

  @Input() alt: string = 'SVG Image';

  @Input() set height(iconHeight: string) {
    this._height = iconHeight;
  }

  @Input() set width(iconWidth: string) {
    this._width = iconWidth;
  }
}
