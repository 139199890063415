import * as LicensesAction from './licenses.actions';
import { createReducer, Action } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { LicenseInfo } from '../../types/license.types';

export const LICENSES_FEATURE_KEY = 'licenses';

export interface LicensesState {
  licenses: LicenseInfo[];
  loaded: boolean;
  error?: any;
}

export interface LicensesPartialState {
  readonly [LICENSES_FEATURE_KEY]: LicensesState;
}

export const initialLicenses: LicenseInfo[] = [];

export const initialState: LicensesState = {
  licenses: initialLicenses,
  loaded: false
};

const LicensesReducer = createReducer(
  initialState,
  immerOn(LicensesAction.LicensesLoaded, (state, { licenses }) => {
    state.licenses = licenses;
    state.loaded = true;
  }),
  immerOn(LicensesAction.RemoveLicensesFromRoleSuccess, (state, { roleId, licenses }) => {
    state.licenses = state.licenses.map((license) => {
      if (licenses.find((l) => l === license.id)) {
        return { ...license, roles: license.roles.filter((role) => role !== roleId) };
      } else return license;
    });
  }),
  immerOn(LicensesAction.AddLicensesToRoleSuccess, (state, { roleId, licenses }) => {
    state.licenses = state.licenses.map((license) => {
      if (licenses.find((l) => l === license.id)) {
        return { ...license, roles: [...license.roles, roleId] };
      } else return license;
    });
  })
);

export function reducer(state: LicensesState | undefined, action: Action) {
  return LicensesReducer(state, action);
}
