<div class="heading">
  <div class="btn-group">
    <a class="btn" routerLinkActive="btn-primary" routerLink="/admin/licensing/users">
      {{ 'LICENSING.USERS' | translate }}
    </a>
    <a class="btn" routerLinkActive="btn-primary" routerLink="/admin/licensing/groups">{{
      'LICENSING.GROUPS' | translate
    }}</a>
    <a class="btn" routerLinkActive="btn-primary" routerLink="/admin/licensing/roles">{{
      'LICENSING.ROLES' | translate
    }}</a>
    <a class="btn" routerLinkActive="btn-primary" routerLink="/admin/licensing/licenses">{{
      'LICENSING.LICENSES' | translate
    }}</a>
  </div>

  <div class="datagrid-filters">
    <div class="search-input-container">
      <input
        type="text"
        placeholder="Filter"
        [value]="searchService.searchFilter$ | async"
        (input)="searchService.searchFilter$.next($event.target.value)"
        (keydown.escape)="searchService.searchFilter$.next('')"
      />
      <cloud-svg-icon fileName="pick_filter"></cloud-svg-icon>
    </div>
  </div>

  <cloud-toggle-button
    *ngIf="router.url.includes('/users')"
    [text]="'LICENSING.USERS_SHOW_INACTIVE' | translate"
    (clicked)="toggleActiveUsers()"
    [toggleState]="(usersFacade.isActiveFilter$ | async) ? 'off' : 'on'"
  ></cloud-toggle-button>
</div>

<router-outlet></router-outlet>
