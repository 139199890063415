import { FeatureFlag, featureFlagDatatypeDefaults } from './feature-flags.types';

export type BaseFeatureFlagIdentifiers = 'DOCS' | 'PRIVACY' | 'TERMSOFUSE' | 'DEBUG_MODE';

export const BaseFeatureFlags: FeatureFlag[] = [
  {
    id: 'DOCS',
    descr: `Show the Documentation`,
    area: 'user',
    datatype: 'boolean',
    defaultValue: featureFlagDatatypeDefaults['boolean']
  },
  {
    id: 'PRIVACY',
    descr: `Replace the link to the privacy statement`,
    area: 'user',
    datatype: 'path',
    defaultValue: featureFlagDatatypeDefaults['path']
  },
  {
    id: 'TERMSOFUSE',
    descr: `Show Custom Terms of Use link`,
    area: 'user',
    datatype: 'path',
    defaultValue: featureFlagDatatypeDefaults['path']
  },
  {
    id: 'DEBUG_MODE',
    descr: `Enables the debug mode`,
    area: 'dev',
    datatype: 'boolean',
    defaultValue: featureFlagDatatypeDefaults['boolean']
  }
];
