import { props, createAction } from '@ngrx/store';
import { AppErrorPayload } from '../../app.types';
import {
  DepotAttribute,
  SearchAttributeAndValue,
  SemanticDepotAttribute
} from '../../shared/+state/attributes/attributes.types';
import {
  AggregateTree,
  AggregateTreeDef,
  AggregateTreeSortingMethod,
  AggregateTreeSortingMethodPerAggregateType,
  AggregateType,
  TreeNode
} from '../../shared/types/aggregateTree.types';
import { ResultColumn, SearchResultEntry } from '../../shared/types/search.types';
import { DepotSearchParameters, DepotSearchResult, MeasurementDeleteOrUndeleteData } from '../depot-search.types';
import { DepotSearchSortDefinition } from './depot-search.reducer';
import { OrderSearchParameters } from '../../order-management/order-search.types';

export enum DepotSearchActionTypes {
  /* Aggregate Tree related */
  TryReselectLazyLoadedNode = '[AggregateTree] Try Re-select Node which was Loaded Lazyly in Previous Search',
  LazyLoadedNodeReselected = '[AggregateTree] Re-selected Node which was Loaded Lazyly in Previous Search',
  ResetLazyLoadedNodesList = '[AggregateTree] Reset List of Lazy-Loaded Nodes',
  ToggleTreeNode = '[AggregateTree] Expand/Collapse Node in Aggregate Tree',
  SetAggregateTreeWidth = '[AggregateTree] Set Aggregate Tree Width',
  GetAggregates = '[AggregateTree] Get Aggregates',
  AddExtraFiltersToAggregates = '[AggregateTree] Add Starttime Constraint to Aggregate',
  SendAggregateRequest = '[AggregateTree] Send Aggregate Search Request',
  AggregatesReceived = '[AggregateTree] Aggregates Received',
  AggregateError = '[AggregateTree] Error during Aggregate Search',
  InitDefaultAggregateTrees = '[AggregateTree] Init Default Aggregate Trees',
  AggregateTreesInitialized = '[AggregateTree] Default Aggregate Trees initialized',
  LoadChildAggregates = '[AggregateTree] Load Child Aggregates for One Tree Node',
  AddExtraFiltersToLoadChildAggs = '[AggregateTree] Add Starttime Constraint to Load Child Aggregates',
  SendChildAggregatesRequest = '[AggregateTree] Send Child Aggregate Search Request',
  ChildAggregatesLoaded = '[AggregateTree] Child Aggregates for Tree Node Loaded',
  LoadChildAggregatesError = '[AggregateTree] Error on Load Child Aggregates for One Tree Node',

  SetAggregateTreeSortingMethod = '[AggregateTree] Set Aggregate Tree Sorting Method',
  RestoreActiveAggregateTreeSortingMethods = '[AggregateTree] Restore Aggregate Tree Sorting Methods from Persistence Layer',
  SetAggregateTreeSelectedType = '[AggregateTree] Set Selected Aggregate Tree Type',
  SortAggregateTrees = '[AggregateTree] Sort Aggregate Trees',

  /* Depot Search related */
  PerformSearch = '[DepotSearch] Perform Search',
  PerformSearchOnInitialNavigation = '[DepotSearch] [Initial Navigation] Perform Search',
  PerformSearchOnPopState = '[DepotSearch] [Pop Navigation History State] Perform Search',
  PerformSearchOnRouteChange = '[DepotSearch] [Route Changed To Deposearch] Perform Search',
  SetLastSearchParameters = '[DepotSearch] Set Last Search Parameters',
  SetSearchTextByRouter = '[DepotSearch] [Router] Set Search Text',
  SetSearchAttributesFiltersByRouter = '[DepotSearch] [Router] Set Search Attributes Filters',
  AddExtraFiltersToSearch = '[DepotSearch] Add Tree Filters and Starttime Constraint To Search Params',
  AddColumnsToSearch = '[DepotSearch] Add Columns To Search Params',
  AddPendingDeletionAttribute = '[DepotSearch] Add Pending Deletions Attribute',
  SendSearchRequest = '[DepotSearch] Send Search Request',
  SendOrderSearchRequest = '[DepotSearch] Send Order Search Request',
  SearchResultReceived = '[DepotSearch] Search Result Received',
  OrderSearchResultReceived = '[DepotSearch] Order Search Result Received',
  SearchError = '[DepotSearch] Error during Search',
  LoadMoreResults = '[DepotSearch] Load More Results for Last Search',
  SelectTreeNode = '[DepotSearch] Set the Selected Node in TreeView -> triggers new search',
  UpdateSelectedNode = '[DepotSearch] Update the Selected Node in TreeView -> no new search',
  UnselectTreeNode = '[DepotSearch] Unselect Node in TreeView -> no new search',
  ModifySearchText = '[DepotSearch] Modify Search Text',
  AddSearchBarFilter = '[DepotSearch] Add Search Bar Filter',
  RemoveSearchBarFilter = '[DepotSearch] Remove Search Bar Filter',
  ModifySearchBarFilter = '[DepotSearch] Modify Search Bar Filter',
  ResetSearchBar = '[DepotSearch] Reset Search Bar - Removing Search Text and All Search Filters',
  RestrictToActiveCC = '[DepotSearch] Restrict Search to Active CC',
  InitDefaultResultColumns = '[DepotSearch] Init Default Result Columns',
  ResultColumnsInitialized = '[DepotSearch] Result Columns loaded from localStorage (or default)',
  AddResultColumn = '[DepotSearch] Add new Result Column',
  AddExtraFiltersToNewColumn = '[DepotSearch] Add Tree Filters and Starttime Constraint to New Column Search',
  SendNewColumnRequest = '[DepotSearch] Send Search Request for Content of New Column',
  ColumnContentReceived = '[DepotSearch] Content for New Column Received',
  NewColumnError = '[DepotSearch] Error during New Column Search',
  RemoveResultColumn = '[DepotSearch] Remove Result Column',
  ViewMeasurementDetails = '[DepotSearch] View Result Detail',
  ViewMeasurementOverviewList = '[DepotSearch] View Result Overview',
  SetNumberShownResultLines = '[DepotSearch] Set Number of Result Lines to show',
  SetMarkedResultLine = '[DepotSearch] Mark Result Line',
  DeleteMeasurement = '[DepotSearch] Delete Measurement',
  MeasurementDeleted = '[DepotSearch] Measurement Deleted',
  DeleteMeasurementError = '[DepotSearch] Delete Measurement Error',
  UndeleteMeasurement = '[DepotSearch] Undelete Measurement',
  MeasurementUndeleted = '[DepotSearch] Measurement Undeleted',
  UndeleteMeasurementError = '[DepotSearch] Undelete Measurement Error',
  SearchForOrder = '[DepotSearch] Get Order to a specific Measurement',
  SetSelectionMeasurementIDs = '[DepotSearch] Set the list of all measurements selected in the depot search list (to reselect them when revisiting the page)',
  RevertSearchAttributeChanges = '[DepotSearch] Revert all changes to the search attributes, that have not yet been used for an actual search',
  ActivateSearchBarFilter = '[DepotSearch] Activate Search Bar Filter', // i.e. its dropdown is opened
  DeactivateSearchBarFilter = '[DepotSearch] Deactivate Search Bar Filter',

  SetSort = '[DepotSearch - Table] Set Sort'
}

/* Aggregate Tree related */
export const SetAggregateTreeSortingMethod = createAction(
  DepotSearchActionTypes.SetAggregateTreeSortingMethod,
  props<{ method: AggregateTreeSortingMethod }>()
);

export const RestoreActiveAggregateTreeSortingMethods = createAction(
  DepotSearchActionTypes.RestoreActiveAggregateTreeSortingMethods,
  props<{ methods: AggregateTreeSortingMethodPerAggregateType }>()
);

export const SetAggregateTreeSelectedType = createAction(
  DepotSearchActionTypes.SetAggregateTreeSelectedType,
  props<{ aggregateType: AggregateType }>()
);

export const SortAggregateTrees = createAction(DepotSearchActionTypes.SortAggregateTrees);

/* Depot Search related */
export const PerformSearch = createAction(
  DepotSearchActionTypes.PerformSearch,
  props<{ suppressHierarchyTreeReload?: boolean }>()
);

export const PerformSearchOnInitialNavigation = createAction(DepotSearchActionTypes.PerformSearchOnInitialNavigation);
export const PerformSearchOnPopState = createAction(DepotSearchActionTypes.PerformSearchOnPopState);
export const PerformSearchOnRouteChange = createAction(DepotSearchActionTypes.PerformSearchOnRouteChange);

export const SetLastSearchParameters = createAction(
  DepotSearchActionTypes.SetLastSearchParameters,
  props<{ params: DepotSearchParameters }>()
);

export const SetSearchTextByRouter = createAction(
  DepotSearchActionTypes.SetSearchTextByRouter,
  props<{ searchText: string; navigationInfo: any }>()
);

export const SetSearchAttributesFiltersByRouter = createAction(
  DepotSearchActionTypes.SetSearchAttributesFiltersByRouter,
  props<{ searchFilters: SearchAttributeAndValue[]; navigationInfo: any }>()
);

export const ModifySearchText = createAction(DepotSearchActionTypes.ModifySearchText, props<{ value: string }>());

export const AddExtraFiltersToSearch = createAction(
  DepotSearchActionTypes.AddExtraFiltersToSearch,
  props<{ params: DepotSearchParameters; isNewSearch: boolean }>()
);

export const AddColumnsToSearch = createAction(
  DepotSearchActionTypes.AddColumnsToSearch,
  props<{ params: DepotSearchParameters; isNewSearch: boolean }>()
);

export const AddPendingDeletionAttribute = createAction(
  DepotSearchActionTypes.AddPendingDeletionAttribute,
  props<{ params: DepotSearchParameters; isNewSearch: boolean }>()
);

export const SendSearchRequest = createAction(
  DepotSearchActionTypes.SendSearchRequest,
  props<{ params: DepotSearchParameters; isNewSearch: boolean }>()
);

export const SendOrderSearchRequest = createAction(
  DepotSearchActionTypes.SendOrderSearchRequest,
  props<{ params: OrderSearchParameters; isNewSearch: boolean }>()
);

export const SearchResultReceived = createAction(
  DepotSearchActionTypes.SearchResultReceived,
  props<{ result: DepotSearchResult; offset: number; isNewSearch: boolean }>()
);

export const OrderSearchResultReceived = createAction(
  DepotSearchActionTypes.OrderSearchResultReceived,
  props<{ result: DepotSearchResult; offset: number; isNewSearch: boolean }>()
);

export const SearchError = createAction(DepotSearchActionTypes.SearchError, props<{ error: AppErrorPayload }>());

export const LoadMoreResults = createAction(DepotSearchActionTypes.LoadMoreResults);

export const SelectTreeNode = createAction(
  DepotSearchActionTypes.SelectTreeNode,
  props<{ node: TreeNode | undefined }>()
);

export const UpdateSelectedNode = createAction(DepotSearchActionTypes.UpdateSelectedNode, props<{ node: TreeNode }>());

export const UnselectTreeNode = createAction(DepotSearchActionTypes.UnselectTreeNode);

export const TryReselectLazyLoadedNode = createAction(
  DepotSearchActionTypes.TryReselectLazyLoadedNode,
  props<{ node: TreeNode }>()
);

export const LazyLoadedNodeReselected = createAction(
  DepotSearchActionTypes.LazyLoadedNodeReselected,
  props<{ selectedNode: TreeNode; loadedNodes: TreeNode[]; selectionPath: TreeNode[] | undefined }>()
);

export const ResetLazyLoadedNodesList = createAction(DepotSearchActionTypes.ResetLazyLoadedNodesList);

export const ToggleTreeNode = createAction(
  DepotSearchActionTypes.ToggleTreeNode,
  props<{ node: TreeNode; expanded: boolean }>()
);

export const SetAggregateTreeWidth = createAction(
  DepotSearchActionTypes.SetAggregateTreeWidth,
  props<{ width: number }>()
);

export const AddSearchBarFilter = createAction(
  DepotSearchActionTypes.AddSearchBarFilter,
  props<{ attribAndValue: SearchAttributeAndValue }>()
);

export const RemoveSearchBarFilter = createAction(
  DepotSearchActionTypes.RemoveSearchBarFilter,
  props<{ attribAndValue: SearchAttributeAndValue }>()
);

export const ResetSearchBar = createAction(DepotSearchActionTypes.ResetSearchBar);

export const RestrictToActiveCC = createAction(
  DepotSearchActionTypes.RestrictToActiveCC,
  props<{ restrictToActiveCC: boolean }>()
);

export const ModifySearchBarFilter = createAction(
  DepotSearchActionTypes.ModifySearchBarFilter,
  props<{ attribAndValue: SearchAttributeAndValue }>()
);

export const InitDefaultResultColumns = createAction(DepotSearchActionTypes.InitDefaultResultColumns);

export const ResultColumnsInitialized = createAction(
  DepotSearchActionTypes.ResultColumnsInitialized,
  props<{ columns: ResultColumn[] }>()
);

export const AddResultColumn = createAction(
  DepotSearchActionTypes.AddResultColumn,
  props<{ attrib: DepotAttribute | SemanticDepotAttribute }>()
);

export const AddExtraFiltersToNewColumn = createAction(
  DepotSearchActionTypes.AddExtraFiltersToNewColumn,
  props<{ params: DepotSearchParameters; column: ResultColumn }>()
);

export const SendNewColumnRequest = createAction(
  DepotSearchActionTypes.SendNewColumnRequest,
  props<{ params: DepotSearchParameters; column: ResultColumn }>()
);

export const ColumnContentReceived = createAction(
  DepotSearchActionTypes.ColumnContentReceived,
  props<{ result: DepotSearchResult; column: ResultColumn }>()
);

export const NewColumnError = createAction(
  DepotSearchActionTypes.NewColumnError,
  props<{ error: AppErrorPayload; column: ResultColumn }>()
);

export const RemoveResultColumn = createAction(
  DepotSearchActionTypes.RemoveResultColumn,
  props<{ column: ResultColumn }>()
);

export const ViewMeasurementDetails = createAction(
  DepotSearchActionTypes.ViewMeasurementDetails,
  props<{ result: SearchResultEntry }>()
);

export const ViewMeasurementOverviewList = createAction(DepotSearchActionTypes.ViewMeasurementOverviewList);

export const SetNumberShownResultLines = createAction(
  DepotSearchActionTypes.SetNumberShownResultLines,
  props<{ numberOfResultLines: number }>()
);

export const SetMarkedResultLine = createAction(
  DepotSearchActionTypes.SetMarkedResultLine,
  props<{ markedResultLine: number }>()
);

export const DeleteMeasurement = createAction(
  DepotSearchActionTypes.DeleteMeasurement,
  props<{ measurement: MeasurementDeleteOrUndeleteData }>()
);

export const MeasurementDeleted = createAction(
  DepotSearchActionTypes.MeasurementDeleted,
  props<{ measurement: MeasurementDeleteOrUndeleteData }>()
);

export const DeleteMeasurementError = createAction(
  DepotSearchActionTypes.DeleteMeasurementError,
  props<{ measurement: MeasurementDeleteOrUndeleteData }>()
);

export const UndeleteMeasurement = createAction(
  DepotSearchActionTypes.UndeleteMeasurement,
  props<{ measurement: MeasurementDeleteOrUndeleteData }>()
);

export const MeasurementUndeleted = createAction(
  DepotSearchActionTypes.MeasurementUndeleted,
  props<{ measurement: MeasurementDeleteOrUndeleteData }>()
);

export const UndeleteMeasurementError = createAction(
  DepotSearchActionTypes.UndeleteMeasurementError,
  props<{ measurement: MeasurementDeleteOrUndeleteData }>()
);

export const SearchForOrder = createAction(DepotSearchActionTypes.SearchForOrder, props<{ orderId: string }>());

export const GetAggregates = createAction(
  DepotSearchActionTypes.GetAggregates,
  props<{ aggregateDefs: AggregateTreeDef[] }>()
);

export const AddExtraFiltersToAggregates = createAction(
  DepotSearchActionTypes.AddExtraFiltersToAggregates,
  props<{ params: DepotSearchParameters }>()
);

export const SendAggregateRequest = createAction(
  DepotSearchActionTypes.SendAggregateRequest,
  props<{ params: DepotSearchParameters }>()
);

export const AggregatesReceived = createAction(
  DepotSearchActionTypes.AggregatesReceived,
  props<{ aggregateTrees: AggregateTree[] }>()
);

export const AggregateError = createAction(DepotSearchActionTypes.AggregateError, props<{ error: AppErrorPayload }>());

export const InitDefaultAggregateTrees = createAction(DepotSearchActionTypes.InitDefaultAggregateTrees);

export const AggregateTreesInitialized = createAction(
  DepotSearchActionTypes.AggregateTreesInitialized,
  props<{ aggregateDefs: AggregateTreeDef[] }>()
);

export const LoadChildAggregates = createAction(
  DepotSearchActionTypes.LoadChildAggregates,
  props<{ node: TreeNode }>()
);

export const AddExtraFiltersToLoadChildAggs = createAction(
  DepotSearchActionTypes.AddExtraFiltersToLoadChildAggs,
  props<{ params: DepotSearchParameters; node: TreeNode }>()
);

export const SendChildAggregatesRequest = createAction(
  DepotSearchActionTypes.SendChildAggregatesRequest,
  props<{ node: TreeNode; params: DepotSearchParameters }>()
);

export const ChildAggregatesLoaded = createAction(
  DepotSearchActionTypes.ChildAggregatesLoaded,
  props<{ node: TreeNode; children: TreeNode[] }>()
);

export const LoadChildAggregatesError = createAction(
  DepotSearchActionTypes.LoadChildAggregatesError,
  props<{ error: AppErrorPayload; node: TreeNode }>()
);

export const SetSelectedMeasurementIDs = createAction(
  DepotSearchActionTypes.SetSelectionMeasurementIDs,
  props<{ measurementIDs: string[] }>()
);

export const RevertSearchAttributeChanges = createAction(DepotSearchActionTypes.RevertSearchAttributeChanges);

export const ActivateSearchBarFilter = createAction(
  DepotSearchActionTypes.ActivateSearchBarFilter,
  props<{ attributeId: string }>()
);

export const DeactivateSearchBarFilter = createAction(DepotSearchActionTypes.DeactivateSearchBarFilter);

export const SetSort = createAction(DepotSearchActionTypes.SetSort, props<{ sort?: DepotSearchSortDefinition[] }>());
