import * as DSActions from './depot-search.actions';

import { DepotSearchResult, defaultSearchParams, DepotSearchParameters } from '../depot-search.types';

import {
  availableTreeSortingMethodsForAggregateType,
  recursiveAggregateTreeNodeSort,
  aggregateTreeSortFunctionLookup
} from '../../depot-search/search-aggregate-tree/search-aggregate-tree.sorting';

import { createReducer, Action } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { pendingDeletionAttrId, ResultColumn, SearchResultEntry, searchStatus } from '../../shared/types/search.types';
import { findAggregateDefForNode, isValidAggregateType } from './../../shared/utility-functions/aggregateTree.helpers';
import { getFlatSearchFilters } from '../../order-management/order.service.helpers';
import {
  AggregateTree,
  AggregateTreeDef,
  AggregateTreeSortingMethod,
  AggregateTreeSortingMethodPerAggregateType,
  AggregateType,
  TreeNode
} from '../../shared/types/aggregateTree.types';
import { getAttributeId } from '../../shared/utility-functions/search.helpers';
import {
  DepotAttribute,
  isDepotAttribute,
  SearchAttributeAndValue,
  SemanticDepotAttribute
} from '../../shared/+state/attributes/attributes.types';
import { SortDirection } from '../../shared/types/sorting.types';

export const DEPOTSEARCH_FEATURE_KEY = 'depotSearch';

export type DepotSearchSortDefinition = {
  attribute: DepotAttribute | SemanticDepotAttribute;
  direction: SortDirection;
};

export interface DepotSearchState {
  aggregateTree: {
    aggregateTreeDefinitions: AggregateTreeDef[];
    aggregateTrees?: AggregateTree[];
    aggregateTreeStatus: searchStatus;
    aggregateTreesInitialized: boolean;
    aggregateTreeWidth: number;
    selectedTreeNode?: TreeNode;
    lazyLoadedNodes: TreeNode[];
    activeAggregateTreeSortingMethod: AggregateTreeSortingMethodPerAggregateType;
    availableAggregateTreeSortingMethods: { [key in AggregateType]: AggregateTreeSortingMethod[] };
    selectedAggregateTreeType?: AggregateType;
  };

  search: {
    depotSearchStatus: searchStatus;
    restrictToActiveCC: boolean;
    lastSearchParameter: DepotSearchParameters;
    searchBarFilters: SearchAttributeAndValue[];
    activeSearchBarFilterAttrId: string | null /* i.e. no attribute is active/opened */;
    searchResults?: DepotSearchResult;
    searchInputModified: boolean;
    searchText: string;
    sort: DepotSearchSortDefinition[];
  };

  searchBasics: {
    resultColumns?: ResultColumn[];
    resultColumnsInitialized: boolean;
  };

  ui: {
    showResultDetailView: boolean;
    selectedMeasurementIDs: string[]; // The ids of the measurements selected in the depot search list
    selectedResultEntry: SearchResultEntry | null;
    numberShownResultLines: number; //how many result lines can be shown in the result list view
    markedResultLine: number; //number of marked result list
  };

  // TODO: Check if necessary in state?
  deletion: {
    deletionsInProgress: string[]; // list of all urls to DepotBrowseContents where a delete action is currently in progress
    undeletionsInProgress: string[];
  };
}

export interface DepotSearchPartialState {
  readonly [DEPOTSEARCH_FEATURE_KEY]: DepotSearchState;
}

export const initialState: DepotSearchState = {
  aggregateTree: {
    aggregateTreeDefinitions: [],
    aggregateTreeStatus: 'init',
    aggregateTreesInitialized: false,
    aggregateTreeWidth: 200,
    lazyLoadedNodes: [],
    activeAggregateTreeSortingMethod: {
      RequestedTermAggregation: {
        method: 'numDocs',
        direction: 'desc'
      },
      RequestedDateHistogramAggregation: {
        method: 'date',
        direction: 'desc'
      }
    },
    availableAggregateTreeSortingMethods: availableTreeSortingMethodsForAggregateType
  },

  search: {
    depotSearchStatus: 'init',
    restrictToActiveCC: false,
    lastSearchParameter: defaultSearchParams,
    searchBarFilters: [],
    activeSearchBarFilterAttrId: null,
    searchInputModified: false,
    searchText: '',
    sort: []
  },

  searchBasics: {
    resultColumnsInitialized: false
  },

  ui: {
    showResultDetailView: false,
    selectedMeasurementIDs: [],
    selectedResultEntry: null,
    numberShownResultLines: 30,
    markedResultLine: -1
  },

  deletion: {
    deletionsInProgress: [],
    undeletionsInProgress: []
  }
};

const DepotSearchReducer = createReducer(
  initialState,
  immerOn(DSActions.SetSearchTextByRouter, (state, { searchText }) => {
    state.search.searchText = searchText;
    state.search.searchInputModified = isSearchInputModified(
      state.search.lastSearchParameter,
      state.search.searchText,
      state.search.searchBarFilters
    );
  }),
  immerOn(DSActions.SetSearchAttributesFiltersByRouter, (state, { searchFilters }) => {
    const sameAttributes =
      JSON.stringify(
        state.search.searchBarFilters.map((searchBarFilter) => {
          const attr = searchBarFilter.attribute;
          return isDepotAttribute(attr) ? attr.idName : attr.id;
        })
      ) ===
      JSON.stringify(
        searchFilters.map((searchFilter) => {
          const attr = searchFilter.attribute;
          return isDepotAttribute(attr) ? attr.idName : attr.id;
        })
      );
    if (sameAttributes) {
      state.search.searchBarFilters.forEach((searchBarFilter) => {
        const newSearchFilter = searchFilters.find((searchFilter) => {
          const searchBarAttr = searchBarFilter.attribute;
          const searchBarAttrId = isDepotAttribute(searchBarAttr) ? searchBarAttr.idName : searchBarAttr.id;
          const searchFilterAttr = searchFilter.attribute;
          const searchFilterAttrId = isDepotAttribute(searchFilterAttr) ? searchFilterAttr.idName : searchFilterAttr.id;
          return searchFilterAttrId === searchBarAttrId;
        });
        searchBarFilter.exact_match = newSearchFilter!.exact_match;
        searchBarFilter.searchAttributeBoolean = newSearchFilter!.searchAttributeBoolean;
        searchBarFilter.searchAttributeEnd = newSearchFilter!.searchAttributeEnd;
        searchBarFilter.searchAttributeStart = newSearchFilter!.searchAttributeStart;
        searchBarFilter.searchAttributeValue = newSearchFilter!.searchAttributeValue;
      });
    } else {
      state.search.searchBarFilters = searchFilters;
    }

    state.search.searchInputModified = isSearchInputModified(
      state.search.lastSearchParameter,
      state.search.searchText,
      state.search.searchBarFilters
    );
  }),
  immerOn(
    DSActions.PerformSearch,
    DSActions.PerformSearchOnInitialNavigation,
    DSActions.PerformSearchOnPopState,
    DSActions.PerformSearchOnRouteChange,
    (state) => {
      state.ui.showResultDetailView = false;
      state.search.depotSearchStatus = 'active';
    }
  ),
  immerOn(DSActions.SetLastSearchParameters, (state, { params }) => {
    state.search.lastSearchParameter = params;
  }),
  immerOn(DSActions.SendSearchRequest, (state) => {
    state.search.depotSearchStatus = 'active';
  }),
  immerOn(DSActions.SearchResultReceived, (state, { result, offset, isNewSearch }) => {
    const hasPreviousResults = state.search.searchResults && offset > 0;

    state.searchBasics.resultColumns?.forEach((col) => (col.contentLoaded = true));
    state.search.depotSearchStatus = 'done';

    if (hasPreviousResults) {
      const clonedResult: DepotSearchResult = JSON.parse(JSON.stringify(state.search.searchResults));
      clonedResult.entries = clonedResult.entries.concat(result.entries);
      clonedResult.numberOfEntries += result.numberOfEntries;
      state.search.searchResults = clonedResult;
    } else {
      state.search.searchResults = result;
      state.search.lastSearchParameter.offset = 0;
    }
    if (isNewSearch) {
      state.search.searchInputModified = isSearchInputModified(
        state.search.lastSearchParameter,
        state.search.searchText,
        state.search.searchBarFilters
      );
    }
  }),
  immerOn(DSActions.SearchError, (state) => {
    state.search.depotSearchStatus = 'error';
  }),
  immerOn(DSActions.LoadMoreResults, (state) => {
    state.search.lastSearchParameter.offset += state.search.lastSearchParameter.searchLimit;
  }),
  immerOn(DSActions.SelectTreeNode, (state, { node }) => {
    state.ui.showResultDetailView = false;
    if (node === undefined) {
      state.aggregateTree.lazyLoadedNodes = [];
    }
    if (state.aggregateTree.selectedTreeNode?.id === node?.id) {
      // Selected node was already selected => unselect it
      state.aggregateTree.selectedTreeNode = undefined;
    } else {
      state.aggregateTree.selectedTreeNode = node;
    }
  }),
  immerOn(DSActions.UnselectTreeNode, (state) => {
    state.ui.showResultDetailView = false;
    state.aggregateTree.selectedTreeNode = undefined;
  }),
  immerOn(DSActions.UpdateSelectedNode, (state, { node }) => {
    state.aggregateTree.selectedTreeNode = node;
    state.aggregateTree.lazyLoadedNodes = [];
    const selectedNode = findNodeInTrees(node, state.aggregateTree.aggregateTrees);
    const parent = selectedNode?.parent;
    const parentNode = findNodeInTrees(parent, state.aggregateTree.aggregateTrees);
    expandAllParents(parentNode, state.aggregateTree.aggregateTrees);
  }),
  immerOn(DSActions.ToggleTreeNode, (state, { node, expanded }) => {
    const nodeToToggle = findNodeInTrees(node, state.aggregateTree.aggregateTrees);
    if (nodeToToggle) {
      nodeToToggle.expanded = expanded;
      if (state.aggregateTree.selectedTreeNode?.id === nodeToToggle.id) {
        state.aggregateTree.selectedTreeNode.expanded = expanded;
      }
    }
  }),
  immerOn(DSActions.SetAggregateTreeWidth, (state, { width }) => {
    state.aggregateTree.aggregateTreeWidth = width;
  }),
  immerOn(DSActions.AddSearchBarFilter, (state, { attribAndValue }) => {
    const attribute = attribAndValue.attribute;
    if (attribute.type !== 'Boolean') {
      state.search.activeSearchBarFilterAttrId = isDepotAttribute(attribute) ? attribute.idName : attribute.id;
    }
  }),
  immerOn(DSActions.ActivateSearchBarFilter, (state, { attributeId }) => {
    state.search.activeSearchBarFilterAttrId = attributeId;
  }),
  immerOn(DSActions.DeactivateSearchBarFilter, (state) => {
    state.search.activeSearchBarFilterAttrId = null;
  }),
  immerOn(DSActions.RestrictToActiveCC, (state, { restrictToActiveCC }) => {
    state.search.restrictToActiveCC = restrictToActiveCC;
  }),
  immerOn(DSActions.ResultColumnsInitialized, (state, { columns }) => {
    state.searchBasics.resultColumnsInitialized = true;
    state.searchBasics.resultColumns = columns;
  }),
  immerOn(DSActions.AddResultColumn, (state, { attrib }) => {
    const field = isDepotAttribute(attrib) ? attrib.idName : attrib.id;
    const isNewColumn = state.searchBasics.resultColumns?.every((x) => x.field !== field) ?? true;
    if (isNewColumn) {
      const newColumn: ResultColumn = {
        attribute: attrib,
        field: field,
        contentLoaded: false
      };
      state.searchBasics.resultColumns = state.searchBasics.resultColumns?.concat(newColumn) ?? [newColumn];
    }
  }),
  immerOn(DSActions.ColumnContentReceived, (state, { result, column }) => {
    if (state.search.searchResults) {
      const resultsToUpdate: DepotSearchResult = JSON.parse(JSON.stringify(state.search.searchResults));
      for (const newEntry of result.entries) {
        updateExistingResultEntry(resultsToUpdate.entries, newEntry);
      }
      state.searchBasics.resultColumns?.forEach((col) => {
        if (col.field === column.field) {
          col.contentLoaded = true;
        }
      });
      state.search.searchResults = resultsToUpdate;
    }
  }),
  immerOn(DSActions.NewColumnError, (state, { error, column }) => {
    const errorColumnIndex = state.searchBasics.resultColumns?.findIndex((col) => col.field === column.field);
    if (errorColumnIndex && errorColumnIndex >= 0) {
      state.searchBasics.resultColumns!.splice(errorColumnIndex, 1);
    }
  }),
  immerOn(DSActions.RemoveResultColumn, (state, { column }) => {
    state.searchBasics.resultColumns = state.searchBasics.resultColumns?.filter((col) => col.field !== column.field);
  }),
  immerOn(DSActions.ViewMeasurementDetails, (state, { result }) => {
    state.ui.showResultDetailView = true;
    state.ui.selectedResultEntry = result;
  }),
  immerOn(DSActions.ViewMeasurementOverviewList, (state) => {
    state.ui.showResultDetailView = false;
    state.ui.selectedResultEntry = null;
  }),
  immerOn(DSActions.SetNumberShownResultLines, (state, { numberOfResultLines }) => {
    state.ui.numberShownResultLines = numberOfResultLines;
  }),
  immerOn(DSActions.SetMarkedResultLine, (state, { markedResultLine }) => {
    state.ui.markedResultLine = markedResultLine;
  }),
  immerOn(DSActions.DeleteMeasurement, (state, { measurement }) => {
    if (!state.deletion.deletionsInProgress.includes(measurement.browseUrl)) {
      state.deletion.deletionsInProgress.push(measurement.browseUrl);
    }
  }),
  immerOn(DSActions.MeasurementDeleted, (state, { measurement }) => {
    for (const measurementId of measurement.ids) {
      const result = state.search.searchResults?.entries?.find((entry) => entry.measurementId === measurementId);
      if (result) {
        result[pendingDeletionAttrId] = 'true';
      }
    }
    const index = state.deletion.deletionsInProgress.indexOf(measurement.browseUrl);
    if (index >= 0) {
      state.deletion.deletionsInProgress.splice(index, 1);
    }
  }),
  immerOn(DSActions.DeleteMeasurementError, (state, { measurement }) => {
    const index = state.deletion.deletionsInProgress.indexOf(measurement.browseUrl);
    if (index >= 0) {
      state.deletion.deletionsInProgress.splice(index, 1);
    }
  }),
  immerOn(DSActions.UndeleteMeasurement, (state, { measurement }) => {
    if (!state.deletion.undeletionsInProgress.includes(measurement.browseUrl)) {
      state.deletion.undeletionsInProgress.push(measurement.browseUrl);
    }
  }),
  immerOn(DSActions.MeasurementUndeleted, (state, { measurement }) => {
    for (const measurementId of measurement.ids) {
      const result = state.search.searchResults?.entries?.find((entry) => entry.measurementId === measurementId);
      if (result) {
        result[pendingDeletionAttrId] = 'false';
      }
    }
    const index = state.deletion.undeletionsInProgress.indexOf(measurement.browseUrl);
    if (index >= 0) {
      state.deletion.undeletionsInProgress.splice(index, 1);
    }
  }),
  immerOn(DSActions.UndeleteMeasurementError, (state, { measurement }) => {
    const index = state.deletion.undeletionsInProgress.indexOf(measurement.browseUrl);
    if (index >= 0) {
      state.deletion.undeletionsInProgress.splice(index, 1);
    }
  }),
  immerOn(DSActions.AggregateTreesInitialized, (state, { aggregateDefs: aggregates }) => {
    state.aggregateTree.aggregateTreesInitialized = true;
    state.aggregateTree.aggregateTreeDefinitions = aggregates;
  }),
  immerOn(DSActions.SetAggregateTreeSelectedType, (state, { aggregateType }) => {
    if (isValidAggregateType(aggregateType)) {
      state.aggregateTree.selectedAggregateTreeType = aggregateType;
    } else {
      console.warn(`Attempted to set ${aggregateType} as selected AggregateTreeType (invalid value)`);
    }
  }),
  immerOn(DSActions.GetAggregates, (state, { aggregateDefs }) => {
    state.aggregateTree.aggregateTreeStatus = 'active';
  }),
  immerOn(DSActions.AggregatesReceived, (state, { aggregateTrees }) => {
    state.aggregateTree.aggregateTreeStatus = 'done';
    state.aggregateTree.aggregateTrees = aggregateTrees;
    if (!state.aggregateTree.selectedAggregateTreeType && aggregateTrees.length > 0) {
      state.aggregateTree.selectedAggregateTreeType = aggregateTrees[0].definition.aggregate.type;
    }
  }),
  immerOn(DSActions.SortAggregateTrees, (state) => {
    if (state.aggregateTree.aggregateTrees) {
      const clonedTrees: AggregateTree[] = JSON.parse(JSON.stringify(state.aggregateTree.aggregateTrees));
      state.aggregateTree.aggregateTrees = clonedTrees.map((tree) => {
        const type: AggregateType = tree.definition.aggregate.type;
        const sortMethod = state.aggregateTree.activeAggregateTreeSortingMethod[type];
        const compareFunction = aggregateTreeSortFunctionLookup[sortMethod.method][sortMethod.direction];
        recursiveAggregateTreeNodeSort(tree.nodes, compareFunction);
        return tree;
      });
    }
  }),
  immerOn(DSActions.AggregateError, (state, { error }) => {
    state.aggregateTree.aggregateTreeStatus = 'error';
  }),
  immerOn(DSActions.SetAggregateTreeSortingMethod, (state, { method }) => {
    let nextMethod = { ...method };

    if (state.aggregateTree.selectedAggregateTreeType) {
      // Sort Direction Toggle
      if (
        state.aggregateTree.activeAggregateTreeSortingMethod[state.aggregateTree.selectedAggregateTreeType].method ===
        nextMethod.method
      ) {
        nextMethod = {
          ...nextMethod,
          direction: nextMethod.direction === 'asc' ? 'desc' : 'asc'
        };
      }
      state.aggregateTree.activeAggregateTreeSortingMethod = {
        ...state.aggregateTree.activeAggregateTreeSortingMethod,
        [state.aggregateTree.selectedAggregateTreeType]: nextMethod
      };
    }
  }),
  immerOn(DSActions.RestoreActiveAggregateTreeSortingMethods, (state, { methods }) => {
    state.aggregateTree.activeAggregateTreeSortingMethod = methods;
  }),
  immerOn(DSActions.LoadChildAggregates, (state, { node }) => {
    const foundNode = findNodeInTrees(node, state.aggregateTree.aggregateTrees);
    if (foundNode) {
      foundNode.childrenLoadingStatus = 'active';
    }
  }),
  immerOn(DSActions.LoadChildAggregatesError, (state, { error, node }) => {
    const foundNode = findNodeInTrees(node, state.aggregateTree.aggregateTrees);
    if (foundNode) {
      foundNode.childrenLoadingStatus = 'error';
    }
  }),
  immerOn(DSActions.ChildAggregatesLoaded, (state, { node, children }) => {
    const foundNode = findNodeInTrees(node, state.aggregateTree.aggregateTrees);
    if (foundNode) {
      const chi = JSON.parse(JSON.stringify(children));

      chi.forEach((child) => {
        child.parent = node;
        const aggDefForChild = findAggregateDefForNode(
          child.aggregate.guid,
          state.aggregateTree.aggregateTreeDefinitions
        );
        child.hasChildren = aggDefForChild?.aggregate.child !== undefined;
        child.childrenLoadingStatus = child.hasChildren ? 'init' : 'done';
      });

      foundNode.childrenLoadingStatus = 'done';
      foundNode.children = chi;
      state.aggregateTree.lazyLoadedNodes = state.aggregateTree.lazyLoadedNodes.concat(chi); // TODO: find existing nodes and replace

      if (state.aggregateTree.selectedTreeNode) {
        state.aggregateTree.selectedTreeNode =
          children.find((x) => x.label === state.aggregateTree.selectedTreeNode!.label) ??
          state.aggregateTree.selectedTreeNode;
      }
    }
  }),
  immerOn(DSActions.LazyLoadedNodeReselected, (state, { selectedNode, loadedNodes, selectionPath }) => {
    if (selectionPath) {
      let selectionNode;
      selectionPath?.forEach((node) => {
        let foundNode = findNodeInTrees(node, state.aggregateTree.aggregateTrees);
        foundNode = { ...node, hasChildren: true, childrenLoadingStatus: 'init', expanded: true };
        selectionNode = foundNode;
      });
      state.aggregateTree.selectedTreeNode = selectionNode;
      state.aggregateTree.lazyLoadedNodes = loadedNodes;
    }
  }),
  immerOn(DSActions.ResetLazyLoadedNodesList, (state) => {
    state.aggregateTree.lazyLoadedNodes = [];
  }),
  immerOn(DSActions.SetSelectedMeasurementIDs, (state, { measurementIDs }) => {
    state.ui.selectedMeasurementIDs = measurementIDs;
  }),
  immerOn(DSActions.RevertSearchAttributeChanges, (state) => {
    const hasValue = state.search.lastSearchParameter.searchFilters !== undefined;
    if (hasValue) {
      state.search.searchBarFilters = getFlatSearchFilters(state.search.lastSearchParameter.searchFilters!);
    }
  }),
  immerOn(DSActions.SetSort, (state, { sort }) => {
    if (sort) {
      const sameSort = JSON.stringify(state.search.sort) === JSON.stringify(sort);
      if (sameSort) {
        state.search.sort = [];
      } else {
        state.search.sort = sort;
      }
    } else {
      state.search.sort = [];
    }
  })
);

export function depotSearchReducer(state: DepotSearchState | undefined, action: Action) {
  return DepotSearchReducer(state, action);
}

function updateExistingResultEntry(existingResultEntries: SearchResultEntry[], newResultEntry: SearchResultEntry) {
  const existingEntry = existingResultEntries.find(
    (existing) => existing.measurementId === newResultEntry.measurementId
  );
  if (existingEntry) {
    for (const property in newResultEntry) {
      if (
        Object.prototype.hasOwnProperty.call(newResultEntry, property) &&
        property !== 'measurementBrowseUrl' &&
        property !== 'measurementId'
      ) {
        existingEntry[property] = newResultEntry[property];
      }
    }
  }
}

function findNodeInTrees(nodeToFind?: TreeNode, trees?: AggregateTree[]): TreeNode | undefined {
  if (nodeToFind && trees) {
    for (const tree of trees) {
      const foundNode = findNode(nodeToFind, tree.nodes);
      if (foundNode) {
        return foundNode;
      }
    }
  }
  return undefined;
}

function expandAllParents(parentNode?: TreeNode, aggregateTrees?: AggregateTree[]) {
  while (parentNode) {
    parentNode.expanded = true;
    parentNode = findNodeInTrees(parentNode.parent, aggregateTrees);
  }
}

const isSearchInputModified = (
  lastSearchParameter: DepotSearchParameters,
  searchText: string,
  searchBarFilters: SearchAttributeAndValue[]
): boolean => {
  let isSame = lastSearchParameter.text === searchText;
  if (isSame && lastSearchParameter.searchFilters) {
    const lastSearchFilters = getFlatSearchFilters(lastSearchParameter.searchFilters);
    isSame = isSame && lastSearchFilters?.length === searchBarFilters.length;

    lastSearchFilters.forEach((lastSearchFilter) => {
      const curSearchFilter = searchBarFilters.find(
        (curFilter) => getAttributeId(curFilter) === getAttributeId(lastSearchFilter)
      );
      isSame = isSame && curSearchFilter !== undefined;
      if (isSame) {
        isSame = isSame && lastSearchFilter.exact_match === curSearchFilter!.exact_match;
        isSame = isSame && lastSearchFilter.searchAttributeValue === curSearchFilter!.searchAttributeValue;
        isSame = isSame && lastSearchFilter.searchAttributeBoolean === curSearchFilter!.searchAttributeBoolean;
        isSame = isSame && lastSearchFilter.searchAttributeStart === curSearchFilter!.searchAttributeStart;
        isSame = isSame && lastSearchFilter.searchAttributeEnd === curSearchFilter!.searchAttributeEnd;
      }
    });
  }
  return !isSame;
};

function findNode(nodeToFind: TreeNode, nodes?: TreeNode[]): TreeNode | undefined {
  if (nodes) {
    for (const currentNode of nodes) {
      if (haveSameValueAttributeAndParents(currentNode, nodeToFind)) {
        if (currentNode.parent) {
          currentNode.expanded = true;
        }
        return currentNode;
      }
      const childNodeFound = findNode(nodeToFind, currentNode.children);
      if (childNodeFound) {
        currentNode.expanded = true;
        return childNodeFound;
      }
    }
  }
  return undefined;
}

function haveSameValueAttributeAndParents(node1: TreeNode, node2: TreeNode): boolean {
  const sameValueAndAttr = node1.label === node2.label;
  if (!sameValueAndAttr) {
    return false;
  }

  if ((node1.parent && !node2.parent) || (!node1.parent && node2.parent)) {
    return false;
  }
  if (node1.parent && node2.parent) {
    return haveSameValueAttributeAndParents(node1.parent, node2.parent);
  }
  return true;
}
