import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { translateDepotAttribute, translateSemanticDepotAttribute } from '../utility-functions/translateAttribute';
import { availableLanguagesStrings } from '../../app.locales';
import { DepotAttribute, isDepotAttribute, SemanticDepotAttribute } from '../+state/attributes/attributes.types';

@Pipe({
  name: 'translateAttribute',
  pure: false
})
export class TranslateAttributePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: DepotAttribute | SemanticDepotAttribute | undefined): string {
    if (!value) {
      return '';
    }
    const currentLanguage = this.translate.currentLang as availableLanguagesStrings;
    return isDepotAttribute(value)
      ? translateDepotAttribute(value, currentLanguage)
      : translateSemanticDepotAttribute(value, currentLanguage);
  }
}
