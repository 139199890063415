import { AggregateTree } from '../shared/types/aggregateTree.types';
import { SearchParameters, SearchResultEntry } from '../shared/types/search.types';
import { defaultOrderSearchLimit } from './order.constants';
export interface OrderSearchParameters extends SearchParameters {
  collapse?: string;
}

export const defaultSearchParams: OrderSearchParameters = {
  text: '',
  searchLimit: defaultOrderSearchLimit,
  offset: 0,
  searchFilters: []
};

export interface OrderSearchResult {
  numberOfEntries: number;
  numberOfEntriesLastSearch: number;
  numberOfTotalResults: number;
  entries: SearchResultEntry[];
  aggregates?: AggregateTree[];
}

export interface OrderProject {
  name: string;
  count: number;
  date_last_modified?: string;
  date_created?: string;
}
