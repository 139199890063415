import { from, Observable, of, OperatorFunction } from 'rxjs';
import { filter, tap, catchError, map, concatMap } from 'rxjs/operators';
import { keepInput } from '../shared/operators';
import { Spec } from '@muellerbbm-vas/grivet';
import {
  SearchAttributeAndValue,
  SearchAttributeCompound,
  SearchFilter
} from '../shared/+state/attributes/attributes.types';

export interface UrlCache {
  [index: string]: URL;
}

/* URL caching related helper functions */

export const getUrlFromCache = (urlCache: UrlCache, path: string[]): URL => {
  const identifier = path.join('_');
  const ret = urlCache[identifier];
  return ret;
};

export const setUrlInCache = (urlCache: UrlCache, path: string[], value: URL): void => {
  const identifier = path.join('_');
  urlCache[identifier] = value;
};

const removeUrlFromCache = (urlCache: UrlCache, path: string[]) => {
  const identifier = path.join('_');
  delete urlCache[identifier];
};

export const urlIsCached = (): OperatorFunction<URL, URL> => {
  return (input$) => input$.pipe(filter((url) => url !== undefined));
};

export const urlIsNotCached = (): OperatorFunction<URL, URL> => {
  return (input$) => input$.pipe(filter((url) => url === undefined));
};

interface DoSthWithUrlAsync {
  fct: (url: URL, ...args: any[]) => Promise<any>; // async function working on an url
  args: any[]; // arguments to use in that fct
}

interface UrlRefresher {
  // in case we need to again follow the links to know which url to use since the cached url is not up-to-date
  urlCache: UrlCache;
  cacheKey: string[];
  getUrlFct: (...args: any[]) => Observable<URL | undefined>;
  getUrlArgs: any[];
}

export interface ToDo {
  fct: (url: URL) => Promise<Spec.JsonApiDocument>;
  args: any[];
}

interface ToDoResult {
  error: boolean;
  result: any;
}

export function doAndRepeatWithFreshUrlIfNecessary(): OperatorFunction<[DoSthWithUrlAsync, UrlRefresher], any> {
  return (input$) =>
    input$.pipe(
      keepInput(
        concatMap(([todo, urlRefresher]) =>
          from(todo.fct(getUrlFromCache(urlRefresher.urlCache, urlRefresher.cacheKey), ...todo.args))
        ),
        map((result) => ({ error: false, result: result } as ToDoResult)),
        catchError((err) => of({ error: err, result: undefined } as ToDoResult).pipe())
      ),
      concatMap(([[todo, urlRefresher], todoResult]) => {
        if (!todoResult.error) {
          return of(todoResult.result);
        } else {
          return of(todoResult.error).pipe(
            tap(() => removeUrlFromCache(urlRefresher.urlCache, urlRefresher.cacheKey)),
            concatMap(() => urlRefresher.getUrlFct(...urlRefresher.getUrlArgs)),
            filter((url) => url !== undefined),
            concatMap((url) => from(todo.fct(url!, ...todo.args)))
          );
        }
      })
    );
}

export function isSearchAttributeCompound(
  candidate: SearchAttributeCompound | SearchAttributeAndValue
): candidate is SearchAttributeCompound {
  return Object.prototype.hasOwnProperty.call(candidate, 'type');
}

export function isSearchAttributeAndValue(
  candidate: SearchAttributeCompound | SearchAttributeAndValue
): candidate is SearchAttributeAndValue {
  return !isSearchAttributeCompound(candidate);
}

export function getFlatSearchFilters(filters: SearchFilter): SearchAttributeAndValue[] {
  const result: SearchAttributeAndValue[] = [];

  filters.forEach((filter) => {
    if (isSearchAttributeCompound(filter)) {
      result.push(...getFlatSearchFilters(filter.filters));
    } else {
      result.push(filter);
    }
  });

  return result;
}
