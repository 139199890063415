import { Pipe, PipeTransform } from '@angular/core';
import {
  AttributeGroup,
  DepotAttribute,
  isSemanticAttribute,
  SemanticDepotAttribute
} from '../../../shared/+state/attributes/attributes.types';

@Pipe({
  name: 'searchBarAttributeGroupFilter'
})
export class SearchBarAttributeGroupFilterPipe implements PipeTransform {
  transform(
    attributes: (SemanticDepotAttribute | DepotAttribute)[],
    group: AttributeGroup | null
  ): (SemanticDepotAttribute | DepotAttribute)[] | null {
    if (attributes && group) {
      return attributes.filter(
        (attribute) => isSemanticAttribute(attribute) && attribute.attributeGroupID === group.id
      );
    }
    if (attributes && group === null) {
      return attributes.filter((attribute) => isSemanticAttribute(attribute) && attribute.attributeGroupID === null);
    }

    return null;
  }
}
